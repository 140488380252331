import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, FormGroup, Label, Row } from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetFormByLogbookTypeQuery } from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import { TraineeLogbookEntryGeneralType } from './TraineeLogbookEntryGeneralType';
import loader from '../../../../assets/images/loader.gif';

const TraineeLogbookEntry = () => {
  const [form, setForm] = useState<LogbookEntrySetupMaster>(
    {} as LogbookEntrySetupMaster,
  );

  const [formId, setFormId] = useState<string>('');
  const [formName, setFormName] = useState<string>('');

  const { data: formSetups, isLoading: isFormSetupsLoading } = useGetFormByLogbookTypeQuery(1);
  const forms = formSetups?.data || [];

  let formOptions: SelectOption[] = [
    ...forms?.map((e: LogbookEntrySetupMaster) => ({
      label: e.particulars!,
      value: e.id!,
    })),
  ];

  const handleFormSelect = (e: any) => {
    const { options, selectedIndex, value } = e.target;
    const form = forms.find((form) => form.id === value);
    setForm(form!);
    setFormId(value);
    setFormName(options[selectedIndex].innerHTML);
  };

  return (
    <div className='page-content'>
      {isFormSetupsLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <div className='page-breadcrumb-wrapper'>
          <BetterBreadcrumb
            title="Logbook Entry"
            breadcrumbItem={[
              {
                link: '/trainee/logbook-entry',
                name: 'Logbook Entry List',
              },
            ]}
            component={
              <Link
                to="/trainee/logbook-entry"
                className="btn btn-success waves-effect waves-light"
              >
                <i className="fas fa-arrow-left me-2" /> Back
              </Link>
            }
          />
        </div>

        <div className="row align-items-center justify-content-center">
          <div className="card">
            {/* <div className="d-flex card-header">
              <h2 className="card-title mb-0 flex-grow-1">
                <i className="fa fa-plus me-2" />
                Logbook Entry
              </h2>
              <div className="flex-shrink-0 align-self-end">
                <Link
                  to="/trainee/logbook-entry"
                  className="btn btn-success waves-effect waves-light"
                >
                  <i className="fas fa-arrow-left me-2" /> Back
                </Link>
              </div>
            </div> */}
            <div className="card-body">
              <Row>
                <Col lg={4} md={6} sm={12}>
                  <FormGroup className="mb-3">
                    <Label className="form-label">Form</Label>
                    <InputField
                      type="select"
                      name="form"
                      placeholder=""
                      value={formId}
                      options={formOptions}
                      onChange={(e) => {
                        handleFormSelect(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <TraineeLogbookEntryGeneralType
                  key={form?.id}
                  form={form}
                  formName={formName}
                  setFormId={setFormId}
                  setForm={setForm}
                />
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TraineeLogbookEntry;
