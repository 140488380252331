import { Button } from 'reactstrap';
import EditLookup from './EditLookup';
import { useState } from 'react';
import { useFormik } from 'formik';

interface Props {
  lookup: LookupChild;
  index: number;
  parentName: string;
}

const LookupTableRow = ({ lookup, index, parentName }: Props) => {
  const [isHidden, setIsHidden] = useState(false);

  return (
    <>
      <tr key={lookup.id}>
        <th scope="row">{index + 1}</th>
        <td>{parentName}</td>

        <td>{lookup.lookup_list_name}</td>
        <td>{lookup.serial_no}</td>
        <td>{lookup.status || 'N/A'}</td>
        <td>{lookup.default_ind || 'N/A'}</td>
        <td>{lookup.required_ind || 'N/A'}</td>
        <td>
          <Button
            color="primary"
            outline
            size="sm"
            onClick={() => setIsHidden(true)}
          >
            Edit
          </Button>
        </td>
      </tr>

      <EditLookup
        isOpen={isHidden}
        toggle={() => setIsHidden(!isHidden)}
        initialValues={lookup}
      />
    </>
  );
};

export default LookupTableRow;
