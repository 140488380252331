import { useFormik } from "formik";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { Button, InputField } from "src/components";
import { setFromErrors } from "src/helpers";
import { useOperationRecordEntryMutation } from "src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi";
import * as Yup from 'yup';

interface RecordOfOperationProps {
    departmentId?: string;
    operationRecordToEdit?: OperationRecordList;
    setModalShow?: any;
}

const initialValues: OperationRecordEntry = {
    operation_record_id: '',
    form_b_department_id: '',
    period_from: '',
    period_to: '',
    operation_type: '',
    operation_name: '',
    number_of_operation: 0,
};

const RecordOfOperationOfTheDeptModal = (props: RecordOfOperationProps) => {
    const { departmentId, operationRecordToEdit, setModalShow } = props;

    const [operationRecordEntry, { data: operationRecordEntryData, isSuccess: isOperationRecordEntryDataSuccess, isLoading: isOperationRecordEntryDataLoading, isError: isOperationRecordEntryDataError, error: operationRecordEntryDataError }] =
        useOperationRecordEntryMutation();

    const err: CustomError = operationRecordEntryDataError as CustomError;

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({}),

        onSubmit: (values: OperationRecordEntry) => {
            console.log(values);
            values.form_b_department_id = departmentId;
            operationRecordEntry(values);
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    const handleFormReset = () => {
        resetForm();
    };

    useEffect(() => {
        if (operationRecordToEdit?.id) {
            setValues({
                operation_record_id: operationRecordToEdit?.id,
                form_b_department_id: operationRecordToEdit?.form_b_department_id,
                period_from: operationRecordToEdit?.period_from,
                period_to: operationRecordToEdit?.period_to,
                operation_type: operationRecordToEdit?.operation_type,
                operation_name: operationRecordToEdit?.operation_name,
                number_of_operation: operationRecordToEdit?.number_of_operation,
            });
        }
    }, []);

    useEffect(() => {
        if (isOperationRecordEntryDataSuccess) {
            handleFormReset();
            toast.success('Operation record saved successfully');
            setModalShow(false);
        }
        if (isOperationRecordEntryDataError) {
            Object.entries(err?.data).forEach((entry: any) => {
                entry[1]?.forEach((message: any) => {
                    toast.error(message);
                });
            });

            setFromErrors(err.data, setErrors);
        }
    }, [isOperationRecordEntryDataSuccess, isOperationRecordEntryDataError]);

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title">
                                    <i className="fa fa-plus me-2" />
                                    Record of operation of the department
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    autoComplete="off"
                                    className="custom-form"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    onReset={(e) => {
                                        e.preventDefault();
                                        handleFormReset();
                                    }}
                                >
                                    <Row>
                                        <Col sm={12} md={3}>
                                            <FormGroup className="mb-3">
                                                <Label for="period_from">From</Label>
                                                <InputField
                                                    type="date"
                                                    placeholder=""
                                                    {...getFieldProps('period_from')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={3}>
                                            <FormGroup className="mb-3">
                                                <Label for="period_to">To</Label>
                                                <InputField
                                                    type="date"
                                                    placeholder=""
                                                    {...getFieldProps('period_to')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="operation_type">Operation Type</Label>
                                                <InputField
                                                    type="select"
                                                    options={[{ label: 'Major', value: 'Major' }, { label: 'Minor', value: 'Minor' }, { label: 'Emergency', value: 'Emergency' }]}
                                                    placeholder=""
                                                    {...getFieldProps('operation_type')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="operation_name">Name of {values.operation_type === 'Minor operation done in OPD in the department' ? 'Minor' : values.operation_type} Operation Done</Label>
                                                <InputField
                                                    type="text"
                                                    placeholder=""
                                                    {...getFieldProps('operation_name')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={4}>
                                            <FormGroup className="mb-3">
                                                <Label for="number_of_operation">No. of Procedure</Label>
                                                <InputField
                                                    min={'0'}
                                                    type="number"
                                                    placeholder=""
                                                    {...getFieldProps('number_of_operation')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} className="mt-3">
                                            <FormGroup className="mb-3 text-center">
                                                <Button
                                                    type="reset"
                                                    text="Reset"
                                                    className="btn btn-warning me-2"
                                                ></Button>
                                                <Button
                                                    type="submit"
                                                    text="Save"
                                                    className="btn btn-success me-2"
                                                    disabled={isOperationRecordEntryDataLoading}
                                                ></Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default RecordOfOperationOfTheDeptModal;