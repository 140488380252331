import { useEffect, useState } from "react";
import { Button as BsButton, Col } from "reactstrap";
import * as url from 'src/helpers/url_helper';
import {
    useGetCommentsDataByMutation,
} from 'src/rtk/features/disertation-thesis/crudApi';
import CommentsModal from "./CommentsModal";

const CommentBtn = (props: any) => {

    const {
        protocolEntry,
        showCommentSectionModal,
        setShowCommentSectionModal,
        commentsFieldName,
        setCommentsFieldName,
        commentsFieldNameToCompare,
    } = props;

    const [messages, setMessages] = useState<any>([]);    

    const [
        commentsDataList,
        {
            isLoading: commentsDataListLoading,
            isSuccess: commentsDataListLoadingSuccess,
            error: commentsDataListLoadingError,
        },
    ] = useGetCommentsDataByMutation();

    const getCommentsData = (id: any, nameOfTheField: any) => {
        commentsDataList(`${url.THESIS_DISSERTATION_PROTOCOL_COMMENT_LIST}/${id}/${nameOfTheField}`).then(function (result: any,) {
            // here you can use the result of promiseB
            if (result?.data?.data) {
                setMessages(result?.data?.data);
            }
        });
    };

    useEffect(() => {
        if (protocolEntry?.id && commentsFieldNameToCompare) {
            getCommentsData(protocolEntry?.id, commentsFieldNameToCompare);
        }
    }, [protocolEntry, commentsFieldNameToCompare]);

    return (
        <>
            <Col sm={'auto'} className='noti-icon position-relative'>
                <span className="badge bg-danger rounded-pill">{messages?.length ? messages?.length : 0}</span>
                <BsButton
                    type='button'
                    className='btn btn-primary btn-sm'
                    onClick={(e) => {
                        setCommentsFieldName(commentsFieldNameToCompare);
                        setShowCommentSectionModal(!showCommentSectionModal);                        
                    }}
                >
                    <i className='fas fa-comments'></i>
                </BsButton>
            </Col>
            {showCommentSectionModal && commentsFieldName === commentsFieldNameToCompare ?
                <CommentsModal
                    fieldName={commentsFieldName}
                    showModal={showCommentSectionModal}
                    setShowModal={setShowCommentSectionModal}
                    existingProtocol={protocolEntry}
                    messages={messages}
                    setMessages={setMessages}
                />
                :
                null
            }
        </>
    )
};

export default CommentBtn;