import { apiSlice } from 'src/rtk/api/apiSlice';
import * as lookupChildUrl from 'src/helpers/url_helper';

export const lookupChildApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['LookupChild'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLookupChild: builder.query<APIResponse<LookupChild>, string>({
        query: (uuid: string) => ({
          method: 'GET',
          url: `${lookupChildUrl.GET_LOOKUP_CHILD}/${uuid}`,
        }),
        providesTags: ['LookupChild'],
      }),

      createLookupChild: builder.mutation<
        APIResponse<LookupChild>,
        LookupChild
      >({
        query: (data) => ({
          url: `${lookupChildUrl.CREATE_LOOKUP_CHILD}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['LookupChild'],
      }),

      updateLookupChild: builder.mutation<APIResponse<LookupChild>, string>({
        query: (url) => ({
          url: `${lookupChildUrl.UPDATE_LOOKUP_CHILD}/${url}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['LookupChild'],
      }),
      
      deleteLookupChild: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${lookupChildUrl.DELETE_LOOKUP_CHILD}/${id}`,
        }),
        invalidatesTags: ['LookupChild'],
      }),
    }),
  });

export const {
  useGetLookupChildQuery,
  //   useGetLookupChildQuery,
  useCreateLookupChildMutation,
  useUpdateLookupChildMutation,
  useDeleteLookupChildMutation,
} = lookupChildApi;
