import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { putUrlString } from 'src/helpers';
import { useGetLogbookEntrySetupDetailsQuery } from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import { useGetLogbookEntrySetupDetailsValueQuery, useUpdateLogbookEntrySetupDetailsValueMutation } from 'src/rtk/features/setup/logBookEntrySetupDetailsValue/logBookEntrySetupDetailsValueApi';
import * as Yup from 'yup';

const initialValues: LogbookEntrySetupDetailsValue = {
    id: '',
    logbook_entry_setup_detail_id: '',
    value_list: '',
    required_ind: 1,
    is_show: 1,
    sl_no: 0,
    default_ind: 1,
    status: true,
    creator_organization_id: '',
    updater_organization_id: '',
    created_by: '',
    updated_by: '',
    deleted_by: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
  };

export const EditLogbookEntrySetupDetailsValue = () => {
  const { id } = useParams<{ id: string }>();
  const { data: logbookEntrySetupDetailsValue, isError: isLogbookEntrySetupDetailsValueFetchError } = useGetLogbookEntrySetupDetailsValueQuery(id);
  const [updateLogbookEntrySetupDetailsValue, { isSuccess, isError, error }] = useUpdateLogbookEntrySetupDetailsValueMutation();
  const [requiredStatus, setRequiredStatus] = useState<boolean>(true);
  const [showStatus, setShowStatus] = useState<boolean>(true);  
  const [defaultStatus, setDefaultStatus] = useState<boolean>(true);

  const { data: logbookEntrySetupDetailsData } = useGetLogbookEntrySetupDetailsQuery();
  const logbookEntrySetupDetails = logbookEntrySetupDetailsData?.data || [];

  const err: CustomError = error as CustomError;
  const history = useHistory();
  const {
    handleSubmit,
    handleChange,
    getFieldProps,
    values,
    touched,
    errors,
    setValues,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
        logbook_entry_setup_detail_id: Yup.string().required('Please select the logbook entry setup detail'),        
    }),

    onSubmit: (values) => {
        if(requiredStatus) {
            values.required_ind = 1;
        } else {
            values.required_ind = 0;
        }

        if(showStatus) {
            values.is_show = 1;
        } else {
            values.is_show = 0;
        }       

        if(defaultStatus) {
            values.default_ind = 1;
        } else {
            values.default_ind = 0;
        }

        const url = putUrlString(id, values);
        updateLogbookEntrySetupDetailsValue(url);
    },
  });

  useEffect(() => {
    if (logbookEntrySetupDetailsValue && !isLogbookEntrySetupDetailsValueFetchError) {
      const data: LogbookEntrySetupDetailsValue = (logbookEntrySetupDetailsValue as any).data;
      setValues({
        id: data.id,
        logbook_entry_setup_detail_id: data.logbook_entry_setup_detail_id,
        value_list: data.value_list,
        required_ind: data.required_ind,
        is_show: data.is_show,
        sl_no: data.sl_no,
        default_ind: data.default_ind,
        status: data.status,
        creator_organization_id: data.creator_organization_id,
        updater_organization_id: data.updater_organization_id,
        created_by: data.created_by,
        updated_by: data.updated_by,
        deleted_by: data.deleted_by,
        created_at: data.created_at,
        updated_at: data.updated_at,
        deleted_at: data.deleted_at,
      });

      if(data.required_ind === 1) {
        setRequiredStatus(true);
      } else {
        setRequiredStatus(false);
      }

      if(data.is_show === 1) {
        setShowStatus(true);
      } else {
        setShowStatus(false);
      }     

      if(data.default_ind === 1) {
        setDefaultStatus(true);
      } else {
        setDefaultStatus(false);
      }
    }
  }, [logbookEntrySetupDetailsValue, isLogbookEntrySetupDetailsValueFetchError]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Logbook entry setup details value updated successfully');
      history.push('/setup/logbook-entry-setup-details-value');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      const errors: any = {};

      if (err?.data) {
        Object.keys(err?.data).forEach((key) => {
          errors[key] = err?.data[key][0];
        });
      }
      setErrors(errors);
    }
  }, [isSuccess, isError]);

  let logbookEntrySetupDetailOptions: SelectOption[] = [    
    ...logbookEntrySetupDetails?.map((l: LogbookEntryDetails) => ({
      label: l.column_name!,
      value: l.id!,
    })),
  ];

  const handleRequiredStatus = (e: any) => {
    setRequiredStatus(e.target.checked);
  };

  const handleShowStatus = (e: any) => {
    setShowStatus(e.target.checked);
  };

  const handleDefaultStatus = (e: any) => {
    setDefaultStatus(e.target.checked);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Edit Logbook Entry Setup Details Value'}
          breadcrumbItem={[{ link: '/setup/logbook-entry-setup-details-value', name: 'Logbook Entry Setup Details Value List' }]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="card mt-3">
            <h5 className="card-header">
              <i className="fa fa-edit me-2" />
              Edit Logbook Entry Setup Details Value
            </h5>
            <div className="card-body">
              <Form
                className="custom-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="row">                  
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="logbook_entry_setup_detail_id">Logbook Entry Setup Detail</Label>
                      <InputField
                        type="select"
                        placeholder="Logbook Entry Setup Detail (Column Name)"
                        options={logbookEntrySetupDetailOptions}
                        invalid={{ errors, touched }}
                        {...getFieldProps('logbook_entry_setup_detail_id')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="value_list">Value List</Label>
                      <InputField
                        type="textarea"
                        placeholder="Write here..."
                        invalid={{ errors, touched }}
                        {...getFieldProps('value_list')}
                      />
                    </FormGroup>
                  </div>                  

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="sl_no">Sl No</Label>
                      <InputField
                        type="number"
                        placeholder="Sl No"
                        invalid={{ errors, touched }}
                        {...getFieldProps('sl_no')}
                      />
                    </FormGroup>
                  </div>                  

                  <div className="col-sm-12">
                    <FormGroup check className="mb-3 mt-4">
                      <Label check>
                        <Input type="checkbox" checked={values.status} {...getFieldProps('status')} />{' '}
                        Status
                      </Label>
                    </FormGroup>
                  </div>
                </div>

                <div className="col-sm-12">
                    <FormGroup check className="mb-3 mt-4">
                        <Label check>
                            <Input
                                name='required_ind'
                                id='required_ind' 
                                type="checkbox" 
                                onChange={(e) => {
                                    handleRequiredStatus(e);
                                    handleChange(e);
                                }}
                                checked={requiredStatus} 
                                />{' '}
                            Required
                        </Label>
                    </FormGroup>
                </div> 

                <div className="col-sm-12">
                    <FormGroup check className="mb-3 mt-4">
                        <Label check>
                            <Input
                                name='is_show'
                                id='is_show' 
                                type="checkbox" 
                                onChange={(e) => {
                                    handleShowStatus(e);
                                    handleChange(e);
                                }}
                                checked={showStatus} 
                                />{' '}
                            Show
                        </Label>
                    </FormGroup>
                </div>

                <div className="col-sm-12">
                    <FormGroup check className="mb-3 mt-4">
                        <Label check>
                            <Input
                                name='default_ind'
                                id='default_ind' 
                                type="checkbox" 
                                onChange={(e) => {
                                    handleDefaultStatus(e);
                                    handleChange(e);
                                }}
                                checked={defaultStatus} 
                                />{' '}
                            Default
                        </Label>
                    </FormGroup>
                  </div> 
                <Button type="submit" text="Submit" />
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
