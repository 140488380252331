import { useEffect, useState } from 'react';
import {
    Col,
    Collapse,
    Row,
    Tooltip,
    UncontrolledTooltip
} from 'reactstrap';
import EditRecursiveCompetencyItem from './EditRecursiveCompetencyItem';

const UpdatedEditCompetencyGroupAndDetails = (props: any) => {
    const { formik, logbookData, competency_group, caseTypeName, skillTypeName, competencyGroup, competenceLevelOptions } =
        props;
    const [isFirstOpen, setIsFirstOpen] = useState(true);
    const [isSecondOpen, setIsSecondOpen] = useState(true);
    const [checkedComIds, setCheckedComIds] = useState<string[]>([]);
    const [competencyGroupParticulars, setCompetencyGroupParticulars] = useState<
        string[]
    >([]);
    const [competencyGroupDetails, setCompetencyGroupDetails] = useState<
        LogbookCompetencyLevels[]
    >([]);
    const [competencyGroupState, setCompetencyGroupState] = useState<any>(competency_group);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchTermCheck, setSearchTermCheck] = useState<boolean>(false);
    const [tooltipState, setTooltipState] = useState<boolean>(false);

    const firstToggle = () => setIsFirstOpen(!isFirstOpen);

    const secondToggle = () => setIsSecondOpen(!isSecondOpen);

    const tooltipToggle = () => setTooltipState(!tooltipState);

    const styleObj: any = {
        tooltipStyling: {
            maxWidth : 'none',                 
        }
    };

    const handleCheckboxChange = (
        e: any,
        competencyGroupId: string,
        competencyGroupName: string,
    ) => {
        const { value, name } = e.target;

        if (e.target.checked) {
            const newCompetencyGroupState: any = [...competencyGroupState];
            newCompetencyGroupState.push({
                id: competencyGroupId,
                name: competencyGroupName,
                level: [],
            });
            setCompetencyGroupState(newCompetencyGroupState);
            const arr = [...checkedComIds];
            arr.push(competencyGroupId);
            setCheckedComIds(arr);
            const arrComParts = [...competencyGroupParticulars];
            arrComParts?.push(competencyGroupName);
            setCompetencyGroupParticulars(arrComParts);
        } else {
            const newCompetencyGroupState: any = [...competencyGroupState];
            newCompetencyGroupState.forEach((competency: any) => {
                if (competency.id === competencyGroupId) {
                    const index = newCompetencyGroupState.indexOf(competency);
                    if (index !== -1) {
                        newCompetencyGroupState.splice(index, 1);
                    }
                }
            });
            setCompetencyGroupState(newCompetencyGroupState);

            const nameIndex =
                competencyGroupParticulars?.indexOf(competencyGroupName);

            if (nameIndex !== -1) {
                const arrComParts = [...competencyGroupParticulars];
                arrComParts?.splice(nameIndex, 1);
                setCompetencyGroupParticulars(arrComParts);
            }

            const checkIndex = checkedComIds?.indexOf(competencyGroupId);
            if (checkIndex !== -1) {
                const arr = [...checkedComIds];
                arr.splice(checkIndex, 1);
                setCheckedComIds(arr);
            }
        }
    };

    useEffect(() => {

        formik.values['competency_group'] = competencyGroupState;

        logbookData?.competency_group?.forEach((competency: any) => {

            // const arr = [...checkedComIds];
            checkedComIds?.push(competency.logbook_entry_setup_master_id);
            // setCheckedComIds(arr);

            // const arrComParts = [...competencyGroupParticulars];
            competencyGroupParticulars?.push(competency.skill_name.trim());
            // setCompetencyGroupParticulars(arrComParts);

            // const arrComDetails = [...competencyGroupDetails];
            competencyGroupDetails?.push(competency);
            // setCompetencyGroupDetails(arrComDetails);
        });
    }, []);

    // useEffect(() => {
    //   if(competencyGroupDetails?.length && competencyGroupParticulars?.length && checkedComIds?.length) {
    //     firstToggle();
    //     secondToggle();
    //   }
    // }, [competencyGroupDetails, competencyGroupParticulars, checkedComIds]);

    useEffect(() => {
        formik.values['competency_group'] = competencyGroupState;
    }, [competencyGroupState]);

    useEffect(() => {
        if (searchTerm) {
            setSearchTermCheck(true);
        }
    }, [searchTerm]);

    return (
        <>
            <Row className='justify-content-between'>
                <Col sm={4}>
                    <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search Competency Group List..."
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                }}
                            />
                            <button className="btn btn-primary" type="button">
                                <i className="bx bx-search-alt align-middle"></i>
                            </button>
                        </div>
                    </form>
                </Col>
                <Col sm={1} className='mt-2'>
                    <span id='infoTooltip'>
                        <i className="fa-solid fa-2x fa-circle-info"></i>
                    </span>
                    <Tooltip
                        placement={'right'}
                        isOpen={tooltipState}
                        target={'infoTooltip'}
                        toggle={tooltipToggle}  
                        container="#logbookEditAndViewModalId"                                                                     
                        style={styleObj.tooltipStyling}
                    >
                        <ul className='list-unstyled'>
                            <li>
                                <h5 className='text-light'>Patient care competence</h5>
                                <ul className='list-unstyled my-3'>
                                    {
                                        competenceLevelOptions?.[0]?.childs?.map((option: any, index: number) => {
                                            return <li key={option.id}><strong>{option.name}</strong> ({option.childs[0]?.name})</li>
                                        })
                                    }
                                </ul>
                            </li>
                            <li>
                                <h5 className='text-light'>Procedural/Surgical skill</h5>

                                <ul className='list-unstyled my-3'>
                                    {
                                        competenceLevelOptions?.[1]?.childs?.map((option: any, index: number) => {
                                            return <li key={option.id}><strong>{option.name}</strong> ({option.childs[0]?.name})</li>
                                        })
                                    }
                                </ul>
                            </li>
                        </ul>
                    </Tooltip>
                </Col>
            </Row>
            <Row className="p-3">
                <Col>
                    <div className='collapse-wrapper'>
                        <div className='collapse-title' onClick={firstToggle}>
                            {isFirstOpen ? (
                                <i className="fa fa-minus me-2" />
                            ) : (
                                <i className="fa fa-plus me-2" />
                            )}
                            {caseTypeName}
                        </div>{' '}
                        <Collapse isOpen={isFirstOpen}>
                            <div className='collapse-title' onClick={secondToggle}>
                                {isSecondOpen ? (
                                    <i className="fa fa-minus me-2" />
                                ) : (
                                    <i className="fa fa-plus me-2" />
                                )}
                                {skillTypeName}
                            </div>{' '}
                            <Collapse isOpen={isSecondOpen}>
                                {competencyGroupDetails?.length &&
                                    competencyGroupParticulars?.length &&
                                    checkedComIds?.length ?
                                    (
                                        <EditRecursiveCompetencyItem
                                            competencyGroup={competencyGroup}
                                            searchTerm={searchTerm}
                                            competencyGroupDetails={competencyGroupDetails}
                                            competencyGroupParticulars={competencyGroupParticulars}
                                            handleCheckboxChange={handleCheckboxChange}
                                            checkedComIds={checkedComIds}
                                            competencyGroupState={competencyGroupState}
                                            setCompetencyGroupState={setCompetencyGroupState}
                                            skillTypeName={skillTypeName}
                                            formik={formik}
                                            searchTermCheck={searchTermCheck}
                                            competenceLevelOptions={competenceLevelOptions}
                                        />
                                    )
                                    :
                                    (
                                        <EditRecursiveCompetencyItem
                                            competencyGroup={competencyGroup}
                                            searchTerm={searchTerm}
                                            competencyGroupDetails={competencyGroupDetails}
                                            competencyGroupParticulars={competencyGroupParticulars}
                                            handleCheckboxChange={handleCheckboxChange}
                                            checkedComIds={checkedComIds}
                                            competencyGroupState={competencyGroupState}
                                            setCompetencyGroupState={setCompetencyGroupState}
                                            skillTypeName={skillTypeName}
                                            formik={formik}
                                            searchTermCheck={searchTermCheck}
                                            competenceLevelOptions={competenceLevelOptions}
                                        />
                                    )}
                            </Collapse>
                        </Collapse>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default UpdatedEditCompetencyGroupAndDetails;
