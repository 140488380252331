import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Col, Form, Row } from 'reactstrap';
import avatar from 'src/assets/images/users/default-avatar.png';
import { InputField } from 'src/components';
import { config } from 'src/config';
import { setFromErrors } from 'src/helpers';
import * as url from 'src/helpers/url_helper';
import { useCreateTrainingFileUploadMutation } from 'src/rtk/features/myTraining/myTrainingApi';
import * as Yup from 'yup';

const FILE_SIZE = 1024 * 1024;

const ProfileView = (props: any) => {

  const { userInfo, attachments, trainingId } = props;
  const fileInputRef = useRef<any>();
  console.log(trainingId);
  const [attachmentFiles, setAttachmentFiles] = useState<any>(attachments);

  // const attachmentFiles: any = [...attachments];

  const [createTrainingFileUpload, { data: uploadedData, isSuccess, isError, error, isLoading }] =
    useCreateTrainingFileUploadMutation();

  const err: CustomError = error as CustomError;

  const initialValues: any = {
    workshop_training_file: '',
    file_type: '',
    workshop_training_skill_master_id: '' || '',
    registration_id: '',
  };

  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    resetForm,
    setErrors,
    handleChange,
    values,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues,

    validationSchema: Yup.object({
      workshop_training_file: Yup.mixed()
        .required('File is required')
        .test('is-pdf', 'File must be a Doc, PDF, PNG, JPG', (value) => {
          if (value) {
            return [
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'application/pdf',
              'image/jpeg',
              'image/png',
              'image/jpg',
            ].includes(value.type);
          }
          return true;
        })
        .test('file-size', 'File too large', (value) => {
          if (value) {
            return value.size <= FILE_SIZE;
          }
          return true;
        }),
    }),

    onSubmit: (values) => {

      values.workshop_training_skill_master_id = trainingId;
      values.registration_id = userInfo['registration_no'];

      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      let postData = {
        queryUrl: url.RTMD_WORKSHOP_TRAINEE_ATTENDANCE_TRAINING_FILE_UPLOAD,
        formData: formData,
      };
      createTrainingFileUpload(postData);
    },
  });

  useEffect(() => {
    if (isSuccess && !isLoading) {
      toast.success(uploadedData?.message || "File upload successfully.");

      if (uploadedData?.data) {
        let attFiles: any = [...attachmentFiles];
        let newFileData = uploadedData?.data || {};
        attFiles.push(newFileData)
        setAttachmentFiles(attFiles);
      }

      resetForm()
      fileInputRef.current.value = '';
    }

    else if (isError) {
      toast.error(err?.message || 'Something Went Wrong!');
      setFromErrors(err.data, setErrors);
    }

  }, [isSuccess, isLoading, isError]);

  return (
    <>
      {userInfo && (
        <div>
          <h6 className="block-heading"> Personal Information </h6>
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className='col-md-12 col-sm-12'>
                  <table className="table table-bordered table-striped table-sm">
                    <tbody>
                      <tr>
                        <td>
                          <span className="fw-semibold">Name: </span>
                          <span>{userInfo?.personal_info?.full_name}</span>
                        </td>
                        <td>
                          {userInfo?.personal_info?.fathers_name && (
                            <div>
                              <span className="fw-semibold">Father's Name: </span>
                              <span>
                                {userInfo?.personal_info?.fathers_name ?? 'N/A'}
                              </span>
                            </div>
                          )}
                          {userInfo?.personal_info?.spouse_name && (
                            <div>
                              <span className="fw-semibold">Spouse Name: </span>
                              <span>{userInfo?.personal_info?.spouse_name ?? 'N/A'}</span>
                            </div>)}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="fw-semibold">Mother's Name: </span>
                          <span>{userInfo?.personal_info?.mothers_name ?? 'N/A'}</span>
                        </td>
                        <td>
                          <span className="fw-semibold">Date of Birth: </span>
                          <span>{userInfo?.personal_info?.date_of_birth ?? 'N/A'}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="fw-semibold">NID: </span>
                          <span>{userInfo?.personal_info?.national_id ?? 'N/A'}</span>
                        </td>
                        <td>
                          <span className="fw-semibold">Phone: </span>
                          <span>{userInfo?.personal_info?.mobile ?? 'N/A'}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="fw-semibold">Email: </span>
                          <span>{userInfo?.personal_info?.email ?? 'N/A'}</span>
                        </td>
                        <td>
                          <span className="fw-semibold">Alternative Mobile Number: </span>
                          <span>{userInfo?.personal_info?.telephone ?? 'N/A'}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
              <div className="img-fluid img-rounded text-center">
                <img
                  src={
                    userInfo?.personal_info?.picture
                      ? `${config?.APP_URL}${userInfo?.personal_info?.picture}`
                      : avatar
                  }
                  width="120"
                  className="img-thumbnail"
                />
              </div>
            </div>
          </div>


          {/* Pre-Test and Post Test Information */}
          <h6 className="block-heading">
            Pre-Test, Post Test and Training Feedback Information
          </h6>

          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <Row>
              <Col sm={12} md={12} lg={12} className='my-2'>
                {/* <Label className="form-label required-field">File Type</Label> */}
                <div className="input-group">
                  <input
                    ref={fileInputRef}
                    name="workshop_training_file"
                    type="file"
                    className="form-control"
                    placeholder="Upload Document"
                    // onChange={handleChange}
                    onChange={(e: any) => {
                      setFieldValue('workshop_training_file', e.target.files[0]);
                    }}
                  ></input>

                  <InputField
                    name="file_type"
                    type="select"
                    onChange={handleChange}
                    value={values.file_type}
                    required
                    options={[
                      { value: 'pre-test', label: 'Pre-Test' },
                      { value: 'post-test', label: 'Post-Test' },
                      { value: 'training-feedback', label: 'Training Feedback' },
                    ]}
                    invalid={{ errors, touched }}
                  />

                  <button
                    className="btn btn-primary btn-block d-flex  align-items-center"
                    type="submit"
                    disabled={isLoading}
                  >
                    {!isLoading && <span>Upload Document</span>}

                    {isLoading && (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="ms-2">Uploading...</span>
                      </>
                    )}
                  </button>


                </div>

                {errors.workshop_training_file && touched.workshop_training_file ? (
                  <div className="text-danger">{errors.workshop_training_file as string}</div>
                ) : null}

              </Col>
            </Row>
          </Form>



          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 me-2 mt-3">

            {(attachmentFiles && attachmentFiles.length > 0) &&
              attachmentFiles.map((fileData: any, key: number) => {
                //attachmentsFiles[fileData.file_type] = fileData;

                return (
                  <div key={fileData.id} className='col  '>
                    <a
                      href={config.APP_URL + fileData?.url}
                      rel="noreferrer"
                      target="_blank"
                      className="btn btn-outline-success btn-sm m-2 w-100 text-start"
                    >
                      {key + 1}. Download {fileData?.file_type}
                    </a>
                  </div>
                )
              })}
          </Row>
        </div>
      )}
    </>
  );
};

export default ProfileView;
