import { useFormik } from 'formik';
import React from 'react';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import PaginationComponent from 'src/components/Pagination/Pagination';

const InstituteList = () => {
  const instituteForm = useFormik({
    initialValues: {
      institute: '',
      fromDate: Date.now().toString(),
      toDate: Date.now().toString(),
      searchTitle: '',
    },

    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <h6>Institute List Page</h6>

        <Col sm={12} md={12} lg={12}>
          <Form
            onSubmit={instituteForm.handleSubmit}
            encType="multipart/form-data"
          >
            <Row className="d-flex">
              <Col className="border-right p-2 mr-2" sm={3} md={3} lg={3}>
                <FormGroup className="mb-5">
                  <div className="mb-3">
                    <Label className="form-label">Institute</Label>
                    <Input
                      name="institute"
                      className="form-control"
                      placeholder="Select"
                      type="select"
                      onChange={instituteForm.handleChange}
                      onBlur={instituteForm.handleBlur}
                      value={instituteForm.values.institute || ''}
                      invalid={
                        instituteForm.touched.institute &&
                        !!instituteForm.errors.institute
                      }
                    >
                      <option value="0">Select</option>
                    </Input>
                    {instituteForm.touched.institute &&
                    instituteForm.errors.institute ? (
                      <FormFeedback type="invalid">
                        {instituteForm.errors.institute}
                      </FormFeedback>
                    ) : null}
                  </div>
                </FormGroup>
              </Col>

              <Col className="border-right p-2 mr-2" sm={3} md={3} lg={3}>
                <FormGroup className="mb-5">
                  <div className="mb-3">
                    <Label className="form-label">From</Label>
                    <Input
                      name="fromDate"
                      className="form-control"
                      placeholder=""
                      type="date"
                      onChange={instituteForm.handleChange}
                      onBlur={instituteForm.handleBlur}
                      value={instituteForm.values.fromDate}
                    />
                  </div>
                </FormGroup>
              </Col>

              <Col className="border-right p-2 mr-2" sm={3} md={3} lg={3}>
                <FormGroup className="mb-5">
                  <div className="mb-3">
                    <Label className="form-label">To</Label>
                    <Input
                      name="toDate"
                      className="form-control"
                      placeholder=""
                      type="date"
                      onChange={instituteForm.handleChange}
                      onBlur={instituteForm.handleBlur}
                      value={instituteForm.values.toDate}
                    />
                  </div>
                </FormGroup>
              </Col>

              <Col className="border-right p-2 mr-2" sm={3} md={3} lg={3}>
                <FormGroup className="mb-5">
                  <div className="mb-3">
                    <Label className="form-label">Search</Label>
                    <Input
                      name="searchTitle"
                      className="form-control"
                      placeholder=""
                      type="search"
                      onChange={instituteForm.handleChange}
                      onBlur={instituteForm.handleBlur}
                      value={instituteForm.values.searchTitle}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>

        <Col sm={12} md={12} lg={12}>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <Table bordered>
                <thead>
                  <tr>
                    <th>SL</th>
                    <th>Date</th>
                    <th>Institute</th>
                    <th>Status of the institute</th>
                    <th>Recognition of BMDC</th>
                    <th>License by DGHS</th>
                    <th>License Number</th>
                    <th>Decision</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>1</td>
                    <td>12/12/22</td>
                    <td>Active</td>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>DG123456</td>
                    <td>
                      <ul className="list-unstyled">
                        <li>
                          <a href="#">Approve</a>
                        </li>
                        <li>
                          <a href="#">Reject</a>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <Button className="me-4">
                        <i className="fa-solid fa-eye"></i>
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">1</th>
                    <td>1</td>
                    <td>12/12/22</td>
                    <td>Active</td>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>DG123456</td>
                    <td>
                      <ul className="list-unstyled">
                        <li>
                          <a href="#">Approve</a>
                        </li>
                        <li>
                          <a href="#">Reject</a>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <Button className="me-4">
                        <i className="fa-solid fa-eye"></i>
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">1</th>
                    <td>2</td>
                    <td>12/12/22</td>
                    <td>Active</td>
                    <td>Yes</td>
                    <td>No</td>
                    <td>N/A</td>
                    <td>
                      <ul className="list-unstyled">
                        <li>
                          <a href="#">Approve</a>
                        </li>
                        <li>
                          <a href="#">Reject</a>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <Button className="me-4">
                        <i className="fa-solid fa-eye"></i>
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">1</th>
                    <td>3</td>
                    <td>03/07/22</td>
                    <td>Inactive</td>
                    <td>No</td>
                    <td>Yes</td>
                    <td>DG123456</td>
                    <td>
                      <ul className="list-unstyled">
                        <li>
                          <a href="#">Approve</a>
                        </li>
                        <li>
                          <a href="#">Reject</a>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <Button className="me-4">
                        <i className="fa-solid fa-eye"></i>
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>

        <PaginationComponent />
      </div>
    </React.Fragment>
  );
};

export default InstituteList;
