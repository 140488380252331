import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import * as url from 'src/helpers/url_helper';
import { useGetDataQuery } from 'src/rtk/features/common/crudApi';
import { useDeleteExamTrainingFeeMutation } from 'src/rtk/features/setup/examTrainingFee/examTrainingFeeSetupApi';

interface Props {
  examTrainingFee: ExamTrainingFee;
  index: number;
}

const ExamTrainingFeeTableRow = ({ examTrainingFee, index }: Props) => {
  const history = useHistory();

  const [infoId, setInfoId] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const [deleteExamTrainingFee, { isSuccess, isLoading, isError }] =
    useDeleteExamTrainingFeeMutation();
  // const { data, error, isLoading: isExamInfoLoading, isError: isExamInfoError } = useGetExamInfosQuery();
  // const examInfos = data?.data || [];
  const generateQueryUrl = () => {
    return `${url.SETUP_EXAM_TRAINING_INFO_LIST}`;
  };

  const {
    data: examInfoData,
    isLoading: dataListLoading,
    isSuccess: dataListLoadingSuccess,
    error: dataListLoadingError,
  } = useGetDataQuery(generateQueryUrl());
  // const data: any = examInfoData?.data || [];
  const examInfos = examInfoData?.data?.data || [];

  const deleteHandler = (modalState: boolean, id: string) => {
    setModalShow(modalState);
    setInfoId(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Exam training fee deleted successfully');
    }
    if (isError) {
      toast.error('Error deleting exam training fee');
    }
  }, [isSuccess, isError]);

  return (
    <tr key={examTrainingFee.id}>
      <th scope="row">{index + 1}</th>
      <td>
        {
          examInfos?.find(
            (e: ExamInfo) => e.id === examTrainingFee.exam_training_id,
          )?.name
        }
      </td>
      <td>{examTrainingFee.exam_fee_new}</td>
      <td>{examTrainingFee.exam_fee_new_in_words}</td>
      <td>{examTrainingFee.exam_fee}</td>
      <td>{examTrainingFee.exam_fee_old_in_words}</td>
      <td>{examTrainingFee.status ? 'Active' : 'Inactive'}</td>
      <td>
        <div className="d-flex gap-2">
          <Button
            size="sm"
            text="Edit"
            color="warning"
            onClick={() =>
              history.push(
                `/setup/exam-training-fee/update/${examTrainingFee.id}`,
              )
            }
          />

          <Button
            size="sm"
            text="Delete"
            color="danger"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => deleteHandler(true, examTrainingFee.id!)}
          />
        </div>
        {modalShow ? (
          <DeleteInformationModal
            isOpen={modalShow}
            toggle={() => setModalShow(!modalShow)}
            id={infoId}
            deleteInfomation={deleteExamTrainingFee}
          />
        ) : null}
      </td>
    </tr>
  );
};

export default ExamTrainingFeeTableRow;
