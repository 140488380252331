import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { Button as BsButton, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Modal, ModalBody, Row } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import { useDeleteIpdOpdRecordMutation, useGetDepartmentByIdQuery, useGetIpdRecordListQuery, useGetOpdRecordListQuery } from 'src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi';
import InPatientRecordsOfTheDeptModal from './InPatientRecordsOfTheDeptModal';
import OutPatientRecordsOfTheDeptModal from './OutPatientRecordsOfTheDeptModal';

const InstituteDeptRegistrationStepTwo = (props: any) => {
    const { goToNext, goToPrevious, instituteName, departmentId, formBDeptId, isDisabled, renewDepartment } = props;
    const [showInPatientRecordModal, setShowInPatientRecordModal] = useState<boolean>(false);
    const [showOutPatientRecordModal, setShowOutPatientRecordModal] = useState<boolean>(false);
    const [ipdRecordInModal, setIpdRecordInModal] = useState<IpdOpdRecordList>({} as IpdOpdRecordList);
    const [showIpdRecordViewModal, setShowIpdRecordViewModal] = useState<boolean>(false);
    const [opdRecordInModal, setOpdRecordInModal] = useState<IpdOpdRecordList>({} as IpdOpdRecordList);
    const [showOpdRecordViewModal, setShowOpdRecordViewModal] = useState<boolean>(false);
    const [ipdOpdEntryForDelete, setIpdOpdEntryForDelete] = useState<IpdOpdRecordList>({} as IpdOpdRecordList);
    const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);
    const [deleteEntry, setDeleteEntry] = useState<boolean>(false);
    const [editIpdRecord, setEditIpdRecord] = useState<boolean>(false);
    const [ipdRecordToEdit, setIpdRecordToEdit] = useState<IpdOpdRecordList>({} as IpdOpdRecordList);
    const [editOpdRecord, setEditOpdRecord] = useState<boolean>(false);
    const [opdRecordToEdit, setOpdRecordToEdit] = useState<IpdOpdRecordList>({} as IpdOpdRecordList);

    const { data: departmentData, isSuccess: isDepartmentDataSuccess, isError: isDepartmentDataError, error: departmentDataError } = useGetDepartmentByIdQuery(formBDeptId);

    const { data: ipdRecordList, isSuccess: isIpdRecordListSuccess, isError: isIpdRecordListError, error: ipdRecordListError } = useGetIpdRecordListQuery(departmentId);

    const { data: opdRecordList, isSuccess: isOpdRecordListSuccess, isError: isOpdRecordListError, error: opdRecordListError } = useGetOpdRecordListQuery(departmentId);

    const [
        deleteIpdOpdEntry,
        {
            data: ipdOpdEntryDelete,
            isSuccess: isIpdOpdEntryDeleteSuccess,
            isError: isIpdOpdEntryDeleteError,
            error: ipdOpdEntryDeleteError,
        },
    ] = useDeleteIpdOpdRecordMutation();

    document.title = renewDepartment === true ? 'Renew Department | BCPS' : 'Department Registration | BCPS';

    const handleEditIpdRecordEntry = (e: any, ipdRecord: IpdOpdRecordList) => {
        setEditIpdRecord(true);
        setIpdRecordToEdit(ipdRecord);
        setShowInPatientRecordModal(true);
    };

    const handleEditOpdRecordEntry = (e: any, opdRecord: IpdOpdRecordList) => {
        setEditOpdRecord(true);
        setOpdRecordToEdit(opdRecord);
        setShowOutPatientRecordModal(true);
    };

    const handleInPatientRecordView = (e: any, ipdRecord: IpdOpdRecordList) => {
        setIpdRecordInModal(ipdRecord);
        setShowIpdRecordViewModal(true);
    };

    const handleOutPatientRecordView = (e: any, opdRecord: IpdOpdRecordList) => {
        setOpdRecordInModal(opdRecord);
        setShowOpdRecordViewModal(true);
    };

    const deleteHandler = (e: any, ipdOpdRecord: IpdOpdRecordList) => {
        setIpdOpdEntryForDelete(ipdOpdRecord);
        setDeleteModalShow(true);
    };

    const inPatientRecordsOfTheDeptColumns: TableColumn<IpdOpdRecordList>[] = [

        {
            name: <div className="text-center">Period</div>,
            selector: (row) => `${moment(row.ipd_period_from).format('DD/MMM/YYYY')} to ${moment(row.ipd_period_to).format('DD/MMM/YYYY')}`,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center"># Non-Paying Beds</div>,
            selector: (row) => row.ipd_number_of_nonpaying_beds!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center"># Paying Beds</div>,
            selector: (row) => row.ipd_number_of_paying_beds!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center"># Cabins</div>,
            selector: (row) => row.ipd_number_of_cabins!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center"># Patients Admitted in Non-Paying Beds</div>,
            selector: (row) => row.ipd_number_of_admitted_patient_nonpaying_beds!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center"># Patients Admitted in Paying Beds</div>,
            selector: (row) => row.ipd_number_of_admitted_patient_paying_beds!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center"># Patients Admitted in Cabins</div>,
            selector: (row) => row.ipd_number_of_admitted_patient_cabins!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Action</div>,
            width: '200px',
            cell: (row) => (
                <>
                    <FormGroup className="btn-group mt-2">
                        <button
                            className="btn btn-sm btn-secondary"
                            onClick={(e) => {
                                handleEditIpdRecordEntry(e, row);
                            }}
                        >
                            <span className="fas fa-edit"></span> Edit
                        </button>
                        <button
                            className="btn btn-sm btn-info"
                            style={{ pointerEvents: 'auto' }}
                            onClick={(e) => {
                                handleInPatientRecordView(e, row);
                            }}
                        >
                            <span className="fas fa-eye"></span> View
                        </button>
                        {
                            <Button
                                size="sm"
                                color="danger"
                                type="button"
                                text={`<span class="fas fa-trash" ></span> Delete`}
                                onClick={(e) => {
                                    deleteHandler(e, row);
                                }}
                            />
                        }
                    </FormGroup>
                </>
            ),

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const outPatientRecordsOfTheDeptColumns: TableColumn<IpdOpdRecordList>[] = [

        {
            name: <div className="text-center">Period</div>,
            selector: (row) => `${moment(row.opd_period_from).format('DD/MMM/YYYY')} to ${moment(row.opd_period_to).format('DD/MMM/YYYY')}`,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">OPD Work Hour (Per Week)</div>,
            selector: (row) => row.opd_duration_of_care!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center"># Patients Attending the OPD (Per Day)</div>,
            selector: (row) => row.opd_number_of_patients_attending_per_day!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center"># Minor OT done in OPD Per Week (for Surgery)</div>,
            selector: (row) => row.opd_minor_operation_done_perday!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Daily OPD Attendance (Avg.)</div>,
            selector: (row) => row.daily_opd_attendance!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Action</div>,
            width: '200px',
            cell: (row) => (
                <>
                    <FormGroup className="btn-group mt-2">
                        <button
                            className="btn btn-sm btn-secondary"
                            onClick={(e) => {
                                handleEditOpdRecordEntry(e, row);
                            }}
                        >
                            <span className="fas fa-edit"></span> Edit
                        </button>
                        <button
                            className="btn btn-sm btn-info"
                            style={{ pointerEvents: 'auto' }}
                            onClick={(e) => {
                                handleOutPatientRecordView(e, row);
                            }}
                        >
                            <span className="fas fa-eye"></span> View
                        </button>
                        {
                            <Button
                                size="sm"
                                color="danger"
                                type="button"
                                text={`<span class="fas fa-trash" ></span> Delete`}
                                onClick={(e) => {
                                    deleteHandler(e, row);
                                }}
                            />
                        }
                    </FormGroup>
                </>
            ),

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (deleteEntry) {
            deleteIpdOpdEntry(ipdOpdEntryForDelete?.id!);
        }
    }, [deleteEntry]);

    useEffect(() => {
        if (!isIpdOpdEntryDeleteError && isIpdOpdEntryDeleteSuccess) {
            toast.dismiss();
            toast.success('IPD/OPD Entry Deleted Successfully');
            setDeleteModalShow(false);
            setDeleteEntry(false);
        } else if (isIpdOpdEntryDeleteError && !isIpdOpdEntryDeleteSuccess) {
            toast.dismiss();
            toast.error('Error while deleting the IPD/OPD entry');
        }
    }, [isIpdOpdEntryDeleteError, isIpdOpdEntryDeleteSuccess]);

    return (
        <div className="page-content">
            <Container fluid>
                <BetterBreadcrumb
                    title="Training Accreditation Application Form"
                    breadcrumbItem={[{ link: '/institute-registration-list', name: 'Department List' }]}
                />
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title mb-0 flex-grow-1">
                                    <span>Part-B : Information about Relevant Department (Continued)</span>
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    autoComplete="off"
                                    className="custom-form"
                                    style={isDisabled === true ? { pointerEvents: 'none' } : {}}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        // handleSubmit();
                                    }}
                                    onReset={(e) => {
                                        e.preventDefault();
                                        // handleFormReset();
                                    }}
                                >
                                    <Row>
                                        <Col sm={12}>
                                            <Row className='justify-content-evenly'>
                                                <Col sm={12} md={6}>
                                                    <FormGroup className="col-sm-12 mb-3">
                                                        <Label for="institute_name">Institute Name</Label>
                                                        <InputField
                                                            id="institute_name"
                                                            name="institute_name"
                                                            type="text"
                                                            value={instituteName}
                                                            placeholder=""
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                {isDepartmentDataSuccess && departmentData?.data &&
                                                    <Col sm={12} md={6}>
                                                        <FormGroup className="col-sm-12 mb-3">
                                                            <Label for="department_name">Name of the Department</Label>
                                                            <InputField
                                                                id="department_name"
                                                                name="department_name"
                                                                type="text"
                                                                value={departmentData?.data?.department_name!}
                                                                placeholder=""
                                                                disabled
                                                            />
                                                        </FormGroup>
                                                    </Col>}
                                            </Row>
                                        </Col>

                                        <Col sm={12}>
                                            <fieldset className="border p-2">
                                                <legend className='w-auto' style={{ float: 'none', padding: 'inherit' }}>
                                                    <span className='px-4 bg-success rounded-pill'><i className="fa-solid fa-4" style={{ color: '#f8f9fc' }}></i></span> Records
                                                </legend>

                                                <div className='filter-wrapper'>
                                                    <h5 className='font-size-14 mb-3 ms-3'><div className="badge rounded-pill d-inline-block  badge-draft font-size-16 fw-medium">4.1</div> In-patient records of the department (last one year) & disease profile of in-patient in the department (last one year)</h5>
                                                    <FormGroup className="ms-auto justify-content-end">
                                                        <BsButton
                                                            type="button"
                                                            className="btn btn-success me-2"
                                                            onClick={(e) => {
                                                                setEditIpdRecord(false);
                                                                setIpdRecordToEdit({} as IpdOpdRecordList);
                                                                setShowInPatientRecordModal(true);
                                                            }}
                                                        >
                                                            <i className="fa fa-plus me-2" /> Add Record
                                                        </BsButton>
                                                    </FormGroup>
                                                </div>
                                                <DataTable
                                                    columns={inPatientRecordsOfTheDeptColumns}
                                                    data={ipdRecordList?.data?.length! > 0 ? ipdRecordList?.data! : []}
                                                    persistTableHead={true}
                                                    noDataComponent={<div className='border border-2 border-light border-top-0 p-5 text-center w-100'>There are no records to display</div>}
                                                    expandOnRowClicked
                                                    highlightOnHover
                                                />

                                                <div className='filter-wrapper mt-5'>
                                                    <h5 className='font-size-14 mb-3 ms-3'><div className="badge rounded-pill d-inline-block badge-draft font-size-16 fw-medium">4.2</div> Out-patient records of the department & disease profile of patients of OPD in last year</h5>
                                                    <FormGroup className="ms-auto justify-content-end">
                                                        <BsButton
                                                            type="button"
                                                            className="btn btn-success me-2"
                                                            onClick={(e) => {
                                                                setEditOpdRecord(false);
                                                                setOpdRecordToEdit({} as IpdOpdRecordList);
                                                                setShowOutPatientRecordModal(true);
                                                            }}
                                                        >
                                                            <i className="fa fa-plus me-2" /> Add Record
                                                        </BsButton>
                                                    </FormGroup>
                                                </div>
                                                <DataTable
                                                    columns={outPatientRecordsOfTheDeptColumns}
                                                    data={opdRecordList?.data?.length! > 0 ? opdRecordList?.data! : []}
                                                    persistTableHead={true}
                                                    noDataComponent={<div className='border border-2 border-light border-top-0 p-5 text-center w-100'>There are no records to display</div>}
                                                    expandOnRowClicked
                                                    highlightOnHover
                                                />
                                            </fieldset>
                                        </Col>
                                    </Row>

                                    <FormGroup row className='justify-content-between mt-5'>
                                        <Col sm={9}>
                                            <BsButton color="warning" style={ isDisabled === true ? { pointerEvents: 'auto' } : {} } className='me-3 text-start' onClick={() => {
                                                goToPrevious();
                                            }}>
                                                {'Previous'}
                                            </BsButton>
                                        </Col>

                                        <Col sm={3}>
                                            {isDisabled !== true ?
                                                <div className='d-flex justify-content-end'>
                                                    <BsButton color="success" onClick={() => {
                                                        goToNext();
                                                    }}>
                                                        {'Save & Next'}
                                                    </BsButton>
                                                </div>
                                                :
                                                <div className='d-flex justify-content-end'>
                                                    <BsButton color="success" style={{ pointerEvents: 'auto' }} onClick={() => {
                                                        goToNext();
                                                    }}>
                                                        {'Next'}
                                                    </BsButton>
                                                </div>}
                                        </Col>
                                    </FormGroup>
                                </Form>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showInPatientRecordModal}
                                    scrollable={true}
                                    size="xl"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            In-patient record of the department
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowInPatientRecordModal(!showInPatientRecordModal);
                                                setEditIpdRecord(false);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <Row>
                                            <Col>
                                                {!editIpdRecord && !ipdRecordToEdit?.id ?
                                                    <InPatientRecordsOfTheDeptModal departmentId={departmentId} setModalShow={setShowInPatientRecordModal} />
                                                    :
                                                    <InPatientRecordsOfTheDeptModal departmentId={departmentId} ipdRecordToEdit={ipdRecordToEdit} setModalShow={setShowInPatientRecordModal} />
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showOutPatientRecordModal}
                                    scrollable={true}
                                    size="xl"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Out-patient record of the department
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowOutPatientRecordModal(!showOutPatientRecordModal);
                                                setEditOpdRecord(false);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <Row>
                                            <Col>
                                                {!editOpdRecord && !opdRecordToEdit?.id ?
                                                    <OutPatientRecordsOfTheDeptModal departmentId={departmentId} setModalShow={setShowOutPatientRecordModal} />
                                                    :
                                                    <OutPatientRecordsOfTheDeptModal departmentId={departmentId} opdRecordToEdit={opdRecordToEdit} setModalShow={setShowOutPatientRecordModal} />
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showIpdRecordViewModal}
                                    scrollable={true}
                                    size="md"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className='modal-header'>
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            IPD Record
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowIpdRecordViewModal(!showIpdRecordViewModal);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <ModalBody>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table table-bordered table-striped table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>Period: </strong>
                                                                {moment(ipdRecordInModal?.ipd_period_from).format('DD/MMM/YYYY')} to {moment(ipdRecordInModal?.ipd_period_to).format('DD/MMM/YYYY')}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>No. of Non-Paying Beds: </strong>
                                                                {ipdRecordInModal?.ipd_number_of_nonpaying_beds ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>No. of Paying Beds: </strong>
                                                                {ipdRecordInModal?.ipd_number_of_paying_beds ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>No. of Cabins: </strong>
                                                                {ipdRecordInModal?.ipd_number_of_cabins ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>No. of Patients Admitted in Non-Paying Beds: </strong>
                                                                {ipdRecordInModal?.ipd_number_of_admitted_patient_nonpaying_beds ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>No. of Patients Admitted in Paying Beds: </strong>
                                                                {ipdRecordInModal?.ipd_number_of_admitted_patient_paying_beds ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>No. of Patients Admitted in Cabins: </strong>
                                                                {ipdRecordInModal?.ipd_number_of_admitted_patient_cabins ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Disease Profile of In-Patients in the Department: </strong>
                                                                {ipdRecordInModal?.ipd_disease_profile ? (
                                                                    <a
                                                                        className="ms-2 me-2 px-2 btn btn-outline-info"
                                                                        href={config.APP_URL + ipdRecordInModal?.ipd_disease_profile}
                                                                        rel="noreferrer"
                                                                        target="_blank"
                                                                    >
                                                                        View
                                                                    </a>
                                                                ) : 'N/A'}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showOpdRecordViewModal}
                                    scrollable={true}
                                    size="md"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className='modal-header'>
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            OPD Record
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowOpdRecordViewModal(!showOpdRecordViewModal);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <ModalBody>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table table-bordered table-striped table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>Period: </strong>
                                                                {moment(opdRecordInModal?.opd_period_from).format('DD/MMM/YYYY')} to {moment(opdRecordInModal?.opd_period_to).format('DD/MMM/YYYY')}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>OPD Work Hour (Per Week): </strong>
                                                                {opdRecordInModal?.opd_duration_of_care ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>No. of Patients Attending the OPD (Per Day): </strong>
                                                                {opdRecordInModal?.opd_number_of_patients_attending_per_day ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>No. of Minor OT done in OPD Per Week (for Surgery): </strong>
                                                                {opdRecordInModal?.opd_minor_operation_done_perday ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Daily OPD Attendance (Avg.): </strong>
                                                                {opdRecordInModal?.daily_opd_attendance ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Disease Profile of Patients of OPD in last year: </strong>
                                                                {opdRecordInModal?.opd_disease_profile ? (
                                                                    <a
                                                                        className="ms-2 me-2 px-2 btn btn-outline-info"
                                                                        href={config.APP_URL + opdRecordInModal?.opd_disease_profile}
                                                                        rel="noreferrer"
                                                                        target="_blank"
                                                                    >
                                                                        View
                                                                    </a>
                                                                ) : 'N/A'}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={deleteModalShow}
                                    scrollable={true}
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Delete IPD/OPD Entry
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setDeleteModalShow(!deleteModalShow);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        {ipdOpdEntryForDelete && (
                                            <Row>
                                                <Col>
                                                    <p>
                                                        Are you sure you want to delete the {ipdOpdEntryForDelete?.record_type?.toUpperCase()} entry? {' '}
                                                    </p>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setDeleteModalShow(false);
                                            }}
                                            className="btn btn-secondary me-2"
                                            data-dismiss="modal"
                                        >
                                            No
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={(e) => {
                                                setDeleteEntry(true);
                                            }}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </Modal>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container >
        </div >
    );
};

export default InstituteDeptRegistrationStepTwo;