import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container, Table } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetRoomsQuery } from 'src/rtk/features/setup/room/roomApi';
import RoomTableRow from './RoomTableRow';

export const Rooms = () => {
  const { data, error, isLoading, isError } = useGetRoomsQuery();

  const err: CustomError = error as CustomError;
  const rooms = data?.data || [];

  let content = null;

  if (isLoading) {
    content = <div className="mt-3">Loading...</div>;
  }
  if (!isLoading && isError && error) {
    content = <div className="mt-3">{err.message}</div>;
  }
  if (!isLoading && !isError && rooms?.length > 0) {
    content = rooms.map((room: any, index: any) => (
      <RoomTableRow room={room} index={index} key={room.id} />
    ));
  } else {
    content = (
      <tr>
        <td colSpan={11}>
          <div className="text-center text-danger h6">No data available!</div>
        </td>
      </tr>
    );
  }
  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Room List'}
          breadcrumbItem={[{ link: '/setup/room/', name: 'Room' }]}
        />
        <Card>
          <CardHeader className="d-flex justify-content-end">
            <Link className="btn btn-sm btn-primary" to="/setup/room/add">
              Add Room
            </Link>
          </CardHeader>
          <CardBody>
            <div className="table-responsive text-nowrap text-center  x-overflow-scroll">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Sl. </th>
                    <th>Name</th>
                    <th>Department</th>
                    <th>Building No</th>
                    <th>Floor No</th>
                    <th>Size</th>
                    <th>Capacity</th>
                    <th>Room Category</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
