import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { Container, Form, Label, FormGroup, Input } from 'reactstrap';
import { InputField, Button } from 'src/components';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { setFromErrors } from 'src/helpers';
import { useGetLogBookMasterSetupsQuery } from 'src/rtk/features/setup/logBookMasterSetup/logBookMasterSetupApi';
import { useHistory } from 'react-router-dom';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useCreateLogbookEssentialInfoMutation, useGetLogbookEssentialInfosQuery } from 'src/rtk/features/setup/logBookEssentialInfoSetup/logBookEssentialInfoSetupApi';
import DynamicFormSetup from 'src/components/DynamicForm/Setup/DynamicFormSetup';

const initialValues: LogbookEssentialInfo = {
    id: '',
    setup_logbook_master_id: '',
    name: '',
    description: '',
    details_json: '',
    setup_logbook_essential_info_id: '',
    sl_no: 0,
    status: true,
    creator_organization_id: '',
    updater_organization_id: '',
    created_by: '',
    updated_by: '',
    deleted_by: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
};

export const AddLogbookEssentialInfo = () => {
  const [createLogbookEssentialInfo, { isSuccess, isError, error }] = useCreateLogbookEssentialInfoMutation();

  const { data: logbookMastersData } = useGetLogBookMasterSetupsQuery();
  const logbookMasters = logbookMastersData?.data || [];

  const { data: logbookEssentialInfosData } = useGetLogbookEssentialInfosQuery();
  const logbookEssentialInfos = logbookEssentialInfosData?.data || [];

  const [ showDetailsJsonForm, setShowDetailsJsonForm ] = useState<boolean>(false);

  const history = useHistory();

  const err: CustomError = error as CustomError;
  const formik =
    useFormik({
      initialValues,
      validationSchema: Yup.object({
        setup_logbook_master_id: Yup.string().required('Please select the logbook master'),        
      }),

      onSubmit: (values) => {
        createLogbookEssentialInfo({
          ...values,
        });
        console.log(values);
      },
    });

  const { handleSubmit, getFieldProps, values, touched, errors, resetForm, setErrors } = formik;

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Logbook essential info created successfully');
      history.push('/setup/logbook-essential-info');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);
  
  let logbookMasterOptions: SelectOption[] = [    
    ...logbookMasters?.map((l: LogBookSetupMasters) => ({
      label: l.logbook_heading!,
      value: l.id!,
    })),
  ];

  let logbookEssentialInfoOptions: SelectOption[] = [    
    ...logbookEssentialInfos?.map((l: LogbookEssentialInfo) => ({
      label: l.name!,
      value: l.id!,
    })),
  ];

  const handleSubmitButtonClick = (e: any) => {
    setShowDetailsJsonForm(true);
  };

  return !showDetailsJsonForm ? (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Add Logbook Essential Info'}
          breadcrumbItem={[{ link: '/setup/logbook-essential-info/', name: 'Logbook Essential Info' }]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="card mt-3">
            <h5 className="card-header">
              <i className="fa fa-plus me-2" />
              Add Logbook Essential Info
            </h5>
            <div className="card-body">
              <Form
                className="custom-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="row">                  
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="setup_logbook_master_id">Setup Logbook Master</Label>
                      <InputField
                        type="select"
                        placeholder="Logbook Master Heading"
                        options={logbookMasterOptions}
                        invalid={{ errors, touched }}
                        {...getFieldProps('setup_logbook_master_id')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="name">Name</Label>
                      <InputField
                        type="text"
                        placeholder="Name"
                        invalid={{ errors, touched }}
                        {...getFieldProps('name')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="description">Description</Label>
                      <InputField
                        type="textarea"
                        placeholder="Description"
                        invalid={{ errors, touched }}
                        {...getFieldProps('description')}
                      />
                    </FormGroup>
                  </div>

                  {/* <div className="col-sm-12">
                    <DynamicFormSetup formikProp={formik} fieldProp={'details_json'} />
                  </div> */}

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="setup_logbook_essential_info_id">Logbook Essential Info Name</Label>
                      <InputField
                        type="select"
                        placeholder="Logbook Essential Info"
                        options={logbookEssentialInfoOptions}
                        invalid={{ errors, touched }}
                        {...getFieldProps('setup_logbook_essential_info_id')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="sl_no">Sl No</Label>
                      <InputField
                        type="number"
                        placeholder="Sl No"
                        invalid={{ errors, touched }}
                        {...getFieldProps('sl_no')}
                      />
                    </FormGroup>
                  </div>                  

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup check className="mb-3 mt-4">
                      <Label check>
                        <Input type="checkbox" checked={values.status} {...getFieldProps('status')} />{' '}
                        Status
                      </Label>
                    </FormGroup>
                  </div>
                </div>
                <Button type="button" text="Submit" onClick={(e) => handleSubmitButtonClick(e)} />
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  ) : (
    <DynamicFormSetup formikProp={formik} fieldProp={'details_json'} />
  );
};
