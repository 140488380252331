import { apiSlice } from 'src/rtk/api/apiSlice';
import * as dissertationListForReviewerUrl from 'src/helpers/url_helper';

export const dissertationListForReviewerApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['dissertationList'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDissertationListForReviewerByPaginate: builder.query<
        any,
        PaginateForLogbookEntries
      >({
        query: (paginate) => {
          let url = `${dissertationListForReviewerUrl.GET_DISSERTATION_LIST_FOR_REVIEWER_BY_PAGINATE}?`;

          if (paginate.page) {
            url += `page=${paginate.page}&`;
          }
          if (paginate.paginate_per_page) {
            url += `paginate_per_page=${paginate.paginate_per_page}`;
          }
          if (paginate.search) {
            url += `${paginate.search}`;
          }

          return {
            method: 'GET',
            url: url,
          };
        },

        providesTags: ['dissertationList'],
      }),

      createDissertationReviewerComment: builder.mutation<
        any,
        DissertationReviewerComment
      >({
        query: (data) => ({
          url: `${dissertationListForReviewerUrl.CREATE_DISSERTATION_REVIEWER_COMMENT}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['dissertationList'],
      }),
    }),
  });

export const {
  useGetDissertationListForReviewerByPaginateQuery,
  useCreateDissertationReviewerCommentMutation,
} = dissertationListForReviewerApi;
