import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { config } from 'src/config';
import { useAppSelector } from 'src/rtk/app/hook';

const Footer = () => {
    const authUser = useAppSelector((state) => state.auth.user);

    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>In Planning and Implementation: BCPS IT.</Col>
                        <Col md={4} className='text-center'>
                            {new Date().getFullYear()} © BCPS.
                            {authUser?.details?.subject?.subject_code &&
                              <a
                                href={config.APP_URL + `guideline/${authUser?.details?.subject?.subject_code}.pdf`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="ms-1 text-decoration-underline"
                              >
                                  User Manual
                              </a>
                            }
                        </Col>
                        <Col md={4}>
                            <div className="text-sm-end d-none d-sm-block">
                                Technical Partner: 
                                <a
                                    href="https://egeneration.co/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="ms-1 text-decoration-underline"
                                >
                                    eGeneration Ltd.
                                </a>

                            </div>

                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
