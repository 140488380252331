import { useFormik } from 'formik';
import React, { ChangeEvent } from 'react';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { InputField } from 'src/components';
import EducationalProfile from 'src/components/ProfileForm/EducationalProfile/EducationalProfile';
import demoSignature from '../../assets/images/signature.png';
import { Criteria } from './components/Criteria';
import { WorkExperienceTable } from './components/WorkExperienceTable';
import { useAppSelector } from '../../rtk/app/hook';

const SupervisorInformation = () => {
  const predefinedWorkshops = [
    "Supervisor's Skill Development Workshop",
    'Research Methodology Workshop',
  ];

  const {
    values,
    setFieldError,
    setFieldValue,
    setValues,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    handleBlur,
    handleChange,
  } = useFormik({
    initialValues: {
      name: '',
      mobile: '',
      email: '',
      degree: '',
      institute: '',
      roll: '',
      fellowNo: '',
      division: '',
      district: '',
      po: '',
      address: '',
      subject: '',
      passingYear: '',
      uploadCert: '',
      registrationNo: '',
      workshopAttended: '',
      attachment: '',
      designation: '',
      workInstitute: '',
      department: '',
      fromDate: Date.now().toString(),
      toDate: Date.now().toString(),
      signature: '',
      workshops: [''],
    },

    onSubmit: (values) => {
      console.log(values);
    },
  });

  const { name } = useAppSelector((state) => state.auth.user);

  const [otherWorkshop, setOtherWorkshop] = React.useState('');
  const handleMoreWorkshop = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 0) {
      setOtherWorkshop(e.target.value);
    }
  };

  const addWorkshop = () => {
    if (!values.workshops.includes(otherWorkshop)) {
      values.workshops.length > 0 && values.workshops[0].length === 0
        ? setFieldValue('workshops', [otherWorkshop])
        : setFieldValue('workshops', [...values.workshops, otherWorkshop]);
    }
  };

  const [skillDevelopmentChecked, setSkillDevelopmentChecked] =
    React.useState(false);
  const [researchMethodologyChecked, setResearchMethodologyChecked] =
    React.useState(false);

  const handleCustomChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    if (name === 'skillDevelopment') {
      if (!skillDevelopmentChecked) {
        setSkillDevelopmentChecked(true);
      } else {
        setSkillDevelopmentChecked(false);
      }
    }
    if (name === 'researchMethodology') {
      if (!researchMethodologyChecked) {
        setResearchMethodologyChecked(true);
      } else {
        setResearchMethodologyChecked(false);
      }
    }
  };

  return (
    <React.Fragment>
      <h5>Supervisor Registration</h5>
      <Criteria />

      <Form onSubmit={handleSubmit} encType="multipart/form-data">
        <Col sm={12} md={12} lg={12}>
          <Row>
            <div className="col-md-4 col-sm-12">
              <Label for="first_name"> Name</Label>
              <InputField
                type="text"
                name="name"
                value={name}
                invalid={{ errors, touched }}
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-12">
              <Label for="mobile">Mobile</Label>
              <InputField
                type="text"
                placeholder="Enter Your Mobile Number"
                invalid={{ errors, touched }}
                {...getFieldProps('mobile')}
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <Label for="email">Email</Label>
              <InputField
                type="text"
                placeholder="Enter Your Email"
                invalid={{ errors, touched }}
                {...getFieldProps('email')}
              />
            </div>

            {/* <div>
                <Label for="signature">Signature</Label>
                <InputField
                  type="file"
                  invalid={{ errors, touched }}
                  {...getFieldProps('signature')}
                />
              </div> */}

            <div className="permanent-address-form mt-3">
              <h5> Mailing Address</h5>
              <div className="large-from-grid">
                <div>
                  <Label for="permanent_division">Division</Label>
                  <select
                    className="form-select"
                    onChange={handleChange}
                    name="permanent_division"
                  >
                    <option> Select Division</option>
                    {/* {selectOptionHandler(
                        permanentDivision,
                        personalProfile?.data?.details?.latest_permanent_address
                          ?.division,
                      )} */}
                  </select>
                </div>
                <div>
                  <Label for="permanent_district">District</Label>
                  <select
                    className="form-select"
                    onChange={handleChange}
                    name="permanent_district"
                  >
                    <option value="">Select District</option>
                    {/* {selectOptionHandler(
                        permanentDistrict,
                        personalProfile?.data?.details?.latest_permanent_address
                          ?.district,
                      )} */}
                  </select>
                </div>
                <div>
                  <Label for="permanent_police_station">Upazila</Label>
                  <select
                    className="form-select"
                    onChange={handleChange}
                    name="permanent_police_station"
                  >
                    <option value="" selected>
                      Select Upazila
                    </option>
                    {/* {selectOptionHandler(
                        permanentPoliceStation,
                        personalProfile?.data?.details?.latest_permanent_address
                          ?.police_station,
                      )} */}
                  </select>
                </div>
                <div>
                  <Label for="permanent_address"> Address</Label>
                  <InputField
                    type="textarea"
                    placeholder="Enter Permanent Address"
                    invalid={{ errors, touched }}
                    rows={1}
                    {...getFieldProps('permanent_address')}
                  />
                </div>
              </div>
            </div>
          </Row>
        </Col>

        <hr />
        <EducationalProfile />
        <hr />

        <Col sm={12} md={12} lg={12}>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <FormGroup className="mb-5">
                <h5>Workshop Attended</h5>
                <div className="my-3">
                  <Input
                    type="checkbox"
                    name={`skillDevelopment`}
                    value={predefinedWorkshops[0]}
                    onChange={handleCustomChange}
                  />
                  <Label className="ms-2" check>
                    Supervisor's Skill Development Workshop
                  </Label>
                </div>
                <div className="my-3">
                  <Input
                    type="checkbox"
                    name={`researchMethodology`}
                    value={predefinedWorkshops[1]}
                    onChange={handleCustomChange}
                  />
                  <Label className="ms-2" check>
                    Research Methodology Workshop
                  </Label>
                </div>

                {values.workshops.map((workshop, index) => (
                  <div className="my-3" key={index}>
                    {workshop.length > 0 && (
                      <Input
                        type="checkbox"
                        name={`workshops[${index}]`}
                        value={workshop}
                        onChange={handleChange}
                        checked={values.workshops.includes(workshop)}
                      />
                    )}
                    {workshop.length > 0 && (
                      <Label className="ms-2" check>
                        {workshop}
                      </Label>
                    )}
                  </div>
                ))}

                <div className="mb-3">
                  <Row className="align-items-center">
                    <Col sm={12} md={6} lg={6}>
                      <Input
                        className="form-control"
                        placeholder="Add Workshop Name"
                        type="text"
                        onChange={handleMoreWorkshop}
                      />
                    </Col>
                    <Col sm={2} md={2} lg={2}>
                      <Button type="button" onClick={addWorkshop}>
                        <i className="fa-sharp fa-solid fa-plus"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </FormGroup>
            </Col>

            <Col sm={12} md={6} lg={6}>
              <FormGroup className="mb-5">
                <div className="mb-3">
                  <Label className="form-label">Attachment</Label>
                  <Row>
                    <Col sm={9} md={9} lg={9}>
                      <Input
                        name="attachment"
                        className="form-control"
                        placeholder="Choose file"
                        type="file"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.attachment}
                      />
                    </Col>

                    <Col sm={3} md={3} lg={3}>
                      <Button>
                        <i className="fa-sharp fa-solid fa-plus"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Col>

        <h5>
          Work Experience
          <span className="text-muted fw-normal ms-2">
            (please mention only the current position)
          </span>
        </h5>
        <div className="large-from-grid">
          <div className="mb-3">
            <Label className="form-label">Select Designation</Label>
            <Input
              name="designation"
              className="form-control"
              placeholder="Select Designation"
              type="select"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.designation || ''}
              invalid={touched.designation && !!errors.designation}
            >
              <option value="0">Select Designation</option>
            </Input>
            {touched.designation && errors.designation ? (
              <FormFeedback type="invalid">{errors.designation}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Institute</Label>
            <Input
              name="workInstitute"
              className="form-control"
              placeholder="Select"
              type="select"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.workInstitute || ''}
              invalid={touched.workInstitute && !!errors.workInstitute}
            >
              <option value="0">Select...</option>
            </Input>
            {touched.workInstitute && errors.workInstitute ? (
              <FormFeedback type="invalid">{errors.workInstitute}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Department</Label>
            <Input
              name="department"
              className="form-control"
              placeholder="Select"
              type="select"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.department || ''}
              invalid={touched.department && !!errors.department}
            >
              <option value="0">Select...</option>
            </Input>
            {touched.department && errors.department ? (
              <FormFeedback type="invalid">{errors.department}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">From</Label>
            <Input
              name="fromDate"
              className="form-control"
              placeholder=""
              type="date"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.fromDate}
            />
          </div>

          <div className="mb-3">
            <Label className="form-label">To</Label>
            <Input
              name="toDate"
              className="form-control"
              placeholder=""
              type="date"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.toDate}
            />
          </div>
        </div>
        <div className="mb-5 text-center">
          <Button className="btn btn-primary w-25" color="primary">
            + Add
          </Button>
        </div>

        <Row>
          <div className="mb-3 col  d-flex align-items-center gap-5">
            <div>
              <Label className="form-label">Signature</Label>
              <Input
                name="signature"
                className="form-control mb-4"
                placeholder="Choose file"
                type="file"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.signature}
              />
            </div>

            <div className="mb-3 col-md-6 col-sm-12 d-flex align-items-center  p-2">
              <img
                className="img-fluid"
                src={demoSignature}
                alt=""
                width={120}
              />
            </div>
          </div>
        </Row>

        <WorkExperienceTable />
      </Form>
    </React.Fragment>
  );
};

export default SupervisorInformation;
