import { apiSlice } from 'src/rtk/api/apiSlice';
import * as fcpsOfflinePaymentUrl from 'src/helpers/url_helper';

export const fcpsOfflinePaymentApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['fcpsOfflinePayment'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getFcpsPaymentList: builder.query<APIResponseWithPagination<GetFcpsOfflinePayment>, void>({
        query: () => ({
          method: 'GET',
          url: `${fcpsOfflinePaymentUrl.GET_FCPS_PAYMENT_LIST}`,
        }),

        providesTags: ['fcpsOfflinePayment'],
      }),

      getTraineePaymentList: builder.query<APIResponseWithPagination<GetFcpsOfflinePayment>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${fcpsOfflinePaymentUrl.GET_TRAINEE_PAYMENT_LIST}?${url}`,
        }),

        providesTags: ['fcpsOfflinePayment'],
      }),

      getFcpsPayment: builder.query<APIResponse<GetFcpsOfflinePayment>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${fcpsOfflinePaymentUrl.GET_FCPS_PAYMENT}/${id}`,
        }),
        
        providesTags: ['fcpsOfflinePayment'],
      }),

      createFcpsPayment: builder.mutation<
        APIResponse<AddFcpsOfflinePayment>,
        any
      >({
        query: (data: any) => ({
          url: `${fcpsOfflinePaymentUrl.CREATE_FCPS_PAYMENT}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['fcpsOfflinePayment'],
      }),

      createInstitutePayment: builder.mutation<
        APIResponse<AddFcpsOfflinePayment>,
        any
      >({
        query: (data: any) => ({
          url: `${fcpsOfflinePaymentUrl.CREATE_INSTITUTE_PAYMENT}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['fcpsOfflinePayment'],
      }),

      createDepartmentPayment: builder.mutation<
        APIResponse<AddFcpsOfflinePayment>,
        any
      >({
        query: (data: any) => ({
          url: `${fcpsOfflinePaymentUrl.CREATE_DEPARTMENT_PAYMENT}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['fcpsOfflinePayment'],
      }),
    }),
  });

export const {
  useGetFcpsPaymentListQuery,
  useGetTraineePaymentListQuery,
  useGetFcpsPaymentQuery,
  useCreateFcpsPaymentMutation,
  useCreateInstitutePaymentMutation,
  useCreateDepartmentPaymentMutation,
} = fcpsOfflinePaymentApi;
