import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useState } from 'react';
import { config } from 'src/config';
import { useGetDepartmentsQuery } from 'src/rtk/features/setup/department/departmentApi';

interface Props {
  data: NoticeList;
}

const InfoViewModal = (props: Props) => {
  const { data } = props;

  const [noticeHeaderFile, setNoticeHeaderFile] = useState('');

  const [noticeFooterFile, setNoticeFooterFile] = useState('');

  // const {
  //   data: departments,
  //   error: departmentsError,
  //   isLoading: isDepartmentsLoading,
  //   isError: isDepartmentsError,
  //   isSuccess: isDepartmentsSuccess,
  // } = useGetDepartmentsQuery();

  useEffect(() => {
    if (data?.notice_header) {
      setNoticeHeaderFile(`${config?.APP_URL}${data?.notice_header}`);
    }

    if (data?.notice_footer) {
      setNoticeFooterFile(`${config?.APP_URL}${data?.notice_footer}`);
    }
  }, []);

  return (
    <div className="row justify-content-center">
      <div className="col-md-12">
        <div className="row justify-content-center">
          <div className="col-md-4 col-sm-12 mb-4">
            <div className="fw-semibold text-center">Notice Type: </div>
            <div className="text-center">{data?.notice_type ?? 'N/A'}</div>
          </div>
          <div className="col-md-4 col-sm-12 mb-4">
            <div className="fw-semibold text-center">Subject: </div>
            <div className="text-center">
              {data?.department_id ?? 'N/A'}
            </div>
          </div>

          <div className="col-md-4 col-sm-12 mb-4">
            <div className="fw-semibold text-center">Notice Title: </div>
            <div className="text-center">{data?.notice_subject ?? 'N/A'}</div>
          </div>

          <div className="col-md-4 col-sm-12 mb-4">
            <div className="fw-semibold text-center">Notice No: </div>
            <div className="text-center">{data?.notice_no ?? 'N/A'}</div>
          </div>

          <div className="col-md-4 col-sm-12 mb-4">
            <div className="fw-semibold text-center">Notice Date: </div>
            <div className="text-center">
              {data?.notice_date?.substring(0, 10) ?? 'N/A'}
            </div>
          </div>

          <div className="col-sm-12 mb-4">
            <div className="fw-semibold text-center">Notice Header: </div>
            <div className="text-center">
              {noticeHeaderFile !== '' ? (
                <a
                  className="me-2 p-0 center"
                  href={noticeHeaderFile}
                  rel="noreferrer"
                  target="_blank"
                >
                  <span className="btn-outline-info btn-sm btn ms-2">View</span>
                </a>
              ) : (
                'N/A'
              )}
            </div>
          </div>

          <div className="col-sm-12 mb-5">
            <div className="fw-semibold text-center">Notice Footer: </div>
            <div className="text-center">
              {noticeFooterFile !== '' ? (
                <a
                  className="me-2 p-0 center"
                  href={noticeFooterFile}
                  rel="noreferrer"
                  target="_blank"
                >
                  <span className="btn-outline-info btn-sm btn ms-2">View</span>
                </a>
              ) : (
                'N/A'
              )}
            </div>
          </div>

          <div className="col-sm-12 mb-2 align-items-center">
            <div className="fw-semibold text-center">Notice Body: </div>
          </div>
          {/* <div className="col-sm-12 mb-2 align-items-center text-center border p-1">
            {JSON.parse(data?.notice_body ?? '{}')?.editorHtml ? (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    JSON.parse(data?.notice_body ?? '{}')?.editorHtml ?? '',
                }}
              />
            ) : (
              'N/A'
            )}
          </div> */}
          <Editor            
            apiKey={config.EDITOR_API_KEY}           
            disabled={true}
            init={{
              height: 500,
              width: 700,
              menubar: false,                    
            }}
            value={JSON.parse(data?.notice_body ?? '{}')?.editorHtml}            
          />

          <div className="col-md-4 col-sm-12 mb-4 mt-5">
            <div className="fw-semibold text-center">Attachments: </div>
            {!data?.trn_notice_copy_attachments?.filter(
              (attachment: TrnNoticeCopyAttachment) =>
                attachment?.attachment_type === 'Attachment',
            )?.length ? (
              <div className="text-center">N/A</div>
            ) : (
              data?.trn_notice_copy_attachments
                ?.filter(
                  (attachment: TrnNoticeCopyAttachment) =>
                    attachment?.attachment_type === 'Attachment',
                )
                ?.map((attachment: TrnNoticeCopyAttachment) => {
                  return (
                    <div className="text-center" key={attachment?.id}>
                      {attachment?.details !== '' ? (
                        <a
                          className="me-2 mb-2 p-0 center"
                          href={`${config?.APP_URL}${attachment?.details}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <span className="btn-outline-info btn-sm btn ms-2 mb-3">
                            View
                          </span>
                        </a>
                      ) : (
                        'N/A'
                      )}
                    </div>
                  );
                })
            )}
          </div>

          <div className="col-md-4 col-sm-12 mb-4 mt-5">
            <div className="fw-semibold text-center">Signatories: </div>
            {!data?.trn_notice_copy_attachments?.filter(
              (attachment: TrnNoticeCopyAttachment) =>
                attachment?.attachment_type === 'Signatory',
            )?.length ? (
              <div className='text-center'>N/A</div>
            ) : (
              data?.trn_notice_copy_attachments
                ?.filter(
                  (attachment: TrnNoticeCopyAttachment) =>
                    attachment?.attachment_type === 'Signatory',
                )
                ?.map((attachment: TrnNoticeCopyAttachment) => {
                  return (
                    <div className="text-center" key={attachment?.id}>
                      {attachment?.signatory_name !== '' ? (
                        <div className="text-center">
                          {attachment?.signatory_name ?? 'N/A'}
                        </div>
                      ) : (
                        'N/A'
                      )}
                    </div>
                  );
                })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoViewModal;
