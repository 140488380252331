import { useFormik } from "formik";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { Button, InputField } from "src/components";
import { setFromErrors } from "src/helpers";
import { useAcademicStaffWorkloadsEntryMutation } from "src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi";
import * as Yup from 'yup';

interface WorkLoadOfAcademicStaffProps {
    departmentId?: string;
    academicStaffWorkloadToEdit?: WorkloadStaffEntryList;
    setModalShow?: any;
}

const initialValues: WorkloadStaffEntry = {
    staff_workloads_id: '',
    form_b_department_id: '',
    faculty: '',
    in_patient_care: 0,
    in_patient_rounds: 0,
    out_patient_attendance: 0,
    on_call: 0,
    emergency_attendance: 0,
};

const WorkLoadOfAcademicStaffModal = (props: WorkLoadOfAcademicStaffProps) => {
    const { departmentId, academicStaffWorkloadToEdit, setModalShow } = props;

    const [academicStaffWorkloadRecordEntry, { data: academicStaffWorkloadRecordEntryData, isSuccess: isAcademicStaffWorkloadRecordEntryDataSuccess, isLoading: isAcademicStaffWorkloadRecordEntryDataLoading, isError: isAcademicStaffWorkloadRecordEntryDataError, error: academicStaffWorkloadRecordEntryDataError }] =
        useAcademicStaffWorkloadsEntryMutation();

    const err: CustomError = academicStaffWorkloadRecordEntryDataError as CustomError;

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({}),

        onSubmit: (values: WorkloadStaffEntry) => {
            console.log(values);
            values.form_b_department_id = departmentId;
            academicStaffWorkloadRecordEntry(values);
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    const handleFormReset = () => {
        resetForm();
    };

    useEffect(() => {
        if (academicStaffWorkloadToEdit?.id) {
            setValues({
                staff_workloads_id: academicStaffWorkloadToEdit?.id,
                form_b_department_id: academicStaffWorkloadToEdit?.form_b_department_id,
                faculty: academicStaffWorkloadToEdit?.faculty,
                in_patient_care: academicStaffWorkloadToEdit?.in_patient_care,
                in_patient_rounds: academicStaffWorkloadToEdit?.in_patient_rounds,
                out_patient_attendance: academicStaffWorkloadToEdit?.out_patient_attendance,
                on_call: academicStaffWorkloadToEdit?.on_call,
                emergency_attendance: academicStaffWorkloadToEdit?.emergency_attendance,
            });
        }
    }, []);

    useEffect(() => {
        if (isAcademicStaffWorkloadRecordEntryDataSuccess) {
            handleFormReset();
            toast.success('Work load academic staff record saved successfully');
            setModalShow(false);
        }
        if (isAcademicStaffWorkloadRecordEntryDataError) {
            Object.entries(err?.data).forEach((entry: any) => {
                entry[1]?.forEach((message: any) => {
                    toast.error(message);
                });
            });

            setFromErrors(err.data, setErrors);
        }
    }, [isAcademicStaffWorkloadRecordEntryDataSuccess, isAcademicStaffWorkloadRecordEntryDataError]);

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title">
                                    <i className="fa fa-plus me-2" />
                                    Work load of academic staff
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    autoComplete="off"
                                    className="custom-form"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    onReset={(e) => {
                                        e.preventDefault();
                                        handleFormReset();
                                    }}
                                >
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="faculty">Duties</Label>
                                                <InputField
                                                    type="select"
                                                    options={[
                                                        { label: 'In-patient care', value: 'In-patient care' },
                                                        { label: 'Ward round', value: 'Ward round' },
                                                        { label: 'Laboratory work load', value: 'Laboratory work load' },
                                                        { label: 'On call frequency', value: 'On call frequency' },
                                                        { label: 'Emergency attendance', value: 'Emergency attendance' },
                                                        { label: 'Teaching (and related works)', value: 'Teaching (and related works)' }
                                                    ]}
                                                    placeholder=""
                                                    {...getFieldProps('faculty')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="in_patient_care">Working hours (per week)</Label>
                                                <InputField
                                                    type="number"
                                                    placeholder=""
                                                    {...getFieldProps('in_patient_care')}
                                                />
                                            </FormGroup>
                                        </Col>                                        

                                        <Col sm={12} className="mt-3">
                                            <FormGroup className="mb-3 text-center">
                                                <Button
                                                    type="reset"
                                                    text="Reset"
                                                    className="btn btn-warning me-2"
                                                ></Button>
                                                <Button
                                                    type="submit"
                                                    text="Save"
                                                    className="btn btn-success me-2"
                                                    disabled={isAcademicStaffWorkloadRecordEntryDataLoading}
                                                ></Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default WorkLoadOfAcademicStaffModal;