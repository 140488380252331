// import { DaynamicForm } from 'src/pages/Setup/ExamInfo/form';
import { AddExamInfo } from 'src/pages/Setup/ExamInfo/AddExamIfo';
// import { EditExamInfo } from '../pages/Setup/ExamInfo/EditExamInfo';
import { ExamInfo } from 'src/pages/Setup/ExamInfo';

export const examInfoRoutes = [
  // setup faculty info
  { path: '/setup/exam-info/', component: ExamInfo },
  // { path: '/setup/exam-info/add', component: DaynamicForm },
  // {
  //   path: '/setup/exam-info/update/:id',
  //   component: DaynamicForm,
  // },
  { path: '/setup/exam-info/add', component: AddExamInfo },
  {
    path: '/setup/exam-info/update/:id',
    component: AddExamInfo,
  },
];
