import { Label } from 'reactstrap';

interface TableRowItemProps {
    permission: PermissionWithChecked;
}

const TableRowItem = ({ permission }: TableRowItemProps) => (
    <tr key={permission.name}>
        <td>{permission.name}</td>
        <td>
            {permission.capabilities.map((capability) => (
                <div key={capability.name}>
                    <Label
                        id={`permissions-${capability.name}`}
                        className="ms-2"
                    >
                        {capability.display_name}{' '}
                        {capability.isChecked && (
                            <span style={{ color: 'green' }}>✓</span>
                        )}
                    </Label>
                </div>
            ))}
        </td>
    </tr>
);

export default TableRowItem;
