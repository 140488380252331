import React, { useEffect, useState } from "react";
import { Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { CommentSectionModal } from "../CommentSectionForProtocol/CommentSectionModal";
import RichTextEditor from "./RichTextEditor";
import * as url from 'src/helpers/url_helper';

const Objectives = (props: any) => {

    const { setObjectivesRichtexts, objectivesRichtexts, existingProtocol, objectives, formikState, commentsDataList, initialContentEditor, setInitialContentEditor, renderKey } = props;

    const [messages, setMessage] = useState<any>([]);
    const [showCommentSectionModal, setShowCommentSectionModal] = useState<boolean>(false);

    // tabs - objectives
    const [activeTab, setActiveTab] = useState('general_objectives');
    const toggleTab = (tab: string) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const getCommentsData = (id: any, nameOfTheField: any) => {
        commentsDataList(`${url.THESIS_DISSERTATION_PROTOCOL_COMMENT_LIST}/${id}/${nameOfTheField}`).then(function (result: any,) {
            // here you can use the result of promiseB
            if (result?.data?.data) {
                setMessage(result?.data?.data);
            }
        });

        // setMessage(commentsData?.data || []);
    };

    useEffect(() => {
        getCommentsData(existingProtocol?.data?.id, 'objectives');
    }, []);
    
    return (
        <React.Fragment>

            <Row>
                <Col lg={12}>
                    <Row className="justify-content-end">
                        <Col sm="auto" style={{ pointerEvents: 'auto' }} className='btn noti-icon position-relative'>
                            <span className="badge bg-danger rounded-pill">{messages?.length ?? 0}</span>
                            <Button
                                type="button"
                                className="btn btn-primary btn-sm mb-3"
                                onClick={(e) => {
                                    setShowCommentSectionModal(!showCommentSectionModal);
                                }}
                            >
                                <i className="fas fa-2x fa-comments"></i>
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col lg={12}>
                    <Nav tabs style={{ pointerEvents: 'auto' }}>
                        <NavItem>
                            <NavLink
                                className={activeTab === objectives.general_objectives.objKey ? 'active' : ''}
                                onClick={() => toggleTab(objectives.general_objectives.objKey)}>
                                {objectives.general_objectives.text}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === objectives.specific_objectives.objKey ? 'active' : ''}
                                onClick={() => toggleTab(objectives.specific_objectives.objKey)}

                            >
                                {objectives.specific_objectives.text}
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={objectives.general_objectives.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{objectives.general_objectives.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={activeTab}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane>
                        <TabPane tabId={objectives.specific_objectives.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{objectives.specific_objectives.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={activeTab}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane>
                    </TabContent>




                </Col>

                {showCommentSectionModal ?
                    <CommentSectionModal
                        fieldName={'objectives'}
                        fieldLabel={'Objectives'}
                        existingProtocol={existingProtocol}
                        showModal={showCommentSectionModal}
                        setShowModal={setShowCommentSectionModal}
                        setMessageCount={setMessage}
                    />
                    :
                    null
                }
            </Row>

        </React.Fragment>
    );
};

export default Objectives;