import { apiSlice } from 'src/rtk/api/apiSlice';
import * as permissionUrl from 'src/helpers/url_helper';

export const permissionApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPermissions: builder.query<
            APIResponse<PermissionWithChecked[]>,
            void
        >({
            query: () => ({
                method: 'GET',
                url: `${permissionUrl.GET_PERMISSIONS}`,
            }),
        }),

        getPermissionWiseMenu: builder.query<
            APIResponse<any>,
            void
        >({
            query: () => ({
                method: 'GET',
                url: `${permissionUrl.GET_PERMISSION_WISE_MENU}`,
            }),
        }),
        getPermissionOfRole: builder.query<
            APIResponse<PermissionWithChecked[]>,
            string
        >({
            query: (name) => ({
                method: 'GET',
                url: `${permissionUrl.GET_PERMISSION_OF_ROLE}/${name}`,
            }),
        }),
        updatePermission: builder.mutation({
            query: (url) => ({
                method: 'PATCH',
                url: `${permissionUrl.UPDATE_PERMISSION}/${url}`,
            }),
        }),
    }),
});

export const {
    // useCreatePermissionMutation,
    useGetPermissionsQuery,
    useGetPermissionWiseMenuQuery,
    useGetPermissionOfRoleQuery,
    useUpdatePermissionMutation,
} = permissionApi;
