import { useFormik } from 'formik';
import { useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { config } from 'src/config';
import { useGetSlotApprovalByAdminMutation } from 'src/rtk/features/courseApproval/courseApprovalApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';
import avatar from '../../assets/images/users/default-avatar.png';

interface Props {
  isOpen: boolean;
  toggle: any;
  id: string;
  info: any;
}

const initialValues: RegApprove = {
  registration_no: '',
  status: '',
  message: '',
  type: '',
  registration_type: '',
};

const CourseApprovalViewModal = (props: Props) => {
  const { isOpen, toggle, id, info } = props;

  const buttonRef = useRef<any>();

  const [getSlotApprovalByAdmin, { isSuccess, isError, error, isLoading }] = useGetSlotApprovalByAdminMutation();

  const err = error as CustomError;

  const {
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    handleChange,
    getFieldProps,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      message: Yup.string().when('status', (status, schema) => {
        if (status === 'Correction') {
          return schema.required('Correction note is Required!');
        }
        return schema;
      }),
    }),
    onSubmit: (values) => {

      buttonRef.current.disabled = true;
      const url = `${info.id}?approve_type=${values.status}&note=${values.message}`;
      getSlotApprovalByAdmin(url);
      console.log(values);
    },
  });


  useEffect(() => {
    if (isSuccess) {
      toast.success('Approval Status Updated Successfully!');
      buttonRef.current.disabled = false;
    }
    if (isError) {
      toast.error('Something went wrong');
      buttonRef.current.disabled = false;
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    setFieldValue('status', 'Approve');
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      backdrop={'static'}
      toggle={toggle}
      size="lg"
    >
      <ModalHeader toggle={toggle}>Course Info</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <ModalBody>
          {isLoading ? (
            <div className="overlay">
              <img src={loader} alt="Loading" height={100} width={300} />
            </div>
          ) : null}
          <div>
            <h6 className="block-heading"> Personal Information</h6>
            <div className="row">
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-12">
                    <table className="table table-bordered table-striped table-sm">
                      <tbody>
                        <tr>
                          <td>
                            <strong>Name: </strong>
                            {info?.profile_info?.personal_info?.full_name}
                          </td>
                          <td>
                            {info?.profile_info?.personal_info?.spouse_name && (
                              <div>
                                <strong>Spouse Name: </strong>
                                {info?.profile_info?.personal_info?.spouse_name ?? 'N/A'}
                              </div>
                            )}

                            {info?.profile_info?.personal_info?.fathers_name && (
                              <div>
                                <strong>Father's Name: </strong>
                                {info?.profile_info?.personal_info?.fathers_name ?? 'N/A'}
                              </div>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Mother's Name: </strong>
                            {info?.profile_info?.personal_info?.mothers_name ?? 'N/A'}
                          </td>
                          <td>
                            <strong>Date of Birth: </strong>
                            {info?.profile_info?.personal_info?.date_of_birth ?? 'N/A'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>NID: </strong>
                            {info?.profile_info?.personal_info?.national_id ?? 'N/A'}
                          </td>

                          <td>
                            <strong>Mobile Number: </strong>
                            {info?.profile_info?.personal_info?.mobile ?? 'N/A'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Email: </strong>
                            {info?.profile_info?.personal_info?.email ?? 'N/A'}
                          </td>

                          <td>
                            <strong>Alternative Number: </strong>
                            {info?.profile_info?.personal_info?.telephone ?? 'N/A'}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <strong>Subject: </strong>
                            {info?.profile_info?.subject?.subject_name ?? 'N/A'}
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="img-fluid img-rounded text-center">
                  <img
                    src={
                      info?.profile_info?.personal_info?.picture
                        ? `${config?.APP_URL}${info?.profile_info?.personal_info?.picture}`
                        : avatar
                    }
                    width="120"
                    className="img-thumbnail"
                  />
                </div>
              </div>
            </div>
            <div>
              <h6 className="block-heading">Payment Details</h6>
            </div>
            <div className="row">
              <div className="col-md-12">
                <table className="table table-sm table-bordered table-striped">
                  <tbody>
                    <tr>
                      <td width="50%">
                        <strong>Registration No: </strong>
                        <span>{info?.registration_id}</span>
                      </td>
                      <td width="50%">
                        <strong>Name: </strong>
                        <span>{info?.profile_info?.personal_info?.full_name ?? 'N/A'}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Training Name: </strong>
                        <span>{info?.exam_name ?? 'N/A'}</span>
                      </td>
                      <td>
                        <strong>Subject: </strong>
                        <span>{info?.profile_info?.subject?.subject_name ?? 'N/A'}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Transaction ID: </strong>
                        <span>
                          {info?.profile_info?.order_master?.payment_transaction_id ?? 'N/A'}
                        </span>
                      </td>

                      <td>
                        <strong>Payment Mode: </strong>
                        <span>{info?.profile_info?.order_master?.payment_mode ?? 'N/A'}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Payment Method: </strong>
                        <span>{info?.profile_info?.order_master?.payment_method ?? 'N/A'}</span>
                      </td>
                      <td>
                        <strong>Paid Amount: </strong>
                        <span>{info?.profile_info?.order_master?.total_amount ?? 'N/A'}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Status: </strong>
                        {info?.profile_info?.order_master?.payment_verification_status === 'Approved' ? (
                          <span className="ms-1 badge badge-approved">
                            Verified
                          </span>
                        ) : (
                          <span className="ms-1 badge badge-pending">
                            Pending
                          </span>
                        )}
                        <span>
                          {info?.profile_info?.order_master?.bank_deposit_slip ? (
                            <a
                              className="ms-2 btn btn-sm btn-outline-info"
                              href={
                                config.APP_URL +
                                info?.profile_info?.order_master?.bank_deposit_slip
                              }
                              rel="noreferrer"
                              target="_blank"
                            >
                              <i className="fa-solid fa-file" /> Payment Slip
                            </a>
                          ) : (
                            <span className="text-muted"></span>
                          )}
                        </span>
                      </td>
                      <td>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <h6 className="block-heading">Course Approval</h6>
          <Row>
            {/* {info?.admin_approval_status !== 'Approve' && info?.admin_approval_status !== 'Reject' ?
              <>
                <div className="col">
                  <FormGroup check inline className="mb-3 ms-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="profile_approval_status"
                      id="profile-approve"
                      checked={values.status === 'Approve'}
                      onChange={(e) => {
                        setFieldValue('status', 'Approve');
                      }}
                      value={values.status}
                    />
                    <label
                      className="form-check-label text-success"
                      htmlFor="profile-approve"
                    >
                      Approve
                    </label>
                  </FormGroup>
                  <FormGroup check inline className="mb-3 ">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="profile_approval_status"
                      checked={values.status === 'Reject'}
                      id="reject"
                      onChange={() => {
                        setFieldValue('status', 'Reject');
                      }}
                      value={values.status}
                    />
                    <label
                      className="form-check-label text-danger"
                      htmlFor="reject"
                    >
                      Reject
                    </label>
                  </FormGroup>

                  {errors.status && touched.status ? (
                    <div className="text-danger">{errors.status}</div>
                  ) : null}
                </div>

                <div className="col">
                  <Label for="message">{values.status}&nbsp;Note</Label>
                  <InputField
                    type="textarea"
                    placeholder="Enter.."
                    invalid={{ errors, touched }}
                    {...getFieldProps('message')}
                  />
                </div>
              </> : <div><strong>Admin Approval Status : </strong> {info?.profile_info?.order_master?.payment_verification_status} </div>
            } */}
            <div><strong>Admin Approval Status : </strong> {info?.profile_info?.order_master?.payment_verification_status ?? 'Pending'} </div>
          </Row>
        </ModalBody>

        <ModalFooter>
          <div className="d-flex gap-2 ">
            {/* {info?.admin_approval_status !== 'Approve' && info?.admin_approval_status !== 'Reject' ?
              <button
                type="submit"
                className="btn btn-primary"
                ref={buttonRef}
              >
                Save
              </button>
              : null
            } */}
            <Button color="danger" onClick={toggle}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CourseApprovalViewModal;
