import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { UserSelectAsyncByPermission } from 'src/components/Common/UserSelectAsyncByPermission';
import { setFromErrors } from 'src/helpers';
import { useGetApprovalsQuery } from 'src/rtk/features/setup/approval/approvalApi';
import { useCreateApprovalDetailMutation } from 'src/rtk/features/setup/approval/approvalDetails';
import { useGetPermissionsAprovalQuery } from 'src/rtk/features/setup/approval/permissionApproval';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';

import * as Yup from 'yup';

const initialValues: ApprovalDetail = {
  setup_approval_id: '',
  user_id: 0,
  registration_no: '',
  step_no: 0,
  approve_status_id: 0,
  approve_status: '',
  status: false,
};

export const AddApprovalDetail = () => {
  const history = useHistory();
  const [createApprovalDetail, { isSuccess, isError, error }] =
    useCreateApprovalDetailMutation();

  const [permissionApproval, setPermissionApproval] = useState('');

  const approvalStatus: SelectOption[] =
    useGetLookupDataQuery('approval_status')
      .data?.data[0]?.childs.map((item) => ({
        value: item.id,
        label: item.name,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) || [];
  const err: CustomError = error as CustomError;

  const approvals: SelectOption[] = (
    useGetApprovalsQuery().data?.data || []
  ).map((item) => ({
    value: item.id ?? '',
    label: item.module_name ?? '',
  }));

  const { data: getPermissionApproval } = useGetPermissionsAprovalQuery();
  const permissionApprovalList = getPermissionApproval?.data[0]?.childs || [];

  const permissionApprovalOption: SelectOption[] =
    permissionApprovalList
      .map((item) => ({
        value: item.id,
        label: item.name,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) || [];

  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    resetForm,
    setErrors,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      setup_approval_id: Yup.string(),
      user_id: Yup.number(),
      approve_status_id: Yup.number(),
    }),

    onSubmit: (values) => {
      console.log(values);
      createApprovalDetail({
        ...values,
      });
    },
  });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Approval Detail created successfully');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  const permissionApprovalhandler = (e: any) => {
    const { options, selectedIndex, value } = e.target;
    const PermissionApproval = options[selectedIndex].innerHTML || '';
    setPermissionApproval(PermissionApproval);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title="Add Approval Details"
          breadcrumbItem={[
            { link: '/setup/approval-details/', name: 'Approval Details' },
          ]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-8 col-sm-12 ">
            <div className="card mt-3">
              <h5 className="card-header">
                <i className="fa fa-plus me-2" />
                Add Approval Detail
              </h5>
              <div className="card-body">
                <Form
                  autoComplete="off"
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <Row>
                    <FormGroup className="mb-3 col-md-6 col-sm-12">
                      <Label for="setup_approval_id">Select Approval</Label>
                      <InputField
                        type="select"
                        placeholder="Select Approval"
                        options={approvals}
                        invalid={{ errors, touched }}
                        {...getFieldProps('setup_approval_id')}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3 col-md-6 col-sm-12">
                      <Label for="permission_approval">Select Permission</Label>
                      <InputField
                        type="select"
                        placeholder="Select Approval"
                        options={permissionApprovalOption}
                        invalid={{ errors, touched }}
                        {...getFieldProps('permission_approval')}
                        onChange={(e) => permissionApprovalhandler(e)}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3 col-md-6 col-sm-12">
                      <Label for="user_id">Select User</Label>
                      {/* <UserSelectAsync
                        setValue={setFieldValue}
                        classNames="mb-3"
                        placeholder="Search User"
                        name="user_id"
                      /> */}

                      <UserSelectAsyncByPermission
                        setValue={setFieldValue}
                        classNames="mb-3"
                        placeholder="Search User"
                        name="user_id"
                        approvalPermissionId={permissionApproval}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3 col-md-6 col-sm-12">
                      <Label for="registration_no">Registration No.</Label>
                      <InputField
                        type="text"
                        placeholder="Registration No."
                        invalid={{ errors, touched }}
                        {...getFieldProps('registration_no')}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3 col-md-6 col-sm-12">
                      <Label for="step_no">Step Number</Label>
                      <InputField
                        type="number"
                        placeholder="Step Number"
                        invalid={{ errors, touched }}
                        {...getFieldProps('step_no')}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3 col-md-6 col-sm-12">
                      <Label for="approve_status">Approve Status</Label>
                      <InputField
                        type="select"
                        options={approvalStatus}
                        placeholder="Approve Status"
                        invalid={{ errors, touched }}
                        {...getFieldProps('approve_status')}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3 col-md-6 col-sm-12">
                      <Label for="approve_status_id">Approve Status ID</Label>
                      <InputField
                        type="number"
                        placeholder="Approve Status ID"
                        invalid={{ errors, touched }}
                        {...getFieldProps('approve_status_id')}
                      />
                    </FormGroup>
                    <div className="col-md-6 col-sm-6 ">
                      <h6>Active Status</h6>
                      <FormGroup check className="mb-3">
                        <Input
                          type="checkbox"
                          role="check"
                          {...getFieldProps('status')}
                        />
                        <Label check>Active</Label>
                      </FormGroup>
                    </div>
                    <div className="text-center">
                      <Button
                        className="w-25"
                        outline
                        color="secondary"
                        type="button"
                        text="Cancel"
                        onClick={() => {
                          history.push('/setup/approval-details/');
                        }}
                      />
                      <Button
                        className="w-25 ms-3"
                        type="submit"
                        text="Submit"
                      />
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
