import { apiSlice } from 'src/rtk/api/apiSlice';
import * as userSelectByPermissionUrl from 'src/helpers/url_helper';

interface Paginate {
  page: number | string;
  paginate_per_page?: number;
  search?: string;
}

export const authApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['Users'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUsers: builder.query<APIResponse<User[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${userSelectByPermissionUrl.GET_USERS_IN_SELECT_BY_PERMISSION_API}`,
        }),
        providesTags: ['Users'],
      }),

      getUsersByPaginate: builder.query<
        APIResponseWithPagination<User>,
        Paginate
      >({
        query: (paginate) => {
          let url = `${userSelectByPermissionUrl.GET_USERS_BY_PAGINATE_IN_SELECT_BY_PERMISSION_API}?`;

          if (paginate.page) {
            url += `&page=${paginate.page}`;
          }
          if (paginate.paginate_per_page) {
            url += `&paginate_per_page=${paginate.paginate_per_page}`;
          }
          if (paginate.search) {
            url += `&search=${paginate.search}`;
          }

          return {
            method: 'GET',
            url: url,
          };
        },
        providesTags: ['Users'],
      }),

      getUser: builder.query<User, string>({
        query: (id) => ({
          url: `${userSelectByPermissionUrl.GET_USER_IN_SELECT_BY_PERMISSION_API}/${id}`,
          method: 'GET',
        }),
        providesTags: ['Users'],
      }),

      getUsersByRole: builder.query<APIResponse<User[]>, string>({
        query: (id) => ({
          url: `${userSelectByPermissionUrl.GET_USERS_BY_ROLE}/${id}`,
          method: 'GET',
        }),
        providesTags: ['Users'],
      }),

      getUsersByPermission: builder.query<APIResponse<User[]>, string>({
        query: (id) => ({
          url: `${userSelectByPermissionUrl.GET_USERS_BY_PERMISSION}/${id}`,
          method: 'GET',
        }),
        providesTags: ['Users'],
      }),

      getInstituteInspector: builder.query<APIResponse<User[]>, void>({
        query: () => ({
          url: `${userSelectByPermissionUrl.GET_INSTITUTE_INSPECTOR}`,
          method: 'GET',
        }),
        providesTags: ['Users'],
      }),

      createUser: builder.mutation<APIResponse<User>, User>({
        query: (data) => ({
          url: `${userSelectByPermissionUrl.CREATE_USER_IN_SELECT_BY_PERMISSION_API}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['Users'],
      }),

      updateUser: builder.mutation<APIResponse<User>, any>({
        query: (url) => ({
          url: `${userSelectByPermissionUrl.UPDATE_USER_IN_SELECT_BY_PERMISSION_API}/${url}`,
          method: 'PUT',
        }),
        invalidatesTags: ['Users'],
      }),
      deleteUser: builder.mutation({
        query: (id: number) => ({
          method: 'DELETE',
          url: `${userSelectByPermissionUrl.DELETE_USER_IN_SELECT_BY_PERMISSION_API}/${id}`,
        }),
        invalidatesTags: ['Users'],
      }),
    }),
  });

export const {
  useCreateUserMutation,
  useGetUsersQuery,
  useGetUserQuery,
  useGetUsersByRoleQuery,
  useGetUsersByPermissionQuery,
  useGetInstituteInspectorQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetUsersByPaginateQuery,
} = authApi;
