import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Button, Card, CardBody, Table } from 'reactstrap';
import { config } from 'src/config';
import { useDeleteEducationalProfileMutation } from 'src/rtk/features/educational/educationalApi';
import { useGetPersonalProfileQuery } from 'src/rtk/features/trainee/traineeApi';
import AddEducation from './AddEducation';
import EditEducation from './EditEducation';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import { ConfirmToast } from 'react-confirm-toast';

interface EducationalProfileProps {
  nextDisabled?: any;
}

const EducationalProfile = (props: EducationalProfileProps) => {

  const [editInfoID, setEditInfoID] = useState('');
  const [isAddForm, setIsAddForm] = useState(true);
  const { data: personalProfile } = useGetPersonalProfileQuery();

  const [infoId, setInfoId] = useState('');

  const [modalShow, setModalShow] = useState(false);

  const educationalProfileData =
    personalProfile?.data?.details?.educational_infos;

  useEffect(()=>{
    educationalProfileData?.length === 0 ? props.nextDisabled(true) : props.nextDisabled(false);
    // props.nextDisabled(false);

  },[educationalProfileData]);

  const [
    deleteEducationalProfile,
    { isSuccess: isDeleteSuccess, isError: isDeleteError },
  ] = useDeleteEducationalProfileMutation();

  const deleteHandler = (modalState: boolean, id: string) => {
    setModalShow(modalState);
    setInfoId(id);
    // const confirm = window.confirm('Are you sure you want to delete?');
    // if (confirm) deleteEducationalProfile(id);
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      toast.success('Educational Info Deleted Successfully!');
      setModalShow(false);
    }
    if (isDeleteError) {
      toast.error('Error Deleting Educational Info');
    }
  }, [isDeleteSuccess, isDeleteError]);
  let serial = 0;

  const handleUpdate = (eduID: any) => {
    // console.log(eduID);
    setEditInfoID(eduID);
    setIsAddForm(false);
  };

  return (
    <div>
      <h5 className='block-heading'>Educational Information</h5>

      {isAddForm ? (
        <AddEducation />
      ) : (
        <EditEducation editInfoID={editInfoID} setIsAddForm={setIsAddForm} />
      )}
      {educationalProfileData && educationalProfileData?.length > 0 && (
        <Card>
          <CardBody>
            <div className="table-responsive">
              <Table className="table table-striped table-sm table-hover mb-0">
                <thead className='table-secondary'>
                  <tr>
                    <th>Sl</th>
                    <th>Degree</th>
                    <th>Subject/Specialty</th>
                    <th>Institute</th>
                    <th>Year</th>
                    <th>Session</th>
                    <th>Certificate</th>
                    {/* <th>Start Date</th>
                    <th>End Date</th> */}
                    <th>Present Working Place</th>
                    <th>Present Designation</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {educationalProfileData
                    .map((elmt) => (
                      <tr key={elmt.id}>
                        <td>{++serial}</td>
                        <td>{elmt.name_of_the_degree ? elmt.name_of_the_degree : 'N/A'}</td>
                        <td>{elmt.subject ? elmt.subject : 'N/A'}</td>
                        <td>{elmt.institute_or_university ? elmt.institute_or_university : 'N/A'}</td>
                        <td>{elmt.passing_year ? elmt.passing_year : 'N/A'}</td>
                        <td>{elmt.session ? elmt.session : 'N/A'}</td>
                        <td>
                          {elmt.certificate_pdf ? (
                            <a
                              href={config.APP_URL + elmt.certificate_pdf}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <p className="btn btn-sm btn-outline-primary ">
                                View
                              </p>
                            </a>
                          ) : (
                            <p className="btn btn-sm btn-outline-secondary ">
                              N/A
                            </p>
                          )}
                        </td>
                        {/* <td>{elmt.start_date ? elmt.start_date : 'N/A'}</td>
                        <td>{elmt.end_date ? elmt.end_date : 'N/A'}</td> */}
                        <td>{elmt.present_working_place ? elmt.present_working_place : 'N/A'}</td>
                        <td>{elmt.present_designation ? elmt.present_designation : 'N/A'}</td>
                        <td>
                          <div className='d-flex gap-1'>
                            <Button
                              className="btn btn-success btn-sm"
                              onClick={() => handleUpdate(elmt.id!)}
                            >
                              Edit
                            </Button>
                            <ConfirmToast
                              asModal={true}
                              childrenClassName="margin-top-10"
                              customCancel="No"
                              customConfirm="Yes"
                              customFunction={() =>
                                deleteEducationalProfile(elmt?.id!)
                              }
                              message={'Do You Want to Delete?'}
                              position="top-right"
                              showCloseIcon={true}
                              theme="lilac"
                            >                            
                              <Button
                                className="btn btn-danger btn-sm"
                                // onClick={() => deleteHandler(true, elmt.id!)}
                              >
                                Delete
                              </Button>
                            </ConfirmToast>
                          </div>
                        </td>
                        {/* {modalShow && infoId === elmt?.id ? (
                          <DeleteInformationModal
                            isOpen={modalShow}
                            toggle={() => setModalShow(!modalShow)}
                            id={infoId}
                            deleteInfomation={deleteEducationalProfile}
                          />
                        ) : null} */}
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default EducationalProfile;
