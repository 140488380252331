//import Breadcrumbs
import { Button, Card, CardBody, CardHeader, Container, Form, FormGroup, Label, Table } from 'reactstrap';
import { useGetFacultyInfosListQuery, useGetFacultyInfosQuery } from 'src/rtk/features/setup/facultyInfo/facultyInfoApi';
// import { useGetOrganizationsQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import { useState } from 'react';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import AddFacultyInfo from './AddFacultyInfo';
import FacultyInfoTableRow from './FacultyInfoTableRow';
import { useFormik } from 'formik';

const FacultyInfo = () => {
  const [search, setSearch] = useState('');

  const { data, error, isLoading, isError } = useGetFacultyInfosListQuery(search);
  const err: CustomError = error as CustomError;
  const facultyInfos = data?.data || [];
  const [showAddForm, setShowAddForm] = useState<boolean>(false);

  // what to render
  let content = null;

  if (isLoading) {
    content = <div className="mt-3">Loading...</div>;
  }
  if (!isLoading && isError && error) {
    content = <div className="mt-3">{err.message}</div>;
  }
  if (!isLoading && !isError && facultyInfos?.length > 0) {
    content = facultyInfos.map((facultyInfo: any, index: number) => (
      <FacultyInfoTableRow
        facultyInfo={facultyInfo}
        index={index}
        key={facultyInfo.id}
      />
    ));
  } else {
    content = (
      <tr>
        <td colSpan={11}>
          <div className="text-center text-danger h6">No data available!</div>
        </td>
      </tr>
    );
  }

  const {
    handleSubmit,
    getFieldProps,
    values,
    touched,
    setFieldValue,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues: {
      search: '',
      status: '',
    },

    onSubmit: (values) => {
      let s: string = '';

      if (values.search !== '') {
        s = s + `&search=${values.search}`;
      }

      if (values.status !== '') {
        s = s + `&status=${values.status === 'Active' ? true : false}`;
      }
      setSearch(s);
    },
  });

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Faculty List'}
          breadcrumbItem={[{ link: '/setup/facultyInfo/', name: 'Faculty' }]}
        />
        <Card>
          <CardHeader className="d-flex justify-content-end">
            <Button
              className="btn btn-sm"
              color='primary'
              onClick={() => {
                setShowAddForm(true)
              }}
            >
              Add Faculty
            </Button>
          </CardHeader>
          <CardBody>
            <Form
              className="custom-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              onReset={(e) => {
                e.preventDefault();
                resetForm();
                handleSubmit();
              }}
            >
              <div className='filter-wrapper'>
                <FormGroup>
                  <Label for="status">Status</Label>
                  <InputField
                    type="select"
                    options={[
                      { label: 'Active', value: 'Active' },
                      { label: 'Inactive', value: 'Inactive' }
                    ]}
                    placeholder="Status"
                    {...getFieldProps('status')}
                  />
                </FormGroup>
                <div className="btn-column mb-3">
                  <Button color="warning" type="reset" outline >Reset</Button>
                  <Button color="primary" type="submit" >Search</Button>
                </div>
              </div>
            </Form>
            <div className="table-responsive">
              <Table className="table table-lg text-nowrap mb-0">
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Faculty Name</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
          </CardBody>
          {showAddForm &&
            <AddFacultyInfo isOpen={showAddForm} toggle={() => setShowAddForm(!showAddForm)} />
          }
        </Card>
      </Container>
    </div>
  );
};

export default FacultyInfo;
