import { apiSlice } from 'src/rtk/api/apiSlice';
import * as subjectInfoGlobalUrl from 'src/helpers/url_helper';

export const setupSubjectInfoApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['SubjectInfos'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSubjectInfos: builder.query<APIResponse<SubjectInfo[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${subjectInfoGlobalUrl.GET_SUBJECT_INFOS_IN_SUBJECT_INFO_GLOBAL_API}`,
        }),
        providesTags: ['SubjectInfos'],
      }),

      getSubjectInfo: builder.query<APIResponse<SubjectInfo>, string>({
        query: (id) => ({
          url: `${subjectInfoGlobalUrl.GET_SUBJECT_INFO_IN_SUBJECT_INFO_GLOBAL_API}/${id}`,
          method: 'GET',
        }),
        providesTags: ['SubjectInfos'],
      }),

      createSubjectInfo: builder.mutation<
        APIResponse<SubjectInfo>,
        SubjectInfo
      >({
        query: (data) => ({
          url: `${subjectInfoGlobalUrl.CREATE_SUBJECT_INFO_IN_SUBJECT_INFO_GLOBAL_API}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['SubjectInfos'],
      }),

      updateSubjectInfo: builder.mutation<APIResponse<SubjectInfo>, string>({
        query: (url) => ({
          url: `${subjectInfoGlobalUrl.UPDATE_SUBJECT_INFO_IN_SUBJECT_INFO_GLOBAL_API}` + url,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['SubjectInfos'],
      }),

      deleteSubjectInfo: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${subjectInfoGlobalUrl.DELETE_SUBJECT_INFO_IN_SUBJECT_INFO_GLOBAL_API}/${id}`,
        }),
        invalidatesTags: ['SubjectInfos'],
      }),
    }),
  });

export const {
  useCreateSubjectInfoMutation,
  useGetSubjectInfosQuery,
  useGetSubjectInfoQuery,
  useUpdateSubjectInfoMutation,
  useDeleteSubjectInfoMutation,
} = setupSubjectInfoApi;
