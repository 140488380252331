import { useFormik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const CourseApplication = () => {
  const CourseApplicationFrom = useFormik({
    initialValues: {
      traineeName: "",
      instituteId: 0,
      supervisorId: 0,
      subjectId: 0,
      bmdcRegNo: "A-2010-23-23232",
      fromDate: "",
      toDate: "",
    },
    validationSchema: Yup.object({
      traineeName: Yup.string().required("Name is required"),
      instituteId: Yup.number().required("Please select an institute"),
      supervisorId: Yup.number().required("Please select a supervisor"),
      subjectId: Yup.number().required("Please select a subject"),
      bmdcRegNo: Yup.string().required("BMDC Reg No is required"),
      fromDate: Yup.date().required("From date is required"),
      toDate: Yup.date().required("To date is required"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });
  document.title = "FCPS-II Enrollment Form";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Enrollment"
            breadcrumbItem="Application for FCPS Part II"
          />
          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="example-text-input" className="form-Label">
                  Name
                </Label>
                <Input
                  className="form-control"
                  type="text"
                  defaultValue="John Doe"
                  disabled
                />
              </div>

              <div className="mb-3">
                <Label className="form-Label">Select Institute</Label>
                <select className="form-select">
                  <option>Select Institute</option>
                  <option>Dhaka Medical College</option>
                  <option>Jamalpur Medical College</option>
                  <option>SOMCH</option>
                </select>
              </div>
              <div className="mb-3">
                <Label className="form-Label">Select Supervisor</Label>
                <select className="form-select">
                  <option>Select Supervisor</option>
                  <option>Person 1</option>
                  <option>Person 1</option>
                  <option>Person 3 </option>
                </select>
              </div>
              <div className="mb-3">
                <Label className="form-Label">Select Subject</Label>
                <select className="form-select">
                  <option>Select Institute</option>
                  <option>Dhaka Medical College</option>
                  <option>Jamalpur Medical College</option>
                  <option>SOMCH</option>
                </select>
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="example-text-input" className="form-Label">
                  BMDC Reg. No
                </Label>
                <Input
                  className="form-control"
                  type="text"
                  defaultValue="A-2010-23-23232"
                  disabled
                />
              </div>

              <div className="mb-3">
                <Label
                  htmlFor="example-datetime-local-input"
                  className="form-Label"
                >
                  From Date
                </Label>
                <Input className="form-control" type="date" />
              </div>
              <div className="mb-3">
                <Label
                  htmlFor="example-datetime-local-input"
                  className="form-Label"
                >
                  To Date
                </Label>
                <Input className="form-control" type="date" />
              </div>
            </Col>
          </Row>
          <hr />

          <Row>
            <h5>Supervisor's Consent</h5>
            <Col>
              <FormGroup className="mb-3 mt-2">
                <div className="form-check">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="invalidCheck"
                  />
                  <Label
                    className="form-check-label px-3 mb-5"
                    htmlFor="invalidCheck"
                  >
                    I'm willing to supervise the above named FCPS Part-II
                    trainee in the specialty of Surgery commencing training from
                    01 January 2023 to 31 Jun 2023.
                  </Label>
                </div>
              </FormGroup>
            </Col>
            <div className="text-center mb-3">
              <Link to="/enrollment-payment">
                <button className="btn btn-primary w-md">
                  Proceed for Payment
                </button>
              </Link>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CourseApplication;
