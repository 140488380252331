import { FormikErrors, FormikTouched } from 'formik';
import React from 'react';
import { FormFeedback, Input } from 'reactstrap';
interface Props {
  name: string;
  className?: string;
  placeholder?: string;
  type: InputType;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  value?: any;
  options?: SelectOption[];
  readOnly?: boolean;
  invalid?: {
    errors: FormikErrors<any>;
    touched: FormikTouched<any>;
  };
  [x: string]: any;
}
const InputField = ({
  name,
  className,
  placeholder,
  type,
  onChange,
  onBlur,
  value,
  options = [],
  invalid,
  readOnly,
  ...rest
}: Props) => {
  const { errors, touched } = invalid || {};
  const error = errors ? errors[name] : '';
  const isTouched = touched ? touched[name] : false;

  const _invalid = error && isTouched ? true : false;
  options = [{ value: '', label: 'Select...' }, ...options];

  return (
    <div>
      <Input
        name={name}
        className={`form-control ${className}`}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        invalid={_invalid}
        readOnly={readOnly}
        {...rest}
      >
        {type === 'select'
          ? options.map((option, index) => (
              <option key={index} className="form-control" value={option.value}>
                {option.label}
              </option>
            ))
          : null}
      </Input>
      {_invalid ? (
        <FormFeedback type="invalid">{error as any}</FormFeedback>
      ) : null}
    </div>
  );
};

export default InputField;
