import { Rooms } from 'src/pages/Setup/Room';
import { AddRoom } from 'src/pages/Setup/Room/AddRoom';
import { EditRoom } from 'src/pages/Setup/Room/EditRoom';

export const roomSetupRoutes = [
  // room setup
  {
    path: '/setup/room/',
    component: Rooms,
  },
  {
    path: '/setup/room/update/:id',
    component: EditRoom,
  },
  {
    path: '/setup/room/add',
    component: AddRoom,
  },
];
