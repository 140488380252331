import { useEffect, useRef, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { Label } from "reactstrap";
import { config } from "src/config";

const FileArray = (props: any) => {
    const { activeStateId, thesisEntryDetails, setThesisEntryDetails } = props;

    const [plusButtonDisabled, setPlusButtonDisabled] = useState<boolean>(true);
    const [uploadedFile, setUploadedFile] = useState<string>('');
    const [fileName, setFileName] = useState<string>('');
    const uploadedFileReference = useRef<any>();

    const handleAddFields = () => {
        const newThesisEntryDetails: any = { ...thesisEntryDetails };

        if (uploadedFile && fileName) {
            newThesisEntryDetails[activeStateId]['file_array'].push({ file: uploadedFile, file_name: fileName });
        }

        setThesisEntryDetails(newThesisEntryDetails);
        setPlusButtonDisabled(true);
        setUploadedFile('');
        setFileName('');
        uploadedFileReference.current.value = '';
    };

    const handleRemoveFields = (index: number) => {
        const newThesisEntryDetails: any = { ...thesisEntryDetails };
        newThesisEntryDetails[activeStateId]['file_array'].splice(index, 1);
        setThesisEntryDetails(newThesisEntryDetails);
    };

    const handleInputChange = (event: any, inputFieldName: string) => {
        const { name, value } = event.target;

        if (inputFieldName === 'fileAttachment') {
            if (event.target.files[0]) {
                setUploadedFile(event.target.files[0]);
                setFileName(event.target.files[0].name);
            } else {
                setUploadedFile('');
                setFileName('');
            }
        } else if (inputFieldName === 'fileName') {
            if (event.target.value) {
                setFileName(event.target.value);
            } else {
                setFileName('');
            }
        }
    };

    // console.log(thesisEntryDetails[activeStateId]['file_array']);

    const columns: TableColumn<any>[] = [
        {
            name: <div>Sl. No.</div>,
            width: '100px',
            cell: (row, index) => index + 1,
            // center: true,
        },

        {
            name: 'File Name',
            selector: (row) => row.file_name,
            sortable: false,
            wrap: true,
            // center: true,
        },

        {
            name: <div>File Preview</div>,
            selector: (row) => row.file && (
                typeof row.file === 'string' ? (
                    <a
                        className="me-2 p-0 center"
                        href={`${config?.APP_URL}${row.file}`}
                        rel="noreferrer"
                        target="_blank"
                    >
                        <span className="btn-outline-info btn-sm btn ms-2">View</span>
                    </a>
                ) : (
                    <a
                        className="me-2 p-0 center"
                        href={URL.createObjectURL(row.file)}
                        rel="noreferrer"
                        target="_blank"
                    >
                        <span className="btn-outline-info btn-sm btn ms-2">View</span>
                    </a>
                )
            ),

            sortable: false,
            wrap: true,
            // center: true,
        },

        {
            name: <div>Action</div>,
            width: '150px',
            cell: (row) => (
                <>
                    <button
                        type="button"
                        className={'btn btn-danger btn-sm mb-2'}
                        onClick={() => handleRemoveFields(thesisEntryDetails[activeStateId]['file_array']?.indexOf(row))}
                    >
                        <i className="fa fa-minus-circle" /> Remove
                    </button>
                </>
            ),

            ignoreRowClick: true,
            allowOverflow: true,
            // button: true,
        },
    ];

    useEffect(() => {
        if (uploadedFile && fileName) {
            setPlusButtonDisabled(false);
        } else {
            setPlusButtonDisabled(true);
        }
    }, [uploadedFile, fileName]);

    return (
        <>
            {thesisEntryDetails[activeStateId]['file_array']?.length > 0 &&
                <div className="row mb-5">
                    <div className="col-sm-12">
                        <DataTable
                            columns={columns}
                            data={thesisEntryDetails[activeStateId]['file_array']?.length > 0 ? thesisEntryDetails[activeStateId]['file_array'] : []}
                            expandOnRowClicked
                            highlightOnHover
                        />
                    </div>
                </div>}

            <div className="row align-items-end">
                <div className="col-sm-5">
                    <Label className='form-label'>File Attachment</Label>
                    <input
                        type="file"
                        ref={uploadedFileReference}
                        className="form-control"
                        name="fileAttachment"
                        onChange={(event) => {
                            handleInputChange(event, 'fileAttachment');
                        }}
                    />
                </div>

                <div className="col-sm-5">
                    <Label className='form-label'>File Name</Label>
                    <input
                        type="text"
                        className="form-control"
                        name="fileName"
                        value={fileName}
                        onChange={(event) => {
                            handleInputChange(event, 'fileName');
                        }}
                    />
                </div>

                <div className="col-sm-2">
                    {!plusButtonDisabled ?
                        (<button
                            type="button"
                            className={'btn btn-secondary'}
                            onClick={handleAddFields}
                        >
                            <i className="fa fa-plus-circle" />
                        </button>)
                        :
                        (<button
                            type="button"
                            className={'btn btn-secondary'}
                            onClick={handleAddFields}
                            disabled
                        >
                            <i className="fa fa-plus-circle" />
                        </button>)}
                </div>
            </div>
        </>
    );
};

export default FileArray;
