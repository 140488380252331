import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { putUrlString } from 'src/helpers';
import { useGetLogbookEssentialInfoQuery, useGetLogbookEssentialInfosQuery, useUpdateLogbookEssentialInfoMutation } from 'src/rtk/features/setup/logBookEssentialInfoSetup/logBookEssentialInfoSetupApi';
import { useGetLogBookMasterSetupsQuery } from 'src/rtk/features/setup/logBookMasterSetup/logBookMasterSetupApi';
import * as Yup from 'yup';

const initialValues: LogbookEssentialInfo = {
    id: '',
    setup_logbook_master_id: '',
    name: '',
    description: '',
    details_json: '',
    setup_logbook_essential_info_id: '',
    sl_no: 0,
    status: true,
    creator_organization_id: '',
    updater_organization_id: '',
    created_by: '',
    updated_by: '',
    deleted_by: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
};

export const EditLogbookEssentialInfo = () => {
  const { id } = useParams<{ id: string }>();
  const { data: logbookEssentialInfo, isError: isLogbookEssentialInfoFetchError } = useGetLogbookEssentialInfoQuery(id);
  const [updateLogbookEssentialInfo, { isSuccess, isError, error }] = useUpdateLogbookEssentialInfoMutation();

  const { data: logbookMastersData } = useGetLogBookMasterSetupsQuery();
  const logbookMasters = logbookMastersData?.data || [];

  const { data: logbookEssentialInfosData } = useGetLogbookEssentialInfosQuery();
  const logbookEssentialInfos = logbookEssentialInfosData?.data || [];

  const err: CustomError = error as CustomError;
  const history = useHistory();
  const {
    handleSubmit,
    getFieldProps,
    values,
    touched,
    errors,
    setValues,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
        setup_logbook_master_id: Yup.string().required('Please select the logbook master'), 
    }),

    onSubmit: (values) => {
      const url = putUrlString(id, values);
      updateLogbookEssentialInfo(url);
    },
  });

  useEffect(() => {
    if (logbookEssentialInfo && !isLogbookEssentialInfoFetchError) {
      const data: LogbookEssentialInfo = (logbookEssentialInfo as any).data;
      setValues({
        id: data.id,
        setup_logbook_master_id: data.setup_logbook_master_id,
        name: data.name,
        description: data.description,
        details_json: data.details_json,
        setup_logbook_essential_info_id: data.setup_logbook_essential_info_id,
        sl_no: data.sl_no,
        status: data.status,
        creator_organization_id: data.creator_organization_id,
        updater_organization_id: data.updater_organization_id,
        created_by: data.created_by,
        updated_by: data.updated_by,
        deleted_by: data.deleted_by,
        created_at: data.created_at,
        updated_at: data.updated_at,
        deleted_at: data.deleted_at,
      });
    }
  }, [logbookEssentialInfo, isLogbookEssentialInfoFetchError]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Logbook essential info updated successfully');
      history.push('/setup/logbook-essential-info');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      const errors: any = {};

      if (err?.data) {
        Object.keys(err?.data).forEach((key) => {
          errors[key] = err?.data[key][0];
        });
      }
      setErrors(errors);
    }
  }, [isSuccess, isError]);

  const logbookMasterOptions: SelectOption[] = logbookMasters?.map((l: LogBookSetupMasters) => ({
    label: l.logbook_heading!,
    value: l.id!,
  }))!;  

  let logbookEssentialInfoOptions: SelectOption[] = [    
    ...logbookEssentialInfos?.map((l: LogbookEssentialInfo) => ({
      label: l.name!,
      value: l.id!,
    })),
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Edit Logbook Essential Info'}
          breadcrumbItem={[{ link: '/setup/logbook-essential-info/', name: 'Logbook Essential Info' }]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="card mt-3">
            <h5 className="card-header">
              <i className="fa fa-edit me-2" />
              Edit Logbook Essential Info
            </h5>
            <div className="card-body">
                <Form
                    className="custom-form"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
              >
                <div className="row">                  
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="setup_logbook_master_id">Setup Logbook Master</Label>
                      <InputField
                        type="select"
                        placeholder="Logbook Master Heading"
                        options={logbookMasterOptions}
                        invalid={{ errors, touched }}
                        {...getFieldProps('setup_logbook_master_id')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="name">Name</Label>
                      <InputField
                        type="text"
                        placeholder="Name"
                        invalid={{ errors, touched }}
                        {...getFieldProps('name')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="description">Description</Label>
                      <InputField
                        type="textarea"
                        placeholder="Description"
                        invalid={{ errors, touched }}
                        {...getFieldProps('description')}
                      />
                    </FormGroup>
                  </div>                  

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="setup_logbook_essential_info_id">Logbook Essential Info Name</Label>
                      <InputField
                        type="select"
                        placeholder="Logbook Essential Info"
                        options={logbookEssentialInfoOptions}
                        invalid={{ errors, touched }}
                        {...getFieldProps('setup_logbook_essential_info_id')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="sl_no">Sl No</Label>
                      <InputField
                        type="number"
                        placeholder="Sl No"
                        invalid={{ errors, touched }}
                        {...getFieldProps('sl_no')}
                      />
                    </FormGroup>
                  </div>                  

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup check className="mb-3 mt-4">
                      <Label check>
                        <Input type="checkbox" checked={values.status} {...getFieldProps('status')} />{' '}
                        Status
                      </Label>
                    </FormGroup>
                  </div>
                </div>
                    <Button type="submit" text="Submit" />
                </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
