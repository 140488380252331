import CreateInspectionTeam from "src/pages/InspectionTeam/CreateInspectionTeam";
import InspectionRecomendationList from "src/pages/InspectionTeam/InspectionRecomendationList";
import InspectionTeamList from "src/pages/InspectionTeam/InspectionTeamList";
import RecomendedApprovalList from "src/pages/InspectionTeam/RecomendedApprovalList";
import UpdateInspectionTeam from "src/pages/InspectionTeam/UpdateInspectionTeam";

export const inspectionTeamRoutes = [
    { path: '/inspection-team-list', component: InspectionTeamList },
    { path: '/create-inspection-team', component: CreateInspectionTeam },
    { path: '/update-inspection-team/:id', component: UpdateInspectionTeam },
    { path: '/inspection-recomendation-list', component: InspectionRecomendationList },
    { path: '/recomended-institute-approval-list', component: RecomendedApprovalList },
];
