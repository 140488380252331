import { Modal, Table } from 'reactstrap';
import { PermissionWithChecked } from './AddRole';
import TableRowItem from './TableRowItem';

interface Props {
    role: Role;
    isOpen: boolean;
    onClose: () => void;
    isLoading: boolean;
    permissions: PermissionWithChecked[] | undefined;
}

const DetailsModal = ({
    isOpen,
    onClose,
    role,
    isLoading,
    permissions,
}: Props) => {
    return (
        <Modal isOpen={isOpen} toggle={onClose} size="lg">
            <div className="modal-header">
                <h5 className="modal-title mt-0 text-capitalize ">
                    {role.name} Permissions
                </h5>
                <button
                    type="button"
                    onClick={onClose}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="card-body">
                    <Table className="table table-bordered mb-0">
                        <thead>
                            <tr>
                                <th>Features</th>
                                <th>Capabilities</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading && (
                                <tr>
                                    <td colSpan={2}>Loading...</td>
                                </tr>
                            )}
                            {!isLoading &&
                                permissions &&
                                permissions.map((permission) => (
                                    <TableRowItem
                                        key={permission.name}
                                        permission={permission}
                                    />
                                ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </Modal>
    );
};

export default DetailsModal;
