import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container, Table } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetLogbookTableOfContentsQuery } from 'src/rtk/features/setup/logBookTableOfContentSetup/logBookTableOfContentSetupApi';
import LogbookTableOfContentTableRow from './LogbookTableOfContentTableRow';

export const LogbookTableContents = () => {
  const { data, error, isLoading, isError } = useGetLogbookTableOfContentsQuery();

  const err: CustomError = error as CustomError;
  const logbookTableOfContents = data?.data || [];

  let content = null;

  if (isLoading) {
    content = <div className="mt-3">Loading...</div>;
  }
  if (!isLoading && isError && error) {
    content = <div className="mt-3">{err.message}</div>;
  }
  if (!isLoading && !isError && logbookTableOfContents?.length > 0) {
    content = logbookTableOfContents.map((logbookTableOfContent: any, index: any) => (
      <LogbookTableOfContentTableRow logbookTableOfContent={logbookTableOfContent} index={index} key={logbookTableOfContent.id} />
    ));
  } else {
    content = (
      <tr>
        <td colSpan={11}>
          <div className="text-center text-danger h6">No data available!</div>
        </td>
      </tr>
    );
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Logbook Table of Content List'}
          breadcrumbItem={[{ link: '/setup/logbook-table-of-content/add', name: 'Add Table of Content' }]}
        />
        <Card>
          <CardHeader className="d-flex justify-content-end">
            <Link className="btn btn-sm btn-primary" to="/setup/logbook-table-of-content/add">
              Add Logbook Table Of Content
            </Link>
          </CardHeader>
          <CardBody>
            <div className="table-responsive">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Sl. </th>
                    <th>Setup Logbook Master Heading</th>
                    <th>Details</th>
                    <th>Note</th>
                    <th>Table of Content Details</th>
                    <th>Page No</th>
                    <th>Serial No</th>                    
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
