import { apiSlice } from 'src/rtk/api/apiSlice';
import * as examEligibilityCriteriaUrl from 'src/helpers/url_helper';

export const examEligibilityCriteriaApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['ExamEligibilityCriteria'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getExamEligibilityCriterias: builder.query<
        APIResponse<ExamEligibilityCriteria[]>,
        void
      >({
        query: () => ({
          method: 'GET',
          url: `${examEligibilityCriteriaUrl.GET_EXAM_ELIGIBILITY_CRITERIAS}`,
        }),
        providesTags: ['ExamEligibilityCriteria'],
      }),

      getExamEligibilityCriteria: builder.query<
        ExamEligibilityCriteria,
        string
      >({
        query: (id: string) => ({
          method: 'GET',
          url: `${examEligibilityCriteriaUrl.GET_EXAM_ELIGIBILITY_CRITERIA}/${id}`,
        }),
        providesTags: ['ExamEligibilityCriteria'],
      }),

      createExamEligibilityCriteria: builder.mutation<
        APIResponse<ExamEligibilityCriteria>,
        ExamEligibilityCriteria
      >({
        query: (data) => ({
          url: `${examEligibilityCriteriaUrl.CREATE_EXAM_ELIGIBILITY_CRITERIA}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['ExamEligibilityCriteria'],
      }),

      updateExamEligibilityCriteria: builder.mutation<
        APIResponse<ExamEligibilityCriteria>,
        string
      >({
        query: (url) => ({
          url: `${examEligibilityCriteriaUrl.UPDATE_EXAM_ELIGIBILITY_CRITERIA}/${url}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['ExamEligibilityCriteria'],
      }),
      deleteExamEligibilityCriteria: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${examEligibilityCriteriaUrl.DELETE_EXAM_ELIGIBILITY_CRITERIA}/${id}`,
        }),
        invalidatesTags: ['ExamEligibilityCriteria'],
      }),
    }),
  });

export const {
  useGetExamEligibilityCriteriasQuery,
  useGetExamEligibilityCriteriaQuery,
  useCreateExamEligibilityCriteriaMutation,
  useUpdateExamEligibilityCriteriaMutation,
  useDeleteExamEligibilityCriteriaMutation,
} = examEligibilityCriteriaApi;
