import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { setFromErrors } from 'src/helpers';
import { useAppDispatch } from 'src/rtk/app/hook';
import { useChangePasswordMutation } from 'src/rtk/features/auth/authApi';
import { userLogout } from 'src/rtk/features/auth/authSlice';
import * as Yup from 'yup';

export const PasswordChangeForm = () => {
  const [changePassword, { isSuccess, isError, error }] =
    useChangePasswordMutation();
  const err: CustomError = error as CustomError;
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [showPassword, setShowPassword] = useState(true);
  const [newPassword, setNewPassword] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState(true);

  const { errors, touched, handleSubmit, getFieldProps, resetForm, setErrors } =
    useFormik({
      initialValues: {
        old_password: '',
        new_password: '',
        confirm_password: '',
      },
      validationSchema: Yup.object({
        old_password: Yup.string().required('Current Password is Required'),
        new_password: Yup.string()
          .min(6, 'Password must be 6 characters long')
          .required('New Password Required')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
            'Must contain at least one uppercase, one lowercase, one numeric and one special character. Example: Abc@123',
          ),
        confirm_password: Yup.string()
          .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
          .required('Password confirmation is Required'),
      }),
      onSubmit: (values: any) => {
        changePassword(values);
      },
    });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Password Changed successfully');
      dispatch(userLogout());
      localStorage.removeItem('bcps-auth-token-and-user');
      window.location.href = '/login';
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError, dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BetterBreadcrumb title="Change Password" />

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                  return false;
                }}
              >
                <Row className="justify-content-center">
                  <FormGroup className="mb-3 col-md-4 col-sm-12">
                    <Label for="old_password" className='form-label required-field'>Current Password</Label>
                    <div className="input-group password-input">
                      <InputField
                        type={showPassword ? 'password' : 'text'}
                        placeholder="Enter Your Current Password"
                        invalid={{ errors, touched }}
                        {...getFieldProps('old_password')}
                      />
                      <Button
                        color="light"
                        size="sm"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <i
                          className={`${showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'
                            }`}
                        />
                      </Button>
                    </div>
                  </FormGroup>
                </Row>
                <Row className="justify-content-center">
                  <FormGroup className="mb-3 col-md-4 col-sm-12">
                    <Label for="new_password" className='form-label required-field'>New Password</Label>
                    <div className="input-group password-input">
                      <InputField
                        type={newPassword ? 'password' : 'text'}
                        placeholder="Enter Your New Password"
                        invalid={{ errors, touched }}
                        {...getFieldProps('new_password')}
                      />
                      <Button
                        color="light"
                        size="sm"
                        onClick={() => setNewPassword(!newPassword)}
                      >
                        <i
                          className={`${newPassword ? 'fa fa-eye' : 'fa fa-eye-slash'
                            }`}
                        />
                      </Button>
                    </div>
                  </FormGroup>
                </Row>
                <Row className="justify-content-center">
                  <FormGroup className="mb-4 col-md-4 col-sm-12">
                    <Label for="confirm_password" className='form-label required-field'>Confirm Password</Label>
                    <div className="input-group password-input">
                      <InputField
                        type={confirmPassword ? 'password' : 'text'}
                        placeholder="Enter New Password Again to Confirm"
                        invalid={{ errors, touched }}
                        {...getFieldProps('confirm_password')}
                      />
                      <Button
                        color="light"
                        size="sm"
                        onClick={() => setConfirmPassword(!confirmPassword)}
                      >
                        <i
                          className={`${confirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'
                            }`}
                        />
                      </Button>
                    </div>
                  </FormGroup>

                  <div className="text-center">
                    <Button color='primary' type="submit">Change Password</Button>
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};
