import { useFormik } from 'formik';
import React from 'react';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from 'reactstrap';

const FacultyList = () => {
  const facultySearchForm = useFormik({
    initialValues: {
      institute: '',
      fromDate: Date.now().toString(),
      toDate: Date.now().toString(),
      searchTitle: '',
    },

    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <h6>Faculty List</h6>

        <Col sm={12} md={12} lg={12}>
          <Form
            onSubmit={facultySearchForm.handleSubmit}
            encType="multipart/form-data"
          >
            <Row className="d-flex">
              <Col className="border-right p-2 mr-2" sm={3} md={3} lg={3}>
                <FormGroup className="mb-5">
                  <div className="mb-3">
                    <Label className="form-label">Institute</Label>
                    <Input
                      name="institute"
                      className="form-control"
                      placeholder="Select"
                      type="select"
                      onChange={facultySearchForm.handleChange}
                      onBlur={facultySearchForm.handleBlur}
                      value={facultySearchForm.values.institute || ''}
                      invalid={
                        facultySearchForm.touched.institute &&
                        !!facultySearchForm.errors.institute
                      }
                    >
                      <option value="0">Select</option>
                    </Input>
                    {facultySearchForm.touched.institute &&
                    facultySearchForm.errors.institute ? (
                      <FormFeedback type="invalid">
                        {facultySearchForm.errors.institute}
                      </FormFeedback>
                    ) : null}
                  </div>
                </FormGroup>
              </Col>

              <Col className="border-right p-2 mr-2" sm={3} md={3} lg={3}>
                <FormGroup className="mb-5">
                  <div className="mb-3">
                    <Label className="form-label">From</Label>
                    <Input
                      name="fromDate"
                      className="form-control"
                      placeholder=""
                      type="date"
                      onChange={facultySearchForm.handleChange}
                      onBlur={facultySearchForm.handleBlur}
                      value={facultySearchForm.values.fromDate}
                    />
                  </div>
                </FormGroup>
              </Col>

              <Col className="border-right p-2 mr-2" sm={3} md={3} lg={3}>
                <FormGroup className="mb-5">
                  <div className="mb-3">
                    <Label className="form-label">To</Label>
                    <Input
                      name="toDate"
                      className="form-control"
                      placeholder=""
                      type="date"
                      onChange={facultySearchForm.handleChange}
                      onBlur={facultySearchForm.handleBlur}
                      value={facultySearchForm.values.toDate}
                    />
                  </div>
                </FormGroup>
              </Col>

              <Col className="border-right p-2 mr-2" sm={3} md={3} lg={3}>
                <FormGroup className="mb-5">
                  <div className="mb-3">
                    <Label className="form-label">Search</Label>
                    <Input
                      name="searchTitle"
                      className="form-control"
                      placeholder=""
                      type="search"
                      onChange={facultySearchForm.handleChange}
                      onBlur={facultySearchForm.handleBlur}
                      value={facultySearchForm.values.searchTitle}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>

        <Col sm={12} md={12} lg={12}>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <Table bordered>
                <thead>
                  <tr>
                    <th>SL</th>
                    <th>Date</th>
                    <th>Name</th>
                    <th>BMDC Number</th>
                    <th>Qualification</th>
                    <th>Workshop Attended</th>
                    <th>Work Experience</th>
                    <th>Institute</th>
                    <th>Decision</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>14 December, 2022</td>
                    <td>Bazlur Rashid</td>
                    <td>AB0123</td>
                    <td>M.B.B.S F.C.P.S</td>
                    <td>Child Health & Pediatrics</td>
                    <td>3 years</td>
                    <td>Dhaka Medical College</td>
                    <td>
                      <ul className="list-unstyled">
                        <li>
                          <a href="#">Approve</a>
                        </li>
                        <li>
                          <a href="#">Reject</a>
                        </li>
                      </ul>
                    </td>
                    <td
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <Button className="me-4 btn-dark">
                        <i className="fa-solid fa-eye"></i>
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>31 January, 2023</td>
                    <td>Maksudur Rahman</td>
                    <td>XY0123</td>
                    <td>M.B.B.S F.C.P.S</td>
                    <td>Covid 19 Pandemic & mRNA vaccines</td>
                    <td>5 years</td>
                    <td>Salimullah Medical College</td>
                    <td>
                      <ul className="list-unstyled">
                        <li>
                          <a href="#">Approve</a>
                        </li>
                        <li>
                          <a href="#">Reject</a>
                        </li>
                      </ul>
                    </td>
                    <td
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <Button className="me-4 btn-dark">
                        <i className="fa-solid fa-eye"></i>
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>

        <Row className="justify-content-between">
          <Col sm={12} md={6} lg={6}>
            Total Applications:
          </Col>
          <Col className="col-auto">
            <Pagination>
              <PaginationItem>
                <PaginationLink first href="#" />
              </PaginationItem>
              <PaginationItem>
                <PaginationLink href="#" previous />
              </PaginationItem>
              <PaginationItem>
                <PaginationLink href="#">1</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink href="#">2</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink href="#">3</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink href="#">4</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink href="#">5</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink href="#" next />
              </PaginationItem>
              <PaginationItem>
                <PaginationLink href="#" last />
              </PaginationItem>
            </Pagination>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default FacultyList;
