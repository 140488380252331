import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Card, CardBody, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { InputField } from 'src/components';
import { config } from 'src/config';
import { setFromErrors } from 'src/helpers';
import { useCreateDOPSEntryByTraineeMutation, useCreateMiniCexEntryByTraineeMutation } from 'src/rtk/features/miniCexCBD/miniCexAndCBDApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

interface Props {
    registrationNo: string;
    supervisorId: string;
    sessionId: string;
    dopsInfo?: any;
}

const PatientInformationUpdateForDOPS = (props: Props) => {
    const { registrationNo, supervisorId, sessionId, dopsInfo } = props;
    const [photo, setPhoto] = useState('');

    const [createDOPSEntryByTrainee, { isSuccess, isError, error, isLoading: isCreateLoading }] = useCreateDOPSEntryByTraineeMutation();
    const err: CustomError = error as CustomError;

    const initialValues = {
        registration_id: '',
        supervisor_id: '',
        logbook_entry_session_id: '',
        date_of_assessment: new Date(Date.now()).toISOString().slice(0, 10),
        complexity_level: '',
        diagnosis_of_the_case: '',
        competency_setting: '',
        patient_address: '',
        patient_name: '',
        patient_reg_no: '',
        patient_age: '',
        patient_gender: '',
        name_of_procedure: '',
        case_description: '',
        case_type: '',
        file_upload: '',
    }

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        resetForm,
        setValues,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({
            // questions: Yup.mixed().required('required'),
            // date_of_assessment: Yup.date().max(new Date(Date.now()), 'Payment date can\'t be a future date').required(),
        }),

        onSubmit: (values: any) => {
            const formData = new FormData();

            Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
            });
            createDOPSEntryByTrainee(formData);
        },
    });


    useEffect(() => {
        if (dopsInfo) {
            setPhoto(`${config?.APP_URL}${dopsInfo?.file_upload}`);

            setValues({
                registration_id: registrationNo,
                supervisor_id: supervisorId,
                logbook_entry_session_id: sessionId,
                date_of_assessment: new Date(Date.now()).toISOString().slice(0, 10),
                complexity_level: dopsInfo?.complexity_level,
                diagnosis_of_the_case: dopsInfo?.diagnosis_of_the_case,
                competency_setting: dopsInfo?.competency_setting,
                patient_address: dopsInfo?.patient_address,
                patient_name: dopsInfo?.patient_name,
                patient_reg_no: dopsInfo?.patient_reg_no,
                patient_age: dopsInfo?.patient_age,
                patient_gender: dopsInfo?.patient_gender,
                case_description: dopsInfo?.case_description,
                name_of_procedure: dopsInfo?.name_of_procedure,
                case_type: dopsInfo?.case_type,
                file_upload: '',
            });
        }

    }, [dopsInfo])

    useEffect(() => {
        if (isSuccess) {
            toast.success('Updated Successfully!');
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);



    return (
        <>
            {isCreateLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Card>
                <CardBody>
                    <Form
                        className="custom-form"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                        onReset={(e) => {
                            // e.preventDefault();
                            resetForm();
                        }}
                    >
                        <Row>
                            <h5 className='block-heading'>3. Patient Information</h5>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="patient_name" className='form-label'>Patient Name</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Patient Name"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('patient_name')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="patient_address" className='form-label'>Address of the Patient</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Address of the Patient"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('patient_address')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="patient_reg_no" className='form-label'>Reg. No. of the Patient</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Reg. No. of the Patient"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('patient_reg_no')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="patient_age" className='form-label'>Age</Label>
                                    <InputField
                                        type="number"
                                        placeholder="Age"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('patient_age')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label className='form-label me-1'>Gender : </Label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="patient_gender"
                                            value="Male"
                                            onChange={(e) => {
                                                handleChange(e);
                                                setFieldValue('patient_gender', 'Male');
                                            }}
                                            checked={values.patient_gender === 'Male'}
                                            id="gender_male"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="gender_male"
                                        >
                                            Male
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="patient_gender"
                                            value="Female"
                                            onChange={(e) => {
                                                handleChange(e);
                                                setFieldValue('patient_gender', 'Female');
                                            }}
                                            checked={values.patient_gender === 'Female'}
                                            id="gender_female"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="gender_female"
                                        >
                                            Female
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="patient_gender"
                                            value="Others"
                                            onChange={(e) => {
                                                handleChange(e);
                                                setFieldValue('patient_gender', 'Others');
                                            }}
                                            checked={values.patient_gender === 'Others'}
                                            id="gender_others"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="gender_others"
                                        >
                                            Others
                                        </label>
                                    </div>
                                    {errors.patient_gender && touched.patient_gender ? (
                                        <div className="text-danger error-size">
                                            {errors.patient_gender as string}
                                        </div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col sm={9}>
                                <FormGroup>
                                    <Label for="diagnosis_of_the_case" className='form-label'>Diagnosis/Problem</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Diagnosis/Problem"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('diagnosis_of_the_case')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label className='form-label me-1'>Case type : </Label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="case_type"
                                            value="New Case"
                                            onChange={(e) => {
                                                setFieldValue("case_type", 'New Case');
                                            }}
                                            checked={values.case_type === 'New Case'}
                                            id="new_case"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="new_case"
                                        >
                                            New Case
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="case_type"
                                            value="Follow Up"
                                            onChange={(e) => {
                                                setFieldValue("case_type", 'Follow Up');
                                            }}
                                            checked={values.case_type === 'Follow Up'}
                                            id="follow_up"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="follow_up"
                                        >
                                            Follow Up
                                        </label>
                                    </div>
                                    {errors.case_type && touched.case_type ? (
                                        <div className="text-danger error-size">
                                            {errors.case_type as string}
                                        </div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label className='form-label me-1'>Complexity : </Label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="complexity_level"
                                            value="Low"
                                            onChange={(e) => {
                                                setFieldValue("complexity_level", 'Low');
                                            }}
                                            checked={values.complexity_level === 'Low'}
                                            id="complexity_low"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="complexity_low"
                                        >
                                            Low
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="complexity_level"
                                            value="Medium"
                                            onChange={(e) => {
                                                setFieldValue("complexity_level", 'Medium');
                                            }}
                                            checked={values.complexity_level === 'Medium'}
                                            id="complexity_medium"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="complexity_medium"
                                        >
                                            Medium
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="complexity_level"
                                            value="High"
                                            onChange={(e) => {
                                                setFieldValue("complexity_level", 'High');
                                            }}
                                            checked={values.complexity_level === 'High'}
                                            id="complexity_high"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="complexity_high"
                                        >
                                            High
                                        </label>
                                    </div>
                                    {errors.complexity_level && touched.complexity_level ? (
                                        <div className="text-danger error-size">
                                            {errors.complexity_level as string}
                                        </div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={5}>
                                <FormGroup>
                                    <Label className='form-label me-1'>Setting : </Label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="competency_setting"
                                            value="Ward"
                                            onChange={(e) => {
                                                setFieldValue("competency_setting", 'Ward');
                                            }}
                                            checked={values.competency_setting === 'Ward'}
                                            id="ward"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="ward"
                                        >
                                            Ward
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="competency_setting"
                                            value="Cabin Icu"
                                            onChange={(e) => {
                                                setFieldValue("competency_setting", 'Cabin Icu');
                                            }}
                                            checked={values.competency_setting === 'Cabin Icu'}
                                            id="cabin_icu"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="cabin_icu"
                                        >
                                            Cabin ICU
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="competency_setting"
                                            value="OPD"
                                            onChange={(e) => {
                                                setFieldValue("competency_setting", 'OPD');
                                            }}
                                            checked={values.competency_setting === 'OPD'}
                                            id="opd"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="opd"
                                        >
                                            OPD
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="competency_setting"
                                            value="Emergency"
                                            onChange={(e) => {
                                                setFieldValue("competency_setting", 'Emergency');
                                            }}
                                            checked={values.competency_setting === 'Emergency'}
                                            id="emergency"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="emergency"
                                        >
                                            Emergency
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="competency_setting"
                                            value="Others"
                                            onChange={(e) => {
                                                setFieldValue("competency_setting", 'Others');
                                            }}
                                            checked={values.competency_setting === 'Others'}
                                            id="others"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="others"
                                        >
                                            Others
                                        </label>
                                    </div>
                                    {errors.competency_setting && touched.competency_setting ? (
                                        <div className="text-danger error-size">
                                            {errors.competency_setting as string}
                                        </div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            {values.competency_setting === 'Others' &&
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label className='form-label'>Please Specify</Label>
                                        <InputField
                                            type="text"
                                            placeholder="Please Specify"
                                            invalid={{ errors, touched }}
                                            name='competency_setting'
                                            onChange={(e) => {
                                                setFieldValue("competency_setting", e.target.value);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>}
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <h5 className='block-heading'>4. Name of Procedure</h5>
                                <InputField
                                    type="textarea"
                                    placeholder="Name of Procedure"
                                    invalid={{ errors, touched }}
                                    {...getFieldProps('name_of_procedure')}
                                />
                            </Col>
                            <Col sm={6} className='mt-2'>
                                <h5 className='block-heading'>5. Short Description of Procedure/Surgery: (subject and procedure specific)</h5>
                                <InputField
                                    type="textarea"
                                    placeholder="Short Description of Procedure/Surgery"
                                    invalid={{ errors, touched }}
                                    {...getFieldProps('case_description')}
                                />
                            </Col>
                            <Col sm={6} className='mt-2'>
                                <Label>Upload</Label>
                                <a
                                    className="me-2 p-0 center"
                                    href={photo}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                </a>
                                <input
                                    name="file_upload"
                                    type="file"
                                    className="form-control"
                                    placeholder="Upload"
                                    onChange={(e: any) => {
                                        setFieldValue('file_upload', e.target.files[0]);
                                    }}
                                ></input>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3 text-end mt-3">
                                    <button
                                        type="submit"
                                        className="btn btn-success"
                                    >
                                        Update
                                    </button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </>
    );
};

export default PatientInformationUpdateForDOPS;
