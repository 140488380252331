import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { Button, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import { useAssignConsultantMutation } from 'src/rtk/features/researchClinicSetup/researchClinicSetupApi';
import { useGetUsersByPermissionQuery } from 'src/rtk/features/user/userSelectByPermissionApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

interface Props {
  info: any;
}
interface Props {
  isOpen: boolean;
  toggle: any;
  info: any;
}
const initialValues = {
  meeting_building: '',
  consultant_id: '',
  meeting_date: '',
};

const ResourceAppointmentModal = (props: Props) => {

  const { isOpen, toggle, info } = props;

  const [selectedConsultant, setSelectedConsultant] = useState<any>('');
  const [fieldStatus, setFieldStatus] = useState<boolean>(false);

  const {
    data: users,
    isLoading,
  } = useGetUsersByPermissionQuery('action-research-clinic-query-solution');

  const allConsultant: SelectOption[] = users?.data?.map((item) => ({
    value: item?.id!,
    label: item?.name,
  })) || [];

  const [assignConsultant, { isSuccess, isError, error, isLoading: isAssignLoading }] = useAssignConsultantMutation();

  const err = error as CustomError;

  const {
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    handleChange,
    setValues,
    setErrors,
    getFieldProps,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      meeting_building: Yup.string().required('Enter Venue'),
      consultant_id: Yup.string().required('Select Resource Person'),
      meeting_date: Yup.string().required('Enter Appointment Date'),
    }),
    onSubmit: (values) => {
      console.log(values);
      assignConsultant({ data: values, id: info?.id });
    },
  });


  useEffect(() => {
    if (isSuccess) {
      toast.success('Consultant Assigned Successfully!');
      toggle();
    }
    if (isError) {
      toast.error('Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (selectedConsultant !== '') {
      setFieldValue('consultant_id', selectedConsultant?.value);
    }
  }, [selectedConsultant]);

  useEffect(() => {
    if (info && info?.meeting_building !== null && info?.consultant_id !== null && info?.meeting_date !== null) {
      setValues({
        meeting_building: info?.meeting_building,
        consultant_id: info?.consultant_id,
        meeting_date: info?.meeting_date,
      });
      setSelectedConsultant({
        value: info?.consultant_id,
        label: info?.consultant?.name
      });
      setFieldStatus(true);
    }
  }, [info]);

  return (
    <Modal
      isOpen={isOpen}
      backdrop={'static'}
      toggle={toggle}
      size="lg"
    >
      {isAssignLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <ModalHeader toggle={toggle}>Assign Consultant</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <ModalBody>
          <div>
            <h6 className="block-heading">Trainee Information</h6>
            <div className="row">
              <div className="col-md-12">
                <table className="table table-bordered table-striped table-sm">
                  <tbody>
                    <tr>
                      <td className="col-md-6 col-lg-6">
                        <strong>Trainee Name: </strong>
                        {info?.registration?.personal_info?.full_name}
                      </td>
                      <td className="col-md-6 col-lg-6">
                        <strong>BMDC Reg. No: </strong>
                        {info?.registration?.bmdc_registration_no}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Date of Birth: </strong>
                        {info?.registration?.personal_info?.date_of_birth ?? 'N/A'}
                      </td>
                      <td>
                        <strong>NID: </strong>
                        {info?.registration?.personal_info?.national_id ?? 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Mobile Number: </strong>
                        {info?.registration?.personal_info?.mobile ?? 'N/A'}
                      </td>
                      <td>
                        <strong>Email: </strong>
                        {info?.registration?.personal_info?.email ?? 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Alternative Number: </strong>
                        {info?.registration?.personal_info?.telephone ?? 'N/A'}
                      </td>
                      <td>
                        <strong>Subject: </strong>
                        {info?.registration?.subject?.subject_name ?? 'N/A'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* <h6 className="block-heading">Thesis Title Details</h6>
            <div className="row">
              <div className='col-md-12'>
                <table className="table table-bordered table-striped table-sm">
                  <tbody>
                    <tr>
                      <td className="col-md-6 col-lg-6">
                        <strong>Category: </strong>
                        {info?.category}
                      </td>
                      <td className="col-md-6 col-lg-6">
                        <strong>Title: </strong>
                        {info?.title_of_thesis}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Place Of Study: </strong>
                        {info?.place_of_study ?? 'N/A'}
                      </td>
                      <td>
                        <strong>Study Period: </strong>
                        {info?.study_period + ' ' + info?.study_period_unit ?? 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Guide: </strong>
                        {info?.guide_name ?? 'N/A'}
                      </td>
                      <td>
                        <strong>Guide Approval Note: </strong>
                        {info?.guide_approval_note ?? 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Guide Approval Status: </strong>
                        {info?.guide_approval_status ?? 'N/A'}
                      </td>
                      <td>

                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Co-Guide </strong>
                      </td>
                      <td>
                        {info?.co_guide_1_name &&
                          <div className='row card mx-1 my-1'>
                            <div className="col">
                              <strong>Name:</strong>  {info?.co_guide_1_name} <br />
                              <strong>Note:</strong>  {info?.co_guide1_approval_note} <br />
                              <strong>Approval Status:</strong>  {info?.co_guide1_approval_status}
                            </div>
                          </div>
                        }
                        {info?.co_guide_2_name &&
                          <div className='row card mx-1 my-1'>
                            <div className="col">
                              <strong>Name:</strong> {info?.co_guide_2_name} <br />
                              <strong>Note:</strong> {info?.co_guide2_approval_note} <br />
                              <strong>Approval Status:</strong> {info?.co_guide2_approval_status}
                            </div>
                          </div>
                        }
                        {info?.co_guide_3_name &&
                          <div className='row card mx-1 my-1'>
                            <div className="col">
                              <strong>Name:</strong> {info?.co_guide_3_name} <br />
                              <strong>Note:</strong> {info?.co_guide3_approval_note} <br />
                              <strong>Approval Status:</strong> {info?.co_guide3_approval_status}
                            </div>
                          </div>
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}
            <h6 className="block-heading">Inquiry</h6>
            <div className="row">
              <div className="col-md-12">
                <table className="table table-bordered table-striped table-sm">
                  <tbody>
                    <tr>
                      <td>
                        <strong>Inquiry Type: </strong>{info?.inquiry_type?.inquiry_type}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Inquiry: </strong>{info?.query}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <h6 className="block-heading">Assign Consultant</h6>
            <div className='row'>
              <div className='col-md-4 col-sm-6'>
                <Label for='venue' className="form-label required-field">Venue</Label>
                <InputField
                  type='text'
                  placeholder='Enter Venue Name'
                  {...getFieldProps('meeting_building')}
                  invalid={{ errors, touched }}
                  readOnly={fieldStatus}
                />
              </div>
              <div className='col-md-4 col-sm-6'>
                <Label for='consultant_id' className="form-label required-field">Resource Person</Label>
                <Select
                  isClearable={true}
                  isSearchable={true}
                  value={selectedConsultant}
                  onChange={setSelectedConsultant}
                  options={allConsultant}
                  placeholder='Select Consultant'
                  name="consultant_id"
                  isDisabled={fieldStatus}
                />
                {errors.consultant_id && touched.consultant_id ? (
                  <div className="text-danger error-size">
                    {errors.consultant_id}
                  </div>
                ) : null}
              </div>
              <div className='col-md-4 col-sm-6'>
                <Label for='meeting_date' className="form-label required-field">Appointment Date</Label>
                <InputField
                  type='date'
                  readOnly={fieldStatus}
                  {...getFieldProps('meeting_date')}
                  invalid={{ errors, touched }}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex gap-2 ">
            <button
              type="button"
              className="btn btn-success"
              disabled={!fieldStatus}
              onClick={() => setFieldStatus(false)}
            >
              Edit
            </button>
            <button
              type="submit"
              className="btn btn-primary"
            >
              Submit
            </button>

            <Button color="danger" onClick={toggle}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ResourceAppointmentModal;
