
export const setFromErrors = (errors: any, setErrors: any) => {
  const _errs: any = {};

  if (errors) {
    Object.keys(errors).forEach((key) => {
      _errs[key] = errors[key][0];
    });
  }
  setErrors(_errs);
};

export const putUrlString = (id: any, values: any) => {
  const urlQuery = Object.keys(values).map((key) => {
    // handle string and array
    if (typeof values[key] === 'string' || typeof values[key] === 'number') {
      return `${key}=${values[key]}&`;
    }
    if (typeof values[key] === 'boolean') {
      return `${key}=${values[key] ? 1 : 0}&`;
    }
    if (Array.isArray(values[key])) {
      return `${key}[]=${values[key].join('&' + key + '[]=')}&`;
    }
  });
  const url = urlQuery.join('');
  return `${id}?${url}`;
};

export const flattenObject = (ob: any) => {
  var toReturn: any = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if ((typeof ob[i]) == 'object' && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}

export const getSelectFormatedData = (data: any, valueKey = '', labelKey = '', labelKey2 = '', listFn = (row: any) => { return row }) => {
  let dataType = typeof data;
  if (typeof listFn === 'function') {
    data = listFn(data)
  }

  if (dataType.toLowerCase() === 'object' && data.length > 0) {
    return data.map((item: any) => {
      let itemType = typeof item;
      if (itemType.toLowerCase() === 'object' && item.length === undefined) {
        item = flattenObject(item);
        let label = '';
        label += item[labelKey] ? item[labelKey] : '';
        if (labelKey2) {
          label += item[labelKey2] ? " (" + item[labelKey2] + ")" : '';
        }
        if (item[valueKey] && label) {
          return { value: item[valueKey], label: label }
        } else {
          return false;
        }
      } else {
        return { value: item, label: item }
      }

    }).filter(Boolean);
  } else if (dataType.toLowerCase() !== 'object') {
    return { value: data, label: data }
  }
}

export const currency = (price: number = 0) => {
  return new Intl.NumberFormat().format(price)
};

export const getTime = (createdAt: any) => {
  const createdAtDate: any = new Date(createdAt);
  const currentTime: any = new Date();

  const differenceInMilliseconds = currentTime - createdAtDate;
  const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));

  const years = Math.floor(differenceInMinutes / (60 * 24 * 365));
  const months = Math.floor((differenceInMinutes % (60 * 24 * 365)) / (60 * 24 * 30));
  const days = Math.floor((differenceInMinutes % (60 * 24 * 30)) / (60 * 24));
  const hours = Math.floor((differenceInMinutes % (60 * 24)) / 60);
  const remainingMinutes = differenceInMinutes % 60;

  if (years > 0) {
      return `${years} year${years !== 1 ? 's' : ''} ${months} month${months !== 1 ? 's' : ''} ${days} day${days !== 1 ? 's' : ''} ${hours} hour${hours !== 1 ? 's' : ''} ${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}`;
  } else if (months > 0) {
      return `${months} month${months !== 1 ? 's' : ''} ${days} day${days !== 1 ? 's' : ''} ${hours} hour${hours !== 1 ? 's' : ''} ${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}`;
  } else if (days > 0) {
      return `${days} day${days !== 1 ? 's' : ''} ${hours} hour${hours !== 1 ? 's' : ''} ${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}`;
  } else if (hours > 0) {
      return `${hours} hour${hours !== 1 ? 's' : ''} ${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}`;
  } else {
      return `${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}`;
  }
}
