import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import { config } from 'src/config';
import InfoViewModal from './InfoViewModal';

interface Props {
  regInfo: RegInfo;
  index: number;
  page: any;
  paginate_per_page: number;
}

const RegTableRow = ({ regInfo, index, page, paginate_per_page }: Props) => {
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);

  const deleteHandler = (id: string) => {
    const confirm = window.confirm('Are you sure you want to delete?');
    if (confirm) console.log('DONE');
  };

  return (
    <tr key={regInfo.registration_no}>
      <th scope="row">{((page - 1) * paginate_per_page + index) + 1}</th>
      <td>{regInfo.bmdc_registration_no ?? 'N/A'}</td>
      <td>{regInfo.personal_info?.full_name}</td>
      <td>{regInfo.personal_info?.email ?? 'N/A'}</td>
      <td>{regInfo.personal_info?.mobile ?? 'N/A'}</td>
      <td>{regInfo.subject?.subject_name ?? 'N/A'}</td>
      <td>{regInfo.bmdc_registration_validity ?? 'N/A'}</td>
      <td>{regInfo.registration_type ?? 'N/A'}</td>
      <td>
        {regInfo.bmdc_registration_no_verified === 'Approve' && (
          <>
            <div className="badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium">
              {'Approved'}
              {/* {regInfo.bmdc_registration_no_verified ?? 'N/A'} */}
            </div>
          </>
        )}

        {regInfo.bmdc_registration_no_verified !== 'Approve' && (
          <>
            <div className="badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium">
              {regInfo.bmdc_registration_no_verified ?? 'N/A'}
            </div>
          </>
        )}
      </td>

      <td>
        {/* {regInfo.bmdc_certificate_source_verified === 'Approve' && (
          <>
            <div className="badge rounded-pill d-block mb-1  badge-soft-success font-size-12 fw-medium">
              Certificate {regInfo.bmdc_certificate_source_verified ?? 'N/A'}
            </div>
          </>
        )}

        {regInfo.bmdc_certificate_source_verified !== 'Approve' && (
          <>
            <div className="badge rounded-pill d-block mb-1  badge-soft-danger font-size-12 fw-medium">
              Certificate{' '}
              {regInfo.bmdc_certificate_source_verified ?? 'Pending'}
            </div>
          </>
        )} */}

        {regInfo.profile_approval_status === 'Approve' && (
          <>
            <div className="badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium">
              {'Approved'}
              {/* {regInfo.profile_approval_status ?? 'N/A'} */}
            </div>
          </>
        )}

        {regInfo.profile_approval_status === 'Reject' && (
          <>
            <div className="badge rounded-pill d-inline-block  badge-rejected font-size-12 fw-medium">
              {/* {regInfo.profile_approval_status ?? 'Pending'} */}
              {'Rejected'}
            </div>
          </>
        )}

        {regInfo.profile_approval_status === 'Submit' && (
          <>
            <div className="badge rounded-pill d-inline-block  badge-applied font-size-12 fw-medium">
              {/* {regInfo.profile_approval_status ?? 'Pending'} */}
              {'Submitted'}
            </div>
          </>
        )}

        {regInfo.profile_approval_status === 'Correction' && (
          <>
            <div className="badge rounded-pill d-inline-block  badge-correction font-size-12 fw-medium">
              {regInfo.profile_approval_status}
            </div>
          </>
        )}

        {regInfo.profile_approval_status !== 'Approve' &&
          regInfo.profile_approval_status !== 'Reject' &&
          regInfo.profile_approval_status !== 'Correction' &&
          regInfo.profile_approval_status !== 'Submit' && (
            <>
              <div className="badge rounded-pill d-inline-block  badge-draft font-size-12 fw-medium">
                {regInfo.profile_approval_status ?? 'Pending'}
              </div>
            </>
          )}
      </td>
      <td>
        {regInfo.bmdc_certificate ? (
          <a
            href={config.APP_URL + regInfo.bmdc_certificate}
            rel="noreferrer"
            target="_blank"
            className="btn btn-link btn-sm "
          >
            Download
          </a>
        ) : (
          <span className=" ">N/A</span>
        )}
      </td>
      <td>
        <Button
          size="sm"
          text="View"
          color="primary"
          onClick={() => setModalShow(true)}
        />

        {modalShow ? (
          <InfoViewModal
            isOpen={modalShow}
            toggle={() => setModalShow(!modalShow)}
            id={regInfo?.registration_no}
          />
        ) : null}
      </td>
    </tr>
  );
};

export default RegTableRow;
