import { useFormik } from 'formik';
import { useState } from 'react';
import { Card, CardBody, Col, Container, Form, FormGroup, Label, Row, Table } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetDeviceRegInfosByPaginateQuery } from 'src/rtk/features/device-registration/deviceRegistrationApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import DeviceRegPaginate from './DeviceRegPaginate';
import DeviceRegTableRowForTrainee from './DeviceRegTableRowForTrainee';

interface SearchFields {
  general_search_term?: string;
  bmdc_reg_no?: string;
}

const initialValues: SearchFields = {
  general_search_term: '',
  bmdc_reg_no: '',
};

const DeviceRegList = () => {

  const [showAddForm, setShowAddForm] = useState<boolean>(true);
  const [deviceRegId, setDeviceRegId] = useState('');
  const [page, setPage] = useState<number | string>('1');
  const [paginate_per_page, set_paginate_per_page] = useState(20);
  const [search, setSearch] = useState('');
  const { data: deviceRegInfos, error, isLoading, isError } = useGetDeviceRegInfosByPaginateQuery({
    page,
    paginate_per_page,
    search,
  });

  const deviceInfos: DeviceInfo[] = deviceRegInfos?.data?.data || [];
  const err: CustomError = error as CustomError;

  const editHandler = (id: string) => {
    setDeviceRegId(id);
    setShowAddForm(false);
    window.scrollTo(0, 0);
  };

  const deviceTypeOptions: SelectOption[] =
    useGetLookupDataQuery('device_type')
      .data?.data[0]?.childs.map((item) => ({
        value: item.name,
        label: item.name,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) || [];

  // what to render
  let content = null;

  if (isLoading) {
    content = (
      <tr>
        <td colSpan={12} className="text-center">
          Loading...
        </td>
      </tr>
    );
  }
  if (!isLoading && isError && error) {
    content = (
      <tr>
        <td colSpan={12} className="text-center">
          {err.message}
        </td>
      </tr>
    );
  }
  if (!isLoading && !isError && deviceInfos?.length > 0) {

    content = deviceInfos.map((deviceInfo, idx) => (
      <DeviceRegTableRowForTrainee deviceRegInfo={deviceInfo} index={idx} editHandler={editHandler} key={deviceInfo.id!} />
    ));
  }

  const { handleSubmit, getFieldProps, values, touched, errors, resetForm, setErrors } =
    useFormik({
      initialValues,

      onSubmit: (values) => {

        let s: string = '';

        if (values.general_search_term !== '') {
          s = s + `&search=${values.general_search_term}`;
        }

        if (values.bmdc_reg_no !== '') {
          s = s + `&bmdc_reg_no=${values.bmdc_reg_no}`;
        }

        setSearch(s);
        setPage('1');
        set_paginate_per_page(20);
      },
    });

  return (
    <div className="page-content">
      <Container fluid>
        <div className='page-breadcrumb-wrapper'>
          <BetterBreadcrumb title="Device List" breadcrumbItem={[{ link: '#', name: 'Device' }]} />

        </div>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Form
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  onReset={() => {
                    resetForm();
                    handleSubmit();
                  }}
                >
                  <div className="filter-wrapper">

                    <FormGroup>
                      <Label for="bmdc_reg_no">BMDC Reg. No.</Label>
                      <InputField
                        type="text"
                        placeholder="BMDC Reg. No."
                        invalid={{ errors, touched }}
                        {...getFieldProps('bmdc_reg_no')}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="general_search_term">Device Type</Label>
                      <InputField
                        type="select"
                        placeholder=""
                        options={deviceTypeOptions}
                        invalid={{ errors, touched }}
                        {...getFieldProps('general_search_term')}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Button
                        type='reset'
                        text='Reset'
                        color='danger'
                        className='me-1'
                      />
                      <Button type="button" text="Search" onClick={() => {
                        handleSubmit();
                      }} />
                    </FormGroup>
                  </div>
                </Form>
              </Col>
            </Row>
            <div className="table-responsive">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Sl.</th>
                    <th>Name</th>
                    <th>BMDC Reg. No.</th>
                    <th>Device Type</th>
                    <th>Manufacturer Name</th>
                    <th>Device ID</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
            {!isLoading && <DeviceRegPaginate data={deviceRegInfos} setPage={setPage} setPaginatePerPage={set_paginate_per_page} />}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default DeviceRegList;
