import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { InputField } from 'src/components';
import { config } from 'src/config';
import { setFromErrors } from 'src/helpers';
import { useAppSelector } from 'src/rtk/app/hook';
import {
  useGetCoGuideApplicationApprovalMutation,
  useGetGuideApplicationApprovalMutation,
} from 'src/rtk/features/guide/guideCoguideApi';
import { useGetRegInfoQuery } from 'src/rtk/features/registration-approval/registrationApprovalApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';
import avatar from '../../assets/images/users/default-avatar.png';

interface Props {
  isOpen: boolean;
  toggle: any;
  id: string;
  approvalId: string;
  type: string;
  verficationStatus: string;
}
const initialValues = {
  registration_no: '',
  status: '',
  message: '',
};

const ApprovalViewModal = (props: Props) => {
  const { isOpen, toggle, id, approvalId, type, verficationStatus } = props;
  const buttonRef = useRef<any>();
  const [isFcps, setIsFcps] = useState<boolean>(false);

  const [
    getGuideApplicationApproval,
    { isSuccess: isGuideSuccess, isError: isGuideError, error: guideError, isLoading: isGuideLoading },
  ] = useGetGuideApplicationApprovalMutation();
  const [
    getCoGuideApplicationApproval,
    {
      isSuccess: isCoGuideSuccess,
      isError: isCoGuideError,
      error: coGuideError,
      isLoading: isCoGuideLoading,
    },
  ] = useGetCoGuideApplicationApprovalMutation();

  const { data, isLoading } = useGetRegInfoQuery(id);

  const userInfo = data?.data || ({} as RegInfo);

  const [approvalStatus, setApprovalStatus] = useState('');
  const authUser = useAppSelector((state) => state.auth.user);

  const err: CustomError = guideError as CustomError;
  const err1: CustomError = coGuideError as CustomError;

  const {
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    handleChange,
    getFieldProps,
    setErrors,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      message: Yup.string().when('status', (status, schema) => {
        if (status === 'Correction' || status === 'Reject') {
          return schema.required('Note is Required!');
        }
        return schema;
      }),
    }),
    onSubmit: (values) => {
      if (type === 'Guide') {
        if (values.status === 'Approve') {
          const guideApproveUrl = `${approvalId}/${values.status}`;
          buttonRef.current.disabled = true;
          getGuideApplicationApproval(guideApproveUrl);
        }

        if (values.status === 'Correction') {
          const guideCorrectionUrl = `${approvalId}/${values.status}?note=${values.message}&email=${userInfo?.personal_info?.email}`;
          buttonRef.current.disabled = true;
          getGuideApplicationApproval(guideCorrectionUrl);
        }

        if (values.status === 'Reject') {
          const guideRejectUrl = `${approvalId}/${values.status}?note=${values.message}&email=${userInfo?.personal_info?.email}`;
          buttonRef.current.disabled = true;
          getGuideApplicationApproval(guideRejectUrl);
        }
      } else if (type === 'Co-Guide') {
        if (values.status === 'Approve') {
          const coGuideApproveUrl = `${approvalId}/${values.status}`;
          buttonRef.current.disabled = true;
          getCoGuideApplicationApproval(coGuideApproveUrl);
        }

        if (values.status === 'Correction') {
          const coGuideCorrectionUrl = `${approvalId}/${values.status}?note=${values.message}&email=${userInfo?.personal_info?.email}`;
          buttonRef.current.disabled = true;
          getCoGuideApplicationApproval(coGuideCorrectionUrl);
        }

        if (values.status === 'Reject') {
          const coGuideRejectUrl = `${approvalId}/${values.status}?note=${values.message}&email=${userInfo?.personal_info?.email}`;
          buttonRef.current.disabled = true;
          getCoGuideApplicationApproval(coGuideRejectUrl);
        }
      }
    },
  });

  useEffect(() => {
    if (isGuideSuccess) {
      toast.success('Guide Approval Status Updated Successfully!');
      resetForm();
      buttonRef.current.disabled = false;
    }
    if (isGuideError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
      buttonRef.current.disabled = false;
    }
  }, [isGuideSuccess, isGuideError]);

  useEffect(() => {
    if (isCoGuideSuccess) {
      toast.success('Co-Guide Approval Status Updated Successfully!');
      resetForm();
      buttonRef.current.disabled = false;
    }
    if (isCoGuideError) {
      toast.error(err1?.message || 'Something went wrong');
      setFromErrors(err1.data, setErrors);
      buttonRef.current.disabled = false;
    }
  }, [isCoGuideSuccess, isCoGuideError]);

  const t = userInfo?.order_master_details?.filter((i) => {
    return i.payment_for === 'Guide' || i.payment_for === 'Co-Guide';
  });

  // const guideCoGuideFilteredValue = t?.[0]?.payment_verification_status_approval_history?.filter((item) => {return item.entry_by_user_id === authUser.id})
  // const lengthOfGuideCoGuideFilteredValue = guideCoGuideFilteredValue?.length;

  useEffect(() => {
    userInfo?.educational_infos?.find((item: any) => item?.name_of_the_degree === 'FCPS') ? setIsFcps(true) : setIsFcps(false);
  }, [userInfo?.educational_infos]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="lg">
      {isLoading || isGuideLoading || isCoGuideLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <ModalHeader toggle={toggle}>Guide/Co-Guide Approval</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
        onReset={(e) => {
          e.preventDefault();
          resetForm();
        }}
      >
        <ModalBody>
          <div>
            <h6 className="block-heading"> Personal Information</h6>
            <div className="row">
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-12">
                    <table className="table table-bordered table-striped table-sm">
                      <tbody>
                        <tr>
                          <td>
                            <strong>Name: </strong>
                            {userInfo?.personal_info?.full_name}
                          </td>
                          <td>
                            {userInfo?.personal_info?.spouse_name && (
                              <div>
                                <strong>Spouse Name: </strong>
                                {userInfo?.personal_info?.spouse_name ?? 'N/A'}
                              </div>
                            )}

                            {userInfo?.personal_info?.fathers_name && (
                              <div>
                                <strong>Father's Name: </strong>
                                {userInfo?.personal_info?.fathers_name ?? 'N/A'}
                              </div>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Mother's Name: </strong>
                            {userInfo?.personal_info?.mothers_name ?? 'N/A'}
                          </td>
                          <td>
                            <strong>Date of Birth: </strong>
                            {userInfo?.personal_info?.date_of_birth ?? 'N/A'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>NID: </strong>
                            {userInfo?.personal_info?.national_id ?? 'N/A'}
                          </td>

                          <td>
                            <strong>Mobile Number: </strong>
                            {userInfo?.personal_info?.mobile ?? 'N/A'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Email: </strong>
                            {userInfo?.personal_info?.email ?? 'N/A'}
                          </td>

                          <td>
                            <strong>Alternative Number: </strong>
                            {userInfo?.personal_info?.telephone ?? 'N/A'}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <strong>Subject: </strong>
                            {userInfo?.subject?.subject_name ?? 'N/A'}
                          </td>
                          <td>
                            <strong>BCPS Reg. No: </strong>
                            {userInfo?.bcps_registration_no ?? 'N/A'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="img-fluid img-rounded text-center">
                  <img
                    src={
                      userInfo?.personal_info?.picture
                        ? `${config?.APP_URL}${userInfo?.personal_info?.picture}`
                        : avatar
                    }
                    width="120"
                    className="img-thumbnail"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12 mb-2">
                <h6 className="block-heading">Present Address</h6>
                <table className="table table-bordered table-striped table-sm">
                  <tbody>
                    <tr>
                      <td>
                        <strong>Address: </strong>
                        {userInfo?.latest_present_address?.address_line_1 ?? ''}
                        &nbsp;
                        {userInfo?.latest_present_address?.address_line_2 ?? ''}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Upazilla: </strong>
                        {userInfo?.latest_present_address?.police_station ??
                          'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>District: </strong>
                        {userInfo?.latest_present_address?.district ?? 'N/A'}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Division: </strong>
                        {userInfo?.latest_present_address?.division ?? 'N/A'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-6 col-sm-12 mb-2">
                <h6 className="block-heading">Permanent Address</h6>
                <table className="table table-bordered table-striped table-sm">
                  <tbody>
                    <tr>
                      <td>
                        <strong>Address: </strong>
                        {userInfo?.latest_permanent_address?.address_line_1 ??
                          ''}
                        &nbsp;
                        {userInfo?.latest_permanent_address?.address_line_2 ??
                          ''}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Upazilla: </strong>

                        {userInfo?.latest_permanent_address?.police_station ??
                          'N/A'}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>District: </strong>

                        {userInfo?.latest_permanent_address?.district ?? 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Division: </strong>

                        {userInfo?.latest_permanent_address?.division ?? 'N/A'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {userInfo?.educational_infos?.length > 0 && (
              <>
                <h6 className="block-heading">
                  Education Information
                </h6>
                <Row>
                  {userInfo?.educational_infos.map((edu, index) => (
                    <div className="col-md-6" key={edu.id}>
                      <Card>
                        <CardBody>
                          <ul className="list-unstyled mb-0">
                            <li>
                              <strong>Degree: </strong>
                              {edu.name_of_the_degree}
                            </li>
                            <li>
                              <strong>Institute: </strong>
                              {edu.institute_or_university}
                            </li>

                            <li>
                              <strong>Passing Year: </strong>
                              {edu.passing_year}
                            </li>

                            <li>
                              <strong>Result: </strong>
                            </li>

                            <li>
                              <strong>Certificate: </strong>
                              {edu.certificate_pdf ? (
                                <>
                                  <a
                                    className="me-2 p-0"
                                    href={config.APP_URL + edu.certificate_pdf}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    Download
                                  </a>
                                </>
                              ) : (
                                <span className="text-muted">N/A</span>
                              )}
                            </li>
                            <li>
                              <strong>Source Verify: </strong>
                              {edu?.certificate_pdf_source_verified ===
                                'Approve' ? (
                                <span className="badge badge-soft-success text-success">
                                  Yes
                                </span>
                              ) : (
                                <span className="badge badge-soft-danger text-danger">
                                  No
                                </span>
                              )}
                            </li>
                          </ul>
                        </CardBody>
                      </Card>
                    </div>
                  ))}
                </Row>
              </>
            )}
            {userInfo?.training_infos?.length > 0 && (
              <>
                <h6 className="block-heading">Training Record</h6>
                <div className="row">
                  {userInfo?.training_infos?.map((training, index) => (
                    <div className="col-md-6" key={training.id}>
                      <div className="card">
                        <div className="card-body">
                          <ul className="list-unstyled mb-0">
                            <li>
                              <strong>Institute: </strong>
                              <span>{training.name_of_institute ?? 'N/A'}</span>
                            </li>

                            <li>
                              <strong>Supervisor: </strong>
                              <span>{training.supervisor_name ?? 'N/A'}</span>
                            </li>

                            <li>
                              <strong>Department: </strong>
                              <span>{training.department ?? 'N/A'}</span>
                            </li>

                            <li>
                              <strong>Duration: </strong>
                              <span className="me-2">
                                {training.duration ?? '-'}
                              </span>
                              <span>{training.duration_unit ?? '-'}</span>
                            </li>

                            <li>
                              <strong>Supervisor's Post-Grad. Degree:</strong>
                              <span className="ms-2">
                                {training.supervisor_post_graduation_degree ??
                                  'N/A'}
                              </span>
                            </li>

                            <li>
                              <strong>Document: </strong>
                              <span>
                                {training.document ? (
                                  <a
                                    className="m-0 p-0"
                                    href={config.APP_URL + training.document}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    Download
                                  </a>
                                ) : (
                                  <span className="text-muted">N/A</span>
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}

            {userInfo?.employment_infos?.length > 0 && (
              <>
                <h6 className="block-heading">Work Experience</h6>
                <div className="row">
                  {userInfo?.employment_infos?.map((employment, index) => (
                    <div className="col-md-6" key={employment.id}>
                      <div className="card">
                        <div className="card-body">
                          <ul className="list-unstyled mb-0">
                            <li>
                              <strong>Institute: </strong>
                              {employment.institute_name ?? 'N/A'}
                            </li>

                            <li>
                              <strong>Designation: </strong>
                              {employment.designation ?? 'N/A'}
                            </li>

                            <li>
                              <strong>Employment Type: </strong>
                              {employment.employment_type ?? 'N/A'}
                            </li>

                            <li>
                              <strong>Current Position: </strong>

                              {employment.current_position
                                ? 'Yes'
                                : 'No'}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div>
            <h6 className="block-heading">
              BMDC Approval Status
            </h6>
            <div>
              <strong>BMDC Approve: </strong>
              {userInfo?.bmdc_registration_no_verified ?
                <div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>Approved</div> :
                <div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>No</div>
              }
            </div>

            <div>
              <strong>BMDC Source Verify: </strong>
              {userInfo?.bmdc_certificate_source_verified ?
                <div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>Approved</div> :
                <div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>No</div>
              }
            </div>
          </div>
          <Row>
            <Col>
              <h6 className="block-heading mt-3">Supervisor Eligibility Criteria</h6>
              <Card>
                <CardBody>
                  <>
                    <div className='mb-1'>
                      Professor or Associate Professor of an institute with FCPS Degree &nbsp;
                      {(userInfo?.current_employment_info?.designation === 'Professor' || userInfo?.current_employment_info?.designation === 'Associate Professor') && isFcps ?
                        <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                        : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                      }
                    </div>
                    <div className='mb-1'>
                      Assistant Professor with completion of Research Methodology TOT program &nbsp;
                      {userInfo?.current_employment_info?.designation === 'Assistant Professor' || userInfo?.current_employment_info?.in_charge ?
                        <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                        : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                      }
                    </div>

                    <div className='mb-1'>
                      Assistant Professor with FCPS Degree &nbsp;
                      {userInfo?.current_employment_info?.designation === 'Assistant Professor' && isFcps ?
                        <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                        : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                      }
                    </div>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {verficationStatus !== 'Approve' && verficationStatus !== 'Reject' ? (
            <div className="my-3">
              <h6 className="block-heading">
                Approval Status
              </h6>
              <span className="fw-bold">
                Guide/Co-Guide Application : &nbsp;
              </span>

              <div className="form-check form-check-inline">
                <Input
                  type="radio"
                  name="status"
                  id="guide-co-guide-approve"
                  value="Approve"
                  // key={Math.random()}
                  // checked={approvalStatus === 'Approve'}
                  onChange={(e) => {
                    // setApprovalStatus('Approve');
                    handleChange(e);
                    setFieldValue('status', 'Approve');
                  }}
                />
                <Label
                  className="form-check-label text-success"
                  htmlFor="guide-co-guide-approve"
                // onClick={() => setApprovalStatus('Approve')}
                >
                  Approve
                </Label>
              </div>
              <div className="form-check form-check-inline">
                <Input
                  type="radio"
                  name="status"
                  id="guide-co-guide-reject"
                  value="Reject"
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue('status', 'Reject');
                  }}
                />
                <Label
                  className="form-check-label text-danger"
                  htmlFor="guide-co-guide-reject"
                >
                  Reject
                </Label>
              </div>
              {/* <div className="form-check form-check-inline">
                <Input
                  type="radio"
                  name="status"
                  id="guide-co-guide-correct"
                  value="Correction"
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue('status', 'Correction');
                  }}
                />
                <Label
                  className="form-check-label text-info"
                  htmlFor="guide-co-guide-correct"
                >
                  Correction
                </Label>
              </div> */}
              {errors.status && touched.status ? (
                <div className="text-danger">{errors.status}</div>
              ) : null}
            </div>
          ) : null}
          {values.status !== 'Approve' && values.status !== '' ? (
            <div>
              <Label for="message">{values.status}&nbsp;Note</Label>
              <InputField
                type="textarea"
                placeholder="Enter.."
                invalid={{ errors, touched }}
                {...getFieldProps('message')}
              />
            </div>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <div className="d-flex gap-2 ">
            {verficationStatus !== 'Approve' && verficationStatus !== 'Reject' ? (
              <button
                type="submit"
                className="btn btn-primary"
                ref={buttonRef}
                disabled={values.status === ''}
              >
                Save
              </button>
            ) : null}
            <Button color="danger" onClick={toggle}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ApprovalViewModal;
