import { apiSlice } from 'src/rtk/api/apiSlice';
import * as lookupDataUrl from 'src/helpers/url_helper';

export const lookupData = apiSlice
  .enhanceEndpoints({ addTagTypes: ['LookupData'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLookupData: builder.query<APIResponse<Lookup[]>, string>({
        query: (lookup_code: string) => ({
          method: 'GET',
          url: `${lookupDataUrl.GET_LOOKUP_DATA}/${lookup_code}`,
        }),
        providesTags: ['LookupData'],
      }),
    }),
  });

export const { useGetLookupDataQuery, useLazyGetLookupDataQuery } = lookupData;


// const lookupName = LookupData?.data[0].childs.map();