import { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { config } from "src/config";
import { CommentSectionModal } from "../CommentSectionForProtocol/CommentSectionModal";

const GuideSignature = (props: any) => {
    const { title, existingProtocol } = props;
    const [nameGuide, setNameGuide] = useState('');
    const [nameCoGuide1, setNameCoGuide1] = useState('');
    const [nameCoGuide2, setNameCoGuide2] = useState('');
    const [designationGuide, setDesignationGuide] = useState('');
    const [designationcoGuide1, setDesignationCoGuide1] = useState('');
    const [designationcoGuide2, setDesignationCoGuide2] = useState('');
    const [signatureGuide, setSignatureGuide] = useState('');
    const [signatureCoGuide1, setSignatureCoGuide1] = useState('');
    const [signatureCoGuide2, setSignatureCoGuide2] = useState('');

    const [showCommentSectionModal, setShowCommentSectionModal] = useState<boolean>(false);
// console.log(existingProtocol);
    useEffect(() => {

        existingProtocol?.data?.title?.guide?.personal_info?.signature &&
            setSignatureGuide(
                `${config?.APP_URL}${existingProtocol?.data?.title?.guide?.personal_info?.signature}`,
            );
        existingProtocol?.data?.title?.co_guide_1?.personal_info?.signature &&
            setSignatureCoGuide1(
                `${config?.APP_URL}${existingProtocol?.data?.title?.co_guide_1?.personal_info?.signature}`,
            );
        existingProtocol?.data?.title?.co_guide_2?.personal_info?.signature &&
            setSignatureCoGuide2(
                `${config?.APP_URL}${existingProtocol?.data?.title?.co_guide_2?.personal_info?.signature}`,
            );

        existingProtocol?.data?.title?.co_guide_1_name && setNameCoGuide1(existingProtocol?.data?.title?.co_guide_1_name);
        existingProtocol?.data?.title?.co_guide_2_name && setNameCoGuide2(existingProtocol?.data?.title?.co_guide_2_name);
        existingProtocol?.data?.title?.guide_name && setNameGuide(existingProtocol?.data?.title?.guide_name);

        existingProtocol?.data?.title?.guide?.current_employment_info?.designation && setDesignationGuide(
            `${existingProtocol?.data?.title?.guide?.current_employment_info?.designation}, ${existingProtocol?.data?.title?.guide?.current_employment_info?.institute_name}`
        );
        existingProtocol?.data?.title?.co_guide_1?.current_employment_info?.designation && setDesignationCoGuide1(
            `${existingProtocol?.data?.title?.co_guide_1?.current_employment_info?.designation}, ${existingProtocol?.data?.title.co_guide_1?.current_employment_info?.institute_name}`
        );
        existingProtocol?.data?.title?.co_guide_2?.current_employment_info?.designation && setDesignationCoGuide2(
            `${existingProtocol?.data?.title?.co_guide_2?.current_employment_info?.designation}, ${existingProtocol?.data?.title.co_guide_2?.current_employment_info?.institute_name}`
        );

    }, [])
    return (
        <Row>
            <Row>
                <Col lg={12}>
                    <Row className="justify-content-end">
                        <Col sm={1} style={{ pointerEvents: 'auto' }}>
                            <Button
                                type="button"
                                className="btn btn-primary btn-sm mb-3"
                                onClick={(e) => {
                                    setShowCommentSectionModal(!showCommentSectionModal);
                                }}
                            >
                                <i className="fas fa-2x fa-comments"></i>
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row >
                <Col lg={12}>
                    <div className="d-flex justify-content-around signature-header-box">
                        <div>
                            <p><b>Signature of the Guide</b></p>
                        </div>
                        <div>
                            <p><b> Signature(s) of the Co-Guide</b></p>
                        </div>
                    </div>

                </Col>
            </Row>
            <Row style={{ minHeight: '350px' }}>
                <Col lg={5} md={5}>
                    {existingProtocol?.data?.guide_approval_status === "Approve" ? (
                        <div className="guide-signature">
                            <p><b>Name:</b> {nameGuide}</p>
                            <p><b>Designation:</b> {designationGuide}</p>
                            <p><b>Signature:</b></p>
                            <img style={{ width: '150px', marginLeft: '70px' }} src={signatureGuide} alt="signaure" />
                        </div>
                    ) : (

                        <p className="not-approved">Not Yet Approved</p>
                    )}
                </Col>
                <Col className="vertical-line col-md-1" >
                    <div></div>
                </Col>
                <Col lg={5} md={5} className="co-guide-signature">
                    {existingProtocol?.data?.co_guide1_approval_status === "Approve" ? (
                        <div className="co-guide-signature">
                            <p><b>Name:</b> {nameCoGuide1}</p>
                            <p><b>Designation:</b> {designationcoGuide1}</p>
                            <p><b>Signature:</b></p>
                            <img style={{ width: '150px', marginLeft: '70px' }} src={signatureCoGuide1} alt="signaure" />
                        </div>
                    ) : (

                        <p className="not-approved">Not Yet Approved</p>
                    )}



                    {existingProtocol?.data?.co_guide2_approval_status === "Approve" ? (
                        <>
                            <hr className="horizontal-line" />
                            <div className="co-guide-signature">

                                <p><b>Name:</b> {nameCoGuide2}</p>
                                <p><b>Designation:</b> {designationcoGuide2}</p>
                                <p><b>Signature:</b></p>
                                <img style={{ width: '150px', marginLeft: '70px' }} src={signatureCoGuide2} alt="signaure" />
                            </div>
                        </>
                    ) : existingProtocol?.data?.co_guide2_approval_status === undefined ? null : null}

                    {/*                  
                    <p><b>Name: Asst. Prof. Dr. Hasanuzzaman Khan</b></p>
                    <p><b>Designation: Assistant Professor, Dhaka Medical College</b></p>
                    <p><b>Signature:</b></p>
                    <img style={{ width: '150px', marginLeft: '70px' }} src={signatureCoGuide1} alt="signaure" />

                    <hr className="horizontal-line" />
                    <p><b>Name: Asst. Prof. Dr. Hasanuzzaman Khan</b></p>
                    <p><b>Designation: Assistant Professor, Dhaka Medical College</b></p>
                    <p><b>Signature:</b></p>
                    <img style={{ width: '150px', marginLeft: '70px' }} src={signatureGuide} alt="signaure" /> */}

                </Col>
            </Row>

            {showCommentSectionModal ?
                <CommentSectionModal
                    fieldName={'signature_of_the_guide_and_co_guide'}
                    fieldLabel={'Signature of the Guide and Co-Guide'}
                    existingProtocol={existingProtocol}
                    showModal={showCommentSectionModal}
                    setShowModal={setShowCommentSectionModal}
                />
                :
                null
            }
        </Row>
    );
};

export default GuideSignature;