import React from 'react';
import {
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from 'reactstrap';
import signature from '../../assets/images/signature.png';
import picture from '../../assets/images/users/avatar-1.jpg';

const FacultyPersonalInformation = (props: any) => {
  const { facultyPersonalInfoForm } = props;

  return (
    <React.Fragment>
      <Container fluid>
        <h4>Personal Information</h4>
        <Row>
          <Form
            onSubmit={facultyPersonalInfoForm.handleSubmit}
            encType="multipart/form-data"
          >
            <Col sm={12} md={12} lg={12}>
              <Row>
                <Col className="border-right p-2 mr-2" sm={12} md={6} lg={6}>
                  <div className="mb-3">
                    <Label className="form-label">Name</Label>
                    <Input
                      name="name"
                      className="form-control"
                      placeholder="Enter name"
                      type="text"
                      onChange={facultyPersonalInfoForm.handleChange}
                      onBlur={facultyPersonalInfoForm.handleBlur}
                      value={facultyPersonalInfoForm.values.name || ''}
                      invalid={
                        facultyPersonalInfoForm.touched.name &&
                        !!facultyPersonalInfoForm.errors.name
                      }
                    />
                    {facultyPersonalInfoForm.touched.name &&
                    facultyPersonalInfoForm.errors.name ? (
                      <FormFeedback type="invalid">
                        {facultyPersonalInfoForm.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">
                      Father's/Husband's Name
                    </Label>
                    <Input
                      name="fatherOrHusbandName"
                      className="form-control"
                      placeholder="Father's/Husband's Name"
                      type="text"
                      onChange={facultyPersonalInfoForm.handleChange}
                      onBlur={facultyPersonalInfoForm.handleBlur}
                      value={
                        facultyPersonalInfoForm.values.fatherOrHusbandName || ''
                      }
                      invalid={
                        facultyPersonalInfoForm.touched.fatherOrHusbandName &&
                        !!facultyPersonalInfoForm.errors.fatherOrHusbandName
                      }
                    />
                    {facultyPersonalInfoForm.touched.fatherOrHusbandName &&
                    facultyPersonalInfoForm.errors.fatherOrHusbandName ? (
                      <FormFeedback type="invalid">
                        {facultyPersonalInfoForm.errors.fatherOrHusbandName}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Mother's Name</Label>
                    <Input
                      name="motherName"
                      className="form-control"
                      placeholder="Mother's Name"
                      type="text"
                      onChange={facultyPersonalInfoForm.handleChange}
                      onBlur={facultyPersonalInfoForm.handleBlur}
                      value={facultyPersonalInfoForm.values.motherName || ''}
                      invalid={
                        facultyPersonalInfoForm.touched.motherName &&
                        !!facultyPersonalInfoForm.errors.motherName
                      }
                    />
                    {facultyPersonalInfoForm.touched.motherName &&
                    facultyPersonalInfoForm.errors.motherName ? (
                      <FormFeedback type="invalid">
                        {facultyPersonalInfoForm.errors.motherName}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">Date of Birth</Label>
                    <Input
                      name="dateOfBirth"
                      className="form-control"
                      placeholder="Date of birth"
                      type="date"
                      onChange={facultyPersonalInfoForm.handleChange}
                      onBlur={facultyPersonalInfoForm.handleBlur}
                      value={facultyPersonalInfoForm.values.dateOfBirth || ''}
                      invalid={
                        facultyPersonalInfoForm.touched.dateOfBirth &&
                        !!facultyPersonalInfoForm.errors.dateOfBirth
                      }
                    />
                    {facultyPersonalInfoForm.touched.dateOfBirth &&
                    facultyPersonalInfoForm.errors.dateOfBirth ? (
                      <FormFeedback type="invalid">
                        {facultyPersonalInfoForm.errors.dateOfBirth}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">NID</Label>
                    <Input
                      name="nid"
                      className="form-control"
                      placeholder="Enter NID number"
                      type="text"
                      onChange={facultyPersonalInfoForm.handleChange}
                      onBlur={facultyPersonalInfoForm.handleBlur}
                      value={facultyPersonalInfoForm.values.nid || ''}
                      invalid={
                        facultyPersonalInfoForm.touched.nid &&
                        !!facultyPersonalInfoForm.errors.nid
                      }
                    />
                    {facultyPersonalInfoForm.touched.nid &&
                    facultyPersonalInfoForm.errors.nid ? (
                      <FormFeedback type="invalid">
                        {facultyPersonalInfoForm.errors.nid}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Phone</Label>
                    <Input
                      name="phone"
                      className="form-control"
                      placeholder="Enter phone number"
                      type="text"
                      onChange={facultyPersonalInfoForm.handleChange}
                      onBlur={facultyPersonalInfoForm.handleBlur}
                      value={facultyPersonalInfoForm.values.phone || ''}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Email</Label>
                    <Input
                      name="email"
                      className="form-control"
                      placeholder="Enter email"
                      type="text"
                      onChange={facultyPersonalInfoForm.handleChange}
                      onBlur={facultyPersonalInfoForm.handleBlur}
                      value={facultyPersonalInfoForm.values.email}
                    />
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">Tel (Res)</Label>
                    <Input
                      name="tel"
                      className="form-control"
                      placeholder="Enter Telephone number (res)"
                      type="text"
                      onChange={facultyPersonalInfoForm.handleChange}
                      onBlur={facultyPersonalInfoForm.handleBlur}
                      value={facultyPersonalInfoForm.values.tel}
                    />
                  </div>
                </Col>

                <Col className=" p-2" sm={12} md={6} lg={6}>
                  <div className="mb-5">
                    <Label className="form-label">Photograph</Label>
                    <Input
                      name="photo"
                      className="form-control"
                      placeholder="Upload Photograph"
                      type="file"
                      onChange={facultyPersonalInfoForm.handleChange}
                      onBlur={facultyPersonalInfoForm.handleBlur}
                      value={facultyPersonalInfoForm.values.photo}
                    />
                    <div className="container  pt-4">
                      <img
                        className="mx-auto d-block rounded"
                        src={picture}
                        width={150}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Signature</Label>
                    <Input
                      name="signature"
                      className="form-control"
                      placeholder="Upload Signature"
                      type="file"
                      onChange={facultyPersonalInfoForm.handleChange}
                      onBlur={facultyPersonalInfoForm.handleBlur}
                      value={facultyPersonalInfoForm.values.signature}
                    />

                    <div className="container  pt-4">
                      <img
                        className="mx-auto d-block rounded"
                        src={signature}
                        width={150}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <hr />

            <Col sm={12} md={12} lg={12}>
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <h5>Present Address</h5>

                  <div className="mb-3">
                    <Label>Present Division</Label>
                    <select className="form-select">
                      <option value="" selected>
                        Select Division
                      </option>
                      <option value="2">Dhaka</option>
                      <option value="1">Sylhet</option>
                      <option value="3">Barishal</option>
                      <option value="4">Loadding...</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <Label>Present District</Label>
                    <select className="form-select">
                      <option value="" selected>
                        Select District
                      </option>
                      <option value="2">Jamalpur</option>
                      <option value="1">Rangpur</option>
                      <option value="3">Sunamganj</option>
                      <option value="4">Loading...</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <Label>Present Police Station</Label>
                    <select className="form-select">
                      <option value="" selected>
                        Select PS
                      </option>
                      <option value="2">Jamalpur</option>
                      <option value="1">Rangpur</option>
                      <option value="3">Sunamganj</option>
                      <option value="4">Loading...</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">Presnet Address</Label>
                    <Input
                      name="presentAddress"
                      className="form-control"
                      placeholder="Enter name"
                      type="textarea"
                      onChange={facultyPersonalInfoForm.handleChange}
                      onBlur={facultyPersonalInfoForm.handleBlur}
                      value={facultyPersonalInfoForm.values.presentAddress}
                    />
                  </div>
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <h5>Permanent Address</h5>

                  <div className="mb-3">
                    <Label>Permanent Division</Label>
                    <select className="form-select">
                      <option value="" selected>
                        Select Division
                      </option>
                      <option value="2">Dhaka</option>
                      <option value="1">Sylhet</option>
                      <option value="3">Barishal</option>
                      <option value="4">Loadding...</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <Label>Permanent District</Label>
                    <select className="form-select">
                      <option value="" selected>
                        Select District
                      </option>
                      <option value="2">Jamalpur</option>
                      <option value="1">Rangpur</option>
                      <option value="3">Sunamganj</option>
                      <option value="4">Loading...</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <Label>Permanent Police Station</Label>
                    <select className="form-select">
                      <option value="" selected>
                        Select PS
                      </option>
                      <option value="2">Jamalpur</option>
                      <option value="1">Rangpur</option>
                      <option value="3">Sunamganj</option>
                      <option value="4">Loading...</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">Permanent Address</Label>
                    <Input
                      name="permanentAddress"
                      className="form-control"
                      placeholder="Enter name"
                      type="textarea"
                      onChange={facultyPersonalInfoForm.handleChange}
                      onBlur={facultyPersonalInfoForm.handleBlur}
                      value={facultyPersonalInfoForm.values.permanentAddress}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Form>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default FacultyPersonalInformation;
