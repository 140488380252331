import { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Alert, Spinner } from 'reactstrap';
import { AuthLayout } from 'src/components';
import { useVerifyEmailTokenQuery } from 'src/rtk/features/auth/authApi';

export const EmailVerification = () => {
  let location = useLocation();
  let token = new URLSearchParams(location.search).get('token');
  let loginType = new URLSearchParams(location.search).get('type');
  const history = useHistory();

  console.log(token);

  const { data, isLoading, isSuccess, error } = useVerifyEmailTokenQuery(
    token ?? '',
  );

  const success = isSuccess && !!data?.data?.message;

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        if(loginType === 'tfs') {
          history.push('/login');
        } else if(loginType === 'ia') {
          history.push('/institute-login');
        }       
      }, 3000);
    }
  }, [success]);

  return (
    <AuthLayout>
      <div className="auth-content my-auto">
        <div className="text-center">
          <h5 className="mb-5">Email Verification</h5>
        </div>

        {success && !isLoading && (
          <Alert color="success">
            <div>
              <i className="mdi mdi-check-circle-outline me-2 fa-2xl"></i>
              Verification Successful. Redirecting to login page...
            </div>
          </Alert>
        )}

        {!success && !isLoading && (
          <>
            <Alert color="danger">
              <div className="d-flex flex-row  align-items-center gap-2">
                <i className="fas fa-info-circle fa-2xl"></i>
                <span>
                  {data?.message || (error as any)?.message} <br />
                  Try again or contact support.
                </span>
              </div>
            </Alert>
            <div className="d-flex justify-content-center align-items-center">
              <Link to="/resend-verification-email">
                <span className="btn btn-sm btn-primary text-white">
                  Resend Verification Link
                </span>
              </Link>
            </div>
          </>
        )}
        {!isSuccess && !isLoading && (
          <Alert color="danger">
            <div className="d-flex flex-row  align-items-center gap-2">
              <i className="fas fa-info-circle fa-2xl"></i>
              <span>
                Something went wrong. Please try again or contact support.
              </span>
            </div>
          </Alert>
        )}

        {isLoading && (
          <div className="row">
            <div className="col-lg-8 mx-auto text-center d-flex align-items-center justify-content-center">
              <Spinner animation="border" variant="secondary" />
              <span className="ms-3 fs-5">Verifying Email...</span>
            </div>
          </div>
        )}

        <div className="mt-5 text-center">
          <p className="text-muted mb-0">
            <Link to="/login" className="btn btn-sm btn-outline-primary fw-semibold">
              Sign In
            </Link>
          </p>
        </div>
      </div>
    </AuthLayout>
  );
};
