import { apiSlice } from 'src/rtk/api/apiSlice';
import * as deviceRegUrl from 'src/helpers/url_helper';

interface Paginate {
  page: number | string;
  paginate_per_page?: number;
  search?: string;
}

export const deviceRegistrationApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['DeviceRegistrationInfo', 'Users'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDeviceRegInfos: builder.query<APIResponse<DeviceInfo[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${deviceRegUrl.GET_DEVICE_REG_INFOS}`,
        }),
        providesTags: ['DeviceRegistrationInfo'],
      }),

      getTraineeDeviceRegInfos: builder.query<APIResponse<DeviceInfo[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${deviceRegUrl.GET_TRAINEE_DEVICE_REG_INFOS}`,
        }),
        providesTags: ['DeviceRegistrationInfo'],
      }),

      getDeviceRegInfosByPaginate: builder.query<
        APIResponseWithPagination<DeviceInfo>,
        Paginate
      >({
        query: (paginate) => {
          let url = `${deviceRegUrl.GET_DEVICE_REG_INFOS_BY_PAGINATE}?`;

          if (paginate.page) {
            url += `page=${paginate.page}&`;
          }
          if (paginate.paginate_per_page) {
            url += `paginate_per_page=${paginate.paginate_per_page}`;
          }
          if (paginate.search) {
            url += `${paginate.search}`;
          }

          return {
            method: 'GET',
            url: url,
          };
        },
        providesTags: ['DeviceRegistrationInfo'],
      }),

      getDeviceRegInfo: builder.query<APIResponse<DeviceInfo>, string>({
        query: (id) => ({
          url: `${deviceRegUrl.GET_DEVICE_REG_INFO}/${id}`,
          method: 'GET',
        }),
        providesTags: ['DeviceRegistrationInfo'],
      }),
      
      getDeviceRegInfoApproval: builder.mutation<any, any>({
        query: (obj) => ({
          url: `${deviceRegUrl.GET_DEVICE_REG_INFO_APPROVAL}/${obj.reg_no}/${obj.status}`,
          method: 'GET',
        }),
        invalidatesTags: ['DeviceRegistrationInfo'],
      }),

      getUser: builder.query<any, string>({
        query: (id) => ({
          url: `${deviceRegUrl.GET_USER_IN_DEVICE_REGISTRATION}/${id}`,
          method: 'GET',
        }),
        providesTags: ['Users'],
      }),

      createDeviceRegInfo: builder.mutation<APIResponse<DeviceInfo>, DeviceInfo>({
        query: (data) => ({
          url: `${deviceRegUrl.CREATE_DEVICE_REG_INFO}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['DeviceRegistrationInfo'],
      }),

      updateDeviceRegInfo: builder.mutation<APIResponse<DeviceInfo>, string>({
        query: (url) => ({
          url: `${deviceRegUrl.UPDATE_DEVICE_REG_INFO}/${url}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['DeviceRegistrationInfo'],
      }),

      deleteDeviceRegInfo: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${deviceRegUrl.DELETE_DEVICE_REG_INFO}/${id}`,
        }),
        invalidatesTags: ['DeviceRegistrationInfo'],
      }),
    }),
  });

export const {
  useCreateDeviceRegInfoMutation,
  useGetDeviceRegInfosQuery,
  useGetDeviceRegInfoQuery,    
  useUpdateDeviceRegInfoMutation,
  useDeleteDeviceRegInfoMutation,
  useGetDeviceRegInfosByPaginateQuery,
  useGetTraineeDeviceRegInfosQuery,
  useGetDeviceRegInfoApprovalMutation,
  // useLazyGetDeviceRegInfoApprovalQuery,
  useGetUserQuery,
} = deviceRegistrationApi;
