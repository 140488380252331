import React, { useEffect, useState } from 'react';

//import components
import DynamicMenu from './DynamicMenu';
import SidebarContent from './SidebarContent';
import { useGetPermissionWiseMenuQuery } from 'src/rtk/features/permissions/permissionApi';

const Sidebar = (props: any) => {
  const [isDynamicMenu, setIsDynamicMenu] = useState<boolean>(true);
  const [isDynamicMenuBtn, setIsDynamicMenuBtn] = useState<boolean>(true);
  const [menus, setMenus] = useState<any>([]);
  const {data : allMenu, isSuccess} = useGetPermissionWiseMenuQuery();

  useEffect(() => {
    if(allMenu && isSuccess){
      setMenus(allMenu?.data?.menus);
    }
  },[allMenu, isSuccess]);

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          {/* {isDynamicMenuBtn && (
            <div
              className="btn btn-sm btn-primary mt-1 mx-3"
              onClick={() => setIsDynamicMenu(!isDynamicMenu)}
            >
              Show {!isDynamicMenu ? 'Dynamic' : 'Static'} Menu
            </div>
          )} */}
          {/* {!isDynamicMenu && props.type !== 'condensed' && isSuccess ? ( */}
            <DynamicMenu 
              menus={menus}
            />
          {/* ) : (
            <SidebarContent />
          )} */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
