
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import {
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Label,
    Row
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import { useAssignDissertationReviewerMutation, useAssignThirdReviewerMutation } from 'src/rtk/features/disertation-thesis/dissertationListForAssigningReviewerApi';
import { useGetUsersByRoleQuery } from 'src/rtk/features/user/userSelectByPermissionApi';
import * as Yup from 'yup';

const initialValues: AssignDissertationReviewer = {
    thesis_id: '',
    reviewer_deadline: '',
    first_reviewer_id: '',
    second_reviewer_id: '',
    instruction: '',
};
const initialValuesForThirdReviewer: AssignDissertationReviewer = {
    thesis_id: '',
    reviewer_deadline: '',
    third_reviewer_id: '',
    instruction: '',
};

const AssignDissertationReviewer = (props: any) => {
    const { dissertationToView, setModalShow } = props;
    const [assignReviewer, { isSuccess, isError, error }] =
        useAssignDissertationReviewerMutation();

    const [assignThirdReviewer, { isSuccess: isThirdReviewerSuccess, isError: isThirdError, error: thirdError }] = useAssignThirdReviewerMutation();

    const [reviewerOneId, setReviewerOneId] = useState<number>(0);
    const [reviewerTwoId, setReviewerTwoId] = useState<number>(0);
    const [reviewerThreeId, setReviewerThreeId] = useState<number>(0);

    const { data: users, isError: isUsersError, error: usersError, isSuccess: isUsersSuccess, isLoading: isUsersLoading } = useGetUsersByRoleQuery('Dissertation Reviewer');

    const err: CustomError = error as CustomError;
    const err1: CustomError = thirdError as CustomError;

    const userOptions: SelectOption[] = users?.data?.map((u: User) => {
        return { label: u.name!, value: u.id! };
    })!;

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({

        }),

        onSubmit: (values: any) => {
            values.thesis_id = dissertationToView.id;
            values.first_reviewer_id = reviewerOneId;
            values.second_reviewer_id = reviewerTwoId;

            assignReviewer({
                ...values,
            });

            console.log(values);
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    const formikForThirdReviewer = useFormik({
        initialValues: initialValuesForThirdReviewer,
        validationSchema: Yup.object({
            third_reviewer_id: Yup.mixed().required('Please Select'),
            reviewer_deadline: Yup.mixed().required('Please Select Date'),

        }),

        onSubmit: (valuesThird: any) => {
            valuesThird.thesis_id = dissertationToView.id;
            // valuesThird.third_reviewer_id = reviewerThreeId;

            assignThirdReviewer({
                ...valuesThird,
            });
            console.log(valuesThird);
        },
    });

    const {
        handleSubmit: handleSubmitThird,
        getFieldProps: getFieldPropsThird,
        values: valuesThird,
        touched: touchedThird,
        errors: errorsThird,
        resetForm: resetFormThird,
        setErrors: setThirdErrors,
        setFieldValue: setThirdFieldValue,
    } = formikForThirdReviewer;

    useEffect(() => {
        if (isSuccess) {
            handleFormReset();
            setModalShow(false);
            toast.success('Reviewer assigned successfully');
        }
        if (isError) {
            toast.error(err?.message || 'Something went wrong');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);

    useEffect(() => {
        if (isThirdReviewerSuccess) {
            handleFormReset();
            setModalShow(false);
            toast.success('Third Reviewer Assigned Successfully');
        }
        if (isThirdError) {
            toast.error(err1?.message || 'Something went wrong');
            setFromErrors(err1.data, setThirdErrors);
        }
    }, [isThirdReviewerSuccess, isThirdError]);

    const handleReviewerOne = (e: any) => {
        setReviewerOneId(e.value);
    };

    const handleReviewerTwo = (e: any) => {
        setReviewerTwoId(e.value);
    };

    const handleReviewerThree = (e: any) => {
        setReviewerThreeId(e.value);
        setThirdFieldValue('third_reviewer_id', e.value);
    };

    const handleFormReset = () => {
        setReviewerOneId(0);
        setReviewerTwoId(0);
        resetForm();
    };

    const handleSecondFormReset = () => {
        setReviewerThreeId(0);
        resetFormThird();
    };

    useEffect(() => {
        if (dissertationToView?.first_reviewer_id !== null) {
            setReviewerOneId(dissertationToView?.first_reviewer_id);
        }
        if (dissertationToView?.second_reviewer_id !== null) {
            setReviewerTwoId(dissertationToView?.second_reviewer_id);
        }
        if (dissertationToView?.instruction !== null && dissertationToView?.reviewer_deadline !== null) {
            setFieldValue('instruction', dissertationToView?.instruction);
            setFieldValue('reviewer_deadline', dissertationToView?.reviewer_deadline);
        }
    }, [dissertationToView]);

    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <table className='table table-bordered table-sm table-striped'>
                        <tbody>
                            <tr>
                                <td>
                                    <span className="fw-semibold">Trainee Name: </span>
                                    <span>{dissertationToView.trainee_name ?? 'N/A'}</span>
                                </td>
                                <td>
                                    <span className="fw-semibold">BMDC Reg. No.: </span>
                                    <span>{dissertationToView.bmdc_registration_no ?? 'N/A'}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-semibold">Subject: </span>
                                    <span>{dissertationToView.subject_name ?? 'N/A'}</span>
                                </td>
                                <td>
                                    <span className="fw-semibold">Type: </span>
                                    <span>{dissertationToView.document_type ?? 'N/A'}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-semibold">Date of Submission: </span>
                                    <span>
                                        {dissertationToView.submission_date ?? 'N/A'}
                                    </span>
                                </td>
                                <td>
                                    <span className="fw-semibold">Title: </span>
                                    <span>
                                        {dissertationToView?.title?.title_of_thesis ?? 'N/A'}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <Row className='mt-4'>
                <Col lg={12}>
                    <Card>
                        <CardBody className="p-4">
                            <React.Fragment>
                                <Form
                                    autoComplete="off"
                                    className="custom-form"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    onReset={(e) => {
                                        e.preventDefault();
                                        handleFormReset();
                                    }}
                                >
                                    <Row>
                                        <Col lg={4} md={6} sm={12}>
                                            <div className="mb-3">
                                                <Label
                                                    htmlFor="reviewer1"
                                                    className="form-Label"
                                                >
                                                    Reviewer 1
                                                </Label>
                                                <Select
                                                    name="reviewer1"
                                                    value={userOptions?.find((u: SelectOption) => u.value === reviewerOneId) ?? { label: '', value: '' }}
                                                    options={userOptions}
                                                    onChange={handleReviewerOne}
                                                    isDisabled={(dissertationToView?.first_reviewer_final === 'Not Acceptable' || dissertationToView?.second_reviewer_final === 'Not Acceptable')}
                                                />
                                            </div>
                                        </Col>

                                        <Col lg={4} md={6} sm={12}>
                                            <div className="mb-3">
                                                <Label
                                                    htmlFor="reviewer2"
                                                    className="form-Label"
                                                >
                                                    Reviewer 2
                                                </Label>
                                                <Select
                                                    name="reviewer2"
                                                    value={userOptions?.find((u: SelectOption) => u.value === reviewerTwoId) ?? { label: '', value: '' }}
                                                    options={userOptions}
                                                    onChange={handleReviewerTwo}
                                                    isDisabled={(dissertationToView?.first_reviewer_final === 'Not Acceptable' || dissertationToView?.second_reviewer_final === 'Not Acceptable')}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4} md={6} sm={12}>
                                            <div className="mb-3">
                                                <Label htmlFor="reviewer_deadline" className="form-Label">
                                                    Review Deadline
                                                </Label>
                                                <InputField
                                                    type="date"
                                                    placeholder=""
                                                    invalid={{ errors, touched }}
                                                    {...getFieldProps('reviewer_deadline')}
                                                    disabled={(dissertationToView?.first_reviewer_final === 'Not Acceptable' || dissertationToView?.second_reviewer_final === 'Not Acceptable')}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4} md={6} sm={12}>
                                            <div className="mb-3">
                                                <Label
                                                    htmlFor="instruction"
                                                    className="form-Label"
                                                >
                                                    Instruction
                                                </Label>
                                                <InputField
                                                    type="textarea"
                                                    placeholder=""
                                                    invalid={{ errors, touched }}
                                                    {...getFieldProps('instruction')}
                                                    disabled={(dissertationToView?.first_reviewer_final === 'Not Acceptable' || dissertationToView?.second_reviewer_final === 'Not Acceptable')}
                                                />
                                            </div>
                                        </Col>

                                        {(dissertationToView?.first_reviewer_final !== 'Not Acceptable' && dissertationToView?.second_reviewer_final !== 'Not Acceptable') &&
                                            <Col sm={12} className="mt-3">
                                                <FormGroup className="mb-3 text-center">
                                                    <Button
                                                        type="reset"
                                                        text="Reset"
                                                        className="btn btn-danger me-2"
                                                        onClick={(e) => {
                                                            handleFormReset();
                                                        }}
                                                    ></Button>
                                                    <Button
                                                        type="submit"
                                                        text="Submit"
                                                        className="btn btn-success me-2"
                                                    ></Button>
                                                </FormGroup>
                                            </Col>}
                                    </Row>
                                </Form>
                                {(dissertationToView?.first_reviewer_final === 'Not Acceptable' || dissertationToView?.second_reviewer_final === 'Not Acceptable') &&
                                    <Form
                                        autoComplete="off"
                                        className="custom-form"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            handleSubmitThird();
                                        }}
                                        onReset={(e) => {
                                            e.preventDefault();
                                            handleSecondFormReset();
                                        }}
                                    >
                                        <Row>
                                            <Col lg={4} md={6} sm={12}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="reviewer3"
                                                        className="form-Label"
                                                    >
                                                        Reviewer 3
                                                    </Label>
                                                    <Select
                                                        name="reviewer3"
                                                        placeholder='Select...'
                                                        value={userOptions?.find((u: SelectOption) => u.value === reviewerThreeId) ?? { label: '', value: '' }}
                                                        options={userOptions}
                                                        onChange={handleReviewerThree}
                                                    />
                                                    {errorsThird.third_reviewer_id && touchedThird.third_reviewer_id ? (
                                                        <div className="text-danger error-size">
                                                            {errorsThird.third_reviewer_id as string}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>


                                            <Col lg={4} md={6} sm={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="reviewer_deadline" className="form-Label">
                                                        Review Deadline
                                                    </Label>
                                                    <InputField
                                                        type="date"
                                                        placeholder=""
                                                        // invalid={{ errors, touched }}
                                                        {...getFieldPropsThird('reviewer_deadline')}
                                                    />
                                                    {errorsThird.reviewer_deadline && touchedThird.reviewer_deadline ? (
                                                        <div className="text-danger error-size">
                                                            {errorsThird.reviewer_deadline as string}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="instruction"
                                                        className="form-Label"
                                                    >
                                                        Instruction
                                                    </Label>
                                                    <InputField
                                                        type="textarea"
                                                        placeholder="Instruction"
                                                        // invalid={{ errors, touched }}
                                                        {...getFieldPropsThird('instruction')}
                                                    />
                                                </div>
                                            </Col>

                                            <Col sm={12} className="mt-3">
                                                <FormGroup className="mb-3 text-center">
                                                    <Button
                                                        type="reset"
                                                        text="Reset"
                                                        className="btn btn-danger me-2"
                                                        onClick={(e) => {
                                                            handleSecondFormReset();
                                                        }}
                                                    ></Button>
                                                    <Button
                                                        type="submit"
                                                        text="Submit"
                                                        className="btn btn-success me-2"
                                                    ></Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>}
                            </React.Fragment>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
};

export default AssignDissertationReviewer;