import { useFormik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Modal,
  Row,
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetNoticeListQuery } from 'src/rtk/features/notice/publishNoticeApi';
import { useGetTrainingWorkshopListByPaginateQuery } from 'src/rtk/features/trainingWorkshop/trainingWorkshopApi';
import loader from '../../assets/images/loader.gif';
import EditTrainingWorkshop from './EditTrainingWorkshop';
import { actionColumnStyles, dataTableCustomStyles } from 'src/components/Common/FixedActionColumn';

interface SearchFields {
  training_name?: string;
  training_type?: string;
  training_for?: string;
  submit_status?: string;
}

const initialValues: SearchFields = {
  training_name: '',
  training_type: '',
  training_for: '',
  submit_status: '',
};

const TrainingWorkshopList = () => {
  document.title = 'Training / Workshop | BCPS';
  const { id: courseId } = useParams<{ id: string }>();

  const [editFormActive, setEditFormActive] = useState<boolean>(false);
  const [trainingWorkshopForEdit, setTrainingWorkshopForEdit] =
    useState<TrainingWorkshopEdit>({} as TrainingWorkshopEdit);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [trainingWorkshopForView, setTrainingWorkshopForView] =
    useState<TrainingWorkshopListEntry>({} as TrainingWorkshopListEntry);
  const [page, setPage] = useState<any>(1);
  const [paginate_per_page, set_paginate_per_page] = useState(10);
  const [search, setSearch] = useState('');
  const [editCount, setEditCount] = useState<number>(1);

  const {
    data: trainingWorkshopList,
    error: trainingWorkshopListError,
    isLoading: isTrainingWorkshopListLoading,
    isError: isTrainingWorkshopListError,
    isSuccess: isTrainingWorkshopListSuccess,
  } = useGetTrainingWorkshopListByPaginateQuery({
    page,
    paginate_per_page,
    search,
  });

  const {
    data: noticeList,
    isError: isNoticeListError,
    error: noticeListError,
    isSuccess: isNoticeListSuccess,
  } = useGetNoticeListQuery();

  const trainingWorkshopListData = trainingWorkshopList?.data?.data!;

  const notices = noticeList?.data!;

  const editHandler = (trainingWorkshop: any) => {
    setTrainingWorkshopForEdit(trainingWorkshop);
    setEditCount((e: number) => e + 1);
    setEditFormActive(true);
    setModalShow(true);
  };

  const modalShowHandler = (modalState: boolean, trainingWorkshop: any) => {
    setModalShow(modalState);
    setTrainingWorkshopForView(trainingWorkshop);
    // setLogbookForEdit(logbook);
    setEditFormActive(false);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    set_paginate_per_page(newPerPage);
    setPage(page);
  };

  const columns: TableColumn<any>[] = [
    {
      name: <div>SL No</div>,
      width: '80px',
      cell: (row, index) =><strong>{((page - 1) * paginate_per_page + index) + 1}</strong>,
    },

    {
      name: <div>Training Code</div>,
      selector: (row) => row?.training_workshop_info?.code,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Training Name</div>,
      selector: (row) => row?.training_workshop_info?.name,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Create Date</div>,
      selector: (row) =>
        row.workshop_create_date
          ? moment(row.workshop_create_date).format('DD/MMM/YYYY')
          : '',
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Applicable For</div>,
      selector: (row) => row.training_for,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Duration</div>,
      selector: (row) => row.duration,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Fees</div>,
      selector: (row) => row.fees,
      sortable: false,
      wrap: true,
    },

    {
      name: <div style={{ width: '200px' }}>No. of Participants</div>,
      selector: (row) => row.no_of_participants,
      sortable: false,
      wrap: true,

    },

    {
      name: <div>Application Start Date</div>,
      selector: (row) =>
        row.application_start_date
          ? moment(row.application_start_date).format('DD/MMM/YYYY')
          : '',
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Application End Date</div>,
      selector: (row) =>
        new Date(row.application_deadline?.substring(0, 10)).getFullYear() -
          new Date(row.application_start_date?.substring(0, 10)).getFullYear() <
          2000
          ? moment(row.application_deadline).format('DD/MMM/YYYY')
          : '',
      sortable: false,
      wrap: true,
    },

    // {
    //   name: <div style={{textAlign: 'center'}}>Notice Date</div>,
    //   selector: (row) => row.notice_date ? moment(row.notice_date).format('DD/MMM/YYYY') : '',
    //   sortable: false,
    //   wrap: true,
    // },

    // {
    //   name: <div style={{textAlign: 'center'}}>Training Type</div>,
    //   selector: (row) => row.training_type,
    //   sortable: false,
    //   wrap: true,
    // },

    {
      name: <div>Status</div>,
      selector: (row) => row.submit_status,
      cell: (row) => {
        switch (row.submit_status) {
          case 'Draft':
            return (
              <>
                <div className="badge badge-soft-dark font-size-12">
                  {row.submit_status}
                </div>
              </>
            );

          case 'Submit':
            return (
              <>
                <div className="badge badge-soft-success font-size-12">
                  {'Submitted'}
                </div>
              </>
            );

          default:
            return (
              <>
                <div className="badge badge-soft-secondary font-size-12">
                  {row.submit_status}
                </div>
              </>
            );
        }
      },
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Action</div>,
      width: '150px',
      style: actionColumnStyles,
      cell: (row) => (
        <>
          {/* btn-group-example */}
            <Button
              size="sm"
              color="info"
              type="button"
              className='me-1 my-1'
              text={`<span class="fas fa-eye" ></span> View`}
              onClick={() => {
                modalShowHandler(true, row);
              }}
            />
            {/* {row.submit_status &&
              row.submit_status.toLowerCase() === 'draft' && ( */}
                <Button
                  size="sm"
                  color="success"
                  type="button"
                  text={`<span class="fas fa-edit" ></span> Edit`}
                  onClick={() => {
                    editHandler(row);
                  }}
                />
              {/* )} */}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      wrap: true,
    },
  ];

  const {
    handleSubmit,
    getFieldProps,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.training_name !== '') {
        s = s + `&training_name=${values.training_name}`;
      }

      if (values.training_type !== '') {
        s = s + `&training_type=${values.training_type}`;
      }

      if (values.training_for !== '') {
        s = s + `&training_for=${values.training_for}`;
      }

      if (values.submit_status !== '') {
        s = s + `&submit_status=${values.submit_status}`;
      }

      setSearch(s);
      setPage('1');
      set_paginate_per_page(10);
    },
  });

  // useEffect(() => {
  //   if (isTrainingWorkshopListLoading || !isTrainingWorkshopListSuccess) {
  //     toast.dismiss();
  //     toast.loading('Data Loading...');
  //   } else {
  //     toast.dismiss();
  //     toast.success('Data Load Successfully');
  //   }
  // }, [isTrainingWorkshopListLoading, isTrainingWorkshopListSuccess]);

  return (
    <div className="page-content">
      {isTrainingWorkshopListLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb title={'Training/Workshop List'} />
        <Card className="mb-5">         
          <CardBody>
            <Row>
              <Col>
                <div className="gap-2 w-100">
                  <Form
                    className="custom-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="filter-wrapper">
                      <FormGroup>
                        <Label for="training_name">Training Name / Code</Label>
                        <InputField
                          type="text"
                          placeholder="Training Name / Code"
                          invalid={{ errors, touched }}
                          {...getFieldProps('training_name')}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="training_type">Training Type</Label>
                        <InputField
                          type="select"
                          placeholder=""
                          options={[
                            {
                              label: 'Mandatory',
                              value: 'Mandatory',
                            },
                            {
                              label: 'Optional',
                              value: 'Optional',
                            },
                          ]}
                          invalid={{ errors, touched }}
                          {...getFieldProps('training_type')}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="training_for">Applicable For</Label>
                        <InputField
                          type="select"
                          placeholder=""
                          options={[
                            { label: 'Trainee', value: 'Trainee' },
                            { label: 'Supervisor', value: 'Supervisor' },
                            { label: 'Fellow', value: 'Fellow' },
                          ]}
                          invalid={{ errors, touched }}
                          {...getFieldProps('training_for')}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="submit_status">Status</Label>
                        <InputField
                          type="select"
                          placeholder=""
                          options={[
                            { label: 'Draft', value: 'Draft' },
                            { label: 'Submit', value: 'Submit' },
                          ]}
                          invalid={{ errors, touched }}
                          {...getFieldProps('submit_status')}
                        />
                      </FormGroup>

                      <FormGroup className="btn-group btn-group-example">
                        <Button
                          color="danger"
                          type="reset"
                          text="Reset"
                          onClick={() => {
                            resetForm();
                            handleSubmit();
                          }}
                        />
                        <Button
                          type="button"
                          text="Search"
                          onClick={() => {
                            handleSubmit();
                          }}
                        />
                      </FormGroup>

                      <FormGroup className="">
                        <Link
                          to="/training-workshop/add"
                          className="btn btn-success btn-sm waves-effect waves-light p-2"
                          style={{ width: '190px', height: '37px' }}
                        >
                          <i className="fa fa-plus me-2" /> Create
                          Training/Workshop
                        </Link>
                      </FormGroup>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
            <DataTable
              columns={columns}
              data={trainingWorkshopListData ? trainingWorkshopListData : []}
              customStyles={dataTableCustomStyles}
              pagination
              paginationServer
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              progressPending={isTrainingWorkshopListLoading}
              paginationTotalRows={trainingWorkshopList?.data.total}
              expandOnRowClicked
              highlightOnHover
              paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
            />
          </CardBody>
        </Card>

        <Modal
          onOpened={function () {
            toast.dismiss();
          }}
          isOpen={modalShow}
          scrollable={true}
          size="xl"
          toggle={() => {
            document.body.classList.add('no_padding');
          }}
          backdrop={'static'}
          modalTransition={{ timeout: 0 }}
          backdropTransition={{ timeout: 0 }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {editFormActive && 'Edit '}
              Training/Workshop Details
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setModalShow(!modalShow);
              }}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <Row>
              <Col>
                {editFormActive 
                // &&
                //   trainingWorkshopForEdit.submit_status &&
                //   trainingWorkshopForEdit.submit_status.toLowerCase() ===
                //   'draft' 
                  && (
                    <EditTrainingWorkshop
                      key={editCount}
                      trainingWorkshopForEdit={trainingWorkshopForEdit}
                      setShowAddForm={setModalShow}
                    />
                  )}

                {!editFormActive && trainingWorkshopForView && (
                  <>
                    <table className="table table-bordered table-sm table-striped">
                      <tbody>
                        <tr>
                          <td>
                            <span className="fw-semibold">Workshop Create Date : </span>
                            <span>{trainingWorkshopForView.workshop_create_date?.slice(0,10,) ?? '-'}</span>
                          </td> 
                          <td>
                            <span className="fw-semibold">Notice Date : </span>
                            <span>{trainingWorkshopForView.notice_date?.slice(0,10,) ?? '-'}</span>
                          </td>
                        </tr>
                          {/* <td>Notice Title</td> */}
                        <tr>
                          <td>
                            <span className="fw-semibold">Training Name : </span>
                            <span>{trainingWorkshopForView.training_name ?? '-'}</span>
                          </td>
                          <td>
                            <span className="fw-semibold">Training Mode : </span>
                            <span>{trainingWorkshopForView.training_mode ?? '-'}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="fw-semibold">Training Type : </span>
                            <span>{trainingWorkshopForView.training_type ?? '-'}</span>
                          </td>
                          <td>
                            <span className="fw-semibold">Training For : </span>
                            <span>{trainingWorkshopForView.training_for ?? '-'}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="fw-semibold">Duration : </span>
                            <span>{trainingWorkshopForView.duration ?? '-'}</span>
                          </td>
                          <td>
                            <span className="fw-semibold">Fees : </span>
                            <span>{trainingWorkshopForView.fees ?? '-'}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="fw-semibold">No. of Participants : </span>
                            <span>{trainingWorkshopForView.no_of_participants ?? '-'}</span>
                          </td>
                          <td>
                            <span className="fw-semibold">Application Start Date : </span>
                            <span>{trainingWorkshopForView.application_start_date?.slice(
                              0,
                              10,
                            ) ?? '-'}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="fw-semibold">Application Deadline : </span>
                            <span>
                            {new Date(
                              trainingWorkshopForView.application_deadline?.substring(
                                0,
                                10,
                              )!,
                            ).getFullYear() -
                              new Date(
                                trainingWorkshopForView.application_start_date?.substring(
                                  0,
                                  10,
                                )!,
                              ).getFullYear() <
                              2000
                              ? trainingWorkshopForView.application_deadline?.substring(
                                0,
                                10,
                              )
                              : '-'}
                            </span>
                          </td>
                          <td>
                            <span className="fw-semibold">Submit Status : </span>
                            <span>{trainingWorkshopForView.submit_status ?? '-'}</span>
                          </td>
                        </tr>
                        {/* <tr>
                          <td>
                            {trainingWorkshopForView.workshop_create_date?.slice(
                              0,
                              10,
                            ) ?? '-'}
                          </td>
                          <td>
                            {trainingWorkshopForView.notice_date?.slice(
                              0,
                              10,
                            ) ?? '-'}
                          </td>
                          <td>
                            {notices?.data?.find(
                              (n: NoticeList) =>
                                n.id === trainingWorkshopForView?.notice_ref_no,
                            )?.notice_subject ?? '-'}
                          </td>
                          <td>
                            {trainingWorkshopForView.training_name ?? '-'}
                          </td>
                          <td>
                            {trainingWorkshopForView.training_mode ?? '-'}
                          </td>
                          <td>
                            {trainingWorkshopForView.training_type ?? '-'}
                          </td>
                          <td>{trainingWorkshopForView.training_for ?? '-'}</td>
                          <td>{trainingWorkshopForView.duration ?? '-'}</td>
                          <td>{trainingWorkshopForView.fees ?? '-'}</td>
                          <td>{trainingWorkshopForView.no_of_participants ?? '-'}</td>
                          <td>
                            {trainingWorkshopForView.application_start_date?.slice(
                              0,
                              10,
                            ) ?? '-'}
                          </td>
                          <td>
                            {new Date(
                              trainingWorkshopForView.application_deadline?.substring(
                                0,
                                10,
                              )!,
                            ).getFullYear() -
                              new Date(
                                trainingWorkshopForView.application_start_date?.substring(
                                  0,
                                  10,
                                )!,
                              ).getFullYear() <
                              2000
                              ? trainingWorkshopForView.application_deadline?.substring(
                                0,
                                10,
                              )
                              : '-'}
                          </td>
                          <td>
                            {trainingWorkshopForView.submit_status ?? '-'}
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                    <div className="col-sm-12  border-top border-light pt-3">
                      <FormGroup className="mb-3 text-left">
                        {/* {logbookForEdit.approved_status &&
                          logbookForEdit.approved_status.toLowerCase() ===
                            'draft' && (
                            <Button
                              size="md"
                              color="success"
                              type="button"
                              text={`<span class="fas fa-edit" ></span> Edit`}
                              onClick={() => {
                                editHandler(logbookForEdit);
                              }}
                            />
                          )} */}
                        <Button
                          className="ms-2"
                          size="md"
                          color="danger"
                          type="button"
                          text={`<span class="fas fa-times" ></span> Close`}
                          onClick={() => {
                            setModalShow(!modalShow);
                          }}
                        />
                        {/* <Button
                          className="ms-2"
                          size="md"
                          color="secondary"
                          type="button"
                          text={`<span class="fas fa-eye" ></span> View Notice`}
                          onClick={() => {
                            setModalShow(!modalShow);
                          }}
                        /> */}
                      </FormGroup>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </Modal>
      </Container>
    </div>
  );
};

export default TrainingWorkshopList;
