import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { InputField } from 'src/components';
import { config } from 'src/config';
import { useGetSlotApprovalByAdminMutation } from 'src/rtk/features/courseApproval/courseApprovalApi';
import { useGetRegInfoQuery } from 'src/rtk/features/registration-approval/registrationApprovalApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';
import avatar from '../../assets/images/users/default-avatar.png';

interface Props {
  isOpen: boolean;
  toggle: any;
  id: string;
  info: any;
}

const initialValues: RegApprove = {
  registration_no: '',
  status: '',
  message: '',
  type: '',
  registration_type: '',
};

const SlotApprovalViewModal = (props: Props) => {
  const { isOpen, toggle, id, info } = props;
  const [showApproveStatus, setShowApproveStatus] = useState<boolean>(false);
  const [showAttachment, setShowAttachment] = useState<boolean>(false);
  const [slotId, setSlotId] = useState<string>('');
  const buttonRef = useRef<any>();

  const [getSlotApprovalByAdmin, { isSuccess, isError, error, isLoading: isApprovalLoading }] = useGetSlotApprovalByAdminMutation();

  const err = error as CustomError;

  const { data, isLoading } = useGetRegInfoQuery(id);
  const userInfo = data?.data || ({} as RegInfo);

  const {
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    handleChange,
    getFieldProps,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      message: Yup.string().when('status', (status, schema) => {
        if (status === 'Correction') {
          return schema.required('Correction note is Required!');
        }
        return schema;
      }),
    }),
    onSubmit: (values) => {

      buttonRef.current.disabled = true;
      const url = `${slotId}?approve_type=${values.status}&note=${values.message}`;
      getSlotApprovalByAdmin(url);

    },
  });


  useEffect(() => {
    if (isSuccess) {
      toast.success('Approval Status Updated Successfully!');
      buttonRef.current.disabled = false;
      setShowApproveStatus(false);
    }
    if (isError) {
      toast.error('Something went wrong');
      buttonRef.current.disabled = false;
    }
  }, [isSuccess, isError]);

  return (
    <Modal
      isOpen={isOpen && !isLoading}
      backdrop={'static'}
      toggle={toggle}
      size="lg"
    >
      <ModalHeader toggle={toggle}>Slot Info</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <ModalBody>
          {isLoading || isApprovalLoading ? (
            <div className="overlay">
              <img src={loader} alt="Loading" height={100} width={300} />
            </div>
          ) : null}
          <div>
            <h6 className="block-heading"> Personal Information</h6>
            <div className="row">
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-12">
                    <table className="table table-bordered table-striped table-sm">
                      <tbody>
                        <tr>
                          <td>
                            <strong>Name: </strong>
                            {userInfo?.personal_info?.full_name}
                          </td>
                          <td>
                            {userInfo?.personal_info?.spouse_name && (
                              <div>
                                <strong>Spouse Name: </strong>
                                {userInfo?.personal_info?.spouse_name ?? 'N/A'}
                              </div>
                            )}

                            {userInfo?.personal_info?.fathers_name && (
                              <div>
                                <strong>Father's Name: </strong>
                                {userInfo?.personal_info?.fathers_name ?? 'N/A'}
                              </div>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Mother's Name: </strong>
                            {userInfo?.personal_info?.mothers_name ?? 'N/A'}
                          </td>
                          <td>
                            <strong>Date of Birth: </strong>
                            {userInfo?.personal_info?.date_of_birth ?? 'N/A'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>NID: </strong>
                            {userInfo?.personal_info?.national_id ?? 'N/A'}
                          </td>

                          <td>
                            <strong>Mobile Number: </strong>
                            {userInfo?.personal_info?.mobile ?? 'N/A'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Email: </strong>
                            {userInfo?.personal_info?.email ?? 'N/A'}
                          </td>

                          <td>
                            <strong>Alternative Number: </strong>
                            {userInfo?.personal_info?.telephone ?? 'N/A'}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <strong>Subject: </strong>
                            {userInfo?.subject?.subject_name ?? 'N/A'}
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="img-fluid img-rounded text-center">
                  <img
                    src={
                      userInfo?.personal_info?.picture
                        ? `${config?.APP_URL}${userInfo?.personal_info?.picture}`
                        : avatar
                    }
                    width="120"
                    className="img-thumbnail"
                  />
                </div>
              </div>
            </div>
            <h6 className="block-heading"> Supervisor Information</h6>
            <div className="row">
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-12">
                    <table className="table table-bordered table-striped table-sm">
                      <tbody>
                        <tr>
                          <td>
                            <strong>Name: </strong>
                            {info?.profile_info?.current_session_and_supervisor_info?.supervisor?.name}
                          </td>

                          <td>
                            <strong>BMDC Reg. No.: </strong>
                            {info?.profile_info?.current_session_and_supervisor_info?.supervisor?.bmdc_reg_no ?? 'N/A'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Designation: </strong>
                            {info?.profile_info?.current_session_and_supervisor_info?.supervisor?.designation ?? 'N/A'}
                          </td>

                          <td>
                            <strong>Mobile Number: </strong>
                            {info?.profile_info?.current_session_and_supervisor_info?.supervisor?.mobile ?? 'N/A'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Institute: </strong>
                            {info?.profile_info?.current_session_and_supervisor_info?.supervisor?.institute_name ?? 'N/A'}
                          </td>
                          <td>
                            <strong>Email: </strong>
                            {info?.profile_info?.current_session_and_supervisor_info?.supervisor?.email ?? 'N/A'}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <strong>Department: </strong>
                            {info?.profile_info?.current_session_and_supervisor_info?.supervisor?.department_name ?? 'N/A'}
                          </td>
                          <td>
                            <strong>Satus: </strong>
                            {info?.profile_info?.current_session_and_supervisor_info?.supervisors_approve_status}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="img-fluid img-rounded text-center">
                  <img
                    src={
                      info?.profile_info?.current_session_and_supervisor_info?.supervisor?.profile_picture
                        ? `${config?.APP_URL}${info?.profile_info?.current_session_and_supervisor_info?.supervisor?.profile_picture}`
                        : avatar
                    }
                    width="120"
                    className="img-thumbnail"
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col table-responsive'>
                <h6 className='block-heading'>Slot Information</h6>
                <table className='table table-sm'>
                  <thead>
                    <tr>
                      <th>Sl No</th>
                      <th>Program Type</th>
                      <th>Slot</th>
                      <th>Duration</th>
                      <th>Institute</th>
                      <th>Department</th>
                      <th>Supervisor</th>
                      <th>Admin Approval Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {info?.slot_info?.map((item: any, index: number) => (
                      <tr key={item.id}>
                        <td><strong>{++index}</strong></td>
                        <td>{item?.exam_training_info?.training_type}</td>
                        <td>{item?.exam_training_info?.name}</td>
                        <td>{moment(item?.session_start_date).format('DD/MMM/YYYY')}{'-'}{moment(item?.session_end_date).format('DD/MMM/YYYY')}</td>
                        <td>{item?.supervisor?.institute_name}</td>
                        <td>{item?.supervisor?.department_name}</td>
                        <td>{item?.supervisor?.name}</td>
                        <td>{
                          item?.admin_approval_status === 'Approve' ?
                            <>
                              <div className="badge badge-soft-success font-size-12">
                                {'Approved'}
                              </div>
                            </>
                            :
                            <>
                              <div className="badge badge-soft-info font-size-12">
                                {item?.admin_approval_status}
                              </div>
                            </>
                        }</td>
                        <td>
                          <div className='d-flex gap-1'>
                            <Button
                              className="btn btn-sm"
                              outline
                              color='success'
                              onClick={() => setShowAttachment(!showAttachment)}
                            >
                              Attachment
                            </Button>

                            <Button
                              className="btn btn-sm"
                              color='primary'
                              disabled={item?.admin_approval_status === 'Approve' || item?.supervisors_approve_status !== 'Approve'}
                              onClick={() => {
                                setShowApproveStatus(!showApproveStatus);
                                setSlotId(item?.id);
                              }}
                            >
                              Approve
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <Row>
            {showApproveStatus ?
              <>
                <div className="col-md-6 col-sm-12">
                  <h6 className="block-heading">Slot Approval</h6>

                  <FormGroup check inline className="mb-3 ms-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="profile_approval_status"
                      id="profile-approve"
                      checked={values.status === 'Approve'}
                      onChange={(e) => {
                        setFieldValue('status', 'Approve');
                      }}
                    />
                    <label
                      className="form-check-label text-success"
                      htmlFor="profile-approve"
                    >
                      Approve
                    </label>
                  </FormGroup>
                  <FormGroup check inline className="mb-3 ">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="profile_approval_status"
                      checked={values.status === 'Reject'}
                      id="reject"
                      onChange={() => {
                        setFieldValue('status', 'Reject');
                      }}
                    />
                    <label
                      className="form-check-label text-danger"
                      htmlFor="reject"
                    >
                      Reject
                    </label>
                  </FormGroup>
                  {/* <FormGroup check inline className="mb-3 ">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="profile_approval_status"
                      checked={values.status === 'Correction'}
                      id="correction"
                      onChange={() => {
                        setFieldValue('status', 'Correction');
                      }}
                    />
                    <label
                      className="form-check-label text-info"
                      htmlFor="correction"
                    >
                      Correction
                    </label>
                  </FormGroup> */}

                  {errors.status && touched.status ? (
                    <div className="text-danger">{errors.status}</div>
                  ) : null}

                  <div>
                    <Label for="message">{values.status}&nbsp;Note</Label>
                    <InputField
                      type="textarea"
                      placeholder="Enter.."
                      invalid={{ errors, touched }}
                      {...getFieldProps('message')}
                    />
                  </div>
                </div>
              </> : null
            }

            {showAttachment ?
              <div className='col-md-6 col-sm-12'>
                <h6 className='block-heading'>Attachment</h6>
                <table className='table table-sm'>
                  <thead>
                    <tr>
                      <th>
                        Sl No
                      </th>
                      <th>Name Of The Document</th>
                      <th>File Preview</th>
                    </tr>
                  </thead>

                </table>
              </div> : null
            }
          </Row>
        </ModalBody>

        <ModalFooter>
          <div className="d-flex gap-2 ">
            {showApproveStatus ?
              <button
                type="submit"
                className="btn btn-primary"
                ref={buttonRef}
              >
                Save
              </button>
              : null
            }
            <Button color="danger" onClick={toggle}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default SlotApprovalViewModal;
