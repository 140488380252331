import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Modal, Row, Table } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import { useGetDepartmentByIdQuery, useGetInstituteByIdQuery } from 'src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi';

const InstituteAndDepartmentDetails = () => {

    const { insId: instituteId, deptId: departmentId } = useParams<{
        insId: string;
        deptId: string;
    }>();

    const history = useHistory();

    const { data: instituteData, isSuccess: isInstituteDataSuccess, isError: isInstituteDataError, error: instituteDataError } = useGetInstituteByIdQuery(instituteId);
    const { data: departmentData, isSuccess: isDepartmentDataSuccess, isError: isDepartmentDataError, error: departmentDataError } = useGetDepartmentByIdQuery(departmentId);


    const [showInstituteViewModal, setShowInstituteViewModal] = useState<boolean>(false);
    const [instituteToView, setInstituteToView] = useState<InstituteAdminInstituteList>({} as InstituteAdminInstituteList);


    const handleInstituteInfoView = (e: any, institute: InstituteAdminInstituteList) => {
        setShowInstituteViewModal(true);
        setInstituteToView(institute);
    };


    return (
        <div className="page-content">
            <Container fluid>
                <BetterBreadcrumb
                    breadcrumbItem={[]}
                    title='Institute & Department Details'
                />
                <Card>
                    <CardBody>
                        <div className='table-responsive'>
                            <Table className='table table-bordered text-center table-sm'>
                                <thead className='table-secondary'>
                                    <tr>
                                        <th>Institute Name</th>
                                        <th>Type</th>
                                        <th>Location</th>
                                        <th>Date of Accreditation</th>
                                        <th>Payment Status</th>
                                        <th>Accreditation Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{instituteData?.data?.institute_name}</td>
                                        <td>{instituteData?.data?.institute_type}</td>
                                        <td>{'-'}</td>
                                        <td>{'-'}</td>
                                        <td>{instituteData?.data?.payment_info?.payment_verification_status ?? '-'}</td>
                                        <td>{instituteData?.data?.approval_status ?? '-'}</td>

                                        {instituteData?.data &&
                                            <td>
                                                <div>

                                                    <Button
                                                        type="button"
                                                        color="info"
                                                        className="btn-sm"
                                                        onClick={(e) => {
                                                            handleInstituteInfoView(e, instituteData?.data);
                                                        }}
                                                    >
                                                        View
                                                    </Button>

                                                </div>
                                            </td>}
                                    </tr>
                                </tbody>

                            </Table>
                        </div>
                    </CardBody>
                </Card>

                {departmentId !== 'undefined' && 
                <Card>
                    <CardBody>
                        <div className="table-responsive">
                            <Table className="table table-bordered text-center table-sm">
                                <thead className="table-secondary">
                                    <tr>
                                        {/* <th>Sl.</th> */}
                                        <th>Institute Name</th>
                                        <th>Department Name</th>
                                        <th>Date of Last Accreditation</th>
                                        <th>Duration to be Counted</th>
                                        <th>Validity Date</th>
                                        <th>Payment Status</th>
                                        <th>Accreditation Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {/* <th scope="row">{1}</th> */}
                                        <td>{departmentData?.data?.institute?.institute_name ?? '-'}</td>
                                        <td>{departmentData?.data?.department_name ?? '-'}</td>
                                        <td>{'-'}</td>
                                        <td>{'-'}</td>
                                        <td>{departmentData?.data?.valid_upto ?? '-'}</td>
                                        <td>{departmentData?.data?.payment_info?.payment_verification_status ?? '-'}</td>
                                        <td>{departmentData?.data?.approval_status ?? '-'}</td>
                                        <td>
                                            <div>
                                                <Button
                                                    size="sm"
                                                    text="View"
                                                    color="info"
                                                    onClick={() => {
                                                        history.push(`/institute/view-department/${departmentData?.data?.department_id}/${departmentData?.data?.id}`);
                                                    }}
                                                > 
                                                    View
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>}
            </Container>

            <Modal
                onOpened={function () {
                    toast.dismiss();
                }}
                isOpen={showInstituteViewModal}
                scrollable={false}
                size="lg"
                toggle={() => {
                    document.body.classList.add('no_padding');
                }}
                backdrop={'static'}
                modalTransition={{ timeout: 0 }}
                backdropTransition={{ timeout: 0 }}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        Institute Information
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setShowInstituteViewModal(!showInstituteViewModal);
                        }}
                        aria-label="Close"
                    ></button>
                </div>
                <div className="modal-body p-0">
                    <div className="bordered mb-3 p-4">
                        <>
                            <Row>
                                <Col sm={12} className="table-responsive">
                                    <table className="table table-bordered border-light table-hover text-capitalize">
                                        <thead>
                                            <tr className="bg-light text-dark text-center">
                                                <th colSpan={2}>Training Accreditation Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Institute Name</th>
                                                <td>{instituteToView?.institute_name ?? '-'}</td>
                                            </tr>
                                            <tr>
                                                <th>Institute Type</th>
                                                <td>{instituteToView?.institute_type ?? '-'}</td>
                                            </tr>
                                            <tr>
                                                <th>Recognition of BMDC</th>
                                                <td>{instituteToView?.recognition_of_bmdc ?? '-'}</td>
                                            </tr>
                                            {instituteToView?.recognition_of_bmdc === 'Recognized' && instituteToView?.recognition_of_bmdc_supporting_document ?
                                                <tr>
                                                    <th>Recognition of BMDC Supporting Documents</th>
                                                    <td>
                                                        <a
                                                            className="me-2 p-0 center"
                                                            href={`${config?.APP_URL}${instituteToView?.recognition_of_bmdc_supporting_document}`}
                                                            rel="noreferrer"
                                                            target="_blank"
                                                        >
                                                            <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                :
                                                null}
                                            <tr>
                                                <th>License of DGHS</th>
                                                <td>{instituteToView?.license_of_dghs ?? '-'}</td>
                                            </tr>
                                            {
                                                instituteToView?.license_of_dghs === 'Yes' ?
                                                    <tr>
                                                        <th>License Number</th>
                                                        <td>{instituteToView?.license_no ?? '-'}</td>
                                                    </tr>
                                                    :
                                                    null
                                            }
                                            <tr>
                                                <th>Affiliation with University</th>
                                                <td>{instituteToView?.affiliation_with_university ?? '-'}</td>
                                            </tr>
                                            {
                                                instituteToView?.affiliation_with_university === 'Yes' ?
                                                    <tr>
                                                        <th>Name of the University</th>
                                                        <td>{instituteToView?.affiliation_university_name ?? '-'}</td>
                                                    </tr>
                                                    :
                                                    null
                                            }
                                            <tr>
                                                <th>Institutional Research Monitoring Committee</th>
                                                <td>{instituteToView?.research_monitor_comittee ?? '-'}</td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr className="bg-light text-dark text-center">
                                                <th colSpan={2}>Information about Hospital Bed</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Total Number of Beds of the Institute</th>
                                                <td>{instituteToView?.total_no_of_beds ?? '-'}</td>
                                            </tr>
                                            <tr>
                                                <th>Number of Paying Beds</th>
                                                <td>{instituteToView?.total_no_of_paying_beds ?? '-'}</td>
                                            </tr>
                                            <tr>
                                                <th>Number of Non-Paying Beds</th>
                                                <td>{instituteToView?.total_no_of_non_paying_beds ?? '-'}</td>
                                            </tr>
                                            <tr>
                                                <th>Number of Cabins</th>
                                                <td>{instituteToView?.total_no_of_cabins ?? '-'}</td>
                                            </tr>
                                            <tr>
                                                <th>Availability of Emergency Services</th>
                                                <td>{instituteToView?.availability_of_emergency_service ?? '-'}</td>
                                            </tr>
                                            <tr>
                                                <th>Availability of ICU Services</th>
                                                <td>{instituteToView?.availability_of_icu_service ?? '-'}</td>
                                            </tr>
                                            {
                                                instituteToView?.availability_of_icu_service === 'Yes' ?
                                                    <>
                                                        <tr>
                                                            <th>Number of ICU Beds</th>
                                                            <td>{instituteToView?.icu_beds ?? '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Number of ICU Ventilators</th>
                                                            <td>{instituteToView?.number_of_ventilators ?? '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Number of ICU Doctors</th>
                                                            <td>{instituteToView?.no_of_icu_doctors ?? '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Number of ICU Nurse and Paramedics</th>
                                                            <td>{instituteToView?.number_of_icu_nurse_and_paramedics ?? '-'}</td>
                                                        </tr>
                                                    </>
                                                    :
                                                    null
                                            }
                                            <tr>
                                                <th>Availability of CCU Services</th>
                                                <td>{instituteToView?.availability_of_ccu_service ?? '-'}</td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr className="bg-light text-dark text-center">
                                                <th colSpan={2}>Payment Information</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Payment Date</th>
                                                <td>{moment(instituteToView?.payment_info?.payment_date).format('DD/MMM/YYYY')}</td>
                                            </tr>
                                            <tr>
                                                <th>Payment Method</th>
                                                <td>{instituteToView?.payment_info?.payment_method ?? '-'}</td>
                                            </tr>
                                            {
                                                instituteToView?.payment_info?.payment_method === 'Bank' ?
                                                    <>
                                                        <tr>
                                                            <th>Bank Name</th>
                                                            <td>{instituteToView?.payment_info?.bank_name ?? '-'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Branch Name</th>
                                                            <td>{instituteToView?.payment_info?.branch_name ?? '-'}</td>
                                                        </tr>
                                                    </>
                                                    :
                                                    null
                                            }
                                            <tr>
                                                <th>Payment Mode</th>
                                                <td>{instituteToView?.payment_info?.payment_mode ?? '-'}</td>
                                            </tr>
                                            <tr>
                                                <th>Total Amount</th>
                                                <td>{instituteToView?.payment_info?.total_amount ?? '-'}</td>
                                            </tr>
                                            {instituteToView?.payment_info?.bank_deposit_slip ?
                                                <tr>
                                                    <th>Deposit Slip</th>
                                                    <td>
                                                        <a
                                                            className="me-2 p-0 center"
                                                            href={`${config?.APP_URL}${instituteToView?.payment_info?.bank_deposit_slip}`}
                                                            rel="noreferrer"
                                                            target="_blank"
                                                        >
                                                            <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                                :
                                                null
                                            }
                                        </tbody>
                                        <thead>
                                            <tr className="bg-light text-dark text-center">
                                                <th colSpan={2}>Department Information</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Present Accreditation Status</th>
                                                <td>{instituteToView?.accreditation_status ?? '-'}</td>
                                            </tr>
                                            <tr>
                                                <th>Observation made at last inspection by BCPS team</th>
                                                <td>{instituteToView?.last_bcps_inspection_status ?? '-'}</td>
                                            </tr>
                                            <tr>
                                                <th>Measures taken to address the observations</th>
                                                <td>{instituteToView?.observation_measures ?? '-'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default InstituteAndDepartmentDetails;
