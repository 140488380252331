import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

const CourseListViewDetails = () => {

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">          
          <Row>
            <Col>
              <Card>
                <div className="table-responsive">
                  <CardBody>
                    <h1>Hello World!</h1>
                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CourseListViewDetails;
