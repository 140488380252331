
const ListGroupContent = (props: any) => {

    const {
        formObj,
        dynamicInputFields,
        activeStates,
        setActiveStates,
        toggleStates,
        setToggleStates,
    } = props;

    const listGroupItemStyles: any = {
        listGroupItem: {
            cursor: 'pointer',
        },
    };

    // console.log(activeStates);
    // console.log(toggleStates);

    return (
        <ul>
            {formObj?.map((f: any, index: number) => {
                if (f?.child?.length) {
                    return (
                        <>
                            <li style={listGroupItemStyles.listGroupItem}
                                className={toggleStates[f?.id] && 'active'}
                                onClick={(e) => {
                                    const newToggleStates: any = { ...toggleStates };
                                    const parentIds: string[] = [];
                                    Object.keys(newToggleStates)?.forEach((toggleId: string, index: number) => {
                                        if (toggleId === f?.id) {
                                            if (!newToggleStates[f?.id]) {
                                                let initialParentId: string = dynamicInputFields[f?.id]?.parent_id;
                                                while (initialParentId) {
                                                    parentIds.push(initialParentId);
                                                    initialParentId = dynamicInputFields[initialParentId]?.parent_id;
                                                }
                                            }

                                            newToggleStates[toggleId] = !newToggleStates[toggleId];
                                        }
                                    });

                                    if (newToggleStates[f?.id]) {
                                        Object.keys(newToggleStates)?.forEach((toggleId: string, index: number) => {
                                            if (toggleId !== f?.id && parentIds.indexOf(toggleId) === -1) {
                                                newToggleStates[toggleId] = false;
                                            }
                                        });
                                    }

                                    setToggleStates(newToggleStates);
                                }}>
                                <span className='thesis-entry-badge'>{f?.column_key}</span>
                                <span className='thesis-entry-label'>{f?.column_name}</span>
                                {toggleStates[f?.id] ? <i className='fas fa-chevron-down' /> :
                                    <i className='fas fa-chevron-right' />}
                            </li>
                            {/*<Collapse >*/}
                            {/*    <ListGroup flush>*/}
                            {/*        */}
                            {/*    </ListGroup>*/}
                            {/*</Collapse>*/}
                            {toggleStates[f?.id] && (
                                <ListGroupContent
                                    key={f?.id}
                                    formObj={f?.child}
                                    dynamicInputFields={dynamicInputFields}
                                    activeStates={activeStates}
                                    setActiveStates={setActiveStates}
                                    toggleStates={toggleStates}
                                    setToggleStates={setToggleStates} />
                            )}

                        </>
                    );
                } else {
                    return (
                        // action active={activeStates[f?.id]}
                        <li key={f?.id} style={listGroupItemStyles.listGroupItem}
                            className={activeStates[f?.id] && 'active'}
                            onClick={(e) => {
                                const newActiveStates: any = { ...activeStates };
                                Object.keys(newActiveStates)?.forEach((activeStateId: string) => {
                                    if (activeStateId === f?.id) {
                                        newActiveStates[activeStateId] = true;
                                    } else {
                                        newActiveStates[activeStateId] = false;
                                    }
                                });
                                setActiveStates(newActiveStates);
                            }}>
                            <span className='thesis-entry-badge'>{f?.column_key}</span>
                            <span className='thesis-entry-label'>{f?.column_name}</span>

                        </li>
                    );
                }
            })}
        </ul>
    );
};

export default ListGroupContent;
