import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Button, Container, Form, FormGroup, Label } from 'reactstrap';
import { Button as BsButton, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import DisplayImage from 'src/components/Common/displayImage';
import { config } from 'src/config';
import store from 'src/rtk/app/store';
import { useGetLogbookReportListForAdminAndSupervisorByPaginateQuery, useGetSupervisorsQuery } from 'src/rtk/features/logbookTrainingSummary/logbookTrainingSummaryApi';
import { useGetOrganizationsQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import loader from '../../assets/images/loader.gif';
import { actionColumnStyles, dataTableCustomStyles } from '../../components/Common/FixedActionColumn';

interface SearchFields {
    bmdc_registration_no?: string;
    name?: string;
    subject_id?: string;
    supervisor_id?: string;
    institute_name?: string;
}

export const LogbookSummaryReportListForAdminAndSupervisor = () => {
    const [logbookReportList, setLogbookReportList] = useState<any>([]);
    const [page, setPage] = useState<string | number>('1');
    const [paginate_per_page, set_paginate_per_page] = useState(10);
    const [search, setSearch] = useState<string>('');
    const [selectedSubject, setSelectedSubject] = useState<any>('');
    const [selectedSupervisor, setSelectedSupervisor] = useState<any>('');
    const [selectedInstitute, setSelectedInstitute] = useState<any>('');

    const history = useHistory();

    const currentUser: any = store.getState().auth.user;

    const {
        data: logbookReportData,
        isLoading: isLogbookReportDataLoading,
        isSuccess: isLogbookReportDataSuccess,
        isError: isLogbookReportDataError,
        error: logbookReportDataError,
    } = useGetLogbookReportListForAdminAndSupervisorByPaginateQuery({
        page,
        paginate_per_page,
        search
    });

    const { data: subjects } = useGetSubjectInfosQuery();

    const { data: supervisors } = useGetSupervisorsQuery();

    const { data: institutes } = useGetOrganizationsQuery();

    const allSubjects: SelectOption[] =
        subjects?.data?.map((subject) => ({
            value: subject.id!,
            label: subject.subject_name!,
        })) || [];

    const allSupervisors: SelectOption[] =
        supervisors?.data?.map((supervisor) => ({
            value: supervisor.id!,
            label: supervisor.name!,
        })) || [];

    const allInstitutes: SelectOption[] =
        institutes?.data?.map((institute: Organization) => ({
            value: institute.organization_name!,
            label: institute.organization_name!,
        })) || [];

    useEffect(() => {
        if (logbookReportData) {
            const tData = logbookReportData?.data?.data || [];
            setLogbookReportList(tData);
        }
    }, [logbookReportData]);

    useEffect(() => {
        if (selectedSubject !== '') {
            setFieldValue('subject_id', selectedSubject?.value);
        }
    }, [selectedSubject]);

    useEffect(() => {
        if (selectedSupervisor !== '') {
            setFieldValue('supervisor_id', selectedSupervisor?.value);
        }
    }, [selectedSupervisor]);

    useEffect(() => {
        if (selectedInstitute !== '') {
            setFieldValue('institute_name', selectedInstitute?.value);
        }
    }, [selectedInstitute]);

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        set_paginate_per_page(newPerPage);
        setPage(page);
    };

    const initialValues: SearchFields = {
        bmdc_registration_no: '',
        name: '',
        subject_id: '',
        supervisor_id: '',
        institute_name: '',
    };

    const {
        handleSubmit,
        handleChange,
        getFieldProps,
        setFieldValue,
        values,
        touched,
        errors,
        resetForm,
        setErrors,
    } = useFormik({
        initialValues,

        onSubmit: (values) => {
            let s: string = '';

            if (values.bmdc_registration_no !== '') {
                s = s + `&bmdc_registration_no=${values.bmdc_registration_no}`;
            }

            if (values.name !== '') {
                s = s + `&name=${values.name}`;
            }

            if (values.subject_id !== '') {
                s = s + `&subject_id=${values.subject_id}`;
            }

            if (values.supervisor_id !== '') {
                s = s + `&supervisor_id=${values.supervisor_id}`;
            }

            if (values.institute_name !== '') {
                s = s + `&institute_name=${values.institute_name}`;
            }

            setSearch(s);
            setPage('1');
            set_paginate_per_page(10);
        },
    });

    const handleFormReset = () => {
        resetForm();
    };

    const columns: TableColumn<any>[] = [
        {
            name: <div>Sl. No.</div>,
            cell: (row, index) => (+page - 1) * paginate_per_page + index + 1,
            width: '80px',
        },

        {
            name: <div>Photo</div>,
            selector: (row) => row?.registration_user_info?.personal_info?.picture,
            cell: (row, index) => (
                <>
                    {row?.registration_user_info?.personal_info?.picture ? (
                        <DisplayImage
                            className="img-thumbnail my-1 rounded-circle avatar-md"
                            src={`${config?.APP_URL}${row?.registration_user_info?.personal_info?.picture}`}
                        />
                    ) : (
                        ''
                    )}
                </>
            ),

            width: "150px",
        },

        {
            name: <div>BMDC Reg. No.</div>,
            selector: (row) => row?.registration_user_info?.bmdc_registration_no ?? '',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Name</div>,
            selector: (row) => row?.registration_user_info?.personal_info?.full_name ?? '',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Subject</div>,
            selector: (row) => row?.registration_user_info?.subject?.subject_name ?? '',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Supervisor</div>,
            selector: (row) => row?.registration_user_info?.current_session_and_supervisor_info?.supervisor?.name ?? '',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Institute</div>,
            selector: (row) => row?.registration_user_info?.current_session_and_supervisor_info?.supervisor?.institute_name ?? '',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Training Type</div>,
            selector: (row) => row?.registration_user_info?.current_session_and_supervisor_info?.slot_info?.training_type ?? '',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Current Slot</div>,
            selector: (row) => row?.registration_user_info?.current_session_and_supervisor_info?.slot_info?.name ?? '',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Action</div>,
            style: actionColumnStyles,
            selector: (row) => row?.id,
            width: '200px',
            cell: (row) => (
                <>
                    <FormGroup className="btn-group mt-2">
                        <Button
                            color='info'
                            size='sm'
                            className='w-100'
                            onClick={() => window.open(`/trainee/training-summary-for-admin-and-supervisor/${row?.registration_user_info?.user_id}/${row?.registration_user_info?.current_session_and_supervisor_info?.id}`, '_blank')}
                        >
                            <i className='fas fa-eye'></i> View Logbook Summary
                        </Button>
                    </FormGroup>
                </>
            ),
        },
    ];

    return (
        <div className="page-content">
            {isLogbookReportDataLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Container fluid>
                <div className='page-breadcrumb-wrapper'>
                    <BetterBreadcrumb
                        title="Logbook Summary Report"
                        breadcrumbItem={
                            currentUser.type === 'Supervisor' ?
                                [
                                    { link: '/supervisor/logbook', name: 'Logbook List' },
                                ]
                                :
                                []
                        }
                    />
                </div>
                <div className="row align-items-center justify-content-center">
                    <div className="card">
                        <div className="card-body">
                            <Form
                                className="custom-form"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                                onReset={(e) => {
                                    handleFormReset();
                                }}
                            >
                                <div className="filter-wrapper">
                                    <FormGroup className='extent-field'>
                                        <Label htmlFor="bmdc_registration_no">BMDC Reg. No.</Label>
                                        <InputField
                                            type="text"
                                            placeholder=""
                                            invalid={{ errors, touched }}
                                            {...getFieldProps('bmdc_registration_no')}
                                        />
                                    </FormGroup>

                                    <FormGroup className='extent-field'>
                                        <Label htmlFor="name">Name</Label>
                                        <InputField
                                            type="text"
                                            placeholder=""
                                            invalid={{ errors, touched }}
                                            {...getFieldProps('name')}
                                        />
                                    </FormGroup>

                                    <FormGroup className='extent-field'>
                                        <Label htmlFor="subject_id">Subject</Label>
                                        <Select
                                            isClearable={true}
                                            isSearchable={true}
                                            value={selectedSubject}
                                            onChange={setSelectedSubject}
                                            options={allSubjects}
                                            placeholder='Select...'
                                            name="subject_id"
                                            id="subject_id"
                                        />
                                    </FormGroup>

                                    {currentUser.type === 'Supervisor' ?
                                        null
                                        :
                                        <FormGroup className='extent-field'>
                                            <Label htmlFor="supervisor_id">Supervisor</Label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                value={selectedSupervisor}
                                                onChange={setSelectedSupervisor}
                                                options={allSupervisors}
                                                placeholder='Select...'
                                                name="supervisor_id"
                                                id="supervisor_id"
                                            />
                                        </FormGroup>}

                                    <FormGroup className='extent-field'>
                                        <Label htmlFor="institute_name">Institute</Label>
                                        <Select
                                            isClearable={true}
                                            isSearchable={true}
                                            value={selectedInstitute}
                                            onChange={setSelectedInstitute}
                                            options={allInstitutes}
                                            placeholder='Select...'
                                            name="institute_name"
                                            id="institute_name"
                                        />
                                    </FormGroup>

                                    <div className="btn-column mb-3">
                                        <BsButton
                                            color="danger"
                                            type="reset"
                                            text="Reset"
                                            onClick={() => {
                                                resetForm();
                                                setSelectedSubject('');
                                                setSelectedSupervisor('');
                                                setSelectedInstitute('');
                                                handleSubmit();
                                            }}
                                        />
                                        <BsButton
                                            type="button"
                                            text="Search"
                                            onClick={() => {
                                                handleSubmit();
                                            }}
                                        />
                                    </div>
                                </div>
                            </Form>
                            <DataTable
                                columns={columns}
                                data={logbookReportList}
                                customStyles={dataTableCustomStyles}
                                pagination
                                paginationServer
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handlePerRowsChange}
                                expandOnRowClicked
                                highlightOnHover
                                paginationTotalRows={logbookReportData?.data?.total}
                                paginationPerPage={paginate_per_page}
                                responsive
                                paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
                                persistTableHead
                            />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};
