import html2pdf from 'html2pdf.js';
import moment from 'moment';
import { useEffect } from 'react';
import defaultAvatar from 'src/assets/images/users/default-avatar.png';
import { config } from 'src/config';

interface Props {
    protocolEntry: any;
    titleEntry: any;
    showReport: boolean;
    setShowReport: any;
}

export const ProtocolSummaryReport = (props: Props) => {
    const { protocolEntry, titleEntry, showReport, setShowReport } = props;

    // const imageUrlToBase64 = async (url: string) => {
    //     const data = await fetch(url);
    //     const blob = await data.blob();
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.readAsDataURL(blob);
    //         reader.onloadend = () => {
    //             const base64data = reader.result;
    //             resolve(base64data);
    //         };
    //         reader.onerror = reject;
    //     });
    // };

    // imageUrlToBase64(`${config?.APP_URL}${titleEntry?.guide?.personal_info?.signature}`).then((value: any) => {
    //     console.log(value);
    // });

    let html =
        `
    <style>   
        @media print {
        
        body {
            margin: 0;
            color: #000;
            background-color: #fff;
        }
        .ProtocolSummaryReport{
            padding: 0.5in;
            color: #000000;
        }
        /*table.table-bordered{*/
        /* border: 1px solid #000 !important;*/
        
        /*}*/
        /*table.table-bordered > tbody> tr> td{*/
        /* border: 1px solid #000 !important;*/
        /*}*/
        /*   table.table-bordered > tbody> tr> td> table td{*/
        /*      border: 1px solid #000 !important;*/
        /*   }*/
        
        }     
        
        tr {
            border-top: 1px solid black;
            border-left: 1px solid black;
            border-bottom: 1px solid black;
        }
        td {
            border-right: 1px solid black;
        }
        th {
            border-right: 1px solid black;
        }
    
    </style>  
                        
            <div class='ProtocolSummaryReport'> 
               <table class='table table-sm'>
                    <thead class='bg-light fixed'>
                        <tr class='text-center text-dark font-size-16 fw-bold'>
                            <td colspan='20'>Dissertation/Thesis Protocol</td>
                        </tr>
                    </thead>

                    <tbody class='text-capitalize text-dark font-size-14'>
                        <tr>
                            <td class='bg-light fw-bold border-start border-dark'>Protocol No</td>
                            <td colspan='2' class='text-center'>${protocolEntry?.protocol_no ?? ''}</td>
                        </tr>
                        <tr>
                            <td width='50'>1</td>
                            <td class='fw-bold'>Date of submission of protocol</td>
                            <td width='60%'>${moment(protocolEntry?.protocol_submission_date).isValid() ? moment(protocolEntry?.protocol_submission_date).format('DD/MMM/YYYY') : ''}</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td class='fw-bold'>Relevant</td>
                            <td>
                                <p><strong>Faculty: </strong> ${protocolEntry?.relevant_faculty ?? ''}</p>
                                <p><strong>Discipline: </strong> ${protocolEntry?.relevant_discipline ?? ''}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td class='fw-bold'>Name of the researcher</td>
                            <td>${titleEntry?.trainee_name ?? ''}</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td class='fw-bold'>Correspondence of the examinee and contact phone numbers</td>
                            <td>
                                <p>
                                    <strong>Address: </strong>
                                    ${titleEntry?.registration?.address_infos[1]?.address_line_1 ?? ''}
                                    ${titleEntry?.registration?.address_infos[1]?.police_station ?? ''}
                                    ${titleEntry?.registration?.address_infos[1]?.district ?? ''}
                                </p>
                                <p><strong>Email Address: </strong> ${titleEntry?.registration?.personal_info?.email ?? ''}</p>
                                <p><strong>Phone number: </strong> ${titleEntry?.registration?.personal_info?.mobile ?? ''}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td class='fw-bold'>Title of the dissertation/thesis</td>
                            <td>${titleEntry?.title_of_thesis ?? ''}</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td class='fw-bold'>Summary</td>
                            <td>
                                <span>${protocolEntry?.summary ?? ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td class='fw-bold'>Place of study</td>
                            <td>${titleEntry?.place_of_study ?? ''}</td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td class='fw-bold'>Study period</td>
                            <td>${titleEntry?.study_period ?? ''} ${titleEntry?.study_period_unit ?? ''}</td>
                        </tr>
                        <tr>
                            <td>9</td>
                            <td class='fw-bold'>Study Design</td>
                            <td>
                                <span>${protocolEntry?.study_design ?? ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>10</td>
                            <td class='fw-bold'>Introduction/context</td>
                            <td>
                                <span>${protocolEntry?.introduction_context ?? ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>11</td>
                            <td class='fw-bold'>Rationale of the research</td>
                            <td>
                                <span>${protocolEntry?.rationale_of_the_research ?? ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>12</td>
                            <td class='fw-bold'>Research question/hypothesis</td>
                            <td>
                                <span>${protocolEntry?.research_question_hypothesis ?? ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>13</td>
                            <td class='fw-bold'>Objectives (General Objectives)</td>
                            <td>
                                <span>${protocolEntry?.objectives ? JSON.parse(protocolEntry?.objectives)[0]?.value?.value : ''}</span>
                            </td>                            
                        </tr>    
                        <tr>
                            <td>14</td>
                            <td class='fw-bold'>Objectives (Specific Objectives)</td>
                            <td>
                                <span>${protocolEntry?.objectives ? JSON.parse(protocolEntry?.objectives)[1]?.value?.value : ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>15</td>
                            <td class='fw-bold'>Materials and methods (Main outcome variables to be studied)</td>
                            <td>
                                <span>${protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[0]?.value?.value : ''}</span>
                            </td>
                        </tr> 
                        <tr>
                            <td>16</td>
                            <td class='fw-bold'>Materials and methods (Confounding variable)</td>
                            <td>
                                <span>${protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[1]?.value?.value : ''}</span>
                            </td>
                        </tr> 
                        <tr>
                            <td>17</td>
                            <td class='fw-bold'>Materials and methods (Study population - mentioning species and strains, ethnic group, age, sex, and other relevant characteristics)</td>
                            <td>
                                <span>${protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[2]?.value?.value : ''}</span>
                            </td>
                        </tr>                          
                        <tr>
                            <td>18</td>
                            <td class='fw-bold'>Materials and methods (Sample size and the statistical basis of it)</td>
                            <td>
                                <span>${protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[3]?.value?.value : ''}</span>
                            </td>
                        </tr> 
                        <tr>
                            <td>19</td>
                            <td class='fw-bold'>Materials and methods (Screening methods, if applicable)</td>
                            <td>
                                <span>${protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[4]?.value?.value : ''}</span>
                            </td>
                        </tr>                        
                        <tr>
                            <td>20</td>
                            <td class='fw-bold'>Materials and methods (Sampling method)</td>
                            <td>
                                <span>${protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[5]?.value?.value : ''}</span>
                            </td>
                        </tr>                           
                        <tr>
                            <td>21</td>
                            <td class='fw-bold'>Materials and methods (Inclusion and exclusion criteria)</td>
                            <td>
                                <span>${protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[6]?.value?.value : ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>22</td>
                            <td class='fw-bold'>Materials and methods (Operational definitions)</td>
                            <td>
                                <span>${protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[7]?.value?.value : ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>23</td>
                            <td class='fw-bold'>Materials and methods (Flow chart showing the sequence of tasks)</td>
                            <td>
                                <span>${protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[8]?.value?.value : ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>24</td>
                            <td class='fw-bold'>Materials and methods (Procedures of preparing and organizing materials e.g. into groups)</td>
                            <td>
                                <span>${protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[9]?.value?.value : ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>25</td>
                            <td class='fw-bold'>Materials and methods (Nature of controls, if applicable)</td>
                            <td>
                                <span>${protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[10]?.value?.value : ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>26</td>
                            <td class='fw-bold'>Materials and methods (Randomization and blinding methods, if and as applicable)</td>
                            <td>
                                <span>${protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[11]?.value?.value : ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>27</td>
                            <td class='fw-bold'>Materials and methods (Equipment to be used)</td>
                            <td>
                                <span>${protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[12]?.value?.value : ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>28</td>
                            <td class='fw-bold'>Materials and methods (Procedures of collecting data including methods of intervention, measurement, estimation, etc.)</td>
                            <td>
                                <span>${protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[13]?.value?.value : ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>29</td>
                            <td class='fw-bold'>Materials and methods (Professional assistance from experts, if applicable)</td>
                            <td>
                                <span>${protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[14]?.value?.value : ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>30</td>
                            <td class='fw-bold'>Materials and methods (Procedure of data analysis or interpretation including methods of statistical analysis, specific methods, and software used for individual analysis)</td>
                            <td>
                                <span>${protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[15]?.value?.value : ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>31</td>
                            <td class='fw-bold'>Materials and methods (Quality assurance strategy)</td>
                            <td>
                                <span>${protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[16]?.value?.value : ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>32</td>
                            <td class='fw-bold'>Materials and methods (Time table)</td>
                            <td>
                                <span>${protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[17]?.value?.value : ''}</span>
                            </td>
                        </tr>                        
                        <tr>
                            <td>33</td>
                            <td class='fw-bold'>Ethical implication</td>
                            <td>
                                <span>${protocolEntry?.ethical_implication ?? ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>34</td>
                            <td class='fw-bold'>Total Budget</td>
                            <td>
                                <span>${protocolEntry?.total_budget_text ?? ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>35</td>
                            <td class='fw-bold'>Sources of funding (if any)</td>
                            <td>
                                <span>${protocolEntry?.source_of_funding ?? ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>36</td>
                            <td class='fw-bold'>Facilities available at the place of study</td>
                            <td>
                                <span>${protocolEntry?.facilities_available_at_the_place_of_the_study ?? ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>37</td>
                            <td class='fw-bold'>Other facilities needed</td>
                            <td>
                                <span>${protocolEntry?.other_facilites_needed ?? ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>38</td>
                            <td class='fw-bold'>Dissemination and use of findings</td>
                            <td>
                                <span>${protocolEntry?.dissemination_and_use_of_findings ?? ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>39</td>
                            <td class='fw-bold'>References</td>
                            <td>
                                <span>${protocolEntry?.references ?? ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>40</td>
                            <td class='fw-bold'>Any other relevant information</td>
                            <td>
                                <span>${protocolEntry?.any_other_relevant_info ?? ''}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>41</td>
                            <td colspan='2'>
                                <p>
                                    I solemnly pledge that this research protocol shall be implemented
                                    in accordance with the relevant ordinance circulars of BCPS and
                                    funding agencies as when it is applicable. I hereby declare that
                                    no part of the proposal research has been used in any thesis dissertation
                                    in partial fulfillment of any degree fellowship or any publication.
                                </p>

                                <p>
                                    I also understand that the BCPS reserves the right of accepting or rejecting this protocol.
                                </p>

                                <div class='row justify-content-between mt-5'>
                                    <div class='col-sm-4 mt-5'>
                                        <p class='text-center mt-4'>${moment(protocolEntry?.signature_date)?.isValid() ? moment(protocolEntry?.signature_date)?.format('DD/MMM/YYYY') : moment(new Date(Date.now()).toISOString()).format('DD/MMM/YYYY')}</p>
                                        <p class='text-center border-top border-dark'>Date</p>
                                    </div>
                                    <div class='col-sm-4'>
                                        <div class='image-upload-wrapper'>
                                            <div class='image-preview ms-5'>
                                                <img src='${protocolEntry?.researcher_signature ? `${config.APP_URL}${protocolEntry?.researcher_signature}` : defaultAvatar}' alt='' />
                                            </div>
                                        </div>
                                        <p class='text-center border-top border-dark'>Signature of the Researcher</p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>42</td>
                            <td colspan='2'>
                                <table class='table'>
                                    <thead class='bg-light fixed'>
                                        <tr class='text-center text-dark font-size-16 fw-bold thesisRecord'>
                                            <td>Signature of the Guide</td>
                                            <td>Signature of the Co-Guide</td>
                                        </tr>
                                    </thead>
                                    <tbody class='text-capitalize text-dark font-size-14'>
                                        <tr class='thesisRecord'>
                                            <td>
                                                <p><strong>Name: </strong>${titleEntry?.guide_name ?? ''}</p>
                                                <p><strong>Designation: </strong>${titleEntry?.guide?.current_employment_info?.designation}, ${titleEntry?.guide?.current_employment_info?.institute_name}</p>
                                                <p><strong>Signature: </strong></p>
                                                <div class='image-upload-wrapper'>
                                                    <div class='image-preview'>
                                                        <img src='${titleEntry?.guide?.personal_info?.signature ? `${config?.APP_URL}${titleEntry?.guide?.personal_info?.signature}` : defaultAvatar}' alt='signature' />
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <p><strong>Name: </strong>${titleEntry?.co_guide_1_name ?? ''}</p>
                                                <p><strong>Designation: </strong>${titleEntry?.co_guide_1?.current_employment_info?.designation}, ${titleEntry?.co_guide_1?.current_employment_info?.institute_name}</p>
                                                <p><strong>Signature: </strong></p>
                                                <div class='image-upload-wrapper'>
                                                    <div class='image-preview'>
                                                        <img src='${titleEntry?.co_guide_1?.personal_info?.signature ? `${config?.APP_URL}${titleEntry?.co_guide_1?.personal_info?.signature}` : defaultAvatar}' alt='signature' />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr class='thesisRecord'>
                            <td>43</td>
                            <td colspan='2' class='fw-bold'>
                                <div class='row justify-content-center'>
                                    <div class='col-sm-4 text-center'>
                                        <p>Attachments</p>
                                    </div>
                                    <div class='col-sm-12 text-center'>
                                        <table class='table text-center'>
                                            <thead>
                                                <th>Sl. No.</th>
                                                <th>File Type</th>
                                                <th>File Preview</th>
                                            </thead>
                                            <tbody>
                                                ${protocolEntry?.protocol_attachments?.map((fileObj: any, index: number) => {
            return (
                `<tr>
                                                            <td>
                                                                ${index + 1}
                                                            </td>
                                                            <td>
                                                                ${fileObj.document_type}
                                                            </td>
                                                            <td>
                                                                <a
                                                                    class="me-2 p-0 center"
                                                                    href='${config?.APP_URL}${fileObj.document_name}'
                                                                    rel="noreferrer"
                                                                    target="_blank"
                                                                >
                                                                    <span class="btn-outline-info btn-sm btn ms-2">View</span>
                                                                </a>
                                                            </td>
                                                        </tr>`
            )
        })}
                                            </tbody>
                                        </table>
                                    </div>                                    
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table></div>`;

    useEffect(() => {
        setShowReport(false);
    }, []);

    const options = {
        margin: [15, 15],
        filename: 'protocolSummaryReport.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, letterRendering: true },
        jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    };

    html2pdf()
        .from(html)
        .set(options)
        .toPdf()
        .get('pdf')
        .then(function (pdf: any) {
            let totalPages = pdf.internal.getNumberOfPages();

            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                pdf.setFontSize(117);
                pdf.setTextColor('#f7efef');
                pdf.text("Reviewer's Copy", 90, pdf.internal.pageSize.getHeight() / 2 + 400, { angle: '55', rotationDirection: '1', renderingMode: 'fillAndAddForClipping' });
            }

            window.open(pdf.output('bloburl'), '_blank');
        });

    return null;
};
