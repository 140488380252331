import { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
interface PaginateLink {
  url: string | null;
  label: string;
  active: boolean;
}
interface Props {
  data: any;
  setPage: any;
  setPaginatePerPage: any;
}

const NotificationPaginate = ({ data: _data, setPage, setPaginatePerPage }: Props) => {
  const [links, setLinks] = useState<PaginateLink[]>([]);
  const data = _data?.data;

  useEffect(() => {
    const links = data?.links;
    if (links?.length) {
      setLinks(links.slice(1, -1));
    }
  }, [data]);
  
  return (
    <div className="d-flex justify-content-between align-items-center mt-3">
      <div>
        <span>
          Showing {data?.from} to {data?.to} of {data?.total}{' '}
        </span>
      </div>
      <div>
        <Input
          id="exampleSelect"
          name="select"
          type="select"
          style={{ width: '100px' }}
          className="form-select-sm"
          onChange={(e) => setPaginatePerPage(parseInt(e.target.value))}
        >
          <option value="">Per Page</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </Input>
      </div>
      <div>
        <ul className="pagination pagination-rounded justify-content-end mb-0">
          <li
            className={
              data?.current_page === 1 ? 'page-item disabled' : 'page-item'
            }
          >
            <button
              className="page-link"
              onClick={() => setPage((data?.current_page as number) - 1)}
            >
              <i
                style={{ fontSize: '20px' }}
                className="mdi mdi-chevron-left"
              />
            </button>
          </li>
          {links.map((link) => (
            <li
              className={link.active ? 'page-item active' : 'page-item'}
              key={link.label}
            >
              <button className="page-link" onClick={() => setPage(link.label)}>
                {link.label}
              </button>
            </li>
          ))}
          <li
            className={
              data?.current_page === (data?.last_page as number)
                ? 'page-item disabled'
                : 'page-item'
            }
          >
            <button
              className="page-link"
              onClick={() => setPage((data?.current_page as number) + 1)}
            >
              <i
                style={{ fontSize: '20px' }}
                className="mdi mdi-chevron-right"
              />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NotificationPaginate;
