import { useFormik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import { Button, InputField } from "src/components";

const SupportService = () => {
  const { values, errors, touched, handleChange } = useFormik({
    initialValues: {
      demo: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="px-md-4 px-sm-2">
            <h5 className="mb-4">Support Service of the Department</h5>
            <FormGroup className="col-sm-6 col-md-3">
              <Label for="departmentName">Facility</Label>
              <Input type="select" name="departmentName">
                <option value={0}>Select Facility </option>
                <option value={1}>Facility Name</option>
              </Input>
            </FormGroup>

            <FormGroup className="col-sm-6 col-md-3">
              <Label>Number of Academic Stuff Available</Label>
              <InputField
                type="text"
                name="totalCabins"
                placeholder="Enter a number"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>

            <FormGroup className="col-sm-6 col-md-3">
              <Label>Number of Support staff (Technologist) </Label>
              <InputField
                type="text"
                name="totalCabins"
                placeholder="Enter a number"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>
            <FormGroup className="col-sm-6 col-md-3">
              <Label>Essential equipment list </Label>
              <InputField
                type="text"
                name="totalCabins"
                placeholder="Equipment list"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>

            <FormGroup className="col-sm-12 col-md-12">
              <Label>Service offered to department </Label>
              <InputField
                type="textarea"
                name="totalCabins"
                placeholder="Services offered to department"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>

            {/* Table */}
            <Col>
              <div className="mb-3 d-flex flex-row-reverse">
                <Button className="float-right px-3" text={"+ Add"} />
              </div>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-striped table-hover mb-0">
                      <thead>
                        <tr>
                          <th>Facility</th>
                          <th>Number of academic staff (Technologist)</th>
                          <th>Essential Equipments list</th>
                          <th>Service offered to department</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Facility Name</td>
                          <td>123</td>
                          <td>
                            <li>Equipment 1</li>
                            <li>Equipment 2</li>
                          </td>
                          <td>Services</td>
                          <td>
                            <div className="btn btn-sm btn-primary d-inline-block m-1">
                              Edit
                            </div>
                            <div className="btn btn-sm btn-danger d-inline-block">
                              Delete
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <h6 className="mb-3">Library</h6>
            <FormGroup className="col-sm-6 col-md-6">
              <Label>Library Service Name</Label>
              <InputField
                type="text"
                name="totalCabins"
                placeholder="Service Name"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>
            <FormGroup className="col-sm-6 col-md-6">
              <Label>Details</Label>
              <InputField
                type="text"
                name="totalCabins"
                placeholder="Details"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>

            {/* Table */}
            <Col>
              <div className="mb-3 d-flex flex-row-reverse">
                <Button className="float-right px-3" text={"+ Add"} />
              </div>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-striped table-hover mb-0">
                      <thead>
                        <tr>
                          <th>Library Services</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <li>Services 1</li>
                            <li>Services 2</li>
                          </td>
                          <td>
                            <div className="btn btn-sm btn-primary d-inline-block m-1">
                              Edit
                            </div>
                            <div className="btn btn-sm btn-danger d-inline-block">
                              Delete
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <hr />

            <FormGroup className="col-sm-6 col-md-3">
              <Label for="departmentName">Select Items</Label>
              <Input type="select" name="departmentName">
                <option value={0}>Select Item </option>
                <option value={1}>Item 1</option>
              </Input>
            </FormGroup>

            <FormGroup className="col-sm-6 col-md-6">
              <Label>Titles</Label>
              <InputField
                type="text"
                name="totalCabins"
                placeholder="Titles"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>

            <FormGroup className="col-sm-6 col-md-3">
              <Label>Total </Label>
              <InputField
                type="text"
                name="totalCabins"
                placeholder="Enter a number"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>

            {/* Table */}
            {/* Table */}
            <Col>
              <div className="mb-3 d-flex flex-row-reverse">
                <Button className="float-right px-3" text={"+ Add"} />
              </div>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-striped table-hover mb-0">
                      <thead>
                        <tr>
                          <th>Books/Journal/Periodicals</th>
                          <th>Titles</th>
                          <th>Total Books</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Facility Name</td>
                          <td>150</td>
                          <td>123</td>
                          <td>
                            <div className="btn btn-sm btn-primary d-inline-block m-1">
                              Edit
                            </div>
                            <div className="btn btn-sm btn-danger d-inline-block">
                              Delete
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Facility Name</td>
                          <td>150</td>
                          <td>123</td>
                          <td>
                            <div className="btn btn-sm btn-primary d-inline-block m-1">
                              Edit
                            </div>
                            <div className="btn btn-sm btn-danger d-inline-block">
                              Delete
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <hr />
            <FormGroup className="col-sm-6 col-md-3">
              <div className="mb-3">
                <h5 className="font-size-14 mb-3">
                  Electronic Version of Books
                </h5>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                      defaultChecked
                    />
                    <label className="form-check-label">Yes</label>
                  </div>
                </div>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                    />
                    <label className="form-check-label">No</label>
                  </div>
                </div>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-6 col-md-3">
              <div className="mb-3">
                <h5 className="font-size-14 mb-3">
                  Student accessibility to the internet
                </h5>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                      defaultChecked
                    />
                    <label className="form-check-label">Yes</label>
                  </div>
                </div>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                    />
                    <label className="form-check-label">No</label>
                  </div>
                </div>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-6 col-md-3">
              <div className="mb-3">
                <h5 className="font-size-14 mb-3">
                  Does the library have any link with other libraries
                </h5>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                      defaultChecked
                    />
                    <label className="form-check-label">Yes</label>
                  </div>
                </div>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                    />
                    <label className="form-check-label">No</label>
                  </div>
                </div>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-6 col-md-3">
              <div className="mb-3">
                <h5 className="font-size-14 mb-3">
                  Access to electronic journal and publications
                </h5>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                      defaultChecked
                    />
                    <label className="form-check-label">Yes</label>
                  </div>
                </div>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                    />
                    <label className="form-check-label">No</label>
                  </div>
                </div>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-6 col-md-3">
              <div className="mb-3">
                <h5 className="font-size-14 mb-3">
                  Link to WHO website (HINARI)
                </h5>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                      defaultChecked
                    />
                    <label className="form-check-label">Yes</label>
                  </div>
                </div>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                    />
                    <label className="form-check-label">No</label>
                  </div>
                </div>
              </div>
            </FormGroup>

            <hr />

            <h5 className="mb-3">Teaching Infrastructures</h5>

            <FormGroup className="col-sm-6 col-md-4">
              <Label for="departmentName">Physical Facilities</Label>
              <Input type="select" name="departmentName">
                <option value={0}>Select Facility </option>
                <option value={1}>Item 1</option>
              </Input>
            </FormGroup>

            <FormGroup className="col-sm-6 col-md-4">
              <Label>Number</Label>
              <InputField
                type="text"
                name="totalCabins"
                placeholder="Number"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>

            <FormGroup className="col-sm-6 col-md-4">
              <Label>Accommodation/Seats </Label>
              <InputField
                type="text"
                name="totalCabins"
                placeholder="Enter a number"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>

            {/* Table */}
            {/* Table */}
            <Col>
              <div className="mb-3 d-flex flex-row-reverse">
                <Button className="float-right px-3" text={"+ Add"} />
              </div>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-striped table-hover mb-0">
                      <thead>
                        <tr>
                          <th>Physical facilities</th>
                          <th>Number</th>
                          <th>Accommodation/Seats</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Facility Name</td>
                          <td>150</td>
                          <td>123</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Next Button */}
          <div className="text-center my-3">
            <Link to="/dashboard">
              <button
                type="submit"
                className="btn btn-primary w-25"
                onSubmit={(e) => {
                  e.preventDefault;
                }}>
                Submit
              </button>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SupportService;
