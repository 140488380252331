import moment from 'moment';
import React, { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import {
  Container,
  Modal,
  ModalBody,
  ModalHeader
} from 'reactstrap';
import { useGetSupervisorSuspiciousApprovalDetailsQuery } from 'src/rtk/features/logbookMonitoringReport/logbookMonitoringReportApi';

interface Props {
  isOpen: boolean;
  toggle: any;
  supervisorId: string;
  entryDate: string;
}

const SupervisorApprovalDetailsViewModal = (props: Props) => {
  const { isOpen, toggle, supervisorId, entryDate } = props;

  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');

  const generateQueryUrl = () => {
    return `page=${page}&paginate_per_page=${limit}${search}`;
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setPage(page);
  };

  const url = {
    id: supervisorId,
    date: entryDate,
    queryUrl: generateQueryUrl(),
  };

  const { data: logbookEntries, isSuccess, isLoading, error } = useGetSupervisorSuspiciousApprovalDetailsQuery(url);
  console.log(logbookEntries);
  const err: CustomError = error as CustomError;
  const logbookEntryList: LogbookEntryData[] = logbookEntries?.data?.data!;

  const getDetailsData = (key = '', dataObj: any = {}) => {
    let perseData: any = {};

    if (
      dataObj['details_json'] &&
      typeof dataObj['details_json'] === 'string'
    ) {
      perseData = JSON.parse(dataObj.details_json);
    } else {
      perseData = dataObj;
    }

    return perseData[key] ? (
      <span
        dangerouslySetInnerHTML={{
          __html:
            key === 'Diagnosis/Findings' ||
              key === 'Brief Procedural Note / Interpretation'
              ? perseData[key].length <= 50
                ? perseData[key]
                : perseData[key].substring(0, 50) + '...'
              : perseData[key],
        }}
      ></span>
    ) : (
      ''
    );
  };

  const columns: TableColumn<any>[] = [
    {
      name: '#',
      width: '50px',
      cell: (row, index) => <strong>{((page - 1) * limit + index) + 1}</strong>,
    },
    {
      name: 'Date',
      selector: (row) =>
        row.created_at ? moment(row.created_at).format('DD/MMM/YYYY h:mm a') : '',
      sortable: false,
      wrap: true,
    },

    {
      name: 'Form',
      selector: (row) => row.heading,
      sortable: false,
      wrap: true,
    },
    {
      name: 'Case Type',
      selector: (row) => getDetailsData('caseType', row),
      sortable: false,
      wrap: true,
    },

    {
      name: 'Skill Type',
      selector: (row) => getDetailsData('skillType', row),
      sortable: false,
      wrap: true,
    },

    {
      name: 'Patient Name',
      selector: (row) => getDetailsData('Patient Name', row),
      sortable: false,
      wrap: true,
    },

    {
      name: 'Gender',
      selector: (row) => getDetailsData('Gender', row),
      sortable: false,
      wrap: true,
    },

    {
      name: 'Status',
      selector: (row) => row.approved_status,
      cell: (row) => {
        switch (row.approved_status) {
          case 'Reject':
            return (
              <>
                <div className="badge badge-soft-danger font-size-12">
                  {row.approved_status}
                </div>
              </>
            );

          case 'Draft':
            return (
              <>
                <div className="badge badge-soft-dark font-size-12">
                  {row.approved_status}
                </div>
              </>
            );

          case 'Approved':
            return (
              <>
                <div className="badge badge-soft-success font-size-12">
                  {row.approved_status}
                </div>
              </>
            );

          case 'Pending':
            return (
              <>
                <div className="badge badge-soft-info font-size-12">
                  {row.approved_status}
                </div>
              </>
            );

          default:
            return (
              <>
                <div className="badge badge-soft-secondary font-size-12">
                  {row.approved_status}
                </div>
              </>
            );
        }
      },
      sortable: false,
    },
  ];

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="xl">
      <ModalHeader toggle={toggle}>Supervisor Suspicious Logbook Approval List</ModalHeader>
      <ModalBody>
        <React.Fragment>
          <Container fluid>
            {/* <CardHeader className="d-flex justify-content-between">
                <Row>
                  <Col>
                    <div className="gap-2 w-100">
                      <Form
                        className="custom-form"
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit();
                        }}
                        onReset={(e) => {
                          handleFormReset();
                        }}
                      >
                        <div className="filter-wrapper">
                          <FormGroup>
                            <Label for="form">Form</Label>
                            <select
                              className="form-select"
                              placeholder=""
                              id="form"
                              name="form"
                              defaultValue={values.form}
                              onChange={handleChange}
                            >
                              <option className="form-control" value={''}>
                                {'Select...'}
                              </option>
                              <option
                                className="form-control"
                                value={'Patient Management'}
                              >
                                {'Patient Management'}
                              </option>
                              <option
                                className="form-control"
                                value={'Procedural and Interpretative Skills'}
                              >
                                {'Procedural and Interpretative Skills'}
                              </option>
                            </select>
                          </FormGroup>

                          <FormGroup>
                            <Label for="from_date">From Date</Label>
                            <InputField
                              type="date"
                              placeholder=""
                              invalid={{ errors, touched }}
                              {...getFieldProps('from_date')}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label for="to_date">To Date</Label>
                            <InputField
                              type="date"
                              placeholder=""
                              invalid={{ errors, touched }}
                              {...getFieldProps('to_date')}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label for="status">Status</Label>
                            <InputField
                              type="select"
                              placeholder=""
                              options={[
                                { label: 'Draft', value: 'Draft' },
                                { label: 'Approved', value: 'Approved' },
                                { label: 'Pending', value: 'Pending' },
                                { label: 'Reject', value: 'Reject' },
                                { label: 'Correction', value: 'Correction' },
                              ]}
                              invalid={{ errors, touched }}
                              {...getFieldProps('approved_status')}
                            />
                          </FormGroup>

                          <FormGroup className="btn-group btn-group-example mb-3">
                            <Button
                              color="danger"
                              type="reset"
                              text="Reset"
                              onClick={() => {
                                resetForm();
                                handleSubmit();
                              }}
                            />
                            <Button
                              type="button"
                              text="Search"
                              onClick={() => {
                                handleSubmit();
                              }}
                            />
                          </FormGroup>

                          <FormGroup className="ms-auto">
                            <Link
                              to="/e-logbook/logbook-entry/add"
                              className="btn btn-success waves-effect waves-light"
                            >
                              <i className="fa fa-plus me-2" /> Create New
                            </Link>
                          </FormGroup>
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </CardHeader> */}
            <DataTable
              columns={columns}
              data={
                logbookEntryList
                  ? logbookEntryList || []
                  : []
              }
              pagination
              paginationServer
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationTotalRows={logbookEntries?.data?.total}
              expandOnRowClicked
              highlightOnHover
              paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
              persistTableHead={true}
            />
          </Container>
        </React.Fragment>
      </ModalBody>
    </Modal>
  );
};

export default SupervisorApprovalDetailsViewModal;
