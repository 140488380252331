//import Breadcrumbs
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Card,
  CardBody,
  Container,
  Form,
  FormGroup,
  Label,
  Table,
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import {
  useGetApprovalStatusQuery,
  useGetBmdcCertificateApprovalStatusQuery,
  useGetRegInfosByPaginateQuery,
} from 'src/rtk/features/registration-approval/registrationApprovalApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import loader from '../../assets/images/loader.gif';
import RegPaginate from './RegPaginate';
import RegTableRow from './RegTableRow';

interface SearchFields {
  search?: string;
  bcps_reg_no?: string;
  bmdc_reg_no?: string;
  approval_status?: string;
  registration_type?: string;
  subject?: string;
  bmdc_certificate_source_verified?: string | null;
  bmdc_registration_no_verified?: string | null;
}

const initialValues: SearchFields = {
  search: '',
  bcps_reg_no: '',
  bmdc_reg_no: '',
  approval_status: '',
  registration_type: '',
  subject: '',
  bmdc_certificate_source_verified: '',
  bmdc_registration_no_verified: '',
};

const RegInfoList = () => {
  document.title = 'Registration Approval | BCPS';
  const [page, setPage] = useState<number | string>('1');
  const [paginate_per_page, set_paginate_per_page] = useState(20);
  const [search, setSearch] = useState('');
  // const [regInfo, setRegInfo] = useState<RegInfo[]>([]);
  const { data, error, isLoading, isError, isFetching } = useGetRegInfosByPaginateQuery({
    page,
    paginate_per_page,
    search,
  });
  const { data: subjects } = useGetSubjectInfosQuery();

  const allSubjects: SelectOption[] =
    subjects?.data?.map((subject) => ({
      value: subject.id!,
      label: subject.subject_name,
    })) || [];

  const regInfo: RegInfo[] = data?.data?.data || [];

  const err: CustomError = error as CustomError;
  const [selectedSubject, setSelectedSubject] = useState<any>('');

  // useEffect(() => {
  //   if(!isLoading && !isError) {
  //     setRegInfo(data?.data?.data || []);
  //   }
  // }, [isLoading, isError]);

  // what to render
  let content = null;

  if (isLoading) {
    content = (
      <tr>
        <td colSpan={12} className="text-center">
          Loading...
        </td>
      </tr>
    );
  }
  if (!isLoading && isError && error) {
    content = (
      <tr>
        <td colSpan={12} className="text-center">
          {err.message}
        </td>
      </tr>
    );
  }
  if (!isLoading && !isError && regInfo?.length > 0) {
    // console.log('Reg Info: ' + regInfo);

    content = regInfo
      // ?.slice()
      // .sort((a: RegInfo, b: RegInfo) => {
      //   if (a.created_at! > b.created_at!) {
      //     return -1;
      //   } else if (a.created_at! < b.created_at!) {
      //     return 1;
      //   } else {
      //     return 0;
      //   }
      // })
      .map((reg, idx) => (
        <RegTableRow regInfo={reg} index={idx} key={reg.bcps_registration_no} page={page} paginate_per_page={paginate_per_page} />
      ));
  }

  const {
    handleSubmit,
    getFieldProps,
    values,
    touched,
    setFieldValue,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.search !== '') {
        s = s + `&search=${values.search}`;
      }

      if (values.bmdc_reg_no !== '') {
        s = s + `&bmdc_registration_no=${values.bmdc_reg_no}`;
      }

      if (values.approval_status !== '') {
        s =
          s +
          `&profile_approval_status=${values.approval_status
          // approvalStatus.find((a) => a.value === values.approval_status)
          //   ?.label
          }`;
      }

      if (values.registration_type !== '') {
        s =
          s +
          `&registration_type=${registrationType.find((r) => r.value === values.registration_type)
            ?.label
          }`;
      }

      if (values.subject !== '' && values.subject !== undefined) {
        s =
          s +
          `&subject=${allSubjects.find((r) => r.value === values.subject)?.label
          }`;
      }

      if (values.bmdc_registration_no_verified === 'Approve') {
        s =
          s +
          `&bmdc_registration_no_verified=${values.bmdc_registration_no_verified}`;
      } else if (values.bmdc_registration_no_verified === 'Pending') {
        s += `&bmdc_registration_no_verified=${values.bmdc_registration_no_verified}`;
      }

      if (values.bmdc_certificate_source_verified === 'Approve') {
        s =
          s +
          `&bmdc_certificate_source_verified=${values.bmdc_certificate_source_verified}`;
      } else if (values.bmdc_certificate_source_verified === 'Pending') {
        s += `&bmdc_certificate_source_verified=${values.bmdc_certificate_source_verified}`;
      }

      setSearch(s);
      setPage('1');
      set_paginate_per_page(20);

      // const regInfos: RegInfo[] = useGetRegInfosByPaginateQuery({
      //   page,
      //   paginate_per_page,
      //   search,
      // }).data?.data?.data!;

      // setRegInfo(regInfos);

      // console.log(values);
    },
  });

  const registrationType: SelectOption[] =
    useGetLookupDataQuery('registration_type')
      .data?.data[0]?.childs.map((item) => ({
        value: item.id,
        label: item.name,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) || [];

  const { data: approveStatus } = useGetApprovalStatusQuery();

  const approvalStatus: SelectOption[] = approveStatus?.map((item) => ({
    value: item.approve_status!,
    label: item.approve_status!,
  }))!;

  const { data: certificateApproveStatus } =
    useGetBmdcCertificateApprovalStatusQuery();

  const bmdcCertificateApprovalStatus: SelectOption[] =
    certificateApproveStatus?.map((item) => ({
      value: item.approve_status!,
      label: item.approve_status!,
    }))!;

  // const statusList: ApprovalStatus[] =
  //   (approveStatus as any)?.data || [];
  // console.log(approveStatus);

  // // create a search handler function with debounce
  // const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setPage('');
  //   debounce(() => {
  //     setSearch(e.target.value);
  //   }, 300)();
  // };

  useEffect(() => {
    console.log(selectedSubject?.value);
    if (selectedSubject !== '') {
      setFieldValue('subject', selectedSubject?.value);
    }
  }, [selectedSubject]);

  return (
    <div className="page-content">
      {isLoading || isFetching ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb title="Registration Approval" />
        <Card>
          <CardBody>
            <Form
              className="custom-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              onReset={(e) => {
                e.preventDefault();
                setSelectedSubject('');
                resetForm();
                handleSubmit();
              }}
            >
              <div className="filter-wrapper">
                {/* <FormGroup>
                        <Label for="search">Search</Label>
                        <InputField
                          type="text"
                          placeholder="Search"
                          {...getFieldProps('search')}
                        />
                      </FormGroup> */}

                <FormGroup className='extent-field'>
                  <Label for="bmdc_reg_no">BMDC Reg. No.</Label>
                  <InputField
                    type="text"
                    placeholder="BMDC Reg. No."
                    {...getFieldProps('bmdc_reg_no')}
                  />
                </FormGroup>

                <FormGroup className='extent-field'>
                  <Label for="subject">Subject</Label>
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    value={selectedSubject}
                    onChange={setSelectedSubject}
                    options={allSubjects}
                    placeholder='Select...'
                    name="subject"
                  />
                </FormGroup>

                <FormGroup className='extent-field'>
                  <Label for="registration_type">Registration Type</Label>
                  <InputField
                    type="select"
                    placeholder="Registration Type"
                    options={registrationType}
                    {...getFieldProps('registration_type')}
                  />
                </FormGroup>

                <FormGroup className='extent-field'>
                  <Label for="bmdc_registration_no_verified">
                    BMDC Registration
                  </Label>
                  <InputField
                    type="select"
                    placeholder="BMDC Approve"
                    options={[
                      { value: 'Approve', label: 'Approved' },
                      { value: 'Pending', label: 'Pending' },
                    ]}
                    {...getFieldProps('bmdc_registration_no_verified')}
                  />
                </FormGroup>

                {/* <FormGroup>
                        <Label for="bmdc_certificate_source_verified">
                          BMDC Certificate
                        </Label>
                        <InputField
                          type="select"
                          placeholder="BMDC Approve"
                          options={bmdcCertificateApprovalStatus}
                          {...getFieldProps('bmdc_certificate_source_verified')}
                        />
                      </FormGroup> */}

                <FormGroup className='extent-field'>
                  <Label for="approval_status">Profile Status</Label>
                  <InputField
                    type="select"
                    options={[
                      { value: 'Approve', label: 'Approved' },
                      { value: 'Pending', label: 'Pending' },
                      { value: 'Draft', label: 'Draft' },
                      { value: 'Correction', label: 'Correction' },
                      { value: 'Reject', label: 'Rejected' },
                      { value: 'Submit', label: 'Submitted' },
                    ]}
                    {...getFieldProps('approval_status')}
                  />
                </FormGroup>
                <div className="btn-column mb-3">
                  <Button color="warning" type="reset" text="Reset" outline />
                  <Button type="submit" text="Search" />
                </div>
              </div>
            </Form>
            <div className="table-responsive">
              <Table className="table table-striped table-sm mb-0 table-hover">
                <thead>
                  <tr>
                    <th>Sl.</th>
                    <th>BMDC No</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Subject</th>
                    <th>BMDC Exp.</th>
                    <th>Reg. Type</th>
                    <th>Reg. Status</th>
                    <th>Profile Status</th>
                    <th>BMDC Cert.</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
            {!isLoading && (
              <RegPaginate
                data={data}
                setPage={setPage}
                setPaginatePerPage={set_paginate_per_page}
              />
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default RegInfoList;
