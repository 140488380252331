import { apiSlice } from 'src/rtk/api/apiSlice';
import * as examTrainingFeeSetupUrl from 'src/helpers/url_helper';

export const setupExamTrainingFeeApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['ExamTrainingFees'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getExamTrainingFees: builder.query<APIResponse<ExamTrainingFee[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${examTrainingFeeSetupUrl.GET_EXAM_TRAINING_FEES}`,
        }),
        providesTags: ['ExamTrainingFees'],
      }),

      getExamTrainingFee: builder.query<APIResponse<ExamTrainingFee>, string>({
        query: (id) => ({
          url: `${examTrainingFeeSetupUrl.GET_EXAM_TRAINING_FEE}/${id}`,
          method: 'GET',
        }),
        providesTags: ['ExamTrainingFees'],
      }),

      createExamTrainingFee: builder.mutation<
        APIResponse<ExamTrainingFee>,
        ExamTrainingFee
      >({
        query: (data) => ({
          url: `${examTrainingFeeSetupUrl.CREATE_EXAM_TRAINING_FEE}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['ExamTrainingFees'],
      }),

      updateExamTrainingFee: builder.mutation<
        APIResponse<ExamTrainingFee>,
        string
      >({
        query: (url) => ({
          url: `${examTrainingFeeSetupUrl.UPDATE_EXAM_TRAINING_FEE}` + url,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['ExamTrainingFees'],
      }),

      deleteExamTrainingFee: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${examTrainingFeeSetupUrl.DELETE_EXAM_TRAINING_FEE}/${id}`,
        }),
        invalidatesTags: ['ExamTrainingFees'],
      }),
    }),
  });

export const {
  useCreateExamTrainingFeeMutation,
  useGetExamTrainingFeesQuery,
  useGetExamTrainingFeeQuery,
  useUpdateExamTrainingFeeMutation,
  useDeleteExamTrainingFeeMutation,
} = setupExamTrainingFeeApi;
