import { apiSlice } from 'src/rtk/api/apiSlice';
import * as documentFileUploadUrl from 'src/helpers/url_helper';

export const documentFileUploadApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['DocumentFileUploads'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDocumentFileUploads: builder.query<
        APIResponse<DocumentFileUpload[]>,
        void
      >({
        query: () => ({
          method: 'GET',
          url: `${documentFileUploadUrl.GET_DOCUMENT_FILE_UPLOADS}`,
        }),
        providesTags: ['DocumentFileUploads'],
      }),

      getDocumentFileUpload: builder.query<DocumentFileUpload, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${documentFileUploadUrl.GET_DOCUMENT_FILE_UPLOAD}/${id}`,
        }),
        providesTags: ['DocumentFileUploads'],
      }),

      createDocumentFileUpload: builder.mutation<
        APIResponse<DocumentFileUpload>,
        DocumentFileUpload
      >({
        query: (data) => ({
          url: `${documentFileUploadUrl.CREATE_DOCUMENT_FILE_UPLOAD}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['DocumentFileUploads'],
      }),

      updateDocumentFileUpload: builder.mutation<
        APIResponse<DocumentFileUpload>,
        string
      >({
        query: (url) => ({
          url: `${documentFileUploadUrl.UPDATE_DOCUMENT_FILE_UPLOAD}/${url}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['DocumentFileUploads'],
      }),
      deleteDocumentFileUpload: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${documentFileUploadUrl.DELETE_DOCUMENT_FILE_UPLOAD}/${id}`,
        }),
        invalidatesTags: ['DocumentFileUploads'],
      }),
    }),
  });

export const {
  useGetDocumentFileUploadsQuery,
  useGetDocumentFileUploadQuery,
  useCreateDocumentFileUploadMutation,
  useUpdateDocumentFileUploadMutation,
  useDeleteDocumentFileUploadMutation,
} = documentFileUploadApi;
