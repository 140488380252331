import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Modal, Row, Table } from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { setFromErrors } from 'src/helpers';
import { useAppSelector } from 'src/rtk/app/hook';
import { useCreateFeedBackForAssesmentMutation, useCreateMiniCexFormEntryBySupervisorMutation, useLazyGetSessionWiseMiniCexListQuery } from 'src/rtk/features/miniCexCBD/miniCexAndCBDApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';
import PatientInformationUpdate from './PatientInformationUpdate';

const MiniCexEntryBySupervisor = () => {
    const { regNo: registrationNo, id: supervisorId, logbookSessionId: sessionId } = useParams<{
        regNo: string;
        id: string;
        logbookSessionId: string;
    }>();

    const history = useHistory();
    const [getSessionWiseMiniCexList, { data }] = useLazyGetSessionWiseMiniCexListQuery();

    useEffect(() => {
        const url = {
            registrationNo: registrationNo,
            supervisorId: supervisorId
        }
        getSessionWiseMiniCexList(url);
    }, []);
    const authUser = useAppSelector((state) => state.auth.user);

    const userInfo: any = data?.data?.trainee_info || ({} as RegInfo);
    const miniCexInfo: any = data?.data?.mini_cex_trainee?.find((item: any) => item?.assessments === null) || {};

    const minicexAssessment = [
        { id: 1, name: "History Taking", slug: 'history_taking' },
        { id: 2, name: "Physical Examination Skills", slug: 'physical_examination_skills' },
        { id: 3, name: "Communications Skills", slug: 'communications_skills' },
        { id: 4, name: "Clinical Judgement", slug: 'clinical_judgement' },
        { id: 5, name: "Professionalism", slug: 'professionalism' },
        { id: 6, name: "Organization/Efficiency", slug: 'organization_efficiency' },
        { id: 7, name: "Counseling Skills", slug: 'counseling_skills' },
        { id: 8, name: "Overall Clinical Competence", slug: 'overall_clinical_competence' },
    ];
    const [miniCexFormBdata, setMiniCexFormBdata] = useState<any>({});
    const [assesorSatisfication, setAssesorSatisfication] = useState<number>();

    const [modalShow, setModalShow] = useState(false);

    const [assesmentOption, setAssesmentOption] = useState(Array(minicexAssessment.length).fill(''));

    const allOptions: SelectOption[] = [
        { label: 'Not Observed', value: 0 },
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
        { label: '8', value: 8 },
        { label: '9', value: 9 },
    ];

    const [createMiniCexFormEntryBySupervisor, { isSuccess, isError, error, isLoading: isCreateLoading }] = useCreateMiniCexFormEntryBySupervisorMutation();
    const [createFeedBackForAssesment, { isSuccess: isFeedBackSuccess, isError: isFeedBackError, error: feedBackError, isLoading: isFeedBackLoading }] = useCreateFeedBackForAssesmentMutation();

    const err: CustomError = error as CustomError;
    const err1: CustomError = feedBackError as CustomError;

    const initialValues = {
        registration_id: '',
        supervisor_id: '',
        logbook_entry_session_id: '',
        trainee_form_id: '',
        date_of_assessment: new Date(Date.now()).toISOString().slice(0, 10),
        input_validation_supervisor: false,
        overall_performance: '',
        areas_of_strength: '',
        suggestions_for_development: '',
        evaluation_time: '',
        feedback_time: '',
    }

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({
            date_of_assessment: Yup.date().max(new Date(Date.now()), 'Date of assessment can\'t be a future date').required(),
            overall_performance: Yup.string().required('This is Required'),
            areas_of_strength: Yup.string().required('This is Required'),
            suggestions_for_development: Yup.string().required('This is Required'),
        }),

        onSubmit: (values: any) => {
            values.competencies = [];

            values.registration_id = userInfo?.registration_no;
            values.supervisor_id = userInfo?.current_session_and_supervisor_info?.supervisor?.id;
            values.logbook_entry_session_id = userInfo?.current_session_and_supervisor_info?.id;
            values.trainee_form_id = miniCexInfo?.id;

            Object.keys(miniCexFormBdata).forEach((key) => {
                values.competencies.push(miniCexFormBdata[key]);
            })

            if (values.competencies.length === minicexAssessment.length) {
                createMiniCexFormEntryBySupervisor(values);
            }
            else {
                toast.error('Please Select all assesment');
            }
        },
    });


    useEffect(() => {
        if (isSuccess) {
            const arr = Array(minicexAssessment.length).fill('');
            setAssesmentOption(arr);
            setMiniCexFormBdata({});
            resetForm();
            setModalShow(true);
            toast.success('Information Stored Successfully!');
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);


    const handleCompetences = (e: any, competence: string, id: string) => {
        const item = { ...miniCexFormBdata };

        if (e.target.value) {
            item[id] = {
                competence: competence,
                perform: e.target.value,
                descriptor: item[id]?.descriptor ?? '',
            }
        }
        setMiniCexFormBdata(item);
    }

    const handleCompetencesRemarks = (e: any, id: string) => {
        const item = { ...miniCexFormBdata };
        if (e.target.value) {
            item[id] = {
                competence: item[id]?.competence ?? '',
                perform: item[id]?.perform ?? '',
                descriptor: e.target.value,
            }
        }
        setMiniCexFormBdata(item);
    }
    const performanceNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    const performanceNumber2 = [
        { label: '1', value: 1, indicator: 'Unsatisfactory' },
        { label: '2', value: 2, indicator: 'Unsatisfactory' },
        { label: '3', value: 3, indicator: 'Unsatisfactory' },
        { label: '4', value: 4, indicator: 'Satisfactory' },
        { label: '5', value: 5, indicator: 'Satisfactory' },
        { label: '6', value: 6, indicator: 'Satisfactory' },
        { label: '7', value: 7, indicator: 'Superior' },
        { label: '8', value: 8, indicator: 'Superior' },
        { label: '9', value: 9, indicator: 'Superior' },
        { label: 'Not Observed', value: 0, indicator: 'Not Observed' },
    ];

    const feedBackData = {
        logbook_session_id: sessionId,
        user_id: authUser?.id,
        satisfactory_label: assesorSatisfication,
        type: 'mini-cex-supervisor'
    };

    useEffect(() => {
        if (isFeedBackSuccess) {
            resetForm();
            toast.success('Feedback Given Successfully!');
            setModalShow(false);
            history.push('/mini-cex-list');
        }
        if (isFeedBackError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isFeedBackSuccess, isFeedBackError]);

    return (
        <div className='page-content'>
            {isCreateLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Container fluid>
                <BetterBreadcrumb
                    breadcrumbItem={[{ link: '/mini-cex-list', name: 'Mini-Cex' }]}
                    title={'Mini Clinical Evaluation Exercise'}
                />
                <Card className='mt-2'>
                    <CardHeader>
                        <h4 className='text-center'><u>Mini Clinical Evaluation Exercise (MINI-CEX)</u></h4>
                        <Row>
                            <Col>
                                <div>
                                    <span>
                                        <strong>Name of the Institution: </strong>
                                    </span>
                                    <span>
                                        {userInfo?.current_session_and_supervisor_info?.supervisor?.institute_name}
                                    </span>
                                </div>
                            </Col>
                            <Col>
                                <div className='row justify-content-end'>
                                    <div className='col-sm-3'>
                                        <strong>Date of Examination : </strong>
                                    </div>
                                    <div className='col-sm-4'>
                                        <InputField
                                            type="date"
                                            placeholder=""
                                            invalid={{ errors, touched }}
                                            name='date_of_assessment'
                                            value={values.date_of_assessment}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <div className="row">
                            <div className="col-md-6 col-sm-12 mb-2">
                                <h5 className="block-heading">1. Trainee Information</h5>
                                <table className="table table-bordered table-striped table-sm">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <strong>Name Of the Trainee: </strong>
                                                {userInfo?.personal_info?.full_name ?? 'N/A'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>BMDC Reg No.: </strong>
                                                {userInfo?.bmdc_registration_no ?? 'N/A'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Speciality: </strong>
                                                {userInfo?.subject?.subject_name ?? 'N/A'}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <strong>Program: </strong>
                                                {'FCPS Part-2'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Year of Training: </strong>
                                                {userInfo?.current_session_and_supervisor_info?.slot_info?.training_type === 'Core' &&
                                                    <div>
                                                        <span>
                                                            <strong><u>FCPS Core: </u></strong>
                                                        </span>
                                                        <span>
                                                            {userInfo?.current_session_and_supervisor_info?.slot_info?.name ?? 'N/A'}
                                                        </span>
                                                    </div>
                                                }
                                                {userInfo?.current_session_and_supervisor_info?.slot_info?.training_type === 'Advance' &&
                                                    <div>
                                                        <span>
                                                            <strong><u>FCPS Speciality: </u></strong>
                                                        </span>
                                                        <span>
                                                            {userInfo?.current_session_and_supervisor_info?.slot_info?.name ?? 'N/A'}
                                                        </span>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                                <h5 className="block-heading">2. Assessor's Information</h5>
                                <table className="table table-bordered table-striped table-sm">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <strong>Name of the assessor: </strong>
                                                {userInfo?.current_session_and_supervisor_info?.supervisor?.name ?? 'N/A'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Assessor’s specialty: </strong>
                                                {userInfo?.current_session_and_supervisor_info?.supervisor?.department_name ?? 'N/A'}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <strong>Assessor’s position: </strong>
                                                {userInfo?.current_session_and_supervisor_info?.supervisor?.designation ?? 'N/A'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Assessor’s phone number: </strong>
                                                {userInfo?.current_session_and_supervisor_info?.supervisor?.mobile ?? 'N/A'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Assessor’s email: </strong>
                                                {userInfo?.current_session_and_supervisor_info?.supervisor?.email ?? 'N/A'}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <PatientInformationUpdate
                            registrationNo={registrationNo}
                            sessionId={sessionId}
                            supervisorId={supervisorId}
                            miniCexInfo={miniCexInfo}
                        />

                        {/* Supervisor Part Starts From Here */}

                        <Form
                            className="custom-form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                            onReset={(e) => {
                                // e.preventDefault();
                                setMiniCexFormBdata({});
                                const arr = Array(minicexAssessment.length).fill('');
                                setAssesmentOption(arr);
                                resetForm();
                            }}
                        >
                            <Row>
                                <Col>
                                    <h5 className='block-heading'>5. Input Validation
                                        <i
                                            className="fas fa-info-circle mx-1"
                                            title="(Assessor/supervisor checks the electronic record against this case record)"
                                        ></i>
                                    </h5>
                                    <div className='form-check form-check-inline col-md-3'>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="input_validation_supervisor"
                                            id="validate-supervisor"
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setFieldValue('input_validation_supervisor', true);
                                                }
                                                else {
                                                    setFieldValue('input_validation_supervisor', false);
                                                }
                                            }}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="validate-supervisor"
                                        >
                                            Input Validated by Assessor/Supervisor
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            {values.input_validation_supervisor &&
                                <div>
                                    <Row className='mt-3'>
                                        <Col>
                                            <h5 className='block-heading'>6. Assesment
                                                <i
                                                    className="fas fa-info-circle mx-1"
                                                    title="Different competencies to be assessed through mini-CEX. All the competencies should not be assessed in a single encounter. However, there must be effort to assess all these competencies through multiple planned mini-CEX during the training slot. Supervisors should try to select gradually difficult cases as the trainee enters different year of training. The assessor/supervisor will plan to assess say history taking or physical examination or communication skill in a particular mini-CEX. As the candidate performs, the Supervisor will mark different section in a 1-9 scale"
                                                ></i>
                                            </h5>
                                            <div className='performance-assesment-indicator-wrapper'>
                                                {minicexAssessment.map((item: any, index: number) => (
                                                    <div className='performance-assesment-indicator' key={item.id}>
                                                        <div>
                                                            {item.name}
                                                        </div>
                                                        <div className='performance-assesment-wrapper'>
                                                            {performanceNumber2?.map((perfomance: any) => (
                                                                <div key={perfomance?.value}>
                                                                    <input
                                                                        type="radio"
                                                                        name={item?.slug}
                                                                        value={perfomance?.value}
                                                                        id={index.toString() + '-' + perfomance?.value}
                                                                        onChange={(e: any) => {
                                                                            handleCompetences(e, item.name, item.id);
                                                                            const assesment = [...assesmentOption];
                                                                            assesment[index] = e.target.value;
                                                                            setAssesmentOption(assesment);
                                                                            // console.log(assesment)
                                                                        }}
                                                                    />
                                                                    <label className={perfomance?.indicator} htmlFor={index.toString() + '-' + perfomance?.value}><span>{perfomance?.indicator}</span>{perfomance?.label}</label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div>
                                                            <InputField
                                                                type="text"
                                                                placeholder="Remarks"
                                                                name='remarks'
                                                                onChange={(e: any) => {
                                                                    handleCompetencesRemarks(e, item.id);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mt-3'>
                                        <h5 className='block-heading'>7. Overall Perfromance</h5>
                                        <Col sm={4}>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="overall_performance"
                                                    value="Competent"
                                                    onChange={(e) => {
                                                        setFieldValue("overall_performance", 'Competent');
                                                    }}
                                                    id="competent"
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="competent"
                                                >
                                                    Competent
                                                </label>
                                            </div>

                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="overall_performance"
                                                    value="Not-Compentent"
                                                    onChange={(e) => {
                                                        setFieldValue("overall_performance", 'Not-Compentent');
                                                    }}
                                                    id="not_competent"
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="not_competent"
                                                >
                                                    Not yet competent/Needs repetition
                                                </label>
                                            </div>
                                            {errors.overall_performance && touched.overall_performance ? (
                                                <div className="text-danger error-size">
                                                    {errors.overall_performance as string}
                                                </div>
                                            ) : null}
                                        </Col>
                                    </Row>

                                    <Row className='mt-3'>
                                        <Col sm={6}>
                                            <Label>Comment of Areas of Strength of the trainee:
                                                <i
                                                    className="fas fa-info-circle mx-1"
                                                    title="(The assessor/supervisor writes a comment stating the areas of strength of the trainee)"
                                                ></i>
                                            </Label>
                                            <FormGroup>
                                                <InputField
                                                    type="textarea"
                                                    placeholder="Comment of Areas of Strength of the trainee"
                                                    invalid={{ errors, touched }}
                                                    {...getFieldProps('areas_of_strength')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={6}>
                                            <Label>Suggestions for further Development/Improvement:
                                                <i
                                                    className="fas fa-info-circle mx-1"
                                                    title="(The assessor must complete this section and submit)"
                                                ></i>
                                            </Label>
                                            <FormGroup>
                                                <InputField
                                                    type="textarea"
                                                    placeholder="Suggestions for Development/Improvement"
                                                    invalid={{ errors, touched }}
                                                    {...getFieldProps('suggestions_for_development')}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={12}>
                                            <Table className='table table-bordered'>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <div className='d-flex gap-2'>
                                                                <div>Total Mini-CEX Evaluation Time:</div>
                                                                <div className='col-2'>
                                                                    <InputField
                                                                        type="number"
                                                                        min={'1'}
                                                                        invalid={{ errors, touched }}
                                                                        {...getFieldProps('evaluation_time')}
                                                                    />
                                                                </div>
                                                                <div>Minutes</div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className='d-flex gap-2'>
                                                                <div>Time spent giving Feedback:</div>
                                                                <div className='col-2'>
                                                                    <InputField
                                                                        type="number"
                                                                        min={'1'}
                                                                        invalid={{ errors, touched }}
                                                                        {...getFieldProps('feedback_time')}
                                                                    />
                                                                </div>
                                                                <div>Minutes</div>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                            </Table>
                                        </Col>
                                    </Row>

                                    <FormGroup className="mb-3 text-center mt-3">
                                        <Button
                                            type="reset"
                                            className="btn btn-secondary me-2"
                                        >
                                            Reset
                                        </Button>

                                        <button
                                            type="submit"
                                            className="btn btn-success"
                                        >
                                            Submit
                                        </button>
                                    </FormGroup>
                                </div>
                            }
                        </Form>
                    </CardBody>
                </Card>
                <Modal
                    onOpened={function () {
                        toast.dismiss();
                    }}
                    isOpen={modalShow}
                    toggle={() => {
                        document.body.classList.add('no_padding');
                    }}
                    backdrop={'static'}
                    modalTransition={{ timeout: 0 }}
                    backdropTransition={{ timeout: 0 }}
                    size='xl'
                >
                    <div className="modal-header">
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                                setModalShow(!modalShow);
                                history.push('/mini-cex-list');
                            }}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col sm={3}>
                                <Label>Satisfaction using Mini-CEX</Label>
                            </Col>
                            <Col sm={8}>
                                <span className='fw-bold'>LOW</span>
                                {performanceNumber?.map((item) => (
                                    <div key={item} className='form-check form-check-inline'>
                                        <button
                                            type='button'
                                            className={assesorSatisfication === item ? "btn btn-primary rounded-pill" : "btn btn-outline-primary rounded-pill"}
                                            onClick={() => {
                                                setAssesorSatisfication(item);
                                            }}
                                        >
                                            {item}
                                        </button>
                                    </div>
                                ))}
                                <span className='fw-bold'>HIGH</span>
                            </Col>
                        </Row>
                        <FormGroup className="text-end mt-3">
                            <button
                                type="button"
                                className="btn btn-danger me-1"
                                onClick={() => {
                                    setModalShow(!modalShow);
                                    history.push('/mini-cex-list');
                                }}
                            >
                                Skip
                            </button>

                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={() => {
                                    createFeedBackForAssesment(feedBackData);
                                }}
                                disabled={assesorSatisfication === undefined}
                            >
                                Submit
                            </button>
                        </FormGroup>
                    </div>
                </Modal>
            </Container>
        </div>
    );
};

export default MiniCexEntryBySupervisor;
