import React from 'react';

import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
    Card,
    CardBody,
    CardGroup,
    CardText,
    CardTitle,
    Col,
    Container,
    Input,
    Row,
    Table
} from "reactstrap";
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { currency } from 'src/helpers';
import * as url from 'src/helpers/url_helper';
import {
    useGetDataQuery,
} from 'src/rtk/features/common/crudApi';

const AccountantDashboard = (props: any) => {
    const { mainDashboard } = props;
    document.title = 'Accountant Dashboard | BCPS';


    var totalPayment: number = 0;
    var numOfPayers: number = 0;

    // const [totalPayment, setTotalPayment] = useState<any>(0);
    const [page, setPage] = useState<any>(1);
    const [limit, setLimit] = useState<any>(10);
    const [search, setSearch] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const generateQueryUrl = () => {
        let queryString = '?';
        if (url.PAYMENT_REPORTS_LIST_ADMIN.includes("?")) {
            queryString = '&';
        }

        // return `${url.PAYMENT_REPORTS_LIST_ADMIN}${queryString}page=${page}&paginate_per_page=${limit}${search}`;
        return `${url.PAYMENT_REPORTS_LIST_ADMIN}${queryString}${search}`;
    };

    const {
        data: dataList,
        isLoading: dataListLoading,
        isSuccess: dataListLoadingSuccess,
        error: dataListLoadingError,
    } = useGetDataQuery(generateQueryUrl());
    const data: any = dataList?.data || [];

    // console.log(data)

    useEffect(() => {
        if (dataListLoadingError) {
            toast.dismiss();
            toast.error('Data Loading error. Please try again later.');
        } else if (dataListLoading || !dataListLoadingSuccess) {
            toast.dismiss();
            // toast.loading('Data Loading...');
        } else {
            toast.dismiss();
            // toast.success('Data Load Successfully');
        }
    }, [dataListLoading, dataListLoadingSuccess, dataListLoadingError]);

    return (
        <>
            {
                mainDashboard ?
                    <React.Fragment>
                        <BetterBreadcrumb title='Accountant Dashboard' />
                        <Container fluid>
                            <Row>
                                <Col xl='12'>
                                    <Card className=''>
                                        <CardBody>
                                            <h4>Transaction Status</h4>
                                            <div className='mt-3'>
                                                <CardGroup>
                                                    <Card body inverse color="info" className='text-center fw-bold rounded me-3'>
                                                        <CardTitle>Total Receive</CardTitle>
                                                        <CardText>20,00,000</CardText>
                                                        <h5 className='text-white fw-bold'>BDT</h5>
                                                    </Card>
                                                    <Card body inverse color="success" className='text-center fw-bold rounded me-3'>
                                                        <CardTitle>Online</CardTitle>
                                                        <CardText>90,000</CardText>
                                                        <h5 className='text-white fw-bold'>BDT</h5>
                                                    </Card>
                                                    <Card body inverse color="secondary" className='text-center fw-bold rounded me-3'>
                                                        <CardTitle>Offline</CardTitle>
                                                        <CardText>1,10,000</CardText>
                                                        <h5 className='text-white fw-bold'>BDT</h5>
                                                    </Card>
                                                    <Card body color="light" className='text-center fw-bold rounded me-3'>
                                                        <CardTitle>Number of Transaction</CardTitle>
                                                        <CardText>1200</CardText>
                                                        <h5 className='text-dark fw-bold'>#</h5>
                                                    </Card>
                                                    <Card body color="warning" className='text-center fw-bold rounded'>
                                                        <CardTitle>Verify Pending</CardTitle>
                                                        <CardText>45</CardText>
                                                        <h5 className='text-dark fw-bold'>#</h5>
                                                    </Card>
                                                </CardGroup>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Card className=''>
                                <CardBody>
                                    <Row>
                                        <Col xl="12">
                                            <Row>
                                                <Col xl="6">
                                                    <Card className=''>
                                                        <CardBody>

                                                            <Table className="table-nowrap align-middle mb-0 table-bordered table-hover">
                                                                <thead>
                                                                    <tr className="text-center bg-light">
                                                                        <th colSpan={11}>Payment Summery Report</th>
                                                                    </tr>
                                                                    <tr className="text-center ">
                                                                        {/* <th > Date</th> */}
                                                                        <th colSpan={11} >
                                                                            <div className="input-group ">
                                                                                <button type="button"
                                                                                    onClick={(e) => {
                                                                                        setSearch(`from_date=${fromDate}&to_date=${toDate}`)
                                                                                    }}
                                                                                    className="btn btn-sm btn-success">
                                                                                    {dataListLoading ? 'Searching...' : 'Search'}
                                                                                </button>
                                                                                <button type="button"
                                                                                    onClick={(e) => {
                                                                                        setFromDate("");
                                                                                        setToDate("");
                                                                                        setSearch(`from_date=&to_date=`)
                                                                                    }}
                                                                                    className="btn btn-sm btn-danger">
                                                                                    {dataListLoading ? 'Searching...' : 'Clear'}
                                                                                </button>
                                                                                {/* <span className="input-group-text">Date</span> */}
                                                                                <Input
                                                                                    className="form-control form-control-sm"
                                                                                    name="from_date"
                                                                                    type="date"
                                                                                    placeholder="From Date"
                                                                                    value={fromDate}
                                                                                    onChange={(e) => {
                                                                                        setFromDate(e.target.value)
                                                                                    }}
                                                                                />

                                                                                <Input
                                                                                    className="form-control form-control-sm"
                                                                                    name="to_date"
                                                                                    type="date"
                                                                                    placeholder="To Date"
                                                                                    value={toDate}
                                                                                    onChange={(e) => {
                                                                                        setToDate(e.target.value)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                    <tr className="text-center">
                                                                        <th style={{ width: "70px" }}>SL#</th>
                                                                        <th>Payment By</th>
                                                                        <th>Number of Payers</th>
                                                                        <th
                                                                            style={{ width: "250px" }}
                                                                        >
                                                                            Amount
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {data.total_payment_count_by_payment_for?.map(
                                                                        (item: any, key: number) => {
                                                                            totalPayment += Number(item.total_amount_sum);
                                                                            numOfPayers += Number(item.total_count);

                                                                            return (
                                                                                <tr className="text-center" key={key}>
                                                                                    <td>
                                                                                        <span className="badge rounded-pill bg-info text-dark">{key + 1}</span>
                                                                                    </td>
                                                                                    <td className="text-start">
                                                                                        <h5 className="font-size-15 mb-1">
                                                                                            {item.payment_for}
                                                                                        </h5>
                                                                                    </td>
                                                                                    <td >{item.total_count}</td>

                                                                                    <td >{currency(item.total_amount_sum)}</td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    )}
                                                                    <tr className='bg-light'>
                                                                        <td colSpan={2} className="text-end">
                                                                            <strong>Total =</strong>
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <h4 className="m-0">
                                                                                {numOfPayers}
                                                                            </h4>
                                                                        </td>
                                                                        <td className="border-0 text-center">
                                                                            <h4 className="m-0">
                                                                                {currency(totalPayment)}
                                                                            </h4>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>

                                                            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Container>
                    </React.Fragment>
                    :
                    <div className='page-content'>
                        <React.Fragment>
                            <BetterBreadcrumb title='Accountant Dashboard' />
                            <Container fluid>
                                <Row>
                                    <Col xl='12'>
                                        <Card className=''>
                                            <CardBody>
                                                <h4>Transaction Status</h4>
                                                <div className='mt-3'>
                                                    <CardGroup>
                                                        <Card body inverse color="info" className='text-center fw-bold rounded me-3'>
                                                            <CardTitle>Total Receive</CardTitle>
                                                            <CardText>20,00,000</CardText>
                                                            <h5 className='text-white fw-bold'>BDT</h5>
                                                        </Card>
                                                        <Card body inverse color="success" className='text-center fw-bold rounded me-3'>
                                                            <CardTitle>Online</CardTitle>
                                                            <CardText>90,000</CardText>
                                                            <h5 className='text-white fw-bold'>BDT</h5>
                                                        </Card>
                                                        <Card body inverse color="secondary" className='text-center fw-bold rounded me-3'>
                                                            <CardTitle>Offline</CardTitle>
                                                            <CardText>1,10,000</CardText>
                                                            <h5 className='text-white fw-bold'>BDT</h5>
                                                        </Card>
                                                        <Card body color="light" className='text-center fw-bold rounded me-3'>
                                                            <CardTitle>Number of Transaction</CardTitle>
                                                            <CardText>1200</CardText>
                                                            <h5 className='text-dark fw-bold'>#</h5>
                                                        </Card>
                                                        <Card body color="warning" className='text-center fw-bold rounded'>
                                                            <CardTitle>Verify Pending</CardTitle>
                                                            <CardText>45</CardText>
                                                            <h5 className='text-dark fw-bold'>#</h5>
                                                        </Card>
                                                    </CardGroup>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Card className=''>
                                    <CardBody>
                                        <Row>
                                            <Col xl="12">
                                                <Row>
                                                    <Col xl="6">
                                                        <Card className=''>
                                                            <CardBody>

                                                                <Table className="table-nowrap align-middle mb-0 table-bordered table-hover">
                                                                    <thead>
                                                                        <tr className="text-center bg-light">
                                                                            <th colSpan={11}>Payment Summery Report</th>
                                                                        </tr>
                                                                        <tr className="text-center ">
                                                                            {/* <th > Date</th> */}
                                                                            <th colSpan={11} >
                                                                                <div className="input-group ">
                                                                                    <button type="button"
                                                                                        onClick={(e) => {
                                                                                            setSearch(`from_date=${fromDate}&to_date=${toDate}`)
                                                                                        }}
                                                                                        className="btn btn-sm btn-success">
                                                                                        {dataListLoading ? 'Searching...' : 'Search'}
                                                                                    </button>
                                                                                    <button type="button"
                                                                                        onClick={(e) => {
                                                                                            setFromDate("");
                                                                                            setToDate("");
                                                                                            setSearch(`from_date=&to_date=`)
                                                                                        }}
                                                                                        className="btn btn-sm btn-danger">
                                                                                        {dataListLoading ? 'Searching...' : 'Clear'}
                                                                                    </button>
                                                                                    {/* <span className="input-group-text">Date</span> */}
                                                                                    <Input
                                                                                        className="form-control form-control-sm"
                                                                                        name="from_date"
                                                                                        type="date"
                                                                                        placeholder="From Date"
                                                                                        value={fromDate}
                                                                                        onChange={(e) => {
                                                                                            setFromDate(e.target.value)
                                                                                        }}
                                                                                    />

                                                                                    <Input
                                                                                        className="form-control form-control-sm"
                                                                                        name="to_date"
                                                                                        type="date"
                                                                                        placeholder="To Date"
                                                                                        value={toDate}
                                                                                        onChange={(e) => {
                                                                                            setToDate(e.target.value)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                        <tr className="text-center">
                                                                            <th style={{ width: "70px" }}>SL#</th>
                                                                            <th>Payment By</th>
                                                                            <th>Number of Payers</th>
                                                                            <th
                                                                                style={{ width: "250px" }}
                                                                            >
                                                                                Amount
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {data?.total_payment_count_by_payment_for?.map(
                                                                            (item: any, key: number) => {
                                                                                totalPayment += Number(item.total_amount_sum);
                                                                                numOfPayers += Number(item.total_count);

                                                                                return (
                                                                                    <tr className="text-center" key={key}>
                                                                                        <td>
                                                                                            <span className="badge rounded-pill bg-info text-dark">{key + 1}</span>
                                                                                        </td>
                                                                                        <td className="text-start">
                                                                                            <h5 className="font-size-15 mb-1">
                                                                                                {item.payment_for}
                                                                                            </h5>
                                                                                        </td>
                                                                                        <td >{item.total_count}</td>

                                                                                        <td >{currency(item.total_amount_sum)}</td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        )}
                                                                        <tr className='bg-light'>
                                                                            <td colSpan={2} className="text-end">
                                                                                <strong>Total =</strong>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <h4 className="m-0">
                                                                                    {numOfPayers}
                                                                                </h4>
                                                                            </td>
                                                                            <td className="border-0 text-center">
                                                                                <h4 className="m-0">
                                                                                    {currency(totalPayment)}
                                                                                </h4>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>

                                                                {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Container>
                        </React.Fragment>
                    </div>
            }
        </>
    );
};

export default AccountantDashboard;
