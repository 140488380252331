import React from "react";

class DropDownComponent extends React.PureComponent<{
    field: any, form: any,
    handleChangeSection: any, fieldName: any, required: any, label: any, placeholder: any, options: any, fullWidth: any, margin: any
}> {
    render() {
        const {
            handleChangeSection,
            fieldName,
            required,
            label,
            placeholder,
            form: {dirty, touched, errors, setFieldValue},
            field,
            options,
            fullWidth,
            margin,
            ...other
        } = this.props;
        const errorText = errors[field.name];
        const hasError = dirty && touched[field.name] && errorText !== undefined;
        const renderValue = (value: any) => {
            return JSON.stringify(value);
        };
        return (
            <div className="form-group">
                {/*<label htmlFor={field.name}>{label}</label>*/}
                <select
                    className="form-control"
                    {...field}
                    value={renderValue(field.value)}
                    onChange={event => {
                        setFieldValue(event.target.name, JSON.parse(event.target.value));
                        if (
                            handleChangeSection !== undefined &&
                            handleChangeSection !== null &&
                            handleChangeSection !== ""
                        ) {
                            handleChangeSection(
                                event.target.name,
                                JSON.parse(event.target.value)
                            );
                        }
                    }}
                    {...other}
                >
                    <option value={""}>{placeholder}</option>
                    {options && options.length
                        ? options.map((item: { [x: string]: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }, index: React.Key | null | undefined) => (
                            <option value={renderValue(item)} key={index}>
                                {item[fieldName]}
                            </option>
                        ))
                        : null}
                </select>
                {hasError && <small>{errorText}</small>}
            </div>
        );
    }
}

export default DropDownComponent;