import { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import FirstReviewerComments from './FirstReviewerComments';
import { useGetDissertationReviewerCommentListQuery } from 'src/rtk/features/disertation-thesis/dissertationListForAssigningReviewerApi';
import SecondReviewerComments from './SecondReviewerComments';
import ThirdReviewerComments from './ThirdReviewerComments';

const ReviewerComments = (props: any) => {
    const { thesisId } = props;
    const [activeTab, setActiveTab] = useState('reviewer1');
    const { data, isError, error, isSuccess, isLoading } = useGetDissertationReviewerCommentListQuery(thesisId);

    const toggleTab = (tab: string) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    return (
        <div>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={activeTab === 'reviewer1' ? 'active' : ''}
                        onClick={() => toggleTab('reviewer1')}
                    >
                        Reviewer 1
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={activeTab === 'reviewer2' ? 'active' : ''}
                        onClick={() => toggleTab('reviewer2')}
                    >
                        Reviewer 2
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={activeTab === 'reviewer3' ? 'active' : ''}
                        onClick={() => toggleTab('reviewer3')}
                    >
                        Reviewer 3
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="reviewer1">
                    {data?.first_reviewer_id ? <FirstReviewerComments reviewerComments={data} /> : <h4 className='mt-4'>No comment available for reviewer 1</h4>}
                </TabPane>
                <TabPane tabId="reviewer2">
                    {data?.second_reviewer_id ? <SecondReviewerComments reviewerComments={data} /> : <h4 className='mt-4'>No comment available for reviewer 2</h4>}
                </TabPane>
                <TabPane tabId="reviewer3">
                    {data?.third_reviewer_id ? <ThirdReviewerComments reviewerComments={data} /> : <h4 className='mt-4'>No comment available for reviewer 3</h4>}
                </TabPane>
            </TabContent>
        </div>
    );
};

export default ReviewerComments;
