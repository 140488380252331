//import Breadcrumbs
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Container, Form, Label, Table } from 'reactstrap';
import { Button } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetPermissionsQuery } from 'src/rtk/features/permissions/permissionApi';
import { useCreateRoleMutation } from 'src/rtk/features/roles/roleApi';
import {
  allCapabilities,
  handleSelectAllCheckbox,
  handleSingleCheckbox,
} from './handler';

export interface PermissionWithChecked {
  name: string;
  isChecked: boolean;
  capabilities: {
    module: string;
    name: string;
    display_name: string;
    isChecked: boolean;
  }[];
}

const AddRole = () => {
  const [createRole, { isSuccess, isError, error }] = useCreateRoleMutation();
  const { data: permissionData, isLoading: isPermissionLoading } =
    useGetPermissionsQuery();
  const err: CustomError = error as CustomError;
  const permission = permissionData?.data;

  const [name, setName] = useState('');
  const [selectedPermission, setSelectedPermission] = useState(['']);
  const [permissionWithChecked, setPermissionWithChecked] = useState<
    PermissionWithChecked[]
  >([]);

  useEffect(() => {
    if (permission) {
      setPermissionWithChecked(permission);
    }
  }, [permission]);

  const selectAllCheckboxChange =
    (_name: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const permissions = handleSelectAllCheckbox(
        _name,
        e,
        permissionWithChecked,
      );
      setPermissionWithChecked(permissions);
    };

  const singleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const permissions = handleSingleCheckbox(e, permissionWithChecked);
    setPermissionWithChecked(permissions);
  };

  useEffect(() => {
    const allCap = allCapabilities(permissionWithChecked);
    setSelectedPermission(allCap);
  }, [permissionWithChecked]);

  const submitHandler = (e: SyntheticEvent) => {
    e.preventDefault();
    if (selectedPermission.length === 0) {
      alert('Please select at least one permission');
      return;
    }
    if (name === '') {
      alert('Please enter role name');
      return;
    }
    createRole({
      name,
      permissions: selectedPermission,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Role created successfully');
      window.location.reload();
    }
    if (isError) {
      toast.error(err.message || 'Something went wrong');
    }
  }, [isSuccess]);

  const TableContent = () => {
    return (
      <Form className="custom-form pt-2" onSubmit={submitHandler}>
        <div className="mb-3">
          <Label className="form-label">Role Name</Label>
          <input
            className="form-control"
            name="name"
            type="text"
            placeholder="Enter Role Name"
            defaultValue={name}
            onBlur={(e) => setName(e.target.value)}
          />
        </div>
        <Table className="table table-bordered mb-0">
          <thead>
            <tr>
              <th>Features</th>
              <th>Capabilities</th>
            </tr>
          </thead>
          <tbody>
            {isPermissionLoading && (
              <tr>
                <td colSpan={2}>Loading...</td>
              </tr>
            )}
            {!isPermissionLoading &&
              permissionWithChecked.map((permission) => (
                <tr key={permission.name}>
                  <td>{permission.name}</td>
                  <td>
                    <div>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="permissions-checkedAll"
                        defaultChecked={permission.isChecked}
                        onChange={selectAllCheckboxChange(permission.name)}
                      />
                      <Label name="permissions-checkedAll" className="ms-2">
                        All
                      </Label>
                    </div>

                    {permission.capabilities.map((capability) => (
                      <div key={capability.name}>
                        <input
                          className="form-check-input"
                          id={`permissions-${capability.name}`}
                          name={`${capability.name}`}
                          type="checkbox"
                          defaultChecked={capability.isChecked}
                          onChange={singleCheckboxChange}
                        />
                        <Label
                          id={`permissions-${capability.name}`}
                          className="ms-2"
                        >
                          {capability.display_name}
                        </Label>
                      </div>
                    ))}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <Button type="submit" text="Submit" className="mt-4" />
      </Form>
    );
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title="Add Roles"
          breadcrumbItem={[{ link: '/roles', name: 'Roles' }]}
        />
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <TableContent />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AddRole;
