import { useEffect } from "react";
import { FormGroup, Label } from "reactstrap";
import { InputField } from "src/components";
import { config } from "src/config";

const UpdatedAccreditationDetails = (props: any) => {
    const { inputList, setInputList, inputListErrors, setInputListErrors, observationDocument, measuresTaken } = props;

    const handleAddFields = () => {
        const values = [...inputList];
        const errors = [...inputListErrors];
        values.push({ name: '', duration: 0, duration_unit: '', validate_upto: '', observation_document: '', measures_taken: '' });
        errors.push({ name: '', duration: '', duration_unit: '', validate_upto: '', observation_document: '', measures_taken: '' });
        setInputList(values);
        setInputListErrors(errors);
    };

    const handleRemoveFields = (index: number) => {
        const values = [...inputList];
        const errors = [...inputListErrors];
        values.splice(index, 1);
        errors.splice(index, 1);
        setInputList(values);
        setInputListErrors(errors);
    };

    const handleInputChange = (index: any, event: any) => {
        const { name, value } = event.target;
        const values = [...inputList];
        const errors = [...inputListErrors];
        if (!value) {
            values[index][name] = value;
            errors[index][name] = 'Required field';
        } else {
            values[index][name] = value;
            errors[index][name] = '';
        }
        setInputList(values);
        setInputListErrors(errors);
    };

    const handleObservationDocument = (index: any, event: any) => {
        const { name, value } = event.target;
        const values = [...inputList];
        const errors = [...inputListErrors];
        if (event.target.files[0]) {
            values[index]['observation_document'] = event.target.files[0];
            errors[index]['observation_document'] = '';
        } else {
            values[index]['observation_document'] = '';
            errors[index]['observation_document'] = 'Required field';
        }
        setInputList(values);
        setInputListErrors(errors);
    };

    const handleValidUptoChange = (index: any, event: any) => {
        const { name, value } = event.target;
        const values = [...inputList];
        const errors = [...inputListErrors];
        if (!value) {
            values[index][name] = '';
            errors[index][name] = 'Required field';
        } else {
            values[index][name] = new Date(value)?.toISOString().substring(0, 10);
            errors[index][name] = '';
        }
        setInputList(values);
        setInputListErrors(errors);
    };

    useEffect(() => {
        if(observationDocument === 'Not Applicable') {
            const values = [...inputList];
            const errors = [...inputListErrors];
            values.forEach((v: any) => {
                v['observation_document'] = '';                
            });
            errors.forEach((e: any) => {
                e['observation_document'] = '';
            });
            setInputList(values);
            setInputListErrors(errors);
        }
    }, [observationDocument]);

    useEffect(() => {
        if(measuresTaken === 'Not Applicable') {
            const values = [...inputList];
            const errors = [...inputListErrors];
            values.forEach((v: any) => {
                v['measures_taken'] = '';                
            });
            errors.forEach((e: any) => {
                e['measures_taken'] = '';
            });
            setInputList(values);
            setInputListErrors(errors);
        }
    }, [measuresTaken]);

    return (
        <>
            {inputList.map((input: any, index: any) => (
                <div key={index} className="row mb-3 bg-light ms-2 py-4">
                    <div className="col-md-4 col-sm-12 mb-3">
                        <Label for="departmentName" className="required-field">Name of the department</Label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            name="name"
                            id="departmentName"
                            value={input.name}
                            onChange={(event) => handleInputChange(index, event)}
                        />
                        {inputListErrors[index]["name"] ? (
                            <div className="text-danger">{inputListErrors[index]["name"]}</div>
                        ) : null}
                    </div>
                    <div className="col-md-4 col-sm-12 mb-3">
                        <Label for="periodOfTraining" className="required-field">Period of training recognized by BCPS</Label>
                        <div className="row">
                            <FormGroup className="mb-3 col-sm-6 px-1">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Duration"
                                    name="duration"
                                    id="periodOfTraining"
                                    value={input.duration}
                                    onChange={(event) => handleInputChange(index, event)}
                                />
                                {inputListErrors[index]["duration"] ? (
                                    <div className="text-danger">{inputListErrors[index]["duration"]}</div>
                                ) : null}
                            </FormGroup>
                            <FormGroup className="mb-3 col-sm-6 px-1">
                                <InputField
                                    type="select"
                                    className="form-control"
                                    placeholder="Duration Unit"
                                    name="duration_unit"
                                    value={input.duration_unit}
                                    options={[{ label: 'Year(s)', value: 'Year' }, { label: 'Month(s)', value: 'Month' }, { label: 'Day(s)', value: 'Day' }]}
                                    onChange={(event) => handleInputChange(index, event)}
                                />
                                {inputListErrors[index]["duration_unit"] ? (
                                    <div className="text-danger">{inputListErrors[index]["duration_unit"]}</div>
                                ) : null}
                            </FormGroup>
                        </div>
                    </div>
                    <div className={observationDocument !== 'Applicable' && measuresTaken !== 'Applicable' ? "col-md-2 col-sm-12 mb-3" : "col-md-4 col-sm-12 mb-3"}>
                        <Label for="validUpto" className="required-field">Valid Upto</Label>
                        <input
                            type="date"
                            className="form-control"
                            placeholder="Valid Upto"
                            name="validate_upto"
                            id="validUpto"
                            value={input.validate_upto}
                            onChange={(event) => handleValidUptoChange(index, event)}
                        />
                        {inputListErrors[index]["validate_upto"] ? (
                            <div className="text-danger">{inputListErrors[index]["validate_upto"]}</div>
                        ) : null}
                    </div>
                    {observationDocument === 'Applicable' ? 
                    <div className="col-md-4 col-sm-12 mb-3">
                        <Label for="observationDocument" className="required-field">Observation document</Label>
                        {typeof input.observation_document === 'string' && input.observation_document !== '' ? (
                            <a
                                className="me-2 p-0 center"
                                href={`${config?.APP_URL}${input.observation_document}`}
                                style={{ pointerEvents: 'auto' }}
                                rel="noreferrer"
                                target="_blank"
                            >
                                <span className="btn-outline-info btn-sm btn ms-2">View</span>
                            </a>
                        ) : (
                            typeof input.observation_document !== 'string' && input.observation_document &&
                            <a
                                className="me-2 p-0 center"
                                href={URL.createObjectURL(input.observation_document)}
                                style={{ pointerEvents: 'auto' }}
                                rel="noreferrer"
                                target="_blank"
                            >
                                <span className="btn-outline-info btn-sm btn ms-2">View</span>
                            </a>
                        )}
                        <input
                            type="file"
                            className="form-control"
                            placeholder="Observation Document"
                            name="observation_document"
                            id="observationDocument"
                            onChange={(event) => handleObservationDocument(index, event)}
                        />
                        {inputListErrors[index]["observation_document"] ? (
                            <div className="text-danger">{inputListErrors[index]["observation_document"]}</div>
                        ) : null}
                    </div> : null}
                    {measuresTaken === 'Applicable' ? 
                    <div className="col-md-6 col-sm-12 mb-3">
                        <Label for="measuresTaken" className="required-field">Measures taken</Label>
                        <InputField
                            type="textarea"
                            className="form-control"
                            placeholder="Measures Taken"
                            name="measures_taken"
                            id="measuresTaken"
                            value={input.measures_taken}
                            onChange={(event) => handleInputChange(index, event)}
                        />
                        {inputListErrors[index]["measures_taken"] ? (
                            <div className="text-danger">{inputListErrors[index]["measures_taken"]}</div>
                        ) : null}
                    </div> : null}
                    <div className="col-sm-2 mt-3">
                        <button
                            type="button"
                            className={'btn btn-danger mb-2'}
                            onClick={() => handleRemoveFields(index)}
                            disabled={inputList.length <= 1}
                        >
                            <i className="fa fa-minus-circle" />
                        </button>
                        <br />
                        {index === inputList.length - 1 && (
                            <button
                                type="button"
                                className={'btn btn-secondary'}
                                onClick={handleAddFields}
                                disabled={!inputList[inputList.length - 1]?.name || !inputList[inputList.length - 1]?.duration || !inputList[inputList.length - 1]?.duration_unit || !inputList[inputList.length - 1]?.validate_upto || (observationDocument === 'Applicable' && !inputList[inputList.length - 1]?.observation_document) || (measuresTaken === 'Applicable' && !inputList[inputList.length - 1]?.measures_taken)}
                            >
                                <i className="fa fa-plus-circle" />
                            </button>
                        )}
                    </div>
                </div>
            ))}
        </>
    );
};

export default UpdatedAccreditationDetails;
