// import Users from 'src/pages/Users';
// import AddUser from 'src/pages/Users/AddUser';
// import EditUser from 'src/pages/Users/EditUser';

import Notification from 'src/pages/Notification/Notification';
import AssignPermission from 'src/pages/Users2/AssignPermission';
import UserForm from 'src/pages/Users2/form';
import UserListPage from 'src/pages/Users2/index';

export const userRoutes = [
  // users
  // { path: '/users', component: Users },
  // { path: '/users/add-user', component: AddUser },
  // { path: '/users/edit-user/:id', component: EditUser },

  { path: '/users', component: UserListPage },
  { path: '/users/add', component: UserForm },
  { path: '/users/update/:id', component: UserForm },
  { path: '/users/permisson/:id', component: AssignPermission },

  { path: '/notification', component: Notification },
];
