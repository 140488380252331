import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Select from 'react-select';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetRecomendationListForAdminQuery } from 'src/rtk/features/instituteInspection/institutueInspectionApi';
import { useGetInstituteNameQuery, useLazyGetInstituteWiseDepartmentNameQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import ViewRecomendedDataModal from './ViewRecomendedDataModal';
import { useHistory } from 'react-router-dom';
import loader from '../../assets/images/loader.gif';

const RecomendedApprovalList = () => {
  const history = useHistory();

  const { data: instituteLookupData } = useGetInstituteNameQuery();
  const [getInstituteWiseDepartmentName, { data: departmentLookupData }] = useLazyGetInstituteWiseDepartmentNameQuery();

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [componenetShow, setComponenetShow] = useState<boolean>(false);
  const [info, setInfo] = useState<RecomendationForm>({});
  const [selectedInstitue, setSelectedInstitute] = useState<any>('');
  const [selectedDepartment, setSelectedDepartment] = useState<any>('');

  const instituteList: SelectOption[] = instituteLookupData?.map((item) => ({
    value: item.id!,
    label: item.institute_name!,
  })) || [];


  const departmentList: SelectOption[] = departmentLookupData?.map((item) => ({
    value: item.id!,
    label: item.department_name!,
  })) || [];

  const allStatus: SelectOption[] = [
    { value: 'Accredited', label: 'Accredited' },
    { value: 'Not Accredited', label: 'Not Accredited' },
  ];

  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');

  const generateQueryUrl = () => {
    return `page=${page}&paginate_per_page=${limit}${search}`;
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setPage(page);
  };

  interface SearchFields {
    search?: string;
    institute_id?: string;
    department_id?: string;
    status?: string;
  }

  const initialValues: SearchFields = {
    search: '',
    institute_id: '',
    department_id: '',
    status: '',
  };

  const {
    data: recomendationList,
    isSuccess,
    isLoading,
  } = useGetRecomendationListForAdminQuery(generateQueryUrl());

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.search !== '') {
        s = s + `&search=${values.search}`;
      }

      if (values.institute_id !== '') {
        s = s + `&institute_id=${values.institute_id}`;
      }

      if (values.department_id !== '') {
        s = s + `&department_id=${values.department_id}`;
      }

      if (values.status !== '') {
        s = s + `&status=${values.status}`;
      }

      setSearch(s);
      setPage('1');
    },
  });


  const columns: TableColumn<any>[] = [
    {
      name: <div>Sl. No.</div>,
      cell: (row, index) => <strong>{((page - 1) * limit + index) + 1}</strong>,
      width: '80px',
      wrap: true,
    },

    {
      name: <div>Institute Name</div>,
      selector: (row) => row?.institute?.institute_name,
      sortable: false,
      wrap: true,
    },


    {
      name: <div>Department Name</div>,
      selector: (row) => row?.department?.department_name ?? '-',
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Period of Training to be Accepted</div>,
      selector: (row) => row?.period_of_accepted_training_in_year! + ' Year ' + row?.period_of_accepted_training_in_month! + ' Months',
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Period of Recognition</div>,
      selector: (row) => row?.period_of_recognition! + ' Years',
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Strating Date of Recognition</div>,
      selector: (row) => row?.starting_date_of_recognition ?  moment(row?.starting_date_of_recognition!).format("DD-MMM-YYYY") : '-',
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Recognition Validity Up to</div>,
      selector: (row) => row?.validity_date_of_recognition ? moment(row?.validity_date_of_recognition!).format("DD-MMM-YYYY") : '-',
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Status</div>,
      selector: (row) => row?.accreditation_status,
      cell: (row) => {
        switch (row?.accreditation_status) {
          case 'Rejected':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-rejected font-size-12 fw-medium">
                  {row?.accreditation_status}
                </div>
              </>
            );

          case 'Not Accredited':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-rejected font-size-12 fw-medium">
                  {row?.accreditation_status}
                </div>
              </>
            );

          case 'Accredited':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium">
                  {row?.accreditation_status}
                </div>
              </>
            );

          default:
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium">
                  {'N/A'}
                </div>
              </>
            );
        }
      },
    },

    {
      name: 'Action',
      width: '200px',
      cell: (row) => (
        <div className='my-2'>
          <Button
            className="btn btn-sm btn-primary me-1"
            text={`Approval`}
            onClick={() => {
              setModalShow(true);
              setInfo(row);
            }}
          />

          <Button
            className="btn btn-sm btn-success"
            text={`View Details`}
            onClick={() => {
              history.push(`/institute-department-details/${row?.institute?.id}/${row?.department?.id}`)
            }}
          />

          {modalShow && info?.id === row?.id &&
            <ViewRecomendedDataModal
              isOpen={modalShow}
              toggle={() => setModalShow(!modalShow)}
              info={row}
            />
          }
        </div>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    if (selectedInstitue !== '') {
      setFieldValue('institute_id', selectedInstitue?.value);
      getInstituteWiseDepartmentName(selectedInstitue?.label);
    }
  }, [selectedInstitue]);

  useEffect(() => {
    if (selectedDepartment !== '') {
      setFieldValue('department_id', selectedDepartment?.value);
    }
  }, [selectedDepartment]);

  return (
    <div className="page-content">
      {isLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb
          breadcrumbItem={[{ link: '#', name: 'Inspection Recomendation List' }]}
          title={'Inspection Recomendation List'}
        />
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Form
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  onReset={(e) => {
                    e.preventDefault();
                    resetForm();
                    setSelectedDepartment('');
                    setSelectedInstitute('');
                    handleSubmit();
                  }}
                >
                  <div className="filter-wrapper">

                    <FormGroup className='extent-field'>
                      <Label for="institute_id">Institute</Label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        value={selectedInstitue}
                        onChange={setSelectedInstitute}
                        options={instituteList}
                        placeholder='Institute'
                        name="institute_id"
                      />
                    </FormGroup>
                    <FormGroup className='extent-field'>
                      <Label for="department_name">Department</Label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        value={selectedDepartment}
                        onChange={setSelectedDepartment}
                        options={departmentList}
                        placeholder='Department'
                        name="department_name"
                      />
                    </FormGroup>

                    <FormGroup className='extent-field'>
                      <Label for="status">Status</Label>
                      <InputField
                        type="select"
                        placeholder="Status"
                        options={allStatus}
                        {...getFieldProps('status')}
                      />
                    </FormGroup>

                    <FormGroup className="btn-column mb-3">
                      <Button color="danger" type="reset" text="Reset" />
                      <Button type="submit" text="Search" />
                    </FormGroup>
                  </div>
                </Form>
              </Col>
            </Row>

            <DataTable
              columns={columns}
              data={recomendationList?.data!}
              pagination
              paginationServer
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              progressPending={isLoading}
              expandOnRowClicked
              highlightOnHover
              paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
              responsive
              persistTableHead={true}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default RecomendedApprovalList;
