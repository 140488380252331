import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import { setFromErrors } from 'src/helpers';
import { useCreateInstituteMutation, useGetInstituteRegistrationListQuery } from 'src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi';
import * as Yup from 'yup';
import UpdatedAccreditationDetails from './UpdatedAccreditationDetails';

const initialValues: InstituteRegistration = {
    institute_name: '',
    institute_type: '',
    non_gov_ins_type: '',
    recognition_of_bmdc: '',
    recognition_of_bmdc_supporting_document: '',
    license_of_dghs: '',
    license_no: '',
    affiliation_with_university: '',
    affiliation_university_name: '',
    research_monitor_comittee: '',
    total_no_of_beds: 0,
    total_no_of_paying_beds: 0,
    total_no_of_non_paying_beds: 0,
    total_no_of_cabins: 0,
    availability_of_icu_service: '',
    availability_of_emergency_service: '',
    icu_beds: 0,
    number_of_ventilators: 0,
    no_of_icu_doctors: 0,
    number_of_icu_nurse_and_paramedics: 0,
    availability_of_ccu_service: '',
    previous_accreditation_status: 0,
    last_bcps_inspection_status: '',
    observation_measures: '',
    submission_status: '',
    departments: [],
};

const InstituteReg = () => {
    const { viewOrEditTag: viewOrEditId } = useParams<{ viewOrEditTag: string }>();
    const [submitStatus, setSubmitStatus] = useState<boolean>(true);
    const [accreditationDetailsList, setAccreditationDetailsList] = useState<any>([]);
    const [accreditationErrors, setAccreditationErrors] = useState<any>([]);
    const [accreditationDetailsDisabled, setAccreditationDetailsDisabled] = useState<boolean>(false);
    const [accreditationStatusDisabled, setAccreditationStatusDisabled] = useState<boolean>(false);
    const [accreditationStatusLabel, setAccreditationStatusLabel] = useState<string>('');
    const [firstTimeRender, setFirstTimeRender] = useState<boolean>(false);

    const recognitionOfBmdcSupportingDocumentReference = useRef<any>();
    // const paymentReceiptFileReference = useRef<any>();
    const history = useHistory();

    const [createInstitute, { data: instituteData, isSuccess: isInstituteSuccess, isError: isInstituteError, error: instituteError }] =
        useCreateInstituteMutation();

    const { data: instituteRegData, isSuccess: isInstituteRegDataSuccess, isError: isInstituteRegDataError, error: instituteRegDataError } = useGetInstituteRegistrationListQuery();

    const err: CustomError = instituteError as CustomError;

    document.title = 'Institute Registration | BCPS';

    const formik = useFormik({
        initialValues,

        validationSchema: Yup.object({
            recognition_of_bmdc_supporting_document: Yup.string().when('recognition_of_bmdc', {
                is: (recognitionOfBMDC: any) => recognitionOfBMDC === 'Recognized',
                then: Yup.string().required('Supproting document is required')
            }),
            license_no: Yup.string().when('license_of_dghs', {
                is: (licenseOfDGHS: any) => licenseOfDGHS === 'Yes',
                then: Yup.string().required('License no. is required')
            }),
            affiliation_university_name: Yup.string().when('affiliation_with_university', {
                is: (affiliationWithUniversity: any) => affiliationWithUniversity === 'Yes',
                then: Yup.string().required('The affiliation university name is required')
            }),
        }),

        onSubmit: (values: any) => {
            console.log(values);

            values.departments = [];

            const generalInputList = [...accreditationDetailsList];

            generalInputList?.forEach((details: any, index: number) => {
                values?.departments?.push({
                    name: details.name ?? '',
                    duration: +details.duration ?? 0,
                    duration_unit: details.duration_unit ?? '',
                    validate_upto: details.validate_upto ?? '',
                    observation_document: details.observation_document ?? '',
                    measures_taken: details.measures_taken ?? '',
                });
            });

            const formData = new FormData();

            Object.keys(values).forEach((key) => {
                if (key === 'submission_status') {
                    if (submitStatus) {
                        values[key] = 'Submit';
                    } else {
                        values[key] = 'Draft';
                    }
                }

                if (key === 'previous_accreditation_status') {
                    if (accreditationStatusLabel === 'Accreditated') {
                        values[key] = 1;
                    } else if (accreditationStatusLabel === 'Not Accreditated') {
                        values[key] = 0;
                    }
                }

                if (key === 'recognition_of_bmdc_supporting_document') {
                    if (typeof values[key] === 'string' && values[key] !== '') {
                        values[key] = '';
                    }
                }

                console.log(values[key]);

                if (key === 'departments') {
                    values.departments.forEach((item: any, index: number) => {
                        formData.append(`departments[${index}][name]`, item.name);
                        formData.append(`departments[${index}][duration]`, item.duration);
                        formData.append(`departments[${index}][duration_unit]`, item.duration_unit);
                        formData.append(`departments[${index}][validate_upto]`, item.validate_upto);
                        formData.append(`departments[${index}][observation_document]`, typeof item.observation_document === 'string' ? '' : item.observation_document);
                        formData.append(`departments[${index}][measures_taken]`, item.measures_taken);
                    });
                }

                if (key !== 'departments') {
                    formData.append(key, values[key]);
                }
            });

            createInstitute({ id: instituteRegData?.data?.id, formData: formData });
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    useEffect(() => {
        setFieldValue('recognition_of_bmdc', 'Recognized');
        setFieldValue('research_monitor_comittee', 'Existent');
        setFieldValue('availability_of_emergency_service', 'Yes');
        setFieldValue('availability_of_icu_service', 'Yes');
        setFieldValue('availability_of_ccu_service', 'Yes');
    }, []);

    useEffect(() => {
        if (isInstituteRegDataSuccess) {
            setFieldValue('institute_name', instituteRegData?.data?.institute_name);
            setFieldValue('institute_type', instituteRegData?.data?.institute_type);
            if (instituteRegData?.data?.institute_type === 'Private') {
                setFieldValue('license_of_dghs', 'Yes');
                setFieldValue('affiliation_with_university', 'Yes');
                setFieldValue('non_gov_ins_type', instituteRegData?.data?.non_gov_ins_type);
            } else if (instituteRegData?.data?.institute_type === 'Government') {
                setFieldValue('license_of_dghs', 'Not Applicable');
                setFieldValue('affiliation_with_university', 'Not Applicable');
            }
        }
    }, [isInstituteRegDataSuccess]);

    useEffect(() => {
        if (instituteRegData?.data?.id && !isInstituteRegDataError) {
            const institute = instituteRegData?.data;
            console.log('Institute Info');
            console.log('-----------------------------------');
            console.log(institute);
            setValues({
                institute_name: institute?.institute_name ?? '',
                institute_type: institute?.institute_type ?? '',
                non_gov_ins_type: institute?.non_gov_ins_type ?? '',
                recognition_of_bmdc: institute?.recognition_of_bmdc ?? 'Recognized',
                recognition_of_bmdc_supporting_document: institute?.recognition_of_bmdc_supporting_document ?? '',
                license_of_dghs: institute?.license_of_dghs ? institute?.license_of_dghs : institute?.institute_type === 'Government' ? 'Not Applicable' : 'Yes',
                license_no: institute?.license_no ?? '',
                affiliation_with_university: institute?.affiliation_with_university ? institute?.affiliation_with_university : institute?.institute_type === 'Government' ? 'Not Applicable' : 'Yes',
                affiliation_university_name: institute?.affiliation_university_name ?? '',
                research_monitor_comittee: institute?.research_monitor_comittee ?? 'Existent',
                total_no_of_beds: institute?.total_no_of_beds ?? 0,
                total_no_of_paying_beds: institute?.total_no_of_paying_beds ?? 0,
                total_no_of_non_paying_beds: institute?.total_no_of_non_paying_beds ?? 0,
                total_no_of_cabins: institute?.total_no_of_cabins ?? 0,
                availability_of_icu_service: institute?.availability_of_icu_service ?? 'Yes',
                availability_of_emergency_service: institute?.availability_of_emergency_service ?? 'Yes',
                icu_beds: institute?.icu_beds ?? 0,
                number_of_ventilators: institute?.number_of_ventilators ?? 0,
                no_of_icu_doctors: institute?.no_of_icu_doctors ?? 0,
                number_of_icu_nurse_and_paramedics: institute?.number_of_icu_nurse_and_paramedics ?? 0,
                availability_of_ccu_service: institute?.availability_of_ccu_service ?? 'Yes',
                previous_accreditation_status: institute?.previous_accreditation_status === true ? 1 : institute?.previous_accreditation_status === false ? 0 : institute?.previous_accreditation_status,
                last_bcps_inspection_status: institute?.last_bcps_inspection_status ?? '',
                observation_measures: institute?.observation_measures ?? '',
                submission_status: institute?.submission_status ?? '',
            });

            setAccreditationStatusLabel(institute?.previous_accreditation_status ? 'Accreditated' : 'Not Accreditated');

            setAccreditationDetailsList(institute?.previous_accredited_departments?.map((d: any) => {
                return {
                    name: d?.department_name,
                    duration: d?.period_of_training_by_bcps,
                    duration_unit: d?.period_unit_of_training_by_bcps,
                    validate_upto: d?.valid_upto,
                    observation_document: d?.observation_document,
                    measures_taken: d?.measures_taken,
                };
            }));

            setAccreditationErrors(institute?.previous_accredited_departments?.map((d: any) => {
                return {
                    name: '',
                    duration: '',
                    duration_unit: '',
                    validate_upto: '',
                    observation_document: '',
                    measures_taken: '',
                };
            }));
        }
    }, [instituteRegData, isInstituteRegDataError]);

    useEffect(() => {
        if (isInstituteSuccess) {
            handleFormReset();
            toast.success('Institute information registered successfully');
            history.push('/institute-registration-list');
        }
        if (isInstituteError) {
            toast.error(err?.message || 'Something went wrong');
            setFromErrors(err.data, setErrors);
        }
    }, [isInstituteSuccess, isInstituteError]);

    const handleFormReset = () => {
        setSubmitStatus(true);
        recognitionOfBmdcSupportingDocumentReference.current.value = '';
        // paymentReceiptFileReference.current.value = '';
        resetForm({
            values: {
                institute_name: instituteRegData?.data?.institute_name!,
                institute_type: instituteRegData?.data?.institute_type!,
                non_gov_ins_type: instituteRegData?.data?.non_gov_ins_type!,
                recognition_of_bmdc: 'Recognized',
                recognition_of_bmdc_supporting_document: '',
                license_of_dghs: values.institute_type === 'Government' ? 'Not Applicable' : 'Yes',
                license_no: '',
                affiliation_with_university: values.institute_type === 'Government' ? 'Not Applicable' : 'Yes',
                affiliation_university_name: '',
                research_monitor_comittee: 'Existent',
                total_no_of_beds: 0,
                total_no_of_paying_beds: 0,
                total_no_of_non_paying_beds: 0,
                total_no_of_cabins: 0,
                availability_of_icu_service: 'Yes',
                availability_of_emergency_service: 'Yes',
                icu_beds: 0,
                number_of_ventilators: 0,
                no_of_icu_doctors: 0,
                number_of_icu_nurse_and_paramedics: 0,
                availability_of_ccu_service: 'Yes',
                previous_accreditation_status: 0,
                last_bcps_inspection_status: '',
                observation_measures: '',
                submission_status: '',
                departments: [],
            }
        });
    };

    useEffect(() => {
        if (values.recognition_of_bmdc && values.recognition_of_bmdc !== 'Recognized') {
            recognitionOfBmdcSupportingDocumentReference.current.value = '';
            setFieldValue('recognition_of_bmdc_supporting_document', '');
        }
    }, [values.recognition_of_bmdc]);

    useEffect(() => {
        if (values.license_of_dghs && values.license_of_dghs !== 'Yes') {
            setFieldValue('license_no', '');
        }
    }, [values.license_of_dghs]);

    useEffect(() => {
        if (values.affiliation_with_university && values.affiliation_with_university !== 'Yes') {
            setFieldValue('affiliation_university_name', '');
        }
    }, [values.affiliation_with_university]);

    useEffect(() => {
        if (values.availability_of_icu_service && values.availability_of_icu_service === 'No') {
            setFieldValue('icu_beds', 0);
            setFieldValue('number_of_ventilators', 0);
            setFieldValue('no_of_icu_doctors', 0);
            setFieldValue('number_of_icu_nurse_and_paramedics', 0);
        }
    }, [values.availability_of_icu_service]);

    useEffect(() => {
        values.total_no_of_beds = +values.total_no_of_paying_beds! + +values.total_no_of_non_paying_beds! + +values.total_no_of_cabins!;
    }, [values.total_no_of_paying_beds, values.total_no_of_non_paying_beds, values.total_no_of_cabins]);

    useEffect(() => {
        if (firstTimeRender) {
            if (accreditationStatusLabel === 'Not Accreditated') {
                setFieldValue('last_bcps_inspection_status', 'Not Applicable');
                setFieldValue('observation_measures', 'Not Applicable');
                setAccreditationDetailsDisabled(true);
                setAccreditationDetailsList([]);
                setAccreditationErrors([]);
            } else if (accreditationStatusLabel === 'Accreditated') {
                setFieldValue('last_bcps_inspection_status', '');
                setFieldValue('observation_measures', '');
                setAccreditationDetailsDisabled(false);
                setAccreditationDetailsList([{ name: '', duration: 0, duration_unit: '', validate_upto: '', observation_document: '', measures_taken: '' }]);
                setAccreditationErrors([{ name: '', duration: '', duration_unit: '', validate_upto: '', observation_document: '', measures_taken: '' }]);
            } else {
                setFieldValue('last_bcps_inspection_status', '');
                setFieldValue('observation_measures', '');
                setAccreditationDetailsDisabled(false);
                setAccreditationDetailsList([]);
                setAccreditationErrors([]);
            }
        }
    }, [accreditationStatusLabel]);

    useEffect(() => {
        if (accreditationDetailsList.length > 1) {
            setAccreditationStatusDisabled(true);
        } else {
            setAccreditationStatusDisabled(false);
        }
    }, [accreditationDetailsList]);

    return (
        <div className="page-content">
            <Container fluid>
                <BetterBreadcrumb
                    title="Training Accreditation Application Form"
                    breadcrumbItem={[{ link: '/institute-registration-list', name: 'Institute List' }]}
                />
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title mb-0 flex-grow-1">
                                    <i className="fa fa-plus me-2" />
                                    <span>Training Accreditation Application Form</span>
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    autoComplete="off"
                                    className="custom-form"
                                    style={viewOrEditId && viewOrEditId !== 'edit' ? { pointerEvents: 'none' } : {}}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        // handleSubmit();
                                    }}
                                    onReset={(e) => {
                                        e.preventDefault();
                                        // handleFormReset();
                                    }}
                                >
                                    <Row>
                                        <h4 className='mb-3'>Training Accreditation Form</h4>
                                        <FormGroup className="col-sm-12 col-md-6 mb-3">
                                            <Label for="institute_name">Name of the institute</Label>
                                            <InputField
                                                type="text"
                                                placeholder="Enter Institute Name"
                                                invalid={{ errors, touched }}
                                                {...getFieldProps('institute_name')}
                                                disabled
                                            />
                                            <p><strong>Institute location: </strong>{instituteRegData?.data?.institute_location}</p>
                                        </FormGroup>

                                        <FormGroup className={values.institute_type === 'Private' ? "col-sm-12 col-md-2 mb-3" : "col-sm-12 col-md-6 mb-3"}>
                                            <Label className="form-label">Status of the institute</Label>
                                            <InputField
                                                type="select"
                                                placeholder=""
                                                options={[{ label: 'Government', value: 'Government' }, { label: 'Private', value: 'Private' }, { label: 'Foreign', value: 'Foreign' }]}
                                                invalid={{ errors, touched }}
                                                {...getFieldProps('institute_type')}
                                                disabled
                                            />
                                        </FormGroup>

                                        {values.institute_type === 'Private' ? <div className="col-sm-12 col-md-4 mb-3 mt-4">
                                            <div className="d-inline-block mx-2">
                                                <div className="form-check form-check-left">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="institutionStatus"
                                                        id="institutionStatusTrust"
                                                        value={'Trust'}
                                                        checked={values.non_gov_ins_type === 'Trust'}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setFieldValue('non_gov_ins_type', 'Trust');
                                                            }
                                                        }}
                                                        disabled
                                                    />
                                                    <label className="form-check-label" htmlFor="institutionStatusTrust">Trust</label>
                                                </div>
                                            </div>
                                            <div className="d-inline-block mx-2">
                                                <div className="form-check form-check-left">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="institutionStatus"
                                                        id="institutionStatusFoundation"
                                                        value={'Foundation'}
                                                        checked={values.non_gov_ins_type === 'Foundation'}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setFieldValue('non_gov_ins_type', 'Foundation');
                                                            }
                                                        }}
                                                        disabled
                                                    />
                                                    <label className="form-check-label" htmlFor="institutionStatusFoundation">Foundation</label>
                                                </div>
                                            </div>
                                            <div className="d-inline-block mx-2">
                                                <div className="form-check form-check-left">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="institutionStatus"
                                                        id="institutionStatusLtdCo"
                                                        value={'Ltd. Co.'}
                                                        checked={values.non_gov_ins_type === 'Ltd. Co.'}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setFieldValue('non_gov_ins_type', 'Ltd. Co.');
                                                            }
                                                        }}
                                                        disabled
                                                    />
                                                    <label className="form-check-label" htmlFor="institutionStatusLtdCo">Ltd. Co.</label>
                                                </div>
                                            </div>
                                            <div className="d-inline-block mx-2">
                                                <div className="form-check form-check-left">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="institutionStatus"
                                                        id="institutionStatusOthers"
                                                        value={'Others'}
                                                        checked={values.non_gov_ins_type === 'Others'}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setFieldValue('non_gov_ins_type', 'Others');
                                                            }
                                                        }}
                                                        disabled
                                                    />
                                                    <label className="form-check-label" htmlFor="institutionStatusOthers">Others</label>
                                                </div>
                                            </div>
                                        </div> : null}

                                        <FormGroup className="col-sm-12 col-md-6 mb-3">
                                            <div className="mb-3">
                                                <h5 className="font-size-14 mb-3">Recognition of BMDC</h5>
                                                <div className="d-inline-block mx-2">
                                                    <div className="form-check form-check-left mb-3">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="BMDCRecognition"
                                                            id="BMDCRecognitionRecognized"
                                                            value={'Recognized'}
                                                            checked={values.recognition_of_bmdc === 'Recognized'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setFieldValue('recognition_of_bmdc', 'Recognized');
                                                                }
                                                            }}
                                                            defaultChecked
                                                        />
                                                        <label className="form-check-label" htmlFor="BMDCRecognitionRecognized">Recognized</label>
                                                    </div>
                                                </div>

                                                <div className="d-inline-block mx-2">
                                                    <div className="form-check form-check-left">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="BMDCRecognition"
                                                            id="BMDCRecognitionNotRecognized"
                                                            value={"Not Recognized"}
                                                            checked={values.recognition_of_bmdc === 'Not Recognized'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setFieldValue('recognition_of_bmdc', 'Not Recognized');
                                                                }
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="BMDCRecognitionNotRecognized">Not Recognized</label>
                                                    </div>
                                                </div>

                                                <div className="d-inline-block mx-2">
                                                    <div className="form-check form-check-left">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="BMDCRecognition"
                                                            id="BMDCRecognitionNotApplicable"
                                                            value={"Not Applicable"}
                                                            checked={values.recognition_of_bmdc === 'Not Applicable'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setFieldValue('recognition_of_bmdc', 'Not Applicable');
                                                                }
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="BMDCRecognitionNotApplicable">Not Applicable</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </FormGroup>

                                        <FormGroup className="col-sm-12 col-md-6 mb-3">
                                            <Label
                                                htmlFor="recognitionDocument"
                                                className="form-Label"
                                            >
                                                Upload supporting documents
                                            </Label>
                                            {typeof values.recognition_of_bmdc_supporting_document === 'string' && values.recognition_of_bmdc_supporting_document !== '' ? (
                                                <a
                                                    className="me-2 p-0 center"
                                                    href={`${config?.APP_URL}${values.recognition_of_bmdc_supporting_document}`}
                                                    style={{ pointerEvents: 'auto' }}
                                                    rel="noreferrer"
                                                    target="_blank"
                                                >
                                                    <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                                </a>
                                            ) : (
                                                typeof values.recognition_of_bmdc_supporting_document !== 'string' && values.recognition_of_bmdc_supporting_document &&
                                                <a
                                                    className="me-2 p-0 center"
                                                    href={URL.createObjectURL(values.recognition_of_bmdc_supporting_document)}
                                                    style={{ pointerEvents: 'auto' }}
                                                    rel="noreferrer"
                                                    target="_blank"
                                                >
                                                    <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                                </a>
                                            )}
                                            <input
                                                ref={recognitionOfBmdcSupportingDocumentReference}
                                                name="uploadSupportingDocuments"
                                                id="recognitionDocument"
                                                type="file"
                                                className="form-control"
                                                placeholder="Upload File"
                                                onChange={(e: any) => {
                                                    setFieldValue(
                                                        'recognition_of_bmdc_supporting_document',
                                                        e.target.files[0],
                                                    );
                                                }}
                                                disabled={values.recognition_of_bmdc !== 'Recognized'}
                                            ></input>
                                            {errors?.['recognition_of_bmdc_supporting_document'] && touched?.['recognition_of_bmdc_supporting_document'] ? (
                                                <div className='text-danger'>
                                                    {errors?.['recognition_of_bmdc_supporting_document'] as string}
                                                </div>
                                            ) : null}
                                        </FormGroup>

                                        {/* DGHSLicense */}
                                        <FormGroup className="col-sm-12 col-md-6 mb-3">
                                            <div className="mb-3">
                                                <h5 className="font-size-14 mb-3">License by DGHS</h5>
                                                <div className="d-inline-block mx-2">
                                                    <div className="form-check form-check-left mb-3">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="hasDGHSLicense"
                                                            id="hasDGHSLicenseYes"
                                                            value={"Yes"}
                                                            checked={values.license_of_dghs === 'Yes'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setFieldValue('license_of_dghs', 'Yes');
                                                                }
                                                            }}
                                                            defaultChecked
                                                            disabled={values.institute_type === 'Government'}
                                                        />
                                                        <label className="form-check-label" htmlFor="hasDGHSLicenseYes">Yes</label>
                                                    </div>
                                                </div>

                                                <div className="d-inline-block mx-2">
                                                    <div className="form-check form-check-left">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="hasDGHSLicense"
                                                            id="hasDGHSLicenseNo"
                                                            value={"No"}
                                                            checked={values.license_of_dghs === 'No'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setFieldValue('license_of_dghs', 'No');
                                                                }
                                                            }}
                                                            disabled={values.institute_type === 'Government'}
                                                        />
                                                        <label className="form-check-label" htmlFor="hasDGHSLicenseNo">No</label>
                                                    </div>
                                                </div>

                                                <div className="d-inline-block mx-2">
                                                    <div className="form-check form-check-left">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="hasDGHSLicense"
                                                            id="hasDGHSLicenseNotApplicable"
                                                            value={"Not Applicable"}
                                                            checked={values.license_of_dghs === 'Not Applicable'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setFieldValue('license_of_dghs', 'Not Applicable');
                                                                }
                                                            }}
                                                            disabled={values.institute_type === 'Government'}
                                                        />
                                                        <label className="form-check-label" htmlFor="hasDGHSLicenseNotApplicable">Not Applicable</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </FormGroup>

                                        <FormGroup className="col-sm-12 col-md-6 mb-3">
                                            <Label for="license_no">License No.</Label>
                                            <InputField
                                                type="text"
                                                placeholder="License Number"
                                                invalid={{ errors, touched }}
                                                {...getFieldProps('license_no')}
                                                readOnly={values.license_of_dghs !== 'Yes'}
                                            />
                                        </FormGroup>

                                        {/* Affiliation  */}
                                        <FormGroup className="col-sm-12 col-md-6 mb-3">
                                            <div className="mb-3">
                                                <h5 className="font-size-14 mb-3">Affiliation with University</h5>
                                                <div className="d-inline-block mx-2">
                                                    <div className="form-check form-check-left mb-3">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="hasAffiliationUniversity"
                                                            id="hasAffiliationUniversityYes"
                                                            value={"Yes"}
                                                            checked={values.affiliation_with_university === 'Yes'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setFieldValue('affiliation_with_university', 'Yes');
                                                                }
                                                            }}
                                                            defaultChecked
                                                            disabled={values.institute_type === 'Government'}
                                                        />
                                                        <label className="form-check-label" htmlFor="hasAffiliationUniversityYes">Yes</label>
                                                    </div>
                                                </div>

                                                <div className="d-inline-block mx-2">
                                                    <div className="form-check form-check-left">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="hasAffiliationUniversity"
                                                            id="hasAffiliationUniversityNo"
                                                            value={"No"}
                                                            checked={values.affiliation_with_university === 'No'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setFieldValue('affiliation_with_university', 'No');
                                                                }
                                                            }}
                                                            disabled={values.institute_type === 'Government'}
                                                        />
                                                        <label className="form-check-label" htmlFor="hasAffiliationUniversityNo">No</label>
                                                    </div>
                                                </div>

                                                <div className="d-inline-block mx-2">
                                                    <div className="form-check form-check-left">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="hasAffiliationUniversity"
                                                            id="hasAffiliationUniversityNotApplicable"
                                                            value={"Not Applicable"}
                                                            checked={values.affiliation_with_university === 'Not Applicable'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setFieldValue('affiliation_with_university', 'Not Applicable');
                                                                }
                                                            }}
                                                            disabled={values.institute_type === 'Government'}
                                                        />
                                                        <label className="form-check-label" htmlFor="hasAffiliationUniversityNotApplicable">Not Applicable</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </FormGroup>

                                        <FormGroup className="col-sm-12 col-md-6 mb-3">
                                            <Label for="affiliation_university_name">Name of the University</Label>
                                            <InputField
                                                type="text"
                                                placeholder="University Name"
                                                invalid={{ errors, touched }}
                                                {...getFieldProps('affiliation_university_name')}
                                                readOnly={values.affiliation_with_university !== 'Yes'}
                                            />
                                        </FormGroup>

                                        <FormGroup className="col-sm-12 col-md-6 mb-3">
                                            <div className="mb-3">
                                                <h5 className="font-size-14 mb-3">Institutional Research Monitoring Committee</h5>
                                                <div className="d-inline-block mx-2">
                                                    <div className="form-check form-check-left mb-3">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="researchMonitoringCommittee"
                                                            id="researchMonitoringCommitteeExistent"
                                                            value={"Existent"}
                                                            checked={values.research_monitor_comittee === 'Existent'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setFieldValue('research_monitor_comittee', 'Existent');
                                                                }
                                                            }}
                                                            defaultChecked
                                                        />
                                                        <label className="form-check-label" htmlFor="researchMonitoringCommitteeExistent">Existent</label>
                                                    </div>
                                                </div>

                                                <div className="d-inline-block mx-2">
                                                    <div className="form-check form-check-left">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="researchMonitoringCommittee"
                                                            id="researchMonitoringCommitteeNonExistent"
                                                            value={"Non Existent"}
                                                            checked={values.research_monitor_comittee === 'Non Existent'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setFieldValue('research_monitor_comittee', 'Non Existent');
                                                                }
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="researchMonitoringCommitteeNonExistent">Non Existent</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </FormGroup>

                                        <hr />

                                        <h4 className='mb-3'>Information about Hospital Bed</h4>

                                        <FormGroup row className="col-sm-12 col-md-6 mb-3">
                                            <Label for="total_no_of_beds" sm={6} className='me-3'>Total number of beds of the institute</Label>
                                            <Col sm={3}>
                                                <InputField
                                                    type="number"
                                                    placeholder="Total No. of Beds"
                                                    invalid={{ errors, touched }}
                                                    {...getFieldProps('total_no_of_beds')}
                                                    readOnly
                                                />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row className="col-sm-12 col-md-6 mb-3 ms-2">
                                            <Col sm={5}>
                                                <h5 className="font-size-14 mb-3">
                                                    Availability of emergency services
                                                </h5>
                                            </Col>
                                            <Col sm={3}>
                                                <div className="d-inline-block mx-2">
                                                    <div className="form-check form-check-left">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="hasEmergencyService"
                                                            id="hasEmergencyServiceYes"
                                                            checked={values.availability_of_emergency_service === 'Yes'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setFieldValue('availability_of_emergency_service', 'Yes');
                                                                }
                                                            }}
                                                            defaultChecked
                                                        />
                                                        <label className="form-check-label" htmlFor="hasEmergencyServiceYes">Yes</label>
                                                    </div>
                                                </div>
                                                <div className="d-inline-block mx-2">
                                                    <div className="form-check form-check-left">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="hasEmergencyService"
                                                            id="hasEmergencyServiceNo"
                                                            checked={values.availability_of_emergency_service === 'No'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setFieldValue('availability_of_emergency_service', 'No');
                                                                }
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="hasEmergencyServiceNo">No</label>
                                                    </div>
                                                </div>
                                            </Col>

                                        </FormGroup>

                                        <FormGroup row className="col-sm-12 col-md-6 mb-3 ms-2">
                                            <Label for="total_no_of_paying_beds" sm={6}>Number of paying beds</Label>
                                            <Col sm={3}>
                                                <InputField
                                                    min={'0'}
                                                    type="number"
                                                    placeholder="Total No. of Paying Beds"
                                                    {...getFieldProps('total_no_of_paying_beds')}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row className="col-sm-12 col-md-6 mb-3">
                                            <Col sm={5}>
                                                <h5 className="font-size-14 mb-3">
                                                    Availability of ICU services
                                                </h5>
                                            </Col>
                                            <Col sm={6}>
                                                <div className="d-inline-block mx-2">
                                                    <div className="form-check form-check-left">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="hasICU"
                                                            id="hasICUYes"
                                                            checked={values.availability_of_icu_service === 'Yes'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setFieldValue('availability_of_icu_service', 'Yes');
                                                                }
                                                            }}
                                                            defaultChecked
                                                        />
                                                        <label className="form-check-label" htmlFor="hasICUYes">Yes</label>
                                                    </div>
                                                </div>
                                                <div className="d-inline-block mx-2">
                                                    <div className="form-check form-check-left">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="hasICU"
                                                            id="hasICUNo"
                                                            checked={values.availability_of_icu_service === 'No'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setFieldValue('availability_of_icu_service', 'No');
                                                                }
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="hasICUNo">No</label>
                                                    </div>
                                                </div>
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row className="col-sm-12 col-md-6 mb-3 ms-2">
                                            <Label for="total_no_of_paying_beds" sm={6}>Number of non-paying beds</Label>
                                            <Col sm={3}>
                                                <InputField
                                                    min={'0'}
                                                    type="number"
                                                    placeholder="Total No. of Non Paying Beds"
                                                    {...getFieldProps('total_no_of_non_paying_beds')}
                                                />
                                            </Col>
                                        </FormGroup>

                                        {values.availability_of_icu_service === 'Yes' ? <FormGroup row className="col-sm-12 col-md-6 mb-3 ms-2">
                                            <Label for="icu_beds" sm={6}>Number of beds</Label>
                                            <Col sm={3}>
                                                <InputField
                                                    min={'0'}
                                                    type="number"
                                                    placeholder="Number of ICU Beds"
                                                    {...getFieldProps('icu_beds')}
                                                />
                                            </Col>
                                        </FormGroup> : <FormGroup row className="col-sm-12 col-md-6 mb-3">
                                            <Col sm={5}>
                                                <h5 className="font-size-14 mb-3">
                                                    Availability of CCU services
                                                </h5>
                                            </Col>
                                            <Col sm={6}>
                                                <div className="d-inline-block mx-2">
                                                    <div className="form-check form-check-left">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="hasCCU"
                                                            id="hasCCUYes"
                                                            checked={values.availability_of_ccu_service === 'Yes'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setFieldValue('availability_of_ccu_service', 'Yes');
                                                                }
                                                            }}
                                                            defaultChecked
                                                        />
                                                        <label className="form-check-label" htmlFor="hasCCUYes">Yes</label>
                                                    </div>
                                                </div>
                                                <div className="d-inline-block mx-2">
                                                    <div className="form-check form-check-left">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="hasCCU"
                                                            id="hasCCUNo"
                                                            checked={values.availability_of_ccu_service === 'No'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setFieldValue('availability_of_ccu_service', 'No');
                                                                }
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="hasCCUNo">No</label>
                                                    </div>
                                                </div>
                                            </Col>
                                        </FormGroup>}

                                        <FormGroup row className="col-sm-12 col-md-6 mb-3 ms-2">
                                            <Label for="totalBed" sm={6}>Number of cabins</Label>
                                            <Col sm={3}>
                                                <InputField
                                                    min={'0'}
                                                    type="number"
                                                    placeholder="Total Cabins"
                                                    invalid={{ errors, touched }}
                                                    {...getFieldProps('total_no_of_cabins')}
                                                />
                                            </Col>
                                        </FormGroup>

                                        {values.availability_of_icu_service === 'No' ? <FormGroup row className="col-sm-12 col-md-6 mb-3 ms-2"></FormGroup> : null}


                                        {values.availability_of_icu_service === 'Yes' ? <FormGroup row className="col-sm-12 col-md-6 mb-3 ms-2">
                                            <Label for="number_of_ventilators" sm={6}>Number of Ventilators</Label>
                                            <Col sm={3}>
                                                <InputField
                                                    min={'0'}
                                                    type="number"
                                                    placeholder="Number of Ventilators"
                                                    {...getFieldProps('number_of_ventilators')}
                                                />
                                            </Col>
                                        </FormGroup> : null}

                                        <FormGroup className="col-sm-12 col-md-6 mb-3">

                                        </FormGroup>

                                        {values.availability_of_icu_service === 'Yes' ? <FormGroup row className="col-sm-12 col-md-6 mb-3 ms-2">
                                            <Label for="no_of_icu_doctors" sm={6}>Number of ICU doctors</Label>
                                            <Col sm={3}>
                                                <InputField
                                                    min={'0'}
                                                    type="number"
                                                    placeholder="Number of ICU Doctors"
                                                    {...getFieldProps('no_of_icu_doctors')}
                                                />
                                            </Col>
                                        </FormGroup> : null}

                                        <FormGroup className="col-sm-12 col-md-6 mb-3">

                                        </FormGroup>

                                        {values.availability_of_icu_service === 'Yes' ? <FormGroup row className="col-sm-12 col-md-6 mb-3 ms-2">
                                            <Label for="number_of_icu_nurse_and_paramedics" sm={6}>
                                                Number of ICU Nurse & Paramedics
                                            </Label>
                                            <Col sm={3}>
                                                <InputField
                                                    min={'0'}
                                                    type="number"
                                                    placeholder="Number of ICU Nurse & Paramedics"
                                                    {...getFieldProps('number_of_icu_nurse_and_paramedics')}
                                                />
                                            </Col>
                                        </FormGroup> : null}

                                        <FormGroup className="col-sm-12 col-md-6 mb-3">

                                        </FormGroup>

                                        {/* CCU Service */}
                                        {values.availability_of_icu_service === 'Yes' ? <FormGroup row className="col-sm-12 col-md-6 mb-3 mt-3">
                                            <Col sm={6}>
                                                <h5 className="font-size-14 mb-3">
                                                    Availability of CCU services
                                                </h5>
                                            </Col>
                                            <Col sm={6}>
                                                <div className="d-inline-block mx-2">
                                                    <div className="form-check form-check-left">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="hasCCU"
                                                            id="hasCCUYes"
                                                            checked={values.availability_of_ccu_service === 'Yes'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setFieldValue('availability_of_ccu_service', 'Yes');
                                                                }
                                                            }}
                                                            defaultChecked
                                                        />
                                                        <label className="form-check-label" htmlFor="hasCCUYes">Yes</label>
                                                    </div>
                                                </div>
                                                <div className="d-inline-block mx-2">
                                                    <div className="form-check form-check-left">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="hasCCU"
                                                            id="hasCCUNo"
                                                            checked={values.availability_of_ccu_service === 'No'}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setFieldValue('availability_of_ccu_service', 'No');
                                                                }
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="hasCCUNo">No</label>
                                                    </div>
                                                </div>
                                            </Col>
                                        </FormGroup> : null}

                                        <hr />

                                        {accreditationStatusDisabled ?
                                            <FormGroup row className="col-sm-12 col-md-4 mb-3 mt-3">
                                                <Label className="form-label" htmlFor='accreditation_status'>Present Accreditation status</Label>
                                                <InputField
                                                    type="select"
                                                    name="accreditation_status"
                                                    id="accreditation_status"
                                                    placeholder=""
                                                    options={[{ label: 'Accreditated', value: 'Accreditated' }, { label: 'Not Accreditated', value: 'Not Accreditated' }]}
                                                    value={accreditationStatusLabel}
                                                    onChange={(e) => {
                                                        setFirstTimeRender(true);
                                                        setAccreditationStatusLabel(e.target.value);
                                                    }}
                                                    disabled
                                                />
                                            </FormGroup>
                                            :
                                            <FormGroup row className="col-sm-12 col-md-4 mb-3 mt-3">
                                                <Label className="form-label" htmlFor='accreditation_status'>Present Accreditation status</Label>
                                                <InputField
                                                    type="select"
                                                    name="accreditation_status"
                                                    id="accreditation_status"
                                                    placeholder=""
                                                    options={[{ label: 'Accreditated', value: 'Accreditated' }, { label: 'Not Accreditated', value: 'Not Accreditated' }]}
                                                    value={accreditationStatusLabel}
                                                    onChange={(e) => {
                                                        setFirstTimeRender(true);
                                                        setAccreditationStatusLabel(e.target.value);
                                                    }}
                                                />
                                            </FormGroup>}

                                        {accreditationDetailsDisabled || accreditationStatusDisabled ?
                                            <FormGroup row className="col-sm-12 col-md-4 mb-3 mt-3">
                                                <Label className="form-label" htmlFor='last_bcps_inspection_status'>Observation made at last inspection by BCPS team</Label>
                                                <InputField
                                                    type="select"
                                                    placeholder=""
                                                    options={[{ label: 'Applicable', value: 'Applicable' }, { label: 'Not Applicable', value: 'Not Applicable' }]}
                                                    invalid={{ errors, touched }}
                                                    {...getFieldProps('last_bcps_inspection_status')}
                                                    disabled
                                                />
                                            </FormGroup>
                                            :
                                            <FormGroup row className="col-sm-12 col-md-4 mb-3 mt-3">
                                                <Label className="form-label" htmlFor='last_bcps_inspection_status'>Observation made at last inspection by BCPS team</Label>
                                                <InputField
                                                    type="select"
                                                    placeholder=""
                                                    options={[{ label: 'Applicable', value: 'Applicable' }, { label: 'Not Applicable', value: 'Not Applicable' }]}
                                                    invalid={{ errors, touched }}
                                                    {...getFieldProps('last_bcps_inspection_status')}
                                                />
                                            </FormGroup>}

                                        {accreditationDetailsDisabled || accreditationStatusDisabled ?
                                            <FormGroup row className="col-sm-12 col-md-4 mb-3 mt-3">
                                                <Label className="form-label" htmlFor='observation_measures'>Measures taken to address the observations</Label>
                                                <InputField
                                                    type="select"
                                                    placeholder=""
                                                    options={[{ label: 'Applicable', value: 'Applicable' }, { label: 'Not Applicable', value: 'Not Applicable' }]}
                                                    invalid={{ errors, touched }}
                                                    {...getFieldProps('observation_measures')}
                                                    disabled
                                                />
                                            </FormGroup>
                                            :
                                            <FormGroup row className="col-sm-12 col-md-4 mb-3 mt-3">
                                                <Label className="form-label" htmlFor='observation_measures'>Measures taken to address the observations</Label>
                                                <InputField
                                                    type="select"
                                                    placeholder=""
                                                    options={[{ label: 'Applicable', value: 'Applicable' }, { label: 'Not Applicable', value: 'Not Applicable' }]}
                                                    invalid={{ errors, touched }}
                                                    {...getFieldProps('observation_measures')}
                                                />
                                            </FormGroup>}

                                        {accreditationStatusLabel === 'Accreditated' ?
                                            <FormGroup row className="col-sm-12 mb-3 mt-3">
                                                <UpdatedAccreditationDetails
                                                    inputList={accreditationDetailsList}
                                                    setInputList={setAccreditationDetailsList}
                                                    inputListErrors={accreditationErrors}
                                                    setInputListErrors={setAccreditationErrors}
                                                    observationDocument={values.last_bcps_inspection_status}
                                                    measuresTaken={values.observation_measures}
                                                />
                                            </FormGroup> : null}
                                    </Row>

                                    <hr />

                                    {/* <Row>
                                        <h4>Payment</h4>
                                        <FormGroup className="col-sm-12 col-md-6 mb-3">
                                            <Label
                                                htmlFor="paymentReceiptFile"
                                                className="form-Label required-field"
                                            >
                                                Payment Receipt
                                            </Label>
                                            <input
                                                ref={paymentReceiptFileReference}
                                                name="paymentReceipt"
                                                id="paymentReceiptFile"
                                                type="file"
                                                className="form-control"
                                                placeholder="Upload File"
                                                onChange={(e: any) => {
                                                    setFieldValue(
                                                        'payment_receipt',
                                                        e.target.files[0],
                                                    );
                                                }}
                                            ></input>
                                        </FormGroup>

                                        <FormGroup row className="col-sm-12 col-md-6 mb-3 mt-4">
                                            <Label for="payment_amount" sm={3} className='me-2'>Payment Amount</Label>
                                            <Col sm={6}>
                                                <InputField
                                                    min={'0'}
                                                    type="number"
                                                    placeholder="Payment Amount"
                                                    invalid={{ errors, touched }}
                                                    {...getFieldProps('payment_amount')}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Row> */}

                                    {viewOrEditId && viewOrEditId !== 'edit' ?
                                        null
                                        :
                                        <div className="d-flex justify-content-end mb-3">
                                            <Button color="secondary" className='me-3' onClick={() => {
                                                handleFormReset();
                                            }}>
                                                {'Reset'}
                                            </Button>
                                            {/* <Button color="info" className='me-3' onClick={() => {

                                        }}>
                                            {'Edit'}
                                        </Button> */}
                                            <Button color="info" className='me-3' onClick={() => {
                                                setSubmitStatus(false);
                                                // console.log(errors);
                                                handleSubmit();
                                            }}>
                                                {'Save as Draft'}
                                            </Button>
                                            <Button color="success" onClick={() => {
                                                setSubmitStatus(true);
                                                // console.log(errors);
                                                handleSubmit();
                                            }}>
                                                {'Submit'}
                                            </Button>
                                        </div>}
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default InstituteReg;
