import { LogbookTableContents } from "src/pages/Setup/LogbookTableOfContent";
import { AddLogbookTableOfContent } from "src/pages/Setup/LogbookTableOfContent/AddLogbookTableOfContent";
import { EditLogbookTableOfContent } from "src/pages/Setup/LogbookTableOfContent/EditLogbookTableOfContent";

export const logBookTableOfContentSetupRoutes = [
  // logbook table of content setup
  {
    path: '/setup/logbook-table-of-content/',
    component: LogbookTableContents,
  },
  {
    path: '/setup/logbook-table-of-content/update/:id',
    component: EditLogbookTableOfContent,
  },
  {
    path: '/setup/logbook-table-of-content/add',
    component: AddLogbookTableOfContent,
  },
];
