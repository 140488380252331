import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Select from 'react-select';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetSuspiciousEntryListQuery } from 'src/rtk/features/logbookMonitoringReport/logbookMonitoringReportApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import DetailsViewModal from './DetailsViewModal';
import loader from '../../assets/images/loader.gif';

const TraineeActivityLog = () => {
  const [entryMasterId, setEntryMasterId] = useState('');

  const [detailsModalShow, setDetailsModalShow] = useState(false);

  const [entryDate, setEntryDate] = useState('');

  const [selectedSubject, setSelectedSubject] = useState<any>('');

  const { data: subjects } = useGetSubjectInfosQuery();

  const allSubjects: SelectOption[] =
    subjects?.data?.map((subject) => ({
      value: subject.id!,
      label: subject.subject_name,
    })) || [];

  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');

  const generateQueryUrl = () => {
    return `page=${page}&paginate_per_page=${limit}${search}`;
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setPage(page);
  };

  interface SearchFields {
    search?: string;
    from_date?: string;
    to_date?: string;
    bmdc_registration_no?: string;
    subject?: string;
  }

  const initialValues: SearchFields = {
    search: '',
    from_date: '',
    to_date: '',
    bmdc_registration_no: '',
    subject: '',
  };

  const { data: logList, isSuccess, isLoading, isFetching } = useGetSuspiciousEntryListQuery(generateQueryUrl());
  // console.log(logList?.data?.data);

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.search !== '') {
        s = s + `&search=${values.search}`;
      }

      if (values.from_date !== '') {
        s = s + `&from_date=${values.from_date}`;
      }

      if (values.to_date !== '') {
        s = s + `&to_date=${values.to_date}`;
      }

      if (values.bmdc_registration_no !== '') {
        s = s + `&bmdc_registration_no=${values.bmdc_registration_no}`;
      }

      if (values.subject !== '') {
        s =
          s +
          `&subject=${allSubjects.find((r) => r.value === values.subject)?.label
          }`;
      }

      setSearch(s);
      setPage('1');
    },
  });

  const columns: TableColumn<any>[] = [
    {
      name: <div>Sl. No.</div>,
      cell: (row, index) => <strong>{((page - 1) * limit + index) + 1}</strong>,
      width: '80px',
      wrap: true,
    },

    {
      name: <div>BMDC Reg No</div>,
      selector: (row) => row?.logbook_entry_master?.bmdc_registration_no,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Name</div>,
      selector: (row) => row?.logbook_entry_master?.registration_user_info?.personal_info?.full_name,
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Supervisor Name</div>,
      selector: (row) => row?.logbook_entry_master?.registration_user_info?.current_session_and_supervisor_info?.supervisor?.name,
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Subject</div>,
      selector: (row) => row?.logbook_entry_master?.registration_user_info?.subject?.subject_name,
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Session</div>,
      selector: (row) => row?.logbook_entry_master?.registration_user_info?.current_session_and_supervisor_info?.session_name,
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Total Entry</div>,
      selector: (row) => row?.total,
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Action</div>,
      cell: (row) => (
        <>
          <Button
            className="btn btn-sm btn-info my-2"
            text="View"
            onClick={() => {
              setDetailsModalShow(true);
              setEntryMasterId(row?.logbook_entry_master_id);
              setEntryDate(row?.date);
            }}
          />
          {detailsModalShow && row.logbook_entry_master_id === entryMasterId ? (
            <DetailsViewModal
              isOpen={detailsModalShow}
              toggle={() => setDetailsModalShow(!detailsModalShow)}
              entryMasterId={entryMasterId}
              entryDate={entryDate}
            />
          ) : null}
        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    if (selectedSubject !== '') {
      setFieldValue('subject', selectedSubject?.value);
    }
  }, [selectedSubject]);

  return (
    <div className="page-content">
      {isLoading || isFetching ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb
          breadcrumbItem={[{ link: '#', name: 'Monitoring' }]}
          title={'Suspicious Entry List'}
        />
        <Card>
          <CardBody>
            <Row>
              <Col>
                <div className="gap-2 w-100 ">
                  <Form
                    className="custom-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    onReset={(e) => {
                      e.preventDefault();
                      setSelectedSubject('');
                      resetForm();
                      handleSubmit();
                    }}
                  >
                    <div className="filter-wrapper">
                      <FormGroup>
                        <Label for="bmdc_registration_no">BMDC Reg. No.</Label>
                        <InputField
                          type="text"
                          placeholder="BMDC Reg. No."
                          {...getFieldProps('bmdc_registration_no')}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="from_date">From Date</Label>
                        <InputField
                          type="date"
                          placeholder=""
                          invalid={{ errors, touched }}
                          {...getFieldProps('from_date')}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="to_date">To Date</Label>
                        <InputField
                          type="date"
                          placeholder=""
                          invalid={{ errors, touched }}
                          {...getFieldProps('to_date')}
                        />
                      </FormGroup>


                      <FormGroup>
                        <Label for="subject">Subject</Label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          value={selectedSubject}
                          onChange={setSelectedSubject}
                          options={allSubjects}
                          placeholder='Select...'
                          name="subject"
                        />
                      </FormGroup>

                      <FormGroup className="btn-column mb-3">
                        <Button color="danger" type="reset" text="Reset" />

                        <Button type="submit" text="Search" />
                      </FormGroup>

                    </div>
                  </Form>
                </div>
              </Col>
            </Row>

            <DataTable
              columns={columns}
              data={logList?.data?.data!}
              pagination
              paginationServer
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              progressPending={isLoading}
              expandOnRowClicked
              highlightOnHover
              paginationTotalRows={logList?.data?.total}
              paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
              responsive
              persistTableHead={true}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default TraineeActivityLog;
