import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Container, Form, FormGroup, Input, Label } from 'reactstrap';
import { Button, InputField } from 'src/components';
import { useGetDynamicFormPropertyByParticularQuery } from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import {
  useGetCompetencyGroupByIdQuery,
  useUpdateLogbookEntryMutation,
} from 'src/rtk/features/trainee/traineeLogbookEntryApi';
import * as Yup from 'yup';
import loader from '../../../../assets/images/loader.gif';
import UpdatedEditCompetencyGroupAndDetails from './UpdatedEditCompetencyGroupAndDetails';

interface Field {
  id: string;
  name: string;
  placeholder?: string;
  type: string;
  options?: LogbookEntryDetails[];
  required?: number;
}

const EditDynamicForm = (props: any) => {
  const { logbookData, setShowAddForm } = props;

  const [reRenderCompetency, setReRenderCompetency] = useState<number>(0);

  const [formName, setFormName] = useState<string>('');
  const [caseTypeName, setCaseTypeName] = useState<string>('');
  const [skillTypeName, setSkillTypeName] = useState<string>('');
  const [entrySetupMasterId, setEntrySetupMasterId] = useState<string>('');

  const { data: formFieldsByCaseType, isError: isFormFieldsError } =
    useGetDynamicFormPropertyByParticularQuery(
      logbookData.logbook_entry_setup_master_id ?? '',
    );
  const dynamicFormProps = formFieldsByCaseType?.data || [];

  const { data: competencyGroupData, isError: isCompetencyGroupDataError } =
    useGetCompetencyGroupByIdQuery(
      logbookData.logbook_entry_setup_master_id ?? '',
    );
  const competencyGroup = competencyGroupData?.data || [];

  const InputFields: Field[] = dynamicFormProps.map(
    (field: any, index: number) => {
      return {
        id: field.id,
        name: `${field['column_name']?.replace('.', '')}`,
        placeholder: field.details_text,
        type: field.column_value_type,
        options: field.options,
        required: field.required_ind,
      };
    },
  );

  const [submitIndicator, setSubmitIndicator] = useState<number>(1);
  const [submitted, setSubmitted] = useState<boolean>(false);

  //   const { data: form, isError: isFormError } = useGetLogBookMasterSetupQuery(formId);
  //   const { data: caseType, isError: isCaseTypeError } = useGetLogbookEntrySetupMasterQuery(caseTypeId);
  //   const { data: skillType, isError: isSkillTypeError } = useGetLogbookEntrySetupMasterQuery(skillTypeId);

  const [
    updateLogbookEntry,
    {
      data: logbookEntry,
      error: logbookEntryError,
      isSuccess: isLogbookEntrySuccess,
      isError: isLogbookEntryError,
      isLoading: isLogbookEntryLoading,
    },
  ] = useUpdateLogbookEntryMutation();

  const competenceLevelOptions: LookupData[] = useGetLookupDataQuery('competence_level').data?.data[0]?.childs!;

  type FormValues = {
    [key: string]: string | number | boolean | undefined;
  };

  const entryError: CustomError = logbookEntryError as CustomError;

  const initialValues = React.useMemo(() => {
    const fieldsObj = InputFields.reduce((acc: FormValues, field: Field) => {
      switch (field.type) {
        case 'text':
        case 'textarea':
        case 'email':
        case 'date':
        case 'file':
          acc[`${field['name']}`] = '';
          break;
        case 'number':
          acc[field['name']] = 0;
          break;
        case 'checkbox':
          acc[field['name']] = false;
          break;
        case 'select':
          if (field.options?.length! > 0) {
            acc[`${field['name']}`] = '';
          }
          break;
        case 'radio':
          if (field.options?.length! > 0) {
            acc[`${field['name']}`] = '';
          }
          break;
        default:
          acc[field['name']] = undefined;
          break;
      }

      return acc;
    }, {});

    return fieldsObj;
  }, [InputFields]);

  const generateValidationSchema = (inputFields: any) => {
    const validationSchema: Record<string, any> = {};

    inputFields.forEach((field: any) => {
      if (
        field.required &&
        (field.type === 'text' ||
          field.type === 'textarea' ||
          field.type === 'date' ||
          field.type === 'email')
      ) {
        validationSchema[field['name']] = Yup.string().required(
          `${field['name']} is required`,
        );
      } else if (field.required && field.type === 'select') {
        if (field.options?.length! > 0) {
          validationSchema[field['name']] = Yup.string().required(
            `${field['name']} is required`,
          );
        }
      } else if (field.required && field.type === 'radio') {
        if (field.options?.length! > 0) {
          validationSchema[field['name']] = Yup.string().required(
            `${field['name']} is required`,
          );
        }
      } else if (field.required && field.type === 'file') {
        validationSchema[field['name']] = Yup.string().required(`${field['name']} is required`);
      }
    });

    return Yup.object().shape(validationSchema);
  };

  const getBase64 = (file: any) => new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  const decodeBase64EncodeString = (base64String: string) => {
    let correctBase64EncodedString: string = base64String.split("base64,")?.[1];
    let tempString: string = base64String.split("base64,")?.[0];
    let typeOfDocument: string = tempString.split("data:")?.[1] + ';base64';
    let byteCharacters = atob(correctBase64EncodedString);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: typeOfDocument });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const returnBase64EncodedFile = (base64String: string) => {
    let correctBase64EncodedString: string = base64String.split("base64,")?.[1];
    let tempString: string = base64String.split("base64,")?.[0];
    let typeOfDocument: string = tempString.split("data:")?.[1] + ';base64';
    let byteCharacters = atob(correctBase64EncodedString);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: typeOfDocument });
    return file;
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema:
      InputFields.length > 0 ? generateValidationSchema(InputFields) : null,
    onSubmit: (values) => {
      setSubmitted(true);
      console.log(values);

      const sortedValues: any = {};
      InputFields?.forEach((i: Field) => {
        sortedValues[i?.name] = values[i?.name];
      });

      const finalSubmittedValue = {
        id: logbookData.id,
        heading: formName,
        entry_setup_master_id: entrySetupMasterId,
        details_json: JSON.stringify(
          {
            entry_setup_master_id: entrySetupMasterId,
            form: formName,
            caseType: caseTypeName,
            skillType: skillTypeName,
            ...sortedValues,
          },
          null,
          2,
        ),
        competency_group: values?.competency_group ?? [],
        submit_ind: submitIndicator,
        status: 1,
        log_type: 1,
      };

      updateLogbookEntry({
        ...finalSubmittedValue,
      });

      // alert(JSON.stringify(values, null, 2));
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    handleReset,
    setValues,
    setFieldValue,
    getFieldProps,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = formik;

  useEffect(() => {
    if (isLogbookEntrySuccess) {
      setSubmitted(false);
      setShowAddForm(false);
      toast.success('Logbook entry updated successfully');
    }
    if (isLogbookEntryError) {
      setSubmitted(false);
      toast.error(entryError?.message || 'Something went wrong!');
    }
  }, [isLogbookEntrySuccess, isLogbookEntryError]);

  useEffect(() => {
    if (formFieldsByCaseType && !isFormFieldsError) {
      const data: any = JSON.parse(logbookData?.details_json);

      setEntrySetupMasterId(data.entry_setup_master_id);
      setFormName(data.form);
      setCaseTypeName(data.caseType);
      setSkillTypeName(data.skillType);

      const jsonValues: any = {};

      Object.entries(data).forEach((entry: any) => {
        if (
          entry[0] !== 'entry_setup_master_id' &&
          entry[0] !== 'form' &&
          entry[0] !== 'caseType' &&
          entry[0] !== 'skillType'
        ) {
          const inputField: Field = InputFields.find(
            (i: Field) => i.name === entry[0],
          )!;
          jsonValues[entry[0]] = entry[1];
        }
      });

      setValues(jsonValues);
    }
  }, [formFieldsByCaseType, isFormFieldsError]);

  return (
    <>
      {isLogbookEntryLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <div className="row align-items-center justify-content-center">
          <div className="card mt-3">
            <h5 className="card-header">
              <i className="fa fa-edit me-2" />
              Edit Logbook Entry
            </h5>
            <div className="card-body">
              <Form
                className="custom-form"
                onSubmit={(e: any) => {
                  e.preventDefault();
                  handleSubmit();
                }}
                onReset={(e: any) => {
                  e.preventDefault();
                  resetForm();
                }}
              >
                <div className="row">
                  {InputFields?.map((field, index) => {
                    switch (field.type) {
                      case 'checkbox':
                        return (
                          <div key={field.id} className="col-sm-12">
                            <FormGroup check className="mb-3 mt-4">
                              <Label check htmlFor={field['name']}>
                                <Input
                                  type={field.type}
                                  checked={!!values[field['name']]}
                                  {...getFieldProps(field['name'])}
                                />{' '}
                                {field['name']}
                              </Label>
                            </FormGroup>
                          </div>
                        );
                      case 'radio':
                        return field.options?.length! > 0 ? (
                          <div key={field.id} className="col-sm-12">
                            <Label htmlFor={field['name']} className={field.required ? "required-field form-label" : "form-label"}>{field['name']}</Label>
                            <br />
                            {field.options?.map((option: any) => {
                              return (
                                <FormGroup check inline key={option.id}>
                                  <Input
                                    id={option.column_name}
                                    name={field['name']}
                                    type={field.type as InputType}
                                    value={option.details_text}
                                    checked={
                                      values[field['name']] ===
                                      option.details_text
                                    }
                                    onChange={(e) => {
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                  />

                                  <Label check htmlFor={option.column_name}>
                                    {option.column_name}
                                  </Label>
                                </FormGroup>
                              );
                            })}
                          </div>
                        ) : null;
                      case 'file':
                        return (
                          <div
                            key={field.id}
                            className="col-lg-4 col-md-6 col-sm-12"
                          >
                            <FormGroup className="mb-3">
                              <Label htmlFor={field['name']} className={field.required ? "required-field form-label me-2" : "form-label me-2"}>
                                {field['name']} (PDF file)
                              </Label>
                              {typeof values?.[field['name']] === 'string' &&
                                values?.[field['name']] !== '' ? (
                                <a
                                  className='btn btn-sm btn-outline-info my-2'
                                  rel='noreferrer'
                                  target='_blank'
                                  onClick={(e) => {
                                    decodeBase64EncodeString(values?.[field['name']] as string);
                                  }}
                                >
                                  View
                                </a>
                              ) : (
                                null
                              )}
                              <InputField
                                type={field.type as InputType}
                                name={field['name']}
                                id={field['name']}
                                placeholder={field.placeholder}
                                onChange={(e) => {
                                  getBase64(e.target?.files[0]).then((value) => {
                                    setFieldValue(field['name'], value);
                                    console.log(value);
                                  }).catch((err) => {
                                    setFieldValue(field['name'], '');
                                  });
                                }}
                                onBlur={handleBlur}
                                invalid={{ errors, touched }}
                              />
                            </FormGroup>
                          </div>
                        );
                      case 'select':
                        return field.options?.length! > 0 ? (
                          <div
                            key={field.id}
                            className="col-lg-4 col-md-6 col-sm-12"
                          >
                            <FormGroup className="mb-3">
                              <Label for={field['name']} className={field.required ? "required-field form-label" : "form-label"}>{field['name']}</Label>
                              <InputField
                                type={field.type as InputType}
                                placeholder={field.placeholder}
                                options={field.options?.map((option: any) => {
                                  return {
                                    label: option.column_name,
                                    value: option.details_text,
                                  };
                                })}
                                invalid={{ errors, touched }}
                                {...getFieldProps(field['name'])}
                              />
                            </FormGroup>
                          </div>
                        ) : null;
                      case 'date':
                        return (
                          ['date of birth', 'dob'].includes(field['name'].toLowerCase()) ?
                            <div key={field.id} className="col-lg-4 col-md-6 col-sm-12">
                              <FormGroup className="mb-3">
                                <Label for={field['name']} className={field.required ? "required-field form-label" : "form-label"}>{field['name']}</Label>
                                <InputField
                                  type={field.type as InputType}
                                  placeholder={field.placeholder}                                  
                                  invalid={{ errors, touched }}
                                  {...getFieldProps(`${field['name']}`)}
                                />
                              </FormGroup>
                            </div>
                            :
                            <div key={field.id} className="col-lg-4 col-md-6 col-sm-12">
                              <FormGroup className="mb-3">
                                <Label for={field['name']} className={field.required ? "required-field form-label" : "form-label"}>{field['name']}</Label>
                                <InputField
                                  type={field.type as InputType}
                                  placeholder={field.placeholder}
                                  min={new Date(Date.now()).toISOString().slice(0, 10)}
                                  invalid={{ errors, touched }}
                                  {...getFieldProps(`${field['name']}`)}
                                />
                              </FormGroup>
                            </div>
                        );
                      default:
                        return (
                          <div
                            key={field.id}
                            className="col-lg-4 col-md-6 col-sm-12"
                          >
                            <FormGroup className="mb-3">
                              <Label for={field['name']} className={field.required ? "required-field form-label" : "form-label"}>{field['name']}</Label>
                              <InputField
                                type={field.type as InputType}
                                placeholder={field.placeholder}
                                invalid={{ errors, touched }}
                                {...getFieldProps(`${field['name']}`)}
                              />
                            </FormGroup>
                          </div>
                        );
                    }
                  })}

                  {logbookData?.competency_group?.length &&
                    formik.values ? (
                    <UpdatedEditCompetencyGroupAndDetails
                      key={reRenderCompetency}
                      formik={formik}
                      logbookData={logbookData}
                      competency_group={
                        logbookData?.competency_group?.map((competency: any) => {
                          return {
                            id: competency.logbook_entry_setup_master_id,
                            name: competency.skill_name,
                            level: [],
                          }
                        })}
                      caseTypeName={caseTypeName}
                      skillTypeName={skillTypeName}
                      competencyGroup={competencyGroup[0]?.competency_group}
                      competenceLevelOptions={competenceLevelOptions}
                    />
                  ) : (
                    competencyGroup[0]?.competency_group?.length ?
                      <UpdatedEditCompetencyGroupAndDetails
                        key={reRenderCompetency}
                        formik={formik}
                        logbookData={logbookData}
                        competency_group={
                          logbookData?.competency_group?.map((competency: any) => {
                            return {
                              id: competency.logbook_entry_setup_master_id,
                              name: competency.skill_name,
                              level: [],
                            }
                          })}
                        caseTypeName={caseTypeName}
                        skillTypeName={skillTypeName}
                        competencyGroup={competencyGroup[0]?.competency_group}
                        competenceLevelOptions={competenceLevelOptions}
                      /> : null
                  )}

                  <div className="col-sm-12">
                    <FormGroup className="mb-3 text-center">
                      <Button
                        type="reset"
                        text="Reset"
                        className="btn btn-secondary me-2"
                        onClick={(e) => {
                          setValues(initialValues);
                        }}
                      ></Button>
                      {!submitted ? (
                        <>
                          <Button
                            type="submit"
                            text="Save as Draft"
                            className="btn btn-info me-2"
                            onClick={(e) => {
                              setSubmitIndicator(0);
                            }}
                          ></Button>
                          <Button
                            type="submit"
                            text="Submit"
                            className="btn btn-success"
                            onClick={(e) => {
                              setSubmitIndicator(1);
                            }}
                          ></Button>
                        </>
                      ) : (
                        <>
                          <Button
                            type="submit"
                            text="Save as Draft"
                            className="btn btn-info me-2"
                            onClick={(e) => {
                              setSubmitIndicator(0);
                            }}
                            disabled
                          ></Button>
                          <Button
                            type="submit"
                            text="Submit"
                            className="btn btn-success"
                            onClick={(e) => {
                              setSubmitIndicator(1);
                            }}
                            disabled
                          ></Button>
                        </>
                      )}
                      <Button
                        className="ms-2"
                        size="md"
                        color="danger"
                        type="button"
                        text={`<span class="fas fa-times" ></span> Close`}
                        onClick={() => {
                          setShowAddForm(false);
                        }}
                      />
                    </FormGroup>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default EditDynamicForm;
