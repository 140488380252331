import moment from 'moment';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardGroup,
    CardHeader,
    CardText,
    CardTitle,
    Col,
    Container,
    Modal,
    Row,
    Table
} from "reactstrap";
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import DisplayImage from 'src/components/Common/displayImage';
import { config } from 'src/config';
import { ViewCertificatePdf } from 'src/pages/Certificate/ViewCertificatePdf';
import { UpdatedViewNoticePdf } from 'src/pages/Notice/UpdatedViewNoticePdf';
import ProfileView from 'src/pages/Supervisor/SessionApproval/ProfileView';
import DescriptionModal from 'src/pages/TrainingWorkshop/DescriptionModal';
import TrainingViewDeatailsModal from 'src/pages/TrainingWorkshop/TrainingViewDeatailsModal';
import WorkshopListModal from 'src/pages/TrainingWorkshop/WorkshopListModal';
import { useAppSelector } from 'src/rtk/app/hook';
import { useLazyGetCertificatePdfQuery } from 'src/rtk/features/certificate/publishCertificateApi';
import { useCreatePaymentWitoutFeesApplyMutation } from 'src/rtk/features/myTraining/myTrainingApi';
import { useGetNoticeListQuery } from 'src/rtk/features/notice/publishNoticeApi';
import { useGetMyRegInfoQuery } from 'src/rtk/features/registration-approval/registrationApprovalApi';
import { useGetSupervisorDashboardInfoQuery } from 'src/rtk/features/traineeDashboard/traineeDashboardApi';
import loader from '../../../assets/images/loader.gif';

const SupervisorDashboard = (props: any) => {
    const { mainDashboard, personalProfile } = props;

    document.title = 'Supervisor Dashboard | BCPS';

    const [descriptionModalShow, setDescriptionnModalShow] = useState(false);
    const [trainingViewModalShow, setTrainingViewModalShow] = useState(false);
    const [trainingId, setTrainingId] = useState('');
    const [description, setDescription] = useState('');
    const [selectedWorkshop, setSelectedWorkshop] = useState<any>({});
    const [noticeId, setNoticeId] = useState<string>('');
    const [certificate, setCertificate] = useState<any>({});
    const [certificateInfoObj, setCertificateInfoObj] = useState<any>({});
    const [trainingTitleForCertificate, setTrainingTitleForCertificate] = useState<string>('');
    const [trainingDuration, setTrainingDuration] = useState<string>('');
    const [trainingDurationUnit, setTrainingDurationUnit] = useState<string>('');
    const [certificateId, setCertificateId] = useState<string>('');
    const [isFcps, setIsFcps] = useState<boolean>(false);
    const [workShopListModalShow, setWorkShopListModalShow] = useState(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [regId, setRegId] = useState<string>('');

    const history = useHistory();

    const [getCertificatePdf, { data: certificateData, isSuccess: isCertificateDataSuccess, isError: isCertificateDataError, isLoading: isCertificateDataLoading, error: certificateDataError }] = useLazyGetCertificatePdfQuery();

    const { data: noticeListData } = useGetNoticeListQuery();

    const [createPaymentWitoutFeesApply, { isError, isSuccess, error }] = useCreatePaymentWitoutFeesApplyMutation();
    const err: CustomError = error as CustomError;

    const authUser = useAppSelector((state) => state.auth.user);

    const { data: info, isLoading: isDataLoading } = useGetMyRegInfoQuery(authUser?.registration_no);

    const userInfo = info?.data || ({} as RegInfo);

    const {
        data: dataList,
        isLoading: dataListLoading,
        isSuccess: dataListLoadingSuccess,
        error: dataListLoadingError,
    } = useGetSupervisorDashboardInfoQuery();

    const data = dataList?.data!;

    console.log(dataList)

    useEffect(() => {
        if (isSuccess) {
            toast.success('Applied successfully');
        }
        if (isError) {
            toast.error(err?.message || 'Something went wrong');
        }
    }, [isSuccess, isError]);

    useEffect(() => {
        userInfo?.educational_infos?.find((item: any) => item?.name_of_the_degree === 'FCPS') ? setIsFcps(true) : setIsFcps(false);
    }, [userInfo?.educational_infos]);

    useEffect(() => {
        if (certificateId) {
            getCertificatePdf(certificateId);
        }
    }, [certificateId]);

    useEffect(() => {
        if (isCertificateDataLoading || !isCertificateDataSuccess) {
            setCertificate({});
            setCertificateInfoObj({});
        } else {
            setCertificate(certificateData?.data);
            setCertificateInfoObj({
                name: authUser?.name,
                bmdc_reg_no: authUser?.details?.bmdc_registration_no,
                subject: authUser?.details?.subject?.subject_name,
                institute: authUser?.details?.current_session_and_supervisor_info?.supervisor?.institute_name,
                issue_date: moment(certificateData?.data?.notice_date?.substring(0, 10)).format('DD/MMM/YYYY'),
            });
        }
    }, [isCertificateDataSuccess, isCertificateDataLoading]);

    const trainingDescriptionModalShowHandler = (modalState: boolean, description: string, trainingId: string) => {
        setDescriptionnModalShow(modalState);
        setDescription(description);
        setTrainingId(trainingId);
    };

    const trainingModalShowHandler = (modalState: boolean, trainingId: string) => {
        setTrainingViewModalShow(modalState);
        setTrainingId(trainingId);
    };

    const modalWorkshopApplyHandler = (modalState: boolean, workshop: any) => {
        setWorkShopListModalShow(modalState);
        setSelectedWorkshop(workshop);
    };

    const row = noticeListData?.data.find((item: any) => { return item.id === noticeId });

    const modalShowHandler = (regNo: string) => {
        setRegId(regNo);
        setShowModal(true);
    }


    return (
        <>
            {mainDashboard ?
                <div className='page-content p-0' style={personalProfile?.data?.details?.profile_approval_status !== 'Approve' ? { pointerEvents: 'none' } : {}}>
                    {dataListLoading ? (
                        <div className="overlay">
                            <img src={loader} alt="Loading" height={100} width={300} />
                        </div>
                    ) : null}
                    <React.Fragment>
                        <BetterBreadcrumb title='Supervisor Dashboard' />
                        <Container fluid>
                            {personalProfile?.data?.details?.bmdc_registration_no_verified ===
                                'Pending' ? (
                                <Alert color='danger'>Your BMDC Number is Under Review!</Alert>
                            ) : null}
                            {/* <br /> */}
                            {personalProfile?.data?.details?.profile_approval_status ===
                                'Submit' ? (
                                <Alert color='danger'> Your Registration Under Review! </Alert>
                            ) : null}
                            {personalProfile?.data?.details?.profile_approval_status ===
                                'Correction' ? (
                                <Alert color='danger'>
                                    {' '}
                                    Please Update Your Profile With Correct Information! {' '}{personalProfile?.data?.details?.profile_approval_note}
                                </Alert>
                            ) : null}
                            {personalProfile?.data?.details?.profile_approval_status ===
                                'Reject' ? (
                                <Alert color='danger'> Your Profile Rejected! </Alert>
                            ) : null}
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <Card className=''>
                                        <CardBody className='mx-4'>
                                            <h5>Trainee Status</h5>
                                            <CardGroup className='mt-3' tag="h3">
                                                <Card body color="info" role='button' className='text-center fw-bold rounded bg-opacity-50 me-5' style={{ width: '100px' }} onClick={(e) => {
                                                    window.open(`/supervisor/session-approval/${'All'}`, '_blank');
                                                }}>
                                                    <CardTitle>Total Trainee</CardTitle>
                                                    <CardText>{data?.trainee_status?.total_trainee}</CardText>
                                                </Card>
                                                <Card body color="success" role='button' className='text-center fw-bold rounded bg-opacity-50 me-5' onClick={(e) => {
                                                    window.open(`/supervisor/session-approval/${'Current'}`, '_blank');
                                                }}>
                                                    <CardTitle>Current Trainee</CardTitle>
                                                    <CardText>{data?.trainee_status?.current_trainee}</CardText>
                                                </Card>
                                                <Card body color="success" role='button' className='text-center fw-bold rounded bg-opacity-50 me-5' onClick={(e) => {
                                                    window.open(`/supervisor/session-approval/${'Completed'}`, '_blank');
                                                }}>
                                                    <CardTitle>Completed Trainee</CardTitle>
                                                    <CardText>{0}</CardText>
                                                </Card>
                                                <Card body color="danger" role='button' className='text-center fw-bold rounded bg-opacity-50 me-5' onClick={(e) => {
                                                    window.open(`/supervisor/session-approval/${'Pending'}`, '_blank');
                                                }}>
                                                    <CardTitle>Slot Approval Pending</CardTitle>
                                                    <CardText>{data?.trainee_status?.slot_approval_pending}</CardText>
                                                </Card>
                                                <Card body color="warning" role='button' className='text-center fw-bold rounded bg-opacity-50' onClick={(e) => {
                                                    window.open(`/supervisor/logbook`, '_blank');
                                                }}>
                                                    <CardTitle>Logbook Approval Pending</CardTitle>
                                                    <CardText>{data?.trainee_status?.logbook_approval_pending}</CardText>
                                                </Card>
                                            </CardGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={6} md={6} sm={6} className='p-1'>
                                            <h5>Current Trainee List</h5>
                                            <Row>
                                                {data?.current_trainee_infos
                                                    ?.map((item: any) => (
                                                        <Col lg={6} md={12} sm={12} key={item.id} className='p-1'>
                                                            <Card>
                                                                <CardBody className='p-2'>
                                                                    <div className='row'>
                                                                        <div className='col-lg-4 col-md-4 col-sm-12'>
                                                                            <DisplayImage
                                                                                className="img-fluid img-rounded img-thumbnail img-width clickable"
                                                                                src={`${config?.APP_URL}${item?.registration_user_info?.personal_info?.picture}`}
                                                                                onClick={() => modalShowHandler(item?.registration_user_info?.registration_no)}
                                                                            />
                                                                            {/* <div className="img-fluid img-rounded text-center">
                                                                    <img
                                                                        src={
                                                                            item?.registration_user_info?.personal_info?.picture
                                                                                ? `${config?.APP_URL}${item?.registration_user_info?.personal_info?.picture}`
                                                                                : defaultAvatar
                                                                        }
                                                                        className="img-thumbnail img-width"
                                                                    />
                                                                </div> */}
                                                                        </div>
                                                                        <div className='col-lg-8 col-md-8 col-sm-12 p-0'>

                                                                            <h5 className="font-size-14 fw-bold mb-1">
                                                                                {item?.registration_user_info?.personal_info?.full_name ?? '--'}
                                                                            </h5>
                                                                            <p className="mb-1">
                                                                                {item?.registration_user_info?.personal_info?.email}
                                                                            </p>
                                                                            <p className="mb-1">
                                                                                BMDC No : {item?.registration_user_info?.bmdc_registration_no}
                                                                            </p>
                                                                            <p className="mb-1">
                                                                                Mobile No : {item?.registration_user_info?.personal_info?.mobile}
                                                                            </p>
                                                                            <p className="mb-1">
                                                                                {item?.registration_user_info?.subject?.subject_name}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <Button
                                                                        color='light'
                                                                        className='w-100'
                                                                        onClick={() => window.open(`/trainee/training-summary-for-admin-and-supervisor/${item?.registration_user_info?.user_id}/${item?.registration_user_info?.current_session_and_supervisor_info?.id}`, '_blank')}
                                                                    >
                                                                        View Logbook Summary
                                                                    </Button>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    ))}
                                            </Row>
                                        </Col>
                                        <Col lg={6} md={6} sm={6} className='py-2'>
                                            <h5>Approval Pending List</h5>
                                            <Card>
                                                <CardHeader className='fw-bold bg-info bg-opacity-25'>
                                                    Pending Logbook Approval
                                                </CardHeader>
                                                <CardBody className='p-2'>
                                                    <div className="table-responsive">
                                                        <Table className="align-middle table-sm mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Event Name</th>
                                                                    <th>Pending Count</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data?.pending_approvals?.pending_logbook_approval?.map((item: any, index: number) => (
                                                                    <tr key={item.id}>
                                                                        <th scope="row">{++index}</th>
                                                                        <td>{item?.heading}</td>
                                                                        <td>{item?.total}</td>
                                                                        <td>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-light btn-sm"
                                                                                onClick={(e) => {
                                                                                    window.open(`/supervisor/logbook/${item?.heading}`, '_blank');
                                                                                }}
                                                                            >
                                                                                View
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardHeader className='fw-bold bg-info bg-opacity-25'>
                                                    Pending Slot Approval
                                                </CardHeader>
                                                <CardBody className='p-2'>
                                                    <div className="table-responsive">
                                                        <Table className="align-middle table-sm mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Event Name</th>
                                                                    <th>Pending Count</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data?.pending_approvals?.pending_slot_approval?.map((item: any, index: number) => (
                                                                    <tr key={item.id}>
                                                                        <th scope="row">{++index}</th>
                                                                        <td>{item?.session_name}</td>
                                                                        <td>{item?.total}</td>
                                                                        <td>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-light btn-sm"
                                                                                onClick={() => history.push('/supervisor/session-approval')}
                                                                            >
                                                                                View
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <h5>Guide/Co-Guide Eligibility</h5>
                                            <Card>
                                                <CardBody>
                                                    <div>
                                                        {(userInfo?.current_employment_info?.designation === 'Professor' ||
                                                            userInfo?.current_employment_info?.designation === 'Assistant Professor' ||
                                                            userInfo?.current_employment_info?.designation === 'Associate Professor') && isFcps ?
                                                            <Alert color="success" style={{ width: '100%' }}><i className="fa-solid fa-check fa-2xl" style={{ color: "#215903" }} />&nbsp;You are eligible for applying as a Guide/Co-Guide</Alert> :
                                                            <Alert color="danger" style={{ width: '100%' }}><i className="fa-solid fa-xmark fa-2xl" style={{ color: "#f00505" }} />&nbsp;Eligibility not matched! Please Update Your Profile</Alert>
                                                        }
                                                    </div>
                                                    <h6 className="block-heading mt-3">Guide/Co-Guide Eligibility Criteria</h6>
                                                    <>
                                                        <div className='mb-1'>
                                                            {(userInfo?.current_employment_info?.designation === 'Professor' || userInfo?.current_employment_info?.designation === 'Associate Professor') && isFcps ?
                                                                <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                                                                : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                                                            }
                                                            &nbsp;Professor or Associate Professor of an institute with FCPS Degree
                                                        </div>
                                                        <div className='mb-1'>
                                                            <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                                                            {/* {userInfo?.current_employment_info?.designation === 'Assistant Professor' || userInfo?.current_employment_info?.in_charge ?
                                                                <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                                                                : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                                                            } */}
                                                            &nbsp;Assistant Professor with completion of Research Methodology TOT program
                                                        </div>
                                                        <div className='mb-1'>
                                                            {userInfo?.current_employment_info?.designation === 'Assistant Professor' && isFcps ?
                                                                <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                                                                : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                                                            }
                                                            &nbsp;Assistant Professor with FCPS Degree
                                                        </div>
                                                        <div className="mt-3 text-end">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-success"
                                                                disabled={(userInfo?.current_employment_info?.designation !== 'Professor' &&
                                                                    userInfo?.current_employment_info?.designation !== 'Assistant Professor' &&
                                                                    userInfo?.current_employment_info?.designation !== 'Associate Professor') ||
                                                                    userInfo?.current_employment_info === null || !isFcps
                                                                }
                                                                onClick={() => {
                                                                    history.push('/guide/list')
                                                                }}
                                                            >
                                                                Apply
                                                            </button>
                                                        </div>
                                                    </>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <h5 className="block-heading my-3">Training/Workshop for Supervisor</h5>
                                    <div className="table-responsive">
                                        <Table className="table table-sm table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Sl</th>
                                                    <th>Type</th>
                                                    <th>Workshop/Training</th>
                                                    <th>Duration</th>
                                                    <th>Fee</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data?.workshop_training_list?.map((item: any, index: number) => (
                                                    <tr key={item?.exam_training_id}>
                                                        <td>{++index}</td>
                                                        <td>{item?.training_workshop_info?.type}</td>
                                                        <td>{item?.training_workshop_info?.name}</td>
                                                        <td>{item?.training_workshop_info?.duration + ' ' + item?.training_workshop_info?.duration_unit}</td>
                                                        <td>{item?.training_workshop_info?.exam_training_fee?.exam_fee === '0' ? 'Free' : item?.training_workshop_info?.exam_training_fee?.exam_fee}</td>
                                                        <td>
                                                            {item?.payment_verification_status === 'Approved' ? <span className="badge bg-success me-1">Approved</span> : item?.payment_verification_status === 'Pending' ? <span className="badge bg-warning me-1">Pending</span> : null}
                                                            {item?.active_course ? <span className="badge bg-success">Applied</span> : <span className="badge bg-info">Not Applied</span>}
                                                        </td>
                                                        <td>
                                                            <button className="me-1 btn btn-sm btn-outline-primary" onClick={() => { trainingDescriptionModalShowHandler(true, item?.training_workshop_info?.description!, item?.training_workshop_info?.id!) }}>
                                                                Instruction
                                                            </button>

                                                            {item?.certificate_issued === 1 &&
                                                                <button className='btn btn-sm btn-warning me-1'
                                                                    onClick={(e) => {
                                                                        setCertificateId(item?.training_workshop_info?.certificate_ref_no!);
                                                                        setTrainingTitleForCertificate(item?.training_workshop_info?.name!);
                                                                        setTrainingDuration(item?.training_workshop_info?.duration!);
                                                                        setTrainingDurationUnit(item?.training_workshop_info?.duration_unit!);
                                                                    }}
                                                                >
                                                                    Certificate
                                                                </button>
                                                            }

                                                            <button
                                                                className="btn btn-sm btn-danger me-1"
                                                                onClick={() => {
                                                                    setNoticeId(item?.notice_ref_no!);
                                                                }}
                                                            >
                                                                Notice
                                                            </button>

                                                            {item?.workshop_skill_master_id &&
                                                                <Button
                                                                    type="button"
                                                                    color="info"
                                                                    className="me-1 btn-sm"
                                                                    onClick={() => { trainingModalShowHandler(true, item?.training_workshop_info?.id!) }}
                                                                >
                                                                    View
                                                                </Button>}
                                                            {item?.training_workshop_info.exam_training_fee?.exam_fee !== '0' ?
                                                                <Button
                                                                    type="button"
                                                                    color="success"
                                                                    className="me-1 btn-sm"
                                                                    onClick={() => modalWorkshopApplyHandler(true, item?.training_workshop_info)}
                                                                    disabled={item?.active_course}
                                                                >
                                                                    Apply
                                                                </Button>
                                                                :
                                                                <Button
                                                                    type="button"
                                                                    color="success"
                                                                    className="me-1 btn-sm"
                                                                    onClick={() => {
                                                                        const body = {
                                                                            exam_course_id: item?.training_workshop_info?.id,
                                                                        };
                                                                        createPaymentWitoutFeesApply(body);
                                                                    }}
                                                                    disabled={item?.active_course}
                                                                >
                                                                    Apply
                                                                </Button>
                                                            }
                                                        </td>
                                                        {descriptionModalShow && trainingId === item?.training_workshop_info.id ? (
                                                            <DescriptionModal
                                                                isOpen={descriptionModalShow}
                                                                toggle={() => setDescriptionnModalShow(!descriptionModalShow)}
                                                                description={description}
                                                            />
                                                        ) : null}
                                                        {trainingViewModalShow && trainingId === item?.training_workshop_info.id ? (
                                                            <TrainingViewDeatailsModal
                                                                isOpen={trainingViewModalShow}
                                                                toggle={() => setTrainingViewModalShow(!trainingViewModalShow)}
                                                                trainingId={item?.workshop_skill_master_id!}
                                                                attachments={item?.attachment}
                                                            />
                                                        ) : null}
                                                        {noticeId === item?.notice_ref_no ? (
                                                            <UpdatedViewNoticePdf data={row} noticeId={noticeId} setNoticeId={setNoticeId} />
                                                        ) : null}
                                                    </tr>
                                                ))}
                                            </tbody>
                                            {workShopListModalShow ? (
                                                <WorkshopListModal
                                                    isOpen={workShopListModalShow}
                                                    toggle={() => setWorkShopListModalShow(!workShopListModalShow)}
                                                    workshopName={selectedWorkshop.name!}
                                                    type={selectedWorkshop.type!}
                                                    fees={selectedWorkshop.exam_training_fee?.exam_fee!}
                                                    id={selectedWorkshop.id!}
                                                />
                                            ) : null}
                                            {certificateId === certificate?.id && certificateInfoObj?.name && trainingTitleForCertificate ? (
                                                <ViewCertificatePdf
                                                    data={certificate}
                                                    certificateId={certificateId}
                                                    setCertificateId={setCertificateId}
                                                    certificateInfoObj={certificateInfoObj}
                                                    trainingTitleForCertificate={trainingTitleForCertificate}
                                                    trainingDuration={trainingDuration}
                                                    trainingDurationUnit={trainingDurationUnit}
                                                />
                                            ) : null}
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Container>
                        <Modal
                            onOpened={function () {
                                toast.dismiss();
                            }}
                            isOpen={showModal}
                            scrollable={false}
                            size="lg"
                            toggle={() => {
                                document.body.classList.add('no_padding');
                            }}
                            backdrop={'static'}
                            modalTransition={{ timeout: 0 }}
                            backdropTransition={{ timeout: 0 }}
                        >

                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">
                                    Profile View
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => {
                                        setShowModal(false);
                                    }}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <ProfileView regNo={regId} />
                            </div>
                        </Modal>
                    </React.Fragment>
                </div>
                :
                <div className='page-content' style={personalProfile?.data?.details?.profile_approval_status !== 'Approve' ? { pointerEvents: 'none' } : {}}>
                    {dataListLoading ? (
                        <div className="overlay">
                            <img src={loader} alt="Loading" height={100} width={300} />
                        </div>
                    ) : null}
                    <React.Fragment>
                        <BetterBreadcrumb title='Supervisor Dashboard' />
                        <Container fluid>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <Card className=''>
                                        <CardBody className='mx-4'>
                                            <h5>Trainee Status</h5>
                                            <CardGroup className='mt-3' tag="h3">
                                                <Card body color="info" className='text-center fw-bold rounded bg-opacity-50 me-5' style={{ width: '100px' }}>
                                                    <CardTitle>Total Trainee</CardTitle>
                                                    <CardText>{data?.trainee_status?.total_trainee}</CardText>
                                                </Card>
                                                <Card body color="success" className='text-center fw-bold rounded bg-opacity-50 me-5'>
                                                    <CardTitle>Current Trainee</CardTitle>
                                                    <CardText>{data?.trainee_status?.current_trainee}</CardText>
                                                </Card>
                                                <Card body color="danger" className='text-center fw-bold rounded bg-opacity-50 me-5'>
                                                    <CardTitle>Slot Approval Pending</CardTitle>
                                                    <CardText>{data?.trainee_status?.slot_approval_pending}</CardText>
                                                </Card>
                                                <Card body color="warning" className='text-center fw-bold rounded bg-opacity-50'>
                                                    <CardTitle>Logbook Approval Pending</CardTitle>
                                                    <CardText>{data?.trainee_status?.logbook_approval_pending}</CardText>
                                                </Card>
                                            </CardGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={9} md={9} sm={9} className='p-1'>
                                            <h5>Current Trainee List</h5>
                                            <Row>
                                                {data?.current_trainee_infos
                                                    ?.map((item: any) => (
                                                        <Col lg={4} md={6} sm={12} key={item.id} className='p-1'>
                                                            <Card>
                                                                <CardBody className='p-2'>
                                                                    <div className='row'>
                                                                        <div className='col-lg-4 col-md-4 col-sm-12'>
                                                                            <DisplayImage
                                                                                className="img-fluid img-rounded img-thumbnail img-width clickable"
                                                                                src={`${config?.APP_URL}${item?.registration_user_info?.personal_info?.picture}`}
                                                                                onClick={() => modalShowHandler(item?.registration_user_info?.registration_no)}
                                                                            />
                                                                            {/* <div className="img-fluid img-rounded text-center">
                                                                            <img
                                                                                src={
                                                                                    item?.registration_user_info?.personal_info?.picture
                                                                                        ? `${config?.APP_URL}${item?.registration_user_info?.personal_info?.picture}`
                                                                                        : defaultAvatar
                                                                                }
                                                                                className="img-thumbnail img-width"
                                                                            />
                                                                        </div> */}
                                                                        </div>
                                                                        <div className='col-lg-8 col-md-8 col-sm-12 p-0'>

                                                                            <h5 className="font-size-14 fw-bold mb-1">
                                                                                {item?.registration_user_info?.personal_info?.full_name ?? '--'}
                                                                            </h5>
                                                                            <p className="mb-1">
                                                                                {item?.registration_user_info?.personal_info?.email}
                                                                            </p>
                                                                            <p className="mb-1">
                                                                                @{item?.registration_user_info?.bmdc_registration_no}
                                                                            </p>
                                                                            <p className="mb-1">
                                                                                {item?.registration_user_info?.subject?.subject_name}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <Button
                                                                        color='light'
                                                                        className='w-100'
                                                                        onClick={() => window.open(`/trainee/training-summary-for-admin-and-supervisor/${item?.registration_user_info?.user_id}/${item?.registration_user_info?.current_session_and_supervisor_info?.id}`, '_blank')}
                                                                    >
                                                                        View Logbook Summary
                                                                    </Button>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    ))}
                                            </Row>
                                        </Col>
                                        <Col lg={3} md={3} sm={3} className='py-2'>
                                            <h5>Approval Pending List</h5>
                                            <Card>
                                                <CardHeader className='fw-bold bg-info bg-opacity-25'>
                                                    Pending Logbook Approval
                                                </CardHeader>
                                                <CardBody className='p-2'>
                                                    <div className="table-responsive">
                                                        <Table className="align-middle table-sm mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Event Name</th>
                                                                    <th>Pending Count</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data?.pending_approvals?.pending_logbook_approval?.map((item: any, index: number) => (
                                                                    <tr key={item.id}>
                                                                        <th scope="row">{++index}</th>
                                                                        <td>{item?.heading}</td>
                                                                        <td>{item?.total}</td>
                                                                        <td>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-light btn-sm"
                                                                                onClick={() => history.push('/supervisor/logbook')}
                                                                            >
                                                                                View
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardHeader className='fw-bold bg-info bg-opacity-25'>
                                                    Pending Slot Approval
                                                </CardHeader>
                                                <CardBody className='p-2'>
                                                    <div className="table-responsive">
                                                        <Table className="align-middle table-sm mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Event Name</th>
                                                                    <th>Pending Count</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data?.pending_approvals?.pending_slot_approval?.map((item: any, index: number) => (
                                                                    <tr key={item.id}>
                                                                        <th scope="row">{++index}</th>
                                                                        <td>{item?.session_name}</td>
                                                                        <td>{item?.total}</td>
                                                                        <td>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-light btn-sm"
                                                                                onClick={() => history.push('/supervisor/session-approval')}
                                                                            >
                                                                                View
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <h5>Guide Co-Guide Eligibility</h5>
                                            <Card>
                                                <CardBody>
                                                    <div>
                                                        {(userInfo?.current_employment_info?.designation === 'Professor' ||
                                                            userInfo?.current_employment_info?.designation === 'Assistant Professor' ||
                                                            userInfo?.current_employment_info?.designation === 'Associate Professor') && isFcps ?
                                                            <Alert color="success" style={{ width: '100%' }}><i className="fa-solid fa-check fa-2xl" style={{ color: "#215903" }} />&nbsp;You are eligible for applying as a Guide/Co-Guide</Alert> :
                                                            <Alert color="danger" style={{ width: '100%' }}><i className="fa-solid fa-xmark fa-2xl" style={{ color: "#f00505" }} />&nbsp;Eligibility not matched! Please Update Your Profile</Alert>
                                                        }
                                                    </div>
                                                    <h6 className="block-heading mt-3">Guide/Co-Guide Eligibility Criteria</h6>
                                                    <>
                                                        <div className='mb-1'>
                                                            {(userInfo?.current_employment_info?.designation === 'Professor' || userInfo?.current_employment_info?.designation === 'Associate Professor') && isFcps ?
                                                                <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                                                                : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                                                            }
                                                            &nbsp;Professor or Associate Professor of an institute with FCPS Degree
                                                        </div>
                                                        <div className='mb-1'>
                                                            <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                                                            {/* {userInfo?.current_employment_info?.designation === 'Assistant Professor' || userInfo?.current_employment_info?.in_charge ?
                                                                <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                                                                : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                                                            } */}
                                                            &nbsp;Assistant Professor with completion of Research Methodology TOT program
                                                        </div>
                                                        <div className='mb-1'>
                                                            {userInfo?.current_employment_info?.designation === 'Assistant Professor' && isFcps ?
                                                                <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                                                                : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                                                            }
                                                            &nbsp;Assistant Professor with FCPS Degree
                                                        </div>
                                                        <div className="mt-3 text-end">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-success"
                                                                disabled={(userInfo?.current_employment_info?.designation !== 'Professor' &&
                                                                    userInfo?.current_employment_info?.designation !== 'Assistant Professor' &&
                                                                    userInfo?.current_employment_info?.designation !== 'Associate Professor') ||
                                                                    userInfo?.current_employment_info === null || !isFcps
                                                                }
                                                                onClick={() => {
                                                                    history.push('/guide/list')
                                                                }}
                                                            >
                                                                Apply
                                                            </button>
                                                        </div>
                                                    </>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <h5 className="block-heading my-3">Training/Workshop for Supervisor</h5>
                                    <div className="table-responsive">
                                        <Table className="table table-sm table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Sl</th>
                                                    <th>Type</th>
                                                    <th>Workshop/Training</th>
                                                    <th>Duration</th>
                                                    <th>Fee</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data?.workshop_training_list?.map((item: any, index: number) => (
                                                    <tr key={item?.exam_training_id}>
                                                        <td>{++index}</td>
                                                        <td>{item?.training_workshop_info?.type}</td>
                                                        <td>{item?.training_workshop_info?.name}</td>
                                                        <td>{item?.training_workshop_info?.duration + ' ' + item?.training_workshop_info?.duration_unit}</td>
                                                        <td>{item?.training_workshop_info?.exam_training_fee?.exam_fee === '0' ? 'Free' : item?.training_workshop_info?.exam_training_fee?.exam_fee}</td>
                                                        <td>
                                                            {item?.payment_verification_status === 'Approved' ? <span className="badge bg-success me-1">Approved</span> : item?.payment_verification_status === 'Pending' ? <span className="badge bg-warning me-1">Pending</span> : null}
                                                            {item?.active_course ? <span className="badge bg-success">Applied</span> : <span className="badge bg-info">Not Applied</span>}
                                                        </td>
                                                        <td>
                                                            <button className="me-1 btn btn-sm btn-outline-primary" onClick={() => { trainingDescriptionModalShowHandler(true, item?.training_workshop_info?.description!, item?.training_workshop_info?.id!) }}>
                                                                Instruction
                                                            </button>

                                                            {item?.certificate_issued === 1 &&
                                                                <button className='btn btn-sm btn-warning me-1'
                                                                    onClick={(e) => {
                                                                        setCertificateId(item?.training_workshop_info?.certificate_ref_no!);
                                                                        setTrainingTitleForCertificate(item?.training_workshop_info?.name!);
                                                                        setTrainingDuration(item?.training_workshop_info?.duration!);
                                                                        setTrainingDurationUnit(item?.training_workshop_info?.duration_unit!);
                                                                    }}
                                                                >
                                                                    Certificate
                                                                </button>
                                                            }

                                                            <button
                                                                className="btn btn-sm btn-danger me-1"
                                                                onClick={() => {
                                                                    setNoticeId(item?.notice_ref_no!);
                                                                }}
                                                            >
                                                                Notice
                                                            </button>

                                                            {item?.workshop_skill_master_id &&
                                                                <Button
                                                                    type="button"
                                                                    color="info"
                                                                    className="me-1 btn-sm"
                                                                    onClick={() => { trainingModalShowHandler(true, item?.training_workshop_info?.id!) }}
                                                                >
                                                                    View
                                                                </Button>}
                                                            {item?.training_workshop_info.exam_training_fee?.exam_fee !== '0' ?
                                                                <Button
                                                                    type="button"
                                                                    color="success"
                                                                    className="me-1 btn-sm"
                                                                    onClick={() => modalWorkshopApplyHandler(true, item?.training_workshop_info)}
                                                                    disabled={item?.active_course}
                                                                >
                                                                    Apply
                                                                </Button>
                                                                :
                                                                <Button
                                                                    type="button"
                                                                    color="success"
                                                                    className="me-1 btn-sm"
                                                                    onClick={() => {
                                                                        const body = {
                                                                            exam_course_id: item?.training_workshop_info?.id,
                                                                        };
                                                                        createPaymentWitoutFeesApply(body);
                                                                    }}
                                                                    disabled={item?.active_course}
                                                                >
                                                                    Apply
                                                                </Button>
                                                            }
                                                        </td>
                                                        {descriptionModalShow && trainingId === item?.training_workshop_info.id ? (
                                                            <DescriptionModal
                                                                isOpen={descriptionModalShow}
                                                                toggle={() => setDescriptionnModalShow(!descriptionModalShow)}
                                                                description={description}
                                                            />
                                                        ) : null}
                                                        {trainingViewModalShow && trainingId === item?.training_workshop_info.id ? (
                                                            <TrainingViewDeatailsModal
                                                                isOpen={trainingViewModalShow}
                                                                toggle={() => setTrainingViewModalShow(!trainingViewModalShow)}
                                                                trainingId={item?.workshop_skill_master_id!}
                                                                attachments={item?.attachment}
                                                            />
                                                        ) : null}
                                                        {noticeId === item?.notice_ref_no ? (
                                                            <UpdatedViewNoticePdf data={row} noticeId={noticeId} setNoticeId={setNoticeId} />
                                                        ) : null}
                                                    </tr>
                                                ))}
                                            </tbody>
                                            {workShopListModalShow ? (
                                                <WorkshopListModal
                                                    isOpen={workShopListModalShow}
                                                    toggle={() => setWorkShopListModalShow(!workShopListModalShow)}
                                                    workshopName={selectedWorkshop.name!}
                                                    type={selectedWorkshop.type!}
                                                    fees={selectedWorkshop.exam_training_fee?.exam_fee!}
                                                    id={selectedWorkshop.id!}
                                                />
                                            ) : null}
                                            {certificateId === certificate?.id && certificateInfoObj?.name && trainingTitleForCertificate ? (
                                                <ViewCertificatePdf
                                                    data={certificate}
                                                    certificateId={certificateId}
                                                    setCertificateId={setCertificateId}
                                                    certificateInfoObj={certificateInfoObj}
                                                    trainingTitleForCertificate={trainingTitleForCertificate}
                                                    trainingDuration={trainingDuration}
                                                    trainingDurationUnit={trainingDurationUnit}
                                                />
                                            ) : null}
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Container>
                        <Modal
                            onOpened={function () {
                                toast.dismiss();
                            }}
                            isOpen={showModal}
                            scrollable={false}
                            size="lg"
                            toggle={() => {
                                document.body.classList.add('no_padding');
                            }}
                            backdrop={'static'}
                            modalTransition={{ timeout: 0 }}
                            backdropTransition={{ timeout: 0 }}
                        >

                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">
                                    Profile View
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => {
                                        setShowModal(false);
                                    }}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <ProfileView regNo={regId} />
                            </div>
                        </Modal>
                    </React.Fragment>
                </div>
            }
        </>
    );
};

export default SupervisorDashboard;
