import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import { useDeleteDeviceRegInfoMutation, useGetDeviceRegInfoApprovalMutation } from 'src/rtk/features/device-registration/deviceRegistrationApi';
import ProfileViewModal from '../Trainee/ProfileViewModal';

interface Props {
  deviceRegInfo: DeviceInfo;
  editHandler: any;
  index: number;
}

const DeviceRegTableRowForAdmin = ({
  deviceRegInfo,
  editHandler,
  index,
}: Props) => {
  const history = useHistory();

  const [infoId, setInfoId] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [profileViewId, setProfileViewId] = useState('');
  const [profilemodalShow, setprofileModalShow] = useState(false);

  const [deleteDeviceRegInfo, { isSuccess, isLoading, isError }] =
    useDeleteDeviceRegInfoMutation();

  const [getDeviceApproval, { isSuccess: isDeviceApprovalSuccess, isLoading: isDeviceApprovalLoading, isError: isDeviceApprovalError }] =
    useGetDeviceRegInfoApprovalMutation();

  const deleteHandler = (modalState: boolean, id: string) => {
    setModalShow(modalState);
    setInfoId(id);
  };

  const activeHandler = (id: string, active: string) => {
    getDeviceApproval({ reg_no: id, status: active });
  };

  const modalShowHandler = (modalState: boolean, id: string) => {
    setprofileModalShow(modalState);
    setProfileViewId(id);
  }
  return (
    <tr key={deviceRegInfo.id}>
      <th scope="row">{index + 1}</th>
      <td>{deviceRegInfo?.registration?.personal_info?.full_name ?? '-'}</td>
      <td>{deviceRegInfo?.registration?.bmdc_registration_no ?? '-'}</td>
      <td>{deviceRegInfo.device_type}</td>
      <td>{deviceRegInfo.manufacturer_name}</td>
      <td>{deviceRegInfo.mac_address}</td>
      <td>{deviceRegInfo.approval_status}</td>
      <td>
        <div className="d-flex gap-2">
          <Button
            size="sm"
            text="View Profile"
            color="info"
            onClick={() => modalShowHandler(true, deviceRegInfo?.id!)}
          />

          <Button
            size="sm"
            text="Active"
            color="success"
            disabled={deviceRegInfo.approval_status === 'Active'}
            onClick={() => {
              activeHandler(deviceRegInfo.id!, 'Active');
            }}
          />

          <Button
            size="sm"
            text="Inactive"
            color="danger"
            disabled={deviceRegInfo.approval_status === 'Pending' || deviceRegInfo.approval_status === 'Inactive'}
            onClick={() => {
              activeHandler(deviceRegInfo.id!, 'Inactive');
            }}
          />

          <Button
            size="sm"
            text="Delete"
            color="danger"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => deleteHandler(true, deviceRegInfo.id!)}
          />
        </div>
      </td>
      {modalShow && infoId === deviceRegInfo?.id ? (
        <DeleteInformationModal
          isOpen={modalShow}
          toggle={() => setModalShow(!modalShow)}
          id={infoId}
          deleteInfomation={deleteDeviceRegInfo}
        />
      ) : null}

      {profilemodalShow && profileViewId === deviceRegInfo?.id ? (
        <ProfileViewModal
          isOpen={profilemodalShow}
          toggle={() => setprofileModalShow(!profilemodalShow)}
          info={''}
          id={deviceRegInfo.registration_no!}
        />
      ) : null}
    </tr>
  );
};

export default DeviceRegTableRowForAdmin;
