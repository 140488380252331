import { useEffect, useState } from 'react';
import { useGetInstituteRegistrationListQuery } from 'src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi';
import InstituteDeptRegistrationStepFive from './InstituteDeptRegistrationStepFive';
import InstituteDeptRegistrationStepFour from './InstituteDeptRegistrationStepFour';
import InstituteDeptRegistrationStepOne from './InstituteDeptRegistrationStepOne';
import InstituteDeptRegistrationStepSeven from './InstituteDeptRegistrationStepSeven';
import InstituteDeptRegistrationStepThree from './InstituteDeptRegistrationStepThree';
import InstituteDeptRegistrationStepTwo from './InstituteDeptRegistrationStepTwo';
import InstituteDeptRegistrationStepSix from './InstituteDeptRegistrationStepSix';

const InstituteDeptRegistrationFormOne = () => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [instituteName, setInstituteName] = useState<string>('');
    const [instituteLocation, setInstituteLocation] = useState<string>('');
    const [deptId, setDeptId] = useState<string>('');
    const [formBDeptId, setFormBDeptId] = useState<string>('');

    const { data: instituteRegData, isSuccess: isInstituteRegDataSuccess, isError: isInstituteRegDataError, error: instituteRegDataError } = useGetInstituteRegistrationListQuery();

    const goToNext = (departmentId?: string, formBDeptId?: string) => {
        if (currentStep < 7) {
            setCurrentStep((p: number) => p + 1);
        } else {
            setCurrentStep(1);
        }

        // console.log(departmentId);
        // console.log(formBDeptId);

        if (departmentId) {
            // console.log(departmentId);            
            setDeptId(departmentId);
        }

        if (formBDeptId) {
            // console.log(formBDeptId);
            setFormBDeptId(formBDeptId);
        }
    };

    const goToPrevious = (departmentId?: string, formBDeptId?: string) => {
        if (currentStep > 1) {
            setCurrentStep((p: number) => p - 1);
        } else {
            setCurrentStep(7);
        }
    };

    document.title = 'Training Accreditation Application Form | BCPS';

    useEffect(() => {
        if (isInstituteRegDataSuccess) {
            setInstituteName(instituteRegData?.data?.institute_name!);
            setInstituteLocation(instituteRegData?.data?.institute_location!);
        }
    }, [isInstituteRegDataSuccess]);

    return (
        <>
            {currentStep === 1 && <InstituteDeptRegistrationStepOne goToNext={goToNext} instituteName={instituteName} instituteLocation={instituteLocation} departmentId={deptId} formBDeptId={formBDeptId} />}
            {currentStep === 2 && <InstituteDeptRegistrationStepTwo goToNext={goToNext} goToPrevious={goToPrevious} instituteName={instituteName} departmentId={deptId} formBDeptId={formBDeptId} />}
            {currentStep === 3 && <InstituteDeptRegistrationStepThree goToNext={goToNext} goToPrevious={goToPrevious} instituteName={instituteName} departmentId={deptId} formBDeptId={formBDeptId} />}
            {currentStep === 4 && <InstituteDeptRegistrationStepFour goToNext={goToNext} goToPrevious={goToPrevious} instituteName={instituteName} departmentId={deptId} formBDeptId={formBDeptId} />}
            {currentStep === 5 && <InstituteDeptRegistrationStepFive goToNext={goToNext} goToPrevious={goToPrevious} instituteName={instituteName} departmentId={deptId} formBDeptId={formBDeptId} />}
            {currentStep === 6 && <InstituteDeptRegistrationStepSix goToNext={goToNext} goToPrevious={goToPrevious} instituteName={instituteName} departmentId={deptId} formBDeptId={formBDeptId} />}
            {currentStep === 7 && <InstituteDeptRegistrationStepSeven goToPrevious={goToPrevious} instituteName={instituteName} departmentId={deptId} formBDeptId={formBDeptId} />}
        </>
    );
};

export default InstituteDeptRegistrationFormOne;
