import { useFormik } from "formik";
import React from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import PaginationComponent from "src/components/Pagination/Pagination";

const SubjectList = () => {
  const subjectForm = useFormik({
    initialValues: {
      institute: "",
      fromDate: Date.now().toString(),
      toDate: Date.now().toString(),
      searchTitle: "",
    },

    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <h6 className="mb-3">Subject List</h6>

        <Form>
          <Row>
            <FormGroup className="col-sm-6 col-md-2">
              <div className="mb-3">
                <Label className="form-label">Subject</Label>
                <Input
                  name="institute"
                  className="form-control"
                  placeholder="Select"
                  type="select"
                  onChange={subjectForm.handleChange}
                  onBlur={subjectForm.handleBlur}
                  value={subjectForm.values.institute || ""}
                  invalid={
                    subjectForm.touched.institute &&
                    !!subjectForm.errors.institute
                  }>
                  <option value="0">Select</option>
                </Input>
                {subjectForm.touched.institute &&
                subjectForm.errors.institute ? (
                  <FormFeedback type="invalid">
                    {subjectForm.errors.institute}
                  </FormFeedback>
                ) : null}
              </div>
            </FormGroup>

            <FormGroup className="col-sm-6 col-md-2">
              <div className="mb-3">
                <Label className="form-label">Accredition</Label>
                <Input
                  name="institute"
                  className="form-control"
                  placeholder="Select"
                  type="select"
                  onChange={subjectForm.handleChange}
                  onBlur={subjectForm.handleBlur}
                  value={subjectForm.values.institute || ""}
                  invalid={
                    subjectForm.touched.institute &&
                    !!subjectForm.errors.institute
                  }>
                  <option value="0">New, Re-Accredition</option>
                </Input>
                {subjectForm.touched.institute &&
                subjectForm.errors.institute ? (
                  <FormFeedback type="invalid">
                    {subjectForm.errors.institute}
                  </FormFeedback>
                ) : null}
              </div>
            </FormGroup>

            <FormGroup className="col-sm-4 col-md-2">
              <div className="mb-3">
                <Label className="form-label">From</Label>
                <Input
                  name="fromDate"
                  className="form-control"
                  placeholder=""
                  type="date"
                  onChange={subjectForm.handleChange}
                  onBlur={subjectForm.handleBlur}
                  value={subjectForm.values.fromDate}
                />
              </div>
            </FormGroup>

            <FormGroup className="col-sm-4 col-md-2">
              <div className="mb-3">
                <Label className="form-label">To</Label>
                <Input
                  name="toDate"
                  className="form-control"
                  placeholder=""
                  type="date"
                  onChange={subjectForm.handleChange}
                  onBlur={subjectForm.handleBlur}
                  value={subjectForm.values.toDate}
                />
              </div>
            </FormGroup>

            <FormGroup className="col-sm-6 col-md-4">
              <div className="mb-3">
                <Label className="form-label">Search</Label>
                <Input
                  name="searchTitle"
                  className="form-control"
                  placeholder="Search keyword"
                  type="search"
                  onChange={subjectForm.handleChange}
                  onBlur={subjectForm.handleBlur}
                  value={subjectForm.values.searchTitle}
                />
              </div>
            </FormGroup>
          </Row>
        </Form>

        <Row>
          <Col sm={12} md={12} lg={12}>
            <Table bordered>
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Date</th>
                  <th>Subject Name</th>
                  <th>Accredition (New/Re-Accredition) </th>
                  <th>Period</th>
                  <th>Already Accredited Subjects</th>
                  <th>Payment Status</th>
                  <th>Decision</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>12/12/22</td>
                  <td>Subject 1</td>
                  <td>New Accredition</td>
                  <td>2 Years</td>
                  <td>
                    <li>Subject 2</li>
                    <li>Subject 3</li>
                  </td>
                  <td>
                    <Button className="me-4">
                      <i className="fa-solid fa-eye"></i>
                    </Button>
                  </td>
                  <td>Submit</td>
                  <td>Draft</td>
                  <td>
                    <ul className="list-unstyled">
                      <li>
                        <a href="#">Approve</a>
                      </li>
                      <li>
                        <a href="#">Reject</a>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>12/12/22</td>
                  <td>Subject 1</td>
                  <td>New Accredition</td>
                  <td>2 Years</td>
                  <td>
                    <li>Subject 2</li>
                    <li>Subject 3</li>
                  </td>
                  <td>
                    <Button className="me-4">
                      <i className="fa-solid fa-eye"></i>
                    </Button>
                  </td>
                  <td>Submit</td>
                  <td>Draft</td>
                  <td>
                    <ul className="list-unstyled">
                      <li>
                        <a href="#">Approve</a>
                      </li>
                      <li>
                        <a href="#">Reject</a>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>/12/22</td>
                  <td>Subject 1</td>
                  <td>New Accredition</td>
                  <td>1 Years</td>
                  <td>
                    <li>Subject 2</li>
                    <li>Subject 3</li>
                  </td>
                  <td>
                    <Button className="me-4">
                      <i className="fa-solid fa-eye"></i>
                    </Button>
                  </td>
                  <td>Submit</td>
                  <td>Approved</td>
                  <td>
                    <ul className="list-unstyled">
                      <li>
                        <a href="#">Approve</a>
                      </li>
                      <li>
                        <a href="#">Reject</a>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>19/12/22</td>
                  <td>Subject 3</td>
                  <td>Re-Accredition</td>
                  <td>3 Years</td>
                  <td>
                    <li>Subject 2</li>
                    <li>Subject 3</li>
                  </td>
                  <td>
                    <Button className="me-4">
                      <i className="fa-solid fa-eye"></i>
                    </Button>
                  </td>
                  <td>Submit</td>
                  <td>Inspected</td>
                  <td>
                    <ul className="list-unstyled">
                      <li>
                        <a href="#">Approve</a>
                      </li>
                      <li>
                        <a href="#">Reject</a>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

        <PaginationComponent />
      </div>
    </React.Fragment>
  );
};

export default SubjectList;
