import moment from 'moment';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Modal, Table } from 'reactstrap';
interface Props {
    formEntryList: any;
}

const MiniCexFormAInformation = (props: Props) => {
    const { formEntryList } = props;

    // console.log(formEntryList);
    const [dataInfo, setDataInfo] = useState<any>('');
    const [modalShow, setModalShow] = useState(false);


    const modalShowHandler = (pageState: boolean, infos: any) => {
        setModalShow(pageState);
        setDataInfo(infos);
    }

    // console.log(dataInfo);
    return (
        <div>
            <Table className='table-bordered mt-2'>
                <thead>
                    <tr>
                        <th>Slot List</th>
                        <th>Entry Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {formEntryList?.mini_cexa?.map((item: any, index: number) => (
                        <tr key={item.id}>
                            <td>{++index}</td>
                            <td>{moment(item?.created_at).format("DD-MMM-YYYY")}</td>
                            <td>
                                <button
                                    className="btn btn-sm btn-info"
                                    onClick={() => {
                                        modalShowHandler(true, item);
                                    }}
                                >
                                    <span className="fas fa-eye"></span> View
                                </button>
                            </td>
                        </tr>
                    ))
                    }
                </tbody>
            </Table>

            <Modal
                onOpened={function () {
                    toast.dismiss();
                }}
                isOpen={modalShow}
                toggle={() => {
                    document.body.classList.add('no_padding');
                }}
                backdrop={'static'}
                modalTransition={{ timeout: 0 }}
                backdropTransition={{ timeout: 0 }}
                size="xl"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        Mini-CEX Form A Informantion
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => {
                            setModalShow(!modalShow);
                        }}
                    ></button>
                </div>
                <div className="modal-body">

                    <Table className='table-bordered mt-2'>
                        <thead>
                            <tr>
                                <th>Competence</th>
                                <th>Descriptor</th>
                                <th>Perform</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataInfo?.details?.map((item: any) => (
                                    <tr key={item.id}>
                                        <td>
                                            {item?.competence}
                                        </td>
                                        <td>
                                            {item?.descriptor}
                                        </td>
                                        <td>
                                            {item?.perform}
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </Table>

                </div>
            </Modal>
        </div>
    );
};

export default MiniCexFormAInformation;
