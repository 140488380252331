import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { putUrlString } from 'src/helpers';
import { useGetLogbookEntrySetupMasterQuery, useGetLogbookEntrySetupMastersQuery, useUpdateLogbookEntrySetupMasterMutation } from 'src/rtk/features/setup/logBookEntrySetupMaster/logBookEntrySetupMasterApi';
import { useGetLogBookMasterSetupsQuery } from 'src/rtk/features/setup/logBookMasterSetup/logBookMasterSetupApi';
import * as Yup from 'yup';

const initialValues: LogbookEntrySetupMaster = {
    id: '',
    setup_logbook_master_id: '',
    logbook_entry_setup_master_id: '',
    particulars: '',
    details: '',
    note: '',
    help: '',
    is_show_ind: 1,
    sl_no: 0,
    particulary_type: 0,
    status: true,
    creator_organization_id: '',
    updater_organization_id: '',
    created_by: '',
    updated_by: '',
    deleted_by: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
};

export const EditLogbookEntrySetupMaster = () => {
  const { id } = useParams<{ id: string }>();
  const { data: logbookEntrySetupMaster, isError: isLogbookEntrySetupMasterFetchError } = useGetLogbookEntrySetupMasterQuery(id);
  const [updateLogbookEntrySetupMaster, { isSuccess, isError, error }] = useUpdateLogbookEntrySetupMasterMutation();
  const [showStatus, setShowStatus] = useState<boolean>(true);

  const { data: logbookMastersData } = useGetLogBookMasterSetupsQuery();
  const logbookMasters = logbookMastersData?.data || [];

  const { data: logbookEntrySetupMastersData } = useGetLogbookEntrySetupMastersQuery();
  const logbookEntrySetupMasters = logbookEntrySetupMastersData?.data || [];

  const err: CustomError = error as CustomError;
  const history = useHistory();
  const {
    handleSubmit,
    handleChange,
    getFieldProps,
    values,
    touched,
    errors,
    setValues,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
        setup_logbook_master_id: Yup.string().required('Please select the logbook master'), 
    }),

    onSubmit: (values) => {
        if(showStatus) {
            values.is_show_ind = 1;
        } else {
            values.is_show_ind = 0;
        }

        const url = putUrlString(id, values);
        updateLogbookEntrySetupMaster(url);
    },
  });

  useEffect(() => {
    if (logbookEntrySetupMaster && !isLogbookEntrySetupMasterFetchError) {
      const data: LogbookEntrySetupMaster = (logbookEntrySetupMaster as any).data;
      setValues({
        id: data.id,
        setup_logbook_master_id: data.setup_logbook_master_id,
        logbook_entry_setup_master_id: data.logbook_entry_setup_master_id,
        particulars: data.particulars,
        details: data.details,
        note: data.note,
        help: data.help,
        is_show_ind: data.is_show_ind,
        sl_no: data.sl_no,
        particulary_type: data.particulary_type,
        status: data.status,
        creator_organization_id: data.creator_organization_id,
        updater_organization_id: data.updater_organization_id,
        created_by: data.created_by,
        updated_by: data.updated_by,
        deleted_by: data.deleted_by,
        created_at: data.created_at,
        updated_at: data.updated_at,
        deleted_at: data.deleted_at,
      });

      if(data.is_show_ind === 1) {
        setShowStatus(true);
      } else {
        setShowStatus(false);
      }
    }
  }, [logbookEntrySetupMaster, isLogbookEntrySetupMasterFetchError]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Logbook entry setup master updated successfully');
      history.push('/setup/logbook-entry-setup-master');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      const errors: any = {};

      if (err?.data) {
        Object.keys(err?.data).forEach((key) => {
          errors[key] = err?.data[key][0];
        });
      }
      setErrors(errors);
    }
  }, [isSuccess, isError]);

  const logbookMasterOptions: SelectOption[] = logbookMasters?.map((l: LogBookSetupMasters) => ({
    label: l.logbook_heading!,
    value: l.id!,
  }))!;  

  let logbookEntrySetupMasterOptions: SelectOption[] = [    
    ...logbookEntrySetupMasters?.map((l: LogbookEntrySetupMaster) => ({
      label: l.particulars!,
      value: l.id!,
    })),
  ];

  const handleShowStatus = (e: any) => {
    setShowStatus(e.target.checked);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Edit Logbook Entry Setup Master'}
          breadcrumbItem={[{ link: '/setup/logbook-entry-setup-master/', name: 'Logbook entry setup master list' }]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="card mt-3">
            <h5 className="card-header">
              <i className="fa fa-edit me-2" />
              Edit Logbook Entry Setup Master
            </h5>
            <div className="card-body">
              <Form
                className="custom-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="row">                  
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="setup_logbook_master_id">Setup Logbook Master</Label>
                      <InputField
                        type="select"
                        placeholder="Logbook Master Heading"
                        options={logbookMasterOptions}
                        invalid={{ errors, touched }}
                        {...getFieldProps('setup_logbook_master_id')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="logbook_entry_setup_master_id">Logbook Entry Setup Master</Label>
                      <InputField
                        type="select"
                        placeholder="Logbook Entry Setup Master"
                        options={logbookEntrySetupMasterOptions}
                        invalid={{ errors, touched }}
                        {...getFieldProps('logbook_entry_setup_master_id')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="particulars">Particulars</Label>
                      <InputField
                        type="textarea"
                        placeholder="Write here..."
                        invalid={{ errors, touched }}
                        {...getFieldProps('particulars')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="details">Details</Label>
                      <InputField
                        type="textarea"
                        placeholder="Details"
                        invalid={{ errors, touched }}
                        {...getFieldProps('details')}
                      />
                    </FormGroup>
                  </div> 

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="note">Note</Label>
                      <InputField
                        type="text"
                        placeholder="Note"
                        invalid={{ errors, touched }}
                        {...getFieldProps('note')}
                      />
                    </FormGroup>
                  </div> 

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="help">Help</Label>
                      <InputField
                        type="text"
                        placeholder="Help"
                        invalid={{ errors, touched }}
                        {...getFieldProps('help')}
                      />
                    </FormGroup>
                  </div>                                    

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="sl_no">Sl No</Label>
                      <InputField
                        type="number"
                        placeholder="Sl No"
                        invalid={{ errors, touched }}
                        {...getFieldProps('sl_no')}
                      />
                    </FormGroup>
                  </div>  

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="particulary_type">Particulary Type</Label>
                      <InputField
                        type="number"
                        placeholder="Particulary Type"
                        invalid={{ errors, touched }}
                        {...getFieldProps('particulary_type')}
                      />
                    </FormGroup>
                  </div>    

                  <div className="col-sm-12">
                    <FormGroup check className="mb-3 mt-4">
                        <Label check>
                            <Input
                                name='is_show_ind'
                                id='is_show_ind' 
                                type="checkbox" 
                                onChange={(e) => {
                                    handleShowStatus(e);
                                    handleChange(e);
                                }}
                                checked={showStatus} 
                                />{' '}
                            Show
                        </Label>
                    </FormGroup>
                  </div>            

                  <div className="col-sm-12">
                    <FormGroup check className="mb-3 mt-4">
                      <Label check>
                        <Input type="checkbox" checked={values.status} {...getFieldProps('status')} />{' '}
                        Status
                      </Label>
                    </FormGroup>
                  </div>
                </div>
                <Button type="submit" text="Submit" />
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
