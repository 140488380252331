import { useState } from "react";
import { Button as BsButton, Col, Row } from "reactstrap";
import { InputField } from "src/components";
import Button from "src/components/Button";
import { config } from "src/config";
import { CommentSectionModal } from "../CommentSectionForProtocol/CommentSectionModal";

const ResearcherSignature = (props: any) => {

    const { formik, personalProfile, existingProtocol } = props;

    const [showCommentSectionModal, setShowCommentSectionModal] = useState<boolean>(false);

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;
    const [agreed, setAgreed] = useState(false);
    const [hideAgreeButton, setHideAgreeButton] = useState(false);
    const [signature, setSignature] = useState('');
    const [date, setDate] = useState('');

    const handleAgreeButton = () => {
        setAgreed(true);
        setHideAgreeButton(true);
        personalProfile?.data?.details?.personal_info?.signature &&
            setSignature(
                `${config?.APP_URL}${personalProfile?.data?.details?.personal_info?.signature}`,
            );
        setDate(formik.values.agree_date_researcher);

    };

    return (
        <Row>
            <Col lg={12}>
                <Row className="justify-content-end">
                    <Col sm={1} style={{ pointerEvents: 'auto' }}>
                        <BsButton
                            type="button"
                            className="btn btn-primary btn-sm mb-3"
                            onClick={(e) => {
                                setShowCommentSectionModal(!showCommentSectionModal);
                            }}
                        >
                            <i className="fas fa-2x fa-comments"></i>
                        </BsButton>
                    </Col>
                </Row>
            </Col>
            <Col lg={12}>
                <Col lg={12}>
                    <p className="signature-font-p">
                        <b>Declaration:</b> I solemnly pledge that this research protocol shall be implemented in accordance with the relevant ordinance circulars of BCPS and funding agencies as when it is applicable. I hereby declare that no part of the proposal research has been used in any thesis dissertation in partial fulfillment of any degree fellowship or any publication.
                    </p>
                </Col>
                <Col lg={12}>
                    <div className="d-flex">
                        <p className="signature-font-p">
                            I also understand that the BCPS reserves the right of accepting or rejecting this protocol.
                        </p>
                        {!hideAgreeButton ? (<Button
                            type="button"
                            text="I AGREE"
                            disabled={hideAgreeButton}
                            className="btn btn-success agree-button"
                            onClick={handleAgreeButton}
                        ></Button>) : null}

                    </div>

                </Col>

                {agreed ? (
                    <Col lg={12} className="d-flex justify-content-center">

                        <div className="signature-date">
                            <InputField
                                type="date"
                                placeholder=""
                                name="date"
                                value={new Date()}
                                invalid={{ errors, touched }}
                                {...getFieldProps('agree_date_researcher')}
                            />
                            <p>Date: {formik.values.agree_date_researcher}</p>
                        </div>
                        <div className="signature">
                            <img style={{ width: '100px' }} src={signature} alt="signaure" />
                            <p>Signature of the Researcher</p>
                        </div>

                    </Col>
                ) : null}

            </Col>

            {showCommentSectionModal ?
                <CommentSectionModal
                    fieldName={'signature_of_the_researcher'}
                    fieldLabel={'Signature of the Researcher'}
                    existingProtocol={existingProtocol}
                    showModal={showCommentSectionModal}
                    setShowModal={setShowCommentSectionModal}
                />
                :
                null
            }
        </Row>
    );
};

export default ResearcherSignature;