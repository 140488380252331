import * as url from 'src/helpers/url_helper';
import * as Yup from 'yup';

const formObj: any = {
  debug: true,

  listAPIUrl: url.INSTITUTE_LIST_ADMIN,
  approvalAPIUrl: url.INSTITUTE_LIST_APPROVE_BY_ADMIN,

  listUrl: '/demo/',
  addUrl: '/demo/add',
  updateUrl: '/demo/update/',

  showSearchForm: true,
  showCreateNewBtn: false,
  showUpdateBtn: false,
  showViewBtn: true,
  showDeleteBtn: false,
  showTableActionColumn: true,

  createNewBtnTitle: 'Create New',
  submitBtnTitle: 'Save',
  updateBtnTitle: 'Update',
  resetBtnTitle: 'Reset',
  draftBtnTitle: '',
  viewBtnTitle: 'View',
  deletBtnTitle: 'Delete',
  deletePromtMessage: 'Do you want to delete?',

  title: 'Institute List For Approval',
  breadcrumbItemForList: [{ link: '/', name: 'Dashboard' }],
  breadcrumbItemForForm: [{ link: '/', name: 'Dashboard' }],

  formFields: [],

  searchFormFields: {
    institute_type: {
      positionInForm: 1,
      label: 'Institute type',
      name: 'institute_type',
      viewCell: (row: any) => row?.institute_type || '',
      cell: (row: any) => row?.institute_type || '',
      type: 'select',
      default: '',
      // isMulti: false,
      isRequired: true,
      validation: Yup.string()
        .required('Institute type is required')
        .oneOf(['Government', 'Non-Government']),
      showInForm: true,

      showInSearch: true,
      colSize: 6,
      data: [
        { label: 'Government', value: 'Government' },
        { label: 'Non-Government', value: 'Non-Government' },
      ],
    },

    institute_name: {
      positionInForm: 2,
      label: 'Institute name',
      name: 'institute_name',
      viewCell: (row: any) => row?.institute_name || '',
      cell: (row: any) => row?.institute_name || '',
      type: 'text',
      default: '',
      isRequired: false,
      showInSearch: true,
      colSize: 6,
    },

    recognition_of_bmdc: {
      label: 'Recognition of BMDC',
      viewCell: (row: any) => row.recognition_of_bmdc,
      cell: (row: any) => row.recognition_of_bmdc,
      type: 'select',
      default: '',
      // isMulti: false,
      isRequired: false,
      showInForm: true,
      showInSearch: true,
      colSize: 6,
      data: [
        { label: 'Recognized', value: 'Recognized' },
        { label: 'Not Recognized', value: 'Not Recognized' },
        { label: 'Not Applicable', value: 'Not Applicable' },
      ],
    },
    license_of_dghs: {
      label: 'License by DGHS',
      viewCell: (row: any) => row.license_of_dghs,
      cell: (row: any) => row.license_of_dghs,
      type: 'select',
      default: '',
      // isMulti: false,
      isRequired: false,
      showInForm: true,
      showInSearch: true,
      colSize: 6,
      data: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
        { label: 'Not Applicable', value: 'Not Applicable' },
      ],
    },


    license_no: {
      label: 'License Number',
      viewCell: (row: any) => row.license_no,
    },

    affiliation_with_university: {
      label: 'Affiliation with University',
      viewCell: (row: any) => row.affiliation_with_university,
      cell: (row: any) => row.affiliation_with_university,
      type: 'select',
      default: '',
      // isMulti: false,
      isRequired: false,
      showInForm: true,
      showInSearch: true,
      colSize: 6,
      data: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
        { label: 'Not Applicable', value: 'Not Applicable' },
      ],
    },
    affiliation_university_name: {
      label: 'Name of the University',
      viewCell: (row: any) => row.affiliation_university_name,
    },
    research_monitor_comittee: {
      label: 'Institutional Research Monitoring Committee',
      viewCell: (row: any) => row.research_monitor_comittee,
    },
    total_no_of_beds: {
      label: 'Total number of beds of the Institute',
      viewCell: (row: any) => row.total_no_of_beds,
      cell: (row: any) => row.total_no_of_beds,
    },
    total_no_of_paying_beds: {
      label: 'Number of paying beds',
      viewCell: (row: any) => row.total_no_of_paying_beds,
    },
    total_no_of_non_paying_beds: {
      label: 'Number of non-paying beds',
      viewCell: (row: any) => row.total_no_of_non_paying_beds,
    },
    total_no_of_cabins: {
      label: 'Number of cabins',
      viewCell: (row: any) => row.total_no_of_cabins,
    },
    availability_of_emergency_service: {
      label: 'Availability of Emergency Services',
      viewCell: (row: any) => row.availability_of_emergency_service,
      cell: (row: any) => row.availability_of_emergency_service,
    },
    availability_of_icu_service: {
      label: 'Availability of ICU Services',
      viewCell: (row: any) => row.availability_of_icu_service,
      cell: (row: any) => row.availability_of_icu_service,
    },
    icu_beds: {
      label: 'Number of ICU beds',
      viewCell: (row: any) => row.icu_beds,
    },
    number_of_ventilators: {
      label: 'Number of Ventilators',
      viewCell: (row: any) => row.number_of_ventilators,
    },
    no_of_icu_doctors: {
      label: 'Number of ICU Doctors',
      viewCell: (row: any) => row.no_of_icu_doctors,
    },
    number_of_icu_nurse_and_paramedics: {
      label: 'Number of ICU Nurse and Paramedics',
      viewCell: (row: any) => row.number_of_icu_nurse_and_paramedics,
    },
    availability_of_ccu_service: {
      label: 'Availability of CCU Services',
      viewCell: (row: any) => row.availability_of_ccu_service,
      cell: (row: any) => row.availability_of_ccu_service,
    },



    approval_status: {
      label: 'Approval status',
      viewCell: (row: any) => row?.approval_status || '',
      cell: (row: any) => row?.approval_status || '',
      type: 'select',
      default: '',
      // isMulti: false,
      isRequired: false,
      showInForm: true,
      showInSearch: true,
      colSize: 6,
      data: [
        { label: 'Approve', value: 'Approve' },
        { label: 'Reject', value: 'Reject' },
        { label: 'Correction', value: 'Correction' },
      ],
    },


  },
};

export default formObj;
