import * as miniCexAndCBDUrl from 'src/helpers/url_helper';
import { apiSlice } from 'src/rtk/api/apiSlice';

export const miniCexAndCBDApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['MiniCexCBD'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSessionWiseMiniCexList: builder.query<APIResponse<any>, any>({
        query: (url: any) => ({
          method: 'GET',
          url: `${miniCexAndCBDUrl.GET_SESSION_WISE_MINI_CEX_LIST}/${url.registrationNo}?supervisor_id=${url.supervisorId}&${url.urls}`,
        }),
        providesTags: ['MiniCexCBD'],
      }),


      createMiniCexFormAEntry: builder.mutation<APIResponse<MiniCEXFormA>, any>({
        query: (data: any) => ({
          url: `${miniCexAndCBDUrl.CREATE_MINI_CEX_FORM_ENTRY_BY_SUPERVISOR}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['MiniCexCBD'],
      }),

      createFormBEntry: builder.mutation<APIResponse<MiniCEXFormB>, any>({
        query: (data: any) => ({
          url: `${miniCexAndCBDUrl.CREATE_MINI_CEX_FORM_ENTRY_BY_SUPERVISOR}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['MiniCexCBD'],
      }),

      createMiniCexFormEntryBySupervisor: builder.mutation<APIResponse<any>, any>({
        query: (data: any) => ({
          url: `${miniCexAndCBDUrl.CREATE_MINI_CEX_FORM_ENTRY_BY_SUPERVISOR}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['MiniCexCBD'],
      }),

      createMiniCexEntryByTrainee: builder.mutation<APIResponse<any>, any>({
        query: (data: any) => ({
          url: `${miniCexAndCBDUrl.CREATE_MINI_CEX_ENTRY_BY_TRAINEE}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['MiniCexCBD'],
      }),

      createCBDFormAEntry: builder.mutation<APIResponse<CBDForm>, any>({
        query: (data: any) => ({
          url: `${miniCexAndCBDUrl.CREATE_CBD_FORM_A_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['MiniCexCBD'],
      }),

      createCBDFormBEntry: builder.mutation<APIResponse<CBDForm>, any>({
        query: (data: any) => ({
          url: `${miniCexAndCBDUrl.CREATE_CBD_FORM_B_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['MiniCexCBD'],
      }),

      createCBDEntryByTrainee: builder.mutation<APIResponse<any>, any>({
        query: (data: any) => ({
          url: `${miniCexAndCBDUrl.CREATE_CBD_ENTRY_BY_TRAINEE}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['MiniCexCBD'],
      }),

      createFeedBackForAssesment: builder.mutation<APIResponse<any>, any>({
        query: (data: any) => ({
          url: `${miniCexAndCBDUrl.CREATE_FEEDBACK_FOR_ASSESMENT}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['MiniCexCBD'],
      }),

      createCBDFormEntryBySupervisor: builder.mutation<APIResponse<any>, any>({
        query: (data: any) => ({
          url: `${miniCexAndCBDUrl.CREATE_CBD_FORM_ENTRY_BY_SUPERVISOR}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['MiniCexCBD'],
      }),

      createDOPSEntryByTrainee: builder.mutation<APIResponse<any>, any>({
        query: (data: any) => ({
          url: `${miniCexAndCBDUrl.CREATE_DOPS_ENTRY_BY_TRAINEE}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['MiniCexCBD'],
      }),

      createDOPSFormEntryBySupervisor: builder.mutation<APIResponse<any>, any>({
        query: (data: any) => ({
          url: `${miniCexAndCBDUrl.CREATE_DOPS_FORM_ENTRY_BY_SUPERVISOR}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['MiniCexCBD'],
      }),

      getTraineeAssessmentList: builder.query<APIResponse<any>, {type: string}>({
        query: (obj: {type: string}) => ({
          method: 'GET',
          url: `${miniCexAndCBDUrl.GET_TRAINEE_ASSESSMENT_LIST}?type=${obj.type}`,
        }),
        providesTags: ['MiniCexCBD'],
      }),
    }),
  });

export const {
  useLazyGetSessionWiseMiniCexListQuery,
  useGetSessionWiseMiniCexListQuery,
  useCreateMiniCexFormAEntryMutation,
  useCreateFormBEntryMutation,
  useCreateFeedBackForAssesmentMutation,
  useCreateMiniCexFormEntryBySupervisorMutation,
  useCreateMiniCexEntryByTraineeMutation,
  useCreateCBDFormAEntryMutation,
  useCreateCBDFormBEntryMutation,
  useCreateCBDEntryByTraineeMutation,
  useCreateCBDFormEntryBySupervisorMutation,
  useCreateDOPSEntryByTraineeMutation,
  useCreateDOPSFormEntryBySupervisorMutation,
  useGetTraineeAssessmentListQuery,
} = miniCexAndCBDApi;
