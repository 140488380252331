import moment from 'moment';
import { useEffect } from 'react';
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import { useLazyGetAssignedDepartmentForViewQuery } from 'src/rtk/features/instituteInspection/institutueInspectionApi';
import { useGetInstituteNameQuery, useLazyGetInstituteWiseDepartmentNameQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';

interface Props {
  isOpen: boolean;
  toggle: any;
  info: RecomendationList;
}


const ViewAssignedDepartmentModal = (props: Props) => {
  const { isOpen, toggle, info } = props;

  // console.log(info?.institute?.institute_name);
  const { data: instituteLookupData } = useGetInstituteNameQuery();
  const [getAssignedDepartmentForView, { data: list, isSuccess: isListSuccess }] = useLazyGetAssignedDepartmentForViewQuery();

  useEffect(() => {
    if (info?.institute_id !== '' && info?.team_creation_id !== '') {
      const url = {
        id: info?.team_creation_id,
        institute_id: info?.institute_id,
        inspection_date: info?.inspection_date
      };
      getAssignedDepartmentForView(url);
    }
  }, [info]);

  const instituteList: SelectOption[] = instituteLookupData?.map((item) => ({
    value: item.id!,
    label: item.institute_name!,
  })) || [];

  console.log(list);
  

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="lg">
      <ModalHeader toggle={toggle}>Assigned Department For {info?.institute?.institute_name}</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Label>Institute Name</Label>
            <Input
              type="select"
              name='institute_name'
              value={info?.institute?.institute_name}
              readOnly
              disabled
            >
              {instituteLookupData?.map((item) => (
                <option key={item.id} value={item.institute_name}>
                  {item.institute_name}
                </option>
              ))}
            </Input>
          </Col>
          <Col>
            <Label>Inspection Date</Label>
            <InputField
              type="text"
              name='inspection_date'
              value={moment(info?.inspection_date).format("DD-MMM-YYYY")}
              readOnly
              disabled
            />
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col>
            <table className="table table-sm table-striped table-bordered table-hover">
              <thead className="bg-light fixed">
                {list &&
                  <tr className="text-center text-dark font-size-16 fw-bold align-top">
                    <td>SL#</td>
                    <td>Institute Name</td>
                    <td>
                      Department Name
                    </td>
                  </tr>}
              </thead>

              <tbody>
                {list?.data[0]?.assigned_departments?.map((item: any, key: number) => {
                  return (
                    <tr key={item.id} className="">
                      <td className="text-center" style={{ width: '50px' }}>
                        <span
                          style={{ width: '20px', height: '20px' }}
                          className="avatar-title rounded-circle bg-soft-info text-dark font-size-12 m-2"
                        >
                          {key + 1}
                        </span>
                      </td>
                      <td style={{ width: '46%' }}>
                        <div className="font-size-16 fw-bold mt-1">
                          {info?.institute?.institute_name}
                        </div>
                      </td>
                      <td className='text-center'>
                        {item?.department?.department_name}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div className="text-center">
          <Button
            type="button"
            text="Close"
            className="btn btn-danger"
            onClick={() => toggle()}
          ></Button>
        </div>
      </ModalFooter>

    </Modal>
  );
};

export default ViewAssignedDepartmentModal;
