import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { setFromErrors } from 'src/helpers';
import { useGetDepartmentByIdQuery, useInstituteDepartmentRenewMutation, useRegisterDepartmentStepOneMutation } from 'src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi';
import { useGetMemberRecomendationCheckMutation, useLazyGetAssignedDepartmentForViewQuery } from 'src/rtk/features/instituteInspection/institutueInspectionApi';
import * as Yup from 'yup';
import InstituteAndDepartmentName from './InstituteAndDepartmentName';
import SubSpecialtyOfTheRelevantDepartment from './SubSpecialtyOfTheRelevantDepartment';

const initialValues: InstituteDeptRegistrationStepOne = {
    form_b_department_id: '',
    department_name: '',
    applied_previously: '',
    required_accreditation: '',
    not_accredited_due_to_short_comings: '',
    total_number_of_beds: 0,
    number_of_paying_beds: 0,
    number_of_nonpaying_beds: 0,
    number_of_cabins: 0,
    availability_of_sub_speciality: '',
    emergency_admission_facility: '',
    availability_of_icu_service: '',
    number_of_icu_beds: 0,
    number_of_icu_ventilators: 0,
    number_of_icu_doctors: 0,
    number_of_icu_nurse_paramedics: 0,
    sub_specialities: [],
};

const InstituteDeptRegistrationStepOne = (props: any) => {
    const {
        goToNext,
        instituteName,
        setInstituteName,
        instituteLocation,
        departmentId,
        setDepartmentId,
        formBDeptId,
        setFormBDeptId,
        isDisabled,
        renewDepartment,
        teamId,
        instituteId,
        inspectionDate
    } = props;

    const [subSpecialtyOfRelevantDepartmentList, setSubSpecialtyOfRelevantDepartmentList] = useState<any>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<any>('');
    const [isMemberFound, setIsMemberFound] = useState<boolean>(true);

    const [registerDepartmentStepOne, { data: departmentData, isSuccess: isDepartmentDataSuccess, isLoading: isDepartmentDataLoading, isError: isDepartmentDataError, error: departmentDataError }] =
        useRegisterDepartmentStepOneMutation();

    const [renewInstituteDepartment, { data: departmentRenewData, isSuccess: isDepartmentRenewDataSuccess, isLoading: isDepartmentRenewDataLoading, isError: isDepartmentRenewDataError, error: departmentRenewDataError }] =
        useInstituteDepartmentRenewMutation();

    const [getAssignedDepartmentForView, { data: list, isSuccess: isListSuccess }] = useLazyGetAssignedDepartmentForViewQuery();

    const [getMemberRecomendationCheck, { data: checkData, isSuccess: isMemberSuccess, isError: isMemberError, error: isMessageError, isLoading: isMemberLoading }] = useGetMemberRecomendationCheckMutation();

    const { data: submittedDepartmentData, isSuccess: isSubmittedDepartmentDataSuccess, isError: isSubmittedDepartmentDataError, error: submittedDepartmentDataError } = useGetDepartmentByIdQuery(formBDeptId);

    const err: CustomError = (renewDepartment === true ? departmentRenewDataError : departmentDataError) as CustomError;

    const err1: CustomError = isMessageError as CustomError;

    document.title = renewDepartment === true ? 'Renew Department | BCPS' : 'Department Registration | BCPS';

    useEffect(() => {
        if (instituteId !== '' && teamId !== '') {
            const url = {
                id: teamId,
                institute_id: instituteId,
                inspection_date: inspectionDate
            };
            getAssignedDepartmentForView(url);
        }
    }, [teamId, instituteId, inspectionDate]);

    const styles = {
        disableDiv: {
            pointerEvents: 'none',
            opacity: 0.8,
        }
    };

    const formik = useFormik({
        initialValues: initialValues,

        validationSchema: Yup.object({
            number_of_icu_beds: Yup.number().when('availability_of_icu_service', { is: 'Yes', then: Yup.number().min(1, 'Invalid number').required('Required field') }),
            number_of_icu_ventilators: Yup.number().when('availability_of_icu_service', { is: 'Yes', then: Yup.number().min(1, 'Invalid number').required('Required field') }),
            number_of_icu_doctors: Yup.number().when('availability_of_icu_service', { is: 'Yes', then: Yup.number().min(1, 'Invalid number').required('Required field') }),
            number_of_icu_nurse_paramedics: Yup.number().when('availability_of_icu_service', { is: 'Yes', then: Yup.number().min(1, 'Invalid number').required('Required field') }),
        }),

        onSubmit: (values: any) => {
            console.log(values);

            if (values.availability_of_sub_speciality === 'Yes' && subSpecialtyOfRelevantDepartmentList?.length === 0) {
                toast.error('Please provide sub-specialty department info', { icon: <i className='fas fa-circle-exclamation'></i>, duration: 5000 });
                return;
            }

            values.sub_specialities = [];

            const subSpecialitiesList = [...subSpecialtyOfRelevantDepartmentList];

            subSpecialitiesList?.forEach((speciality: any, index: number) => {
                values?.sub_specialities?.push({
                    name: speciality.nameOfSubSpecialty ?? '',
                    total_number_of_beds: +speciality.totalNumberOfBeds ?? 0,
                    number_of_paying_beds: +speciality.numberOfPayingBeds ?? 0,
                    number_of_nonpaying_beds: +speciality.numberOfNonPayingBeds ?? 0,
                    number_of_cabins: +speciality.numberOfCabins ?? 0,
                });
            });

            if (renewDepartment === true) {
                values.form_b_department_id = '';
                values.department_id = departmentId;
                renewInstituteDepartment(values);
            } else {
                registerDepartmentStepOne(values);
            }
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    const departmentOptions: SelectOption[] =
        list?.data[0]?.assigned_departments?.map((department: any) => ({
            value: department?.department?.id!,
            label: department?.department?.department_name!,
        })) || [];

    useEffect(() => {
        if (instituteId && formBDeptId && teamId) {
            console.log(list?.data?.length);
            getMemberRecomendationCheck(`team_creation_id=${teamId}&institute_id=${instituteId}&form_b_department_id=${formBDeptId}`)
        }        
    }, [instituteId, formBDeptId, teamId]);

    useEffect(() => {
        if (isMemberSuccess) {
            setIsMemberFound(true);            
        }
        if (isMemberError) {
            toast.error(err1?.message);
            setIsMemberFound(false);
        }
    }, [isMemberSuccess, isMemberError, checkData]);

    useEffect(() => {
        if (!departmentId) {
            if (renewDepartment) {
                setFieldValue('applied_previously', 'Yes');
                setFieldValue('required_accreditation', 'Yes');
                setFieldValue('not_accredited_due_to_short_comings', 'Yes');
            } else {
                setFieldValue('applied_previously', 'No');
                setFieldValue('required_accreditation', 'No');
                setFieldValue('not_accredited_due_to_short_comings', 'No');
            }

            setFieldValue('emergency_admission_facility', 'Yes');
            setFieldValue('availability_of_icu_service', 'Yes');
            setFieldValue('availability_of_sub_speciality', 'Yes');
        }

        console.log(departmentId);
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (values.availability_of_icu_service === 'No') {
            setFieldValue('number_of_icu_beds', 0);
            setFieldValue('number_of_icu_ventilators', 0);
            setFieldValue('number_of_icu_doctors', 0);
            setFieldValue('number_of_icu_nurse_paramedics', 0);
        }
    }, [values.availability_of_icu_service]);

    useEffect(() => {
        if (values.availability_of_sub_speciality === 'No') {
            setSubSpecialtyOfRelevantDepartmentList([]);
        }
    }, [values.availability_of_sub_speciality]);

    useEffect(() => {
        if (submittedDepartmentData?.data?.id && !isSubmittedDepartmentDataError) {
            const department = submittedDepartmentData?.data;

            if (!renewDepartment) {
                setValues({
                    form_b_department_id: department?.id,
                    department_name: department?.department_name,
                    applied_previously: department?.applied_previously,
                    required_accreditation: department?.required_accreditation,
                    not_accredited_due_to_short_comings: department?.not_accredited_due_to_short_comings,
                    total_number_of_beds: department?.total_number_of_beds,
                    number_of_paying_beds: department?.number_of_paying_beds,
                    number_of_nonpaying_beds: department?.number_of_nonpaying_beds,
                    number_of_cabins: department?.number_of_cabins,
                    availability_of_sub_speciality: department?.availability_of_sub_speciality,
                    emergency_admission_facility: department?.emergency_admission_facility,
                    availability_of_icu_service: department?.availability_of_icu_service,
                    number_of_icu_beds: department?.number_of_icu_beds,
                    number_of_icu_ventilators: department?.number_of_icu_ventilators,
                    number_of_icu_doctors: department?.number_of_icu_doctors,
                    number_of_icu_nurse_paramedics: department?.number_of_icu_nurse_paramedics,
                    sub_specialities: department?.sub_speciality_departments?.map((s: any) => {
                        return {
                            name: s.sub_speciality_department_name,
                            total_number_of_beds: s.total_number_of_beds,
                            number_of_paying_beds: s.number_of_paying_beds,
                            number_of_nonpaying_beds: s.number_of_nonpaying_beds,
                            number_of_cabins: s.number_of_cabins,
                        };
                    }),
                    submission_status: department?.submission_status,
                });
            } else {
                setValues({
                    form_b_department_id: department?.id,
                    department_name: department?.department_name,
                    applied_previously: 'Yes',
                    required_accreditation: 'Yes',
                    not_accredited_due_to_short_comings: 'Yes',
                    total_number_of_beds: department?.total_number_of_beds,
                    number_of_paying_beds: department?.number_of_paying_beds,
                    number_of_nonpaying_beds: department?.number_of_nonpaying_beds,
                    number_of_cabins: department?.number_of_cabins,
                    availability_of_sub_speciality: department?.availability_of_sub_speciality,
                    emergency_admission_facility: department?.emergency_admission_facility,
                    availability_of_icu_service: department?.availability_of_icu_service,
                    number_of_icu_beds: department?.number_of_icu_beds,
                    number_of_icu_ventilators: department?.number_of_icu_ventilators,
                    number_of_icu_doctors: department?.number_of_icu_doctors,
                    number_of_icu_nurse_paramedics: department?.number_of_icu_nurse_paramedics,
                    sub_specialities: department?.sub_speciality_departments?.map((s: any) => {
                        return {
                            name: s.sub_speciality_department_name,
                            total_number_of_beds: s.total_number_of_beds,
                            number_of_paying_beds: s.number_of_paying_beds,
                            number_of_nonpaying_beds: s.number_of_nonpaying_beds,
                            number_of_cabins: s.number_of_cabins,
                        };
                    }),
                    submission_status: department?.submission_status,
                });
            }

            setSelectedDepartment({ label: department?.department_name!, value: department?.department_name! });

            setSubSpecialtyOfRelevantDepartmentList(department?.sub_speciality_departments?.map((s: any) => {
                return {
                    nameOfSubSpecialty: s.sub_speciality_department_name,
                    totalNumberOfBeds: s.total_number_of_beds,
                    numberOfPayingBeds: s.number_of_paying_beds,
                    numberOfNonPayingBeds: s.number_of_nonpaying_beds,
                    numberOfCabins: s.number_of_cabins,
                };
            }));
        }
    }, [submittedDepartmentData, isSubmittedDepartmentDataError]);


    useEffect(() => {
        if (isDepartmentDataSuccess) {
            handleFormReset();
            toast.success('Department information registered successfully');
            goToNext(departmentData?.department_id, departmentData?.form_b_department_id);
        }
        if (isDepartmentDataError) {
            toast.error(err?.message || 'Something went wrong');
            setFromErrors(err.data, setErrors);
        }
    }, [isDepartmentDataSuccess, isDepartmentDataError]);

    useEffect(() => {
        if (isDepartmentRenewDataSuccess) {
            handleFormReset();
            toast.success('Department renew started successfully');
            goToNext(departmentData?.department_id, departmentData?.form_b_department_id);
        }
        if (isDepartmentRenewDataError) {
            toast.error(err?.message || 'Something went wrong');
            setFromErrors(err.data, setErrors);
        }
    }, [isDepartmentRenewDataSuccess, isDepartmentRenewDataError]);

    const handleFormReset = () => {
        resetForm();
    };

    useEffect(() => {
        if (selectedDepartment !== '') {
            const theSelectedDepartment: any = list?.data[0]?.assigned_departments?.find((dept: any, index: number) => dept?.department?.id === selectedDepartment?.value)!;
            console.log(theSelectedDepartment);
            setDepartmentId(theSelectedDepartment?.department?.department_id);
            setFormBDeptId(theSelectedDepartment?.department?.id);
            setFieldValue('department_name', selectedDepartment?.label);
        } else {
            console.log(selectedDepartment);
        }
    }, [selectedDepartment]);

    useEffect(() => {
        values.total_number_of_beds = +values.number_of_paying_beds! + +values.number_of_nonpaying_beds! + +values.number_of_cabins!;
    }, [values.number_of_paying_beds, values.number_of_nonpaying_beds, values.number_of_cabins]);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BetterBreadcrumb
                        title="Training Accreditation Application Form"
                        breadcrumbItem={[{ link: '/institute-registration-list', name: 'Department List' }]}
                    />
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0 flex-grow-1">
                                        <span>Part-B : Information about Relevant Department</span>
                                    </h5>
                                </CardHeader>
                                <CardBody>
                                    <Form
                                        autoComplete="off"
                                        className="custom-form"
                                        style={isDisabled === true ? { pointerEvents: 'none' } : {}}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            handleSubmit();
                                        }}
                                        onReset={(e) => {
                                            e.preventDefault();
                                            handleFormReset();
                                        }}
                                    >
                                        <Row>
                                            <InstituteAndDepartmentName
                                                instituteName={instituteName}
                                                setInstituteName={setInstituteName}
                                                instituteLocation={instituteLocation}
                                                instituteId={instituteId}
                                                departmentOptions={departmentOptions}
                                                selectedDepartment={selectedDepartment}
                                                setSelectedDepartment={setSelectedDepartment}
                                            />

                                            <Col sm={12}>
                                                <Row className='justify-content-evenly'>
                                                    <Col sm={12} md={6} className='mt-4'>
                                                        <FormGroup className="col-sm-12 mb-3">
                                                            <h5 className="font-size-14 mb-3">The department applied previously</h5>
                                                            <div className="d-inline-block mx-2">
                                                                <div className="form-check form-check-left mb-3">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="departmentAppliedPreviously"
                                                                        id="departmentAppliedPreviouslyYes"
                                                                        value={"Yes"}
                                                                        checked={values.applied_previously === 'Yes'}
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                setFieldValue('applied_previously', 'Yes');
                                                                            }
                                                                        }}
                                                                        defaultChecked
                                                                    />
                                                                    <label className="form-check-label" htmlFor="departmentAppliedPreviouslyYes">Yes</label>
                                                                </div>
                                                            </div>

                                                            <div className="d-inline-block mx-2">
                                                                <div className="form-check form-check-left">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="departmentAppliedPreviously"
                                                                        id="departmentAppliedPreviouslyNo"
                                                                        value={"No"}
                                                                        checked={values.applied_previously === 'No'}
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                setFieldValue('applied_previously', 'No');
                                                                            }
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="departmentAppliedPreviouslyNo">No</label>
                                                                </div>
                                                            </div>
                                                        </FormGroup>

                                                        <FormGroup className="col-sm-12 mb-3">
                                                            <div>
                                                                <h5 className="font-size-14 mb-3">The department was accredited previously but required re-accreditation</h5>
                                                                <div className="d-inline-block mx-2">
                                                                    <div className="form-check form-check-left mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="departmentAccreditedPreviously"
                                                                            id="departmentAccreditedPreviouslyYes"
                                                                            value={"Yes"}
                                                                            checked={values.required_accreditation === 'Yes'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('required_accreditation', 'Yes');
                                                                                }
                                                                            }}
                                                                            defaultChecked
                                                                        />
                                                                        <label className="form-check-label" htmlFor="departmentAccreditedPreviouslyYes">Yes</label>
                                                                    </div>
                                                                </div>

                                                                <div className="d-inline-block mx-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="departmentAccreditedPreviously"
                                                                            id="departmentAccreditedPreviouslyNo"
                                                                            value={"No"}
                                                                            checked={values.required_accreditation === 'No'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('required_accreditation', 'No');
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="departmentAccreditedPreviouslyNo">No</label>
                                                                    </div>
                                                                </div>

                                                                <div className="d-inline-block mx-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="departmentAccreditedPreviously"
                                                                            id="departmentAccreditedPreviouslyNotApplicable"
                                                                            value={"Not Applicable"}
                                                                            checked={values.required_accreditation === 'Not Applicable'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('required_accreditation', 'Not Applicable');
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="departmentAccreditedPreviouslyNotApplicable">Not Applicable</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </FormGroup>

                                                        <FormGroup className="col-sm-12 mb-3">
                                                            <div className="mb-3">
                                                                <h5 className="font-size-14 mb-3">The department was not considered for accreditation due to short comings</h5>
                                                                <div className="d-inline-block mx-2">
                                                                    <div className="form-check form-check-left mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="departmentShortComings"
                                                                            id="departmentShortComingsYes"
                                                                            value={"Yes"}
                                                                            checked={values.not_accredited_due_to_short_comings === 'Yes'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('not_accredited_due_to_short_comings', 'Yes');
                                                                                }
                                                                            }}
                                                                            defaultChecked
                                                                        />
                                                                        <label className="form-check-label" htmlFor="departmentShortComingsYes">Yes</label>
                                                                    </div>
                                                                </div>

                                                                <div className="d-inline-block mx-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="departmentShortComings"
                                                                            id="departmentShortComingsNo"
                                                                            value={"No"}
                                                                            checked={values.not_accredited_due_to_short_comings === 'No'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('not_accredited_due_to_short_comings', 'No');
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="departmentShortComingsNo">No</label>
                                                                    </div>
                                                                </div>

                                                                <div className="d-inline-block mx-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="departmentShortComings"
                                                                            id="departmentShortComingsNotApplicable"
                                                                            value={"Not Applicable"}
                                                                            checked={values.not_accredited_due_to_short_comings === 'Not Applicable'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('not_accredited_due_to_short_comings', 'Not Applicable');
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="departmentShortComingsNotApplicable">Not Applicable</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={12} md={6}>
                                                        <fieldset className="border p-2">
                                                            <legend className='w-auto' style={{ float: 'none', padding: 'inherit' }}>
                                                                <span className='px-4 bg-success rounded-pill'><i className="fa-solid fa-1" style={{ color: '#f8f9fc' }}></i></span> Number of beds in the department
                                                            </legend>
                                                            <FormGroup row className="col-sm-12 mb-3">
                                                                <Label for="total_number_of_beds" sm={7}>Total number of beds of the department</Label>
                                                                <Col sm={3}>
                                                                    <InputField
                                                                        type="number"
                                                                        placeholder=""
                                                                        invalid={{ errors, touched }}
                                                                        {...getFieldProps('total_number_of_beds')}
                                                                        readOnly
                                                                    />
                                                                </Col>
                                                            </FormGroup>

                                                            <FormGroup row className="col-sm-12 mb-3">
                                                                <Label for="number_of_paying_beds" sm={7}>Number of paying beds</Label>
                                                                <Col sm={3}>
                                                                    <InputField
                                                                        min={'0'}
                                                                        type="number"
                                                                        placeholder=""
                                                                        {...getFieldProps('number_of_paying_beds')}
                                                                    />
                                                                </Col>
                                                            </FormGroup>

                                                            <FormGroup row className="col-sm-12 mb-3">
                                                                <Label for="number_of_nonpaying_beds" sm={7}>Number of non-paying beds</Label>
                                                                <Col sm={3}>
                                                                    <InputField
                                                                        min={'0'}
                                                                        type="number"
                                                                        placeholder=""
                                                                        {...getFieldProps('number_of_nonpaying_beds')}
                                                                    />
                                                                </Col>
                                                            </FormGroup>

                                                            <FormGroup row className="col-sm-12 mb-3">
                                                                <Label for="number_of_cabins" sm={7}>Number of cabins</Label>
                                                                <Col sm={3}>
                                                                    <InputField
                                                                        min={'0'}
                                                                        type="number"
                                                                        placeholder=""
                                                                        invalid={{ errors, touched }}
                                                                        {...getFieldProps('number_of_cabins')}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                        </fieldset>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col sm={12}>
                                                <Row>
                                                    <Col sm={12} md={6}>
                                                        <fieldset className='border p-2 mt-3'>
                                                            <legend className='w-auto' style={{ float: 'none', padding: 'inherit' }}><span className='px-4 bg-success rounded-pill'><i className="fa-solid fa-2" style={{ color: '#f8f9fc' }}></i></span> </legend>
                                                            <FormGroup className="col-sm-12 mb-3">
                                                                <h5 className="font-size-14 mb-3"><span className='px-1 bg-primary rounded'><i className="fa-solid fa-a" style={{ color: '#f8f9fc' }}></i></span> Availability of the sub-specialty of the relevant department</h5>
                                                                <div className="d-inline-block mx-2">
                                                                    <div className="form-check form-check-left mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="availabilityOfSubSpecialty"
                                                                            id="availabilityOfSubSpecialtyYes"
                                                                            value={"Yes"}
                                                                            checked={values.availability_of_sub_speciality === 'Yes'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('availability_of_sub_speciality', 'Yes');
                                                                                }
                                                                            }}
                                                                            defaultChecked
                                                                        />
                                                                        <label className="form-check-label" htmlFor="availabilityOfSubSpecialtyYes">Yes</label>
                                                                    </div>
                                                                </div>

                                                                <div className="d-inline-block mx-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="availabilityOfSubSpecialty"
                                                                            id="availabilityOfSubSpecialtyNo"
                                                                            value={"No"}
                                                                            checked={values.availability_of_sub_speciality === 'No'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('availability_of_sub_speciality', 'No');
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="availabilityOfSubSpecialtyNo">No</label>
                                                                    </div>
                                                                </div>
                                                            </FormGroup>

                                                            {values.availability_of_sub_speciality === 'Yes' ? <SubSpecialtyOfTheRelevantDepartment inputList={subSpecialtyOfRelevantDepartmentList} setInputList={setSubSpecialtyOfRelevantDepartmentList} isDisabled={isDisabled} /> : null}
                                                        </fieldset>
                                                    </Col>

                                                    <Col sm={12} md={6}>
                                                        <fieldset className='border p-2 mt-3'>
                                                            <legend className='w-auto' style={{ float: 'none', padding: 'inherit' }}><span className='px-4 bg-success rounded-pill'><i className="fa-solid fa-3" style={{ color: '#f8f9fc' }}></i></span></legend>
                                                            <FormGroup className="col-sm-12 mb-3">
                                                                <h5 className="font-size-14 mb-3"><span className='px-1 bg-primary rounded'><i className="fa-solid fa-a" style={{ color: '#f8f9fc' }}></i></span> Facilities for emergency admission</h5>
                                                                <div className="d-inline-block me-2">
                                                                    <div className="form-check form-check-left mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="facilityForEmergencyAdmission"
                                                                            id="facilityForEmergencyAdmissionYes"
                                                                            value={"Yes"}
                                                                            checked={values.emergency_admission_facility === 'Yes'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('emergency_admission_facility', 'Yes');
                                                                                }
                                                                            }}
                                                                            defaultChecked
                                                                        />
                                                                        <label className="form-check-label" htmlFor="facilityForEmergencyAdmissionYes">Yes</label>
                                                                    </div>
                                                                </div>

                                                                <div className="d-inline-block me-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="facilityForEmergencyAdmission"
                                                                            id="facilityForEmergencyAdmissionNo"
                                                                            value={"No"}
                                                                            checked={values.emergency_admission_facility === 'No'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('emergency_admission_facility', 'No');
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="facilityForEmergencyAdmissionNo">No</label>
                                                                    </div>
                                                                </div>
                                                            </FormGroup>

                                                            <FormGroup className="col-sm-12 mb-3">
                                                                <h5 className="font-size-14 mb-3"><span className='px-1 bg-primary rounded'><i className="fa-solid fa-b" style={{ color: '#f8f9fc' }}></i></span> Availability of ICU Services</h5>
                                                                <div className="d-inline-block me-2">
                                                                    <div className="form-check form-check-left mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="availabilityOfICUServices"
                                                                            id="availabilityOfICUServicesYes"
                                                                            value={"Yes"}
                                                                            checked={values.availability_of_icu_service === 'Yes'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('availability_of_icu_service', 'Yes');
                                                                                }
                                                                            }}
                                                                            defaultChecked
                                                                        />
                                                                        <label className="form-check-label" htmlFor="availabilityOfICUServicesYes">Yes</label>
                                                                    </div>
                                                                </div>

                                                                <div className="d-inline-block me-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="availabilityOfICUServices"
                                                                            id="availabilityOfICUServicesNo"
                                                                            value={"No"}
                                                                            checked={values.availability_of_icu_service === 'No'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('availability_of_icu_service', 'No');
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="availabilityOfICUServicesNo">No</label>
                                                                    </div>
                                                                </div>
                                                            </FormGroup>

                                                            {values.availability_of_icu_service === 'Yes' ?
                                                                <>
                                                                    <FormGroup row className="col-sm-12 mb-3">
                                                                        <Label for="number_of_icu_beds" sm={6}>Number of beds</Label>
                                                                        <Col sm={3}>
                                                                            <InputField
                                                                                min={'0'}
                                                                                type="number"
                                                                                placeholder=""
                                                                                invalid={{ errors, touched }}
                                                                                {...getFieldProps('number_of_icu_beds')}
                                                                            />
                                                                        </Col>
                                                                    </FormGroup>

                                                                    <FormGroup row className="col-sm-12 mb-3">
                                                                        <Label for="number_of_icu_ventilators" sm={6}>Number of ventilators</Label>
                                                                        <Col sm={3}>
                                                                            <InputField
                                                                                min={'0'}
                                                                                type="number"
                                                                                placeholder=""
                                                                                invalid={{ errors, touched }}
                                                                                {...getFieldProps('number_of_icu_ventilators')}
                                                                            />
                                                                        </Col>
                                                                    </FormGroup>

                                                                    <FormGroup row className="col-sm-12 mb-3">
                                                                        <Label for="number_of_icu_doctors" sm={6}>Number of ICU doctors</Label>
                                                                        <Col sm={3}>
                                                                            <InputField
                                                                                min={'0'}
                                                                                type="number"
                                                                                placeholder=""
                                                                                invalid={{ errors, touched }}
                                                                                {...getFieldProps('number_of_icu_doctors')}
                                                                            />
                                                                        </Col>
                                                                    </FormGroup>

                                                                    <FormGroup row className="col-sm-12 mb-3">
                                                                        <Label for="number_of_icu_nurse_paramedics" sm={6}>
                                                                            Number of ICU nurse and paramedics
                                                                        </Label>
                                                                        <Col sm={3}>
                                                                            <InputField
                                                                                min={'0'}
                                                                                type="number"
                                                                                placeholder=""
                                                                                invalid={{ errors, touched }}
                                                                                {...getFieldProps('number_of_icu_nurse_paramedics')}
                                                                            />
                                                                        </Col>
                                                                    </FormGroup>
                                                                </> : null}
                                                        </fieldset>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <div className="d-flex justify-content-end my-3">
                                            {
                                                isDisabled !== true ?
                                                    <>
                                                        <Button type="reset" color="secondary" className='me-3' onClick={() => {
                                                            handleFormReset();
                                                        }}>
                                                            {'Reset'}
                                                        </Button>
                                                        <Button type="button" color="success" disabled={isDepartmentDataLoading || isDepartmentRenewDataLoading || !isMemberFound} onClick={() => {
                                                            handleSubmit();
                                                        }}>
                                                            {'Save & Next'}
                                                        </Button>
                                                    </>
                                                    :
                                                    <>
                                                        <Button type="button" style={{ pointerEvents: 'auto' }} color="success" onClick={() => {
                                                            goToNext();
                                                        }}>
                                                            {'Next'}
                                                        </Button>
                                                    </>
                                            }
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container >
            </div >
        </>
    );
};

export default InstituteDeptRegistrationStepOne;