import { useEffect, useState } from 'react';
import { useGetSessionWiseMiniCexListQuery } from 'src/rtk/features/miniCexCBD/miniCexAndCBDApi';
import { CBDPDFWrapper } from './CBDPDFWrapper';

interface Props {
    printId: any;
    setPrintId: any;
    data?: any;
    cbdAssessmentData?: any;
}

export const CBDPDF = (props: Props) => {
    const { printId, setPrintId, data, cbdAssessmentData } = props;

    const image1 = new Image();
    image1.src = '/images/notice_header.png';
    const image2 = new Image();
    image2.src = '/images/notice_footer.png';
    
    const [flag, setFlag] = useState<string>('random text');    

    const performanceNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    const options = {
        margin: [15, 15, 15, 15],
        image: { type: 'png', quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        pagebreak: { avoid: ['div', '.none'] },
    };    

    return (
        <>
            {flag !== '' && data ? <CBDPDFWrapper data={data} cbdAssessmentData={cbdAssessmentData} performanceNumber={performanceNumber} options={options} setPrintId={setPrintId} setFlag={setFlag} /> : null}
        </>
    );
};
