import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useState } from "react";
import { sideBarIndexInitialValues } from "./StaticFilesProtocolEntry"
import Objectives from "./Objectives";
import { config } from "src/config";


const RichTextEditor = (props: any) => {
    const { setObjectivesRichtexts, objectivesRichtexts, formikState, initialContentEditor, setInitialContentEditor, formikField } = props;
    const [contentEditor, setContentEditor] = useState();
    const [textEditorContent, setTextEditorContent] = useState<string>('');

    const setValuesOfObjectiveRichText = (values: any, content: any) => {

        // objectives
        if (formikField === 'general_objectives' || formikField === 'specific_objectives') {

            if (!values.find((x: { name: string; }) => x.name === formikField)) {
                values.push({ parent: 'objectives', value: '', name: formikField });
            }
            values[values.findIndex((x: { name: string; }) => x.name === formikField)].value = content;
        } else if (
            formikField === sideBarIndexInitialValues.study_design.objKey ||
            formikField === sideBarIndexInitialValues.introduction_context.objKey ||
            formikField === sideBarIndexInitialValues.rationale_of_research.objKey ||
            formikField === sideBarIndexInitialValues.research_question_hypothesis.objKey ||
            formikField === sideBarIndexInitialValues.ethical_implication.objKey ||
            formikField === sideBarIndexInitialValues.total_budget.objKey ||
            formikField === sideBarIndexInitialValues.source_of_funding.objKey ||
            formikField === sideBarIndexInitialValues.facilities_available_at_the_place_of_the_study.objKey ||
            formikField === sideBarIndexInitialValues.other_facilities_needed.objKey ||
            formikField === sideBarIndexInitialValues.dissemination_and_use_of_findings.objKey ||
            formikField === sideBarIndexInitialValues.references.objKey ||
            formikField === sideBarIndexInitialValues.any_other_relevant_info.objKey) {
            // other richtext
            if (!values.find((x: { name: string; }) => x.name === formikField)) {
                values.push({ parent: 'none', value: '', name: formikField });
            }
            values[values.findIndex((x: { name: string; }) => x.name === formikField)].value = content;

        } else if (formikField === sideBarIndexInitialValues.basic_information_of_the_thesis.objKey) {
            if (!values.find((x: { name: string; }) => x.name === 'summary')) {
                values.push({ parent: 'none', value: '', name: 'summary' });
            }
            values[values.findIndex((x: { name: string; }) => x.name === 'summary')].value = content;

        } else {
            // material and methods
            if (!values.find((x: { name: string; }) => x.name === formikField)) {
                values.push({ parent: 'material_methods', value: '', name: formikField });
            }
            values[values.findIndex((x: { name: string; }) => x.name === formikField)].value = content;
        }

    }

    const handleEditorChange = (content: any, editor: any) => {

        // handle objectives richtext
        const values = [...objectivesRichtexts];
        setObjectivesRichtexts(values);

        // handle richtext for objective and material and methods
        switch (formikField) {
            case 'general_objectives':
                setValuesOfObjectiveRichText(values, content);
                break;
            case 'specific_objectives':
                setValuesOfObjectiveRichText(values, content);
                break;
            case 'main_outcome':
                setValuesOfObjectiveRichText(values, content);
                break;
            case 'confounding_variable':
                setValuesOfObjectiveRichText(values, content);
                break;
            case 'study_population':
                setValuesOfObjectiveRichText(values, content);
                break;
            case 'sample_size':
                setValuesOfObjectiveRichText(values, content);
                break;
            case 'screening_methods':
                setValuesOfObjectiveRichText(values, content);
                break;
            case 'sampling_methods':
                setValuesOfObjectiveRichText(values, content);
                break;
            case 'inclusion_exclusion_criteria':
                setValuesOfObjectiveRichText(values, content);
                break;
            case 'operational_definitions':
                setValuesOfObjectiveRichText(values, content);
                break;
            case 'flow_chart':
                setValuesOfObjectiveRichText(values, content);
                break;
            case 'material_preparation':
                setValuesOfObjectiveRichText(values, content);
                break;
            case 'nature_of_controls':
                setValuesOfObjectiveRichText(values, content);
                break;
            case 'randomization_blinding':
                setValuesOfObjectiveRichText(values, content);
                break;
            case 'equipment':
                setValuesOfObjectiveRichText(values, content);
                break;
            case 'data_collection':
                setValuesOfObjectiveRichText(values, content);
                break;
            case 'professional_assistance':
                setValuesOfObjectiveRichText(values, content);
                break;
            case 'data_analysis_interpretation':
                setValuesOfObjectiveRichText(values, content);
                break;
            case 'quality_assurance':
                setValuesOfObjectiveRichText(values, content);
                break;
            case 'time_table':
                setValuesOfObjectiveRichText(values, content);
                break;
            default:
                setValuesOfObjectiveRichText(values, content);
        }
        setObjectivesRichtexts(values);



        // console.log('Content was updated:', content);
        setContentEditor(content);
        setTextEditorContent(content);
        // formikState[formikField] = content;
        // console.log(' fformikState[formikField] : ',  formikState[formikField] );
    };

    // useEffect(() => {
    //     let initialBody: any = null;

    //     if (formikState[formikField]?.includes('editorHtml')) {
    //         initialBody = JSON.parse(formikState[formikField]);
    //     } else {
    //         initialBody = { editorHtml: '' };
    //     }
    //     const initialContent: any = initialBody?.editorHtml;
    //     setInitialContentEditor(initialContent);
    // }, []);
    useEffect(() => {
        let initialBody: any = null;

        // if (formikField === 'general_objectives' ||
        //     formikField === 'specific_objectives' ||
        //     formikField === 'main_outcome' ||
        //     formikField === 'confounding_variable' ||
        //     formikField === 'study_population' ||
        //     formikField === 'sample_size' ||
        //     formikField === 'screening_methods' ||
        //     formikField === 'sampling_methods' ||
        //     formikField === 'inclusion_exclusion_criteria' ||
        //     formikField === 'operational_definitions' ||
        //     formikField === 'flow_chart' ||
        //     formikField === 'material_preparation' ||
        //     formikField === 'nature_of_controls' ||
        //     formikField === 'randomization_blinding' ||
        //     formikField === 'equipment' ||
        //     formikField === 'data_collection' ||
        //     formikField === 'professional_assistance' ||
        //     formikField === 'data_analysis_interpretation' ||
        //     formikField === 'quality_assurance' ||
        //     formikField === 'time_table') {

        if (objectivesRichtexts.find((x: { name: any; }) => x.name === formikField)) {
            const test = objectivesRichtexts.find((x: { name: any; }) => x.name === formikField).value;
            initialBody = objectivesRichtexts.find((x: { name: any; }) => x.name === formikField).value;
        } else if (
            formikField === 'general_objectives' ||
            formikField === 'specific_objectives'
        ) {
            initialBody = '';
        } else if (formikField === 'main_outcome' ||
            formikField === 'confounding_variable' ||
            formikField === 'study_population' ||
            formikField === 'sample_size' ||
            formikField === 'screening_methods' ||
            formikField === 'sampling_methods' ||
            formikField === 'inclusion_exclusion_criteria' ||
            formikField === 'operational_definitions' ||
            formikField === 'flow_chart' ||
            formikField === 'material_preparation' ||
            formikField === 'nature_of_controls' ||
            formikField === 'randomization_blinding' ||
            formikField === 'equipment' ||
            formikField === 'data_collection' ||
            formikField === 'professional_assistance' ||
            formikField === 'data_analysis_interpretation' ||
            formikField === 'quality_assurance' ||
            formikField === 'time_table'
        ) {
            initialBody =  '';
        } else if (formikField === sideBarIndexInitialValues.basic_information_of_the_thesis.objKey) {
            initialBody = formikState[sideBarIndexInitialValues.basic_information_of_the_thesis.summaryObjKey];
        } else {
            // if (formikField === sideBarIndexInitialValues.objectives.general_objectives.objKey) {
            //     initialBody = formikState[sideBarIndexInitialValues.objectives.objKey].find((objective: any) => objective.key === sideBarIndexInitialValues.objectives.general_objectives.objKey).value.value;
            // } else if (formikField === sideBarIndexInitialValues.objectives.specific_objectives.objKey) {
            //     initialBody = formikState[sideBarIndexInitialValues.objectives.objKey].find((objective: any) => objective.key === sideBarIndexInitialValues.objectives.specific_objectives.objKey).value.value;
            // } else {

            //}
            initialBody = formikState[formikField];
        }

        // old logic
        // if (formikState[formikField]?.includes('editorHtml')) {
        //     initialBody = JSON.parse(formikState[formikField]);
        // } else {
        //     initialBody = { editorHtml: '' };
        // }

        const initialContent: any = initialBody;
        setInitialContentEditor(initialContent);
    }, [formikField, formikState]);

    useEffect(() => {
        // formikState[formikField] = JSON.stringify({ editorHtml: contentEditor });
        // console.log("formikState[formikField] = JSON.stringify({ editorHtml: contentEditor });", JSON.stringify({ editorHtml: contentEditor }))

    }, [contentEditor]);


    return (
        <>
            <Editor
                initialValue={initialContentEditor}
                apiKey={config.EDITOR_API_KEY}
                init={{

                    height: 500,
                    menubar:  true ,
                    plugins: [
                        'advlist',
                        'autolink',
                        'lists',
                        'link',
                        'image',
                        'charmap',
                        'preview',
                        'anchor',
                        'searchreplace',
                        'visualblocks',
                        'fullscreen',
                        'insertdatetime',
                        'media',
                        'table',
                        'wordcount',
                        'pagebreak',
                        'help',
                        

   
                    ],
                    toolbar:
                        'undo redo |  blocks  | alignleft aligncenter alignright alignjustify outdent indent | bold italic forecolor  backcolor | ' +
                        ' fontfamily fontsize bullist numlist   | subscript superscript | removeformat  table',
                    // content_style:
                    //   'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',

                }}
                //  value={contentEditor ?? initialContentEditor}
                value={objectivesRichtexts.find((x: { name: any; }) => x.name === formikField)?.value ?? contentEditor ?? initialContentEditor}
                onEditorChange={handleEditorChange}
                disabled={formikState['submission_status'] === "Submit" ? true : false }
                
            />
            {/* <textarea className='tiny-mce' id='editor1' value={textEditorContent}></textarea> */}

            {/* <PDFViewer>
        <Document>
          <Page size="A4">
            <Html>{textEditorContent}</Html>
          </Page>
        </Document>
      </PDFViewer> */}
        </>
    );

};


export default RichTextEditor;