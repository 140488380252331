import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Button, InputField } from 'src/components';
import { config } from 'src/config';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import { useCreateInstitutePaymentMutation } from 'src/rtk/features/trainee/fcpsOfflinePaymentApi';
import * as Yup from 'yup';

interface EditOfflinePaymentProps {
  institutePayment?: GetFcpsOfflinePayment;
  setShowAddForm?: any;
}

const initialValues: AddFcpsOfflinePayment = {
  id: '',
  payment_method: '',
  payment_mode: 'Offline',
  status: true,
  bank_name: '',
  branch_name: '',
  deposit_slip_no: '',
  bank_deposit_slip: '',
  total_amount: '',
  payment_date: '',
  order_submit_type: '',
  institute_id: '',
};
const FILE_SIZE = 1024*1024;

const EditInstituteOfflinePayment = (props: EditOfflinePaymentProps) => {
  const { institutePayment, setShowAddForm } = props;

  const fileInputRef = useRef<any>();
  const [onlineRadio, setOnlineRadio] = useState<boolean>(false);
  const [offlineRadio, setOfflineRadio] = useState<boolean>(true);
  const [uploadSlipStatus, setUploadSlipStatus] = useState<boolean>(false);
  const [slip, setSlip] = useState('');
  const [btnClicked, setBtnClicked] = useState<string>('');
  const [cashSelected, setCashSelected] = useState<boolean>(false);


  const [
    createInstitutePayment,
    { data: paymentResponse, isSuccess, isError, error },
  ] = useCreateInstitutePaymentMutation();


  const paymentMethods: SelectOption[] =
    useGetLookupDataQuery('payment_method')
      .data?.data[0]?.childs.filter(
        (item) =>
          item.name.trim() !== 'Mobile Finance' &&
          item.name.trim() !== 'Credit Card',
      )
      .map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      }) || [];

  const bankNames: SelectOption[] =
    useGetLookupDataQuery('bank_name')
      .data?.data[0]?.childs.map((item) => ({
        value: item.name,
        label: item.name,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) || [];

  const branchNames: SelectOption[] =
    useGetLookupDataQuery('branch_name')
      .data?.data[0]?.childs.map((item) => ({
        value: item.name,
        label: item.name,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) || [];

  const err: CustomError = error as CustomError;

  const handleFormReset = () => {
    resetForm();
    setOnlineRadio(false);
    setOfflineRadio(true);
    setUploadSlipStatus(false);
    setCashSelected(false);
    fileInputRef.current.value = '';
    setShowAddForm(true);
  };

  const handleCashSelection = (e: any) => {
    if (e.target.value.trim() === 'Cash') {
      setCashSelected(true);
    } else {
      setCashSelected(false);
    }
  };

  const {
    handleSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    resetForm,
    setFieldValue,
    setValues,
    setErrors,
  } = useFormik({
    initialValues,

    validationSchema: Yup.object({
      payment_method: Yup.string().required(),
      payment_date: Yup.date().max(new Date(Date.now()), 'Payment date can\'t be a future date').required(),
      bank_name: cashSelected ? Yup.string() : Yup.string().required('Bank name is Required!'),
      branch_name: cashSelected ? Yup.string() : Yup.string().required('Branch name is Required!'),
      deposit_slip_no: uploadSlipStatus
        ? Yup.string().required()
        : Yup.string(),
      bank_deposit_slip: Yup.mixed()
        // .required('Deposit Slip is Required!')
        .test('is-pdf', 'Must be a File', (value) => {
          if (value) {
            return [
              'application/pdf',
              'image/jpeg',
              'image/png',
              'image/jpg',
            ].includes(value.type);
          }
          return true;
        })
        .test('file-size', 'File too large', (value) => {
          if (value) {
            return value.size <= FILE_SIZE;
          }
          return true;
        }),
    }),

    onSubmit: (values: any) => {
      console.log(values);

      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        if (key === 'status') {
          values[key] = values.status ? 1 : 0;
        }
        if (key === 'order_submit_type') {
          if (btnClicked === 'submit') {
            values[key] = 'Submit';
          } else if (btnClicked === 'save_as_draft') {
            values[key] = 'Draft';
          }
        }

        formData.append(key, values[key]);
        console.log(key, values[key], formData);
      });

      createInstitutePayment(formData);
    },
  });

  useEffect(() => {
    const data: GetFcpsOfflinePayment = institutePayment!;

    console.log(data);

    setValues({
      id: data?.id,
      institute_id: data?.institute_id!,
      payment_method: data?.payment_method,
      payment_mode: data?.payment_mode!,
      status: data?.status!,
      bank_name: data?.bank_name,
      branch_name: data?.branch_name,
      deposit_slip_no: data?.deposit_slip_no || '',
      bank_deposit_slip: '',
      total_amount: data?.total_amount,
      payment_date: data?.payment_date,
      order_submit_type: data?.order_submit_type,
    });

    if(data?.payment_method?.trim() === 'Cash') {
      setCashSelected(true);
    }

    setSlip(`${config?.APP_URL}${data?.bank_deposit_slip}`);
  }, []);



  useEffect(() => {
    if (isSuccess) {
      handleFormReset();
      setShowAddForm(false);
      toast.success('Institute Payment Updated Successfully');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      const errors: any = {};

      if (err?.data) {
        Object.keys(err?.data).forEach((key) => {
          errors[key] = err?.data[key][0];
        });
      }
      setErrors(errors);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (cashSelected) {
      setFieldValue('bank_name', '');
      setFieldValue('branch_name', '');
    }
  }, [cashSelected]);

  return (
    <div className="row align-items-center justify-content-center">
      <div className="card">
        <div className="card-body">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            onReset={(e) => {
              e.preventDefault();
              handleFormReset();
            }}
          >
            <Row className="justify-content-evenly">
              <Col sm={12}>
                <div className="mb-3">
                  <Label className="form-label required-field">
                    Payment Mode
                  </Label>
                  <br />
                  <FormGroup check inline>
                    <Input
                      id="paymentMode1"
                      name="payment_mode"
                      type="radio"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setOnlineRadio(true);
                          setOfflineRadio(false);
                        }

                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      value={'Online'}
                      checked={onlineRadio}
                      readOnly
                      disabled
                    />

                    <Label check for="paymentMode1">
                      Online
                    </Label>
                  </FormGroup>

                  <FormGroup check inline>
                    <Input
                      id="paymentMode2"
                      name="payment_mode"
                      type="radio"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setOnlineRadio(false);
                          setOfflineRadio(true);
                        }

                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      value={'Offline'}
                      checked={offlineRadio}
                    />

                    <Label check for="paymentMode2">
                      Offline
                    </Label>
                  </FormGroup>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={3} sm={12}>
                <FormGroup className="mb-3">
                  <Label className="form-label required-field">
                    Payment For
                  </Label>
                  <InputField
                    type="text"
                    name="payment_for"
                    id="payment_for"
                    value={institutePayment?.payment_for}
                    placeholder="Payment For"
                    readOnly
                  />
                </FormGroup>
              </Col>

              <Col md={3} sm={12}>
                <FormGroup className="mb-3">
                  <Label className="form-label required-field">Fees</Label>
                  <InputField
                    type="text"
                    placeholder="Amount"
                    invalid={{ errors, touched }}
                    {...getFieldProps('total_amount')}
                    readOnly
                  />
                </FormGroup>
              </Col>

              <Col md={3} sm={12}>
                <FormGroup className="mb-3">
                  <Label className="form-label required-field">
                    Payment Method
                  </Label>
                  <InputField
                    id="payment_method"
                    name="payment_method"
                    type="select"
                    placeholder=""
                    options={paymentMethods}
                    value={values.payment_method}
                    invalid={{ errors, touched }}
                    onChange={(e) => {
                      handleCashSelection(e);
                      handleChange(e);
                    }}
                  />
                </FormGroup>
              </Col>

              <Col md={3} sm={12}>
                <FormGroup className="mb-3">
                  <Label className="form-label required-field">
                    Payment Date
                  </Label>
                  <InputField
                    type="date"
                    invalid={{ errors, touched }}
                    {...getFieldProps('payment_date')}
                  />
                </FormGroup>
              </Col>

              <Col md={3} sm={12}>
                {!cashSelected ? (
                  <FormGroup className="mb-3">
                    <Label className="form-label required-field">
                      Bank Name
                    </Label>
                    <InputField
                      type="text"
                      list="bank_name"
                      placeholder="Bank Name"
                      invalid={{ errors, touched }}
                      {...getFieldProps('bank_name')}
                    />
                    <datalist id="bank_name">
                      {bankNames.map((bank) => {
                        return (
                          <option value={`${bank.value}`} key={`${bank.value}`}>
                            {`${bank.label}`}
                          </option>
                        );
                      })}
                    </datalist>
                  </FormGroup>
                ) : (
                  <FormGroup className="mb-3">
                    <Label className="form-label">Bank Name</Label>
                    <InputField
                      type="text"
                      list="bank_name"
                      placeholder="Bank Name"
                      invalid={{ errors, touched }}
                      {...getFieldProps('bank_name')}
                      readOnly
                    />
                    <datalist id="bank_name">
                      {bankNames.map((bank) => {
                        return (
                          <option value={`${bank.value}`} key={`${bank.value}`}>
                            {`${bank.label}`}
                          </option>
                        );
                      })}
                    </datalist>
                  </FormGroup>
                )}
              </Col>

              <Col md={3} sm={12}>
                {!cashSelected ? (
                  <FormGroup className="mb-3">
                    <Label className="form-label required-field">
                      Branch Name
                    </Label>
                    <InputField
                      type="text"
                      list="branch_name"
                      placeholder="Branch Name"
                      invalid={{ errors, touched }}
                      {...getFieldProps('branch_name')}
                    />
                    <datalist id="branch_name">
                      {branchNames.map((branch) => {
                        return (
                          <option
                            value={`${branch.value}`}
                            key={`${branch.value}`}
                          >
                            {`${branch.label}`}
                          </option>
                        );
                      })}
                    </datalist>
                  </FormGroup>
                ) : (
                  <FormGroup className="mb-3">
                    <Label className="form-label">Branch Name</Label>
                    <InputField
                      type="text"
                      list="branch_name"
                      placeholder="Branch Name"
                      invalid={{ errors, touched }}
                      {...getFieldProps('branch_name')}
                      readOnly
                    />
                    <datalist id="branch_name">
                      {branchNames.map((branch) => {
                        return (
                          <option
                            value={`${branch.value}`}
                            key={`${branch.value}`}
                          >
                            {`${branch.label}`}
                          </option>
                        );
                      })}
                    </datalist>
                  </FormGroup>
                )}
              </Col>

              <Col md={3} sm={12}>
                <FormGroup className="mb-3">
                  <Label
                    className={
                      uploadSlipStatus
                        ? 'form-label required-field'
                        : 'form-label'
                    }
                  >
                    Deposit/Check No.
                  </Label>
                  <InputField
                    type="text"
                    placeholder="Deposit Slip No"
                    invalid={{ errors, touched }}
                    {...getFieldProps('deposit_slip_no')}
                  />
                </FormGroup>
              </Col>

              <Col md={3} sm={12}>
                <FormGroup className="mb-3">
                  <Label className="form-label">Upload Slip</Label>{' '}
                  <i className="fas fa-info-circle" title="Size cannot be greater than 1MB" />
                  {slip && (
                    <a
                      className="me-2 p-0 center"
                      href={slip}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <span className="btn-outline-info btn-sm btn ms-2">
                        View
                      </span>
                    </a>
                  )}
                  <input
                    ref={fileInputRef}
                    name="bank_deposit_slip"
                    type="file"
                    className="form-control"
                    placeholder="Upload Slip"
                    onChange={(e: any) => {
                      setFieldValue('bank_deposit_slip', e.target.files[0]);
                      if (e.target.files[0]) {
                        setUploadSlipStatus(true);
                      } else {
                        setUploadSlipStatus(false);
                      }
                    }}
                    onBlur={handleBlur}
                  ></input>
                  {errors.bank_deposit_slip && touched.bank_deposit_slip ? (
                    <div className="text-danger">
                      {errors.bank_deposit_slip as string}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>

              {/* <Col sm={12}>
                        <FormGroup check className='mb-3 mt-4'>
                            <Input
                              type="checkbox"
                              role="check"
                              checked={values.status}
                              {...getFieldProps('status')}
                            />
                            <Label check>Status</Label>
                        </FormGroup>
                        </Col>                                                                                                                                                                                                                                                                 */}

              <Col sm={12}>
                <FormGroup className="mb-3 text-center">
                  <Button
                    type="reset"
                    text="Reset"
                    className="btn btn-secondary me-2"
                  ></Button>
                  <Button
                    type="submit"
                    text="Save as Draft"
                    className="btn btn-info me-2"
                    onClick={(e) => {
                      setBtnClicked('save_as_draft');
                    }}
                  ></Button>
                  <Button
                    type="submit"
                    text="Submit"
                    className="btn btn-success"
                    onClick={(e) => {
                      setBtnClicked('submit');
                    }}
                  ></Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EditInstituteOfflinePayment;
