import TransactionNoticeLists from "src/pages/Setup/TrnNoticeMaster";
import AddNoticeAttachment from "src/pages/Setup/TrnNoticeMaster/AddAttachment";
import AddNoticeMaster from "src/pages/Setup/TrnNoticeMaster/AddNoticeMaster";
import EditNoticeMaster from "src/pages/Setup/TrnNoticeMaster/EditNoticeMaster";
import NoticeAttachmentList from "src/pages/Setup/TrnNoticeMaster/NoticeAttachmentList";

export const trnNoticeMasterRoutes = [
  // Transaction notice setup
  {
    path: '/setup/trn-notice-master-list/',
    component: TransactionNoticeLists,
  },
  {
    path: '/setup/trn-notice-master/update/:id',
    component: EditNoticeMaster,
  },
  {
    path: '/setup/trn-notice-master/add',
    component: AddNoticeMaster,
  },
  {
    path: '/setup/trn-notice-attachment/add',
    component: AddNoticeAttachment,
  },
  {
    path: '/setup/trn-notice-attachment/:id',
    component: NoticeAttachmentList
  }
];
