import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { Container, Form, Label, FormGroup, Input } from 'reactstrap';
import { InputField, Button } from 'src/components';
import { useFormik } from 'formik';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { putUrlString } from 'src/helpers';
import { useGetDepartmentsQuery } from 'src/rtk/features/setup/department/departmentApi';
import { useGetNoticeTemplateListQuery, useUpdateNoticeTemplateListMutation } from 'src/rtk/features/setup/noticeTemplateList/setupNoticeTemplateListApi';
import EditNoticeTemplateCopyAttachment from './EditNoticeTemplateCopyAttachment';
import { useGetNoticeTemplateAttachmentsQuery } from 'src/rtk/features/setup/noticeTemplateAttachment/setupNoticeTemplateAttachmentApi';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';

const initialValues: NoticeTemplateList = {
    id: '',
    notice_type: '',
    department_id: '',
    notice_subject: '',
    notice_header: '',
    notice_body: '',
    notice_footer: '',
    status: true,
    creator_organization_id: '',
    updater_organization_id: '',
    created_by: '',
    updated_by: '',
    deleted_by: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
};

const EditNoticeTemplateList = () => {
    const { id } = useParams<{ id: string }>();
    const { data: noticeTemplateList, isError: isNoticeTemplateListFetchError } =
        useGetNoticeTemplateListQuery(id);
    const [updateNoticeTemplateList, { isSuccess, isError, error }] =
        useUpdateNoticeTemplateListMutation();

    const { data } = useGetDepartmentsQuery();
    const departments = data?.data || [];

    const { data: noticeAttachmentList } = useGetNoticeTemplateAttachmentsQuery();
    const noticeAttachments = noticeAttachmentList?.data.filter((n: any) => n.notice_list_id === id) || [];

    const noticeType: SelectOption[] =
    useGetLookupDataQuery('notice_type')
      .data?.data[0]?.childs.map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) || [];

    const err: CustomError = error as CustomError;
    const history = useHistory();
  const {
    handleSubmit,
    getFieldProps,
    values,
    touched,
    errors,
    setValues,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({        
        department_id: Yup.string().required(
          'Notice template must be under a department',
        ),
    }),

    onSubmit: (values) => {
      const url = putUrlString(id, values);
      updateNoticeTemplateList(url);
    },
  });

  useEffect(() => {
    if (noticeTemplateList && !isNoticeTemplateListFetchError) {
      const data: NoticeTemplateList = (noticeTemplateList as any).data;      
      setValues({
        id: data.id,
        notice_type: data.notice_type,
        department_id: data.department_id,
        notice_subject: data.notice_subject,
        notice_header: data.notice_header,
        notice_body: data.notice_body,
        notice_footer: data.notice_footer,
        status: data.status,
        creator_organization_id: data.creator_organization_id,
        updater_organization_id: data.updater_organization_id,
        created_by: data.created_by,
        updated_by: data.updated_by,
        deleted_by: data.deleted_by,
        created_at: data.created_at,
        updated_at: data.updated_at,
        deleted_at: data.deleted_at,
      });
    }
  }, [noticeTemplateList, isNoticeTemplateListFetchError]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Notice template updated successfully');      
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      const errors: any = {};

      if (err?.data) {
        Object.keys(err?.data).forEach((key) => {
          errors[key] = err?.data[key][0];
        });
      }
      setErrors(errors);
    }
  }, [isSuccess, isError]);

  // let op: SelectOption[] = [{label: 'Select a Department', value: ''}];
  let op: SelectOption[] = [...departments?.map((e: Department) => ({label: e.department_name!, value: e.id!}))]

  return (
    <div className="page-content">
      <Container fluid>    
        <BetterBreadcrumb
            breadcrumbItem={[{ link: '/setup/notice-template-list', name: 'Notice Template List' }]}
            title={'Edit Notice Template'}
          />    
        <div className="row align-items-center justify-content-center">         
            <div className="card mt-3">
              <h5 className="card-header">
                <i className="fa fa-edit me-2" />
                Edit Notice Template
              </h5>
              <div className="card-body">
                <Form
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <div className="row">
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                      <FormGroup className="mb-3">
                        <Label for="notice_type">Notice Type</Label>
                        <InputField
                          type="select"
                          placeholder="Notice Type"
                          options={noticeType}
                          invalid={{ errors, touched }}
                          {...getFieldProps('notice_type')}
                        />
                      </FormGroup>
                    </div>

                    <div className='col-lg-4 col-md-6 col-sm-12'>
                      <FormGroup className="mb-3">
                        <Label for="department_id">Department</Label>
                        <InputField
                          type="select"
                          placeholder="Department"
                          options={op}
                          invalid={{ errors, touched }}
                          {...getFieldProps('department_id')}
                        />
                      </FormGroup>
                    </div>

                    <div className='col-lg-4 col-md-6 col-sm-12'>
                      <FormGroup className="mb-3">
                        <Label for="notice_subject">Notice Subject</Label>
                        <InputField
                          type="text"
                          placeholder="Notice Subject"
                          invalid={{ errors, touched }}
                          {...getFieldProps('notice_subject')}
                        />
                      </FormGroup>
                    </div>

                    <div className='col-lg-4 col-md-6 col-sm-12'>
                      <FormGroup className="mb-3">
                        <Label for="notice_header">Notice Header</Label>
                        <InputField
                          type="text"
                          placeholder="Notice Header"
                          invalid={{ errors, touched }}
                          {...getFieldProps('notice_header')}
                        />
                      </FormGroup>
                    </div>

                    <div className='col-lg-4 col-md-6 col-sm-12'>
                      <FormGroup className="mb-3">
                        <Label for="notice_body">Notice Body</Label>
                        <InputField
                          type="textarea"
                          placeholder="Notice Body"
                          invalid={{ errors, touched }}
                          {...getFieldProps('notice_body')}
                        />
                      </FormGroup>
                    </div>

                    <div className='col-lg-4 col-md-6 col-sm-12'>
                      <FormGroup className="mb-3">
                        <Label for="notice_footer">Notice Footer</Label>
                        <InputField
                          type="textarea"
                          placeholder="Notice Footer"
                          invalid={{ errors, touched }}
                          {...getFieldProps('notice_footer')}
                        />
                      </FormGroup>
                    </div>

                    <div className='col-lg-4 col-md-6 col-sm-12'>
                      <FormGroup check className='mb-3 mt-4'>
                        <Label check>
                            <Input type="checkbox" {...getFieldProps('status')} checked={values.status} />{' '}
                            Status
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                  <Button type="submit" text="Submit" />
                </Form>
              </div>
            </div>            
        </div>
      </Container>
    </div>
  );
};

export default EditNoticeTemplateList;
function useGetNoticeTemplateQuery(id: string): { data: any; isError: any; } {
  throw new Error('Function not implemented.');
}

