import ELogBookSuperVisorList from 'src/pages/ELogBook/Supervisor/ELogBookSuperVisorList';
import TraineeAcademicActivitiesEntry from 'src/pages/ELogBook/Trainee/AcademicActivities/TraineeAcademicActivitiesEntry';
import TraineeAcademicActivitiesList from 'src/pages/ELogBook/Trainee/AcademicActivities/TraineeAcademicActivitiesList';
import TraineeLogbookEntry from 'src/pages/ELogBook/Trainee/LogbookEntry/TraineeLogbookEntry';
import UpdatedLogbookEntryList from 'src/pages/ELogBook/Trainee/LogbookEntry/UpdatedLogbookEntryList';

export const eLogBookRoutes = [
  // E-Log-Book Super-visor
  { path: '/supervisor/logbook', component: ELogBookSuperVisorList },
  { path: '/supervisor/logbook/:id', component: ELogBookSuperVisorList },
  {
    path: '/trainee/logbook/academic-activities',
    component: TraineeAcademicActivitiesList,
  },
  {
    path: '/trainee/logbook/academic-activities/:id',
    component: TraineeAcademicActivitiesEntry,
  },
  {
    path: '/trainee/logbook-entry/academic-activities/:id',
    component: TraineeAcademicActivitiesList,
  },
  { path: '/trainee/logbook-entry', component: UpdatedLogbookEntryList },
  { path: '/trainee/logbook-entry/:id', component: UpdatedLogbookEntryList },
  { path: '/e-logbook/logbook-entry/add', component: TraineeLogbookEntry },
];
