import { apiSlice } from 'src/rtk/api/apiSlice';
import * as faqUrl from 'src/helpers/url_helper';

export const FAQApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['faqs'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getFaqListForAdmin: builder.query<APIResponse<ResearchClinicFAQ[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${faqUrl.GET_FAQ_LIST_FOR_ADMIN}`,
        }),
        providesTags: ['faqs'],
      }),      

      getFaqListForTrainee: builder.query<APIResponse<ResearchClinicFAQ[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${faqUrl.GET_FAQ_LIST_FOR_TRAINEE}`,
        }),
        providesTags: ['faqs'],
      }),      

      createFaq: builder.mutation<APIResponse<ResearchClinicFAQ>, ResearchClinicFAQ>({
        query: (data) => ({
          url: `${faqUrl.CREATE_FAQ}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['faqs'],
      }),

      updateFaq: builder.mutation<APIResponse<ResearchClinicFAQ>, ResearchClinicFAQ>({
        query: ({id, ...data}) => ({
          url: `${faqUrl.UPDATE_FAQ}/${id}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['faqs'],
      }),

      deleteFaq: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${faqUrl.DELETE_FAQ}/${id}`,
        }),
        invalidatesTags: ['faqs'],
      }),
    }),
  });

export const {
  useGetFaqListForAdminQuery,  
  useGetFaqListForTraineeQuery,  
  useCreateFaqMutation,
  useUpdateFaqMutation,
  useDeleteFaqMutation,
} = FAQApi;
