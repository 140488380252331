import { useFormik } from 'formik';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { InputField } from "src/components";
import * as Yup from 'yup';

const OnlinePaymentForm = () => {

  const {
    handleSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    resetForm,
    setFieldValue,
    setErrors,
  } = useFormik({
    initialValues: {
      customer_name: '',
      customer_mobile: '',
      customer_email: '',
      address: '',
      address2: ''
    },

    validationSchema: Yup.object({
      // customer_name: Yup.string().required('Name is Required!'),
      // customer_mobile: Yup.string().required('Mobile Number is Required!'),
      // customer_email: Yup.string().required('Email is Required!'),
      // address: Yup.string().required('Address is Required!'),
    }),

    onSubmit: (values: any) => {
      console.log(values);
    },
  });

  return (
    <div className="page-content bg-light">
      <div className="container">
        <Row>
          <Col sm={3}>
          </Col>
          <Col sm={6}>
            <Card className="shadow-lg">
              <CardHeader>
                <strong>Payment Details</strong>
              </CardHeader>
              <CardBody>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <FormGroup>
                    <Label for="customer_name" className="form-label required-field">Full Name</Label>
                    <InputField
                      type="text"
                      name='customer_name'
                      placeholder='Full Name'
                      onChange={(e: any) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      invalid={{ errors, touched }}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="customer_mobile" className="form-label required-field">Mobile</Label>
                    <div className="input-group col-sm-12">
                      <div className="input-group-prepend">
                        <span className="input-group-text">+88</span>
                      </div>
                      <Input
                        type="text"
                        name='customer_mobile'
                        placeholder='Mobile'
                        onChange={(e: any) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        required
                      />
                    </div>
                    {errors.customer_mobile && touched.customer_mobile ? (
                      <div className="text-danger error-size">{errors.customer_mobile as string}</div>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <Label for="customer_email" className="form-label required-field">Email</Label>
                    <InputField
                      type="email"
                      name='customer_email'
                      placeholder='Email'
                      onChange={(e: any) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      invalid={{ errors, touched }}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="address" className="form-label required-field">Address</Label>
                    <InputField
                      type="text"
                      name='address'
                      placeholder='Address'
                      onChange={(e: any) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      invalid={{ errors, touched }}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="address2" className="form-label">Address 2(Optional)</Label>
                    <InputField
                      type="text"
                      name='address2'
                      placeholder='Address(optional)'
                      onChange={(e: any) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      invalid={{ errors, touched }}
                    />
                  </FormGroup>
                  <div className='text-center bg-secondary bg-opacity-75 p-1 rounded mb-1'>
                    <h5>Total(BDT): 2000</h5>
                  </div>
                  <div>
                    <button
                      type='submit'
                      className='btn btn-success w-100'
                    >
                      Make Payment
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col sm={3}>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OnlinePaymentForm;
