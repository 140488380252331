import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import { useDeleteFacultyInfoMutation } from 'src/rtk/features/setup/facultyInfo/facultyInfoApi';
import EditFacultyInfo from './EditFacultyInfo';
// import { useDeleteOrganizationMutation } from 'src/rtk/features/setup/organization/setupOrganizationApi';

interface Props {
  facultyInfo: FacultyInfo;
  index: number;
}

const FacultyInfoTableRow = ({ facultyInfo, index }: Props) => {
  const history = useHistory();

  const [infoId, setInfoId] = useState('');
  const [editId, setEditId] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);

  const [deleteFacultyInfo, { isSuccess, isLoading, isError }] =
    useDeleteFacultyInfoMutation();

  const deleteHandler = (modalState: boolean, id: string) => {
    setModalShow(modalState);
    setInfoId(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Faculty deleted successfully');
    }
    if (isError) {
      toast.error('Error deleting faculty');
    }
  }, [isSuccess, isError]);

  return (
    <tr key={facultyInfo.id}>
      <td>{facultyInfo.code}</td>
      <td>{facultyInfo.faculty_name}</td>
      <td>{facultyInfo.description}</td>
      <td>{facultyInfo.status ? 'Active' : 'Inactive'}</td>
      <td>
        <div className="d-flex gap-2">
          <Button
            size="sm"
            text="Edit"
            color="warning"
            onClick={() => {
              setEditModalShow(true);
              setEditId(facultyInfo?.id!);
            }}
          />
          <Button
            size="sm"
            text="Delete"
            color="danger"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => deleteHandler(true, facultyInfo.id!)}
          />
        </div>
        {modalShow ? (
          <DeleteInformationModal
            isOpen={modalShow}
            toggle={() => setModalShow(!modalShow)}
            id={infoId}
            deleteInfomation={deleteFacultyInfo}
          />
        ) : null}
        {editModalShow &&
          <EditFacultyInfo isOpen={editModalShow} toggle={() => setEditModalShow(!editModalShow)} id={editId}/>
        }
      </td>
    </tr>
  );
};

export default FacultyInfoTableRow;
