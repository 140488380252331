import { useFormik } from "formik";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { Button, InputField } from "src/components";
import { setFromErrors } from "src/helpers";
import { useLaboratoryRecordEntryMutation } from "src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi";
import * as Yup from 'yup';

interface LaboratoryWorkProps {
    departmentId?: string;
    laboratoryRecordToEdit?: LaboratoryRecordList;
    setModalShow?: any;
}

const initialValues: LaboratoryRecordEntry = {
    laboratory_record_id: '',
    form_b_department_id: '',
    period_from: '',
    period_to: '',
    type: '',
    laboratory_work_name: '',
    laboratory_work_done: 0,
    total_number: 0,
};

const RecordsOfRoutineAndEmergencyLaboratoryWorkOfTheDeptModal = (props: LaboratoryWorkProps) => {
    const { departmentId, laboratoryRecordToEdit, setModalShow } = props;

    const [laboratoryRecordEntry, { data: laboratoryRecordEntryData, isSuccess: isLaboratoryRecordEntryDataSuccess, isLoading: isLaboratoryRecordEntryDataLoading, isError: isLaboratoryRecordEntryDataError, error: laboratoryRecordEntryDataError }] =
        useLaboratoryRecordEntryMutation();

    const err: CustomError = laboratoryRecordEntryDataError as CustomError;

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({}),

        onSubmit: (values: LaboratoryRecordEntry) => {
            console.log(values);
            values.form_b_department_id = departmentId;
            laboratoryRecordEntry(values);
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    const handleFormReset = () => {
        resetForm();
    };

    useEffect(() => {
        if (laboratoryRecordToEdit?.id) {
            setValues({
                laboratory_record_id: laboratoryRecordToEdit?.id,
                form_b_department_id: laboratoryRecordToEdit?.form_b_department_id,
                period_from: laboratoryRecordToEdit?.period_from,
                period_to: laboratoryRecordToEdit?.period_to,
                type: laboratoryRecordToEdit?.type,
                laboratory_work_name: laboratoryRecordToEdit?.laboratory_work_name,
                laboratory_work_done: laboratoryRecordToEdit?.laboratory_work_done,
                total_number: laboratoryRecordToEdit?.total_number,
            });
        }
    }, []);

    useEffect(() => {
        if (isLaboratoryRecordEntryDataSuccess) {
            handleFormReset();
            toast.success('Laboratory record saved successfully');
            setModalShow(false);
        }
        if (isLaboratoryRecordEntryDataError) {
            Object.entries(err?.data).forEach((entry: any) => {
                entry[1]?.forEach((message: any) => {
                    toast.error(message);
                });
            });

            setFromErrors(err.data, setErrors);
        }
    }, [isLaboratoryRecordEntryDataSuccess, isLaboratoryRecordEntryDataError]);

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title">
                                    <i className="fa fa-plus me-2" />
                                    Record of routine and emergency laboratory work of the department
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    autoComplete="off"
                                    className="custom-form"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    onReset={(e) => {
                                        e.preventDefault();
                                        handleFormReset();
                                    }}
                                >
                                    <Row>
                                        <Col sm={12} md={3}>
                                            <FormGroup className="mb-3">
                                                <Label for="period_from">From</Label>
                                                <InputField
                                                    type="date"
                                                    placeholder=""
                                                    {...getFieldProps('period_from')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={3}>
                                            <FormGroup className="mb-3">
                                                <Label for="period_to">To</Label>
                                                <InputField
                                                    type="date"
                                                    placeholder=""
                                                    {...getFieldProps('period_to')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="laboratory_work_name">Name of Laboratory Work</Label>
                                                <InputField
                                                    type="text"
                                                    placeholder=""
                                                    {...getFieldProps('laboratory_work_name')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={4}>
                                            <FormGroup className="mb-3">
                                                <Label for="type">Type of Laboratory Work</Label>
                                                <InputField
                                                    type="select"
                                                    options={[{ label: 'Routine', value: 'Routine' }, { label: 'Emergency', value: 'Emergency' }]}
                                                    placeholder=""
                                                    {...getFieldProps('type')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={4}>
                                            <FormGroup className="mb-3">
                                                <Label for="laboratory_work_done">No. of Laboratory Work Done</Label>
                                                <InputField
                                                    min={'0'}
                                                    type="number"
                                                    placeholder=""
                                                    {...getFieldProps('laboratory_work_done')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={4}>
                                            <FormGroup className="mb-3">
                                                <Label for="total_number">No. of Procedure</Label>
                                                <InputField
                                                    min={'0'}
                                                    type="number"
                                                    placeholder=""
                                                    {...getFieldProps('total_number')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} className="mt-3">
                                            <FormGroup className="mb-3 text-center">
                                                <Button
                                                    type="reset"
                                                    text="Reset"
                                                    className="btn btn-warning me-2"
                                                ></Button>
                                                <Button
                                                    type="submit"
                                                    text="Save"
                                                    className="btn btn-success me-2"
                                                    disabled={isLaboratoryRecordEntryDataLoading}
                                                ></Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default RecordsOfRoutineAndEmergencyLaboratoryWorkOfTheDeptModal;