import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { Button, InputField } from "src/components";
import { config } from "src/config";
import { setFromErrors } from "src/helpers";
import { useAcademicStaffInfoEntryMutation } from "src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi";
import * as Yup from 'yup';

interface AcademicStaffProps {
    departmentId?: string;
    academicStaffToEdit?: AcademicStaffInfoList;
    setModalShow?: any;
}

const initialValues: AcademicStaffInfoEntry = {
    staff_info_id: '',
    form_b_department_id: '',
    bmdc_reg_no: '',
    name: '',
    designation: '',
    job_type: '',
    total_number: 0,
    no_of_full_time: 0,
    no_of_part_time: 0,
    qualification: '',
    bmdc_reg_validity: '',
    teaching_experience: 0,
    list_of_publications: '',
};

const AcademicStaffRecordsModal = (props: AcademicStaffProps) => {
    const { departmentId, academicStaffToEdit, setModalShow } = props;

    const [academicStaffRecordEntry, { data: academicStaffRecordEntryData, isSuccess: isAcademicStaffRecordEntryDataSuccess, isLoading: isAcademicStaffRecordEntryDataLoading, isError: isAcademicStaffRecordEntryDataError, error: academicStaffRecordEntryDataError }] =
        useAcademicStaffInfoEntryMutation();

    const [listOfPublicationFile, setListOfPublicationFile] = useState<any>('');
    const listOfPublicationsFileReference = useRef<any>();

    const err: CustomError = academicStaffRecordEntryDataError as CustomError;

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({}),

        onSubmit: (values: any) => {
            console.log(values);
            values.form_b_department_id = departmentId;

            const formData = new FormData();

            Object.keys(values).forEach((key: string, index: number) => {
                formData.append(key, values[key]);
            });

            academicStaffRecordEntry(formData);
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    const handleFormReset = () => {
        if (listOfPublicationsFileReference && listOfPublicationsFileReference?.current?.value) {
            listOfPublicationsFileReference.current.value = '';
        }

        setListOfPublicationFile('');
        resetForm({
            values: {
                staff_info_id: academicStaffToEdit?.id ?? '',
                form_b_department_id: '',
                bmdc_reg_no: '',
                name: '',
                designation: '',
                job_type: '',
                total_number: 0,
                no_of_full_time: 0,
                no_of_part_time: 0,
                qualification: '',
                bmdc_reg_validity: '',
                teaching_experience: 0,
                list_of_publications: '',
            }
        });
    };

    useEffect(() => {
        if (academicStaffToEdit?.id) {
            setValues({
                staff_info_id: academicStaffToEdit?.id,
                form_b_department_id: academicStaffToEdit?.form_b_department_id,
                bmdc_reg_no: academicStaffToEdit?.bmdc_reg_no,
                name: academicStaffToEdit?.name,
                designation: academicStaffToEdit?.designation,
                job_type: academicStaffToEdit?.job_type,
                total_number: academicStaffToEdit?.total_number,
                no_of_full_time: academicStaffToEdit?.no_of_full_time,
                no_of_part_time: academicStaffToEdit?.no_of_part_time,
                qualification: academicStaffToEdit?.qualification,
                bmdc_reg_validity: academicStaffToEdit?.bmdc_reg_validity,
                teaching_experience: +academicStaffToEdit?.teaching_experience!,
                list_of_publications: '',
            });

            setListOfPublicationFile(academicStaffToEdit?.list_of_publication);
        }
    }, []);

    useEffect(() => {
        if (isAcademicStaffRecordEntryDataSuccess) {
            handleFormReset();
            toast.success('Academic staff record saved successfully');
            setModalShow(false);
        }
        if (isAcademicStaffRecordEntryDataError) {
            Object.entries(err?.data).forEach((entry: any) => {
                entry[1]?.forEach((message: any) => {
                    toast.error(message);
                });
            });

            setFromErrors(err.data, setErrors);
        }
    }, [isAcademicStaffRecordEntryDataSuccess, isAcademicStaffRecordEntryDataError]);

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title">
                                    <i className="fa fa-plus me-2" />
                                    Academic staff of the department
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    autoComplete="off"
                                    className="custom-form"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    onReset={(e) => {
                                        e.preventDefault();
                                        handleFormReset();
                                    }}
                                >
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="name">Name</Label>
                                                <InputField
                                                    type="text"
                                                    placeholder=""
                                                    {...getFieldProps('name')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="designation">Designation</Label>
                                                <InputField
                                                    type="select"
                                                    options={[
                                                        { label: 'Professor', value: 'Professor' },
                                                        { label: 'Associate Professor', value: 'Associate Professor' },
                                                        { label: 'Assistant Professor', value: 'Assistant Professor' },
                                                        { label: 'Sr. Consultant', value: 'Sr. Consultant' },
                                                        { label: 'Jr. Consultant', value: 'Jr. Consultant' },
                                                    ]}
                                                    placeholder=""
                                                    {...getFieldProps('designation')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="job_type">Full/part time</Label>
                                                <InputField
                                                    type="select"
                                                    options={[{ label: 'Full time', value: 'Full Time' }, { label: 'Part time', value: 'Part Time' }]}
                                                    placeholder=""
                                                    {...getFieldProps('job_type')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="qualification">Qualification</Label>
                                                <InputField
                                                    type="text"
                                                    placeholder=""
                                                    {...getFieldProps('qualification')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3 mt-4">
                                                <Label for="bmdc_reg_no">BMDC Reg. No. with validity</Label>
                                                <FormGroup row>
                                                    <Col sm={6} className="me-0 pe-0">
                                                        <InputField
                                                            type="text"
                                                            placeholder=""
                                                            {...getFieldProps('bmdc_reg_no')}
                                                        />
                                                    </Col>
                                                    <Col sm={6} className="ms-0 ps-0">
                                                        <InputField
                                                            type="date"
                                                            placeholder=""
                                                            {...getFieldProps('bmdc_reg_validity')}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3 mt-1">
                                                <Label for="teaching_experience">Teaching experience in the rank of Asst. Prof. and above (in years)</Label>
                                                <InputField
                                                    type="number"
                                                    placeholder=""
                                                    {...getFieldProps('teaching_experience')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="listOfPublications">Essential equipments (provide list)</Label>
                                                {typeof listOfPublicationFile === 'string' && listOfPublicationFile !== '' ? (
                                                    <a
                                                        className="me-2 p-0 center"
                                                        href={`${config?.APP_URL}${listOfPublicationFile}`}
                                                        rel="noreferrer"
                                                        target="_blank"
                                                    >
                                                        <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                                    </a>
                                                ) : (
                                                    typeof listOfPublicationFile !== 'string' && listOfPublicationFile &&
                                                    <a
                                                        className="me-2 p-0 center"
                                                        href={URL.createObjectURL(listOfPublicationFile)}
                                                        rel="noreferrer"
                                                        target="_blank"
                                                    >
                                                        <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                                    </a>
                                                )}
                                                <input
                                                    type="file"
                                                    ref={listOfPublicationsFileReference}
                                                    name="listOfPublications"
                                                    id="listOfPublications"
                                                    className="form-control"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        setFieldValue('list_of_publications', e.target.files![0] ?? '');
                                                        setListOfPublicationFile(e.target.files![0] ?? '');
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} className="mt-3">
                                            <FormGroup className="mb-3 text-center">
                                                <Button
                                                    type="reset"
                                                    text="Reset"
                                                    className="btn btn-warning me-2"
                                                ></Button>
                                                <Button
                                                    type="submit"
                                                    text="Save"
                                                    className="btn btn-success me-2"
                                                    disabled={isAcademicStaffRecordEntryDataLoading}
                                                ></Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default AcademicStaffRecordsModal;