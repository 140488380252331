import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import { useDeleteLogbookEssentialInfoMutation, useGetLogbookEssentialInfosQuery } from 'src/rtk/features/setup/logBookEssentialInfoSetup/logBookEssentialInfoSetupApi';
import { useGetLogBookMasterSetupsQuery } from 'src/rtk/features/setup/logBookMasterSetup/logBookMasterSetupApi';
import DetailsInfoViewModal from './DetailsInfoViewModal';

interface Props {
  logbookEssentialInfo: LogbookEssentialInfo;
  index: number;
}

const LogbookEssentialInfoTableRow = ({ logbookEssentialInfo, index }: Props) => {
  const history = useHistory();
  const [deleteLogbookEssentialInfo, { isSuccess, isLoading, isError }] =
    useDeleteLogbookEssentialInfoMutation();

  const [ modalShow, setModalShow ] = useState<boolean>(false);
  const [ logbookEssentialInfoId, setLogbookEssentialInfoId ] = useState<string>('');

  const { data: logbookMastersData } = useGetLogBookMasterSetupsQuery();
  const logbookMasters = logbookMastersData?.data || [];

  const { data: logbookEssentialInfosData } = useGetLogbookEssentialInfosQuery();
  const logbookEssentialInfos = logbookEssentialInfosData?.data || [];

  const deleteHandler = (id: string) => {
    const confirm = window.confirm('Are you sure you want to delete?');
    if (confirm) deleteLogbookEssentialInfo(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Logbook essential info deleted successfully');
    }
    if (isError) {
      toast.error('Error deleting logbook essential info');
    }
  }, [isSuccess, isError]);

  const handleViewButtonClick = (id: string) => {
    setModalShow(true);
    setLogbookEssentialInfoId(id);
  };

  return (
    <>
      <tr key={logbookEssentialInfo.id}>
        <th scope="row">{index + 1}</th>      
        <td>
          {
            logbookMasters?.find((l: LogBookSetupMasters) => l.id === logbookEssentialInfo.setup_logbook_master_id)
              ?.logbook_heading
          }
        </td>
        <td>{logbookEssentialInfo.name}</td>
        <td>{logbookEssentialInfo.description}</td>
        <td>
          {
            logbookEssentialInfos?.find((l: LogbookEssentialInfo) => l.id === logbookEssentialInfo.setup_logbook_essential_info_id)
              ?.name
          }
        </td>      
        <td>{logbookEssentialInfo.sl_no}</td>
        <td>{logbookEssentialInfo.status ? 'Active' : 'Inactive'}</td>       
        <td>
          <div className="d-flex gap-2">
            <Button
              size="sm"
              text="Edit"
              color="warning"
              onClick={() =>
                history.push(`/setup/logbook-essential-info/update/${logbookEssentialInfo.id}`)
              }
            />

            <Button
              size="sm"
              text="Delete"
              color="danger"
              disabled={isLoading}
              isLoading={isLoading}
              onClick={() => deleteHandler(logbookEssentialInfo.id!)}
            />

            <Button
              size="sm"
              text="View Details"
              color="info"
              onClick={() =>
                handleViewButtonClick(logbookEssentialInfo.id!)
              }
            />
          </div>
        </td>
      </tr>
      {
        modalShow ? (
          <DetailsInfoViewModal
              isOpen={modalShow}
              toggle={() => setModalShow(!modalShow)}
              id={logbookEssentialInfoId}
          /> 
        ) : null
      }
    </>
  );
};

export default LogbookEssentialInfoTableRow;
