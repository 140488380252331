import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import * as url from 'src/helpers/url_helper';
import {
  useCreateByPostMutation,
  useGetDataQuery,
} from 'src/rtk/features/common/crudApi';
import formObj from './formObj';
// import { useUpdateRoleMutation } from 'src/rtk/features/roles/roleApi';

const getPermissionData = (menusData = []) => {
  let retData: any = [];
  menusData.map((item: any) => {
    if (item.name.toLowerCase() !== 'dashboard')
      retData.push({
        label: `${item.name} (${item.permission})`,
        value: `${item.name.toLowerCase()} (${item.permission.toLowerCase()})`,
        permission: item.permission,
      });
    // if (item.sub_menus.length > 0) {
    //   item.sub_menus.map((subItem: any) => {
    //     retData.push({
    //       label: subItem.name,
    //       value: subItem.name.toLowerCase(),
    //     });
    //   });
    // }
  });

  retData = retData.filter(Boolean).sort(function (a: any, b: any) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });
  return retData;
};

const AssignPermission = () => {
  const { id: userId } = useParams<{ id: string }>();
  const [selectedPermission, setSelectedPermission] = useState<any>([]);
  const [searchList, setSearchList] = useState<any>([]);
  const [search, setSearch] = useState<any>([]);
  // const [updateRole, { isSuccess, isError, error, isLoading }] =
  //   useUpdateRoleMutation();

  const [updateRole, { isSuccess, isError, error, isLoading }] =
    useCreateByPostMutation();
  const err: CustomError = error as CustomError;

  useEffect(() => {
    if (isSuccess) {
      toast.success('Permissions assign successfully');
      history.push(formObj.listUrl);
    }
    if (isError) {
      if (err.data?.message) {
        toast.error(err.data?.message || 'Something went wrong');
      } else {
        toast.error(err?.message || 'Something went wrong');
      }
    }
  }, [isSuccess, isError]);

  const history = useHistory();

  const defaultIcon = 'mdi mdi-format-list-bulleted';

  const isFound = (item: any, parentItem: any = null) => {
    let retResult = true;
    if (search.length > 0) {
      let searchText = `${item.name.toLowerCase()} (${item.permission.toLowerCase()})`;

      retResult = search.find((data: any) => {
        return searchText.includes(data);
      });
    }
    return retResult;
  };

  let allPermissions: any = [];

  const {
    data: getAllRolePermissionData,
    isLoading: rolePermissionDataLoading,
    isSuccess: rolePermissionDataListLoadingSuccess,
    error: rolePermissionDataListLoadingError,
  } = useGetDataQuery(`${formObj.getPermissonAPIUrl}${userId}`);
  const rolePermissionData: any = getAllRolePermissionData?.data || [];

  useEffect(() => {
    if (
      rolePermissionData.permissions &&
      rolePermissionData.permissions.length > 0
    ) {
      let oldPermissions = rolePermissionData.permissions.map(
        (permission: any) => permission.name,
      );
      setSelectedPermission(oldPermissions);
    }
  }, [rolePermissionData]);

  const havePermission = (item: any) => {
    if (item && item.permission) {
      let index = selectedPermission.indexOf(item.permission);
      return index === -1 ? false : true;
    }
  };

  const selectPermission = (
    e: any,
    item: any,
    parentItem: any = {},
    grandItem: any = {},
  ) => {
    let isChecked = e.target.checked;
    let permissionArray: any = [];

    if (item?.permission) {
      permissionArray.push(item?.permission);

      item?.actions?.map((actionsubItem: any) => {
        if (actionsubItem?.permission) {
          permissionArray.push(actionsubItem?.permission);
        }
      });

      if (item?.sub_menus?.length > 0) {
        item?.sub_menus?.map((subItem: any) => {
          if (subItem?.permission) {
            permissionArray.push(subItem?.permission);
          }

          subItem?.actions?.map((actionsubItem: any) => {
            if (actionsubItem?.permission) {
              permissionArray.push(actionsubItem?.permission);
            }
          });
        });
      }
    }

    if (isChecked) {
      if (parentItem && parentItem?.permission)
        permissionArray.push(parentItem?.permission);

      if (grandItem && grandItem?.permission)
        permissionArray.push(grandItem?.permission);

      let assignNewPermission: any = [];
      permissionArray.map((permission: any) => {
        if (!havePermission({ permission: permission })) {
          assignNewPermission.push(permission);
        }
      });
      const newSelectedPermission =
        selectedPermission.concat(assignNewPermission);
      setSelectedPermission(newSelectedPermission);
    } else {
      let assignNewPermission: any = [];
      assignNewPermission = selectedPermission.filter((permission: any) => {
        return permissionArray.indexOf(permission) < 0;
      });
      setSelectedPermission(assignNewPermission);
    }

    /*

    let permissionName = e.target.dataset.permission;

    let newSelectedPermission: any = selectedPermission;

    if (permissionName) {
      var index = newSelectedPermission.indexOf(permissionName);

      if (e.target.checked) {
        if (index === -1) {
          newSelectedPermission.push(permissionName);
        }
      } else {
        if (index !== -1) {
          newSelectedPermission.splice(index, 1);
        }
      }
      setSelectedPermission(newSelectedPermission);
    }
    */
  };

  const generateQueryUrl = () => {
    return `${url.GET_ALL_MENUS}`;
  };

  const {
    data: getAllMenusData,
    isLoading: dataListLoading,
    isSuccess: dataListLoadingSuccess,
    error: dataListLoadingError,
  } = useGetDataQuery(generateQueryUrl());
  const menusData: any = getAllMenusData?.data || [];

  useEffect(() => {
    if (menusData.length > 0) {
      setSearchList(getPermissionData(menusData));
    }
  }, [menusData]);

  // console.log(menusData);

  useEffect(() => {
    if (dataListLoadingError) {
      toast.dismiss();
      toast.error('Data Loading error. Please try again later.');
    } else if (dataListLoading || !dataListLoadingSuccess) {
      toast.dismiss();
      // toast.loading('Data Loading...');
    } else {
      toast.dismiss();
      // toast.success('Data Load Successfully');
    }
  }, [dataListLoading, dataListLoadingSuccess, dataListLoadingError]);

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Permissions assign for User'}
          breadcrumbItem={[
            {
              link: formObj?.listUrl || '',
              name: 'User List',
            },
          ]}
        />
        <Card>
          <CardHeader className="row">
            <div className="col btn-group">
              <button
                className="btn  btn-success"
                disabled={isLoading}
                onClick={() => {
                  let postData = {
                    queryUrl: formObj.setPermissonAPIUrl,
                    formData: {
                      user: userId,
                      permissions: selectedPermission.filter(
                        (permission: any) => permission !== '',
                      ),
                    },
                  };
                  updateRole(postData);
                }}
              >
                {isLoading ? 'Updating..' : 'Update'}
              </button>

              <button
                className="btn  btn-primary"
                onClick={() => {
                  setSelectedPermission(allPermissions);
                }}
              >
                Select All
              </button>

              <button
                className="btn  btn-danger"
                onClick={() => {
                  setSelectedPermission([]);
                }}
              >
                Clear All
              </button>
              {Object.keys(selectedPermission).length > 0 && (
                <>
                  <button
                    className="btn  btn-dark"
                    onClick={() => {
                      let selectSearch: any = [];
                      selectSearch = searchList.map((sItem: any) => {
                        if (havePermission(sItem)) {
                          return sItem.value;
                        }
                      });
                      selectSearch = selectSearch.filter(Boolean);
                      setSearch(selectSearch);
                    }}
                  >
                    Show Only Selected
                  </button>
                  <button
                    className="btn  btn-info"
                    onClick={() => {
                      setSearch([]);
                    }}
                  >
                    Show All
                  </button>
                </>
              )}
            </div>
            <div className="col col-md-8">
              <Select
                name="search"
                placeholder="Search"
                isMulti={true}
                isClearable={true}
                isSearchable={true}
                options={searchList}
                // value={search}
                defaultValue={search}
                onChange={(item: any) => {
                  let selectedData: any = [];
                  item.map((data: any) => {
                    if (data.value) {
                      selectedData.push(data.value);
                    }
                  });
                  setSearch(selectedData);
                }}
              />
            </div>
          </CardHeader>
          <CardBody>
            {/* {menusData.length > 0 && (
              <MultiSelectCheckboxControlled
                userId={userId}
                treedata={menusData}
              />
            )} */}

            <div
              className="checkbox border border-1 mt-3 shadow"
              style={{ maxHeight: '500px', overflow: 'auto' }}
            >
              <ul className="list-group ">
                {/* list-group-numbered menuItem.type.toLowerCase() === 'main-menu' && */}
                {menusData.length > 0 &&
                  menusData
                    .filter(Boolean)
                    .sort(function (a: any, b: any) {
                      if (a.name < b.name) {
                        return -1;
                      }
                      if (a.name > b.name) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((menuItem: any) => {
                      if (
                        menuItem.name !== 'Dashboard' &&
                        menuItem.name !== '' &&
                        isFound(menuItem)
                      ) {
                        allPermissions.push(menuItem.permission);
                        let displayClass = '';
                        if (havePermission(menuItem) === false) {
                          displayClass = ' d-none ';
                        }
                        return (
                          <li
                            className="list-group-item align-items-start"
                            key={menuItem.id}
                          >
                            <div className="form-check form-switch " dir="ltr">
                              <input
                                key={Math.random()}
                                type="checkbox"
                                className="form-check-input"
                                id={'permissionSwitch-' + menuItem.id}
                                data-permission={menuItem.permission}
                                defaultChecked={havePermission(menuItem)}
                                onChange={(e: any, item: any = menuItem) => {
                                  selectPermission(e, item);
                                }}
                              />
                              <label
                                htmlFor={'permissionSwitch-' + menuItem.id}
                                className="text-truncate"
                                role="button"
                              >
                                {menuItem.name}
                                <small className="text-muted font-size-9 ms-1">
                                  ({menuItem.permission})
                                </small>
                              </label>
                            </div>

                            <ul
                              className={
                                'action-menu list-group ' + displayClass
                              }
                            >
                              {menuItem.actions
                                .filter(Boolean)
                                .sort(function (a: any, b: any) {
                                  if (a.name < b.name) {
                                    return -1;
                                  }
                                  if (a.name > b.name) {
                                    return 1;
                                  }
                                  return 0;
                                })
                                .map((actionsMenuItem: any) => {
                                  if (
                                    actionsMenuItem.name &&
                                    actionsMenuItem.type.toLowerCase() ===
                                      'action'
                                    //   &&
                                    // actionsMenuItem.name
                                    //   .toLowerCase()
                                    //   .includes(search)
                                  ) {
                                    allPermissions.push(
                                      actionsMenuItem.permission,
                                    );

                                    return (
                                      <li
                                        className="list-group-item"
                                        key={actionsMenuItem.id}
                                      >
                                        <div
                                          className="form-check form-switch "
                                          dir="ltr"
                                        >
                                          <input
                                            key={Math.random()}
                                            type="checkbox"
                                            className="form-check-input"
                                            id={
                                              'permissionSwitch-' +
                                              actionsMenuItem.id
                                            }
                                            data-permission={
                                              actionsMenuItem.permission
                                            }
                                            defaultChecked={havePermission(
                                              actionsMenuItem,
                                            )}
                                            onChange={(
                                              e: any,
                                              item: any = actionsMenuItem,
                                              parentItem: any = menuItem,
                                            ) => {
                                              selectPermission(
                                                e,
                                                item,
                                                parentItem,
                                              );
                                            }}
                                          />
                                          <label
                                            htmlFor={
                                              'permissionSwitch-' +
                                              actionsMenuItem.id
                                            }
                                            className="text-truncate"
                                            role="button"
                                          >
                                            {actionsMenuItem.name}
                                            <small className="text-muted font-size-9 ms-1">
                                              ({actionsMenuItem.permission})
                                            </small>
                                          </label>
                                        </div>
                                      </li>
                                    );
                                  }
                                })}
                            </ul>
                            <ul
                              className={'sub-menu list-group ' + displayClass}
                            >
                              {menuItem.sub_menus
                                .filter(Boolean)
                                .sort(function (a: any, b: any) {
                                  if (a.name < b.name) {
                                    return -1;
                                  }
                                  if (a.name > b.name) {
                                    return 1;
                                  }
                                  return 0;
                                })
                                .map((subMenuItem: any) => {
                                  if (
                                    subMenuItem.name &&
                                    subMenuItem.name !== '' &&
                                    subMenuItem.type.toLowerCase() ===
                                      'sub-menu'
                                    //  &&
                                    // isFound(subMenuItem, menuItem)
                                  ) {
                                    allPermissions.push(subMenuItem.permission);
                                    let displaySubClass = '';
                                    if (havePermission(subMenuItem) === false) {
                                      displaySubClass = ' d-none ';
                                    }
                                    return (
                                      <li
                                        className="list-group-item align-items-start"
                                        key={subMenuItem.id}
                                      >
                                        <div
                                          className="form-check form-switch "
                                          dir="ltr"
                                        >
                                          <input
                                            key={Math.random()}
                                            type="checkbox"
                                            className="form-check-input"
                                            id={
                                              'permissionSwitch-' +
                                              subMenuItem.id
                                            }
                                            data-permission={
                                              subMenuItem.permission
                                            }
                                            defaultChecked={havePermission(
                                              subMenuItem,
                                            )}
                                            onChange={(
                                              e: any,
                                              item: any = subMenuItem,
                                            ) => {
                                              selectPermission(
                                                e,
                                                item,
                                                menuItem,
                                              );
                                            }}
                                          />
                                          <label
                                            htmlFor={
                                              'permissionSwitch-' +
                                              subMenuItem.id
                                            }
                                            className="text-truncate"
                                            role="button"
                                          >
                                            {subMenuItem.name}{' '}
                                            <small className="text-muted font-size-9 ms-1">
                                              ({subMenuItem.permission})
                                            </small>
                                          </label>
                                        </div>

                                        <ul
                                          className={
                                            'action-menu list-group ' +
                                            displaySubClass
                                          }
                                        >
                                          {subMenuItem.actions
                                            .filter(Boolean)
                                            .sort(function (a: any, b: any) {
                                              if (a.name < b.name) {
                                                return -1;
                                              }
                                              if (a.name > b.name) {
                                                return 1;
                                              }
                                              return 0;
                                            })
                                            .map((actionsMenuItem: any) => {
                                              if (
                                                actionsMenuItem.name &&
                                                actionsMenuItem.type.toLowerCase() ===
                                                  'action'
                                                //   &&
                                                // actionsMenuItem.name
                                                //   .toLowerCase()
                                                //   .includes(search)
                                              ) {
                                                allPermissions.push(
                                                  actionsMenuItem.permission,
                                                );
                                                return (
                                                  <li
                                                    className="list-group-item"
                                                    key={actionsMenuItem.id}
                                                  >
                                                    <div
                                                      className="form-check form-switch "
                                                      dir="ltr"
                                                    >
                                                      <input
                                                        key={Math.random()}
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={
                                                          'permissionSwitch-' +
                                                          actionsMenuItem.id
                                                        }
                                                        data-permission={
                                                          actionsMenuItem.permission
                                                        }
                                                        defaultChecked={havePermission(
                                                          actionsMenuItem,
                                                        )}
                                                        onChange={(
                                                          e: any,
                                                          item: any = actionsMenuItem,
                                                          parentItem: any = subMenuItem,
                                                          grandItem: any = menuItem,
                                                        ) => {
                                                          selectPermission(
                                                            e,
                                                            item,
                                                            parentItem,
                                                            grandItem,
                                                          );
                                                        }}
                                                      />
                                                      <label
                                                        htmlFor={
                                                          'permissionSwitch-' +
                                                          actionsMenuItem.id
                                                        }
                                                        className="text-truncate"
                                                        role="button"
                                                      >
                                                        {actionsMenuItem.name}
                                                        <small className="text-muted font-size-9 ms-1">
                                                          (
                                                          {
                                                            actionsMenuItem.permission
                                                          }
                                                          )
                                                        </small>
                                                      </label>
                                                    </div>
                                                  </li>
                                                );
                                              }
                                            })}
                                        </ul>
                                      </li>
                                    );
                                  }
                                })}
                            </ul>
                          </li>
                        );
                      }
                    })}
              </ul>
            </div>
            <div className="my-3">
              <span className="me-2">
                Selected: {Object.keys(selectedPermission).length}
              </span>
              <span className="me-2">
                Found: {Object.keys(allPermissions).length}
              </span>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default AssignPermission;
