import { apiSlice } from 'src/rtk/api/apiSlice';
import * as trainingWorkshopUrl from 'src/helpers/url_helper';

export const trainingWorkshopApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['TrainingWorkshops', 'TraineeFcpsCourseList'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getFcpsCourseListsForTrainingWorkshop: builder.mutation<
        APIResponse<FcpsCourseList[]>,
        {courseType: string; subject_id: string;}
      >({
        query: ({courseType, subject_id}) => ({
          url: `${trainingWorkshopUrl.GET_FCPS_COURSE_LISTS_FOR_TRAINING_WORKSHOP}`,
          method: 'POST',
          body: {type: courseType, subject_id: subject_id},
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['TraineeFcpsCourseList'],
      }),

      getTrainings: builder.query<APIResponseWithPagination<ExamInfo>, {workshop_training: string; main_training_id: string}>({
        query: (data) => ({
          method: 'GET',
          url: `${trainingWorkshopUrl.GET_TRAININGS}?workshop_training=${data.workshop_training}&main_training_id=${data.main_training_id}`,
        }),

        providesTags: ['TrainingWorkshops'],
      }), 

      getAllTrainings: builder.query<APIResponseWithPagination<ExamInfo>, {workshop_training: string;}>({
        query: (data) => ({
          method: 'GET',
          url: `${trainingWorkshopUrl.GET_ALL_TRAININGS}?workshop_training=${data.workshop_training}`,
        }),

        providesTags: ['TrainingWorkshops'],
      }),

      getTrainingNameList: builder.query<APIResponse<ExamInfo[]>, {workshop_training: string; page: string}>({
        query: (data) => ({
          method: 'GET',
          url: `${trainingWorkshopUrl.GET_TRAINING_NAME_LIST}?workshop_training=${data.workshop_training}&page=${data.page}`,
        }),

        providesTags: ['TrainingWorkshops'],
      }), 
      
      getTrainingWorkshopListByPaginate: builder.query<
        APIResponseWithPagination<TrainingWorkshopListEntry>,
        PaginateForLogbookEntries
      >({
        query: (paginate) => {
          let url = `${trainingWorkshopUrl.GET_TRAINING_WORKSHOP_LIST_BY_PAGINATE}?paginate=${true}&`;

          if (paginate.page) {
            url += `page=${paginate.page}&`;
          }
          if (paginate.paginate_per_page) {
            url += `paginate_per_page=${paginate.paginate_per_page}`;
          }
          if (paginate.search) {
            url += `${paginate.search}`;
          }

          return {
            method: 'GET',
            url: url,
          };
        },

        providesTags: ['TrainingWorkshops'],
      }), 

      createTrainingWorkshop: builder.mutation<APIResponse<any>, TrainingWorkshopCreate>({
        query: (data) => ({
          url: `${trainingWorkshopUrl.CREATE_TRAINING_WORKSHOP}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['TrainingWorkshops'],
      }),

      updateTrainingWorkshop: builder.mutation<APIResponse<any>, TrainingWorkshopCreate>({
        query: (data) => ({
          url: `${trainingWorkshopUrl.UPDATE_TRAINING_WORKSHOP}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['TrainingWorkshops'],
      }),

    //   deleteExamInfo: builder.mutation({
    //     query: (id: string) => ({
    //       method: 'DELETE',
    //       url: `/setup/setup-exam-training-info/${id}`,
    //     }),

    //     invalidatesTags: ['TrainingWorkshops'],
    //   }),
    }),
  });

export const {
  useGetFcpsCourseListsForTrainingWorkshopMutation,
  useGetAllTrainingsQuery,
  useLazyGetAllTrainingsQuery,
  useGetTrainingNameListQuery,
  useLazyGetTrainingNameListQuery,
  useGetTrainingsQuery,
  useLazyGetTrainingsQuery,
  useCreateTrainingWorkshopMutation,
  useGetTrainingWorkshopListByPaginateQuery,  
  useUpdateTrainingWorkshopMutation,
} = trainingWorkshopApi;
