import { apiSlice } from 'src/rtk/api/apiSlice';
import * as thesisMarksheetUrl from 'src/helpers/url_helper';

export const thesisMarksheetApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['thesisDissertationList',],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getThesisDissertationFileListForAdminByPaginate: builder.query<
        any,
        PaginateForLogbookEntries
      >({
        query: (paginate) => {
          let url = `${thesisMarksheetUrl.GET_THESIS_DISSERTATION_FILE_LIST_FOR_ADMIN_BY_PAGINATE}?category=${'Thesis'}&`;

          if (paginate.page) {
            url += `page=${paginate.page}&`;
          }
          if (paginate.paginate_per_page) {
            url += `paginate_per_page=${paginate.paginate_per_page}`;
          }
          if (paginate.search) {
            url += `${paginate.search}`;
          }

          return {
            method: 'GET',
            url: url,
          };
        },

        providesTags: ['thesisDissertationList'],
      }),

      getNotifyTrainee: builder.query<APIResponse<any>, string>({
        query: (id: any) => ({
          method: 'GET',
          url: `${thesisMarksheetUrl.GET_NOTIFY_TRAINEE}/${id}`,
        }),

        providesTags: ['thesisDissertationList'],
      }),
    }),
  });

export const {
  useGetThesisDissertationFileListForAdminByPaginateQuery,
  useLazyGetNotifyTraineeQuery,
} = thesisMarksheetApi;
