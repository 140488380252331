import { apiSlice } from 'src/rtk/api/apiSlice';
import * as protocolListForApprovalUrl from 'src/helpers/url_helper';

export const protocolListForApprovalApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['ProtocolById'] })
  .injectEndpoints({
    endpoints: (builder) => ({      
      getProtocolById: builder.query<any, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${protocolListForApprovalUrl.GET_PROTOCOL_BY_ID}/${id}`,
        }),
        
        providesTags: ['ProtocolById'],
      }),      
    }),
  });

export const {  
  useGetProtocolByIdQuery,  
} = protocolListForApprovalApi;
