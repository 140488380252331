import { apiSlice } from 'src/rtk/api/apiSlice';
import * as guideCoguideUrl from 'src/helpers/url_helper';

export const guideCoguideApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['guideCoguide', 'GuideCoGuideBankInfo'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getGuideCoGuideList: builder.query<GuideCoGuide[], void>({
        query: () => ({
          method: 'GET',
          url: `${guideCoguideUrl.GET_GUIDE_CO_GUIDE_LIST}`,
        }),

        providesTags: ['guideCoguide'],
      }),

      getGuideCoGuideApprovalList: builder.query<DataWithPagination<GuideCoGuide[]>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${guideCoguideUrl.GET_GUIDE_CO_GUIDE_APPROVAL_LIST}?${url}`,
        }),
        providesTags: ['guideCoguide'],
      }),

      getGuideApplicationApproval: builder.mutation<APIResponse<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${guideCoguideUrl.GET_GUIDE_APPLICATION_APPROVAL}/${url}`,
        }),
        invalidatesTags: ['guideCoguide'],
      }),

      getCoGuideApplicationApproval: builder.mutation<APIResponse<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${guideCoguideUrl.GET_CO_GUIDE_APPLICATION_APPROVAL}/${url}`,
        }),
        invalidatesTags: ['guideCoguide'],
      }),

      createGuideCoGuide: builder.mutation<
        APIResponse<ApplyForGuideCoGuide>,
        any
      >({
        query: (data: any) => ({
          url: `${guideCoguideUrl.CREATE_GUIDE_CO_GUIDE}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['guideCoguide'],
      }),

      updateGuideCoGuide: builder.mutation<
        APIResponse<GuideCoGuide>,
        any
      >({
        query: (url) => ({
          url: `${guideCoguideUrl.UPDATE_GUIDE_CO_GUIDE}/${url}`,
          method: 'PATCH',
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['guideCoguide'],
      }),

      //Guide Co-Guide Bank Info

      createBankInfoEntryForGuideCoGuide: builder.mutation<
        APIResponse<any>,
        any
      >({
        query: (data: any) => ({
          url: `${guideCoguideUrl.CREATE_BANK_INFO_ENTRY_FOR_GUIDE_CO_GUIDE}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['GuideCoGuideBankInfo'],
      }),

      getBankInformationForGuideCoGuide: builder.query<APIResponse<any>, void>({
        query: () => ({
          method: 'GET',
          url: `${guideCoguideUrl.GET_BANK_INFORMATION_FOR_GUIDE_CO_GUIDE}`,
        }),

        providesTags: ['GuideCoGuideBankInfo'],
      }),

      getGuideListForTitleEntry: builder.query<DataWithPagination<any>, void>({
        query: () => ({
          method: 'GET',
          url: `${guideCoguideUrl.GET_GUIDE_COGUIDE_LIST}?type=Guide&`,
        }),
        providesTags: ['guideCoguide'],
      }),

      getCoGuideListForTitleEntry: builder.query<DataWithPagination<any>, void>({
        query: () => ({
          method: 'GET',
          url: `${guideCoguideUrl.GET_GUIDE_COGUIDE_LIST}?type=Co-Guide&`,
        }),
        providesTags: ['guideCoguide'],
      }),

    }),
  });

export const {
  useGetGuideCoGuideListQuery,
  useGetGuideCoGuideApprovalListQuery,
  useGetGuideApplicationApprovalMutation,
  useGetCoGuideApplicationApprovalMutation,
  useCreateGuideCoGuideMutation,
  useUpdateGuideCoGuideMutation,
  useCreateBankInfoEntryForGuideCoGuideMutation,
  useGetBankInformationForGuideCoGuideQuery,
  useGetGuideListForTitleEntryQuery,
  useGetCoGuideListForTitleEntryQuery,
} = guideCoguideApi;
