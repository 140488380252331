import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { Container, FormGroup, Label } from 'reactstrap';
import { Button } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetTrainingSummaryQuery, useGetUserSlotInfoForTraineeQuery } from 'src/rtk/features/logbookTrainingSummary/logbookTrainingSummaryApi';
import { useGetPersonalProfileQuery } from 'src/rtk/features/trainee/traineeApi';
import loader from '../../assets/images/loader.gif';

export const TrainingSummary = () => {
  const { userId, sessionId } = useParams<{ userId: string; sessionId: string }>();
  const [trainingSummaryList, setTrainingSummaryList] = useState<
    LogbookTrainingSummary[]
  >([]);
  const [selectedSlot, setSelectedSlot] = useState<any>('');

  const history = useHistory();

  const {
    data: personalProfile,
    isError: isPersonalProfileError,
    isLoading: isPersonalProfileLoading
  } = useGetPersonalProfileQuery();

  const {
    data: userSlotData,
    isLoading: isUserSlotDataLoading,
    isSuccess: isUserSlotDataSuccess,
    isError: isUserSlotDataError,
    error: userSlotDataError,
  } = useGetUserSlotInfoForTraineeQuery(userId ? userId : (personalProfile?.data as any)?.id!);

  const {
    data: trainingSummaries,
    isLoading: isTrainingSummariesLoading,
    isSuccess: isTrainingSummariesSuccess,
  } = useGetTrainingSummaryQuery({ 
      user_id: userId ? userId : (personalProfile?.data as any)?.id!, 
      session_id: selectedSlot?.value ? selectedSlot?.value : sessionId ? 
      sessionId : userSlotData?.data?.log_entry_current_session?.id! 
    });

  const logEntrySessions: SelectOption[] = userSlotData?.data?.log_entry_session?.map((slot: any, index: any) => {
    return { label: slot?.exam_training_info?.name, value: slot?.id };
  });

  useEffect(() => {
    if (trainingSummaries) {
      const tData = trainingSummaries?.data || [];
      setTrainingSummaryList(tData);
    }
  }, [trainingSummaries]);

  const columns: TableColumn<any>[] = [
    {
      name: 'Sl. No.',
      cell: (row, index) => index + 1,
      width: '80px',
    },

    {
      name: 'Events/Tasks',
      selector: (row) => row?.particulars,
      sortable: false,
      wrap: true,
    },

    {
      name: 'Approved',
      selector: (row) => row?.approve,
      sortable: false,
      center: true,
    },

    {
      name: <div style={{ textAlign: 'center' }}>Correction</div>,
      selector: (row) => row?.correction,
      sortable: false,
      center: true,
    },

    {
      name: <div style={{ textAlign: 'center' }}>Rejected</div>,
      selector: (row) => row?.reject,
      sortable: false,
      center: true,
    },

    {
      name: <div style={{ textAlign: 'center' }}>Approval Pending</div>,
      selector: (row) => row?.pending,
      sortable: false,
      center: true,
    },

    {
      name: 'Draft',
      selector: (row) => row?.draft,
      sortable: false,
      center: true,
    },

    {
      name: 'Total',
      selector: (row) => row?.total,
      sortable: false,
      center: true,
    },

    {
      name: 'Action',
      cell: (row) => (
        <>
          <FormGroup className="btn-group mt-2">
            <Button
              size="sm"
              color="info"
              type="button"
              disabled={row?.entry_setup_master_for_log?.length === 0}
              text={`View`}
              onClick={() => {
                if (!['Procedural and Interpretative Skills', 'Procedure'].includes(row?.particulars)) {
                  history.push(`/trainee/training-summary/${row?.particulars}/${row.id}/${userId ? userId : (personalProfile?.data as any)?.id!}/${selectedSlot?.value ? selectedSlot?.value : sessionId ? sessionId : userSlotData?.data?.log_entry_current_session?.id!}`);
                } else {
                  history.push(`/trainee/summary-of-procedural-and-interpretative-skills/${userId ? userId : (personalProfile?.data as any)?.id!}/${selectedSlot?.value ? selectedSlot?.value : sessionId ? sessionId : userSlotData?.data?.log_entry_current_session?.id!}`);
                }
              }}
            />
          </FormGroup>
        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div className="page-content">
      {isTrainingSummariesLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <div className='page-breadcrumb-wrapper'>
          <BetterBreadcrumb
            title="Training Summary"
            breadcrumbItem={
              [
                { link: '/trainee/logbook-entry', name: 'Logbook Entry' },
                { link: '/trainee/logbook/academic-activities', name: 'Academic Activities Entry' },
              ]
            }
          />
        </div>
        <div className="row align-items-center justify-content-center">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-4">
                <FormGroup className='extent-field'>
                  <Label htmlFor="slot">Select Slot</Label>
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    value={selectedSlot}
                    onChange={setSelectedSlot}
                    options={logEntrySessions}
                    placeholder='Select...'
                    name="slot"
                    id="slot"
                  />
                </FormGroup>
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body">
                <DataTable
                  columns={columns}
                  data={trainingSummaryList}
                  // progressPending={isTrainingSummariesLoading}
                  expandOnRowClicked
                  highlightOnHover
                  responsive
                  persistTableHead
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
