import toast from "react-hot-toast";
import { Col, Modal, Row } from "reactstrap";
import CommentSection from "./CommentSection";

export const CommentSectionModal = (props: any) => {

    const {
        thesisDissertationFile,
        thesisDissertationFormSetupId,
        thesisDissertationFormSetupColumnName,
        showModal,
        setShowModal,
        setMessageCount,
    } = props;

    return (
        <Modal
            onOpened={function () {
                toast.dismiss();
            }}
            isOpen={showModal}
            scrollable={true}
            size="lg"
            toggle={() => {
                document.body.classList.add('no_padding');
            }}
            backdrop={'static'}
            modalTransition={{ timeout: 0 }}
            backdropTransition={{ timeout: 0 }}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                    Comments for {thesisDissertationFormSetupColumnName}
                </h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                        setShowModal(!showModal);
                    }}
                    aria-label="Close"
                ></button>
            </div>
            <div className="modal-body">
                <Row>
                    <Col sm={12}>
                        <CommentSection
                            thesisDissertationFile={thesisDissertationFile}
                            thesisDissertationFormSetupId={thesisDissertationFormSetupId}
                            thesisDissertationFormSetupColumnName={thesisDissertationFormSetupColumnName}
                            setMessageCount={setMessageCount}
                        />
                    </Col>
                </Row>
            </div>
        </Modal>
    )
};