import { apiSlice } from 'src/rtk/api/apiSlice';
import * as lookupInfoUrl from 'src/helpers/url_helper';

export const lookupInfoApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['LookupInfo'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLookupInfos: builder.query<APIResponse<LookupInfo[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${lookupInfoUrl.GET_LOOKUP_INFOS}`,
        }),
        providesTags: ['LookupInfo'],
      }),

      getLookupInfo: builder.query<LookupInfo, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${lookupInfoUrl.GET_LOOKUP_INFO}/${id}`,
        }),
      }),

      createLookupInfo: builder.mutation<APIResponse<LookupInfo>, LookupInfo>({
        query: (data) => ({
          url: `${lookupInfoUrl.CREATE_LOOKUP_INFO}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['LookupInfo'],
      }),

      updateLookupInfo: builder.mutation<APIResponse<LookupChild>, string>({
        query: (url) => ({
          url: `${lookupInfoUrl.UPDATE_LOOKUP_INFO}/${url}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['LookupInfo'],
      }),

      deleteLookupInfo: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${lookupInfoUrl.DELETE_LOOKUP_INFO}/${id}`,
        }),
        invalidatesTags: ['LookupInfo'],
      }),
    }),
  });

export const {
  useGetLookupInfosQuery,
  useGetLookupInfoQuery,
  useCreateLookupInfoMutation,
  useUpdateLookupInfoMutation,
  useDeleteLookupInfoMutation,
} = lookupInfoApi;
