import { apiSlice } from 'src/rtk/api/apiSlice';
import * as logbookMonitoringReportUrl from 'src/helpers/url_helper';

export const logbookMonitoringReportApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['MonitoringReports'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getReportList: builder.query<APIResponse<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${logbookMonitoringReportUrl.GET_REPORT_LIST}?${url}`,
        }),
        providesTags: ['MonitoringReports'],
      }),

      getSuspiciousEntryList: builder.query<APIResponseWithPagination<SuspiciousEntryList>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${logbookMonitoringReportUrl.GET_SUSPICIOUS_ENTRY_LIST}?${url}`,
        }),
        providesTags: ['MonitoringReports'],
      }),

      getSuspiciousEntryDetails: builder.query<APIResponse<any>, any>({
        query: (url) => ({
          method: 'GET',
          url: `${logbookMonitoringReportUrl.GET_SUSPICIOUS_ENTRY_DETAILS}/${url.id}/${url.date}?${url.queryUrl}`,
        }),
        providesTags: ['MonitoringReports'],
      }),

      getSupervisorSuspiciousApprovalList: builder.query<APIResponseWithPagination<SuspiciousEntryList>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${logbookMonitoringReportUrl.GET_SUPERVISOR_SUSPICIOUS_APPROVAL_LIST}?${url}`,
        }),
        providesTags: ['MonitoringReports'],
      }),

      getSupervisorSuspiciousApprovalDetails: builder.query<APIResponse<any>, any>({
        query: (url) => ({
          method: 'GET',
          url: `${logbookMonitoringReportUrl.GET_SUPERVISOR_SUSPICIOUS_APPROVAL_DETAILS}/${url.id}/${url.date}?${url.queryUrl}`,
        }),
        providesTags: ['MonitoringReports'],
      }),

      getSixMonthlyReportData: builder.query<APIResponse<any>, string>({
        query: (id) => ({
          method: 'GET',
          url: `${logbookMonitoringReportUrl.GET_SIX_MONTHLY_REPORT_DATA}/${id}`,
        }),
        providesTags: ['MonitoringReports'],
      }),

      getProgressReportData: builder.query<APIResponse<any>, string>({
        query: (id) => ({
          method: 'GET',
          url: `${logbookMonitoringReportUrl.GET_PROGRESS_REPORT_DATA}/${id}`,
        }),
        providesTags: ['MonitoringReports'],
      }),

      createProgressReportEntry: builder.mutation<APIResponse<any>, any>({
        query: (data: any) => ({
          url: `${logbookMonitoringReportUrl.CREATE_PROGRESS_REPORT_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['MonitoringReports'],
      }),
    }),
  });

export const {
  useGetReportListQuery,
  useGetSuspiciousEntryListQuery,
  useGetSuspiciousEntryDetailsQuery,
  useGetSupervisorSuspiciousApprovalListQuery,
  useGetSupervisorSuspiciousApprovalDetailsQuery,
  useLazyGetSixMonthlyReportDataQuery,
  useLazyGetProgressReportDataQuery,
  useGetProgressReportDataQuery,
  useCreateProgressReportEntryMutation,
} = logbookMonitoringReportApi;
