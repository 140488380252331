import { apiSlice } from 'src/rtk/api/apiSlice';
import * as traineeDashboardUrl from 'src/helpers/url_helper';

export const traineeDashboardApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['TraineeDashboard', 'SupervisorDashboard'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTraineeDashboardInfo: builder.query<APIResponse<any>, void>({
        query: () => ({
          method: 'GET',
          url: `${traineeDashboardUrl.GET_TRAINEE_DASHBOARD_INFO}`,
        }),

        providesTags: ['TraineeDashboard'],
      }), 

      getSupervisorDashboardInfo: builder.query<APIResponse<any>, void>({
        query: () => ({
          method: 'GET',
          url: `${traineeDashboardUrl.GET_SUPERVISOR_DASHBOARD_INFO}`,
        }),

        providesTags: ['SupervisorDashboard'],
      }), 
    }),
  });

export const {
  useGetTraineeDashboardInfoQuery,
  useGetSupervisorDashboardInfoQuery,
} = traineeDashboardApi;
