import React, { useEffect } from 'react';
// import { Button } from 'src/components';
import { Col, Row } from 'reactstrap';
// import { flattenObject } from 'src/helpers';
import toast from 'react-hot-toast';
import { useGetDataQuery } from 'src/rtk/features/common/crudApi';
const DynamicView = (props: any) => {
  let { viewColumn = [], viewData = {}, apiUrl = '', header = '' } = props;
  if (apiUrl) {
    const {
      data: dataList,
      isLoading: dataListLoading,
      isSuccess: dataListLoadingSuccess,
      error: dataListLoadingError,
    } = useGetDataQuery(`${apiUrl}`);
    viewData = dataList?.data || [];
    useEffect(() => {
      if (dataListLoadingError) {
        toast.dismiss();
        toast.error('Data Loading error. Please try again later.');
      } else if (dataListLoading || !dataListLoadingSuccess) {
        toast.dismiss();
        // toast.loading('Data Loading...');
      } else {
        toast.dismiss();
        // toast.success('Data Load Successfully');
      }
    }, [dataListLoading, dataListLoadingSuccess, dataListLoadingError]);
  }

  return (
    <>
      <Row>
        <Col>
          <table className="table table-bordered table-striped table-hover table-sm">
            {header && <thead><tr><th className='text-center' colSpan={2}>{header}</th></tr></thead>}
            <tbody>
              {Object.keys(viewColumn).map((vcData: any, key: any) => {
                let itemData = viewColumn[vcData];
                let viewCellData =
                  itemData?.viewCell && typeof itemData?.viewCell === 'function'
                    ? itemData.viewCell(viewData)
                    : viewData?.[itemData?.viewCell];

                return (
                  <React.Fragment key={Math.random()}>
                    <tr className="text-capitalize" >
                      <th>{itemData?.name}</th>
                      <td>
                        {typeof viewCellData === 'string' ? (
                          <div
                            dangerouslySetInnerHTML={{ __html: viewCellData }}
                          ></div>
                        ) : (
                          viewCellData
                        )}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </Col>
      </Row>
    </>
  );
};

export default DynamicView;
