import { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useGetUsersByPermissionQuery } from 'src/rtk/features/user/userSelectByPermissionApi';

interface UserSelectAsyncProps {
  name: string;
  classNames?: string;
  placeholder?: string;
  approvalPermissionId: string;
  setValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => any;
}

export const UserSelectAsyncByPermission = (props: UserSelectAsyncProps) => {
  const {
    name,
    classNames,
    placeholder,
    approvalPermissionId,
    setValue,
    ...rest
  } = props;

  const [search, setSearch] = useState('');

  const {
    data: users,
    isFetching,
    isLoading,
    isSuccess,
  } = useGetUsersByPermissionQuery(approvalPermissionId);

  const userList = users?.data as User[];

  let allowCall = true;
  let counter = setTimeout(() => {
    allowCall = true;
  }, 300);

  const controlDelay = (inputValue: string) => {
    if (allowCall) {
      setSearch(inputValue);
      allowCall = false;
      clearTimeout(counter);
    }
  };

  const promiseOptions = (inputValue: string) =>
    new Promise<SelectOption[]>((resolve) => {
      resolve(getUsers(inputValue));
    });

  const getUsers = (inputValue: string) => {
    controlDelay(inputValue);

    return userList?.map((user) => ({
      value: user.id!,
      label: user.name,
    }));
  };

  const changeSelectedOption = (e: any) => {
    setValue(name, e.value);
  };
  return (
    <AsyncSelect
      loadOptions={promiseOptions}
      isLoading={isLoading || isFetching}
      {...rest}
      placeholder={placeholder}
      onChange={changeSelectedOption}
    />
  );
};
