import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { Button, InputField } from "src/components";
import { config } from "src/config";
import { setFromErrors } from "src/helpers";
import { useRegisterDepartmentStepTwoMutation } from "src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi";
import * as Yup from 'yup';

interface IpdRecordProps {
    departmentId?: string;
    ipdRecordToEdit?: IpdOpdRecordList;
    setModalShow?: any;
}

const initialValues: IpdOpdRecordEntry = {
    ipd_opd_record_id: '',
    form_b_department_id: '',
    record_type: '',
    ipd_period_from: '',
    ipd_period_to: '',
    ipd_disease_profile: '',
    ipd_number_of_paying_beds: 0,
    ipd_number_of_nonpaying_beds: 0,
    ipd_number_of_cabins: 0,
    ipd_number_of_admitted_patient_paying_beds: 0,
    ipd_number_of_admitted_patient_nonpaying_beds: 0,
    ipd_number_of_admitted_patient_cabins: 0,
};

const InPatientRecordsOfTheDeptModal = (props: IpdRecordProps) => {
    const { departmentId, ipdRecordToEdit, setModalShow } = props;

    const diseaseProfileOfInPatientFileReference = useRef<any>();
    const [diseaseProfileFile, setDiseaseProfileFile] = useState<any>('');

    const [registerDepartmentStepTwo, { data: ipdOpdData, isSuccess: isIpdOpdDataSuccess, isLoading: isIpdOpdDataLoading, isError: isIpdOpdDataError, error: ipdOpdDataError }] =
        useRegisterDepartmentStepTwoMutation();

    const err: CustomError = ipdOpdDataError as CustomError;

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({}),

        onSubmit: (values: any) => {
            console.log(values);
            values.record_type = 'ipd';
            values.form_b_department_id = departmentId;
            const formData = new FormData();

            Object.keys(values).forEach((key: string) => {
                formData.append(key, values[key]);
            });

            registerDepartmentStepTwo(formData);
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    const handleFormReset = () => {
        diseaseProfileOfInPatientFileReference.current.value = '';
        setDiseaseProfileFile('');
        resetForm();
    };

    useEffect(() => {
        if (ipdRecordToEdit?.id) {
            setValues({
                ipd_opd_record_id: ipdRecordToEdit?.id,
                form_b_department_id: ipdRecordToEdit?.form_b_department_id,
                record_type: ipdRecordToEdit?.record_type,
                ipd_period_from: ipdRecordToEdit?.ipd_period_from,
                ipd_period_to: ipdRecordToEdit?.ipd_period_to,
                ipd_disease_profile: '',
                ipd_number_of_paying_beds: ipdRecordToEdit?.ipd_number_of_paying_beds,
                ipd_number_of_nonpaying_beds: ipdRecordToEdit?.ipd_number_of_nonpaying_beds,
                ipd_number_of_cabins: ipdRecordToEdit?.ipd_number_of_cabins,
                ipd_number_of_admitted_patient_paying_beds: ipdRecordToEdit?.ipd_number_of_admitted_patient_paying_beds,
                ipd_number_of_admitted_patient_nonpaying_beds: ipdRecordToEdit?.ipd_number_of_admitted_patient_nonpaying_beds,
                ipd_number_of_admitted_patient_cabins: ipdRecordToEdit?.ipd_number_of_admitted_patient_cabins,
            });
            setDiseaseProfileFile(ipdRecordToEdit?.ipd_disease_profile);            
        }
    }, []);

    useEffect(() => {
        if (isIpdOpdDataSuccess) {
            handleFormReset();
            toast.success('In patient record saved successfully');
            setModalShow(false);
        }
        if (isIpdOpdDataError) {
            Object.entries(err?.data).forEach((entry: any) => {
                entry[1]?.forEach((message: any) => {
                    toast.error(message);
                });
            });
            setFromErrors(err.data, setErrors);
        }
    }, [isIpdOpdDataSuccess, isIpdOpdDataError]);

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title">
                                    <i className="fa fa-plus me-2" />
                                    In-patient record of the department
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    autoComplete="off"
                                    className="custom-form"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    onReset={(e) => {
                                        e.preventDefault();
                                        handleFormReset();
                                    }}
                                >
                                    <Row>
                                        <Col sm={12} md={3}>
                                            <FormGroup className="mb-3">
                                                <Label for="ipd_period_from">From</Label>
                                                <InputField
                                                    type="date"
                                                    placeholder=""
                                                    {...getFieldProps('ipd_period_from')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={3}>
                                            <FormGroup className="mb-3">
                                                <Label for="ipd_period_to">To</Label>
                                                <InputField
                                                    type="date"
                                                    placeholder=""
                                                    {...getFieldProps('ipd_period_to')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="diseaseProfile">Disease profile of in-patients in the department (last one year)</Label>
                                                {typeof diseaseProfileFile === 'string' && diseaseProfileFile !== '' ? (
                                                    <a
                                                        className="me-2 p-0 center"
                                                        href={`${config?.APP_URL}${diseaseProfileFile}`}
                                                        rel="noreferrer"
                                                        target="_blank"
                                                    >
                                                        <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                                    </a>
                                                ) : (
                                                    typeof diseaseProfileFile !== 'string' && diseaseProfileFile &&
                                                        <a
                                                            className="me-2 p-0 center"
                                                            href={URL.createObjectURL(diseaseProfileFile)}
                                                            rel="noreferrer"
                                                            target="_blank"
                                                        >
                                                            <span className="btn-outline-info btn-sm btn ms-2">View</span>                                                            
                                                        </a>
                                                )}
                                                <input
                                                    type="file"
                                                    ref={diseaseProfileOfInPatientFileReference}
                                                    name="diseaseProfile"
                                                    id="diseaseProfile"
                                                    className="form-control"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        setFieldValue('ipd_disease_profile', e.target.files![0]);
                                                        setDiseaseProfileFile(e.target.files![0]);
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup row className="col-sm-12 mb-3">
                                                <Label for="ipd_number_of_nonpaying_beds" sm={8}>Number of non-paying beds</Label>
                                                <Col sm={3}>
                                                    <InputField
                                                        min={'0'}
                                                        type="number"
                                                        placeholder=""
                                                        {...getFieldProps('ipd_number_of_nonpaying_beds')}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup row className="col-sm-12 mb-3">
                                                <Label for="ipd_number_of_paying_beds" sm={8}>Number of paying beds</Label>
                                                <Col sm={3}>
                                                    <InputField
                                                        min={'0'}
                                                        type="number"
                                                        placeholder=""
                                                        {...getFieldProps('ipd_number_of_paying_beds')}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup row className="col-sm-12 mb-3">
                                                <Label for="ipd_number_of_cabins" sm={8}>Number of cabins</Label>
                                                <Col sm={3}>
                                                    <InputField
                                                        min={'0'}
                                                        type="number"
                                                        placeholder=""
                                                        {...getFieldProps('ipd_number_of_cabins')}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup row className="col-sm-12 mb-3">
                                                <Label for="ipd_number_of_admitted_patient_nonpaying_beds" sm={8}>
                                                    Number of patients admitted in non-paying beds
                                                </Label>
                                                <Col sm={3}>
                                                    <InputField
                                                        min={'0'}
                                                        type="number"
                                                        placeholder=""
                                                        {...getFieldProps('ipd_number_of_admitted_patient_nonpaying_beds')}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup row className="col-sm-12 mb-3">
                                                <Label for="ipd_number_of_admitted_patient_paying_beds" sm={8}>
                                                    Number of patients admitted in paying beds
                                                </Label>
                                                <Col sm={3}>
                                                    <InputField
                                                        min={'0'}
                                                        type="number"
                                                        placeholder=""
                                                        {...getFieldProps('ipd_number_of_admitted_patient_paying_beds')}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup row className="col-sm-12 mb-3">
                                                <Label for="ipd_number_of_admitted_patient_cabins" sm={8}>
                                                    Number of patients admitted in cabins
                                                </Label>
                                                <Col sm={3}>
                                                    <InputField
                                                        min={'0'}
                                                        type="number"
                                                        placeholder=""
                                                        {...getFieldProps('ipd_number_of_admitted_patient_cabins')}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} className="mt-3">
                                            <FormGroup className="mb-3 text-center">
                                                <Button
                                                    type="reset"
                                                    text="Reset"
                                                    className="btn btn-warning me-2"
                                                ></Button>
                                                <Button
                                                    type="submit"
                                                    text="Save"
                                                    className="btn btn-success me-2"
                                                    disabled={isIpdOpdDataLoading}
                                                ></Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default InPatientRecordsOfTheDeptModal;