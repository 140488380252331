import NoticeTemplateLists from "src/pages/Setup/NoticeTemplate";
import AddNoticeTemplateCopyAttachment from "src/pages/Setup/NoticeTemplate/AddNoticeTemplateCopyAttachment";
import AddNoticeTemplateList from "src/pages/Setup/NoticeTemplate/AddNoticeTemplateList";
import EditNoticeTemplateList from "src/pages/Setup/NoticeTemplate/EditNoticeTemplateList";
import NoticeAttachmentList from "src/pages/Setup/NoticeTemplate/NoticeAttachmentList";

export const noticeTemplateListSetupRoutes = [
  // notice template list setup
  {
    path: '/setup/notice-template-list/',
    component: NoticeTemplateLists,
  },
  {
    path: '/setup/notice-template-list/update/:id',
    component: EditNoticeTemplateList,
  },
  {
    path: '/setup/notice-template-list/add',
    component: AddNoticeTemplateList,
  },
  {
    path: '/setup/notice-template-attachment/add',
    component: AddNoticeTemplateCopyAttachment,
  },
  {
    path: '/setup/notice-attachment-list/:id',
    component: NoticeAttachmentList
  }
];
