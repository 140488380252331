import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { Container, FormGroup, Label } from 'reactstrap';
import { Button } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import store from 'src/rtk/app/store';
import { useGetTrainingSummaryForAdminAndSupervisorQuery, useGetUserSlotInfoForAdminAndSupervisorQuery } from 'src/rtk/features/logbookTrainingSummary/logbookTrainingSummaryApi';
import loader from '../../assets/images/loader.gif';

export const TrainingSummary = () => {
    const { userId, sessionId } = useParams<{ userId: string; sessionId: string }>();
    const [trainingSummaryList, setTrainingSummaryList] = useState<
        LogbookTrainingSummary[]
    >([]);
    const [selectedSlot, setSelectedSlot] = useState<any>('');

    const history = useHistory();

    const currentUser: any = store.getState().auth.user;

    const {
        data: userSlotData,
        isLoading: isUserSlotDataLoading,
        isSuccess: isUserSlotDataSuccess,
        isError: isUserSlotDataError,
        error: userSlotDataError,
    } = useGetUserSlotInfoForAdminAndSupervisorQuery(userId);

    const logEntrySessions: SelectOption[] = userSlotData?.data?.log_entry_session?.map((slot: any, index: any) => {
        return { label: slot?.exam_training_info?.name, value: slot?.id };
    });

    const {
        data: trainingSummaries,
        isLoading: isTrainingSummariesLoading,
        isSuccess: isTrainingSummariesSuccess,
        isError: isTrainingSummariesError,
        error: trainingSummariesError,
    } = useGetTrainingSummaryForAdminAndSupervisorQuery({ user_id: userId, session_id: selectedSlot?.value ? selectedSlot?.value : sessionId });

    useEffect(() => {
        if (trainingSummaries) {
            const tData = trainingSummaries?.data || [];
            setTrainingSummaryList(tData);
        }
    }, [trainingSummaries]);

    const columns: TableColumn<any>[] = [
        {
            name: 'Sl. No.',
            cell: (row, index) => index + 1,
            width: '80px',
        },

        {
            name: 'Events/Tasks',
            selector: (row) => row?.particulars,
            sortable: false,
            wrap: true,
        },

        {
            name: 'Approved',
            selector: (row) => row?.approve,
            cell: (row) => <div className='bg-success p-2 text-white'>{row?.approve}</div>,
            sortable: false,
            center: true,
        },

        {
            name: <div style={{ textAlign: 'center' }}>Correction</div>,
            selector: (row) => row?.correction,
            cell: (row) => <div className='bg-warning p-2 text-dark'>{row?.correction}</div>,
            sortable: false,
            center: true,
        },

        {
            name: <div style={{ textAlign: 'center' }}>Rejected</div>,
            selector: (row) => row?.reject,
            cell: (row) => <div className='bg-danger p-2 text-white'>{row?.reject}</div>,
            sortable: false,
            center: true,
        },

        {
            name: <div style={{ textAlign: 'center' }}>Approval Pending</div>,
            selector: (row) => row?.pending,
            cell: (row) => <div className='bg-warning p-2 text-dark'>{row?.pending}</div>,
            sortable: false,
            center: true,
        },

        {
            name: 'Draft',
            selector: (row) => row?.draft,
            cell: (row) => <div className='bg-info p-2 text-white'>{row?.draft}</div>,
            sortable: false,
            center: true,
        },

        {
            name: 'Total',
            selector: (row) => row?.total,
            cell: (row) => <div className='bg-secondary p-2 text-white'>{row?.total}</div>,
            sortable: false,
            center: true,
        },

        {
            name: 'Action',
            cell: (row) => (
                <>
                    <FormGroup className="btn-group mt-2">
                        <Button
                            size="sm"
                            color="info"
                            type="button"
                            disabled={row?.entry_setup_master_for_log?.length === 0}
                            text={`View`}
                            onClick={() => {
                                if (!['Procedural and Interpretative Skills', 'Procedure'].includes(row?.particulars)) {
                                    history.push(`/trainee/training-summary-for-admin-and-supervisor/${row?.particulars}/${row?.id}/${userId}/${selectedSlot?.value ? selectedSlot?.value : sessionId}`);
                                } else {
                                    history.push(`/trainee/summary-of-procedural-and-interpretative-skills-for-admin-and-supervisor/${userId}/${selectedSlot?.value ? selectedSlot?.value : sessionId}`);
                                }
                            }}
                        />
                    </FormGroup>
                </>
            ),

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <div className="page-content">
            {isTrainingSummariesLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Container fluid>
                <div className='page-breadcrumb-wrapper'>
                    <BetterBreadcrumb
                        title="Training Summary"
                        breadcrumbItem={
                            currentUser.type === 'Supervisor' ?
                                [
                                    { link: '/supervisor/logbook', name: 'Logbook List' },
                                ]
                                :
                                []
                        }
                    />
                </div>
                <div className="row align-items-center justify-content-center">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-4">
                                <table className="table table-bordered table-striped table-sm">
                                    <tbody>
                                        <tr>
                                            {userSlotData?.data?.registration_user_info?.personal_info?.full_name && (
                                                <td>
                                                    <strong>Name: </strong>
                                                    {userSlotData?.data?.registration_user_info?.personal_info?.full_name}
                                                </td>
                                            )}
                                        </tr>
                                        <tr>
                                            {userSlotData?.data?.registration_user_info?.bmdc_registration_no && (
                                                <td>
                                                    <strong>BMDC No: </strong>
                                                    {userSlotData?.data?.registration_user_info?.bmdc_registration_no}
                                                </td>
                                            )}
                                        </tr>
                                        <tr>
                                            {userSlotData?.data?.registration_user_info?.personal_info?.mobile && (
                                                <td>
                                                    <strong>Mobile: </strong>
                                                    {userSlotData?.data?.registration_user_info?.personal_info?.mobile}
                                                </td>
                                            )}
                                        </tr>
                                        <tr>
                                            {userSlotData?.data?.registration_user_info?.personal_info?.email && (
                                                <td>
                                                    <strong>Email: </strong>
                                                    {userSlotData?.data?.registration_user_info?.personal_info?.email}
                                                </td>
                                            )}
                                        </tr>                                        
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-sm-4">
                                <table className="table table-bordered table-striped table-sm">
                                    <tbody>
                                        <tr>
                                            {userSlotData?.data?.log_entry_current_session?.exam_training_info?.name && (
                                                <td>
                                                    <strong>Current Slot: </strong>
                                                    {userSlotData?.data?.log_entry_current_session?.exam_training_info?.name}
                                                </td>
                                            )}
                                        </tr>
                                        <tr>
                                            {userSlotData?.data?.log_entry_current_session?.exam_training_info?.session && (
                                                <td>
                                                    <strong>Session: </strong>
                                                    {userSlotData?.data?.log_entry_current_session?.exam_training_info?.session}
                                                </td>
                                            )}
                                        </tr>
                                        <tr>
                                            {userSlotData?.data?.log_entry_current_session?.exam_training_info?.training_type && (
                                                <td>
                                                    <strong>Training Type: </strong>
                                                    {userSlotData?.data?.log_entry_current_session?.exam_training_info?.training_type}
                                                </td>
                                            )}
                                        </tr>
                                        <tr>
                                            {userSlotData?.data?.registration_user_info?.subject?.subject_name && (
                                                <td>
                                                    <strong>Subject: </strong>
                                                    {userSlotData?.data?.registration_user_info?.subject?.subject_name}
                                                </td>
                                            )}
                                        </tr>
                                        <tr>
                                            {userSlotData?.data?.registration_user_info?.current_session_and_supervisor_info?.supervisor?.name && (
                                                <td>
                                                    <strong>Supervisor: </strong>
                                                    {userSlotData?.data?.registration_user_info?.current_session_and_supervisor_info?.supervisor?.name}
                                                </td>
                                            )}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <FormGroup className='extent-field'>
                                        <Label htmlFor="slot">Select Slot</Label>
                                        <Select
                                            isClearable={true}
                                            isSearchable={true}
                                            value={selectedSlot}
                                            onChange={setSelectedSlot}
                                            options={logEntrySessions}
                                            placeholder='Select...'
                                            name="slot"
                                            id="slot"
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <DataTable
                                    columns={columns}
                                    data={trainingSummaryList}
                                    // progressPending={isTrainingSummariesLoading}
                                    expandOnRowClicked
                                    highlightOnHover
                                    responsive
                                    persistTableHead
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};
