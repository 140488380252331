import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { Container, Form, Label, FormGroup, Input } from 'reactstrap';
import { InputField, Button } from 'src/components';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { setFromErrors } from 'src/helpers';
import { useHistory } from 'react-router-dom';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetTrnNoticeMasterListsQuery } from 'src/rtk/features/setup/trnNoticeMaster/setupTrnNoticeMaster';
import { useCreateTransactionNoticeDetailsMutation } from 'src/rtk/features/setup/trnNoticeDetails/setupTrnNoticeDetails';

const initialValues: TransactionNoticeDetails = {
    id: '',
    notice_master_id: '',
    details: '',
    status: true,
    creator_organization_id: '',
    updater_organization_id: '',
    created_by: '',
    updated_by: '',
    deleted_by: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
};

export const AddTrnNoticeDetails = () => {
  const [createTrnNoticeDetails, { isSuccess, isError, error }] = useCreateTransactionNoticeDetailsMutation();

  const { data } = useGetTrnNoticeMasterListsQuery();
  const noticeMasterList = data?.data || [];

  const history = useHistory();

  const err: CustomError = error as CustomError;
  const { handleSubmit, getFieldProps, touched, errors, values, resetForm, setErrors } =
    useFormik({
      initialValues,
      validationSchema: Yup.object({
        notice_master_id: Yup.string().required('Transaction Notice Details must be under a transaction notice master'),        
      }),

      onSubmit: (values) => {
        createTrnNoticeDetails({
          ...values,
        });
        console.log(values);
      },
    });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Notice details created successfully');
      history.push('/setup/trn-notice-details');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

//   let op: SelectOption[] = [{ label: 'Select a Transaction Notice Master', value: '' }];

  let op = [    
    ...noticeMasterList?.map((e: TransactionNoticeMaster) => ({
      label: e.notice_subject!,
      value: e.id!,
    })),
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Add Notice Details'}
          breadcrumbItem={[{ link: '/setup/trn-notice-details/', name: 'Notice Details List' }]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="card mt-3">
            <h5 className="card-header">
              <i className="fa fa-plus me-2" />
              Add Notice Details
            </h5>
            <div className="card-body">
                <Form
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <div className="row">
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                      <FormGroup className="mb-3">
                        <Label for="notice_master_id">Notice Master</Label>
                        <InputField
                          type="select"
                          placeholder="Notice Master"
                          options={op}
                          invalid={{ errors, touched }}
                          {...getFieldProps('notice_master_id')}
                        />
                      </FormGroup>
                    </div>                

                    <div className='col-lg-4 col-md-6 col-sm-12'>
                      <FormGroup className="mb-3">
                        <Label for="details">Details</Label>
                        <InputField
                          type="textarea"
                          placeholder="Details"
                          invalid={{ errors, touched }}
                          {...getFieldProps('details')}
                        />
                      </FormGroup>
                    </div>                   

                    <div className='col-lg-4 col-md-6 col-sm-12'>
                      <FormGroup check className='mb-3 mt-4'>
                        <Label check>
                            <Input type="checkbox" {...getFieldProps('status')} checked={values.status} />{' '}
                            Status
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                  <Button type="submit" text="Submit" />
                </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
