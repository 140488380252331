import { apiSlice } from 'src/rtk/api/apiSlice';
import * as logBookEntrySetupDetailsRecordUrl from 'src/helpers/url_helper';

export const logBookEntrySetupDetailsRecordApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['logBookEntrySetupDetails'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLogbookEntrySetupDetailsRecords: builder.query<APIResponse<LogbookEntrySetupDetailsRecord[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${logBookEntrySetupDetailsRecordUrl.GET_LOGBOOK_ENTRY_SETUP_DETAILS_RECORDS}`,
        }),
        providesTags: ['logBookEntrySetupDetails'],
      }),

      getLogbookEntrySetupDetailsRecord: builder.query<APIResponse<LogbookEntrySetupDetailsRecord>, string>({
        query: (id) => ({
          url: `${logBookEntrySetupDetailsRecordUrl.GET_LOGBOOK_ENTRY_SETUP_DETAILS_RECORD}/${id}`,
          method: 'GET',
        }),
        providesTags: ['logBookEntrySetupDetails'],
      }),

      createLogbookEntrySetupDetailsRecord: builder.mutation<APIResponse<LogbookEntrySetupDetailsRecord>, LogbookEntrySetupDetailsRecord>({
        query: (data) => ({
          url: `${logBookEntrySetupDetailsRecordUrl.CREATE_LOGBOOK_ENTRY_SETUP_DETAILS_RECORD}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['logBookEntrySetupDetails'],
      }),

      updateLogbookEntrySetupDetailsRecord: builder.mutation<APIResponse<LogbookEntrySetupDetailsRecord>, string>({
        query: (url) => ({
          url: `${logBookEntrySetupDetailsRecordUrl.UPDATE_LOGBOOK_ENTRY_SETUP_DETAILS_RECORD}` + url,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['logBookEntrySetupDetails'],
      }),

      deleteLogbookEntrySetupDetailsRecord: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${logBookEntrySetupDetailsRecordUrl.DELETE_LOGBOOK_ENTRY_SETUP_DETAILS_RECORD}/${id}`,
        }),
        invalidatesTags: ['logBookEntrySetupDetails'],
      }),
    }),
  });

export const {
  useCreateLogbookEntrySetupDetailsRecordMutation,
  useGetLogbookEntrySetupDetailsRecordQuery,
  useGetLogbookEntrySetupDetailsRecordsQuery,
  useUpdateLogbookEntrySetupDetailsRecordMutation,
  useDeleteLogbookEntrySetupDetailsRecordMutation,
} = logBookEntrySetupDetailsRecordApi;
