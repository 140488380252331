import moment from 'moment';
import * as url from 'src/helpers/url_helper';
import * as Yup from 'yup';

const trainer_info = (info: any) => {
  let trainer_info = JSON.parse(info);
  return (<>
    {Object.keys(trainer_info).map((item: any) => {
      return (<>
        <div><b>{trainer_info?.[item].type}:</b> {trainer_info?.[item].name}</div>
      </>)
    })}
  </>)
}

const formObj: any = {
  debug: true,

  createAPIUrl: url.RTMD_BATCH_CREATE,
  updateAPIUrl: url.RTMD_BATCH_UPDATE,
  getDataByIdAPIUrl: '',
  listAPIUrl: url.RTMD_WORKSHOP_TRAINING_BATCH_LIST,
  deleteAPIUrl: url.SETUP_EXAM_TRAINING_INFO_LIST,

  listUrl: '/rtmd/batch/',
  addUrl: '/rtmd/batch/add',
  updateUrl: '/rtmd/batch/update/',

  showSearchForm: true,
  showCreateNewBtn: true,
  showUpdateBtn: true,
  showViewBtn: true,
  showDeleteBtn: false,
  showTableActionColumn: true,

  createNewBtnTitle: 'Create New',
  submitBtnTitle: 'Save',
  updateBtnTitle: 'Update',
  resetBtnTitle: 'Reset',
  draftBtnTitle: '',
  viewBtnTitle: 'View',
  deletBtnTitle: 'Delete',
  deletePromtMessage: 'Do you want to delete?',

  title: 'Batch/Session',
  breadcrumbItemForList: [{ link: '/', name: 'Dashboard' }],
  breadcrumbItemForForm: [
    { link: '/', name: 'Dashboard' },
    { link: '/rtmd/batch/', name: 'Batch/Session List' },
  ],

  formFields: {
    training_name: {
      positionInForm: 1,
      label: 'Training Title',
      name: 'training_name',
      viewCell: (row: any) => row.workshop_training.training_name,
      cell: (row: any) => row.workshop_training.training_name,
      type: 'select',
      default: '',
      // isMulti: false,
      isRequired: true,
      validation: Yup.string().ensure().required('Training Title is required!'),
      showInForm: true,
      showInSearch: true,
      colSize: 6,
      dataApi: {
        url: url.RTMD_WORKSHOP_TRAINING_LIST,
        key: 'id',
        label: 'training_name',
        label2: '',
      },
      data: [],
    },

    batch_no: {
      positionInForm: 2,
      label: 'Batch No',
      name: 'batch_no',
      viewCell: (row: any) => row.batch_no,
      cell: (row: any) => row.batch_no,
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Bbatch no is required'),
      showInForm: true,
      showInSearch: true,
      colSize: 6,
    },



    // no_of_trainer: {
    //   label: 'No of Trainer',
    //   viewCell: (row: any) => row.trainer_id.split(',').length,
    //   cell: (row: any) => row.trainer_id.split(',').length,
    // },

    training_for: {
      label: 'Training For',
      viewCell: (row: any) => row.workshop_training.training_for,
      cell: (row: any) => row.workshop_training.training_for,
    },

    duration: {
      label: 'Duration',
      viewCell: (row: any) => row.workshop_training.duration,
      cell: (row: any) => row.workshop_training.duration,
    },
    fees: {
      label: 'Fees',
      viewCell: (row: any) => row.workshop_training.fees,
      cell: (row: any) => row.workshop_training.fees,
    },

    notice_date: {
      label: 'Notice Date',
      viewCell: (row: any) =>
        moment(row.workshop_training.notice_date).format('DD-MMM-YY'),
      cell: (row: any) =>
        moment(row.workshop_training.notice_date).format('DD-MMM-YY'),
    },

    trainer_id: {
      positionInForm: 2,
      label: 'Trainer',
      name: 'trainer_id',
      type: 'select',
      default: '',
      isMulti: false,
      isRequired: true,
      validation: Yup.string().ensure().required('Trainer is required!'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
      dataApi: {
        url: url.RTMD_SUPERVISOR_LIST,
        key: 'id',
        label: 'name',
        label2: '',
      },
      data: [],
      dependField: 'type',
      dependValue: 'main-training',
      condition: '!==',
    },

    description: {
      positionInForm: 8,
      label: 'Batch Details',
      name: 'description',
      viewCell: (row: any) => row.description,
      type: 'textarea',
      default: '',
      isRequired: false,
      validation: Yup.string().required('Description is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },

    batch_trainers: {
      label: 'Day wise Trainer',
      viewCell: (row: any) => {
        return (<>
          <table className="table table-bordered ">
            <thead className="bg-light">
              <tr className="align-text-top text-center text-capitalize">
                <th>Days</th>
                <th>Date</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Trainer Info</th>
              </tr>
            </thead>

            <tbody>
              {Object.keys(row.batch_trainers).map((itemKey: any, key: number) => {
                let item = row.batch_trainers[itemKey];
                return (
                  <tr
                    key={Math.random()}
                    className="align-text-top text-center text-capitalize"
                  >
                    <td style={{ width: '150px' }}>
                      Day {key + 1}
                    </td>
                    <td>
                      {
                        item?.training_date
                      }
                    </td>
                    <td>
                      {
                        moment(item?.start_time, "h:mm").format("h:mm A")
                      }
                    </td>
                    <td>
                      {
                        moment(item?.end_time, "h:mm").format("h:mm A")
                      }
                    </td>

                    <td className='text-start'>
                      {
                        trainer_info(item?.trainer_info)
                      }
                    </td>
                  </tr>
                );
              })}


            </tbody>
          </table>

        </>)
      },
    },

    batch_user: {
      label: <div>No of Participants</div>,
      cell: (row: any) => Object.keys(row.batch_user).length,
      viewCell: (row: any) => {
        return (<>
          <table className="table table-bordered ">
            <thead className="bg-light">
              <tr className="align-text-top text-center text-capitalize">
                <th colSpan={5}>No Of Participants: {Object.keys(row.batch_user).length}</th>
              </tr>
              <tr className="align-text-top text-center text-capitalize">
                <th>SL#</th>
                <th>Name</th>
                <th>BMDC Reg. NO</th>
                <th>Mobile</th>
                <th>FCPS Part-1 Passing Year</th>
              </tr>
            </thead>

            <tbody>
              {Object.keys(row.batch_user).map((itemKey: any, key: number) => {
                let item = row.batch_user[itemKey];
                return (
                  <tr
                    key={Math.random()}
                    className="align-text-top text-center text-capitalize"
                  >
                    <td style={{ width: '50px' }}>
                      <span
                        style={{
                          width: '20px',
                          height: '20px',
                        }}
                        className="avatar-title rounded-circle bg-soft-info text-dark font-size-12 m-2"
                      >
                        {key + 1}
                      </span>
                    </td>
                    <td>
                      {
                        item?.user_details
                          ?.personal_info?.full_name
                      }
                    </td>

                    <td>
                      {
                        item?.user_details
                          ?.bmdc_registration_no
                      }
                    </td>

                    <td>
                      {
                        item?.user_details
                          ?.personal_info?.mobile
                      }
                    </td>
                    <td>
                      {item?.user_details?.fcps_part_one_passing_year}
                    </td>
                  </tr>
                );
              })}


            </tbody>
          </table>

        </>)
      },
    },


  },

};

export default formObj;
