import { apiSlice } from 'src/rtk/api/apiSlice';
import * as logBookMasterSetupUrl from 'src/helpers/url_helper';

export const logBookMasterSetupApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['LogBookSetupMasters'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLogBookMasterSetups: builder.query<APIResponse<LogBookSetupMasters[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${logBookMasterSetupUrl.GET_LOGBOOK_MASTER_SETUPS}`,
        }),
        providesTags: ['LogBookSetupMasters'],
      }),

      getLogBookMasterSetup: builder.query<APIResponse<LogBookSetupMasters>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${logBookMasterSetupUrl.GET_LOGBOOK_MASTER_SETUP}/${id}`,
        }),
        providesTags: ['LogBookSetupMasters'],
      }),

      createLogBookMasterSetup: builder.mutation<APIResponse<LogBookSetupMasters>, LogBookSetupMasters>({
        query: (data) => ({
          url: `${logBookMasterSetupUrl.CREATE_LOGBOOK_MASTER_SETUP}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['LogBookSetupMasters'],
      }),

      updateLogBookMasterSetup: builder.mutation<APIResponse<LogBookSetupMasters>, string>({
        query: (url) => ({
          url: `${logBookMasterSetupUrl.UPDATE_LOGBOOK_MASTER_SETUP}/${url}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['LogBookSetupMasters'],
      }),

      deleteLogBookMasterSetup: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${logBookMasterSetupUrl.DELETE_LOGBOOK_MASTER_SETUP}/${id}`,
        }),
        invalidatesTags: ['LogBookSetupMasters'],
      }),
    }),
  });

export const {
  useGetLogBookMasterSetupsQuery,
  useGetLogBookMasterSetupQuery,
  useCreateLogBookMasterSetupMutation,
  useUpdateLogBookMasterSetupMutation,
  useDeleteLogBookMasterSetupMutation,
} = logBookMasterSetupApi;
