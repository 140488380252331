import moment from 'moment';
import * as url from 'src/helpers/url_helper';
import store from 'src/rtk/app/store';
import * as Yup from 'yup';

const formObj: any = {
  debug: false,

  listAPIUrl: url.THESIS_LIST_FOR_EXAMINER,
  reviewerAssignAPIUrl: url.THESIS_MARKING_BY_EXAMINER,

  listUrl: '/demo/',
  addUrl: '/demo/add',
  updateUrl: '/thesis-dissertation-protocol-view/',
  deleteAPIUrl: '/send-dissertation-to-library/',

  showSearchForm: true,
  showCreateNewBtn: false,
  showUpdateBtn: true,
  showViewBtn: true,
  showDeleteBtn: true,
  showTableActionColumn: true,

  createNewBtnTitle: 'Create New',
  submitBtnTitle: 'Save',
  updateBtnTitle: 'Comments',
  resetBtnTitle: 'Reset',
  draftBtnTitle: '',
  viewBtnTitle: 'View',
  deletBtnTitle: 'Send To Library',
  deletePromtMessage: 'Do you want to send to library?',

  title: 'Examiners Marking For Thesis',
  breadcrumbItemForList: [{ link: '/', name: 'Dashboard' }],
  breadcrumbItemForForm: [{ link: '/', name: 'Dashboard' }],

  searchFormFields: {
    category: {
      positionInForm: 2,
      label: 'Category',
      name: 'category',
      viewCell: (row: any) => row?.title?.category || '',
      cell: (row: any) => row?.title?.category || '',
      cellWidth: '100px',
      type: 'select',
      default: 'Dissertation',
      // isMulti: false,
      isRequired: false,
      validation: Yup.string()
        .required('Category is required')
        .oneOf(['Thesis', 'Dissertation']),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
      data: [
        { label: 'Thesis', value: 'Thesis' },
        { label: 'Dissertation', value: 'Dissertation' },
      ],
    },

    title_of_thesis: {
      positionInForm: 1,
      label: 'Title',
      name: 'title_of_thesis',
      viewCell: (row: any) => row?.title?.title_of_thesis || '',
      cell: (row: any) => row?.title?.title_of_thesis || '',
      cellWidth: '250px',
      type: 'text',
      default: '',
      isRequired: false,
      showInSearch: true,
      colSize: 6,
    },

    updated: {
      label: 'Submission Date',
      viewCell: (row: any) => moment(row.updated_at).format("YYYY-MMM-DD"),
      cell: (row: any) => moment(row.updated_at).format("DD-MMM-YYYY"),
    },

    bmdc_registration_no: {
      positionInForm: 3,
      label: 'BMDC No.',
      viewCell: (row: any) => row?.bmdc_registration_no || '',
      cell: (row: any) => row?.bmdc_registration_no || '',
      name: 'bmdc_registration_no',
      type: 'text',
      default: '',
      isRequired: false,
      showInSearch: true,
      colSize: 3,
    },

    full_name: {
      positionInForm: 3,
      label: 'Trainee Name',
      name: 'full_name',
      type: 'text',
      default: '',
      isRequired: false,
      showInSearch: true,
      colSize: 3,
      viewCell: (row: any) => row?.trainee_name || '',
      cell: (row: any) => row?.trainee_name || '',
      cellWidth: '150px',
    },
    // subject_name: {
    //   positionInForm: 4,
    //   name: 'full_name',
    //   type: 'text',
    //   default: '',
    //   isRequired: false,
    //   showInSearch: true,
    //   colSize: 3,
    //   label: 'Discipline',
    //   viewCell: (row: any) => row?.registration?.subject_name || '',
    //   cell: (row: any) => row?.registration?.subject_name || '',
    // },

    exam_date: {
      positionInForm: 3,
      label: 'Exam Date',
      name: 'exam_date',
      viewCell: (row: any) => row?.exam_date || '',
      cell: (row: any) => moment(row?.exam_date).isValid() ? moment(row?.exam_date).format("DD-MMM-YYYY") : '',
      type: 'date',
      options: {
        mode: 'single', //range, multiple, single
        dateFormat: 'Y-m-d', // 'Y-m-d' 'd/m/Y'
        retDateFormat: 'YYYY-MM-DD', // 'YYYY-MM-DD' 'DD-MM-YYYY'
        minDate: new Date(),
        // defaultDate: new Date(),
        allowInput: true,
        defaultDate: '',
        // defaultDate: [values['period_from'], values['period_to']],
      },
      isRequired: true,
      validation: Yup.string().required('Exam Date is required'),
      showInForm: true,
      showInSearch: true,
      colSize: 6,
    },

    // thesis_writing_mark: {
    //   label: 'Writing mark',
    //   name: 'thesis_writing_mark',
    //   viewCell: (row: any) => row?.thesis_writing_mark || '',
    //   cell: (row: any) => row?.thesis_writing_mark || '',
    //   type: 'text',
    //   default: '',
    //   isRequired: true,
    //   validation: Yup.string().required('Writing mark is required'),
    //   showInForm: true,
    //   showInSearch: false,
    //   colSize: 6,
    // },
    // thesis_defence_mark: {
    //   label: 'Defence mark',
    //   name: 'thesis_defence_mark',
    //   viewCell: (row: any) => row?.thesis_defence_mark || '',
    //   cell: (row: any) => row?.thesis_defence_mark || '',
    //   type: 'text',
    //   default: '',
    //   isRequired: true,
    //   validation: Yup.string().required('Defence mark is required'),
    //   showInForm: true,
    //   showInSearch: false,
    //   colSize: 6,
    // },

    // attendance: {
    //   label: 'Attendance',
    //   name: 'attendance',
    //   viewCell: (row: any) => row.attendance,
    //   cell: (row: any) => row.attendance,
    //   type: 'checkbox',
    //   isSwitch: true,
    //   default: 'Present',
    //   isRequired: false,
    //   validation: Yup.array()
    //     .required('Attendance for is required'),
    //   showInForm: true,
    //   showInSearch: true,
    //   colSize: 12,
    //   data: [
    //     { label: 'Present', value: 'Present' },
    //   ],
    // },

    // thesis_marking_comment: {
    //   label: 'Comment',
    //   name: 'thesis_marking_comment',
    //   viewCell: (row: any) => row?.thesis_marking_comment || '',
    //   type: 'richtext',
    //   default: '',
    //   isRequired: false,
    //   validation: Yup.string().required('Comment is required'),
    //   showInForm: true,
    //   showInSearch: false,
    //   colSize: 12,
    // },

    guide_id: {
      label: 'Guide',
      name: 'guide_id',
      viewCell: (row: any) => row?.title?.guide?.personal_info?.full_name || '',
      type: 'modal',
      default: '',
      isMulti: false,
      isRequired: true,
      validation: Yup.string().ensure().required('Guide is required!'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
      dataApi: {
        listAPIUrl: url.GET_GUIDE_COGUIDE_LIST + '?type=Guide&',
        displayLabel: (row: any) => row?.label || '',
        key: (row: any) =>
          row?.registration?.personal_info?.full_name
            ? row?.registration_no
            : false,
        label: (row: any) => {
          return row?.registration?.personal_info?.full_name || '';
        },
        showSearchForm: true,
        searchFormFields: [
          {
            label: 'BMDC NO',
            name: 'bmdc_registration_no',
            type: 'text',
            cell: (row: any) => row?.registration?.bmdc_registration_no || '',
            showInSearch: true,
          },
          {
            label: 'Name',
            name: 'full_name',
            type: 'text',
            cell: (row: any) =>
              row?.registration?.personal_info?.full_name || '',
            showInSearch: true,
          },

          {
            label: 'Institute Name',
            name: 'institute_name',
            type: 'text',
            cell: (row: any) =>
              row.registration?.employment_infos?.institute_name || '',
            showInSearch: true,
          },

          {
            label: 'Mobile',
            name: 'mobile',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.mobile || '',
            showInSearch: true,
          },
          {
            label: 'Email',
            name: 'email',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.email || '',
            showInSearch: true,
          },
        ],
      },
      data: [],
    },

    co_guide_id: {
      label: 'Co-guide',
      name: 'co_guide_id',
      type: 'modal',
      default: '',
      isMulti: true,
      isRequired: true,
      validation: Yup.array()
        .ensure()
        .required('Co-guide is required!')
        .min(1, 'Select Minimum 1.')
        .max(3, 'Select Maximum 3'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
      dataApi: {
        listAPIUrl: url.GET_GUIDE_COGUIDE_LIST + '?type=Co-Guide&',
        displayLabel: (row: any) => row?.label || '',
        key: (row: any) =>
          row?.registration?.personal_info?.full_name
            ? row?.registration_no
            : false,
        label: (row: any) => {
          return row?.title?.registration?.personal_info?.full_name || '';
        },
        showSearchForm: true,
        searchFormFields: [
          {
            label: 'BMDC NO',
            name: 'bmdc_registration_no',
            type: 'text',
            cell: (row: any) => row?.registration?.bmdc_registration_no || '',
            showInSearch: true,
          },
          {
            label: 'Name',
            name: 'full_name',
            type: 'text',
            cell: (row: any) =>
              row?.registration?.personal_info?.full_name || '',
            showInSearch: true,
          },

          {
            label: 'Institute Name',
            name: 'institute_name',
            type: 'text',
            cell: (row: any) =>
              row.registration?.employment_infos?.institute_name || '',
            showInSearch: true,
          },

          {
            label: 'Mobile',
            name: 'mobile',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.mobile || '',
            showInSearch: true,
          },
          {
            label: 'Email',
            name: 'email',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.email || '',
            showInSearch: true,
          },
        ],
      },
      data: [],
    },

    guide_approval_note: {
      label: 'Guide approval note',
      viewCell: (row: any) => row?.guide_approval_note || '',
    },

    guide_approval_status: {
      label: 'Guide approval status',
      viewCell: (row: any) => row?.guide_approval_status || '',
    },

    co_guide_view: {
      label: 'Co-Guide',
      viewCell: (row: any) => {
        return (
          <div className="d-flex">
            {row?.co_guide_id_1 && (
              <div className="m-1 p-2 border rounded  ">
                <div>Name: {row?.title?.co_guide_1_name || ''}</div>
                <div>Note: {row?.co_guide1_approval_note || ''}</div>
                <div>
                  Approval status: {row?.co_guide1_approval_status || ''}
                </div>
              </div>
            )}
            {row?.co_guide_id_2 && (
              <div className="m-1 p-2 border rounded">
                <div>Name: {row?.title?.co_guide_2_name || ''}</div>
                <div>Note: {row?.co_guide2_approval_note || ''}</div>
                <div>
                  Approval status: {row?.co_guide2_approval_status || ''}
                </div>
              </div>
            )}
            {row?.co_guide_id_3 && (
              <div className="m-1 p-2 border rounded">
                <div>Name: {row?.title?.co_guide_3_name || ''}</div>
                <div>Note: {row?.co_guide3_approval_note || ''}</div>
                <div>
                  Approval status: {row?.co_guide3_approval_status || ''}
                </div>
              </div>
            )}
          </div>
        );
      },
    },    

    result: {
      label: 'Result',
      viewCell: (row: any) => row?.result || "",
      cell: (row: any) => row?.result || "",
    },

    marking_status: {
      label: 'Marking status',
      viewCell: (row: any) => row?.marking_status || "",
      cell: (row: any) => row?.marking_status || "",
    },

    library_status: {
      label: 'Send Library',
      viewCell: (row: any) => row?.library_status || "No",
      cell: (row: any) => row?.library_status || "No",
    },

    examiners: {
      label: 'Examiners Marking',
      viewCell: (row: any) => {
        return (
          <div className="row">
            {row.examiners.filter((item: any) => item?.examiner_name === store.getState().auth.user.name).map((item: any) => {
              return (
                <div key={Math.random()} className="col-md-6">
                  <div className="m-1 p-2 border rounded">
                    <div>Name: {item?.examiner_name || ''}</div>
                    <div>Writing mark: {item?.thesis_writing_mark || ''}</div>
                    <div>Defence mark: {item?.thesis_defence_mark || ''}</div>
                    <div>Attendance: {item?.attendance || ''}</div>
                    <div>Comment: {item?.thesis_marking_comment || ''}</div>
                  </div>
                </div>
              )
            })}

          </div>
        );
      },
    },

  },
  formFields: {

    thesis_writing_mark: {
      label: 'Writing mark (Pass mark = 30, Highest mark = 50)',
      name: 'thesis_writing_mark',
      viewCell: (row: any) => row?.thesis_writing_mark || '',
      cell: (row: any) => row?.thesis_writing_mark || '',
      type: 'number',
      default: 0,      
      isRequired: true,
      validation: Yup.number().max(50, 'Writing mark cannot exceed highest mark').min(0, 'Mark must be at least zero').required('Writing mark is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 4,
    },
    thesis_defence_mark: {
      label: 'Defence mark (Pass mark = 30, Highest mark = 50)',
      name: 'thesis_defence_mark',
      viewCell: (row: any) => row?.thesis_defence_mark || '',
      cell: (row: any) => row?.thesis_defence_mark || '',
      type: 'number',
      default: 0,      
      isRequired: true,
      validation: Yup.number().required('Defence mark is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 4,
    },

    attendance: {
      label: 'Attendance',
      name: 'attendance',
      viewCell: (row: any) => row.attendance,
      cell: (row: any) => row.attendance,
      type: 'checkbox',
      isSwitch: true,
      default: 'Present',
      isRequired: false,
      validation: Yup.array()
        .required('Attendance for is required'),
      showInForm: true,
      showInSearch: true,
      colSize: 4,
      data: [
        { label: 'Present', value: 'Present' },
      ],
    },

    thesis_marking_comment: {
      label: 'Comment',
      name: 'thesis_marking_comment',
      viewCell: (row: any) => row?.thesis_marking_comment || '',
      type: 'textarea',
      default: '',
      isRequired: false,
      validation: Yup.string().required('Comment is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 4,
    },



  },
};

export default formObj;
