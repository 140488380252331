import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { Button } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetThesisEntryDetailsByIdQuery } from 'src/rtk/features/fileUploadForThesis/thesisDissertationFileEntryApi';
import StepOne from './StepOne';
import { ThesisDissertationSummaryReport } from './ThesisDissertationSummaryReport';

const ThesisDissertationSummaryView = () => {
    const { id } = useParams<{ id: string }>();
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [showReport, setShowReport] = useState<boolean>(false);    

    const {
        data: thesisDetails,
        isSuccess: isThesisDetailsSuccess,
        isError: isThesisDetailsError,
        isLoading: isThesisDetailsLoading,
        error: thesisDetailsError,
    } = useGetThesisEntryDetailsByIdQuery(id);

    const thesisEntry: any = thesisDetails?.data!;      
   

    const goToNext = () => {
        if (currentStep < 3) {
            setCurrentStep((p: number) => p + 1);
        }
    };

    const goToPrevious = () => {
        if (currentStep > 1) {
            setCurrentStep((p: number) => p - 1);
        }
    };

    return (
        <>
            <>
                <div className='page-content'>
                    <Container fluid>
                        <BetterBreadcrumb
                            title={`${thesisEntry?.document_type} Summary`}
                            breadcrumbItem={[{ link: '/', name: 'Dashboard' }]}
                        />

                        <div className={"d-flex gap-3 justify-content-end mb-3"}>
                            <Button
                                type="button"
                                text={`${thesisEntry?.document_type} Entry`}
                                color="info"
                                outline
                                onClick={(e) => {
                                    window.open(`/thesis-entry-details-for-supervisor/${thesisEntry?.id}`, '_blank');
                                }}
                            ></Button>

                            <Button
                                type="button"
                                text="View in PDF"
                                color="secondary"
                                onClick={(e) => {
                                    setShowReport(true);
                                }}
                            ></Button>
                        </div>

                        <Row className='justify-content-center'>
                            <Col sm={10}>

                                <StepOne thesisEntry={thesisEntry} />


                                {
                                    showReport ?
                                        <ThesisDissertationSummaryReport
                                            thesisEntry={thesisEntry}
                                            showReport={showReport}
                                            setShowReport={setShowReport}
                                        />
                                        :
                                        null
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        </>
    );
};

export default ThesisDissertationSummaryView;
