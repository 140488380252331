import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import Select from 'react-select';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Modal,
  Row
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import store from 'src/rtk/app/store';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import { useGetFcpsPaymentListQuery, useGetTraineePaymentListQuery } from 'src/rtk/features/trainee/fcpsOfflinePaymentApi';
import loader from '../../assets/images/loader.gif';
import UpdatedEditOfflinePayment from './UpdatedEditOfflinePayment';
import { actionColumnStyles, dataTableCustomStyles } from 'src/components/Common/FixedActionColumn';

const UpdatedOfflinePaymentList = () => {
  document.title = 'Trainee Payment List | BCPS';

  const [editFormActive, setEditFormActive] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [editCount, setEditCount] = useState<number>(1);
  const [fcpsPayment, setFcpsPayment] = useState<GetFcpsOfflinePayment>(
    {} as GetFcpsOfflinePayment,
  );

  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');
  // const [selectedSubject, setSelectedSubject] = useState<any>('');
  const [status, setStatus] = useState(false);

  const generateQueryUrl = () => {
    return `page=${page}&paginate_per_page=${limit}${search}`;
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setPage(page);
  };

  interface SearchFields {
    search?: string;
    from_date?: string;
    to_date?: string;
    payment_confirmation_ind?: boolean | null;
    // subject?: string;
  }

  const initialValues: SearchFields = {
    search: '',
    from_date: '',
    to_date: '',
    payment_confirmation_ind: null,
    // subject: '',
  };

  const {
    data: fcpsPayments,
    error: fcpsPaymentsError,
    isLoading: isFcpsPaymentsLoading,
    isError: isFcpsPaymentsError,
    isSuccess: isFcpsPaymentsSuccess,
    isFetching,
  } = useGetTraineePaymentListQuery(generateQueryUrl());

  const fcpsPaymentList = fcpsPayments?.data?.data;

  const { data: subjects } = useGetSubjectInfosQuery();

  const allSubjects: SelectOption[] =
    subjects?.data?.map((subject) => ({
      value: subject.id!,
      label: subject.subject_name,
    })) || [];

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.search !== '') {
        s = s + `&search=${values.search}`;
      }

      if (values.from_date !== '') {
        s = s + `&from_date=${values.from_date}`;
      }

      if (values.to_date !== '') {
        s = s + `&to_date=${values.to_date}`;
      }

      if (values.payment_confirmation_ind !== null) {
        s = s + `&payment_confirmation_ind=${status}`;
      }

      // if (values.subject !== '') {
      //   s = s + `&subject=${values.subject}`;
      // }

      setSearch(s);
      setPage('1');
    },
  });

  const editHandler = (fcpsPayment: GetFcpsOfflinePayment) => {
    setFcpsPayment(fcpsPayment);
    setEditCount((e: number) => e + 1);
    setEditFormActive(true);
    setModalShow(true);
  };

  const modalShowHandler = (
    modalState: boolean,
    fcpsPayment: GetFcpsOfflinePayment,
  ) => {
    setFcpsPayment(fcpsPayment);
    setModalShow(modalState);
    setEditFormActive(false);
  };

  const columns: TableColumn<any>[] = [
    {
      name: <div>Sl. No.</div>,
      width: '90px',
      cell: (row, index) => <strong>{((page - 1) * limit + index) + 1}</strong>,
    },
    {
      name: <div>Name</div>,
      selector: (row) => store.getState().auth.user.name,
      sortable: false,
      wrap: true,
      width: '150px',
    },

    {
      name: <div>Subject</div>,
      width: '150px',
      selector: (row) => JSON.parse(row?.extra_info)?.subject_name,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Training Name</div>,
      selector: (row) => JSON.parse(row?.extra_info)?.course_name,
      sortable: false,
      wrap: true,
      width: '250px',
    },

    {
      name: <div>Payment For</div>,
      selector: (row) => row.payment_for,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Payment Mode</div>,
      selector: (row) => row.payment_mode,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Payment Date</div>,
      width: '120px',
      selector: (row) =>
        row.payment_date ? moment(row.payment_date).format('DD-MMM-YYYY') : '',
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Payment Method</div>,
      selector: (row) => row.payment_method,
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Fees</div>,
      selector: (row) => row.total_amount,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Payment Submit Type</div>,
      selector: (row) =>
        row.order_submit_type.toLowerCase() === 'submit'
          ? 
          <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
            {'Submitted'}
          </div>
          : 
          <div className="badge rounded-pill d-inline-block badge-draft font-size-12 fw-medium">
            {row.order_submit_type}
          </div>,
      sortable: false,
      wrap: true,
      width: '150px',
    },

    {
      name: <div>Verification Status</div>,
      width: '110px',
      selector: (row) => row.payment_verification_status,
      cell: (row) => {
        switch (row.payment_verification_status) {
          case 'Reject':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-rejected font-size-12 fw-medium">
                  {'Rejected'}
                </div>
              </>
            );

          case 'Draft':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-draft font-size-12 fw-medium">
                  {row.payment_verification_status}
                </div>
              </>
            );

          case 'Approved':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium">
                  {'Approved'}
                </div>
              </>
            );
            
          case 'Approve':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium">
                  {'Approved'}
                </div>
              </>
            );

          case 'Pending':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium">
                  {row.payment_verification_status}
                </div>
              </>
            );

          default:
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium">
                  {row.payment_verification_status}
                </div>
              </>
            );
        }
      },
    },

    {
      name: <div>Action</div>,
      style: actionColumnStyles,
      width: '230px',
      cell: (row) => (
        <>
          {/* btn-group-example */}
          <FormGroup className="btn-group mt-2 me-1">
            <Button
              size="sm"
              color="info"
              type="button"
              text={`<span class="fas fa-eye" ></span> View`}
              onClick={() => {
                modalShowHandler(true, row);
              }}
            />
            {row.order_submit_type &&
              row.order_submit_type.toLowerCase() === 'draft' &&
              row.payment_mode &&
              row.payment_mode.toLowerCase() === 'offline' && (
                <Button
                  size="sm"
                  color="success"
                  type="button"
                  text={`<span class="fas fa-edit" ></span> Edit`}
                  onClick={() => {
                    editHandler(row);
                  }}
                />
              )}
          </FormGroup>
          {row?.bank_deposit_slip && 
          <div className='mt-2'>
            <a
              href={config.APP_URL + row?.bank_deposit_slip}
              rel="noreferrer"
              target="_blank"
            >
              <p className="btn btn-sm btn-outline-primary ">
                Deposit Slip
              </p>
            </a>
          </div>}
        </>
      ),
    },
  ];

  // useEffect(() => {
  //   if (selectedSubject !== '') {
  //     setFieldValue('subject', selectedSubject?.value);
  //   }
  // }, [selectedSubject]);

  const handleApprovalStatus = (e: any) => {
    console.log(e.target.value);
    if(e?.target?.value === 'Approved'){
      setStatus(true);
    }
    else{
      setStatus(false);
    }
  };
  // useEffect(() => {
  //   if (isFcpsPaymentsLoading || !isFcpsPaymentsSuccess) {
  //     toast.dismiss();
  //     toast.loading('Data Loading...');
  //   } else {
  //     toast.dismiss();
  //     toast.success('Data Load Successfully');
  //   }
  // }, [isFcpsPaymentsLoading, isFcpsPaymentsSuccess]);

  return (
    <div className="page-content">
      {isFcpsPaymentsLoading || isFetching ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb breadcrumbItem={[{ link: '/', name: 'Dashboard' }]} title={'Payment List'} />
        <Card>
          <CardBody>
            <div>
              <Form
                className="custom-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="filter-wrapper mb-0">

                  {/* <FormGroup>
                    <Label for="subject">Subject</Label>
                    <Select
                      isClearable={true}
                      isSearchable={true}
                      value={selectedSubject}
                      onChange={setSelectedSubject}
                      options={allSubjects}
                      placeholder='Select...'
                      name="subject"
                    />
                  </FormGroup> */}

                  <FormGroup>
                    <Label for="from_date">From Date</Label>
                    <InputField
                      type="date"
                      placeholder=""
                      invalid={{ errors, touched }}
                      {...getFieldProps('from_date')}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="to_date">To Date</Label>
                    <InputField
                      type="date"
                      placeholder=""
                      invalid={{ errors, touched }}
                      {...getFieldProps('to_date')}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="payment_confirmation_ind">
                      Approval Status
                    </Label>
                    <InputField
                      type="select"
                      name='payment_confirmation_ind'
                      placeholder="Approval Status"
                      options={[
                        { value: 'Approved', label: 'Approved' },
                        { value: 'Pending', label: 'Pending' },
                      ]}
                      onChange={(e)=>{
                        handleChange(e);
                        handleApprovalStatus(e);
                      }}
                    />
                  </FormGroup>

                  <div className="btn-column mb-3">
                    <Button
                      color="danger"
                      type="reset"
                      text="Reset"
                      onClick={() => {
                        // setSelectedSubject('');
                        resetForm();
                        handleSubmit();
                      }}
                    />
                    <Button
                      type="button"
                      text="Search"
                      onClick={() => {
                        handleSubmit();
                      }}
                    />
                  </div>
                </div>
              </Form>
            </div>
            <DataTable
              columns={columns}
              data={fcpsPaymentList ? fcpsPaymentList : []}
              customStyles={dataTableCustomStyles}
              pagination
              paginationServer
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              progressPending={isFcpsPaymentsLoading}
              expandOnRowClicked
              highlightOnHover
              paginationTotalRows={fcpsPayments?.data?.total}
              paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
              responsive
              persistTableHead={true}
            />
          </CardBody>
        </Card>

        <Modal
          onOpened={function () {
            toast.dismiss();
          }}
          isOpen={modalShow}
          scrollable={true}
          size="lg"
          toggle={() => {
            document.body.classList.add('no_padding');
          }}
          backdrop={'static'}
          modalTransition={{ timeout: 0 }}
          backdropTransition={{ timeout: 0 }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {editFormActive && 'Edit '}
              Payment Details
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setModalShow(!modalShow);
              }}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <Row>
              <Col>
                {editFormActive &&
                  fcpsPayment.order_submit_type &&
                  fcpsPayment.order_submit_type.toLowerCase() === 'draft' && (
                    <UpdatedEditOfflinePayment
                      key={editCount}
                      fcpsPayment={fcpsPayment}
                      setShowAddForm={setModalShow}
                    />
                  )}

                {!editFormActive && (
                  <table className="table table-sm table-bordered table-striped">
                    <tbody>
                      <tr>
                        <td>
                          <span className="fw-semibold">Payment Date: </span>
                          <span>{moment(fcpsPayment.payment_date).format('DD-MMM-YYYY')}</span>
                        </td>
                        <td>
                          <span className="fw-semibold">Total Amount: </span>
                          <span>{fcpsPayment.total_amount}</span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span className="fw-semibold">Payment For: </span>
                          <span>{fcpsPayment.payment_for}</span>
                        </td>
                        <td>
                          <span className="fw-semibold">
                            Payment Method:{' '}
                          </span>
                          <span>{fcpsPayment.payment_method}</span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span className="fw-semibold">Bank Name: </span>
                          <span>{fcpsPayment.bank_name}</span>
                        </td>
                        <td>
                          <span className="fw-semibold">Branch Name: </span>
                          <span>{fcpsPayment.branch_name}</span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span className="fw-semibold">
                            Deposit Slip No:{' '}
                          </span>
                          <span>{fcpsPayment.deposit_slip_no}</span>
                        </td>
                        <td>
                          <span className="fw-semibold">
                            Payment Confirmed:{' '}
                          </span>
                          <span>
                            {fcpsPayment.payment_confirmation_ind
                              ? 'Yes'
                              : 'No'}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span className="fw-semibold">
                            Payment Verification Status:{' '}
                          </span>
                          <span>
                            {fcpsPayment.payment_verification_status}
                          </span>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </Col>
            </Row>
          </div>
        </Modal>
      </Container>
    </div>
  );
};

export default UpdatedOfflinePaymentList;
