import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { putUrlString } from 'src/helpers';
import {
  useGetDocumentFileUploadQuery,
  useUpdateDocumentFileUploadMutation,
} from 'src/rtk/features/setup/documentFileUpload/documentFileUploadApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import * as Yup from 'yup';

const initialValues = {
  document_for: '',
  document_type: '',
  document_name: '',
  file_type: '',
  file_size: 0,
  file_size_unit: '',
  status: false,
};

export const EditDocumentFileUpload = () => {
  const { id } = useParams<{ id: string }>();
  const { data: documentFileUpload, isError: isDeptFetchError } =
    useGetDocumentFileUploadQuery(id);
  const [updateDocumentFileUpload, { isSuccess, isError, error }] =
    useUpdateDocumentFileUploadMutation();
  const err: CustomError = error as CustomError;

  const fileSizeUnitOptions = [
    { value: 'KB', label: 'KB' },
    { value: 'MB', label: 'MB' },
    { value: 'GB', label: 'GB' },
  ];

  const { data: fileLookup } = useGetLookupDataQuery('file_types');

  const fileTypesOptions: SelectOption[] =
    fileLookup?.data[0].childs.map((item) => ({
      value: item.name,
      label: item.name,
    })) || [];

  const { data: fileUploadDocumentFor } = useGetLookupDataQuery(
    'file_upload_document_for',
  );

  const fileUploadDocumentForOptions: SelectOption[] =
    fileUploadDocumentFor?.data[0].childs.map((item) => ({
      value: item.name,
      label: item.name,
    })) || [];

  const history = useHistory();
  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    handleChange,
    values,
    setValues,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      document_name: Yup.string().required('Please Enter Document Name'),
    }),

    onSubmit: (values) => {
      const url = putUrlString(id, values);
      updateDocumentFileUpload(url);
    },
  });

  useEffect(() => {
    if (documentFileUpload && !isDeptFetchError) {
      const data: DocumentFileUpload = (documentFileUpload as any).data;
      setValues({
        document_for: data.document_for || '',
        document_type: data.document_type || '',
        document_name: data.document_name || '',
        file_type: data.file_type || '',
        file_size: data.file_size || 0,
        file_size_unit: data.file_size_unit || '',
        status: data.status || false,
      });
    }
  }, [documentFileUpload, isDeptFetchError]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Document File Upload updated successfully');
      history.push('/setup/document-file-upload');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      const errors: any = {};

      if (err?.data) {
        Object.keys(err?.data).forEach((key) => {
          errors[key] = err?.data[key][0];
        });
      }
      setErrors(errors);
    }
  }, [isSuccess, isError]);

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title="Edit Document File Upload"
          breadcrumbItem={[
            {
              link: '/setup/document-file-upload/',
              name: 'Document File Upload',
            },
          ]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-8 col-sm-12 ">
            <div className="card mt-3">
              <h5 className="card-header">
                <i className="fa fa-edit me-2" />
                Edit Document File
              </h5>
              <div className="card-body">
                <Form
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <Row>
                    <FormGroup className="mb-3">
                      <Label for="document_name">Document Name</Label>
                      <InputField
                        type="text"
                        placeholder="Document Name"
                        invalid={{ errors, touched }}
                        {...getFieldProps('document_name')}
                      />
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <Label for="document_for">Document For</Label>
                      <InputField
                        type="select"
                        name="document_for"
                        options={[
                          // { value: '', label: 'Select Document For' },
                          ...fileUploadDocumentForOptions,
                        ]}
                        invalid={{ errors, touched }}
                        value={values.document_for}
                        onChange={handleChange}
                      />
                    </FormGroup>

                    <FormGroup className="mb-3 col-md-6 col-sm-12">
                      <Label for="document_type">Document Type</Label>
                      <InputField
                        type="text"
                        placeholder="Document Type"
                        invalid={{ errors, touched }}
                        {...getFieldProps('document_type')}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3 col-md-6 col-sm-12">
                      <Label for="file_type">File Type</Label>
                      <InputField
                        type="select"
                        name="file_type"
                        options={[
                          // { value: '', label: 'Select File Type' },
                          ...fileTypesOptions,
                        ]}
                        invalid={{ errors, touched }}
                        value={values.file_type}
                        onChange={handleChange}
                      />
                    </FormGroup>

                    <FormGroup className="md-3 col-md-6 col-sm-12">
                      <Label for="file_size">File Size</Label>
                      <InputField
                        type="number"
                        placeholder="File Size"
                        invalid={{ errors, touched }}
                        {...getFieldProps('file_size')}
                      />
                    </FormGroup>

                    <FormGroup className="md-3 col-md-6 col-sm-12">
                      <Label for="name">File Size Unit</Label>
                      <InputField
                        type="select"
                        name="file_size_unit"
                        options={[
                          // { value: '', label: 'Select File Size Unit' },
                          ...fileSizeUnitOptions,
                        ]}
                        invalid={{ errors, touched }}
                        value={values.file_size_unit}
                        onChange={handleChange}
                      />
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <h6>Active Status</h6>
                      <FormGroup check className="mb-3">
                        <Input
                          type="checkbox"
                          role="check"
                          {...getFieldProps('status')}
                        />
                        <Label check>Active</Label>
                      </FormGroup>
                    </FormGroup>

                    <div className="text-center">
                      <Button className="w-25" type="submit" text="Submit" />
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
