import { useEffect, useState } from 'react';
import Select from 'react-select';
import { Label } from "reactstrap";
import { useLazyGetDepartmentsByInstituteQuery } from 'src/rtk/features/setup/department/departmentApi';
import { useGetSupervisorsByInstituteQuery } from 'src/rtk/features/trainingRecord/trainingRecordlApi';

export const SessionApplyComponent = (props: any) => {

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    allInstitute,
    fcpsPartOneInfo,
  } = props;

  const [selectedInstitue, setSelectedInstitute] = useState<any>('');
  const [selectedDepartment, setSelectedDepartment] = useState<any>('');
  const [selectedSupervisor, setSelectedSupervisor] = useState<any>('');
  const [instituteName, setInstituteName] = useState('');
  const [instituteForDeparment, setInstituteForDeparment] = useState('');

  const [getDepartmentsByInstitute, { data: departmentLookupData }] = useLazyGetDepartmentsByInstituteQuery();

  useEffect(() => {
    if (instituteForDeparment !== '') {
      getDepartmentsByInstitute(instituteForDeparment);
    }
  }, [instituteForDeparment]);

  const allDepartment: SelectOption[] =
    departmentLookupData?.data?.map((department) => ({
      value: department.department_name,
      label: department.department_name,
    })) || [];

  const { data: supervisors } = useGetSupervisorsByInstituteQuery(instituteName);

  const allSupervisor: SelectOption[] =
    supervisors?.data
      ?.filter((supervisor) => supervisor?.available_slot! > 0)
      ?.map((supervisor) => ({
        value: supervisor?.user_id!,
        label: supervisor?.full_name! + ` (Available ${supervisor?.available_slot} out of ${supervisor?.session_count! + supervisor?.available_slot!})`,
      })) || [];

  useEffect(() => {
    console.log(selectedInstitue?.value);
    if (selectedInstitue !== '') {
      setFieldValue('institute_name', selectedInstitue?.value);
      setInstituteName(selectedInstitue?.value);
      setInstituteForDeparment(selectedInstitue?.value);
    }
  }, [selectedInstitue]);

  useEffect(() => {
    console.log(selectedDepartment?.value);
    if (selectedDepartment !== '') {
      setFieldValue('department', selectedDepartment?.value);
    }
  }, [selectedDepartment]);

  useEffect(() => {
    console.log(selectedSupervisor?.value);
    if (selectedSupervisor !== '') {
      setFieldValue('supervisor_id', selectedSupervisor?.value);
    }
  }, [selectedSupervisor]);

  useEffect(() => {
    if (fcpsPartOneInfo?.fcps_part1_info) {
      fcpsPartOneInfo?.fcps_part1_info?.fcps_part_one_passing_session === 'January' ?
        setFieldValue('course_session', 'January-June')
        :
        setFieldValue('course_session', 'July-December');
    }
  }, [fcpsPartOneInfo]);

  return (
    <div className="row justify-content-center">
      <div className="col-md-10">
        {/* <div className='mb-3'>
                  <Label for="course_session" className='required-field'>Session</Label>
                  <Input
                    type="select"
                    name='course_session'
                    // options={sessionOptions}
                    // invalid={{ errors, touched }}
                    value={values.course_session}
                    onChange={(e) => {
                      // handleSessionChange(e);
                      handleChange(e)
                    }}
                  // {...getFieldProps('course_session')}
                  >
                    {sessionOptions?.map((sessionSelect) => (
                      <option key={sessionSelect.label} value={sessionSelect.value}>
                        {sessionSelect.label}
                      </option>
                    ))}
                  </Input>
                  {errors.course_session && touched.course_session ? (
                    <div className="text-danger">{errors.course_session}</div>
                  ) : null}
                </div> */}

        <div className='mb-3'>
          <Label className="form-label">Institute</Label>
          {/* <Input
                    type="select"
                    name="institute_name"
                    // value={values.institute_name}
                    onChange={(e) => {
                      handleInstitute(e);
                    }}
                  >
                    <option value="">Select Institute</option>
                    {instituteList.map((instituteItem) => (
                      <option key={instituteItem.id} value={instituteItem.organization_name}>
                        {instituteItem.organization_name}
                      </option>
                    ))}
                  </Input> */}
          <Select
            isClearable={true}
            isSearchable={true}
            value={selectedInstitue}
            onChange={setSelectedInstitute}
            options={allInstitute}
            placeholder='Select Institute'
            name="institute_name"
          />
        </div>

        <div className='mb-3'>
          <Label className="form-label">Department</Label>
          {/* <Input
                    type="select"
                    name="department"
                    value={values.department}
                    onChange={handleChange}
                  >
                    <option value="">Select Department</option>
                    {departmentList?.map((item) => (
                      <option key={item.id} value={item.department_name}>
                        {item.department_name}
                      </option>
                    ))}
                  </Input> */}
          <Select
            isClearable={true}
            isSearchable={true}
            value={selectedDepartment}
            onChange={setSelectedDepartment}
            options={allDepartment}
            placeholder='Select Department'
            name="department"
          />
          {errors.department && touched.department ? (
            <div className="text-danger">{errors.department}</div>
          ) : null}
        </div>

        <div className='mb-3'>
          <Label className="form-label required-field">Supervisor</Label>
          {/* <Input
                    type="select"
                    name="supervisor_id"
                    value={values.supervisor_id}
                    onChange={handleChange}
                  >
                    <option value="">Select Supervisor</option>
                    {supervisorList?.map((item) => (
                      <option key={item.id} value={item.user_id}>
                        {item.full_name+` (Trainee ${item?.available_slot} out of ${item?.session_count! + item?.available_slot!})`}
                      </option>
                    ))}
                  </Input> */}
          <Select
            isClearable={true}
            isSearchable={true}
            value={selectedSupervisor}
            onChange={setSelectedSupervisor}
            options={allSupervisor}
            placeholder='Select Supervisor'
            name="supervisor_id"
          />
          {errors.supervisor_id && touched.supervisor_id ? (
            <div className="text-danger">{errors.supervisor_id}</div>
          ) : null}
        </div>
      </div>
    </div>
  )
};