import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import { Col, Container, Form, FormGroup, Label, Modal, Row } from 'reactstrap';
import { Button, InputField, NonPermissonMessage } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import useCheckPermissions from 'src/hooks/useCheckPermissions';
import store from 'src/rtk/app/store';
import { useGetTraineeLogListQuery } from 'src/rtk/features/e-log-book/traineeAcademicActivitiesApi';
import {
  useGetFormByLogbookTypeQuery,
} from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import loader from '../../../../assets/images/loader.gif';
import EditDynamicForm from './EditDynamicLogbookEntry';

const TraineeAcademicActivitiesList = () => {
  document.title = 'Academic Activities | BCPS';
  const { id: formValue } = useParams<{ id: string }>();
  const [formName, setFormName] = useState<string>(formValue?.replaceAll('%2F', '/').replaceAll('%2C', ',').replaceAll('%2E', '.'));
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editFormActive, setEditFormActive] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>({});
  const [logbookEntryDetails, setLogbookEntryDetails] = useState<any>({});
  const [form, setForm] = useState<LogbookEntrySetupMaster>(
    {} as LogbookEntrySetupMaster,
  );
  const [traineeLogList, setTraineeLogList] = useState<any>([]);
  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');
  const [approveStatus, setApproveStatus] = useState<string>('');
  const [note, setNote] = useState<string>('');

  const havePermissons: any = useCheckPermissions(
    'TRAINEE_ACADEMIC_ACTIVITIES',
  );

  interface SearchFields {
    form?: string;
    type?: string;
    from_date?: string;
    to_date?: string;
    approved_status?: string;
  }

  const initialValues: SearchFields = {
    form: formName ? formName : '',
    type: '',
    from_date: '',
    to_date: '',
    approved_status: '',
  };

  const { data: formSetups, isLoading: formSetupsLoading } =
    useGetFormByLogbookTypeQuery(2); // 2 for Academic Activities
  const forms =
    (formSetups?.data && formSetups?.data[0]?.entry_setup_master) || [];
  let formOptions: SelectOption[] = [
    ...forms?.map((e: LogbookEntrySetupMaster) => ({
      label: e.particulars!,
      value: e.particulars!,
    })),
  ];

  const generateQueryUrl = () => {
    return `2?page=${page}&per_page=${limit}${search}`;
  };

  const {
    data: traineeLogData,
    isLoading: traineeLogListLoading,
    isSuccess,
    isError,
  } = useGetTraineeLogListQuery(generateQueryUrl()); // 2 for Academic Activities

  // const handleSort = (column: string, sortDirection: string) => {
  //   console.log(column, sortDirection);
  // };

  const editModal = (data: any) => {
    setEditData(data);
    havePermissons.edit && setEditFormActive(true);
    setShowModal(true);
  };

  const viewModal = (data: any) => {
    setEditData(data);
    let logbookEntryDetails = JSON.parse(data.details_json) || {};
    setLogbookEntryDetails(logbookEntryDetails);
    setEditFormActive(false);
    setApproveStatus(data?.approved_status?.toLowerCase()!);
    setNote(data?.note!);
    setShowModal(true);
  }; 

  useEffect(() => {
    if (traineeLogData) {
      const tData = traineeLogData?.data || [];
      setTraineeLogList(tData);
    }
  }, [traineeLogData]);

  // const ExpandedComponent: React.FC<ExpanderComponentProps<any>> = ({
  //   data,
  // }) => {
  //   let logbookEntryDetails = JSON.parse(data.details_json) || {};

  //   return (
  //     <>
  //       <table className="table table-bordered border-dark text-capitalize">
  //         <tbody>
  //           {Object.entries(logbookEntryDetails).map((entry: any) => {
  //             let formLabel = entry[0]
  //               .replace(/([A-Z])/g, ' $1')
  //               .trim()
  //               .replaceAll('_', ' ');
  //             return (
  //               !['entry_setup_master_id'].includes(entry[0]) && (
  //                 <tr key={entry[0]}>
  //                   <th className="bg-light" style={{ width: '25%' }}>
  //                     {formLabel}
  //                   </th>
  //                   <td>{entry[1]}</td>
  //                 </tr>
  //               )
  //             );
  //           })}
  //         </tbody>
  //       </table>
  //     </>
  //   );
  // };

  const getDetailsData = (key = '', dataObj: any = {}) => {
    let perseData: any = {};

    if (
      dataObj['details_json'] &&
      typeof dataObj['details_json'] === 'string'
    ) {
      perseData = JSON.parse(dataObj.details_json);
    } else {
      perseData = dataObj;
    }

    return perseData[key] ? (
      <span dangerouslySetInnerHTML={{ __html: perseData[key] }}></span>
    ) : (
      ''
    );
  };

  const decodeBase64EncodeString = (base64String: string) => {
    let correctBase64EncodedString: string = base64String.split("base64,")?.[1];
    let tempString: string = base64String.split("base64,")?.[0];
    let typeOfDocument: string = tempString.split("data:")?.[1] + ';base64';
    let byteCharacters = atob(correctBase64EncodedString);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: typeOfDocument });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const columns: TableColumn<any>[] = [
    {
      name: '#',
      width: '60px',
      cell: (row, index) => traineeLogList.from + index,
    },
    {
      name: 'Date',
      selector: (row) =>
        row.created_at ? moment(row.created_at).format('DD/MMM/YYYY h:mm:ss a') : '',
      sortable: false,
      wrap: true,
    },

    {
      name: 'Form',
      selector: (row) => row.heading,
      sortable: false,
      wrap: true,
    },
    {
      name: 'System Type',
      selector: (row) => getDetailsData('systemType', row) ? getDetailsData('systemType', row) : 'N/A',
      sortable: false,
      wrap: true,
    },

    {
      name: 'Topic Type',
      selector: (row) => getDetailsData('topicType', row) ? getDetailsData('topicType', row) : 'N/A',
      sortable: false,
      wrap: true,
    },

    {
      name: 'Skill Type',
      selector: (row) => JSON.parse(row?.details_json)?.['systemType'] === JSON.parse(row?.details_json)?.['skillType'] ? 'N/A' : getDetailsData('skillType', row) ? getDetailsData('skillType', row) : 'N/A',
      sortable: false,
      wrap: true,
    },

    // {
    //   name: 'Patient Name',
    //   selector: (row) => getDetailsData('Patient Name', row),
    //   sortable: false,
    // },
    // {
    //   name: 'Reg No',
    //   selector: (row) => getDetailsData('Reg No', row),
    //   sortable: false,
    // },
    // {
    //   name: 'Diagnosis/Findings',
    //   selector: (row) => getDetailsData('Diagnosis/Findings', row),
    //   sortable: false,
    // },

    // {
    //   name: 'Brief Procedural Note / Interpretation',
    //   selector: (row) =>
    //     getDetailsData('Brief Procedural Note / Interpretation', row),
    //   sortable: false,
    // },

    {
      name: 'Status',
      selector: (row) => row.approved_status,
      cell: (row) => {
        switch (row.approved_status.toLowerCase()) {
          case 'reject':
            return (
              <>
                <div className='badge rounded-pill d-inline-block  badge-rejected font-size-12 fw-medium'>
                  {'Rejected'}
                </div>
              </>
            );

          case 'draft':
            return (
              <>
                <div className='badge rounded-pill d-inline-block  badge-draft font-size-12 fw-medium'>
                  {row.approved_status}
                </div>
              </>
            );

          case 'approved':
            return (
              <>
                <div className='badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium'>
                  {row.approved_status}
                </div>
              </>
            );

          case 'correction':
            return (
              <>
                <div className='badge rounded-pill d-inline-block  badge-correction font-size-12 fw-medium'>
                  {row.approved_status}
                </div>
              </>
            );

          case 'pending':
            return (
              <>
                <div className='badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium'>
                  {row.approved_status}
                </div>
              </>
            );

          default:
            return (
              <>
                <div className='badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium'>
                  {row.approved_status}
                </div>
              </>
            );
        }
      },
      sortable: false,
    },
    {
      name: 'Action',
      width: '150px',
      cell: (row) => (
        <>
          {/* btn-group-example */}
          <FormGroup className='d-flex gap-1 mt-2'>
            {havePermissons?.view && (
              <Button
                size='sm'
                color='info'
                type='button'
                text={`View`}
                onClick={() => {
                  viewModal(row);
                }}
              />
            )}
            {havePermissons?.edit &&
              row.approved_status &&
              (row.approved_status.toLowerCase() === 'draft' ||
                row.approved_status.toLowerCase() === 'correction') && (
                <Button
                  size='sm'
                  color='success'
                  type='button'
                  text={`Edit`}
                  onClick={() => {
                    editModal(row);
                  }}
                />
              )}
          </FormGroup>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      // button: true,
    },
  ];

  const {
    handleSubmit,
    handleChange,
    getFieldProps,
    setFieldValue,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
    isSubmitting,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.form !== '') {
        s = s + `&heading=${values.form}`;
      }

      if (values.from_date !== '') {
        s = s + `&from_date=${values.from_date}`;
      }

      if (values.to_date !== '') {
        s = s + `&to_date=${values.to_date}`;
      }

      if (values.approved_status !== '') {
        s = s + `&approved_status=${values.approved_status}`;
      }

      setSearch(s);
      setPage('1');
      setFormName('');
      // setLimit(20);
    },
  });

  useEffect(() => {
    if (isError) {
      toast.dismiss();
      toast.error('Data Not Found');
    }
  }, [traineeLogListLoading, isSuccess, isError]);

  return (
    <div className='page-content'>
      {traineeLogListLoading ? (
        <div className='overlay'>
          <img src={loader} alt='Loading' height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <div className='page-breadcrumb-wrapper'>
          <BetterBreadcrumb
            title='Academic Activities'
            breadcrumbItem={[
              { link: '/trainee/logbook/academic-activities/add', name: 'Academic Activities Entry' },
            ]}
          />
          <div>
            <a
              type='button'
              rel='noreferrer'
              className='btn btn-link font-size-14 text-decoration-underline'
              href={
                store?.getState()?.auth?.user?.details?.subject?.subject_name?.toLowerCase() === 'medicine' ?
                  '/documents/Logbook Essential Information - Medicine.pdf'
                  : store?.getState()?.auth?.user?.details?.subject?.subject_name?.toLowerCase() === 'surgery' ?
                    '/documents/Logbook Essential Information - Surgery.pdf'
                    : store?.getState()?.auth?.user?.details?.subject?.subject_name?.toLowerCase() === 'paediatrics' ?
                      '/documents/Logbook Essential Information - Paediatrics.pdf'
                      : ''
              }
              target='_blank'>
              Essential Info
            </a>
            {havePermissons?.create && (
              <Link
                to={window.location.pathname + '/add'}
                className='btn btn-success waves-effect waves-light'
              >
                Create New
              </Link>
            )}
          </div>
        </div>
        {Object.keys(havePermissons).length > 0 && (
          <>

            <div className='card p-0 mt-3'>
              {/* <div className="card-header">
                  <Row>
                    <Col>
                      <div className="gap-2 ">

                      </div>
                    </Col>
                  </Row>
                </div> */}


              <div className='card-body'>
                <Form
                  className='custom-form'
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >

                  <div className='filter-wrapper'>
                    {havePermissons?.search && (
                      <>
                        <FormGroup>
                          <Label for='form'>Form</Label>
                          <select
                            className='form-select'
                            id='form'
                            name='form'                            
                            onChange={handleChange}
                          >
                            {formOptions.map((form) => {
                              return (
                                <option
                                  key={form.value}
                                  className='form-control'
                                  value={form.value}
                                  selected={values.form === form.value}
                                >
                                  {form.label}
                                </option>
                              );
                            })}
                          </select>
                        </FormGroup>

                        {/* <FormGroup>
                            <Label for="type">Type</Label>
                            <InputField
                              type="select"
                              placeholder=""
                              options={[]}
                              invalid={{ errors, touched }}
                              {...getFieldProps('type')}
                            />
                          </FormGroup> */}

                        <FormGroup>
                          <Label for='from_date'>From Date</Label>
                          <InputField
                            type='date'
                            placeholder=''
                            invalid={{ errors, touched }}
                            {...getFieldProps('from_date')}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label for='to_date'>To Date</Label>
                          <InputField
                            type='date'
                            placeholder=''
                            invalid={{ errors, touched }}
                            {...getFieldProps('to_date')}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label for='status'>Status</Label>
                          <InputField
                            type='select'
                            placeholder=''
                            options={[
                              { label: 'Approved', value: 'Approved' },
                              { label: 'Draft', value: 'Draft' },
                              { label: 'Pending', value: 'Pending' },
                              { label: 'Rejected', value: 'Reject' },
                            ]}
                            invalid={{ errors, touched }}
                            {...getFieldProps('approved_status')}
                          />
                        </FormGroup>

                        <FormGroup className='btn-group btn-group-example mb-3'>
                          <Button
                            color='danger'
                            type='reset'
                            text='Reset'
                            onClick={() => {
                              resetForm();
                              handleSubmit();
                            }}
                          />
                        </FormGroup>
                        <FormGroup className='btn-group btn-group-example mb-3'>
                          <Button
                            type='button'
                            text='Search'
                            onClick={() => {
                              handleSubmit();
                            }}
                          />
                        </FormGroup>
                      </>
                    )}
                  </div>
                </Form>
                {havePermissons?.list && (
                  <DataTable
                    columns={columns}
                    data={
                      !formName && traineeLogList.data
                        ? traineeLogList.data || []
                        : traineeLogList.data?.filter(
                          (t: any) =>
                            JSON.parse(t.details_json).systemType ===
                            formName,
                        ) || []
                    }
                    pagination
                    paginationServer
                    onChangePage={(page: number) => {
                      setPage(page);
                    }}
                    onChangeRowsPerPage={(
                      newPerPage: number,
                      page: number,
                    ) => {
                      setLimit(newPerPage);
                      setPage(page);
                    }}
                    // progressPending={traineeLogListLoading}
                    paginationTotalRows={traineeLogList.total}
                    expandOnRowClicked
                    highlightOnHover
                    paginationRowsPerPageOptions={
                      config.paginationRowsPerPageOptions
                    }
                    persistTableHead
                  />
                )}
              </div>

            </div>


            <Modal
              onOpened={function () {
                toast.dismiss();
              }}
              isOpen={showModal}
              scrollable={true}
              size='lg'
              toggle={() => {
                document.body.classList.add('no_padding');
              }}
              backdrop={'static'}
              modalTransition={{ timeout: 0 }}
              backdropTransition={{ timeout: 0 }}
            >
              <div className='modal-header bg-light'>
                <h5 className='modal-title' id='staticBackdropLabel'>
                  {editFormActive && 'Edit '}
                  Logbook Details
                </h5>
                <button
                  type='button'
                  className='btn-close'
                  onClick={() => {
                    setShowModal(!showModal);
                  }}
                  aria-label='Close'
                ></button>
              </div>
              <div className='modal-body'>
                <Row>
                  <Col>
                    {editFormActive &&
                      editData.approved_status &&
                      (editData.approved_status.toLowerCase() === 'draft' ||
                        editData.approved_status.toLowerCase() === 'correction') && (
                        <EditDynamicForm
                          logbookData={editData}
                          setShowAddForm={setShowModal}
                        />
                      )}

                    {!editFormActive && (
                      <>
                        <table className='table table-bordered table-striped table-sm text-capitalize'>
                          <tbody>
                            {Object.entries(logbookEntryDetails).map(
                              (entry: any) => {
                                let formLabel = entry[0]
                                  .replace(/([A-Z])/g, ' $1')
                                  .trim()
                                  .replaceAll('_', ' ');
                                return (
                                  !['entry_setup_master_id', 'competency_group'].includes(
                                    entry[0],
                                  ) && (
                                    <tr key={entry[0]}>
                                      <th
                                        style={{ width: '35%' }}
                                        dangerouslySetInnerHTML={{
                                          __html: formLabel,
                                        }}
                                      ></th>
                                      <td>
                                        {typeof entry[1] === 'string' &&
                                          entry[1]?.toLowerCase()?.includes('base64') ? (
                                          <a
                                            className='btn btn-sm btn-outline-info'
                                            rel='noreferrer'
                                            target='_blank'
                                            onClick={(e) => {
                                              decodeBase64EncodeString(entry[1]);
                                            }}
                                          >
                                            View
                                          </a>
                                        ) : (
                                          entry[1]
                                        )}
                                      </td>
                                    </tr>
                                  )
                                );
                              },
                            )}
                            <tr>
                              <th style={{ width: '35%' }}>
                                Status
                              </th>
                              <td>{approveStatus.toLowerCase() === 'reject' ? 'Rejected' : approveStatus}</td>
                            </tr>
                            {approveStatus.toLowerCase() === 'correction' ||
                              approveStatus.toLowerCase() === 'reject' ||
                              (approveStatus.toLowerCase() === 'approved' && note) ?
                              <tr>
                                <th style={{ width: '35%' }}>
                                  {approveStatus.toLowerCase() === 'correction' ? 'Correction Note' :
                                    approveStatus.toLowerCase() === 'reject' ? 'Rejection Note' :
                                      (approveStatus.toLowerCase() === 'approved' && note) ? 'Approval Note' : ''}
                                </th>
                                <td>
                                  <span className={approveStatus.toLowerCase() === 'approved' ? 'text-success' :
                                    approveStatus.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}>
                                    {note}
                                  </span>
                                </td>
                              </tr> : null}
                          </tbody>
                        </table>
                        <div className='col-sm-12  border-top border-light'>
                          <div className='text-end mt-3'>
                            {editData.approved_status &&
                              (editData.approved_status.toLowerCase() ===
                                'draft' || editData.approved_status.toLowerCase() ===
                                'correction') && (
                                <Button
                                  size='md'
                                  color='success'
                                  type='button'
                                  text={`<span class='fas fa-edit' ></span> Edit`}
                                  onClick={() => {
                                    editModal(editData);
                                  }}
                                />
                              )}
                            <Button
                              className='ms-2'
                              size='md'
                              color='danger'
                              type='button'
                              text={`<span class='fas fa-times' ></span> Close`}
                              onClick={() => {
                                setShowModal(!showModal);
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </div>
            </Modal>
          </>
        )}
        {Object.keys(havePermissons).length == 0 && (
          <>
            <NonPermissonMessage />
          </>
        )}
      </Container>
    </div>
  );
};

export default TraineeAcademicActivitiesList;
