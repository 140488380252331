import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import defaultAvatar from 'src/assets/images/users/default-avatar.png';
import { InputField } from 'src/components';
import { config } from 'src/config';
import { useCreateSupervisorApprovalMutation } from 'src/rtk/features/fellowSupervisorApply/fellowSupervisorApplyApi';
import { useGetMyRegInfoQuery } from 'src/rtk/features/registration-approval/registrationApprovalApi';
import * as Yup from 'yup';

interface Props {
  isOpen: boolean;
  toggle: any;
  regNo: string;
}

const initialValues = {
  registration_id: '',
  status: '',
  message: '',
};

const SupervisorApprovalViewModal = (props: Props) => {
  const { isOpen, toggle, regNo } = props;

  const { data, isLoading: isDataLoading, isSuccess: isDataSuccess, isError: isDataError, error: error1 } = useGetMyRegInfoQuery(regNo);

  const userInfo = data?.data || ({} as RegInfo);
  const [createSupervisorApproval, { isSuccess, isError, error, isLoading }] = useCreateSupervisorApprovalMutation();

  const err = error as CustomError;

  const {
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    handleChange,
    getFieldProps,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      message: Yup.string().when('status', (status, schema) => {
        if (status === 'Correction') {
          return schema.required('Correction note is Required!');
        }
        return schema;
      }),
    }),
    onSubmit: (values) => {
      values.registration_id = regNo;
      console.log(values);
      createSupervisorApproval(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success('Approval Submitted Successfully');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
    }
  }, [isSuccess, isError]);


  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="lg">
      <ModalHeader toggle={toggle}>Applicant View</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <ModalBody>
          <div>
            <Row>
              <Col md={2}>
                <div className="img-fluid img-rounded text-center">
                  <img
                    src={
                      userInfo?.personal_info?.picture
                        ? `${config?.APP_URL}${userInfo?.personal_info?.picture}`
                        : defaultAvatar
                    }
                    width="120"
                    className="img-thumbnail"
                  />
                </div>
              </Col>
              <Col md={4}>
                <div><strong>Name: </strong> {userInfo?.personal_info?.full_name}</div>
                <div><strong>Speciliaty: </strong> {userInfo?.subject?.subject_name}</div>
              </Col>
            </Row>
            <>
              <h6 className="block-heading mt-3">Current Work Information</h6>
              <Card>
                <CardBody>
                  <ul className="list-unstyled mb-0">
                    <Row>
                      <Col>
                        <li>
                          <strong>Job Title: </strong>
                          {userInfo?.current_employment_info?.designation ?? 'N/A'}
                        </li>

                        <li>
                          <strong>Institute: </strong>
                          {userInfo?.current_employment_info?.institute_name ?? 'N/A'}
                        </li>

                        <li>
                          <strong>Department: </strong>
                          {userInfo?.current_employment_info?.department_name ?? 'N/A'}
                        </li>

                        <li>
                          <strong>From Date: </strong>
                          {userInfo?.current_employment_info?.from_date ?? 'N/A'}
                        </li>
                      </Col>
                      <Col>

                        <li>
                          <strong>Institute Type: </strong>
                          {userInfo?.current_employment_info?.employment_type ?? 'N/A'}
                        </li>

                        <li>
                          <strong>Head of the Department: </strong>

                          {userInfo?.current_employment_info?.department_head
                            ? <span className="badge rounded-pill d-inline-block  badge-approved font-size-12">
                              Yes
                            </span>
                            : <span className="badge rounded-pill d-inline-block  badge-pending font-size-12">
                              No
                            </span>}
                        </li>
                        <li>
                          <strong>In Charge of Unit: </strong>

                          {userInfo?.current_employment_info?.in_charge
                            ? <span className="badge rounded-pill d-inline-block  badge-approved font-size-12">
                              Yes
                            </span>
                            : <span className="badge rounded-pill d-inline-block  badge-pending font-size-12">
                              No
                            </span>}
                        </li>
                        <li>
                          <strong>Unit Name: </strong>
                          {userInfo?.current_employment_info?.unit_name}

                        </li>
                      </Col>
                    </Row>
                  </ul>
                </CardBody>
              </Card>
            </>
            <Row>
              <Col>
                <h6 className="block-heading mt-3">Supervispr Eligibility Criteria</h6>
                <Card>
                  <CardBody>
                    <>
                      <div className='mb-1'>
                        Professor or Associate Professor of an institute &nbsp;
                        {userInfo?.current_employment_info?.designation === 'Professor' || userInfo?.current_employment_info?.designation === 'Associate Professor' ?
                          <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                          : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                        }
                      </div>
                      <div className='mb-1'>
                        Assistant Professor or Consultant holding a clinical unit as in-charge of an accreditated institute &nbsp;
                        {userInfo?.current_employment_info?.designation === 'Assistant Professor' || userInfo?.current_employment_info?.in_charge ?
                          <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                          : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                        }
                      </div>
                      <div className='mb-1'>
                        Consultant working on District Hospital &nbsp;
                        {userInfo?.current_employment_info?.designation === 'Consultant' || userInfo?.current_employment_info?.institute_name === 'District' ?
                          <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                          : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                        }
                      </div>
                      <div>
                        Head of the Department of an institute/hospital accreditated by BCPS &nbsp;
                        {userInfo?.current_employment_info?.department_head ?
                          <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                          : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                        }
                      </div>
                    </>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {userInfo?.approval_for_fellow_supervisor_apply !== 'Approve' && userInfo?.approval_for_fellow_supervisor_apply !== 'Reject' ?
              <div>
                <h6 className="block-heading">Supervisor Approval</h6>
                <table className="table table-sm table-bordered table-striped">
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <FormGroup check inline className="mb-3 ">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="status"
                              id="approve"
                              checked={values.status === 'Approve'}
                              onChange={(e) => {
                                setFieldValue('status', 'Approve');
                              }}
                            />
                            <label
                              className="form-check-label text-success"
                              htmlFor="approve"
                            >
                              Approve
                            </label>
                          </FormGroup>

                          <FormGroup check inline className="mb-3 ">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="status"
                              id="reject"
                              checked={values.status === 'Reject'}
                              onChange={(e) => {
                                setFieldValue('status', 'Reject');
                              }}
                            />
                            <label
                              className="form-check-label text-danger"
                              htmlFor="reject"
                            >
                              Reject
                            </label>
                          </FormGroup>

                          <FormGroup check inline className="mb-3 ">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="status"
                              id="correction"
                              checked={values.status === 'Correction'}
                              onChange={(e) => {
                                setFieldValue('status', 'Correction');
                              }}
                            />
                            <label
                              className="form-check-label text-info"
                              htmlFor="correction"
                            >
                              Correction
                            </label>
                          </FormGroup>

                        </div>
                      </td>
                    </tr>
                    {values.status && <tr>
                      <td>
                        <Label>{values.status} Note</Label>
                        <InputField
                          type="textarea"
                          placeholder='Enter Note'
                          invalid={{ errors, touched }}
                          {...getFieldProps('message')}
                        />
                      </td>
                    </tr>}
                  </tbody>
                </table>
              </div> : null}

          </div>
        </ModalBody>

        <ModalFooter>
          <div className="d-flex gap-2 ">
            {userInfo?.approval_for_fellow_supervisor_apply !== 'Approve' && userInfo?.approval_for_fellow_supervisor_apply !== 'Reject' ?
              <button
                type="submit"
                className="btn btn-primary"
              >
                Save
              </button>: null}
            <Button color="danger" onClick={toggle}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default SupervisorApprovalViewModal;
