import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Select from 'react-select';
import {
    Card,
    CardBody,
    Container,
    Form,
    FormGroup,
    Label
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetThesisDissertationOldTitleListQuery } from 'src/rtk/features/disertation-thesis/thesisDissertationOldTitleListApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoGlobalApi';
import loader from '../../../assets/images/loader.gif';

interface SearchFields {
    search?: string;
    year_of_submission?: string;
    subject_name?: string;
}

const initialValues: SearchFields = {
    search: '',
    year_of_submission: '',
    subject_name: '',
};

const ListOfDissertationTitle = () => {
    document.title = 'Dissertation/Thesis Accepted Titles | BCPS';
    const [page, setPage] = useState<any>('1');
    const [paginate_per_page, set_paginate_per_page] = useState<any>(10);
    const [search, setSearch] = useState('');
    const [selectedSubject, setSelectedSubject] = useState<any>('');

    const { data: thesisDissertationListData, error: thesisDissertationListDataError, isLoading: isThesisDissertationListDataLoading, isError: isThesisDissertationListDataError } = useGetThesisDissertationOldTitleListQuery({
        page,
        paginate_per_page,
        search
    });

    const thesisDissertationList = thesisDissertationListData?.data || [];

    const { data: subjectList, isError: isSubFetchError } =
        useGetSubjectInfosQuery();

    const allSubjects: SelectOption[] =
        subjectList?.data?.map((subject) => ({
            value: subject.subject_name!,
            label: subject.subject_name,
        })) || [];

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        set_paginate_per_page(newPerPage);
        setPage(page);
    };

    const columns: TableColumn<any>[] = [
        {
            name: <div>Sl. No.</div>,
            width: '80px',
            cell: (row, index) => <strong>{((page - 1) * paginate_per_page + index) + 1}</strong>,
            center: true,
        },

        {
            name: <div>Name of Candidate</div>,
            width: '200px',
            selector: (row) =>
                row.trainee_name,
            sortable: false,
            wrap: true,
        },

        {
            name: <div>BMDC Reg. No.</div>,
            width: '100px',
            selector: (row) => '-',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Year</div>,
            width: '70px',
            selector: (row) =>
                row.year_of_submission,
            sortable: false,
            center: true,
            wrap: true,
        },

        {
            name: <div>Type</div>,
            width: '70px',
            selector: (row) => '-',
            sortable: false,
            center: true,
            wrap: true,
        },

        {
            name: <div>Title of Dissertation/Thesis</div>,
            selector: (row) =>
                row.title_of_thesis,
            sortable: false,
            wrap: true,
            width: '750px',
        },

        {
            name: <div>Progress Status</div>,
            selector: (row) =>
                row.progress_status ?? '-',
            sortable: false,
            wrap: true,
            center: true,
        },
    ];

    const {
        handleSubmit,
        getFieldProps,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        onSubmit: (values) => {
            let s: string = '';

            if (values.search !== '') {
                s = s + `&search=${values.search}`;
            }

            if (values.subject_name !== '') {
                s = s + `&subject_name=${values.subject_name}`;
            }

            if (values.year_of_submission !== '') {
                s = s + `&year_of_submission=${values.year_of_submission}`;
            }

            setSearch(s);
            setPage('1');
            set_paginate_per_page(10);
        },
    });

    useEffect(() => {
        if (selectedSubject !== '') {
            setFieldValue('subject_name', selectedSubject?.value);
        }
    }, [selectedSubject]);

    return (
        <div className="page-content">
            {isThesisDissertationListDataLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Container fluid>
                <BetterBreadcrumb
                    breadcrumbItem={[{ link: '#', name: 'Dissertation/Thesis' }]}
                    title={'Dissertation/Thesis Accepted Titles'}
                />
                <Card className="mb-5">
                    <CardBody>
                        <Form
                            className="custom-form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                //   handleSubmit();
                            }}
                        >
                            <div className="row align-items-end">
                                <div className="col-4">
                                    <FormGroup className="mb-3">
                                        <Label for="subject">Select Subject</Label>
                                        <Select
                                            isClearable={true}
                                            isSearchable={true}
                                            value={selectedSubject}
                                            onChange={setSelectedSubject}
                                            options={allSubjects}
                                            placeholder='Select...'
                                            name="subject"
                                            id="subject_id"
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-2">
                                    <FormGroup className="mb-3">
                                        <Label for="year_of_submission">Year</Label>
                                        <InputField
                                            type="text"
                                            placeholder="Year of Submission"
                                            invalid={{ errors, touched }}
                                            {...getFieldProps('year_of_submission')}
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-2">
                                    <FormGroup className="mb-3">
                                        <Label for="search">Search</Label>
                                        <InputField
                                            type="text"
                                            placeholder="Search a Keyword"
                                            invalid={{ errors, touched }}
                                            {...getFieldProps('search')}
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-3">
                                    <FormGroup className="mb-3">
                                        <Button
                                            color="danger"
                                            type="reset"
                                            text="Reset"
                                            onClick={() => {
                                                setSelectedSubject('');
                                                resetForm();
                                                handleSubmit();
                                            }}
                                        />
                                        <Button
                                            type="button"
                                            text="Search"
                                            onClick={() => {
                                                handleSubmit();
                                            }}
                                            className={'ms-2'}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </Form>
                        <DataTable
                            className="regular-datatable-view"
                            columns={columns}
                            data={thesisDissertationList ? thesisDissertationList : []}
                            pagination
                            paginationServer
                            paginationPerPage={10}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            progressPending={isThesisDissertationListDataLoading}
                            paginationTotalRows={thesisDissertationListData?.total}
                            expandOnRowClicked
                            highlightOnHover
                            paginationRowsPerPageOptions={[2, 5, 10, 15, 20, 50, 100, 200]}
                            persistTableHead
                        />
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default ListOfDissertationTitle;
