import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Table,
} from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import LogBookMasterSetupTableRow from 'src/pages/Setup/LogBookMasterSetup/LogBookMasterSetupTableRow';
import { useGetLogBookMasterSetupsQuery } from 'src/rtk/features/setup/logBookMasterSetup/logBookMasterSetupApi';
export const LogBookMasterSetup = () => {
  const { data, error, isLoading, isError } = useGetLogBookMasterSetupsQuery();

  const err: CustomError = error as CustomError;
  const LogBookMasterSetup = data?.data || [];

  let content = null;
  if (isLoading) {
    content = <div className="mt-3">Loading...</div>;
  }
  if (!isLoading && isError && error) {
    content = <div className="mt-3">{err.message}</div>;
  }
  if (!isLoading && !isError && LogBookMasterSetup?.length > 0) {
    content = LogBookMasterSetup.map(
      (logBookMaster: LogBookSetupMasters, index) => (
        <LogBookMasterSetupTableRow
          logBookSetupMaster={logBookMaster}
          index={index}
          key={logBookMaster.id}
        />
      ),
    );
  } else {
    content = (
      <tr>
        <td colSpan={11}>
          <div className="text-center text-danger h6">No data available!</div>
        </td>
      </tr>
    );
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb title="Log Book Master Setup" />
        <Card>
          <CardHeader className="d-flex justify-content-between text-center align-items-center ">
            <div className="fw-bold fs-5 d-flex flex-shrink-1">List View</div>
            <div className="d-flex flex-shrink-0">
              <Link to={'/Log/Book/master/Setup/Add'}>
                <Button color="success" size="sm" outline className="rounded-5">
                  <i className="bx bx-plus"></i> Add Log Book Master
                </Button>
              </Link>
            </div>
          </CardHeader>
          <CardBody>
            <div className="table-responsive text-nowrap text-center  x-overflow-scroll">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Sl.</th>
                    <th>Log Book Heading</th>
                    <th>Exam Name</th>
                    <th>Subject Name</th>
                    <th>Log Book Type</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
