import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { Button, Container } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import { useDeleteInquiryTypeMutation, useGetInquiryListQuery } from 'src/rtk/features/researchClinicSetup/researchClinicSetupApi';
import loader from '../../assets/images/loader.gif';
import AddInquiryType from './AddInquiryType';
import EditInquiryType from './EditInquiryType';
import PaymentOption from './PaymentOption';

const ResearchClinicSetupEntryList = () => {
  const [inquiryId, setInquiryId] = useState('');

  const [showAddForm, setShowAddForm] = useState<boolean>(false);
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);

  const [infoId, setInfoId] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const {
    data: inquiryList,
    isSuccess,
    isLoading,
  } = useGetInquiryListQuery();

  const [deleteInquiryType, { isSuccess: isDeleteSuccess, isError: isDeleteError, isLoading: isDeleteLoading }] = useDeleteInquiryTypeMutation();

  const editHandler = (id: string) => {
    setInquiryId(id);
    setShowEditForm(true);
  };

  const deleteHandler = (modalState: boolean, id: string) => {
    setModalShow(modalState);
    setInfoId(id);
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      toast.success('Inquiry Type Deleted Successfully!');
      setModalShow(false);
    }
    if (isDeleteError) {
      toast.error('Error Deleting Inquiry Type!');
    }
  }, [isDeleteSuccess, isDeleteError]);


  const columns: TableColumn<any>[] = [
    {
      name: 'Sl. No.',
      cell: (row, index) => index + 1,
      width: '80px',
    },

    {
      name: 'Inquiry Type',
      selector: (row) => row?.inquiry_type,
      sortable: false,
    },
    {
      name: 'Status',
      selector: (row) => row?.active_status,
      sortable: false,
    },

    {
      name: 'Action',
      width: '170px',
      cell: (row) => (
        <>
          <div className="d-flex gap-1 my-1">
            <Button
              className="btn btn-sm btn-info"
              onClick={() => editHandler(row?.id!)}
            >
              Edit
            </Button>

            <Button
              className="btn btn-sm btn-danger"
              onClick={() => deleteHandler(true, row?.id!)}
            >
              Delete
            </Button>
            {modalShow && infoId === row?.id ? (
              <DeleteInformationModal
                isOpen={modalShow}
                toggle={() => setModalShow(!modalShow)}
                id={infoId}
                deleteInfomation={deleteInquiryType}
              />
            ) : null}
            {showEditForm && inquiryId === row?.id ? (
              <EditInquiryType
                id={inquiryId}
                isOpen={showEditForm}
                toggle={() => setShowEditForm(!showEditForm)}
              />
            ) : null}
          </div>
        </>
      ),
    },
  ];

  return (
    <div className="page-content">
      {isLoading || isDeleteLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <div className='page-breadcrumb-wrapper'>
          <BetterBreadcrumb
            breadcrumbItem={[{ link: '/', name: 'Dashboard' }]}
            title={'Research Clinic Setup'}
          />
          <div>
            <Button color="primary" className="mx-2" onClick={() => setShowAddForm(true)}>
              Inquiry Entry
            </Button>
            <Button color="secondary" onClick={() => setShowPaymentForm(true)}>
              Payment Information
            </Button>
          </div>
        </div>

        <div className="row align-items-center justify-content-center">
          <div className="card mt-1">
            <div className="card-body">
              <DataTable
                columns={columns}
                data={inquiryList?.data!}
                progressPending={isLoading}
                expandOnRowClicked
                highlightOnHover
                responsive
                persistTableHead={true}
              />
            </div>
          </div>
        </div>
        {showPaymentForm &&
          <PaymentOption isOpen={showPaymentForm} toggle={() => setShowPaymentForm(!showPaymentForm)} />
        }
        {showAddForm &&
          <AddInquiryType isOpen={showAddForm} toggle={() => setShowAddForm(!showAddForm)} />
        }
      </Container>
    </div>
  );
};

export default ResearchClinicSetupEntryList;
