import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { ConfirmToast } from 'react-confirm-toast';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { Container } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import formObj from './formObj';

import { Link, useHistory } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Modal,
  Row
} from 'reactstrap';
import { Button, DaynamicSearchInputField, DynamicView } from 'src/components';
import { config } from 'src/config';
import { putUrlString } from 'src/helpers';
import {
  useDeleteByIdMutation,
  useGetDataQuery,
} from 'src/rtk/features/common/crudApi';
import loader from '../../assets/images/loader.gif';

const UserListPage = () => {
  if (formObj?.title) {
    document.title = formObj?.title + ' | BCPS';
  }
  const history = useHistory();

  const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [viewData, setViewData] = useState<any>({});
  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(20);
  const [search, setSearch] = useState('');
  const generateQueryUrl = () => {
    return `${formObj.listAPIUrl}?page=${page}&paginate_per_page=${limit}${search}`;
  };

  const {
    data: dataList,
    isLoading: dataListLoading,
    isSuccess: dataListLoadingSuccess,
    error: dataListLoadingError,
  } = useGetDataQuery(generateQueryUrl());
  const data: any = dataList?.data || [];

  useEffect(() => {
    if (dataListLoadingError) {
      toast.dismiss();
      toast.error('Data Loading error. Please try again later.');
    } else if (dataListLoading || !dataListLoadingSuccess) {
      toast.dismiss();
      // toast.loading('Data Loading...');
    } else {
      toast.dismiss();
      // toast.success('Data Load Successfully');
    }
  }, [dataListLoading, dataListLoadingSuccess, dataListLoadingError]);

  const [deleteByID, { isSuccess, isLoading, isError, error }] =
    useDeleteByIdMutation();
  const err: CustomError = error as CustomError;

  useEffect(() => {
    if (isSuccess) {
      toast.success(formObj.title + ' delete successfully');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
    }
  }, [isSuccess, isError]);

  let initialSearchValues: any = {};
  let inputSearchFields: any = [];
  let tableColumn: any = [];
  let viewColumn: any = [];
  let searchFormFilds = formObj?.searchFormFields || {};

  Object.keys(searchFormFilds)
    .sort(function (a: any, b: any) {
      return (
        parseInt(searchFormFilds[a].positionInForm) -
        parseInt(searchFormFilds[b].positionInForm)
      );
    })
    .map((key) => {
      let searchFormField = searchFormFilds[key];

      if (searchFormField['viewCell']) {
        viewColumn.push({
          name: searchFormField['label'],
          viewCell: searchFormField['viewCell'],
        });
      }

      if (searchFormField['cell']) {
        tableColumn.push({
          ...searchFormField,
          name: <div>{searchFormField['label']}</div>,
          cell: searchFormField['cell'],
        });
      }

      if (formObj?.showSearchForm && searchFormFilds[key]['showInSearch']) {
        inputSearchFields.push(searchFormFilds[key]);

        if (
          searchFormField['type'] === 'checkbox' &&
          typeof searchFormField['default'] === 'string'
        ) {
          searchFormField['default'] = [searchFormField['default'].trim()];
        } else if (
          searchFormField['type'] === 'radio' &&
          typeof searchFormField['default'] === 'object'
        ) {
          searchFormField['default'] = searchFormField['default']
            .join(' ')
            .trim();
        } else if (searchFormField['type'] === 'date') {
          searchFormField['default'] =
            searchFormField?.options?.defaultDate || '';
        }

        initialSearchValues[searchFormField['name']] = '';
      }
    });

  let columns: TableColumn<any>[] = [
    {
      name: '#',
      maxWidth: '10px',
      cell: (row, index: any) => (data.from ? data.from + index : index + 1),
    },
    ...tableColumn,
  ];

  if (formObj?.showTableActionColumn) {
    columns.push({
      name: 'Action',
      width: '180px',
      selector: (row: any) => row.id,
      cell: (row: any, index: any) => (
        <>
          {/* <Button
            size="sm"
            className="rounded-0 my-2"
            text={`<span class="fas fa-eye" ></span> Assign Permissons`}
            color="success"
            onClick={() => history.push(`${formObj.permissonUrl}${row.id}`)}
          /> */}
          {formObj?.showViewBtn && (
            <Button
              size="sm"
              className="my-1 me-2"
              text={`<span class="fas fa-eye" ></span> ${formObj.viewBtnTitle}`}
              color="primary"
              onClick={() => {
                setShowModal(true);
                // setViewData(row);
                setViewData(row);
              }}
            />
          )}

          {formObj?.showUpdateBtn && (
            <Button
              size="sm"
              className=""
              text={`<span class="fas fa-edit" ></span> ${formObj.updateBtnTitle}`}
              color="info"
              onClick={() => history.push(`${formObj.updateUrl}${row.id}`)}
            />
          )}

          {formObj?.showDeleteBtn && (
            <ConfirmToast
              asModal={true}
              childrenClassName="margin-top-10"
              customCancel="No"
              customConfirm="Yes"
              customFunction={() =>
                deleteByID(`${formObj.deleteAPIUrl}${row.id}`)
              }
              message={formObj.deletePromtMessage}
              position="top-right" //will be ignored cause asModal=true
              showCloseIcon={true}
              theme="lilac"
            >
              <Button
                size="sm"
                className="rounded-0"
                text={`<span class="fas fa-trash" ></span> ${formObj.deletBtnTitle}`}
                color="danger"
              />
            </ConfirmToast>
          )}
        </>
      ),
    });
  }

  const {
    handleSubmit: handleSearchSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    values,
    setValues,
    setFieldValue,
    touched,
    errors,
    resetForm: resetSearchForm,
    setErrors,
  } = useFormik({
    initialValues: initialSearchValues,
    onSubmit: (searchValues) => {
      let s: string = '';

      Object.keys(searchValues).map((i: any) => {
        if (searchValues[i] === '') {
          delete searchValues[i];
        }
      });

      if (searchValues?.name) {
        s = s + `&search=${searchValues.name}`;
        delete searchValues.name;
      }

      if (Object.keys(searchValues).length > 0) {
        console.log(searchValues);

        if (searchValues.status) {
          searchValues.status = searchValues.status === 'Active' ? 1 : 0;
        }

        s = s + `&` + putUrlString('', searchValues).replaceAll('?', '');
      }

      setSearch(s);
      setPage('1');
      // setLimit(20);
    },
  });
  return (
    <>
      <div className="page-content">
        {dataListLoading ? (
          <div className="overlay">
            <img src={loader} alt="Loading" height={100} width={300} />
          </div>
        ) : null}
        <Container fluid>
          {formObj.breadcrumbItemForList && (
            <BetterBreadcrumb
              title={formObj?.title + ' List'}
              breadcrumbItem={formObj.breadcrumbItemForList}
            />
          )}

          <Card>

            <CardBody>
              <Row>
                {formObj?.showSearchForm && inputSearchFields.length > 0 && (
                  <>
                    <Form
                      className="custom-form w-100"
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSearchSubmit();
                      }}
                    >
                      <div className="filter-wrapper">
                        {inputSearchFields.length > 0 &&
                          inputSearchFields.map(
                            (searchField: any, key: any) => {
                              if (
                                !['hidden', 'submit'].includes(
                                  searchField['type'],
                                )
                              ) {
                                searchField['isRequired'] = false;
                                searchField['default'] = '';

                                if (
                                  ['radio', 'checkbox'].includes(
                                    searchField['type'],
                                  )
                                ) {
                                  searchField['type'] = 'select';
                                }

                                if (
                                  searchField['dependField'] &&
                                  searchField['dependValue'] &&
                                  searchField['condition']
                                ) {
                                  let isMatch = true;
                                  switch (searchField['condition']) {
                                    case '===':
                                      isMatch =
                                        values[searchField['dependField']] ===
                                        searchField['dependValue'];
                                      break;

                                    case '!==':
                                      isMatch =
                                        values[searchField['dependField']] !==
                                        searchField['dependValue'];
                                      break;

                                    case 'includes':
                                      isMatch = searchField[
                                        'dependValue'
                                      ].includes(
                                        values[searchField['dependField']],
                                      );
                                      break;

                                    default:
                                      break;
                                  }
                                  if (!isMatch) {
                                    if (
                                      values[searchField['name']] !==
                                      searchField['default']
                                    ) {
                                      setFieldValue(
                                        searchField['name'],
                                        searchField['default'],
                                      );
                                    }
                                    return <></>;
                                  }
                                }

                                return (
                                  <FormGroup key={searchField['name'] + key}
                                    // key={searchField['name'] + key}
                                    className="mb-3"
                                  >
                                    <>
                                      {['select'].includes(
                                        searchField['type'],
                                      ) && (
                                          <DaynamicSearchInputField
                                            label={searchField['label']}
                                            name={searchField['name']}
                                            type={searchField['type']}
                                            placeholder={'Select'}
                                            options={searchField['data']}
                                            dataApi={searchField['dataApi']}
                                            value={
                                              values[searchField['name']] !==
                                                undefined
                                                ? values[searchField['name']]
                                                : searchField['default']
                                            }
                                            isMulti={searchField['isMulti']}
                                            required={
                                              searchField['isRequired']
                                            }
                                            invalid={{ errors, touched }}
                                            onChange={(data: any) => {
                                              if (data === null) {
                                                setFieldValue(
                                                  searchField['name'],
                                                  '',
                                                );
                                              } else if (
                                                data &&
                                                data?.length === undefined &&
                                                data.value !== undefined
                                              ) {
                                                setFieldValue(
                                                  searchField['name'],
                                                  data.value,
                                                );
                                              } else if (
                                                data &&
                                                data?.length !== undefined
                                              ) {
                                                setFieldValue(
                                                  searchField['name'],
                                                  data.map(
                                                    (item: any) => item.value,
                                                  ),
                                                );
                                              }
                                            }}
                                          />
                                        )}

                                      {['date'].includes(
                                        searchField['type'],
                                      ) && (
                                          <>
                                            <DaynamicSearchInputField
                                              label={searchField['label']}
                                              name={searchField['name']}
                                              type={searchField['type']}
                                              placeholder={''}
                                              options={searchField['options']}
                                              invalid={{ errors, touched }}
                                              value={
                                                values[
                                                  searchField['name']
                                                ] !== undefined
                                                  ? values[
                                                  searchField['name']
                                                  ]
                                                  : searchField?.options
                                                    ?.defaultDate
                                              }
                                              required={
                                                searchField['isRequired']
                                              }
                                              setData={(data: any) => {
                                                if (
                                                  searchField?.options
                                                    ?.mode === 'range'
                                                ) {
                                                  setFieldValue(
                                                    searchField['name'],
                                                    data[0],
                                                  );
                                                  setFieldValue(
                                                    searchField['name2'],
                                                    data[1],
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    searchField['name'],
                                                    data.join(',').trim(),
                                                  );
                                                }
                                              }}
                                            />
                                          </>
                                        )}

                                      {['radio', 'checkbox'].includes(
                                        searchField['type'],
                                      ) && (
                                          <DaynamicSearchInputField
                                            label={searchField['label']}
                                            name={searchField['name']}
                                            type={searchField['type']}
                                            placeholder={''}
                                            options={searchField['data']}
                                            dataApi={searchField['dataApi']}
                                            value={
                                              values[searchField['name']] !==
                                                undefined
                                                ? values[searchField['name']]
                                                : searchField['default']
                                            }
                                            isMulti={searchField['isMulti']}
                                            required={
                                              searchField['isRequired']
                                            }
                                            invalid={{ errors, touched }}
                                            onChange={(data: any) => {
                                              let selectedValue =
                                                data.target.value.toString();
                                              if (
                                                searchField['type'] ===
                                                'checkbox'
                                              ) {
                                                let isChecked =
                                                  data.target.checked;
                                                let oldValue =
                                                  values[searchField['name']];

                                                let getIndex =
                                                  oldValue.indexOf(
                                                    selectedValue,
                                                  );
                                                if (
                                                  isChecked &&
                                                  getIndex === -1
                                                ) {
                                                  oldValue.push(
                                                    selectedValue,
                                                  );
                                                } else if (
                                                  !isChecked &&
                                                  getIndex > -1
                                                ) {
                                                  oldValue.splice(
                                                    getIndex,
                                                    1,
                                                  );
                                                }

                                                setFieldValue(
                                                  searchField['name'],
                                                  oldValue,
                                                );
                                              } else {
                                                setFieldValue(
                                                  searchField['name'],
                                                  selectedValue,
                                                );
                                              }
                                            }}
                                          />
                                        )}

                                      {![
                                        'select',
                                        'radio',
                                        'checkbox',
                                        'date',
                                      ].includes(searchField['type']) && (
                                          <DaynamicSearchInputField
                                            label={searchField['label']}
                                            type={searchField['type']}
                                            placeholder={searchField['label']}
                                            required={
                                              searchField['isRequired']
                                            }
                                            invalid={{ errors, touched }}
                                            {...getFieldProps(
                                              searchField['name'],
                                            )}
                                          />
                                        )}
                                    </>
                                  </FormGroup>
                                );
                              }
                            },
                          )}
                        <FormGroup className='btn-column'>
                          <Button
                            color="danger"
                            type="reset"
                            text="Reset"
                            onClick={() => {
                              resetSearchForm();
                              handleSearchSubmit();
                            }}
                          />
                          <Button
                            type="button"
                            text="Search"
                            onClick={() => {
                              handleSearchSubmit();
                            }}
                          />

                        </FormGroup>

                        <FormGroup className=''>
                          {formObj?.showCreateNewBtn && (
                            <Button
                              color="success"
                              type="button"
                              text={formObj.createNewBtnTitle || 'Create New'}
                              onClick={() => {
                                history.push(`${formObj.addUrl}`);
                                // handleSearchSubmit();
                              }}
                            />
                          )}
                        </FormGroup>
                      </div>
                    </Form>

                  </>
                )}
                {formObj?.addUrl &&
                  formObj?.showCreateNewBtn &&
                  inputSearchFields.length === 0 && (
                    <Col
                      md={
                        formObj?.showSearchForm && inputSearchFields.length > 0
                          ? ''
                          : 12
                      }
                      sm={12}
                      className="d-flex justify-content-end align-items-center"
                    >
                      <div className="mt-2">
                        <Link
                          to={formObj.addUrl}
                          className="btn btn-success waves-effect waves-light"
                        >
                          <i className="fa fa-plus me-2" />{' '}
                          {formObj.createNewBtnTitle || 'Create New'}
                        </Link>
                      </div>
                    </Col>
                  )}
              </Row>
              {data?.total !== undefined && columns.length > 0 && (
                <DataTable
                  columns={columns}
                  data={data?.data ? data.data : []}
                  pagination
                  paginationServer
                  onChangePage={(page: number) => {
                    setPage(page);
                  }}
                  onChangeRowsPerPage={(newPerPage: number, page: number) => {
                    setLimit(newPerPage);
                    setPage(page);
                  }}
                  progressPending={dataListLoading}
                  paginationTotalRows={data?.total}
                  expandOnRowClicked
                  highlightOnHover
                  paginationPerPage={limit}
                  paginationRowsPerPageOptions={
                    config.paginationRowsPerPageOptions
                  }
                />
              )}

              {/* {data?.total === undefined && columns.length > 0 && (
            <DataTable
              columns={columns}
              data={data}
              pagination
              progressPending={dataListLoading}
              paginationTotalRows={data?.total}
              highlightOnHover
              paginationRowsPerPageOptions={config.paginationRowsPerPageOptions}
            />
          )} */}
            </CardBody>
          </Card>
          <Modal
            onOpened={function () { }}
            isOpen={showModal}
            scrollable={false}
            size="lg"
            toggle={() => {
              document.body.classList.add('no_padding');
            }}
            backdrop={'static'}
            modalTransition={{ timeout: 0 }}
            backdropTransition={{ timeout: 0 }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Details Info
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setShowAssignModal(false);
                  setShowModal(!showModal);
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <DynamicView viewColumn={viewColumn} viewData={viewData} />
            </div>
            <div className="modal-footer">
              <Button
                type="button"
                text="Close"
                className="btn btn-danger"
                onClick={() => {
                  setShowModal(!showModal);
                }}
              ></Button>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  );
};

export default UserListPage;
