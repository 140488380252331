import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { Col, Container, FormGroup, Label, Row } from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetFormByLogbookTypeQuery } from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';

import DynamicFormById from './DynamicFormById';
import { TraineeAcademicActivitiesEntryGeneralType } from './TraineeAcademicActivitiesEntryGeneralType';
import loader from '../../../../assets/images/loader.gif';

const TraineeAcademicActivitiesEntry = () => {
  document.title = 'Academic Activities | BCPS';

  const [form, setForm] = useState<LogbookEntrySetupMaster>(
    {} as LogbookEntrySetupMaster,
  );
  const [formName, setFormName] = useState<string>('');
  const [entrySetupMaster, setEntrySetupMaster] = useState<
    LogbookEntrySetupMaster[]
  >([]);

  const { data: formSetups, isLoading: formSetupsLoading } =
    useGetFormByLogbookTypeQuery(2); // 2 for Academic Activities
  const forms = formSetups?.data || [];

  // useEffect(() => {
  //   if (formSetupsLoading) {
  //     toast.dismiss();
  //     toast.loading('Data Loading...');
  //   } else {
  //     toast.dismiss();
  //     toast.success('Data Load Successfully');
  //   }
  // }, [formSetupsLoading]);

  let formOptions: SelectOption[] = [
    ...forms?.map((e: LogbookEntrySetupMaster) => ({
      label: e.particulars!,
      value: e.id!,
    })),
  ];

  const handleFormSelect = (e: any) => {
    const { options, selectedIndex, value } = e.target;
    const form = forms.find((form) => form.id === value);
    setForm(form!);
    setFormName(options[selectedIndex].innerHTML);
    setEntrySetupMaster(form?.entry_setup_master || []);
  };

  return (
    <div className="page-content">
      {formSetupsLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb
          title="Academic Activities Entry"
          breadcrumbItem={[                        
            {
              link: '/trainee/logbook/academic-activities',
              name: 'Academic Activity List',
            },
          ]}
          component={
            <Link
              to="/trainee/logbook/academic-activities"
              className="btn btn-success waves-effect waves-light"
            >
              <i className="fas fa-arrow-left me-2" /> Back
            </Link>}
        />
        <div className="row align-items-center justify-content-center">
          <div className="card">
            {/* <div className="d-flex card-header">
              <h4 className="card-title mb-0 flex-grow-1">
                <i className="fa fa-plus me-2" />
                Academic Activities Entry
              </h4>
              <div className="flex-shrink-0 align-self-end">
                <Link
                  to="/trainee/logbook/academic-activities"
                  className="btn btn-success waves-effect waves-light"
                >
                  <i className="fas fa-arrow-left me-2" /> Back
                </Link>
              </div>
            </div> */}

            <div className="card-body">
              <Row>
                <Col lg={4} md={6} sm={12}>
                  <FormGroup className="mb-3">
                    <Label className="form-label">Form</Label>
                    <InputField
                      type="select"
                      name="form"
                      placeholder=""
                      value={form?.id ?? ''}
                      options={formOptions}
                      onChange={(e) => {
                        handleFormSelect(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                {/* {formName && (
                  <TraineeAcademicActivitiesEntrySystemType
                    form={form}
                    formName={formName}
                  />
                )} */}

                {entrySetupMaster.length === 0 && form?.id && (
                  <DynamicFormById
                    dynamicFormId={form.id}
                    formName={formName}
                    caseTypeName={''}
                    entrySetupMasterId={form.id}
                    setForm={setForm}
                  />
                )}
                {entrySetupMaster.length > 0 && form?.id && (
                  <TraineeAcademicActivitiesEntryGeneralType
                    form={form}
                    formName={formName}
                    setForm={setForm}
                  />
                )}
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TraineeAcademicActivitiesEntry;
