import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Row, Table } from 'reactstrap';
import { InputField } from 'src/components';
import DisplayImage from 'src/components/Common/displayImage';
import { config } from 'src/config';
import { setFromErrors } from 'src/helpers';
import { useCreateCBDFormBEntryMutation } from 'src/rtk/features/miniCexCBD/miniCexAndCBDApi';
import { useGetMyRegInfoQuery } from 'src/rtk/features/registration-approval/registrationApprovalApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

const discussion = [
    "History Writing",
    "History Taking",
    "Physical Examination",
    "Diagnosis and possible differentials with reasoning/justification",
    "Planing Invasgination and Justification",
    "Planing Management and its Justification",
    "Follow up Prognosis and Referral Knowledge",
    "Recent update on the topic",
    "Communication skill/ Dealing with patient and attendants/ Ethical approach",
    "Overall clinical care",
]

const performanceOption = [
    { value: 'Below expectation for the stage of training', label: 'Below expectation for the stage of training' },
    { value: 'Borderline for the stage of training', label: 'Borderline for the stage of training' },
    { value: 'Meets Expectation for the stage of training', label: 'Meets Expectation for the stage of training' },
    { value: 'Above Expectation for the stage of training', label: 'Above Expectation for the stage of training' },
    { value: 'Well above Expectation for the stage of training', label: 'Well above Expectation for the stage of training' },
];
interface Props {
    regitrationNo: string;
    supervisorId: string;
    sessionId: string;
}

const CBDFormB = (props: Props) => {
    const { regitrationNo, supervisorId, sessionId } = props;
    const history = useHistory();

    const { data, isLoading } = useGetMyRegInfoQuery(regitrationNo);
    const userInfo = data?.data || ({} as RegInfo);

    const [createCBDFormBEntry, { isSuccess, isError, error, isLoading: isCreateLoading }] = useCreateCBDFormBEntryMutation();
    const err: CustomError = error as CustomError;

    const [cbdFormBdata, setCbdFormBdata] = useState<any>({});

    const [assesmentOption, setAssesmentOption] = useState(Array(discussion.length).fill(''));

    const initialValues = {
        registration_id: '',
        supervisor_id: '',
        logbook_entry_session_id: '',
        date_of_assessment: '',
        difficulty_level: '',
        start_time: '',
        end_time: '',
        diagnosis_of_the_case: '',
        competency_to_be_covered: '',
        institute: '',
        supervisor_name: '',
        academic_status: '',
        student_name: '',
        training_duration: '',
        trainee_signature: '',
        supervisor_signature: '',
        data: [],
    }

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({
            difficulty_level: Yup.string().required('Please Select!'),
            diagnosis_of_the_case: Yup.string().required('Diagnosis of the case Required'),
            start_time: Yup.string().required('Start Time is Required'),
            end_time: Yup.string()
                .required('End time is required')
                .test('is-after-start', 'End time must be after start time', function (
                    value
                ) {
                    const { start_time } = this.parent;
                    return !start_time || !value || value > start_time;
                }),
            competency_to_be_covered: Yup.string().required('Competency is Required'),
        }),

        onSubmit: (values: any) => {
            values.data = [];

            values.registration_id = regitrationNo;
            values.supervisor_id = supervisorId;
            values.logbook_entry_session_id = sessionId;

            Object.keys(cbdFormBdata).forEach((key) => {
                values.data.push(cbdFormBdata[key]);
            });

            createCBDFormBEntry(values);
            console.log(values);
        },
    });

    useEffect(() => {
        if (isSuccess) {
            const arr = Array(discussion.length).fill('');
            setAssesmentOption(arr);

            setCbdFormBdata({});
            resetForm();
            toast.success('CBD Form B Information Stored Successfully!');
            history.push('/case-based-disscussion-list');
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);

    const handleDisussionFeedback = (e: any, title: string, id: number) => {
        const item = { ...cbdFormBdata };

        if (e.target.value) {
            item[id] = {
                discussion_area: title,
                feedback: e.target.value,
            }
        }
        setCbdFormBdata(item);
    }

    return (
        <div>
            {isLoading || isCreateLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Form
                className="custom-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
                onReset={(e) => {
                    // e.preventDefault();
                    const arr = Array(discussion.length).fill('');
                    setAssesmentOption(arr);
                    setCbdFormBdata({});
                    resetForm();
                    resetForm();
                }}
            >
                <Card className='mt-2'>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="date_of_assessment" className='form-label required-field'>Date Of Assesment</Label>
                                    <InputField
                                        type="date"
                                        placeholder=""
                                        invalid={{ errors, touched }}
                                        name='date_of_assessment'
                                        value={values.date_of_assessment = new Date(Date.now()).toISOString().slice(0, 10)}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="institute" className='form-label'>Accredited Institute</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Accridited Institute"
                                        {...getFieldProps('institute')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="supervisor_name" className='form-label'>Name Of Supervisor</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Name Of Supervisor"
                                        name='supervisor_name'
                                        value={values.supervisor_name = userInfo?.current_session_and_supervisor_info?.supervisor?.name!}
                                        readOnly
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="academic_status" className='form-label'>Academic Status</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Academic Status"
                                        {...getFieldProps('academic_status')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="student_name" className='form-label'>Student`s Name</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Student Name"
                                        name='student_name'
                                        value={values.student_name = userInfo?.personal_info?.full_name}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="training_duration" className='form-label'>Years Of Training</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Training Years"
                                        {...getFieldProps('training_duration')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="diagnosis_of_the_case" className='form-label required-field'>Diagnosis Of the Case</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Diagnosis Of the Case"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('diagnosis_of_the_case')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="difficulty_level" className='form-label required-field'>Dificulty Level</Label>
                                    <InputField
                                        type="select"
                                        placeholder="Dificulty Level"
                                        invalid={{ errors, touched }}
                                        options={[
                                            { value: 'Simple', label: 'Simple' },
                                            { value: 'Moderate', label: 'Moderate' },
                                            { value: 'Difficult', label: 'Difficult' },
                                        ]}
                                        {...getFieldProps('difficulty_level')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="competency_to_be_covered" className='form-label required-field'>Competency Level</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Competency Level"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('competency_to_be_covered')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="start_time" className='form-label required-field'>Time Of Start</Label>
                                    <InputField
                                        type="time"
                                        placeholder="Time Of Start"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('start_time')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="end_time" className='form-label required-field'>Time Of Ending</Label>
                                    <InputField
                                        type="time"
                                        placeholder="Time Of Ending"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('end_time')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className='mb-2'><strong>Areas to be considered Disccusion and feedback : </strong></div>

                        <Table className='table-bordered'>
                            {discussion.map((item: any, index: number) => (
                                <tr key={index}>
                                    <td className='col-sm-5'>
                                        <div>
                                            {item}
                                        </div>
                                    </td>
                                    <td className='col-sm-2'>
                                        <div className='m-1 p-1'>
                                            <InputField
                                                className='form-select'
                                                type="select"
                                                placeholder=""
                                                options={performanceOption}
                                                name='competancies'
                                                value={assesmentOption[index]}
                                                onChange={(e: any) => {
                                                    handleDisussionFeedback(e, item, index);
                                                    const assesment = [...assesmentOption];
                                                    assesment[index] = e.target.value;
                                                    setAssesmentOption(assesment);
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td></td>
                                </tr>
                            ))}
                        </Table>

                        <Row className='mt-3'>
                            <Col sm={2}>
                                <Label>Trainee Signature : </Label>
                                <div className="img-fluid img-rounded">
                                    {userInfo?.personal_info?.signature &&
                                        <DisplayImage className="img-thumbnail profile-img-width" src={`${config?.APP_URL}${userInfo?.personal_info?.signature}`} />
                                    }
                                </div>
                            </Col>
                            <Col sm={2}>
                                <Label>Supervisor Signature : </Label>
                                <div className="img-fluid img-rounded">
                                    {userInfo?.personal_info?.signature &&
                                        <DisplayImage className="img-thumbnail profile-img-width" src={`${config?.APP_URL}${userInfo?.personal_info?.signature}`} />
                                    }
                                </div>
                            </Col>
                        </Row>


                        <FormGroup className="mb-3 text-center mt-2">
                            <Button
                                type="reset"
                                className="btn btn-secondary me-2"
                            >
                                Reset
                            </Button>

                            <button
                                type="submit"
                                className="btn btn-success"
                            >
                                Submit
                            </button>
                        </FormGroup>
                    </CardBody>
                </Card>
            </Form>
        </div>
    );
};

export default CBDFormB;
