import { useFormik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import { Button, InputField } from "src/components";

const ResearchInformation = () => {
  const { values, errors, touched, handleChange } = useFormik({
    initialValues: {
      demo: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="px-md-4 px-sm-2">
            <h5 className="mb-3">Research Information</h5>
            <FormGroup className="col-sm-6 col-md-3">
              <div className="mb-3">
                <h5 className="font-size-14 mb-3">
                  Research related information
                </h5>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                      defaultChecked
                    />
                    <label className="form-check-label">Nil</label>
                  </div>
                </div>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                    />
                    <label className="form-check-label">Yes</label>
                  </div>
                </div>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-6 col-md-3">
              <Label>Details</Label>
              <InputField
                type="text"
                name="totalCabins"
                placeholder="Enter a number"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>
            <FormGroup className="col-sm-6 col-md-3">
              <Label>No. of ongoing research</Label>
              <InputField
                type="number"
                name="totalCabins"
                placeholder="Enter a number"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>
            <FormGroup className="col-sm-6 col-md-3">
              <Label>No. research conducted last year</Label>
              <InputField
                type="text"
                name="totalCabins"
                placeholder="Enter a number"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>
            <hr />
            <h6>Research projects conducted by the department</h6>
            <FormGroup className="col-sm-6 col-md-3">
              <Label>Name of project</Label>
              <InputField
                type="text"
                name="totalCabins"
                placeholder="Project Name"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>
            <FormGroup className="col-sm-6 col-md-3">
              <Label>Agency</Label>
              <InputField
                type="text"
                name="totalCabins"
                placeholder="Project Agency"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>
            <FormGroup className="col-sm-6 col-md-3">
              <div className="mb-3">
                <h5 className="font-size-14 mb-3">Status</h5>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                      defaultChecked
                    />
                    <label className="form-check-label">Ongoing</label>
                  </div>
                </div>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                    />
                    <label className="form-check-label">Proposed</label>
                  </div>
                </div>
              </div>
            </FormGroup>
            <Col>
              <div className="mb-3 d-flex flex-row-reverse">
                <Button className="float-right px-3" text={"+ Add"} />
              </div>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-striped table-hover mb-0">
                      <thead>
                        <tr>
                          <th>Sl</th>
                          <th>Name of Project</th>
                          <th>Sponsoring Agency</th>
                          <th>On-going/proposed</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>GAN on synthetic DNA sequence generation</td>
                          <td>Springer</td>
                          <td>Proposed</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <h6 className=" mb-3">
              Publications by the faculty members of the department in the last
              3 years
            </h6>
            <Row>
              <FormGroup className="col-sm-12 col-md-6">
                <Label>Title</Label>
                <InputField
                  type="text"
                  name="totalCabins"
                  placeholder="Enter publication title"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
              <FormGroup className="col-sm-8 col-md-4">
                <Label>Journal</Label>
                <InputField
                  type="text"
                  name="totalCabins"
                  placeholder="Enter journal name"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
              <FormGroup className="col-sm-4 col-md-2">
                <Label>Year</Label>
                <InputField
                  type="number"
                  name="totalCabins"
                  placeholder="Enter year"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
            </Row>
            <Col>
              <div className="mb-3 d-flex flex-row-reverse">
                <Button className="float-right px-3" text={"+ Add"} />
              </div>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-striped table-hover mb-0">
                      <thead>
                        <tr>
                          <th>Sl</th>
                          <th>Title</th>
                          <th>Journal</th>
                          <th>Year</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>GAN on synthetic DNA sequence generation</td>
                          <td>Springer</td>
                          <td>2021</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <h6>Trainee's Clinical/Laboratory work attachment</h6>
            <Row>
              <FormGroup className="col-sm-6 col-md-3">
                <Label for="departmentName">Duties</Label>
                <Input type="select" name="departmentName">
                  <option value={0}>Select Duties </option>
                  <option value={1}>Duty 1</option>
                  <option value={1}>Duty 2</option>
                  <option value={1}>Duty 3</option>
                </Input>
              </FormGroup>
              <FormGroup className="col-sm-6 col-md-3">
                <Label>Frequency / week</Label>
                <InputField
                  className="d-inline"
                  type="text"
                  name="totalCabins"
                  placeholder="Frequency"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
            </Row>
            <Col>
              <div className="mb-3 d-flex flex-row-reverse">
                <Button className="float-right px-3" text={"+ Add"} />
              </div>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-striped table-hover mb-0">
                      <thead>
                        <tr>
                          <th>Duties</th>
                          <th>Frequency / Week</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Some duty</td>
                          <td>3</td>
                          <td>
                            <div className="btn btn-sm btn-primary d-inline-block m-1">
                              Edit
                            </div>
                            <div className="btn btn-sm btn-danger d-inline-block">
                              Delete
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* Academic involvement */}
            <h6>Trainee's Academic involvement</h6>
            <Row>
              <FormGroup className="col-sm-6 col-md-3">
                <Label for="departmentName">Duties</Label>
                <Input type="select" name="departmentName">
                  <option value={0}>Select Duties </option>
                  <option value={1}>Duty 1</option>
                  <option value={1}>Duty 2</option>
                  <option value={1}>Duty 3</option>
                </Input>
              </FormGroup>
              <FormGroup className="col-sm-6 col-md-3">
                <Label>Frequency / month</Label>
                <InputField
                  className="d-inline"
                  type="text"
                  name="totalCabins"
                  placeholder="Frequency"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
            </Row>
            <Col>
              <div className="mb-3 d-flex flex-row-reverse">
                <Button className="float-right px-3" text={"+ Add"} />
              </div>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-striped table-hover mb-0">
                      <thead>
                        <tr>
                          <th>Duties</th>
                          <th>Frequency / month</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Some duty</td>
                          <td>3</td>
                          <td>
                            <div className="btn btn-sm btn-primary d-inline-block m-1">
                              Edit
                            </div>
                            <div className="btn btn-sm btn-danger d-inline-block">
                              Delete
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="text-center mb-3">
            <Link to="/institute/subject-registration-6">
              <button
                type="submit"
                className="btn btn-primary w-25"
                onSubmit={(e) => {
                  e.preventDefault;
                }}>
                Save and Next {">"}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResearchInformation;
