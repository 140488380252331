import moment from 'moment';
import {
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { Button } from 'src/components';


interface Props {
  isOpen: boolean;
  toggle: any;
  viewDetails: InspectionTeam;
}

const TeamViewModal = (props: Props) => {
  const { isOpen, toggle, viewDetails } = props;
  console.log(viewDetails);

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="md">
      <ModalHeader toggle={toggle}>Team Details</ModalHeader>

      <ModalBody>
        <table className="table table-sm table-bordered table-striped">
          <tbody>
            <tr>
              <td className='col-lg-6 col-md-6 col-sm-12'>
                <Label>Team Name : </Label> {viewDetails?.team_name}
              </td>
              <td className='col-lg-6 col-md-6 col-sm-12'>
                <Label>Status : </Label> {viewDetails?.assignment_status}
              </td>
            </tr>
            <tr>
              <td className='col-lg-6 col-md-6 col-sm-12'>
                <Label>Creation Date : </Label> {moment(viewDetails?.created_at).format("DD-MMM-YYYY")}
              </td>
              <td className='col-lg-6 col-md-6 col-sm-12'>
                <Label>Inspection Date : </Label> {moment(viewDetails?.created_at).format("DD-MMM-YYYY")}
              </td>
            </tr>
            <tr>
              <td className='col-lg-6 col-md-6 col-sm-12'>
                <Label>Chairman : </Label> {viewDetails?.chairman_name}
              </td>
              <td className='col-lg-6 col-md-6 col-sm-12'>
                <Label>Members : </Label>
                <table>
                  <tbody>
                    <tr>
                      {viewDetails?.team_members?.map((item: any, index: number) => (
                        <td key={item.id}>
                          <b>{++index + "."}</b>{item?.member_name}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <div className='block-heading mb-2'>Assigned Institute & Departments</div>
        <table className='table table-sm table-bordered table-striped'>
          <thead>
            <tr>
              <th>Institute</th>
              <th>Department</th>
              <th>Inspection Date</th>
            </tr>
          </thead>
          <tbody>
            {viewDetails?.team_assignments?.map((item: any) => (
            <tr key={item?.id}>
              <td>{item?.institute?.institute_name ?? '-'}</td>
              <td>{item?.department?.department_name ?? '-'}</td>
              <td>{moment(item?.inspection_date).format("DD-MMM-YYYY") ?? '-'}</td>
            </tr>
            ))}
          </tbody>
        </table>
      </ModalBody>
      <ModalFooter>
        <div className="text-center">
          <Button
            type="button"
            text="Close"
            className="btn btn-danger"
            onClick={toggle}
          ></Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default TeamViewModal;
