import { apiSlice } from 'src/rtk/api/apiSlice';
import * as logBookEssentialInfoSetupUrl from 'src/helpers/url_helper';

export const logBookEssentialInfoSetupApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['logBookEssentialInfos'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLogbookEssentialInfos: builder.query<APIResponse<LogbookEssentialInfo[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${logBookEssentialInfoSetupUrl.GET_LOGBOOK_ESSENTIAL_INFOS}`,
        }),
        providesTags: ['logBookEssentialInfos'],
      }),

      getLogbookEssentialInfo: builder.query<APIResponse<LogbookEssentialInfo>, string>({
        query: (id) => ({
          url: `${logBookEssentialInfoSetupUrl.GET_LOGBOOK_ESSENTIAL_INFO}/${id}`,
          method: 'GET',
        }),
        providesTags: ['logBookEssentialInfos'],
      }),

      createLogbookEssentialInfo: builder.mutation<APIResponse<LogbookEssentialInfo>, LogbookEssentialInfo>({
        query: (data) => ({
          url: `${logBookEssentialInfoSetupUrl.CREATE_LOGBOOK_ESSENTIAL_INFO}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['logBookEssentialInfos'],
      }),

      updateLogbookEssentialInfo: builder.mutation<APIResponse<LogbookEssentialInfo>, string>({
        query: (url) => ({
          url: `${logBookEssentialInfoSetupUrl.UPDATE_LOGBOOK_ESSENTIAL_INFO}` + url,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['logBookEssentialInfos'],
      }),

      deleteLogbookEssentialInfo: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${logBookEssentialInfoSetupUrl.DELETE_LOGBOOK_ESSENTIAL_INFO}/${id}`,
        }),
        invalidatesTags: ['logBookEssentialInfos'],
      }),
    }),
  });

export const {
  useCreateLogbookEssentialInfoMutation,
  useGetLogbookEssentialInfoQuery,
  useGetLogbookEssentialInfosQuery,
  useUpdateLogbookEssentialInfoMutation,
  useDeleteLogbookEssentialInfoMutation,
} = logBookEssentialInfoSetupApi;
