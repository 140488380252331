import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useState } from 'react';
import { config } from 'src/config';

interface Props {
  data: CertificateTemplate;
}

const InfoViewModal = (props: Props) => {
  const { data } = props;

  const [certificateHeaderFile, setCertificateHeaderFile] = useState('');

  const [certificateFooterFile, setCertificateFooterFile] = useState('');  

  useEffect(() => {
    if (data?.notice_header) {
      setCertificateHeaderFile(`${config?.APP_URL}${data?.notice_header}`);
    }

    if (data?.notice_footer) {
      setCertificateFooterFile(`${config?.APP_URL}${data?.notice_footer}`);
    }
  }, []);

  return (
    <div className="row justify-content-center">
      <div className="col-md-12">
        <div className="row justify-content-center">
          <div className="col-md-4 col-sm-12 mb-4">
            <div className="fw-semibold text-center">Template Name: </div>
            <div className="text-center">{data?.template_name ?? 'N/A'}</div>
          </div>

          <div className="col-md-4 col-sm-12 mb-4">
            <div className="fw-semibold text-center">Certificate Type: </div>
            <div className="text-center">{data?.notice_type ?? 'N/A'}</div>
          </div>
          <div className="col-md-4 col-sm-12 mb-4">
            <div className="fw-semibold text-center">Subject: </div>
            <div className="text-center">
              {data?.department_id ?? 'N/A'}
            </div>
          </div>

          <div className="col-md-4 col-sm-12 mb-4">
            <div className="fw-semibold text-center">Certificate Title: </div>
            <div className="text-center">{data?.notice_subject ?? 'N/A'}</div>
          </div>

          <div className="col-md-4 col-sm-12 mb-4">
            <div className="fw-semibold text-center">Certificate Header: </div>
            <div className="text-center">
              {certificateHeaderFile !== '' ? (
                <a
                  className="me-2 p-0 center"
                  href={certificateHeaderFile}
                  rel="noreferrer"
                  target="_blank"
                >
                  <span className="btn-outline-info btn-sm btn ms-2">View</span>
                </a>
              ) : (
                'N/A'
              )}
            </div>
          </div>

          <div className="col-md-4 col-sm-12 mb-4">
            <div className="fw-semibold text-center">Certificate Footer: </div>
            <div className="text-center">
              {certificateFooterFile !== '' ? (
                <a
                  className="me-2 p-0 center"
                  href={certificateFooterFile}
                  rel="noreferrer"
                  target="_blank"
                >
                  <span className="btn-outline-info btn-sm btn ms-2">View</span>
                </a>
              ) : (
                'N/A'
              )}
            </div>
          </div>

          <div className="col-sm-3 mb-2 align-items-center">
            <div className="fw-semibold text-center">Certificate Body: </div>
          </div>          
          <Editor            
            apiKey={config.EDITOR_API_KEY}
            disabled={true}
            init={{
              height: 500,
              width: 700,
              menubar: false,              
            }}
            value={JSON.parse(data?.notice_body ?? '{}')?.editorHtml}            
          />
        </div>
      </div>
    </div>
  );
};

export default InfoViewModal;
