import { useFormik } from "formik";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { Button, InputField } from "src/components";
import { setFromErrors } from "src/helpers";
import { useTeachingInfrastructureEntryMutation } from "src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi";
import * as Yup from 'yup';

interface TeachingInfrastructureProps {
    departmentId?: string;
    recordOfTeachingInfrastructureToEdit?: TeachingInfrastructureList;
    setModalShow?: any;
}

const initialValues: TeachingInfrastructureEntry = {
    teaching_infrastructure_id: '',
    form_b_department_id: '',
    physical_facilities: '',
    total_number: 0,
    accomodation_or_seats: 0,
};

const TeachingInfrastructureModal = (props: TeachingInfrastructureProps) => {
    const { departmentId, recordOfTeachingInfrastructureToEdit, setModalShow } = props;

    const [teachingInfrastructureEntry, { data: teachingInfrastructureEntryData, isSuccess: isTeachingInfrastructureEntryDataSuccess, isLoading: isTeachingInfrastructureEntryDataLoading, isError: isTeachingInfrastructureEntryDataError, error: teachingInfrastructureEntryDataError }] =
        useTeachingInfrastructureEntryMutation();

    const err: CustomError = teachingInfrastructureEntryDataError as CustomError;

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({}),

        onSubmit: (values: TeachingInfrastructureEntry) => {
            console.log(values);
            values.form_b_department_id = departmentId;
            teachingInfrastructureEntry(values);
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    const handleFormReset = () => {
        resetForm();
    };

    useEffect(() => {
        if (recordOfTeachingInfrastructureToEdit?.id) {
            setValues({
                teaching_infrastructure_id: recordOfTeachingInfrastructureToEdit?.id,
                form_b_department_id: recordOfTeachingInfrastructureToEdit?.form_b_department_id,
                physical_facilities: recordOfTeachingInfrastructureToEdit?.physical_facilities,
                total_number: recordOfTeachingInfrastructureToEdit?.total_number,
                accomodation_or_seats: recordOfTeachingInfrastructureToEdit?.accomodation_or_seats,
            });
        }
    }, []);

    useEffect(() => {
        if (isTeachingInfrastructureEntryDataSuccess) {
            handleFormReset();
            toast.success('Teaching infrastructure record saved successfully');
            setModalShow(false);
        }
        if (isTeachingInfrastructureEntryDataError) {
            Object.entries(err?.data).forEach((entry: any) => {
                entry[1]?.forEach((message: any) => {
                    toast.error(message);
                });
            });
            setFromErrors(err.data, setErrors);
        }
    }, [isTeachingInfrastructureEntryDataSuccess, isTeachingInfrastructureEntryDataError]);

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title">
                                    <i className="fa fa-plus me-2" />
                                    Teaching Infrastructure
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    autoComplete="off"
                                    className="custom-form"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    onReset={(e) => {
                                        e.preventDefault();
                                        handleFormReset();
                                    }}
                                >
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="physical_facilities">Physical Facilities</Label>
                                                <InputField
                                                    type="select"
                                                    options={[
                                                        {label: 'Class room', value: 'Class room'},
                                                        {label: 'Seminar room', value: 'Seminar room'},
                                                        {label: 'Students\' common room', value: 'Students\' common room'},
                                                        {label: 'Museum', value: 'Museum'},
                                                        {label: 'Name and number of audio-visual aids', value: 'Name and number of audio-visual aids'},
                                                        {label: 'Skill lab', value: 'Skill lab'},
                                                    ]}
                                                    placeholder=""
                                                    {...getFieldProps('physical_facilities')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="total_number">Number</Label>
                                                <InputField
                                                    type="number"
                                                    placeholder=""
                                                    {...getFieldProps('total_number')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="accomodation_or_seats">Accomodation/Seats</Label>
                                                <InputField
                                                    type="number"
                                                    placeholder=""
                                                    {...getFieldProps('accomodation_or_seats')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} className="mt-3">
                                            <FormGroup className="mb-3 text-center">
                                                <Button
                                                    type="reset"
                                                    text="Reset"
                                                    className="btn btn-warning me-2"
                                                ></Button>
                                                <Button
                                                    type="submit"
                                                    text="Save"
                                                    className="btn btn-success me-2"
                                                    disabled={isTeachingInfrastructureEntryDataLoading}
                                                ></Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default TeachingInfrastructureModal;