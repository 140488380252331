import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./rtk/app/store";
// Import scss
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/preloader.scss";
import "./assets/scss/theme.scss";

const getId: any = document.getElementById("root");
const root = ReactDOM.createRoot(getId);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
