import { useState } from 'react';
import { Button, Container, Progress } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import EducationalProfile from '../../components/ProfileForm/EducationalProfile/EducationalProfile';
import ExperienceProfile from '../../components/ProfileForm/ExperienceProfile/ExperienceProfile';
import PersonalProfile from '../../components/ProfileForm/PersonalProfile';
import TrainingRecordProfile from '../../components/ProfileForm/TrainingProfile/TrainingProfile';
import TraineeRegModal from './TraineeRegModal';

const submitButton = (type: number) => {
  let buttonLabel = '';
  switch (type) {
    case 1:
      return (buttonLabel = 'Next');
    case 2:
      return (buttonLabel = 'Next');
    case 3:
      return (buttonLabel = 'Next');
    default:
      return (buttonLabel = 'Submit');
  }
};

const TraineeRegistration = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const [saveChanges, setSaveChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const handleNextClick = () => {
    if (currentStep !== 1 && currentStep !== 4) {
      setCurrentStep((p) => p + 1);
    }
    if (currentStep === 1) {
      setSaveChanges((p) => !p);
    }
    if (currentStep === 4) {
      setModalShow(true);
    }
  };

  const modalShowHandler = (modalState: boolean) => {
    setModalShow(modalState);
  };

  // useEffect(() => {
  //   if (!isLoading) {
  //     setCurrentStep((p) => p + 1);
  //   }
  // }, [isLoading, saveChanges]);

  const goToNext = (msg: any) => {
    setCurrentStep((p) => p + 1);
  };

  document.title = 'Profile Update | BCPS';

  return (
    <div className='page-content'>
      <Container fluid>
        <div className='page-breadcrumb-wrapper'>
          <BetterBreadcrumb
            title='Profile Update'
            breadcrumbItem={[{ link: '/dashboard', name: 'Dashboard' }]}
          />
        </div>

        <div className='px-3 py-4 bg-white border'>
          <Progress
            className='mb-4'
            animated
            color='success'
            value={25 * currentStep}
          />

          {currentStep === 1 && <PersonalProfile goToNext={goToNext} />}
          {currentStep === 2 && <EducationalProfile nextDisabled={setIsNextDisabled} />}
          {currentStep === 3 && <TrainingRecordProfile nextDisabled={setIsNextDisabled} setCurrentStep={setCurrentStep} />}
          {currentStep === 4 && <ExperienceProfile nextDisabled={setIsNextDisabled} />}

          {currentStep !== 1 && (
            <div className='d-flex justify-content-center gap-3 mb-3'>
              <Button
                color='success'
                onClick={() => setCurrentStep((p) => p - 1)}
                disabled={currentStep === 1}
              >
                <i className="fa-solid fa-chevron-left" /> Previous
              </Button>
              <Button color='success' onClick={handleNextClick} disabled={isNextDisabled}>
                {submitButton(currentStep)} 
                {' '}{currentStep === 4 ? '' : <i className="fa-solid fa-chevron-right" />}
              </Button>
            </div>
          )}
          {modalShow ? (
            <TraineeRegModal
              isOpen={modalShow}
              toggle={() => setModalShow(!modalShow)}
            />
          ) : null}
        </div>

      </Container>
    </div>
  );
};

export default TraineeRegistration;
