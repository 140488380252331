import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { Button } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetProtocolByIdQuery } from 'src/rtk/features/disertation-thesis/protocolListForApprovalApi';
import { ProtocolSummaryReport } from './ProtocolSummaryReport';
import StepOne from './StepOne';
import StepThree from './StepThree';
import StepTwo from './StepTwo';

const ProtocolApprovalView = () => {
    const { id } = useParams<{ id: string }>();
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [showReport, setShowReport] = useState<boolean>(false);

    const {
        data: protocolData,
        isLoading: isProtocolDataLoading,
        isSuccess: isProtocolDataSuccess,
        isError: isProtocolDataError,
        error: protocolDataError,
    } = useGetProtocolByIdQuery(id);

    const protocolEntry: any = protocolData!;
    const titleEntry: any = protocolData?.title!;

    const goToNext = () => {
        if (currentStep < 3) {
            setCurrentStep((p: number) => p + 1);
        }
    };

    const goToPrevious = () => {
        if (currentStep > 1) {
            setCurrentStep((p: number) => p - 1);
        }
    };

    return (
        <>
            <div className='page-content'>
                <Container fluid>
                    <BetterBreadcrumb
                        title="Protocol Summary"
                        breadcrumbItem={[{ link: '/', name: 'Dashboard' }]}
                    />

                    <div className={"d-flex gap-3 justify-content-end mb-3"}>
                        <Button
                            type="button"
                            text="Protocol Entry"
                            color="info"
                            outline
                            onClick={(e) => {
                                window.open(`/thesis-dissertation-protocol-entry-view/${protocolEntry?.id}`, '_blank');
                            }}
                        ></Button>

                        <Button
                            type="button"
                            text="View in PDF"
                            color="secondary"
                            onClick={(e) => {
                                setShowReport(true);
                            }}
                        ></Button>
                    </div>

                    <Row className='justify-content-center'>
                        <Col sm={10}>
                            {currentStep === 1 && <StepOne goToNext={goToNext} protocolEntry={protocolEntry} titleEntry={titleEntry} />}
                            {currentStep === 2 && <StepTwo goToNext={goToNext} goToPrevious={goToPrevious} protocolEntry={protocolEntry} titleEntry={titleEntry} />}
                            {currentStep === 3 && <StepThree goToPrevious={goToPrevious} protocolEntry={protocolEntry} titleEntry={titleEntry} />}

                            {
                                showReport ?
                                    <ProtocolSummaryReport
                                        protocolEntry={protocolEntry}
                                        titleEntry={titleEntry}
                                        showReport={showReport}
                                        setShowReport={setShowReport}
                                    />
                                    :
                                    null
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default ProtocolApprovalView;
