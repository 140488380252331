import { apiSlice } from 'src/rtk/api/apiSlice';
import * as logBookTableOfContentSetupUrl from 'src/helpers/url_helper';

export const logBookTableOfContentSetupApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['logBookTableOfContents'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLogbookTableOfContents: builder.query<APIResponse<LogbookTableOfContent[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${logBookTableOfContentSetupUrl.GET_LOGBOOK_TABLE_OF_CONTENTS}`,
        }),
        providesTags: ['logBookTableOfContents'],
      }),

      getLogbookTableOfContent: builder.query<APIResponse<LogbookTableOfContent>, string>({
        query: (id) => ({
          url: `${logBookTableOfContentSetupUrl.GET_LOGBOOK_TABLE_OF_CONTENT}/${id}`,
          method: 'GET',
        }),
        providesTags: ['logBookTableOfContents'],
      }),

      createLogbookTableOfContent: builder.mutation<APIResponse<LogbookTableOfContent>, LogbookTableOfContent>({
        query: (data) => ({
          url: `${logBookTableOfContentSetupUrl.CREATE_LOGBOOK_TABLE_OF_CONTENT}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['logBookTableOfContents'],
      }),

      updateLogbookTableOfContent: builder.mutation<APIResponse<LogbookTableOfContent>, string>({
        query: (url) => ({
          url: `${logBookTableOfContentSetupUrl.UPDATE_LOGBOOK_TABLE_OF_CONTENT}` + url,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['logBookTableOfContents'],
      }),

      deleteLogbookTableOfContent: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${logBookTableOfContentSetupUrl.DELETE_LOGBOOK_TABLE_OF_CONTENT}/${id}`,
        }),
        invalidatesTags: ['logBookTableOfContents'],
      }),
    }),
  });

export const {
  useCreateLogbookTableOfContentMutation,
  useGetLogbookTableOfContentQuery,
  useGetLogbookTableOfContentsQuery,
  useUpdateLogbookTableOfContentMutation,
  useDeleteLogbookTableOfContentMutation,
} = logBookTableOfContentSetupApi;
