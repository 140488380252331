import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { Button, InputField } from "src/components";
import { config } from "src/config";
import { setFromErrors } from "src/helpers";
import { useRegisterDepartmentStepTwoMutation } from "src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi";
import * as Yup from 'yup';

interface OpdRecordProps {
    departmentId?: string;
    opdRecordToEdit?: IpdOpdRecordList;
    setModalShow?: any;
}

const initialValues: IpdOpdRecordEntry = {
    ipd_opd_record_id: '',
    form_b_department_id: '',
    record_type: '',
    opd_period_from: '',
    opd_period_to: '',
    opd_disease_profile: '',
    opd_duration_of_care: 0,
    opd_number_of_patients_attending_per_day: 0,
    opd_minor_operation_done_perday: 0,
    daily_opd_attendance: 0,
};

const OutPatientRecordsOfTheDeptModal = (props: OpdRecordProps) => {
    const { departmentId, opdRecordToEdit, setModalShow } = props;

    const diseaseProfileOfOutPatientFileReference = useRef<any>();
    const [diseaseProfileFile, setDiseaseProfileFile] = useState<any>('');

    const [registerDepartmentStepTwo, { data: ipdOpdData, isSuccess: isIpdOpdDataSuccess, isLoading: isIpdOpdDataLoading, isError: isIpdOpdDataError, error: ipdOpdDataError }] =
        useRegisterDepartmentStepTwoMutation();

    const err: CustomError = ipdOpdDataError as CustomError;

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({}),

        onSubmit: (values: any) => {
            console.log(values);
            values.record_type = 'opd';
            values.form_b_department_id = departmentId;
            const formData = new FormData();

            Object.keys(values).forEach((key: string) => {
                formData.append(key, values[key]);
            });

            registerDepartmentStepTwo(formData);
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    const handleFormReset = () => {
        diseaseProfileOfOutPatientFileReference.current.value = '';
        setDiseaseProfileFile('');
        resetForm();
    };

    useEffect(() => {
        if (opdRecordToEdit?.id) {
            setValues({
                ipd_opd_record_id: opdRecordToEdit?.id,
                form_b_department_id: opdRecordToEdit?.form_b_department_id,
                record_type: opdRecordToEdit?.record_type,
                opd_period_from: opdRecordToEdit?.opd_period_from,
                opd_period_to: opdRecordToEdit?.opd_period_to,
                opd_disease_profile: '',
                opd_duration_of_care: opdRecordToEdit?.opd_duration_of_care,
                opd_number_of_patients_attending_per_day: opdRecordToEdit?.opd_number_of_patients_attending_per_day,
                opd_minor_operation_done_perday: opdRecordToEdit?.opd_minor_operation_done_perday,
                daily_opd_attendance: opdRecordToEdit?.daily_opd_attendance,
            });
            setDiseaseProfileFile(opdRecordToEdit?.opd_disease_profile);
        }
    }, []);

    useEffect(() => {
        if (isIpdOpdDataSuccess) {
            handleFormReset();
            toast.success('Out patient record saved successfully');
            setModalShow(false);
        }
        if (isIpdOpdDataError) {
            Object.entries(err?.data).forEach((entry: any) => {
                entry[1]?.forEach((message: any) => {
                    toast.error(message);
                });
            });
            setFromErrors(err.data, setErrors);
        }
    }, [isIpdOpdDataSuccess, isIpdOpdDataError]);

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title">
                                    <i className="fa fa-plus me-2" />
                                    Out-patient record of the department
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    autoComplete="off"
                                    className="custom-form"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    onReset={(e) => {
                                        e.preventDefault();
                                        handleFormReset();
                                    }}
                                >
                                    <Row>
                                        <Col sm={12} md={3}>
                                            <FormGroup className="mb-3">
                                                <Label for="opd_period_from">From</Label>
                                                <InputField
                                                    type="date"
                                                    placeholder=""
                                                    {...getFieldProps('opd_period_from')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={3}>
                                            <FormGroup className="mb-3">
                                                <Label for="opd_period_to">To</Label>
                                                <InputField
                                                    type="date"
                                                    placeholder=""
                                                    {...getFieldProps('opd_period_to')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="diseaseProfile">Disease profile of patients of OPD in last year</Label>
                                                {typeof diseaseProfileFile === 'string' && diseaseProfileFile !== '' ? (
                                                    <a
                                                        className="me-2 p-0 center"
                                                        href={`${config?.APP_URL}${diseaseProfileFile}`}
                                                        rel="noreferrer"
                                                        target="_blank"
                                                    >
                                                        <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                                    </a>
                                                ) : (
                                                    typeof diseaseProfileFile !== 'string' && diseaseProfileFile &&
                                                    <a
                                                        className="me-2 p-0 center"
                                                        href={URL.createObjectURL(diseaseProfileFile)}
                                                        rel="noreferrer"
                                                        target="_blank"
                                                    >
                                                        <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                                    </a>
                                                )}
                                                <input
                                                    type="file"
                                                    ref={diseaseProfileOfOutPatientFileReference}
                                                    name="diseaseProfile"
                                                    id="diseaseProfile"
                                                    className="form-control"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        setFieldValue('opd_disease_profile', e.target.files![0]);
                                                        setDiseaseProfileFile(e.target.files![0]);
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup row className="col-sm-12 mb-3">
                                                <Label for="opd_duration_of_care" sm={8}>OPD Work Hour (Per Week)</Label>
                                                <Col sm={3}>
                                                    <InputField
                                                        min={'0'}
                                                        type="number"
                                                        placeholder=""
                                                        {...getFieldProps('opd_duration_of_care')}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup row className="col-sm-12 mb-3">
                                                <Label for="opd_number_of_patients_attending_per_day" sm={8}>No. of Patients Attending the OPD (Per Day)</Label>
                                                <Col sm={3}>
                                                    <InputField
                                                        min={'0'}
                                                        type="number"
                                                        placeholder=""
                                                        {...getFieldProps('opd_number_of_patients_attending_per_day')}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup row className="col-sm-12 mb-3">
                                                <Label for="opd_minor_operation_done_perday" sm={8}>No. of Minor OT done in OPD Per Week (for Surgery)</Label>
                                                <Col sm={3}>
                                                    <InputField
                                                        min={'0'}
                                                        type="number"
                                                        placeholder=""
                                                        {...getFieldProps('opd_minor_operation_done_perday')}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup row className="col-sm-12 mb-3">
                                                <Label for="daily_opd_attendance" sm={8}>
                                                    Daily OPD Attendance (Avg.)
                                                </Label>
                                                <Col sm={3}>
                                                    <InputField
                                                        min={'0'}
                                                        type="number"
                                                        placeholder=""
                                                        {...getFieldProps('daily_opd_attendance')}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} className="mt-3">
                                            <FormGroup className="mb-3 text-center">
                                                <Button
                                                    type="reset"
                                                    text="Reset"
                                                    className="btn btn-warning me-2"
                                                ></Button>
                                                <Button
                                                    type="submit"
                                                    text="Save"
                                                    className="btn btn-success me-2"
                                                    disabled={isIpdOpdDataLoading}
                                                ></Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default OutPatientRecordsOfTheDeptModal;