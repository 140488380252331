import { EditLogbookEntryDetails } from 'src/pages/Setup/LogbookEntryDetails/EditLogbookEntryDetails';
import SelectLogbookEntryDetailsGroup from 'src/pages/Setup/LogbookEntryDetails/SelectLogbookEntryDetailsGroup';
import SelectAcademicActivityEntryDetailsGroup from 'src/pages/Setup/SetupAcademicActivitiesFields/SelectLogbookEntryDetailsGroup';

export const logbookEntrySetupDetailsRoutes = [
  // logbook entry setup details
  {
    path: '/setup/logbook-entry-setup-details/',
    component: SelectLogbookEntryDetailsGroup,
  },
  {
    path: '/setup/academic-activity-entry-details/',
    component: SelectAcademicActivityEntryDetailsGroup,
  },
  {
    path: '/setup/logbook-entry-details/update/:id',
    component: EditLogbookEntryDetails,
  },
  {
    path: '/setup/logbook-entry-setup-details/add',
    component: SelectLogbookEntryDetailsGroup,
  },
];
