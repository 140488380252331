import React from "react";
import gateWay from "../../assets/images/payment/gateway.png";

const GatewayDemo = () => {
  return (
    <div className="page-content">
      <div className="container ">
        <img
          className="img-fluid d-block rounded mx-auto"
          src={gateWay}
          alt=""
        />
      </div>
    </div>
  );
};

export default GatewayDemo;
