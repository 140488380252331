import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { config } from 'src/config';
import {
  useGetPaymentConfirmMutation,
  useGetPaymentListQuery,
} from 'src/rtk/features/payment/paymentApprovalApi';
import { useGetRegInfoQuery } from 'src/rtk/features/registration-approval/registrationApprovalApi';

import avatar from '../../assets/images/users/default-avatar.png';
import loader from '../../assets/images/loader.gif';

interface Props {
  isOpen: boolean;
  toggle: any;
  regNo: string;
  id: string;
}

const initialValues: RegApprove = {
  registration_no: '',
  status: '',
  message: '',
  type: '',
  registration_type: '',
};

const PaymentApprovalViewModal = (props: Props) => {
  const { isOpen, toggle, regNo, id } = props;

  const [verifyId, setVerifyId] = useState('');

  const { data, isLoading: isMyInfoLoading } = useGetRegInfoQuery(regNo);

  const userInfo = data?.data || ({} as RegInfo);

  const { data: paymentList, isLoading: isPaymentInfoLoading } = useGetPaymentListQuery(id);
  let obj;
  if (paymentList?.data) {
    obj = JSON.parse(paymentList?.data?.extra_info!);
  }

  const [
    getPaymentConfirm,
    { data: paymentConfirm, isSuccess, isError, error },
  ] = useGetPaymentConfirmMutation();

  const handleVerify = (e: any) => {
    getPaymentConfirm(id);
  };
  const err = error as CustomError;

  useEffect(() => {
    if (isSuccess) {
      toast.success('Verified Successfully');
      isOpen && toggle();
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
    }
  }, [isSuccess, isError]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="lg">
      {isMyInfoLoading || isPaymentInfoLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <ModalHeader toggle={toggle}>Applicant View</ModalHeader>

      <ModalBody>
        <div>
          <h6 className="block-heading">Personal Information</h6>
          <div className="row">
            <div className="col-md-9">
              <table className="table table-sm table-bordered table-striped">
                <tbody>
                  <tr>
                    <td>
                      <strong>Name: </strong>
                      <span>{userInfo?.personal_info?.full_name}</span>
                    </td>

                    <td>
                      <strong>Father's Name: </strong>
                      <span>
                        {userInfo?.personal_info?.fathers_name ?? 'N/A'}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Mother's Name: </strong>
                      <span>
                        {userInfo?.personal_info?.mothers_name ?? 'N/A'}
                      </span>
                    </td>
                    <td>
                      <strong>Date of Birth: </strong>
                      <span>
                        {userInfo?.personal_info?.date_of_birth ?? 'N/A'}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>NID: </strong>
                      <span>
                        {userInfo?.personal_info?.national_id ?? 'N/A'}
                      </span>
                    </td>

                    <td>
                      <strong>Mobile Number: </strong>
                      <span>{userInfo?.personal_info?.mobile ?? 'N/A'}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Email: </strong>
                      <span>{userInfo?.personal_info?.email ?? 'N/A'}</span>
                    </td>

                    <td>
                      <strong>Alt Number: </strong>
                      <span>{userInfo?.personal_info?.telephone ?? 'N/A'}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-3 col-sm-12">
              <div className="img-fluid img-rounded">
                <img
                  src={
                    userInfo?.personal_info?.picture
                      ? `${config?.APP_URL}${userInfo?.personal_info?.picture}`
                      : avatar
                  }
                  width="120"
                />
              </div>
            </div>
          </div>
          <div>
            <h6 className="block-heading">Payment Details</h6>
          </div>
          <div className="row">
            <div className="col-md-12">
              <table className="table table-sm table-bordered table-striped">
                <tbody>
                  <tr>
                    <td width="50%">
                      <strong>Registration No: </strong>
                      <span>{obj?.registration_no}</span>
                    </td>
                    <td width="50%">
                      <strong>Name: </strong>
                      <span>{obj?.registration_name ?? 'N/A'}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Training Name: </strong>
                      <span>{obj?.course_name ?? 'N/A'}</span>
                    </td>
                    <td>
                      <strong>Subject: </strong>
                      <span>{obj?.subject_name ?? 'N/A'}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Transaction ID: </strong>
                      <span>
                        {paymentList?.data?.payment_transaction_id ?? 'N/A'}
                      </span>
                    </td>

                    <td>
                      <strong>Payment Mode: </strong>
                      <span>{paymentList?.data?.payment_mode ?? 'N/A'}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Payment Method: </strong>
                      <span>{paymentList?.data?.payment_method ?? 'N/A'}</span>
                    </td>
                    <td>
                      <strong>Paid Amount: </strong>
                      <span>{paymentList?.data?.total_amount ?? 'N/A'}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Status: </strong>
                      {paymentList?.data.payment_confirmation_ind ? (
                        <span className="ms-1 badge badge-approved">
                          Verified
                        </span>
                      ) : (
                        <span className="ms-1 badge badge-pending">
                          Pending
                        </span>
                      )}
                      <span>
                        {paymentList?.data.bank_deposit_slip ? (
                          <a
                            className="ms-2 btn btn-sm btn-outline-info"
                            href={
                              config.APP_URL +
                              paymentList?.data.bank_deposit_slip
                            }
                            rel="noreferrer"
                            target="_blank"
                          >
                            <i className="fa-solid fa-file" /> Payment Slip
                          </a>
                        ) : (
                          <span className="text-muted"></span>
                        )}
                      </span>
                    </td>
                    <td>
                      {paymentList?.data?.payment_verification_status !== 'Approved' && (
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={(e) => {
                            handleVerify(e);
                          }}
                        >
                          Verify Payment Now
                        </button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <div className="d-flex gap-2 ">
          <Button color="danger" onClick={toggle}>
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default PaymentApprovalViewModal;
