import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container, Table } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetLogbookEssentialInfosQuery } from 'src/rtk/features/setup/logBookEssentialInfoSetup/logBookEssentialInfoSetupApi';
import LogbookEssentialInfoTableRow from './LogbookEssentialInfoTableRow';

export const LogbookEssentialInfos = () => {
  const { data, error, isLoading, isError } = useGetLogbookEssentialInfosQuery();

  const err: CustomError = error as CustomError;
  const logbookEssentialInfos = data?.data || [];

  let content = null;

  if (isLoading) {
    content = <div className="mt-3">Loading...</div>;
  }
  if (!isLoading && isError && error) {
    content = <div className="mt-3">{err.message}</div>;
  }
  if (!isLoading && !isError && logbookEssentialInfos?.length > 0) {
    content = logbookEssentialInfos.map((logbookEssentialInfo: any, index: any) => (
      <LogbookEssentialInfoTableRow logbookEssentialInfo={logbookEssentialInfo} index={index} key={logbookEssentialInfo.id} />
    ));
  } else {
    content = (
      <tr>
        <td colSpan={11}>
          <div className="text-center text-danger h6">No data available!</div>
        </td>
      </tr>
    );
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Logbook Essential Info List'}
          breadcrumbItem={[{ link: '/setup/logbook-essential-info/add', name: 'Add Logbook Essential Info' }]}
        />
        <Card>
          <CardHeader className="d-flex justify-content-end">
            <Link className="btn btn-sm btn-primary" to="/setup/logbook-essential-info/add">
              Add Logbook Essential Info
            </Link>
          </CardHeader>
          <CardBody>
            <div className="table-responsive">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Sl. </th>
                    <th>Setup Logbook Master Heading</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Setup Logbook Essential Info</th>                   
                    <th>Serial No</th>                    
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
