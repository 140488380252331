import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import { useGetFacultyInfosQuery } from 'src/rtk/features/setup/facultyInfo/facultyInfoApi';
import { useDeleteSubjectInfoMutation } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import { EditSubjectInfo } from './EditSubjectInfo';

interface Props {
  subjectInfo: SubjectInfo;
  index: number;
}

const SubjectInfoTableRow = ({ subjectInfo, index }: Props) => {
  const history = useHistory();
  
  const [infoId, setInfoId] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editId, setEditId] = useState('');

  const [deleteSubjectInfo, { isSuccess, isLoading, isError }] =
    useDeleteSubjectInfoMutation();

  const { data } = useGetFacultyInfosQuery();
  const facultyInfos = data?.data;

  const deleteHandler = (modalState: boolean, id: string) => {
    setModalShow(modalState);
    setInfoId(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Subject Info deleted successfully');
    }
    if (isError) {
      toast.error('Error deleting subject info');
    }
  }, [isSuccess, isError]);

  return (
    <tr key={subjectInfo.id}>
      <td>{subjectInfo.subject_code}</td>
      <td>{subjectInfo.subject_name}</td>
      <td>{subjectInfo.category}</td>
      <td>{subjectInfo.description}</td>
      <td>{subjectInfo.duration}{' '}{subjectInfo.duration_unit}</td>
      <td>
        {
          facultyInfos?.find(
            (f: any) => f.id === subjectInfo.setup_faculty_info_id,
          )?.faculty_name
        }
      </td>
      <td>{subjectInfo.status ? 'Active' : 'Inactive' || 'N/A'}</td>
      <td>
        <div className="d-flex gap-2">
          <Button
            size="sm"
            text="Edit"
            color="warning"
            onClick={() => {
              setEditModalShow(true);
              setEditId(subjectInfo?.id!);
            }}
          />
{/* 
          <Button
            size="sm"
            text="Delete"
            color="danger"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => deleteHandler(true, subjectInfo.id!)}
          /> */}
        </div>
        {modalShow ? (
          <DeleteInformationModal
            isOpen={modalShow}
            toggle={() => setModalShow(!modalShow)}
            id={infoId}
            deleteInfomation={deleteSubjectInfo}
          />
        ) : null}
        {editModalShow &&
          <EditSubjectInfo isOpen={editModalShow} toggle={() => setEditModalShow(!editModalShow)} id={editId}/>
        }
      </td>
    </tr>
  );
};

export default SubjectInfoTableRow;
