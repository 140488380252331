import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

interface Props {
    isOpen: boolean;
    toggle: any;
    info: any;
}

const AnswerViewModal = (props: Props) => {
    const { isOpen, toggle, info } = props;

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="md" backdrop={'static'}>
            <ModalHeader toggle={toggle}>View Answer</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <table className="table table-bordered table-striped table-sm">
                            <tbody>
                                <tr>
                                    <td>
                                        <strong>Inquiry Type: </strong>{info?.inquiry_type?.inquiry_type}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Inquiry: </strong>{info?.query}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Answer: </strong>{info?.answer}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default AnswerViewModal;
