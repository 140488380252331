import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { Container, Form, Label, FormGroup, Input } from 'reactstrap';
import { InputField, Button } from 'src/components';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { setFromErrors } from 'src/helpers';
import { useGetLogBookMasterSetupsQuery } from 'src/rtk/features/setup/logBookMasterSetup/logBookMasterSetupApi';
import { useHistory } from 'react-router-dom';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useCreateLogbookEntrySetupMasterMutation, useGetLogbookEntrySetupMastersQuery } from 'src/rtk/features/setup/logBookEntrySetupMaster/logBookEntrySetupMasterApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import { useGetFcpsCourseListsForLogbookEntryMutation } from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';

const initialValues: LogbookEntrySetupMaster = {
    id: '',
    setup_logbook_master_id: '',
    logbook_entry_setup_master_id: '',
    particulars: '',
    details: '',
    note: '',
    help: '',
    is_show_ind: 1,
    sl_no: 0,
    particulary_type: 0,
    course_type: '',
    target_minimum: 0,
    target_maximum: 0,
    status: true,
    creator_organization_id: '',
    updater_organization_id: '',
    created_by: '',
    updated_by: '',
    deleted_by: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
};

export const AddLogbookEntrySetupMaster = () => {
  const [createLogbookEntrySetupMaster, { isSuccess, isError, error }] = useCreateLogbookEntrySetupMasterMutation();
  const [showStatus, setShowStatus] = useState<boolean>(true);
  const [subject, setSubject] = useState<SubjectInfo>(
    {} as SubjectInfo
  );

  const [mainTrainings, setMainTrainings] = useState<FcpsCourseList[]>([]);

  const { data: getSubjectInfolist } = useGetSubjectInfosQuery();
  const subjects = getSubjectInfolist?.data;
  
  const [createFcpsCourse, { data: fcpsCourseListData, isSuccess: isFcpsCourseListSuccess, isError: isFcpsCourseListError, error: fcpsCourseListError }] =
        useGetFcpsCourseListsForLogbookEntryMutation();

  const { data: logbookEntrySetupMastersData } = useGetLogbookEntrySetupMastersQuery();
  const logbookEntrySetupMasters = logbookEntrySetupMastersData?.data || [];

  const history = useHistory();

  const err: CustomError = error as CustomError;
  const { handleSubmit, handleChange, getFieldProps, values, touched, errors, resetForm, setErrors } =
    useFormik({
      initialValues,
      validationSchema: Yup.object({
        setup_logbook_master_id: Yup.string().required('Please select the logbook master'),        
      }),

      onSubmit: (values) => {
        if(showStatus) {
            values.is_show_ind = 1;
        } else {
            values.is_show_ind = 0;
        }
        
        createLogbookEntrySetupMaster({
          ...values,
        });
        console.log(values);
      },
    });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Logbook entry setup master created successfully');
      history.push('/setup/logbook-entry-setup-master');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  let subjectOptions: SelectOption[] =
    subjects?.map((subject) => ({
      value: subject.id!,
      label: subject.subject_name,
    })) || [];
  
  let mainTrainingOptions: SelectOption[] =
    mainTrainings?.map((mainTraining) => ({
      value: mainTraining.id!,
      label: mainTraining.name!,
    })) || [];

  // let logbookEntrySetupMasterOptions: SelectOption[] = [    
  //   ...logbookEntrySetupMasters?.map((l: LogbookEntrySetupMaster) => ({
  //     label: l.particulars!,
  //     value: l.id!,
  //   })),
  // ];

  useEffect(() => {
    createFcpsCourse(subject?.id!);
  }, [subject]);

  useEffect(() => {
    if (isFcpsCourseListSuccess && fcpsCourseListData?.success) {
      const mainTrainings: FcpsCourseList[] = fcpsCourseListData.data;
      setMainTrainings(mainTrainings);                                     
    } else {
      setMainTrainings([]);
    }   
  }, [isFcpsCourseListSuccess, fcpsCourseListData?.success]);

  const handleSubjectSelect = (e: any) => {
    const { options, selectedIndex, value } = e.target;
    const subject = subjects?.find((subject) => subject.id === value);
    setSubject(subject!);    
  };

  const handleMainTrainingOption = (e: any) => {
    const { options, selectedIndex, value } = e.target;
    const mainTraining = mainTrainings?.find((mainTraining) => mainTraining.id === value);
    values.setup_logbook_master_id = value;    
  };

  const handleCoreAdvance = (e: any) => {
    const { options, selectedIndex, value } = e.target;        
    values.course_type = value;
  };

  const handleShowStatus = (e: any) => {
    setShowStatus(e.target.checked);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Add Logbook Entry Setup Master'}
          breadcrumbItem={[{ link: '/setup/logbook-entry-setup-master/', name: 'Logbook entry setup master list' }]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="card mt-3">
            <h5 className="card-header">
              <i className="fa fa-plus me-2" />
              Add Logbook Entry Setup Master
            </h5>
            <div className="card-body">
              <Form
                className="custom-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="row">      
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label className="form-label">Subject</Label>
                      <InputField
                        type="select"
                        name="subject"
                        placeholder=""
                        options={subjectOptions}
                        onChange={(e) => {
                          handleSubjectSelect(e);
                        }}
                      />
                    </FormGroup>
                  </div> 

                  <div className="col-lg-4 col-md-6 col-sm-12">
                      <FormGroup className="mb-3">
                        <Label className="form-label">Main Training</Label>
                        <InputField
                          type="select"
                          name="mainTraining"
                          placeholder=""
                          options={mainTrainingOptions}
                          onChange={(e) => {
                            handleMainTrainingOption(e);
                          }}
                        />
                      </FormGroup>
                  </div>

                  {/* <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="logbook_entry_setup_master_id">Logbook Entry Setup Master</Label>
                      <InputField
                        type="select"
                        placeholder="Logbook Entry Setup Master"
                        options={logbookEntrySetupMasterOptions}
                        invalid={{ errors, touched }}
                        {...getFieldProps('logbook_entry_setup_master_id')}
                      />
                    </FormGroup>
                  </div> */}

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="particulars">Particulars</Label>
                      <InputField
                        type="textarea"
                        placeholder="Write here..."
                        invalid={{ errors, touched }}
                        {...getFieldProps('particulars')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="details">Details</Label>
                      <InputField
                        type="textarea"
                        placeholder="Details"
                        invalid={{ errors, touched }}
                        {...getFieldProps('details')}
                      />
                    </FormGroup>
                  </div> 

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="note">Note</Label>
                      <InputField
                        type="text"
                        placeholder="Note"
                        invalid={{ errors, touched }}
                        {...getFieldProps('note')}
                      />
                    </FormGroup>
                  </div> 

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="help">Help</Label>
                      <InputField
                        type="text"
                        placeholder="Help"
                        invalid={{ errors, touched }}
                        {...getFieldProps('help')}
                      />
                    </FormGroup>
                  </div>                                    

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="sl_no">Sl No</Label>
                      <InputField
                        type="number"
                        placeholder="Sl No"
                        invalid={{ errors, touched }}
                        {...getFieldProps('sl_no')}
                      />
                    </FormGroup>
                  </div>  

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="particulary_type">Particulary Type</Label>
                      <InputField
                        type="number"
                        placeholder="Particulary Type"
                        invalid={{ errors, touched }}
                        {...getFieldProps('particulary_type')}
                      />
                    </FormGroup>
                  </div>    

                  <div className="col-lg-4 col-md-6 col-sm-12">
                      <FormGroup className="mb-3">
                        <Label className="form-label">Core/Advanced</Label>
                        <InputField
                          type="select"
                          name="coreAdvance"
                          placeholder=""
                          options={[{label: 'Core', value: 'core'}, {label: 'Advanced', value: 'adv'}]}
                          onChange={(e) => {
                            handleCoreAdvance(e);
                          }}
                        />
                      </FormGroup>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <FormGroup className="mb-3">
                        <Label for="target_minimum">Target Minimum</Label>
                        <InputField
                          type="number"                          
                          invalid={{ errors, touched }}
                          {...getFieldProps('target_minimum')}
                        />
                      </FormGroup>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <FormGroup className="mb-3">
                        <Label for="target_maximum">Target Maximum</Label>
                        <InputField
                          type="number"                          
                          invalid={{ errors, touched }}
                          {...getFieldProps('target_maximum')}
                        />
                      </FormGroup>
                    </div>

                  <div className="col-sm-12">
                    <FormGroup check className="mb-3 mt-4">
                      <Label check>
                        <Input
                            name='is_show_ind'
                            id='is_show_ind' 
                            type="checkbox" 
                            onChange={(e) => {
                                handleShowStatus(e);
                                handleChange(e);
                            }}
                            checked={showStatus} 
                             />{' '}
                        Show
                      </Label>
                    </FormGroup>
                  </div>            

                  <div className="col-sm-12">
                    <FormGroup check className="mb-3 mt-4">
                      <Label check>
                        <Input type="checkbox" checked={values.status} {...getFieldProps('status')} />{' '}
                        Status
                      </Label>
                    </FormGroup>
                  </div>
                </div>
                <Button type="submit" text="Submit" />
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
