import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { Container, Form, Label, FormGroup, Input } from 'reactstrap';
import { InputField, Button } from 'src/components';
import { useFormik } from 'formik';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { putUrlString } from 'src/helpers';
import { useGetNoticeTemplateListsQuery } from 'src/rtk/features/setup/noticeTemplateList/setupNoticeTemplateListApi';
import { useGetNoticeTemplateDetailsQuery, useUpdateNoticeTemplateDetailsMutation } from 'src/rtk/features/setup/noticeTemplateDetails/setupNoticeTemplateDetailsApi';

const initialValues: NoticeTemplateDetails = {
    id: '',
    notice_list_id: '',
    details: '',
    status: true,
    creator_organization_id: '',
    updater_organization_id: '',
    created_by: '',
    updated_by: '',
    deleted_by: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
};

const EditNoticeTemplateDetails = () => {
    const { id } = useParams<{ id: string }>();
    const { data: noticeTemplateDetails, isError: isNoticeTemplateDetailsFetchError } =
        useGetNoticeTemplateDetailsQuery(id);
    const [updateNoticeTemplateDetails, { isSuccess, isError, error }] =
        useUpdateNoticeTemplateDetailsMutation();

    const { data } = useGetNoticeTemplateListsQuery();
    const noticeTemplateLists = data?.data || [];

    const err: CustomError = error as CustomError;
    const history = useHistory();
  const {
    handleSubmit,
    getFieldProps,
    values,
    touched,
    errors,
    setValues,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({      
        notice_list_id: Yup.string().required(
          'Notice template details must be under a notice list',
        ),
    }),

    onSubmit: (values) => {
      const url = putUrlString(id, values);
      updateNoticeTemplateDetails(url);
    },
  });

  useEffect(() => {
    if (noticeTemplateDetails && !isNoticeTemplateDetailsFetchError) {
      const data: NoticeTemplateDetails = (noticeTemplateDetails as any).data;      
      setValues({
        id: data.id,
        notice_list_id: data.notice_list_id,
        details: data.details,
        status: data.status,
        creator_organization_id: data.creator_organization_id,
        updater_organization_id: data.updater_organization_id,
        created_by: data.created_by,
        updated_by: data.updated_by,
        deleted_by: data.deleted_by,
        created_at: data.created_at,
        updated_at: data.updated_at,
        deleted_at: data.deleted_at,
      });
    }
  }, [noticeTemplateDetails, isNoticeTemplateDetailsFetchError]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Notice template details updated successfully');
      history.push('/setup/notice-template-details');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      const errors: any = {};

      if (err?.data) {
        Object.keys(err?.data).forEach((key) => {
          errors[key] = err?.data[key][0];
        });
      }
      setErrors(errors);
    }
  }, [isSuccess, isError]);

  let op: SelectOption[] = [{label: 'Select a Notice List', value: ''}];
  op = [...op, ...noticeTemplateLists?.map((n: NoticeTemplateList) => ({label: n.notice_subject!, value: n.id!}))]

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Notice Template Details Setup" breadcrumbItem="Notice Template Details" />
        <div className="row align-items-center justify-content-center">         
            <div className="card mt-3">
              <h5 className="card-header">
                <i className="fa fa-edit me-2" />
                Edit Notice Template Details
              </h5>
              <div className="card-body">
                <Form
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <div className="row">
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                      <FormGroup className="mb-3">
                        <Label for="notice_list_id">Notice List</Label>
                        <InputField
                          type="select"
                          placeholder="Notice List"
                          options={op}
                          invalid={{ errors, touched }}
                          {...getFieldProps('notice_list_id')}
                        />
                      </FormGroup>
                    </div>                

                    <div className='col-lg-4 col-md-6 col-sm-12'>
                      <FormGroup className="mb-3">
                        <Label for="details">Details</Label>
                        <InputField
                          type="textarea"
                          placeholder="Details"
                          invalid={{ errors, touched }}
                          {...getFieldProps('details')}
                        />
                      </FormGroup>
                    </div>                   

                    <div className='col-lg-4 col-md-6 col-sm-12'>
                      <FormGroup check className='mb-3 mt-4'>
                        <Label check>
                            <Input type="checkbox" {...getFieldProps('status')} checked={values.status} />{' '}
                            Status
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                  <Button type="submit" text="Submit" />
                </Form>
              </div>
            </div>
        </div>
      </Container>
    </div>
  );
};

export default EditNoticeTemplateDetails;

function useGetNoticeTemplateQuery(id: string): { data: any; isError: any; } {
  throw new Error('Function not implemented.');
}

