//import Breadcrumbs
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Button, InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import { useCreateFacultyInfoMutation } from 'src/rtk/features/setup/facultyInfo/facultyInfoApi';
import * as Yup from 'yup';

const initialValues: FacultyInfo = {
  id: '',
  code: '',
  faculty_name: '',
  description: '',
  status: true,
  creator_organization_id: '',
  updater_organization_id: '',
  created_by: '',
  updated_by: '',
  deleted_by: '',
  created_at: '',
  updated_at: '',
  deleted_at: '',
};

interface Props {
  isOpen: boolean;
  toggle: any;
}

const AddFacultyInfo = (props: Props) => {
  const { isOpen, toggle } = props;

  const [createFacultyInfo, { isSuccess, isError, error }] =
    useCreateFacultyInfoMutation();
  const err: CustomError = error as CustomError;
  const history = useHistory();

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setErrors,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      faculty_name: Yup.string().required('Please Enter Faculty Name'),
      code: Yup.string().required('Please Enter Faculty Code'),
    }),
    onSubmit: (values) => {
      createFacultyInfo(values);
      console.log({ values });
    },
  });

  useEffect(() => {
    if (isSuccess) {
      toggle();
      toast.success('Faculty created successfully');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} size="lg" backdrop={'static'}>
        <ModalHeader toggle={toggle}>Add Faculty</ModalHeader>
        <ModalBody>
          <Form
            className="custom-form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <Label className="form-label required-field">Faculty Code</Label>
                  <InputField
                    name="code"
                    type="text"
                    placeholder="Enter Code"
                    value={values.code}
                    onChange={handleChange}
                    invalid={{ errors, touched }}
                  />
                </div>
              </div>

              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <Label className="form-label required-field">Faculty Name</Label>
                  <InputField
                    name="faculty_name"
                    type="text"
                    placeholder="Enter Name"
                    value={values.faculty_name}
                    onChange={handleChange}
                    invalid={{ errors, touched }}
                  />
                </div>
              </div>

              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <Label className="form-label">Description</Label>
                  <InputField
                    name="description"
                    type="text"
                    placeholder="Enter Description"
                    value={values.description}
                    onChange={handleChange}
                    invalid={{ errors, touched }}
                  />
                </div>
              </div>

              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup check className="mb-3 mt-4">
                    <Label check>
                      <Input
                        type="checkbox"
                        name="status"
                        checked={values.status}
                        onChange={handleChange}
                      />{' '}
                      Active
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </div>

            <Button type="submit" text="Submit" />
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddFacultyInfo;
