import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { setFromErrors } from 'src/helpers';
import { useCreateApprovalMutation } from 'src/rtk/features/setup/approval/approvalApi';
import * as Yup from 'yup';

const initialValues: Approval = {
  table_name: '',
  module_name: '',
  page_name: '',
  total_step: 0,
  minimum_step: 0,
  status: false,
};

export const AddApproval = () => {
  const history = useHistory();
  const [createApproval, { isSuccess, isError, error }] =
    useCreateApprovalMutation();

  const err: CustomError = error as CustomError;
  const { handleSubmit, getFieldProps, touched, errors, resetForm, setErrors } =
    useFormik({
      initialValues,
      validationSchema: Yup.object({
        table_name: Yup.string().required('Table Name is required'),
        module_name: Yup.string().required('Module Name is required'),
        page_name: Yup.string().required('Page Name is required'),
        total_step: Yup.number(),
        minimum_step: Yup.number().max(
          Yup.ref('total_step'),
          'Total Step must be greater than Minimum Step',
        ),
        status: Yup.boolean(),
      }),

      onSubmit: (values) => {
        createApproval({
          ...values,
        });
        console.log(values);
      },
    });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Approval created successfully');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title="Add Approval"
          breadcrumbItem={[{ link: '/setup/approval/', name: 'Approval' }]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-8 col-sm-12 ">
            <div className="card mt-3">
              <h5 className="card-header">
                <i className="fa fa-plus me-2" />
                Add Approval
              </h5>
              <div className="card-body">
                <Form
                  autoComplete="off"
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <Row>
                    <FormGroup className="mb-3">
                      <Label for="table_name">Table Name</Label>
                      <InputField
                        type="text"
                        placeholder="Table Name"
                        invalid={{ errors, touched }}
                        {...getFieldProps('table_name')}
                      />
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <Label for="module_name">Module Name</Label>
                      <InputField
                        type="text"
                        placeholder="Module Name"
                        invalid={{ errors, touched }}
                        {...getFieldProps('module_name')}
                      />
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <Label for="page_name">Page Name</Label>
                      <InputField
                        type="text"
                        placeholder="Page Name"
                        invalid={{ errors, touched }}
                        {...getFieldProps('page_name')}
                      />
                    </FormGroup>

                    <FormGroup className="mb-3 col-6">
                      <Label for="total_step">Total Steps</Label>
                      <InputField
                        type="number"
                        placeholder="Total Steps"
                        invalid={{ errors, touched }}
                        {...getFieldProps('total_step')}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3 col-6">
                      <Label for="minimum_step">Minimum Steps</Label>
                      <InputField
                        type="number"
                        placeholder="Minimum Steps"
                        invalid={{ errors, touched }}
                        {...getFieldProps('minimum_step')}
                      />
                    </FormGroup>

                    <div className="col-md-6 col-sm-6 ">
                      <h6>Active Status</h6>
                      <FormGroup check className="mb-3">
                        <Input
                          type="checkbox"
                          role="check"
                          {...getFieldProps('status')}
                        />
                        <Label check>Active</Label>
                      </FormGroup>
                    </div>

                    <div className="text-center">
                      <Button
                        className="w-25"
                        outline
                        color="secondary"
                        type="button"
                        text="Cancel"
                        onClick={() => {
                          history.push('/setup/approval/');
                        }}
                      />
                      <Button
                        className="w-25 ms-3"
                        type="submit"
                        text="Submit"
                      />
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
