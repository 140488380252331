import { LogbookEntrySetupMasters } from "src/pages/Setup/LogbookEntrySetupMaster";
import { AddLogbookEntrySetupMaster } from "src/pages/Setup/LogbookEntrySetupMaster/AddLogbookEntrySetupMaster";
import { EditLogbookEntrySetupMaster } from "src/pages/Setup/LogbookEntrySetupMaster/EditLogbookEntrySetupMaster";

export const logBookEntrySetupMasterRoutes = [
  // logbook entry setup master
  {
    path: '/setup/logbook-entry-setup-master/',
    component: LogbookEntrySetupMasters,
  },
  {
    path: '/setup/logbook-entry-setup-master/update/:id',
    component: EditLogbookEntrySetupMaster,
  },
  {
    path: '/setup/logbook-entry-setup-master/add',
    component: AddLogbookEntrySetupMaster,
  },
];
