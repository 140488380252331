import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';
import { Col, Form, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { setFromErrors } from 'src/helpers';
import {
  useCreateTrainingWorkshopMutation,
  useLazyGetTrainingNameListQuery,
} from 'src/rtk/features/trainingWorkshop/trainingWorkshopApi';
import * as Yup from 'yup';
import NoticeList from './NoticeSelectionList';

const initialValues: TrainingWorkshopCreate = {
  workshop_create_date: '',
  notice_date: '',
  notice_ref_no: '',
  training_type: '',
  exam_training_id: '',
  training_name: '',
  training_for: '',
  duration: '',
  fees: '',
  application_deadline: '',
  submit_status: 'Submit',
  training_mode: 'Online',
  no_of_participants: '',
  application_start_date: '',
};

const AddTrainingWorkshop = () => {
  const [
    createTrainingWorkshop,
    {
      data: trainingWorkshopCreate,
      isError: isTrainingWorkshopCreateError,
      isSuccess: isTrainingWorkshopCreateSuccess,
      error: trainingWorkshopCreateError,
    },
  ] = useCreateTrainingWorkshopMutation();

  const history = useHistory();

  // const {
  //   data: subjects,
  //   isError: isSubjectsError,
  //   error: subjectsError,
  // } = useGetSubjectInfosQuery();
  // const [
  //   getMainTraining,
  //   {
  //     data: mainTraining,
  //     isError: isMainTrainingError,
  //     error: mainTrainingError,
  //     isSuccess: isMainTrainingSuccess,
  //   },
  // ] = useGetFcpsCourseListsForTrainingWorkshopMutation();

  const [
    getTrainingNames,
    {
      data: trainingNames,
      isError: isTrainingNamesError,
      error: trainingNamesError,
      isSuccess: isTrainingNamesSuccess,
    },
  ] = useLazyGetTrainingNameListQuery();

  // const [subjectId, setSubjectId] = useState<string>('');
  // const [subjectOptions, setSubjectOptions] = useState<SelectOption[]>([]);
  // const [mainTrainingData, setMainTrainingData] = useState<FcpsCourseList[]>(
  //   [],
  // );
  // const [mainTrainingOptions, setMainTrainingOptions] = useState<
  //   SelectOption[]
  // >([]);
  // const [mainTrainingId, setMainTrainingId] = useState<string>('');
  const [trainingNameOptions, setTrainingNameOptions] = useState<
    SelectOption[]
  >([]);
  const [examTrainingId, setExamTrainingId] = useState<string>('');
  const [onlineRadio, setOnlineRadio] = useState<boolean>(true);
  const [offlineRadio, setOfflineRadio] = useState<boolean>(false);
  const [trainingName, setTrainingName] = useState<string>('');
  const [trainingType, setTrainingType] = useState<string>('');
  const [duration, setDuration] = useState<string>('');
  const [trainingFees, setTrainingFees] = useState<string>('');
  const [btnClicked, setBtnClicked] = useState<string>('');
  const [noticeModalShow, setNoticeModalShow] = useState<boolean>(false);
  const [continueState, setContinueState] = useState<boolean>(false);
  const [applicableForTrainee, setApplicableForTrainee] =
    useState<boolean>(false);
  const [selectedNotice, setSelectedNotice] = useState<NoticeList>(
    {} as NoticeList,
  );

  const err: CustomError = trainingWorkshopCreateError as CustomError;

  // const noticeReferenceOptions: SelectOption[] =
  //   useGetNoticeTemplateListQuery()
  //     .data?.data?.map((item) => ({
  //       value: item?.id!,
  //       label: item?.template_name!,
  //     }))
  //     .sort((a, b) => {
  //       return a?.label?.localeCompare(b?.label);
  //     }) || [];

  const {
    handleSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    resetForm,
    setFieldValue,
    setValues,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({      
      notice_date: Yup.date()
        .min(
          new Date(Date.now() - 86400000),
          "Notice date can't be a past date",
        )
        .required('Notice Date is Required!'),
      training_type: Yup.string().required('Select Training Type'),
      training_name: Yup.string().required('Select Training Name'),
      training_for: Yup.mixed().required('Please Select User'),
      application_start_date: Yup.date()
        .min(
          new Date(Date.now() - 86400000),
          "Application Start date can't be a past date",
        )
        .required('Application Start Date is Required'),
      application_deadline: Yup.date().when(
        'application_start_date',
        (application_start_date, schema) => {
          if (application_start_date) {
            const startDate = new Date(
              application_start_date.getTime() + 86400000,
            );
            return (
              schema
                .required('Application Deadline is Required')
                .min(startDate, 'Application Deadline must be after Start date')
            );
          }
          return schema;
        },
      ),
    }),
    onSubmit: (values) => {
      if (btnClicked === 'submit') {
        values.submit_status = 'Submit';
      } else if (btnClicked === 'save_as_draft') {
        values.submit_status = 'Draft';
      }

      values.exam_training_id = examTrainingId;
      values.training_name = trainingName;
      values['training_for'] = values['training_for'].toString();
      values.duration = duration;
      values.fees = trainingFees;
      values.workshop_create_date = new Date(Date.now())
        .toISOString()
        .slice(0, 10);
      values.notice_ref_no = selectedNotice?.id;

      createTrainingWorkshop({
        ...values,
      });

      console.log(values);
    },
  });

  useEffect(() => {
    if (isTrainingWorkshopCreateSuccess) {
      // setMainTrainingId('');
      setTrainingFees('');
      setOnlineRadio(true);
      setOfflineRadio(false);
      setSelectedNotice({} as NoticeList);
      setContinueState(false);
      setApplicableForTrainee(false);
      resetForm();
      toast.success('Training / Workshop created successfully');
      history.push('/training-workshop');
    }
    if (isTrainingWorkshopCreateError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isTrainingWorkshopCreateSuccess, isTrainingWorkshopCreateError]);

  // useEffect(() => {
  //   if (subjects && !isSubjectsError) {
  //     const subjectInfos: SubjectInfo[] = subjects?.data!;

  //     if (subjectInfos.length) {
  //       const options: SelectOption[] =
  //         subjectInfos
  //           ?.map((item) => ({
  //             value: item.id!,
  //             label: item.subject_name!,
  //           }))
  //           .sort((a, b) => {
  //             return a.label.localeCompare(b.label);
  //           }) || [];

  //       setSubjectOptions(options);
  //     }
  //   }
  // }, [subjects, isSubjectsError]);

  // useEffect(() => {
  //   if (subjectId !== '') {
  //     getMainTraining({ courseType: 'main-training', subject_id: subjectId });
  //   }
  // }, [subjectId]);

  // useEffect(() => {
  //   if (mainTraining && !isMainTrainingError) {
  //     const mainTrainingList: FcpsCourseList[] = mainTraining.data;
  //     setMainTrainingData(mainTrainingList);
  //   }
  // }, [mainTraining, isMainTrainingError]);

  // useEffect(() => {
  //   if (mainTrainingData.length) {
  //     const options: SelectOption[] =
  //       mainTrainingData
  //         ?.map((item) => ({
  //           value: item.id!,
  //           label: item.name!,
  //         }))
  //         .sort((a, b) => {
  //           return a.label.localeCompare(b.label);
  //         }) || [];

  //     setMainTrainingOptions(options);
  //   }
  // }, [mainTrainingData]);

  useEffect(() => {
    getTrainingNames({
      workshop_training: 'Workshop/Training',
      page: 'all'
    });
  }, []);

  useEffect(() => {
    if (trainingNames?.data?.length && isTrainingNamesSuccess) {
      const options: SelectOption[] =
        trainingNames?.data
          ?.map((item) => ({
            value: item.id!,
            label: item.name + '-' + item.code!,
          }))
          .sort((a, b) => {
            return a.label.localeCompare(b.label);
          }) || [];

      setTrainingNameOptions(options);
    }
  }, [trainingNames, isTrainingNamesSuccess]);

  useEffect(() => {
    if (continueState) {
      setFieldValue(
        'application_start_date',
        new Date(Date.now()).toISOString().substring(0, 10),
      );
      const thousandsYearsFromNow = new Date(Date.now());
      thousandsYearsFromNow.setFullYear(
        thousandsYearsFromNow.getFullYear() + 2000,
      );
      setFieldValue(
        'application_deadline',
        thousandsYearsFromNow.toISOString().substring(0, 10),
      );
    } else {
      setFieldValue('application_start_date', '');
      setFieldValue('application_deadline', '');
    }
  }, [continueState]);

  useEffect(() => {
    if (values.training_type === 'Mandatory') {
      setApplicableForTrainee(true);
      setFieldValue('training_for', []);
    } else if (values.training_type === 'Optional') {
      setApplicableForTrainee(false);
      setFieldValue('training_for', []);
    }
  }, [values.training_type]);

  useEffect(() => {
    values.no_of_participants = values.no_of_participants?.toString();
  }, [values.no_of_participants]);

  // const handleSubjectSelection = (e: any) => {
  //   const { options, selectedIndex, value } = e.target;
  //   setSubjectId(value);
  // };

  // const handleMainTrainingSelection = (e: any) => {
  //   const { options, selectedIndex, value } = e.target;
  //   setMainTrainingId(value);
  // };

  const handleTrainingName = (e: any) => {
    const { options, selectedIndex, value } = e.target;
    setExamTrainingId(value);
    setTrainingName(options[selectedIndex].innerHTML);
    const training: ExamInfo = trainingNames?.data?.find(
      (t) => t.id === value,
    )!;
    setTrainingFees(training?.exam_tranning_fee?.exam_fee);
    setDuration(training?.duration?.toString() + ' ' + training?.duration_unit);
    setTrainingType(training?.type!);
  };

  const handleNoticeSelection = (e: any) => {
    setNoticeModalShow(true);
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <BetterBreadcrumb title={'Create Training / Workshop'} />
        <div className="row align-items-center justify-content-center">
          <div className="col-sm-12">
            <div className="card">
              <div className="d-flex card-header">
                <h3 className="card-title mb-0 flex-grow-1">
                  <i className="fa fa-plus me-2" />
                  Add
                </h3>
                <div className="flex-shrink-0 align-self-end">
                  <Link
                    to="/training-workshop"
                    className="btn btn-success waves-effect waves-light"
                  >
                    <i className="fas fa-arrow-left me-2" /> Back
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <Form
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  onReset={(e) => {
                    // setMainTrainingId('');
                    // e.preventDefault();
                    setTrainingName('');
                    setTrainingFees('');
                    setOnlineRadio(true);
                    setOfflineRadio(false);
                    setDuration('');
                    setSelectedNotice({} as NoticeList);
                    setTrainingFees('');
                    setContinueState(false);
                    setApplicableForTrainee(false);
                    resetForm();
                  }}
                >
                  <Row className="justify-content-evenly">
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">{trainingType ? trainingType : 'Training'} Mode</Label>
                        <br />
                        <FormGroup check inline>
                          <Input
                            id="trainingMode1"
                            name="training_mode"
                            type="radio"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setOnlineRadio(true);
                                setOfflineRadio(false);
                              }

                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            value={'Online'}
                            checked={onlineRadio}
                          />

                          <Label check for="trainingMode1">
                            Online
                          </Label>
                        </FormGroup>

                        <FormGroup check inline>
                          <Input
                            id="trainingMode2"
                            name="training_mode"
                            type="radio"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setOnlineRadio(false);
                                setOfflineRadio(true);
                              }

                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            value={'Offline'}
                            checked={offlineRadio}
                          />

                          <Label check for="trainingMode2">
                            Offline
                          </Label>
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col lg={4} md={6} sm={12}>
                            <FormGroup className="mb-3">
                              <Label className="form-label">Subject</Label>
                              <InputField
                                type="select"
                                name="subject"
                                id="subject"                            
                                options={subjectOptions}                                 
                                onChange={(e) => {
                                  handleSubjectSelection(e);
                                }}                                                   
                              />                       
                            </FormGroup>
                          </Col> */}

                    {/* <Col lg={4} md={6} sm={12}>
                            <FormGroup className="mb-3">
                              <Label className="form-label">Main Training</Label>
                              <InputField
                                type="select"
                                name="mainTraining"
                                id="mainTraining"                            
                                options={mainTrainingOptions}                                 
                                onChange={(e) => {
                                  handleMainTrainingSelection(e);
                                }}                                                   
                              />                       
                            </FormGroup>
                          </Col> */}

                    <Col lg={4} md={6} sm={12} style={{ marginTop: '14px' }}>
                      <FormGroup className="mb-3">
                        <Label className="form-label required-field">
                          {trainingType ? trainingType : 'Training'} Name
                        </Label>
                        <InputField
                          type="select"
                          name="training_name"
                          placeholder=""
                          options={trainingNameOptions}
                          onChange={(e) => {
                            handleChange(e);
                            handleTrainingName(e);
                          }}
                          invalid={{ errors, touched }}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={6} style={{ marginTop: '14px' }}>
                      <FormGroup className="mb-3">
                        <Label className="form-label required-field">
                          Notice Date
                        </Label>
                        <InputField
                          type="date"
                          invalid={{ errors, touched }}
                          {...getFieldProps('notice_date')}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                      <FormGroup className="mb-3">
                        <Label className="form-label required-field">
                          Notice Title
                        </Label>
                        <Button
                          type="button"
                          text="Select Notice"
                          className="btn btn-secondary mb-1 ms-2"
                          onClick={(e) => {
                            handleNoticeSelection(e);
                          }}
                        ></Button>
                        <InputField
                          type="text"
                          placeholder="Select Notice"
                          name="notice_ref_no"
                          value={selectedNotice?.notice_subject ?? ''}
                          invalid={{ errors, touched }}
                          readOnly
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={6} sm={12}>
                      <FormGroup className="mb-3">
                        <Label className="form-label required-field">
                          {trainingType ? trainingType : 'Training'} Type
                        </Label>
                        <InputField
                          type="select"
                          placeholder=""
                          options={[
                            { label: 'Mandatory', value: 'Mandatory' },
                            { label: 'Optional', value: 'Optional' },
                          ]}
                          invalid={{ errors, touched }}
                          {...getFieldProps('training_type')}
                        />
                      </FormGroup>
                    </Col>

                    {!applicableForTrainee ? (
                      <Col lg={5} md={6} sm={12} className='mt-3'>
                        <h6 className="required-field">Applicable For</h6>
                        <FormGroup check inline>
                          <Input
                            type="checkbox"
                            value={'Trainee'}
                            checked={values['training_for'].includes('Trainee')}
                            id={'trainingForTrainee'}
                            name="training_for"
                            onChange={handleChange}
                          />
                          <Label check htmlFor={'trainingForTrainee'}>
                            Trainee
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            type="checkbox"
                            value={'Supervisor'}
                            checked={values['training_for'].includes(
                              'Supervisor',
                            )}
                            name="training_for"
                            onChange={handleChange}
                            id={'trainingForSupervisor'}
                          />
                          <Label check htmlFor={'trainingForSupervisor'}>
                            Supervisor
                          </Label>
                        </FormGroup>

                        <FormGroup check inline>
                          <Input
                            type="checkbox"
                            value={'Fellow'}
                            name="training_for"
                            checked={values['training_for'].includes('Fellow')}
                            onChange={handleChange}
                            id={'trainingForFellow'}
                          />
                          <Label check htmlFor={'trainingForFellow'}>
                            Fellow
                          </Label>
                        </FormGroup>

                        <FormGroup check inline>
                          <Input
                            type="checkbox"
                            value={'Other'}
                            name="training_for"
                            checked={values['training_for'].includes('Other')}
                            onChange={handleChange}
                            id={'trainingForOther'}
                          />
                          <Label check htmlFor={'trainingForOther'}>
                            Other
                          </Label>
                        </FormGroup>
                        {errors.training_for && touched.training_for ? (
                          <div className="text-danger">
                            {errors.training_for}
                          </div>
                        ) : null}
                      </Col>
                    ) : (
                      <Col lg={5} md={6} sm={12} className='mt-3'>
                        <h6 className="required-field">Applicable For</h6>
                        <FormGroup check inline>
                          <Input
                            type="checkbox"
                            value={'Trainee'}
                            checked={values['training_for'].includes('Trainee')}
                            id={'trainingForTrainee'}
                            name="training_for"
                            onChange={handleChange}
                          />
                          <Label check htmlFor={'trainingForTrainee'}>
                            Trainee
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            type="checkbox"
                            value={'Supervisor'}
                            checked={values['training_for'].includes(
                              'Supervisor',
                            )}
                            name="training_for"
                            onChange={handleChange}
                            id={'trainingForSupervisor'}
                            disabled
                          />
                          <Label check htmlFor={'trainingForSupervisor'}>
                            Supervisor
                          </Label>
                        </FormGroup>

                        <FormGroup check inline>
                          <Input
                            type="checkbox"
                            value={'Fellow'}
                            name="training_for"
                            checked={values['training_for'].includes('Fellow')}
                            onChange={handleChange}
                            id={'trainingForFellow'}
                            disabled
                          />
                          <Label check htmlFor={'trainingForFellow'}>
                            Fellow
                          </Label>
                        </FormGroup>

                        <FormGroup check inline>
                          <Input
                            type="checkbox"
                            value={'Other'}
                            name="training_for"
                            checked={values['training_for'].includes('Other')}
                            onChange={handleChange}
                            id={'trainingForOther'}
                            disabled
                          />
                          <Label check htmlFor={'trainingForOther'}>
                            Other
                          </Label>
                        </FormGroup>
                        {errors.training_for && touched.training_for ? (
                          <div className="text-danger">
                            {errors.training_for}
                          </div>
                        ) : null}
                      </Col>
                    )}

                    {/* <Col lg={4} md={6} sm={12}>
                      <FormGroup className="mb-3">
                        <Label className="form-label">Training For</Label>
                        <InputField
                          type="select"
                          placeholder=""
                          options={[
                            { label: 'Trainee', value: 'Trainee' },
                            { label: 'Supervisor', value: 'Supervisor' },
                          ]}
                          invalid={{ errors, touched }}
                          {...getFieldProps('training_for')}
                        />
                      </FormGroup>
                    </Col> */}

                    <Col lg={4} md={6} sm={12} className="me-0 pe-0">
                      <FormGroup className="mb-3">
                        <Label className="form-label">
                          No. of Participants (Per Batch)
                        </Label>
                        <InputField
                          type="number"
                          min={0}
                          invalid={{ errors, touched }}
                          {...getFieldProps('no_of_participants')}
                        />
                      </FormGroup>
                    </Col>

                    {duration ? (
                      <Col lg={4} md={6} sm={12}>
                        <FormGroup className="mb-3">
                          <Label className="form-label">Duration</Label>
                          <InputField
                            name="duration"
                            id="duration"
                            type="text"
                            value={duration}
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col lg={4} md={6} sm={12}>
                        <FormGroup className="mb-3">
                          <Label className="form-label">Duration</Label>
                          <InputField
                            name="duration"
                            id="duration"
                            type="text"
                            value={''}
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                    )}

                    {trainingFees ? (
                      <Col lg={4} md={6} className="me-0 pe-0">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Fees</Label>
                          <InputField
                            type="text"
                            id="trainingFee"
                            name="trainingFee"
                            value={trainingFees}
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col lg={4} md={6} className="me-0 pe-0">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Fees</Label>
                          <InputField
                            type="text"
                            id="trainingFee"
                            name="trainingFee"
                            value={''}
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                    )}



                    {!continueState ? (
                      <Col lg={4} md={6} className="me-0 pe-0">
                        <FormGroup className="mb-3">
                          <Label className="form-label required-field">
                            Application Start Date
                          </Label>
                          <InputField
                            type="date"
                            invalid={{ errors, touched }}
                            {...getFieldProps('application_start_date')}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col lg={4} md={6} className="me-0 pe-0">
                        <FormGroup className="mb-3">
                          <Label className="form-label required-field">
                            Application Start Date
                          </Label>
                          <InputField
                            type="date"
                            readOnly={true}
                            invalid={{ errors, touched }}
                            {...getFieldProps('application_start_date')}
                          />
                        </FormGroup>
                      </Col>
                    )}

                    {!continueState ? (
                      <Col lg={4} md={6} className="me-0 pe-0">
                        <FormGroup className="mb-3">
                          <Label className="form-label required-field">
                            Application Deadline
                          </Label>
                          <InputField
                            type="date"
                            invalid={{ errors, touched }}
                            {...getFieldProps('application_deadline')}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col lg={4} md={6} className="me-0 pe-0">
                        <FormGroup className="mb-3">
                          <Label className="form-label required-field">
                            Application Deadline
                          </Label>
                          <InputField
                            type="date"
                            name="invalid_deadline"
                            id="invalid_deadline"
                            value={''}
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                    )}
                    <Col lg={4} md={6} sm={12}>
                      <div className="ms-3 mt-4 mb-3">
                        <FormGroup switch className="mb-3">
                          <Input
                            type="switch"
                            role="switch"
                            checked={continueState}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setContinueState(true);
                              } else {
                                setContinueState(false);
                              }
                            }}
                          />
                          <Label>Continue</Label>
                        </FormGroup>
                      </div>
                    </Col>
                    {/* <Col sm={12}>
                            <FormGroup check className='mb-3 mt-4'>
                              <Input
                                type="checkbox"
                                role="check"
                                checked={values.status}
                                {...getFieldProps('required_ind')}
                              />
                              <Label check>Required</Label>
                            </FormGroup>
                          </Col>                                                                                                                                   */}

                    <Col sm={12}>
                      <FormGroup className="mb-3 text-center">
                        <Button
                          type="reset"
                          text="Reset"
                          className="btn btn-secondary me-2"
                        ></Button>
                        <Button
                          type="submit"
                          text="Save as Draft"
                          className="btn btn-info me-2"
                          onClick={(e) => {
                            setBtnClicked('save_as_draft');
                          }}
                        ></Button>
                        <Button
                          type="submit"
                          text="Save"
                          className="btn btn-success"
                          onClick={(e) => {
                            setBtnClicked('submit');
                          }}
                        ></Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>

              <Modal
                onOpened={function () {
                  toast.dismiss();
                }}
                isOpen={noticeModalShow}
                scrollable={true}
                // style={{ maxWidth: '1600px', width: '95%' }}
                // size="xl"
                fullscreen={true}
                toggle={() => {
                  document.body.classList.add('no_padding');
                }}
                backdrop={'static'}
                modalTransition={{ timeout: 0 }}
                backdropTransition={{ timeout: 0 }}
              >
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Select Notice
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      setNoticeModalShow(!noticeModalShow);
                    }}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col>
                      <NoticeList
                        selectedNotice={selectedNotice}
                        setSelectedNotice={setSelectedNotice}
                        setModalShow={setNoticeModalShow}
                      />
                    </Col>
                  </Row>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTrainingWorkshop;
