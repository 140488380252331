import { useEffect, useState } from 'react';
import { ConfirmToast } from 'react-confirm-toast';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { Button } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import {
  useDeleteByIdMutation,
  useGetDataQuery,
} from 'src/rtk/features/common/crudApi';

const DynamicPage = (props: any) => {
  const { formObj } = props;
  const history = useHistory();

  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');
  const generateQueryUrl = () => {
    return `${formObj.listAPIUrl}?page=${page}&paginate_per_page=${limit}${search}`;
  };

  const {
    data: dataList,
    isLoading: dataListLoading,
    isSuccess: dataListLoadingSuccess,
    error: dataListLoadingError,
  } = useGetDataQuery(generateQueryUrl());
  const data: any = dataList?.data || [];

  useEffect(() => {
    if (dataListLoadingError) {
      toast.dismiss();
      toast.error('Data Loading error. Please try again later.');
    } else if (dataListLoading || !dataListLoadingSuccess) {
      toast.dismiss();
      // toast.loading('Data Loading...');
    } else {
      toast.dismiss();
      // toast.success('Data Load Successfully');
    }
  }, [dataListLoading, dataListLoadingSuccess, dataListLoadingError]);

  const [deleteByID, { isSuccess, isLoading, isError, error }] =
    useDeleteByIdMutation();
  const err: CustomError = error as CustomError;

  useEffect(() => {
    if (isSuccess) {
      toast.success(formObj.title + ' delete successfully');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
    }
  }, [isSuccess, isError]);

  let columns: TableColumn<any>[] = [
    {
      name: '#',
      width: '50px',
      cell: (row, index: any) => (data.from ? data.from + index : index + 1),
    },
    ...formObj.columns,
    {
      name: 'Action',
      width: '230px',
      selector: (row: any) => row.id,
      cell: (row: any, index: any) => (
        <>
          <Button
            size="sm"
            className="rounded-0"
            text={`<span class="fas fa-edit" ></span> ${formObj.updateBtnTitle}`}
            color="info"
            onClick={() => history.push(`${formObj.updateUrl}${row.id}`)}
          />

          <ConfirmToast
            asModal={true}
            childrenClassName="margin-top-10"
            customCancel="No"
            customConfirm="Yes"
            customFunction={() =>
              deleteByID(`${formObj.deleteAPIUrl}${row.id}`)
            }
            message={formObj.deletePromtMessage}
            position="top-right" //will be ignored cause asModal=true
            showCloseIcon={true}
            theme="lilac"
          >
            <Button
              size="sm"
              className="rounded-0"
              text={`<span class="fas fa-trash" ></span> Delete`}
              color="danger"
            />
          </ConfirmToast>
        </>
      ),
    },
  ];

  return (
    <div className="page-content">
      <Container fluid>
        {formObj.breadcrumbItemForList && (
          <BetterBreadcrumb
            title={formObj?.title + ' List'}
            breadcrumbItem={formObj.breadcrumbItemForList}
          />
        )}
        <Card>
          <CardHeader className="d-flex justify-content-end">
            {formObj.addUrl && (
              <Link
                to={formObj.addUrl}
                className="btn btn-success waves-effect waves-light"
              >
                <i className="fa fa-plus me-2" />{' '}
                {formObj.createNewBtnTitle || 'Create New'}
              </Link>
            )}
          </CardHeader>
          <CardBody>
            {data.total !== undefined && columns.length > 0 && (
              <DataTable
                columns={columns}
                data={data.data ? data.data : []}
                pagination
                paginationServer
                onChangePage={(page: number) => {
                  setPage(page);
                }}
                onChangeRowsPerPage={(newPerPage: number, page: number) => {
                  setLimit(newPerPage);
                  setPage(page);
                }}
                progressPending={dataListLoading}
                paginationTotalRows={data.total}
                expandOnRowClicked
                highlightOnHover
                paginationRowsPerPageOptions={
                  config.paginationRowsPerPageOptions
                }
              />
            )}

            {data.total === undefined && columns.length > 0 && (
              <DataTable
                columns={columns}
                data={data}
                pagination
                progressPending={dataListLoading}
                paginationTotalRows={data.total}
                highlightOnHover
                paginationRowsPerPageOptions={
                  config.paginationRowsPerPageOptions
                }
              />
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default DynamicPage;
