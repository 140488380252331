import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Table,
} from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import LogBookEntryMasterTableRow from 'src/pages/Setup/LogBookEntryMaster/LogBookEntryMasterTableRow';
import { useGetLogBookEntryMastersQuery } from 'src/rtk/features/setup/logBookEntryMaster/logBookEntryMasterApi';
export const LogBookEntryMaster = () => {
  const { data, error, isLoading, isError } = useGetLogBookEntryMastersQuery();

  const err: CustomError = error as CustomError;
  const LogBookEntryMasters = data?.data || [];

  let content = null;
  if (isLoading) {
    content = <div className="mt-3">Loading...</div>;
  }
  if (!isLoading && isError && error) {
    content = <div className="mt-3">{err.message}</div>;
  }
  if (!isLoading && !isError && LogBookEntryMasters?.length > 0) {
    content = LogBookEntryMasters.map(
      (logBookMaster: LogBookEntryMasters, index) => (
        <LogBookEntryMasterTableRow
          logBookEntryMaster={logBookMaster}
          index={index}
          key={logBookMaster.id}
        />
      ),
    );
  } else {
    content = (
      <tr>
        <td colSpan={11}>
          <div className="text-center text-danger h6">No data available!</div>
        </td>
      </tr>
    );
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb title="Log Book Entry Master" />
        <Card>
          <CardHeader className="d-flex justify-content-between text-center align-items-center ">
            <div className="fw-bold fs-5 d-flex flex-shrink-1">List View</div>
            <div className="d-flex flex-shrink-0">
              <Link to={'/log/book/entry/master/Add'}>
                <Button color="success" size="sm" outline className="rounded-5">
                  <i className="bx bx-plus"></i> Add Log Book Entry Master
                </Button>
              </Link>
            </div>
          </CardHeader>
          <CardBody>
            <div className="table-responsive text-nowrap text-center  x-overflow-scroll">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Sl.</th>
                    <th>Exam Name</th>
                    <th>Subject Name</th>
                    <th>Signature of Trainee</th>
                    <th>Signature of Director</th>
                    <th>Approval Status</th>
                    <th>Serial No</th>
                    <th>Completion Status</th>
                    <th>Date of Enrollment With RTMD</th>
                    <th>Date of Enrollment With SST</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
