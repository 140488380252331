import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router';
import { Button, Card, CardBody, Table } from 'reactstrap';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import { useGetPersonalProfileQuery } from 'src/rtk/features/trainee/traineeApi';
import { useDeleteWorkExperienceMutation } from 'src/rtk/features/workExperience/workExperienceApi';
import AddExperienceProfile from './AddExperienceProfile';
import EditExperienceProfile from './EditExperienceProfile';
import { ConfirmToast } from 'react-confirm-toast';
interface ExperienceProfileProps {
  nextDisabled?: any;
}
const ExperienceProfile = (props: ExperienceProfileProps) => {
  const [editInfoID, setEditInfoID] = useState('');
  const [isAddForm, setIsAddForm] = useState(true);    

  const [infoId, setInfoId] = useState('');

  const [modalShow, setModalShow] = useState(false);

  const { data: workExperience } = useGetPersonalProfileQuery();

  const workExperienceData = workExperience?.data?.details?.employment_infos;

  useEffect(()=>{
    workExperienceData?.length === 0 ? props.nextDisabled(true) : props.nextDisabled(false);
  },[workExperienceData]);

  const [
    deleteWorkExperience,
    { isSuccess: isDeleteSuccess, isError: isDeleteError },
  ] = useDeleteWorkExperienceMutation();

  const deleteHandler = (modalState: boolean, id: string) => {
    setModalShow(modalState);
    setInfoId(id);
    // const confirm = window.confirm('Are you sure you want to delete?');
    // if (confirm) deleteWorkExperience(id);
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      toast.success('Experience Info Deleted Successfully!');
      setModalShow(false);
    }
    if (isDeleteError) {
      toast.error('Error Deleting Experience Info!');
    }
  }, [isDeleteSuccess, isDeleteError]);

  const handleUpdate = (exprienceID: string) => {
    setEditInfoID(exprienceID);
    setIsAddForm(false);
  };

  let serial = 0;

  return (
    <div className="mb-4">
      <h5 className='block-heading'>Work Experience</h5>
      {isAddForm ? (
        <AddExperienceProfile  nextDisabled={props.nextDisabled} length={workExperienceData?.length}/>
      ) : (
        <EditExperienceProfile
          editInfoID={editInfoID}
          setIsAddForm={setIsAddForm}
        />
      )}

      {workExperienceData && workExperienceData?.length > 0 && (
        <Card className="mt-4">
          <CardBody>
            <div className="table-responsive">
              <Table className="table table-striped table-sm table-hover mb-0">
                <thead className='table-secondary'>
                  <tr>
                    <th>SL. No.</th>
                    <th>Job Title</th>
                    <th>Institute</th>
                    <th>Institute Type</th>
                    <th>Department</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {workExperienceData?.map((elmt) => (
                    <tr key={elmt.id}>
                      <td>{++serial}</td>
                      <td>{elmt.designation}</td>
                      <td>{elmt.institute_name}</td>
                      <td>{elmt.employment_type}</td>
                      <td>{elmt.department_name}</td>
                      <td>{elmt.from_date}</td>
                      <td>
                        {elmt.to_date ? (
                          elmt.to_date
                        ) : (
                          <p className="text-info">Running</p>
                        )}
                      </td>
                      <td>
                        <div className='d-flex gap-1'>
                          <Button
                            className="btn btn-success btn-sm"
                            onClick={() => handleUpdate(elmt.id!)}
                          >
                            Edit
                          </Button>
                          <ConfirmToast
                            asModal={true}
                            childrenClassName="margin-top-10"
                            customCancel="No"
                            customConfirm="Yes"
                            customFunction={() =>
                              deleteWorkExperience(elmt?.id!)
                            }
                            message={'Do You Want to Delete?'}
                            position="top-right"
                            showCloseIcon={true}
                            theme="lilac"
                          >                            
                            <Button
                              className="btn btn-danger btn-sm"
                              // onClick={() => deleteHandler(true, elmt.id!)}
                            >
                              Delete
                            </Button>
                          </ConfirmToast>
                        </div>
                      </td>
                      {/* {modalShow && infoId === elmt?.id ? (
                          <DeleteInformationModal
                            isOpen={modalShow}
                            toggle={() => setModalShow(!modalShow)}
                            id={infoId}
                            deleteInfomation={deleteWorkExperience}
                          />
                        ) : null} */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default ExperienceProfile;
