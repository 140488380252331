import { apiSlice } from 'src/rtk/api/apiSlice';
import * as traineeAcademicActivitiesUrl from 'src/helpers/url_helper';

export const traineeAcademicActivitiesApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['logBookEntrySetupDetails', 'logBookEntrySetupMaster', 'logbookEntries'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLogbookEntrySetupDetails: builder.query<APIResponse<LogbookEntryDetails[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${traineeAcademicActivitiesUrl.GET_LOGBOOK_ENTRY_SETUP_DETAILS}`,
        }),

        providesTags: ['logBookEntrySetupDetails'],
      }),

      getLogbookEntrySetupDetail: builder.query<APIResponse<LogbookEntryDetails>, string>({
        query: (id) => ({
          url: `${traineeAcademicActivitiesUrl.GET_LOGBOOK_ENTRY_SETUP_DETAIL}/${id}`,
          method: 'GET',
        }),

        providesTags: ['logBookEntrySetupDetails'],
      }),

      getFormByLogbookType: builder.query<APIResponse<LogbookEntrySetupMaster[]>, number>({
        query: (id: number) => ({
          url: `${traineeAcademicActivitiesUrl.GET_FORM_BY_LOGBOOK_TYPE}/${id}`,
          method: 'GET',
        }),

        // providesTags: ['logBookEntrySetupMaster'],
      }),

     

      getDynamicFormPropertyByParticular: builder.query<APIResponse<LogbookEntryDetails[]>, string>({
        query: (id) => ({
          url: `${traineeAcademicActivitiesUrl.GET_DYNAMIC_FORM_PROPERTY_BY_PARTICULAR}/${id}`,
          method: 'GET',
        }),
        
        // providesTags: ['logBookEntrySetupDetails'],
      }),

      createLogbookEntrySetupDetail: builder.mutation<APIResponse<LogbookEntryDetails>, LogbookEntryDetails>({
        query: (data) => ({
          url: `${traineeAcademicActivitiesUrl.CREATE_LOGBOOK_ENTRY_SETUP_DETAIL}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['logBookEntrySetupDetails'],
      }),

      updateLogbookEntrySetupDetail: builder.mutation<APIResponse<LogbookEntryDetails>, string>({
        query: (url) => ({
          url: `${traineeAcademicActivitiesUrl.UPDATE_LOGBOOK_ENTRY_SETUP_DETAIL}/` + url,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['logBookEntrySetupDetails'],
      }),

      deleteLogbookEntrySetupDetail: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${traineeAcademicActivitiesUrl.DELETE_LOGBOOK_ENTRY_SETUP_DETAIL}/${id}`,
        }),
        invalidatesTags: ['logBookEntrySetupDetails'],
      }),

      createLogbookEntry: builder.mutation<
        APIResponse<any>,
        any
      >({
        query: (data: any) => ({
          url: `${traineeAcademicActivitiesUrl.CREATE_LOGBOOK_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['logbookEntries'],
      }),

       getTraineeLogList: builder.query<APIResponse<any>, any>({
        query: (querydata) => ({
          method: 'GET',
          url: `${traineeAcademicActivitiesUrl.GET_TRAINEE_LOG_LIST}/${querydata}`,
        }),
        providesTags: ['logbookEntries'],
      }),

    }),
  });

export const {
  useGetTraineeLogListQuery,
  useCreateLogbookEntrySetupDetailMutation,
  useGetLogbookEntrySetupDetailQuery,
  useGetLogbookEntrySetupDetailsQuery,
  useGetFormByLogbookTypeQuery,
  useLazyGetFormByLogbookTypeQuery,  
  useGetDynamicFormPropertyByParticularQuery,
  useLazyGetDynamicFormPropertyByParticularQuery,
  useUpdateLogbookEntrySetupDetailMutation,
  useDeleteLogbookEntrySetupDetailMutation,
  useCreateLogbookEntryMutation,
} = traineeAcademicActivitiesApi;
