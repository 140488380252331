import { apiSlice } from 'src/rtk/api/apiSlice';
import * as eLogBookUrl from 'src/helpers/url_helper';

interface Paginate {
  page: number | string;
  paginate_per_page?: number;
  search?: string;
}

export const eLogBookApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['ELogBookInfo'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getELogBooks: builder.query<APIResponse<eLogBook[]>, any>({
        query: (queryUrl) => ({
          method: 'GET',
          url: queryUrl,
        }),
        providesTags: ['ELogBookInfo'],
      }),

       updateELogBook: builder.mutation<APIResponse<eLogBookStatus>, eLogBookStatus>({
        query: (data) => ({
          url: `${eLogBookUrl.UPDATE_ELOGBOOK}/${data.id}/${data.status}/${data.message}`,
          method: 'GET',
          // body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['ELogBookInfo'],
      }),

      
    }),
  });

export const {
  useGetELogBooksQuery,
  useUpdateELogBookMutation,
} = eLogBookApi;
