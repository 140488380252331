import { useFormik } from "formik";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { Button, InputField } from "src/components";
import { setFromErrors } from "src/helpers";
import { useLibraryServiceEntryMutation } from "src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi";
import * as Yup from 'yup';

interface LibraryServiceProps {
    departmentId?: string;
    recordOfLibraryServiceToEdit?: LibraryServiceList;
    setModalShow?: any;
}

const initialValues: LibraryServiceEntry = {
    library_service_id: '',
    form_b_department_id: '',
    category: '',
    titles: '',
    total_books: 0,
};

const BooksJournalsPeriodicalsModal = (props: LibraryServiceProps) => {
    const { departmentId, recordOfLibraryServiceToEdit, setModalShow } = props;

    const [libraryServiceEntry, { data: libraryServiceEntryData, isSuccess: isLibraryServiceEntryDataSuccess, isLoading: isLibraryServiceEntryDataLoading, isError: isLibraryServiceEntryDataError, error: libraryServiceEntryDataError }] =
        useLibraryServiceEntryMutation();

    const err: CustomError = libraryServiceEntryDataError as CustomError;

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({}),

        onSubmit: (values: LibraryServiceEntry) => {
            console.log(values);
            values.form_b_department_id = departmentId;
            libraryServiceEntry(values);
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    const handleFormReset = () => {
        resetForm();
    };

    useEffect(() => {
        if (recordOfLibraryServiceToEdit?.id) {
            setValues({
                library_service_id: recordOfLibraryServiceToEdit?.id,
                form_b_department_id: recordOfLibraryServiceToEdit?.form_b_department_id,
                category: recordOfLibraryServiceToEdit?.category,
                titles: recordOfLibraryServiceToEdit?.titles,
                total_books: recordOfLibraryServiceToEdit?.total_books,
            });
        }
    }, []);

    useEffect(() => {
        if (isLibraryServiceEntryDataSuccess) {
            handleFormReset();
            toast.success('Books / journals / periodicals record saved successfully');
            setModalShow(false);
        }
        if (isLibraryServiceEntryDataError) {
            Object.entries(err?.data).forEach((entry: any) => {
                entry[1]?.forEach((message: any) => {
                    toast.error(message);
                });
            });
            setFromErrors(err.data, setErrors);
        }
    }, [isLibraryServiceEntryDataSuccess, isLibraryServiceEntryDataError]);

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title">
                                    <i className="fa fa-plus me-2" />
                                    Books / Journals / Periodicals
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    autoComplete="off"
                                    className="custom-form"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    onReset={(e) => {
                                        e.preventDefault();
                                        handleFormReset();
                                    }}
                                >
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="category">Type</Label>
                                                <InputField
                                                    type="select"
                                                    options={[
                                                        { label: 'Books (Title of books)', value: 'Books (Title of books)' },
                                                        { label: 'Journals', value: 'Journals' },
                                                        { label: 'Periodicals', value: 'Periodicals' }
                                                    ]}
                                                    placeholder=""
                                                    {...getFieldProps('category')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="titles">Title</Label>
                                                <InputField
                                                    type="text"
                                                    placeholder=""
                                                    {...getFieldProps('titles')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="total_books">Total Books</Label>
                                                <InputField
                                                    type="number"
                                                    placeholder=""
                                                    {...getFieldProps('total_books')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} className="mt-3">
                                            <FormGroup className="mb-3 text-center">
                                                <Button
                                                    type="reset"
                                                    text="Reset"
                                                    className="btn btn-warning me-2"
                                                ></Button>
                                                <Button
                                                    type="submit"
                                                    text="Save"
                                                    className="btn btn-success me-2"
                                                    disabled={isLibraryServiceEntryDataLoading}
                                                ></Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default BooksJournalsPeriodicalsModal;