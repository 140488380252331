import { apiSlice } from 'src/rtk/api/apiSlice';
import * as leaveManagementUrl from 'src/helpers/url_helper';

export const leaveManagementApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['leaveManagement'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLeaveEntryList: builder.query<DataWithPagination<LeaveList>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${leaveManagementUrl.GET_LEAVE_ENTRY_LIST}?${url}`,
        }),

        providesTags: ['leaveManagement'],
      }),


      getLeaveApprovalListForAdmin: builder.query<DataWithPagination<LeaveList>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${leaveManagementUrl.GET_LEAVE_APPROVAL_LIST_FOR_ADMIN}?${url}`,
        }),
        providesTags: ['leaveManagement'],
      }),

      getLeaveApprovalListForSupervisor: builder.query<DataWithPagination<LeaveList>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${leaveManagementUrl.GET_LEAVE_APPROVAL_LIST_FOR_SUPERVISOR}?${url}`,
        }),
        providesTags: ['leaveManagement'],
      }),

      getLeaveApplicationApproval: builder.mutation<APIResponse<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${leaveManagementUrl.GET_LEAVE_APPLICATION_APPROVAL}/${url}`,
        }),
        invalidatesTags: ['leaveManagement'],
      }),


      createLeaveEntry: builder.mutation<
        APIResponse<ApplyForLeave>,
        any
      >({
        query: (data: any) => ({
          url: `${leaveManagementUrl.CREATE_LEAVE_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['leaveManagement'],
      }),

      updateLeaveEntry: builder.mutation<
        APIResponse<ApplyForLeave>,
        any
      >({
        query: (url) => ({
          url: `${leaveManagementUrl.UPDATE_LEAVE_ENTRY}/${url.id}`,
          method: 'POST',
          body: url.data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['leaveManagement'],
      }),

      deleteLeaveEntry: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${leaveManagementUrl.DELETE_LEAVE_ENTRY}/${id}`,
        }),
        invalidatesTags: ['leaveManagement'],
      }),
    }),
  });

export const {
  useGetLeaveEntryListQuery,
  useGetLeaveApprovalListForAdminQuery,
  useGetLeaveApprovalListForSupervisorQuery,
  useGetLeaveApplicationApprovalMutation,
  useCreateLeaveEntryMutation,
  useUpdateLeaveEntryMutation,
  useDeleteLeaveEntryMutation,
} = leaveManagementApi;
