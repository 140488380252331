import { apiSlice } from 'src/rtk/api/apiSlice';
import * as traineeLogbookEntryUrl from 'src/helpers/url_helper';

export const traineeLogbookEntryApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['logbookEntries'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({

      createLogbookEntry: builder.mutation<
        APIResponse<any>,
        any
      >({
        query: (data: any) => ({
          url: `${traineeLogbookEntryUrl.CREATE_LOGBOOK_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['logbookEntries'],
      }),

      updateLogbookEntry: builder.mutation<
        APIResponse<any>,
        any
      >({
        query: (data: any) => ({
          url: `${traineeLogbookEntryUrl.UPDATE_LOGBOOK_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['logbookEntries'],
      }),

      getLogbookEntries: builder.query<APIResponse<LogbookEntryData[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${traineeLogbookEntryUrl.GET_LOGBOOK_ENTRIES}`,
        }),

        providesTags: ['logbookEntries'],
      }),

      getCompetencyGroupById: builder.query<APIResponse<LogbookEntrySetupMaster[]>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${traineeLogbookEntryUrl.GET_COMPETENCY_GROUP_BY_ID}/${id}`,
        }),

        providesTags: ['logbookEntries'],
      }),

      getLogbookEntriesByPaginate: builder.query<
        APIResponseWithPagination<LogbookEntryData>,
        PaginateForLogbookEntries
      >({
        query: (paginate) => {
          let url = `${traineeLogbookEntryUrl.GET_LOGBOOK_ENTRIES_BY_PAGINATE}?`;

          if (paginate.page) {
            url += `page=${paginate.page}&`;
          }
          if (paginate.paginate_per_page) {
            url += `paginate_per_page=${paginate.paginate_per_page}`;
          }
          if (paginate.search) {
            url += `${paginate.search}`;
          }

          return {
            method: 'GET',
            url: url,
          };
        },

        providesTags: ['logbookEntries'],
      }),
    }),
  });

export const {
  useCreateLogbookEntryMutation,
  useUpdateLogbookEntryMutation,
  useGetLogbookEntriesQuery,
  useGetLogbookEntriesByPaginateQuery,
  useGetCompetencyGroupByIdQuery,
  useLazyGetCompetencyGroupByIdQuery,
} = traineeLogbookEntryApi;
