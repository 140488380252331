import { Field, FieldArray, Form, Formik } from 'formik';
import { useState } from 'react';
import { Container } from 'reactstrap';
import { useGetLookupInfosQuery } from 'src/rtk/features/setup/lookup/lookupInfoApi';
import CheckBoxComponent from '../../Common/CheckBoxComponent';
import DropDownComponent from '../../Common/DropDownComponent';
import TextFieldComponent from '../../Common/TestFieldComponent';
import { MyDynamicForm } from '../Transactional/DynamicFormModified';

const DynamicFormSetup = (props?: any) => {

  const { formikProp, fieldProp } = props;

  const initialState = {
    formName: '',
    formSubtitle: '',
    setupFields: [
      {
        fieldName: '',
        fieldType: '',
        dropdownData: '',
        required: false,
      },
    ],
  };

  const fieldTypes = [
    { label: 'Number field', value: 'number' },
    { label: 'Text field', value: 'text' },
    { label: 'Textarea field', value: 'textarea' },
    { label: 'Date field', value: 'date' },
    { label: 'File attachment select', value: 'file' },
    { label: 'Checkbox', value: 'checkbox' },
    { label: 'Select dropdown', value: 'select' },
  ];

  // const dropdownDataTable = [
  //     {label: "Diseases", value: "diseases"},
  //     {label: "Certificate Type", value: "certificate_type"},
  //     {label: "Leave Type", value: "leave_type"},
  //     {label: "Admission Type", value: "admission_type"},
  // ]

  const [setupForm, setSetupForm] = useState(initialState);
  const [formStructure, setFormStructure] = useState<any>({});
  const [generateForm, setGenerateForm] = useState<boolean>(false);

  const {
    data: lookups,
    isSuccess,
    isError,
    isLoading,
  } = useGetLookupInfosQuery();

  const lookupList = lookups?.data || [];
  const dropdownOptions =
    lookupList.map((item) => ({
      value: item.lookup_name,
      label: item.lookup_name,
    })) || [];

  const handleSubmit = (e: any, values: any) => {
    e.preventDefault();
    setFormStructure(values);
    setGenerateForm(true);
    console.log(values);
  };

  return (
    <>
      <div className='page-content'>           
        {!generateForm ? (
          <Formik initialValues={setupForm} onSubmit={() => {}}>
            {({ values, setFieldValue }) => {
              return (
                <Container fluid>
                  <Form>
                    {/* <h3>BCPS Dynamic Form Setup</h3>
                    <br />
                    <br /> */}
                    <div className="col-12">
                      <div className="row">
                        {/* <div className="col-6">
                                                  <label>Form Name</label>
                                                  <Field
                                                      type="text"
                                                      name={`formName`}
                                                      component={TextAreaComponent}>
                                                  </Field>
                                              </div>
                                              <div className="col-6">
                                                  <label>Form Subtitle</label>
                                                  <Field
                                                      type="text"
                                                      name={`formSubtitle`}
                                                      component={TextAreaComponent}>
                                                  </Field>
                                              </div> */}
                      </div>
                    </div>

                    <FieldArray
                      name="setupFields"
                      render={(setupFieldArray) => (
                        <div className="card bg-light  shadow-sm mt-4 mb-4">
                          {fieldProp ? <h5 className='card-header'>{fieldProp}</h5> : null}  
                          {values.setupFields.map((setupField, index) => (
                            <div className="card-body" key={index}>
                              <div className="form-row ">
                                <div className="col-12">
                                  <div className="row">
                                    <div className="col-3">
                                      <label>Field Name</label>
                                      <Field
                                        type="text"
                                        name={`setupFields[${index}].fieldName`}
                                        component={TextFieldComponent}
                                      ></Field>
                                    </div>
                                    <div className="col-3">
                                      <label>Field Type</label>

                                      <Field
                                        type="text"
                                        name={`setupFields[${index}].fieldType`}
                                        placeholder="Select Field Type"
                                        fieldName={'label'}
                                        label="label"
                                        options={fieldTypes || []}
                                        component={DropDownComponent}
                                      />
                                    </div>
                                    <div className="col-4">
                                      <label>Field Data Source</label>

                                      <Field
                                        type="text"
                                        name={`setupFields[${index}].dropdownData`}
                                        placeholder="Dropdown Data Type"
                                        fieldName={'label'}
                                        label="label"
                                        options={dropdownOptions || []}
                                        component={DropDownComponent}
                                      />
                                    </div>
                                    <div className="col-2">
                                      {/* <label>Required Field</label> */}
                                      <Field
                                        type="text"
                                        name={`setupFields[${index}].required`}
                                        component={CheckBoxComponent}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="row justify-content-between">
                            <div className="col-2">
                              <div className="form-group ms-4">
                                <button
                                  type="button"
                                  className={'btn btn-secondary p-2'}
                                  onClick={() => {
                                    setupFieldArray.push({
                                      fieldName: '',
                                      fieldType: '',
                                      dropdownData: '',
                                      required: false,
                                    });
                                  }}
                                >
                                  <i className="fa fa-2x fa-plus-circle" />
                                </button>
                              </div>                            
                            </div>

                            <div className="col-2">
                              <div className="form-group ms-4">
                                <button type="submit" className="btn btn-success" onClick={(e) => handleSubmit(e, setupFieldArray.form.values)}>
                                  {' '}
                                  Submit
                                </button>
                              </div>                            
                            </div>
                          </div>
                        </div>
                      )}
                    />                  
                  </Form>
                </Container>
              );
            }}
          </Formik>
        ) : (
          <MyDynamicForm formStructure={formStructure} formikProp={formikProp} fieldProp={fieldProp} /> 
        )}
      </div>             
    </>
  );
};
export default DynamicFormSetup;
