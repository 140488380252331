import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import store from 'src/rtk/app/store';
import { useCreateDissertationReviewerCommentMutation } from 'src/rtk/features/disertation-thesis/dissertationListForReviewerApi';
import * as Yup from 'yup';

const initialValues: DissertationReviewerComment = {
    thesis_file_id: '',
    background_information: '',
    background_information_comment: '',
    methodology: '',
    methodology_comment: '',
    overall_study_design: '',
    overall_study_design_comment: '',
    result: '',
    result_comment: '',
    table_photo_and_graphs: '',
    table_photo_and_graphs_comment: '',
    statistical_assessment: '',
    statistical_assessment_comment: '',
    discussion: '',
    discussion_comment: '',
    conclusion_and_recommendation: '',
    conclusion_and_recommendation_comment: '',
    references: '',
    references_comment: '',
    ethical_issues: '',
    ethical_issues_comment: '',
    final: '',
    final_comment: '',
};

const AddDissertationReviewerComment = (props: any) => {
    const { thesisFileId, dissertation, setModalShow } = props;

    const [createDissertationReviewerComment, { isSuccess, isError, error }] =
        useCreateDissertationReviewerCommentMutation();

    const [backgroundInfoOne, setBackgroundInfoOne] = useState<boolean>(false);
    const [backgroundInfoTwo, setBackgroundInfoTwo] = useState<boolean>(true);
    const [methodologyOne, setMethodologyOne] = useState<boolean>(false);
    const [methodologyTwo, setMethodologyTwo] = useState<boolean>(true);
    const [overAllStudyDesignOne, setOverAllStudyDesignOne] = useState<boolean>(false);
    const [overAllStudyDesignTwo, setOverAllStudyDesignTwo] = useState<boolean>(true);
    const [resultOne, setResultOne] = useState<boolean>(false);
    const [resultTwo, setResultTwo] = useState<boolean>(true);
    const [tablePhotoOne, setTablePhotoOne] = useState<boolean>(false);
    const [tablePhotoTwo, setTablePhotoTwo] = useState<boolean>(true);
    const [statisticalOne, setStatisticalOne] = useState<boolean>(false);
    const [statisticalTwo, setStatisticalTwo] = useState<boolean>(true);
    const [discussionOne, setDiscussionOne] = useState<boolean>(false);
    const [discussionTwo, setDiscussionTwo] = useState<boolean>(true);
    const [conclusionAndRecommendationOne, setConclusionAndRecommendationOne] = useState<boolean>(false);
    const [conclusionAndRecommendationTwo, setConclusionAndRecommendationTwo] = useState<boolean>(true);
    const [referencesOne, setReferencesOne] = useState<boolean>(false);
    const [referencesTwo, setReferencesTwo] = useState<boolean>(true);
    const [ethicalIssuesOne, setEthicalIssuesOne] = useState<boolean>(false);
    const [ethicalIssuesTwo, setEthicalIssuesTwo] = useState<boolean>(false);
    const [ethicalIssuesThree, setEthicalIssuesThree] = useState<boolean>(true);
    const [finalOne, setFinalOne] = useState<boolean>(false);
    const [finalTwo, setFinalTwo] = useState<boolean>(false);
    const [finalThree, setFinalThree] = useState<boolean>(true);

    const err: CustomError = error as CustomError;

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({

        }),

        onSubmit: (values: any) => {

            values.thesis_file_id = thesisFileId;

            if (backgroundInfoOne) {
                values.background_information = 'Accepted';
            } else if (backgroundInfoTwo) {
                values.background_information = 'Correction';
            }

            if (methodologyOne) {
                values.methodology = 'Accepted';
            } else if (methodologyTwo) {
                values.methodology = 'Correction';
            }

            if (overAllStudyDesignOne) {
                values.overall_study_design = 'Accepted';
            } else if (overAllStudyDesignTwo) {
                values.overall_study_design = 'Correction';
            }

            if (resultOne) {
                values.result = 'Accepted';
            } else if (resultTwo) {
                values.result = 'Correction';
            }

            if (tablePhotoOne) {
                values.table_photo_and_graphs = 'Accepted';
            } else if (tablePhotoTwo) {
                values.table_photo_and_graphs = 'Correction';
            }

            if (statisticalOne) {
                values.statistical_assessment = 'Accepted';
            } else if (statisticalTwo) {
                values.statistical_assessment = 'Correction';
            }

            if (discussionOne) {
                values.discussion = 'Accepted';
            } else if (discussionTwo) {
                values.discussion = 'Correction';
            }

            if (conclusionAndRecommendationOne) {
                values.conclusion_and_recommendation = 'Accepted';
            } else if (conclusionAndRecommendationTwo) {
                values.conclusion_and_recommendation = 'Correction';
            }

            if (referencesOne) {
                values.references = 'Accepted';
            } else if (referencesTwo) {
                values.references = 'Correction';
            }

            if (ethicalIssuesOne) {
                values.ethical_issues = 'Properly Addressed';
            } else if (ethicalIssuesTwo) {
                values.ethical_issues = 'Not Adequately Addressed';
            } else if (ethicalIssuesThree) {
                values.ethical_issues = 'Not Addressed';
            }

            if (finalOne) {
                values.final = 'Acceptable';
            } else if (finalTwo) {
                values.final = 'Acceptable After Correction';
            } else if (finalThree) {
                values.final = 'Not Acceptable';
            }

            createDissertationReviewerComment({
                ...values,
            });

            console.log(values);
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    useEffect(() => {
        if (dissertation?.id) {

            if (dissertation?.first_reviewer_id === store.getState().auth.user.id) {
                setValues({
                    thesis_file_id: dissertation?.id,
                    background_information: dissertation?.first_reviewer_background_information ?? '',
                    background_information_comment: dissertation?.first_reviewer_background_information_comment ?? '',
                    methodology: dissertation?.first_reviewer_methodology ?? '',
                    methodology_comment: dissertation?.first_reviewer_methodology_comment ?? '',
                    overall_study_design: dissertation?.first_reviewer_overall_study_design ?? '',
                    overall_study_design_comment: dissertation?.first_reviewer_overall_study_design_comment ?? '',
                    result: dissertation?.first_reviewer_result ?? '',
                    result_comment: dissertation?.first_reviewer_result_comment ?? '',
                    table_photo_and_graphs: dissertation?.first_reviewer_table_photo_and_graphs ?? '',
                    table_photo_and_graphs_comment: dissertation?.first_reviewer_table_photo_and_graphs_comment ?? '',
                    statistical_assessment: dissertation?.first_reviewer_statistical_assessment ?? '',
                    statistical_assessment_comment: dissertation?.first_reviewer_statistical_assessment_comment ?? '',
                    discussion: dissertation?.first_reviewer_discussion ?? '',
                    discussion_comment: dissertation?.first_reviewer_discussion_comment ?? '',
                    conclusion_and_recommendation: dissertation?.first_reviewer_conclusion_and_recommendation ?? '',
                    conclusion_and_recommendation_comment: dissertation?.first_reviewer_conclusion_and_recommendation_comment ?? '',
                    references: dissertation?.first_reviewer_references ?? '',
                    references_comment: dissertation?.first_reviewer_references_comment ?? '',
                    ethical_issues: dissertation?.first_reviewer_ethical_issues ?? '',
                    ethical_issues_comment: dissertation?.first_reviewer_ethical_issues_comment ?? '',
                    final: dissertation?.first_reviewer_final ?? '',
                    final_comment: dissertation?.first_reviewer_final_comment ?? '',
                });

                if (dissertation?.first_reviewer_background_information === 'Accepted') {
                    setBackgroundInfoOne(true);
                    setBackgroundInfoTwo(false);
                } else if (dissertation?.first_reviewer_background_information === 'Correction') {
                    setBackgroundInfoOne(false);
                    setBackgroundInfoTwo(true);
                }

                if (dissertation?.first_reviewer_methodology === 'Accepted') {
                    setMethodologyOne(true);
                    setMethodologyTwo(false);
                } else if (dissertation?.first_reviewer_methodology === 'Correction') {
                    setMethodologyOne(false);
                    setMethodologyTwo(true);
                }

                if (dissertation?.first_reviewer_overall_study_design === 'Accepted') {
                    setOverAllStudyDesignOne(true);
                    setOverAllStudyDesignTwo(false);
                } else if (dissertation?.first_reviewer_overall_study_design === 'Correction') {
                    setOverAllStudyDesignOne(false);
                    setOverAllStudyDesignTwo(true);
                }

                if (dissertation?.first_reviewer_result === 'Accepted') {
                    setResultOne(true);
                    setResultTwo(false);
                } else if (dissertation?.first_reviewer_result === 'Correction') {
                    setResultOne(false);
                    setResultTwo(true);
                }

                if (dissertation?.first_reviewer_table_photo_and_graphs === 'Accepted') {
                    setTablePhotoOne(true);
                    setTablePhotoTwo(false);
                } else if (dissertation?.first_reviewer_table_photo_and_graphs === 'Correction') {
                    setTablePhotoOne(false);
                    setTablePhotoTwo(true);
                }

                if (dissertation?.first_reviewer_statistical_assessment === 'Accepted') {
                    setStatisticalOne(true);
                    setStatisticalTwo(false);
                } else if (dissertation?.first_reviewer_statistical_assessment === 'Correction') {
                    setStatisticalOne(false);
                    setStatisticalTwo(true);
                }

                if (dissertation?.first_reviewer_discussion === 'Accepted') {
                    setDiscussionOne(true);
                    setDiscussionTwo(false);
                } else if (dissertation?.first_reviewer_discussion === 'Correction') {
                    setDiscussionOne(false);
                    setDiscussionTwo(true);
                }

                if (dissertation?.first_reviewer_conclusion_and_recommendation === 'Accepted') {
                    setConclusionAndRecommendationOne(true);
                    setConclusionAndRecommendationTwo(false);
                } else if (dissertation?.first_reviewer_conclusion_and_recommendation === 'Correction') {
                    setConclusionAndRecommendationOne(false);
                    setConclusionAndRecommendationTwo(true);
                }

                if (dissertation?.first_reviewer_references === 'Accepted') {
                    setReferencesOne(true);
                    setReferencesTwo(false);
                } else if (dissertation?.first_reviewer_references === 'Correction') {
                    setReferencesOne(false);
                    setReferencesTwo(true);
                }

                if (dissertation?.first_reviewer_ethical_issues === 'Properly Addressed') {
                    setEthicalIssuesOne(true);
                    setEthicalIssuesTwo(false);
                    setEthicalIssuesThree(false);
                } else if (dissertation?.first_reviewer_ethical_issues === 'Not Adequately Addressed') {
                    setEthicalIssuesOne(false);
                    setEthicalIssuesTwo(true);
                    setEthicalIssuesThree(false);
                } else if (dissertation?.first_reviewer_ethical_issues === 'Not Addressed') {
                    setEthicalIssuesOne(false);
                    setEthicalIssuesTwo(false);
                    setEthicalIssuesThree(true);
                }

                if (dissertation?.first_reviewer_final === 'Acceptable') {
                    setFinalOne(true);
                    setFinalTwo(false);
                    setFinalThree(false);
                } else if (dissertation?.first_reviewer_final === 'Acceptable After Correction') {
                    setFinalOne(false);
                    setFinalTwo(true);
                    setFinalThree(false);
                } else if (dissertation?.first_reviewer_final === 'Not Acceptable') {
                    setFinalOne(false);
                    setFinalTwo(false);
                    setFinalThree(true);
                }
            } else if (dissertation?.second_reviewer_id === store.getState().auth.user.id) {
                setValues({
                    thesis_file_id: dissertation?.id,
                    background_information: dissertation?.second_reviewer_background_information ?? '',
                    background_information_comment: dissertation?.second_reviewer_background_information_comment ?? '',
                    methodology: dissertation?.second_reviewer_methodology ?? '',
                    methodology_comment: dissertation?.second_reviewer_methodology_comment ?? '',
                    overall_study_design: dissertation?.second_reviewer_overall_study_design ?? '',
                    overall_study_design_comment: dissertation?.second_reviewer_overall_study_design_comment ?? '',
                    result: dissertation?.second_reviewer_result ?? '',
                    result_comment: dissertation?.second_reviewer_result_comment ?? '',
                    table_photo_and_graphs: dissertation?.second_reviewer_table_photo_and_graphs ?? '',
                    table_photo_and_graphs_comment: dissertation?.second_reviewer_table_photo_and_graphs_comment ?? '',
                    statistical_assessment: dissertation?.second_reviewer_statistical_assessment ?? '',
                    statistical_assessment_comment: dissertation?.second_reviewer_statistical_assessment_comment ?? '',
                    discussion: dissertation?.second_reviewer_discussion ?? '',
                    discussion_comment: dissertation?.second_reviewer_discussion_comment ?? '',
                    conclusion_and_recommendation: dissertation?.second_reviewer_conclusion_and_recommendation ?? '',
                    conclusion_and_recommendation_comment: dissertation?.second_reviewer_conclusion_and_recommendation_comment ?? '',
                    references: dissertation?.second_reviewer_references ?? '',
                    references_comment: dissertation?.second_reviewer_references_comment ?? '',
                    ethical_issues: dissertation?.second_reviewer_ethical_issues ?? '',
                    ethical_issues_comment: dissertation?.second_reviewer_ethical_issues_comment ?? '',
                    final: dissertation?.second_reviewer_final ?? '',
                    final_comment: dissertation?.second_reviewer_final_comment ?? '',
                });

                if (dissertation?.second_reviewer_background_information === 'Accepted') {
                    setBackgroundInfoOne(true);
                    setBackgroundInfoTwo(false);
                } else if (dissertation?.second_reviewer_background_information === 'Correction') {
                    setBackgroundInfoOne(false);
                    setBackgroundInfoTwo(true);
                }

                if (dissertation?.second_reviewer_methodology === 'Accepted') {
                    setMethodologyOne(true);
                    setMethodologyTwo(false);
                } else if (dissertation?.second_reviewer_methodology === 'Correction') {
                    setMethodologyOne(false);
                    setMethodologyTwo(true);
                }

                if (dissertation?.second_reviewer_overall_study_design === 'Accepted') {
                    setOverAllStudyDesignOne(true);
                    setOverAllStudyDesignTwo(false);
                } else if (dissertation?.second_reviewer_overall_study_design === 'Correction') {
                    setOverAllStudyDesignOne(false);
                    setOverAllStudyDesignTwo(true);
                }

                if (dissertation?.second_reviewer_result === 'Accepted') {
                    setResultOne(true);
                    setResultTwo(false);
                } else if (dissertation?.second_reviewer_result === 'Correction') {
                    setResultOne(false);
                    setResultTwo(true);
                }

                if (dissertation?.second_reviewer_table_photo_and_graphs === 'Accepted') {
                    setTablePhotoOne(true);
                    setTablePhotoTwo(false);
                } else if (dissertation?.second_reviewer_table_photo_and_graphs === 'Correction') {
                    setTablePhotoOne(false);
                    setTablePhotoTwo(true);
                }

                if (dissertation?.second_reviewer_statistical_assessment === 'Accepted') {
                    setStatisticalOne(true);
                    setStatisticalTwo(false);
                } else if (dissertation?.second_reviewer_statistical_assessment === 'Correction') {
                    setStatisticalOne(false);
                    setStatisticalTwo(true);
                }

                if (dissertation?.second_reviewer_discussion === 'Accepted') {
                    setDiscussionOne(true);
                    setDiscussionTwo(false);
                } else if (dissertation?.second_reviewer_discussion === 'Correction') {
                    setDiscussionOne(false);
                    setDiscussionTwo(true);
                }

                if (dissertation?.second_reviewer_conclusion_and_recommendation === 'Accepted') {
                    setConclusionAndRecommendationOne(true);
                    setConclusionAndRecommendationTwo(false);
                } else if (dissertation?.second_reviewer_conclusion_and_recommendation === 'Correction') {
                    setConclusionAndRecommendationOne(false);
                    setConclusionAndRecommendationTwo(true);
                }

                if (dissertation?.second_reviewer_references === 'Accepted') {
                    setReferencesOne(true);
                    setReferencesTwo(false);
                } else if (dissertation?.second_reviewer_references === 'Correction') {
                    setReferencesOne(false);
                    setReferencesTwo(true);
                }

                if (dissertation?.second_reviewer_ethical_issues === 'Properly Addressed') {
                    setEthicalIssuesOne(true);
                    setEthicalIssuesTwo(false);
                    setEthicalIssuesThree(false);
                } else if (dissertation?.second_reviewer_ethical_issues === 'Not Adequately Addressed') {
                    setEthicalIssuesOne(false);
                    setEthicalIssuesTwo(true);
                    setEthicalIssuesThree(false);
                } else if (dissertation?.second_reviewer_ethical_issues === 'Not Addressed') {
                    setEthicalIssuesOne(false);
                    setEthicalIssuesTwo(false);
                    setEthicalIssuesThree(true);
                }

                if (dissertation?.second_reviewer_final === 'Acceptable') {
                    setFinalOne(true);
                    setFinalTwo(false);
                    setFinalThree(false);
                } else if (dissertation?.second_reviewer_final === 'Acceptable After Correction') {
                    setFinalOne(false);
                    setFinalTwo(true);
                    setFinalThree(false);
                } else if (dissertation?.second_reviewer_final === 'Not Acceptable') {
                    setFinalOne(false);
                    setFinalTwo(false);
                    setFinalThree(true);
                }
            } else if (dissertation?.third_reviewer_id === store.getState().auth.user.id) {
                setValues({
                    thesis_file_id: dissertation?.id,
                    background_information: dissertation?.third_reviewer_background_information ?? '',
                    background_information_comment: dissertation?.third_reviewer_background_information_comment ?? '',
                    methodology: dissertation?.third_reviewer_methodology ?? '',
                    methodology_comment: dissertation?.third_reviewer_methodology_comment ?? '',
                    overall_study_design: dissertation?.third_reviewer_overall_study_design ?? '',
                    overall_study_design_comment: dissertation?.third_reviewer_overall_study_design_comment ?? '',
                    result: dissertation?.third_reviewer_result ?? '',
                    result_comment: dissertation?.third_reviewer_result_comment ?? '',
                    table_photo_and_graphs: dissertation?.third_reviewer_table_photo_and_graphs ?? '',
                    table_photo_and_graphs_comment: dissertation?.third_reviewer_table_photo_and_graphs_comment ?? '',
                    statistical_assessment: dissertation?.third_reviewer_statistical_assessment ?? '',
                    statistical_assessment_comment: dissertation?.third_reviewer_statistical_assessment_comment ?? '',
                    discussion: dissertation?.third_reviewer_discussion ?? '',
                    discussion_comment: dissertation?.third_reviewer_discussion_comment ?? '',
                    conclusion_and_recommendation: dissertation?.third_reviewer_conclusion_and_recommendation ?? '',
                    conclusion_and_recommendation_comment: dissertation?.third_reviewer_conclusion_and_recommendation_comment ?? '',
                    references: dissertation?.third_reviewer_references ?? '',
                    references_comment: dissertation?.third_reviewer_references_comment ?? '',
                    ethical_issues: dissertation?.third_reviewer_ethical_issues ?? '',
                    ethical_issues_comment: dissertation?.third_reviewer_ethical_issues_comment ?? '',
                    final: dissertation?.third_reviewer_final ?? '',
                    final_comment: dissertation?.third_reviewer_final_comment ?? '',
                });

                if (dissertation?.third_reviewer_background_information === 'Accepted') {
                    setBackgroundInfoOne(true);
                    setBackgroundInfoTwo(false);
                } else if (dissertation?.third_reviewer_background_information === 'Correction') {
                    setBackgroundInfoOne(false);
                    setBackgroundInfoTwo(true);
                }

                if (dissertation?.third_reviewer_methodology === 'Accepted') {
                    setMethodologyOne(true);
                    setMethodologyTwo(false);
                } else if (dissertation?.third_reviewer_methodology === 'Correction') {
                    setMethodologyOne(false);
                    setMethodologyTwo(true);
                }

                if (dissertation?.third_reviewer_overall_study_design === 'Accepted') {
                    setOverAllStudyDesignOne(true);
                    setOverAllStudyDesignTwo(false);
                } else if (dissertation?.third_reviewer_overall_study_design === 'Correction') {
                    setOverAllStudyDesignOne(false);
                    setOverAllStudyDesignTwo(true);
                }

                if (dissertation?.third_reviewer_result === 'Accepted') {
                    setResultOne(true);
                    setResultTwo(false);
                } else if (dissertation?.third_reviewer_result === 'Correction') {
                    setResultOne(false);
                    setResultTwo(true);
                }

                if (dissertation?.third_reviewer_table_photo_and_graphs === 'Accepted') {
                    setTablePhotoOne(true);
                    setTablePhotoTwo(false);
                } else if (dissertation?.third_reviewer_table_photo_and_graphs === 'Correction') {
                    setTablePhotoOne(false);
                    setTablePhotoTwo(true);
                }

                if (dissertation?.third_reviewer_statistical_assessment === 'Accepted') {
                    setStatisticalOne(true);
                    setStatisticalTwo(false);
                } else if (dissertation?.third_reviewer_statistical_assessment === 'Correction') {
                    setStatisticalOne(false);
                    setStatisticalTwo(true);
                }

                if (dissertation?.third_reviewer_discussion === 'Accepted') {
                    setDiscussionOne(true);
                    setDiscussionTwo(false);
                } else if (dissertation?.third_reviewer_discussion === 'Correction') {
                    setDiscussionOne(false);
                    setDiscussionTwo(true);
                }

                if (dissertation?.third_reviewer_conclusion_and_recommendation === 'Accepted') {
                    setConclusionAndRecommendationOne(true);
                    setConclusionAndRecommendationTwo(false);
                } else if (dissertation?.third_reviewer_conclusion_and_recommendation === 'Correction') {
                    setConclusionAndRecommendationOne(false);
                    setConclusionAndRecommendationTwo(true);
                }

                if (dissertation?.third_reviewer_references === 'Accepted') {
                    setReferencesOne(true);
                    setReferencesTwo(false);
                } else if (dissertation?.third_reviewer_references === 'Correction') {
                    setReferencesOne(false);
                    setReferencesTwo(true);
                }

                if (dissertation?.third_reviewer_ethical_issues === 'Properly Addressed') {
                    setEthicalIssuesOne(true);
                    setEthicalIssuesTwo(false);
                    setEthicalIssuesThree(false);
                } else if (dissertation?.third_reviewer_ethical_issues === 'Not Adequately Addressed') {
                    setEthicalIssuesOne(false);
                    setEthicalIssuesTwo(true);
                    setEthicalIssuesThree(false);
                } else if (dissertation?.third_reviewer_ethical_issues === 'Not Addressed') {
                    setEthicalIssuesOne(false);
                    setEthicalIssuesTwo(false);
                    setEthicalIssuesThree(true);
                }

                if (dissertation?.third_reviewer_final === 'Acceptable') {
                    setFinalOne(true);
                    setFinalTwo(false);
                    setFinalThree(false);
                } else if (dissertation?.third_reviewer_final === 'Acceptable After Correction') {
                    setFinalOne(false);
                    setFinalTwo(true);
                    setFinalThree(false);
                } else if (dissertation?.third_reviewer_final === 'Not Acceptable') {
                    setFinalOne(false);
                    setFinalTwo(false);
                    setFinalThree(true);
                }
            }
        }
    }, []);

    useEffect(() => {
        if(backgroundInfoOne) {
            setFieldValue('background_information_comment', '');
        }
    }, [backgroundInfoOne]);

    useEffect(() => {
        if(methodologyOne) {
            setFieldValue('methodology_comment', '');
        }
    }, [methodologyOne]);

    useEffect(() => {
        if(overAllStudyDesignOne) {
            setFieldValue('overall_study_design_comment', '');
        }
    }, [overAllStudyDesignOne]);

    useEffect(() => {
        if(resultOne) {
            setFieldValue('result_comment', '');
        }
    }, [resultOne]);

    useEffect(() => {
        if(tablePhotoOne) {
            setFieldValue('table_photo_and_graphs_comment', '');
        }
    }, [tablePhotoOne]);

    useEffect(() => {
        if(statisticalOne) {
            setFieldValue('statistical_assessment_comment', '');
        }
    }, [statisticalOne]);

    useEffect(() => {
        if(discussionOne) {
            setFieldValue('discussion_comment', '');
        }
    }, [discussionOne]);

    useEffect(() => {
        if(conclusionAndRecommendationOne) {
            setFieldValue('conclusion_and_recommendation_comment', '');
        }
    }, [conclusionAndRecommendationOne]);

    useEffect(() => {
        if(referencesOne) {
            setFieldValue('references_comment', '');
        }
    }, [referencesOne]);    

    useEffect(() => {
        if(ethicalIssuesOne) {
            setFieldValue('ethical_issues_comment', '');
        }
    }, [ethicalIssuesOne]);

    useEffect(() => {
        if(ethicalIssuesOne) {
            setFieldValue('ethical_issues_comment', '');
        }
    }, [ethicalIssuesOne]);

    useEffect(() => {
        if (isSuccess) {
            resetForm();
            setModalShow(false);
            toast.success('Comment submitted successfully');
        }
        if (isError) {
            // toast.error(err?.message || 'Something went wrong');
            Object.entries(err?.data).forEach((entry: any) => {
                entry[1]?.forEach((message: any) => {
                    toast.error(message);
                });
            });
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);

    const handleFormReset = () => {
        setBackgroundInfoOne(false);
        setBackgroundInfoTwo(true);
        setMethodologyOne(false);
        setMethodologyTwo(true);
        setOverAllStudyDesignOne(false);
        setOverAllStudyDesignTwo(true);
        setResultOne(false);
        setResultTwo(true);
        setTablePhotoOne(false);
        setTablePhotoTwo(true);
        setStatisticalOne(false);
        setStatisticalTwo(true);
        setDiscussionOne(false);
        setDiscussionTwo(true);
        setConclusionAndRecommendationOne(false);
        setConclusionAndRecommendationTwo(true);
        setReferencesOne(false);
        setReferencesTwo(true);
        setEthicalIssuesOne(false);
        setEthicalIssuesTwo(false);
        setEthicalIssuesThree(true);
        setFinalOne(false);
        setFinalTwo(false);
        setFinalThree(true);
        resetForm();
    };

    return (
        <>
            <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader className="d-flex">
                                    <h5 className="card-title mb-0 flex-grow-1">
                                        <i className="fa fa-plus me-2" />
                                        Reviewer Comments
                                    </h5>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <React.Fragment>
                                        <Form
                                            autoComplete="off"
                                            className="custom-form"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                handleSubmit();
                                            }}
                                            onReset={(e) => {
                                                e.preventDefault();
                                                handleFormReset();
                                            }}
                                        >
                                            <Row>
                                                <Col sm={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">1. Background Information</Label>
                                                        <br />
                                                        <FormGroup check inline>
                                                            <Input
                                                                id="background1"
                                                                name="background_information"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setBackgroundInfoOne(true);
                                                                        setBackgroundInfoTwo(false);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Accepted'}
                                                                checked={backgroundInfoOne}
                                                            />

                                                            <Label check for="background1">
                                                                Adequate Background Information
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Input
                                                                id="background2"
                                                                name="background_information"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setBackgroundInfoOne(false);
                                                                        setBackgroundInfoTwo(true);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Correction'}
                                                                checked={backgroundInfoTwo}
                                                            />

                                                            <Label check for="background2">
                                                                Need Changes (Give Comment)
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </Col>

                                                {backgroundInfoTwo ?
                                                    (<Col sm={10}>
                                                        <div className="mb-3">
                                                            <Label
                                                                htmlFor="background_information_comment"
                                                                className="form-Label"
                                                            >
                                                                Comment
                                                            </Label>
                                                            <InputField
                                                                type="textarea"
                                                                placeholder=""
                                                                invalid={{ errors, touched }}
                                                                {...getFieldProps('background_information_comment')}
                                                            />
                                                        </div>
                                                    </Col>) : null}
                                            </Row>

                                            <Row className='mt-4'>
                                                <Col sm={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">2. Methodology</Label>
                                                        <br />
                                                        <FormGroup check inline>
                                                            <Input
                                                                id="methodology1"
                                                                name="methodology"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setMethodologyOne(true);
                                                                        setMethodologyTwo(false);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Accepted'}
                                                                checked={methodologyOne}
                                                            />

                                                            <Label check for="methodology1">
                                                                Adequately Described
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Input
                                                                id="methodology2"
                                                                name="methodology"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setMethodologyOne(false);
                                                                        setMethodologyTwo(true);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Correction'}
                                                                checked={methodologyTwo}
                                                            />

                                                            <Label check for="methodology2">
                                                                Need Changes (Give Comment)
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </Col>

                                                {methodologyTwo ?
                                                    (<Col sm={10}>
                                                        <div className="mb-3">
                                                            <Label
                                                                htmlFor="methodology_comment"
                                                                className="form-Label"
                                                            >
                                                                Comment
                                                            </Label>
                                                            <InputField
                                                                type="textarea"
                                                                placeholder=""
                                                                invalid={{ errors, touched }}
                                                                {...getFieldProps('methodology_comment')}
                                                            />
                                                        </div>
                                                    </Col>) : null}
                                            </Row>

                                            <Row className='mt-4'>
                                                <Col sm={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">3. Overall Study Design</Label>
                                                        <br />
                                                        <FormGroup check inline>
                                                            <Input
                                                                id="study1"
                                                                name="overall_study_design"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setOverAllStudyDesignOne(true);
                                                                        setOverAllStudyDesignTwo(false);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Accepted'}
                                                                checked={overAllStudyDesignOne}
                                                            />

                                                            <Label check for="study1">
                                                                Acceptable
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Input
                                                                id="study2"
                                                                name="overall_study_design"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setOverAllStudyDesignOne(false);
                                                                        setOverAllStudyDesignTwo(true);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Correction'}
                                                                checked={overAllStudyDesignTwo}
                                                            />

                                                            <Label check for="study2">
                                                                Need Changes (Give Comment)
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </Col>

                                                {overAllStudyDesignTwo ?
                                                    (<Col sm={10}>
                                                        <div className="mb-3">
                                                            <Label
                                                                htmlFor="overall_study_design_comment"
                                                                className="form-Label"
                                                            >
                                                                Comment
                                                            </Label>
                                                            <InputField
                                                                type="textarea"
                                                                placeholder=""
                                                                invalid={{ errors, touched }}
                                                                {...getFieldProps('overall_study_design_comment')}
                                                            />
                                                        </div>
                                                    </Col>) : null}
                                            </Row>

                                            <Row className='mt-4'>
                                                <Col sm={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">4. Result</Label>
                                                        <br />
                                                        <FormGroup check inline>
                                                            <Input
                                                                id="result1"
                                                                name="result"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setResultOne(true);
                                                                        setResultTwo(false);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Accepted'}
                                                                checked={resultOne}
                                                            />

                                                            <Label check for="result1">
                                                                Well Presented
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Input
                                                                id="result2"
                                                                name="result"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setResultOne(false);
                                                                        setResultTwo(true);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Correction'}
                                                                checked={resultTwo}
                                                            />

                                                            <Label check for="result2">
                                                                Need Changes (Give Comment)
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </Col>

                                                {resultTwo ?
                                                    (<Col sm={10}>
                                                        <div className="mb-3">
                                                            <Label
                                                                htmlFor="result_comment"
                                                                className="form-Label"
                                                            >
                                                                Comment
                                                            </Label>
                                                            <InputField
                                                                type="textarea"
                                                                placeholder=""
                                                                invalid={{ errors, touched }}
                                                                {...getFieldProps('result_comment')}
                                                            />
                                                        </div>
                                                    </Col>) : null}
                                            </Row>

                                            <Row className='mt-4'>
                                                <Col sm={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">5. Table, Photograph and Graphs</Label>
                                                        <br />
                                                        <FormGroup check inline>
                                                            <Input
                                                                id="tablePhoto1"
                                                                name="table_photo_and_graphs"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setTablePhotoOne(true);
                                                                        setTablePhotoTwo(false);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Accepted'}
                                                                checked={tablePhotoOne}
                                                            />

                                                            <Label check for="tablePhoto1">
                                                                Placed Accordingly
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Input
                                                                id="tablePhoto2"
                                                                name="table_photo_and_graphs"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setTablePhotoOne(false);
                                                                        setTablePhotoTwo(true);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Correction'}
                                                                checked={tablePhotoTwo}
                                                            />

                                                            <Label check for="tablePhoto2">
                                                                Need Changes (Give Comment)
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </Col>

                                                {tablePhotoTwo ?
                                                    (<Col sm={10}>
                                                        <div className="mb-3">
                                                            <Label
                                                                htmlFor="table_photo_and_graphs_comment"
                                                                className="form-Label"
                                                            >
                                                                Comment
                                                            </Label>
                                                            <InputField
                                                                type="textarea"
                                                                placeholder=""
                                                                invalid={{ errors, touched }}
                                                                {...getFieldProps('table_photo_and_graphs_comment')}
                                                            />
                                                        </div>
                                                    </Col>) : null}
                                            </Row>

                                            <Row className='mt-4'>
                                                <Col sm={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">6. Statistical Assessment</Label>
                                                        <br />
                                                        <FormGroup check inline>
                                                            <Input
                                                                id="statistical1"
                                                                name="statistical_assessment"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setStatisticalOne(true);
                                                                        setStatisticalTwo(false);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Accepted'}
                                                                checked={statisticalOne}
                                                            />

                                                            <Label check for="statistical1">
                                                                Mentioned Clearly
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Input
                                                                id="statistical2"
                                                                name="statistical_assessment"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setStatisticalOne(false);
                                                                        setStatisticalTwo(true);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Correction'}
                                                                checked={statisticalTwo}
                                                            />

                                                            <Label check for="statistical2">
                                                                Need Changes (Give Comment)
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </Col>

                                                {statisticalTwo ?
                                                    (<Col sm={10}>
                                                        <div className="mb-3">
                                                            <Label
                                                                htmlFor="statistical_assessment_comment"
                                                                className="form-Label"
                                                            >
                                                                Comment
                                                            </Label>
                                                            <InputField
                                                                type="textarea"
                                                                placeholder=""
                                                                invalid={{ errors, touched }}
                                                                {...getFieldProps('statistical_assessment_comment')}
                                                            />
                                                        </div>
                                                    </Col>) : null}
                                            </Row>

                                            <Row className='mt-4'>
                                                <Col sm={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">7. Discussion</Label>
                                                        <br />
                                                        <FormGroup check inline>
                                                            <Input
                                                                id="discussion1"
                                                                name="discussion"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setDiscussionOne(true);
                                                                        setDiscussionTwo(false);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Accepted'}
                                                                checked={discussionOne}
                                                            />

                                                            <Label check for="discussion1">
                                                                Described Adequately
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Input
                                                                id="discussion2"
                                                                name="discussion"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setDiscussionOne(false);
                                                                        setDiscussionTwo(true);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Correction'}
                                                                checked={discussionTwo}
                                                            />

                                                            <Label check for="discussion2">
                                                                Need Changes (Give Comment)
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </Col>

                                                {discussionTwo ?
                                                    (<Col sm={10}>
                                                        <div className="mb-3">
                                                            <Label
                                                                htmlFor="discussion_comment"
                                                                className="form-Label"
                                                            >
                                                                Comment
                                                            </Label>
                                                            <InputField
                                                                type="textarea"
                                                                placeholder=""
                                                                invalid={{ errors, touched }}
                                                                {...getFieldProps('discussion_comment')}
                                                            />
                                                        </div>
                                                    </Col>) : null}
                                            </Row>

                                            <Row className='mt-4'>
                                                <Col sm={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">8. Conclusion and Recommendation</Label>
                                                        <br />
                                                        <FormGroup check inline>
                                                            <Input
                                                                id="conclusion1"
                                                                name="conclusion_and_recommendation"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setConclusionAndRecommendationOne(true);
                                                                        setConclusionAndRecommendationTwo(false);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Accepted'}
                                                                checked={conclusionAndRecommendationOne}
                                                            />

                                                            <Label check for="conclusion1">
                                                                Mentioned Clearly
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Input
                                                                id="conclusion2"
                                                                name="conclusion_and_recommendation"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setConclusionAndRecommendationOne(false);
                                                                        setConclusionAndRecommendationTwo(true);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Correction'}
                                                                checked={conclusionAndRecommendationTwo}
                                                            />

                                                            <Label check for="conclusion2">
                                                                Not Acceptable
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </Col>

                                                {conclusionAndRecommendationTwo ? (<Col sm={10}>
                                                    <div className="mb-3">
                                                        <Label
                                                            htmlFor="conclusion_and_recommendation_comment"
                                                            className="form-Label"
                                                        >
                                                            Comment
                                                        </Label>
                                                        <InputField
                                                            type="textarea"
                                                            placeholder=""
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('conclusion_and_recommendation_comment')}
                                                        />
                                                    </div>
                                                </Col>) : null}
                                            </Row>

                                            <Row className='mt-4'>
                                                <Col sm={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">9. References</Label>
                                                        <br />
                                                        <FormGroup check inline>
                                                            <Input
                                                                id="references1"
                                                                name="references"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setReferencesOne(true);
                                                                        setReferencesTwo(false);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Accepted'}
                                                                checked={referencesOne}
                                                            />

                                                            <Label check for="references1">
                                                                Cited as per Guideline of the Manual
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Input
                                                                id="references2"
                                                                name="references"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setReferencesOne(false);
                                                                        setReferencesTwo(true);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Correction'}
                                                                checked={referencesTwo}
                                                            />

                                                            <Label check for="references2">
                                                                Needs Corrections (Mark the places of improper citation)
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </Col>

                                                {referencesTwo ? (<Col sm={10}>
                                                    <div className="mb-3">
                                                        <Label
                                                            htmlFor="references_comment"
                                                            className="form-Label"
                                                        >
                                                            Comment
                                                        </Label>
                                                        <InputField
                                                            type="textarea"
                                                            placeholder=""
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('references_comment')}
                                                        />
                                                    </div>
                                                </Col>) : null}
                                            </Row>

                                            <Row className='mt-4'>
                                                <Col sm={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">10. Ethical Issues</Label>
                                                        <br />
                                                        <FormGroup check inline>
                                                            <Input
                                                                id="ethical1"
                                                                name="ethical_issues"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setEthicalIssuesOne(true);
                                                                        setEthicalIssuesTwo(false);
                                                                        setEthicalIssuesThree(false);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Properly Addressed'}
                                                                checked={ethicalIssuesOne}
                                                            />

                                                            <Label check for="ethical1">
                                                                Properly Addressed
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Input
                                                                id="ethical2"
                                                                name="ethical_issues"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setEthicalIssuesOne(false);
                                                                        setEthicalIssuesTwo(true);
                                                                        setEthicalIssuesThree(false);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Not Adequately Addressed'}
                                                                checked={ethicalIssuesTwo}
                                                            />

                                                            <Label check for="ethical2">
                                                                Not Adequately Addressed
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Input
                                                                id="ethical3"
                                                                name="ethical_issues"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setEthicalIssuesOne(false);
                                                                        setEthicalIssuesTwo(false);
                                                                        setEthicalIssuesThree(true);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Not Addressed'}
                                                                checked={ethicalIssuesThree}
                                                            />

                                                            <Label check for="ethical3">
                                                                Not Addressed
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </Col>

                                                {ethicalIssuesTwo || ethicalIssuesThree ? (<Col sm={10}>
                                                    <div className="mb-3">
                                                        <Label
                                                            htmlFor="ethical_issues_comment"
                                                            className="form-Label"
                                                        >
                                                            Comment
                                                        </Label>
                                                        <InputField
                                                            type="textarea"
                                                            placeholder=""
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('ethical_issues_comment')}
                                                        />
                                                    </div>
                                                </Col>) : null}
                                            </Row>

                                            <Row className='mt-4'>
                                                <Col sm={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">11. Final Comment</Label>
                                                        <br />
                                                        <FormGroup check inline>
                                                            <Input
                                                                id="final1"
                                                                name="final"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setFinalOne(true);
                                                                        setFinalTwo(false);
                                                                        setFinalThree(false);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Acceptable'}
                                                                checked={finalOne}
                                                            />

                                                            <Label check for="final1">
                                                                Acceptable
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Input
                                                                id="final2"
                                                                name="final"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setFinalOne(false);
                                                                        setFinalTwo(true);
                                                                        setFinalThree(false);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Acceptable After Correction'}
                                                                checked={finalTwo}
                                                            />

                                                            <Label check for="final2">
                                                                Acceptable After Correction
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Input
                                                                id="final3"
                                                                name="final"
                                                                type="radio"
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setFinalOne(false);
                                                                        setFinalTwo(false);
                                                                        setFinalThree(true);
                                                                    }

                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={'Not Acceptable'}
                                                                checked={finalThree}
                                                            />

                                                            <Label check for="final3">
                                                                Not Acceptable
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </Col>

                                                <Col sm={10}>
                                                    <div className="mb-3">
                                                        <Label
                                                            htmlFor="final_comment"
                                                            className="form-Label"
                                                        >
                                                            Comment
                                                        </Label>
                                                        <InputField
                                                            type="textarea"
                                                            placeholder=""
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('final_comment')}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col sm={12} className="mt-3">
                                                    <FormGroup className="mb-3 text-center">
                                                        <Button
                                                            type="reset"
                                                            text="Reset"
                                                            className="btn btn-warning me-2"
                                                            onClick={(e) => {
                                                                handleFormReset();
                                                            }}
                                                        ></Button>
                                                        <Button
                                                            type="submit"
                                                            text="Submit"
                                                            className="btn btn-success me-2"
                                                        ></Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </React.Fragment>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        </>
    );
};

export default AddDissertationReviewerComment;
