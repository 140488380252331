import { Lookup } from 'src/pages/Setup/Lookup';
import { LookupItem } from 'src/pages/Setup/Lookup/LookupItem';

export const lookupRoutes = [
  {
    path: '/lookup/',
    component: Lookup,
  },
  {
    path: '/setup/setup-lookup-info/:uuid',
    component: LookupItem,
  },
];
