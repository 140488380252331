import { apiSlice } from 'src/rtk/api/apiSlice';
import * as trainingRecordUrl from 'src/helpers/url_helper';

export const trainingRecordlApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['TrainingRecords'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTrainingRecord: builder.query<TrainingRecord, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${trainingRecordUrl.GET_TRAINING_RECORD}/${id}`,
        }),
        providesTags: ['TrainingRecords'],
      }),

      getSupervisors: builder.query<
        APIResponse<Supervisors[]>,
        void
      >({
        query: () => ({
          method: 'GET',
          url: `${trainingRecordUrl.GET_SUPERVISORS}`,
        }),
        providesTags: ['TrainingRecords'],
      }),

      getSupervisorsByInstitute: builder.query<APIResponse<SupervisorByInstitute[]>, string>({
        query: (name: string) => ({
          method: 'GET',
          url: `${trainingRecordUrl.GET_SUPERVISORS_BY_INSTITUTE}/${name}`,
        }),
        providesTags: ['TrainingRecords'],
      }),


      createTrainingRecord: builder.mutation<
        APIResponse<TrainingRecord>,
        any
      >({
        query: (data: any) => ({
          url: `${trainingRecordUrl.CREATE_TRAINING_RECORD}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['TrainingRecords'],
      }),

      updateTrainingRecord: builder.mutation<
        APIResponse<TrainingRecord>,
        any
      >({
        query: (url) => ({
          url: `${trainingRecordUrl.UPDATE_TRAINING_RECORD}/${url.id}`,
          method: 'POST',
          body: url.data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['TrainingRecords'],
      }),
      deleteTrainingRecord: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${trainingRecordUrl.DELETE_TRAINING_RECORD}/${id}`,
        }),
        invalidatesTags: ['TrainingRecords'],
      }),
    }),
  });

export const {
  useGetTrainingRecordQuery,
  useGetSupervisorsByInstituteQuery,
  useGetSupervisorsQuery,
  useCreateTrainingRecordMutation,
  useUpdateTrainingRecordMutation,
  useDeleteTrainingRecordMutation,
} = trainingRecordlApi;
