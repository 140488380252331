import { DocumentFileUploads } from "src/pages/Setup/DocumentFileUpload";
import { AddDocumentFileUpload } from "src/pages/Setup/DocumentFileUpload/AddDocumentFileUpload";
import { EditDocumentFileUpload } from "src/pages/Setup/DocumentFileUpload/EditDocumentFileUpload";


export const documentFileUploadSetupRoutes = [
  // setup faculty info
  { path: '/setup/document-file-upload', component: DocumentFileUploads },
  { path: '/setup/document-file-upload/add', component: AddDocumentFileUpload },
  {
    path: '/setup/document-file-upload/update/:id',
    component: EditDocumentFileUpload,
  },
];
