import { useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import toast from "react-hot-toast";
import { Label, Modal } from "reactstrap";
import { InputField } from "src/components";

const EditSignatories = (props: any) => {
  const { userList, signatoryInputList, setInputList } = props;

  const signatoryUserOptions: SelectOption[] = userList?.map((item: any) => ({
    value: item.id ?? '',
    label: item.name ?? '',
  }));

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [signatoryIndex, setSignatoryIndex] = useState<number>();
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const [selectedSignatorySerial, setSelectedSignatorySerial] = useState<number>();

  const handleUserInputChange = (event: any) => {
    const { name, value } = event.target;
    setSelectedUserId(value);
  };

  const handleSignatorySerialInputChange = (event: any) => {
    const { name, value } = event.target;
    setSelectedSignatorySerial(value);
  };

  const handleEditSignatory = (index: number) => {
    setModalShow(true);
    setSignatoryIndex(index);
    setSelectedUserId(signatoryInputList[index]?.user_id);
    setSelectedSignatorySerial(signatoryInputList[index]?.signatory_serial);
  };

  const handleSignatorySubmit = (e: any, index: number) => {
    if (selectedUserId) {
      signatoryInputList[index].user_id = +selectedUserId;
    }

    if(selectedSignatorySerial) {
      signatoryInputList[index].signatory_serial = +selectedSignatorySerial;
    }

    setModalShow(false);
  };

  console.log(signatoryInputList);

  const columns: TableColumn<any>[] = [
    {
      name: <div className="text-center">Sl. No.</div>,
      width: '100px',
      cell: (row, index) => index + 1,
      center: true,
    },

    {
      name: <div className="text-center">User</div>,
      selector: (row) => userList?.find((u: any) => u.id === row.user_id)?.name,
      sortable: false,
      wrap: true,
      center: true,
    },

    {
      name: <div className="text-center">Signatory Serial</div>,
      selector: (row) => row.signatory_serial,
      sortable: false,
      wrap: true,
      center: true,
    },

    {
      name: <div className="text-center">Action</div>,
      width: '150px',
      cell: (row) => (
        <>
          <button
            type="button"
            className={'btn btn-secondary mb-2'}
            onClick={() => handleEditSignatory(signatoryInputList?.indexOf(row))}
          >
            <i className="fa fa-edit" />
          </button>
        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <>
      {signatoryInputList?.length > 0 &&
        <div className="row mb-5">
          <div className="col-sm-12">
            <DataTable
              columns={columns}
              data={signatoryInputList?.length > 0 ? signatoryInputList : []}
              expandOnRowClicked
              highlightOnHover
            />
          </div>
        </div>}

      <Modal
        onOpened={function () {
          toast.dismiss();
        }}
        isOpen={modalShow}
        scrollable={true}
        size="md"
        toggle={() => {
          document.body.classList.add('no_padding');
        }}
        backdrop={'static'}
        modalTransition={{ timeout: 0 }}
        backdropTransition={{ timeout: 0 }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Edit Signatory
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalShow(!modalShow);
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-sm-5">
              <Label className='form-label'>User</Label>
              <InputField
                type="select"
                className="form-control"
                name="userId"
                options={signatoryUserOptions}
                value={selectedUserId}
                onChange={(event) => handleUserInputChange(event)}
              />
            </div>
            <div className="col-sm-5">
              <Label className='form-label'>Signatory Serial</Label>
              <InputField
                type="number"
                className="form-control"
                name="signatorySerial"
                value={selectedSignatorySerial}
                onChange={(event) => handleSignatorySerialInputChange(event)}
              />
            </div>

            <div className="col-sm-12">
              <a
                className="me-2 p-0 center"
                rel="noreferrer"
                target="_blank"
                onClick={(e) => {
                  handleSignatorySubmit(e, signatoryIndex!);
                }}
              >
                <span className="btn-success btn mt-3">Submit</span>
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditSignatories;
