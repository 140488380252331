import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  pdf
} from '@react-pdf/renderer';
import Html from 'react-pdf-html';

interface Props {
  data: any;
  trainingSummaryId: string;
  setTrainingSummaryId: any;
}
// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 0,
  },
  nextLine: {
    marginTop: -20,
    textAlign: 'center',
    fontSize: '10px',
  },
});

// Create Document Component
// const MyDocument = () => (
//   <Document>
//     <Page size="A4" style={styles.page}>
//       <View style={styles.section}>
//         <Text>Section #1</Text>
//       </View>
//       <View style={styles.section}>
//         <Text>Section #2</Text>
//       </View>
//     </Page>
//   </Document>
// );

export const SummaryReports = (props: Props) => {
  const { data, trainingSummaryId, setTrainingSummaryId } = props;
  // console.log(data);
  let pdfUrl = '';

  const html = `  
  <html>    
    <body>
        <style>                    

        table td {
                    
        }

        table th {
          text-align: center;         
        }

        table {
          margin: 3px 0px;
          padding: 0px;
          border: 1px solid black;
          font-size: 12px;
        }

        table > tbody> tr > *{
          padding: 4.5px
        }
        
        .slNo {
          border-left: 1px solid black;
        }

        th {
          font-family: 'Times-Bold';          
        }

        .cardiovascular {
          border-left: 1px solid black;
          background-color: rgb(192, 192, 192);
        }

        .procedural {
          border-left: 1px solid black;          
        }

        .colWidth {
          min-width: 300px;
        }

      </style>

      <div>        
        <table>
          <thead>
            <tr>
              <th class='cardiovascular'>
                <p>${data?.particulars}</p>
              </th>
            </tr>
            <tr>
              <th class='procedural'>
                ${data?.entry_setup_master_for_log![1]?.particulars}
              </th>
            </tr>
            <tr>
              <th>
                Sl. No.
              </th>
              <th style="min-width: 300px;">
                Skill
              </th>
              <th>
                Level of competence
              </th>
              <th>
                Minimum number required to be performed
              </th>
              <th>
                Total number performed
              </th>
            </tr>
          </thead>
          <tbody>
            ${data?.entry_setup_master_for_log![1]?.entry_setup_master_for_log?.map((item: any, index: number) => (
    `<tr>
                <td>
                  ${++index}
                </td>
                <td style="min-width: 310px;">
                  ${item?.particulars}
                </td>
                <td>
                  ${item?.log_entry_target[0]!?.compitency_level || 'N/A'}
                </td>
                <td>
                  ${item?.log_entry_target[0]!?.min_value}
                </td>
                <td>
                  ${item?.total}
                </td>
              </tr>`
  )).join('')
    }
            <tr>
              <th class='procedural'>
                ${data?.entry_setup_master_for_log![0]?.particulars}
              </th>
            </tr>
            <tr>
              <th>
                SL. No.
              </th> 
              <th class='colWidth'>
                Skill
              </th>           
              <th>
                Minimum number to be performed
              </th>
              <th>
                Total number performed
              </th>
            </tr>
            ${data?.entry_setup_master_for_log![0]?.entry_setup_master_for_log?.map((item: any, index: number) => (
      `<tr>
                <td>
                  ${++index}
                </td>
                <td class='colWidth'>
                  ${item?.particulars}
                </td>
                <td>
                  ${item?.log_entry_target![0]?.min_value}
                </td>
                <td>
                  ${item?.total}
                </td>
              </tr>`
    )).join('')
    }
          </tbody>
        </table>
      </div>      
    </body>
  </html>
`;

  // const MyDocument = (
  //   <Document>
  //     <Page size="A4" style={styles.page}>
  //       <View style={styles.section}>
  //         <Text>Section #1</Text>
  //       </View>
  //       <View style={styles.section}>
  //         <Text>Section #2</Text>
  //       </View>
  //     </Page>
  //   </Document>
  // );

  const MyDocument = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.row}>
          <Image
            src="/images/apple-icon-180x180.png"
            style={{
              width: '40px',
              marginTop: '20px',
              marginBottom: '0px',
              marginLeft: '10px',
            }}
          />
          <Text
            style={{
              textAlign: 'center',
              fontFamily: 'Times-Bold',
              fontSize: '14px',
              margin: '20px',
              marginBottom: '0px',
            }}
          >
            BANGLADESH COLLEGE OF PHYSICIANS AND SURGEONS (BCPS)
          </Text>
        </View>
        <Text style={styles.nextLine}>
          67, Shaheed Tajuddin Ahmed Sarani, Mohakhali, Dhaka-1212
        </Text>
        <Text
          style={{
            textAlign: 'center',
            fontFamily: 'Helvetica-Bold',
            fontSize: '14px',
            margin: '20px',
          }}
        >
          Summary of Procedural and Interpretative Skills
        </Text>
        <Html>{html}</Html>
      </Page>
    </Document>
  );

  if (trainingSummaryId) {
    const pdfData = pdf(MyDocument).toBlob();
    pdfData.then((pdfData) => {
      const pdfContent = new Blob([pdfData], { type: 'application/pdf' });
      const url = URL.createObjectURL(pdfContent);
      pdfUrl = url;
      window.open(pdfUrl, '_blank');
      setTrainingSummaryId('');
    });
  }

  return null;
};
