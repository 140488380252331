import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { InputField } from 'src/components';
import { useCreateSolutionEntryMutation } from 'src/rtk/features/researchClinicSetup/researchClinicSetupApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

interface Props {
  info: any;
}
interface Props {
  isOpen: boolean;
  toggle: any;
  info: any;
}
const initialValues = {
  answer: '',
};

const SolutionEntryByConsultantModal = (props: Props) => {

  const { isOpen, toggle, info } = props;

  const [createSolutionEntry, { isSuccess, isError, error, isLoading: isCreateLoading }] = useCreateSolutionEntryMutation();

  const err = error as CustomError;

  const {
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    handleChange,
    getFieldProps,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      answer: Yup.string().required('Enter Your Answer'),
    }),
    onSubmit: (values) => {
      console.log(values);
      createSolutionEntry({ data: values, id: info?.id });
    },
  });


  useEffect(() => {
    if (info?.answer && info?.answer !== null) {
      setFieldValue('answer', info?.answer);
    }
  }, [info]);

  useEffect(() => {
    if (isSuccess) {
      toast.success('Answer Submitted Successfully!');
      toggle();
    }
    if (isError) {
      toast.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  return (
    <Modal
      isOpen={isOpen}
      backdrop={'static'}
      toggle={toggle}
      size="lg"
    >
      {isCreateLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <ModalHeader toggle={toggle}>Solution Entry</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <ModalBody>
          <div>
            <h6 className="block-heading">Trainee Information</h6>
            <div className="row">
              <div className="col-md-12">
                <table className="table table-bordered table-striped table-sm">
                  <tbody>
                    <tr>
                      <td className="col-md-6 col-lg-6">
                        <strong>Trainee Name: </strong>
                        {info?.thesis_title?.registration?.personal_info?.full_name}
                      </td>
                      <td className="col-md-6 col-lg-6">
                        <strong>BMDC Reg. No: </strong>
                        {info?.thesis_title?.registration?.bmdc_registration_no}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Date of Birth: </strong>
                        {info?.thesis_title?.registration?.personal_info?.date_of_birth ?? 'N/A'}
                      </td>
                      <td>
                        <strong>NID: </strong>
                        {info?.thesis_title?.registration?.personal_info?.national_id ?? 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Mobile Number: </strong>
                        {info?.thesis_title?.registration?.personal_info?.mobile ?? 'N/A'}
                      </td>
                      <td>
                        <strong>Email: </strong>
                        {info?.thesis_title?.registration?.personal_info?.email ?? 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Alternative Number: </strong>
                        {info?.thesis_title?.registration?.personal_info?.telephone ?? 'N/A'}
                      </td>
                      <td>
                        <strong>Subject: </strong>
                        {info?.thesis_title?.registration?.subject?.subject_name ?? 'N/A'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* <h6 className="block-heading">Thesis Title Details</h6>
            <div className="row">
              <div className='col-md-12'>
                <table className="table table-bordered table-striped table-sm">
                  <tbody>
                    <tr>
                      <td className="col-md-6 col-lg-6">
                        <strong>Category: </strong>
                        {info?.category}
                      </td>
                      <td className="col-md-6 col-lg-6">
                        <strong>Title: </strong>
                        {info?.title_of_thesis}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Place Of Study: </strong>
                        {info?.place_of_study ?? 'N/A'}
                      </td>
                      <td>
                        <strong>Study Period: </strong>
                        {info?.study_period + ' ' + info?.study_period_unit ?? 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Guide: </strong>
                        {info?.guide_name ?? 'N/A'}
                      </td>
                      <td>
                        <strong>Guide Approval Note: </strong>
                        {info?.guide_approval_note ?? 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Guide Approval Status: </strong>
                        {info?.guide_approval_status ?? 'N/A'}
                      </td>
                      <td>

                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Co-Guide </strong>
                      </td>
                      <td>
                        {info?.co_guide_1_name &&
                          <div className='row card mx-1 my-1'>
                            <div className="col">
                              <strong>Name:</strong>  {info?.co_guide_1_name} <br />
                              <strong>Note:</strong>  {info?.co_guide1_approval_note} <br />
                              <strong>Approval Status:</strong>  {info?.co_guide1_approval_status}
                            </div>
                          </div>
                        }
                        {info?.co_guide_2_name &&
                          <div className='row card mx-1 my-1'>
                            <div className="col">
                              <strong>Name:</strong> {info?.co_guide_2_name} <br />
                              <strong>Note:</strong> {info?.co_guide2_approval_note} <br />
                              <strong>Approval Status:</strong> {info?.co_guide2_approval_status}
                            </div>
                          </div>
                        }
                        {info?.co_guide_3_name &&
                          <div className='row card mx-1 my-1'>
                            <div className="col">
                              <strong>Name:</strong> {info?.co_guide_3_name} <br />
                              <strong>Note:</strong> {info?.co_guide3_approval_note} <br />
                              <strong>Approval Status:</strong> {info?.co_guide3_approval_status}
                            </div>
                          </div>
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}
            <h6 className="block-heading">Inquiry</h6>
            <div className="row">
              <div className="col-md-12">
                <table className="table table-bordered table-striped table-sm">
                  <tbody>
                    <tr>
                      <td>
                        <strong>Inquiry Type: </strong>{info?.inquiry_type?.inquiry_type}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Inquiry: </strong>{info?.query}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <h6 className="block-heading">Solution Entry</h6>
            <div className='row'>
              <div className='col-md-12 col-sm-12'>
                <InputField
                  type='textarea'
                  placeholder='Enter Your Solution'
                  readOnly={info?.answer}
                  invalid={{ errors, touched }}
                  {...getFieldProps('answer')}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex gap-2 ">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={info?.answer}
            >
              Save
            </button>

            <Button color="danger" onClick={toggle}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default SolutionEntryByConsultantModal;
