import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row
} from 'reactstrap';
import { Button } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useDeleteThesisDissertationFormSetupMutation, useGetThesisDissertationFormSetupsQuery } from 'src/rtk/features/setup/thesisEntryFormSetup/thesisEntryFormSetupApi';
import loader from '../../../assets/images/loader.gif';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';

const ThesisEntrySetupFormList = () => {
    document.title = 'Thesis Entry Setup Form List | BCPS';
    const history = useHistory();

    const [setupId, setSetupId] = useState<string>('');
    const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);

    const {
        data: thesisDissertationForms,
        error: thesisDissertationFormsError,
        isSuccess: isThesisDissertationFormsSuccess,
        isLoading: isThesisDissertationFormsLoading,
        isError: isThesisDissertationFormsError
    } = useGetThesisDissertationFormSetupsQuery();

    const thesisDissertations = thesisDissertationForms?.data || [];

    const [deleteThesisDissertationFormSetup, { isSuccess: isDeleteThesisDissertationFormSetupSuccess, isLoading: isDeleteThesisDissertationFormSetupLoading, isError: isDeleteThesisDissertationFormSetupError }] =
        useDeleteThesisDissertationFormSetupMutation();

    const deleteHandler = (modalState: boolean, id: string) => {
        setDeleteModalShow(modalState);
        setSetupId(id);
    };

    useEffect(() => {
        if (isDeleteThesisDissertationFormSetupSuccess) {
            toast.success('Thesis/dissertation entry form deleted successfully');
        }
        if (isDeleteThesisDissertationFormSetupError) {
            toast.error('Error deleting thesis/dissertation entry form');
        }
    }, [isDeleteThesisDissertationFormSetupSuccess, isDeleteThesisDissertationFormSetupError]);

    const columns: TableColumn<any>[] = [
        {
            name: <div style={{ textAlign: 'center' }}>#</div>,
            width: '80px',
            cell: (row, index) => index + 1,
            center: true,
        },

        {
            name: <div style={{ textAlign: 'center' }}>Column Name</div>,
            selector: (row) =>
                row.column_name || '-',
            sortable: false,            
            wrap: true,
        },

        {
            name: <div style={{ textAlign: 'center' }}>Data Type</div>,
            selector: (row) => row.data_type || '-',
            sortable: false,
            center: true,
            wrap: true,
        },

        {
            name: <div style={{ textAlign: 'center' }}>Parent Column Name</div>,
            selector: (row) => thesisDissertations?.find((t: SetupThesisEntryForm) => t.id === row.parent_id)?.column_name || '-',
            sortable: false,
            wrap: true,            
        },

        {
            name: <div style={{ textAlign: 'center' }}>Serial No.</div>,
            selector: (row) => row.sl_no || '-',
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div style={{ textAlign: 'center' }}>Has File?</div>,
            selector: (row) => row.has_file ? 'Yes' : 'No',
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div style={{ textAlign: 'center' }}>Publish Status</div>,
            selector: (row) => row.publish_status ? 'Yes' : 'No',
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div style={{ textAlign: 'center' }}>Required</div>,
            selector: (row) => row.required_ind ? 'Yes' : 'No',
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div style={{ textAlign: 'center' }}>Has Input Field?</div>,
            selector: (row) => row.input_ind ? 'Yes' : 'No',
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div style={{ textAlign: 'center' }}>Action</div>,
            width: '150px',
            cell: (row) => (
                <>
                    <div className="d-flex gap-2">
                        <Button
                            size="sm"
                            text="Edit"
                            color="warning"
                            onClick={() => {
                                history.push(`/setup/thesis-dissertation/update/${row.id}`);
                            }}
                        />

                        <Button
                            size="sm"
                            text="Delete"
                            color="danger"
                            disabled={isDeleteThesisDissertationFormSetupLoading}
                            isLoading={isDeleteThesisDissertationFormSetupLoading}
                            onClick={() => deleteHandler(true, row.id!)}
                        />
                    </div>
                    {deleteModalShow && setupId === row.id ? (
                        <DeleteInformationModal
                            isOpen={deleteModalShow}
                            toggle={() => setDeleteModalShow(!deleteModalShow)}
                            id={setupId}
                            deleteInfomation={deleteThesisDissertationFormSetup}
                        />
                    ) : null}
                </>
            ),

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true,
            wrap: true,
        },
    ];

    return (
        <div className="page-content">
            {isThesisDissertationFormsLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Container fluid>
                <BetterBreadcrumb
                    title={'Thesis/Dissertation Entry Form List'}
                    breadcrumbItem={[{ link: '/setup/thesis-dissertation', name: 'Thesis/Dissertation Setup List' }]}
                />
                <Card className="mb-5">
                    <CardHeader className="d-flex justify-content-end">
                        <Row>
                            <Col>
                                <div className="flex-shrink-0 align-self-end">
                                    <Link
                                        to="/setup/thesis-dissertation/add"
                                        className="btn btn-success btn-sm waves-effect waves-light p-2"
                                    >
                                        <i className="fa fa-plus me-2" /> Add Thesis/Dissertation Entry Form
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <DataTable
                            columns={columns}
                            data={thesisDissertations ? thesisDissertations : []}
                            progressPending={isThesisDissertationFormsLoading}
                            persistTableHead={true}
                            expandOnRowClicked
                            highlightOnHover
                        />
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default ThesisEntrySetupFormList;
