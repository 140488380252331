import { FormikErrors, FormikTouched } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
// import moment from 'moment';
import Select from 'react-select';
import { FormFeedback, Input, Label } from 'reactstrap';
import { getSelectFormatedData } from 'src/helpers';
import {
  useGetByDataQuery,
  useGetDataQuery,
} from 'src/rtk/features/common/crudApi';

interface Props {
  name: string;
  className?: string;
  placeholder?: string;
  type: InputType;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  value?: any;
  options?: any;
  readOnly?: boolean;
  invalid?: {
    errors: FormikErrors<any>;
    touched: FormikTouched<any>;
  };
  [x: string]: any;
}

const isChecked = (value: any, option: any) => {
  if (value) {
    if (typeof value === 'object') {
      return value.includes(option.value.toString().trim());
    } else {
      return (
        value.toString().trim().toLowerCase() ===
        option.value.toString().trim().toLowerCase()
      );
    }
  }
};

const DaynamicSearchInputField = ({
  label,
  dataApi,
  name,
  className,
  placeholder,
  type,
  onChange,
  onBlur,
  value,
  options = [],
  invalid,
  isMulti = false,
  required = false,
  readOnly,
  setData,
  ...rest
}: Props) => {
  const { errors, touched } = invalid || {};
  const error = errors ? errors[name] : '';
  const isTouched = touched ? touched[name] : false;
  const _invalid = error && isTouched ? true : false;

  const [optionsListLoading, setOptionsListLoading] = useState<boolean>(false);

  if (isMulti && typeof value === 'string') {
    value = [];
  }

  if (dataApi && dataApi.url) {
    if (typeof dataApi.url === 'object') {
      const {
        data: optionsListData,
        isLoading: optionsListDataLoading,
        isSuccess: optionsListDataLoadingSuccess,
        error: optionsListDataLoadingError,
      } = useGetByDataQuery(dataApi.url);

      options = optionsListData?.data
        ? getSelectFormatedData(
          optionsListData?.data,
          dataApi?.key,
          dataApi?.label,
          dataApi?.label2,
        )
        : [];

      useEffect(() => {
        if (optionsListDataLoadingSuccess) {
          setOptionsListLoading(false);
        }
        if (optionsListDataLoadingError) {
          setOptionsListLoading(false);
        }
        if (optionsListDataLoading) {
          setOptionsListLoading(true);
        }
      }, [
        optionsListDataLoading,
        optionsListDataLoadingSuccess,
        optionsListDataLoadingError,
      ]);
    } else {
      const {
        data: optionsListData,
        isLoading: optionsListDataLoading,
        isSuccess: optionsListDataLoadingSuccess,
        error: optionsListDataLoadingError,
      } = useGetDataQuery(dataApi.url);

      options = optionsListData?.data
        ? getSelectFormatedData(
          optionsListData?.data,
          dataApi?.key,
          dataApi?.label,
          dataApi?.label2,
        )
        : [];

      useEffect(() => {
        if (optionsListDataLoadingSuccess) {
          setOptionsListLoading(false);
        }
        if (optionsListDataLoadingError) {
          setOptionsListLoading(false);
        }
        if (optionsListDataLoading) {
          setOptionsListLoading(true);
        }
      }, [
        optionsListDataLoading,
        optionsListDataLoadingSuccess,
        optionsListDataLoadingError,
      ]);
    }
  }

  let options2: any = [];
  if (type === 'select' && options?.length > 0) {
    options2 = [{ value: '', label: 'Select...' }, ...options];
  }

  return (
    <React.Fragment>
      {label ? (
        <Label
          className={
            required
              ? 'form-label me-2 required-field text-capitalize'
              : 'form-label me-2 text-capitalize'
          }
        >
          {label}
        </Label>
      ) : (
        ''
      )}

      {type === 'date' && (
        <React.Fragment>
          <Flatpickr
            name={name}
            className="form-control d-block"
            placeholder="YYYY-MM-DD"
            options={options}
            onChange={(data: any) => {
              let dFormate = options?.retDateFormat || 'YYYY-MM-DD';
              if (data.length) {
                let retDate: any = [];
                data.map((d: any) => {
                  retDate.push(moment(d).format(dFormate));
                });
                if (options?.mode === 'range' && retDate.length === 2) {
                  setData(retDate);
                } else if (options?.mode !== 'range') {
                  setData(retDate);
                }
              }
            }}
          />
          <Input
            name={name + '-2'}
            className={`form-control ${className}`}
            placeholder={placeholder}
            type={'text'}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            invalid={_invalid}
            readOnly={readOnly}
            {...rest}
            style={{ height: 0, opacity: 0, display: 'none' }}
          ></Input>
        </React.Fragment>
      )}

      {type === 'select' && (
        <React.Fragment>
          <Select
            name={name}
            isMulti={isMulti}
            isLoading={optionsListLoading}
            isClearable={true}
            isSearchable={true}
            className={`${className}`}
            placeholder={placeholder}
            type={type}
            options={options}
            onChange={onChange}
            onBlur={onBlur}
            value={options?.filter(function (option: any) {
              if (value) {
                if (typeof value === 'object') {
                  return value.includes(option.value);
                } else {
                  return (
                    value.toString().trim().toLowerCase() ===
                    option.value.toString().trim().toLowerCase()
                  );
                }
              }
            })}
            invalid={_invalid}
            // required={required}
            readOnly={readOnly}
            {...rest}
          />
          <Input
            name={name + '-2'}
            className={`form-control hidden ${className}`}
            placeholder={placeholder}
            type={'text'}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            multiple={isMulti}
            invalid={_invalid}
            readOnly={readOnly}
            {...rest}
            style={{ height: 0, opacity: 0, display: 'none' }}
          >
            {/* {options2.map((option: any, index: any) => (
              <React.Fragment key={Math.random()}>
                <option className="form-control" value={option.value}>
                  {option.label}
                </option>
              </React.Fragment>
            ))} */}
          </Input>
        </React.Fragment>
      )}

      {['radio', 'checkbox'].includes(type) && (
        <React.Fragment>
          {options.map((option: any, index: any) => (
            <React.Fragment key={type + name + index}>
              <Input
                className="me-2"
                name={name}
                type={type}
                id={type + name + index}
                // switch="success"
                onChange={onChange}
                onBlur={onBlur}
                value={option.value.toString()}
                invalid={_invalid}
                readOnly={readOnly}
                defaultChecked={isChecked(value, option)}
                {...rest}
              />
              <Label
                className="form-label me-2 text-capitalize"
                htmlFor={type + name + index}
              >
                {option.label}
              </Label>
            </React.Fragment>
          ))}
        </React.Fragment>
      )}

      {!['select', 'radio', 'checkbox', 'date'].includes(type) && (
        <React.Fragment>
          <Input
            key={name + type}
            name={name}
            className={`form-control ${className}`}
            placeholder={placeholder}
            type={type}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            invalid={_invalid}
            readOnly={readOnly}
            {...rest}
          ></Input>
        </React.Fragment>
      )}

      {_invalid ? (
        <FormFeedback type="invalid">{error as any}</FormFeedback>
      ) : null}
    </React.Fragment>
  );
};

export default DaynamicSearchInputField;
