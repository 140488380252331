import { useFormik } from "formik";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { Button, InputField } from "src/components";

const InstituteRegistration = () => {
  const { values, errors, touched, handleChange } = useFormik({
    initialValues: {
      instituteName: "",
      instituteStatus: "",
      instituteType: "",
      hasBMDCRecognition: "",
      BMDCRecognitionDocs: "",
      hasDGHSLicense: "",
      DGHSLicenseNumber: "",
      hasAffiliationUniversity: "",
      affiliationUniversity: "",

      totalBeds: "",
      totalPayingBeds: "",
      totalNonPayingBeds: "",
      totalCabins: "",

      hasEmergencyService: "",
      hasICU: "",
      totalICUBeds: "",
      totalVentilators: "",
      totalICUDoctors: "",
      totalICUNurses: "",

      hasCCU: "",
      presentAccreditationStatus: "",
      departmentName: "",
      BCPSTrainingPeriod: "",
      accreditationValidTill: "",

      email: "",
      phone: "",
      division: "",
      district: "",
      policeStation: "",
      address: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <div className="page-content">
      <Container fluid>
        <h5 className="mb-3">Training Accreditation</h5>
        <Row>
          <FormGroup className="col-sm-12 col-md-6 mb-3">
            <Label for="instituteName">Institute Name</Label>
            <InputField
              type="text"
              name="instituteName"
              placeholder="Enter Institute Name"
              value={values.instituteName}
              invalid={{ errors, touched }}
            />
          </FormGroup>
          <div className="col-sm-12 col-md-6 mb-3">
            <h5 className="font-size-14 mb-3">Status of the Institute</h5>
            <div className="d-inline-block mx-2">
              <div className="form-check form-check-left mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="institutionStatus"
                  defaultChecked
                />
                <label className="form-check-label">Government</label>
              </div>
            </div>

            <div className="d-inline-block mx-2">
              <div className="form-check form-check-left">
                <input
                  className="form-check-input"
                  type="radio"
                  name="institutionStatus"
                />
                <label className="form-check-label">Non-Government</label>
              </div>
            </div>

            <div className="d-inline-block mx-2">
              <div className="form-check form-check-left">
                <input
                  className="form-check-input"
                  type="radio"
                  name="institutionStatus"
                />
                <label className="form-check-label">Trust</label>
              </div>
            </div>
            <div className="d-inline-block mx-2">
              <div className="form-check form-check-left">
                <input
                  className="form-check-input"
                  type="radio"
                  name="institutionStatus"
                />
                <label className="form-check-label">Foundation</label>
              </div>
            </div>
            <div className="d-inline-block mx-2">
              <div className="form-check form-check-left">
                <input
                  className="form-check-input"
                  type="radio"
                  name="institutionStatus"
                />
                <label className="form-check-label">Ltd. Co</label>
              </div>
            </div>
            <div className="d-inline-block mx-2">
              <div className="form-check form-check-left">
                <input
                  className="form-check-input"
                  type="radio"
                  name="institutionStatus"
                />
                <label className="form-check-label">Others</label>
              </div>
            </div>
          </div>
          <FormGroup className="col-sm-12 col-md-6 mb-3">
            <div className="mb-3">
              <h5 className="font-size-14 mb-3">A Recognition of BMDC</h5>
              <div className="d-inline-block mx-2">
                <div className="form-check form-check-left mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="BMDCRecognition"
                    defaultChecked
                  />
                  <label className="form-check-label">Recognized</label>
                </div>
              </div>

              <div className="d-inline-block mx-2">
                <div className="form-check form-check-left">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="BMDCRecognition"
                  />
                  <label className="form-check-label">Not Recognized</label>
                </div>
              </div>

              <div className="d-inline-block mx-2">
                <div className="form-check form-check-left">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="BMDCRecognition"
                  />
                  <label className="form-check-label">Not Applicable</label>
                </div>
              </div>
            </div>
          </FormGroup>

          <FormGroup className="col-sm-12 col-md-6 mb-3">
            <Label for="BMDCRecognitionDocs">Upload Supporting Documents</Label>
            <InputField
              type="file"
              name="BMDCRecognitionDocs"
              placeholder="Upload Supporting Documents"
              value={values.BMDCRecognitionDocs}
              invalid={{ errors, touched }}
            />
          </FormGroup>
          {/* DGHSLicense */}
          <FormGroup className="col-sm-12 col-md-6 mb-3">
            <div className="mb-3">
              <h5 className="font-size-14 mb-3">License by DGHS</h5>
              <div className="d-inline-block mx-2">
                <div className="form-check form-check-left mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="hasDGHSLicense"
                    defaultChecked
                  />
                  <label className="form-check-label">Yes</label>
                </div>
              </div>

              <div className="d-inline-block mx-2">
                <div className="form-check form-check-left">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="hasDGHSLicense"
                  />
                  <label className="form-check-label">No</label>
                </div>
              </div>

              <div className="d-inline-block mx-2">
                <div className="form-check form-check-left">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="hasDGHSLicense"
                  />
                  <label className="form-check-label">Not Applicable</label>
                </div>
              </div>
            </div>
          </FormGroup>

          <FormGroup className="col-sm-12 col-md-6 mb-3">
            <Label for="DGHSLicenseNumber">License Number</Label>
            <InputField
              type="text"
              name="DGHSLicenseNumber"
              placeholder="License Number"
              value={values.DGHSLicenseNumber}
              invalid={{ errors, touched }}
            />
          </FormGroup>
          {/* Affiliation  */}
          <FormGroup className="col-sm-12 col-md-6 mb-3">
            <div className="mb-3">
              <h5 className="font-size-14 mb-3">Affiliation with University</h5>
              <div className="d-inline-block mx-2">
                <div className="form-check form-check-left mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="hasAffiliationUniversity"
                    defaultChecked
                  />
                  <label className="form-check-label">Yes</label>
                </div>
              </div>

              <div className="d-inline-block mx-2">
                <div className="form-check form-check-left">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="hasAffiliationUniversity"
                  />
                  <label className="form-check-label">No</label>
                </div>
              </div>

              <div className="d-inline-block mx-2">
                <div className="form-check form-check-left">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="hasAffiliationUniversity"
                  />
                  <label className="form-check-label">Not Applicable</label>
                </div>
              </div>
            </div>
          </FormGroup>
          <FormGroup className="col-sm-12 col-md-6 mb-3">
            <Label for="DGHSLicenseNumber">Name Of the University</Label>
            <InputField
              type="text"
              name="affiliationUniversity"
              placeholder="University Name"
              value={values.affiliationUniversity}
              invalid={{ errors, touched }}
            />
          </FormGroup>
        </Row>
        <hr />
        <Row>
          {/* Hospital Bed Info */}
          <h5 className="mb-3">Information about Hospital Bed</h5>
          <FormGroup className="col-sm-12 col-md-6 mb-3">
            <Label for="totalBed">Number of Bed of the institute</Label>
            <InputField
              type="number"
              name="totalBeds"
              placeholder="Total Beds"
              onChange={handleChange}
              value={values.totalBeds}
              invalid={{ errors, touched }}
            />
          </FormGroup>
          <FormGroup className="col-sm-12 col-md-6 mb-3">
            <Label for="totalBed">Number of Paying Beds</Label>
            <InputField
              type="number"
              name="totalPayingBeds"
              placeholder="Total Paying Beds"
              onChange={handleChange}
              value={values.totalPayingBeds}
              invalid={{ errors, touched }}
            />
          </FormGroup>

          <FormGroup className="col-sm-12 col-md-6 mb-3">
            <Label for="totalBed">Number of Non-Paying Beds</Label>
            <InputField
              type="number"
              name="totalNonPayingBeds"
              placeholder="Total Non-Paying Beds"
              onChange={handleChange}
              value={values.totalNonPayingBeds}
              invalid={{ errors, touched }}
            />
          </FormGroup>
          <FormGroup className="col-sm-12 col-md-6 mb-3">
            <Label for="totalBed">Number of Cabins</Label>
            <InputField
              type="number"
              name="totalCabins"
              placeholder="Total Cabins"
              onChange={handleChange}
              value={values.totalCabins}
              invalid={{ errors, touched }}
            />
          </FormGroup>
          {/* Emergency Services */}
          <FormGroup className="col-sm-12 col-md-6 mb-3">
            <div className="mb-3">
              <h5 className="font-size-14 mb-3">
                Availability of Emergency Services
              </h5>
              <div className="d-inline-block mx-2">
                <div className="form-check form-check-left">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="hasEmergencyService"
                  />
                  <label className="form-check-label">Yes</label>
                </div>
              </div>
              <div className="d-inline-block mx-2">
                <div className="form-check form-check-left">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="hasEmergencyService"
                  />
                  <label className="form-check-label">No</label>
                </div>
              </div>
            </div>
          </FormGroup>
          {/* ICU Services */}
          <FormGroup className="col-sm-12 col-md-6 mb-3">
            <div className="mb-3">
              <h5 className="font-size-14 mb-3">
                Availability of ICU Services
              </h5>
              <div className="d-inline-block mx-2">
                <div className="form-check form-check-left">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="hasICU"
                  />
                  <label className="form-check-label">Yes</label>
                </div>
              </div>
              <div className="d-inline-block mx-2">
                <div className="form-check form-check-left">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="hasICU"
                  />
                  <label className="form-check-label">No</label>
                </div>
              </div>
            </div>
          </FormGroup>

          <FormGroup className="col-sm-12 col-md-6 mb-3">
            <Label for="totalICUBeds">Number of ICU Beds</Label>
            <InputField
              type="number"
              name="totalICUBeds"
              placeholder="Number of ICU Beds"
              onChange={handleChange}
              value={values.totalICUBeds}
              invalid={{ errors, touched }}
            />
          </FormGroup>

          <FormGroup className="col-sm-12 col-md-6 mb-3">
            <Label for="totalVentilators">Number of Ventilators</Label>
            <InputField
              type="number"
              name="totalVentilators"
              placeholder="Number of Ventilators"
              onChange={handleChange}
              value={values.totalVentilators}
              invalid={{ errors, touched }}
            />
          </FormGroup>
          <FormGroup className="col-sm-12 col-md-6 mb-3">
            <Label for="totalICUDoctors">Number of ICU Doctors</Label>
            <InputField
              type="number"
              name="totalICUDoctors"
              placeholder="Number of ICU Doctors"
              onChange={handleChange}
              value={values.totalICUDoctors}
              invalid={{ errors, touched }}
            />
          </FormGroup>

          <FormGroup className="col-sm-12 col-md-6 mb-3">
            <Label for="totalICUNurses">
              Number of ICU Nurse and Paramedics
            </Label>
            <InputField
              type="number"
              name="totalICUNurses"
              placeholder="Number of ICU Nurse and Paramedics"
              onChange={handleChange}
              value={values.totalICUNurses}
              invalid={{ errors, touched }}
            />
          </FormGroup>

          {/* CCU Service */}
          <FormGroup className="col-sm-12 col-md-6 mb-3">
            <div className="mb-3">
              <h5 className="font-size-14 mb-3">
                Availability of CCU Services
              </h5>
              <div className="d-inline-block mx-2">
                <div className="form-check form-check-left">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="hasCCU"
                  />
                  <label className="form-check-label">Yes</label>
                </div>
              </div>
              <div className="d-inline-block mx-2">
                <div className="form-check form-check-left">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="hasCCU"
                  />
                  <label className="form-check-label">No</label>
                </div>
              </div>
            </div>
          </FormGroup>

          {/* Present Accreditation */}
          <FormGroup className="col-sm-12 col-md-6 mb-3">
            <div className="mb-3">
              <h5 className="font-size-14 mb-3">
                Present Accreditation Status
              </h5>
              <div className="d-inline-block mx-2">
                <div className="form-check form-check-left">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="presentAccreditationStatus"
                  />
                  <label className="form-check-label">Yes</label>
                </div>
              </div>
              <div className="d-inline-block mx-2">
                <div className="form-check form-check-left">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="presentAccreditationStatus"
                  />
                  <label className="form-check-label">No</label>
                </div>
              </div>
            </div>
          </FormGroup>
        </Row>

        <hr />
        {/* Accreditation details */}
        <Row>
          <h5 className=" mb-3">Please Select Accredited Details</h5>
          {/* Department Name */}

          <FormGroup className="col-sm-12 col-md-4 mb-3">
            <Label for="departmentName">Name of the Departments</Label>
            <Input type="select" name="departmentName" onChange={handleChange}>
              <option value={0}>Select Department </option>
              <option value={1}>Department 1</option>
              <option value={2}>Department 2</option>
              <option value={3}>Department 3</option>
            </Input>
          </FormGroup>

          <FormGroup className="col-sm-6 col-md-4 mb-3">
            <Label for="BCPSTrainingPeriod">
              Period of Training Recognized by BCPS
            </Label>
            <InputField
              type="text"
              name="BCPSTrainingPeriod"
              placeholder="Period of Training"
              onChange={handleChange}
              value={values.BCPSTrainingPeriod}
              invalid={{ errors, touched }}
            />
          </FormGroup>

          <FormGroup className="col-sm-6 col-md-4 mb-3">
            <Label for="accreditationValidTill">Valid Till</Label>
            <InputField
              type="date"
              name="accreditationValidTill"
              placeholder="Valid Till"
              onChange={handleChange}
              value={values.accreditationValidTill}
              invalid={{ errors, touched }}
            />
          </FormGroup>
        </Row>
        {/* Table */}
        <Col>
          <div className="mb-3 d-flex flex-row-reverse">
            <Button className="float-right px-3" text={"+ Add"} />
          </div>
          <Card>
            <CardBody>
              <div className="table-responsive">
                <Table className="table table-striped table-hover mb-0">
                  <thead>
                    <tr>
                      <th>Name of Departments</th>
                      <th>Period of Training Recognized by BCPS</th>
                      <th>Valid Upto</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Department 1</td>
                      <td>2 years</td>
                      <td>December 2024</td>
                      <td>
                        <div className="btn btn-sm btn-primary d-inline-block mx-1">
                          Edit
                        </div>
                        <div className="btn btn-sm btn-danger d-inline-block">
                          Delete
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Department 2</td>
                      <td>2.5 years</td>
                      <td>July 2022</td>
                      <td>
                        <div className="btn btn-sm btn-primary d-inline-block mx-1">
                          Edit
                        </div>
                        <div className="btn btn-sm btn-danger d-inline-block">
                          Delete
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Department 3</td>
                      <td>3 years</td>
                      <td>January 2025</td>
                      <td>
                        <div className="btn btn-sm btn-primary d-inline-block mx-1">
                          Edit
                        </div>
                        <div className="btn btn-sm btn-danger d-inline-block">
                          Delete
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>

        {/* Address */}
        <Row>
          <h5>Address</h5>
          <Col sm={12} md={6} lg={6}>
            <FormGroup>
              <Label for="email">Email</Label>
              <InputField
                type="text"
                name="email"
                placeholder="Email"
                onChange={handleChange}
                value={values.email}
                invalid={{ errors, touched }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="phone">Phone</Label>
              <InputField
                type="text"
                name="phone"
                placeholder="Phone"
                onChange={handleChange}
                value={values.phone}
                invalid={{ errors, touched }}
              />
            </FormGroup>
            <div className="mb-3">
              <FormGroup className="mb-3">
                <Label for="division">Division</Label>
                <Input type="select" name="division" onChange={handleChange}>
                  <option selected disabled value={0}>
                    Select Department
                  </option>
                  <option value={1}>Dhaka</option>
                  <option value={2}>Mymensing</option>
                  <option value={3}>Sylhet</option>
                </Input>
              </FormGroup>
            </div>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <div className="mb-3">
              <FormGroup className="mb-3">
                <Label for="district">District</Label>
                <Input type="select" name="district" onChange={handleChange}>
                  <option value={0}>Select District</option>
                  <option value={1}>Dhaka</option>
                  <option value={2}>Mymensing</option>
                  <option value={3}>Sylhet</option>
                </Input>
              </FormGroup>
            </div>
            <div className="mb-3">
              <FormGroup className="mb-3">
                <Label for="policeStation">PS</Label>
                <Input
                  type="select"
                  name="policeStation"
                  onChange={handleChange}>
                  <option value={0}>Select PS</option>
                  <option value={1}>Dhaka</option>
                  <option value={2}>Mymensing</option>
                  <option value={3}>Sylhet</option>
                </Input>
              </FormGroup>
            </div>

            <FormGroup>
              <Label for="address">Phone</Label>
              <InputField
                type="textarea"
                name="address"
                placeholder="Address"
                onChange={handleChange}
                value={values.address}
                invalid={{ errors, touched }}
              />
            </FormGroup>
          </Col>
        </Row>

        <div className="text-center mb-3">
          <Link to="/enrollment-payment">
            <button
              type="submit"
              className="btn btn-primary w-25"
              onSubmit={(e) => {
                e.preventDefault;
              }}>
              Save and payment Process
            </button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default InstituteRegistration;
