import { Card, CardBody, Row } from 'reactstrap';
import { config } from 'src/config';

import DisplayImage from 'src/components/Common/displayImage';
import * as url from 'src/helpers/url_helper';
import { useGetDataQuery } from 'src/rtk/features/common/crudApi';

const ProfileView = (props: any) => {
  const { regNo } = props;

  console.log(regNo);

  const generateQueryUrl = () => {
    return `${url.PROFILE_INFO_BY_REG_ID}/${regNo}`;
  };

  const {
    data: userInfoData,
    isLoading: dataListLoading,
    isSuccess: dataListLoadingSuccess,
    error: dataListLoadingError,
  } = useGetDataQuery(generateQueryUrl());
  const userInfo: RegInfo = userInfoData?.data || [];

  return (
    <>
      {userInfo && (
        <div>
          <h6 className="block-heading"> Personal Information</h6>
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-bordered table-striped table-sm">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Name: </strong>
                          {userInfo?.personal_info?.full_name}
                        </td>
                        <td>
                          {userInfo?.personal_info?.spouse_name && (
                            <div>
                              <strong>Spouse Name: </strong>
                              {userInfo?.personal_info?.spouse_name ?? 'N/A'}
                            </div>
                          )}

                          {userInfo?.personal_info?.fathers_name && (
                            <div>
                              <strong>Father's Name: </strong>
                              {userInfo?.personal_info?.fathers_name ?? 'N/A'}
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Mother's Name: </strong>
                          {userInfo?.personal_info?.mothers_name ?? 'N/A'}
                        </td>
                        <td>
                          <strong>Date of Birth: </strong>
                          {userInfo?.personal_info?.date_of_birth ?? 'N/A'}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>NID: </strong>
                          {userInfo?.personal_info?.national_id ?? 'N/A'}
                        </td>

                        <td>
                          <strong>Mobile Number: </strong>
                          {userInfo?.personal_info?.mobile ?? 'N/A'}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Email: </strong>
                          {userInfo?.personal_info?.email ?? 'N/A'}
                        </td>

                        <td>
                          <strong>Alternative Number: </strong>
                          {userInfo?.personal_info?.telephone ?? 'N/A'}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Subject: </strong>
                          {userInfo?.subject?.subject_name ?? 'N/A'}
                        </td>
                        <td>
                          <strong>BCPS Reg. No: </strong>
                          {userInfo?.bcps_registration_no ?? 'N/A'}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="img-fluid img-rounded text-center">
                {userInfo?.personal_info?.picture &&
                  <DisplayImage className="img-thumbnail profile-img-width" src={`${config?.APP_URL}${userInfo?.personal_info?.picture}`} />
                }
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-12 mb-2">
              <h6 className="block-heading">Present Address</h6>
              <table className="table table-bordered table-striped table-sm">
                <tbody>
                  <tr>
                    <td>
                      <strong>Address: </strong>
                      {userInfo?.latest_present_address?.address_line_1 ?? ''}
                      &nbsp;
                      {userInfo?.latest_present_address?.address_line_2 ?? ''}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Upazilla: </strong>
                      {userInfo?.latest_present_address?.police_station ??
                        'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>District: </strong>
                      {userInfo?.latest_present_address?.district ?? 'N/A'}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Division: </strong>
                      {userInfo?.latest_present_address?.division ?? 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Mailing Address: </strong>
                      {userInfo?.mailing_address === 'present_address' ? (
                        <span className="badge badge-soft-success text-success">
                          Yes
                        </span>
                      ) : (
                        <span className="badge badge-soft-danger text-danger">
                          No
                        </span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-6 col-sm-12 mb-2">
              <h6 className="block-heading">Permanent Address</h6>
              <table className="table table-bordered table-striped table-sm">
                <tbody>
                  <tr>
                    <td>
                      <strong>Address: </strong>
                      {userInfo?.latest_permanent_address?.address_line_1 ??
                        ''}
                      &nbsp;
                      {userInfo?.latest_permanent_address?.address_line_2 ??
                        ''}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Upazilla: </strong>

                      {userInfo?.latest_permanent_address?.police_station ??
                        'N/A'}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>District: </strong>

                      {userInfo?.latest_permanent_address?.district ?? 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Division: </strong>

                      {userInfo?.latest_permanent_address?.division ?? 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Mailing Address: </strong>
                      {userInfo?.mailing_address === 'permanent_address' ? (
                        <span className="badge badge-soft-success text-success">
                          Yes
                        </span>
                      ) : (
                        <span className="badge badge-soft-danger text-danger">
                          No
                        </span>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {userInfo?.educational_infos?.length > 0 && (
            <>
              <h6 className="block-heading">
                Education Information
              </h6>
              <Row>
                {userInfo?.educational_infos.map((edu, index) => (
                  <div className="col-md-6" key={edu.id}>
                    <Card>
                      <CardBody className='p-2'>
                        <ul className="list-unstyled mb-0">
                          <li>
                            <strong>Degree: </strong>
                            {edu.name_of_the_degree}
                          </li>
                          <li>
                            <strong>Institute: </strong>
                            {edu.institute_or_university}
                          </li>

                          <li>
                            <strong>Passing Year: </strong>
                            {edu.passing_year}
                          </li>

                        </ul>
                      </CardBody>
                    </Card>
                  </div>
                ))}
              </Row>
            </>
          )}
          {userInfo?.training_infos?.length > 0 && (
            <>
              <h6 className="block-heading">Training Record</h6>
              <div className="row">
                {userInfo?.training_infos?.map((training, index) => (
                  <div className="col-md-6" key={training.id}>
                    <div className="card">
                      <div className="card-body">
                        <ul className="list-unstyled mb-0">
                          <li>
                            <strong>Institute: </strong>
                            <span>{training.name_of_institute ?? 'N/A'}</span>
                          </li>

                          <li>
                            <strong>Supervisor: </strong>
                            <span>{training.supervisor_name ?? 'N/A'}</span>
                          </li>

                          <li>
                            <strong>Department: </strong>
                            <span>{training.department ?? 'N/A'}</span>
                          </li>

                          <li>
                            <strong>Duration: </strong>
                            <span className="me-2">
                              {training.duration ?? '-'}
                            </span>
                            <span>{training.duration_unit ?? '-'}</span>
                          </li>

                          <li>
                            <strong>Supervisor's Post-Grad. Degree:</strong>
                            <span className="ms-2">
                              {training.supervisor_post_graduation_degree ??
                                'N/A'}
                            </span>
                          </li>

                          <li>
                            <strong>Document: </strong>
                            <span>
                              {training.document ? (
                                <a
                                  className="m-0 p-0"
                                  href={config.APP_URL + training.document}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  Download
                                </a>
                              ) : (
                                <span className="text-muted">N/A</span>
                              )}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {userInfo?.employment_infos?.length > 0 && (
            <>
              <h6 className="block-heading">Work Experience</h6>
              <div className="row">
                {userInfo?.employment_infos?.map((employment, index) => (
                  <div className="col-md-6" key={employment.id}>
                    <div className="card">
                      <div className="card-body">
                        <ul className="list-unstyled mb-0">
                          <li>
                            <strong>Institute: </strong>
                            {employment.institute_name ?? 'N/A'}
                          </li>

                          <li>
                            <strong>Designation: </strong>
                            {employment.designation ?? 'N/A'}
                          </li>

                          <li>
                            <strong>Employment Type: </strong>
                            {employment.employment_type ?? 'N/A'}
                          </li>

                          <li>
                            <strong>Current Position: </strong>

                            {employment.current_position
                              ? 'Yes'
                              : 'No'}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ProfileView;
