import { apiSlice } from 'src/rtk/api/apiSlice';
import * as logBookEntrySetupDetailsValueUrl from 'src/helpers/url_helper';

export const logBookEntrySetupDetailsValueApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['logBookEntrySetupDetailsValues'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLogbookEntrySetupDetailsValues: builder.query<APIResponse<LogbookEntrySetupDetailsValue[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${logBookEntrySetupDetailsValueUrl.GET_LOGBOOK_ENTRY_SETUP_DETAILS_VALUES}`,
        }),
        providesTags: ['logBookEntrySetupDetailsValues'],
      }),

      getLogbookEntrySetupDetailsValue: builder.query<APIResponse<LogbookEntrySetupDetailsValue>, string>({
        query: (id) => ({
          url: `${logBookEntrySetupDetailsValueUrl.GET_LOGBOOK_ENTRY_SETUP_DETAILS_VALUE}/${id}`,
          method: 'GET',
        }),
        providesTags: ['logBookEntrySetupDetailsValues'],
      }),

      createLogbookEntrySetupDetailsValue: builder.mutation<APIResponse<LogbookEntrySetupDetailsValue>, LogbookEntrySetupDetailsValue>({
        query: (data) => ({
          url: `${logBookEntrySetupDetailsValueUrl.CREATE_LOGBOOK_ENTRY_SETUP_DETAILS_VALUE}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['logBookEntrySetupDetailsValues'],
      }),

      updateLogbookEntrySetupDetailsValue: builder.mutation<APIResponse<LogbookEntrySetupDetailsValue>, string>({
        query: (url) => ({
          url: `${logBookEntrySetupDetailsValueUrl.UPDATE_LOGBOOK_ENTRY_SETUP_DETAILS_VALUE}` + url,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['logBookEntrySetupDetailsValues'],
      }),

      deleteLogbookEntrySetupDetailsValue: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${logBookEntrySetupDetailsValueUrl.DELETE_LOGBOOK_ENTRY_SETUP_DETAILS_VALUE}/${id}`,
        }),
        invalidatesTags: ['logBookEntrySetupDetailsValues'],
      }),
    }),
  });

export const {
  useCreateLogbookEntrySetupDetailsValueMutation,
  useGetLogbookEntrySetupDetailsValueQuery,
  useGetLogbookEntrySetupDetailsValuesQuery,
  useUpdateLogbookEntrySetupDetailsValueMutation,
  useDeleteLogbookEntrySetupDetailsValueMutation,
} = logBookEntrySetupDetailsValueApi;
