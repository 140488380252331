import { apiSlice } from 'src/rtk/api/apiSlice';
import * as setupNoticeTemplateDetailsUrl from 'src/helpers/url_helper';

export const setupNoticeTemplateDetailsApi = apiSlice
    .enhanceEndpoints({ addTagTypes: ['NoticeTemplateDetails'] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getNoticeTemplateDetailsList: builder.query<APIResponse<NoticeTemplateDetails[]>, void>({
                query: () => ({
                    method: 'GET',
                    url: `${setupNoticeTemplateDetailsUrl.GET_NOTICE_TEMPLATE_DETAILS_LIST}`,
                }),
                providesTags: ['NoticeTemplateDetails'],
            }),

            getNoticeTemplateDetails: builder.query<APIResponse<NoticeTemplateDetails>, string>({
                query: (id) => ({
                    url: `${setupNoticeTemplateDetailsUrl.GET_NOTICE_TEMPLATE_DETAILS}/${id}`,
                    method: 'GET',
                }),
                providesTags: ['NoticeTemplateDetails'],
            }),

            createNoticeTemplateDetails: builder.mutation<APIResponse<NoticeTemplateDetails>, NoticeTemplateDetails>({
                query: (data) => ({
                    url: `${setupNoticeTemplateDetailsUrl.CREATE_NOTICE_TEMPLATE_DETAILS}`,
                    method: 'POST',
                    body: data,
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                }),
                invalidatesTags: ['NoticeTemplateDetails'],
            }),

            updateNoticeTemplateDetails: builder.mutation<APIResponse<NoticeTemplateDetails>, string>({
                query: (url) => ({
                    url: `${setupNoticeTemplateDetailsUrl.UPDATE_NOTICE_TEMPLATE_DETAILS}` + url,
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                }),
                invalidatesTags: ['NoticeTemplateDetails'],
            }),

            deleteNoticeTemplateDetails: builder.mutation({
                query: (id: string) => ({
                    method: 'DELETE',
                    url: `${setupNoticeTemplateDetailsUrl.DELETE_NOTICE_TEMPLATE_DETAILS}/${id}`,
                }),
                invalidatesTags: ['NoticeTemplateDetails'],
            }),
        }),
    });

export const {
    useCreateNoticeTemplateDetailsMutation,
    useGetNoticeTemplateDetailsListQuery,
    useGetNoticeTemplateDetailsQuery,
    useUpdateNoticeTemplateDetailsMutation,
    useDeleteNoticeTemplateDetailsMutation
} = setupNoticeTemplateDetailsApi;
