import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import { useDeleteApprovalDetailMutation } from 'src/rtk/features/setup/approval/approvalDetails';
import { useGetOrganizationsQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';

interface Props {
  approvalDetail: ApprovalDetail;
  index: number;
  userName: string;
  approvalName: string;
}

const ApprovalDetailTableRow = ({
  approvalDetail,
  index,
  userName,
  approvalName,
}: Props) => {
  const history = useHistory();

  const [infoId, setInfoId] = useState('');
  const [modalShow, setModalShow] = useState(false);
  
  const [deleteApprovalDetail, { isSuccess, isLoading, isError }] =
    useDeleteApprovalDetailMutation();
  const { data } = useGetOrganizationsQuery();
  const organizations = data?.data;

  const deleteHandler = (modalState: boolean, id: string) => {
    setModalShow(modalState);
    setInfoId(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('ApprovalDetail deleted successfully');
    }
    if (isError) {
      toast.error('Error deleting approvalDetail');
    }
  }, [isSuccess, isError]);

  return (
    <tr key={approvalDetail.id}>
      <th scope="row">{index + 1}</th>
      <td colSpan={4}>{approvalName}</td>
      <td>{userName}</td>
      <td>{approvalDetail.registration_no}</td>
      <td>{approvalDetail.approve_status ?? '-'}</td>
      <td>{approvalDetail.status ? 'Active' : 'Inactive' ?? 'N/A'}</td>

      <td>
        <div className="d-flex gap-2 justify-content-center">
          <Button
            size="sm"
            text="Edit"
            color="warning"
            onClick={() =>
              history.push(
                `/setup/approval-details/update/${approvalDetail.id}`,
              )
            }
          />

          <Button
            size="sm"
            text="Delete"
            color="danger"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => deleteHandler(true, approvalDetail.id!)}
          />
        </div>
        {modalShow ? (
          <DeleteInformationModal
            isOpen={modalShow}
            toggle={() => setModalShow(!modalShow)}
            id={infoId}
            deleteInfomation={deleteApprovalDetail}
          />
        ) : null}
      </td>
    </tr>
  );
};

export default ApprovalDetailTableRow;
