import { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { Button, InputField } from "src/components";

const FacultyProfessionalEducationInfo = (props: any) => {
  const { degreeForm } = props;

  const [renderForm, setRenderForm] = useState(0);
  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRenderForm(parseInt(value));
    console.log(renderForm);
  };

  return (
    <div>
      <Row className="px-md-4 px-sm-2F">
        <h5>Professional Education Information</h5>
        <Col sm={12} md={6} lg={6}>
          {/* Degree */}
          <div className="mb-3">
            <FormGroup className="mb-3">
              <Label for="exampleSelect">Degree</Label>
              <Input type="select" name="degree" onChange={handleFormChange}>
                <option value={0}>Select Degree </option>
                <option value={1}>MBBS/BDS</option>
                <option value={2}> FCPS Part-I</option>
                <option value={3}>
                  FCPS, MD, MS, MRCP, MRCS, MRCOG, MRCPCH
                </option>
              </Input>
            </FormGroup>
          </div>

          {/* Institute */}
          <div className="mb-3">
            <FormGroup className="mb-3">
              <Label for="exampleSelect">Institute</Label>
              <Input type="select" name="degree">
                <option value={0}>Select Institute </option>
                <option value={1}> Dhaka Medical College</option>
                <option value={2}>Mymensingh Medical College</option>
                <option value={3}>Sir Salimullah Medical College</option>
              </Input>
            </FormGroup>
          </div>

          {/* Session */}
          {renderForm === 2 ? (
            <div className="mb-3">
              <FormGroup className="mb-3">
                <Label for="exampleSelect">Session</Label>
                <Input type="select" name="degree">
                  <option value={0}>Select Session </option>
                  <option value={1}> January - June</option>
                  <option value={2}>July - December</option>
                </Input>
              </FormGroup>
            </div>
          ) : null}

          {/* Present Designation */}
          {renderForm === 3 ? (
            <div className="mb-3">
              <Label className="form-label">Present Designation</Label>
              <InputField
                name="designation"
                type="text"
                placeholder="Present Designation"
                onChange={degreeForm.handleChange}
                value={degreeForm.values.designation}
                invalid={{
                  errors: degreeForm.errors,
                  touched: degreeForm.touched,
                }}
              />
            </div>
          ) : null}

          {/* Present Working Place */}
          {renderForm === 3 ? (
            <div className="mb-3">
              <Label className="form-label">Present Working Place</Label>
              <InputField
                name="workingPlace"
                type="text"
                placeholder="Present Working Place"
                onChange={degreeForm.handleChange}
                value={degreeForm.values.workingPlace}
                invalid={{
                  errors: degreeForm.errors,
                  touched: degreeForm.touched,
                }}
              />
            </div>
          ) : null}

          {/* Roll */}
          {renderForm === 2 ? (
            <div className="mb-3">
              <Label className="form-label">Roll</Label>
              <InputField
                name="roll"
                type="text"
                placeholder="Roll Number"
                onChange={degreeForm.handleChange}
                value={degreeForm.values.roll}
                invalid={{
                  errors: degreeForm.errors,
                  touched: degreeForm.touched,
                }}
              />
            </div>
          ) : null}
        </Col>
        <Col sm={12} md={6} lg={6}>
          {/* Subject */}
          {renderForm === 2 || renderForm === 3 ? (
            <div className="mb-3">
              <FormGroup className="mb-3">
                <Label>Subject</Label>
                <Input type="select" name="subject">
                  <option value={0}>Select Subject </option>
                  <option value={1}>Subject 1</option>
                  <option value={2}>Subject 2</option>
                  <option value={3}>Subject 3</option>
                </Input>
              </FormGroup>
            </div>
          ) : null}

          {/* Passing Year */}
          <div className="mb-3">
            <Label className="form-label">Passing Year</Label>
            <InputField
              name="year"
              type="text"
              placeholder="Passing year"
              onChange={degreeForm.handleChange}
              value={degreeForm.values.year}
              invalid={{
                errors: degreeForm.errors,
                touched: degreeForm.touched,
              }}
            />
          </div>

          {/* Certificate */}
          <div className="mb-3">
            <Label className="form-label">Certificate</Label>
            <InputField
              name="certificate"
              type="file"
              placeholder="Upload Certificate"
              onChange={degreeForm.handleChange}
              value={degreeForm.values.certificate}
              invalid={{
                errors: degreeForm.errors,
                touched: degreeForm.touched,
              }}
            />
          </div>
        </Col>

        <Col>
          <div className="mb-3 d-flex flex-row-reverse">
            <Button className="float-right px-3" text={"+ Add"} />
          </div>
          <Card>
            <CardBody>
              <div className="table-responsive">
                <Table className="table table-striped table-hover mb-0">
                  <thead>
                    <tr>
                      <th>Sl</th>
                      <th>Degree</th>
                      <th>Subject/Specialty</th>
                      <th>Institute</th>
                      <th>Year</th>
                      <th>Session</th>
                      <th>Certificate</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>MBBS</td>
                      <td>Medicine</td>
                      <td>Dhaka Medical College</td>
                      <td>2021</td>
                      <td>2018-19</td>
                      <td>Certificate info</td>
                      <td>
                        <div className="btn btn-sm btn-primary d-inline-block mx-1">
                          Edit
                        </div>
                        <div className="btn btn-sm btn-danger d-inline-block">
                          Delete
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default FacultyProfessionalEducationInfo;
