import { useFormik } from 'formik';
import React from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

const ApplyTrainingWorkshop = () => {
  const trainingApplyForm = useFormik({
    initialValues: {
      program: '',
      department: '',
      title: '',
      bmdcRegNo: '',
      name: '',
      yearOfPassingFCPS: '',
      midTermExam: '',
      protocolSLNo: '',
      email: '',
      phone: '',
      signature: '',
      fellowNo: '',
      specialty: '',
      subject: '',
      division: '',
      district: '',
      ps: '',
      address: '',
      paymentType: '',
      bankDepositeSlipNo: '',
      depositeDate: '',
      amount: '',
      bankName: '',
      attachmentOfBankDeposite: '',
      trainingType: '',
    },
    // validationSchema: Yup.object({
    //   name: Yup.string().required("Name is required"),
    //   fatherOrHusbandName: Yup.string().required(
    //     "Father/Husband name is required"
    //   ),
    //   motherName: Yup.string().required("Mother name is required"),
    //   dateOfBirth: Yup.string().required("Date of birth is required"),
    //   nid: Yup.string().required("NID is required"),
    //   phone: Yup.string(),
    // }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4 className="my-4">Training and Workshop</h4>

          <Form
            onSubmit={trainingApplyForm.handleSubmit}
            encType="multipart/form-data"
          >
            <Row>
              <Col>
                <div className="mb-3">
                  <FormGroup check inline>
                    <Input type="radio" name="program" /> Training
                  </FormGroup>

                  <FormGroup check inline>
                    <Input type="radio" name="program" /> Workshop
                  </FormGroup>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label>Department</Label>
                  <select className="form-select">
                    <option value="" selected>
                      IT Department
                    </option>
                  </select>
                </div>
              </Col>

              <Col>
                <div className="mb-3">
                  <Label className="form-label">Title</Label>
                  <Input
                    name="title"
                    className="form-control"
                    placeholder="Title"
                    type="text"
                    onChange={trainingApplyForm.handleChange}
                    onBlur={trainingApplyForm.handleBlur}
                    value={trainingApplyForm.values.title || ''}
                    invalid={
                      trainingApplyForm.touched.title &&
                      !!trainingApplyForm.errors.title
                    }
                  />
                  {trainingApplyForm.touched.title &&
                  trainingApplyForm.errors.title ? (
                    <FormFeedback type="invalid">
                      {trainingApplyForm.errors.title}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <h4 className="my-4">Personal Information</h4>
            <Row>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label className="form-label">BMDC Registration Number</Label>
                  <Input
                    name="name"
                    className="form-control"
                    placeholder="BMDC Reg Number"
                    type="text"
                    onChange={trainingApplyForm.handleChange}
                    onBlur={trainingApplyForm.handleBlur}
                    value={trainingApplyForm.values.bmdcRegNo || ''}
                    invalid={
                      trainingApplyForm.touched.bmdcRegNo &&
                      !!trainingApplyForm.errors.bmdcRegNo
                    }
                  />
                  {trainingApplyForm.touched.bmdcRegNo &&
                  trainingApplyForm.errors.bmdcRegNo ? (
                    <FormFeedback type="invalid">
                      {trainingApplyForm.errors.bmdcRegNo}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label className="form-label">Fellow No.</Label>
                  <Input
                    name="fellowNo"
                    className="form-control"
                    placeholder="Fellow No."
                    type="text"
                    onChange={trainingApplyForm.handleChange}
                    onBlur={trainingApplyForm.handleBlur}
                    value={trainingApplyForm.values.fellowNo || ''}
                    invalid={
                      trainingApplyForm.touched.fellowNo &&
                      !!trainingApplyForm.errors.fellowNo
                    }
                  />
                  {trainingApplyForm.touched.fellowNo &&
                  trainingApplyForm.errors.fellowNo ? (
                    <FormFeedback type="invalid">
                      {trainingApplyForm.errors.fellowNo}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label className="form-label">Name</Label>
                  <Input
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    type="text"
                    onChange={trainingApplyForm.handleChange}
                    onBlur={trainingApplyForm.handleBlur}
                    value={trainingApplyForm.values.name || ''}
                    invalid={
                      trainingApplyForm.touched.name &&
                      !!trainingApplyForm.errors.name
                    }
                  />
                  {trainingApplyForm.touched.name &&
                  trainingApplyForm.errors.name ? (
                    <FormFeedback type="invalid">
                      {trainingApplyForm.errors.name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label className="form-label">Speciality</Label>
                  <Input
                    name="specialty"
                    className="form-control"
                    placeholder="Speciality"
                    type="text"
                    onChange={trainingApplyForm.handleChange}
                    onBlur={trainingApplyForm.handleBlur}
                    value={trainingApplyForm.values.specialty || ''}
                    invalid={
                      trainingApplyForm.touched.specialty &&
                      !!trainingApplyForm.errors.specialty
                    }
                  />
                  {trainingApplyForm.touched.specialty &&
                  trainingApplyForm.errors.specialty ? (
                    <FormFeedback type="invalid">
                      {trainingApplyForm.errors.specialty}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label>Year of Passing FCPS Part 1</Label>
                  <select className="form-select">
                    <option value="" selected>
                      Year
                    </option>
                  </select>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3 mt-3 pt-3">
                  <FormGroup check inline>
                    <Input type="radio" name="passingMonth" /> January
                  </FormGroup>

                  <FormGroup check inline>
                    <Input type="radio" name="passingMonth" /> July
                  </FormGroup>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label>Mid Term Examination (Proposed)</Label>
                  <select className="form-select">
                    <option value="" selected>
                      Year
                    </option>
                  </select>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3 mt-3 pt-3">
                  <FormGroup check inline>
                    <Input type="radio" name="passingMonth" /> January
                  </FormGroup>

                  <FormGroup check inline>
                    <Input type="radio" name="passingMonth" /> July
                  </FormGroup>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label className="form-label">Protocol SL No</Label>
                  <Input
                    name="protocolSLNo"
                    className="form-control"
                    placeholder="Protocol SL No"
                    type="text"
                    onChange={trainingApplyForm.handleChange}
                    onBlur={trainingApplyForm.handleBlur}
                    value={trainingApplyForm.values.protocolSLNo || ''}
                    invalid={
                      trainingApplyForm.touched.protocolSLNo &&
                      !!trainingApplyForm.errors.protocolSLNo
                    }
                  />
                  {trainingApplyForm.touched.protocolSLNo &&
                  trainingApplyForm.errors.protocolSLNo ? (
                    <FormFeedback type="invalid">
                      {trainingApplyForm.errors.protocolSLNo}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label className="form-label">Subject</Label>
                  <Input
                    name="subject"
                    className="form-control"
                    placeholder="Subject"
                    type="text"
                    onChange={trainingApplyForm.handleChange}
                    onBlur={trainingApplyForm.handleBlur}
                    value={trainingApplyForm.values.subject || ''}
                    invalid={
                      trainingApplyForm.touched.subject &&
                      !!trainingApplyForm.errors.subject
                    }
                  />
                  {trainingApplyForm.touched.subject &&
                  trainingApplyForm.errors.subject ? (
                    <FormFeedback type="invalid">
                      {trainingApplyForm.errors.subject}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label className="form-label">Email</Label>
                  <Input
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    type="text"
                    onChange={trainingApplyForm.handleChange}
                    onBlur={trainingApplyForm.handleBlur}
                    value={trainingApplyForm.values.email}
                  />
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label className="form-label">Phone</Label>
                  <Input
                    name="phone"
                    className="form-control"
                    placeholder="Phone"
                    type="text"
                    onChange={trainingApplyForm.handleChange}
                    onBlur={trainingApplyForm.handleBlur}
                    value={trainingApplyForm.values.phone || ''}
                  />
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label>Division</Label>
                  <select className="form-select">
                    <option value="" selected>
                      Select Division
                    </option>
                  </select>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label>District</Label>
                  <select className="form-select">
                    <option value="" selected>
                      Select District
                    </option>
                  </select>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label>PS</Label>
                  <select className="form-select">
                    <option value="" selected>
                      Select PS
                    </option>
                  </select>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label className="form-label">Address</Label>
                  <Input
                    name="address"
                    className="form-control"
                    placeholder="Subject"
                    type="text"
                    onChange={trainingApplyForm.handleChange}
                    onBlur={trainingApplyForm.handleBlur}
                    value={trainingApplyForm.values.address || ''}
                    invalid={
                      trainingApplyForm.touched.address &&
                      !!trainingApplyForm.errors.address
                    }
                  />
                  {trainingApplyForm.touched.address &&
                  trainingApplyForm.errors.address ? (
                    <FormFeedback type="invalid">
                      {trainingApplyForm.errors.address}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label className="form-label">Signature</Label>
                  <Input
                    name="signature"
                    className="form-control"
                    placeholder=""
                    type="file"
                    onChange={trainingApplyForm.handleChange}
                    onBlur={trainingApplyForm.handleBlur}
                    value={trainingApplyForm.values.signature}
                  />
                </div>
              </Col>
            </Row>

            <h4 className="my-4">Payment Process</h4>

            <Row>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <p>Payment Type</p>
                  <FormGroup check inline>
                    <Input type="radio" name="paymentType" /> Online Payment
                  </FormGroup>

                  <FormGroup check inline>
                    <Input type="radio" name="paymentType" checked /> Bank
                    Deposite
                  </FormGroup>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <p>Training Type</p>
                  <FormGroup check inline>
                    <Input type="radio" name="trainingType" /> Online
                  </FormGroup>

                  <FormGroup check inline>
                    <Input type="radio" name="trainingType" /> Offline
                  </FormGroup>
                </div>
              </Col>

              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label className="form-label">Bank Deposite Slip No</Label>
                  <Input
                    name="bankDepositSlipNo"
                    className="form-control"
                    placeholder="Bank Deposite Slip No"
                    type="text"
                    onChange={trainingApplyForm.handleChange}
                    onBlur={trainingApplyForm.handleBlur}
                    value={trainingApplyForm.values.bankDepositeSlipNo || ''}
                    invalid={
                      trainingApplyForm.touched.bankDepositeSlipNo &&
                      !!trainingApplyForm.errors.bankDepositeSlipNo
                    }
                  />
                  {trainingApplyForm.touched.bankDepositeSlipNo &&
                  trainingApplyForm.errors.bankDepositeSlipNo ? (
                    <FormFeedback type="invalid">
                      {trainingApplyForm.errors.bankDepositeSlipNo}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label className="form-label">Amount</Label>
                  <Input
                    name="amount"
                    className="form-control"
                    placeholder="Amount"
                    type="text"
                    onChange={trainingApplyForm.handleChange}
                    onBlur={trainingApplyForm.handleBlur}
                    value={trainingApplyForm.values.amount || ''}
                    invalid={
                      trainingApplyForm.touched.amount &&
                      !!trainingApplyForm.errors.amount
                    }
                  />
                  {trainingApplyForm.touched.amount &&
                  trainingApplyForm.errors.amount ? (
                    <FormFeedback type="invalid">
                      {trainingApplyForm.errors.amount}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label className="form-label">
                    Attachment of Bank Deposite
                  </Label>
                  <Input
                    name="attachmentOfBankDeposite"
                    className="form-control"
                    placeholder="Attachment Of Bank Deposite"
                    type="file"
                    onChange={trainingApplyForm.handleChange}
                    onBlur={trainingApplyForm.handleBlur}
                    value={
                      trainingApplyForm.values.attachmentOfBankDeposite || ''
                    }
                    invalid={
                      trainingApplyForm.touched.attachmentOfBankDeposite &&
                      !!trainingApplyForm.errors.attachmentOfBankDeposite
                    }
                  />
                  {trainingApplyForm.touched.attachmentOfBankDeposite &&
                  trainingApplyForm.errors.attachmentOfBankDeposite ? (
                    <FormFeedback type="invalid">
                      {trainingApplyForm.errors.attachmentOfBankDeposite}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label className="form-label">Deposite Date</Label>
                  <Input
                    name="depositeDate"
                    className="form-control"
                    placeholder="Deposite Date"
                    type="date"
                    onChange={trainingApplyForm.handleChange}
                    onBlur={trainingApplyForm.handleBlur}
                    value={trainingApplyForm.values.depositeDate || ''}
                    invalid={
                      trainingApplyForm.touched.depositeDate &&
                      !!trainingApplyForm.errors.depositeDate
                    }
                  />
                  {trainingApplyForm.touched.depositeDate &&
                  trainingApplyForm.errors.depositeDate ? (
                    <FormFeedback type="invalid">
                      {trainingApplyForm.errors.depositeDate}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div className="mb-3">
                  <Label className="form-label">Bank Name</Label>
                  <Input
                    name="bankName"
                    className="form-control"
                    placeholder="Bank Name"
                    type="text"
                    onChange={trainingApplyForm.handleChange}
                    onBlur={trainingApplyForm.handleBlur}
                    value={trainingApplyForm.values.bankName || ''}
                    invalid={
                      trainingApplyForm.touched.bankName &&
                      !!trainingApplyForm.errors.bankName
                    }
                  />
                  {trainingApplyForm.touched.bankName &&
                  trainingApplyForm.errors.bankName ? (
                    <FormFeedback type="invalid">
                      {trainingApplyForm.errors.bankName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <div className="text-center my-5">
              <Button className="w-20" variant="success" type="submit">
                Save{' '}
              </Button>
            </div>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ApplyTrainingWorkshop;
