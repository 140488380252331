import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { putUrlString, setFromErrors } from 'src/helpers';
import { useGetGuideCoGuideListQuery, useUpdateGuideCoGuideMutation } from 'src/rtk/features/guide/guideCoguideApi';
import * as Yup from 'yup';

interface EditProps {
  id?: string;
  setShowAddForm?: any;
}

const initialValues: ApplyForGuideCoGuide = {
  id: '',
  type: '',
  submit_status: '',
};

const EditGuideCoGuideApply = (props: EditProps) => {
  const { id, setShowAddForm } = props;

  const [btnClicked, setBtnClicked] = useState<string>('');
  const [updateGuideCoGuide, { isSuccess, isError, error }] =
    useUpdateGuideCoGuideMutation();
  const [guideCoGuide, setGuideCoGuide] = useState<GuideCoGuide>({} as GuideCoGuide);
  // const [guideChecked, setGuideChecked] = useState(true);
  // const [coGuideChecked, setCoGuideChecked] = useState(false);
  
  const { data: list, isError: isGuideError } = useGetGuideCoGuideListQuery();

  const guideList = [...list!];

  const err: CustomError = error as CustomError;

  const {
    handleSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    values,setValues,
    touched,
    errors,
    resetForm,
    setFieldValue,
    setErrors,
  } = useFormik({
    initialValues,

    validationSchema: Yup.object({
      type: Yup.mixed().required('Please Select a Type'),
    }),

    onSubmit: (values: any) => {
      values.id = id;
      
      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        if (key === 'submit_status') {
          if (btnClicked === 'submit') {
            values[key] = 'Submit';
          } else if (btnClicked === 'save_as_draft') {
            values[key] = 'Draft';
          }
        }
        formData.append(key, values[key]);
      });

      const url = `${values.id}?type=${values.type}&submit_status=${values.submit_status}`
      updateGuideCoGuide(url);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      handleFormReset();
      setShowAddForm(true);
      toast.success('Information Updated Successfully!');
      // buttonRef.current.disabled = false;
    }
    if (isError) {
      toast.error(err?.message || 'Something Went Wrong!');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (guideCoGuide) {
      const data: GuideCoGuide = guideCoGuide;              

      // console.log(data);
      
      setValues({
        id: id,
        type: data?.payment_for!,
        submit_status: data?.order_submit_type!,  
      });  
    }
  }, [guideCoGuide]);

  useEffect(() => {
    if (guideList) {
      const data: GuideCoGuide[] = guideList!;             
      const editedGuideCoGuide: GuideCoGuide = data.find((f: GuideCoGuide) => f.id === id)!;
      setGuideCoGuide(editedGuideCoGuide);            
    }
  }, [guideList]);

  const handleFormReset = () => {    
    resetForm();      
    setShowAddForm(true);           
  };
  // const handleGuideChecked = (e : any) => {
  //   setGuideChecked(e.target.checked);
  //   setCoGuideChecked(!e.target.checked);
  // }

  // const handleCoGuideChecked = (e : any) => {
  //   setGuideChecked(!e.target.checked);
  //   setCoGuideChecked(e.target.checked);
  // }
  // const handleReset = (e: any) => {
  //   resetForm();
  //   setGuideChecked(true);
  // }
  return (
    <React.Fragment>
      <div className="row align-items-center justify-content-center">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          onReset={(e) => {
            e.preventDefault();
            handleFormReset();
        }} 
        >
          <div className="card">
            <div className="card-body">
              <div>
                <div>
                  <label className='form-label required-field'>Registration For</label>
                </div>
                <div className="form-check form-check-inline">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="type"
                    value={'Guide'}
                    id="type-guide"
                    onChange={(e) => {
                      // handleGuideChecked(e);
                      handleChange(e);
                    }}
                    checked = {values.type === 'Guide'}
                  />
                  <label className="form-check-label" htmlFor="type-guide">
                    Guide
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="type"
                    value={'Co-Guide'}
                    id="type-co-guide"
                    onChange={(e) => {
                      // handleCoGuideChecked(e);
                      handleChange(e);
                    }}
                    checked={values.type === 'Co-Guide'}
                  />
                  <label className="form-check-label" htmlFor="type-co-guide">
                    Co-Guide
                  </label>
                </div>
                {errors.type && touched.type ? (
                  <div className="text-danger">{errors.type as string}</div>
                ) : null}
              </div>
            </div>
            <FormGroup className="mb-3 text-center">
              <Button
                type="reset"
                className="btn btn-secondary me-2"
              >
                Reset
              </Button>
              <Button
                type="submit"
                className="btn btn-info me-2"
                onClick={(e) => {
                  setBtnClicked('save_as_draft');
                }}
              >
                Save as Draft
              </Button>
              <Button
                type="submit"
                className="btn btn-success"
                onClick={(e) => {
                  setBtnClicked('submit');
                }}
              >
                Update
              </Button>
            </FormGroup>
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default EditGuideCoGuideApply;
