import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { setFromErrors } from 'src/helpers';
import * as url from 'src/helpers/url_helper';
import { useGetDataQuery } from 'src/rtk/features/common/crudApi';
import { useCreateExamTrainingFeeMutation } from 'src/rtk/features/setup/examTrainingFee/examTrainingFeeSetupApi';
import * as Yup from 'yup';

const initialValues: ExamTrainingFee = {
  id: '',
  exam_training_id: '',
  exam_fee_new: '',
  exam_fee_new_in_words: '',
  exam_fee: '',
  exam_fee_old_in_words: '',
  status: true,
  creator_organization_id: '',
  updater_organization_id: '',
  created_by: '',
  updated_by: '',
  deleted_by: '',
  created_at: '',
  updated_at: '',
  deleted_at: '',
};

const AddExamTrainingFee = () => {
  const [createExamTrainingFee, { isSuccess, isError, error }] =
    useCreateExamTrainingFeeMutation();

  const generateQueryUrl = () => {
    return `${url.SETUP_EXAM_TRAINING_INFO_LIST}`;
  };

  const { data: examInfoData } = useGetDataQuery(generateQueryUrl());

  // const data: any = examInfoData?.data || [];
  // const { data } = useGetExamInfosQuery();
  const examInfos = examInfoData?.data?.data || [];

  const history = useHistory();

  const err: CustomError = error as CustomError;
  const {
    handleSubmit,
    getFieldProps,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      exam_training_id: Yup.string().required(
        'Exam training fee must be under an exam training info',
      ),
    }),

    onSubmit: (values) => {
      createExamTrainingFee({
        ...values,
      });
      console.log(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Exam training fee created successfully');
      history.push('/setup/exam-training-fee');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  let op = [
    ...examInfos?.map((e: ExamInfo) => ({ label: e.name!, value: e.id! })),
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title="Add Exam Training Fee"
          breadcrumbItem={[
            {
              link: '/setup/exam-training-fee/',
              name: 'Exam Training Fee List',
            },
          ]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-8 col-sm-12 ">
            <div className="card mt-3">
              <h5 className="card-header">
                <i className="fa fa-plus me-2" />
                Add Exam Training Fee
              </h5>
              <div className="card-body">
                <Form
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <FormGroup className="mb-3">
                        <Label for="exam_training_id">Exam Training</Label>
                        <InputField
                          type="select"
                          placeholder="Exam Training"
                          options={op}
                          invalid={{ errors, touched }}
                          {...getFieldProps('exam_training_id')}
                        />
                      </FormGroup>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <FormGroup className="mb-3">
                        <Label for="exam_fee_new">Exam Fee (New)</Label>
                        <InputField
                          type="text"
                          placeholder="Exam Fee (New)"
                          invalid={{ errors, touched }}
                          {...getFieldProps('exam_fee_new')}
                        />
                      </FormGroup>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <FormGroup className="mb-3">
                        <Label for="exam_fee_new_in_words">
                          Exam Fee (New) - In Words
                        </Label>
                        <InputField
                          type="text"
                          placeholder="Exam Fee (New) - In Words"
                          invalid={{ errors, touched }}
                          {...getFieldProps('exam_fee_new_in_words')}
                        />
                      </FormGroup>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <FormGroup className="mb-3">
                        <Label for="exam_fee">Exam Fee</Label>
                        <InputField
                          type="text"
                          placeholder="Exam Fee"
                          invalid={{ errors, touched }}
                          {...getFieldProps('exam_fee')}
                        />
                      </FormGroup>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <FormGroup className="mb-3">
                        <Label for="exam_fee_old_in_words">
                          Exam Fee (Old) - In Words
                        </Label>
                        <InputField
                          type="text"
                          placeholder="Exam Fee (Old) - In Words"
                          invalid={{ errors, touched }}
                          {...getFieldProps('exam_fee_old_in_words')}
                        />
                      </FormGroup>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <FormGroup check className="mb-3 mt-4">
                        <Input
                          type="checkbox"
                          role="check"
                          checked={values.status}
                          {...getFieldProps('status')}
                        />
                        <Label check>Status</Label>
                      </FormGroup>
                    </div>
                  </div>
                  <Button type="submit" text="Submit" />
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AddExamTrainingFee;
