import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Modal,
  ModalFooter,
  Row
} from 'reactstrap';
import * as Yup from 'yup';
// //Import Scrollbar
import toast from 'react-hot-toast';
import SimpleBar from 'simplebar-react';
// import { Button } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import store from 'src/rtk/app/store';
import {
  useCreateByPostMutation,
  useGetCommentsDataByMutation,
  useGetDataQuery,
  useUpdateByPatchMutation,
} from 'src/rtk/features/disertation-thesis/crudApi';
import { useGetPersonalProfileQuery } from 'src/rtk/features/trainee/traineeApi';
import Attachments from './Attachment';
import BasicInformation from './BasicInformation';
import GuideSignature from './GuideSignature';
import MaterialAndMethods from './MaterialAndMethods';
import Objectives from './Objectives';
import ResearcherSignature from './ResearcherSignature';
import SimpleComponentWithRichTextEditor from './SimpleComponentWithRichTextEditor';
import { apiUrls, sideBarIndexInitialValues } from './StaticFilesProtocolEntry';
import StudyDesign from './StudyDesign';


let sideBarIndex = structuredClone(sideBarIndexInitialValues);

const arrayOfSideBaritems = Object.values(sideBarIndex).map((item) => (item as { numberText: string }).numberText);

sideBarIndex.basic_information_of_the_thesis.isSelected = true;

const ProtocolEntryMock = () => {

  // fetch relevant data
  const { data: personalProfile } = useGetPersonalProfileQuery();
  const currentUser = store.getState().auth.user;

  const {
    data: title,
    isLoading: titleLoading,
    isSuccess: titleLoadingSuccess,
    error: titleLoadingError,
  } = useGetDataQuery(`${apiUrls.titleUrl}`);

  const {
    data: existingProtocol,
    isLoading: existingProtocoLoading,
    isSuccess: existingProtocoLoadingSuccess,
    error: existingProtocoLoadingError,
  } = useGetDataQuery(`${apiUrls.getProtocolListUrl}`);

  const [
    commentsDataList,
    {
      isLoading: commentsDataListLoading,
      isSuccess: commentsDataListLoadingSuccess,
      error: commentsDataListLoadingError,
    },
  ] = useGetCommentsDataByMutation();


  const initialValues: any = {
    relevant_faculty: '',
    relevant_discipline: '',
    summary: '',
    place_of_study: '',
    study_period: '',
    study_design: '',
    introduction_context: '',
    rationale_of_the_research: '',
    research_question_hypothesis: '',
    // objectives: { general_objectives: '', specific_objectives: '' },
    objectives: [],
    material_methods: [],
    ethical_implication: '',
    total_budget: '',
    total_budget_text: '',
    source_of_funding: '',
    facilities_available_at_the_place_of_the_study: '',
    other_facilites_needed: '',
    dissemination_and_use_of_findings: '',
    references: '',
    any_other_relevant_info: '',
    others: '',
    submission_status: '',
    attachments: [],
    // not required to post
    agree_date_researcher: '',
  };

  const materialMethodLabels: string[] = [
    'main_outcome',
    'confounding_variable',
    'study_population',
    'sample_size',
    'screening_methods',
    'sampling_methods',
    'inclusion_exclusion_criteria',
    'operational_definitions',
    'flow_chart',
    'material_preparation',
    'nature_of_controls',
    'randomization_blinding',
    'equipment',
    'data_collection',
    'professional_assistance',
    'data_analysis_interpretation',
    'quality_assurance',
    'time_table'
  ];

  const [id, setId] = useState<string>('');
  const [btnClicked, setBtnClicked] = useState<string>('');
  const [noteModalShow, setNoteModalShow] = useState<boolean>(false);
  const [typeOfNote, setTypeOfNote] = useState<string>('');
  const [contentValidationMessage, setContentValidationMessage] = useState<string>('');
  const [protocolSubmission, setProtocolSubmission] = useState<boolean>(false);
  const [messageCount, setMessageCount] = useState<number>(0);
  const [protocolSubmissionState, setProtocolSubmissionState] = useState<string>('');
  const [objectivesActiveTab, setObjectivesActiveTab] = useState('general_objectives');
  const [materialMethodsActiveTab, setMaterialMethodsActiveTab] = useState(sideBarIndex.materials_and_methods.main_outcome.objKey);
  // const [showReport, setShowReport] = useState<boolean>(false);

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({}),
    onSubmit: (values) => {

      // console.log(values);

      if (btnClicked === 'Submit') {
        values.submission_status = 'Submit';
      } else if (btnClicked === 'Draft') {
        values.submission_status = 'Draft';
      }

      if (protocolSubmissionState === 'Draft' || protocolSubmissionState === 'Submit') {
        if (attachmentInputList?.length > 0) {
          const attachmentTypes: any = attachmentInputList?.map((attachment: any, index: number) => {
            return attachment.attachmentType;
          });

          if (!attachmentTypes.includes('Data Collection Instruments')) {
            toast.error('Please provide data collection instruments file', { icon: <i className='fas fa-circle-exclamation'></i>, duration: 5000 });
            return;
          }

          if (!attachmentTypes.includes('Ethical Clearance')) {
            toast.error('Please provide ethical clearance file', { icon: <i className='fas fa-circle-exclamation'></i>, duration: 5000 });
            return;
          }
        } else {
          toast.error('Please provide data collection instruments and ethical clearance file', { icon: <i className='fas fa-circle-exclamation'></i>, duration: 5000 });
          return;
        }

        // process  attachments
        const attachmentsList = attachmentInputList;

        attachmentsList?.forEach((attachment: any, index: number) => {
          let fileIndex = `file_${index}`;
          values[fileIndex] = attachment.attachmentFile;

          if (typeof attachment.attachmentFile === 'string') {
            values?.attachment?.push({
              type: attachment.attachmentType,
              fileIndex: fileIndex,
            });
          } else {
            values?.attachment?.push({
              type: attachment.attachmentType,
              add: 1,
              fileIndex: fileIndex,
            });
          }
        });

        let relevantErrorMessage: string = 'The following fields are missing: ';
        let relevantErrorFlag: boolean = false;

        const protocolKeys: string[] = [
          'summary',
          'study_design',
          'introduction_context',
          'rationale_of_the_research',
          'research_question_hypothesis',
          'objectives',
          'material_methods',
          'ethical_implication',
          'total_budget_text',
          'source_of_funding',
          'facilities_available_at_the_place_of_the_study',
          'other_facilites_needed',
          'dissemination_and_use_of_findings',
          'references',
          'any_other_relevant_info',
        ];

        const protocolSubmissionKeys: string[] = Object.keys(values);

        protocolKeys.forEach((key: string, index: number) => {
          if (!protocolSubmissionKeys.includes(key)) {
            relevantErrorMessage = relevantErrorMessage + key.replaceAll('_', ' ') + ', ';
            relevantErrorFlag = true;
          } else {
            if (!values[key] || values[key] === 'null' || values[key] === '<p>null</p>') {
              relevantErrorMessage = relevantErrorMessage + key.replaceAll('_', ' ') + ', ';
              relevantErrorFlag = true;
            }
          }
        });

        if (relevantErrorFlag) {
          toast.error(relevantErrorMessage, { icon: <i className='fas fa-circle-exclamation'></i>, duration: 5000 });
          return;
        }
      }

      // process richtexts

      objectivesRichtexts.forEach((item: any, index: number) => {

        // console.log(item);

        if (item.parent === sideBarIndex.objectives.objKey) {

          if (values?.objectives.length > 0) {
            if (values!.objectives.find((objective: any) => objective.key === item.name)) {
              values!.objectives.find((objective: any) => objective.key === item.name).value.value = item.value;
            } else {
              values?.objectives?.push({
                key: item.name,
                value: item,
              });
            }

          } else {
            values?.objectives?.push({
              key: item.name,
              value: item,
            });
          }
        }

        if (item.parent === sideBarIndex.materials_and_methods.objKey) {

          if (values?.material_methods.length > 0) {

            if (values!.material_methods.find((material: any) => material.key === item.name)) {
              values!.material_methods.find((material: any) => material.key === item.name).value.value = item.value;
            } else {
              values?.material_methods?.push({
                key: item.name,
                value: item,
              });

            }
          } else {
            values?.material_methods?.push({
              key: item.name,
              value: item,
            });

          }

        }

        if (item.parent === 'none') {
          values[item.name] = item.value;
        }
      });

      if (protocolSubmissionState === 'Draft' || protocolSubmissionState === 'Submit') {
        let relevantErrorMessageForObjectives: string = 'The following fields are missing: ';
        let relevantErrorFlagForObjectives: boolean = false;

        let objectivesKeys: string[] = [
          'general_objectives',
          'specific_objectives',
        ];

        const objectivesSubmissionKeys: string[] = values.objectives.map((item: any, index: number) => item.key);
        const objectivesSubmissionKeyValues: any = values.objectives.reduce((acc: any, item: any, index: number) => {
          acc[item.key] = item.value.value;
          return acc;
        }, {});

        // console.log(objectivesSubmissionKeys);
        // console.log(objectivesSubmissionKeyValues);
        // console.log(objectivesRichtexts);

        objectivesKeys.forEach((item: string, index: number) => {
          if (!objectivesSubmissionKeys.includes(item)) {
            relevantErrorMessageForObjectives = relevantErrorMessageForObjectives + item.replaceAll('_', ' ') + ', ';
            relevantErrorFlagForObjectives = true;
          } else {
            if (!objectivesSubmissionKeyValues[item] || objectivesSubmissionKeyValues[item] === 'null' || objectivesSubmissionKeyValues[item] === '<p>null</p>') {
              relevantErrorMessageForObjectives = relevantErrorMessageForObjectives + item.replaceAll('_', ' ') + ', ';
              relevantErrorFlagForObjectives = true;
            }
          }
        });

        if (relevantErrorFlagForObjectives) {
          toast.error(relevantErrorMessageForObjectives, { icon: <i className='fas fa-circle-exclamation'></i>, duration: 5000 });
          return;
        }

        let relevantErrorMessageForMaterialMethods: string = 'The following fields are missing: ';
        let relevantErrorFlagForMaterialMethods: boolean = false;

        let materialMethodsKeys: string[] = [
          'main_outcome',
          'confounding_variable',
          'study_population',
          'sample_size',
          'screening_methods',
          'sampling_methods',
          'inclusion_exclusion_criteria',
          'operational_definitions',
          'flow_chart',
          'material_preparation',
          'nature_of_controls',
          'randomization_blinding',
          'equipment',
          'data_collection',
          'professional_assistance',
          'data_analysis_interpretation',
          'quality_assurance',
          'time_table'
        ];

        const materialMethodsSubmissionKeys: string[] = values.material_methods.map((item: any, index: number) => item.key);
        const materialMethodsSubmissionKeyValues: any = values.material_methods.reduce((acc: any, item: any, index: number) => {
          acc[item.key] = item.value.value;
          return acc;
        }, {});

        // console.log(materialMethodsSubmissionKeys);
        // console.log(materialMethodsSubmissionKeyValues);
        // console.log(objectivesRichtexts);

        materialMethodsKeys.forEach((item: string, index: number) => {
          if (!materialMethodsSubmissionKeys.includes(item)) {
            relevantErrorMessageForMaterialMethods = relevantErrorMessageForMaterialMethods + item.replaceAll('_', ' ') + ', ';
            relevantErrorFlagForMaterialMethods = true;
          } else {
            if (!materialMethodsSubmissionKeyValues[item] || materialMethodsSubmissionKeyValues[item] === 'null' || materialMethodsSubmissionKeyValues[item] === '<p>null</p>') {
              relevantErrorMessageForMaterialMethods = relevantErrorMessageForMaterialMethods + item.replaceAll('_', ' ') + ', ';
              relevantErrorFlagForMaterialMethods = true;
            }
          }
        });

        if (relevantErrorFlagForMaterialMethods) {
          toast.error(relevantErrorMessageForMaterialMethods, { icon: <i className='fas fa-circle-exclamation'></i>, duration: 5000 });
          return;
        }
      }

      // process form data
      const formData = new FormData();
      Object.keys(values).forEach((key) => {

        // attachments
        if (key === 'attachment') {
          for (var i = 0; i < values[key].length; i++) {
            formData.append('attachments[]', JSON.stringify({ ...values[key][i] }));
          }
        } else if (key === sideBarIndex.objectives.objKey) {
          // objectives          
          formData.set(sideBarIndex.objectives.objKey, JSON.stringify(values[key]));
        } else if (key === sideBarIndex.materials_and_methods.objKey) {
          // materials         
          formData.set(sideBarIndex.materials_and_methods.objKey, JSON.stringify(values[key]));
        } else {
          // others          
          formData.append(key, values[key]);
        }

      });

      if (id) {
        let postData = {
          queryUrl: apiUrls.updateUrl,
          formData: formData,
        };

        createByPost(postData);

        // patch
        // const updateUrl = apiUrls.updateUrl + putUrlString(id, formData);
        // updateByPatch(updateUrl);

      } else {
        let postData = {
          queryUrl: apiUrls.createUrl,
          formData: formData,
        };

        createByPost(postData);
      }
    },
  });

  const {
    handleSubmit,
    getFieldProps,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    resetForm,
    setValues,
    setErrors,
    setFieldValue,
  } = formik;

  // side bar
  const ref = useRef<any>();
  const [switchExpressionForSidebar, SetSwitchExpressionForSidebar] = useState(
    sideBarIndexInitialValues.basic_information_of_the_thesis.numberText,
  );
  const [contentTilte, setContentTitle] = useState(
    `${sideBarIndexInitialValues.basic_information_of_the_thesis.numberText}. ${sideBarIndexInitialValues.basic_information_of_the_thesis.text}`,
  );

  // rich text
  const [reRenderState, setReRenderState] = useState<number>(1);
  const [initialContentEditor, setInitialContentEditor] = useState<string>('');
  const [initialContentLoaded, setInitialContentLoaded] = useState<boolean>(false);

  // side bar functionality
  const handleSideBarItemClick = (sidebarNumberText: string) => {
    sideBarIndex = structuredClone(sideBarIndexInitialValues);

    switch (sidebarNumberText) {
      case sideBarIndex.basic_information_of_the_thesis.numberText:
        SetSwitchExpressionForSidebar(
          sideBarIndex.basic_information_of_the_thesis.numberText,
        );
        sideBarIndex.basic_information_of_the_thesis.isSelected = true;
        setContentTitle(
          `${sideBarIndex.basic_information_of_the_thesis.numberText}. ${sideBarIndex.basic_information_of_the_thesis.text}`,
        );

        break;
      case sideBarIndex.study_design.numberText:
        SetSwitchExpressionForSidebar(sideBarIndex.study_design.numberText);
        sideBarIndex.study_design.isSelected = true;
        setContentTitle(
          `${sideBarIndex.study_design.numberText}. ${sideBarIndex.study_design.text}`,
        );

        break;
      case sideBarIndex.introduction_context.numberText:
        SetSwitchExpressionForSidebar(
          sideBarIndex.introduction_context.numberText,
        );
        sideBarIndex.introduction_context.isSelected = true;
        setContentTitle(
          `${sideBarIndex.introduction_context.numberText}. ${sideBarIndex.introduction_context.text}`,
        );

        break;
      case sideBarIndex.rationale_of_research.numberText:
        SetSwitchExpressionForSidebar(
          sideBarIndex.rationale_of_research.numberText,
        );
        sideBarIndex.rationale_of_research.isSelected = true;
        setContentTitle(
          `${sideBarIndex.rationale_of_research.numberText}. ${sideBarIndex.rationale_of_research.text}`,
        );

        break;
      case sideBarIndex.research_question_hypothesis.numberText:
        SetSwitchExpressionForSidebar(
          sideBarIndex.research_question_hypothesis.numberText,
        );
        sideBarIndex.research_question_hypothesis.isSelected = true;
        setContentTitle(
          `${sideBarIndex.research_question_hypothesis.numberText}. ${sideBarIndex.research_question_hypothesis.text}`,
        );

        break;
      case sideBarIndex.objectives.numberText:
        SetSwitchExpressionForSidebar(sideBarIndex.objectives.numberText);
        sideBarIndex.objectives.isSelected = true;
        setContentTitle(
          `${sideBarIndex.objectives.numberText}. ${sideBarIndex.objectives.text}`,
        );

        break;
      case sideBarIndex.materials_and_methods.numberText:
        SetSwitchExpressionForSidebar(
          sideBarIndex.materials_and_methods.numberText,
        );
        sideBarIndex.materials_and_methods.isSelected = true;
        setContentTitle(
          `${sideBarIndex.materials_and_methods.numberText}. ${sideBarIndex.materials_and_methods.text}`,
        );

        break;
      case sideBarIndex.ethical_implication.numberText:
        SetSwitchExpressionForSidebar(
          sideBarIndex.ethical_implication.numberText,
        );
        sideBarIndex.ethical_implication.isSelected = true;
        setContentTitle(
          `${sideBarIndex.ethical_implication.numberText}. ${sideBarIndex.ethical_implication.text}`,
        );

        break;
      case sideBarIndex.total_budget.numberText:
        SetSwitchExpressionForSidebar(sideBarIndex.total_budget.numberText);
        sideBarIndex.total_budget.isSelected = true;
        setContentTitle(
          `${sideBarIndex.total_budget.numberText}. ${sideBarIndex.total_budget.text}`,
        );

        break;
      case sideBarIndex.source_of_funding.numberText:
        SetSwitchExpressionForSidebar(
          sideBarIndex.source_of_funding.numberText,
        );
        sideBarIndex.source_of_funding.isSelected = true;
        setContentTitle(
          `${sideBarIndex.source_of_funding.numberText}. ${sideBarIndex.source_of_funding.text}`,
        );

        break;
      case sideBarIndex.facilities_available_at_the_place_of_the_study
        .numberText:
        SetSwitchExpressionForSidebar(
          sideBarIndex.facilities_available_at_the_place_of_the_study
            .numberText,
        );
        sideBarIndex.facilities_available_at_the_place_of_the_study.isSelected =
          true;
        setContentTitle(
          `${sideBarIndex.facilities_available_at_the_place_of_the_study.numberText}. ${sideBarIndex.facilities_available_at_the_place_of_the_study.text}`,
        );

        break;
      case sideBarIndex.other_facilities_needed.numberText:
        SetSwitchExpressionForSidebar(
          sideBarIndex.other_facilities_needed.numberText,
        );
        sideBarIndex.other_facilities_needed.isSelected = true;
        setContentTitle(
          `${sideBarIndex.other_facilities_needed.numberText}. ${sideBarIndex.other_facilities_needed.text}`,
        );

        break;
      case sideBarIndex.dissemination_and_use_of_findings.numberText:
        SetSwitchExpressionForSidebar(
          sideBarIndex.dissemination_and_use_of_findings.numberText,
        );
        sideBarIndex.dissemination_and_use_of_findings.isSelected = true;
        setContentTitle(
          `${sideBarIndex.dissemination_and_use_of_findings.numberText}. ${sideBarIndex.dissemination_and_use_of_findings.text}`,
        );

        break;
      case sideBarIndex.references.numberText:
        SetSwitchExpressionForSidebar(sideBarIndex.references.numberText);
        sideBarIndex.references.isSelected = true;
        setContentTitle(
          `${sideBarIndex.references.numberText}. ${sideBarIndex.references.text}`,
        );

        break;
      case sideBarIndex.any_other_relevant_info.numberText:
        SetSwitchExpressionForSidebar(
          sideBarIndex.any_other_relevant_info.numberText,
        );
        sideBarIndex.any_other_relevant_info.isSelected = true;
        setContentTitle(
          `${sideBarIndex.any_other_relevant_info.numberText}. ${sideBarIndex.any_other_relevant_info.text}`,
        );

        break;
      case sideBarIndex.signature_of_the_researcher.numberText:
        SetSwitchExpressionForSidebar(
          sideBarIndex.signature_of_the_researcher.numberText,
        );
        sideBarIndex.signature_of_the_researcher.isSelected = true;
        setContentTitle(
          `${sideBarIndex.signature_of_the_researcher.numberText}. ${sideBarIndex.signature_of_the_researcher.text}`,
        );

        break;
      case sideBarIndex.signature_of_the_guide_and_co_guide.numberText:
        SetSwitchExpressionForSidebar(
          sideBarIndex.signature_of_the_guide_and_co_guide.numberText,
        );
        sideBarIndex.signature_of_the_guide_and_co_guide.isSelected = true;
        setContentTitle(
          `${sideBarIndex.signature_of_the_guide_and_co_guide.numberText}. ${sideBarIndex.signature_of_the_guide_and_co_guide.text}`,
        );

        break;
      case sideBarIndex.attachment.numberText:
        SetSwitchExpressionForSidebar(
          sideBarIndex.attachment.numberText,
        );
        sideBarIndex.attachment.isSelected = true;
        setContentTitle(
          `${sideBarIndex.attachment.text}`,
        );

        break;
      case 'submission':
        setProtocolSubmission(true);

        break;
      default:

        break;
    }
  };

  // handel next - previous
  const handleNextButton = (numberText: string) => {
    if (sideBarIndex.objectives.isSelected && objectivesActiveTab === 'general_objectives') {
      setObjectivesActiveTab('specific_objectives');
    } else if (sideBarIndex.materials_and_methods.isSelected && materialMethodsActiveTab !== 'time_table') {
      const currentActiveIndex: number = materialMethodLabels.indexOf(materialMethodsActiveTab);
      setMaterialMethodsActiveTab(materialMethodLabels[currentActiveIndex + 1]);
    } else {
      if (numberText !== 'submission') {
        const isNumtext = (element: any) => element === numberText;
        let targetIndex = arrayOfSideBaritems.findIndex(isNumtext);
        let nextNumberText = arrayOfSideBaritems[targetIndex + 1];
        handleSideBarItemClick(nextNumberText);
      } else {
        handleSideBarItemClick(numberText);
      }
    }
  };
  const handlePreviousButton = (numberText: string) => {
    if (sideBarIndex.objectives.isSelected && objectivesActiveTab === 'specific_objectives') {
      setObjectivesActiveTab('general_objectives');
    } else if (sideBarIndex.materials_and_methods.isSelected && materialMethodsActiveTab !== 'main_outcome') {
      const currentActiveIndex: number = materialMethodLabels.indexOf(materialMethodsActiveTab);
      setMaterialMethodsActiveTab(materialMethodLabels[currentActiveIndex - 1]);
    } else {
      const isNumtext = (element: any) => element === numberText;
      let targetIndex = arrayOfSideBaritems.findIndex(isNumtext);
      if (targetIndex !== 0) {
        let nextNumberText = arrayOfSideBaritems[targetIndex - 1];
        handleSideBarItemClick(nextNumberText);
      }
    }
  };

  // attachments
  const [attachmentInputList, setAttachmentInputList] = useState<any>([]);

  // objectives
  const [objectivesRichtexts, setObjectivesRichtexts] = useState<any>([]);

  const [isTitleApproved, setIsTitleApproved] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  // api communications

  // useEffect based on fetching of relevant data

  useEffect(() => {

    if (existingProtocoLoadingError) {
      toast.dismiss();
      // toast.success('Data not found. Please create first.');
      setInitialContentLoaded(true);
    } else if (existingProtocoLoading || !existingProtocoLoadingSuccess) {
      toast.dismiss();
    } else if (existingProtocoLoadingSuccess) {
      let existingProtocolData = existingProtocol?.data || false;
      // console.log(existingProtocolData);
      if (existingProtocolData['id']) {
        setId(existingProtocolData['id']);
        let objectivesJsonObject, materialsJsonIbject, attachmentJsonObject;
        // process received existing protocol
        // console.log(' before set value formik values', formik.values);

        Object.keys(formik.values).forEach((key) => {
          // console.log('protocol values: key : ', existingProtocolData[key]);

          if (key === sideBarIndex.objectives.objKey) {
            objectivesJsonObject = JSON.parse(existingProtocolData[key]);
            setFieldValue(key, JSON.parse(existingProtocolData[key]));
            objectivesJsonObject.forEach((objective: any) => {
              objectivesRichtexts.push({
                parent: objective.value.parent,
                value: objective.value.value,
                name: objective.value.name,
              });
            });

          } else if (key === sideBarIndex.materials_and_methods.objKey) {
            materialsJsonIbject = JSON.parse(existingProtocolData[key]);
            setFieldValue(key, JSON.parse(existingProtocolData[key]));
            materialsJsonIbject.forEach((material: any) => {
              objectivesRichtexts.push({
                parent: material.value.parent,
                value: material.value.value,
                name: material.value.name,
              });
            });
          } else if (key === 'attachments') {
            const values: any = [];

            existingProtocolData['protocol_attachments']?.forEach((attachment: any) => {
              values.push({
                attachmentType: attachment.document_type,
                nameOfTheDocument: attachment.document_type,
                attachmentFile: `${attachment.document_name}`,

              });
            });

            setAttachmentInputList(values);

            console.log('values', values);


          } else {
            setFieldValue(key, existingProtocolData[key]);
            // console.log(existingProtocolData[key]);
          }

        });

        // const values = [...attachmentInputList];
        // setAttachmentInputList(values);

        // attachmentJsonObject = existingProtocolData['protocol_attachments'];

        // if(attachmentJsonObject.length > 0){

        //     attachmentJsonObject.map((attachment: any) => {
        //         values.push({
        //             attachmentType: attachment.document_type,
        //             nameOfTheDocument: attachment.document_type,
        //             attachmentFile: attachment.document_name

        //         });
        //     });
        // }

        // console.log('values', values);

        //   setValues(initialValues);
        formik.setFieldValue(sideBarIndex.attachment.ObjKey, initialValues.attachments);
        formik.setFieldValue(sideBarIndex.total_budget.total_budget_objKey, 0);

        if (existingProtocol?.data?.submission_status === 'Approve') {
          setTypeOfNote('Approval');
        } else if (existingProtocol?.data?.submission_status === 'Correction') {
          setTypeOfNote('Correction');
        } else if (existingProtocol?.data?.submission_status === 'Reject') {
          setTypeOfNote('Rejection');
        } else {
          setTypeOfNote('Submission');
        }


        //   getCommentsData(vData['id']);
        //   setViewData(vData);
        //   setShowForm(false);


        //   inputFields.findIndex((item: any) => {
        //     setFieldValue(item.name, vData[item.name] || '');
        //   })
        // setFieldValue("id", vData["id"] || '');


        //   Object.keys(vData).map((itemKey: any) => {
        //     console.log('itemKey' ,itemKey);
        //     setFieldValue(itemKey, vData[itemKey] || '');
        //   });        
      } else {
        setId("NoSummary");
        setInitialContentLoaded(true);
      }
      toast.dismiss();
    }

    if (titleLoadingSuccess) {
      setFieldValue('place_of_study', title.data.place_of_study ?? '');
      setFieldValue('study_period', title.data.study_period ?? '');
      if (title?.data.submission_status === 'Approve') {
        setIsTitleApproved(true);
      } else {
        setIsTitleApproved(false);
      }

    }

  }, [existingProtocoLoading, existingProtocoLoadingSuccess, existingProtocoLoadingError, titleLoadingSuccess]);

  const [createByPost, {
    data: protocolSubmissionResponseData,
    isSuccess: isProtocolSubmissionSucces,
    isLoading: isProtocolSubmissionLoading,
    isError: isProtocolSubmissionError,
    error: protocolSubmissionError,
  }] =
    useCreateByPostMutation();
  const [
    updateByPatch,
    {
      isSuccess: isUpdateSuccess,
      isLoading: isUpdateLoading,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateByPatchMutation();


  useEffect(() => {
    if (isProtocolSubmissionError) {
      toast.dismiss();
      // const errorMessage =  protocolSubmissionError?.message || 'Error saving protocol';      
      if (!(protocolSubmissionError as any)?.success) {
        toast.error((protocolSubmissionError as any)?.message || 'Something went wrong');
      } else {
        toast.error('Something went wrong');
      }
      setShowUpdateButton(false);
      setShowEditButton(true);
    } else if (isProtocolSubmissionLoading || !isProtocolSubmissionSucces) {
      toast.dismiss();
    } else if (isProtocolSubmissionSucces) {
      if (contentTilte !== `${sideBarIndex.attachment.text}`) {
        const itemIndex: number = Object.values(sideBarIndex)?.findIndex((item: any, index: number) => contentTilte === `${item.numberText}. ${item.text}`)!;
        toast.success(`${(Object.values(sideBarIndex)?.[itemIndex - 1] as any)?.text} saved succesfully`);
      } else {
        if (!protocolSubmission) {
          const itemIndex: number = Object.values(sideBarIndex)?.findIndex((item: any, index: number) => contentTilte === `${item.text}`)!;
          toast.success(`${(Object.values(sideBarIndex)?.[itemIndex - 1] as any)?.text} saved succesfully`);
        } else {
          toast.success(`Attachment saved succesfully`);
        }
      }
      setShowUpdateButton(false);
      setShowEditButton(true);
    }

  }, [isProtocolSubmissionSucces, isProtocolSubmissionError, isProtocolSubmissionLoading]);

  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [showEditButton, setShowEditButton] = useState(true);

  const handleEditButton = () => {
    setShowUpdateButton(true);
    setShowEditButton(false);
  };

  const handleModal = () => {
    setModalShow(true);
  };

  useEffect(() => {
    // console.log('title', title);
  }, [title]);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BetterBreadcrumb
            title='Protocol Entry'
            breadcrumbItem={[{ link: '/', name: 'Dashboard' }]}
          />
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <p className='header-basic-info'>Name: {currentUser.name}</p>
                  {/* <div className="d-flex">
                                            <label htmlFor="basic-info-name">Name:</label>
                                            <p id="basic-info-name" className="ml-1">MD. ZAHIRUL ISLAM</p>
                                        </div> */}
                </Col>
                <Col>
                  <p className='header-basic-info'>BMDC Reg. No: {currentUser.details.bmdc_registration_no}</p>
                  {/* <div className="d-flex">
                                            <label htmlFor="basic-info-name">BMDC Reg. No:</label>
                                            <p id="basic-info-name" className="ml-1">111111111</p>
                                        </div> */}
                </Col>
                {/* Prof. DR. Khaled Masud
                            Asst. Prof. Hasanuzzaman Khan{' '}
                            */}
                <Col>
                  <p className='header-basic-info'>
                    Guide: {title?.data.guide_name || 'N/A'}
                  </p>
                  {/* <div className="d-flex">
                                            <label htmlFor="basic-info-name">Guide:</label>
                                            <p id="basic-info-name" className="ml-1">Prof. DR. Khaled Masud</p>
                                        </div> */}
                </Col>
                <Col>
                  <p className='header-basic-info'>
                    Co-Guide: {title?.data?.co_guide_1_name ? `${title?.data?.co_guide_1_name}` : ``}
                    {title?.data?.co_guide_2_name ? `, ${title?.data?.co_guide_2_name}` : ``}
                    {title?.data?.co_guide_3_name ? `, ${title?.data?.co_guide_3_name}` : ``}
                  </p>
                  {/* <div className="d-flex">
                                            <label htmlFor="basic-info-name">Co-Guide:</label>
                                            <p id="basic-info-name" className="ml-1">Asst. Prof. Hasanuzzaman Khan</p>
                                        </div> */}
                </Col>
                <Col>
                  <p>Protocol No: <b>{existingProtocol?.data?.protocol_no ?? '-'}</b></p>
                </Col>
                <Col>
                  {existingProtocol?.data?.submission_status === 'Submit' ? (
                    <p className='header-basic-info'>
                      Submission Status: <br /><span
                        className='badge rounded-pill d-inline-block badge-applied font-size-12 fw-medium'
                        onClick={(e) => {
                          if (existingProtocol?.data?.admin_approval_note ||
                            existingProtocol?.data?.guide_approval_note ||
                            existingProtocol?.data?.co_guide1_approval_note ||
                            existingProtocol?.data?.co_guide2_approval_note ||
                            existingProtocol?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}> {(existingProtocol?.data?.admin_approval_status?.toLowerCase() === 'correction' ||
                          existingProtocol?.data?.guide_approval_status?.toLowerCase() === 'correction' ||
                          existingProtocol?.data?.co_guide1_approval_status?.toLowerCase() === 'correction' ||
                          existingProtocol?.data?.co_guide2_approval_status?.toLowerCase() === 'correction' ||
                          existingProtocol?.data?.co_guide3_approval_status?.toLowerCase() === 'correction') ?
                          'Resubmitted' : 'Submitted'}</span>
                    </p>

                  ) : existingProtocol?.data?.submission_status === 'Approve' ? (
                    <p className='header-basic-info'>
                      Submission Status: <br /><span
                        className='badge rounded-pill badge-approved font-size-12 fw-medium'
                        onClick={(e) => {
                          if (existingProtocol?.data?.admin_approval_note ||
                            existingProtocol?.data?.guide_approval_note ||
                            existingProtocol?.data?.co_guide1_approval_note ||
                            existingProtocol?.data?.co_guide2_approval_note ||
                            existingProtocol?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}>Approved</span>
                    </p>


                  ) : existingProtocol?.data?.submission_status === 'Reject' ? (
                    <p className='header-basic-info'>
                      Submission Status: <br /><span
                        className='badge rounded-pill badge-rejected font-size-12 fw-medium'
                        onClick={(e) => {
                          if (existingProtocol?.data?.admin_approval_note ||
                            existingProtocol?.data?.guide_approval_note ||
                            existingProtocol?.data?.co_guide1_approval_note ||
                            existingProtocol?.data?.co_guide2_approval_note ||
                            existingProtocol?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}>Rejected</span>
                    </p>

                  ) : existingProtocol?.data?.submission_status === 'Correction' ? (
                    <p className='header-basic-info'>
                      Submission Status: <br /><span
                        className='badge rounded-pill badge-correction font-size-12 fw-medium'
                        onClick={(e) => {
                          if (existingProtocol?.data?.admin_approval_note ||
                            existingProtocol?.data?.guide_approval_note ||
                            existingProtocol?.data?.co_guide1_approval_note ||
                            existingProtocol?.data?.co_guide2_approval_note ||
                            existingProtocol?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}>Correction</span>
                    </p>

                  ) : existingProtocol?.data?.submission_status === 'Draft' ? (
                    <p className='header-basic-info'>
                      Submission Status: <br /><span
                        className={
                          (existingProtocol?.data?.admin_approval_status?.toLowerCase() === 'correction' ||
                            existingProtocol?.data?.guide_approval_status?.toLowerCase() === 'correction' ||
                            existingProtocol?.data?.co_guide1_approval_status?.toLowerCase() === 'correction' ||
                            existingProtocol?.data?.co_guide2_approval_status?.toLowerCase() === 'correction' ||
                            existingProtocol?.data?.co_guide3_approval_status?.toLowerCase() === 'correction') ?
                            'badge rounded-pill badge-correction font-size-12 fw-medium'
                            :
                            'badge rounded-pill badge-draft font-size-12 fw-medium'
                        }
                        onClick={(e) => {
                          if (existingProtocol?.data?.admin_approval_note ||
                            existingProtocol?.data?.guide_approval_note ||
                            existingProtocol?.data?.co_guide1_approval_note ||
                            existingProtocol?.data?.co_guide2_approval_note ||
                            existingProtocol?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}>{(existingProtocol?.data?.admin_approval_status?.toLowerCase() === 'correction' ||
                          existingProtocol?.data?.guide_approval_status?.toLowerCase() === 'correction' ||
                          existingProtocol?.data?.co_guide1_approval_status?.toLowerCase() === 'correction' ||
                          existingProtocol?.data?.co_guide2_approval_status?.toLowerCase() === 'correction' ||
                          existingProtocol?.data?.co_guide3_approval_status?.toLowerCase() === 'correction') ?
                          'Correction' : 'Draft'}</span>
                    </p>


                  ) : (
                    <p className='header-basic-info'>
                      Submission Status: <br /><span
                        className='badge rounded-pill badge-draft font-size-12 fw-medium'
                        onClick={(e) => {
                          if (existingProtocol?.data?.admin_approval_note ||
                            existingProtocol?.data?.guide_approval_note ||
                            existingProtocol?.data?.co_guide1_approval_note ||
                            existingProtocol?.data?.co_guide2_approval_note ||
                            existingProtocol?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}>{existingProtocol?.data?.submission_status ?? 'Not Yet Submitted'}</span>
                    </p>
                  )
                  }

                  {/* <div className="d-flex">
                                            <label htmlFor="basic-info-name">Protocol Status:</label>
                                            <p id="basic-info-name" className="ml-1">Not Yet Submitted</p>
                                        </div> */}
                </Col>
                <Col>
                  {existingProtocol?.data?.admin_approval_status === 'Submit' ? (
                    <p className='header-basic-info'>
                      Admin Approval Status: <br /><span
                        className='badge rounded-pill d-inline-block badge-applied font-size-12 fw-medium'
                        onClick={(e) => {
                          if (existingProtocol?.data?.admin_approval_note ||
                            existingProtocol?.data?.guide_approval_note ||
                            existingProtocol?.data?.co_guide1_approval_note ||
                            existingProtocol?.data?.co_guide2_approval_note ||
                            existingProtocol?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}>Submitted</span>
                    </p>

                  ) : existingProtocol?.data?.admin_approval_status === 'Approve' ? (
                    <p className='header-basic-info'>
                      Admin Approval Status: <br /><span
                        className='badge rounded-pill badge-approved font-size-12 fw-medium'
                        onClick={(e) => {
                          if (existingProtocol?.data?.admin_approval_note ||
                            existingProtocol?.data?.guide_approval_note ||
                            existingProtocol?.data?.co_guide1_approval_note ||
                            existingProtocol?.data?.co_guide2_approval_note ||
                            existingProtocol?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}>Approved</span>
                    </p>


                  ) : existingProtocol?.data?.admin_approval_status === 'Reject' ? (
                    <p className='header-basic-info'>
                      Admin Approval Status: <br /><span
                        className='badge rounded-pill badge-rejected font-size-12 fw-medium'
                        onClick={(e) => {
                          if (existingProtocol?.data?.admin_approval_note ||
                            existingProtocol?.data?.guide_approval_note ||
                            existingProtocol?.data?.co_guide1_approval_note ||
                            existingProtocol?.data?.co_guide2_approval_note ||
                            existingProtocol?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}>Rejected</span>
                    </p>

                  ) : existingProtocol?.data?.admin_approval_status === 'Correction' ? (
                    <p className='header-basic-info'>
                      Admin Approval Status: <br /><span
                        className='badge rounded-pill badge-correction font-size-12 fw-medium'
                        onClick={(e) => {
                          if (existingProtocol?.data?.admin_approval_note ||
                            existingProtocol?.data?.guide_approval_note ||
                            existingProtocol?.data?.co_guide1_approval_note ||
                            existingProtocol?.data?.co_guide2_approval_note ||
                            existingProtocol?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}>Correction</span>
                    </p>

                  ) : (
                    <p className='header-basic-info'>
                      Admin Approval Status: <br /><span
                        className='badge rounded-pill badge-draft font-size-12 fw-medium'
                        onClick={(e) => {
                          if (existingProtocol?.data?.admin_approval_note ||
                            existingProtocol?.data?.guide_approval_note ||
                            existingProtocol?.data?.co_guide1_approval_note ||
                            existingProtocol?.data?.co_guide2_approval_note ||
                            existingProtocol?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}>{existingProtocol?.data?.admin_approval_status ?? 'Pending'}</span>
                    </p>
                  )
                  }

                  {/* <div className="d-flex">
                                            <label htmlFor="basic-info-name">Protocol Status:</label>
                                            <p id="basic-info-name" className="ml-1">Not Yet Submitted</p>
                                        </div> */}
                </Col>
              </Row>
              <Row>
                <p className='header-basic-info-title'>
                  Title: {title?.data?.title_of_thesis || 'N/A'}
                </p>
                {/* <div className="d-flex">
                                        <label htmlFor="basic-info-name">Title:</label>
                                        <p id="basic-info-name" className="ml-1">Study of saline hysterosalpingography infertile infussion to see the senography tubal patency</p>
                                    </div> */}
              </Row>
            </CardBody>
          </Card>

          <Row>
            {/* side bar                */}
            <Col md={12}>
              {/* side bar */}
              <div className='protocol-entry-tab-wrapper'>
                <div className='protocol-entry-tab-nav'>
                  <SimpleBar style={{ maxHeight: '100%' }}>
                    <div className='protocol-entry-sidebar-menu'>
                      <ul>
                        <li
                          className={sideBarIndex.basic_information_of_the_thesis.isSelected ? 'menu-title-protocol-entry clicked' : 'menu-title-protocol-entry'}
                          onClick={() =>
                            handleSideBarItemClick(
                              sideBarIndex.basic_information_of_the_thesis
                                .numberText,
                            )
                          }
                        >
                          <div className='protocol-entry-badge'>
                            1 - 8
                          </div>
                          <div
                            className={sideBarIndex.basic_information_of_the_thesis.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                            Basic information of the thesis
                          </div>

                        </li>
                        <li
                          className={sideBarIndex.study_design.isSelected ? 'menu-title-protocol-entry clicked' : 'menu-title-protocol-entry '}
                          onClick={() =>
                            handleSideBarItemClick(
                              sideBarIndex.study_design.numberText,
                            )
                          }
                        >

                          <div className='protocol-entry-badge'>
                            9
                          </div>
                          <div
                            className={sideBarIndex.study_design.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                            Study Design
                          </div>

                        </li>
                        <li
                          className={sideBarIndex.introduction_context.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                          onClick={() =>
                            handleSideBarItemClick(
                              sideBarIndex.introduction_context.numberText,
                            )
                          }
                        >

                          <div className='protocol-entry-badge'>10</div>
                          <div
                            className={sideBarIndex.introduction_context.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                            {sideBarIndex.introduction_context.text}
                          </div>

                        </li>
                        <li
                          className={sideBarIndex.rationale_of_research.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                          onClick={() =>
                            handleSideBarItemClick(
                              sideBarIndex.rationale_of_research.numberText,
                            )
                          }
                        >

                          <div className='protocol-entry-badge'>11</div>
                          <div
                            className={sideBarIndex.rationale_of_research.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                            Rationale of Research

                          </div>
                        </li>
                        <li
                          className={sideBarIndex.research_question_hypothesis.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                          onClick={() =>
                            handleSideBarItemClick(
                              sideBarIndex.research_question_hypothesis
                                .numberText,
                            )
                          }
                        >

                          <div className='protocol-entry-badge'>12</div>
                          <div
                            className={sideBarIndex.research_question_hypothesis.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                            Research Question/Hypothesis
                          </div>

                        </li>
                        <li
                          className={sideBarIndex.objectives.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                          onClick={() =>
                            handleSideBarItemClick(
                              sideBarIndex.objectives.numberText,
                            )
                          }
                        >

                          <div className='protocol-entry-badge'>13</div>
                          <div
                            className={sideBarIndex.objectives.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>Objectives
                          </div>

                        </li>
                        <li
                          className={sideBarIndex.materials_and_methods.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                          onClick={() =>
                            handleSideBarItemClick(
                              sideBarIndex.materials_and_methods.numberText,
                            )
                          }
                        >

                          <div className='protocol-entry-badge'>14</div>
                          <div
                            className={sideBarIndex.materials_and_methods.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                            Materials and Methods
                          </div>

                        </li>
                        <li
                          className={sideBarIndex.ethical_implication.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                          onClick={() =>
                            handleSideBarItemClick(
                              sideBarIndex.ethical_implication.numberText,
                            )
                          }
                        >

                          <div className='protocol-entry-badge'>15</div>
                          <div
                            className={sideBarIndex.ethical_implication.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                            Ethical Implecation
                          </div>

                        </li>
                        <li
                          className={sideBarIndex.total_budget.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                          onClick={() =>
                            handleSideBarItemClick(
                              sideBarIndex.total_budget.numberText,
                            )
                          }
                        >

                          <div className='protocol-entry-badge'>16</div>
                          <div
                            className={sideBarIndex.total_budget.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                            Total Budget
                          </div>

                        </li>
                        <li
                          className={sideBarIndex.source_of_funding.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                          onClick={() =>
                            handleSideBarItemClick(
                              sideBarIndex.source_of_funding.numberText,
                            )
                          }
                        >

                          <div className='protocol-entry-badge'>17</div>
                          <div
                            className={sideBarIndex.source_of_funding.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                            Source(s) of Finding(if any)
                          </div>

                        </li>
                        <li
                          className={sideBarIndex.facilities_available_at_the_place_of_the_study.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                          onClick={() =>
                            handleSideBarItemClick(
                              sideBarIndex
                                .facilities_available_at_the_place_of_the_study
                                .numberText,
                            )
                          }
                        >

                          <div className='protocol-entry-badge'>18</div>
                          <div
                            className={sideBarIndex.facilities_available_at_the_place_of_the_study.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                            Facilities available at the place of Study
                          </div>

                        </li>
                        <li
                          className={sideBarIndex.other_facilities_needed.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                          onClick={() =>
                            handleSideBarItemClick(
                              sideBarIndex.other_facilities_needed.numberText,
                            )
                          }
                        >

                          <div className='protocol-entry-badge'>19</div>
                          <div
                            className={sideBarIndex.other_facilities_needed.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                            Other facilities needed
                          </div>

                        </li>
                        <li
                          className={sideBarIndex.dissemination_and_use_of_findings.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                          onClick={() =>
                            handleSideBarItemClick(
                              sideBarIndex.dissemination_and_use_of_findings
                                .numberText,
                            )
                          }
                        >

                          <div className='protocol-entry-badge'>20</div>
                          <div
                            className={sideBarIndex.dissemination_and_use_of_findings.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                            Dissemination and use of findings
                          </div>

                        </li>
                        <li
                          className={sideBarIndex.references.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                          onClick={() =>
                            handleSideBarItemClick(
                              sideBarIndex.references.numberText,
                            )
                          }
                        >

                          <div className='protocol-entry-badge'>21</div>
                          <div
                            className={sideBarIndex.references.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>References
                          </div>

                        </li>
                        <li
                          className={sideBarIndex.any_other_relevant_info.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                          onClick={() =>
                            handleSideBarItemClick(
                              sideBarIndex.any_other_relevant_info.numberText,
                            )
                          }
                        >

                          <div className='protocol-entry-badge'>22</div>
                          <div
                            className={sideBarIndex.any_other_relevant_info.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                            Any other relavent information
                          </div>

                        </li>
                        <li
                          className={sideBarIndex.signature_of_the_researcher.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                          onClick={() =>
                            handleSideBarItemClick(
                              sideBarIndex.signature_of_the_researcher
                                .numberText,
                            )
                          }
                        >

                          <div className='protocol-entry-badge'>23</div>
                          <div
                            className={sideBarIndex.signature_of_the_researcher.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                            Signature of the Researcher
                          </div>

                        </li>
                        <li
                          className={sideBarIndex.signature_of_the_guide_and_co_guide.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                          onClick={() =>
                            handleSideBarItemClick(
                              sideBarIndex.signature_of_the_guide_and_co_guide
                                .numberText,
                            )
                          }
                        >

                          <div className='protocol-entry-badge'>24</div>
                          <div
                            className={sideBarIndex.signature_of_the_guide_and_co_guide.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                            Signature of the Guide and Co-Guide
                          </div>

                        </li>
                        <li
                          className={sideBarIndex.attachment.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                          onClick={() =>
                            handleSideBarItemClick(
                              sideBarIndex.attachment
                                .numberText,
                            )
                          }
                        >

                          <div className='protocol-entry-badge'><i className='fa-solid fa-paperclip' /></div>
                          <div
                            className={sideBarIndex.attachment.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                            Attachment
                          </div>

                        </li>
                      </ul>
                    </div>
                  </SimpleBar>
                </div>
                <Card className='protocol-entry-tab-content'>
                  {/* load component dynamically */}
                  <CardBody>
                    <p className='protocol-entry-legend'>{contentTilte}</p>
                    <Form
                      autoComplete='off'
                      className='custom-form'
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >


                      {switchExpressionForSidebar ===
                        sideBarIndex.basic_information_of_the_thesis.numberText &&
                        (formik.values?.['summary'] || initialContentLoaded) ? (
                        <>
                          <BasicInformation
                            setObjectivesRichtexts={setObjectivesRichtexts}
                            objectivesRichtexts={objectivesRichtexts}
                            existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                            title={titleLoadingSuccess ? title : { message: 'null' }}
                            formik={formik}
                            commentsDataList={commentsDataList}
                            personalProfile={personalProfile}
                            initialContentEditor={initialContentEditor}
                            setInitialContentEditor={setInitialContentEditor}
                            formikField={sideBarIndex.basic_information_of_the_thesis.objKey}
                            contentValidationMessage={contentValidationMessage}
                            setContentValidationMessage={setContentValidationMessage}
                            renderKey={reRenderState}
                          />
                        </>
                      ) : switchExpressionForSidebar ===
                        sideBarIndex.study_design.numberText ? (
                        <StudyDesign
                          setObjectivesRichtexts={setObjectivesRichtexts}
                          objectivesRichtexts={objectivesRichtexts}
                          existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                          formikState={formik.values}
                          commentsDataList={commentsDataList}
                          initialContentEditor={initialContentEditor}
                          setInitialContentEditor={setInitialContentEditor}
                          formikField={sideBarIndex.study_design.objKey}
                          renderKey={reRenderState}
                        />
                      ) : switchExpressionForSidebar ===
                        sideBarIndex.introduction_context.numberText ? (
                        <SimpleComponentWithRichTextEditor
                          setObjectivesRichtexts={setObjectivesRichtexts}
                          objectivesRichtexts={objectivesRichtexts}
                          existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                          formikState={formik.values}
                          commentsDataList={commentsDataList}
                          initialContentEditor={initialContentEditor}
                          setInitialContentEditor={setInitialContentEditor}
                          formikField={sideBarIndex.introduction_context.objKey}
                          fieldLabel={sideBarIndex.introduction_context.text}
                          renderKey={reRenderState}
                          key={sideBarIndex.introduction_context.numberText}
                        />
                      ) : switchExpressionForSidebar ===
                        sideBarIndex.rationale_of_research.numberText ? (
                        <SimpleComponentWithRichTextEditor
                          setObjectivesRichtexts={setObjectivesRichtexts}
                          objectivesRichtexts={objectivesRichtexts}
                          existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                          formikState={formik.values}
                          commentsDataList={commentsDataList}
                          initialContentEditor={initialContentEditor}
                          setInitialContentEditor={setInitialContentEditor}
                          formikField={sideBarIndex.rationale_of_research.objKey}
                          fieldLabel={sideBarIndex.rationale_of_research.text}
                          renderKey={reRenderState}
                          key={sideBarIndex.rationale_of_research.numberText}
                        />
                      ) : switchExpressionForSidebar ===
                        sideBarIndex.research_question_hypothesis.numberText ? (
                        <SimpleComponentWithRichTextEditor
                          setObjectivesRichtexts={setObjectivesRichtexts}
                          objectivesRichtexts={objectivesRichtexts}
                          existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                          formikState={formik.values}
                          commentsDataList={commentsDataList}
                          initialContentEditor={initialContentEditor}
                          setInitialContentEditor={setInitialContentEditor}
                          formikField={
                            sideBarIndex.research_question_hypothesis.objKey
                          }
                          fieldLabel={sideBarIndex.research_question_hypothesis.text}
                          renderKey={reRenderState}
                          key={
                            sideBarIndex.research_question_hypothesis.numberText
                          }
                        />
                      ) : switchExpressionForSidebar ===
                        sideBarIndex.ethical_implication.numberText ? (
                        <SimpleComponentWithRichTextEditor
                          setObjectivesRichtexts={setObjectivesRichtexts}
                          objectivesRichtexts={objectivesRichtexts}
                          existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                          formikState={formik.values}
                          commentsDataList={commentsDataList}
                          initialContentEditor={initialContentEditor}
                          setInitialContentEditor={setInitialContentEditor}
                          formikField={sideBarIndex.ethical_implication.objKey}
                          fieldLabel={sideBarIndex.ethical_implication.text}
                          renderKey={reRenderState}
                          key={sideBarIndex.ethical_implication.numberText}
                        />
                      ) : switchExpressionForSidebar ===
                        sideBarIndex.total_budget.numberText ? (
                        <SimpleComponentWithRichTextEditor
                          setObjectivesRichtexts={setObjectivesRichtexts}
                          objectivesRichtexts={objectivesRichtexts}
                          existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                          formikState={formik.values}
                          commentsDataList={commentsDataList}
                          initialContentEditor={initialContentEditor}
                          setInitialContentEditor={setInitialContentEditor}
                          formikField={sideBarIndex.total_budget.objKey}
                          fieldLabel={sideBarIndex.total_budget.text}
                          renderKey={reRenderState}
                          key={sideBarIndex.total_budget.numberText}
                        />
                      ) : switchExpressionForSidebar ===
                        sideBarIndex.source_of_funding.numberText ? (
                        <SimpleComponentWithRichTextEditor
                          setObjectivesRichtexts={setObjectivesRichtexts}
                          objectivesRichtexts={objectivesRichtexts}
                          existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                          formikState={formik.values}
                          commentsDataList={commentsDataList}
                          initialContentEditor={initialContentEditor}
                          setInitialContentEditor={setInitialContentEditor}
                          formikField={sideBarIndex.source_of_funding.objKey}
                          fieldLabel={sideBarIndex.source_of_funding.text}
                          renderKey={reRenderState}
                          key={sideBarIndex.source_of_funding.numberText}
                        />
                      ) : switchExpressionForSidebar ===
                        sideBarIndex
                          .facilities_available_at_the_place_of_the_study
                          .numberText ? (
                        <SimpleComponentWithRichTextEditor
                          setObjectivesRichtexts={setObjectivesRichtexts}
                          objectivesRichtexts={objectivesRichtexts}
                          existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                          formikState={formik.values}
                          commentsDataList={commentsDataList}
                          initialContentEditor={initialContentEditor}
                          setInitialContentEditor={setInitialContentEditor}
                          formikField={
                            sideBarIndex
                              .facilities_available_at_the_place_of_the_study
                              .objKey
                          }
                          fieldLabel={sideBarIndex.facilities_available_at_the_place_of_the_study.text}
                          renderKey={reRenderState}
                          key={
                            sideBarIndex
                              .facilities_available_at_the_place_of_the_study
                              .numberText
                          }
                        />
                      ) : switchExpressionForSidebar ===
                        sideBarIndex.other_facilities_needed.numberText ? (
                        <SimpleComponentWithRichTextEditor
                          formikState={formik.values}
                          setObjectivesRichtexts={setObjectivesRichtexts}
                          objectivesRichtexts={objectivesRichtexts}
                          existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                          initialContentEditor={initialContentEditor}
                          setInitialContentEditor={setInitialContentEditor}
                          formikField={
                            sideBarIndex.other_facilities_needed.objKey
                          }
                          commentsDataList={commentsDataList}
                          fieldLabel={sideBarIndex.other_facilities_needed.text}
                          renderKey={reRenderState}
                          key={sideBarIndex.other_facilities_needed.numberText}
                        />
                      ) : switchExpressionForSidebar ===
                        sideBarIndex.dissemination_and_use_of_findings
                          .numberText ? (
                        <SimpleComponentWithRichTextEditor
                          setObjectivesRichtexts={setObjectivesRichtexts}
                          objectivesRichtexts={objectivesRichtexts}
                          existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                          formikState={formik.values}
                          initialContentEditor={initialContentEditor}
                          setInitialContentEditor={setInitialContentEditor}
                          formikField={
                            sideBarIndex.dissemination_and_use_of_findings.objKey
                          }
                          commentsDataList={commentsDataList}
                          fieldLabel={sideBarIndex.dissemination_and_use_of_findings.text}
                          renderKey={reRenderState}
                          key={
                            sideBarIndex.dissemination_and_use_of_findings
                              .numberText
                          }
                        />
                      ) : switchExpressionForSidebar ===
                        sideBarIndex.references.numberText ? (
                        <SimpleComponentWithRichTextEditor
                          setObjectivesRichtexts={setObjectivesRichtexts}
                          objectivesRichtexts={objectivesRichtexts}
                          existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                          formikState={formik.values}
                          initialContentEditor={initialContentEditor}
                          setInitialContentEditor={setInitialContentEditor}
                          formikField={sideBarIndex.references.objKey}
                          commentsDataList={commentsDataList}
                          fieldLabel={sideBarIndex.references.text}
                          renderKey={reRenderState}
                          key={sideBarIndex.references.numberText}
                        />
                      ) : switchExpressionForSidebar ===
                        sideBarIndex.any_other_relevant_info.numberText ? (
                        <SimpleComponentWithRichTextEditor
                          setObjectivesRichtexts={setObjectivesRichtexts}
                          objectivesRichtexts={objectivesRichtexts}
                          existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                          formikState={formik.values}
                          commentsDataList={commentsDataList}
                          initialContentEditor={initialContentEditor}
                          setInitialContentEditor={setInitialContentEditor}
                          formikField={
                            sideBarIndex.any_other_relevant_info.objKey
                          }
                          fieldLabel={sideBarIndex.any_other_relevant_info.text}
                          renderKey={reRenderState}
                          key={sideBarIndex.any_other_relevant_info.numberText}
                        />
                      ) : switchExpressionForSidebar === sideBarIndex.objectives.numberText ? (
                        <Objectives
                          setObjectivesRichtexts={setObjectivesRichtexts}
                          objectivesRichtexts={objectivesRichtexts}
                          existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                          objectives={sideBarIndex.objectives}
                          formikState={formik.values}
                          commentsDataList={commentsDataList}
                          initialContentEditor={initialContentEditor}
                          setInitialContentEditor={setInitialContentEditor}
                          activeTab={objectivesActiveTab}
                          setActiveTab={setObjectivesActiveTab}
                          renderKey={reRenderState}
                          key={sideBarIndex.objectives.numberText}
                        />
                      ) : switchExpressionForSidebar === sideBarIndex.materials_and_methods.numberText ? (
                        <MaterialAndMethods
                          setObjectivesRichtexts={setObjectivesRichtexts}
                          objectivesRichtexts={objectivesRichtexts}
                          existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                          materialsAndMethods={sideBarIndex.materials_and_methods}
                          formikState={formik.values}
                          commentsDataList={commentsDataList}
                          initialContentEditor={initialContentEditor}
                          setInitialContentEditor={setInitialContentEditor}
                          activeTab={materialMethodsActiveTab}
                          setActiveTab={setMaterialMethodsActiveTab}
                          renderKey={reRenderState}
                          key={sideBarIndex.materials_and_methods.numberText}
                        />

                      ) : switchExpressionForSidebar === sideBarIndex.attachment.numberText ?
                        (

                          <Attachments
                            key={reRenderState}
                            attachmentInputList={attachmentInputList}
                            setInputList={setAttachmentInputList}
                            existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                            commentsDataList={commentsDataList}
                          />

                        ) : switchExpressionForSidebar === sideBarIndex.signature_of_the_researcher.numberText ?
                          (
                            <ResearcherSignature
                              formik={formik}
                              personalProfile={personalProfile}
                              existingProtocol={existingProtocol}
                            />
                          ) : switchExpressionForSidebar === sideBarIndex.signature_of_the_guide_and_co_guide.numberText ?
                            (
                              <GuideSignature
                                title={title}
                                existingProtocol={existingProtocol}
                              />

                            )
                            : null
                      }
                    </Form>
                  </CardBody>

                  {/* footer content */}

                </Card>
                <div className={"protocol-entry-tab-navigator"}>

                  <div>
                    <Button
                      type='submit'
                      onClick={() => {
                        setProtocolSubmissionState('Previous');
                        handlePreviousButton(switchExpressionForSidebar);
                      }}
                    >Previous</Button>
                  </div>

                  <div className='d-flex gap-3 justify-content-center'>

                    {formik.values.submission_status === 'Submit' && isProtocolSubmissionSucces ? null : (
                      <>
                        {
                          // showEditButton && switchExpressionForSidebar !==
                          //   sideBarIndex.attachment.numberText && switchExpressionForSidebar !==
                          //   sideBarIndex.signature_of_the_guide_and_co_guide.numberText ?
                          //   <Button
                          //     type='button'
                          //     color={"info"}
                          //     onClick={() => handleEditButton()}
                          //   >Edit</Button>
                          //   : null
                        }


                        {
                          // showUpdateButton ?
                          //   <Button
                          //     type='button'
                          //     onClick={(e) => {
                          //       setBtnClicked('Draft');
                          //       handleSubmit();
                          //     }}
                          //   >Update</Button>
                          //   :
                          //   null

                        }


                        {
                          switchExpressionForSidebar ===
                            sideBarIndex.attachment.numberText ?
                            <>
                              <Button
                                type='button'
                                color={"info"}
                                onClick={(e) => {
                                  setProtocolSubmissionState('Draft');
                                  setBtnClicked('Draft');
                                  handleNextButton('submission');
                                  handleSubmit();
                                }}
                                disabled={title === undefined ||
                                  contentValidationMessage !== '' ||
                                  !isTitleApproved ||
                                  ['Approve', 'Reject', 'Submit'].includes(existingProtocol?.data?.submission_status)}
                              >Save as Draft</Button>
                            </>
                            :
                            null
                        }
                      </>
                    )
                    }


                    {switchExpressionForSidebar ===
                      sideBarIndex.attachment.numberText
                      ?
                      <>
                        <Button
                          type='button'
                          color={"warning"}
                          disabled={!existingProtocol?.data?.id}
                          onClick={(e) => {
                            setProtocolSubmissionState('Preview');
                            // setShowReport(true);
                            window.open(`/protocol-entry-view/${existingProtocol?.data?.id}`, '_blank');
                          }}
                        >
                          Preview Protocol
                        </Button>
                      </>
                      :
                      null}

                    {formik.values.submission_status === 'Submit' && isProtocolSubmissionSucces ? null : (
                      <>
                        {switchExpressionForSidebar ===
                          sideBarIndex.attachment.numberText ?

                          (<>
                            <Button
                              color={"success"}
                              type='button'
                              onClick={(e) => {
                                setProtocolSubmissionState('Submit');
                                // setBtnClicked("Submit");
                                // handleSubmit();
                                handleModal();
                                handleNextButton('submission');
                              }}
                              disabled={title === undefined ||
                                contentValidationMessage !== '' ||
                                !isTitleApproved ||
                                ['Approve', 'Reject', 'Submit'].includes(existingProtocol?.data?.submission_status)
                              }
                            >
                              Submit Protocol
                            </Button>

                          </>
                          )
                          :
                          null
                        }
                      </>
                    )
                    }


                  </div>

                  <div>
                    {
                      switchExpressionForSidebar ===
                        sideBarIndex.attachment.numberText ? null : (
                        !['Approve', 'Reject', 'Submit'].includes(existingProtocol?.data?.submission_status) ?
                          <Button
                            color="success"
                            type='submit'
                            disabled={contentValidationMessage ? true : false}
                            onClick={() => {
                              if (sideBarIndex.objectives.isSelected && objectivesActiveTab === 'general_objectives') {
                                handleNextButton(switchExpressionForSidebar);
                              } else if (sideBarIndex.materials_and_methods.isSelected && materialMethodsActiveTab !== 'time_table') {
                                handleNextButton(switchExpressionForSidebar);
                              } else {
                                setProtocolSubmissionState('Save');
                                setBtnClicked('Draft');
                                handleSubmit();
                                handleNextButton(switchExpressionForSidebar);
                              }
                            }
                            }
                          >
                            Save & Next
                          </Button>
                          :
                          <Button
                            color="success"
                            type='submit'
                            onClick={() => {
                              if (sideBarIndex.objectives.isSelected && objectivesActiveTab === 'general_objectives') {
                                handleNextButton(switchExpressionForSidebar);
                              } else if (sideBarIndex.materials_and_methods.isSelected && materialMethodsActiveTab !== 'time_table') {
                                handleNextButton(switchExpressionForSidebar);
                              } else {
                                setProtocolSubmissionState('Next');
                                handleNextButton(switchExpressionForSidebar);
                              }
                            }
                            }
                          >
                            Next
                          </Button>
                      )
                    }
                  </div>


                </div>
              </div>
              {/*.protocol-entry-tab-wrapper*/}
            </Col>
          </Row>

          <Modal
            onOpened={function () {
              toast.dismiss();
            }}
            isOpen={modalShow}
            size='md'
            toggle={() => {
              document.body.classList.add('no_padding');
            }}
            backdrop={'static'}
            modalTransition={{ timeout: 0 }}
            backdropTransition={{ timeout: 0 }}
          >
            <div className='modal-header'>
              <h5 className='modal-title' id='staticBackdropLabel'>
                Protocol Submission
              </h5>
              <button
                type='button'
                className='btn-close'
                onClick={() => {
                  setModalShow(!modalShow);
                }}
                aria-label='Close'
              >
              </button>
            </div>
            <div className='modal-body'>
              <p>Are you sure to submit the protocol?</p>
            </div>
            <ModalFooter>
              <button
                type='button'
                className='btn-success'
                onClick={() => {
                  setBtnClicked('Submit');
                  handleSubmit();
                  setModalShow(!modalShow);
                }}

              >
                Submit
              </button>
              {' '}
              <button
                type='button'
                className='btn-default'
                onClick={() => {
                  setModalShow(!modalShow);
                }}

              >
                Cancel
              </button>

            </ModalFooter>

          </Modal>

          <Modal
            onOpened={function () {
              toast.dismiss();
            }}
            isOpen={noteModalShow}
            scrollable={true}
            size={'md'}
            toggle={() => {
              document.body.classList.add('no_padding');
            }}
            backdrop={'static'}
            modalTransition={{ timeout: 0 }}
            backdropTransition={{ timeout: 0 }}
          >
            <div className="modal-header bg-light">
              <h5 className="modal-title" id="staticBackdropLabel">
                {typeOfNote} Note
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setNoteModalShow(!noteModalShow);
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Row>
                <Col>
                  <>
                    <table className="table table-bordered table-striped table-sm text-capitalize">
                      <tbody>
                        {
                          existingProtocol?.data?.admin_approval_note &&
                          <tr>
                            <th style={{ width: '25%' }}>Admin Approval Note</th>
                            <td>
                              {existingProtocol?.data?.admin_approval_note}
                            </td>
                          </tr>
                        }
                        {
                          existingProtocol?.data?.guide_approval_note &&
                          <tr>
                            <th style={{ width: '25%' }}>Guide Approval Note</th>
                            <td>
                              {existingProtocol?.data?.guide_approval_note}
                            </td>
                          </tr>
                        }
                        {
                          existingProtocol?.data?.co_guide1_approval_note &&
                          <tr>
                            <th style={{ width: '25%' }}>Co-Guide 1 Approval Note</th>
                            <td>
                              {existingProtocol?.data?.co_guide1_approval_note}
                            </td>
                          </tr>
                        }
                        {
                          existingProtocol?.data?.co_guide2_approval_note &&
                          <tr>
                            <th style={{ width: '25%' }}>Co-Guide 2 Approval Note</th>
                            <td>
                              {existingProtocol?.data?.co_guide2_approval_note}
                            </td>
                          </tr>
                        }
                        {
                          existingProtocol?.data?.co_guide3_approval_note &&
                          <tr>
                            <th style={{ width: '25%' }}>Co-Guide 3 Approval Note</th>
                            <td>
                              {existingProtocol?.data?.co_guide3_approval_note}
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </>
                </Col>
              </Row>
            </div>
          </Modal>

          {/* {
            showReport ?
              <ProtocolSummaryReport
                protocolEntry={existingProtocol?.data}
                titleEntry={title?.data}
                showReport={showReport}
                setShowReport={setShowReport}
              />
              :
              null
          } */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProtocolEntryMock;
