import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { ConfirmToast } from 'react-confirm-toast';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { Button, Card, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { InputField } from 'src/components';
import { useCreateChangeRequestForTitleMutation, useDeleteChangedRequestMutation, useGetTraineeChangeRequestListQuery } from 'src/rtk/features/changeRequest/changeRequest';
import { useGetCoGuideListForTitleEntryQuery, useGetGuideListForTitleEntryQuery } from 'src/rtk/features/guide/guideCoguideApi';
import { useGetOrganizationsQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import * as Yup from 'yup';

interface TitleType {
    category?: string,
    title_of_thesis?: string,
    place_of_study?: string,
    study_period?: string,
    study_period_unit?: string,
    guide_id?: string,
    co_guide_id?: any[],
}

const initialValues: TitleType = {
    category: '',
    title_of_thesis: '',
    place_of_study: '',
    study_period: '',
    study_period_unit: 'Month',
    guide_id: '',
    co_guide_id: []
};

interface Props {
    changeInfo: any;
    onlyPlaceOfStudyChange?: boolean;
    onlyStudyPeriodChange?: boolean;
    onlyTitleOfTheThesisChange?: boolean;
    setOnlyPlaceOfStudyChange?: any;
    setOnlyStudyPeriodChange?: any;
    setOnlyTitleOfTheThesisChange?: any;
}

const ChangeRequestModal = (props: Props) => {
    const { changeInfo, onlyPlaceOfStudyChange, setOnlyPlaceOfStudyChange, onlyStudyPeriodChange, setOnlyStudyPeriodChange, onlyTitleOfTheThesisChange, setOnlyTitleOfTheThesisChange } = props;

    const buttonRef = useRef<any>();
    const draftButtonRef = useRef<any>();
    const [selectedGuide, setSelectedGuide] = useState<any>('');
    const [selectedCoGuide, setSelectedCoGuide] = useState<any>('');
    const [selectedInstitue, setSelectedInstitute] = useState<any>('');

    const [titleStatus, setTitleStatus] = useState<boolean>(true);
    const [placeOfStudyStatus, setPlaceOfStudyStatus] = useState<boolean>(true);
    const [studyPeriodStatus, setStudyPeriodStatus] = useState<boolean>(true);
    const [guideStatus, setGuideStatus] = useState<boolean>(true);
    const [coGuideStatus, setCoGuideStatus] = useState<boolean>(true);

    const [allCoGuideId, setAllCoGuideId] = useState<any>([]);
    const [allCoGuideOptions, setAllCoGuideOptions] = useState<any>([]);

    const { data: instituteLookupData } = useGetOrganizationsQuery();
    const { data: traineeChangeRequests } = useGetTraineeChangeRequestListQuery();

    const [createChangeRequest, { isSuccess, isLoading, isError, error }] = useCreateChangeRequestForTitleMutation();
    const err: CustomError = error as CustomError;

    const [
        deleteChangedRequest,
        { isSuccess: isDeleteSuccess, isError: isDeleteError },
    ] = useDeleteChangedRequestMutation();

    const {
        data: guideListData,
        isLoading: guideListDataLoading,
        isSuccess: guideListDataLoadingSuccess,
        error: guideListDataLoadingError,
    } = useGetGuideListForTitleEntryQuery();

    const {
        data: coGuideListData,
        isLoading: coGuideListDataLoading,
        isSuccess: coGuideListDataLoadingSuccess,
        error: coGuideListDataLoadingError,
    } = useGetCoGuideListForTitleEntryQuery();

    const allGuides: SelectOption[] =
        guideListData?.data?.map((item) => ({
            value: item?.id!,
            label: item?.registration?.personal_info?.full_name + '(' + item?.thesis_limit + ' out of 6)',
        })) || [];

    const allCoGuides: SelectOption[] =
        coGuideListData?.data?.map((item) => ({
            value: item?.id!,
            label: item?.registration?.personal_info?.full_name,
        })) || [];

    const allInstitute: SelectOption[] =
        instituteLookupData?.data?.map((institute) => ({
            value: institute?.organization_name,
            label: institute?.organization_name,
        })) || [];

    const submittedValues: any = {
        type: 'title',
        related_id: changeInfo?.id!,
        datas: [],
    };

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setValues,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({

        }),

        onSubmit: (values: any) => {
            Object.keys(changedInput).forEach((key) => {
                submittedValues?.datas?.push(changedInput[key]);
            });
            createChangeRequest(submittedValues);
        },
    });

    const handleSubmitValue = () => {
        Object.keys(changedInput).forEach((key) => {
            submittedValues?.datas?.push(changedInput[key]);
        });
        createChangeRequest(submittedValues);
    };

    useEffect(() => {
        if (changeInfo) {
            let coGuideOptions = [];
            let coGuideId = [];

            if (changeInfo['co_guide_id_1']) {
                coGuideId.push(changeInfo['co_guide_id_1']);

                coGuideOptions.push({
                    label: changeInfo?.co_guide_1_name || '',
                    value: changeInfo?.co_guide_id_1 || '',
                });
            }

            if (changeInfo['co_guide_id_2']) {
                coGuideId.push(changeInfo['co_guide_id_2']);
                coGuideOptions.push({
                    label: changeInfo?.co_guide_2_name || '',
                    value: changeInfo?.co_guide_id_2 || '',
                });
            }
            if (changeInfo['co_guide_id_3']) {
                coGuideId.push(changeInfo['co_guide_id_3']);
                coGuideOptions.push({
                    label: changeInfo?.co_guide_3_name || '',
                    value: changeInfo?.co_guide_id_3 || '',
                });
            }
            setValues({
                category: changeInfo?.['category'] || '',
                co_guide_id: coGuideId || [],
                guide_id: changeInfo?.['guide_id'] || '',
                place_of_study: changeInfo?.['place_of_study'] || '',
                study_period: changeInfo?.['study_period'] || '',
                study_period_unit: changeInfo?.['study_period_unit'] || '',
                title_of_thesis: changeInfo?.['title_of_thesis'] || '',
            });

            setSelectedGuide({
                value: changeInfo?.['guide_id'],
                label: changeInfo?.['guide_name'],
            });

            setSelectedInstitute({
                value: changeInfo?.['place_of_study'],
                label: changeInfo?.['place_of_study'],
            });

            setSelectedCoGuide(coGuideOptions);
            setAllCoGuideOptions(coGuideOptions);
            setAllCoGuideId(coGuideId);
        }
    }, [changeInfo])


    const [changedInput, setChangedInput] = useState<any>({});

    const handleTitleChange = (event: any) => {
        const values = { ...changedInput };
        values['title_of_thesis']['updated_value'] = event.target.value;
        setChangedInput(values);
    }

    const handleStudyPeriodChange = (event: any) => {
        const values = { ...changedInput };
        values['study_period']['updated_value'] = event.target.value;
        setChangedInput(values);
    }

    useEffect(() => {
        if (selectedInstitue !== '' && !placeOfStudyStatus) {
            setFieldValue('place_of_study', selectedInstitue?.value);
            const values = { ...changedInput };
            values['place_of_study']['updated_value'] = selectedInstitue?.value;
            setChangedInput(values);
            console.log('eeeeeeeeeeeee');
        }
    }, [selectedInstitue]);

    useEffect(() => {
        if (selectedGuide !== '' && !guideStatus) {
            setFieldValue('guide_id', selectedGuide?.value);
            const values = { ...changedInput };
            values['guide_id']['updated_value'] = selectedGuide?.value;
            setChangedInput(values);
        }
    }, [selectedGuide]);


    useEffect(() => {
        const coGuide: any = [];

        selectedCoGuide && selectedCoGuide.map((item: any, index: number) => {
            coGuide[index] = item.value;
        });
        setFieldValue('co_guide_id', coGuide);
        if (!coGuideStatus) {
            const values = { ...changedInput };
            values['co_guide_id']['updated_value'] = coGuide;
            setChangedInput(values);
        }
    }, [selectedCoGuide])

    useEffect(() => {
        console.log(changedInput);
    }, [changedInput])


    useEffect(() => {
        if (isSuccess) {
            toast.dismiss();
            toast.success('Change Request Submitted Successfully');
        }
        if (isError) {
            toast.dismiss();
            toast.error(err?.message || 'Something went wrong');
        }
    }, [isLoading, isSuccess, isError]);

    useEffect(() => {
        if (isDeleteSuccess) {
            toast.dismiss();
            toast.success('Change Request Deleted Successfully');
        }
        if (isDeleteError) {
            toast.dismiss();
            toast.error(err?.message || 'Something went wrong');
        }
    }, [isDeleteSuccess, isDeleteError]);

    // IF CHANGE REQUEST COMES FROM PROTOCOL ENTRY

    useEffect(() => {
        if (onlyPlaceOfStudyChange) {
            setPlaceOfStudyStatus(false);
            const values = { ...changedInput };
            values['place_of_study'] = {
                column_name: 'place_of_study',
                previous_value: changeInfo?.['place_of_study'],
                updated_value: ''
            }
            setChangedInput(values);
        }
    }, [onlyPlaceOfStudyChange]);

    useEffect(() => {
        if (onlyStudyPeriodChange) {
            setStudyPeriodStatus(false);
            const values = { ...changedInput };
            values['study_period'] = {
                column_name: 'study_period',
                previous_value: changeInfo?.['study_period'],
                updated_value: ''
            }
            setChangedInput(values);
        }
    }, [onlyStudyPeriodChange]);

    useEffect(() => {
        if (onlyTitleOfTheThesisChange) {
            setTitleStatus(false);
            const values = { ...changedInput };
            values['title_of_thesis'] = {
                column_name: 'title_of_thesis',
                previous_value: changeInfo?.['title_of_thesis'],
                updated_value: ''
            }
            setChangedInput(values);
        }
    }, [onlyTitleOfTheThesisChange]);


    return (
        <React.Fragment>
            {/* <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
                onReset={(e) => {
                    resetForm();
                }}
            > */}
            <Row>
                <Col sm={12} lg={12} md={12} className='bg-info bg-opacity-10'>
                    <Label>Select The Field You want to Change</Label>
                    <Row>
                        <Col sm={4} lg={4} md={4}>
                            <FormGroup switch>
                                <Input
                                    type="switch"
                                    role="switch"
                                    id='title'
                                    checked={onlyTitleOfTheThesisChange}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setTitleStatus(false);
                                            if (setOnlyTitleOfTheThesisChange) {
                                                setOnlyTitleOfTheThesisChange(true);
                                            }
                                            const values = { ...changedInput };
                                            values['title_of_thesis'] = {
                                                column_name: 'title_of_thesis',
                                                previous_value: changeInfo?.['title_of_thesis'],
                                                updated_value: ''
                                            }
                                            setChangedInput(values);
                                        } else {
                                            setTitleStatus(true);
                                            if (setOnlyTitleOfTheThesisChange) {
                                                setOnlyTitleOfTheThesisChange(false);
                                            }
                                            const values = { ...changedInput };
                                            delete values['title_of_thesis'];
                                            setChangedInput(values);
                                            setFieldValue('title_of_thesis', changeInfo?.['title_of_thesis']);
                                        }
                                    }}
                                />
                                <Label for='title'>Title</Label>
                            </FormGroup>
                        </Col>
                        <Col sm={4} lg={4} md={4}>
                            <FormGroup switch>
                                <Input
                                    type="switch"
                                    role="switch"
                                    id='placeofstudy'
                                    checked={onlyPlaceOfStudyChange}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setPlaceOfStudyStatus(false);
                                            if (setOnlyPlaceOfStudyChange) {
                                                setOnlyPlaceOfStudyChange(true);
                                            }
                                            const values = { ...changedInput };
                                            values['place_of_study'] = {
                                                column_name: 'place_of_study',
                                                previous_value: changeInfo?.['place_of_study'],
                                                updated_value: ''
                                            }
                                            setChangedInput(values);
                                        } else {
                                            setPlaceOfStudyStatus(true);
                                            if (setOnlyPlaceOfStudyChange) {
                                                setOnlyPlaceOfStudyChange(false);
                                            }
                                            const values = { ...changedInput };
                                            delete values['place_of_study'];
                                            setChangedInput(values);
                                            setSelectedInstitute({
                                                value: changeInfo?.['place_of_study'],
                                                label: changeInfo?.['place_of_study'],
                                            });

                                        }
                                    }}
                                />
                                <Label for='placeofstudy'>Place of Study</Label>
                            </FormGroup>
                        </Col>
                        <Col sm={4} lg={4} md={4}>
                            <FormGroup switch>
                                <Input
                                    type="switch"
                                    role="switch"
                                    id='studyperiod'
                                    checked={onlyStudyPeriodChange}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setStudyPeriodStatus(false);
                                            if (setOnlyStudyPeriodChange) {
                                                setOnlyStudyPeriodChange(true);
                                            }
                                            const values = { ...changedInput };
                                            values['study_period'] = {
                                                column_name: 'study_period',
                                                previous_value: changeInfo?.['study_period'],
                                                updated_value: ''
                                            }
                                            setChangedInput(values);
                                        } else {
                                            setStudyPeriodStatus(true);
                                            if (setOnlyStudyPeriodChange) {
                                                setOnlyStudyPeriodChange(false);
                                            }
                                            const values = { ...changedInput };
                                            delete values['study_period'];
                                            setChangedInput(values);
                                            setFieldValue('study_period', changeInfo?.['study_period']);
                                        }
                                    }}
                                />
                                <Label for='studyperiod'>Study Period</Label>
                            </FormGroup>
                        </Col>
                        <Col sm={4} lg={4} md={4}>
                            <FormGroup switch>
                                <Input
                                    type="switch"
                                    role="switch"
                                    id='guide'
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setGuideStatus(false);
                                            const values = { ...changedInput };
                                            values['guide_id'] = {
                                                column_name: 'guide_id',
                                                previous_value: changeInfo?.['guide_id'],
                                                updated_value: ''
                                            }
                                            setChangedInput(values);
                                        } else {
                                            setGuideStatus(true);
                                            const values = { ...changedInput };
                                            delete values['guide_id'];
                                            setChangedInput(values);
                                            setSelectedGuide({
                                                value: changeInfo?.['guide_id'],
                                                label: changeInfo?.['guide_name'],
                                            });
                                        }
                                    }}
                                />
                                <Label for='guide'>Guide</Label>
                            </FormGroup>
                        </Col>
                        <Col sm={4} lg={4} md={4}>
                            <FormGroup switch>
                                <Input
                                    type="switch"
                                    role="switch"
                                    id='coguide'
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setCoGuideStatus(false);
                                            const values = { ...changedInput };
                                            values['co_guide_id'] = {
                                                column_name: 'co_guide_id',
                                                previous_value: allCoGuideId,
                                                updated_value: ''
                                            }
                                            setChangedInput(values);
                                        } else {
                                            setCoGuideStatus(true);
                                            const values = { ...changedInput };
                                            delete values['co_guide_id'];
                                            setFieldValue('co_guide_id', allCoGuideId);
                                            setChangedInput(values);
                                            setSelectedCoGuide(allCoGuideOptions);
                                        }
                                    }}
                                />
                                <Label for='coguide'>Co-Guide</Label>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col sm={12}>
                    <FormGroup>
                        <Label for="title_of_thesis" className="form-label">Title</Label>
                        <InputField
                            type="text"
                            name='title_of_thesis'
                            onChange={(e: any) => {
                                handleChange(e);
                                handleTitleChange(e);
                            }}
                            onBlur={handleBlur}
                            value={values.title_of_thesis}
                            invalid={{ errors, touched }}
                            disabled={titleStatus}
                        />
                    </FormGroup>
                </Col>

                <Col sm={6}>
                    <FormGroup>
                        <Label for="place_of_study" className="form-label">Place of Study</Label>
                        {/* <InputField
                                type="text"
                                placeholder='Place of Study'
                                name='place_of_study'
                                onChange={(e: any) => {
                                    handleChange(e);
                                    handlePlaceOfStudyChange(e);
                                }}
                                value={values.place_of_study}
                                invalid={{ errors, touched }}
                                disabled={placeOfStudyStatus}
                            /> */}
                        <Select
                            isClearable={true}
                            isSearchable={true}
                            value={selectedInstitue}
                            onChange={setSelectedInstitute}
                            options={allInstitute}
                            placeholder='Place of Study'
                            name="place_of_study"
                            isDisabled={placeOfStudyStatus}
                        />
                    </FormGroup>
                </Col>

                <Col sm={6} lg={6} md={6}>
                    <div className='row'>
                        <FormGroup className='col-md-6'>
                            <Label for="study_period" className="form-label">Study Period</Label>
                            <InputField
                                type="number"
                                name='study_period'
                                value={values.study_period}
                                onChange={(e: any) => {
                                    handleChange(e);
                                    handleStudyPeriodChange(e);
                                }}
                                invalid={{ errors, touched }}
                                min={'12'}
                                disabled={studyPeriodStatus}
                            />
                        </FormGroup>
                        <FormGroup className='col-md-6'>
                            <Label for="study_period_unit" className="form-label">Unit</Label>
                            <InputField
                                type="text"
                                placeholder='Enter Unit'
                                {...getFieldProps('study_period_unit')}
                                invalid={{ errors, touched }}
                                disabled
                            />
                        </FormGroup>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                        <Label for="guide_id">Guide</Label>
                        <Select
                            isClearable={true}
                            isSearchable={true}
                            value={selectedGuide}
                            onChange={setSelectedGuide}
                            options={allGuides}
                            placeholder='Select Guide'
                            name="guide_id"
                            isDisabled={guideStatus}
                        />
                        {errors.guide_id && touched.guide_id ? (
                            <div className="text-danger">{errors.guide_id as string}</div>
                        ) : null}
                    </FormGroup>
                </Col>

                <Col lg={6} md={6} sm={12}>
                    <FormGroup>
                        <Label for="co_guide_id">
                            Co-Guide
                        </Label>
                        <Select
                            isClearable={true}
                            isSearchable={true}
                            isMulti={true}
                            value={selectedCoGuide}
                            isOptionDisabled={() => values.co_guide_id?.length! >= 3}
                            onChange={setSelectedCoGuide}
                            options={allCoGuides}
                            placeholder='Select Co-Guide'
                            name="co_guide_id"
                            isDisabled={coGuideStatus}
                        />
                    </FormGroup>
                </Col>

                <FormGroup className="mb-3 text-center">
                    <button
                        type="submit"
                        ref={buttonRef}
                        className="btn btn-success"
                        style={{ width: '160px' }}
                        onClick={handleSubmitValue}
                    >
                        Save
                    </button>
                </FormGroup>
            </Row>
            {/* </Form> */}
            {traineeChangeRequests?.data?.length > 0 &&
                <Card className='p-1'>
                    <div className='block-heading'>
                        Changed Requests
                    </div>
                    <div className='table-responsive'>
                        <table className='table table-sm'>
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Field Name</th>
                                    <th>Previous Value</th>
                                    <th>Updated Value</th>
                                    <th>Admin Approval Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {traineeChangeRequests?.data?.map((item: any) => (
                                    <tr key={item?.id}>
                                        <td>{item?.type}</td>
                                        <td>
                                            {
                                                item?.column_name === 'title_of_thesis' ? 'Title of the Thesis' 
                                                : item?.column_name === 'place_of_study' ? 'Place of Study' 
                                                : item?.column_name === 'study_period' ? 'Study Period'
                                                : item?.column_name === 'guide_id' ? 'Guide'
                                                : item?.column_name === 'co_guide_id' ? 'Co-Guide'
                                                : ''
                                            }
                                        </td>
                                        <td>
                                            {
                                                item?.previous_value_info ? 
                                                item?.previous_value_info?.map((item: any) => (
                                                    <div key={item.registration_no}>
                                                        {item?.name}
                                                    </div>
                                                )): item?.previous_value
                                            }
                                        </td>
                                        <td>
                                            {
                                                item?.updated_value_info ? 
                                                item?.updated_value_info?.map((item: any) => (
                                                    <div key={item.registration_no}>
                                                        {item?.name}
                                                    </div>
                                                )): item?.updated_value
                                            }
                                        </td>
                                        <td>
                                            <div>
                                                {item?.admin_approval_status?.toLowerCase() === 'approve' && (
                                                    <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                                        {'Accepted'}
                                                    </div>
                                                )}
                                                {item?.admin_approval_status?.toLowerCase() === 'reject' && (
                                                    <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                                        {item?.admin_approval_status}
                                                    </div>
                                                )}
                                                {item?.admin_approval_status?.toLowerCase() === 'correction' && (
                                                    <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                                        {item?.admin_approval_status}
                                                    </div>
                                                )}
                                                {item?.admin_approval_status !== 'Approve' &&
                                                    item?.admin_approval_status !== 'Reject' &&
                                                    item?.admin_approval_status !== 'Correction' && (
                                                        <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                                            {item?.admin_approval_status ?? 'Pending'}
                                                        </div>
                                                    )}
                                            </div>
                                        </td>
                                        <td>
                                            <ConfirmToast
                                                asModal={true}
                                                childrenClassName="margin-top-10"
                                                customCancel="No"
                                                customConfirm="Yes"
                                                customFunction={() =>
                                                    deleteChangedRequest(item?.id!)
                                                }
                                                message={'Do You Want to Delete?'}
                                                position="top-right"
                                                showCloseIcon={true}
                                                theme="lilac"
                                            >
                                                <Button
                                                    className="btn btn-danger btn-sm"
                                                >
                                                    Delete
                                                </Button>
                                            </ConfirmToast>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Card>}
        </React.Fragment>
    );
};

export default ChangeRequestModal;
