import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { Container, Form, Label, FormGroup, Input } from 'reactstrap';
import { InputField, Button } from 'src/components';
import { useFormik } from 'formik';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import toast from 'react-hot-toast';
import { setFromErrors } from 'src/helpers';
import { useHistory, useLocation } from 'react-router-dom';
import { useCreateNoticeAttachmentMutation } from 'src/rtk/features/setup/trnNoticeCopyAttachment/setupTrnNoticeCopyAttachment';

interface NoticeAttachmentProps {    
    noticeMasterId: string;
    noticeAttachmentList: TransactionNoticeCopyAttachment[];
}

const initialValues: TransactionNoticeCopyAttachment = {
    id: '',
    notice_master_id: '',
    attachment_type: '',
    details: '',
    user_id: '',
    status: true,
    creator_organization_id: '',
    updater_organization_id: '',
    created_by: '',
    updated_by: '',
    deleted_by: '',
    created_at: '',
    updated_at: '',
    deleted_at: ''
};

const AddNoticeAttachment = (props: NoticeAttachmentProps) => {
    const { noticeMasterId, noticeAttachmentList } = props;    
    const location: any = useLocation(); 

    const [createNoticeCopyAttachment, { data: noticeAttachment, isSuccess, isError, error }] = useCreateNoticeAttachmentMutation();    

    const history = useHistory();    

    const err: CustomError = error as CustomError;

    const { handleSubmit, handleChange, getFieldProps, values, touched, errors, resetForm, setErrors } =
        useFormik({
            initialValues,      

            onSubmit: (values) => {
                values.notice_master_id = noticeMasterId;

                createNoticeCopyAttachment({
                    ...values,
                });              

                console.log(values);
            },
    });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Notice attachment created successfully');          
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);  

  return (      
      <div className="row align-items-center justify-content-center">           
          <div className="card mt-3">
            <h5 className="card-header">
              <i className="fa fa-plus me-2" />
              Add Notice Attachment
            </h5>
            <div className="card-body">
              <Form
                className="custom-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="row">                                        
                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    <FormGroup className="mb-3">
                      <Label for="attachment_type">Attachment Type</Label>
                      <InputField
                        type="text"
                        placeholder="Attachment Type"                         
                        invalid={{ errors, touched }}
                        {...getFieldProps('attachment_type')}
                      />
                    </FormGroup>
                  </div>

                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    <FormGroup className="mb-3">
                      <Label for="details">Details</Label>
                      <InputField
                        type="textarea"
                        placeholder="Details"
                        invalid={{ errors, touched }}
                        {...getFieldProps('details')}
                      />
                    </FormGroup>
                  </div>

                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    <FormGroup check className='mb-3 mt-4'>
                      <Label check>
                          <Input type="checkbox" {...getFieldProps('status')} checked={values.status} />{' '}
                          Status
                      </Label>
                    </FormGroup>
                  </div>

                  <div className='col-lg-4 col-md-6 col-sm-12'>
                    <FormGroup className="mb-3" hidden>
                      <Label for="user_id">User</Label>
                      <InputField
                        type="text"
                        placeholder="User"
                        invalid={{ errors, touched }}
                        {...getFieldProps('user_id')}                          
                      />
                    </FormGroup>
                  </div>                    
                </div>
                <Button type="submit" text="Submit" />
              </Form>
            </div>
          </div>                     
        </div>    
  );
};

export default AddNoticeAttachment;
