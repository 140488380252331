import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { Alert, Col, Form, Label, Row } from 'reactstrap';
import { AuthLayout, InputField } from 'src/components';
import { useResendEmailTokenMutation } from 'src/rtk/features/auth/authApi';
import * as Yup from 'yup';

const ResendVerificationEmail = () => {
  const [resendEmail, { data, isLoading, isSuccess, isError, error }] =
    useResendEmailTokenMutation();

  const { handleChange, handleSubmit, errors, touched, values } = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required('Please Enter Your Email'),
    }),
    onSubmit: (values) => {
      resendEmail({ email: values.email });
    },
  });

  return (
    <AuthLayout>
      <div className="auth-content my-auto">
        <div className="text-center">
          <h5 className="mb-0">Resend Email verification</h5>
          <p className="text-muted">Enter your registered email address.</p>
        </div>
        {isSuccess && (
          <Alert color="success">
            <div>
              <i className="mdi mdi-check-circle-outline me-2"></i>
              {data?.message}
            </div>
          </Alert>
        )}
        {isError && (
          <Alert color="danger">
            <div className="d-flex flex-row  align-items-center gap-2">
              <i className="fas fa-info-circle fa-2xl"></i>
              <span>
                {data?.message || (error as any)?.message} <br /> Make sure your
                email address is valid.
              </span>
            </div>
          </Alert>
        )}

        <div className="row">
          <div className="col-lg-8 mx-auto">
            <Form
              className="custom-form mt-4"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="mb-3">
                <Label className="form-label">Email Address</Label>
                <InputField
                  name="email"
                  type="email"
                  placeholder="Enter Email Address"
                  onChange={handleChange}
                  value={values.email}
                  invalid={{ errors, touched }}
                />
              </div>

              <Row className="mb-3">
                <Col className="text-end">
                  <button
                    className="btn btn-primary w-100 waves-effect waves-light"
                    type="submit"
                    disabled={isLoading}
                  >
                    Resend Email
                  </button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>

        <div className="mt-5 text-center">
          <p className="text-muted mb-0">
            <Link to="/login" className="btn btn-sm btn-outline-primary fw-semibold">
              Sign In
            </Link>
          </p>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ResendVerificationEmail;
