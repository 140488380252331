import { apiSlice } from 'src/rtk/api/apiSlice';
import * as thesisDissertationOldTitleListUrl from 'src/helpers/url_helper';

export const thesisDissertationOldTitleListApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['thesisDissertationOldList'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getThesisDissertationOldTitleList: builder.query<
        DataWithPagination<ThesisDissertationOldTitleList>,
        PaginateForLogbookEntries
      >({
        query: (paginate) => {
          let url = `${thesisDissertationOldTitleListUrl.GET_THESIS_DISSERTATION_OLD_TITLE_LIST}?`;

          if (paginate.page) {
            url += `page=${paginate.page}&`;
          }
          if (paginate.paginate_per_page) {
            url += `paginate_per_page=${paginate.paginate_per_page}`;
          }
          if (paginate.search) {
            url += `${paginate.search}`;
          }

          return {
            method: 'GET',
            url: url,
          };
        },

        providesTags: ['thesisDissertationOldList'],
      }),      
    }),
  });

export const {
  useGetThesisDissertationOldTitleListQuery,  
} = thesisDissertationOldTitleListApi;
