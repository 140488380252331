import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { setFromErrors } from 'src/helpers';
import { useCreateThesisDissertationFormSetupMutation, useGetThesisDissertationFormSetupsQuery } from 'src/rtk/features/setup/thesisEntryFormSetup/thesisEntryFormSetupApi';
import * as Yup from 'yup';

const initialValues: SetupThesisEntryForm = {
    id: '',
    sl_no: 0,
    column_name: '',
    details_text: '',
    data_type: '',
    parent_id: '',
    has_file: false,
    publish_status: false,
    required_ind: false,
    input_ind: false,
    default_ind: false,
    status: false,
    creator_organization_id: '',
    updater_organization_id: '',
    created_by: '',
    updated_by: '',
    deleted_by: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
};

export const AddThesisEntrySetupForm = () => {
    const [createThesisEntrySetupForm, { isSuccess, isError, error }] = useCreateThesisDissertationFormSetupMutation();

    const { data } = useGetThesisDissertationFormSetupsQuery();
    const thesisDissertationFormSetups = data?.data || [];

    const history = useHistory();

    const err: CustomError = error as CustomError;

    const { handleSubmit, getFieldProps, setFieldValue, values, touched, errors, resetForm, setErrors } =
        useFormik({
            initialValues,
            validationSchema: Yup.object({
                column_name: Yup.string().required('Çolumn name is required'),
            }),

            onSubmit: (values) => {
                createThesisEntrySetupForm({
                    ...values,
                });

                console.log(values);
            },
        });

    useEffect(() => {
        if (isSuccess) {
            resetForm();
            toast.success('Thesis/dissertation entry form created successfully');
            history.push('/setup/thesis-dissertation');
        }
        if (isError) {
            toast.error(err?.message || 'Something went wrong');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);

    useEffect(() => {
        if(!values.input_ind) {
            setFieldValue('data_type', '');
            setFieldValue('sl_no', 0);
            setFieldValue('has_file', false);
            setFieldValue('required_ind', false);
        }
    }, [values.input_ind]);

    let op: SelectOption[] = [
        ...thesisDissertationFormSetups?.map((t: SetupThesisEntryForm) => ({
            label: t.column_name!,
            value: t.id!,
        })),
    ];

    return (
        <div className="page-content">
            <Container fluid>
                <BetterBreadcrumb
                    title={'Thesis/Dissertation Entry Form'}
                    breadcrumbItem={[{ link: '/setup/thesis-dissertation', name: 'Thesis/Dissertation Setup List' }]}
                />
                <div className="row align-items-center justify-content-center">
                    <div className="card">
                        <div className="d-flex card-header">
                            <h2 className="card-title mb-0 flex-grow-1">
                                <i className="fa fa-plus me-2" />
                                Thesis/Dissertation Entry Form Setup
                            </h2>
                            <div className="flex-shrink-0 align-self-end">
                                <Link
                                    to="/setup/thesis-dissertation"
                                    className="btn btn-success waves-effect waves-light"
                                >
                                    <i className="fas fa-arrow-left me-2" /> Back
                                </Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <Form
                                className="custom-form"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                                onReset={(e) => {
                                    e.preventDefault();
                                    resetForm();
                                }}
                            >
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="column_name">Column Name</Label>
                                            <InputField
                                                type="text"
                                                placeholder="Column Name"
                                                invalid={{ errors, touched }}
                                                {...getFieldProps('column_name')}
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="parent_id">Parent Column</Label>
                                            <InputField
                                                type="select"
                                                placeholder="Parent Column"
                                                options={op}
                                                invalid={{ errors, touched }}
                                                {...getFieldProps('parent_id')}
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className="col-lg-2 col-md-6 col-sm-12">
                                        <FormGroup check className="mb-3 mt-4">
                                            <Label check>
                                                <Input type="checkbox" checked={values.publish_status} {...getFieldProps('publish_status')} />{' '}
                                                Publish Status
                                            </Label>
                                        </FormGroup>
                                    </div>

                                    <div className="col-lg-2 col-md-6 col-sm-12">
                                        <FormGroup check className="mb-3 mt-4">
                                            <Label check>
                                                <Input type="checkbox" checked={values.input_ind} {...getFieldProps('input_ind')} />{' '}
                                                Has Input Field?
                                            </Label>
                                        </FormGroup>
                                    </div>

                                    {values.input_ind ?
                                        <>
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="data_type">Data Type</Label>
                                                    <InputField
                                                        type="select"
                                                        placeholder="Data Type"
                                                        options={[
                                                            { label: 'Text Editor', value: 'rich-text' },
                                                            { label: 'File', value: 'file' },
                                                            { label: 'File Array', value: 'file-array' },
                                                        ]}
                                                        invalid={{ errors, touched }}
                                                        {...getFieldProps('data_type')}
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="sl_no">Serial No</Label>
                                                    <InputField
                                                        type="number"
                                                        placeholder="Serial No"
                                                        invalid={{ errors, touched }}
                                                        {...getFieldProps('sl_no')}
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className="col-lg-2 col-md-6 col-sm-12">
                                                <FormGroup check className="mb-3 mt-4">
                                                    <Label check>
                                                        <Input type="checkbox" checked={values.has_file} {...getFieldProps('has_file')} />{' '}
                                                        Has File?
                                                    </Label>
                                                </FormGroup>
                                            </div>

                                            <div className="col-lg-2 col-md-6 col-sm-12">
                                                <FormGroup check className="mb-3 mt-4">
                                                    <Label check>
                                                        <Input type="checkbox" checked={values.required_ind} {...getFieldProps('required_ind')} />{' '}
                                                        Required
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        </>
                                        :
                                        null}
                                </div>

                                <div className="text-center">
                                    <Button type="submit" text="Submit" />
                                    <Button type="reset" color='warning' text="Reset" className='ms-2' />
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};
