// import LogbookMonitoring from "src/pages/LogbookMonitoring/LogbookMonitoringList";
// import ProgressReportInterview from "src/pages/LogbookMonitoring/ProgressReportInterview";
import PerformanceEvaluation from "src/pages/LogbookMonitoring/PerformanceEvaluation";

import ProgressReport from "src/pages/LogbookMonitoring/ProgressReport";
import SupervisorActivityLog from "src/pages/LogbookMonitoring/SupervisorActivityLog";
import TraineeActivityLog from "src/pages/LogbookMonitoring/TraineeActivityLog";
import TraineeListForSixMonthReport from "src/pages/LogbookMonitoring/TraineeListForSixMonthReport";


export const logBookMonitoringRoutes = [
  // { path: '/monitoring-list', component: LogbookMonitoring },
  // { path: '/progress-report-interview', component: ProgressReportInterview },
  { path: '/performance-evaluation/:regNo/:id/:name/:trainingId', component: PerformanceEvaluation },
  { path: '/six-monthly-report', component: TraineeListForSixMonthReport },
  { path: '/progress-report', component: ProgressReport },
  { path: '/trainee-activity-log', component: TraineeActivityLog },
  { path: '/supervisor-activity-log', component: SupervisorActivityLog },
];
