import { useState } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { useGetInstituteDepartmentListByPaginateQuery } from 'src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi';
import InstituteDepartmentListPaginate from './InstituteDepartmentListPaginate';
import InstituteDepartmentListTableRow from './InstituteDepartmentListTableRow';
import { useHistory } from 'react-router-dom';

const InstituteDepartmentList = () => {

    const history = useHistory();

    const [page, setPage] = useState<number | string>('1');
    const [paginate_per_page, set_paginate_per_page] = useState(20);
    const [search, setSearch] = useState('');

    const {
        data: instituteDepartmentRegData,
        isSuccess: isInstituteDepartmentRegDataSuccess,
        isError: isInstituteDepartmentRegDataError,
        isLoading: isInstituteDepartmentRegDataLoading,
        error: instituteDepartmentRegDataError
    } = useGetInstituteDepartmentListByPaginateQuery({
        page,
        paginate_per_page,
        search,
    });

    const departmentInfos: InstituteDepartmentRegData[] = instituteDepartmentRegData?.data?.data || [];
    const err: CustomError = instituteDepartmentRegDataError as CustomError;

    //   const editHandler = (id: string) => {
    //     setDeviceRegId(id);
    //     setShowAddForm(false);
    //     window.scrollTo(0, 0);
    //   };

    // what to render
    let content = null;

    if (isInstituteDepartmentRegDataLoading) {
        content = <div className="mt-3">Loading...</div>;
    }
    if (!isInstituteDepartmentRegDataLoading && isInstituteDepartmentRegDataError && instituteDepartmentRegDataError) {
        content = <div className="mt-3">{err.message}</div>;
    }
    if (!isInstituteDepartmentRegDataLoading && !isInstituteDepartmentRegDataError && departmentInfos?.length > 0) {

        content = departmentInfos.map((departmentInfo, idx) => (
            <InstituteDepartmentListTableRow departmentInfo={departmentInfo} index={idx} key={departmentInfo.id!} />
        ));
    }

    //   const { handleSubmit, getFieldProps, values, touched, errors, resetForm, setErrors } =
    //     useFormik({
    //       initialValues,

    //       onSubmit: (values) => {

    //         let s: string = '';

    //         if (values.general_search_term !== '') {
    //           s = s + `&search=${values.general_search_term}`;
    //         }

    //         setSearch(s);
    //         setPage('1');
    //         set_paginate_per_page(20);
    //       },
    //     });

    return (
        <>
            <h6 className='block-heading'>
                <div className="row justify-content-between">
                    <div className="col-2">
                        Department List
                    </div>
                    <div className="col-2">
                        <button
                            type="button"
                            className="btn btn-sm btn-success"
                            onClick={(e) => {
                                history.push('/institute/department-registration');
                            }}
                        >
                            Apply for New Department
                        </button>
                    </div>
                </div>
            </h6>
            <Card>
                <CardBody>
                    <div className="table-responsive">
                        <Table className="table table-bordered text-center table-sm">
                            <thead className="table-secondary">
                                <tr>
                                    <th>Sl.</th>
                                    <th>Institute Name</th>
                                    <th>Department Name</th>
                                    <th>Date of Last Accreditation</th>
                                    <th>Duration to be Counted</th>
                                    <th>Validity Date</th>
                                    <th>Payment Status</th>
                                    <th>Accreditation Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>{content}</tbody>
                        </Table>
                    </div>
                    {!isInstituteDepartmentRegDataLoading && <InstituteDepartmentListPaginate data={instituteDepartmentRegData} setPage={setPage} setPaginatePerPage={set_paginate_per_page} />}
                </CardBody>
            </Card>
        </>
    );
};

export default InstituteDepartmentList;
