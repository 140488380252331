
interface Props {
  info: any;
  type?: string;
}

const ApprovalForGuideCoGuide = (props: Props) => {
  const { info, type } = props;

  return (
    <>
      {info?.title ?
        <div>
          <h6 className="block-heading">Trainee Information</h6>
          <div className="row">
            <div className="col-md-12">
              <table className="table table-bordered table-striped table-sm">
                <tbody>
                  <tr>
                    <td className="col-md-6 col-lg-6">
                      <strong>Trainee Name: </strong>
                      {info?.title?.registration?.personal_info?.full_name}
                    </td>
                    <td className="col-md-6 col-lg-6">
                      <strong>BMDC Reg. No: </strong>
                      {info?.title?.registration?.bmdc_registration_no}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Date of Birth: </strong>
                      {info?.title?.registration?.personal_info?.date_of_birth ?? 'N/A'}
                    </td>
                    <td>
                      <strong>NID: </strong>
                      {info?.title?.registration?.personal_info?.national_id ?? 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Mobile Number: </strong>
                      {info?.title?.registration?.personal_info?.mobile ?? 'N/A'}
                    </td>
                    <td>
                      <strong>Email: </strong>
                      {info?.title?.registration?.personal_info?.email ?? 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Alternative Number: </strong>
                      {info?.title?.registration?.personal_info?.telephone ?? 'N/A'}
                    </td>
                    <td>
                      <strong>Subject: </strong>
                      {info?.title?.registration?.subject?.subject_name ?? 'N/A'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <h6 className="block-heading">Thesis Title Details</h6>
          <div className="row">
            <div className='col-md-12'>
              <table className="table table-bordered table-striped table-sm">
                <tbody>
                  <tr>
                    <td className="col-md-6 col-lg-6">
                      <strong>Category: </strong>
                      {info?.title?.category}
                    </td>
                    <td className="col-md-6 col-lg-6">
                      <strong>Title: </strong>
                      {info?.title?.title_of_thesis}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Place Of Study: </strong>
                      {info?.title?.place_of_study ?? 'N/A'}
                    </td>
                    <td>
                      <strong>Study Period: </strong>
                      {info?.title?.study_period + ' ' + info?.title?.study_period_unit ?? 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Guide: </strong>
                    </td>
                    <td>
                      <div className='row'>
                        <div className="col">
                          <strong>BMDC Reg. No.:</strong> {info?.title?.guide?.bmdc_registration_no ?? 'N/A'} <br />
                          <strong>Name:</strong>  {info?.title?.guide_name ?? 'N/A'} <br />
                          <div className="d-flex gap-1 justify-content-start">
                            <strong>Approval Status:</strong>
                            <div>
                              {info?.title?.guide_approval_status?.toLowerCase() === 'approve' && (
                                <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                  {'Accepted'}
                                </div>
                              )}
                              {info?.title?.guide_approval_status?.toLowerCase() === 'reject' && (
                                <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                  {'Rejected'}
                                </div>
                              )}
                              {info?.title?.guide_approval_status?.toLowerCase() === 'correction' && (
                                <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                  {info?.title?.guide_approval_status}
                                </div>
                              )}
                              {info?.title?.guide_approval_status !== 'Approve' &&
                                info?.title?.guide_approval_status !== 'Reject' &&
                                info?.title?.guide_approval_status !== 'Correction' && (
                                  <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                    {info?.title?.guide_approval_status ?? 'Pending'}
                                  </div>
                                )}
                            </div>
                          </div>
                          <strong>Approval Note: </strong>
                          <span className={info?.title?.guide_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                            info?.title?.guide_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                          >
                            {info?.title?.guide_approval_note}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Co-Guide </strong>
                    </td>
                    <td>
                      {info?.title?.co_guide_1_name &&
                        <div className='row card mx-1 my-1'>
                          <div className="col">
                            <strong>BMDC Reg. No.:</strong> {info?.title?.co_guide_1?.bmdc_registration_no ?? 'N/A'} <br />
                            <strong>Name:</strong>  {info?.title?.co_guide_1_name ?? 'N/A'} <br />
                            <div className="d-flex gap-1 justify-content-start">
                              <strong>Approval Status:</strong>
                              <div>
                                {info?.title?.co_guide1_approval_status?.toLowerCase() === 'approve' && (
                                  <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                    {'Accepted'}
                                  </div>
                                )}
                                {info?.title?.co_guide1_approval_status?.toLowerCase() === 'reject' && (
                                  <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                    {'Rejected'}
                                  </div>
                                )}
                                {info?.title?.co_guide1_approval_status?.toLowerCase() === 'correction' && (
                                  <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                    {info?.title?.co_guide1_approval_status}
                                  </div>
                                )}
                                {info?.title?.co_guide1_approval_status !== 'Approve' &&
                                  info?.title?.co_guide1_approval_status !== 'Reject' &&
                                  info?.title?.co_guide1_approval_status !== 'Correction' && (
                                    <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                      {info?.title?.co_guide1_approval_status ?? 'Pending'}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <strong>Approval Note: </strong>
                            <span className={info?.title?.co_guide1_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                              info?.title?.co_guide1_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                            >
                              {info?.title?.co_guide1_approval_note}
                            </span>
                          </div>
                        </div>
                      }
                      {info?.title?.co_guide_2_name &&
                        <div className='row card mx-1 my-1'>
                          <div className="col">
                            <strong>BMDC Reg. No.:</strong> {info?.title?.co_guide_2?.bmdc_registration_no ?? 'N/A'} <br />
                            <strong>Name:</strong>  {info?.title?.co_guide_2_name ?? 'N/A'} <br />
                            <div className="d-flex gap-1 justify-content-start">
                              <strong>Approval Status:</strong>
                              <div>
                                {info?.title?.co_guide2_approval_status?.toLowerCase() === 'approve' && (
                                  <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                    {'Accepted'}
                                  </div>
                                )}
                                {info?.title?.co_guide2_approval_status?.toLowerCase() === 'reject' && (
                                  <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                    {'Rejected'}
                                  </div>
                                )}
                                {info?.title?.co_guide2_approval_status?.toLowerCase() === 'correction' && (
                                  <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                    {info?.title?.co_guide2_approval_status}
                                  </div>
                                )}
                                {info?.title?.co_guide2_approval_status !== 'Approve' &&
                                  info?.title?.co_guide2_approval_status !== 'Reject' &&
                                  info?.title?.co_guide2_approval_status !== 'Correction' && (
                                    <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                      {info?.title?.co_guide2_approval_status ?? 'Pending'}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <strong>Approval Note: </strong>
                            <span className={info?.title?.co_guide2_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                              info?.title?.co_guide2_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                            >
                              {info?.title?.co_guide2_approval_note}
                            </span>
                          </div>
                        </div>
                      }
                      {info?.title?.co_guide_3_name &&
                        <div className='row card mx-1 my-1'>
                          <div className="col">
                            <strong>BMDC Reg. No.:</strong> {info?.title?.co_guide_3?.bmdc_registration_no ?? 'N/A'} <br />
                            <strong>Name:</strong>  {info?.title?.co_guide_3_name ?? 'N/A'} <br />
                            <div className="d-flex gap-1 justify-content-start">
                              <strong>Approval Status:</strong>
                              <div>
                                {info?.title?.co_guide3_approval_status?.toLowerCase() === 'approve' && (
                                  <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                    {'Accepted'}
                                  </div>
                                )}
                                {info?.title?.co_guide3_approval_status?.toLowerCase() === 'reject' && (
                                  <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                    {'Rejected'}
                                  </div>
                                )}
                                {info?.title?.co_guide3_approval_status?.toLowerCase() === 'correction' && (
                                  <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                    {info?.title?.co_guide3_approval_status}
                                  </div>
                                )}
                                {info?.title?.co_guide3_approval_status !== 'Approve' &&
                                  info?.title?.co_guide3_approval_status !== 'Reject' &&
                                  info?.title?.co_guide3_approval_status !== 'Correction' && (
                                    <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                      {info?.title?.co_guide3_approval_status ?? 'Pending'}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <strong>Approval Note: </strong>
                            <span className={info?.title?.co_guide3_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                              info?.title?.co_guide3_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                            >
                              {info?.title?.co_guide3_approval_note}
                            </span>
                          </div>
                        </div>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {type === 'Protocol' &&
            <>
              <h6 className="block-heading">Protocol Details</h6>
              <div className="row">
                <div className='col-md-12'>
                  <table className="table table-bordered table-striped table-sm">
                    <tbody>
                      <tr>
                        <td className="col-6">
                          <strong>Guide: </strong>
                        </td>
                        <td className="col-6">
                          <div className='row'>
                            <div className="col">
                              <strong>BMDC Reg. No.:</strong> {info?.title?.guide?.bmdc_registration_no ?? 'N/A'} <br />
                              <strong>Name:</strong>  {info?.title?.guide_name ?? 'N/A'} <br />
                              <div className="d-flex gap-1 justify-content-start">
                                <strong>Approval Status:</strong>
                                <div>
                                  {info?.guide_approval_status?.toLowerCase() === 'approve' && (
                                    <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                      {'Accepted'}
                                    </div>
                                  )}
                                  {info?.guide_approval_status?.toLowerCase() === 'reject' && (
                                    <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                      {'Rejected'}
                                    </div>
                                  )}
                                  {info?.guide_approval_status?.toLowerCase() === 'correction' && (
                                    <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                      {info?.guide_approval_status}
                                    </div>
                                  )}
                                  {info?.guide_approval_status !== 'Approve' &&
                                    info?.guide_approval_status !== 'Reject' &&
                                    info?.guide_approval_status !== 'Correction' && (
                                      <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                        {info?.guide_approval_status ?? 'Pending'}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <strong>Approval Note: </strong>
                              <span className={info?.guide_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                                info?.guide_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                              >
                                {info?.guide_approval_note ?? 'N/A'}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Co-Guide </strong>
                        </td>
                        <td>
                          {info?.title?.co_guide_1_name &&
                            <div className='row card mx-1 my-1'>
                              <div className="col">
                                <strong>BMDC Reg. No.:</strong> {info?.title?.co_guide_1?.bmdc_registration_no ?? 'N/A'} <br />
                                <strong>Name:</strong>  {info?.title?.co_guide_1_name ?? 'N/A'} <br />
                                <div className="d-flex gap-1 justify-content-start">
                                  <strong>Approval Status:</strong>
                                  <div>
                                    {info?.co_guide1_approval_status?.toLowerCase() === 'approve' && (
                                      <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                        {'Accepted'}
                                      </div>
                                    )}
                                    {info?.co_guide1_approval_status?.toLowerCase() === 'reject' && (
                                      <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                        {'Rejected'}
                                      </div>
                                    )}
                                    {info?.co_guide1_approval_status?.toLowerCase() === 'correction' && (
                                      <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                        {info?.co_guide1_approval_status}
                                      </div>
                                    )}
                                    {info?.co_guide1_approval_status !== 'Approve' &&
                                      info?.co_guide1_approval_status !== 'Reject' &&
                                      info?.co_guide1_approval_status !== 'Correction' && (
                                        <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                          {info?.co_guide1_approval_status ?? 'Pending'}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <strong>Approval Note: </strong>
                                <span className={info?.co_guide1_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                                  info?.co_guide1_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                                >
                                  {info?.co_guide1_approval_note ?? 'N/A'}
                                </span>
                              </div>
                            </div>
                          }
                          {info?.title?.co_guide_2_name &&
                            <div className='row card mx-1 my-1'>
                              <div className="col">
                                <strong>BMDC Reg. No.:</strong> {info?.title?.co_guide_2?.bmdc_registration_no ?? 'N/A'} <br />
                                <strong>Name:</strong>  {info?.title?.co_guide_2_name ?? 'N/A'} <br />
                                <div className="d-flex gap-1 justify-content-start">
                                  <strong>Approval Status:</strong>
                                  <div>
                                    {info?.co_guide2_approval_status?.toLowerCase() === 'approve' && (
                                      <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                        {'Accepted'}
                                      </div>
                                    )}
                                    {info?.co_guide2_approval_status?.toLowerCase() === 'reject' && (
                                      <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                        {'Rejected'}
                                      </div>
                                    )}
                                    {info?.co_guide2_approval_status?.toLowerCase() === 'correction' && (
                                      <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                        {info?.co_guide2_approval_status}
                                      </div>
                                    )}
                                    {info?.co_guide2_approval_status !== 'Approve' &&
                                      info?.co_guide2_approval_status !== 'Reject' &&
                                      info?.co_guide2_approval_status !== 'Correction' && (
                                        <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                          {info?.co_guide2_approval_status ?? 'Pending'}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <strong>Approval Note: </strong>
                                <span className={info?.co_guide2_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                                  info?.co_guide2_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                                >
                                  {info?.co_guide2_approval_note ?? 'N/A'}
                                </span>
                              </div>
                            </div>
                          }
                          {info?.title?.co_guide_3_name &&
                            <div className='row card mx-1 my-1'>
                              <div className="col">
                                <strong>BMDC Reg. No.:</strong> {info?.title?.co_guide_3?.bmdc_registration_no ?? 'N/A'} <br />
                                <strong>Name:</strong>  {info?.title?.co_guide_3_name ?? 'N/A'} <br />
                                <div className="d-flex gap-1 justify-content-start">
                                  <strong>Approval Status:</strong>
                                  <div>
                                    {info?.co_guide3_approval_status?.toLowerCase() === 'approve' && (
                                      <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                        {'Accepted'}
                                      </div>
                                    )}
                                    {info?.co_guide3_approval_status?.toLowerCase() === 'reject' && (
                                      <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                        {'Rejected'}
                                      </div>
                                    )}
                                    {info?.co_guide3_approval_status?.toLowerCase() === 'correction' && (
                                      <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                        {info?.co_guide3_approval_status}
                                      </div>
                                    )}
                                    {info?.co_guide3_approval_status !== 'Approve' &&
                                      info?.co_guide3_approval_status !== 'Reject' &&
                                      info?.co_guide3_approval_status !== 'Correction' && (
                                        <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                          {info?.co_guide3_approval_status ?? 'Pending'}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <strong>Approval Note: </strong>
                                <span className={info?.co_guide3_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                                  info?.co_guide3_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                                >
                                  {info?.co_guide3_approval_note ?? 'N/A'}
                                </span>
                              </div>
                            </div>
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          }
          {type === 'DissertationThesis' &&
            <>
              <h6 className="block-heading">Dissertation Thesis Details</h6>
              <div className="row">
                <div className='col-md-12'>
                  <table className="table table-bordered table-striped table-sm">
                    <tbody>
                      <tr>
                        <td className="col-6">
                          <strong>Guide: </strong>
                        </td>
                        <td className="col-6">
                          <div className='row'>
                            <div className="col">
                              <strong>BMDC Reg. No.:</strong> {info?.title?.guide?.bmdc_registration_no ?? 'N/A'} <br />
                              <strong>Name:</strong>  {info?.title?.guide_name ?? 'N/A'} <br />
                              <div className="d-flex gap-1 justify-content-start">
                                <strong>Approval Status:</strong>
                                <div>
                                  {info?.guide_approval_status?.toLowerCase() === 'approve' && (
                                    <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                      {'Accepted'}
                                    </div>
                                  )}
                                  {info?.guide_approval_status?.toLowerCase() === 'reject' && (
                                    <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                      {'Rejected'}
                                    </div>
                                  )}
                                  {info?.guide_approval_status?.toLowerCase() === 'correction' && (
                                    <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                      {info?.guide_approval_status}
                                    </div>
                                  )}
                                  {info?.guide_approval_status !== 'Approve' &&
                                    info?.guide_approval_status !== 'Reject' &&
                                    info?.guide_approval_status !== 'Correction' && (
                                      <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                        {info?.guide_approval_status ?? 'Pending'}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <strong>Approval Note: </strong>
                              <span className={info?.guide_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                                info?.guide_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                              >
                                {info?.guide_approval_note ?? 'N/A'}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Co-Guide </strong>
                        </td>
                        <td>
                          {info?.title?.co_guide_1_name &&
                            <div className='row card mx-1 my-1'>
                              <div className="col">
                                <strong>BMDC Reg. No.:</strong> {info?.title?.co_guide_1?.bmdc_registration_no ?? 'N/A'} <br />
                                <strong>Name:</strong>  {info?.title?.co_guide_1_name ?? 'N/A'} <br />
                                <div className="d-flex gap-1 justify-content-start">
                                  <strong>Approval Status:</strong>
                                  <div>
                                    {info?.co_guide1_approval_status?.toLowerCase() === 'approve' && (
                                      <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                        {'Accepted'}
                                      </div>
                                    )}
                                    {info?.co_guide1_approval_status?.toLowerCase() === 'reject' && (
                                      <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                        {'Rejected'}
                                      </div>
                                    )}
                                    {info?.co_guide1_approval_status?.toLowerCase() === 'correction' && (
                                      <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                        {info?.co_guide1_approval_status}
                                      </div>
                                    )}
                                    {info?.co_guide1_approval_status !== 'Approve' &&
                                      info?.co_guide1_approval_status !== 'Reject' &&
                                      info?.co_guide1_approval_status !== 'Correction' && (
                                        <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                          {info?.co_guide1_approval_status ?? 'Pending'}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <strong>Approval Note: </strong>
                                <span className={info?.co_guide1_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                                  info?.co_guide1_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                                >
                                  {info?.co_guide1_approval_note ?? 'N/A'}
                                </span>
                              </div>
                            </div>
                          }
                          {info?.title?.co_guide_2_name &&
                            <div className='row card mx-1 my-1'>
                              <div className="col">
                                <strong>BMDC Reg. No.:</strong> {info?.title?.co_guide_2?.bmdc_registration_no ?? 'N/A'} <br />
                                <strong>Name:</strong>  {info?.title?.co_guide_2_name ?? 'N/A'} <br />
                                <div className="d-flex gap-1 justify-content-start">
                                  <strong>Approval Status:</strong>
                                  <div>
                                    {info?.co_guide2_approval_status?.toLowerCase() === 'approve' && (
                                      <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                        {'Accepted'}
                                      </div>
                                    )}
                                    {info?.co_guide2_approval_status?.toLowerCase() === 'reject' && (
                                      <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                        {'Rejected'}
                                      </div>
                                    )}
                                    {info?.co_guide2_approval_status?.toLowerCase() === 'correction' && (
                                      <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                        {info?.co_guide2_approval_status}
                                      </div>
                                    )}
                                    {info?.co_guide2_approval_status !== 'Approve' &&
                                      info?.co_guide2_approval_status !== 'Reject' &&
                                      info?.co_guide2_approval_status !== 'Correction' && (
                                        <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                          {info?.co_guide2_approval_status ?? 'Pending'}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <strong>Approval Note: </strong>
                                <span className={info?.co_guide2_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                                  info?.co_guide2_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                                >
                                  {info?.co_guide2_approval_note ?? 'N/A'}
                                </span>
                              </div>
                            </div>
                          }
                          {info?.title?.co_guide_3_name &&
                            <div className='row card mx-1 my-1'>
                              <div className="col">
                                <strong>BMDC Reg. No.:</strong> {info?.title?.co_guide_3?.bmdc_registration_no ?? 'N/A'} <br />
                                <strong>Name:</strong>  {info?.title?.co_guide_3_name ?? 'N/A'} <br />
                                <div className="d-flex gap-1 justify-content-start">
                                  <strong>Approval Status:</strong>
                                  <div>
                                    {info?.co_guide3_approval_status?.toLowerCase() === 'approve' && (
                                      <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                        {'Accepted'}
                                      </div>
                                    )}
                                    {info?.co_guide3_approval_status?.toLowerCase() === 'reject' && (
                                      <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                        {'Rejected'}
                                      </div>
                                    )}
                                    {info?.co_guide3_approval_status?.toLowerCase() === 'correction' && (
                                      <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                        {info?.co_guide3_approval_status}
                                      </div>
                                    )}
                                    {info?.co_guide3_approval_status !== 'Approve' &&
                                      info?.co_guide3_approval_status !== 'Reject' &&
                                      info?.co_guide3_approval_status !== 'Correction' && (
                                        <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                          {info?.co_guide3_approval_status ?? 'Pending'}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <strong>Approval Note: </strong>
                                <span className={info?.co_guide3_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                                  info?.co_guide3_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                                >
                                  {info?.co_guide3_approval_note ?? 'N/A'}
                                </span>
                              </div>
                            </div>
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          }
        </div>
        :
        <div>
          <h6 className="block-heading">Trainee Information</h6>
          <div className="row">
            <div className="col-md-12">
              <table className="table table-bordered table-striped table-sm">
                <tbody>
                  <tr>
                    <td className="col-md-6 col-lg-6">
                      <strong>Trainee Name: </strong>
                      {info?.registration?.personal_info?.full_name}
                    </td>
                    <td className="col-md-6 col-lg-6">
                      <strong>BMDC Reg. No: </strong>
                      {info?.registration?.bmdc_registration_no}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Date of Birth: </strong>
                      {info?.registration?.personal_info?.date_of_birth ?? 'N/A'}
                    </td>
                    <td>
                      <strong>NID: </strong>
                      {info?.registration?.personal_info?.national_id ?? 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Mobile Number: </strong>
                      {info?.registration?.personal_info?.mobile ?? 'N/A'}
                    </td>
                    <td>
                      <strong>Email: </strong>
                      {info?.registration?.personal_info?.email ?? 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Alternative Number: </strong>
                      {info?.registration?.personal_info?.telephone ?? 'N/A'}
                    </td>
                    <td>
                      <strong>Subject: </strong>
                      {info?.registration?.subject?.subject_name ?? 'N/A'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <h6 className="block-heading">Thesis Title Details</h6>
          <div className="row">
            <div className='col-md-12'>
              <table className="table table-bordered table-striped table-sm">
                <tbody>
                  <tr>
                    <td className="col-md-6 col-lg-6">
                      <strong>Category: </strong>
                      {info?.category}
                    </td>
                    <td className="col-md-6 col-lg-6">
                      <strong>Title: </strong>
                      {info?.title_of_thesis}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Place Of Study: </strong>
                      {info?.place_of_study ?? 'N/A'}
                    </td>
                    <td>
                      <strong>Study Period: </strong>
                      {info?.study_period + ' ' + info?.study_period_unit ?? 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Guide: </strong>
                    </td>
                    <td>
                      <div className='row'>
                        <div className="col">
                          <strong>BMDC Reg. No.:</strong> {info?.guide?.bmdc_registration_no ?? 'N/A'} <br />
                          <strong>Name:</strong>  {info?.guide_name ?? 'N/A'} <br />
                          <div className="d-flex gap-1 justify-content-start">
                            <strong>Approval Status:</strong>
                            <div>
                              {info?.guide_approval_status?.toLowerCase() === 'approve' && (
                                <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                  {'Accepted'}
                                </div>
                              )}
                              {info?.guide_approval_status?.toLowerCase() === 'reject' && (
                                <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                  {'Rejected'}
                                </div>
                              )}
                              {info?.guide_approval_status?.toLowerCase() === 'correction' && (
                                <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                  {info?.guide_approval_status}
                                </div>
                              )}
                              {info?.guide_approval_status !== 'Approve' &&
                                info?.guide_approval_status !== 'Reject' &&
                                info?.guide_approval_status !== 'Correction' && (
                                  <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                    {info?.guide_approval_status ?? 'Pending'}
                                  </div>
                                )}
                            </div>
                          </div>
                          <strong>Approval Note: </strong>
                          <span className={info?.guide_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                            info?.guide_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                          >
                            {info?.guide_approval_note}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Co-Guide </strong>
                    </td>
                    <td>
                      {info?.co_guide_1_name &&
                        <div className='row card mx-1 my-1'>
                          <div className="col">
                            <strong>BMDC Reg. No.:</strong> {info?.co_guide_1?.bmdc_registration_no ?? 'N/A'} <br />
                            <strong>Name:</strong>  {info?.co_guide_1_name ?? 'N/A'} <br />
                            <div className="d-flex gap-1 justify-content-start">
                              <strong>Approval Status:</strong>
                              <div>
                                {info?.co_guide1_approval_status?.toLowerCase() === 'approve' && (
                                  <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                    {'Accepted'}
                                  </div>
                                )}
                                {info?.co_guide1_approval_status?.toLowerCase() === 'reject' && (
                                  <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                    {'Rejected'}
                                  </div>
                                )}
                                {info?.co_guide1_approval_status?.toLowerCase() === 'correction' && (
                                  <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                    {info?.co_guide1_approval_status}
                                  </div>
                                )}
                                {info?.co_guide1_approval_status !== 'Approve' &&
                                  info?.co_guide1_approval_status !== 'Reject' &&
                                  info?.co_guide1_approval_status !== 'Correction' && (
                                    <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                      {info?.co_guide1_approval_status ?? 'Pending'}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <strong>Approval Note: </strong>
                            <span className={info?.co_guide1_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                              info?.co_guide1_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                            >
                              {info?.co_guide1_approval_note}
                            </span>
                          </div>
                        </div>
                      }
                      {info?.co_guide_2_name &&
                        <div className='row card mx-1 my-1'>
                          <div className="col">
                            <strong>BMDC Reg. No.:</strong> {info?.co_guide_2?.bmdc_registration_no ?? 'N/A'} <br />
                            <strong>Name:</strong>  {info?.co_guide_2_name ?? 'N/A'} <br />
                            <div className="d-flex gap-1 justify-content-start">
                              <strong>Approval Status:</strong>
                              <div>
                                {info?.co_guide2_approval_status?.toLowerCase() === 'approve' && (
                                  <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                    {'Accepted'}
                                  </div>
                                )}
                                {info?.co_guide2_approval_status?.toLowerCase() === 'reject' && (
                                  <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                    {'Rejected'}
                                  </div>
                                )}
                                {info?.co_guide2_approval_status?.toLowerCase() === 'correction' && (
                                  <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                    {info?.co_guide2_approval_status}
                                  </div>
                                )}
                                {info?.co_guide2_approval_status !== 'Approve' &&
                                  info?.co_guide2_approval_status !== 'Reject' &&
                                  info?.co_guide2_approval_status !== 'Correction' && (
                                    <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                      {info?.co_guide2_approval_status ?? 'Pending'}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <strong>Approval Note: </strong>
                            <span className={info?.co_guide2_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                              info?.co_guide2_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                            >
                              {info?.co_guide2_approval_note}
                            </span>
                          </div>
                        </div>
                      }
                      {info?.co_guide_3_name &&
                        <div className='row card mx-1 my-1'>
                          <div className="col">
                            <strong>BMDC Reg. No.:</strong> {info?.co_guide_3?.bmdc_registration_no ?? 'N/A'} <br />
                            <strong>Name:</strong>  {info?.co_guide_3_name ?? 'N/A'} <br />
                            <div className="d-flex gap-1 justify-content-start">
                              <strong>Approval Status:</strong>
                              <div>
                                {info?.co_guide3_approval_status?.toLowerCase() === 'approve' && (
                                  <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                    {'Accepted'}
                                  </div>
                                )}
                                {info?.co_guide3_approval_status?.toLowerCase() === 'reject' && (
                                  <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                    {'Rejected'}
                                  </div>
                                )}
                                {info?.co_guide3_approval_status?.toLowerCase() === 'correction' && (
                                  <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                    {info?.co_guide3_approval_status}
                                  </div>
                                )}
                                {info?.co_guide3_approval_status !== 'Approve' &&
                                  info?.co_guide3_approval_status !== 'Reject' &&
                                  info?.co_guide3_approval_status !== 'Correction' && (
                                    <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                      {info?.co_guide3_approval_status ?? 'Pending'}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <strong>Approval Note: </strong>
                            <span className={info?.co_guide3_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                              info?.co_guide3_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                            >
                              {info?.co_guide3_approval_note}
                            </span>
                          </div>
                        </div>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default ApprovalForGuideCoGuide;
