import { TransactionNoticeDetails } from "src/pages/Setup/TrnNoticeDetails";
import { AddTrnNoticeDetails } from "src/pages/Setup/TrnNoticeDetails/AddTrnNoticeDetails";
import { EditTransactionNoticeDetails } from "src/pages/Setup/TrnNoticeDetails/EditTrnNoticeDetails";

export const trnNoticeDetailsRoutes = [
  // trn notice details setup
  {
    path: '/setup/trn-notice-details/',
    component: TransactionNoticeDetails,
  },
  {
    path: '/setup/trn-notice-details/update/:id',
    component: EditTransactionNoticeDetails,
  },
  {
    path: '/setup/trn-notice-details/add',
    component: AddTrnNoticeDetails,
  },
];
