import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import { DaynamicInputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { putUrlString, setFromErrors } from 'src/helpers';
import {
  useCreateByPostMutation,
  useGetDataQuery,
  useUpdateByPatchMutation,
} from 'src/rtk/features/common/crudApi';
import * as Yup from 'yup';
import formObj from './formObj';

const DaynamicForm = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [btnClicked, setBtnClicked] = useState<string>('');

  let initialValues: any = {};
  let validationObj: any = {};
  let inputFields: any = [];
  let formFilds = formObj?.formFields || {};
  Object.keys(formFilds)
    .sort(function (a: any, b: any) {
      return parseInt(a.positionInForm) - parseInt(b.positionInForm);
    })
    .map((key) => {
      if (formFilds[key]['showInForm']) {
        let field = formFilds[key];
        inputFields.push(formFilds[key]);

        if (
          field['type'] === 'checkbox' &&
          typeof field['default'] === 'string'
        ) {
          field['default'] = [field['default'].trim()];
        } else if (
          field['type'] === 'radio' &&
          typeof field['default'] === 'object'
        ) {
          field['default'] = field['default'].join(' ').trim();
        } else if (field['type'] === 'date') {
          field['default'] = field?.options?.defaultDate || '';
        }

        if (field['isRequired']) {
          validationObj[field['name']] = field['validation'];
        }

        initialValues[field['name']] = field['default'];
      }
    });

  const [createByPost, { isSuccess, isError, error }] =
    useCreateByPostMutation();
  const err: CustomError = error as CustomError;

  const [
    updateByPatch,
    { isSuccess: isUpdateSuccess, isError: isUpdateError, error: updateError },
  ] = useUpdateByPatchMutation();
  const updateErr: CustomError = updateError as CustomError;

  useEffect(() => {
    if (isUpdateSuccess) {
      toast.success(formObj.title + ' updated successfully');
      history.push(formObj.listUrl);
    }
    if (isUpdateError) {
      toast.error(updateErr?.message || 'Something went wrong');
      setFromErrors(updateErr.data, setErrors);
    }
  }, [isUpdateSuccess, isUpdateError]);

  const {
    handleSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    values,
    setValues,
    setFieldValue,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({ ...validationObj }),
    onSubmit: (values) => {
      if (btnClicked === 'submit') {
        values.submit_status = true;
      } else if (btnClicked === 'save_as_draft') {
        values.submit_status = false;
      }

      if (id) {
        const updateUrl = formObj.updateAPIUrl + putUrlString(id, values);
        updateByPatch(updateUrl);
      } else {
        let postData = {
          queryUrl: formObj['createAPIUrl'],
          formData: values,
        };
        createByPost(postData);
      }
    },
  });

  if (id) {
    const { data: updatedData, isError: isExamFetchError } = useGetDataQuery(
      `${formObj.getDataByIdAPIUrl}${id}`,
    );

    useEffect(() => {
      if (updatedData && !isExamFetchError) {
        const data: any = (updatedData as any).data;

        setValues({
          code: data.code || '',
          name: data.name || '',
          type: data.type || '',
          main_trainings: data.exam_training_id || '',
          session: data.session || '',
          description: data.description || '',
          duration: data.duration || '',
          duration_unit: data.duration_unit || '',
          paid_course: parseInt(data.paid_course) || 0,
          fee: data.exam_tranning_fee?.exam_fee || 0,
          required_ind: data.required_ind,
          status: data.status,
        });
      }
    }, [updatedData, isExamFetchError]);
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success(formObj.title + ' save successfully');
      history.push(formObj.listUrl);
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {formObj.breadcrumbItemForForm && (
            <BetterBreadcrumb
              title={
                id === undefined
                  ? 'Add ' + formObj?.title
                  : 'Update ' + formObj?.title
              }
              breadcrumbItem={formObj.breadcrumbItemForForm}
            />
          )}
          <div className="row align-items-center justify-content-center">
            <Form
              className="custom-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              onReset={(e) => {
                e.preventDefault();
                resetForm();
              }}
            >
              <div className="card">
                {/* <div className="card-header"></div> */}
                <div className="card-body">
                  <Row>
                    {inputFields.length > 0 &&
                      inputFields.map((field: any, key: any) => {
                        if (!['hidden', 'submit'].includes(field['type'])) {
                          field['type'] = field['type'];

                          if (
                            field['dependField'] &&
                            field['dependValue'] &&
                            field['condition']
                          ) {
                            let isMatch = true;
                            switch (field['condition']) {
                              case '===':
                                isMatch =
                                  values[field['dependField']] ===
                                  field['dependValue'];
                                break;

                              case '!==':
                                isMatch =
                                  values[field['dependField']] !==
                                  field['dependValue'];
                                break;

                              case 'includes':
                                isMatch = field['dependValue'].includes(
                                  values[field['dependField']],
                                );
                                break;

                              default:
                                break;
                            }
                            if (!isMatch) {
                              if (values[field['name']] !== field['default']) {
                                setFieldValue(field['name'], field['default']);
                              }
                              return <></>;
                            }
                          }

                          return (
                            <Col
                              key={field['name'] + key}
                              md={field['colSize']}
                              sm={12}
                            >
                              <FormGroup className="mb-3">
                                <>
                                {['richtext'].includes(
                                            field['type'],
                                          ) && (
                                              <div className="accordion-item">
                                                <h2
                                                  className="accordion-header"
                                                  id={'heading-' + field['name']}
                                                >
                                                  <button
                                                    className="accordion-button text-capitalize"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={
                                                      '#collapse-' + field['name']
                                                    }
                                                    aria-expanded="true"
                                                    aria-controls="collapseOne"
                                                    onClick={(e: any) => {
                                                      let bsTarget =
                                                        e.target?.dataset
                                                          ?.bsTarget || '';

                                                      let selectorTarget =
                                                        document.querySelector(
                                                          bsTarget,
                                                        );

                                                      if (
                                                        selectorTarget.classList.contains(
                                                          'show',
                                                        )
                                                      ) {
                                                        selectorTarget.classList.remove(
                                                          'show',
                                                        );
                                                      } else {
                                                        selectorTarget.className +=
                                                          ' show';
                                                      }
                                                    }}
                                                  >
                                                    <Label
                                                      className={
                                                        field['isRequired']
                                                          ? 'form-label me-2 required-field text-capitalize'
                                                          : 'form-label me-2 text-capitalize'
                                                      }
                                                    >
                                                      {field['label']}
                                                    </Label>
                                                  </button>
                                                </h2>
                                                <div
                                                  id={'collapse-' + field['name']}
                                                  className="accordion-collapse collapse "
                                                  aria-labelledby={
                                                    'heading-' + field['name']
                                                  }
                                                  data-bs-parent="#accordionExample"
                                                >
                                                  <div className="accordion-body">
                                                    <DaynamicInputField
                                                            label={field['label']}
                                                            type={field['type']}
                                                            placeholder={
                                                              field['label']
                                                            }
                                                            required={
                                                              field['isRequired']
                                                            }
                                                            invalid={{
                                                              errors,
                                                              touched,
                                                            }}
                                                            setData={(
                                                              data: any,
                                                            ) => {
                                                              setFieldValue(
                                                                field['name'],
                                                                data,
                                                              );
                                                            }}
                                                            {...getFieldProps(
                                                              field['name'],
                                                            )}
                                                          />
                                                  </div>
                                                </div>
                                              </div>
                                            )}

                                  {['modal'].includes(field['type']) && (
                                    <DaynamicInputField
                                      label={field['label']}
                                      name={field['name']}
                                      type={field['type']}
                                      placeholder={field['label']}
                                      options={field['data']}
                                      dataApi={field['dataApi']}
                                      value={
                                        values[field['name']] !== undefined
                                          ? values[field['name']]
                                          : field['default']
                                      }
                                      isMulti={field['isMulti']}
                                      required={field['isRequired']}
                                      invalid={{ errors, touched }}
                                      onChange={(
                                        data: any,
                                        isSelected: boolean = false,
                                      ) => {
                                        if (data === null) {
                                          setFieldValue(field['name'], '');
                                          field['data'] = [];
                                        } else if (
                                          data &&
                                          typeof data === 'string'
                                        ) {
                                          let isSelected =
                                            values[field['name']].includes(
                                              data,
                                            );

                                          if (isSelected) {
                                            setFieldValue(field['name'], '');
                                            field['data'] = [];
                                          } else {
                                            setFieldValue(field['name'], data);
                                            field['data'] = [data];
                                          }
                                        } else if (
                                          data &&
                                          typeof data === 'object' &&
                                          data?.length === undefined
                                        ) {
                                          let isSelected =
                                            values[field['name']] ===
                                            data?.value;

                                          if (isSelected) {
                                            setFieldValue(field['name'], '');
                                            field['data'] = [];
                                          } else {
                                            setFieldValue(
                                              field['name'],
                                              data?.value || '',
                                            );
                                            field['data'] = data;
                                          }
                                        } else if (
                                          data &&
                                          typeof data === 'object' &&
                                          data?.length > 0
                                        ) {
                                          if (!isSelected) {
                                            let filterData = field[
                                              'data'
                                            ].filter((item: any) => {
                                              return (
                                                item.value !== data?.[0].value
                                              );
                                            });

                                            setFieldValue(
                                              field['name'],
                                              filterData.map(
                                                (item: any) => item.value,
                                              ),
                                            );
                                            field['data'] = filterData;
                                          } else {
                                            setFieldValue(
                                              field['name'],
                                              data.map(
                                                (item: any) => item.value,
                                              ),
                                            );
                                            field['data'] = data;
                                          }
                                        }
                                      }}
                                    />
                                  )}

                                  {['select'].includes(field['type']) && (
                                    <DaynamicInputField
                                      label={field['label']}
                                      name={field['name']}
                                      type={field['type']}
                                      placeholder={field['label']}
                                      options={field['data']}
                                      dataApi={field['dataApi']}
                                      value={
                                        values[field['name']] !== undefined
                                          ? values[field['name']]
                                          : field['default']
                                      }
                                      isMulti={field['isMulti']}
                                      required={field['isRequired']}
                                      invalid={{ errors, touched }}
                                      onChange={(data: any) => {
                                        if (data === null) {
                                          setFieldValue(field['name'], '');
                                        } else if (
                                          data &&
                                          data?.length === undefined &&
                                          data.value !== undefined
                                        ) {
                                          setFieldValue(
                                            field['name'],
                                            data.value,
                                          );
                                        } else if (
                                          data &&
                                          data?.length !== undefined
                                        ) {
                                          setFieldValue(
                                            field['name'],
                                            data.map((item: any) => item.value),
                                          );
                                        }
                                      }}
                                    />
                                  )}

                                  {['date'].includes(field['type']) && (
                                    <>
                                      <DaynamicInputField
                                        label={field['label']}
                                        name={field['name']}
                                        type={field['type']}
                                        placeholder={field['label']}
                                        options={field['options']}
                                        invalid={{ errors, touched }}
                                        value={
                                          values[field['name']] !== undefined
                                            ? values[field['name']]
                                            : field?.options?.defaultDate
                                        }
                                        required={field['isRequired']}
                                        setData={(data: any) => {
                                          if (
                                            field?.options?.mode === 'range'
                                          ) {
                                            setFieldValue(
                                              field['name'],
                                              data[0],
                                            );
                                            setFieldValue(
                                              field['name2'],
                                              data[1],
                                            );
                                          } else {
                                            setFieldValue(
                                              field['name'],
                                              data.join(',').trim(),
                                            );
                                          }
                                        }}
                                      />
                                    </>
                                  )}

                                  {['radio', 'checkbox'].includes(
                                    field['type'],
                                  ) && (
                                    <DaynamicInputField
                                      label={field['label']}
                                      name={field['name']}
                                      type={field['type']}
                                      placeholder={field['label']}
                                      options={field['data']}
                                      dataApi={field['dataApi']}
                                      value={
                                        values[field['name']] !== undefined
                                          ? values[field['name']]
                                          : field['default']
                                      }
                                      isMulti={field['isMulti']}
                                      required={field['isRequired']}
                                      invalid={{ errors, touched }}
                                      onChange={(data: any) => {
                                        let selectedValue =
                                          data.target.value.toString();
                                        if (field['type'] === 'checkbox') {
                                          let isChecked = data.target.checked;
                                          let oldValue = values[field['name']];

                                          let getIndex =
                                            oldValue.indexOf(selectedValue);
                                          if (isChecked && getIndex === -1) {
                                            oldValue.push(selectedValue);
                                          } else if (
                                            !isChecked &&
                                            getIndex > -1
                                          ) {
                                            oldValue.splice(getIndex, 1);
                                          }

                                          setFieldValue(
                                            field['name'],
                                            oldValue,
                                          );
                                        } else {
                                          setFieldValue(
                                            field['name'],
                                            selectedValue,
                                          );
                                        }
                                      }}
                                    />
                                  )}

                                  {![
                                    'richtext',
                                    'modal',
                                    'select',
                                    'radio',
                                    'checkbox',
                                    'date',
                                  ].includes(field['type']) && (
                                    <DaynamicInputField
                                      label={field['label']}
                                      type={field['type']}
                                      placeholder={field['label']}
                                      required={field['isRequired']}
                                      invalid={{ errors, touched }}
                                      {...getFieldProps(field['name'])}
                                    />
                                  )}
                                </>
                              </FormGroup>
                            </Col>
                          );
                        }
                      })}
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <ButtonGroup className="mb-3 text-left">
                        <Button
                          type="submit"
                          className="btn btn-success"
                          onClick={(e) => {
                            setBtnClicked('submit');
                          }}
                        >
                          {id === undefined
                            ? formObj.submitBtnTitle
                              ? formObj.submitBtnTitle
                              : 'Submit'
                            : formObj.updateBtnTitle
                            ? formObj.updateBtnTitle
                            : 'Update'}
                        </Button>

                        {formObj.draftBtnTitle && (
                          <Button
                            type="submit"
                            className="btn btn-info"
                            onClick={(e) => {
                              setBtnClicked('save_as_draft');
                            }}
                          >
                            {formObj.draftBtnTitle}
                          </Button>
                        )}

                        {formObj.resetBtnTitle && (
                          <Button
                            type="reset"
                            onClick={(e) => {
                              resetForm();
                            }}
                            className="btn btn-danger me-2"
                          >
                            {formObj.resetBtnTitle}
                          </Button>
                        )}
                      </ButtonGroup>
                    </Col>
                  </Row>
                  {formObj.debug && (
                    <Row>
                      <Col sm={12}>{JSON.stringify(values)}</Col>
                    </Row>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default DaynamicForm;
