import { all, fork } from 'redux-saga/effects';

//Layout
import LayoutSaga from './saga';

//Login

//User Profile
// import ProfileSaga from "./auth/profile/saga";

// Forget Password
// import forgetPasswordSaga from "./auth/forgetpwd/saga";

export default function* rootSaga() {
    yield all([
        //public
        fork(LayoutSaga),
    ]);
}
