import { useEffect, useState } from "react";
import { Button as BsButton, Col } from "reactstrap";
import * as url from 'src/helpers/url_helper';
import {
    useGetCommentsDataByMutation,
} from 'src/rtk/features/disertation-thesis/crudApi';
import { CommentsModal } from './CommentsModal';

const CommentBtn = (props: any) => {

    const {
        thesisDissertationFile,
        thesisDissertationFormSetupId,
        showCommentSectionModal,
        setShowCommentSectionModal,
        commentsFieldId,
        setCommentsFieldId,
        commentsFieldName,
        setCommentsFieldName,
        detailsObj,       
    } = props;

    const [messages, setMessages] = useState<any>([]);

    const [
        commentsDataList,
        {
            isLoading: commentsDataListLoading,
            isSuccess: commentsDataListLoadingSuccess,
            error: commentsDataListLoadingError,
        },
    ] = useGetCommentsDataByMutation();

    const getCommentsData = (thesis_dissertation_id: string, column_id: string) => {
        commentsDataList(`${url.THESIS_DISSERTATION_ENTRY_COMMENT_LIST}/${thesis_dissertation_id}/${column_id}`).then(function (result: any,) {
            // here you can use the result of promiseB
            if (result?.data?.data) {
                setMessages(result?.data?.data);
            }
        });
    };

    useEffect(() => {
        if (thesisDissertationFile?.id && thesisDissertationFormSetupId) {
            getCommentsData(thesisDissertationFile?.id, thesisDissertationFormSetupId);
        }
    }, [thesisDissertationFile, thesisDissertationFormSetupId]);

    return (
        <>
            <Col sm={'auto'} className='btn noti-icon align-self-center'>
                <span className="badge bg-danger rounded-pill">{messages?.length ?? 0}</span>
                <BsButton
                    type='button'
                    className='btn-sm'
                    onClick={(e) => {
                        setShowCommentSectionModal(!showCommentSectionModal);
                        setCommentsFieldId(detailsObj?.thesis_dissertation_setup_id);
                        setCommentsFieldName(detailsObj?.thesis_setup_column_name);
                    }}
                >
                    <i className='fas fa-comments font-size-10'></i>
                </BsButton>
            </Col>
            {showCommentSectionModal && commentsFieldId === detailsObj?.thesis_dissertation_setup_id ?
                <CommentsModal
                    thesisDissertationFile={thesisDissertationFile}
                    thesisDissertationFormSetupId={commentsFieldId}
                    thesisDissertationFormSetupColumnName={commentsFieldName}
                    showModal={showCommentSectionModal}
                    setShowModal={setShowCommentSectionModal}
                    messages={messages}
                    setMessages={setMessages}
                />
                :
                null
            }
        </>
    )
};

export default CommentBtn;