import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Table,
} from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetApprovalsQuery } from 'src/rtk/features/setup/approval/approvalApi';
import ApprovalTableRow from './ApprovalTableRow';

export const Approval = () => {
  const { data, error, isLoading, isError } = useGetApprovalsQuery();

  const err: CustomError = error as CustomError;
  const approvals = data?.data || [];

  let content = null;
  if (isLoading) {
    content = <div className="mt-3">Loading...</div>;
  }
  if (!isLoading && isError && error) {
    content = <div className="mt-3">{err.message}</div>;
  }
  if (!isLoading && !isError && approvals?.length > 0) {
    content = approvals.map((approval, index) => (
      <ApprovalTableRow approval={approval} index={index} key={approval.id} />
    ));
  } else {
    content = (
      <tr>
        <td colSpan={11}>
          <div className="text-center text-danger h6">No data available!</div>
        </td>
      </tr>
    );
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb title="Approval" />
        <Card>
          <CardHeader className="d-flex justify-content-between text-center align-items-center ">
            <div className="fw-bold fs-5 d-flex flex-shrink-1">
              Approvals List
            </div>
            <div className="d-flex flex-shrink-0">
              <Link to={'/setup/approval/add'}>
                <Button color="success" size="sm" outline className="rounded-5">
                  <i className="bx bx-plus"></i> Add Approval
                </Button>
              </Link>
            </div>
          </CardHeader>
          <CardBody>
            <div className="table-responsive text-nowrap text-center  x-overflow-scroll">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Sl.</th>
                    <th>Module Name</th>
                    <th>Table Name</th>
                    <th>Page Name</th>
                    <th>Total Steps</th>
                    <th>Minimum Steps</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
