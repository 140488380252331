import React, { useEffect, useRef, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { Button, Col, Label, Row } from "reactstrap";
import { config } from "src/config";
import { CommentSectionModal } from "../CommentSectionForProtocol/CommentSectionModal";
import * as url from 'src/helpers/url_helper';

const Attachments = (props: any) => {
    const { attachmentInputList, setInputList, existingProtocol, commentsDataList } = props;

    const [dataButtonDisabled, setDataButtonDisabled] = useState<boolean>(true);
    const [ethicalButtonDisabled, setEthicalButtonDisabled] = useState<boolean>(true);
    const [otherButtonDisabled, setOtherButtonDisabled] = useState<boolean>(true);

    const [uploadedDataFile, setUploadedDataFile] = useState<string>('');
    const [uploadedEthicalFile, setUploadedEthicalFile] = useState<string>('');
    const [uploadedOtherFile, setUploadedOtherFile] = useState<string>('');
    const [numberOfData, setNumberOfData] = useState(0);
    const [numberOfEthical, setNumberOfEthical] = useState(0);
    const [numberOfOther, setNumberOfOther] = useState(0);

    const [nameOfOtherDocument, setNameOfOtherDocument] = useState('');

    const [messages, setMessage] = useState<any>([]);
    const [showCommentSectionModal, setShowCommentSectionModal] = useState<boolean>(false);


    const uploadedDataFileReference = useRef<any>();
    const uploadedEthicalFileReference = useRef<any>();
    const uploadedOtherFileReference = useRef<any>();

    const getCommentsData = (id: any, nameOfTheField: any) => {
        commentsDataList(`${url.THESIS_DISSERTATION_PROTOCOL_COMMENT_LIST}/${id}/${nameOfTheField}`).then(function (result: any,) {
            // here you can use the result of promiseB
            if (result?.data?.data) {
                setMessage(result?.data?.data);
            }
        });

        // setMessage(commentsData?.data || []);
    };

    useEffect(() => {
        getCommentsData(existingProtocol?.data?.id, 'attachment');
    }, []);

    const handleAddFields = (button: string) => {
        const values = [...attachmentInputList];
        setInputList(values);

        if (button === "data") {
            setDataButtonDisabled(true);
            setNumberOfData(numberOfData + 1);
            // add code changes according to backend    ---------------------
            values.push({
                attachmentType: 'Data Collection Instruments',
                nameOfTheDocument: numberOfData === 0 ? 'Data Collection Instruments' : `Data Collection Instruments (${numberOfData})`,
                attachmentFile: uploadedDataFile ? uploadedDataFile : '',
            });
            setUploadedDataFile('');
            uploadedDataFileReference.current.value = '';

        } else if (button === "ethical") {
            setEthicalButtonDisabled(true);
            setNumberOfEthical(numberOfEthical + 1)
            // add code changes according to backend   ------------------- 
            values.push({
                attachmentType: 'Ethical Clearance',
                nameOfTheDocument: numberOfEthical === 0 ? 'Ethical Clearance' : `Ethical Clearance (${numberOfEthical})`,
                attachmentFile: uploadedEthicalFile ? uploadedEthicalFile : '',
            });
            setUploadedEthicalFile('');
            uploadedEthicalFileReference.current.value = '';
        } else {
            setOtherButtonDisabled(true);
            setNumberOfOther(numberOfOther + 1)
            // add code changes according to backend   ------------------- 
            values.push({
                attachmentType: 'Other Documents',
                nameOfTheDocument: nameOfOtherDocument.length > 0 ? nameOfOtherDocument : numberOfOther === 0 ? 'Other Documents' : `Other Documents (${numberOfOther})`,
                attachmentFile: uploadedOtherFile ? uploadedOtherFile : '',
            });
            setUploadedOtherFile('');
            uploadedOtherFileReference.current.value = '';
        }

    };

    const handleRemoveFields = (index: number) => {
        const values = [...attachmentInputList];
        values.splice(index, 1);
        setInputList(values);
    };

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        if (name === "attachmentDataFile") {

            if (event.target.files[0]) {
                setDataButtonDisabled(false);
                setUploadedDataFile(event.target.files[0]);
            } else {
                setDataButtonDisabled(true);
                setUploadedDataFile('');
            }
        } else if (name === "attachmentEthicalFile") {
            if (event.target.files[0]) {
                setEthicalButtonDisabled(false);
                setUploadedEthicalFile(event.target.files[0]);
            } else {
                setEthicalButtonDisabled(true);
                setUploadedEthicalFile('');
            }
        } else {
            if (event.target.files[0]) {
                setOtherButtonDisabled(false);
                setUploadedOtherFile(event.target.files[0]);
            } else {
                setOtherButtonDisabled(true);
                setUploadedOtherFile('');
            }
        }
    };

    const handleNameInputChange = (event: any) => {
        setNameOfOtherDocument(event.currentTarget.value);
    };

    // console.log(attachmentInputList);

    const columns: TableColumn<any>[] = [
        {
            name: <div className="text-center">Sl. No.</div>,
            width: '100px',
            cell: (row, index) => index + 1,
            center: true,
        },
        {
            name: <div className="text-center">Name of the Document</div>,
            width: '300px',
            cell: (row, index) => attachmentInputList[index]?.nameOfTheDocument ? attachmentInputList[index].nameOfTheDocument : 'N/A',
            center: true,
        },

        {
            name: <div className="text-center">File Preview</div>,
            selector: (row) => row.attachmentFile && (
                typeof row.attachmentFile === 'string' ? (
                    <a
                        className="me-2 p-0 center"
                        href={`${config?.APP_URL}${row.attachmentFile}`}
                        rel="noreferrer"
                        target="_blank"
                    >
                        <span className="btn-outline-info btn-sm btn ms-2">View</span>
                    </a>
                ) : (
                    <a
                        className="me-2 p-0 center"
                        href={URL.createObjectURL(row.attachmentFile)}
                        rel="noreferrer"
                        target="_blank"
                    >
                        <span className="btn-outline-info btn-sm btn ms-2">View</span>
                    </a>
                )
            ),

            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Action</div>,
            width: '150px',
            cell: (row) => (
                <>
                    <button
                        type="button"
                        className={'btn btn-danger mb-2'}
                        onClick={() => handleRemoveFields(attachmentInputList?.indexOf(row))}
                    >
                        <i className="fa fa-minus-circle" />
                    </button>
                </>
            ),

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <React.Fragment>

            <Row>
                <Col lg={12}>
                    <Row className="justify-content-end">
                        <Col sm="auto" className='btn noti-icon position-relative'>
                            <span className="badge bg-danger rounded-pill">{messages?.length ?? 0}</span>
                            <Button
                                type="button"
                                className="btn btn-primary btn-sm mb-3"
                                onClick={(e) => {
                                    setShowCommentSectionModal(!showCommentSectionModal);
                                }}
                            >
                                <i className="fas fa-2x fa-comments"></i>
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col lg={12}>
                    <Row className="mb-5">
                        {/* Data collection */}
                        <Row>
                            <Col lg={5}>
                                <Label className='form-label  required-field'>Data Collection Instruments</Label>
                                <input
                                    type="file"
                                    ref={uploadedDataFileReference}
                                    className="form-control"
                                    name="attachmentDataFile"
                                    onChange={(event) => {
                                        handleInputChange(event);
                                    }}
                                />
                            </Col>
                            {/* button */}
                            <Col lg={1}>
                                {!dataButtonDisabled ?
                                    (<button
                                        type="button"
                                        className={'btn btn-success button-attachmentAdd '}
                                        onClick={() => handleAddFields('data')}
                                    >
                                        Add
                                    </button>)
                                    :
                                    (<button
                                        type="button"
                                        className={'btn btn-success button-attachmentAdd '}
                                        onClick={() => handleAddFields('data')}
                                        disabled
                                    >
                                        Add
                                    </button>)}
                            </Col>
                            {/* tip */}
                            <Col lg={6}>
                                <p className="button-tip">
                                    e.g., subject recruiting materials, questionnaire, interview guide, group discussion guide, physical/clinical data collection sheet etc. if and as applicable.
                                </p>
                            </Col>
                        </Row>
                        {/* Ethical Clearance */}
                        <Row>
                            <Col lg={5}>
                                <Label className='form-label  required-field'>Ethical Clearance</Label>
                                <input
                                    type="file"
                                    ref={uploadedEthicalFileReference}
                                    className="form-control"
                                    name="attachmentEthicalFile"
                                    onChange={(event) => {
                                        handleInputChange(event);
                                    }}
                                />
                            </Col>
                            {/* button */}
                            <Col lg={1}>
                                {!ethicalButtonDisabled ?
                                    (<button
                                        type="button"
                                        className={'btn btn-success button-attachmentAdd'}
                                        onClick={() => handleAddFields('ethical')}
                                    >
                                        Add
                                    </button>)
                                    :
                                    (<button
                                        type="button"
                                        className={'btn btn-success button-attachmentAdd'}
                                        onClick={() => handleAddFields('ethical')}
                                        disabled
                                    >
                                        Add
                                    </button>)}
                            </Col>
                            {/* tip */}
                            <Col lg={6}>
                                <p className="button-tip">
                                    Documents regarding ethical clearance as prescribed by the relevant body of BCPS.
                                </p>
                            </Col>
                        </Row>

                        {/* Other Documents */}
                        <Row>
                            <Col lg={5}>
                                <Label className='form-label'>Other Documents</Label>
                                <input
                                    type="file"
                                    ref={uploadedOtherFileReference}
                                    className="form-control"
                                    name="attachmentOtherFile"
                                    onChange={(event) => {
                                        handleInputChange(event);
                                    }}
                                />
                            </Col>
                            <Col lg={5}>
                                <input
                                    type="text"
                                    placeholder="Name of the Document"
                                    className="form-control button-name-document"
                                    name="nameOftheDocument"
                                    onChange={(event) => {
                                        handleNameInputChange(event);
                                    }}
                                />
                            </Col>
                            <Col lg={2}>
                                {!otherButtonDisabled ?
                                    (<button
                                        type="button"
                                        className={'btn btn-success button-attachmentAdd'}
                                        onClick={() => handleAddFields('other')}
                                    >
                                        Add
                                    </button>)
                                    :
                                    (<button
                                        type="button"
                                        className={'btn btn-success button-attachmentAdd'}
                                        onClick={() => handleAddFields('other')}
                                        disabled
                                    >
                                        Add
                                    </button>)}
                            </Col>
                        </Row>



                    </Row>

                    {/* attachment table */}
                    {attachmentInputList?.length > 0 &&
                        <div className="row mb-5 mt-5">
                            <p>Attachments:</p>
                            <div className="col-sm-12">
                                <DataTable
                                    columns={columns}
                                    data={attachmentInputList?.length > 0 ? attachmentInputList : []}
                                    expandOnRowClicked
                                    highlightOnHover
                                />
                            </div>
                        </div>}
                </Col>

                {showCommentSectionModal ?
                    <CommentSectionModal
                        fieldName={'attachment'}
                        fieldLabel={'Attachment'}
                        existingProtocol={existingProtocol}
                        showModal={showCommentSectionModal}
                        setShowModal={setShowCommentSectionModal}
                        setMessageCount={setMessage}
                    />
                    :
                    null
                }
            </Row>

        </React.Fragment>
    );
};

export default Attachments;
