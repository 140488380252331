import LeaveApprovalListForAdmin from "src/pages/LeaveRecords/LeaveApprovalListForAdmin";
import LeaveApprovalListForSupervisor from "src/pages/LeaveRecords/LeaveApprovalListForSupervisor";
import LeaveEntryList from "src/pages/LeaveRecords/LeaveEntryList";

export const leaveRoutes = [
    { path: '/trainee-leave-apply', component: LeaveEntryList },    
    { path: '/supervisor-leave-apply', component: LeaveEntryList },    
    { path: '/leave-approval-by-admin', component: LeaveApprovalListForAdmin },    
    { path: '/leave-approval-by-supervisor', component: LeaveApprovalListForSupervisor },    
];
