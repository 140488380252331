import { useFormik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Button, Card, CardBody, Container, Form, FormGroup, Label } from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetLeaveApprovalListForAdminQuery } from 'src/rtk/features/leaveManagement/leaveManagementApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import LeaveApprovalViewModal from './LeaveApprovalViewModal';
import loader from '../../assets/images/loader.gif';

const LeaveApprovalListForAdmin = () => {
    const [modalShow, setModalShow] = useState(false);
    const [leaveId, setLeaveId] = useState('');

    const { data: leaveTypeLookupData } = useGetLookupDataQuery('leave_type');

    const typeList: Lookup[] = (leaveTypeLookupData as any)?.data[0]?.childs || [];

    const allLeaveType: SelectOption[] =
        typeList?.map((type) => ({
            value: type.name,
            label: type.name,
        })) || [];

    const allStatus: SelectOption[] = [
        { value: 'Approve', label: 'Approve' },
        { value: 'Pending', label: 'Pending' },
        { value: 'Reject', label: 'Reject' },
    ];


    const [page, setPage] = useState<any>(1);
    const [limit, setLimit] = useState<any>(10);
    const [search, setSearch] = useState('');

    const generateQueryUrl = () => {
        return `page=${page}&paginate_per_page=${limit}${search}`;
    };

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setLimit(newPerPage);
        setPage(page);
    };

    const {
        data: approvalList,
        isSuccess,
        isLoading,
        isFetching,
    } = useGetLeaveApprovalListForAdminQuery(generateQueryUrl());

    interface SearchFields {
        search?: string;
        start_date?: string;
        end_date?: string;
        approved_status?: string;
        leave_type?: string;
        bmdc_registration_no?: string;
    }

    const initialValues: SearchFields = {
        search: '',
        start_date: '',
        end_date: '',
        approved_status: '',
        leave_type: '',
        bmdc_registration_no: '',
    };

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        onSubmit: (values: any) => {
            let s: string = '';

            if (values.search !== '') {
                s = s + `&search=${values.search}`;
            }

            if (values.start_date !== '') {
                s = s + `&start_date=${values.start_date}`;
            }

            if (values.end_date !== '') {
                s = s + `&end_date=${values.end_date}`;
            }

            if (values.leave_type !== '') {
                s = s + `&leave_type=${values.leave_type}`;
            }

            if (values.approved_status !== '') {
                s = s + `&approved_status=${values.approved_status}`;
            }
            if (values.bmdc_registration_no !== '') {
                s = s + `&bmdc_registration_no=${values.bmdc_registration_no}`;
            }

            setSearch(s);
            setPage('1');
            console.log(values);
        },
    });

    const columns: TableColumn<any>[] = [
        {
            name: <div>Sl. No.</div>,
            cell: (row, index) => <strong>{((page - 1) * limit + index) + 1}</strong>,
            width: '80px',
        },

        {
            name: <div>Name</div>,
            selector: (row) => row?.registration?.personal_info?.full_name,
            sortable: false,
            wrap: true,
        },
        {
            name: <div>BMDC Reg. No</div>,
            selector: (row) => row?.registration?.bmdc_registration_no,
            sortable: false,
        },
        {
            name: <div>From Date</div>,
            selector: (row) => moment(row?.start_date).format("DD-MMM-YYYY"),
            sortable: false,
        },
        {
            name: <div>To Date</div>,
            selector: (row) => moment(row?.end_date).format("DD-MMM-YYYY"),
            sortable: false,
        },
        {
            name: <div>No. of Days</div>,
            selector: (row) => row?.no_of_days,
            sortable: false,
        },
        {
            name: <div>Leave Type</div>,
            selector: (row) => row?.leave_type,
            sortable: false,
            wrap: true,
        },
        {
            name: <div>Reason</div>,
            selector: (row) => row?.leave_reason,
            sortable: false,
            wrap: true,
        },
        {
            name: <div>Status</div>,
            selector: (row) => row?.approved_status,
            cell: (row) => {
                switch (row?.approved_status) {

                    case 'Approve':
                        return (
                            <div className="badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium">
                                {'Approved'}
                            </div>
                        );
                    case 'Pending':
                        return (
                            <div className="badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium">
                                {'Pending'}
                            </div>
                        );
                    case 'Reject':
                        return (
                            <div className="badge rounded-pill d-inline-block  badge-rejected font-size-12 fw-medium">
                                {'Rejected'}
                            </div>
                        );

                    default:
                        return (
                            <div className="badge rounded-pill d-inline-block  badge-draft font-size-12 fw-medium">
                                {row?.approved_status}
                            </div>
                        );
                }
            },
            sortable: false,
        },

        {
            name: <div>Action</div>,
            cell: (row) => (
                <div className='my-1'>
                    <Button
                        className="btn btn-sm btn-info"
                        onClick={() => modalShowHandler(true, row?.id)}
                    >
                        <span className="fas fa-eye"></span> View
                    </Button>
                    {modalShow && leaveId === row?.id ? (
                        <LeaveApprovalViewModal
                            isOpen={modalShow}
                            toggle={() => setModalShow(!modalShow)}
                            userInfo={row}
                            id={row.id!}
                        />
                    ) : null}
                </div>
            ),
        },
    ];

    const modalShowHandler = (modalState: boolean, id: string) => {
        setModalShow(modalState);
        setLeaveId(id);
    }

    return (
        <div className="page-content">
            {isLoading || isFetching ? (
                <div className="overlay">
                <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Container fluid>
                <BetterBreadcrumb
                    breadcrumbItem={[{ link: '#', name: 'Admin' }]}
                    title={'Leave Approval'}
                />
                <Card>
                    <CardBody>
                        <Form
                            className="custom-form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                            onReset={(e) => {
                                e.preventDefault();
                                handleSubmit();
                                resetForm();
                            }}
                        >
                            <div className="filter-wrapper">
                                <FormGroup>
                                    <Label for="bmdc_registration_no">BMDC Reg. No</Label>
                                    <InputField
                                        type="text"
                                        placeholder="BMDC Reg. No"
                                        {...getFieldProps('bmdc_registration_no')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="leave_type">Leave Type</Label>
                                    <InputField
                                        type="select"
                                        placeholder="Leave Type"
                                        options={allLeaveType}
                                        {...getFieldProps('leave_type')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="start_date">From Date</Label>
                                    <InputField
                                        type="date"
                                        {...getFieldProps('start_date')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="end_date">To Date</Label>
                                    <InputField
                                        type="date"
                                        {...getFieldProps('end_date')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="approved_status">Status</Label>
                                    <InputField
                                        type="select"
                                        placeholder="Status"
                                        options={allStatus}
                                        {...getFieldProps('approved_status')}
                                    />
                                </FormGroup>

                                <FormGroup className="btn-column mb-3">
                                    <Button color="warning" type="reset" outline>Reset</Button>
                                    <Button type="submit" color='primary'>Search</Button>
                                </FormGroup>
                            </div>
                        </Form>

                        <DataTable
                            columns={columns}
                            data={approvalList?.data!}
                            pagination
                            paginationServer
                            progressPending={isLoading}
                            expandOnRowClicked
                            highlightOnHover
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            paginationTotalRows={approvalList?.total}
                            paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
                            responsive
                            persistTableHead={true}
                        />
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default LeaveApprovalListForAdmin;
