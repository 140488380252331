import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row
} from 'reactstrap';
import { DaynamicInputField, DynamicView } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import { putUrlString, setFromErrors } from 'src/helpers';
import store from 'src/rtk/app/store';


import {
  useCreateByPostFormCommentsMutation,
  useCreateByPostMutation,
  useGetCommentsDataByMutation,
  useGetDataQuery,
  useUpdateByPatchMutation,
} from 'src/rtk/features/disertation-thesis/crudApi';
import { useGetPersonalProfileQuery } from 'src/rtk/features/trainee/traineeApi';
import * as Yup from 'yup';
import formObj from './formObj';

const transformInToFormObject = (data: any) => {
  let formData = new FormData();
  for (let key in data) {
    if (Array.isArray(data[key])) {
      data[key].forEach((obj: any, index: any) => {
        let keyList = Object.keys(obj);
        keyList.forEach((keyItem) => {
          let keyName = [key, "[", index, "]", ".", keyItem].join("");
          formData.append(keyName, obj[keyItem]);
        });
      });
    } else if (typeof data[key] === "object") {
      for (let innerKey in data[key]) {
        formData.append(`${key}.${innerKey}`, data[key][innerKey]);
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  return formData;
}

const ProtocolEntry = () => {
  // const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [btnClicked, setBtnClicked] = useState<string>('');
  const [id, setId] = useState<string>('');
  const [showForm, setShowForm] = useState<boolean>(true);
  const [viewData, setViewData] = useState<any>(false);
  const [messages, setMessage] = useState<any>([]);
  const [comment, setComment] = useState<any>({});

  const { data: personalProfile } = useGetPersonalProfileQuery();
  const currentUser: any = store.getState().auth.user;



  let initialValues: any = {
    attachments: []
  };
  let validationObj: any = {};
  let inputFields: any = [];
  let viewColumn: any = [];
  let formFilds = formObj?.formFields || {};
  let styles = {
    changeRequestGuide: {
      marginLeft: '76px',
    },
    changeRequestCoGuide: {
      marginLeft: '176px',
    },
  };

  Object.keys(formFilds)
    .sort(function (a: any, b: any) {
      return parseInt(a.positionInForm) - parseInt(b.positionInForm);
    })
    .map((key) => {
      let field = formFilds[key];

      if (field['viewCell'] && field['type'] !== "richtext") {
        viewColumn.push({
          name: field['label'],
          viewCell: field['viewCell'],
        });
      }

      if (formFilds[key]['showInForm']) {
        inputFields.push(formFilds[key]);

        if (
          field['type'] === 'checkbox' &&
          typeof field['default'] === 'string'
        ) {
          field['default'] = [field['default'].trim()];
        } else if (
          field['type'] === 'radio' &&
          typeof field['default'] === 'object'
        ) {
          field['default'] = field['default'].join(' ').trim();
        } else if (field['type'] === 'date') {
          field['default'] = field?.options?.defaultDate || '';
        }

        if (field['isRequired']) {
          validationObj[field['name']] = field['validation'];
        }

        initialValues[field['name']] = field['default'];
      }
    });

  const [createByPost, { isSuccess, isLoading, isError, error }] =
    useCreateByPostMutation();
  const err: CustomError = error as CustomError;

  const [
    createByPostComment,
    {
      isSuccess: isCommentSuccess,
      isLoading: isCommentLoading,
      isError: isCommentError,
      error: commentError,
    },
  ] = useCreateByPostFormCommentsMutation();

  const [
    commentsDataList,
    {
      isLoading: commentsDataListLoading,
      isSuccess: commentsDataListLoadingSuccess,
      error: commentsDataListLoadingError,
    },
  ] = useGetCommentsDataByMutation();

  const getCommentsData = (id: any) => {
    commentsDataList(`${formObj.listCommentAPIUrl}/${id}`).then(function (
      result: any,
    ) {
      // here you can use the result of promiseB
      if (result?.data?.data) {
        setMessage(result?.data?.data);
      }
    });

    // setMessage(commentsData?.data || []);
  };

  useEffect(() => {
    if (isCommentSuccess) {
      getCommentsData(id);
      toast.dismiss();
    }
    if (isCommentError) {
      toast.dismiss();
      toast.error('Something went wrong');
    }
  }, [isCommentLoading, isCommentSuccess, isCommentError]);

  const [
    updateByPatch,
    {
      isSuccess: isUpdateSuccess,
      isLoading: isUpdateLoading,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateByPatchMutation();
  const updateErr: CustomError = updateError as CustomError;

  useEffect(() => {
    if (isUpdateLoading) {
      toast.dismiss();
      toast.loading(formObj.title + ' form submitting..');
    }
    if (isUpdateSuccess) {
      toast.success(formObj.title + ' updated successfully');
      // setShowForm(false)
      // history.push(formObj.listUrl);
    }
    if (isUpdateError) {
      toast.error(updateErr?.message || 'Something went wrong');
      setFromErrors(updateErr.data, setErrors);
    }
  }, [isUpdateLoading, isUpdateSuccess, isUpdateError]);

  const {
    handleSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    values,
    setValues,
    setFieldValue,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({ ...validationObj }),
    onSubmit: (values) => {
      values.registration_no = currentUser.registration_no;
      values.submission_status = 'Submit';

      if (btnClicked === 'submit') {
        values.submission_status = 'Submit';
      } else if (btnClicked === 'save_as_draft') {
        values.submission_status = 'Draft';
      }

      // const formData = transformInToFormObject(values);

      const formData = new FormData();
      Object.keys(values).forEach((key) => {

        if (key === "attachments") {
          for (var i = 0; i < values[key].length; i++) {
            // formData.append('attachments[]', values[key][i]["type"]);
            formData.append('attachments[]', JSON.stringify({ ...values[key][i] }));
          }
        } else
          formData.append(key, values[key]);
      });



      console.log(values)
      console.log(formData)

      if (id) {
        if (formObj?.isUpdateByPatch) {
          const updateUrl = formObj.updateAPIUrl + putUrlString(id, formData);
          updateByPatch(updateUrl);
        } else {
          let postData = {
            queryUrl: formObj['updateAPIUrl'],
            formData: formData,
          };
          createByPost(postData);
        }
      } else {
        let postData = {
          queryUrl: formObj['createAPIUrl'],
          formData: formData,
        };

        createByPost(postData);
      }
    },
  });

  useEffect(() => {
    if (isLoading) {
      toast.dismiss();
      toast.loading(formObj.title + ' form submitting..');
    }

    if (isSuccess) {
      toast.dismiss();
      toast.success(formObj.title + ' save successfully');
      // history.push(formObj.listUrl);
      setShowForm(false);
    }

    if (isError) {
      toast.dismiss();
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isLoading, isSuccess, isError]);

  let apiUrl = formObj.listAPIUrl;

  if (apiUrl) {
    const {
      data: dataList,
      isLoading: dataListLoading,
      isSuccess: dataListLoadingSuccess,
      error: dataListLoadingError,
    } = useGetDataQuery(`${apiUrl}`);

    useEffect(() => {
      if (dataListLoadingError) {
        toast.dismiss();
        toast.success('Data not found. Please create first.');
      } else if (dataListLoading || !dataListLoadingSuccess) {
        toast.dismiss();
        // toast.loading('Data Loading...');
      } else {
        let vData = dataList?.data || false;
        if (vData['id']) {
          setId(vData['id']);
          getCommentsData(vData['id']);
          setViewData(vData);
          setShowForm(false);

          // let coGuideId = [];

          // if (vData['co_guide_id_1']) {
          //   coGuideId.push(vData['co_guide_id_1']);
          // }

          // if (vData['co_guide_id_2']) {
          //   coGuideId.push(vData['co_guide_id_2']);
          // }
          // if (vData['co_guide_id_3']) {
          //   coGuideId.push(vData['co_guide_id_3']);
          // }

          inputFields.findIndex((item: any) => {
            setFieldValue(item.name, vData[item.name] || '');
          })
          setFieldValue("id", vData["id"] || '');


          // console.log(inputFields)
          // Object.keys(vData).map((itemKey: any) => {
          //   console.log(itemKey)
          //   let hasItem = 
          //   console.log(itemKey, hasItem)
          //   setFieldValue(itemKey, vData[itemKey] || '');
          // });

          // setFieldValue('co_guide_id', coGuideId);
        } else {
          setShowForm(true);
        }
        toast.dismiss();
        // toast.success('Data Load Successfully');
      }
    }, [dataListLoading, dataListLoadingSuccess, dataListLoadingError]);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {formObj.breadcrumbItemForForm && (
            <BetterBreadcrumb
              title={
                !showForm
                  ? 'View ' + formObj?.title
                  : id === ''
                    ? 'Add ' + formObj?.title
                    : 'Update ' + formObj?.title
              }
              breadcrumbItem={formObj.breadcrumbItemForForm}
            />
          )}

          <Row>
            <Col>
              {(
                <div className="row align-items-center justify-content-center">
                  <Form
                    className="custom-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    onReset={(e) => {
                      e.preventDefault();
                      resetForm();
                    }}

                  >
                    <div className="card">
                      {personalProfile?.data?.details?.personal_info
                        ?.full_name && (
                          <div className="card-header">
                            <div className="d-flex bd-highlight">
                              <div className="row p-2 w-100 bd-highlight">
                                <div className="col-4">
                                  <p style={{ fontSize: '18px' }}>
                                    <b>Trainee Name: </b>
                                    {
                                      personalProfile?.data?.details
                                        ?.personal_info?.full_name
                                    }
                                  </p>

                                  <p style={{ fontSize: '18px' }}>
                                    <b>Address: </b>
                                    {personalProfile?.data?.details
                                      ?.latest_present_address?.district && (
                                        <>
                                          {
                                            personalProfile?.data?.details
                                              ?.latest_present_address
                                              ?.address_line_1
                                          }
                                          ,{' '}
                                          {
                                            personalProfile?.data?.details
                                              ?.latest_present_address
                                              ?.police_station
                                          }
                                          ,{' '}
                                          {
                                            personalProfile?.data?.details
                                              ?.latest_present_address?.district
                                          }
                                        </>
                                      )}
                                  </p>

                                  <p style={{ fontSize: '18px' }}>
                                    <b>Contact No: </b>
                                    {
                                      personalProfile?.data?.details
                                        ?.personal_info?.mobile
                                    }
                                  </p>

                                  <p style={{ fontSize: '18px' }}>
                                    <b>Discipline: </b>
                                    {
                                      personalProfile?.data?.details?.subject
                                        ?.subject_name
                                    }
                                  </p>
                                </div>

                                {/* <div className="col-7">
                                  <p style={{ fontSize: '18px' }}>
                                    <b>Place of Study: </b>
                                    {
                                      personalProfile?.data?.details
                                        ?.current_session_and_supervisor_info
                                        ?.supervisor
                                        ?.institute_name
                                    }

                                    <Button
                                      type="button"
                                      className="btn btn-sm btn-success ms-2"
                                      onClick={(e) => {

                                      }}
                                    >
                                      <i className='fas fa-add'></i> {'Add'}
                                    </Button>

                                    <Button
                                      type="button"
                                      className="btn btn-sm btn-secondary ms-2"
                                      onClick={(e) => {

                                      }}
                                    >
                                      <i className='fas fa-edit'></i> {'Change Request'}
                                    </Button>
                                  </p>

                                  <p style={{ fontSize: '18px' }}>
                                    <b>Guide: </b>
                                    {personalProfile?.data?.details
                                      ?.latest_present_address?.district && (
                                        <>
                                          {
                                            personalProfile?.data?.details
                                              ?.latest_present_address
                                              ?.address_line_1
                                          }
                                          ,{' '}
                                          {
                                            personalProfile?.data?.details
                                              ?.latest_present_address
                                              ?.police_station
                                          }
                                          ,{' '}
                                          {
                                            personalProfile?.data?.details
                                              ?.latest_present_address?.district
                                          }
                                        </>
                                      )}

                                    <Button
                                      type="button"
                                      style={styles.changeRequestGuide}
                                      className="btn btn-sm btn-secondary"
                                      onClick={(e) => {

                                      }}
                                    >
                                      <i className='fas fa-edit'></i> {'Change Request'}
                                    </Button>
                                  </p>

                                  <p style={{ fontSize: '18px' }}>
                                    <b>Co-Guide: </b>
                                    {
                                      personalProfile?.data?.details
                                        ?.personal_info?.mobile
                                    }

                                    <Button
                                      type="button"
                                      style={styles.changeRequestCoGuide}
                                      className="btn btn-sm btn-secondary"
                                      onClick={(e) => {

                                      }}
                                    >
                                      <i className='fas fa-edit'></i> {'Change Request'}
                                    </Button>
                                  </p>
                                </div> */}
                              </div>
                              {id !== '' && (
                                <>
                                  <div className="p-2 flex-shrink-1 bd-highlight">
                                    <Button
                                      type="button"
                                      className="btn btn-success"
                                      onClick={(e) => {
                                        setShowForm(!showForm);
                                      }}
                                    >
                                      {!showForm ? formObj.editBtnTitle ? formObj.editBtnTitle : 'Edit' : 'View'}
                                    </Button>

                                    {/* <Button
                                      type="button"
                                      className="btn btn-dark  float-right"
                                      onClick={(e) => {
                                        setShowForm(false);
                                      }}
                                    >
                                      Back
                                    </Button> */}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      <div className="card-body">

                        <DynamicView
                          header="Title Entry Details"
                          viewColumn={formObj.titleViewData}
                          // viewData={viewData}
                          apiUrl={formObj.titleEntryListAPIUrl}
                        />

                        {!showForm && viewData && (
                          <>

                            <DynamicView
                              header="Protocol Entry Details"
                              viewColumn={viewColumn}
                              viewData={viewData}
                            // apiUrl={formObj.listAPIUrl}
                            />
                          </>

                        )}

                        <div className="accordion" id="accordionExample">
                          <Row>
                            {inputFields.length > 0 &&
                              inputFields.map((field: any, key: any) => {
                                if (
                                  !['hidden', 'submit'].includes(field['type'])
                                ) {
                                  field['type'] = field['type'];

                                  if (
                                    field['dependField'] &&
                                    field['dependValue'] &&
                                    field['condition']
                                  ) {
                                    let isMatch = true;
                                    switch (field['condition']) {
                                      case '===':
                                        isMatch =
                                          values[field['dependField']] ===
                                          field['dependValue'];
                                        break;

                                      case '!==':
                                        isMatch =
                                          values[field['dependField']] !==
                                          field['dependValue'];
                                        break;

                                      case 'includes':
                                        isMatch = field['dependValue'].includes(
                                          values[field['dependField']],
                                        );
                                        break;

                                      default:
                                        break;
                                    }
                                    if (!isMatch) {
                                      if (
                                        values[field['name']] !==
                                        field['default']
                                      ) {
                                        setFieldValue(
                                          field['name'],
                                          field['default'],
                                        );
                                      }
                                      return <></>;
                                    }
                                  }

                                  return (
                                    <React.Fragment key={field['name'] + key}>
                                      {(
                                        <Col

                                          md={field['colSize']}
                                          sm={12}
                                        >
                                          <FormGroup className="mb-3">
                                            <>
                                              {['richtext'].includes(
                                                field['type'],
                                              ) && (
                                                  <div className="accordion-item">
                                                    <h2
                                                      className="accordion-header"
                                                      id={'heading-' + field['name']}
                                                    >
                                                      <button
                                                        className="accordion-button text-capitalize"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={
                                                          '#collapse-' + field['name']
                                                        }
                                                        aria-expanded="true"
                                                        aria-controls="collapseOne"
                                                        onClick={(e: any) => {
                                                          let bsTarget =
                                                            e.target?.dataset
                                                              ?.bsTarget || '';

                                                          let selectorTarget =
                                                            document.querySelector(
                                                              bsTarget,
                                                            );

                                                          if (
                                                            selectorTarget.classList.contains(
                                                              'show',
                                                            )
                                                          ) {
                                                            selectorTarget.classList.remove(
                                                              'show',
                                                            );
                                                          } else {
                                                            selectorTarget.className +=
                                                              ' show';
                                                          }
                                                        }}
                                                      >
                                                        <Label
                                                          className={
                                                            field['isRequired'] && showForm
                                                              ? 'form-label me-2 required-field text-capitalize'
                                                              : 'form-label me-2 text-capitalize'
                                                          }
                                                        >
                                                          {/* {field['positionInForm']}.&nbsp; */}
                                                          {field['label']}
                                                        </Label>
                                                      </button>
                                                    </h2>
                                                    <div
                                                      id={'collapse-' + field['name']}
                                                      className="accordion-collapse collapse "
                                                      aria-labelledby={
                                                        'heading-' + field['name']
                                                      }
                                                      data-bs-parent="#accordionExample"
                                                    >
                                                      <div className="accordion-body">
                                                        <Row>
                                                          <Col>
                                                            {!showForm && (
                                                              <>
                                                                <Label
                                                                  className={
                                                                    field['isRequired'] && showForm
                                                                      ? 'form-label me-2 required-field text-capitalize'
                                                                      : 'form-label me-2 text-capitalize'
                                                                  }
                                                                >
                                                                  {field['label']}
                                                                </Label>
                                                                <div className="p-2 m-0 border rounded" dangerouslySetInnerHTML={{ __html: values[field['name']] }}>
                                                                </div>
                                                              </>

                                                            )}
                                                            {showForm && (
                                                              <DaynamicInputField
                                                                label={field['label']}
                                                                type={field['type']}
                                                                placeholder={
                                                                  field['label']
                                                                }
                                                                required={
                                                                  field['isRequired']
                                                                }
                                                                invalid={{
                                                                  errors,
                                                                  touched,
                                                                }}
                                                                setData={(
                                                                  data: any,
                                                                ) => {
                                                                  setFieldValue(
                                                                    field['name'],
                                                                    data,
                                                                  );
                                                                }}
                                                                {...getFieldProps(
                                                                  field['name'],
                                                                )}
                                                              />
                                                            )}
                                                          </Col>
                                                          {id && (
                                                            <>
                                                              <Col sm={4}>
                                                                <Label
                                                                  className={
                                                                    'form-label me-2 text-capitalize'
                                                                  }
                                                                >
                                                                  Comments for {field['label']}
                                                                </Label>
                                                                <div className="border rounded">
                                                                  <div
                                                                    className="chat-conversation p-3 px-2"
                                                                    style={{
                                                                      height: '425px',
                                                                      overflow:
                                                                        'auto',
                                                                    }}
                                                                  >
                                                                    <ul className="list-unstyled">
                                                                      {/* <li>
                                                                  <div className="chat-day-title">
                                                                    <span className="title">
                                                                      Comments
                                                                    </span>
                                                                  </div>
                                                                </li> */}
                                                                      {messages &&
                                                                        messages.map(
                                                                          (
                                                                            message: any,
                                                                          ) => {
                                                                            console.log(message?.registration_id, currentUser?.registration_no)
                                                                            if (
                                                                              message?.column_name ===
                                                                              field[
                                                                              'name'
                                                                              ]
                                                                            )
                                                                              return (
                                                                                <li
                                                                                  key={
                                                                                    'test_k' +
                                                                                    message.id
                                                                                  }
                                                                                  className={
                                                                                    message?.registration_id ===
                                                                                      currentUser?.registration_no
                                                                                      ? 'right'
                                                                                      : ''
                                                                                  }
                                                                                >
                                                                                  <div className="conversation-list">
                                                                                    <div className="ctext-wrap">
                                                                                      <div className="ctext-wrap-content">
                                                                                        {
                                                                                          message?.registration_id ===
                                                                                            currentUser?.registration_no
                                                                                            ? (<h5 className="conversation-name">
                                                                                              <span className="time me-0">
                                                                                                {moment(
                                                                                                  message.updated_at,
                                                                                                ).calendar()}
                                                                                              </span>
                                                                                            </h5>)
                                                                                            : (<h5 className="conversation-name">
                                                                                              <div className="user-name">
                                                                                                {
                                                                                                  message.name
                                                                                                }
                                                                                              </div>
                                                                                              <span className="time">
                                                                                                {moment(
                                                                                                  message.updated_at,
                                                                                                ).calendar()}
                                                                                              </span>
                                                                                            </h5>)
                                                                                        }

                                                                                        <p className="mb-0" dangerouslySetInnerHTML={{ __html: message.comments }}>
                                                                                        </p>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </li>
                                                                              );
                                                                          },
                                                                        )}
                                                                    </ul>
                                                                  </div>
                                                                  <div className="p-3 border-top">
                                                                    <Row>
                                                                      <Col>
                                                                        <div className="position-relative">
                                                                          <input
                                                                            type="textare"
                                                                            name={
                                                                              'comment'
                                                                            }
                                                                            value={
                                                                              comment[
                                                                              field[
                                                                              'name'
                                                                              ]
                                                                              ] || ''
                                                                            }
                                                                            onChange={(
                                                                              e: any,
                                                                              key: any = field[
                                                                                'name'
                                                                              ],
                                                                            ) => {
                                                                              let message =
                                                                                e
                                                                                  .target
                                                                                  .value ||
                                                                                '';

                                                                              let newComment =
                                                                              {
                                                                                ...comment,
                                                                                [key]:
                                                                                  message,
                                                                              };


                                                                              setComment(
                                                                                newComment,
                                                                              );
                                                                            }}
                                                                            className="form-control border bg-soft-light"
                                                                            placeholder={
                                                                              'Enter comment for ' +
                                                                              field[
                                                                              'label'
                                                                              ]
                                                                            }
                                                                          />
                                                                        </div>
                                                                      </Col>
                                                                      <Col className="col-auto">
                                                                        <Button
                                                                          type="button"
                                                                          color="primary"
                                                                          onClick={() => {
                                                                            if (
                                                                              comment[
                                                                              field[
                                                                              'name'
                                                                              ]
                                                                              ]
                                                                            ) {


                                                                              let postCommentData =
                                                                              {
                                                                                queryUrl:
                                                                                  formObj[
                                                                                  'createCommentAPIUrl'
                                                                                  ],
                                                                                formData:
                                                                                {
                                                                                  protocol_id:
                                                                                    id,
                                                                                  comments:
                                                                                    comment[
                                                                                    field[
                                                                                    'name'
                                                                                    ]
                                                                                    ] ||
                                                                                    '',
                                                                                  column_name:
                                                                                    field[
                                                                                    'name'
                                                                                    ],
                                                                                },
                                                                              };

                                                                              createByPostComment(
                                                                                postCommentData,
                                                                              ).then(
                                                                                (
                                                                                  res: any,
                                                                                ) => {
                                                                                  if (
                                                                                    res
                                                                                      .data
                                                                                      ?.success
                                                                                  ) {
                                                                                    comment[
                                                                                      field[
                                                                                      'name'
                                                                                      ]
                                                                                    ];

                                                                                    let newComment =
                                                                                    {
                                                                                      ...comment,
                                                                                      [field[
                                                                                        'name'
                                                                                      ]]:
                                                                                        "",
                                                                                    };

                                                                                    setComment(
                                                                                      newComment,
                                                                                    );

                                                                                  }
                                                                                },
                                                                              );
                                                                            }
                                                                          }}
                                                                          className="btn btn-success chat-send w-md"
                                                                          disabled={
                                                                            isCommentLoading
                                                                          }
                                                                        >
                                                                          <span className="d-none d-sm-inline-block me-2">
                                                                            {isCommentLoading
                                                                              ? 'Sending...'
                                                                              : 'Send'}
                                                                          </span>{' '}
                                                                          <i className="mdi mdi-send float-end" />
                                                                        </Button>
                                                                      </Col>
                                                                    </Row>
                                                                  </div>
                                                                </div>
                                                              </Col>
                                                            </>
                                                          )}
                                                        </Row>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}

                                              {showForm && (<>
                                                {['modal'].includes(
                                                  field['type'],
                                                ) && (
                                                    <DaynamicInputField
                                                      label={field['label']}
                                                      name={field['name']}
                                                      type={field['type']}
                                                      placeholder={field['label']}
                                                      options={field['data']}
                                                      dataApi={field['dataApi']}
                                                      value={
                                                        values[field['name']] !==
                                                          undefined
                                                          ? values[field['name']]
                                                          : field['default']
                                                      }
                                                      isMulti={field['isMulti']}
                                                      required={field['isRequired']}
                                                      invalid={{ errors, touched }}
                                                      onChange={(
                                                        data: any,
                                                        isSelected: boolean = false,
                                                      ) => {
                                                        if (data === null) {
                                                          setFieldValue(
                                                            field['name'],
                                                            '',
                                                          );
                                                          field['data'] = [];
                                                        } else if (
                                                          data &&
                                                          typeof data === 'string'
                                                        ) {
                                                          let isSelected =
                                                            values[
                                                              field['name']
                                                            ].includes(data);

                                                          if (isSelected) {
                                                            setFieldValue(
                                                              field['name'],
                                                              '',
                                                            );
                                                            field['data'] = [];
                                                          } else {
                                                            setFieldValue(
                                                              field['name'],
                                                              data,
                                                            );
                                                            field['data'] = [data];
                                                          }
                                                        } else if (
                                                          data &&
                                                          typeof data === 'object' &&
                                                          data?.length === undefined
                                                        ) {
                                                          let isSelected =
                                                            values[field['name']] ===
                                                            data?.value;

                                                          if (isSelected) {
                                                            setFieldValue(
                                                              field['name'],
                                                              '',
                                                            );
                                                            field['data'] = [];
                                                          } else {
                                                            setFieldValue(
                                                              field['name'],
                                                              data?.value || '',
                                                            );
                                                            field['data'] = data;
                                                          }
                                                        } else if (
                                                          data &&
                                                          typeof data === 'object' &&
                                                          data?.length > 0
                                                        ) {
                                                          if (!isSelected) {
                                                            let filterData = field[
                                                              'data'
                                                            ].filter((item: any) => {
                                                              return (
                                                                item.value !==
                                                                data?.[0].value
                                                              );
                                                            });

                                                            setFieldValue(
                                                              field['name'],
                                                              filterData.map(
                                                                (item: any) =>
                                                                  item.value,
                                                              ),
                                                            );
                                                            field['data'] = filterData;
                                                          } else {
                                                            setFieldValue(
                                                              field['name'],
                                                              data.map(
                                                                (item: any) =>
                                                                  item.value,
                                                              ),
                                                            );
                                                            field['data'] = data;
                                                          }
                                                        }
                                                      }}
                                                    />
                                                  )}

                                                {['select'].includes(
                                                  field['type'],
                                                ) && (
                                                    <DaynamicInputField
                                                      label={field['label']}
                                                      name={field['name']}
                                                      type={field['type']}
                                                      placeholder={field['label']}
                                                      options={field['data']}
                                                      dataApi={field['dataApi']}
                                                      value={
                                                        values[field['name']] !==
                                                          undefined
                                                          ? values[field['name']]
                                                          : field['default']
                                                      }
                                                      isMulti={field['isMulti']}
                                                      required={field['isRequired']}
                                                      invalid={{ errors, touched }}
                                                      onChange={(data: any) => {
                                                        if (data === null) {
                                                          setFieldValue(
                                                            field['name'],
                                                            '',
                                                          );
                                                        } else if (
                                                          data &&
                                                          data?.length === undefined &&
                                                          data.value !== undefined
                                                        ) {
                                                          setFieldValue(
                                                            field['name'],
                                                            data.value,
                                                          );
                                                        } else if (
                                                          data &&
                                                          data?.length !== undefined
                                                        ) {
                                                          setFieldValue(
                                                            field['name'],
                                                            data.map(
                                                              (item: any) => item.value,
                                                            ),
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  )}

                                                {['date'].includes(field['type']) && (
                                                  <>
                                                    <DaynamicInputField
                                                      label={field['label']}
                                                      name={field['name']}
                                                      type={field['type']}
                                                      placeholder={field['label']}
                                                      options={field['options']}
                                                      invalid={{ errors, touched }}
                                                      value={
                                                        values[field['name']] !==
                                                          undefined
                                                          ? values[field['name']]
                                                          : field?.options
                                                            ?.defaultDate
                                                      }
                                                      required={field['isRequired']}
                                                      setData={(data: any) => {
                                                        if (
                                                          field?.options?.mode ===
                                                          'range'
                                                        ) {
                                                          setFieldValue(
                                                            field['name'],
                                                            data[0],
                                                          );
                                                          setFieldValue(
                                                            field['name2'],
                                                            data[1],
                                                          );
                                                        } else {
                                                          setFieldValue(
                                                            field['name'],
                                                            data.join(',').trim(),
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  </>
                                                )}

                                                {['radio', 'checkbox'].includes(
                                                  field['type'],
                                                ) && (
                                                    <DaynamicInputField
                                                      label={field['label']}
                                                      name={field['name']}
                                                      type={field['type']}
                                                      placeholder={field['label']}
                                                      options={field['data']}
                                                      dataApi={field['dataApi']}
                                                      value={
                                                        values[field['name']] !==
                                                          undefined
                                                          ? values[field['name']]
                                                          : field['default']
                                                      }
                                                      isMulti={field['isMulti']}
                                                      required={field['isRequired']}
                                                      invalid={{ errors, touched }}
                                                      onChange={(data: any) => {
                                                        let selectedValue =
                                                          data.target.value.toString();
                                                        if (
                                                          field['type'] === 'checkbox'
                                                        ) {
                                                          let isChecked = data.target.checked;
                                                          let oldValue = values[field['name']];

                                                          let getIndex = oldValue.indexOf(selectedValue,);
                                                          if (isChecked && getIndex === -1) {
                                                            oldValue.push(selectedValue,);
                                                          } else if (!isChecked && getIndex > -1) {
                                                            oldValue.splice(getIndex, 1,);
                                                          }

                                                          setFieldValue(field['name'], oldValue,);
                                                        } else {
                                                          setFieldValue(field['name'], selectedValue,);
                                                        }
                                                      }}
                                                    />
                                                  )}

                                                {['file', 'image'].includes(field['type']) && (
                                                  <>
                                                    <DaynamicInputField
                                                      label={field['label']}
                                                      type={field['type']}
                                                      name={field['name']}
                                                      placeholder={field['label']}
                                                      required={field['isRequired']}
                                                      invalid={{ errors, touched }}
                                                      setData={(data: any) => {
                                                        console.log(data)
                                                        setFieldValue(
                                                          field['name'],
                                                          data,
                                                        );
                                                      }}
                                                    />
                                                  </>
                                                )}

                                                {![
                                                  'file',
                                                  'image',
                                                  'modal',
                                                  'select',
                                                  'radio',
                                                  'richtext',
                                                  'checkbox',
                                                  'date',
                                                ].includes(field['type']) && (
                                                    <>
                                                      <DaynamicInputField
                                                        label={field['label']}
                                                        type={field['type']}
                                                        placeholder={field['label']}
                                                        required={field['isRequired']}
                                                        invalid={{ errors, touched }}
                                                        {...getFieldProps(field['name'])}
                                                      />
                                                    </>
                                                  )}
                                              </>)}

                                              {/* {!showForm && ![
                                            'richtext',
                                          ].includes(field['type']) && (
                                              <>
                                                <Label> {field['label']}</Label>
                                                <div className="mb-0" dangerouslySetInnerHTML={{ __html: values[field['name']] }}>
                                                </div>
                                              </>
                                            )} */}
                                            </>
                                          </FormGroup>
                                        </Col>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              })}
                          </Row>
                          {showForm && (<>
                            <Row>
                              <Col>
                                <Button
                                  type="button"
                                  className="btn btn-info my-2"
                                  disabled={isLoading}
                                  onClick={(e) => {
                                    let attachments = values["attachments"];
                                    attachments.push({
                                      "type": "",
                                      "add": 1,
                                    });
                                    //let itemKey = attachments.length;

                                    //validationObj['file' + itemKey] = Yup.string().required('Files is required');
                                    //validationObj['type' + itemKey] = Yup.string().required('File type is required');

                                    setFieldValue("attachments", attachments)
                                  }}
                                >
                                  Add Attachments
                                </Button>
                              </Col>

                              {Object.keys(values["attachments"]).map((itemKey: any) => {

                                if (values["attachments"][itemKey]?.add === 0) {
                                  return "";
                                }

                                return (
                                  <Row key={"attachments-" + itemKey}>
                                    <Col >
                                      <div className="input-group mb-3">
                                        <DaynamicInputField
                                          label={""}
                                          name={'file' + itemKey}
                                          type={'file'}
                                          placeholder={""}
                                          options={"image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
                                          required={true}
                                          invalid={{ errors, touched }}
                                          setData={(data: any) => {
                                            let attachments = values["attachments"];
                                            const selectedPicture = data;
                                            const fileName = "file_" + itemKey;
                                            attachments[itemKey]["fileIndex"] = fileName;
                                            // attachments[itemKey]["file"] = selectedPicture;
                                            // attachments[itemKey] = selectedPicture;
                                            setFieldValue(fileName, selectedPicture)
                                            setFieldValue("attachments", attachments)
                                          }}
                                        />
                                        <DaynamicInputField
                                          className='form'
                                          label={""}
                                          name={"type" + itemKey}
                                          type={"select"}
                                          placeholder={"File type"}
                                          dataApi={{
                                            url: `/setup/get-data/file_upload_document_for`,
                                            key: 'name',
                                            label: 'name',
                                            label2: '',
                                            listFn: (row: any) => {
                                              return row?.[0]?.childs || []
                                            }
                                          }}
                                          value={
                                            values["attachments"][itemKey]["type"] !==
                                              undefined
                                              ? values["attachments"][itemKey]["type"]
                                              : ""
                                          }
                                          isMulti={false}
                                          required={true}
                                          invalid={{ errors, touched }}
                                          onChange={(data: any) => {
                                            let attachments = values["attachments"];

                                            if (data === null) {
                                              attachments[itemKey]["type"] = '';
                                            } else if (
                                              data &&
                                              data?.length === undefined &&
                                              data.value !== undefined
                                            ) {
                                              attachments[itemKey]["type"] = data.value;
                                            } else if (
                                              data &&
                                              data?.length !== undefined
                                            ) {
                                              attachments[itemKey]["type"] = data.map(
                                                (item: any) => item.value,
                                              );
                                            }

                                            setFieldValue("attachments", attachments)

                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                )
                              })}


                              <div className='row d-flex'>
                                {viewData?.protocol_attachments?.length > 0 && viewData?.protocol_attachments.map((fileItem: any) => {
                                  return (<React.Fragment key={fileItem?.id} >
                                    {fileItem?.document_type && fileItem?.document_name && (
                                      <div className="col col-xs-12 col-sm-4 col-lg-3">
                                        <div className="btn-group w-100  m-2 fade show" role="alert">
                                          <button
                                            className="waves-light waves-effect btn btn-danger "
                                            style={{ "width": "100px", "flex": "unset" }}
                                            type="button"
                                            onClick={(e) => {
                                              let attachments = values["attachments"];
                                              let isDeleted = attachments.findIndex((item: any) => fileItem?.id === item?.deletable_id)
                                              if (isDeleted === -1) {
                                                attachments.push({
                                                  "type": fileItem?.document_type,
                                                  "deletable_id": fileItem?.id,
                                                  "fileIndex": "",
                                                  "add": 0,
                                                });
                                              } else {
                                                attachments.splice(isDeleted, 1);
                                              }

                                              setFieldValue("attachments", attachments)
                                            }}

                                          >
                                            {values["attachments"].findIndex((item: any) => fileItem?.id === item?.deletable_id) === -1 && (
                                              <i className="mdi mdi-checkbox-blank-outline me-2"></i>
                                            )}

                                            {values["attachments"].findIndex((item: any) => fileItem?.id === item?.deletable_id) !== -1 && (
                                              <i className="mdi mdi-checkbox-marked me-2"></i>
                                            )}


                                            <i className="far fa-trash-alt"></i>
                                          </button>
                                          <a
                                            href={config.APP_URL + fileItem?.document_name}
                                            rel="noreferrer"
                                            target="_blank"
                                            className="waves-light waves-effect btn btn-outline-primary   "
                                            style={{ "width": "100%", "flex": "unset" }}
                                          >
                                            {fileItem?.document_type}
                                          </a>
                                        </div>
                                      </div>
                                    )}
                                  </React.Fragment>)
                                })}
                              </div>

                            </Row>
                          </>)}



                          {showForm && (
                            <Row>
                              <Col sm={12}>
                                <ButtonGroup className="my-3 text-left">
                                  <Button
                                    type="submit"
                                    className="btn btn-success"
                                    onClick={(e) => {
                                      setBtnClicked('submit');
                                    }}
                                    disabled={isLoading}
                                  >
                                    {id === ''
                                      ? formObj.submitBtnTitle
                                        ? formObj.submitBtnTitle
                                        : 'Submit'
                                      : formObj.updateBtnTitle
                                        ? formObj.updateBtnTitle
                                        : 'Update'}
                                  </Button>

                                  {formObj.draftBtnTitle && (
                                    <Button
                                      type="submit"
                                      className="btn btn-info"
                                      disabled={isLoading}
                                      onClick={(e) => {
                                        setBtnClicked('save_as_draft');
                                      }}
                                    >
                                      {formObj.draftBtnTitle}
                                    </Button>
                                  )}

                                  {formObj.resetBtnTitle && (
                                    <Button
                                      type="reset"
                                      className="btn btn-danger me-2"
                                      disabled={isLoading}
                                    >
                                      {formObj.resetBtnTitle}
                                    </Button>
                                  )}
                                </ButtonGroup>
                              </Col>
                            </Row>
                          )}
                          {formObj.debug && (
                            <Row>
                              <Col sm={12}><pre>{JSON.stringify(values, null, 2)}</pre></Col>
                            </Row>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              )}

              {/* {showForm && viewData && (
                <>
                  <DynamicView
                    viewColumn={viewColumn}
                    viewData={viewData}
                  // apiUrl={formObj.listAPIUrl}
                  />
                  {formObj.addUrl &&
                    viewData?.['submission_status'] &&
                    viewData?.['submission_status'].toLowerCase() ===
                    'submit' && (
                      <div className="mb-5">
                        <Button
                          type="button"
                          className="btn btn-success"
                          onClick={(e) => {
                            setShowForm(true);
                          }}
                        >
                          {formObj.editBtnTitle ? formObj.editBtnTitle : 'Edit'}
                        </Button>
                      </div>
                    )}
                </>
              )} */}
            </Col>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  );
};

export default ProtocolEntry;
