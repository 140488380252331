import Dashboard from "src/pages/Dashboard";
import AccountantDashboard from "src/pages/Dashboard/Accountant/AccountantDashboard";
import SupervisorDashboard from "src/pages/Dashboard/Supervisor/SupervisorDashboard";
import ThesisExamDashboard from "src/pages/Dashboard/ThesisExam/ThesisExamDashboard";
import TraineeDashboard from "src/pages/Dashboard/Trainee/TraineeDashboard";

export const dashboardRoutes = [
  // dashboard
  { path: '/dashboard', component: Dashboard },
  { path: '/accountant/dashboard', component: AccountantDashboard },
  { path: '/trainee/dashboard', component: TraineeDashboard }, 
  { path: '/supervisor/dashboard', component: SupervisorDashboard },
  { path: '/thesis-exam/dashboard', component: ThesisExamDashboard }, 
];
