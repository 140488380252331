import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Card, CardHeader, CardBody, Table } from 'reactstrap';
import { Button } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useDeleteNoticeTemplateAttachmentMutation, useGetNoticeTemplateAttachmentsQuery } from 'src/rtk/features/setup/noticeTemplateAttachment/setupNoticeTemplateAttachmentApi';
import AddNoticeTemplateCopyAttachment from './AddNoticeTemplateCopyAttachment';
import EditNoticeTemplateCopyAttachment from './EditNoticeTemplateCopyAttachment';

const NoticeAttachmentList = () => {
  const { id: noticeTemplateId } = useParams<{ id: string }>();  

  const [deleteFlag, setDeleteFlag] = useState<boolean>(false);
  const [showAddForm, setShowAddForm] = useState<boolean>(true);
  const [noticeAttachmentId, setNoticeAttachmentId] = useState<string>('');
  const { data: noticeAttachments, error, isLoading, isError } = useGetNoticeTemplateAttachmentsQuery();

  const noticeAttachmentList = noticeAttachments?.data.filter((n: NoticeTemplateCopyAttachment) => n.notice_template_list_id === noticeTemplateId);

  const [deleteNoticeTemplateAttachment, { isSuccess: attachmentDeleteIsSuccess, isLoading: attachmentDeleteIsLoading, isError: attachmentDeleteIsError }] =
    useDeleteNoticeTemplateAttachmentMutation();

  const editHandler = (id: string) => {
    setDeleteFlag(false);
    setShowAddForm(false);
    setNoticeAttachmentId(id);
  };

  const deleteHandler = (id: string) => {    
    const confirm = window.confirm('Are you sure you want to delete?');
    if (confirm) {   
      if(noticeAttachmentList?.length! > 1) {
        setDeleteFlag(true);
      } else {
        setShowAddForm(true);
        setDeleteFlag(false);
      }
         
      deleteNoticeTemplateAttachment(id);
    }
  };
  
  return (  
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          breadcrumbItem={[{ link: '/setup/notice-template-list', name: 'Notice Template List' }]}
          title={'Notice Attachment'}
        />        
        <Card>          
          <CardBody>

            {
              showAddForm && !deleteFlag ? (
                <AddNoticeTemplateCopyAttachment noticeTemplateId={noticeTemplateId!} noticeAttachmentList={noticeAttachmentList!} />
              ) : (
                !deleteFlag ? (
                  <EditNoticeTemplateCopyAttachment noticeAttachmentId={noticeAttachmentId!} />
                ) : null                
              )
            }

            {
              noticeAttachmentList?.length ? (
              <Card className='mt-5 mb-5'>
                <CardHeader className="d-flex justify-content-start">
                  <strong>Notice Attachment List</strong>
                </CardHeader>
                <CardBody>
                <div className="table-responsive">
                  <Table className="table mb-5">
                    <thead>
                      <tr>
                        <th>Sl. </th>
                        <th>Attachment Type</th>
                        <th>Attachment Details</th>
                        <th>Attachment Status</th> 
                        <th>Action</th>                   
                      </tr>
                    </thead>
                    <tbody>
                        {
                            noticeAttachmentList?.map((n: NoticeTemplateCopyAttachment, index: number) => {
                                return (
                                    <tr key={n.id}>
                                        <td>{index + 1}</td>
                                        <td>{n.attachment_type}</td>
                                        <td>{n.details}</td>
                                        <td>{n.status ? 'Active' : 'Inactive'}</td>
                                        <td>
                                          <div className="d-flex gap-2">
                                            <Button
                                              size="sm"
                                              text="Edit"
                                              color="warning"
                                              onClick={() =>
                                                editHandler(n.id!)
                                              }
                                            />

                                            <Button
                                              size="sm"
                                              text="Delete"
                                              color="danger"                                              
                                              onClick={() => 
                                                deleteHandler(n.id!)
                                              }
                                            />
                                          </div>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                  </Table>
                </div>
                </CardBody>
              </Card>
              ) : null
            }
            
          </CardBody>
        </Card>
        </Container>
      </div>
   
  );
};

export default NoticeAttachmentList;
