import moment from 'moment';
import { useState } from 'react';
import { Button as BsButton, Col, FormGroup, Row } from 'reactstrap';
import CommentBtn from './CommentBtn';

const StepOne = (props: any) => {

  const { goToNext, protocolEntry, titleEntry } = props;
  const [showCommentSectionModal, setShowCommentSectionModal] = useState<boolean>(false);
  const [commentsFieldName, setCommentsFieldName] = useState<string>('');

  return (
    <>
      <div className='col-lg-12 col-sm-12'>
        {protocolEntry?.id ?
          <table className='table table-sm table-bordered'>
            <thead className='bg-light fixed'>
              <tr className='text-center text-dark font-size-16 fw-bold'>
                <td colSpan={3}>Dissertation/Thesis Protocol</td>
              </tr>
            </thead>

            <tbody className='text-capitalize text-dark font-size-14'>
              <tr>
                <td className='bg-light fw-bold fs-6'>Protocol No</td>
                <td colSpan={2} className='text-center'>{protocolEntry?.protocol_no ?? ''}</td>
              </tr>
              <tr>
                <td>1</td>
                <td className='fw-bold' width={'200'}>Date of submission of protocol</td>
                <td
                  className=''>{moment(protocolEntry?.protocol_submission_date).isValid() ? moment(protocolEntry?.protocol_submission_date).format('DD/MMM/YYYY') : ''}</td>
              </tr>
              <tr>
                <td>2</td>
                <td className='fw-bold'>Relevant</td>
                <td>
                  <p><strong>Faculty: </strong> {protocolEntry?.relevant_faculty ?? ''}</p>
                  <p><strong>Discipline: </strong> {protocolEntry?.relevant_discipline ?? ''}</p>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td className='fw-bold'>Name of the researcher</td>
                <td>{titleEntry?.trainee_name ?? ''}</td>
              </tr>
              <tr>
                <td>4</td>
                <td className='fw-bold'>Correspondence of the examinee and contact phone numbers</td>
                <td>
                  <p>
                    <strong>Address: </strong>
                    {titleEntry?.registration?.address_infos[1]?.address_line_1 ? `${titleEntry?.registration?.address_infos[1]?.address_line_1}, ` : ``}
                    {titleEntry?.registration?.address_infos[1]?.police_station ? `${titleEntry?.registration?.address_infos[1]?.police_station}, ` : ``}
                    {titleEntry?.registration?.address_infos[1]?.district ? `${titleEntry?.registration?.address_infos[1]?.district}` : ``}.
                  </p>
                  <p><strong>Email Address: </strong> {titleEntry?.registration?.personal_info?.email ?? ''}</p>
                  <p><strong>Phone number: </strong> {titleEntry?.registration?.personal_info?.mobile ?? ''}</p>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td className='fw-bold'>Title of the dissertation/thesis</td>
                <td>{titleEntry?.title_of_thesis ?? ''}</td>
              </tr>
              <tr>
                <td>6</td>
                <td className='fw-bold'>Summary</td>
                <td>
                  <Row className='justify-content-between align-items-center mb-2'>
                    <Col sm={'10'}>
                      <span dangerouslySetInnerHTML={{ __html: protocolEntry?.summary }}></span>
                    </Col>
                    <CommentBtn
                      protocolEntry={protocolEntry}
                      showCommentSectionModal={showCommentSectionModal}
                      setShowCommentSectionModal={setShowCommentSectionModal}
                      commentsFieldName={commentsFieldName}
                      setCommentsFieldName={setCommentsFieldName}
                      commentsFieldNameToCompare={'summary'}
                    />
                  </Row>
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td className='fw-bold'>Place of study</td>
                <td>{titleEntry?.place_of_study ?? ''}</td>
              </tr>
              <tr>
                <td>8</td>
                <td className='fw-bold'>Study period</td>
                <td>{titleEntry?.study_period ?? ''} {titleEntry?.study_period_unit ?? ''}</td>
              </tr>
              <tr>
                <td>9</td>
                <td className='fw-bold'>Study Design</td>
                <td>
                  <Row className='justify-content-between align-items-center mb-2'>
                    <Col sm={'10'}>
                      <span dangerouslySetInnerHTML={{ __html: protocolEntry?.study_design }}></span>
                    </Col>
                    <CommentBtn
                      protocolEntry={protocolEntry}
                      showCommentSectionModal={showCommentSectionModal}
                      setShowCommentSectionModal={setShowCommentSectionModal}
                      commentsFieldName={commentsFieldName}
                      setCommentsFieldName={setCommentsFieldName}
                      commentsFieldNameToCompare={'study_design'}
                    />
                  </Row>
                </td>
              </tr>
              <tr>
                <td>10</td>
                <td className='fw-bold'>Introduction/context</td>
                <td>
                  <Row className='justify-content-between align-items-center mb-2'>
                    <Col sm={'10'}>
                      <span dangerouslySetInnerHTML={{ __html: protocolEntry?.introduction_context }}></span>
                    </Col>
                    <CommentBtn
                      protocolEntry={protocolEntry}
                      showCommentSectionModal={showCommentSectionModal}
                      setShowCommentSectionModal={setShowCommentSectionModal}
                      commentsFieldName={commentsFieldName}
                      setCommentsFieldName={setCommentsFieldName}
                      commentsFieldNameToCompare={'introduction_context'}
                    />
                  </Row>
                </td>
              </tr>
              <tr>
                <td>11</td>
                <td className='fw-bold'>Rationale of the research</td>
                <td>
                  <Row className='justify-content-between align-items-center mb-2'>
                    <Col sm={'10'}>
                      <span dangerouslySetInnerHTML={{ __html: protocolEntry?.rationale_of_the_research }}></span>
                    </Col>
                    <CommentBtn
                      protocolEntry={protocolEntry}
                      showCommentSectionModal={showCommentSectionModal}
                      setShowCommentSectionModal={setShowCommentSectionModal}
                      commentsFieldName={commentsFieldName}
                      setCommentsFieldName={setCommentsFieldName}
                      commentsFieldNameToCompare={'rationale_of_the_research'}
                    />
                  </Row>
                </td>
              </tr>
              <tr>
                <td>12</td>
                <td className='fw-bold'>Research question/hypothesis</td>
                <td>
                  <Row className='justify-content-between align-items-center mb-2'>
                    <Col sm={'10'}>
                      <span dangerouslySetInnerHTML={{ __html: protocolEntry?.research_question_hypothesis ?? '' }}></span>
                    </Col>
                    <CommentBtn
                      protocolEntry={protocolEntry}
                      showCommentSectionModal={showCommentSectionModal}
                      setShowCommentSectionModal={setShowCommentSectionModal}
                      commentsFieldName={commentsFieldName}
                      setCommentsFieldName={setCommentsFieldName}
                      commentsFieldNameToCompare={'research_question_hypothesis'}
                    />
                  </Row>
                </td>
              </tr>
              <tr>
                <td rowSpan={2}>13</td>
                <td rowSpan={2} className='fw-bold'>Objectives</td>
                <td>
                  <Row className='justify-content-between align-items-center mb-2'>
                    <Col sm={'10'}>
                      <p className='fw-bold'>{'a) General Objectives'}</p>
                      <span dangerouslySetInnerHTML={{ __html: protocolEntry?.objectives ? JSON.parse(protocolEntry?.objectives)[0]?.value?.value : '' }}></span>
                    </Col>
                    <CommentBtn
                      protocolEntry={protocolEntry}
                      showCommentSectionModal={showCommentSectionModal}
                      setShowCommentSectionModal={setShowCommentSectionModal}
                      commentsFieldName={commentsFieldName}
                      setCommentsFieldName={setCommentsFieldName}
                      commentsFieldNameToCompare={'objectives'}
                    />
                  </Row>
                </td>
              </tr>
              <tr>
                <td>
                  <Row className='justify-content-between align-items-center mb-2'>
                    <Col sm={'10'}>
                      <p className='fw-bold'>{'b) Specific Objectives'}</p>
                      <span dangerouslySetInnerHTML={{ __html: protocolEntry?.objectives ? JSON.parse(protocolEntry?.objectives)[1]?.value?.value : '' }}></span>
                    </Col>
                    <CommentBtn
                      protocolEntry={protocolEntry}
                      showCommentSectionModal={showCommentSectionModal}
                      setShowCommentSectionModal={setShowCommentSectionModal}
                      commentsFieldName={commentsFieldName}
                      setCommentsFieldName={setCommentsFieldName}
                      commentsFieldNameToCompare={'objectives'}
                    />
                  </Row>
                </td>
              </tr>
            </tbody>
          </table>
          :
          null}
      </div>

      <FormGroup className='mt-5'>
        <div className='d-flex justify-content-end'>
          <BsButton color='success' onClick={() => {
            goToNext();
          }}>
            <i className='fas fa-2x fa-arrow-right-long'></i>
          </BsButton>
        </div>
      </FormGroup>
    </>
  );
};

export default StepOne;
