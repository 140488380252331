import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Card, CardBody, CardHeader, Col, FormGroup, Label } from 'reactstrap';
import { InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import { useCreateBankInfoEntryForGuideCoGuideMutation, useGetBankInformationForGuideCoGuideQuery } from 'src/rtk/features/guide/guideCoguideApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

const initialValues = {
    bank_name: '',
    branch_name: '',
    routing_no: '',
    account_no: '',
    submission_status: '',
};

const GuideCoGuideBankInfoEntry = () => {

    const { data: bankInfo, isSuccess: isBankInfoSuccess, isLoading: isBankInfoLoading } = useGetBankInformationForGuideCoGuideQuery();

    const [createBankInfoEntryForGuideCoGuide, { isSuccess, isError, error, isLoading: isCreateLoading }] = useCreateBankInfoEntryForGuideCoGuideMutation();

    const [btnClicked, setBtnClicked] = useState<string>('');

    const err: CustomError = error as CustomError;

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setValues,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({
            // bank_name: Yup.string().required('Bank Name is Required'),
            // branch_name: Yup.string().required('Branch Name is Required'),
            // routing_no: Yup.string().required('Routing No is Required'),
            // account_no: Yup.string().required('Account No is Required'),
        }),

        onSubmit: (values: any) => {

        },
    });

    useEffect(() => {
        if (isSuccess) {
            toast.success('Bank Information Submitted Successfully!');
            setBtnClicked('');
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);

    useEffect(() => {
        if (btnClicked !== '') {
            values.submission_status = btnClicked;
            createBankInfoEntryForGuideCoGuide(values);
        }
    }, [btnClicked]);

    useEffect(() => {
        if (bankInfo?.data !== null) {
            setValues({
                bank_name: bankInfo?.data?.bank_name || '',
                branch_name: bankInfo?.data?.branch_name || '',
                routing_no: bankInfo?.data?.routing_no || '',
                account_no: bankInfo?.data?.account_no || '',
                submission_status: bankInfo?.data?.submission_status || '',
            })
        }
        console.log(bankInfo?.data);
    }, [bankInfo])

    return (
        <Col md={4} lg={4} sm={12}>
            {isCreateLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Card className='mt-5'>
                <CardHeader className='text-center fw-semibold bg-info bg-opacity-75'>
                    Bank Account Information <br />(For Research Grant Purpose)
                </CardHeader>
                <CardBody>
                    <div className='row'>
                        <div className='col-md-4'>
                            <Label for="bank_name" className="form-label">Bank Name</Label>
                        </div>
                        <div className='col-md-8'>
                            <InputField
                                type="text"
                                placeholder="Bank Name"
                                {...getFieldProps('bank_name')}
                                invalid={{ errors, touched }}
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-md-4'>
                            <Label for="branch_name" className="form-label">Branch</Label>
                        </div>
                        <div className='col-md-8'>
                            <InputField
                                type="text"
                                placeholder="Branch"
                                {...getFieldProps('branch_name')}
                                invalid={{ errors, touched }}
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-md-4'>
                            <Label for="account_no" className="form-label">Acount Number</Label>
                        </div>
                        <div className='col-md-8'>
                            <InputField
                                type="text"
                                placeholder="Acount Number"
                                {...getFieldProps('account_no')}
                                invalid={{ errors, touched }}
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-md-4'>
                            <Label for="routing_no" className="form-label">Routing No.</Label>
                        </div>
                        <div className='col-md-8'>
                            <InputField
                                type="text"
                                placeholder="Routing No."
                                {...getFieldProps('routing_no')}
                                invalid={{ errors, touched }}
                            />
                        </div>
                    </div>
                    <FormGroup className='mt-3 text-end'>
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={(e) => {
                                setBtnClicked('Submit');
                            }}
                        >
                            Save
                        </button>
                    </FormGroup>
                </CardBody>
            </Card>
        </Col>
    );
}

export default GuideCoGuideBankInfoEntry;