import { apiSlice } from 'src/rtk/api/apiSlice';
import * as permissionApprovalUrl from 'src/helpers/url_helper';

export const permissionApprovalApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['PermissionAproval'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPermissionsAproval: builder.query<APIResponse<Lookup[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${permissionApprovalUrl.GET_PERMISSIONS_APPROVAL}`,
        }),
        providesTags: ['PermissionAproval'],
      }),

      getPermissionAproval: builder.query<Lookup, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${permissionApprovalUrl.GET_PERMISSION_APPROVAL}/${id}`,
        }),
        providesTags: ['PermissionAproval'],
      }),

      createPermissionAproval: builder.mutation<
        APIResponse<Lookup>,
        Lookup
      >({
        query: (data) => ({
          url: `${permissionApprovalUrl.CREATE_PERMISSION_APPROVAL}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['PermissionAproval'],
      }),

      updatePermissionAproval: builder.mutation<
        APIResponse<Lookup>,
        string
      >({
        query: (url) => ({
          url: `${permissionApprovalUrl.UPDATE_PERMISSION_APPROVAL}/${url}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['PermissionAproval'],
      }),
      deletePermissionAproval: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${permissionApprovalUrl.DELETE_PERMISSION_APPROVAL}/${id}`,
        }),
        invalidatesTags: ['PermissionAproval'],
      }),
    }),
  });

export const {
  useGetPermissionAprovalQuery,
  useGetPermissionsAprovalQuery,
  useCreatePermissionAprovalMutation,
  useUpdatePermissionAprovalMutation,
  useDeletePermissionAprovalMutation,
} = permissionApprovalApi;
