import {
  Button,  
  Form,  
  FormGroup,  
  Input,  
  Label,  
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,  
} from 'reactstrap';

interface Props {
    isOpen: boolean;
    toggle: any;
    description: string;
  }
const DescriptionModal = (props: Props) => {
  const { isOpen, toggle, description } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="md">
      <ModalHeader toggle={toggle}>Instruction</ModalHeader>

        <ModalBody>
          <div>                
              <div className="row justify-content-center">                    
                  <div className="col-md-11">
                      <div className="row justify-content-between">
                          <div>
                            {/* <p className='justified-text'>{description}</p>  */}
                            <span dangerouslySetInnerHTML={{ __html: description }} ></span>
                          </div>
                      </div>
                  </div>                    
              </div>                                        
          </div>
        </ModalBody>

        <ModalFooter>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <Button color="danger" onClick={toggle}>
                Close
              </Button>
            </div>
        </ModalFooter>      
    </Modal>
  );
};

export default DescriptionModal;
