import { useState } from 'react';
import { useParams } from 'react-router-dom';
import InstituteDeptRegistrationStepFive from './InstituteDeptRegistrationStepFive';
import InstituteDeptRegistrationStepFour from './InstituteDeptRegistrationStepFour';
import InstituteDeptRegistrationStepOne from './InstituteDeptRegistrationStepOne';
import InstituteDeptRegistrationStepSix from './InstituteDeptRegistrationStepSix';
import InstituteDeptRegistrationStepThree from './InstituteDeptRegistrationStepThree';
import InstituteDeptRegistrationStepTwo from './InstituteDeptRegistrationStepTwo';
import RecommendationForm from './RecommendationForm';

const InstituteDeptRegistrationFormTwo = () => {

    const { id: teamId, insId: instituteId, insLocation: instituteLocation, date: inspectionDate, chairman: chairmanId } = useParams<{
        id: string;
        insId: string;
        insLocation: string;        
        date: string;
        chairman: string;
    }>();

    const [currentStep, setCurrentStep] = useState<number>(1);
    const [instituteName, setInstituteName] = useState<string>('');    
    const [deptId, setDeptId] = useState<string>('');
    const [formBDeptId, setFormBDeptId] = useState<string>('');

    const goToNext = (departmentId?: string, formBDeptId?: string) => {
        if (currentStep < 7) {
            setCurrentStep((p: number) => p + 1);
        } else {
            setCurrentStep(1);
        }

        if (departmentId) {
            // console.log(departmentId);            
            setDeptId(departmentId);
        }

        if (formBDeptId) {
            // console.log(formBDeptId);
            setFormBDeptId(formBDeptId);
        }
    };

    const goToPrevious = (departmentId?: string, formBDeptId?: string) => {
        if (currentStep > 1) {
            setCurrentStep((p: number) => p - 1);
        } else {
            setCurrentStep(7);
        }
    };

    document.title = 'Training Accreditation Application Form | BCPS';

    return (
        <>
            {currentStep === 1 &&
                <InstituteDeptRegistrationStepOne
                    goToNext={goToNext}
                    instituteName={instituteName}
                    setInstituteName={setInstituteName}
                    instituteLocation={instituteLocation}                             
                    departmentId={deptId}
                    setDepartmentId={setDeptId}
                    formBDeptId={formBDeptId}
                    setFormBDeptId={setFormBDeptId}
                    teamId={teamId}
                    instituteId={instituteId}
                    inspectionDate={inspectionDate}
                />
            }
            {currentStep === 2 &&
                <InstituteDeptRegistrationStepTwo
                    goToNext={goToNext}
                    goToPrevious={goToPrevious}
                    instituteName={instituteName}
                    departmentId={deptId}
                    formBDeptId={formBDeptId}
                />
            }
            {currentStep === 3 &&
                <InstituteDeptRegistrationStepThree
                    goToNext={goToNext}
                    goToPrevious={goToPrevious}
                    instituteName={instituteName}
                    departmentId={deptId}
                    formBDeptId={formBDeptId}
                />}
            {currentStep === 4 &&
                <InstituteDeptRegistrationStepFour
                    goToNext={goToNext}
                    goToPrevious={goToPrevious}
                    instituteName={instituteName}
                    departmentId={deptId}
                    formBDeptId={formBDeptId}
                />
            }
            {currentStep === 5 &&
                <InstituteDeptRegistrationStepFive
                    goToNext={goToNext}
                    goToPrevious={goToPrevious}
                    instituteName={instituteName}
                    departmentId={deptId}
                    formBDeptId={formBDeptId}
                />}
            {currentStep === 6 &&
                <InstituteDeptRegistrationStepSix
                    goToPrevious={goToPrevious}
                    goToNext={goToNext}
                    instituteName={instituteName}
                    departmentId={deptId}
                    formBDeptId={formBDeptId}
                />
            }
            {currentStep === 7 &&
                <RecommendationForm
                    teamId={teamId}
                    instituteId={instituteId}
                    inspectionDate={inspectionDate}
                    chairmanId={chairmanId}
                    formBDeptId={formBDeptId}
                />
            }
        </>
    );
};

export default InstituteDeptRegistrationFormTwo;