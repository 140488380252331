//import Breadcrumbs
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Select from 'react-select';
import { Card, CardBody, Container, Form, FormGroup, Label } from 'reactstrap';
import { Button } from 'src/components';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { useGetAllOrganizationListQuery, useGetOrganizationsQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';

interface SearchFields {
  organization_id?: string;
  organization_type?: string;
}

const initialValues: SearchFields = {
  organization_id: '',
  organization_type: '',
};

const Organizations = () => {

  const [selectedOrganization, setSelectedOrganization] = useState<any>('');
  const [selectedInstituteType, setSelectedInstituteType] = useState<any>('');
  const [page, setPage] = useState<any>(1);
  const [paginate_per_page, set_paginate_per_page] = useState<any>(20);
  const [search, setSearch] = useState('');

  const generateQueryUrl = () => {
    return `page=${page}&paginate_per_page=${paginate_per_page}${search}`;
  };


  const { data, error, isLoading, isError } = useGetAllOrganizationListQuery(generateQueryUrl());
  const { data: dropdownData, error: dropdownDataError, isLoading: dropdownDataLoading, isError: isdropdownDataError } = useGetOrganizationsQuery();

  const err: CustomError = error as CustomError;
  const organizations = data?.data;

  const organizationOptions: SelectOption[] = dropdownData?.data?.map((o: Organization) => {
    return { label: o.organization_name!, value: o.id! };
  })!;

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    set_paginate_per_page(newPerPage);
    setPage(page);
  };

  const columns: TableColumn<any>[] = [
    {
      name: <div>Sl. No.</div>,
      width: '80px',
      cell: (row, index) => (+page - 1) * paginate_per_page + index + 1,
    },

    {
      name: <div>Institute Name</div>,
      width: '350px',
      selector: (row) =>
        row?.organization_name,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Institute Type</div>,
      width: '110px',
      selector: (row) =>
        row?.organization_type,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Address</div>,
      selector: (row) => row?.district + ', ' + row?.division,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Telephone</div>,
      selector: (row) => row?.telephone1,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Email</div>,
      selector: (row) => row?.email,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Status</div>,
      selector: (row) => 'Accreditated',
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Action</div>,
      width: '200px',
      cell: (row) => (
        <>
          <div className="d-flex gap-2">
            <Button
              size="sm"
              text="View Details"
              color="info"
              onClick={() => {

              }}
            />
            <Button
              size="sm"
              text="ITMC"
              color="secondary"
              // disabled={isLoading}
              // isLoading={isLoading}
              onClick={() => {

              }}
            />
          </div>
        </>
      ),
    },
  ];

  const {
    handleSubmit,
    getFieldProps,
    values,
    touched,
    errors,
    resetForm,
    setFieldValue,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.organization_id !== '') {
        s = s + `&organization_id=${values.organization_id}`;
      }

      if (values.organization_type !== '') {
        s = s + `&organization_type=${values.organization_type}`;
      }

      setSearch(s);
      setPage('1');
      set_paginate_per_page(20);
    },
  });



  useEffect(() => {
    if (selectedOrganization !== '') {
      setFieldValue('organization_id', selectedOrganization?.value);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (selectedInstituteType !== '') {
      setFieldValue('organization_type', selectedInstituteType?.value);
    }
  }, [selectedInstituteType]);

  // // create a search handler function with debounce
  // const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
  //     setPage('');
  //     debounce(() => {
  //         setSearch(e.target.value);
  //     }, 300)();
  // };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Institute List" breadcrumbItem="Institute" />
        <Card>

          <CardBody>
            <Form
              className="custom-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="row">
                <div className='col-sm-4'>
                  <FormGroup className="mb-3">
                    <Label for="organization">Institute</Label>
                    <Select
                      isClearable={true}
                      isSearchable={true}
                      value={selectedOrganization}
                      onChange={setSelectedOrganization}
                      options={organizationOptions}
                      placeholder='Select...'
                      name="organization_id"
                      id="organization"
                    />
                  </FormGroup>
                </div>

                <div className='col-sm-2'>
                  <FormGroup className="mb-3">
                    <Label for="institute_type">Institute Type</Label>
                    <Select
                      isClearable={true}
                      isSearchable={true}
                      value={selectedInstituteType}
                      onChange={setSelectedInstituteType}
                      options={
                        [
                          { label: 'Government', value: 'Government' },
                          { label: 'Private', value: 'Private' },
                          { label: 'Foreign', value: 'Foreign' }
                        ]
                      }
                      placeholder='Select...'
                      name="institute_type"
                      id="institute_type"
                    />
                  </FormGroup>
                </div>

                <div className="col-sm-2 mt-4">
                  <div className="btn-separator mb-3">
                    <Button
                      color="danger"
                      type="reset"
                      text="Reset"
                      onClick={() => {
                        setSelectedOrganization('');
                        setSelectedInstituteType('');
                        resetForm();
                        handleSubmit();
                      }}
                    />
                    <Button
                      type="submit"
                      text="Search"
                    />
                  </div>
                </div>
              </div>
            </Form>
            <div>
              <DataTable
                columns={columns}
                data={organizations?.data ? organizations?.data : []}
                pagination
                paginationServer
                paginationPerPage={20}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                progressPending={isLoading}
                paginationTotalRows={organizations?.total}
                persistTableHead
                expandOnRowClicked
                highlightOnHover
                paginationRowsPerPageOptions={[2, 5, 10, 15, 20, 50, 100, 200]}
              />
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Organizations;
