import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
} from 'reactstrap';
import { DaynamicSearchInputField } from 'src/components';
// import { flattenObject } from 'src/helpers';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { config } from 'src/config';
import { putUrlString } from 'src/helpers';
import { useGetDataQuery } from 'src/rtk/features/common/crudApi';

const DynamicModalTable = (props: any) => {
  let {
    dataApi = [],
    viewData = {},
    apiUrl = '',
    showTableActionColumn = true,
    isMulti = false,
    showModal = true,
    setShowModal,
    label = '',
    value = '',
    options = [],
    onChange,
  } = props;

  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');
  const generateQueryUrl = () => {
    return `${apiUrl}page=${page}&paginate_per_page=${limit}${search}`;
  };

  // if (apiUrl) {
  const {
    data: dataList,
    isLoading: dataListLoading,
    isSuccess: dataListLoadingSuccess,
    error: dataListLoadingError,
  } = useGetDataQuery(generateQueryUrl());
  viewData = dataList?.data || [];

  viewData = viewData.filter((row: any) => {
    let dataKey = dataApi.key(row);
    if (
      value &&
      typeof value === 'object' &&
      value.includes(dataKey) &&
      typeof options === 'object'
    ) {
      let isFounded = options.find((option: any) => dataKey === option.value);
      if (isFounded === undefined) {
        let newSelectedData = {
          label: dataApi.label(row),
          value: dataApi.key(row),
        };
        options instanceof Array && options.push(newSelectedData);
      }
    } else if (value && typeof value === 'string' && value === dataKey) {
      let newSelectedData = {
        label: dataApi.label(row),
        value: dataApi.key(row),
      };
      options instanceof Array && options.push(newSelectedData);
    }
    return dataKey;
  });

  useEffect(() => {
    if (dataListLoadingError) {
      toast.dismiss();
      toast.error('Data Loading error. Please try again later.');
    } else if (dataListLoading || !dataListLoadingSuccess) {
      toast.dismiss();
      // toast.loading('Data Loading...');
    } else {
      toast.dismiss();
      // toast.success('Data Load Successfully');
    }
  }, [dataListLoading, dataListLoadingSuccess, dataListLoadingError]);
  // }

  let initialSearchValues: any = {};
  let inputSearchFields: any = [];
  let tableColumn: any = [];
  let viewColumn: any = [];
  let searchFormFilds = dataApi?.searchFormFields || {};

  Object.keys(searchFormFilds)
    .sort(function (a: any, b: any) {
      return (
        parseInt(searchFormFilds[a].positionInForm) -
        parseInt(searchFormFilds[b].positionInForm)
      );
    })
    .map((key) => {
      let searchFormField = searchFormFilds[key];

      if (searchFormField['viewCell']) {
        viewColumn.push({
          name: searchFormField['label'],
          viewCell: searchFormField['viewCell'],
        });
      }

      if (searchFormField['cell']) {
        tableColumn.push({
          name: searchFormField['label'],
          cell: searchFormField['cell'],
        });
      }

      if (dataApi?.showSearchForm && searchFormFilds[key]['showInSearch']) {
        inputSearchFields.push(searchFormFilds[key]);

        if (
          searchFormField['type'] === 'checkbox' &&
          typeof searchFormField['default'] === 'string'
        ) {
          searchFormField['default'] = [searchFormField['default'].trim()];
        } else if (
          searchFormField['type'] === 'radio' &&
          typeof searchFormField['default'] === 'object'
        ) {
          searchFormField['default'] = searchFormField['default']
            .join(' ')
            .trim();
        } else if (searchFormField['type'] === 'date') {
          searchFormField['default'] =
            searchFormField?.options?.defaultDate || '';
        }

        initialSearchValues[searchFormField['name']] = '';
      }
    });

  let columns: TableColumn<any>[] = [
    {
      name: '#',
      width: '50px',
      cell: (row, index: any) =>
        viewData.from ? viewData.from + index : index + 1,
    },

    ...tableColumn,
  ];

  if (showTableActionColumn) {
    columns.push({
      name: 'Action',
      width: '100px',
      selector: (row: any) => row.id,
      cell: (row: any, index: any) => (
        <>
          {dataApi.key(row) && (
            <div className="mt-1">
              <Input
                key={Math.random()}
                type="checkbox"
                id={'required-switch-all-' + row?.id}
                switch="success"
                onChange={(e) => {
                  let isChecked = e.target.checked;
                  let newSelectedData = {
                    label: dataApi.label(row),
                    value: dataApi.key(row),
                  };
                  if (isMulti) {
                    if (isChecked) {
                      onChange([...options, newSelectedData], isChecked);
                    } else {
                      onChange([newSelectedData], isChecked);
                    }
                  } else {
                    onChange(newSelectedData, isChecked);
                    setShowModal(false);
                  }
                }}
                defaultChecked={value.includes(dataApi.key(row))}
                disabled={row?.payment_for === 'Guide' && row?.thesis_limit === 0}
              />             
              <Label
                htmlFor={'required-switch-all-' + row?.id}
                data-on-label="Yes"
                data-off-label="No"
              ></Label>
            </div>
          )}
        </>
      ),
    });
  }

  const {
    handleSubmit: handleSearchSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    values,
    setValues,
    setFieldValue,
    touched,
    errors,
    resetForm: resetSearchForm,
    setErrors,
  } = useFormik({
    initialValues: initialSearchValues,
    onSubmit: (searchValues) => {
      let s: string = '';

      // Object.keys(searchValues).map((i: any) => {
      //   if (searchValues[i] === '') {
      //     delete searchValues[i];
      //   }
      // });

      if (searchValues?.name) {
        s = s + `&search=${searchValues.name}`;
        // delete searchValues.name;
      }

      if (Object.keys(searchValues).length > 0) {
        s = s + `&` + putUrlString('', searchValues).replaceAll('?', '');
      }

      setSearch(s);
      setPage('1');
      // setLimit(20);
    },
  });

  return (
    <>
      <Modal
        onOpened={function () {}}
        isOpen={showModal}
        scrollable={false}
        size="xl"
        toggle={() => {
          document.body.classList.add('no_padding');
        }}
        backdrop={'static'}
        modalTransition={{ timeout: 0 }}
        backdropTransition={{ timeout: 0 }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {label} List
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setShowModal(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          {/* Search Option Start Here */}
          <Row className="d-flex">
            {dataApi?.showSearchForm && inputSearchFields.length > 0 && (
              <>
                <Col className="d-flex d-md-block  d-sm-none justify-content-start align-items-center">
                  <Form
                    className="custom-form w-100"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSearchSubmit();
                    }}
                  >
                    <div className="row">
                      {inputSearchFields.length > 0 &&
                        inputSearchFields.map((searchField: any, key: any) => {
                          if (
                            !['hidden', 'submit'].includes(searchField['type'])
                          ) {
                            searchField['isRequired'] = false;
                            searchField['default'] = '';

                            if (
                              ['radio', 'checkbox'].includes(
                                searchField['type'],
                              )
                            ) {
                              searchField['type'] = 'select';
                            }

                            if (
                              searchField['dependField'] &&
                              searchField['dependValue'] &&
                              searchField['condition']
                            ) {
                              let isMatch = true;
                              switch (searchField['condition']) {
                                case '===':
                                  isMatch =
                                    values[searchField['dependField']] ===
                                    searchField['dependValue'];
                                  break;

                                case '!==':
                                  isMatch =
                                    values[searchField['dependField']] !==
                                    searchField['dependValue'];
                                  break;

                                case 'includes':
                                  isMatch = searchField['dependValue'].includes(
                                    values[searchField['dependField']],
                                  );
                                  break;

                                default:
                                  break;
                              }
                              if (!isMatch) {
                                if (
                                  values[searchField['name']] !==
                                  searchField['default']
                                ) {
                                  setFieldValue(
                                    searchField['name'],
                                    searchField['default'],
                                  );
                                }
                                return <></>;
                              }
                            }

                            return (
                              <Col
                                md={'6'}
                                lg={'6'}
                                xl={'4'}
                                xxl={''}
                                key={searchField['name'] + key}
                              >
                                <FormGroup
                                  // key={searchField['name'] + key}
                                  className="mb-3"
                                >
                                  <>
                                    {['select'].includes(
                                      searchField['type'],
                                    ) && (
                                      <DaynamicSearchInputField
                                        label={searchField['label']}
                                        name={searchField['name']}
                                        type={searchField['type']}
                                        placeholder={'Select'}
                                        options={searchField['data']}
                                        dataApi={searchField['dataApi']}
                                        value={
                                          values[searchField['name']] !==
                                          undefined
                                            ? values[searchField['name']]
                                            : searchField['default']
                                        }
                                        isMulti={searchField['isMulti']}
                                        required={searchField['isRequired']}
                                        invalid={{ errors, touched }}
                                        onChange={(data: any) => {
                                          if (data === null) {
                                            setFieldValue(
                                              searchField['name'],
                                              '',
                                            );
                                          } else if (
                                            data &&
                                            data?.length === undefined &&
                                            data.value !== undefined
                                          ) {
                                            setFieldValue(
                                              searchField['name'],
                                              data.value,
                                            );
                                          } else if (
                                            data &&
                                            data?.length !== undefined
                                          ) {
                                            setFieldValue(
                                              searchField['name'],
                                              data.map(
                                                (item: any) => item.value,
                                              ),
                                            );
                                          }
                                        }}
                                      />
                                    )}

                                    {['date'].includes(searchField['type']) && (
                                      <>
                                        <DaynamicSearchInputField
                                          label={searchField['label']}
                                          name={searchField['name']}
                                          type={searchField['type']}
                                          placeholder={''}
                                          options={searchField['options']}
                                          invalid={{ errors, touched }}
                                          value={
                                            values[searchField['name']] !==
                                            undefined
                                              ? values[searchField['name']]
                                              : searchField?.options
                                                  ?.defaultDate
                                          }
                                          required={searchField['isRequired']}
                                          setData={(data: any) => {
                                            if (
                                              searchField?.options?.mode ===
                                              'range'
                                            ) {
                                              setFieldValue(
                                                searchField['name'],
                                                data[0],
                                              );
                                              setFieldValue(
                                                searchField['name2'],
                                                data[1],
                                              );
                                            } else {
                                              setFieldValue(
                                                searchField['name'],
                                                data.join(',').trim(),
                                              );
                                            }
                                          }}
                                        />
                                      </>
                                    )}

                                    {['radio', 'checkbox'].includes(
                                      searchField['type'],
                                    ) && (
                                      <DaynamicSearchInputField
                                        label={searchField['label']}
                                        name={searchField['name']}
                                        type={searchField['type']}
                                        placeholder={''}
                                        options={searchField['data']}
                                        dataApi={searchField['dataApi']}
                                        value={
                                          values[searchField['name']] !==
                                          undefined
                                            ? values[searchField['name']]
                                            : searchField['default']
                                        }
                                        isMulti={searchField['isMulti']}
                                        required={searchField['isRequired']}
                                        invalid={{ errors, touched }}
                                        onChange={(data: any) => {
                                          let selectedValue =
                                            data.target.value.toString();
                                          if (
                                            searchField['type'] === 'checkbox'
                                          ) {
                                            let isChecked = data.target.checked;
                                            let oldValue =
                                              values[searchField['name']];

                                            let getIndex =
                                              oldValue.indexOf(selectedValue);
                                            if (isChecked && getIndex === -1) {
                                              oldValue.push(selectedValue);
                                            } else if (
                                              !isChecked &&
                                              getIndex > -1
                                            ) {
                                              oldValue.splice(getIndex, 1);
                                            }

                                            setFieldValue(
                                              searchField['name'],
                                              oldValue,
                                            );
                                          } else {
                                            setFieldValue(
                                              searchField['name'],
                                              selectedValue,
                                            );
                                          }
                                        }}
                                      />
                                    )}

                                    {![
                                      'select',
                                      'radio',
                                      'checkbox',
                                      'date',
                                    ].includes(searchField['type']) && (
                                      <DaynamicSearchInputField
                                        label={searchField['label']}
                                        type={searchField['type']}
                                        placeholder={searchField['label']}
                                        required={searchField['isRequired']}
                                        invalid={{ errors, touched }}
                                        {...getFieldProps(searchField['name'])}
                                      />
                                    )}
                                  </>
                                </FormGroup>
                              </Col>
                            );
                          }
                        })}
                    </div>
                  </Form>
                </Col>

                <Col lg={'auto'} md={'5'} sm={12} className="mt-4 pt-1">
                  <FormGroup className="btn-group btn-group-example ">
                    <ButtonGroup className="d-inline-block ">
                      <Button
                        color="danger"
                        type="reset"
                        text="Reset"
                        onClick={() => {
                          resetSearchForm();
                          handleSearchSubmit();
                        }}
                      >
                        Reset
                      </Button>
                      <Button
                        color="success"
                        type="button"
                        text="Search"
                        onClick={() => {
                          handleSearchSubmit();
                        }}
                      >
                        Search
                      </Button>
                    </ButtonGroup>
                  </FormGroup>
                </Col>
              </>
            )}
          </Row>
          {/* Search Option End Here */}
          <Row>
            <Col>
              {viewData?.total !== undefined && columns.length > 0 && (
                <DataTable
                  columns={columns}
                  data={viewData?.data ? viewData.data : []}
                  pagination
                  paginationServer
                  onChangePage={(page: number) => {
                    setPage(page);
                  }}
                  onChangeRowsPerPage={(newPerPage: number, page: number) => {
                    setLimit(newPerPage);
                    setPage(page);
                  }}
                  progressPending={dataListLoading}
                  paginationTotalRows={viewData?.total}
                  expandOnRowClicked
                  highlightOnHover
                  paginationRowsPerPageOptions={
                    config.paginationRowsPerPageOptions
                  }
                />
              )}
              {viewData?.total === undefined && columns.length > 0 && (
                <DataTable
                  columns={columns}
                  data={viewData}
                  pagination
                  progressPending={dataListLoading}
                  paginationTotalRows={viewData?.length || 0}
                  highlightOnHover
                  paginationRowsPerPageOptions={
                    config.paginationRowsPerPageOptions
                  }
                  persistTableHead
                />
              )}
            </Col>
            {/* {JSON.stringify(viewColumn)}
        {JSON.stringify(viewData)} */}
          </Row>
        </div>
        <div className="modal-footer">
          <Button
            type="button"
            text="Close"
            className="btn btn-danger"
            onClick={() => {
              setShowModal(!showModal);
            }}
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default DynamicModalTable;
