import { MyDynamicForm } from 'src/components/DynamicForm/Transactional/DynamicFormModified';
import CourseApplication from 'src/pages/Trainee/CourseApplication';
import CourseList from 'src/pages/Trainee/CourseList';
import CourseListViewDetails from 'src/pages/Trainee/CourseListViewDetails';
import OnlinePayment from 'src/pages/Trainee/OnlinePayment';
import TraineeRegistration from 'src/pages/Trainee/TraineeRegistration';
import UpdatedAddTraineeOfflinePayment from 'src/pages/Trainee/UpdatedAddOfflinePayment';
import UpdatedOfflinePaymentList from 'src/pages/Trainee/UpdatedOfflinePaymentList';
import DynamicFormSetup from '../components/DynamicForm/Setup/DynamicFormSetup';
import CourseApprovalList from 'src/pages/Trainee/CourseApprovalList';

export const traineeRoutes = [
  // { path: '/trainee/trainee-registration', component: TraineeMain },
  { path: '/trainee/trainee-registration', component: TraineeRegistration },
  { path: '/profile-update', component: TraineeRegistration },
  // {
  //   path: '/trainee/trainee-registration-2',
  //   component: FacultyProfessionalEducationInfo,
  // },
  // { path: '/trainee/trainee-registration-3', component: TrainingRecord },
  // { path: '/trainee/trainee-registration-4', component: WorkExperience },
  { path: '/trainee/course-list', component: CourseList },
  { path: '/trainee/course-application', component: CourseApplication },
  { path: '/trainee/dynamic-form', component: MyDynamicForm },
  { path: '/trainee/dynamic-setup-form', component: DynamicFormSetup },
  {
    path: '/trainee/offline-payment/:type/:id/:fees/:year?/:session?',
    component: UpdatedAddTraineeOfflinePayment,
  },
  {
    path: '/trainee/offline-payment-list',
    component: UpdatedOfflinePaymentList,
  },
  { path: '/trainee/online-payment/:id/:id', component: OnlinePayment },
  { path: '/trainee/view-course-details', component: CourseListViewDetails },


  { path: '/course-application-list', component: CourseApprovalList },
];
