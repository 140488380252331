import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Modal,
  Row
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import { setFromErrors } from 'src/helpers';
import {
  useCreateNoticeMutation,
  useGetSignatoriesByPermissionQuery,
} from 'src/rtk/features/notice/publishNoticeApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoGlobalApi';
import * as Yup from 'yup';
import Attachments from './Attachments';
import NoticeTemplateSelectionList from './NoticeTemplateSelectionList';
import Signatories from './Signatories';
import RichTextEditor from './UpdatedRichTextEditor';

const initialValues: PostNotice = {
  id: '',
  notice_no: '',
  notice_date: '',
  department_id: '',
  notice_subject: '',
  notice_type: '',
  notice_header: '',
  notice_footer: '',
  notice_body: '',
  attachments: [],
  save_as_template: 0,
  template_name: '',
  type: '',
};

const AddNotice = () => {
  const [createNotice, { isSuccess, isError, error }] =
    useCreateNoticeMutation();

  const [attachmentInputList, setAttachmentInputList] = useState<any>([]);

  const [signatoryInputList, setSignatoryInputList] = useState<any>([]);

  const [forwardToInputList, setForwardToInputList] = useState<any>([
    { name: '' },
  ]);

  const noticeHeaderFileRef = useRef<any>();
  const noticeFooterFileRef = useRef<any>();

  const [noticeHeaderFile, setNoticeHeaderFile] = useState('');

  const [noticeFooterFile, setNoticeFooterFile] = useState('');

  // const [attachmentRadio, setAttachmentRadio] = useState<boolean>(true);
  // const [signatoryRadio, setSignatoryRadio] = useState<boolean>(false);

  const [saveAsTemplateState, setSaveAsTemplateState] =
    useState<boolean>(false);
  const [templateModalShow, setTemplateModalShow] = useState<boolean>(false);
  const [templateNameShow, setTemplateNameShow] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<NoticeTemplate>(
    {} as NoticeTemplate,
  );
  const [reRenderState, setReRenderState] = useState<number>(1);
  const [initialContentEditor, setInitialContentEditor] = useState<string>('');

  const err: CustomError = error as CustomError;

  const { data: subjectList, isError: isSubFetchError } =
    useGetSubjectInfosQuery();

  const departmentOptions: SelectOption[] = (
    subjectList?.data || []
  ).map((item) => ({
    value: item.subject_name ?? '',
    label: item.subject_name ?? '',
  }));

  departmentOptions.push({ value: 'All', label: 'All' });

  const signatoryUsers = useGetSignatoriesByPermissionQuery(
    'can-approve-notice-as-signatory',
  ).data?.data!;

  const noticeTypeOptions: SelectOption[] =
    useGetLookupDataQuery('notice_type')
      .data?.data[0]?.childs.map((item) => ({
        value: item.name,
        label: item.name,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) || [];

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      department_id: Yup.string().required('Please select department'),
      notice_no: Yup.string().required('Notice no. is required'),
      notice_date: Yup.date()
        .min(
          new Date(Date.now() - 86400000),
          "Notice date can't be a past date",
        )
        .required(),
    }),

    onSubmit: (values: any) => {
      values.attachments = [];
      values.type = 'Notice';

      const generalInputList = [...attachmentInputList, ...signatoryInputList];

      generalInputList?.forEach((attachment: any, index: number) => {
        values?.attachments?.push({
          type: attachment.attachmentType,
          file: attachment.attachmentFile,
          user_id: attachment.userId,
          signatory_serial: attachment.signatorySerial,
        });
      });

      values.notice_date = moment(values.notice_date).format('YYYY-MM-DD');

      console.log(values);

      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        if (key === 'save_as_template') {
          if (saveAsTemplateState) {
            values[key] = 1;
          } else {
            values[key] = 0;
          }
        }

        if (key === 'attachments') {
          values.attachments.forEach((item: any, index: number) => {
            if (item.type === 'Attachment' || item.type === 'Signatory') {
              formData.append(`attachments[${index}][type]`, item.type);
            }
            if (item.type === 'Attachment') {
              formData.append(`attachments[${index}][file]`, item.file);
            } else if (item.type === 'Signatory') {
              formData.append(`attachments[${index}][user_id]`, item.user_id);
              formData.append(
                `attachments[${index}][signatory_serial]`,
                item.signatory_serial,
              );
            }
          });
          // console.log(key, formData.getAll(`attachements[${index}][type]`), formData);
        }

        if (key !== 'attachment_signatory' && key !== 'attachments') {
          formData.append(key, values[key]);
        }
      });

      createNotice(formData);
    },
  });

  const {
    handleSubmit,
    getFieldProps,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    resetForm,
    setValues,
    setErrors,
    setFieldValue,
  } = formik;

  useEffect(() => {
    setFieldValue('notice_date', new Date(Date.now()).toISOString().substring(0, 10));
  }, []);

  useEffect(() => {
    if (selectedTemplate?.id) {
      setValues({
        // notice_no: selectedTemplate?.notice_no,
        // notice_date: selectedTemplate?.notice_date?.slice(0, 10),
        department_id: selectedTemplate?.department_id!,
        notice_subject: selectedTemplate?.notice_subject,
        notice_type: selectedTemplate?.notice_type,
        notice_header: '',
        notice_footer: '',
        notice_body: selectedTemplate?.notice_body,
      });

      // const allNoticeAttachments: any =
      //   selectedTemplate?.trn_notice_copy_attachments?.map((t: any) => {
      //     return {
      //       trn_notice_attachment_id: t.id,
      //       notice_master_id: t.notice_master_id,
      //       attachment_type: t.attachment_type,
      //       details: t.details,
      //       user_id: t.user_id,
      //       signatory_name: t.signatory_name,
      //       signatory_status: t.signatory_status,
      //       signatory_serial: t.signatory_serial,
      //     };
      //   });

      // const fetchedAttachmentInputList: any = allNoticeAttachments
      //   ?.filter((n: any) => n.attachment_type === 'Attachment')
      //   ?.map((n: any) => {
      //     return {
      //       attachmentType: n.attachment_type,
      //       attachmentFile: n.details,
      //       userId: 0,
      //       signatorySerial: 0,
      //     };
      //   });

      // const fetchedSignatoryInputList: any = allNoticeAttachments
      //   ?.filter((n: any) => n.attachment_type === 'Signatory')
      //   ?.map((n: any) => {
      //     return {
      //       attachmentType: n.attachment_type,
      //       attachmentFile: '',
      //       userId: n.user_id,
      //       signatorySerial: n.signatory_serial,
      //     };
      //   });

      // setAttachmentInputList(fetchedAttachmentInputList);
      // setSignatoryInputList(fetchedSignatoryInputList);

      setNoticeHeaderFile(
        `${config?.APP_URL}${selectedTemplate?.notice_header}`,
      );

      setNoticeFooterFile(
        `${config?.APP_URL}${selectedTemplate?.notice_footer}`,
      );

      // setAttachmentRadio(true);
      // setSignatoryRadio(false);

      if (reRenderState === 1) {
        setReRenderState(0);
      } else if (reRenderState === 0) {
        setReRenderState(1);
      }

      toast.dismiss();
      toast.success('Notice templated selected');
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      noticeHeaderFileRef.current.value = '';
      noticeFooterFileRef.current.value = '';
      if (reRenderState === 1) {
        setReRenderState(0);
      } else if (reRenderState === 0) {
        setReRenderState(1);
      }
      // setAttachmentRadio(true);
      // setSignatoryRadio(false);
      setAttachmentInputList([]);
      setSignatoryInputList([]);
      setNoticeHeaderFile('');
      setNoticeFooterFile('');
      setSaveAsTemplateState(false);
      setTemplateNameShow(false);
      setFieldValue('notice_date', new Date(Date.now()).toISOString().substring(0, 10));
      toast.success('Notice submitted successfully');
      // history.push('/setup/department');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  const handleTemplateSelection = (e: any) => {
    setTemplateModalShow(true);
  };

  const handleTemplateNameModalShow = (e: any) => {
    setTemplateNameShow(true);
    setSaveAsTemplateState(true);
  };

  const handleDepartmentChange = (e: any) => {
    const departmentList: string = e?.reduce((acc: any, option: any) => {
      if (acc === '') {
        return option?.value;
      }

      return acc + ', ' + option?.value;
    }, '');

    setFieldValue('department_id', departmentList);
  };

  const handleFormReset = () => {
    resetForm();
    noticeHeaderFileRef.current.value = '';
    noticeFooterFileRef.current.value = '';
    if (reRenderState === 1) {
      setReRenderState(0);
    } else if (reRenderState === 0) {
      setReRenderState(1);
    }
    // setAttachmentRadio(true);
    // setSignatoryRadio(false);
    setAttachmentInputList([]);
    setSignatoryInputList([]);
    setNoticeHeaderFile('');
    setNoticeFooterFile('');
    setSelectedTemplate({} as NoticeTemplate);
    setSaveAsTemplateState(false);
    setFieldValue('notice_date', new Date(Date.now()).toISOString().substring(0, 10));
  };

  return (
    <>
      <React.Fragment>
        <Container fluid>
          <BetterBreadcrumb title="Notice Entry" />
          <Row>
            <Col lg={12}>
              <Card>

                <CardBody className="p-4">
                  <h6 className='block-heading d-flex align-items-center'>  Notice Entry
                    <Button
                      type="button"
                      text="Get Template"
                      className="btn btn-info  ms-auto"
                      onClick={(e) => {
                        handleTemplateSelection(e);
                      }}
                    ></Button></h6>
                  <React.Fragment>
                    <Form
                      autoComplete="off"
                      className="custom-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      onReset={(e) => {
                        e.preventDefault();
                        handleFormReset();
                      }}
                    >
                      <Row>
                        <Col xl="3" lg={4} md={6} sm={12}>
                          <div className="mb-3">
                            <Label htmlFor="notice_no" className="form-Label">
                              Reference No.
                            </Label>
                            <InputField
                              type="text"
                              placeholder="Reference No."
                              invalid={{ errors, touched }}
                              {...getFieldProps('notice_no')}
                            />
                          </div>
                        </Col>
                        <Col xl="3" lg={4} md={6} sm={12}>
                          <div className="mb-3">
                            <Label htmlFor="notice_date" className="form-Label">
                              Date
                            </Label>
                            <InputField
                              type="date"
                              placeholder=""
                              invalid={{ errors, touched }}
                              {...getFieldProps('notice_date')}
                            />
                          </div>
                        </Col>
                        <Col xl="3" lg={4} md={6} sm={12}>
                          <div className="mb-3">
                            <Label
                              htmlFor="department_id"
                              className="form-Label"
                            >
                              Subject
                            </Label>
                            {/* <InputField
                              type="select"
                              options={departmentOptions}
                              placeholder=""
                              invalid={{ errors, touched }}
                              {...getFieldProps('department_id')}
                            /> */}
                            <Select
                              // defaultValue={[]}
                              isMulti
                              name="department_id"
                              value={
                                values.department_id !== ''
                                  ? values.department_id
                                    ?.split(', ')
                                    ?.map((dept: any) => ({
                                      label: dept,
                                      value: dept,
                                    }))
                                  : []
                              }
                              options={departmentOptions}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={handleDepartmentChange}
                              menuPortalTarget={document.body}
                              menuPosition={'fixed'}
                              styles={{ menuPortal: (provided: any, state: any) => ({ ...provided, zIndex: '9999', }) }}
                            />
                            {errors?.['department_id'] && touched?.['department_id'] &&
                              <div className='text-danger'>
                                {errors?.['department_id'] as string}
                              </div>
                            }
                          </div>
                        </Col>
                        <Col xl="3" lg={4} md={6} sm={12}>
                          <div className="mb-3">
                            <Label
                              htmlFor="notice_subject"
                              className="form-Label"
                            >
                              Notice Title
                            </Label>
                            <InputField
                              type="text"
                              placeholder="Notice Title"
                              invalid={{ errors, touched }}
                              {...getFieldProps('notice_subject')}
                            />
                          </div>
                        </Col>
                        <Col xl="3" lg={4} md={6} sm={12}>
                          <div className="mb-3">
                            <Label htmlFor="notice_type" className="form-Label">
                              Notice Type
                            </Label>
                            <InputField
                              type="select"
                              options={noticeTypeOptions}
                              placeholder=""
                              invalid={{ errors, touched }}
                              {...getFieldProps('notice_type')}
                            />
                          </div>
                        </Col>
                        <Col xl="3" lg={4} md={6} sm={12}>
                          <div className="mb-3">
                            <Label
                              htmlFor="notice_header"
                              className="form-Label"
                            >
                              Notice Header
                            </Label>
                            {noticeHeaderFile?.substring(`${config.APP_URL}`.length) &&
                              noticeHeaderFile?.substring(`${config.APP_URL}`.length) !== 'null' && (
                                <a
                                  className="me-2 p-0 center"
                                  href={noticeHeaderFile}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  <span className="btn-outline-info btn-sm btn ms-2">
                                    View
                                  </span>
                                </a>
                              )}
                            <input
                              ref={noticeHeaderFileRef}
                              name="noticeHeader"
                              type="file"
                              className="form-control"
                              placeholder="Upload File"
                              onChange={(e: any) => {
                                setFieldValue(
                                  'notice_header',
                                  e.target.files[0],
                                );
                              }}
                            ></input>
                          </div>
                        </Col>
                        <Col xl="3" lg={4} md={6} sm={12}>
                          <div className="mb-3">
                            <Label
                              htmlFor="notice_footer"
                              className="form-Label"
                            >
                              Notice Footer
                            </Label>
                            {noticeFooterFile?.substring(`${config.APP_URL}`.length) &&
                              noticeFooterFile?.substring(`${config.APP_URL}`.length) !== 'null' && (
                                <a
                                  className="me-2 p-0 center"
                                  href={noticeFooterFile}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  <span className="btn-outline-info btn-sm btn ms-2">
                                    View
                                  </span>
                                </a>
                              )}
                            <input
                              ref={noticeFooterFileRef}
                              name="noticeFooter"
                              type="file"
                              className="form-control"
                              placeholder="Upload File"
                              onChange={(e: any) => {
                                setFieldValue(
                                  'notice_footer',
                                  e.target.files[0],
                                );
                              }}
                            ></input>
                          </div>
                        </Col>
                        <Col sm={12} className="my-3">
                          <div className="mb-3">
                            <Label htmlFor="notice_body" className="form-Label">
                              Notice Body
                            </Label>
                            <RichTextEditor
                              formikState={formik.values}
                              initialContentEditor={initialContentEditor}
                              setInitialContentEditor={setInitialContentEditor}
                              key={reRenderState}
                            />
                          </div>
                        </Col>
                        {/* <Col sm={12}>
                          <div className="mb-3">
                            {/* <Label className="form-label">Training Mode</Label> */}
                        {/* <br />
                            <FormGroup check inline>
                              <Input
                                id="attachmentSignature1"
                                name="attachment_signatory"
                                type="radio"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setAttachmentRadio(true);
                                    setSignatoryRadio(false);
                                  }

                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={'Attachment'}
                                checked={attachmentRadio}
                              />

                              <Label check for="attachmentSignature1">
                                Attachments
                              </Label>
                            </FormGroup>

                            <FormGroup check inline>
                              <Input
                                id="attachmentSignature2"
                                name="attachment_signatory"
                                type="radio"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setAttachmentRadio(false);
                                    setSignatoryRadio(true);
                                  }

                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={'Signatory'}
                                checked={signatoryRadio}
                              />

                              <Label check for="attachmentSignature2">
                                Signatories
                              </Label>
                            </FormGroup>
                          </div>
                        </Col> */}
                        <Col md={6} sm={12}>
                          <Label htmlFor="attachments" className="form-Label">
                            Attachments
                          </Label>
                          <Attachments
                            key={reRenderState}
                            attachmentInputList={attachmentInputList}
                            setInputList={setAttachmentInputList}
                          />
                        </Col>
                        <Col md={6} sm={12}>
                          <Label htmlFor="signatories" className="form-Label">
                            Signatories
                          </Label>
                          <Signatories
                            key={reRenderState}
                            userList={signatoryUsers}
                            signatoryInputList={signatoryInputList}
                            setInputList={setSignatoryInputList}
                          />
                        </Col>
                        {/* <Col lg={4} md={6} sm={12}>
                        <Label htmlFor="forwardTo" className="form-Label">
                          Forward To
                        </Label>
                        <ForwardTo key={reRenderState} inputList={forwardToInputList} setInputList={setForwardToInputList} />
                      </Col> */}

                        {/* <Col sm={12}>
                          <div className="mb-3">
                            <FormGroup switch className="mb-3">
                              <Input
                                type="switch"
                                role="switch"
                                checked={saveAsTemplateState}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSaveAsTemplateState(true);
                                  } else {
                                    setSaveAsTemplateState(false);
                                  }
                                }}
                              />
                              <Label>Save as Template</Label>
                            </FormGroup>
                          </div>
                        </Col> */}
                        <Col sm={12} className="mt-5">
                          <FormGroup className="mb-3 text-center">
                            <Button
                              type="reset"
                              text="Reset"
                              className="btn btn-warning me-2"
                              onClick={(e) => {
                                handleFormReset();
                              }}
                            ></Button>
                            <Button
                              type="submit"
                              text="Save as Draft"
                              className="btn btn-info me-2"
                              onClick={(e) => {
                                setSaveAsTemplateState(false);
                              }}
                            ></Button>
                            <Button
                              type="submit"
                              text="Submit"
                              className="btn btn-success me-2"
                              onClick={(e) => {
                                setSaveAsTemplateState(false);
                              }}
                            ></Button>
                            <Button
                              type="button"
                              text="Save as Template"
                              className="btn btn-secondary"
                              onClick={(e) => {
                                handleTemplateNameModalShow(e);
                              }}
                            ></Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </React.Fragment>

                  <Modal
                    onOpened={function () {
                      toast.dismiss();
                    }}
                    isOpen={templateModalShow}
                    scrollable={true}
                    // style={{ maxWidth: '1600px', width: '95%' }}
                    // size="xl"
                    fullscreen={true}
                    toggle={() => {
                      document.body.classList.add('no_padding');
                    }}
                    backdrop={'static'}
                    modalTransition={{ timeout: 0 }}
                    backdropTransition={{ timeout: 0 }}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title" id="staticBackdropLabel">
                        Select Template
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                          setTemplateModalShow(!templateModalShow);
                        }}
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <Row>
                        <Col>
                          <NoticeTemplateSelectionList
                            selectedTemplate={selectedTemplate}
                            setSelectedTemplate={setSelectedTemplate}
                            setModalShow={setTemplateModalShow}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Modal>

                  <Modal
                    onOpened={function () {
                      toast.dismiss();
                    }}
                    isOpen={templateNameShow}
                    scrollable={true}
                    size="sm"
                    toggle={() => {
                      document.body.classList.add('no_padding');
                    }}
                    backdrop={'static'}
                    modalTransition={{ timeout: 0 }}
                    backdropTransition={{ timeout: 0 }}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title" id="staticBackdropLabel">
                        Provide Template Name
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                          setTemplateNameShow(!templateNameShow);
                        }}
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <Row>
                        <Col sm={12}>
                          <div className="mb-3">
                            <Label
                              htmlFor="notice_subject"
                              className="form-Label"
                            >
                              Name of the template
                            </Label>
                            <InputField
                              type="text"
                              placeholder="Template Name"
                              invalid={{ errors, touched }}
                              {...getFieldProps('template_name')}
                            />
                          </div>
                        </Col>
                        <Col sm={12} className="mt-3">
                          <FormGroup className="mb-3 text-end">
                            <Button
                              type="button"
                              text="Submit"
                              disabled={values.template_name === ''}
                              className="btn btn-success me-2"
                              onClick={(e) => {
                                handleSubmit();
                              }}
                            ></Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </>
  );
};

export default AddNotice;
