import { useEffect, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";

export const CompetencyGroupLevels = (props: any) => {
    const { finalFormikProp, setFinalFormikProp, competency, checkedCompetencyIds, competenceLevelOptions } = props;

    // const [formikCompetencyGroup, setFormikCompetencyGroup] = useState<any>([]);

    let levelLabels: string[] = [];

    if(competenceLevelOptions?.length && competency?.competence_level_type === 'Patient Care Competence') {
        levelLabels = competenceLevelOptions[0]?.childs?.map((option: any, index: number) => option.name);
    } else if(competenceLevelOptions?.length && competency?.competence_level_type === 'Procedural/Surgical skill') {
        levelLabels = competenceLevelOptions[1]?.childs?.map((option: any, index: number) => option.name);
    } else {
        levelLabels = ['Level 1', 'Level 2', 'Level 3', 'Level 4'];
    }

    const handleCompetencyLevel = (e: any, value: number) => {
        if(e.target.checked) {
            const newFinalFormikProp: any = [...finalFormikProp];
            newFinalFormikProp?.forEach((c: any) => {
                if(c.id  === competency.id) {
                    c['level'].push(value);
                }
            });
            // formik.values['competency_group'] = newFormikCompetencyGroup;
            setFinalFormikProp(newFinalFormikProp);
        } else {
            const newFinalFormikProp: any = [...finalFormikProp];
            newFinalFormikProp?.forEach((c: any) => {
                if(c.id  === competency.id) {
                    const index = c['level'].indexOf(value);
                    c['level'].splice(index, 1);
                }
            });
            // formik.values['competency_group'] = newFormikCompetencyGroup;
            setFinalFormikProp(newFinalFormikProp);
        }
    };

    // useEffect(() => {
    //     setFormikCompetencyGroup(formik.values['competency_group']);
    // }, []);    

    // console.log(formik.values['competency_group']);

    return (
            checkedCompetencyIds?.indexOf(competency.id) !== -1 ? (
                <>
                    {'['}
                    <Label check className="ms-3 me-3">
                        <Input type='checkbox' 
                            name='levelOne' 
                            id='levelOne' 
                            checked={finalFormikProp?.find((c: any) => c.id === competency.id)?.['level']?.includes(1)}
                            onChange={(e) => {
                                handleCompetencyLevel(e, 1);
                            }}/>{' '}
                        {`${levelLabels[0]}`}
                    </Label>
                    <Label check className="me-3">
                        <Input type='checkbox' 
                            name='levelTwo' 
                            id='levelTwo' 
                            checked={finalFormikProp?.find((c: any) => c.id === competency.id)?.['level']?.includes(2)}
                            onChange={(e) => {
                                handleCompetencyLevel(e, 2);
                            }}/>{' '}
                        {`${levelLabels[1]}`}
                    </Label>
                    <Label check className="me-3">
                        <Input type='checkbox' 
                            name='levelThree' 
                            id='levelThree' 
                            checked={finalFormikProp?.find((c: any) => c.id === competency.id)?.['level']?.includes(3)}
                            onChange={(e) => {
                                handleCompetencyLevel(e, 3);
                            }}/>{' '}
                        {`${levelLabels[2]}`}
                    </Label>
                    <Label check className="me-3">
                        <Input type='checkbox' 
                            name='levelFour' 
                            id='levelFour' 
                            checked={finalFormikProp?.find((c: any) => c.id === competency.id)?.['level']?.includes(4)}
                            onChange={(e) => {
                                handleCompetencyLevel(e, 4);
                            }}/>{' '}
                        {`${levelLabels[3]}`}
                    </Label>
                    {']'}
                </>
            ) : null        
    );
};