import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Button, InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import { useGetFacultyInfosQuery } from 'src/rtk/features/setup/facultyInfo/facultyInfoApi';
import { useCreateSubjectInfoMutation } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import * as Yup from 'yup';

const initialValues: SubjectInfo = {
  id: '',
  subject_code: '',
  subject_name: '',
  category: '',
  description: '',
  duration: 0,
  duration_unit: 'Years',
  setup_faculty_info_id: '',
  status: true,
  creator_organization_id: '',
  updater_organization_id: '',
  created_by: '',
  updated_by: '',
  deleted_by: '',
  created_at: '',
  updated_at: '',
  deleted_at: '',
};
interface Props {
  isOpen: boolean;
  toggle: any;
}

export const AddSubjectInfo = (props: Props) => {
  const { isOpen, toggle } = props;

  const [createSubjectInfo, { isSuccess, isError, error }] =
    useCreateSubjectInfoMutation();

  const { data } = useGetFacultyInfosQuery();
  const facultyInfos: SelectOption[] = (data?.data || []).map((item) => ({
    label: item.faculty_name!,
    value: item.id!,
  }));

  const unitOptions: SelectOption[] = [
    { label: '3 Years', value: 3 },
    { label: '4 Years', value: 4 },
    { label: '5 Years', value: 5 },
  ];

  const history = useHistory();

  const err: CustomError = error as CustomError;
  const {
    values,
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      subject_name: Yup.string().required('Please Enter Subject Name'),
      subject_code: Yup.string().required('Please Enter Subject Code'),
      category: Yup.string().required('Please Enter Subject Type'),
      setup_faculty_info_id: Yup.string()
        .required('Subject must be under a faculty')
        .min(1, 'Subject must be under a faculty'),
      duration: Yup.number()
        .required('Please Enter Duration')
        .min(1, 'Please Enter Duration'),
      duration_unit: Yup.string()
        .required('Please Enter Duration Unit')
        .min(1, 'Please Enter Duration Unit'),
    }),

    onSubmit: (values) => {
      createSubjectInfo({
        ...values,
      });
    },
  });

  useEffect(() => {
    if (isSuccess) {
      toggle();
      toast.success('Subject created successfully');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} size="lg" backdrop={'static'}>
        <ModalHeader toggle={toggle}>Add Subject</ModalHeader>
        <ModalBody>
          <Form
            className="custom-form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <FormGroup className="mb-3">
                  <Label for="subject_code" className='required-field'>Subject Code</Label>
                  <InputField
                    type="text"
                    placeholder="Subject Code"
                    invalid={{ errors, touched }}
                    {...getFieldProps('subject_code')}
                  />
                </FormGroup>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <FormGroup className="mb-3">
                  <Label for="subject_name" className='required-field'>Subject Name</Label>
                  <InputField
                    type="text"
                    placeholder="Subject Name"
                    invalid={{ errors, touched }}
                    {...getFieldProps('subject_name')}
                  />
                </FormGroup>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <FormGroup className="mb-3">
                  <Label for="category" className='required-field'>Subject Type</Label>
                  <InputField
                    type="text"
                    placeholder="Subject Type"
                    invalid={{ errors, touched }}
                    {...getFieldProps('category')}
                  />
                </FormGroup>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <FormGroup className="mb-3">
                  <Label for="description">Description</Label>
                  <InputField
                    type="text"
                    placeholder="Description"
                    invalid={{ errors, touched }}
                    {...getFieldProps('description')}
                  />
                </FormGroup>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <FormGroup className="mb-3">
                  <Label for="duration" className='required-field'>Fellowship Duration</Label>
                  <InputField
                    type="select"
                    // step={0.5}
                    // min={1}
                    options={unitOptions}
                    placeholder="Fellowship Duration"
                    invalid={{ errors, touched }}
                    {...getFieldProps('duration')}
                  />
                </FormGroup>
              </div>

              {/* <div className="col-lg-4 col-md-6 col-sm-12">
                <FormGroup className="mb-3">
                  <Label for="duration_unit" className='required-field'>Duration Unit</Label>
                  <InputField
                    type="select"
                    options={unitOptions}
                    invalid={{ errors, touched }}
                    {...getFieldProps('duration_unit')}
                  />
                </FormGroup>
              </div> */}

              <div className="col-lg-4 col-md-6 col-sm-12">
                <FormGroup className="mb-3">
                  <Label for="subject_name" className='required-field'>Faculty</Label>
                  <InputField
                    type="select"
                    options={facultyInfos}
                    placeholder="Faculty"
                    invalid={{ errors, touched }}
                    {...getFieldProps('setup_faculty_info_id')}
                  />
                </FormGroup>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <FormGroup check className="mb-3 mt-4">
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={values.status}
                      {...getFieldProps('status')}
                    />
                    Active
                  </Label>
                </FormGroup>
              </div>
            </div>

            <Button type="submit" text="Submit" />
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};
