import { apiSlice } from 'src/rtk/api/apiSlice';
import * as setupTrnNoticeCopyAttachmentUrl from 'src/helpers/url_helper';

export const setupTrnNoticeCopyAttachmentApi = apiSlice
    .enhanceEndpoints({ addTagTypes: ['NoticeAttachments'] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getNoticeAttachments: builder.query<APIResponse<TransactionNoticeCopyAttachment[]>, void>({
                query: () => ({
                    method: 'GET',
                    url: `${setupTrnNoticeCopyAttachmentUrl.GET_NOTICE_ATTACHMENTS}`,
                }),
                providesTags: ['NoticeAttachments'],
            }),

            getNoticeAttachment: builder.query<APIResponse<TransactionNoticeCopyAttachment>, string>({
                query: (id) => ({
                    url: `${setupTrnNoticeCopyAttachmentUrl.GET_NOTICE_ATTACHMENT}/${id}`,
                    method: 'GET',
                }),
                providesTags: ['NoticeAttachments'],
            }),

            createNoticeAttachment: builder.mutation<APIResponse<TransactionNoticeCopyAttachment>, TransactionNoticeCopyAttachment>({
                query: (data) => ({
                    url: `${setupTrnNoticeCopyAttachmentUrl.CREATE_NOTICE_ATTACHMENT}`,
                    method: 'POST',
                    body: data,
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                }),
                invalidatesTags: ['NoticeAttachments'],
            }),

            updateNoticeAttachment: builder.mutation<APIResponse<TransactionNoticeCopyAttachment>, string>({
                query: (url) => ({
                    url: `${setupTrnNoticeCopyAttachmentUrl.UPDATE_NOTICE_ATTACHMENT_IN_TRN_NOTICE_COPY_ATTACHMENT_SETUP}` + url,
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                }),
                invalidatesTags: ['NoticeAttachments'],
            }),

            deleteNoticeAttachment: builder.mutation({
                query: (id: string) => ({
                    method: 'DELETE',
                    url: `${setupTrnNoticeCopyAttachmentUrl.DELETE_NOTICE_ATTACHMENT}/${id}`,
                }),
                invalidatesTags: ['NoticeAttachments'],
            }),
        }),
    });

export const {
    useCreateNoticeAttachmentMutation,
    useGetNoticeAttachmentQuery,
    useGetNoticeAttachmentsQuery,
    useUpdateNoticeAttachmentMutation,
    useDeleteNoticeAttachmentMutation
} = setupTrnNoticeCopyAttachmentApi;
