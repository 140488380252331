import Organizations from 'src/pages/Setup/Organization';
import AddOrganization from 'src/pages/Setup/Organization/AddOrganization';
import EditOrganization from 'src/pages/Setup/Organization/EditOrganization';

export const organizationSetupRoutes = [
  // setup Organizations
  { path: '/setup/organizations', component: Organizations },
  { path: '/setup/organizations/add-organization', component: AddOrganization },
  {
    path: '/setup/organizations/edit-organization/:id',
    component: EditOrganization,
  },
];
