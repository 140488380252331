import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container, Table } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetTransactionNoticeDetailsQuery } from 'src/rtk/features/setup/trnNoticeDetails/setupTrnNoticeDetails';
import TransactionNoticeDetailsTableRow from './TrnNoticeDetailsTableRow';

export const TransactionNoticeDetails = () => {
  const { data, error, isLoading, isError } = useGetTransactionNoticeDetailsQuery();

  const err: CustomError = error as CustomError;
  const transactionNoticeDetails = data?.data || [];

  let content = null;

  if (isLoading) {
    content = <div className="mt-3">Loading...</div>;
  }
  if (!isLoading && isError && error) {
    content = <div className="mt-3">{err.message}</div>;
  }
  if (!isLoading && !isError && transactionNoticeDetails?.length > 0) {
    content = transactionNoticeDetails.map((transactionNoticeDetail: any, index: any) => (
      <TransactionNoticeDetailsTableRow transactionNoticeDetails={transactionNoticeDetail} index={index} key={transactionNoticeDetail.id} />
    ));
  } else {
    content = (
      <tr>
        <td colSpan={11}>
          <div className="text-center text-danger h6">No data available!</div>
        </td>
      </tr>
    );
  }
  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Notice Details List'}
          breadcrumbItem={[{ link: '/setup/trn-notice-details/add', name: 'Notice Details Add' }]}
        />
        <Card>
          <CardHeader className="d-flex justify-content-end">
            <Link className="btn btn-sm btn-primary" to="/setup/trn-notice-details/add">
              Add Notice Details
            </Link>
          </CardHeader>
          <CardBody>
            <div className="table-responsive">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Sl. </th>
                    <th>Notice Master Subject</th>                    
                    <th>Details</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
