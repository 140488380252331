import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container, Table } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetExamEligibilityCriteriasQuery } from 'src/rtk/features/setup/examEligibilityCriteria/examEligibilityCriteriaApi';
import ExamEligibilityCriteriaTableRow from './ExamEligibilityCriteriaTableRow';

export const ExamEligibilityCriterias = () => {
  const { data, error, isLoading, isError } =
    useGetExamEligibilityCriteriasQuery();

  const err: CustomError = error as CustomError;
  const examEligibilityCriterias = data?.data || [];

  let content = null;
  if (isLoading) {
    content = <div className="mt-3">Loading...</div>;
  }
  if (!isLoading && isError && error) {
    content = <div className="mt-3">{err.message}</div>;
  }
  if (!isLoading && !isError && examEligibilityCriterias?.length > 0) {
    content = examEligibilityCriterias.map((examEligibilityCriteria, index) => (
      <ExamEligibilityCriteriaTableRow
        examEligibilityCriteria={examEligibilityCriteria}
        index={index}
        key={examEligibilityCriteria.id}
      />
    ));
  } else {
    content = (
      <tr>
        <td colSpan={11}>
          <div className="text-center text-danger h6">No data available!</div>
        </td>
      </tr>
    );
  }
  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb title="Exam Eligibility Criteria" />{' '}
        <Card>
          <CardHeader className="d-flex justify-content-end">
            <Link
              to="/setup/xm-eligibility-criteria/add"
              className="btn btn-primary btn-sm"
            >
              Add Exam Eligibility Criteria
            </Link>
          </CardHeader>
          <CardBody>
            <div className="table-responsive text-nowrap text-center  x-overflow-scroll">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Sl. </th>
                    <th>Eligibility Criteria</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
