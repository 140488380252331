import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container, Table } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetLogbookEntrySetupDetailsValuesQuery } from 'src/rtk/features/setup/logBookEntrySetupDetailsValue/logBookEntrySetupDetailsValueApi';
import LogbookEntrySetupDetailsValueTableRow from './LogbookEntrySetupDetailsValueTableRow';

export const LogbookEntrySetupDetailsValues = () => {
  const { data, error, isLoading, isError } = useGetLogbookEntrySetupDetailsValuesQuery();

  const err: CustomError = error as CustomError;
  const logbookEntrySetupDetailsValues = data?.data || [];

  let content = null;

  if (isLoading) {
    content = <div className="mt-3">Loading...</div>;
  }
  if (!isLoading && isError && error) {
    content = <div className="mt-3">{err.message}</div>;
  }
  if (!isLoading && !isError && logbookEntrySetupDetailsValues?.length > 0) {
    content = logbookEntrySetupDetailsValues.map((logbookEntrySetupDetailsValue: any, index: any) => (
      <LogbookEntrySetupDetailsValueTableRow logbookEntrySetupDetailsValue={logbookEntrySetupDetailsValue} index={index} key={logbookEntrySetupDetailsValue.id} />
    ));
  } else {
    content = (
      <tr>
        <td colSpan={11}>
          <div className="text-center text-danger h6">No data available!</div>
        </td>
      </tr>
    );
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Logbook Entry Setup Details Value List'}
          breadcrumbItem={[{ link: '/setup/logbook-entry-setup-details-value/add', name: 'Add Logbook Entry Setup Details Value' }]}
        />
        <Card>
          <CardHeader className="d-flex justify-content-end">
            <Link className="btn btn-sm btn-primary" to="/setup/logbook-entry-setup-details-value/add">
              Add Logbook Entry Setup Details Value
            </Link>
          </CardHeader>
          <CardBody>
            <div className="table-responsive">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Sl. </th>
                    <th>Logbook Entry Setup Detail</th>
                    <th>Value List</th>
                    <th>Required</th>
                    <th>Show</th>                    
                    <th>Serial No</th>  
                    <th>Default</th>                  
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
