import { apiSlice } from 'src/rtk/api/apiSlice';
import * as myTrainingUrl from 'src/helpers/url_helper';

export const myTrainingApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['MyTrainings', 'Documents'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMyTraining: builder.query<APIResponse<MyTraining>,
      any
      >({
        query: (data: any) => ({
          url: `${myTrainingUrl.GET_MY_TRAINING}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        providesTags: ['MyTrainings'],
      }),

      createMyTraining: builder.mutation<
        APIResponse<MyTraining>,
        any
      >({
        query: (data: any) => ({
          url: `${myTrainingUrl.CREATE_MY_TRAINING}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['MyTrainings'],
      }),

      createSupervisorCourseApply: builder.mutation<
        APIResponse<SupervisorCourseApply>,
        any
      >({
        query: (data: any) => ({
          url: `${myTrainingUrl.CREATE_SUPERVISOR_COURSE_APPLY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['MyTrainings'],
      }),

      createChangeSupervisorApply: builder.mutation<
        APIResponse<SupervisorCourseApply>,
        any
      >({
        query: (data: any) => ({
          url: `${myTrainingUrl.CREATE_CHANGE_SUPERVISOR_APPLY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['MyTrainings'],
      }),

      createDocumentUpload: builder.mutation<
        APIResponse<DocumentUpload>,
        any
      >({
        query: (data: any) => ({
          url: `${myTrainingUrl.CREATE_DOCUMENT_UPLOAD}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['Documents'],
      }),

      createPaymentWitoutFeesApply: builder.mutation<
        APIResponse<any>,
        any
      >({
        query: (data: any) => ({
          url: `${myTrainingUrl.CREATE_PAYMENT_WITHOUT_FEES_APPLY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['MyTrainings'],
      }),

      getCurrentSupervisor: builder.query<APIResponse<GetCurrentSupervisor>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${myTrainingUrl.GET_CURRENT_SUPERVISOR}/${id}`,
        }),
        providesTags: ['MyTrainings'],
      }),

      createTrainingFileUpload: builder.mutation<APIResponse<any>, any>({
        query: (data: any) => ({
          url: data.queryUrl,
          method: 'POST',
          body: data.formData,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['MyTrainings'],
      }),

      updateMyTraining: builder.mutation<
        APIResponse<MyTraining>,
        any
      >({
        query: (url) => ({
          url: `/${url}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['MyTrainings'],
      }),
      deleteMyTraining: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `/${id}`,
        }),
        invalidatesTags: ['MyTrainings'],
      }),
    }),
  });

export const {
  useLazyGetMyTrainingQuery,
  useCreateMyTrainingMutation,
  useCreateTrainingFileUploadMutation,
  useCreateDocumentUploadMutation,
  useCreateSupervisorCourseApplyMutation,
  useCreateChangeSupervisorApplyMutation,
  useCreatePaymentWitoutFeesApplyMutation,
  useGetCurrentSupervisorQuery,
} = myTrainingApi;
