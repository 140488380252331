import moment from 'moment';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Col, Modal, Row, Table } from 'reactstrap';
interface Props {
    formEntryList: any;
}

const CBDFormBInformation = (props: Props) => {
    const { formEntryList } = props;

    // console.log(formEntryList);
    const [dataInfo, setDataInfo] = useState<any>('');
    const [modalShow, setModalShow] = useState(false);


    const modalShowHandler = (pageState: boolean, infos: any) => {
        setModalShow(pageState);
        setDataInfo(infos);
    }

    // console.log(dataInfo);
    return (
        <div>
            <Table className='table-bordered mt-2'>
                <thead>
                    <tr>
                        <th>Slot List</th>
                        <th>Entry Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {formEntryList?.cbd_b?.map((item: any, index: number) => (
                        <tr key={item.id}>
                            <td>{++index}</td>
                            <td>{moment(item?.date_of_assessment).format("DD-MMM-YYYY")}</td>
                            <td>
                                <button
                                    className="btn btn-sm btn-info"
                                    onClick={() => {
                                        modalShowHandler(true, item);
                                    }}
                                >
                                    <span className="fas fa-eye"></span> View
                                </button>
                            </td>
                        </tr>
                    ))
                    }
                </tbody>
            </Table>

            <Modal
                onOpened={function () {
                    toast.dismiss();
                }}
                isOpen={modalShow}
                toggle={() => {
                    document.body.classList.add('no_padding');
                }}
                backdrop={'static'}
                modalTransition={{ timeout: 0 }}
                backdropTransition={{ timeout: 0 }}
                size="xl"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        CBD Form B Informantion
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => {
                            setModalShow(!modalShow);
                        }}
                    ></button>
                </div>
                <div className="modal-body">
                    <Row className='mb-3'>
                        <Col>
                            <b>Date Of Assesment :</b> {dataInfo?.date_of_assessment}
                        </Col>
                        <Col>
                            <b>Diagnosis Of the Case :</b> {dataInfo?.diagnosis_of_the_case}
                        </Col>
                        <Col>
                            <b>Dificulty Level :</b> {dataInfo?.difficulty_level}
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col>
                            <b>Competences To be Covered :</b> {dataInfo?.competency_to_be_covered}
                        </Col>
                        <Col>
                            <b>Time Of Start :</b> {moment(dataInfo?.start_time, "HH:mm").format("hh:mm A")}
                        </Col>
                        <Col>
                            <b>Time Of Ending :</b> {moment(dataInfo?.end_time, "HH:mm").format("hh:mm A")}
                        </Col>
                    </Row>
                    
                    <Table className='table-bordered mt-2'>
                        <thead>
                            <tr>
                                <th>Discussion Area</th>
                                <th>Feedback</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataInfo?.cbdb_details?.map((info: any) => (
                                <tr key={info.id}>
                                    <td>
                                        {info?.discussion_area}
                                    </td>
                                    <td>
                                        {info?.feedback}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Modal>
        </div>
    );
};

export default CBDFormBInformation;
