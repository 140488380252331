import ExamTrainingFees from 'src/pages/Setup/ExamTrainingFee';
import AddExamTrainingFee from 'src/pages/Setup/ExamTrainingFee/AddExamTrainingFee';
import EditExamTrainingFee from 'src/pages/Setup/ExamTrainingFee/EditExamTrainingFee';

export const examTrainingFeeSetupRoutes = [
  // exam training fee setup
  {
    path: '/setup/exam-training-fee/',
    component: ExamTrainingFees,
  },
  {
    path: '/setup/exam-training-fee/update/:id',
    component: EditExamTrainingFee,
  },
  {
    path: '/setup/exam-training-fee/add',
    component: AddExamTrainingFee,
  },
];
