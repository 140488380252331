import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { putUrlString } from 'src/helpers';
import { useGetTransactionNoticeDetailQuery, useUpdateTransactionNoticeDetailsMutation } from 'src/rtk/features/setup/trnNoticeDetails/setupTrnNoticeDetails';
import { useGetTrnNoticeMasterListsQuery } from 'src/rtk/features/setup/trnNoticeMaster/setupTrnNoticeMaster';
import * as Yup from 'yup';

const initialValues: TransactionNoticeDetails = {
    id: '',
    notice_master_id: '',
    details: '',
    status: true,
    creator_organization_id: '',
    updater_organization_id: '',
    created_by: '',
    updated_by: '',
    deleted_by: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
};

export const EditTransactionNoticeDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { data: transactionNoticeDetails, isError: isTransactionNoticeDetailsFetchError } = useGetTransactionNoticeDetailQuery(id);
  const [updateTransactionNoticeDetails, { isSuccess, isError, error }] = useUpdateTransactionNoticeDetailsMutation();

  const { data } = useGetTrnNoticeMasterListsQuery();
  const noticeMasterList = data?.data || [];

  const err: CustomError = error as CustomError;
  const history = useHistory();
  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    values,
    setValues,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
        notice_master_id: Yup.string().required('Transaction Notice Details must be under a transaction notice master'),
    }),

    onSubmit: (values) => {
      const url = putUrlString(id, values);
      updateTransactionNoticeDetails(url);
    },
  });

  useEffect(() => {
    if (transactionNoticeDetails && !isTransactionNoticeDetailsFetchError) {
      const data: TransactionNoticeDetails = (transactionNoticeDetails as any).data;
      setValues({
        id: data.id,
        notice_master_id: data.notice_master_id,
        details: data.details,
        status: data.status,
        creator_organization_id: data.creator_organization_id,
        updater_organization_id: data.updater_organization_id,
        created_by: data.created_by,
        updated_by: data.updated_by,
        deleted_by: data.deleted_by,
        created_at: data.created_at,
        updated_at: data.updated_at,
        deleted_at: data.deleted_at,
      });
    }
  }, [transactionNoticeDetails, isTransactionNoticeDetailsFetchError]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Notice details updated successfully');
      history.push('/setup/trn-notice-details');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      const errors: any = {};

      if (err?.data) {
        Object.keys(err?.data).forEach((key) => {
          errors[key] = err?.data[key][0];
        });
      }
      setErrors(errors);
    }
  }, [isSuccess, isError]);

  const op: SelectOption[] = noticeMasterList?.map((d) => ({
    label: d.notice_subject!,
    value: d.id!,
  }))!;

//   op.push({ label: 'Select a Notice Master', value: '' });

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Edit Notice Details'}
          breadcrumbItem={[{ link: '/setup/trn-notice-details/', name: 'Notice Details List' }]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="card mt-3">
            <h5 className="card-header">
              <i className="fa fa-edit me-2" />
              Edit Notice Details
            </h5>
            <div className="card-body">
                <Form
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <div className="row">
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                      <FormGroup className="mb-3">
                        <Label for="notice_master_id">Notice Master</Label>
                        <InputField
                          type="select"
                          placeholder="Notice Master"
                          options={op}
                          invalid={{ errors, touched }}
                          {...getFieldProps('notice_master_id')}
                        />
                      </FormGroup>
                    </div>                

                    <div className='col-lg-4 col-md-6 col-sm-12'>
                      <FormGroup className="mb-3">
                        <Label for="details">Details</Label>
                        <InputField
                          type="textarea"
                          placeholder="Details"
                          invalid={{ errors, touched }}
                          {...getFieldProps('details')}
                        />
                      </FormGroup>
                    </div>                   

                    <div className='col-lg-4 col-md-6 col-sm-12'>
                      <FormGroup check className='mb-3 mt-4'>
                        <Label check>
                            <Input type="checkbox" {...getFieldProps('status')} checked={values.status} />{' '}
                            Status
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                  <Button type="submit" text="Submit" />
                </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
