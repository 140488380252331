import { apiSlice } from 'src/rtk/api/apiSlice';
import * as approvalDetailsUrl from 'src/helpers/url_helper';

export const approvalDetailsApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['ApprovalDetails'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getApprovalDetails: builder.query<APIResponse<ApprovalDetail[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${approvalDetailsUrl.GET_APPROVAL_DETAILS}`,
        }),
        providesTags: ['ApprovalDetails'],
      }),

      getApprovalDetail: builder.query<ApprovalDetail, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${approvalDetailsUrl.GET_APPROVAL_DETAIL}/${id}`,
        }),
        providesTags: ['ApprovalDetails'],
      }),

      createApprovalDetail: builder.mutation<
        APIResponse<ApprovalDetail>,
        ApprovalDetail
      >({
        query: (data) => ({
          url: `${approvalDetailsUrl.CREATE_APPROVAL_DETAIL}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['ApprovalDetails'],
      }),

      updateApprovalDetail: builder.mutation<
        APIResponse<ApprovalDetail>,
        string
      >({
        query: (url) => ({
          url: `${approvalDetailsUrl.UPDATE_APPROVAL_DETAIL}/${url}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['ApprovalDetails'],
      }),
      deleteApprovalDetail: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${approvalDetailsUrl.DELETE_APPROVAL_DETAIL}/${id}`,
        }),
        invalidatesTags: ['ApprovalDetails'],
      }),
    }),
  });

export const {
  useGetApprovalDetailsQuery,
  useGetApprovalDetailQuery,
  useCreateApprovalDetailMutation,
  useUpdateApprovalDetailMutation,
  useDeleteApprovalDetailMutation,
} = approvalDetailsApi;
