import { apiSlice } from 'src/rtk/api/apiSlice';
import * as logBookEntryMasterUrl from 'src/helpers/url_helper';

export const logBookEntryMasterApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['LogBookEntryMasters'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLogBookEntryMasters: builder.query<APIResponse<LogBookSetupMasters[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${logBookEntryMasterUrl.GET_LOGBOOK_ENTRY_MASTERS}`,
        }),
        providesTags: ['LogBookEntryMasters'],
      }),

      getLogBookEntryMaster: builder.query<LogBookSetupMasters, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${logBookEntryMasterUrl.GET_LOGBOOK_ENTRY_MASTER}/${id}`,
        }),
        providesTags: ['LogBookEntryMasters'],
      }),

      createLogBookEntryMaster: builder.mutation<APIResponse<LogBookSetupMasters>, LogBookSetupMasters>({
        query: (data) => ({
          url: `${logBookEntryMasterUrl.CREATE_LOGBOOK_ENTRY_MASTER}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['LogBookEntryMasters'],
      }),

      updateLogBookEntryMaster: builder.mutation<APIResponse<LogBookSetupMasters>, string>({
        query: (url) => ({
          url: `${logBookEntryMasterUrl.UPDATE_LOGBOOK_ENTRY_MASTER}/${url}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['LogBookEntryMasters'],
      }),
      deleteLogBookEntryMaster: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${logBookEntryMasterUrl.DELETE_LOGBOOK_ENTRY_MASTER}/${id}`,
        }),
        invalidatesTags: ['LogBookEntryMasters'],
      }),
    }),
  });

export const {
  useGetLogBookEntryMastersQuery,
  useGetLogBookEntryMasterQuery,
  useCreateLogBookEntryMasterMutation,
  useUpdateLogBookEntryMasterMutation,
  useDeleteLogBookEntryMasterMutation,
} = logBookEntryMasterApi;
