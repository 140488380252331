import { useFormik } from 'formik';
import React from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import PaginationComponent from 'src/components/Pagination/Pagination';
import Breadcrumbs from '../../components/Common/Breadcrumb';

const SupervisorList = () => {
  const supervisorSearchForm = useFormik({
    initialValues: {
      institute: '',
      fromDate: Date.now().toString(),
      toDate: Date.now().toString(),
      searchTitle: '',
    },
    // validationSchema: Yup.object({
    //   name: Yup.string().required("Name is required"),
    //   fatherOrHusbandName: Yup.string().required(
    //     "Father/Husband name is required"
    //   ),
    //   motherName: Yup.string().required("Mother name is required"),
    //   dateOfBirth: Yup.string().required("Date of birth is required"),
    //   nid: Yup.string().required("NID is required"),
    //   phone: Yup.string(),
    // }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Supervisor" breadcrumbItem="Supervisor List" />

          <Col sm={12} md={12} lg={12}>
            <Form
              onSubmit={supervisorSearchForm.handleSubmit}
              encType="multipart/form-data"
            >
              <Row>
                <Col sm={3} md={3} lg={3}>
                  <FormGroup className="mb-5">
                    <div className="mb-3">
                      <Label className="form-label">Institute</Label>
                      <Input
                        name="institute"
                        className="form-control"
                        placeholder="Select"
                        type="select"
                        onChange={supervisorSearchForm.handleChange}
                        onBlur={supervisorSearchForm.handleBlur}
                        value={supervisorSearchForm.values.institute || ''}
                        invalid={
                          supervisorSearchForm.touched.institute &&
                          !!supervisorSearchForm.errors.institute
                        }
                      >
                        <option value="0">Select</option>
                      </Input>
                      {supervisorSearchForm.touched.institute &&
                      supervisorSearchForm.errors.institute ? (
                        <FormFeedback type="invalid">
                          {supervisorSearchForm.errors.institute}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </FormGroup>
                </Col>

                <Col sm={3} md={3} lg={3}>
                  <FormGroup className="mb-5">
                    <div className="mb-3">
                      <Label className="form-label">From</Label>
                      <Input
                        name="fromDate"
                        className="form-control"
                        placeholder=""
                        type="date"
                        onChange={supervisorSearchForm.handleChange}
                        onBlur={supervisorSearchForm.handleBlur}
                        value={supervisorSearchForm.values.fromDate}
                      />
                    </div>
                  </FormGroup>
                </Col>

                <Col sm={3} md={3} lg={3}>
                  <FormGroup className="mb-5">
                    <div className="mb-3">
                      <Label className="form-label">To</Label>
                      <Input
                        name="toDate"
                        className="form-control"
                        placeholder=""
                        type="date"
                        onChange={supervisorSearchForm.handleChange}
                        onBlur={supervisorSearchForm.handleBlur}
                        value={supervisorSearchForm.values.toDate}
                      />
                    </div>
                  </FormGroup>
                </Col>

                <Col sm={3} md={3} lg={3}>
                  <FormGroup className="mb-5">
                    <div className="mb-3">
                      <Label className="form-label">Search</Label>
                      <Input
                        name="searchTitle"
                        className="form-control"
                        placeholder=""
                        type="search"
                        onChange={supervisorSearchForm.handleChange}
                        onBlur={supervisorSearchForm.handleBlur}
                        value={supervisorSearchForm.values.searchTitle}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>

          <Row>
            <Col sm={12} md={12} lg={12}>
              <Table bordered>
                <thead>
                  <tr>
                    <th>SL</th>
                    <th>Date</th>
                    <th>Name</th>
                    <th>BMDC Number</th>
                    <th>Qualification</th>
                    <th>Workshop Attended</th>
                    <th>Work Experience</th>
                    <th>Institute</th>
                    <th>Decision</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>04/05/2023</td>
                    <td>Dr. John Doe</td>
                    <td>1234567</td>
                    <td>PhD</td>
                    <td>
                      <li>WS01</li>
                      <li>WS02</li>
                    </td>
                    <td>6 Years</td>
                    <td>Dhaka Medical College</td>
                    <td>Approve</td>
                    <td>
                      <ul className="list-unstyled">
                        <li>
                          <a href="#">Approve</a>
                        </li>
                        <li>
                          <a href="#">Reject</a>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <Button className="me-4 btn-dark">
                        <i className="fa-solid fa-eye"></i>
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>04/05/2023</td>
                    <td>Dr. John Doe</td>
                    <td>1234567</td>
                    <td>PhD</td>
                    <td>
                      <li>WS01</li>
                      <li>WS02</li>
                    </td>
                    <td>6 Years</td>
                    <td>Dhaka Medical College</td>
                    <td>Approve</td>
                    <td>
                      <ul className="list-unstyled">
                        <li>
                          <a href="#">Approve</a>
                        </li>
                        <li>
                          <a href="#">Reject</a>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <Button className="me-4 btn-dark">
                        <i className="fa-solid fa-eye"></i>
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>04/05/2023</td>
                    <td>Dr. John Doe</td>
                    <td>1234567</td>
                    <td>PhD</td>
                    <td>
                      <li>WS01</li>
                      <li>WS02</li>
                    </td>
                    <td>6 Years</td>
                    <td>Dhaka Medical College</td>
                    <td>Approve</td>
                    <td>
                      <ul className="list-unstyled">
                        <li>
                          <a href="#">Approve</a>
                        </li>
                        <li>
                          <a href="#">Reject</a>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <Button className="me-4 btn-dark">
                        <i className="fa-solid fa-eye"></i>
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>04/05/2023</td>
                    <td>Dr. John Doe</td>
                    <td>1234567</td>
                    <td>PhD</td>
                    <td>
                      <li>WS01</li>
                      <li>WS02</li>
                    </td>
                    <td>6 Years</td>
                    <td>Dhaka Medical College</td>
                    <td>Approve</td>
                    <td>
                      <ul className="list-unstyled">
                        <li>
                          <a href="#">Approve</a>
                        </li>
                        <li>
                          <a href="#">Reject</a>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <Button className="me-4 btn-dark">
                        <i className="fa-solid fa-eye"></i>
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          <PaginationComponent />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SupervisorList;
