import { apiSlice } from 'src/rtk/api/apiSlice';

interface Paginate {
  page: number | string;
  paginate_per_page?: number;
  search?: string;
}

export const crudApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['CRUD', 'Comments-CRUD'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getData: builder.query<APIResponse<any>, any>({
        query: (queryUrl) => ({
          method: 'GET',
          url: queryUrl,
        }),
        providesTags: ['CRUD'],
      }),

      updateByGet: builder.mutation<APIResponse<any>, any>({
        query: (queryUrl) => ({
          url: queryUrl,
          method: 'GET',
          // body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        // invalidatesTags: ['CRUD'],
        invalidatesTags: (result, error) => error ? [] : ['CRUD'],
      }),


      getByData: builder.query<APIResponse<any>, any>({
        query: (data: any) => ({
          url: data.queryUrl,
          method: 'POST',
          body: data.formData,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
      }),

      createByPost: builder.mutation<APIResponse<any>, any>({
        query: (data: any) => ({
          url: data.queryUrl,
          method: 'POST',
          body: data.formData,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        // invalidatesTags: ['CRUD'],
        invalidatesTags: (result, error) => error ? [] : ['CRUD'],
      }),

      createByPostFormComments: builder.mutation<APIResponse<any>, any>({
        query: (data: any) => ({
          url: data.queryUrl,
          method: 'POST',
          body: data.formData,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: (result, error) => error ? [] : ['Comments-CRUD'],
      }),

      updateByPatch: builder.mutation<APIResponse<any>, any>({
        query: (queryUrl: any) => ({
          url: queryUrl,
          method: 'PATCH',
        }),
        invalidatesTags: ['CRUD'],
      }),

      updateByPut: builder.mutation<APIResponse<any>, any>({
        query: (queryUrl: any) => ({
          url: queryUrl,
          method: 'PUT',
        }),
        invalidatesTags: ['CRUD'],
      }),

      deleteById: builder.mutation({
        query: (queryUrl) => ({
          method: 'DELETE',
          url: queryUrl,
        }),
        // invalidatesTags: ['CRUD'],
        invalidatesTags: (result, error) => error ? [] : ['CRUD'],
      }),

      getDataBy: builder.mutation<APIResponse<any>, any>({
        query: (queryUrl) => ({
          method: 'GET',
          url: queryUrl,
        }),
      }),

      getCommentsDataBy: builder.mutation<APIResponse<any>, any>({
        query: (queryUrl) => ({
          method: 'GET',
          url: queryUrl,
        }),
      }),


    }),
  });

export const {
  useGetDataQuery,
  useGetByDataQuery,
  useCreateByPostMutation,
  useCreateByPostFormCommentsMutation,
  useUpdateByPatchMutation,
  useUpdateByPutMutation,
  useUpdateByGetMutation,
  useDeleteByIdMutation,
  useGetDataByMutation,
  useGetCommentsDataByMutation,
} = crudApi;
