import { Button, Container } from "reactstrap";

const ServerError = () => {

    return (
        <div className="server-error-container vh-100">
            <Container className="text-center">
                <h1 className="display-1">500</h1>
                <h2>Internal Server Error</h2>
                <p className="lead">
                    Something went wrong on our end. Please try again later.
                </p>
                <div className="animation">
                    <img src="https://media.tenor.com/eDchk3srtycAAAAi/piffle-error.gif" alt="Funny Animation" />
                </div>
                <Button variant="primary" href="/">
                    Go to Homepage
                </Button>
            </Container>
        </div>
    );
};
export default ServerError;
