import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import store from 'src/rtk/app/store';
import { useGetMyRegInfoQuery } from 'src/rtk/features/registration-approval/registrationApprovalApi';
import ProfileView from './ProfileView';

interface Props {
  isOpen: boolean;
  toggle: any;
  trainingId: string;
  attachments: any;
}
const InstructionDeatailsModal = (props: Props) => {
  const { isOpen, toggle, trainingId, attachments } = props;
  const reg_no = store.getState().auth.user.registration_no;

  const { data, isLoading } = useGetMyRegInfoQuery(reg_no);
  const userInfo = data?.data || ({} as RegInfo);

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="lg">
      <ModalHeader toggle={toggle}>Profile View</ModalHeader>

      <ModalBody>
        <div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="row justify-content-between">
                <ProfileView
                  userInfo={userInfo}
                  trainingId={trainingId}
                  attachments={attachments}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <Button color="danger" onClick={toggle}>
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default InstructionDeatailsModal;
