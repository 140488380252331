import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { InputField } from 'src/components';
import { putUrlString } from 'src/helpers';
import { useUpdateLookupChildMutation } from 'src/rtk/features/setup/lookup/lookupChildApi';
import { useGetLookupInfosQuery } from 'src/rtk/features/setup/lookup/lookupInfoApi';

interface Props {
  isOpen: boolean;
  toggle: any;
  initialValues: LookupChild;
}

const EditModal = (props: Props) => {
  const { isOpen, toggle, initialValues } = props;
  const [updateLookUp, { isSuccess, isError, error }] =
    useUpdateLookupChildMutation();

  const { data: lookupInfo } = useGetLookupInfosQuery();
  const err: CustomError = error as CustomError;

  const options: { value: string; label: string }[] = [
    { value: '', label: 'Select Lookup Info' },
  ];

  lookupInfo?.data?.map((lookup) => {
    options.push({ value: lookup.id!, label: lookup.lookup_name });
  });

  const { values, getFieldProps, handleSubmit, resetForm } = useFormik({
    initialValues,
    onSubmit: (values) => {
      const newLookup = { ...values, lookup_name: values.lookup_list_name };
      const url = putUrlString(values.id, newLookup);

      updateLookUp(url);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Lookup Item updated successfully');
      isOpen && toggle();
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
    }
  }, [isSuccess, isError]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Data</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
          console.log(values);
        }}
      >
        <ModalBody>
          <div>
            <FormGroup className="mb-3">
              <Label for="setup_lookup_info_id">Lookup Info ID</Label>
              <Select
                
                isSearchable={true}
                options={options}
                name="setup_lookup_info_id"
                value={options.find(
                  (option) => option.value === values.setup_lookup_info_id,
                )}
              />
            </FormGroup>

            <FormGroup className="mb-3">
              <Label for="lookup_list_name">Lookup List Name</Label>
              <InputField
                type="text"
                placeholder="Lookup List Name"
                {...getFieldProps('lookup_list_name')}
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="serial_no">Serial No</Label>
              <InputField
                type="number"
                placeholder="Serial No"
                {...getFieldProps('serial_no')}
              />
            </FormGroup>

            <FormGroup switch className="mb-3">
              <Input
                type="switch"
                role="switch"
                placeholder="Status"
                {...getFieldProps('status')}
              />
              <Label for="status">Active Status</Label>
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary">
            Save
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditModal;
