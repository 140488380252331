import moment from 'moment';
import { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import {
    Card,
    CardBody,
    Col,
    Container,
    Modal,
    Row
} from 'reactstrap';
import { Button } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetTraineeAssessmentListQuery } from 'src/rtk/features/miniCexCBD/miniCexAndCBDApi';
import loader from '../../assets/images/loader.gif';
import UpdatedMiniCexFormEntry from './UpdatedMiniCexFormEntry';


const MiniCEXTraineeList = () => {
    document.title = 'Mini Clinical Evaluation Exercise (Mini CEX) | BCPS';

    const [miniCEXEntryModalShow, setMiniCEXEntryModalShow] = useState<boolean>(false);
    const [viewModalShow, setViewModalShow] = useState<boolean>(false);
    const [miniCexEntryToView, setMiniCexEntryToView] = useState<any>({});

    const {
        data: traineeAssessmentList,
        error: traineeAssessmentListError,
        isLoading: isTraineeAssessmentListLoading,
        isError: isTraineeAssessmentListError,
        isSuccess: isTraineeAssessmentListSuccess,
        isFetching: isTraineeAssessmentListFetching,
    } = useGetTraineeAssessmentListQuery({ type: 'mini-cex' });

    const miniCEXTraineeList: any = traineeAssessmentList?.data[0]?.mini_cex_trainee;

    const createNewModalHandler = (modalState: boolean) => {
        setMiniCEXEntryModalShow(modalState);
    };

    const viewModalHandler = (modalState: boolean, miniCexEntry: any) => {
        setViewModalShow(modalState);
        setMiniCexEntryToView(miniCexEntry);
    };

    const columns: TableColumn<any>[] = [
        {
            name: <div>Sl. No.</div>,
            width: '60px',
            cell: (row, index) => index + 1,
        },

        {
            name: <div>Patient Name</div>,
            selector: (row) => row?.patient_name ?? '',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Patient Reg. No.</div>,
            selector: (row) => row?.patient_reg_no ?? '',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Patient Address</div>,
            selector: (row) => row?.patient_address ?? '',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Patient Gender</div>,
            selector: (row) => row?.patient_gender ?? '',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Complexity Level</div>,
            selector: (row) => row?.complexity_level ?? '',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Diagnosis of the Case</div>,
            selector: (row) => row?.diagnosis_of_the_case ?? '',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Competency Setting</div>,
            selector: (row) => row?.competency_setting ?? '',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Date of Assessment</div>,
            selector: (row) => moment(row?.date_of_assessment).isValid() ?
                moment(row?.date_of_assessment).format('DD/MM/YYYY') : '',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Case Description</div>,
            selector: (row) => row?.case_description ?? '',
            sortable: false,
            wrap: true,
        },

        {
            name: 'Action',
            width: '150px',
            cell: (row) => (
                <>
                    <div className="d-flex gap-1 my-1">
                        <Button
                            size="sm"
                            color="info"
                            type="button"
                            text={`View`}
                            onClick={() => {
                                viewModalHandler(true, row);
                            }}
                        />
                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
        },
    ];

    return (
        <div className="page-content">
            {isTraineeAssessmentListFetching ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Container fluid>
                <div className='page-breadcrumb-wrapper'>
                    <div>
                        <BetterBreadcrumb
                            title={'Mini Clinical Evaluation Exercise (Mini-CEX)'}
                            breadcrumbItem={[]}
                        />
                    </div>
                    <div>
                        <Button
                            type="button"
                            color="primary"
                            text="Create New"
                            onClick={(e) => {
                                createNewModalHandler(true);
                            }}
                        />
                    </div>
                </div>

                <Card className="mb-3">
                    <CardBody>
                        <DataTable
                            columns={columns}
                            data={miniCEXTraineeList?.length ? miniCEXTraineeList : []}
                            progressPending={isTraineeAssessmentListFetching}
                            expandOnRowClicked
                            highlightOnHover
                            persistTableHead
                        />
                    </CardBody>
                </Card>

                <Modal
                    onOpened={function () {
                        toast.dismiss();
                    }}
                    isOpen={miniCEXEntryModalShow}
                    scrollable={true}
                    size={'xl'}
                    // fullscreen={true}
                    toggle={() => {
                        document.body.classList.add('no_padding');
                    }}
                    backdrop={'static'}
                    modalTransition={{ timeout: 0 }}
                    backdropTransition={{ timeout: 0 }}
                >
                    <div className="modal-header bg-light">
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => {
                                setMiniCEXEntryModalShow(!miniCEXEntryModalShow);
                            }}
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col>
                                <UpdatedMiniCexFormEntry setMiniCEXEntryModalShow={setMiniCEXEntryModalShow} />
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer">
                        <Button
                            className='ms-2'
                            size='md'
                            color='danger'
                            type='button'
                            text={`<span class='fas fa-times' ></span> Close`}
                            onClick={() => {
                                setMiniCEXEntryModalShow(!miniCEXEntryModalShow);
                            }}
                        />
                    </div>
                </Modal>

                <Modal
                    onOpened={function () {
                        toast.dismiss();
                    }}
                    isOpen={viewModalShow}
                    scrollable={true}
                    size={'xl'}
                    // fullscreen={true}
                    toggle={() => {
                        document.body.classList.add('no_padding');
                    }}
                    backdrop={'static'}
                    modalTransition={{ timeout: 0 }}
                    backdropTransition={{ timeout: 0 }}
                >
                    <div className="modal-header bg-light">
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => {
                                setViewModalShow(!viewModalShow);
                            }}
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col>
                                {miniCexEntryToView?.id && <UpdatedMiniCexFormEntry view={true} miniCEXTraineeInfo={miniCexEntryToView} />}
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer">
                        <Button
                            className='ms-2'
                            size='md'
                            color='danger'
                            type='button'
                            text={`<span class='fas fa-times' ></span> Close`}
                            onClick={() => {
                                setViewModalShow(!viewModalShow);
                            }}
                        />
                    </div>
                </Modal>
            </Container>
        </div>
    );
};

export default MiniCEXTraineeList;
