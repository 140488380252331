import { apiSlice } from 'src/rtk/api/apiSlice';
import * as traineeFcpsCourseListUrl from 'src/helpers/url_helper';

export const traineeFcpsCourseListApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['TraineeFcpsCourseList', 'SupervisorList'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getFcpsCourseLists: builder.mutation<
        APIResponse<FcpsCourseList[]>,
        string
      >({
        query: (courseType: string) => ({
          url: `${traineeFcpsCourseListUrl.GET_FCPS_COURSE_LISTS}`,
          method: 'POST',
          body: {type: courseType},
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['TraineeFcpsCourseList'],
      }),

      getFcpsCourseList: builder.mutation<
        APIResponse<FcpsCourseList[]>,
        {type: string; name: string}
      >({
        query: (obj) => ({
          url: `${traineeFcpsCourseListUrl.GET_FCPS_COURSE_LIST}`,
          method: 'POST',
          body: obj,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['TraineeFcpsCourseList'],
      }),

      getFcpsCourseListById: builder.query<APIResponse<FcpsCourseList>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${traineeFcpsCourseListUrl.GET_FCPS_COURSE_LIST_BY_ID}/${id}`,
        }),

        providesTags: ['TraineeFcpsCourseList'],
      }),

      getSupervisors: builder.query<APIResponse<Supervisors[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${traineeFcpsCourseListUrl.GET_SUPERVISORS}`,
        }),

        providesTags: ['SupervisorList'],
      }),
    }),
  });

export const {
  useGetFcpsCourseListMutation,
  useGetFcpsCourseListsMutation,
  useGetFcpsCourseListByIdQuery,
  useLazyGetFcpsCourseListByIdQuery,
  useGetSupervisorsQuery,
} = traineeFcpsCourseListApi;
