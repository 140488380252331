import { useFormik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import {
    Button as BsButton,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from 'reactstrap';
import { Button } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { actionColumnStyles, dataTableCustomStyles } from 'src/components/Common/FixedActionColumn';
import { config } from 'src/config';
import { useGetTitleProtocolThesisDissertationListQuery } from 'src/rtk/features/disertation-thesis/listOfTitleProtocolThesisApi';
import loader from '../../../assets/images/loader.gif';
import ReviewerComments from '../DissertationListForAssigningReviewer/ReviewerComments';
import ChangeRequestModal from '../TitleEntry/ChangeRequestModal';
import TitleEntry from '../TitleEntry/form';
import ApprovalForGuideCoGuide from '../TitleList/ApprovalForGuideCoGuide';

interface SearchFields {
    year_of_submission?: string;
    subject_name?: string;
}

const initialValues: SearchFields = {
    year_of_submission: '',
    subject_name: '',
};

const ListOfTitleProtocolAndThesis = () => {
    document.title = 'Thesis / Dissertation Management | BCPS';
    const [page, setPage] = useState<string | number>('1');
    const [paginate_per_page, set_paginate_per_page] = useState(15);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [viewTitleInfo, setViewTitleInfo] = useState<any>({});
    const [changeRequestModalShow, setChangeRequestModalShow] = useState<boolean>(false);
    const [titleEditBtnStatus, setTitleEditBtnStatus] = useState<boolean>(false);
    const [titleForEdit, setTitleForEdit] = useState<any>({});

    const {
        data: allAboutThesisForTraineeData,
        error: allAboutThesisForTraineeDataError,
        isLoading: isAllAboutThesisForTraineeDataLoading,
        isError: isAllAboutThesisForTraineeDataError,
    } = useGetTitleProtocolThesisDissertationListQuery();

    const titleList = allAboutThesisForTraineeData?.data?.title || [];
    const protocolList = allAboutThesisForTraineeData?.data?.protocol || [];
    const thesisDissertationList = allAboutThesisForTraineeData?.data?.thesis_dissertation || [];

    const [commentModalShow, setCommentModalShow] = useState<boolean>(false);
    const [commentThesisId, setCommentThesisId] = useState<string>('');

    const commentModalShowHandler = (modalState: boolean, thesisId: string) => {
        setCommentModalShow(modalState);
        setCommentThesisId(thesisId);
    };

    const viewTitleModalHandler = (showModal: boolean, title: any) => {
        setShowModal(showModal);
        setViewTitleInfo(title);
    };

    const changeRequestModalHandler = (showModal: boolean, title: any) => {
        setChangeRequestModalShow(showModal);
        setViewTitleInfo(title);
    };

    const titleListColumns: TableColumn<any>[] = [
        {
            name: <div>Sl. No.</div>,
            width: '80px',
            cell: (row, index) => index + 1,
        },

        {
            name: <div>Category</div>,
            selector: (row) => row.category,
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Title</div>,
            selector: (row) => row.title_of_thesis,
            sortable: false,
            wrap: true,
            width: '200px',
        },

        {
            name: <div>Place of Study</div>,
            selector: (row) => row.place_of_study,
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Study Period</div>,
            selector: (row) => row.study_period && row.study_period_unit ? row.study_period + ' ' + row.study_period_unit : '-',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Co-Guide 1 Approval Status</div>,
            selector: (row) => row.co_guide1_approval_status ?? '-',
            cell: (row) => (
                <>
                    {
                        row?.co_guide1_approval_status === 'Approve' &&
                        (<div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>
                            {'Accepted'}
                        </div>)
                    }
                    {
                        row?.co_guide1_approval_status === 'Reject' &&
                        (<div className='badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium'>
                            {'Rejected'}
                        </div>)
                    }
                    {
                        row?.co_guide1_approval_status === 'Correction' &&
                        (<div className='badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium'>
                            {row?.co_guide1_approval_status}
                        </div>)
                    }
                    {
                        row?.co_guide1_approval_status === null &&
                        (<div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>
                            N/A
                        </div>)
                    }

                </>
            ),
            sortable: false,
            wrap: true,
            width: '200px',
        },

        {
            name: <div>Co-Guide 2 Approval Status</div>,
            selector: (row) => row.co_guide2_approval_status ?? '-',
            cell: (row) => (
                <>
                    {
                        row?.co_guide2_approval_status === 'Approve' &&
                        (<div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>
                            {'Accepted'}
                        </div>)
                    }
                    {
                        row?.co_guide2_approval_status === 'Reject' &&
                        (<div className='badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium'>
                            {'Rejected'}
                        </div>)
                    }
                    {
                        row?.co_guide2_approval_status === 'Correction' &&
                        (<div className='badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium'>
                            {row?.co_guide2_approval_status}
                        </div>)
                    }
                    {
                        row?.co_guide2_approval_status === null &&
                        (<div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>
                            N/A
                        </div>)
                    }

                </>
            ),
            sortable: false,
            wrap: true,
            width: '200px',
        },

        {
            name: <div>Guide Approval Status</div>,
            selector: (row) => row.guide_approval_status ?? '-',
            cell: (row) => (
                <>
                    {
                        row?.guide_approval_status === 'Approve' &&
                        (<div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>
                            {'Accepted'}
                        </div>)
                    }
                    {
                        row?.guide_approval_status === 'Reject' &&
                        (<div className='badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium'>
                            {'Rejected'}
                        </div>)
                    }
                    {
                        row?.guide_approval_status === 'Correction' &&
                        (<div className='badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium'>
                            {row?.guide_approval_status}
                        </div>)
                    }
                    {
                        row?.guide_approval_status === null &&
                        (<div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>
                            N/A
                        </div>)
                    }

                </>
            ),
            sortable: false,
            wrap: true,
        },

        // {
        //     name: <div>Admin Approval Status</div>,
        //     selector: (row) => row.admin_approval_status ?? '-',
        //     cell: (row) => (
        //         <>
        //             {
        //                 row?.admin_approval_status === 'Approve' &&
        //                 (<div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>
        //                     {'Accepted'}
        //                 </div>)
        //             }
        //             {
        //                 row?.admin_approval_status === 'Reject' &&
        //                 (<div className='badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium'>
        //                     {'Rejected'}
        //                 </div>)
        //             }
        //             {
        //                 row?.admin_approval_status === 'Correction' &&
        //                 (<div className='badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium'>
        //                     {row?.admin_approval_status}
        //                 </div>)
        //             }
        //             {
        //                 row?.admin_approval_status === null &&
        //                 (<div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>
        //                     N/A
        //                 </div>)
        //             }
        //         </>
        //     ),
        //     sortable: false,
        //     wrap: true,
        // },

        {
            name: <div>Submission Status</div>,
            selector: (row) => row.submission_status ?? '-',
            cell: (row) => (
                <>
                    {/* {
                        row?.submission_status === 'Approve' &&
                        (<div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>
                            {'Accepted'}
                        </div>)
                    }
                    {
                        row?.submission_status === 'Reject' &&
                        (<div className='badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium'>
                            {'Rejected'}
                        </div>)
                    }
                    {
                        (row?.submission_status === 'Correction' || row?.submission_status === 'Draft') &&
                        (<div className='badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium'>
                            {row?.submission_status}
                        </div>)
                    } */}
                    {((row?.guide_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit') ||
                        (row?.co_guide1_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit') ||
                        (row?.co_guide2_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit') ||
                        (row?.co_guide3_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit')
                    )
                        ? (
                            <div className="badge rounded-pill d-inline-block badge-applied font-size-12 fw-medium">
                                {'Resubmitted'}
                            </div>
                        ) :
                        <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                            {row?.submission_status === 'Approve' ?
                                <span className='text-success'>Approved</span>
                                :
                                row?.submission_status === 'Submit' ?
                                    <span className='text-warning'>Submitted</span>
                                    :
                                    row?.submission_status === 'Reject' ?
                                        <span className='text-danger'>Rejected</span>
                                        :
                                        <span className='text-info'>{row?.submission_status}</span>}
                        </div>
                    }
                    {
                        row?.submission_status === null &&
                        (<div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>
                            N/A
                        </div>)
                    }
                </>
            ),
            sortable: false,
            wrap: true,
            width: '200px',
        },

        {
            name: 'Action',
            width: '250px',
            style: actionColumnStyles,
            cell: (row) => (
                <>
                    {/* btn-group-example */}
                    <div className="d-flex gap-1 my-1">
                        <Button
                            size="sm"
                            color="info"
                            type="button"
                            text={`<i class='fas fa-eye'></i> View`}
                            onClick={() => {
                                viewTitleModalHandler(true, row);
                            }}
                        />
                        {row?.['submission_status'] &&
                            row?.['submission_status']?.toLowerCase() ===
                            'draft' && (
                                <BsButton
                                    type='button'
                                    className='btn btn-success btn-sm'
                                    onClick={(e) => {
                                        setTitleEditBtnStatus(true);
                                        setTitleForEdit(row);
                                    }}
                                    disabled={row?.['admin_approval_status'] === 'Reject'
                                        || row?.['co_guide1_approval_status'] === 'Reject'
                                        || row?.['co_guide2_approval_status'] === 'Reject'
                                        || row?.['co_guide3_approval_status'] === 'Reject'
                                    }
                                >
                                    <i className="fas fa-edit" /> {'Edit'}
                                </BsButton>
                            )}
                        {row?.admin_approval_status?.toLowerCase() === 'approve' &&
                            <Button
                                size="sm"
                                color="success"
                                type="button"
                                text={`<i class='fas fa-edit'></i> Change Request`}
                                // disabled={['Approve', 'Reject', 'Submit'].includes(row?.submission_status)}
                                onClick={() => {
                                    changeRequestModalHandler(true, row);
                                }}
                            />}
                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
        },
    ];

    const protocolListColumns: TableColumn<any>[] = [
        {
            name: <div>Sl. No.</div>,
            width: '80px',
            cell: (row, index) => index + 1,
        },

        {
            name: <div>BMDC Reg. No.</div>,
            selector: (row) => row.bmdc_registration_no ?? '-',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Name of the Researcher</div>,
            selector: (row) => row.name_of_the_researcher ?? '-',
            sortable: false,
            wrap: true,
            width: '200px',
        },

        {
            name: <div>Faculty</div>,
            selector: (row) => row.relevant_faculty ?? '-',
            sortable: false,
            wrap: true,
            width: '200px',
        },

        {
            name: <div>Discipline</div>,
            selector: (row) => row.relevant_discipline ?? '-',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Place of Study</div>,
            selector: (row) => row.place_of_study ?? '-',
            sortable: false,
            wrap: true,
            width: '200px',
        },

        {
            name: <div>Protocol No.</div>,
            selector: (row) => row.protocol_no ?? '-',
            sortable: false,
            wrap: true,
            width: '200px',
        },

        {
            name: <div>Protocol Submission Date</div>,
            selector: (row) => moment(row.protocol_submission_date).isValid() ? moment(row.protocol_submission_date).format('DD-MMM-YYYY') : '-',
            sortable: false,
            wrap: true,
            width: '200px',
        },

        {
            name: <div>Co-Guide 1 Approval Status</div>,
            selector: (row) => row.co_guide1_approval_status ?? '-',
            cell: (row) => (
                <>
                    {
                        row?.co_guide1_approval_status === 'Approve' &&
                        (<div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>
                            {'Accepted'}
                        </div>)
                    }
                    {
                        row?.co_guide1_approval_status === 'Reject' &&
                        (<div className='badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium'>
                            {'Rejected'}
                        </div>)
                    }
                    {
                        row?.co_guide1_approval_status === 'Correction' &&
                        (<div className='badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium'>
                            {row?.co_guide1_approval_status}
                        </div>)
                    }
                    {
                        row?.co_guide1_approval_status === null &&
                        (<div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>
                            N/A
                        </div>)
                    }

                </>
            ),
            sortable: false,
            wrap: true,
            width: '200px',
        },

        {
            name: <div>Co-Guide 2 Approval Status</div>,
            selector: (row) => row.co_guide2_approval_status ?? '-',
            cell: (row) => (
                <>
                    {
                        row?.co_guide2_approval_status === 'Approve' &&
                        (<div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>
                            {'Accepted'}
                        </div>)
                    }
                    {
                        row?.co_guide2_approval_status === 'Reject' &&
                        (<div className='badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium'>
                            {'Rejected'}
                        </div>)
                    }
                    {
                        row?.co_guide2_approval_status === 'Correction' &&
                        (<div className='badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium'>
                            {row?.co_guide2_approval_status}
                        </div>)
                    }
                    {
                        row?.co_guide2_approval_status === null &&
                        (<div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>
                            N/A
                        </div>)
                    }

                </>
            ),
            sortable: false,
            wrap: true,
            width: '200px',
        },

        {
            name: <div>Guide Approval Status</div>,
            selector: (row) => row.guide_approval_status ?? '-',
            cell: (row) => (
                <>
                    {
                        row?.guide_approval_status === 'Approve' &&
                        (<div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>
                            {'Accepted'}
                        </div>)
                    }
                    {
                        row?.guide_approval_status === 'Reject' &&
                        (<div className='badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium'>
                            {'Rejected'}
                        </div>)
                    }
                    {
                        row?.guide_approval_status === 'Correction' &&
                        (<div className='badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium'>
                            {row?.guide_approval_status}
                        </div>)
                    }
                    {
                        row?.guide_approval_status === null &&
                        (<div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>
                            N/A
                        </div>)
                    }

                </>
            ),
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Admin Approval Status</div>,
            selector: (row) => row.admin_approval_status ?? '-',
            cell: (row) => (
                <>
                    {
                        row?.admin_approval_status === 'Approve' &&
                        (<div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>
                            {'Accepted'}
                        </div>)
                    }
                    {
                        row?.admin_approval_status === 'Reject' &&
                        (<div className='badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium'>
                            {'Rejected'}
                        </div>)
                    }
                    {
                        row?.admin_approval_status === 'Correction' &&
                        (<div className='badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium'>
                            {row?.admin_approval_status}
                        </div>)
                    }
                    {
                        row?.admin_approval_status === 'Pending' &&
                        (<div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>
                            {row?.admin_approval_status}
                        </div>)
                    }
                    {
                        row?.admin_approval_status === null &&
                        (<div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>
                            N/A
                        </div>)
                    }
                </>
            ),
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Submission Status</div>,
            selector: (row) => row.submission_status ?? '-',
            cell: (row) => (
                <>
                    {/* {
                        row?.submission_status === 'Approve' &&
                        (<div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>
                            {'Accepted'}
                        </div>)
                    }
                    {
                        row?.submission_status === 'Reject' &&
                        (<div className='badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium'>
                            {'Rejected'}
                        </div>)
                    }
                    {
                        (row?.submission_status === 'Correction' || row?.submission_status === 'Draft') &&
                        (<div className='badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium'>
                            {row?.submission_status}
                        </div>)
                    } */}
                    {((row?.admin_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit') ||
                        (row?.guide_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit') ||
                        (row?.co_guide1_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit') ||
                        (row?.co_guide2_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit') ||
                        (row?.co_guide3_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit')
                    )
                        ? (
                            <div className="badge rounded-pill d-inline-block badge-applied font-size-12 fw-medium">
                                {'Resubmitted'}
                            </div>
                        ) :
                        <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                            {row?.submission_status === 'Approve' ?
                                <span className='text-success'>Approved</span>
                                :
                                row?.submission_status === 'Submit' ?
                                    <span className='text-warning'>Submitted</span>
                                    :
                                    row?.submission_status === 'Reject' ?
                                        <span className='text-danger'>Rejected</span>
                                        :
                                        <span className='text-info'>{row?.submission_status}</span>}
                        </div>
                    }
                    {
                        row?.submission_status === null &&
                        (<div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>
                            N/A
                        </div>)
                    }
                </>
            ),
            sortable: false,
            wrap: true,
            width: '200px',
        },

        {
            name: 'Action',
            width: '200px',
            style: actionColumnStyles,
            cell: (row) => (
                <>
                    {/* btn-group-example */}
                    <div className="d-flex gap-1 my-1">
                        <Button
                            size="sm"
                            color="info"
                            type="button"
                            text={`<i class='fas fa-eye'></i> View`}
                            onClick={() => {
                                window.open(`/protocol-entry-view/${row.id}`, '_blank');
                            }}
                        />
                        <Button
                            size="sm"
                            color="success"
                            type="button"
                            text={`<i class='fas fa-edit'></i> Edit`}
                            disabled={['Approve', 'Reject', 'Submit'].includes(row?.submission_status)}
                            onClick={() => {
                                window.open('/thesis-dissertation-protocol-entry', '_blank');
                            }}
                        />
                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
        },
    ];

    const thesisDissertationListColumns: TableColumn<any>[] = [
        {
            name: <div>Sl. No.</div>,
            width: '80px',
            cell: (row, index) => index + 1,
        },

        {
            name: <div>Name of the Researcher</div>,
            selector: (row) => row.trainee_name ?? '-',
            sortable: false,
            wrap: true,
            width: '200px',
        },

        {
            name: <div>Subject</div>,
            selector: (row) => row.subject_name ?? '-',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Category</div>,
            selector: (row) => row.document_type ?? '-',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Thesis / Dissertation File</div>,
            selector: (row) => row.document_name ?? '-',
            cell: (row) => (
                <>
                    {
                        row.document_name ?
                            <a
                                className="me-2 p-0 center"
                                href={`${config?.APP_URL}${row.document_name}`}
                                rel="noreferrer"
                                target="_blank"
                            >
                                <span className="btn-outline-info btn-sm btn ms-2">View</span>
                            </a>
                            :
                            '-'
                    }
                </>
            ),
            sortable: false,
            wrap: true,
            width: '200px',
        },

        {
            name: <div>Co-Guide 1 Approval Status</div>,
            selector: (row) => row.co_guide1_approval_status ?? '-',
            cell: (row) => (
                <>
                    {
                        row?.co_guide1_approval_status === 'Approve' &&
                        (<div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>
                            {'Accepted'}
                        </div>)
                    }
                    {
                        row?.co_guide1_approval_status === 'Reject' &&
                        (<div className='badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium'>
                            {'Rejected'}
                        </div>)
                    }
                    {
                        row?.co_guide1_approval_status === 'Correction' &&
                        (<div className='badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium'>
                            {row?.co_guide1_approval_status}
                        </div>)
                    }
                    {
                        row?.co_guide1_approval_status === null &&
                        (<div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>
                            N/A
                        </div>)
                    }

                </>
            ),
            sortable: false,
            wrap: true,
            width: '200px',
        },

        {
            name: <div>Co-Guide 2 Approval Status</div>,
            selector: (row) => row.co_guide2_approval_status ?? '-',
            cell: (row) => (
                <>
                    {
                        row?.co_guide2_approval_status === 'Approve' &&
                        (<div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>
                            {'Accepted'}
                        </div>)
                    }
                    {
                        row?.co_guide2_approval_status === 'Reject' &&
                        (<div className='badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium'>
                            {'Rejected'}
                        </div>)
                    }
                    {
                        row?.co_guide2_approval_status === 'Correction' &&
                        (<div className='badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium'>
                            {row?.co_guide2_approval_status}
                        </div>)
                    }
                    {
                        row?.co_guide2_approval_status === null &&
                        (<div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>
                            N/A
                        </div>)
                    }

                </>
            ),
            sortable: false,
            wrap: true,
            width: '200px',
        },

        {
            name: <div>Guide Approval Status</div>,
            selector: (row) => row.guide_approval_status ?? '-',
            cell: (row) => (
                <>
                    {
                        row?.guide_approval_status === 'Approve' &&
                        (<div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>
                            {'Accepted'}
                        </div>)
                    }
                    {
                        row?.guide_approval_status === 'Reject' &&
                        (<div className='badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium'>
                            {'Rejected'}
                        </div>)
                    }
                    {
                        row?.guide_approval_status === 'Correction' &&
                        (<div className='badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium'>
                            {row?.guide_approval_status}
                        </div>)
                    }
                    {
                        row?.guide_approval_status === null &&
                        (<div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>
                            N/A
                        </div>)
                    }

                </>
            ),
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Admin Approval Status</div>,
            selector: (row) => row.admin_approval_status ?? '-',
            cell: (row) => (
                <>
                    {
                        row?.admin_approval_status === 'Approve' &&
                        (<div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>
                            {'Accepted'}
                        </div>)
                    }
                    {
                        row?.admin_approval_status === 'Reject' &&
                        (<div className='badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium'>
                            {'Rejected'}
                        </div>)
                    }
                    {
                        row?.admin_approval_status === 'Correction' &&
                        (<div className='badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium'>
                            {row?.guide_approval_status}
                        </div>)
                    }
                    {
                        row?.admin_approval_status === null &&
                        (<div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>
                            N/A
                        </div>)
                    }
                </>
            ),
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Submission Date</div>,
            selector: (row) => moment(row.submission_date).isValid() ? moment(row.submission_date).format('DD-MMM-YYYY') : '-',
            sortable: false,
            wrap: true,
            width: '200px',
        },

        {
            name: <div>Submission Status</div>,
            selector: (row) => row.submission_status ?? '-',
            cell: (row) => (
                <>
                    {/* {
                        row?.submission_status === 'Approve' &&
                        (<div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>
                            {'Accepted'}
                        </div>)
                    }
                    {
                        row?.submission_status === 'Reject' &&
                        (<div className='badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium'>
                            {'Rejected'}
                        </div>)
                    }
                    {
                        (row?.submission_status === 'Correction' || row?.submission_status === 'Draft') &&
                        (<div className='badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium'>
                            {row?.submission_status}
                        </div>)
                    } */}
                    {((row?.admin_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit') ||
                        (row?.guide_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit') ||
                        (row?.co_guide1_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit') ||
                        (row?.co_guide2_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit') ||
                        (row?.co_guide3_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit')
                    )
                        ? (
                            <div className="badge rounded-pill d-inline-block badge-applied font-size-12 fw-medium">
                                {'Resubmitted'}
                            </div>
                        ) :
                        <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                            {row?.submission_status === 'Approve' ?
                                <span className='text-success'>Approved</span>
                                :
                                row?.submission_status === 'Submit' ?
                                    <span className='text-warning'>Submitted</span>
                                    :
                                    row?.submission_status === 'Reject' ?
                                        <span className='text-danger'>Rejected</span>
                                        :
                                        <span className='text-info'>{row?.submission_status}</span>}
                        </div>
                    }
                    {
                        row?.submission_status === null &&
                        (<div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>
                            N/A
                        </div>)
                    }
                </>
            ),
            sortable: false,
            wrap: true,
            width: '200px',
        },

        {
            name: 'Action',
            width: '350px',
            style: actionColumnStyles,
            cell: (row) => (
                <>
                    {/* btn-group-example */}
                    <div className="d-flex gap-1 my-1">
                        <Button
                            size="sm"
                            color="info"
                            type="button"
                            text={`<i class='fas fa-eye'></i> View`}
                            onClick={() => {
                                window.open(`/view-thesis-entry-details/${row?.id}`, '_blank');
                            }}
                        />
                        <Button
                            size="sm"
                            color="success"
                            type="button"
                            text={`<i class='fas fa-edit'></i> Edit`}
                            disabled={['Approve', 'Reject', 'Submit'].includes(row?.submission_status)}
                            onClick={() => {
                                window.open('/thesis-file-entry', '_blank');
                            }}
                        />
                        {/* {row?.admin_approval_status === 'Correction' && */}
                        <Button
                            size="sm"
                            color="primary"
                            type="button"
                            text={`<span class="fas fa-edit" ></span> Reviewer's Comment`}
                            onClick={() => {
                                commentModalShowHandler(true, row.id);
                            }}
                        />
                        {/* } */}
                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
        },
    ];

    const {
        handleSubmit,
        getFieldProps,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        onSubmit: (values) => {
            let s: string = '';

            if (values.subject_name !== '') {
                s = s + `&subject_name=${values.subject_name}`;
            }

            if (values.year_of_submission !== '') {
                s = s + `&year_of_submission=${values.year_of_submission}`;
            }

            setSearch(s);
            setPage('1');
            set_paginate_per_page(15);
        },
    });

    return (
        <div className="page-content">
            {isAllAboutThesisForTraineeDataLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Container fluid>
                <BetterBreadcrumb
                    breadcrumbItem={[{ link: '#', name: 'Thesis / Dissertation Management' }]}
                    title={'Thesis / Dissertation Management'}
                />
                <Card className="mb-5">
                    <CardHeader className='bg-secondary bg-opacity-75 p-0'>
                        <h5 className='text-center text-white' style={{ fontFamily: 'Roboto' }}>Title List</h5>
                    </CardHeader>
                    <CardBody className='p-0'>
                        {/* <Form
                            className="custom-form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                //   handleSubmit();
                            }}
                        >
                            <div className="row">
                                <div className="col-4">
                                    <FormGroup className="mb-3">
                                        <Label for="subject">Select Subject</Label>
                                        <Select
                                            isClearable={true}
                                            isSearchable={true}
                                            value={selectedSubject}
                                            onChange={setSelectedSubject}
                                            options={allSubjects}
                                            placeholder='Select...'
                                            name="subject"
                                            id="subject_id"
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-2">
                                    <FormGroup className="mb-3">
                                        <Label for="year_of_submission">Year</Label>
                                        <InputField
                                            type="text"
                                            placeholder="Year of Submission"
                                            invalid={{ errors, touched }}
                                            {...getFieldProps('year_of_submission')}
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-3">
                                    <FormGroup className="btn-group btn-group-example mb-3 mt-4">
                                        <Button
                                            color="danger"
                                            type="reset"
                                            text="Reset"
                                            onClick={() => {
                                                setSelectedSubject('');
                                                resetForm();
                                                handleSubmit();
                                            }}
                                        />
                                        <Button
                                            type="button"
                                            text="Search"
                                            onClick={() => {
                                                handleSubmit();
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </Form> */}
                        <DataTable
                            columns={titleListColumns}
                            customStyles={dataTableCustomStyles}
                            data={titleList ? titleList : []}
                            progressPending={isAllAboutThesisForTraineeDataLoading}
                            persistTableHead={true}
                            expandOnRowClicked
                            highlightOnHover
                        />
                    </CardBody>
                </Card>

                <Card className="mb-5">
                    <CardHeader className='bg-secondary bg-opacity-75 p-0'>
                        <h5 className='text-white text-center' style={{ fontFamily: 'Roboto' }}>Protocol List</h5>
                    </CardHeader>
                    <CardBody className='p-0'>
                        {/* <Form
                            className="custom-form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                //   handleSubmit();
                            }}
                        >
                            <div className="row">
                                <div className="col-4">
                                    <FormGroup className="mb-3">
                                        <Label for="subject">Select Subject</Label>
                                        <Select
                                            isClearable={true}
                                            isSearchable={true}
                                            value={selectedSubject}
                                            onChange={setSelectedSubject}
                                            options={allSubjects}
                                            placeholder='Select...'
                                            name="subject"
                                            id="subject_id"
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-2">
                                    <FormGroup className="mb-3">
                                        <Label for="year_of_submission">Year</Label>
                                        <InputField
                                            type="text"
                                            placeholder="Year of Submission"
                                            invalid={{ errors, touched }}
                                            {...getFieldProps('year_of_submission')}
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-3">
                                    <FormGroup className="btn-group btn-group-example mb-3 mt-4">
                                        <Button
                                            color="danger"
                                            type="reset"
                                            text="Reset"
                                            onClick={() => {
                                                setSelectedSubject('');
                                                resetForm();
                                                handleSubmit();
                                            }}
                                        />
                                        <Button
                                            type="button"
                                            text="Search"
                                            onClick={() => {
                                                handleSubmit();
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </Form> */}
                        <DataTable
                            columns={protocolListColumns}
                            customStyles={dataTableCustomStyles}
                            data={protocolList ? protocolList : []}
                            progressPending={isAllAboutThesisForTraineeDataLoading}
                            persistTableHead={true}
                            expandOnRowClicked
                            highlightOnHover
                        />
                    </CardBody>
                </Card>

                <Card>
                    <CardHeader className='bg-secondary bg-opacity-75 p-0'>
                        <h5 className='text-white text-center' style={{ fontFamily: 'Roboto' }}>Thesis / Dissertation List</h5>
                    </CardHeader>
                    <CardBody className='p-0'>
                        {/* <Form
                            className="custom-form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                //   handleSubmit();
                            }}
                        >
                            <div className="row">
                                <div className="col-4">
                                    <FormGroup className="mb-3">
                                        <Label for="subject">Select Subject</Label>
                                        <Select
                                            isClearable={true}
                                            isSearchable={true}
                                            value={selectedSubject}
                                            onChange={setSelectedSubject}
                                            options={allSubjects}
                                            placeholder='Select...'
                                            name="subject"
                                            id="subject_id"
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-2">
                                    <FormGroup className="mb-3">
                                        <Label for="year_of_submission">Year</Label>
                                        <InputField
                                            type="text"
                                            placeholder="Year of Submission"
                                            invalid={{ errors, touched }}
                                            {...getFieldProps('year_of_submission')}
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-3">
                                    <FormGroup className="btn-group btn-group-example mb-3 mt-4">
                                        <Button
                                            color="danger"
                                            type="reset"
                                            text="Reset"
                                            onClick={() => {
                                                setSelectedSubject('');
                                                resetForm();
                                                handleSubmit();
                                            }}
                                        />
                                        <Button
                                            type="button"
                                            text="Search"
                                            onClick={() => {
                                                handleSubmit();
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </Form> */}
                        <DataTable
                            columns={thesisDissertationListColumns}
                            customStyles={dataTableCustomStyles}
                            data={thesisDissertationList ? thesisDissertationList : []}
                            progressPending={isAllAboutThesisForTraineeDataLoading}
                            persistTableHead={true}
                            expandOnRowClicked
                            highlightOnHover
                        />
                    </CardBody>
                </Card>

                <Modal
                    onOpened={function () {
                        toast.dismiss();
                    }}
                    isOpen={commentModalShow}
                    scrollable={true}
                    size="lg"
                    toggle={() => {
                        document.body.classList.add('no_padding');
                    }}
                    backdrop={'static'}
                    modalTransition={{ timeout: 0 }}
                    backdropTransition={{ timeout: 0 }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            Reviewer's Comment
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => {
                                setCommentModalShow(!commentModalShow);
                            }}
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <Row>
                            <Col>
                                <ReviewerComments thesisId={commentThesisId} />
                            </Col>
                        </Row>
                    </div>
                </Modal>

                {
                    titleEditBtnStatus && titleForEdit ? (
                        <TitleEntry
                            titleEditBtnStatus={titleEditBtnStatus}
                            titleForEdit={titleForEdit}
                            setTitleEditBtnStatus={setTitleEditBtnStatus}
                            setTitleForEdit={setTitleForEdit}
                        />
                    ) : (
                        null
                    )
                }
            </Container>

            <Modal
                onOpened={function () {
                }}
                isOpen={showModal}
                scrollable={false}
                size='lg'
                toggle={() => {
                    document.body.classList.add('no_padding');
                }}
                backdrop={'static'}
                modalTransition={{ timeout: 0 }}
                backdropTransition={{ timeout: 0 }}
            >
                <div className='modal-header'>
                    <h5 className='modal-title' id='staticBackdropLabel'>
                        Details Info
                    </h5>
                    <button
                        type='button'
                        className='btn-close'
                        onClick={() => {
                            setShowModal(false);
                        }}
                        aria-label='Close'
                    ></button>
                </div>
                <div className='modal-body'>
                    <ApprovalForGuideCoGuide info={viewTitleInfo} />
                </div>
            </Modal>

            <Modal isOpen={changeRequestModalShow} size={'lg'} toggle={() => setChangeRequestModalShow(!changeRequestModalShow)}>
                <ModalHeader toggle={() => setChangeRequestModalShow(!changeRequestModalShow)}>Change Information</ModalHeader>
                <ModalBody>
                    <ChangeRequestModal changeInfo={viewTitleInfo} />
                </ModalBody>
            </Modal>
        </div>
    );
};

export default ListOfTitleProtocolAndThesis;
