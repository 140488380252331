import moment from 'moment';
import { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { config } from 'src/config';
import fileOthers from '../../../assets/images/file-others.svg';
import CommentBtn from './CommentBtn';

const StepOne = (props: any) => {

    const { thesisEntry } = props;

    const [showCommentSectionModal, setShowCommentSectionModal] = useState<boolean>(false);
    const [commentsFieldId, setCommentsFieldId] = useState<string>('');
    const [commentsFieldName, setCommentsFieldName] = useState<string>('');

    const thesisDetails: any = thesisEntry?.title?.thesis_master_data?.details!;

    return (
        <>
            {thesisEntry?.id ?
                <table className='table table-sm table-bordered'>
                    <thead className='bg-light fixed'>
                        <tr className='text-center text-dark font-size-16 fw-bold'>
                            <td colSpan={4}>{thesisEntry?.document_type}</td>
                        </tr>
                    </thead>

                    <tbody className='text-capitalize text-dark font-size-14'>
                        <tr>
                            <td>1</td>
                            <td className='fw-bold'>Submission Date</td>
                            <td className=''>{moment(thesisEntry?.submission_date).isValid() ? moment(thesisEntry?.submission_date).format('DD/MMM/YYYY') : ''}</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td className='fw-bold'>Discipline</td>
                            <td>
                                {thesisEntry?.title?.registration?.subject?.subject_name ?? ''}
                            </td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td className='fw-bold'>Name of the researcher</td>
                            <td>{thesisEntry?.trainee_name ?? ''}</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td className='fw-bold'>Correspondence of the examinee and contact phone numbers</td>
                            <td>
                                <p>
                                    <strong>Address: </strong>
                                    {thesisEntry?.title?.registration?.address_infos[1]?.address_line_1 ? `${thesisEntry?.title?.registration?.address_infos[1]?.address_line_1}, ` : ``}
                                    {thesisEntry?.title?.registration?.address_infos[1]?.police_station ? `${thesisEntry?.title?.registration?.address_infos[1]?.police_station}, ` : ``}
                                    {thesisEntry?.title?.registration?.address_infos[1]?.district ? `${thesisEntry?.title?.registration?.address_infos[1]?.district}` : ``}.
                                </p>
                                <p><strong>Email Address: </strong> {thesisEntry?.title?.registration?.personal_info?.email ?? ''}</p>
                                <p><strong>Phone number: </strong> {thesisEntry?.title?.registration?.personal_info?.mobile ?? ''}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td className='fw-bold'>Place of study</td>
                            <td>{thesisEntry?.title?.place_of_study ?? ''}</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td className='fw-bold'>Study period</td>
                            <td>{thesisEntry?.title?.study_period ?? ''} {thesisEntry?.title?.study_period_unit ?? ''}</td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td className='fw-bold'>Title of the {thesisEntry?.document_type}</td>
                            <td>{thesisEntry?.title?.title_of_thesis ?? ''}</td>
                        </tr>

                        <tr>
                            <td>8</td>
                            <td className='fw-bold'>{thesisEntry?.document_type} File</td>
                            <td>
                                <div className={'dissertation-upload-files-wrapper'}>
                                    <div className='dissertation-file-field-wrapper'>
                                        <div className='m-0 m-auto'>
                                            <a
                                                className=''
                                                href={`${config?.APP_URL}${thesisEntry?.document_name}`}
                                                rel='noreferrer'
                                                target='_blank'
                                            >
                                                <span className='uploaded-file-preview'>
                                                    <img src={fileOthers} alt='' />
                                                </span>
                                                <p className={'file-name my-2'}>
                                                    {thesisEntry?.document_name}
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </td>
                        </tr>

                        {thesisDetails?.map((detailsObj: any, index: number) => {
                            if (['acceptance (signatory)', 'protocol', 'title', '1.1 title']?.includes(detailsObj?.thesis_setup_column_name?.toLowerCase())) {
                                return null;
                            } else if (detailsObj.details_text && !detailsObj.details_file) {
                                return (
                                    <tr key={detailsObj?.id}>
                                        <td>{index + 8}</td>
                                        <td className='fw-bold'>{detailsObj?.thesis_setup_column_name}</td>
                                        <td>
                                            <Row className='justify-content-between gx-5 mb-2'>
                                                <Col sm={'11'}>
                                                    <span dangerouslySetInnerHTML={{ __html: detailsObj?.details_text }}></span>
                                                </Col>

                                                <CommentBtn
                                                    thesisDissertationFile={thesisEntry}
                                                    thesisDissertationFormSetupId={detailsObj?.thesis_dissertation_setup_id}
                                                    showCommentSectionModal={showCommentSectionModal}
                                                    setShowCommentSectionModal={setShowCommentSectionModal}
                                                    commentsFieldId={commentsFieldId}
                                                    setCommentsFieldId={setCommentsFieldId}
                                                    commentsFieldName={commentsFieldName}
                                                    setCommentsFieldName={setCommentsFieldName}                                                    
                                                    detailsObj={detailsObj}
                                                />
                                            </Row>
                                        </td>
                                    </tr>
                                )
                            } else if (!detailsObj?.details_text && detailsObj?.details_file) {
                                return (
                                    <tr key={detailsObj?.id}>
                                        <td>{index + 8}</td>
                                        <td className='fw-bold'>{detailsObj?.thesis_setup_column_name}</td>
                                        <td>
                                            <Row className='justify-content-between gx-5 mb-2'>
                                                <Col sm={'11'}>
                                                    <div className={'dissertation-upload-files-wrapper'}>
                                                        <div className='dissertation-file-field-wrapper'>
                                                            <div className='m-0 m-auto'>
                                                                <a
                                                                    className=''
                                                                    href={`${config?.APP_URL}${detailsObj?.details_file}`}
                                                                    rel='noreferrer'
                                                                    target='_blank'
                                                                >
                                                                    <span className='uploaded-file-preview'>
                                                                        <img src={fileOthers} alt='' />
                                                                    </span>
                                                                    <p className={'file-name my-2'}>
                                                                        {detailsObj?.details_file}
                                                                    </p>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <CommentBtn
                                                    thesisDissertationFile={thesisEntry}
                                                    thesisDissertationFormSetupId={detailsObj?.thesis_dissertation_setup_id}
                                                    showCommentSectionModal={showCommentSectionModal}
                                                    setShowCommentSectionModal={setShowCommentSectionModal}
                                                    commentsFieldId={commentsFieldId}
                                                    setCommentsFieldId={setCommentsFieldId}
                                                    commentsFieldName={commentsFieldName}
                                                    setCommentsFieldName={setCommentsFieldName}                                                    
                                                    detailsObj={detailsObj}
                                                />
                                            </Row>
                                        </td>
                                    </tr>
                                )
                            } else if (detailsObj?.details_text && detailsObj?.details_file) {
                                return (
                                    <tr key={detailsObj?.id}>
                                        <td>{index + 8}</td>
                                        <td className='fw-bold'>{detailsObj?.thesis_setup_column_name}</td>
                                        <td>
                                            <Row className='justify-content-between mb-2'>
                                                <Col sm={'11'}>
                                                    <tr className='border-bottom'>
                                                        <td className='border-end'>
                                                            <p className='fw-bold'>{'Text Content'}</p>
                                                        </td>
                                                        <td>
                                                            <span dangerouslySetInnerHTML={{ __html: detailsObj?.details_text }}></span>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className='border-end'>
                                                            <p className='fw-bold'>{'Attachment'}</p>
                                                        </td>
                                                        <td>
                                                            <div className={'dissertation-upload-files-wrapper'}>
                                                                <div className='dissertation-file-field-wrapper'>
                                                                    <div className='m-0 m-auto'>
                                                                        <a
                                                                            className=''
                                                                            href={`${config?.APP_URL}${detailsObj?.details_file}`}
                                                                            rel='noreferrer'
                                                                            target='_blank'
                                                                        >
                                                                            <span className='uploaded-file-preview'>
                                                                                <img src={fileOthers} alt='' />
                                                                            </span>
                                                                            <p className={'file-name my-2'}>
                                                                                {detailsObj?.details_file}
                                                                            </p>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </Col>
                                                <CommentBtn
                                                    thesisDissertationFile={thesisEntry}
                                                    thesisDissertationFormSetupId={detailsObj?.thesis_dissertation_setup_id}
                                                    showCommentSectionModal={showCommentSectionModal}
                                                    setShowCommentSectionModal={setShowCommentSectionModal}
                                                    commentsFieldId={commentsFieldId}
                                                    setCommentsFieldId={setCommentsFieldId}
                                                    commentsFieldName={commentsFieldName}
                                                    setCommentsFieldName={setCommentsFieldName}
                                                    detailsObj={detailsObj}
                                                />
                                            </Row>
                                        </td>
                                    </tr>
                                )
                            } else if (JSON.parse(detailsObj?.file_array) instanceof Array
                                && JSON.parse(detailsObj?.file_array)?.length > 0) {
                                return (
                                    <tr key={detailsObj?.id}>
                                        <td>{index + 8}</td>
                                        <td className='fw-bold'>{detailsObj?.thesis_setup_column_name}</td>
                                        <td>
                                            <Row className='justify-content-between mb-2'>
                                                <Col sm={'11'}>
                                                    <table className='table table-bordered text-center'>
                                                        <thead>
                                                            <th>Sl. No.</th>
                                                            <th>File Name</th>
                                                            <th>File Preview</th>
                                                        </thead>
                                                        <tbody>
                                                            {JSON.parse(detailsObj?.file_array)?.map((fileObj: any, index: number) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {index + 1}
                                                                        </td>
                                                                        <td>
                                                                            {fileObj?.file_name}
                                                                        </td>
                                                                        <td>
                                                                            <a
                                                                                className="me-2 p-0 center"
                                                                                href={`${config?.APP_URL}${fileObj?.file}`}
                                                                                rel="noreferrer"
                                                                                target="_blank"
                                                                            >
                                                                                <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </Col>
                                                <CommentBtn
                                                    thesisDissertationFile={thesisEntry}
                                                    thesisDissertationFormSetupId={detailsObj?.thesis_dissertation_setup_id}
                                                    showCommentSectionModal={showCommentSectionModal}
                                                    setShowCommentSectionModal={setShowCommentSectionModal}
                                                    commentsFieldId={commentsFieldId}
                                                    setCommentsFieldId={setCommentsFieldId}
                                                    commentsFieldName={commentsFieldName}
                                                    setCommentsFieldName={setCommentsFieldName}
                                                    detailsObj={detailsObj}
                                                />
                                            </Row>
                                        </td>
                                    </tr>
                                )
                            } else {
                                return (
                                    <tr key={detailsObj?.id}>
                                        <td>{index + 8}</td>
                                        <td className='fw-bold'>{detailsObj?.thesis_setup_column_name}</td>
                                        <td>

                                        </td>
                                    </tr>
                                )
                            }
                        })}
                    </tbody>
                </table>
                :
                null}

            {/* <FormGroup className='mt-5'>
                <div className='d-flex justify-content-end'>
                    <BsButton color='success' onClick={() => {
                        goToNext();
                    }}>
                        <i className='fas fa-2x fa-arrow-right-long'></i>
                    </BsButton>
                </div>
            </FormGroup> */}            
        </>
    );
};

export default StepOne;
