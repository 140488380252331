import { Button } from 'src/components';

export const examinfo = {
  title: 'Exam Info',
  listGetUrl: '/setup/setup-exam-training-info',
  GetByIdUrl: '/setup/setup-exam-training-info/',
  CreateUrl: '/setup/setup-exam-training-info',
  UpdateUrl: '/setup/setup-exam-training-info/',
  DeleteByIdUrl: '/setup/setup-exam-training-info/',
  breadcrumbItem: [
    { link: '/', name: 'Dashboard' },
    { link: '/setup', name: 'Setup' },
  ],

  columns: [
    {
      name: 'Name',
      selector: (row: any) => row.name,
    },

    {
      name: 'Code',
      selector: (row: any) => row.code,
    },
    {
      name: 'Type',
      selector: (row: any) => row.type,
    },
    {
      name: 'Session',
      selector: (row: any) => row.session,
    },
    {
      name: 'Duration',
      selector: (row: any) => row.duration,
      cell: (row: any) =>
        row.duration ? row.duration + ' ' + row.duration_unit : '',
    },

    {
      name: 'Is Paid Course?',
      selector: (row: any) => row.paid_course,
      cell: (row: any) => (row.paid_course ? 'Yes' : 'No'),
    },

    {
      name: 'Status',
      selector: (row: any) => row.status,
      cell: (row: any) => (row.status ? 'Active' : 'Inactive'),
    },

    {
      name: 'Action',
      selector: (row: any) => row.id,
      cell: (row: any, index: any) => (
        <>
          <Button
            size="sm"
            color="info"
            type="button"
            text={`<span class="fas fa-eye" ></span> View`}
            onClick={() => {
              console.log(row);
            }}
          />
        </>
      ),
    },
  ],
};

export const registration = {
  title: 'Registration Approval',
  listGetUrl: '/registration/reg-registration-info',
  GetByIdUrl: '/registration/reg-registration-info',
  breadcrumbItem: [{ link: '/', name: 'Dashboard' }],
  columns: [
    {
      name: 'bmdc_registration_no',
      selector: (row: any) => row.bmdc_registration_no,
    },

    {
      name: 'full name',
      selector: (row: any) => row.personal_info?.full_name,
    },

    {
      name: 'email',
      selector: (row: any) => row.personal_info?.email,
    },

    {
      name: 'mobile',
      selector: (row: any) => row.personal_info?.mobile,
    },

    {
      name: 'national id',
      selector: (row: any) => row.personal_info?.national_id,
    },
  ],
};
