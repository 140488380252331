import { LogbookEntrySetupDetailsValues } from "src/pages/Setup/LogbookEntrySetupDetailsValue";
import { AddLogbookEntrySetupDetailsValue } from "src/pages/Setup/LogbookEntrySetupDetailsValue/AddLogbookEntrySetupDetailsValue";
import { EditLogbookEntrySetupDetailsValue } from "src/pages/Setup/LogbookEntrySetupDetailsValue/EditLogbookEntrySetupDetailsValue";

export const logBookEntrySetupDetailsValueRoutes = [
  // logbook entry setup details value
  {
    path: '/setup/logbook-entry-setup-details-value',
    component: LogbookEntrySetupDetailsValues,
  },
  {
    path: '/setup/logbook-entry-setup-details-value/update/:id',
    component: EditLogbookEntrySetupDetailsValue,
  },
  {
    path: '/setup/logbook-entry-setup-details-value/add',
    component: AddLogbookEntrySetupDetailsValue,
  },
];
