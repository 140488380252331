import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Html from 'react-pdf-html';
import { Col, Modal, Row } from 'reactstrap';
import { useGetProgressReportDataQuery } from 'src/rtk/features/logbookMonitoringReport/logbookMonitoringReportApi';
import loader from '../../assets/images/loader.gif';

interface Props {
  userInfo: any;
  setReportId: any;
}
// Create styles
const styles = StyleSheet.create({
  page: {
    margin: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 0,
  },
  nextLine: {
    marginTop: -20,
    textAlign: 'center',
    fontSize: '10px',
  },
});

// Create Document Component
// const MyDocument = () => (
//   <Document>
//     <Page size="A4" style={styles.page}>
//       <View style={styles.section}>
//         <Text>Section #1</Text>
//       </View>
//       <View style={styles.section}>
//         <Text>Section #2</Text>
//       </View>
//     </Page>
//   </Document>
// );

export const ProgressReportPDF = (props: Props) => {
  const { userInfo, setReportId } = props;
  const { data: list, isSuccess, isLoading } = useGetProgressReportDataQuery(userInfo?.registration_no);


  const data: any = list?.data || [];
  const [modalShow, setModalShow] = useState(false);


  console.log(data);
  let pdfUrl = '';

  const modalShowHandler = (pageState: boolean) => {
    setModalShow(pageState);
  }

  const html = `  
  <html>    
    <body>
        <style>        

        .print-container{
          padding: 0 15px;
        }

        .wide-column {
          width: 500px;
          background-color: powderblue;
        }

        table {
          margin: 0px 0px;
          padding: 0px;
          border: 1px solid black;
          font-size: 12px;
        }

        table > tbody> tr > *{
          padding: 5px
        }

        table th {
          text-align: center;    
        }
        
        table td {
                
        }

        .bgColor {
          background-color: powderblue;
        }
        b {
          font-family: 'Times-Bold'; 
        }
        p {
          font-size: 14px
        }
       
      </style>

      <div class="print-container"> 
        <p>(A)</p>
        <table>
          <tbody>
            <tr class='bgColor'>
              <td style="width: 66px"><b>Reg. No. of BCPS:</b></td>
              <td>R${userInfo?.bmdc_registration_no ?? ''}</td>
              <td>Date of Submission: 02/03/2022</td>
            </tr>
            <tr class='bgColor'>
              <td><b>Name of the Trainee:</b></td>
              <td colspan="2">${userInfo?.personal_info?.full_name ?? ''}</td>
            </tr>
            <tr>
              <td class='bgColor'><b>Specialty:</b></td>
              <td colspan="2">${userInfo?.subject?.subject_name ?? ''}</td>
            </tr>
            <tr>
              <td class='bgColor'><b>Enrollment/Online Reg. No:</b></td>
              <td>2012-256-4559</td>
            </tr>
            <tr>
              <td class='bgColor'><b>Cell Phone:</b></td>
              <td>${userInfo?.personal_info?.mobile ?? ''}</td>
              <td>${userInfo?.personal_info?.telephone ?? ''}</td>
            </tr>
            <tr>
              <td class='bgColor'><b>Email:</b></td>
              <td>${userInfo?.personal_info?.email ?? ''}</td>
            </tr>
            <tr>
              <td class='bgColor'><b>Present Workplace with Designation:</b></td>
              <td>Dhaka Medical College, Consultant, Medicine Department.</td>
            </tr>
          </tbody>
        </table>
        <p>(B)</p>
        <table>
        
          <tbody> 
            <tr style="background-color: rgb(106, 110, 253);">
              <td>Supervisor Certificate</td>
            </tr>
            <tr>
              <td class='bgColor'>Name of the Supervisor: </td>
              <td>${userInfo?.current_session_and_supervisor_info?.supervisor?.name ?? ''
    }</td>
            </tr>
            <tr>
              <td class='bgColor'>Institute & Department: </td>
              <td>Dhaka Medical College</td>
            </tr>
            <tr>
              <td class='bgColor'>Number of Bed in the Unit: </td>
              <td>12</td>
            </tr>
            <tr>
              <td class='bgColor'>Number of FCPS Trainee in the Unit: </td>
              <td>8</td>
            </tr>
            <tr>
              <td class='bgColor'>Number of Faculty Members 
              (Asst. Prof. or equivalent post and above) in the Unit: 
              </td>
              <td>7</td>
            </tr>
          </tbody>
        </table> 
        <p>(C)</p>
        ${data?.map((item: any) => (
      `<table>
            <thead> 
              <tr style="background-color: rgb(254, 190, 177);">
                <th>Performance</th>
                <th>Poor</th>
                <th>Average</th>
                <th>Good</th>
                <th>Satisfactory</th>
                <th>Excellent</th>
              </tr>
            </thead>
            <tbody>
              <tr>
              <td style="background-color: rgb(254, 190, 177);">Attendance</td>
              <td>${item.attendance === 'Poor' ? '<img src="/images/Picture1.png" style="width: 20px; margin-left: 25px;" />' : ''}</td>
              <td>${item.attendance === 'Average' ? '<img src="/images/Picture1.png" style="width: 20px; margin-left: 25px;" />' : ''}</td>
              <td>${item.attendance === 'Good' ? '<img src="/images/Picture1.png" style="width: 20px; margin-left: 25px;" />' : ''}</td>
              <td>${item.attendance === 'Satisfactory' ? '<img src="/images/Picture1.png" style="width: 20px; margin-left: 25px;" />' : ''}</td>
              <td>${item.attendance === 'Excellent' ? '<img src="/images/Picture1.png" style="width: 20px; margin-left: 25px;" />' : ''}</td>
              </tr>
              <tr>
              <td style="background-color: rgb(254, 190, 177);">Knowledge</td>
              <td>${item.knowledge === 'Poor'
                  ? '<img src="/images/Picture1.png" style="width: 20px; margin-left: 25px;" />'
                  : ''
                }</td>
                        <td>${item.knowledge === 'Average'
                  ? '<img src="/images/Picture1.png" style="width: 20px; margin-left: 25px;" />'
                  : ''
                }</td>
                        <td>${item.knowledge === 'Good'
                  ? '<img src="/images/Picture1.png" style="width: 20px; margin-left: 25px;" />'
                  : ''
                }</td>
                        <td>${item.knowledge === 'Satisfactory'
                  ? '<img src="/images/Picture1.png" style="width: 20px; margin-left: 25px;" />'
                  : ''
                }</td>
                        <td>${item.knowledge === 'Excellent'
                  ? '<img src="/images/Picture1.png" style="width: 20px; margin-left: 25px;" />'
                  : ''
                }</td>
                        </tr>
                        <tr>
                        <td style="background-color: rgb(254, 190, 177);">Skill</td>
                        <td>${item.skill === 'Poor'
                  ? '<img src="/images/Picture1.png" style="width: 20px; margin-left: 25px;" />'
                  : ''
                }</td>
                        <td>${item.skill === 'Average'
                  ? '<img src="/images/Picture1.png" style="width: 20px; margin-left: 25px;" />'
                  : ''
                }</td>
                        <td>${item.skill === 'Good'
                  ? '<img src="/images/Picture1.png" style="width: 20px; margin-left: 25px;" />'
                  : ''
                }</td>
                        <td>${item.skill === 'Satisfactory'
                  ? '<img src="/images/Picture1.png" style="width: 20px; margin-left: 25px;" />'
                  : ''
                }</td>
                        <td>${item.skill === 'Excellent'
                  ? '<img src="/images/Picture1.png" style="width: 20px; margin-left: 25px;" />'
                  : ''
                }</td>
                        </tr>
                        <tr>
                        <td style="background-color: rgb(254, 190, 177);">Attitude</td>
                        <td>${item.attitude === 'Poor'
                  ? '<img src="/images/Picture1.png" style="width: 20px; margin-left: 25px;" />'
                  : ''
                }</td>
                        <td>${item.attitude === 'Average'
                  ? '<img src="/images/Picture1.png" style="width: 20px; margin-left: 25px;" />'
                  : ''
                }</td>
                        <td>${item.attitude === 'Good'
                  ? '<img src="/images/Picture1.png" style="width: 20px; margin-left: 25px;" />'
                  : ''
                }</td>
                        <td>${item.attitude === 'Satisfactory'
                  ? '<img src="/images/Picture1.png" style="width: 20px; margin-left: 25px;" />'
                  : ''
                }</td>
                        <td>${item.attitude === 'Excellent'
                  ? '<img src="/images/Picture1.png" style="width: 20px; margin-left: 25px;" />'
                  : ''
                }</td>
              </tr>
            </tbody>
          </table>
          <p style="margin-top: 50px;">
            Supervisor’s Comment: ${item?.supervisor_comment ?? ''}<br/>
            Supervisor’s Recommendation: Considering his/her overall performance I recommend that the trainee <u>${item?.supervisor_recommendation ?? ''}</u> proceed for further training.
          </p>`
        )).join('')}
          
      </div>      
    </body>
  </html>
`;

  // const MyDocument = (
  //   <Document>
  //     <Page size="A4" style={styles.page}>
  //       <View style={styles.section}>
  //         <Text>Section #1</Text>
  //       </View>
  //       <View style={styles.section}>
  //         <Text>Section #2</Text>
  //       </View>
  //     </Page>
  //   </Document>
  // );

  const MyDocument = (
    <Document>
      <Page size="A4">
        <View style={styles.row}>
          <Image
            src="/images/apple-icon-180x180.png"
            style={{
              width: '40px',
              marginTop: '20px',
              marginBottom: '0px',
              marginLeft: '10px',
            }}
          />
          <Text
            style={{
              textAlign: 'center',
              fontFamily: 'Times-Bold',
              fontSize: '14px',
              margin: '20px',
              marginBottom: '0px',
            }}
          >
            BANGLADESH COLLEGE OF PHYSICIANS AND SURGEONS (BCPS)
          </Text>
        </View>
        <Text style={styles.nextLine}>
          67, Shaheed Tajuddin Ahmed Sarani, Mohakhali, Dhaka-1212
        </Text>
        <Text
          style={{
            textAlign: 'center',
            fontFamily: 'Helvetica-Bold',
            fontSize: '14px',
            margin: '15px',
          }}
        >
          FCPS Part-II Training Progress Report
        </Text>
        <Text
          style={{
            textAlign: 'center',
            fontFamily: 'Helvetica-Bold',
            fontSize: '12px',
          }}
        >
          Period:{' '}
          {userInfo?.current_session_and_supervisor_info?.session_start_date ?? ''} to{' '}
          {userInfo?.current_session_and_supervisor_info?.session_end_date ?? ''}
        </Text>
        <Html>{html}</Html>
      </Page>
    </Document>
  );

  useEffect(() => {
    if (isSuccess) {
      const pdfData = pdf(MyDocument).toBlob();
      pdfData.then((pdfData) => {
        const pdfContent = new Blob([pdfData], { type: 'application/pdf' });
        const url = URL.createObjectURL(pdfContent);
        pdfUrl = url;
        setReportId('');
        window.open(pdfUrl, '_blank');
      });
    }
  }, [isSuccess]);

  return (
    <div>
      <div>
        {isLoading ? (
          <div className="overlay">
            <img src={loader} alt="Loading" height={100} width={300} />
          </div>
        ) : null}
        {/* {authUser?.type === 'Operation' ?
          <button
            className="btn btn-sm btn-info"
            disabled={userInfo?.current_session_and_supervisor_info?.progress_report?.submit_status !== 'Submit'}
            onClick={() => {
              modalShowHandler(true);
            }}
          >
            <span className="fas fa-eye"></span> View
          </button>
          : <button
            className="btn btn-sm btn-info"
            disabled={userInfo?.current_session_and_supervisor_info?.progress_report?.submit_status !== 'Submit'}
            onClick={() => {
              window.open(pdfUrl, '_blank');
              console.log(data);
            }}
          >
            <span className="fas fa-eye"></span> View
          </button>} */}
      </div>
      
    </div>
  );
};
