import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useAppDispatch } from 'src/rtk/app/hook';
import { userLogout } from 'src/rtk/features/auth/authSlice';

interface LogoutProps {
    history: any;
}

const Logout = ({ history }: LogoutProps) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(userLogout());
        localStorage.removeItem('bcps-auth-token-and-user');
        history.push('/login');
    }, [dispatch]);

    return <></>;
};

export default withRouter(Logout);
