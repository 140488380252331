import { EditApprovalDetail } from 'src/pages/Setup/ApprovalDetails/EditApprovalDetail';
import { AddApproval } from '../pages/Setup/Approval/AddApproval';
import { EditApproval } from '../pages/Setup/Approval/EditApproval';
import { Approval } from '../pages/Setup/Approval/index';
import { AddApprovalDetail } from '../pages/Setup/ApprovalDetails/AddApprovalDetail';
import { ApprovalDetail } from '../pages/Setup/ApprovalDetails/index';
import PolicySetup from 'src/pages/Setup/Policy/PolicySetup';

export const approvalSetupRoutes = [
  // approval setup
  {
    path: '/setup/approval/',
    component: Approval,
  },
  {
    path: '/setup/approval/update/:id',
    component: EditApproval,
  },
  {
    path: '/setup/approval/add',
    component: AddApproval,
  },
  // approval details setup
  {
    path: '/setup/approval-details/',
    component: ApprovalDetail,
  },
  {
    path: '/setup/approval-details/update/:id',
    component: EditApprovalDetail,
  },
  {
    path: '/setup/approval-details/add',
    component: AddApprovalDetail,
  },
  {
    path: '/policy-setup',
    component: PolicySetup,
  },
];
