import { useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import { useHistory } from 'react-router-dom';
import defaultAvatar from 'src/assets/images/users/default-avatar.png';
import { config } from 'src/config';
import { useAppDispatch, useAppSelector } from 'src/rtk/app/hook';
import { useLayoutMutation } from 'src/rtk/features/auth/authApi';
import { userLogout } from 'src/rtk/features/auth/authSlice';
import DisplayImage from "../Common/displayImage";

const ProfileMenu = (props: any) => {
  const [menu, setMenu] = useState<boolean>(false);
  const [username, setUsername] = useState('Admin');
  const history = useHistory();
  const authUser = useAppSelector((state) => state.auth.user);
  const [user, setUser] = useState<any>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const authUser: any = localStorage.getItem('authUser');
    if (authUser) {
      setUser(JSON.parse(authUser));

      setTimeout(() => {
        // dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch /* success */]);

  const [logoutApi, { data, isSuccess }] = useLayoutMutation();

  const layoutHandler = () => {
    logoutApi()
      .then((res) => {
        dispatch(userLogout);
        localStorage.removeItem('bcps-auth-token-and-user');
        if (authUser?.type === 'Operation') {
          window.location.href = '/admin-login';
        } else {
          window.location.href = '/login';
        }
      })
      .catch((err) => {
        localStorage.removeItem('bcps-auth-token-and-user');
        window.location.href = '/login';
      });
  };

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block"
    >
      <DropdownToggle
        className="btn header-item bg-soft-light border-start border-end"
        id="page-header-user-dropdown"
        tag="button"
      >
        <DisplayImage className="rounded-circle header-profile-user" src={authUser?.details?.profile_approval_status === 'Approve'
          ? `${config?.APP_URL}${authUser?.details?.personal_info?.picture}`
          : defaultAvatar} />

        <span className="d-none d-xl-inline-block ms-2 me-1">
          {authUser.name}
        </span>
        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem tag="a" href="/profile">
          <i className="bx bx-user font-size-16 align-middle me-1" />
          Profile
        </DropdownItem>
        <DropdownItem tag="a" href="/change-password">
          <i className="fa-solid fa-key me-1" />
          Change Password
        </DropdownItem>
        {/* <DropdownItem tag="a" href="#">
          <span className="badge bg-success float-end"></span>
          <i className="bx bx-wrench font-size-16 align-middle me-1" />
          Settings
        </DropdownItem>
        <DropdownItem tag="a" href="#">
          <i className="bx bx-lock-open font-size-16 align-middle me-1" />
          Lock screen
        </DropdownItem> */}
        <div className="dropdown-divider" />
        <button className="dropdown-item" onClick={layoutHandler}>
          <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
          <span>Logout</span>
        </button>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileMenu;
