//SERVICES
export const AUTH_URL = `/v1`
export const ACCESS_URL = `/access/v1`
export const SETUP_URL = `/setup/v1`
export const REGISTRATION_URL = `/registration/v1`
export const TRAINING_INSTITUTE_URL = `/training-institute/v1`
export const ELOGBOOK_MANAGEMENT_URL = `/elogbook-management/v1`

//AUTH
export const GET_DATA_FOR_REGISTRATION = `${AUTH_URL}/get-data-for-registration`
export const VERIFY_EMAIL_TOKEN = `${AUTH_URL}/email-verification`
export const RESEND_EMAIL_TOKEN = `${AUTH_URL}/email-verification-resend`
export const REGISTRATION = `${AUTH_URL}/registration`
export const LOGIN = `${AUTH_URL}/login`
export const LOGIN_AS = `${AUTH_URL}/login-as`
export const FORGET_PASSWORD = `${AUTH_URL}/password-reset-mail`
export const PASSWORD_RESET_MAIL_MOBILE = `${AUTH_URL}/password-reset-mail-mobile`
export const RESET_PASSWORD = `${AUTH_URL}/password-reset`
export const PASSWORD_RESET_MOBILE = `${AUTH_URL}/password-reset-mobile`
export const PASSWORD_RESET_CODE_CHECK_MOBILE = `${AUTH_URL}/password-reset-code-check-mobile`
export const CHANGE_PASSWORD = `${AUTH_URL}/me/change-password`
export const LOGOUT = `${AUTH_URL}/logout`

//NOTIFICATION
export const NOTIFICATION_STATUS = `${AUTH_URL}/get-notification-status`
export const GET_ALL_NOTIFICATION = `${AUTH_URL}/all-notifications`
export const GET_NOTIFICATION_SEEN = `${SETUP_URL}/notification-seen`

//CERTIFICATE
export const GET_CERTIFICATE_LIST = `${SETUP_URL}/get-notice`
export const GET_CERTIFICATE_TEMPLATE_LIST = `${SETUP_URL}/setup-notice-template-list`
export const GET_CERTIFICATE_VIEW = `${SETUP_URL}/get-notice`
export const GET_CERTIFICATE_PDF = `${SETUP_URL}/trn-notice-master`
export const GET_SIGNATORIES_BY_PERMISSION = `${AUTH_URL}/get-users-by-permission`
export const CREATE_CERTIFICATE = `${SETUP_URL}/post-notice`
export const UPDATE_CERTIFICATE_MASTER = `${SETUP_URL}/update-notice-master`
export const UPDATE_CERTIFICATE_ATTACHMENT = `${SETUP_URL}/update-notice-attachment`

//COURSE APPROVAL
export const GET_COURSE_APPLICATION_LIST = `${ELOGBOOK_MANAGEMENT_URL}/course-application-lists`
export const GET_COURSE_APPROVAL_BY_ADMIN = `${ELOGBOOK_MANAGEMENT_URL}/course-application-approval`
export const GET_TITLE_LIST_FOR_RTMD = `${TRAINING_INSTITUTE_URL}/title-list-for-admin-approval`
export const CREATE_TITLE_APPROVAL_FOR_RTMD = `${TRAINING_INSTITUTE_URL}/title-admin-approval`

//DEVICE REGISTRATION
export const GET_DEVICE_REG_INFOS = `${REGISTRATION_URL}/device-infos`
export const GET_TRAINEE_DEVICE_REG_INFOS = `${REGISTRATION_URL}/device-info-for-trainee`
export const GET_DEVICE_REG_INFOS_BY_PAGINATE = `${REGISTRATION_URL}/device-infos`
export const GET_DEVICE_REG_INFO = `${REGISTRATION_URL}/device-infos`
export const GET_DEVICE_REG_INFO_APPROVAL = `${REGISTRATION_URL}/device-registration-approval`
export const GET_USER_IN_DEVICE_REGISTRATION = `${AUTH_URL}/users`
export const CREATE_DEVICE_REG_INFO = `${REGISTRATION_URL}/device-infos`
export const UPDATE_DEVICE_REG_INFO = `${REGISTRATION_URL}/device-infos`
export const DELETE_DEVICE_REG_INFO = `${REGISTRATION_URL}/device-infos`

//DISSERTATION LIST FOR ASSIGNING REVIEWER
export const GET_DISSERTATION_LIST_FOR_ASSIGNING_REVIEWER_BY_PAGINATE = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-file-list-for-admin`
export const ASSIGN_DISSERTATION_REVIEWER = `${TRAINING_INSTITUTE_URL}/dissertation-reviewer-assign`
export const GET_DISSERTATION_REVIEWER_COMMENT_LIST = `${TRAINING_INSTITUTE_URL}/dissertation-reviewer-comment-list`
export const ASSIGN_THIRD_REVIEWER = `${TRAINING_INSTITUTE_URL}/dissertation-third-reviewer-assign`

//DISSERTATION LIST FOR REVIEWER
export const GET_DISSERTATION_LIST_FOR_REVIEWER_BY_PAGINATE = `${TRAINING_INSTITUTE_URL}/dissertation-list-for-reviewer`
export const CREATE_DISSERTATION_REVIEWER_COMMENT = `${TRAINING_INSTITUTE_URL}/dissertation-reviewer-comment`

//THESIS DISSERTATION OLD TITLE LIST
export const GET_THESIS_DISSERTATION_OLD_TITLE_LIST = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-old-title-list`

//PROTOCOL LIST FOR APPROVAL API
export const GET_PROTOCOL_BY_ID = `${TRAINING_INSTITUTE_URL}/protocol-view`

//ELOG BOOK API
export const UPDATE_ELOGBOOK = `${ELOGBOOK_MANAGEMENT_URL}/approval-log-entry`

//TRAINEE ACADEMIC ACTIVITIES API
export const GET_LOGBOOK_ENTRY_SETUP_DETAILS = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-setup-details`
export const GET_LOGBOOK_ENTRY_SETUP_DETAIL = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-setup-details`
export const GET_FORM_BY_LOGBOOK_TYPE = `${ELOGBOOK_MANAGEMENT_URL}/get-form-list`
export const GET_DYNAMIC_FORM_PROPERTY_BY_PARTICULAR = `${ELOGBOOK_MANAGEMENT_URL}/dynamic-form-property-by-particular`
export const CREATE_LOGBOOK_ENTRY_SETUP_DETAIL = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-setup-details`
export const UPDATE_LOGBOOK_ENTRY_SETUP_DETAIL = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-setup-details`
export const DELETE_LOGBOOK_ENTRY_SETUP_DETAIL = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-setup-details`
export const CREATE_LOGBOOK_ENTRY = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-details`
export const GET_TRAINEE_LOG_LIST = `${ELOGBOOK_MANAGEMENT_URL}/trainee-log-entry-list`

//EDUCATIONAL API
export const GET_EDUCATIONAL_PROFILE = `${AUTH_URL}/me`
export const CREATE_EDUCATIONAL_PROFILE = `${REGISTRATION_URL}/me/step-two`
export const UPDATE_EDUCATIONAL_PROFILE = `${REGISTRATION_URL}/me/step-two`
export const DELETE_EDUCATIONAL_PROFILE = `${REGISTRATION_URL}/me/step-two`

export const GET_FCPS_PART_1_INFO = `${AUTH_URL}/get-fcps-part1-info`
export const GET_FCPS_PART_1_DETAILS = `${AUTH_URL}/get-fcps-part1-details`

//FELLOW SUPERVISOR APPLY
export const GET_SUPERVISOR_APPLICANTS_LIST = `${REGISTRATION_URL}/fellow-supervisor-applications`
export const CREATE_FELLOW_APPLY_FOR_SUPERVISOR = `${REGISTRATION_URL}/fellow-supervisor-apply`
export const CREATE_SUPERVISOR_APPROVAL = `${REGISTRATION_URL}/fellow-supervisor-application-approve`

//THESIS DISSERTATION FILE ENTRY
export const GET_THESIS_DISSERTATION_FILE_ENTRY_LIST = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-file-list`
export const CREATE_THESIS_DISSERTATION_FILE_ENTRY = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-file-entry`
export const UPDATE_INQUIRY_TYPE_ENTRY = `${TRAINING_INSTITUTE_URL}/research-clinic-inquiry-type-update`
export const DELETE_INQUIRY_TYPE = `${TRAINING_INSTITUTE_URL}/delete-research-clinic-inquiry-type`
export const GET_THESIS_DISSERTATION_TITLE_LIST = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-title-list`
export const GET_THESIS_DISSERTATION_FORM_SETUP_LIST = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-form-setup`
export const GET_THESIS_ENTRY_DETAILS_BY_ID = `${TRAINING_INSTITUTE_URL}/thesis-file-view-by-id`
export const GET_THESIS_ENTRY_LIST_FOR_TRAINEE = `${TRAINING_INSTITUTE_URL}/thesis-entry-list-for-trainee`
export const GET_THESIS_ENTRY_LIST_FOR_ADMIN = `${TRAINING_INSTITUTE_URL}/thesis-entry-list-for-admin`
export const CREATE_THESIS_ENTRY_MASTER_WITH_DETAILS = `${TRAINING_INSTITUTE_URL}/thesis-entry-master-with-details`

//GUIDE CO GUIDE API
export const GET_GUIDE_CO_GUIDE_LIST = `${REGISTRATION_URL}/supervisor/guide-applications`
export const GET_GUIDE_CO_GUIDE_APPROVAL_LIST = `${REGISTRATION_URL}/guide-co-guide-lists`
export const GET_GUIDE_APPLICATION_APPROVAL = `${REGISTRATION_URL}/approval/guide-application-approval`
export const GET_CO_GUIDE_APPLICATION_APPROVAL = `${REGISTRATION_URL}/approval/co-guide-application-approval`
export const CREATE_GUIDE_CO_GUIDE = `${REGISTRATION_URL}/supervisor/guide-applications`
export const UPDATE_GUIDE_CO_GUIDE = `${REGISTRATION_URL}/supervisor/guide-applications`
export const CREATE_BANK_INFO_ENTRY_FOR_GUIDE_CO_GUIDE = `${TRAINING_INSTITUTE_URL}/bank-info-entry`
export const GET_BANK_INFORMATION_FOR_GUIDE_CO_GUIDE = `${TRAINING_INSTITUTE_URL}/bank-info`

//INSTITUTE AND DEPARTMENT REGISTRATION
export const CREATE_INSTITUTE = `${TRAINING_INSTITUTE_URL}/institute-registration-update`
export const REGISTER_DEPARTMENT_STEP_ONE = `${TRAINING_INSTITUTE_URL}/institute-department-registration`
export const INSTITUTE_DEPARTMENT_RENEW = `${TRAINING_INSTITUTE_URL}/institute-department-renew`
export const REGISTER_DEPARTMENT_STEP_TWO = `${TRAINING_INSTITUTE_URL}/ipd-opd-record-entry`
export const REGISTER_DEPARTMENT_STEP_THREE = `${TRAINING_INSTITUTE_URL}/ot-info-entry`
export const GET_OT_INFO = `${TRAINING_INSTITUTE_URL}/get-ot-info`
export const TOTAL_NO_OF_OPERATION_ENTRY = `${TRAINING_INSTITUTE_URL}/total-no-of-operation-entry`
export const TOTAL_NO_OF_OPERATION_LIST = `${TRAINING_INSTITUTE_URL}/total-no-of-operation-list`
export const DELETE_TOTAL_NO_OF_OPERATION = `${TRAINING_INSTITUTE_URL}/total-no-of-operation-delete`
export const PROCEDURE_RECORD_ENTRY = `${TRAINING_INSTITUTE_URL}/procedure-record-entry`
export const GET_PROCEDURE_RECORD_LIST = `${TRAINING_INSTITUTE_URL}/procedure-record-list`
export const DELETE_PROCEDURE_RECORD = `${TRAINING_INSTITUTE_URL}/procedure-record-delete`
export const INSTITUTE_ADMIN_REGISTRATION = `${AUTH_URL}/institute-admin-registration`
export const GET_INSTITUTE_REGISTRATION_LIST = `${TRAINING_INSTITUTE_URL}/institute-registration-list`
export const GET_INSTITUTE_DEPARTMENT_LIST_BY_PAGINATE = `${TRAINING_INSTITUTE_URL}/institute-department-list`
export const GET_DEPARTMENT_BY_ID = `${TRAINING_INSTITUTE_URL}/institute-department-view`
export const GET_INSTITUTE_BY_ID = `${TRAINING_INSTITUTE_URL}/institute-view`
export const GET_IPD_RECORD_LIST = `${TRAINING_INSTITUTE_URL}/ipd-opd-record-list`
export const GET_OPD_RECORD_LIST = `${TRAINING_INSTITUTE_URL}/ipd-opd-record-list`
export const DELETE_IPD_OPD_RECORD = `${TRAINING_INSTITUTE_URL}/ipd-opd-record-delete`
export const OPERATION_RECORD_ENTRY = `${TRAINING_INSTITUTE_URL}/operation-record-entry`
export const GET_OPERATION_RECORD_LIST = `${TRAINING_INSTITUTE_URL}/operation-record-list`
export const DELETE_OPERATION_RECORD = `${TRAINING_INSTITUTE_URL}/operation-record-delete`
export const LABORATORY_RECORD_ENTRY = `${TRAINING_INSTITUTE_URL}/laboratory-record-entry`
export const GET_LABORATORY_RECORD_LIST = `${TRAINING_INSTITUTE_URL}/laboratory-record-list`
export const DELETE_LABORATORY_RECORD = `${TRAINING_INSTITUTE_URL}/laboratory-record-delete`
export const ACADEMIC_STAFF_INFO_ENTRY = `${TRAINING_INSTITUTE_URL}/academic-staff-info-entry`
export const GET_ACADEMIC_STAFF_INFO_LIST = `${TRAINING_INSTITUTE_URL}/academic-staff-info-list`
export const DELETE_ACADEMIC_STAFF_INFO = `${TRAINING_INSTITUTE_URL}/academic-staff-info-delete`
export const ACADEMIC_STAFF_WORKLOADS_ENTRY = `${TRAINING_INSTITUTE_URL}/academic-staff-workloads-entry`
export const GET_ACADEMIC_STAFF_WORKLOADS_LIST = `${TRAINING_INSTITUTE_URL}/academic-staff-workloads-list`
export const DELETE_ACADEMIC_STAFF_WORKLOAD = `${TRAINING_INSTITUTE_URL}/academic-staff-workload-delete`
export const PART_C_MASTER_INFO_ENTRY = `${TRAINING_INSTITUTE_URL}/part-c-master-info-entry`
export const PART_C_RESEARCH_INFO_ENTRY = `${TRAINING_INSTITUTE_URL}/part-c-research-info-entry`
export const GET_PART_C_MASTER_INFO_ENTRY = `${TRAINING_INSTITUTE_URL}/get-part-c-master-info`
export const GET_PART_C_RESEARCH_INFO_ENTRY = `${TRAINING_INSTITUTE_URL}/part-c-research-info-list`
export const PART_C_RESEARCH_PROJECT_ENTRY = `${TRAINING_INSTITUTE_URL}/part-c-research-project-entry`
export const GET_PART_C_RESEARCH_PROJECT_LIST = `${TRAINING_INSTITUTE_URL}/part-c-research-project-list`
export const DELETE_PART_C_RESEARCH_PROJECT = `${TRAINING_INSTITUTE_URL}/part-c-research-project-delete`
export const INS_PUBLICATIONS_ENTRY = `${TRAINING_INSTITUTE_URL}/ins-publications-entry`
export const INS_PUBLICATIONS_LIST = `${TRAINING_INSTITUTE_URL}/ins-publications-list`
export const DELETE_INS_PUBLICATIONS = `${TRAINING_INSTITUTE_URL}/ins-publications-delete`
export const INS_DUTIES_ENTRY = `${TRAINING_INSTITUTE_URL}/ins-duties-entry`
export const INS_DUTIES_LIST = `${TRAINING_INSTITUTE_URL}/ins-duties-list`
export const DELETE_INS_DUTIES = `${TRAINING_INSTITUTE_URL}/ins-duties-delete`
export const PART_D_MASTER_INFO_ENTRY = `${TRAINING_INSTITUTE_URL}/part-d-master-info-entry`
export const GET_PART_D_MASTER_INFO_ENTRY = `${TRAINING_INSTITUTE_URL}/get-part-d-master-info`
export const LIBRARY_SERVICE_ENTRY = `${TRAINING_INSTITUTE_URL}/part-d-library-services-entry`
export const GET_LIBRARY_SERVICE_LIST = `${TRAINING_INSTITUTE_URL}/part-d-library-services-list`
export const DELETE_LIBRARY_SERVICE = `${TRAINING_INSTITUTE_URL}/part-d-library-services-delete`
export const SUPPORT_SERVICE_ENTRY = `${TRAINING_INSTITUTE_URL}/part-d-support-services-entry`
export const GET_SUPPORT_SERVICE_LIST = `${TRAINING_INSTITUTE_URL}/part-d-support-services-list`
export const DELETE_SUPPORT_SERVICE = `${TRAINING_INSTITUTE_URL}/part-d-support-services-delete`
export const TEACHING_INFRASTRUCTURE_ENTRY = `${TRAINING_INSTITUTE_URL}/teaching-infrastructure-entry`
export const GET_TEACHING_INFRASTRUCTURE_LIST = `${TRAINING_INSTITUTE_URL}/teaching-infrastructure-list`
export const DELETE_TEACHING_INFRASTRUCTURE = `${TRAINING_INSTITUTE_URL}/teaching-infrastructure-delete`

//INSTITUTE INSPECTION
export const CREATE_INSPECTION_TEAM = `${TRAINING_INSTITUTE_URL}/inspection-team-create`
export const DEPARTMENT_ASSIGN = `${TRAINING_INSTITUTE_URL}/inspection-team-assign`
export const UPDATE_INSPECTION_TEAM = `${TRAINING_INSTITUTE_URL}/inspection-team-update`
export const GET_INSPECTION_TEAM_LIST_FOR_ADMIN = `${TRAINING_INSTITUTE_URL}/inspection-team-list-for-admin`
export const GET_RECOMMENDATION_LIST_FOR_ADMIN = `${TRAINING_INSTITUTE_URL}/recommendation-list-for-admin`
export const GET_RECOMMENDATION_LIST = `${TRAINING_INSTITUTE_URL}/inspection-recommendation-list`
export const GET_INSPECTION_TEAM_VIEW = `${TRAINING_INSTITUTE_URL}/inspection-team-view`
export const GET_ASSIGNED_DEPARTMENT = `${TRAINING_INSTITUTE_URL}/inspection-assignment-view`
export const GET_ASSIGNED_DEPARTMENT_FOR_VIEW = `${TRAINING_INSTITUTE_URL}/assigned-departments`
export const CREATE_INSPECTION_RECOMMENDATION = `${TRAINING_INSTITUTE_URL}/inspection-recommendation-criteria`
export const GET_MEMBER_RECOMMENDATION_CHECK = `${TRAINING_INSTITUTE_URL}/member-recommendation-check`
export const GET_RECOMMENDATION_APPROVAL = `${TRAINING_INSTITUTE_URL}/recommendation-approval-by-admin`
export const DELETE_INSPECTION_TEAM = `${TRAINING_INSTITUTE_URL}/inspection-team-delete`

//LEAVE MANAGEMENT
export const GET_LEAVE_ENTRY_LIST = `${REGISTRATION_URL}/leave-entry-list-for-trainee`
export const GET_LEAVE_APPROVAL_LIST_FOR_ADMIN = `${REGISTRATION_URL}/leave-entry-list-for-admin`
export const GET_LEAVE_APPROVAL_LIST_FOR_SUPERVISOR = `${REGISTRATION_URL}/leave-entry-list-for-supervisor`
export const GET_LEAVE_APPLICATION_APPROVAL = `${REGISTRATION_URL}/leave-approval`
export const CREATE_LEAVE_ENTRY = `${REGISTRATION_URL}/leave-apply`
export const UPDATE_LEAVE_ENTRY = `${REGISTRATION_URL}/leave-update`
export const DELETE_LEAVE_ENTRY = `${REGISTRATION_URL}/leave-delete`

//LOGBOOK MONITORING REPORT
export const GET_REPORT_LIST = `${ELOGBOOK_MANAGEMENT_URL}/users-for-report-view`
export const GET_SUSPICIOUS_ENTRY_LIST = `${ELOGBOOK_MANAGEMENT_URL}/trainee-suspicious-activity-logbook-entry-lists`
export const GET_SUSPICIOUS_ENTRY_DETAILS = `${ELOGBOOK_MANAGEMENT_URL}/trainee-suspicious-activity-logbook-entry-details`
export const GET_SUPERVISOR_SUSPICIOUS_APPROVAL_LIST = `${ELOGBOOK_MANAGEMENT_URL}/supervisor-suspicious-activity-logbook-entry-lists`
export const GET_SUPERVISOR_SUSPICIOUS_APPROVAL_DETAILS = `${ELOGBOOK_MANAGEMENT_URL}/supervisor-suspicious-activity-logbook-entry-details`
export const GET_SIX_MONTHLY_REPORT_DATA = `${ELOGBOOK_MANAGEMENT_URL}/six-month-report`
export const GET_PROGRESS_REPORT_DATA = `${ELOGBOOK_MANAGEMENT_URL}/progress-report-by-reg-no`
export const CREATE_PROGRESS_REPORT_ENTRY = `${ELOGBOOK_MANAGEMENT_URL}/progress-report-entry`

//LOGBOOK TRAINING SUMMARY
export const GET_TRAINING_SUMMARY = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-report-by-from`
export const GET_LOGBOOK_REPORT_LIST_FOR_ADMIN_AND_SUPERVISOR = `${ELOGBOOK_MANAGEMENT_URL}/users-list-for-logbook-summary`
export const GET_TRAINING_SUMMARY_FOR_ADMIN_AND_SUPERVISOR = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-report-by-from`
export const GET_USER_SLOT_INFO_FOR_ADMIN_AND_SUPERVISOR = `${ELOGBOOK_MANAGEMENT_URL}/user-slot-info`
export const GET_USER_SLOT_INFO_FOR_TRAINEE = `${ELOGBOOK_MANAGEMENT_URL}/user-slot-info`
export const GET_TRAINING_SUMMARY_BY_ID = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-report-by-from`
export const GET_TRAINING_SUMMARY_FOR_ADMIN_AND_SUPERVISOR_BY_ID = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-report-by-from`

//MINI CEX AND CBD DOPS
export const GET_SESSION_WISE_MINI_CEX_LIST = `${ELOGBOOK_MANAGEMENT_URL}/get-session-wises-user-mini-cex-and-cbd`
export const GET_TRAINEE_ASSESSMENT_LIST = `${ELOGBOOK_MANAGEMENT_URL}/get-trainee-assessment-list`
export const CREATE_MINI_CEX_FORM_ENTRY_BY_SUPERVISOR = `${ELOGBOOK_MANAGEMENT_URL}/monitoring-mini-cex-assessment`
export const CREATE_MINI_CEX_ENTRY_BY_TRAINEE = `${ELOGBOOK_MANAGEMENT_URL}/monitoring-mini-cex-trainee`
export const CREATE_CBD_FORM_A_ENTRY = `${ELOGBOOK_MANAGEMENT_URL}/monitoring-cbd-a`
export const CREATE_CBD_FORM_B_ENTRY = `${ELOGBOOK_MANAGEMENT_URL}/monitoring-cbd-b`
export const CREATE_CBD_FORM_ENTRY_BY_SUPERVISOR = `${ELOGBOOK_MANAGEMENT_URL}/monitoring-cbd-assessment`
export const CREATE_CBD_ENTRY_BY_TRAINEE = `${ELOGBOOK_MANAGEMENT_URL}/monitoring-cbd-trainee`
export const CREATE_DOPS_FORM_ENTRY_BY_SUPERVISOR = `${ELOGBOOK_MANAGEMENT_URL}/monitoring-dops-assessment`
export const CREATE_DOPS_ENTRY_BY_TRAINEE = `${ELOGBOOK_MANAGEMENT_URL}/monitoring-dops-trainee`

export const CREATE_FEEDBACK_FOR_ASSESMENT = `${ELOGBOOK_MANAGEMENT_URL}/monitoring-feedback`

//MY TRAINING API
export const GET_MY_TRAINING = `${SETUP_URL}/get-course-lists`
export const CREATE_MY_TRAINING = `${SETUP_URL}/get-course-lists`
export const CREATE_SUPERVISOR_COURSE_APPLY = `${ELOGBOOK_MANAGEMENT_URL}/supervisor-apply`
export const CREATE_CHANGE_SUPERVISOR_APPLY = `${ELOGBOOK_MANAGEMENT_URL}/supervisor-change`
export const CREATE_DOCUMENT_UPLOAD = `${REGISTRATION_URL}/trainee-employees-document-upload`
export const CREATE_PAYMENT_WITHOUT_FEES_APPLY = `${REGISTRATION_URL}/fcps-apply-without-pay`
export const GET_CURRENT_SUPERVISOR = `${ELOGBOOK_MANAGEMENT_URL}/get-current-supervisor`

//PUBLISH NOTICE API
export const GET_NOTICE_LIST = `${SETUP_URL}/get-notice`
export const GET_NOTICE_PDF = `${SETUP_URL}/trn-notice-master`
export const GET_NOTICE_LIST_BY_PAGINATE = `${SETUP_URL}/get-notice`
export const GET_NOTICE_TEMPLATE_LIST = `${SETUP_URL}/setup-notice-template-list`
export const GET_NOTICE_VIEW = `${SETUP_URL}/get-notice`
export const GET_SIGNATORIES_BY_PERMISSION_IN_NOTICE = `${AUTH_URL}/get-users-by-permission`
export const CREATE_NOTICE = `${SETUP_URL}/post-notice`
export const UPDATE_NOTICE_MASTER = `${SETUP_URL}/update-notice-master`
export const UPDATE_NOTICE_ATTACHMENT = `${SETUP_URL}/update-notice-attachment`
export const DOWNLOAD_NOTICE = `${AUTH_URL}/download-notice`

//NOTICE APPROVAL API
export const UPDATE_SIGNATORY_STATUS = `${SETUP_URL}/signatory-status-update`

//PAYMENT APPROVAL API
export const GET_PAYMENT_LISTS = `${REGISTRATION_URL}/reg-order-master`
export const GET_PAYMENT_LISTS_BY_PAGINATE = `${REGISTRATION_URL}/reg-order-master`
export const GET_PAYMENT_LIST = `${REGISTRATION_URL}/reg-order-master`
export const GET_PAYMENT_CONFIRM = `${REGISTRATION_URL}/fcps-payment-confirm`
export const GET_INSTITUTE_PAYMENT_APPROVAL_LIST = `${TRAINING_INSTITUTE_URL}/ins-dept-payment-list-for-admin`
export const CREATE_PAYMENT_LIST = `${REGISTRATION_URL}/reg-order-master`
export const DELETE_PAYMENT_LIST = `${REGISTRATION_URL}/reg-order-master`

//PERMISSION API
export const GET_PERMISSIONS = `${ACCESS_URL}/permissions`
export const GET_PERMISSION_OF_ROLE = `${ACCESS_URL}/role-permission/get-permissions-by-role-name`
export const UPDATE_PERMISSION = `${ACCESS_URL}/permissions`
export const GET_PERMISSION_WISE_MENU = `${ACCESS_URL}/get-permission-wise-menu`

//REGISTRATION APPROVAL API
export const GET_REG_INFOS = `${REGISTRATION_URL}/reg-registration-info`
export const GET_REG_INFOS_BY_PAGINATE = `${REGISTRATION_URL}/reg-registration-info`
export const GET_REG_INFO = `${REGISTRATION_URL}/reg-registration-info`
export const GET_REG_ADDRESS_INFO = `${REGISTRATION_URL}/reg-address-info`
export const GET_REG_PERSONAL_INFO = `${REGISTRATION_URL}/reg-personal-info`
export const GET_REG_EMPLOYMENT_INFO = `${REGISTRATION_URL}/reg-employment-info`
export const GET_REG_EDUCATIONAL_INFO = `${REGISTRATION_URL}/reg-educational-info`
export const GET_REG_TRAINING_INFO = `${REGISTRATION_URL}/reg-training-info`
export const GET_REG_TRAINING_DOCUMENT = `${REGISTRATION_URL}/reg-training-document`
export const GET_REG_PRE_FCPS_TWO_Q_TRAINEES_INFO = `${REGISTRATION_URL}/reg-pre-fcps2-q-trainees-info`
export const GET_REG_FCPS_MD_MS_Q_TRAINEES_INFO = `${REGISTRATION_URL}/reg-fcps-md-ms-q-trainees-info`
export const GET_REG_ORDER_DETAIL = `${REGISTRATION_URL}/reg-order-detail`
export const TRN_EXP_OF_INTEREST_MSTR = `${REGISTRATION_URL}/trn-exp-of-interest-mstr`
export const TRN_EXP_OF_INTEREST_DETAILS = `${REGISTRATION_URL}/trn-exp-of-interest-details`
export const GET_MY_REG_INFO = `${REGISTRATION_URL}/get-reg-registration-info`
export const GET_APPROVAL_STATUS = `${REGISTRATION_URL}/get-approval-status/bmdc_registration_no_approval`
export const GET_BMDC_CERTIFICATE_APPROVAL_STATUS = `${REGISTRATION_URL}/get-approval-status/bmdc_certificate_approval`
export const GET_CERTIFICATE_SOURCE_VERIFY = `${REGISTRATION_URL}/approval/certificate-source-verification`
export const GET_BMDC_APPROVAL = `${REGISTRATION_URL}/approval/bmdc-number-verification`
export const GET_BMDC_SOURCE_VERIFY = `${REGISTRATION_URL}/approval/bmdc-certificate-source-verification`
export const GET_TRAINEE_PROFILE_APPROVAL = `${REGISTRATION_URL}/approval/trainee-profile-approval`
export const CREATE_REG_INFO = `${REGISTRATION_URL}/reg-registration-info`
export const UPDATE_REG_INFO = `${REGISTRATION_URL}/approval-reg-registration`
export const DELETE_REG_INFO = `${REGISTRATION_URL}/reg-registration-info`

//RESEARCH CLINIC FAQ API
export const GET_FAQ_LIST_FOR_ADMIN = `${TRAINING_INSTITUTE_URL}/get-research-clinic-faq-list`
export const GET_FAQ_LIST_FOR_TRAINEE = `${TRAINING_INSTITUTE_URL}/get-research-clinic-faq-list-for-trainee`
export const CREATE_FAQ = `${TRAINING_INSTITUTE_URL}/add-research-clinic-faq`
export const UPDATE_FAQ = `${TRAINING_INSTITUTE_URL}/update-research-clinic-faq`
export const DELETE_FAQ = `${TRAINING_INSTITUTE_URL}/delete-research-clinic-faq`

//RESEARCH CLINIC SETUP API
export const GET_INQUIRY_LIST = `${TRAINING_INSTITUTE_URL}/get-research-clinic-inquiry-type-list`
export const CREATE_INQUIRY_TYPE_ENTRY = `${TRAINING_INSTITUTE_URL}/research-clinic-inquiry-type-entry`
export const UPDATE_INQUIRY_TYPE_ENTRY_IN_RESEARCH_CLINIC_SETUP = `${TRAINING_INSTITUTE_URL}/research-clinic-inquiry-type-update`
export const DELETE_INQUIRY_TYPE_IN_RESEARCH_CLINIC_SETUP = `${TRAINING_INSTITUTE_URL}/delete-research-clinic-inquiry-type`
export const GET_INQUIRY_LIST_FOR_TRAINEE = `${TRAINING_INSTITUTE_URL}/get-research-clinic-inquiry-type-list-for-trainee`
export const GET_SUBMITTED_QUERY_LIST = `${TRAINING_INSTITUTE_URL}/research-clinic-query-list-for-trainee`
export const GET_RESEARCH_CLINIC_WORKLIST = `${TRAINING_INSTITUTE_URL}/research-clinic-query-list-for-admin`
export const GET_WORKLIST_FOR_CONSULTANT = `${TRAINING_INSTITUTE_URL}/research-clinic-query-list-for-consultant`
export const CREATE_SOLUTION_ENTRY = `${TRAINING_INSTITUTE_URL}/research-clinic-query-answer`
export const ASSIGN_CONSULTANT = `${TRAINING_INSTITUTE_URL}/research-clinic-meeting-schedule`
export const GET_PAYMENT_INFO = `${TRAINING_INSTITUTE_URL}/rc-payment-package-list`
export const RESOLVE_STATUS_UPDATE = `${TRAINING_INSTITUTE_URL}/research-clinic-resolve-status-update`
export const CREATE_INQUIRY_ENTRY_BY_TRAINEE = `${TRAINING_INSTITUTE_URL}/research-clinic-query-entry`
export const ADD_PAYMENT_PACKAGE = `${TRAINING_INSTITUTE_URL}/add-research-clinic-payment-package`
export const CREATE_EXPECTED_FINAL_EXAM_SESSION = `${TRAINING_INSTITUTE_URL}/expected-final-exam-session`
export const DELETE_SUBMITTED_QUERY = `${TRAINING_INSTITUTE_URL}/delete-rc-query`
export const CREATE_RESEARCH_GRANT_FORM_ENTRY = `${TRAINING_INSTITUTE_URL}/rc-grant-form-a-entry`
export const GET_BANK_INFORMATION = `${TRAINING_INSTITUTE_URL}/rc-grant-form-a`
export const GET_RESEARCH_GRANT_LIST_FOR_RTMD = `${TRAINING_INSTITUTE_URL}/rc-grant-form-a-for-admin`
export const CREATE_MONEY_RECEIPT = `${TRAINING_INSTITUTE_URL}/money-receipt-create`
export const GET_MONEY_RECEIPT_LIST = `${TRAINING_INSTITUTE_URL}/money-receipt-list`

//ROLE API
export const GET_ROLES = `${ACCESS_URL}/roles`
export const CREATE_ROLE = `${ACCESS_URL}/roles`
export const UPDATE_ROLE = `${ACCESS_URL}/roles`
export const DELETE_ROLE = `${ACCESS_URL}/roles`

//FCPS OFFLINE PAYMENT API
export const GET_FCPS_PAYMENT_LIST = `${REGISTRATION_URL}/reg-order-master-trainee`
export const GET_TRAINEE_PAYMENT_LIST = `${REGISTRATION_URL}/reg-order-master-trainee`
export const GET_FCPS_PAYMENT = `${AUTH_URL}/fcps-payments`
export const CREATE_FCPS_PAYMENT = `${REGISTRATION_URL}/fcps-offline-payment`
export const FCPS_ONLINE_PAYMENT = `${AUTH_URL}/fcps-online-payment`
export const CREATE_INSTITUTE_PAYMENT = `${REGISTRATION_URL}/institute-offline-payment`
export const CREATE_DEPARTMENT_PAYMENT = `${REGISTRATION_URL}/department-offline-payment`

//TRAINEE API
export const GET_PERSONAL_PROFILE = `${AUTH_URL}/me/`
export const CREATE_PERSONAL_PROFILE = `${REGISTRATION_URL}/me/step-one`
export const UPDATE_PERSONAL_PROFILE = `${REGISTRATION_URL}/me/step-one`
export const DELETE_PERSONAL_PROFILE = `${REGISTRATION_URL}/me/step-one`
export const GET_STATUS_UPDATE = `${AUTH_URL}/profile-status-update`
export const GET_TRAINEE_IRREGULAR_LOG_ENTRY_MESSAGE = `${ELOGBOOK_MANAGEMENT_URL}/trainee-irregular-log-entry-check`

//TRAINEE FCPS COURSE LIST API
export const GET_FCPS_COURSE_LISTS = `${SETUP_URL}/get-course-lists`
export const GET_FCPS_COURSE_LIST = `${SETUP_URL}/get-course-lists`
export const GET_FCPS_COURSE_LIST_BY_ID = `${SETUP_URL}/get-course-details`
export const GET_SUPERVISORS = `${AUTH_URL}/supervisors`

//TRAINEE LOGBOOK ENTRY API
export const CREATE_LOGBOOK_ENTRY_IN_TRAINEE_LOGBOOK_ENTRY_API = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-details`
export const UPDATE_LOGBOOK_ENTRY = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-details`
export const GET_LOGBOOK_ENTRIES = `${ELOGBOOK_MANAGEMENT_URL}/trainee-log-entry-list`
export const GET_COMPETENCY_GROUP_BY_ID = `${ELOGBOOK_MANAGEMENT_URL}/get-competency-group`
export const GET_LOGBOOK_ENTRIES_BY_PAGINATE = `${ELOGBOOK_MANAGEMENT_URL}/trainee-log-entry-list`

//TRAINING RECORD API
export const GET_TRAINING_RECORD = `${AUTH_URL}/me`
export const GET_SUPERVISORS_IN_TRAINING_RECORD_API = `${AUTH_URL}/supervisors`
export const GET_SUPERVISORS_BY_INSTITUTE = `${REGISTRATION_URL}/supervisors-by-institute`
export const CREATE_TRAINING_RECORD = `${REGISTRATION_URL}/me/step-three`
export const UPDATE_TRAINING_RECORD = `${REGISTRATION_URL}/me/step-three`
export const DELETE_TRAINING_RECORD = `${REGISTRATION_URL}/me/step-three`

//TRAINING WORKSHOP API
export const GET_FCPS_COURSE_LISTS_FOR_TRAINING_WORKSHOP = `${SETUP_URL}/get-course-lists`
export const GET_TRAININGS = `${SETUP_URL}/setup-exam-training-info`
export const GET_ALL_TRAININGS = `${SETUP_URL}/setup-exam-training-info`
export const GET_TRAINING_WORKSHOP_LIST_BY_PAGINATE = `${REGISTRATION_URL}/trn-workshop-training-master`
export const CREATE_TRAINING_WORKSHOP = `${REGISTRATION_URL}/trn-workshop-training-master-create`
export const UPDATE_TRAINING_WORKSHOP = `${REGISTRATION_URL}/trn-workshop-training-master-create`
export const GET_TRAINING_NAME_LIST = `${SETUP_URL}/setup-exam-training-info`

//USER API
export const GET_USERS = `${AUTH_URL}/users`
export const GET_USERS_BY_PAGINATE = `${AUTH_URL}/users`
export const GET_USER = `${AUTH_URL}/users`
export const CREATE_USER = `${AUTH_URL}/users`
export const UPDATE_USER = `${AUTH_URL}/users`
export const DELETE_USER = `${AUTH_URL}/users`

//USER SELECT BY PERMISSION API
export const GET_USERS_IN_SELECT_BY_PERMISSION_API = `${AUTH_URL}/users`
export const GET_USERS_BY_PAGINATE_IN_SELECT_BY_PERMISSION_API = `${AUTH_URL}/users`
export const GET_USER_IN_SELECT_BY_PERMISSION_API = `${AUTH_URL}/users`
export const GET_USERS_BY_PERMISSION = `${AUTH_URL}/get-users-by-permission`
export const GET_USERS_BY_ROLE = `${AUTH_URL}/get-users-by-role`
export const GET_INSTITUTE_INSPECTOR = `${AUTH_URL}/get-users-by-permission/can-inspect-institute`
export const CREATE_USER_IN_SELECT_BY_PERMISSION_API = `${AUTH_URL}/users`
export const UPDATE_USER_IN_SELECT_BY_PERMISSION_API = `${AUTH_URL}/users`
export const DELETE_USER_IN_SELECT_BY_PERMISSION_API = `${AUTH_URL}/users`

//WORK EXPERIENCE API
export const GET_WORK_EXPERIENCE = `${AUTH_URL}/me`
export const CREATE_WORK_EXPERIENCE = `${REGISTRATION_URL}/me/step-four`
export const UPDATE_WORK_EXPERIENCE = `${REGISTRATION_URL}/me/step-four`
export const DELETE_WORK_EXPERIENCE = `${REGISTRATION_URL}/me/step-four`

//APPROVAL API
export const GET_APPROVALS = `${SETUP_URL}/setup-approval/`
export const GET_APPROVAL = `${SETUP_URL}/setup-approval`
export const CREATE_APPROVAL = `${SETUP_URL}/setup-approval`
export const UPDATE_APPROVAL = `${SETUP_URL}/setup-approval`
export const DELETE_APPROVAL = `${SETUP_URL}/setup-approval`

//APPROVAL DETAILS API
export const GET_APPROVAL_DETAILS = `${SETUP_URL}/setup-approval-details`
export const GET_APPROVAL_DETAIL = `${SETUP_URL}/setup-approval-details`
export const CREATE_APPROVAL_DETAIL = `${SETUP_URL}/setup-approval-details`
export const UPDATE_APPROVAL_DETAIL = `${SETUP_URL}/setup-approval-details`
export const DELETE_APPROVAL_DETAIL = `${SETUP_URL}/setup-approval-details`

//PERMISSION APPROVAL API
export const GET_PERMISSIONS_APPROVAL = `${SETUP_URL}/get-data/permission_approval`
export const GET_PERMISSION_APPROVAL = `${SETUP_URL}/get-data/permission_approval`
export const CREATE_PERMISSION_APPROVAL = `${SETUP_URL}/get-data/permission_approval`
export const UPDATE_PERMISSION_APPROVAL = `${SETUP_URL}/get-data/permission_approval`
export const DELETE_PERMISSION_APPROVAL = `${SETUP_URL}/get-data/permission_approval`

//DEPARTMENT API
export const GET_DEPARTMENTS = `${SETUP_URL}/setup-department`
export const GET_DEPARTMENTS_BY_PAGINATE = `${TRAINING_INSTITUTE_URL}/get-all-department`
export const GET_DEPARTMENTS_BY_INSTITUTE = `${SETUP_URL}/setup-department`
export const GET_DEPARTMENT = `${SETUP_URL}/setup-department`
export const CREATE_DEPARTMENT = `${SETUP_URL}/setup-department`
export const UPDATE_DEPARTMENT = `${SETUP_URL}/setup-department`
export const DELETE_DEPARTMENT = `${SETUP_URL}/setup-department`

//DOCUMENT FILE UPLOAD API
export const GET_DOCUMENT_FILE_UPLOADS = `${SETUP_URL}/setup-document-file-upload`
export const GET_DOCUMENT_FILE_UPLOAD = `${SETUP_URL}/setup-document-file-upload`
export const CREATE_DOCUMENT_FILE_UPLOAD = `${SETUP_URL}/setup-document-file-upload`
export const UPDATE_DOCUMENT_FILE_UPLOAD = `${SETUP_URL}/setup-document-file-upload`
export const DELETE_DOCUMENT_FILE_UPLOAD = `${SETUP_URL}/setup-document-file-upload`

//EXAM ELIGIBILITY CRITERIA API
export const GET_EXAM_ELIGIBILITY_CRITERIAS = `${SETUP_URL}/setup-xm-eligiblty-criteria`
export const GET_EXAM_ELIGIBILITY_CRITERIA = `${SETUP_URL}/setup-xm-eligiblty-criteria`
export const CREATE_EXAM_ELIGIBILITY_CRITERIA = `${SETUP_URL}/setup-xm-eligiblty-criteria`
export const UPDATE_EXAM_ELIGIBILITY_CRITERIA = `${SETUP_URL}/setup-xm-eligiblty-criteria`
export const DELETE_EXAM_ELIGIBILITY_CRITERIA = `${SETUP_URL}/setup-xm-eligiblty-criteria`

//EXAM INFO SETUP API
export const GET_EXAM_INFOS = `${SETUP_URL}/setup-exam-training-info`
export const GET_EXAM_INFO = `${SETUP_URL}/setup-exam-training-info`
export const CREATE_EXAM_INFO = `${SETUP_URL}/setup-exam-training-info`
export const UPDATE_EXAM_INFO = `${SETUP_URL}/setup-exam-training-info`
export const DELETE_EXAM_INFO = `${SETUP_URL}/setup-exam-training-info`

//EXAM TRAINING FEE SETUP API
export const GET_EXAM_TRAINING_FEES = `${SETUP_URL}/setup-exam-training-fee`
export const GET_EXAM_TRAINING_FEE = `${SETUP_URL}/setup-exam-training-fee`
export const CREATE_EXAM_TRAINING_FEE = `${SETUP_URL}/setup-exam-training-fee`
export const UPDATE_EXAM_TRAINING_FEE = `${SETUP_URL}/setup-exam-training-fee/`
export const DELETE_EXAM_TRAINING_FEE = `${SETUP_URL}/setup-exam-training-fee`

//FACULTY INFO API
export const GET_FACULTY_INFOS = `${SETUP_URL}/setup-faculty-info`
export const GET_FACULTY_INFO = `${SETUP_URL}/setup-faculty-info`
export const CREATE_FACULTY_INFO = `${SETUP_URL}/setup-faculty-info`
export const UPDATE_FACULTY_INFO = `${SETUP_URL}/setup-faculty-info/`
export const DELETE_FACULTY_INFO = `${SETUP_URL}/setup-faculty-info`

//LOGBOOK ENTRY MASTER API
export const GET_LOGBOOK_ENTRY_MASTERS = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-master`
export const GET_LOGBOOK_ENTRY_MASTER = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-master`
export const CREATE_LOGBOOK_ENTRY_MASTER = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-master`
export const UPDATE_LOGBOOK_ENTRY_MASTER = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-master`
export const DELETE_LOGBOOK_ENTRY_MASTER = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-master`

//LOGBOOK ENTRY SETUP DETAILS API
export const GET_LOGBOOK_ENTRY_SETUP_DETAILS_IN_SETUP = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-setup-details`
export const GET_LOGBOOK_ENTRY_SETUP_DETAILS_BY_PAGINATE = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-setup-details`
export const GET_LOGBOOK_ENTRY_SETUP_DETAIL_IN_SETUP = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-setup-details`
export const GET_FORM_BY_LOGBOOK_TYPE_IN_SETUP = `${ELOGBOOK_MANAGEMENT_URL}/get-form-list`
export const GET_FORM_BY_LOGBOOK_TYPE_AND_SUBJECT = `${ELOGBOOK_MANAGEMENT_URL}/get-form-list`
export const GET_DYNAMIC_FORMS_BY_ID = `${AUTH_URL}/get-dynamic-forms`
export const GET_DYNAMIC_FORM_PROPERTY_BY_PARTICULAR_IN_SETUP = `${ELOGBOOK_MANAGEMENT_URL}/dynamic-form-property-by-particular`
export const GET_DYNAMIC_FORM_PROPERTY_BY_PARTICULAR_UPDATED_API = `${ELOGBOOK_MANAGEMENT_URL}/dynamic-form-property-by-particular`
export const GET_DYNAMIC_FORM_PROPERTY_BY_PARTICULAR_SETUP_API = `${ELOGBOOK_MANAGEMENT_URL}/dynamic-form-property-by-particular`
export const GET_FCPS_COURSE_LISTS_FOR_LOGBOOK_ENTRY = `${SETUP_URL}/get-course-lists`
export const CREATE_LOGBOOK_ENTRY_SETUP_DETAIL_IN_SETUP = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-setup-details`
export const DELETE_LOGBOOK_ENTRY_SETUP_DETAIL_UPDATED = `${ELOGBOOK_MANAGEMENT_URL}/delete-logbook-entry-setup-details`
export const PUBLISH_LOGBOOK_ENTRY_SETUP_DETAIL = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-setup-details-publish`
export const UPDATE_LOGBOOK_ENTRY_SETUP_DETAIL_IN_SETUP = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-setup-details/`
export const DELETE_LOGBOOK_ENTRY_SETUP_DETAIL_IN_SETUP = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-setup-details`
export const CREATE_LOGBOOK_ENTRY_SETUP_DETAIL_GROUP = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-setup-details-group`
export const CREATE_LOGBOOK_ENTRY_SETUP_COMPETENCY_GROUP = `${ELOGBOOK_MANAGEMENT_URL}/add-competency-group`
export const GET_LOGBOOK_ENTRY_SETUP_DETAILS_GROUPS = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-setup-details-group`
export const DELETE_LOGBOOK_ENTRY_COMPETENCY_GROUP = `${ELOGBOOK_MANAGEMENT_URL}/delete-competency-group`

//LOGBOOK ENTRY SETUP DETAILS RECORD API
export const GET_LOGBOOK_ENTRY_SETUP_DETAILS_RECORDS = `${ELOGBOOK_MANAGEMENT_URL}/lbook-entry-setup-details-rec`
export const GET_LOGBOOK_ENTRY_SETUP_DETAILS_RECORD = `${ELOGBOOK_MANAGEMENT_URL}/lbook-entry-setup-details-rec`
export const CREATE_LOGBOOK_ENTRY_SETUP_DETAILS_RECORD = `${ELOGBOOK_MANAGEMENT_URL}/lbook-entry-setup-details-rec`
export const UPDATE_LOGBOOK_ENTRY_SETUP_DETAILS_RECORD = `${ELOGBOOK_MANAGEMENT_URL}/lbook-entry-setup-details-rec/`
export const DELETE_LOGBOOK_ENTRY_SETUP_DETAILS_RECORD = `${ELOGBOOK_MANAGEMENT_URL}/lbook-entry-setup-details-rec`

//LOGBOOK ENTRY SETUP DETAILS VALUE API
export const GET_LOGBOOK_ENTRY_SETUP_DETAILS_VALUES = `${ELOGBOOK_MANAGEMENT_URL}/lbook-entry-setup-details-value`
export const GET_LOGBOOK_ENTRY_SETUP_DETAILS_VALUE = `${ELOGBOOK_MANAGEMENT_URL}/lbook-entry-setup-details-value`
export const CREATE_LOGBOOK_ENTRY_SETUP_DETAILS_VALUE = `${ELOGBOOK_MANAGEMENT_URL}/lbook-entry-setup-details-value`
export const UPDATE_LOGBOOK_ENTRY_SETUP_DETAILS_VALUE = `${ELOGBOOK_MANAGEMENT_URL}/lbook-entry-setup-details-value/`
export const DELETE_LOGBOOK_ENTRY_SETUP_DETAILS_VALUE = `${ELOGBOOK_MANAGEMENT_URL}/lbook-entry-setup-details-value`

//LOGBOOK ENTRY SETUP MASTER API
export const GET_LOGBOOK_ENTRY_SETUP_MASTERS = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-setup-master`
export const GET_LOGBOOK_ENTRY_SETUP_MASTER = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-setup-master`
export const CREATE_LOGBOOK_ENTRY_SETUP_MASTER = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-setup-master`
export const UPDATE_LOGBOOK_ENTRY_SETUP_MASTER = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-setup-master/`
export const DELETE_LOGBOOK_ENTRY_SETUP_MASTER = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-setup-master`

//LOGBOOK ESSENTIAL INFO SETUP API
export const GET_LOGBOOK_ESSENTIAL_INFOS = `${ELOGBOOK_MANAGEMENT_URL}/setup-logbook-essential-info`
export const GET_LOGBOOK_ESSENTIAL_INFO = `${ELOGBOOK_MANAGEMENT_URL}/setup-logbook-essential-info`
export const CREATE_LOGBOOK_ESSENTIAL_INFO = `${ELOGBOOK_MANAGEMENT_URL}/setup-logbook-essential-info`
export const UPDATE_LOGBOOK_ESSENTIAL_INFO = `${ELOGBOOK_MANAGEMENT_URL}/setup-logbook-essential-info/`
export const DELETE_LOGBOOK_ESSENTIAL_INFO = `${ELOGBOOK_MANAGEMENT_URL}/setup-logbook-essential-info`

//LOGBOOK MASTER SETUP API
export const GET_LOGBOOK_MASTER_SETUPS = `${ELOGBOOK_MANAGEMENT_URL}/setup-logbook-master`
export const GET_LOGBOOK_MASTER_SETUP = `${ELOGBOOK_MANAGEMENT_URL}/setup-logbook-master`
export const CREATE_LOGBOOK_MASTER_SETUP = `${ELOGBOOK_MANAGEMENT_URL}/setup-logbook-master`
export const UPDATE_LOGBOOK_MASTER_SETUP = `${ELOGBOOK_MANAGEMENT_URL}/setup-logbook-master`
export const DELETE_LOGBOOK_MASTER_SETUP = `${ELOGBOOK_MANAGEMENT_URL}/setup-logbook-master`

//LOGBOOK TABLE OF CONTENT SETUP API
export const GET_LOGBOOK_TABLE_OF_CONTENTS = `${ELOGBOOK_MANAGEMENT_URL}/setup-logbook-table-of-content`
export const GET_LOGBOOK_TABLE_OF_CONTENT = `${ELOGBOOK_MANAGEMENT_URL}/setup-logbook-table-of-content`
export const CREATE_LOGBOOK_TABLE_OF_CONTENT = `${ELOGBOOK_MANAGEMENT_URL}/setup-logbook-table-of-content`
export const UPDATE_LOGBOOK_TABLE_OF_CONTENT = `${ELOGBOOK_MANAGEMENT_URL}/setup-logbook-table-of-content/`
export const DELETE_LOGBOOK_TABLE_OF_CONTENT = `${ELOGBOOK_MANAGEMENT_URL}/setup-logbook-table-of-content`

//LOOKUP CHILD API
export const GET_LOOKUP_CHILD = `${SETUP_URL}/setup-lookup-list`
export const CREATE_LOOKUP_CHILD = `${SETUP_URL}/setup-lookup-list`
export const UPDATE_LOOKUP_CHILD = `${SETUP_URL}/setup-lookup-list`
export const DELETE_LOOKUP_CHILD = `${SETUP_URL}/setup-lookup-list`

//LOOKUP DATA API
export const GET_LOOKUP_DATA = `${SETUP_URL}/get-data`

//LOOKUP DETAILS API
export const GET_LOOKUP_ITEM = `${SETUP_URL}/setup-lookup-info`
export const CREATE_LOOKUP_ITEM = `${SETUP_URL}/setup-lookup-info`
export const UPDATE_LOOKUP_ITEM = `${SETUP_URL}/setup-lookup-info`
export const DELETE_LOOKUP_ITEM = `${SETUP_URL}/setup-lookup-info`

//LOOKUP INFO API
export const GET_LOOKUP_INFOS = `${SETUP_URL}/setup-lookup-info`
export const GET_LOOKUP_INFO = `${SETUP_URL}/setup-lookup-info`
export const CREATE_LOOKUP_INFO = `${SETUP_URL}/setup-lookup-info`
export const UPDATE_LOOKUP_INFO = `${SETUP_URL}/setup-lookup-info`
export const DELETE_LOOKUP_INFO = `${SETUP_URL}/setup-lookup-info`

//SETUP NOTICE TEMPLATE ATTACHMENT API
export const GET_NOTICE_TEMPLATE_ATTACHMENTS = `${SETUP_URL}/setup-notice-tmplt-cpy-attchmnt`
export const GET_NOTICE_TEMPLATE_ATTACHMENT = `${SETUP_URL}/setup-notice-tmplt-cpy-attchmnt`
export const CREATE_NOTICE_TEMPLATE_ATTACHMENT = `${SETUP_URL}/setup-notice-tmplt-cpy-attchmnt`
export const UPDATE_NOTICE_TEMPLATE_ATTACHMENT = `${SETUP_URL}/setup-notice-tmplt-cpy-attchmnt/`
export const DELETE_NOTICE_TEMPLATE_ATTACHMENT = `${SETUP_URL}/setup-notice-tmplt-cpy-attchmnt`

//SETUP NOTICE TEMPLATE DETAILS API
export const GET_NOTICE_TEMPLATE_DETAILS_LIST = `${SETUP_URL}/setup-notice-template-details`
export const GET_NOTICE_TEMPLATE_DETAILS = `${SETUP_URL}/setup-notice-template-details`
export const CREATE_NOTICE_TEMPLATE_DETAILS = `${SETUP_URL}/setup-notice-template-details`
export const UPDATE_NOTICE_TEMPLATE_DETAILS = `${SETUP_URL}/setup-notice-template-details/`
export const DELETE_NOTICE_TEMPLATE_DETAILS = `${SETUP_URL}/setup-notice-template-details`

//SETUP NOTICE TEMPLATE LIST API
export const GET_NOTICE_TEMPLATE_LISTS = `${SETUP_URL}/setup-notice-template-list`
export const GET_NOTICE_TEMPLATE_LIST_IN_SETUP_NOTICE_TEMPLATE_LIST = `${SETUP_URL}/setup-notice-template-list`
export const CREATE_NOTICE_TEMPLATE_LIST = `${SETUP_URL}/setup-notice-template-list`
export const UPDATE_NOTICE_TEMPLATE_LIST = `${SETUP_URL}/setup-notice-template-list/`
export const DELETE_NOTICE_TEMPLATE_LIST = `${SETUP_URL}/setup-notice-template-list`

//SETUP ORGANIZATION API
export const GET_ORGANIZATIONS = `${SETUP_URL}/setup-organization`
export const GET_ALL_ORGANIZATION_LIST = `${SETUP_URL}/get-setup-organization`
export const GET_INSTITUTE_NAME = `${TRAINING_INSTITUTE_URL}/get-institute-name`
export const GET_INSTITUTE_WISE_DEPARTMENT_NAME = `${TRAINING_INSTITUTE_URL}/get-institute-wise-department-name`
export const GET_INSTITUTES_BY_ORGANIZATION = `${SETUP_URL}/setup-organization`
export const GET_ORGANIZATION = `${SETUP_URL}/setup-organization`
export const CREATE_ORGANIZATION = `${SETUP_URL}/setup-organization`
export const UPDATE_ORGANIZATION = `${SETUP_URL}/setup-organization/`
export const DELETE_ORGANIZATION = `${SETUP_URL}/setup-organization`

//SETUP ROOM API
export const GET_ROOMS = `${SETUP_URL}/setup-room`
export const GET_ROOM = `${SETUP_URL}/setup-room`
export const CREATE_ROOM = `${SETUP_URL}/setup-room`
export const UPDATE_ROOM = `${SETUP_URL}/setup-room/`
export const DELETE_ROOM = `${SETUP_URL}/setup-room`

// POLICY SETUP
export const GET_POLICY_SETUP_DATA = `${SETUP_URL}/policy-setup-master-data`
export const CREATE_POLICY_SETUP_MASTER = `${SETUP_URL}/policy-setup-details-entry`

//SETUP SUBJECT INFO API
export const GET_SUBJECT_INFOS = `${SETUP_URL}/setup-subject-info`
export const GET_SUBJECT_INFO = `${SETUP_URL}/setup-subject-info`
export const CREATE_SUBJECT_INFO = `${SETUP_URL}/setup-subject-info`
export const UPDATE_SUBJECT_INFO = `${SETUP_URL}/setup-subject-info/`
export const DELETE_SUBJECT_INFO = `${SETUP_URL}/setup-subject-info`

//SETUP SUBJECT INFO GLOBAL API
export const GET_SUBJECT_INFOS_IN_SUBJECT_INFO_GLOBAL_API = `${SETUP_URL}/setup-subject-info`
export const GET_SUBJECT_INFO_IN_SUBJECT_INFO_GLOBAL_API = `${SETUP_URL}/setup-subject-info`
export const CREATE_SUBJECT_INFO_IN_SUBJECT_INFO_GLOBAL_API = `${SETUP_URL}/setup-subject-info`
export const UPDATE_SUBJECT_INFO_IN_SUBJECT_INFO_GLOBAL_API = `${SETUP_URL}/setup-subject-info/`
export const DELETE_SUBJECT_INFO_IN_SUBJECT_INFO_GLOBAL_API = `${SETUP_URL}/setup-subject-info`

//SETUP TRN NOTICE COPY ATTACHMENT API
export const GET_NOTICE_ATTACHMENTS = `${SETUP_URL}/trn-notice-cpy-attchmnt`
export const GET_NOTICE_ATTACHMENT = `${SETUP_URL}/trn-notice-cpy-attchmnt`
export const CREATE_NOTICE_ATTACHMENT = `${SETUP_URL}/trn-notice-cpy-attchmnt`
export const UPDATE_NOTICE_ATTACHMENT_IN_TRN_NOTICE_COPY_ATTACHMENT_SETUP = `${SETUP_URL}/trn-notice-cpy-attchmnt/`
export const DELETE_NOTICE_ATTACHMENT = `${SETUP_URL}/trn-notice-cpy-attchmnt`

//SETUP TRN NOTICE DETAILS API
export const GET_TRANSACTION_NOTICE_DETAILS = `${SETUP_URL}/trn-notice-details`
export const GET_TRANSACTION_NOTICE_DETAIL = `${SETUP_URL}/trn-notice-details`
export const CREATE_TRANSACTION_NOTICE_DETAILS = `${SETUP_URL}/trn-notice-details`
export const UPDATE_TRANSACTION_NOTICE_DETAILS = `${SETUP_URL}/trn-notice-details/`
export const DELETE_TRANSACTION_NOTICE_DETAILS = `${SETUP_URL}/trn-notice-details`

//SETUP TRN NOTICE MASTER API
export const GET_TRN_NOTICE_MASTER_LISTS = `${SETUP_URL}/trn-notice-master`
export const GET_TRN_NOTICE_MASTER_LIST = `${SETUP_URL}/trn-notice-master`
export const CREATE_TRN_NOTICE_MASTER_LIST = `${SETUP_URL}/trn-notice-master`
export const UPDATE_TRN_NOTICE_MASTER_LIST = `${SETUP_URL}/trn-notice-master/`
export const DELETE_TRN_NOTICE_MASTER_LIST = `${SETUP_URL}/trn-notice-master`

//THESIS ENTRY FORM SETUP API
export const GET_THESIS_DISSERTATION_FORM_SETUPS = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-form-setup`
export const GET_THESIS_DISSERTATION_FORM_SETUP = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-form-setup`
export const CREATE_THESIS_DISSERTATION_FORM_SETUP = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-form-setup`
export const UPDATE_THESIS_DISSERTATION_FORM_SETUP = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-form-setup/`
export const DELETE_THESIS_DISSERTATION_FORM_SETUP = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-form-setup`

//SUPER_VISOR
export const SUPER_VISOR_LOGBOOK_ENTRY_DETAILS = `${ELOGBOOK_MANAGEMENT_URL}/logbook-entry-details`

//SUPER VISOR SESSION APPROVAL
export const SUPER_VISOR_COURSE_LIST = `${ELOGBOOK_MANAGEMENT_URL}/supervisor-course-lists`
export const SUPER_VISOR_COURSE_APPROVAL = `${ELOGBOOK_MANAGEMENT_URL}/supervisor-course-approval`
export const PROFILE_INFO_BY_REG_ID = `${REGISTRATION_URL}/get-reg-registration-info`


export const SETUP_SUBJECT_INFO_LIST = `${SETUP_URL}/setup-subject-info`

// EXAM TRAINING INFO
export const SETUP_EXAM_TRAINING_INFO_LIST = `${SETUP_URL}/setup-exam-training-info`
export const SETUP_EXAM_TRAINING_CREATE = `${SETUP_URL}/setup-exam-training-info`
export const SETUP_EXAM_TRAINING_UPDATE = `${SETUP_URL}/setup-exam-training-info/`
export const SETUP_EXAM_TRAINING_DELETE = `${SETUP_URL}/setup-exam-training-info/`
export const SETUP_EXAM_TRAINING_BY_ID = `${SETUP_URL}/setup-exam-training-info/`

// Users
export const USER_LIST = `${AUTH_URL}/users`
export const USER_CREATE = `${AUTH_URL}/users`
export const USER_UPDATE = `${AUTH_URL}/users/`
export const USER_DELETE = `${AUTH_URL}/users/`
export const USER_BY_ID = `${AUTH_URL}/users/`
export const GET_ROLES_LIST = `${ACCESS_URL}/roles`
export const SET_USER_PERMISSIONS = `${ACCESS_URL}/role-permission/assignDirectPermissionsToUser`
export const GET_USER_PERMISSIONS = `${AUTH_URL}/get-permissions-by-user/`
export const DEFAULT_NOTIFICATION_FOR_USER = `${AUTH_URL}/default-notification-for-user`

export const GET_ALL_MENUS = `${AUTH_URL}/menu`
export const GET_OTHER_PERMISSIONS = `${AUTH_URL}/other-permission`
export const GET_MENU = `${AUTH_URL}/menu`
export const GET_ALL_PERMISSIONS_FOR_MENU = `${AUTH_URL}/get-all-permissions-for-menu`
export const RTMD_Training_CREATE = `${SETUP_URL}/workshop-training-create`
export const RTMD_Training_UPDATE = `${SETUP_URL}/setup-exam-training-info/`
export const RTMD_GET_COURSE_LISTS = `${SETUP_URL}/get-course-lists`
export const RTMD_EXAM_TRAINING_INFO_SUBJECT_MAP = `${AUTH_URL}/exam-training-info-subject-map`
export const RTMD_WORKSHOP_TRAINING_LIST = `${REGISTRATION_URL}/trn-workshop-training-master`
export const RTMD_SUPERVISOR_LIST = `${AUTH_URL}/supervisors`

export const RTMD_BATCH_CREATE = `${REGISTRATION_URL}/batch-create-for-workshop-training`
export const RTMD_BATCH_UPDATE = `${REGISTRATION_URL}/batch-create-for-workshop-training`
export const RTMD_APPLICATION_LIST_FOR_UPDATE = `${REGISTRATION_URL}/get-workshop-training-application-list-for-update/`
export const RTMD_APPLICATION_LIST_FOR_CREATE = `${REGISTRATION_URL}/get-workshop-training-application-list/`
export const RTMD_WORKSHOP_TRAINING_BATCH_LIST = `${REGISTRATION_URL}/workshop-training-batch`
export const RTMD_WORKSHOP_TRAINING_BATCH_BY_ID = `${REGISTRATION_URL}/workshop-training-batch/`

export const RTMD_WORKSHOP_TRAINEE_ATTENDANCE_BATCH_BY_ID = `${REGISTRATION_URL}/workshop-trainee-attendance`
export const RTMD_WORKSHOP_TRAINEE_ATTENDANCE_TRAINING_FILE_UPLOAD = `${REGISTRATION_URL}/trn-workshop-training-file-upload`
export const RTMD_WORKSHOP_TRAINEE_ATTENDANCE_TRAINING_FILE_REMOVE = `${REGISTRATION_URL}/remove-workshop-training-file/`
export const UPDATE_MENU_PERMISSIONS = `${AUTH_URL}/update-menu-permissions`
export const GET_MENU_PERMISSIONS = `${AUTH_URL}/get-permissions-by-role/`


export const GET_GUIDE_COGUIDE_LIST = `${REGISTRATION_URL}/guide-or-co-guide-name`

// THESIS DISSERTATION TITLE ENTRY
export const THESIS_DISSERTATION_TITLE_ENTRY_LIST = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-title-list`
export const NEW_THESIS_DISSERTATION_TITLE_ENTRY_LIST = `${TRAINING_INSTITUTE_URL}/get-all-about-thesis-for-trainee`
export const THESIS_DISSERTATION_TITLE_ENTRY_CREATE = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-title-entry`
export const THESIS_DISSERTATION_TITLE_ENTRY_UPDATE = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-title-entry/`
export const THESIS_DISSERTATION_TITLE_ENTRY_DELETE = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-title-entry/`
export const THESIS_DISSERTATION_TITLE_ENTRY_BY_ID = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-title-entry/`

export const THESIS_DISSERTATION_TITLE_LIST_SUPERVISOR = `${TRAINING_INSTITUTE_URL}/title-entry-list-for-supervisor`
export const THESIS_DISSERTATION_TITLE_APPROVE_BY_SUPERVISOR = `${TRAINING_INSTITUTE_URL}/thesis-title-approval`

export const THESIS_DISSERTATION_PROTOCOL_LIST_SUPERVISOR = `${TRAINING_INSTITUTE_URL}/protocol-entry-list-for-supervisor`
export const THESIS_DISSERTATION_PROTOCOL_APPROVE_BY_SUPERVISOR = `${TRAINING_INSTITUTE_URL}/protocol-approval`

export const THESIS_DISSERTATION_FILE_LIST_SUPERVISOR = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-file-list-for-supervisor`
export const THESIS_DISSERTATION_FILE_APPROVE_BY_SUPERVISOR = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-file-approval`


export const THESIS_DISSERTATION_PROTOCOL_LIST_ADMIN = `${TRAINING_INSTITUTE_URL}/protocol-entry-list`
export const THESIS_DISSERTATION_PROTOCOL_APPROVE_BY_ADMIN = `${REGISTRATION_URL}/approval/protocol-admin-approval`
export const THESIS_DISSERTATION_PROTOCOL_REVIEWER_ASSIGN_BY_ADMIN = `${TRAINING_INSTITUTE_URL}/thesis-protocol-reviewer-assign`
export const THESIS_DISSERTATION_THESIS_LIST = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-file-list-for-admin`
export const THESIS_DISSERTATION_THESIS_EXAMINER_ASSIGN_BY_ADMIN = `${TRAINING_INSTITUTE_URL}/thesis-examiner-assign`

export const SCHEDULE_LIST_FOR_EXAMINER = `${TRAINING_INSTITUTE_URL}/thesis-exam-scedule-list`
export const SEND_EMAIL_FOR_EXAMINER = `${TRAINING_INSTITUTE_URL}/send-email-to-examiners/`
export const THESIS_LIST_FOR_EXAMINER = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-file-list-for-examiner`
export const THESIS_MARKING_BY_EXAMINER = `${TRAINING_INSTITUTE_URL}/thesis-marking-by-examiner`

export const THESIS_DISSERTATION_PROTOCOL_LIST_REVIEWER = `${TRAINING_INSTITUTE_URL}/protocol-list-for-review`
export const THESIS_DISSERTATION_PROTOCOL_APPROVE_BY_REVIEWER = `${TRAINING_INSTITUTE_URL}/protocol-review-approval`

export const THESIS_DISSERTATION_FILE_APPROVE_BY_RTMD_USER = `${REGISTRATION_URL}/approval/thesis-file-admin-approval`

export const THESIS_DISSERTATION_REVIEWER_LIST = `${AUTH_URL}/get-users-by-permission/can-review-thesis-dissertation`
export const THESIS_DISSERTATION_EXAMINER_LIST = `${AUTH_URL}/get-users-by-role/Thesis Examiner`
export const THESIS_DISSERTATION_PROTOCOL_REVIEWER_LIST = `${AUTH_URL}/get-users-by-role/Protocol Reviewer`
export const THESIS_DISSERTATION_TITLE_CHANGE_REQUEST = `${AUTH_URL}/change-request`
export const THESIS_DISSERTATION_TRAINEE_CHANGE_REQUEST_LIST = `${AUTH_URL}/trainee-change-request`
export const THESIS_DISSERTATION_TRAINEE_DELETE_CHANGE_REQUEST = `${AUTH_URL}/delete-change-request`
export const THESIS_DISSERTATION_ALL_CHANGE_REQUESTS = `${AUTH_URL}/all-change-request`
export const THESIS_DISSERTATION_CHANGE_REQUEST_APPROVAL = `${AUTH_URL}/change-request-approval`

// THESIS DISSERTATION PROTOCOL ENTRY
export const THESIS_DISSERTATION_PROTOCOL_ENTRY_LIST = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-protocol-list`
export const THESIS_DISSERTATION_PROTOCOL_ENTRY_CREATE = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-protocol-entry`
export const THESIS_DISSERTATION_PROTOCOL_ENTRY_UPDATE = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-protocol-entry/`
export const THESIS_DISSERTATION_PROTOCOL_ENTRY_DELETE = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-protocol-list/`
export const THESIS_DISSERTATION_PROTOCOL_ENTRY_BY_ID = `${TRAINING_INSTITUTE_URL}/thesis-creation-form/`
export const THESIS_DISSERTATION_PROTOCOL_COMMENT_SUBMIT = `${TRAINING_INSTITUTE_URL}/protocol-reviewer-comment`
export const THESIS_DISSERTATION_PROTOCOL_COMMENT_LIST = `${TRAINING_INSTITUTE_URL}/protocol-reviewer-comment-list`
export const THESIS_DISSERTATION_ENTRY_COMMENT_SUBMIT = `${TRAINING_INSTITUTE_URL}/thesis-file-reviewer-comment`
export const THESIS_DISSERTATION_ENTRY_COMMENT_LIST = `${TRAINING_INSTITUTE_URL}/file-reviewer-comment-list`

//THESIS MARKSHEET API
export const GET_THESIS_DISSERTATION_FILE_LIST_FOR_ADMIN_BY_PAGINATE = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-file-list-for-admin`
export const GET_NOTIFY_TRAINEE = `${TRAINING_INSTITUTE_URL}/thesis-result-notify-to-trainee`

//LIST OF TITLE PROTOCOL AND THESIS API
export const GET_TITLE_PROTOCOL_THESIS_DISSERTATION_LIST = `${TRAINING_INSTITUTE_URL}/get-all-about-thesis-for-trainee`

//PROTOCOL ENTRY MOCK
export const GET_PROTOCOL_LIST_URL = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-protocol-list`
export const ADD_URL = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-list/add`
export const UPDATE_LIST_URL = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-list/update/`
export const TITLE_URL = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-title-list`
export const UPDATE_URL = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-protocol-entry/`
export const CREATE_URL = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-protocol-entry`

//PROTOCOL ENTRY MOCK VIEW
export const GET_PROTOCOL_LIST_URL_FOR_VIEW = `${TRAINING_INSTITUTE_URL}/thesis-creation-form/`
export const ADD_URL_FOR_VIEW = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-list/add`
export const UPDATE_LIST_URL_FOR_VIEW = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-list/update/`
export const TITLE_URL_FOR_VIEW = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-title-list`
export const UPDATE_URL_FOR_VIEW = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-protocol-entry/`
export const CREATE_URL_FOR_VIEW = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-protocol-entry`

//PROTOCOL SUMMARY VIEW
export const GET_EXISTING_PROTOCOL = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-protocol-list`
export const GET_EXISTING_TITLE = `${TRAINING_INSTITUTE_URL}/thesis-dissertation-title-list`

// INSTITUTE
export const INSTITUTE_LIST_ADMIN = `${TRAINING_INSTITUTE_URL}/institute-registration-list-for-admin`
export const INSTITUTE_LIST_APPROVE_BY_ADMIN = `${REGISTRATION_URL}/approval/institute-registration-approval`


// Report
export const PAYMENT_REPORTS_LIST_ADMIN = `${REGISTRATION_URL}/payment-reports`

// Thesis Exam Dashboard API
export const GET_THESIS_EXAM_DASHBOARD_BASIC_INFO = `${TRAINING_INSTITUTE_URL}/thesis-exam-dashboard`
export const GET_THESIS_EXAM_SCHEDULE_LIST_FOR_DASHBOARD = `${TRAINING_INSTITUTE_URL}/thesis-exam-scedule-list-for-dashboard`

// Trainee Dashboard API
export const GET_TRAINEE_DASHBOARD_INFO = `${REGISTRATION_URL}/trainee-dashboard`

//SUPERVISOR DASHBOARD API
export const GET_SUPERVISOR_DASHBOARD_INFO = `${REGISTRATION_URL}/supervisor-dashboard`

//TRAINING ALLOWANCE
export const CREATE_TRAINING_ALLOWANCE = `${TRAINING_INSTITUTE_URL}/training-allowance-form-entry`
export const CREATE_TRAINING_ALLOWANCE_ELIGIBLE_STATUS_UPDATE = `${TRAINING_INSTITUTE_URL}/training-allowance-eligible-status-update`
export const TRAINING_ALLOWANCE_LIST_FOR_ADMIN = `${TRAINING_INSTITUTE_URL}/training-allowance-report-list`
export const TRAINING_ALLOWANCE_LIST_FOR_TRAINEE = `${TRAINING_INSTITUTE_URL}/training-allowance-report-for-trainee`
