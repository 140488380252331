import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import {
  Button,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { setFromErrors } from 'src/helpers';
import { useCreateChangeSupervisorApplyMutation, useGetCurrentSupervisorQuery } from 'src/rtk/features/myTraining/myTrainingApi';
import { useLazyGetDepartmentsByInstituteQuery } from 'src/rtk/features/setup/department/departmentApi';
import { useGetOrganizationsQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import { useGetSupervisorsByInstituteQuery } from 'src/rtk/features/trainingRecord/trainingRecordlApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

interface Props {
  isOpen: boolean;
  toggle: any;
  id: string;
  trainingType?: string;
  slotName?: string;
  fcpsPartOneInfoForChange: any;
}

const ChangeSupervisorModal = (props: Props) => {
  const { isOpen, toggle, id, trainingType, slotName, fcpsPartOneInfoForChange } = props;

  const initialValues: SupervisorCourseApply = {
    exam_course_id: '',
    supervisor_id: '',
    start_date: '',
    end_date: '',
    course_session: '',
    institute_name: '',
    department: '',
    message: '',
  };

  const [instituteName, setInstituteName] = useState('');
  const [instituteForDeparment, setInstituteForDeparment] = useState('');

  const [selectedInstitue, setSelectedInstitute] = useState<any>('');
  const [selectedDepartment, setSelectedDepartment] = useState<any>('');
  const [selectedSupervisor, setSelectedSupervisor] = useState<any>('');
  const [selectedReason, setSelectedReason] = useState<any>('');

  const [toDate, setToDate] = useState<Date | null>(null);

  const [createChangeSupervisorApply, { isError, isSuccess, error, isLoading }] =
    useCreateChangeSupervisorApplyMutation();

  const { data: instituteLookupData } = useGetOrganizationsQuery();

  const { data: currentSupervisors } = useGetCurrentSupervisorQuery(id);
  // console.log(currentSupervisors);

  const allInstitute: SelectOption[] =
    instituteLookupData?.data?.map((institute) => ({
      value: institute.organization_name,
      label: institute.organization_name,
    })) || [];

  // const { data: departmentLookupData } = useGetDepartmentsByInstituteQuery(instituteForDeparment);
  const [getDepartmentsByInstitute, { data: departmentLookupData }] = useLazyGetDepartmentsByInstituteQuery();

  useEffect(() => {
    if (instituteForDeparment !== '') {
      getDepartmentsByInstitute(instituteForDeparment);
    }
  }, [instituteForDeparment]);

  const allDepartment: SelectOption[] =
    departmentLookupData?.data?.map((department: any) => ({
      value: department.department_name,
      label: department.department_name,
    })) || [];

  const { data: supervisors } =
    useGetSupervisorsByInstituteQuery(instituteName);

  const allSupervisor: SelectOption[] =
    supervisors?.data?.filter((supervisor) => supervisor?.available_slot! > 0)
      ?.map((supervisor) => ({
        value: supervisor?.user_id!,
        label: supervisor?.full_name! + ` (Available ${supervisor?.available_slot} out of ${supervisor?.session_count! + supervisor?.available_slot!})`,
      })) || [];

  const sessionOptions: SelectOption[] = [
    { label: 'Select Session', value: '' },
    { label: 'January-June', value: 'January-June' },
    { label: 'July-December', value: 'July-December' },
  ];

  const allReason: SelectOption[] = [
    { label: 'Transfer', value: 'Transfer' },
    { label: 'Death', value: 'Death' },
    { label: 'Others', value: 'Others' },
  ];

  const err: CustomError = error as CustomError;

  const {
    handleSubmit,
    getFieldProps,
    handleChange,
    touched,
    errors,
    values,
    resetForm,
    setErrors,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      supervisor_id: Yup.string().required('Select Supervisor'),
      course_session: Yup.string().required('Select Session'),
      start_date: Yup.string().required('Select Statring Date'),
      end_date: Yup.string().required('Select End Date'),
      message: selectedReason?.value === 'Others' ? Yup.string().required('Reason is Required') : Yup.string(),
    }),

    onSubmit: (values) => {
      values.exam_course_id = id;
      createChangeSupervisorApply(values);
    },
  });

  const handleFromDateChange = (event: any) => {
    const newToDate = new Date(event.target.value);
    newToDate.setDate(newToDate.getDate() + 180);
    setToDate(newToDate);
  }

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Supervisor Changed successfully');
      toggle(false);
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  const handleInstitute = (e: any) => {
    setInstituteName(e.target.value);
    setInstituteForDeparment(e.target.value);
  };

  useEffect(() => {
    console.log(selectedInstitue?.value);
    if (selectedInstitue !== '') {
      setFieldValue('institute_name', selectedInstitue?.value);
      setInstituteName(selectedInstitue?.value);
      setInstituteForDeparment(selectedInstitue?.value);
    }
  }, [selectedInstitue]);

  useEffect(() => {
    console.log(selectedDepartment?.value);
    if (selectedDepartment !== '') {
      setFieldValue('department', selectedDepartment?.value);
    }
  }, [selectedDepartment]);

  useEffect(() => {
    console.log(selectedSupervisor?.value);
    if (selectedSupervisor !== '') {
      setFieldValue('supervisor_id', selectedSupervisor?.value);
    }
  }, [selectedSupervisor]);

  useEffect(() => {
    if (currentSupervisors?.data?.session_info) {
      setFieldValue('course_session', currentSupervisors?.data?.session_info?.session_name);
    }
  }, [currentSupervisors]);

  useEffect(() => {
    if (selectedReason !== '') {
      selectedReason?.value !== 'Others' ? setFieldValue('message', selectedReason?.value) : values.message = '';
    }
  }, [selectedReason]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="md">
      {isLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <ModalHeader toggle={toggle}>Supervisor Change Request for {trainingType} - {slotName}</ModalHeader>

      <ModalBody>
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div>
                <h6 className="block-heading">Current Supervisor</h6>
                {currentSupervisors &&
                  <div>
                    <div><b>Name: </b>{currentSupervisors?.data?.name}</div>
                    <div><b>Designation: </b>{currentSupervisors?.data?.designation}</div>
                    <div><b>Institute: </b>{currentSupervisors?.data?.institute_name}</div>
                    <div><b>Department: </b>{currentSupervisors?.data?.department}</div>
                  </div>
                }
              </div>
              <hr />
              <div className="row justify-content-between">
                {/* <div className='mb-3'>
                  <Label for="course_session" className='required-field'>Session</Label>
                  <Input
                    type="select"
                    name='course_session'
                    value={values.course_session = currentSupervisors?.data?.session_info?.session_name}
                    onChange={(e)=>{
                      handleChange(e)
                    }}
                    readOnly
                    disabled
                  >
                    {sessionOptions?.map((sessionSelect) => (
                      <option key={sessionSelect.label} value={sessionSelect.value}>
                        {sessionSelect.label}
                      </option>
                    ))}
                  </Input>
                  {errors.course_session && touched.course_session ? (
                      <div className="text-danger">{errors.course_session}</div>
                  ) : null}
                </div> */}
                <div className='mb-3'>
                  <div className="input-group-with-label">
                    <div className="m-0">
                      <Label className="form-Label">FCPS Part-1 Passing Session</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="course_session"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        readOnly
                        value={fcpsPartOneInfoForChange?.fcps_part1_info?.fcps_part_one_passing_session}
                      />
                    </div>
                    <div className="m-0">
                      <Label className="form-Label">FCPS Part-1 Passing Year</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="course_year"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        readOnly
                        value={fcpsPartOneInfoForChange?.fcps_part1_info?.fcps_part_one_passing_year}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="input-group-with-label mb-3">
                    <div className="m-0">
                      <Label className="form-Label required-field">From Date</Label>
                      <Input
                        className="form-control"
                        type="date"
                        name="start_date"
                        onChange={(e) => {
                          handleFromDateChange(e);
                          handleChange(e);
                        }}
                        value={values.start_date = currentSupervisors?.data?.session_info?.session_start_date}
                        readOnly
                      />
                      {errors.start_date && touched.start_date ? (
                        <div className="text-danger">{errors.start_date}</div>
                      ) : null}
                    </div>
                    <div className="m-0">
                      <Label className="form-Label required-field">To Date</Label>
                      <Input
                        className="form-control"
                        type="date"
                        name="end_date"
                        onChange={handleChange}
                        value={values.end_date = currentSupervisors?.data?.session_info?.session_end_date}
                        readOnly
                      />
                      {errors.end_date && touched.end_date ? (
                        <div className="text-danger">{errors.end_date}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className='mb-3'>
                  <Label className="form-label">Institute</Label>
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    value={selectedInstitue}
                    onChange={setSelectedInstitute}
                    options={allInstitute}
                    placeholder='Select Institute'
                    name="institute_name"
                  />
                </div>

                <div className='mb-3'>
                  <Label className="form-label">Department</Label>
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    value={selectedDepartment}
                    onChange={setSelectedDepartment}
                    options={allDepartment}
                    placeholder='Select Department'
                    name="department"
                  />
                  {errors.department && touched.department ? (
                    <div className="text-danger">{errors.department}</div>
                  ) : null}
                </div>

                <div className='mb-3'>
                  <Label className="form-label required-field">Supervisor</Label>
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    value={selectedSupervisor}
                    onChange={setSelectedSupervisor}
                    options={allSupervisor}
                    placeholder='Select Supervisor'
                    name="supervisor_id"
                  />
                  {errors.supervisor_id && touched.supervisor_id ? (
                    <div className="text-danger">{errors.supervisor_id}</div>
                  ) : null}
                </div>

                <div className='mb-3'>
                  <Label className="form-label">Reason</Label>
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    value={selectedReason}
                    onChange={setSelectedReason}
                    options={allReason}
                    placeholder='Select Reason'
                    name="message"
                  />
                </div>
                {selectedReason?.value === 'Others' &&
                  <div>
                    <Label className="form-label required-field">Others</Label>
                    <Input
                      name="message"
                      type="text"
                      placeholder="Reason"
                      onChange={handleChange}
                    ></Input>
                    {errors.message && touched.message ? (
                      <div className="text-danger">{errors.message}</div>
                    ) : null}
                  </div>}

              </div>
            </div>
          </div>
          <hr />
          <div className="d-grid gap-2 d-md-flex justify-content-md-center">
            <Button color="danger" onClick={toggle}>
              Close
            </Button>

            <Button color="success" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ChangeSupervisorModal;
