import { useState } from 'react';
import { Button as BsButton, Col, FormGroup, Row } from 'reactstrap';
import CommentBtn from './CommentBtn';

const StepTwo = (props: any) => {

    const { goToNext, goToPrevious, protocolEntry, titleEntry } = props;
    const [showCommentSectionModal, setShowCommentSectionModal] = useState<boolean>(false);
    const [commentsFieldName, setCommentsFieldName] = useState<string>('');

    return (
        <>
            <div className="col-lg-12 col-sm-12 ">
                {protocolEntry?.id ?
                    <table className="table table-sm table-bordered  ">
                        <thead className="bg-light fixed">
                            <tr className="text-center text-dark font-size-16 fw-bold">
                                <td colSpan={20}>Dissertation/Thesis Protocol</td>
                            </tr>
                        </thead>

                        <tbody className="text-capitalize text-dark font-size-14">
                            <tr>
                                <td className="bg-light fw-bold">Protocol No</td>
                                <td colSpan={2} className='text-center'>{protocolEntry?.protocol_no ?? ''}</td>
                            </tr>
                            <tr>
                                <td>14</td>
                                <td width={'200'} className="fw-bold">Materials and methods</td>
                                <td>
                                    <table className="table table-sm">
                                        <tbody className="text-capitalize text-dark font-size-14">
                                            <tr>
                                                <td className="fw-bold">{'A) Main outcome variables to be studied'}</td>
                                                <td>
                                                    <Row className='justify-content-between align-items-center mb-2'>
                                                        <Col sm={'10'}>
                                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[0]?.value?.value : '' }}></span>
                                                        </Col>
                                                        <CommentBtn
                                                            protocolEntry={protocolEntry}
                                                            showCommentSectionModal={showCommentSectionModal}
                                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                                            commentsFieldName={commentsFieldName}
                                                            setCommentsFieldName={setCommentsFieldName}
                                                            commentsFieldNameToCompare={'material_methods'}
                                                        />
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold">{'B) Confounding variable'}</td>
                                                <td>
                                                    <Row className='justify-content-between align-items-center mb-2'>
                                                        <Col sm={'10'}>
                                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[1]?.value?.value : '' }}></span>
                                                        </Col>
                                                        <CommentBtn
                                                            protocolEntry={protocolEntry}
                                                            showCommentSectionModal={showCommentSectionModal}
                                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                                            commentsFieldName={commentsFieldName}
                                                            setCommentsFieldName={setCommentsFieldName}
                                                            commentsFieldNameToCompare={'material_methods'}
                                                        />
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold">{'C) Study population (mentioning species and strains, ethnic group, age, sex, and other relevant characteristics)'}</td>
                                                <td>
                                                    <Row className='justify-content-between align-items-center mb-2'>
                                                        <Col sm={'10'}>
                                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[2]?.value?.value : '' }}></span>
                                                        </Col>
                                                        <CommentBtn
                                                            protocolEntry={protocolEntry}
                                                            showCommentSectionModal={showCommentSectionModal}
                                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                                            commentsFieldName={commentsFieldName}
                                                            setCommentsFieldName={setCommentsFieldName}
                                                            commentsFieldNameToCompare={'material_methods'}
                                                        />
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold">{'D) Sample size and the statistical basis of it'}</td>
                                                <td>
                                                    <Row className='justify-content-between align-items-center mb-2'>
                                                        <Col sm={'10'}>
                                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[3]?.value?.value : '' }}></span>
                                                        </Col>
                                                        <CommentBtn
                                                            protocolEntry={protocolEntry}
                                                            showCommentSectionModal={showCommentSectionModal}
                                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                                            commentsFieldName={commentsFieldName}
                                                            setCommentsFieldName={setCommentsFieldName}
                                                            commentsFieldNameToCompare={'material_methods'}
                                                        />
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold">{'E) Screening methods, if applicable'}</td>
                                                <td>
                                                    <Row className='justify-content-between align-items-center mb-2'>
                                                        <Col sm={'10'}>
                                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[4]?.value?.value : '' }}></span>
                                                        </Col>
                                                        <CommentBtn
                                                            protocolEntry={protocolEntry}
                                                            showCommentSectionModal={showCommentSectionModal}
                                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                                            commentsFieldName={commentsFieldName}
                                                            setCommentsFieldName={setCommentsFieldName}
                                                            commentsFieldNameToCompare={'material_methods'}
                                                        />
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold">{'F) Sampling method(s)'}</td>
                                                <td>
                                                    <Row className='justify-content-between align-items-center mb-2'>
                                                        <Col sm={'10'}>
                                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[5]?.value?.value : '' }}></span>
                                                        </Col>
                                                        <CommentBtn
                                                            protocolEntry={protocolEntry}
                                                            showCommentSectionModal={showCommentSectionModal}
                                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                                            commentsFieldName={commentsFieldName}
                                                            setCommentsFieldName={setCommentsFieldName}
                                                            commentsFieldNameToCompare={'material_methods'}
                                                        />
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold">{'G) Inclusion and exclusion criteria'}</td>
                                                <td>
                                                    <Row className='justify-content-between align-items-center mb-2'>
                                                        <Col sm={'10'}>
                                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[6]?.value?.value : '' }}></span>
                                                        </Col>
                                                        <CommentBtn
                                                            protocolEntry={protocolEntry}
                                                            showCommentSectionModal={showCommentSectionModal}
                                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                                            commentsFieldName={commentsFieldName}
                                                            setCommentsFieldName={setCommentsFieldName}
                                                            commentsFieldNameToCompare={'material_methods'}
                                                        />
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold">{'H) Operational definitions'}</td>
                                                <td>
                                                    <Row className='justify-content-between align-items-center mb-2'>
                                                        <Col sm={'10'}>
                                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[7]?.value?.value : '' }}></span>
                                                        </Col>
                                                        <CommentBtn
                                                            protocolEntry={protocolEntry}
                                                            showCommentSectionModal={showCommentSectionModal}
                                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                                            commentsFieldName={commentsFieldName}
                                                            setCommentsFieldName={setCommentsFieldName}
                                                            commentsFieldNameToCompare={'material_methods'}
                                                        />
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold">{'I) Flow chart showing the sequence of tasks'}</td>
                                                <td>
                                                    <Row className='justify-content-between align-items-center mb-2'>
                                                        <Col sm={'10'}>
                                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[8]?.value?.value : '' }}></span>
                                                        </Col>
                                                        <CommentBtn
                                                            protocolEntry={protocolEntry}
                                                            showCommentSectionModal={showCommentSectionModal}
                                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                                            commentsFieldName={commentsFieldName}
                                                            setCommentsFieldName={setCommentsFieldName}
                                                            commentsFieldNameToCompare={'material_methods'}
                                                        />
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold">{'J) Procedures of preparing and organizing materials (e.g., into groups)'}</td>
                                                <td>
                                                    <Row className='justify-content-between align-items-center mb-2'>
                                                        <Col sm={'10'}>
                                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[9]?.value?.value : '' }}></span>
                                                        </Col>
                                                        <CommentBtn
                                                            protocolEntry={protocolEntry}
                                                            showCommentSectionModal={showCommentSectionModal}
                                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                                            commentsFieldName={commentsFieldName}
                                                            setCommentsFieldName={setCommentsFieldName}
                                                            commentsFieldNameToCompare={'material_methods'}
                                                        />
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold">{'K) Nature of controls, if applicable'}</td>
                                                <td>
                                                    <Row className='justify-content-between align-items-center mb-2'>
                                                        <Col sm={'10'}>
                                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[10]?.value?.value : '' }}></span>
                                                        </Col>
                                                        <CommentBtn
                                                            protocolEntry={protocolEntry}
                                                            showCommentSectionModal={showCommentSectionModal}
                                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                                            commentsFieldName={commentsFieldName}
                                                            setCommentsFieldName={setCommentsFieldName}
                                                            commentsFieldNameToCompare={'material_methods'}
                                                        />
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold">{'L) Randomization and blinding methods, if and as applicable'}</td>
                                                <td>
                                                    <Row className='justify-content-between align-items-center mb-2'>
                                                        <Col sm={'10'}>
                                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[11]?.value?.value : '' }}></span>
                                                        </Col>
                                                        <CommentBtn
                                                            protocolEntry={protocolEntry}
                                                            showCommentSectionModal={showCommentSectionModal}
                                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                                            commentsFieldName={commentsFieldName}
                                                            setCommentsFieldName={setCommentsFieldName}
                                                            commentsFieldNameToCompare={'material_methods'}
                                                        />
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold">{'M) Equipment\'s to be used'}</td>
                                                <td>
                                                    <Row className='justify-content-between align-items-center mb-2'>
                                                        <Col sm={'10'}>
                                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[12]?.value?.value : '' }}></span>
                                                        </Col>
                                                        <CommentBtn
                                                            protocolEntry={protocolEntry}
                                                            showCommentSectionModal={showCommentSectionModal}
                                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                                            commentsFieldName={commentsFieldName}
                                                            setCommentsFieldName={setCommentsFieldName}
                                                            commentsFieldNameToCompare={'material_methods'}
                                                        />
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold">{'N) Procedures of collecting data (including methods of intervention, measurement, estimation, etc.)'}</td>
                                                <td>
                                                    <Row className='justify-content-between align-items-center mb-2'>
                                                        <Col sm={'10'}>
                                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[13]?.value?.value : '' }}></span>
                                                        </Col>
                                                        <CommentBtn
                                                            protocolEntry={protocolEntry}
                                                            showCommentSectionModal={showCommentSectionModal}
                                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                                            commentsFieldName={commentsFieldName}
                                                            setCommentsFieldName={setCommentsFieldName}
                                                            commentsFieldNameToCompare={'material_methods'}
                                                        />
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold">{'O) Professional assistance from experts, if applicable'}</td>
                                                <td>
                                                    <Row className='justify-content-between align-items-center mb-2'>
                                                        <Col sm={'10'}>
                                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[14]?.value?.value : '' }}></span>
                                                        </Col>
                                                        <CommentBtn
                                                            protocolEntry={protocolEntry}
                                                            showCommentSectionModal={showCommentSectionModal}
                                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                                            commentsFieldName={commentsFieldName}
                                                            setCommentsFieldName={setCommentsFieldName}
                                                            commentsFieldNameToCompare={'material_methods'}
                                                        />
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold">{'P) Procedure of data analysis or interpretation (including methods of statistical analysis, specific methods, and software used for individual analysis)'}</td>
                                                <td>
                                                    <Row className='justify-content-between align-items-center mb-2'>
                                                        <Col sm={'10'}>
                                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[15]?.value?.value : '' }}></span>
                                                        </Col>
                                                        <CommentBtn
                                                            protocolEntry={protocolEntry}
                                                            showCommentSectionModal={showCommentSectionModal}
                                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                                            commentsFieldName={commentsFieldName}
                                                            setCommentsFieldName={setCommentsFieldName}
                                                            commentsFieldNameToCompare={'material_methods'}
                                                        />
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold">{'Q) Quality assurance strategy'}</td>
                                                <td>
                                                    <Row className='justify-content-between align-items-center mb-2'>
                                                        <Col sm={'10'}>
                                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[16]?.value?.value : '' }}></span>
                                                        </Col>
                                                        <CommentBtn
                                                            protocolEntry={protocolEntry}
                                                            showCommentSectionModal={showCommentSectionModal}
                                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                                            commentsFieldName={commentsFieldName}
                                                            setCommentsFieldName={setCommentsFieldName}
                                                            commentsFieldNameToCompare={'material_methods'}
                                                        />
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold">{'R) Time table'}</td>
                                                <td>
                                                    <Row className='justify-content-between align-items-center mb-2'>
                                                        <Col sm={'10'}>
                                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.material_methods ? JSON.parse(protocolEntry?.material_methods)[17]?.value?.value : '' }}></span>
                                                        </Col>
                                                        <CommentBtn
                                                            protocolEntry={protocolEntry}
                                                            showCommentSectionModal={showCommentSectionModal}
                                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                                            commentsFieldName={commentsFieldName}
                                                            setCommentsFieldName={setCommentsFieldName}
                                                            commentsFieldNameToCompare={'material_methods'}
                                                        />
                                                    </Row>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>15</td>
                                <td className="fw-bold">Ethical implication</td>
                                <td>
                                    <Row className='justify-content-between align-items-center mb-2'>
                                        <Col sm={'10'}>
                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.ethical_implication }}></span>
                                        </Col>
                                        <CommentBtn
                                            protocolEntry={protocolEntry}
                                            showCommentSectionModal={showCommentSectionModal}
                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                            commentsFieldName={commentsFieldName}
                                            setCommentsFieldName={setCommentsFieldName}
                                            commentsFieldNameToCompare={'ethical_implication'}
                                        />
                                    </Row>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    :
                    null}
            </div>            

            <FormGroup row className='justify-content-between mt-5'>
                <Col sm={9}>
                    <BsButton color="warning" className='me-3 text-start' onClick={() => {
                        goToPrevious();
                    }}>
                        <i className="fas fa-2x fa-arrow-left-long"></i>
                    </BsButton>
                </Col>

                <Col sm={3}>
                    <div className='d-flex justify-content-end'>
                        <BsButton color="success" style={{ pointerEvents: 'auto' }} onClick={() => {
                            goToNext();
                        }}>
                            <i className="fas fa-2x fa-arrow-right-long"></i>
                        </BsButton>
                    </div>
                </Col>
            </FormGroup>
        </>
    )
};

export default StepTwo;
