import { apiSlice } from 'src/rtk/api/apiSlice';
import * as thesisEntryFormSetupUrl from 'src/helpers/url_helper';

export const thesisEntryFormSetupApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['thesisEntryForms'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getThesisDissertationFormSetups: builder.query<APIResponse<SetupThesisEntryForm[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${thesisEntryFormSetupUrl.GET_THESIS_DISSERTATION_FORM_SETUPS}?query=${'all'}`,
        }),

        providesTags: ['thesisEntryForms'],
      }),

      getThesisDissertationFormSetup: builder.query<APIResponse<SetupThesisEntryForm>, string>({
        query: (id: string) => ({
          url: `${thesisEntryFormSetupUrl.GET_THESIS_DISSERTATION_FORM_SETUP}/${id}`,
          method: 'GET',
        }),

        providesTags: ['thesisEntryForms'],
      }),

      createThesisDissertationFormSetup: builder.mutation<APIResponse<SetupThesisEntryForm>, SetupThesisEntryForm>({
        query: (data: SetupThesisEntryForm) => ({
          url: `${thesisEntryFormSetupUrl.CREATE_THESIS_DISSERTATION_FORM_SETUP}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['thesisEntryForms'],
      }),

      updateThesisDissertationFormSetup: builder.mutation<APIResponse<SetupThesisEntryForm>, string>({
        query: (url: string) => ({
          url: `${thesisEntryFormSetupUrl.UPDATE_THESIS_DISSERTATION_FORM_SETUP}` + url,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['thesisEntryForms'],
      }),

      deleteThesisDissertationFormSetup: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${thesisEntryFormSetupUrl.DELETE_THESIS_DISSERTATION_FORM_SETUP}/${id}`,
        }),

        invalidatesTags: ['thesisEntryForms'],
      }),
    }),
  });

export const {
  useGetThesisDissertationFormSetupsQuery,
  useGetThesisDissertationFormSetupQuery,
  useCreateThesisDissertationFormSetupMutation,
  useUpdateThesisDissertationFormSetupMutation,
  useDeleteThesisDissertationFormSetupMutation,
} = thesisEntryFormSetupApi;
