import React from "react";
import AccreditationInformation from "./AccreditationInformation";
import OperationTheater from "./OperationTheater";

const SubjectAccreditation = () => {
  return (
    <div className="page-content">
      <AccreditationInformation />
    </div>
  );
};

export default SubjectAccreditation;
