import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { Container, Form, Label, FormGroup, Input } from 'reactstrap';
import { InputField, Button } from 'src/components';
import { useFormik } from 'formik';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import toast from 'react-hot-toast';
import { setFromErrors } from 'src/helpers';
import { useGetDepartmentsQuery } from 'src/rtk/features/setup/department/departmentApi';
import { useCreateRoomMutation } from 'src/rtk/features/setup/room/roomApi';
import { useHistory } from 'react-router-dom';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';

const initialValues: Room = {
  id: '',
  room_name: '',
  department_id: '',
  building_no: '',
  floor_no: '',
  size: '',
  capacity: '',
  room_category: '',
  room_description: '',
  status: false,
  creator_organization_id: '',
  updater_organization_id: '',
  created_by: '',
  updated_by: '',
  deleted_by: '',
  created_at: '',
  updated_at: '',
  deleted_at: '',
};

export const AddRoom = () => {
  const [createRoom, { isSuccess, isError, error }] = useCreateRoomMutation();

  const { data } = useGetDepartmentsQuery();
  const departments = data?.data || [];

  const history = useHistory();

  const err: CustomError = error as CustomError;
  const { handleSubmit, getFieldProps, touched, errors, resetForm, setErrors } =
    useFormik({
      initialValues,
      validationSchema: Yup.object({
        room_name: Yup.string().required('Please Enter Room Name'),
        department_id: Yup.string().required('Room must be under a department'),
      }),

      onSubmit: (values) => {
        createRoom({
          ...values,
        });
        console.log(values);
      },
    });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Room created successfully');
      history.push('/setup/room');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  // let op: SelectOption[] = [{ label: 'Select a Department', value: '' }];
  let op: SelectOption[] = [
    ...departments?.map((e: Department) => ({
      label: e.department_name!,
      value: e.id!,
    })),
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Add Room'}
          breadcrumbItem={[{ link: '/setup/room/', name: 'Room' }]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="card mt-3">
            <h5 className="card-header">
              <i className="fa fa-plus me-2" />
              Add Room
            </h5>
            <div className="card-body">
              <Form
                className="custom-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="room_name">Room Name</Label>
                      <InputField
                        type="text"
                        placeholder="Room Name"
                        invalid={{ errors, touched }}
                        {...getFieldProps('room_name')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="department_id">Department</Label>
                      <InputField
                        type="select"
                        placeholder="Department"
                        options={op}
                        invalid={{ errors, touched }}
                        {...getFieldProps('department_id')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="building_no">Building No</Label>
                      <InputField
                        type="text"
                        placeholder="Building No"
                        invalid={{ errors, touched }}
                        {...getFieldProps('building_no')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="floor_no">Floor No</Label>
                      <InputField
                        type="text"
                        placeholder="Floor No"
                        invalid={{ errors, touched }}
                        {...getFieldProps('floor_no')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="size">Size</Label>
                      <InputField
                        type="text"
                        placeholder="Size"
                        invalid={{ errors, touched }}
                        {...getFieldProps('size')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="capacity">Capacity</Label>
                      <InputField
                        type="text"
                        placeholder="Capacity"
                        invalid={{ errors, touched }}
                        {...getFieldProps('capacity')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="capacity">Room Category</Label>
                      <InputField
                        type="text"
                        placeholder="Room Category"
                        invalid={{ errors, touched }}
                        {...getFieldProps('room_category')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="capacity">Room Description</Label>
                      <InputField
                        type="text"
                        placeholder="Room Description"
                        invalid={{ errors, touched }}
                        {...getFieldProps('room_description')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup check className="mb-3 mt-4">
                      <Label check>
                        <Input type="checkbox" {...getFieldProps('status')} />{' '}
                        Status
                      </Label>
                    </FormGroup>
                  </div>
                </div>
                <Button type="submit" text="Submit" />
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
