import React, { useState } from 'react';
import BarChart from "./barchart";
//import Breadcrumbs
import toast from 'react-hot-toast';
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Modal,
    Progress,
    Row,
    Table,
} from "reactstrap";
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetTraineeDashboardInfoQuery } from 'src/rtk/features/traineeDashboard/traineeDashboardApi';

const TraineeDashboard = (props: any) => {
    const { mainDashboard, personalProfile } = props;
    document.title = 'Trainee Dashboard | BCPS';

    const completeIcon = `<i class=" fas fa-check"></i>`;
    const defaultIcon = `<i class="fas fa-circle"></i>`;
    const youAreHereIcon = `<i class="fas fa-map-marker-alt"></i>`;

    const [timelineModalShow, setTimeLineModalShow] = useState<boolean>(false);
    const [viewDetailsModalShow, setViewDetailsModalShow] = useState<boolean>(false);

    const {
        data: traineeDashboardData,
        isSuccess: isTraineeDashboardDataSuccess,
        isError: isTraineeDashboardDataError,
        isLoading: isTraineeDashboardDataLoading,
        error: traineeDashboardDataError,
    } = useGetTraineeDashboardInfoQuery();

    var progressData = [
        {
            icon: `fas fa-book-open`,
            title: `FCPS Part-I`,
            isComplete: traineeDashboardData?.data?.fcps_part_1,
        },

        {
            icon: `fas fa-book-medical`,
            title: `FCPS Part-II`,
            isComplete: traineeDashboardData?.data?.fcps_part_2,
        },
        {
            icon: `fas fa-clinic-medical`,
            title: `Core Training Year-1`,
            isComplete: traineeDashboardData?.data?.core_training_year_1_slot_2,
        },

        {
            icon: `fas fa-clinic-medical`,
            title: `Core Training Year-2`,
            isComplete: traineeDashboardData?.data?.core_training_year_2_slot_2,
        },

        {
            icon: `fas fa-notes-medical`,
            title: `Mid-Term Exam`,
            isComplete: traineeDashboardData?.data?.mid_term_exam,
        },
        {
            icon: `fas fa-stethoscope`,
            title: `Advanced Training Year-1`,
            isComplete: traineeDashboardData?.data?.advance_training_year_1_slot_2,
        },

        {
            icon: `fas fa-stethoscope`,
            title: `Advanced Training Year-2`,
            isComplete: traineeDashboardData?.data?.advance_training_year_2,
        },
        {
            icon: `fas fa-stethoscope`,
            title: `Advanced Training Year-3`,
            isComplete: traineeDashboardData?.data?.advance_training_year_3,
        },


        {
            icon: ` fas fa-heartbeat`,
            title: `Submission of Thesis`,
            isComplete: traineeDashboardData?.data?.thesis_submission,
        },

        {
            icon: `fas fa-notes-medical`,
            title: `FCPS Final Exam`,
            isComplete: traineeDashboardData?.data?.['fcps-final-exam'],
        },
        {
            icon: ` fas fa-certificate`,
            title: `Fellowship`,
            isComplete: traineeDashboardData?.data?.fellowship,
        },


    ]

    var progressPercentage = 0;

    var thesisDissertationData = [
        {
            date: `01-Jul-2023`,
            title: `Title`,
            isComplete: true,
        },

        {
            date: ``,
            title: `Protocol`,
            isComplete: true,
        },

        {
            date: ``,
            title: `Thesis/Dissertation`,
            isComplete: false,
        },
    ];

    var thesisDissertationDataForTimelineModal = [
        {
            date: `01-Jul-2023`,
            title: `Title approved by <br/> Guide. Co-Guide`,
            isComplete: true,
        },

        {
            date: ``,
            title: `Protocol Submitted to<br/>SCI'S`,
            isComplete: true,
        },
        {
            date: ``,
            title: `Protocol Approved by<br/>BCPS`,
            isComplete: false,
        },

        {
            date: ``,
            title: `Thesis/Dissertation<br/>Submitted to BCPS`,
            isComplete: false,
        },

        {
            date: ``,
            title: `Thesis Defense:<br/>Passed/Failed`,
            isComplete: false,
        },
        {
            date: ``,
            title: `Dissertation Review:<br/>Accepted/Rejected`,
            isComplete: false,
        },
    ];


    var thesisDissertationPercentage = 0;


    return (
        <>
            {
                mainDashboard ?
                    <React.Fragment >
                        <BetterBreadcrumb title='Trainee Dashboard' />
                        {personalProfile?.data?.details?.bmdc_registration_no_verified ===
                            'Pending' ? (
                            <Alert color='danger'>Your BMDC Number is Under Review!</Alert>
                        ) : null}
                        {/* <br /> */}
                        {personalProfile?.data?.details?.profile_approval_status ===
                            'Submit' ? (
                            <Alert color='danger'> Your Registration Under Review! </Alert>
                        ) : null}
                        {personalProfile?.data?.details?.profile_approval_status ===
                            'Correction' ? (
                            <Alert color='danger'>
                                {' '}
                                Please Update Your Profile With Correct Information! {' '}{personalProfile?.data?.details?.profile_approval_note}
                            </Alert>
                        ) : null}
                        {personalProfile?.data?.details?.profile_approval_status ===
                            'Reject' ? (
                            <Alert color='danger'> Your Profile Rejected! </Alert>
                        ) : null}
                        <Row id='trainee-dashboard' style={personalProfile?.data?.details?.profile_approval_status !== 'Approve' ? { pointerEvents: 'none' } : {}}>
                            <Col md="12">
                                <Card>
                                    <CardBody>
                                        <Row className='mt-3'>
                                            <Col xxl={2} xl={2} md={4} sm={12} className='' >
                                                <div className='bg-primary bg-opacity-75 text-white card' role='button' onClick={(e) => {
                                                    window.open(`/trainee/course-list`, '_blank');
                                                }}>
                                                    <div className="text-center p-2">
                                                        <h5 className="fw-normal text-white">
                                                            FCPS Apply
                                                        </h5>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                <div className='bg-secondary bg-opacity-75 text-white card' role='button' onClick={(e) => {
                                                    window.open(`/trainee/trainee-registration-worklist`, '_blank');
                                                }}>
                                                    <div className="text-center p-2">
                                                        <h5 className="fw-normal text-white">
                                                            My Training
                                                        </h5>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                <div className='bg-success bg-opacity-75 text-dark card' role='button' onClick={(e) => {
                                                    window.open(`/trainee/offline-payment-list`, '_blank');
                                                }}>
                                                    <div className="text-center p-2">
                                                        <h5 className="fw-normal text-dark">
                                                            Payment List
                                                        </h5>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                <div className='bg-danger bg-opacity-75 text-dark card' role='button' onClick={(e) => {
                                                    window.open(`/trainee/logbook-entry`, '_blank');
                                                }}>
                                                    <div className="text-center p-2">
                                                        <h5 className="fw-normal text-dark">
                                                            Log Entry
                                                        </h5>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                <div className='bg-warning bg-opacity-75 text-dark card' role='button' onClick={(e) => {
                                                    window.open(`/trainee/logbook/academic-activities`, '_blank');
                                                }}>
                                                    <div className="text-center p-2">
                                                        <h5 className="fw-normal text-dark">
                                                            Academic Activity
                                                        </h5>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                <div className='bg-info bg-opacity-75 text-dark card' role='button' onClick={(e) => {
                                                    window.open(`/trainee/training-summary`, '_blank');
                                                }}>
                                                    <div className="text-center p-2">
                                                        <h5 className="fw-normal text-dark">
                                                            Logbook Summary
                                                        </h5>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                <div className='bg-dark bg-opacity-75 text-white card' role='button' onClick={(e) => {
                                                    window.open(`/thesis-dissertation-title-and-protocol-management`, '_blank');
                                                }}>
                                                    <div className="text-center p-2">
                                                        <h5 className="fw-normal text-white">
                                                            Dissertation/ Thesis
                                                        </h5>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                <div className='bg-primary bg-opacity-75 text-white card' role='button' onClick={(e) => {

                                                }}>
                                                    <div className="text-center p-2">
                                                        <h5 className="fw-normal text-white">
                                                            Exam
                                                        </h5>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                <div className='bg-secondary bg-opacity-75 text-white card' role='button' onClick={(e) => {

                                                }}>
                                                    <div className="text-center p-2">
                                                        <h5 className="fw-normal text-white">
                                                            LMS
                                                        </h5>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                <div className='bg-success bg-opacity-75 text-dark card' role='button' onClick={(e) => {

                                                }}>
                                                    <div className="text-center p-2">
                                                        <h5 className="fw-normal text-dark">
                                                            E-Library
                                                        </h5>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                <div className='bg-danger bg-opacity-75 text-dark card' role='button' onClick={(e) => {

                                                }}>
                                                    <div className="text-center p-2">
                                                        <h5 className="fw-normal text-dark">
                                                            BCPS Updates
                                                        </h5>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                <div className='bg-warning bg-opacity-75 text-dark card' role='button' onClick={(e) => {

                                                }}>
                                                    <div className="text-center p-2">
                                                        <h5 className="fw-normal text-dark">
                                                            Journal
                                                        </h5>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl="12">
                                <Row>
                                    <Col xl="12">
                                        <Card className='progressContainer'>
                                            {/* <CardHeader>
                                    <CardTitle className="h5">Your Progress Map</CardTitle>
                                </CardHeader> */}
                                            <CardBody>
                                                <div className="progressData" >
                                                    <div className="position-relative progressWrapper " >


                                                        {progressData.map((data: any, key: number) => {

                                                            if (data.isComplete === 'active') {
                                                                progressPercentage = ((key / (progressData.length - 1) * 100));
                                                            }

                                                            return (
                                                                <div key={Math.random()} className="position-absolute top-0  translate-middle " style={{ left: (key / (progressData.length - 1) * 100) + "%" }}>
                                                                    <div className={data.isComplete === 'active' ?
                                                                        "bg-success thumbnail" :
                                                                        "bg-white thumbnail"}
                                                                    >
                                                                        <i className={data.icon} ></i>
                                                                    </div>

                                                                    <button
                                                                        className={data.isComplete === 'active' ? 'position-absolute top-0  translate-middle btn btn-sm btn-success rounded-pill' : 'position-absolute top-0  translate-middle btn btn-sm btn-secondary rounded-pill '}
                                                                        dangerouslySetInnerHTML={{ __html: data.isComplete === 'active' ? completeIcon : defaultIcon }}
                                                                    >
                                                                    </button>
                                                                    <div className="position-absolute translate-middle-x text-center  title " style={{ width: "100px", height: "auto", top: "30px" }}
                                                                        dangerouslySetInnerHTML={{ __html: data.title }}>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                        <div className="you-are-here-marker" style={{ left: progressPercentage + 6 + "%" }}>
                                                            <button
                                                                className={'position-absolute top-0  translate-middle btn btn-sm btn-success rounded-pill'}
                                                                dangerouslySetInnerHTML={{ __html: youAreHereIcon }}
                                                            >
                                                            </button>
                                                            <div className="info" >
                                                                You are<br />here
                                                            </div>
                                                        </div>

                                                        <Progress
                                                            striped
                                                            value={progressPercentage + 6}
                                                            color="success"
                                                            style={{ height: "4px" }}
                                                        ></Progress>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <Card>
                                            <CardHeader>
                                                <CardTitle className="h5"><i className="fas fa-book"></i> Logbook Summary</CardTitle>
                                            </CardHeader>
                                            <CardBody>

                                                <Row className='mt-3'>
                                                    <Col xl={3} md={6} sm={12} className='mt-3' >
                                                        <div className='bg-success text-white shadow-primary card-h-100 card '>
                                                            <div className="text-center p-2">
                                                                <i className="fas fa-stethoscope widget-box-1-icon"></i>
                                                                <div className="avatar-md m-auto position-absolute top-0 start-50 translate-middle">
                                                                    <span className="avatar-title rounded-circle bg-light-subtle text-white font-size-24">
                                                                        <i className="fas fa-stethoscope"></i>
                                                                    </span>
                                                                </div>
                                                                <h5 className="mt-4 lh-base fw-normal text-white font-size-18">
                                                                    <b>Patient</b><br />Management
                                                                </h5>
                                                                <h1 className="mt-0 lh-base fw-normal text-primary font-size-24 ">
                                                                    <b>405</b>
                                                                </h1>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xl={3} md={6} sm={12} className='mt-3'>
                                                        <div className='bg-dark text-white shadow-primary card-h-100 card '>
                                                            <div className="text-center p-2">
                                                                <i className=" fas fa-bed widget-box-1-icon"></i>
                                                                <div className="avatar-md m-auto position-absolute top-0 start-50 translate-middle">
                                                                    <span className="avatar-title rounded-circle bg-warning text-white font-size-24">
                                                                        <i className=" fas fa-bed"></i>
                                                                    </span>
                                                                </div>
                                                                <h5 className="mt-4 lh-base fw-normal text-white font-size-18">
                                                                    <b>Procedure</b><br />&nbsp;
                                                                </h5>
                                                                <h1 className="mt-0 lh-base fw-normal text-warning font-size-24 ">
                                                                    <b>65</b>
                                                                </h1>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xl={3} md={6} sm={12} className='mt-3'>
                                                        <div className='bg-primary text-white shadow-primary card-h-100 card '>
                                                            <div className="text-center p-2">
                                                                <i className="fas fa-stethoscope widget-box-1-icon"></i>
                                                                <div className="avatar-md m-auto position-absolute top-0 start-50 translate-middle">
                                                                    <span className="avatar-title rounded-circle bg-danger text-white font-size-24">
                                                                        <i className="fas fa-stethoscope"></i>
                                                                    </span>
                                                                </div>
                                                                <h5 className="mt-4 lh-base fw-normal text-white font-size-18">
                                                                    <b>Interpretation</b><br />&nbsp;
                                                                </h5>
                                                                <h1 className="mt-0 lh-base fw-normal text-danger font-size-24 ">
                                                                    <b>52</b>
                                                                </h1>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xl={3} md={6} sm={12} className='mt-3'>
                                                        <div className='bg-warning text-white shadow-primary card-h-100 card '>
                                                            <div className="text-center p-2">
                                                                <i className=" fas fa-heartbeat widget-box-1-icon"></i>
                                                                <div className="avatar-md m-auto position-absolute top-0 start-50 translate-middle">
                                                                    <span className="avatar-title rounded-circle bg-success text-white font-size-24">
                                                                        <i className=" fas fa-heartbeat"></i>
                                                                    </span>
                                                                </div>
                                                                <h5 className="mt-4 lh-base fw-normal text-white font-size-18">
                                                                    <b>Academic</b><br />Activities
                                                                </h5>
                                                                <h1 className="mt-0 lh-base fw-normal text-success font-size-24 ">
                                                                    <b>12</b>
                                                                </h1>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                </Row>


                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="6">
                                        <Card>
                                            <CardHeader>
                                                <CardTitle className="h5"><i className=" fas fa-calendar-alt"></i> Upcoming Schedule</CardTitle>
                                            </CardHeader>
                                            <CardBody>
                                                <Table className="align-middle mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Event Name</th>
                                                            <th>Date</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">1</th>
                                                            <td>Event 1</td>
                                                            <td>01-Jul-2023</td>
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-light btn-sm"
                                                                >
                                                                    View
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">2</th>
                                                            <td>Event 2</td>
                                                            <td>05-Jul-2023</td>
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-light btn-sm"
                                                                >
                                                                    View
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">3</th>
                                                            <td>Event 3</td>
                                                            <td>05-Sep-2023</td>
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-light btn-sm"
                                                                >
                                                                    View
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="12">
                                        <Card className='card-h-100'>
                                            <CardHeader>
                                                <CardTitle className="h5"><i className=" fas fa-tasks"></i> Logbook Target vs Achievement</CardTitle>
                                            </CardHeader>
                                            <CardBody>
                                                <BarChart />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={"12"}>
                                        <Card className='thesisDissertationContainer'>
                                            <CardHeader className='row align-items-center justify-content-between'>
                                                <Col md={7} sm={12}>
                                                    <CardTitle className="h5"><i className=" fas fa-notes-medical"></i>&nbsp;Dissertation/Thesis Status</CardTitle>
                                                    <p className="card-text"><b>Title: </b>Section 1.10.33 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC</p>
                                                </Col>
                                                <Col md={4} sm={12}>
                                                    <Button
                                                        type="button"
                                                        color='secondary'
                                                        className='me-2 mb-2'
                                                        onClick={(e) => {
                                                            setTimeLineModalShow(true);
                                                        }}>
                                                        <span className='fas fa-eye'></span> View timeline
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        color='primary'
                                                        className='mb-2'
                                                        onClick={(e) => {
                                                            setViewDetailsModalShow(true);
                                                        }}>
                                                        <span className='fas fa-eye'></span> View details
                                                    </Button>
                                                </Col>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col md={"12"}>
                                                        <div className="thesisDissertationData " >
                                                            <div className="position-relative thesisDissertationWrapper " >
                                                                {thesisDissertationData.map((data: any, key: number) => {

                                                                    if (data.isComplete) {
                                                                        thesisDissertationPercentage = ((key / (thesisDissertationData.length - 1) * 100));
                                                                    }

                                                                    return (
                                                                        <div key={Math.random()} className="position-absolute top-0 translate-middle" style={{ left: (key / (thesisDissertationData.length - 1) * 100) + "%" }}>


                                                                            {data.date && <div className={"thumbnail ms-5"}>{data.date}</div>}

                                                                            <button
                                                                                id="checkButton"
                                                                                className={data.isComplete ? 'position-absolute top-0 ms-5  translate-middle btn btn-sm btn-success rounded-pill' : 'position-absolute top-0 ms-5  translate-middle btn btn-sm btn-secondary rounded-pill'}
                                                                                dangerouslySetInnerHTML={{ __html: data.isComplete ? completeIcon : defaultIcon }}
                                                                            >
                                                                            </button>
                                                                            <div className="position-absolute translate-middle-x text-center"
                                                                                style={{ width: "150px", height: "100px", top: "30px", marginLeft: "55px" }}
                                                                                dangerouslySetInnerHTML={{ __html: data.title }}
                                                                            >
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}


                                                                <Progress
                                                                    striped
                                                                    value={thesisDissertationPercentage}
                                                                    color="success"
                                                                    style={{ height: "4px", width: "1180px", marginLeft: "50px" }}
                                                                ></Progress>
                                                            </div>
                                                        </div>

                                                    </Col>
                                                    <Col md='12'>
                                                        <hr />
                                                        <ol className='m-0 d-flex gap-5'>
                                                            <li>Start Date: 01-Jul-23</li>
                                                            <li>Study Period: 12 Months</li>
                                                            <li>Place of Study: DMCH</li>
                                                            <li>Guide: Prof. Dr. Rashedul Islam</li>
                                                            <li>Co-Guide: Asst. Prof. Dr. Kibria, Asst. Prof. Dr. Stranger</li>
                                                        </ol>

                                                    </Col>
                                                </Row>

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Modal
                            onOpened={function () {
                                toast.dismiss();
                            }}
                            isOpen={timelineModalShow}
                            scrollable={true}
                            size='xl'
                            toggle={() => {
                                document.body.classList.add('no_padding');
                            }}
                            backdrop={'static'}
                            modalTransition={{ timeout: 0 }}
                            backdropTransition={{ timeout: 0 }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">
                                    Dissertation/Thesis Timeline
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => {
                                        setTimeLineModalShow(!timelineModalShow);
                                    }}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <Row id='trainee-dashboard'>
                                    <Row>
                                        <Col md={"12"}>
                                            <Card className='thesisDissertationContainer'>
                                                <CardHeader>
                                                    <CardTitle className="h5"><i className=" fas fa-notes-medical"></i>&nbsp;Dissertation/Thesis Status</CardTitle>
                                                    <p className="card-text"><b>Title: </b>Section 1.10.33 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC</p>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col md={"12"}>
                                                            <div className="thesisDissertationData " >
                                                                <div className="position-relative thesisDissertationWrapper " >
                                                                    {thesisDissertationDataForTimelineModal.map((data: any, key: number) => {

                                                                        if (data.isComplete) {
                                                                            thesisDissertationPercentage = ((key / (thesisDissertationData.length - 1) * 100));
                                                                        }

                                                                        return (
                                                                            <div key={Math.random()} className="position-absolute top-0 translate-middle" style={{ left: (key / (thesisDissertationData.length - 1) * 100) + "%" }}>


                                                                                {data.date && <div className={"thumbnail ms-5"}>{data.date}</div>}

                                                                                <button
                                                                                    id="checkButton"
                                                                                    className={data.isComplete ? 'position-absolute top-0 ms-5  translate-middle btn btn-sm btn-success rounded-pill' : 'position-absolute top-0 ms-5  translate-middle btn btn-sm btn-secondary rounded-pill'}
                                                                                    dangerouslySetInnerHTML={{ __html: data.isComplete ? completeIcon : defaultIcon }}
                                                                                >
                                                                                </button>
                                                                                <div className="position-absolute translate-middle-x text-center"
                                                                                    style={{ width: "150px", height: "100px", top: "30px", marginLeft: "55px" }}
                                                                                    dangerouslySetInnerHTML={{ __html: data.title }}
                                                                                >
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}


                                                                    <Progress
                                                                        striped
                                                                        value={thesisDissertationPercentage}
                                                                        color="success"
                                                                        style={{ height: "4px", width: "1180px", marginLeft: "50px" }}
                                                                    ></Progress>
                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col md='12'>
                                                            <hr />
                                                            <ol className='m-0 d-flex gap-5'>
                                                                <li>Start Date: 01-Jul-23</li>
                                                                <li>Study Period: 12 Months</li>
                                                                <li>Place of Study: DMCH</li>
                                                                <li>Guide: Prof. Dr. Rashedul Islam</li>
                                                                <li>Co-Guide: Asst. Prof. Dr. Kibria, Asst. Prof. Dr. Stranger</li>
                                                            </ol>

                                                        </Col>
                                                    </Row>

                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Row>
                            </div>
                        </Modal>

                        <Modal
                            onOpened={function () {
                                toast.dismiss();
                            }}
                            isOpen={viewDetailsModalShow}
                            scrollable={true}
                            size='xl'
                            toggle={() => {
                                document.body.classList.add('no_padding');
                            }}
                            backdrop={'static'}
                            modalTransition={{ timeout: 0 }}
                            backdropTransition={{ timeout: 0 }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">
                                    Dissertation/Thesis Details
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => {
                                        setViewDetailsModalShow(!viewDetailsModalShow);
                                    }}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <h6 className="block-heading">Trainee Information</h6>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <table className="table table-bordered table-striped table-sm">
                                                <tbody>
                                                    <tr>
                                                        <td className="col-md-6 col-lg-6">
                                                            <strong>Trainee Name: </strong>
                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.trainee_name}
                                                        </td>
                                                        <td className="col-md-6 col-lg-6">
                                                            <strong>BMDC Reg. No: </strong>
                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.bmdc_registration_no}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <h6 className="block-heading">Dissertation/Thesis Details</h6>
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <table className="table table-bordered table-striped table-sm">
                                                <tbody>
                                                    <tr>
                                                        <td className="col-md-6 col-lg-6">
                                                            <strong>Category: </strong>
                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.category}
                                                        </td>
                                                        <td className="col-md-6 col-lg-6">
                                                            <strong>Title: </strong>
                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.title_of_thesis}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>Place Of Study: </strong>
                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.place_of_study ?? 'N/A'}
                                                        </td>
                                                        <td>
                                                            <strong>Study Period: </strong>
                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.study_period + ' ' + traineeDashboardData?.data?.thesis_disssertation_status?.study_period_unit ?? 'N/A'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>Guide: </strong>
                                                        </td>
                                                        <td>
                                                            <div className='row'>
                                                                <div className="col">
                                                                    <strong>Name:</strong>  {traineeDashboardData?.data?.thesis_disssertation_status?.guide_name ?? 'N/A'} <br />
                                                                    <div className="d-flex gap-1 justify-content-start">
                                                                        <strong>Approval Status:</strong>
                                                                        <div>
                                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_status?.toLowerCase() === 'approve' && (
                                                                                <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                                                                    {'Accepted'}
                                                                                </div>
                                                                            )}
                                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_status?.toLowerCase() === 'reject' && (
                                                                                <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                                                                    {'Rejected'}
                                                                                </div>
                                                                            )}
                                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_status?.toLowerCase() === 'correction' && (
                                                                                <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                                                                    {traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_status}
                                                                                </div>
                                                                            )}
                                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_status !== 'Approve' &&
                                                                                traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_status !== 'Reject' &&
                                                                                traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_status !== 'Correction' && (
                                                                                    <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                                                                        {traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_status ?? 'Pending'}
                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                    <strong>Approval Note: </strong>
                                                                    <span className={traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                                                                        traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                                                                    >
                                                                        {traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_note}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>Co-Guide </strong>
                                                        </td>
                                                        <td>
                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide_1_name &&
                                                                <div className='row card mx-1 my-1'>
                                                                    <div className="col">
                                                                        <strong>Name:</strong>  {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide_1_name ?? 'N/A'} <br />
                                                                        <div className="d-flex gap-1 justify-content-start">
                                                                            <strong>Approval Status:</strong>
                                                                            <div>
                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_status?.toLowerCase() === 'approve' && (
                                                                                    <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                                                                        {'Accepted'}
                                                                                    </div>
                                                                                )}
                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_status?.toLowerCase() === 'reject' && (
                                                                                    <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                                                                        {'Rejected'}
                                                                                    </div>
                                                                                )}
                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_status?.toLowerCase() === 'correction' && (
                                                                                    <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                                                                        {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_status}
                                                                                    </div>
                                                                                )}
                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_status !== 'Approve' &&
                                                                                    traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_status !== 'Reject' &&
                                                                                    traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_status !== 'Correction' && (
                                                                                        <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_status ?? 'Pending'}
                                                                                        </div>
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                        <strong>Approval Note: </strong>
                                                                        <span className={traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                                                                            traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                                                                        >
                                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_note ?? 'N/A'}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide_2_name &&
                                                                <div className='row card mx-1 my-1'>
                                                                    <div className="col">
                                                                        <strong>Name:</strong>  {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide_2_name ?? 'N/A'} <br />
                                                                        <div className="d-flex gap-1 justify-content-start">
                                                                            <strong>Approval Status:</strong>
                                                                            <div>
                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_status?.toLowerCase() === 'approve' && (
                                                                                    <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                                                                        {'Accepted'}
                                                                                    </div>
                                                                                )}
                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_status?.toLowerCase() === 'reject' && (
                                                                                    <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                                                                        {'Rejected'}
                                                                                    </div>
                                                                                )}
                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_status?.toLowerCase() === 'correction' && (
                                                                                    <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                                                                        {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_status}
                                                                                    </div>
                                                                                )}
                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_status !== 'Approve' &&
                                                                                    traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_status !== 'Reject' &&
                                                                                    traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_status !== 'Correction' && (
                                                                                        <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_status ?? 'Pending'}
                                                                                        </div>
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                        <strong>Approval Note: </strong>
                                                                        <span className={traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                                                                            traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                                                                        >
                                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_note ?? 'N/A'}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide_3_name &&
                                                                <div className='row card mx-1 my-1'>
                                                                    <div className="col">
                                                                        <strong>Name:</strong>  {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide_3_name ?? 'N/A'} <br />
                                                                        <div className="d-flex gap-1 justify-content-start">
                                                                            <strong>Approval Status:</strong>
                                                                            <div>
                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_status?.toLowerCase() === 'approve' && (
                                                                                    <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                                                                        {'Accepted'}
                                                                                    </div>
                                                                                )}
                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_status?.toLowerCase() === 'reject' && (
                                                                                    <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                                                                        {'Rejected'}
                                                                                    </div>
                                                                                )}
                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_status?.toLowerCase() === 'correction' && (
                                                                                    <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                                                                        {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_status}
                                                                                    </div>
                                                                                )}
                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_status !== 'Approve' &&
                                                                                    traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_status !== 'Reject' &&
                                                                                    traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_status !== 'Correction' && (
                                                                                        <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_status ?? 'Pending'}
                                                                                        </div>
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                        <strong>Approval Note: </strong>
                                                                        <span className={traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                                                                            traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                                                                        >
                                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_note ?? 'N/A'}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </React.Fragment >
                    :
                    <div className='page-content' style={personalProfile?.data?.details?.profile_approval_status !== 'Approve' ? { pointerEvents: 'none' } : {}}>
                        <React.Fragment >
                            <BetterBreadcrumb title='Trainee Dashboard' />
                            {personalProfile?.data?.details?.bmdc_registration_no_verified ===
                                'Pending' ? (
                                <Alert color='danger'>Your BMDC Number is Under Review!</Alert>
                            ) : null}
                            {/* <br /> */}
                            {personalProfile?.data?.details?.profile_approval_status ===
                                'Submit' ? (
                                <Alert color='danger'> Your Registration Under Review! </Alert>
                            ) : null}
                            {personalProfile?.data?.details?.profile_approval_status ===
                                'Correction' ? (
                                <Alert color='danger'>
                                    {' '}
                                    Please Update Your Profile With Correct Information! {' '}{personalProfile?.data?.details?.profile_approval_note}
                                </Alert>
                            ) : null}
                            {personalProfile?.data?.details?.profile_approval_status ===
                                'Reject' ? (
                                <Alert color='danger'> Your Profile Rejected! </Alert>
                            ) : null}
                            <Row id='trainee-dashboard'>
                                <Col md="12">
                                    <Card>
                                        <CardBody>
                                            <Row className='mt-3'>
                                                <Col xxl={2} xl={2} md={4} sm={12} className='' >
                                                    <div className='bg-primary bg-opacity-75 text-white card' role='button' onClick={(e) => {
                                                        window.open(`/trainee/course-list`, '_blank');
                                                    }}>
                                                        <div className="text-center p-2">
                                                            <h5 className="fw-normal text-white">
                                                                FCPS Apply
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                    <div className='bg-secondary bg-opacity-75 text-white card' role='button' onClick={(e) => {
                                                        window.open(`/trainee/trainee-registration-worklist`, '_blank');
                                                    }}>
                                                        <div className="text-center p-2">
                                                            <h5 className="fw-normal text-white">
                                                                My Training
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                    <div className='bg-success bg-opacity-75 text-dark card' role='button' onClick={(e) => {
                                                        window.open(`/trainee/offline-payment-list`, '_blank');
                                                    }}>
                                                        <div className="text-center p-2">
                                                            <h5 className="fw-normal text-dark">
                                                                Payment List
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                    <div className='bg-danger bg-opacity-75 text-dark card' role='button' onClick={(e) => {
                                                        window.open(`/trainee/logbook-entry`, '_blank');
                                                    }}>
                                                        <div className="text-center p-2">
                                                            <h5 className="fw-normal text-dark">
                                                                Log Entry
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                    <div className='bg-warning bg-opacity-75 text-dark card' role='button' onClick={(e) => {
                                                        window.open(`/trainee/logbook/academic-activities`, '_blank');
                                                    }}>
                                                        <div className="text-center p-2">
                                                            <h5 className="fw-normal text-dark">
                                                                Academic Activity
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                    <div className='bg-info bg-opacity-75 text-dark card' role='button' onClick={(e) => {
                                                        window.open(`/trainee/training-summary`, '_blank');
                                                    }}>
                                                        <div className="text-center p-2">
                                                            <h5 className="fw-normal text-dark">
                                                                Logbook Summary
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                    <div className='bg-dark bg-opacity-75 text-white card' role='button' onClick={(e) => {
                                                        window.open(`/thesis-dissertation-title-and-protocol-management`, '_blank');
                                                    }}>
                                                        <div className="text-center p-2">
                                                            <h5 className="fw-normal text-white">
                                                                Dissertation / Thesis
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                    <div className='bg-primary bg-opacity-75 text-white card' role='button' onClick={(e) => {

                                                    }}>
                                                        <div className="text-center p-2">
                                                            <h5 className="fw-normal text-white">
                                                                Exam
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                    <div className='bg-secondary bg-opacity-75 text-white card' role='button' onClick={(e) => {

                                                    }}>
                                                        <div className="text-center p-2">
                                                            <h5 className="fw-normal text-white">
                                                                LMS
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                    <div className='bg-success bg-opacity-75 text-dark card' role='button' onClick={(e) => {

                                                    }}>
                                                        <div className="text-center p-2">
                                                            <h5 className="fw-normal text-dark">
                                                                E-Library
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                    <div className='bg-danger bg-opacity-75 text-dark card' role='button' onClick={(e) => {

                                                    }}>
                                                        <div className="text-center p-2">
                                                            <h5 className="fw-normal text-dark">
                                                                BCPS Updates
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={2} xl={2} md={4} sm={12} className=''>
                                                    <div className='bg-warning bg-opacity-75 text-dark card' role='button' onClick={(e) => {

                                                    }}>
                                                        <div className="text-center p-2">
                                                            <h5 className="fw-normal text-dark">
                                                                Journal
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>


                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl="12">
                                    <Row>
                                        <Col xl="12">
                                            <Card className='progressContainer'>
                                                {/* <CardHeader>
                                    <CardTitle className="h5">Your Progress Map</CardTitle>
                                </CardHeader> */}
                                                <CardBody>
                                                    <div className="progressData" >
                                                        <div className="position-relative progressWrapper " >


                                                            {progressData.map((data: any, key: number) => {

                                                                if (data.isComplete === 'active') {
                                                                    progressPercentage = ((key / (progressData.length - 1) * 100));
                                                                }

                                                                return (
                                                                    <div key={Math.random()} className="position-absolute top-0  translate-middle " style={{ left: (key / (progressData.length - 1) * 100) + "%" }}>
                                                                        <div className={data.isComplete === 'active' ?
                                                                            "bg-success thumbnail" :
                                                                            "bg-white thumbnail"}
                                                                        >
                                                                            <i className={data.icon} ></i>
                                                                        </div>

                                                                        <button
                                                                            className={data.isComplete === 'active' ? 'position-absolute top-0  translate-middle btn btn-sm btn-success rounded-pill' : 'position-absolute top-0  translate-middle btn btn-sm btn-secondary rounded-pill '}
                                                                            dangerouslySetInnerHTML={{ __html: data.isComplete === 'active' ? completeIcon : defaultIcon }}
                                                                        >
                                                                        </button>
                                                                        <div className="position-absolute translate-middle-x text-center  title " style={{ width: "100px", height: "auto", top: "30px" }}
                                                                            dangerouslySetInnerHTML={{ __html: data.title }}>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}

                                                            <div className="you-are-here-marker" style={{ left: progressPercentage + 6 + "%" }}>
                                                                <button
                                                                    className={'position-absolute top-0  translate-middle btn btn-sm btn-success rounded-pill'}
                                                                    dangerouslySetInnerHTML={{ __html: youAreHereIcon }}
                                                                >
                                                                </button>
                                                                <div className="info" >
                                                                    You are<br />here
                                                                </div>
                                                            </div>

                                                            <Progress
                                                                striped
                                                                value={progressPercentage + 6}
                                                                color="success"
                                                                style={{ height: "4px" }}
                                                            ></Progress>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle className="h5"><i className="fas fa-book"></i> Logbook Summary</CardTitle>
                                                </CardHeader>
                                                <CardBody>

                                                    <Row className='mt-3'>
                                                        <Col xl={3} md={6} sm={12} className='mt-3' >
                                                            <div className='bg-success text-white shadow-primary card-h-100 card '>
                                                                <div className="text-center p-2">
                                                                    <i className="fas fa-stethoscope widget-box-1-icon"></i>
                                                                    <div className="avatar-md m-auto position-absolute top-0 start-50 translate-middle">
                                                                        <span className="avatar-title rounded-circle bg-light-subtle text-white font-size-24">
                                                                            <i className="fas fa-stethoscope"></i>
                                                                        </span>
                                                                    </div>
                                                                    <h5 className="mt-4 lh-base fw-normal text-white font-size-18">
                                                                        <b>Patient</b><br />Management
                                                                    </h5>
                                                                    <h1 className="mt-0 lh-base fw-normal text-primary font-size-24 ">
                                                                        <b>405</b>
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xl={3} md={6} sm={12} className='mt-3'>
                                                            <div className='bg-dark text-white shadow-primary card-h-100 card '>
                                                                <div className="text-center p-2">
                                                                    <i className=" fas fa-bed widget-box-1-icon"></i>
                                                                    <div className="avatar-md m-auto position-absolute top-0 start-50 translate-middle">
                                                                        <span className="avatar-title rounded-circle bg-warning text-white font-size-24">
                                                                            <i className=" fas fa-bed"></i>
                                                                        </span>
                                                                    </div>
                                                                    <h5 className="mt-4 lh-base fw-normal text-white font-size-18">
                                                                        <b>Procedure</b><br />&nbsp;
                                                                    </h5>
                                                                    <h1 className="mt-0 lh-base fw-normal text-warning font-size-24 ">
                                                                        <b>65</b>
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xl={3} md={6} sm={12} className='mt-3'>
                                                            <div className='bg-primary text-white shadow-primary card-h-100 card '>
                                                                <div className="text-center p-2">
                                                                    <i className="fas fa-stethoscope widget-box-1-icon"></i>
                                                                    <div className="avatar-md m-auto position-absolute top-0 start-50 translate-middle">
                                                                        <span className="avatar-title rounded-circle bg-danger text-white font-size-24">
                                                                            <i className="fas fa-stethoscope"></i>
                                                                        </span>
                                                                    </div>
                                                                    <h5 className="mt-4 lh-base fw-normal text-white font-size-18">
                                                                        <b>Interpretation</b><br />&nbsp;
                                                                    </h5>
                                                                    <h1 className="mt-0 lh-base fw-normal text-danger font-size-24 ">
                                                                        <b>52</b>
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xl={3} md={6} sm={12} className='mt-3'>
                                                            <div className='bg-warning text-white shadow-primary card-h-100 card '>
                                                                <div className="text-center p-2">
                                                                    <i className=" fas fa-heartbeat widget-box-1-icon"></i>
                                                                    <div className="avatar-md m-auto position-absolute top-0 start-50 translate-middle">
                                                                        <span className="avatar-title rounded-circle bg-success text-white font-size-24">
                                                                            <i className=" fas fa-heartbeat"></i>
                                                                        </span>
                                                                    </div>
                                                                    <h5 className="mt-4 lh-base fw-normal text-white font-size-18">
                                                                        <b>Academic</b><br />Activities
                                                                    </h5>
                                                                    <h1 className="mt-0 lh-base fw-normal text-success font-size-24 ">
                                                                        <b>12</b>
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </Col>

                                                    </Row>


                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md="6">
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle className="h5"><i className=" fas fa-calendar-alt"></i> Upcoming Schedule</CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <Table className="align-middle mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Event Name</th>
                                                                <th>Date</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">1</th>
                                                                <td>Event 1</td>
                                                                <td>01-Jul-2023</td>
                                                                <td>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-light btn-sm"
                                                                    >
                                                                        View
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">2</th>
                                                                <td>Event 2</td>
                                                                <td>05-Jul-2023</td>
                                                                <td>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-light btn-sm"
                                                                    >
                                                                        View
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">3</th>
                                                                <td>Event 3</td>
                                                                <td>05-Sep-2023</td>
                                                                <td>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-light btn-sm"
                                                                    >
                                                                        View
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="12">
                                            <Card className='card-h-100'>
                                                <CardHeader>
                                                    <CardTitle className="h5"><i className=" fas fa-tasks"></i> Logbook Target vs Achievement</CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <BarChart />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={"12"}>
                                            <Card className='thesisDissertationContainer'>
                                                <CardHeader className='row align-items-center justify-content-between'>
                                                    <Col md={7} sm={12}>
                                                        <CardTitle className="h5"><i className=" fas fa-notes-medical"></i>&nbsp;Dissertation/Thesis Status</CardTitle>
                                                        <p className="card-text"><b>Title: </b>Section 1.10.33 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC</p>
                                                    </Col>
                                                    <Col md={4} sm={12}>
                                                        <Button
                                                            type="button"
                                                            color='secondary'
                                                            className='me-2 mb-2'
                                                            onClick={(e) => {
                                                                setTimeLineModalShow(true);
                                                            }}>
                                                            <span className='fas fa-eye'></span> View timeline
                                                        </Button>
                                                        <Button
                                                            type="button"
                                                            color='primary'
                                                            className='mb-2'
                                                            onClick={(e) => {
                                                                setViewDetailsModalShow(true);
                                                            }}>
                                                            <span className='fas fa-eye'></span> View details
                                                        </Button>
                                                    </Col>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col md={"12"}>
                                                            <div className="thesisDissertationData " >
                                                                <div className="position-relative thesisDissertationWrapper " >
                                                                    {thesisDissertationData.map((data: any, key: number) => {

                                                                        if (data.isComplete) {
                                                                            thesisDissertationPercentage = ((key / (thesisDissertationData.length - 1) * 100));
                                                                        }

                                                                        return (
                                                                            <div key={Math.random()} className="position-absolute top-0 translate-middle" style={{ left: (key / (thesisDissertationData.length - 1) * 100) + "%" }}>


                                                                                {data.date && <div className={"thumbnail ms-5"}>{data.date}</div>}

                                                                                <button
                                                                                    id="checkButton"
                                                                                    className={data.isComplete ? 'position-absolute top-0 ms-5  translate-middle btn btn-sm btn-success rounded-pill' : 'position-absolute top-0 ms-5  translate-middle btn btn-sm btn-secondary rounded-pill'}
                                                                                    dangerouslySetInnerHTML={{ __html: data.isComplete ? completeIcon : defaultIcon }}
                                                                                >
                                                                                </button>
                                                                                <div className="position-absolute translate-middle-x text-center"
                                                                                    style={{ width: "150px", height: "100px", top: "30px", marginLeft: "55px" }}
                                                                                    dangerouslySetInnerHTML={{ __html: data.title }}
                                                                                >
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}


                                                                    <Progress
                                                                        striped
                                                                        value={thesisDissertationPercentage}
                                                                        color="success"
                                                                        style={{ height: "4px", width: "1180px", marginLeft: "50px" }}
                                                                    ></Progress>
                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col md='12'>
                                                            <hr />
                                                            <ol className='m-0 d-flex gap-5'>
                                                                <li>Start Date: 01-Jul-23</li>
                                                                <li>Study Period: 12 Months</li>
                                                                <li>Place of Study: DMCH</li>
                                                                <li>Guide: Prof. Dr. Rashedul Islam</li>
                                                                <li>Co-Guide: Asst. Prof. Dr. Kibria, Asst. Prof. Dr. Stranger</li>
                                                            </ol>

                                                        </Col>
                                                    </Row>

                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Modal
                                onOpened={function () {
                                    toast.dismiss();
                                }}
                                isOpen={timelineModalShow}
                                scrollable={true}
                                size='xl'
                                toggle={() => {
                                    document.body.classList.add('no_padding');
                                }}
                                backdrop={'static'}
                                modalTransition={{ timeout: 0 }}
                                backdropTransition={{ timeout: 0 }}
                            >
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel">
                                        Dissertation/Thesis Timeline
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => {
                                            setTimeLineModalShow(!timelineModalShow);
                                        }}
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <Row id='trainee-dashboard'>
                                        <Row>
                                            <Col md={"12"}>
                                                <Card className='thesisDissertationContainer'>
                                                    <CardHeader>
                                                        <CardTitle className="h5"><i className=" fas fa-notes-medical"></i>&nbsp;Dissertation/Thesis Status</CardTitle>
                                                        <p className="card-text"><b>Title: </b>Section 1.10.33 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC</p>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col md={"12"}>
                                                                <div className="thesisDissertationData " >
                                                                    <div className="position-relative thesisDissertationWrapper " >
                                                                        {thesisDissertationDataForTimelineModal.map((data: any, key: number) => {

                                                                            if (data.isComplete) {
                                                                                thesisDissertationPercentage = ((key / (thesisDissertationData.length - 1) * 100));
                                                                            }

                                                                            return (
                                                                                <div key={Math.random()} className="position-absolute top-0 translate-middle" style={{ left: (key / (thesisDissertationData.length - 1) * 100) + "%" }}>


                                                                                    {data.date && <div className={"thumbnail ms-5"}>{data.date}</div>}

                                                                                    <button
                                                                                        id="checkButton"
                                                                                        className={data.isComplete ? 'position-absolute top-0 ms-5  translate-middle btn btn-sm btn-success rounded-pill' : 'position-absolute top-0 ms-5  translate-middle btn btn-sm btn-secondary rounded-pill'}
                                                                                        dangerouslySetInnerHTML={{ __html: data.isComplete ? completeIcon : defaultIcon }}
                                                                                    >
                                                                                    </button>
                                                                                    <div className="position-absolute translate-middle-x text-center"
                                                                                        style={{ width: "150px", height: "100px", top: "30px", marginLeft: "55px" }}
                                                                                        dangerouslySetInnerHTML={{ __html: data.title }}
                                                                                    >
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}


                                                                        <Progress
                                                                            striped
                                                                            value={thesisDissertationPercentage}
                                                                            color="success"
                                                                            style={{ height: "4px", width: "1180px", marginLeft: "50px" }}
                                                                        ></Progress>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col md='12'>
                                                                <hr />
                                                                <ol className='m-0 d-flex gap-5'>
                                                                    <li>Start Date: 01-Jul-23</li>
                                                                    <li>Study Period: 12 Months</li>
                                                                    <li>Place of Study: DMCH</li>
                                                                    <li>Guide: Prof. Dr. Rashedul Islam</li>
                                                                    <li>Co-Guide: Asst. Prof. Dr. Kibria, Asst. Prof. Dr. Stranger</li>
                                                                </ol>

                                                            </Col>
                                                        </Row>

                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Row>
                                </div>
                            </Modal>

                            <Modal
                                onOpened={function () {
                                    toast.dismiss();
                                }}
                                isOpen={viewDetailsModalShow}
                                scrollable={true}
                                size='xl'
                                toggle={() => {
                                    document.body.classList.add('no_padding');
                                }}
                                backdrop={'static'}
                                modalTransition={{ timeout: 0 }}
                                backdropTransition={{ timeout: 0 }}
                            >
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel">
                                        Dissertation/Thesis Details
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => {
                                            setViewDetailsModalShow(!viewDetailsModalShow);
                                        }}
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <div>
                                        <h6 className="block-heading">Trainee Information</h6>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table table-bordered table-striped table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td className="col-md-6 col-lg-6">
                                                                <strong>Trainee Name: </strong>
                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.trainee_name}
                                                            </td>
                                                            <td className="col-md-6 col-lg-6">
                                                                <strong>BMDC Reg. No: </strong>
                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.bmdc_registration_no}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <h6 className="block-heading">Dissertation/Thesis Details</h6>
                                        <div className="row">
                                            <div className='col-md-12'>
                                                <table className="table table-bordered table-striped table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td className="col-md-6 col-lg-6">
                                                                <strong>Category: </strong>
                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.category}
                                                            </td>
                                                            <td className="col-md-6 col-lg-6">
                                                                <strong>Title: </strong>
                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.title_of_thesis}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Place Of Study: </strong>
                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.place_of_study ?? 'N/A'}
                                                            </td>
                                                            <td>
                                                                <strong>Study Period: </strong>
                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.study_period + ' ' + traineeDashboardData?.data?.thesis_disssertation_status?.study_period_unit ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Guide: </strong>
                                                            </td>
                                                            <td>
                                                                <div className='row'>
                                                                    <div className="col">
                                                                        <strong>Name:</strong>  {traineeDashboardData?.data?.thesis_disssertation_status?.guide_name ?? 'N/A'} <br />
                                                                        <div className="d-flex gap-1 justify-content-start">
                                                                            <strong>Approval Status:</strong>
                                                                            <div>
                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_status?.toLowerCase() === 'approve' && (
                                                                                    <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                                                                        {'Accepted'}
                                                                                    </div>
                                                                                )}
                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_status?.toLowerCase() === 'reject' && (
                                                                                    <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                                                                        {'Rejected'}
                                                                                    </div>
                                                                                )}
                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_status?.toLowerCase() === 'correction' && (
                                                                                    <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                                                                        {traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_status}
                                                                                    </div>
                                                                                )}
                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_status !== 'Approve' &&
                                                                                    traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_status !== 'Reject' &&
                                                                                    traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_status !== 'Correction' && (
                                                                                        <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_status ?? 'Pending'}
                                                                                        </div>
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                        <strong>Approval Note: </strong>
                                                                        <span className={traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                                                                            traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                                                                        >
                                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.guide_approval_note}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Co-Guide </strong>
                                                            </td>
                                                            <td>
                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide_1_name &&
                                                                    <div className='row card mx-1 my-1'>
                                                                        <div className="col">
                                                                            <strong>Name:</strong>  {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide_1_name ?? 'N/A'} <br />
                                                                            <div className="d-flex gap-1 justify-content-start">
                                                                                <strong>Approval Status:</strong>
                                                                                <div>
                                                                                    {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_status?.toLowerCase() === 'approve' && (
                                                                                        <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                                                                            {'Accepted'}
                                                                                        </div>
                                                                                    )}
                                                                                    {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_status?.toLowerCase() === 'reject' && (
                                                                                        <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                                                                            {'Rejected'}
                                                                                        </div>
                                                                                    )}
                                                                                    {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_status?.toLowerCase() === 'correction' && (
                                                                                        <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_status}
                                                                                        </div>
                                                                                    )}
                                                                                    {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_status !== 'Approve' &&
                                                                                        traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_status !== 'Reject' &&
                                                                                        traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_status !== 'Correction' && (
                                                                                            <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_status ?? 'Pending'}
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                            </div>
                                                                            <strong>Approval Note: </strong>
                                                                            <span className={traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                                                                                traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                                                                            >
                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide1_approval_note ?? 'N/A'}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide_2_name &&
                                                                    <div className='row card mx-1 my-1'>
                                                                        <div className="col">
                                                                            <strong>Name:</strong>  {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide_2_name ?? 'N/A'} <br />
                                                                            <div className="d-flex gap-1 justify-content-start">
                                                                                <strong>Approval Status:</strong>
                                                                                <div>
                                                                                    {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_status?.toLowerCase() === 'approve' && (
                                                                                        <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                                                                            {'Accepted'}
                                                                                        </div>
                                                                                    )}
                                                                                    {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_status?.toLowerCase() === 'reject' && (
                                                                                        <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                                                                            {'Rejected'}
                                                                                        </div>
                                                                                    )}
                                                                                    {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_status?.toLowerCase() === 'correction' && (
                                                                                        <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_status}
                                                                                        </div>
                                                                                    )}
                                                                                    {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_status !== 'Approve' &&
                                                                                        traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_status !== 'Reject' &&
                                                                                        traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_status !== 'Correction' && (
                                                                                            <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_status ?? 'Pending'}
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                            </div>
                                                                            <strong>Approval Note: </strong>
                                                                            <span className={traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                                                                                traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                                                                            >
                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide2_approval_note ?? 'N/A'}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide_3_name &&
                                                                    <div className='row card mx-1 my-1'>
                                                                        <div className="col">
                                                                            <strong>Name:</strong>  {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide_3_name ?? 'N/A'} <br />
                                                                            <div className="d-flex gap-1 justify-content-start">
                                                                                <strong>Approval Status:</strong>
                                                                                <div>
                                                                                    {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_status?.toLowerCase() === 'approve' && (
                                                                                        <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                                                                            {'Accepted'}
                                                                                        </div>
                                                                                    )}
                                                                                    {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_status?.toLowerCase() === 'reject' && (
                                                                                        <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                                                                            {'Rejected'}
                                                                                        </div>
                                                                                    )}
                                                                                    {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_status?.toLowerCase() === 'correction' && (
                                                                                        <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                                                                            {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_status}
                                                                                        </div>
                                                                                    )}
                                                                                    {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_status !== 'Approve' &&
                                                                                        traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_status !== 'Reject' &&
                                                                                        traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_status !== 'Correction' && (
                                                                                            <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_status ?? 'Pending'}
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                            </div>
                                                                            <strong>Approval Note: </strong>
                                                                            <span className={traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                                                                                traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                                                                            >
                                                                                {traineeDashboardData?.data?.thesis_disssertation_status?.co_guide3_approval_note ?? 'N/A'}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </React.Fragment >
                    </div>
            }
        </>
    );
};

export default TraineeDashboard;
