import html2pdf from 'html2pdf.js';
import { useEffect } from 'react';

interface Props {
  data: any;
  setFormBId: any;
  setSubmitting?: any;
}

export const FormBTraineePdf = (props: Props) => {
  const { data, setFormBId, setSubmitting } = props;
  const image1 = new Image();
  image1.src = '/images/notice_header.png';
  const image2 = new Image();
  image2.src = '/images/notice_footer.png';
  console.log(data);
  const html =`
    <html>
      <style>
        .print-container{
          padding: 0 15px;
        }
        
        .table-bordered {
          border: 1px solid;
        }

        .wide-column {
          width: 500px;
          background-color: powderblue;
        }
        
        .flex-container {
          display: flex;
          flex-wrap: nowrap;
        }

        .flex-container > div {
          width: 300px;
          margin: 10px;
          text-align: center;
        }

        .fontName {
          font-family: "Times New Roman", Times, serif;
        }
      </style>
      <body>

        <div class="fontName">
          <div style="text-align: center; margin-bottom: 20px">
            <h3>BANGLADESH COLLEGE OF PHYSICIANS AND SURGEONS</h3>
            <h5>MOHAKHALI, DHAKA.</h5>
          </div>
          <div style="text-align: center; margin-bottom: 20px">
            <h5>AGREEMENT AND BUDGET BREAKUP FOR RESEARCH GRANT</h5>
          </div>
          <div style="margin-bottom: 20px">
            <div>Name of the Researcher: ${data?.registration?.personal_info?.full_name ?? ''}</div>
            <div>Title of the Research/Dissertation/Thesis: ${data?.title?.title_of_thesis ?? ''}</div>
            <div>Name of the Guide: ${data?.title?.guide_name ?? ''}</div>
            <div>Name of the Co-Guide(If Applicable): ${data?.title?.co_guide_1_name ?? ''}</div>
            <div style="display: flex; flex-wrap: nowrap">
              <div>BMDC Reg. No: ${data?.registration?.bmdc_registration_no ?? ''} &nbsp; &nbsp;</div>
              <div>Mobile No: ${data?.registration?.personal_info?.mobile ?? ''} &nbsp; &nbsp;</div>
              <div>Email: ${data?.registration?.personal_info?.email ?? ''} &nbsp; &nbsp;</div>
            </div>
          </div>
          <table class="table table-bordered table-sm">
            <thead>
              <tr>
                <th><p>SL</p></th>
                <th><p>Head of expenditure</p></th>
                <th><p>Description</p></th>
                <th><p>No</p></th>
                <th><p>Rate</p></th>
                <th><p>Day</p></th>
                <th><p>Total Amount</p></th>
              </tr>
            </thead>

            <tbody>          
              <tr>
                <td rowSpan="3"><p>1</p></td>
                <td rowSpan="3"><p>Data Collection & Analysis DSA</p></td>
                <td><p>Data Collection by principal investigator/researcher (DSA)</p></td>
                <td><p>1</p></td>
                <td><p>1,500</p></td>
                <td><p>22</p></td>
                <td><p>33,000.00</p></td>
              </tr>
              <tr>
                <td><p>Guide/Supervisor (DSA)</p></td>
                <td><p>1</p></td>
                <td><p>1,500</p></td>
                <td><p>6</p></td>
                <td><p>9,000.00</p></td>
              </tr>
              <tr>
                <td><p>Co-Guide/Reviewer (DSA)</p></td>
                <td><p>1</p></td>
                <td><p>1,500</p></td>
                <td><p>4</p></td>
                <td><p>6,000.00</p></td>
              </tr>
              <tr>
                <td><p>2</p></td>
                <td><p>Research Methodology work processing and its management</p></td>
                <td><p>Office entertainment, Sundry & other expenses</p></td>
                <td><p>1</p></td>
                <td><p>2,000</p></td>
                <td><p>1</p></td>
                <td><p>2,000.00</p></td>
              </tr>
              <tr>
                <td colSpan="2"><b>Total</b></td>
                <td colSpan="4"><b>Fifty thousand only for each the research work</b></td>
                <td><b>50,000.00</b></td>
              </tr>
              <tr>
                <td colSpan="2"><p>Deduct- Source TAX/VAT</p></td>
                <td colSpan="4"><b>10% of honorarium (9,000+6,000)=15,000 tk</b></td>
                <td><p>1,500.00</p></td>
              </tr>
              <tr>
                <td colSpan="2"><b>In Word</b></td>
                <td colSpan="4"><b>After deduction of tax (forty eight thousand five hundred) only for each research</b></td>
                <td><b>48,500.00</b></td>
              </tr>
            </tbody>
          </table>
          <div style="margin: 20px 0px">
            <h5>Terms and Conditions</h5>
            <ol>
              <li>The above mentioned research/Dissertation/Thesis should be submitted to the College by december 2021</li>
              <li>The researcher shall follow the financial rules and regulations of GOB in this regard.</li>
              <li>The research grant will be given after final submission of research report, Bill-vouchers within june 22</li>
              <li>All TAX/VAT/Govt. duty will be paid by the researchers personally as per GOB rules regulation.</li>
              <li>Honorary Secretary and Director, RTM, BCPS & Line director HEMPD will Coordinate the said activities.</li>
            </ol>
          </div>
          <div style="margin-top: 20px">
            <div class="flex-container">
              <div>Seal & Siganture of Researcher <br />Date: </div>
              <div>Concern Officer of RTMD</div>
              <div>Honorary Director (RTM) BCPS</div>
            </div>
          </div>
          <div style="margin: 20px 0px; padding: 15px">
            The above mentioned Research/Dissertation/Thesis budget approved with term and condition.
          </div>
          <div class="flex-container">
            <div>Director(Admin.) BCPS</div>
            <div>Honorary Secratary,BCPS</div>
            <div>Treasure,BCPS</div>
          </div>
        </div>
      </body>
    </html>`
    ;

  useEffect(() => {
    setFormBId('');
    setSubmitting(false);
  }, []);

  const options = {
    margin: [10, 10, 10, 10],
    image: { type: 'png', quality: 0.98 },
    html2canvas: { scale: 2, useCORS: true },
  };

  html2pdf()
    .from(html)
    .set(options)
    .toPdf()
    .get('pdf')
    .then(function (pdf: any) {
      var totalPages = pdf.internal.getNumberOfPages();

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10);
        pdf.setTextColor(150);
      }

      window.open(pdf.output('bloburl'), '_blank');
    });

  return null;
};
