import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Alert, Button, Col, Form, Label, Row } from 'reactstrap';
import { AuthLayout, InputField } from 'src/components';
import { useResetPasswordMutation } from 'src/rtk/features/auth/authApi';
import * as Yup from 'yup';

interface ResetPasswordProps {}

const ResetPassword = ({}: ResetPasswordProps) => {
  const history = useHistory();
  const location = useLocation();
  // const token = location?.search?.split('=')[1];
  // const username = location?.search?.split('=')[2];

  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const username = params.get("username");

  const [resetPassword, { data, isLoading, isSuccess, isError, error }] =
    useResetPasswordMutation();

  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  
  const { handleChange, handleSubmit, errors, touched, values } = useFormik({
    enableReinitialize: true,
    initialValues: {
      token: '',
      password: '',
      password_confirmation: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, 'Password must be 6 characters long')
        .required('Password is required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
          'Must contain at least one uppercase, one lowercase, one numeric and one special character. Example: Abc@123',
        ),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'New password and confirm password is not matched')
        .required('Password confirmation is required'),
    }),
    onSubmit: (values) => {
      console.log({
        ...values,
        token,
      });

      resetPassword({
        ...values,
        token,
      });
    },
  });

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        history.push('/login');
      }, 2000);
    }
  }, [isSuccess, isError]);

  document.title = 'Forget Password | BCPS';

  return (
    <AuthLayout>
      <div className="auth-content my-auto">
        <div className="text-center">
          <h5 className="mb-0">Reset Password</h5>
          <p className="text-muted mt-2">Reset Password with BCPS.</p>
        </div>
        {isSuccess && (
          <Alert color="success">
            <div>
              <i className="mdi mdi-check-circle-outline me-2"></i>
              {data?.message}
            </div>
          </Alert>
        )}
        {isError && (
          <Alert color="danger">
            <div>
              <i className="mdi mdi-check-circle-outline me-2"></i>
              {(error as any)?.message}
            </div>
          </Alert>
        )}

        <div className="row">
          <div className="col-lg-8 mx-auto">
            <Form
              className="custom-form mt-4"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="mb-3">
                <Label className="form-label required-field">Username</Label>
                <InputField
                  name="token"
                  type="text"
                  onChange={handleChange}
                  value={username}
                  disabled
                  invalid={{ errors, touched }}
                />
              </div>
              <div className="input-group password-input mb-4">
                <Label className="form-label required-field">New Password</Label>
                <InputField
                  name="password"
                  type={showPassword ? 'password' : 'text'}
                  placeholder="Enter Password"
                  onChange={handleChange}
                  value={values.password}
                  invalid={{ errors, touched }}
                />
                <Button
                    color="light"
                    size="sm"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <i
                      className={`${
                        showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'
                      }`}
                    />
                  </Button>
              </div>
              <div className="input-group password-input mb-4">
                <Label className="form-label required-field">Confirm Password</Label>
                <InputField
                  name="password_confirmation"
                  type={showConfirmPassword ? 'password' : 'text'}
                  placeholder="Enter Confirm Password"
                  onChange={handleChange}
                  value={values.password_confirmation}
                  invalid={{ errors, touched }}
                />
                <Button
                    color="light"
                    size="sm"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    <i
                      className={`${
                        showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'
                      }`}
                    />
                  </Button>
              </div>

              <Row className="mb-3">
                <Col className="text-end">
                  <button
                    className="btn btn-primary w-100 waves-effect waves-light"
                    type="submit"
                    disabled={isLoading}
                  >
                    Reset
                  </button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>

        <div className="mt-5 text-center">
          <p className="text-muted mb-0">
            <Link to="/login" className="btn btn-sm btn-outline-primary fw-semibold">
              Sign In
            </Link>
          </p>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;
