import html2pdf from 'html2pdf.js';
import moment from 'moment';
import { useEffect } from 'react';

interface Props {
    data: any;
    setShowAllowanceReport: any;
    setAllowanceFormId: any;
}

export const TrainingAllowancePDF = (props: Props) => {
    const { data, setShowAllowanceReport, setAllowanceFormId } = props;

    const image1 = new Image();
    image1.src = '/images/notice_header.png';
    const image2 = new Image();
    image2.src = '/images/notice_footer.png';

    let html =
        `
                <style>   
                    @media print {
                    
                        body {
                            margin: 0;
                            color: #000;
                            background-color: #fff;
                        }
                        .AllowanceReport {
                            padding: 0.5in;
                            color: #000000;
                        }
                    }                                                  
                
                </style>  
                        
               <div>
                    <!--1st page starting-->

                    <div class='d-flex justify-content-end'>
                        <span>ক্রঃ নং-</span>
                    </div>
                    <div class='d-flex justify-content-center mb-1'>
                        <span class='text-decoration h5'>অবৈতনিক প্রশিক্ষণার্থীদের ভাতাদি বাবদ সহায়তা (পারিতোষিক) প্রদান বিল</span>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-12'>
                            <span>নাম (ইংরেজী) Capital letter : </span>
                            ${data?.registration?.personal_info?.full_name ? 
                                `<span>${data?.registration?.personal_info?.full_name?.toUpperCase()}</span>`
                                : 
                                `<span>
                                    ..............................................................................................................................................................................
                                </span>`
                            }
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-6'>
                            <span>বিএমডিসি রেজিষ্ট্রেশন নং : </span>
                            ${data?.registration?.bmdc_registration_no ? 
                                `<span>${data?.registration?.bmdc_registration_no}</span>`
                                : 
                                `<span>....................................................</span>`
                            }                                                        
                        </div>
                        <div class='col-6'>
                            <span>প্রশিক্ষণে যোগদানের তারিখ : </span>                            
                            ${data?.registration?.current_session_and_supervisor_info?.session_start_date ? 
                                `<span>${moment(data?.registration?.current_session_and_supervisor_info?.session_start_date).format('DD-MMM-YYYY')}</span>`
                                : 
                                `<span>.....................................................</span>`
                            } 
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-6'>
                            <span>প্রতিষ্ঠানের নাম : </span>                            
                            ${data?.registration?.current_session_and_supervisor_info?.supervisor?.institute_name ? 
                                `<span>${data?.registration?.current_session_and_supervisor_info?.supervisor?.institute_name}</span>`
                                : 
                                `<span>.........................................................................</span>`
                            } 
                        </div>
                        <div class='col-6'>
                            <span>কর্মরত বিভাগ : </span>                            
                            ${data?.registration?.subject_name ? 
                                `<span>${data?.registration?.subject_name}</span>`
                                : 
                                `<span>...............................................................................</span>`
                            } 
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-6'>
                            <span>প্রশিক্ষণ সময় : </span>                            
                            ${data?.registration?.current_session_and_supervisor_info?.session_start_date && data?.registration?.current_session_and_supervisor_info?.session_end_date ? 
                                `<span>${moment(data?.registration?.current_session_and_supervisor_info?.session_start_date).format('DD-MMM-YYYY')} to  ${moment(data?.registration?.current_session_and_supervisor_info?.session_end_date).format('DD-MMM-YYYY')}</span>`
                                : 
                                `<span>............................................................................</span>`
                            } 
                        </div>
                        <div class='col-6'>
                            <span>প্রশিক্ষণ কাল/ স্লট : </span>
                            <span>০৬ (ছয়) মাস</span>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-6'>
                            <span>আবেদনকারীর রেজিষ্ট্রেশন নং : </span>                                                                       
                            ${data?.registration?.bcps_registration_no ? 
                                `<span>${data?.registration?.bcps_registration_no}</span>`
                                : 
                                `<span>.............................................</span>`
                            } 
                        </div>
                        <div class='col-6'>
                            <span>মোবাইল নম্বর : </span>                                                                                                                            
                            ${data?.registration?.personal_info?.mobile ? 
                                `<span>${data?.registration?.personal_info?.mobile}</span>`
                                : 
                                `<<span>................................................................................</span>`
                            } 
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-6'>
                            <span>ব্যাংকের নাম : </span>                            
                            ${data?.registration?.bank_info?.bank_name ? 
                                `<span>${data?.registration?.bank_info?.bank_name}</span>`
                                : 
                                `<span>...............................................................................</span>`
                            } 
                        </div>
                        <div class='col-6'>
                            <span>শাখা : </span>                            
                            ${data?.registration?.bank_info?.branch_name ? 
                                `<span>${data?.registration?.bank_info?.branch_name}</span>`
                                : 
                                `<span>..................................................................................................</span>`
                            } 
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-6'>
                            <span>ব্যাংক হিসাব নম্বর : </span>                            
                            ${data?.registration?.bank_info?.account_no ? 
                                `<span>${data?.registration?.bank_info?.account_no}</span>`
                                : 
                                `<span>......................................................................</span>`
                            }
                        </div>
                        <div class='col-6'>
                            <span>রাউটিং নম্বর : </span>                            
                            ${data?.registration?.bank_info?.routing_no ? 
                                `<span>${data?.registration?.bank_info?.routing_no}</span>`
                                : 
                                `<span>....................................................................................</span>`
                            }
                        </div>
                    </div>
                    <div class='row'>
                        <div class='col-12'>
                            <p>
                                অবৈতনিক প্রশিক্ষণার্থী হিসেবে পারিতোষিক বাবদ <strong>জুলাই থেকে ডিসেম্বর</strong> ২০২৩, মোট ০৬ (ছয়) মাসের প্রাপ্য ভাতা ২৫,০০০/- X ৬ = ১,৫০,০০০/- (কথায়ঃ এক লক্ষ পঞ্চাশ হাজার টাকা) মাত্র বুঝে পেলাম।
                            </p>
                        </div>
                    </div>
                    <div class='row'>
                        <div class='col-12'>
                            <p>
                                আমি ${data?.registration?.personal_info?.full_name ? `<span>${data?.registration?.personal_info?.full_name}</span>` : `<span>...............................................................................</span>`} এই মর্মে ঘোষণা করছি যে, উল্লেখিত সকল তথ্য সঠিক এবং আমি উক্ত সময়ে প্রশিক্ষণরত ছিলাম। উপরোক্ত তথ্য ভুল প্রমাণিত হলে বিসিপিএস আমার বিরুদ্ধে যে কোন আইনানুগ ব্যবস্থা গ্রহণ করতে পারবে এবং আমি সমুদয় টাকা ফেরত দিতে বাধ্য থাকব।
                            </p>
                        </div>
                    </div>
                    <div class='row justify-content-end mb-4'>
                        <div class='col-2 border border-dark text-center px-2 py-2'>
                            <span>১০ টাকা মূল্যের রেভিনিউ ষ্ট্যাম্প সংযুক্ত করুন।</span>
                        </div>
                        <div class='col-6 border-top border-dark mt-4 ms-4 text-center'>
                            <span>অবৈতনিক প্রশিক্ষণার্থীর পুরো নাম, স্বাক্ষর ও তারিখ</span>
                        </div>
                    </div>
                    <div class='row justify-content-between mb-3'>
                        <div class='col-6 border-bottom border-dark text-center'>
                            <span>পরিচালক (হাসপাতাল)/ সুপারিনটেনডেন্ট এর স্বাক্ষর ও সীল</span>
                        </div>
                        <div class='col-3 border-bottom border-dark text-center'>
                            <span>প্রশিক্ষকের স্বাক্ষর ও সীল</span>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-12'>
                            <span>*******************************************************************************************************************************************************************************</span>
                        </div>
                    </div>
                    <div class='d-flex justify-content-center mb-2'>
                        <span class='text-decoration-underline'>আরটিএমডি'র ব্যবহারের জন্য</span>
                    </div>
                    <div class='row mb-5'>
                        <div class='col-12'>
                            <span>অবৈতনিক প্রশিক্ষণার্থীর দাখিলকৃত প্রশিক্ষণ প্রতিবেদন ও তথ্য প্রমানাদি সঠিক এবং পারিতোষিক প্রদান করা যেতে পারে।</span>
                        </div>
                    </div>
                    <div class='row justify-content-between mb-3'>
                        <div class='col-5 border-top border-dark text-center'>
                            <span><strong>প্রিন্সিপাল রিসার্চ অফিসার (আরটিএমডি)</strong></span>
                        </div>
                        <div class='col-4 border-top border-dark text-center'>
                            <span><strong>অনারারি পরিচালক (আরটিএম)</strong></span>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-12'>
                            <span>*******************************************************************************************************************************************************************************</span>
                        </div>
                    </div>
                    <div class='d-flex justify-content-center mb-1'>
                        <span class='text-decoration-underline'>অর্থ শাখা ও প্রশাসন বিভাগে ব্যবহারের জন্য</span>
                    </div>
                    <div class='row mb-2'>
                        <div class='col-12'>
                            <p>
                                ভাতাদি বাবদ সহায়তা খাতে (কোড নং ৩৬৩১১০২) সরকার থেকে প্রাপ্ত অবৈতনিক প্রশিক্ষণার্থী পারিতোষিক বাবদ দাখিলকৃত বিল, প্রশিক্ষণ প্রদান প্রতিষ্ঠান এবং বিসিপিএস আরটিএমডি প্রতিবেদন মোতাবেক <strong>জুলাই থেকে ডিসেম্বর</strong> ২০২৩, মোট ০৬ (ছয়) মাসের ভাতা ২৫,০০০/- X ৬ = ১,৫০,০০০/- (কথায়ঃ এক লক্ষ পঞ্চাশ হাজার টাকা) মাত্র মঞ্জুর ও প্রদান করা হলো।
                            </p>
                        </div>
                    </div>
                    <div class='row justify-content-between mb-4'>
                        <div class='col-3 text-center'>
                            <span>হিসাবরক্ষণ কর্মকর্তা</span>
                        </div>
                        <div class='col-3 text-center'>
                            <span>উপ পরিচালক (অর্থ)</span>
                        </div>
                        <div class='col-3 text-center'>
                            <span>উপ পরিচালক (প্রশাসন)</span>
                        </div>
                    </div>
                    <div class='row justify-content-between'>
                        <div class='col-3 text-center'>
                            <span>পরিচালক (প্রশাসন)</span>
                        </div>
                        <div class='col-2 text-center'>
                            <span>সচিব</span>
                        </div>
                        <div class='col-2 text-center'>
                            <span>কোষাধ্যক্ষ</span>
                        </div>
                    </div>

                    <!--

                    2nd page starting

                    <div class='d-flex mb-3'>
                        <strong><span class='text-decoration-underline'>অবৈতনিক প্রশিক্ষণার্থীদের ভাতাদি বাবদ সহায়তা (পারিতোষিক) প্রদান বিল ফরমটি জমাদানের নিয়মাবলীঃ</span></strong>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-12'>
                            <ul class='list-unstyled'>
                                <li class='mb-2'>
                                    ১. প্রশিক্ষণ সময় বলতে বর্তমান ৬ মাস প্রশিক্ষন সময়কাল লিখতে হবে।
                                </li>
                                <li class='mb-2'>
                                    ২. প্রশিক্ষণার্থীকে অবশ্যই বিসিপিএস কর্তৃক স্বীকৃত প্রতিষ্ঠানে প্রশিক্ষণ গ্রহণ করতে হবে।
                                </li>
                                <li class='mb-2'>
                                    ৩. আবেদনকারীর রেজিষ্ট্রেশন নম্বরে এফসিপিএস পার্ট-১ পাশের পর রেজিষ্ট্রিকৃত রেজিষ্ট্রেশন নম্বর (যা আইটি বিভাগ সরবরাহ করেছে) লিখতে হবে।
                                </li>
                                <li class='mb-2'>
                                    ৪. পারিতোষিক বিলে অবশ্যই ১০ টাকা মূল্যের রেভিনিউ ষ্ট্যাম্প নির্দিষ্ট স্থানে সংযুক্ত করতে হবে।
                                </li>
                                <li class='mb-2'>
                                    ৫. এফসিপিএস ১ম পর্ব পাসকৃত অবৈতনিক প্রশিক্ষণার্থীদের পারিতোষিক বিল জমাদানের ক্ষেত্রে আবেদনকারীকে পূর্বে আবেদনকৃত ফরমে উল্লেখিত <strong>সচল ব্যাংক হিসাব নম্বর</strong> লিখতে হবে। অন্যথায় টাকা পেতে বিলম্ব হলে/ না পেলে বিসিপিএস কর্তৃপক্ষ দায়ী থাকবে না।
                                </li>
                                <li class='mb-2'>
                                    ৬. পারিতোষিক বিল বিসিপিএস এর আরটিএম বিভাগে জমাদানের সময় আবেদনকারীদের পারিতোষিক বিলে অবৈতনিক প্রশিক্ষণার্থীদের রেজিষ্ট্রেশন খাতায় উল্লেখিত ক্রমিক নম্বর বিল ফরমের উপরে ডান পাশে লিখে জমা দিতে হবে।
                                </li>
                                <li class='mb-2'>
                                    ৭. ইতঃপূর্বে যাঁরা অবৈতনিক প্রশিক্ষণার্থী হিসেবে পারিতোষিকের জন্য আবেদন করেছেন <strong>কেবলমাত্র তাঁরাই</strong> বিল ফরম জমা দিবেন এবং পূর্বের কোন তথ্য পরিবর্তন হলে আরটিএম বিভাগের অনারারি পরিচালক বরাবর লিখিত আবেদনের মাধ্যমে অবহিত করতে হবে।
                                </li>
                                <li class='mb-2'>
                                    ৮. এফসিপিএস ১ম পর্ব পাসকৃত অবৈতনিক প্রশিক্ষণার্থীদের পারিতোষিক বিল জমাদানের ক্ষেত্রে যে সকল আবেদনকারী এফসিপিএস কোর্স ব্যতীত রেসিডেন্সি কোর্স/ ডিপ্লোমা কোর্স/ পেইড ট্রেনিং/ চাকুরিতে আছেন তাঁদেরকে পারিতোষিক বিল জমা না দেয়ার জন্য অনুরোধ করা হলো। তাঁরা এই অনারিয়ামের আওতাভুক্ত নন।
                                </li>
                                <li class='mb-2'>
                                    ৯. নির্ধারিত সময়ের মধ্যে অবশ্যই বিল ফরম দাখিল করতে হবে।
                                </li>
                                <li>
                                    ১০. অসম্পূর্ন ও ত্রুটিপূর্ণ বিল ফরম গ্রহণযোগ্য হবে না।
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class='d-flex mb-3'>
                        <strong><span>বিঃদ্রঃ</span></strong>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-12'>
                            <span>এফসিপিএস ১ম পর্ব পাস করা অবৈতনিক প্রশিক্ষণার্থীগণ একবার ভাতা প্রাপ্ত হওয়ার পর বিরতি দিয়ে পুনরায় প্রশিক্ষণ শুরু করলে নিম্নলিখিত শর্ত সাপেক্ষে প্রশিক্ষণ ভাতা প্রাপ্য হবেনঃ</span>
                        </div>
                    </div>
                    <div class='row mb-1 ms-3'>
                        <div class='col-12'>
                            <ul class='list-unstyled'>
                                <li class='mb-2'>
                                    ১) প্রশিক্ষণ থেকে বিরতি নেয়ার পূর্বে প্রশিক্ষণার্থীকে অবশ্যই কলেজের আরটিএম বিভাগ ও সংশ্লিষ্ট প্রতিষ্ঠানের কর্তৃপক্ষের নিকট থেকে অনুমতি নিতে হবে।
                                </li>
                                <li class='mb-2'>
                                    ২) বিরতি নেয়ার পর পুনরায় প্রশিক্ষণে যোগদান করলে প্রশিক্ষণার্থীকে কলেজের আরটিএম বিভাগে লিখিত আবেদন সহ সংশ্লিষ্ট প্রতিষ্ঠানের যোগদানপত্র এবং সংশ্লিষ্ট যাবতীয় কাগজপত্রাদি কলেজের আরটিএম বিভাগে জমা দিতে হবে।
                                </li>
                                <li class='mb-2'>
                                    ৩) ০৫ বছর মেয়াদী ফেলোশীপ প্রোগ্রামের আওতায় প্রশিক্ষণ গ্রহণকালে যুক্তিসঙ্গত কারণে একজন প্রশিক্ষণার্থী সর্বোচ্চ ০৩ বার প্রশিক্ষণে বিরতি নিতে পারবেন।
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                -->
                
        `;

    useEffect(() => {
        setShowAllowanceReport(false);
        setAllowanceFormId('');
    }, []);

    const options = {
        margin: [20, 10, 10, 10],
        image: { type: 'png', quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
    };

    html2pdf()
        .from(html)
        .set(options)
        .toPdf()
        .get('pdf')
        .then(function (pdf: any) {
            var totalPages = pdf.internal.getNumberOfPages();

            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                pdf.setFontSize(10);
                pdf.setTextColor(150);
                pdf.addImage(image1, "PNG", 0, 0, pdf.internal.pageSize.getWidth(), 20);
                pdf.addImage(image2, "PNG", 0, pdf.internal.pageSize.getHeight() - 10, pdf.internal.pageSize.getWidth(), 10);
            }

            window.open(pdf.output('bloburl'), '_blank');
        });

    return null;
};
