import moment from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Button, Col, Row } from "reactstrap";
import * as url from 'src/helpers/url_helper';
import store from "src/rtk/app/store";
import {
    useCreateByPostFormCommentsMutation,
    useGetCommentsDataByMutation,
} from 'src/rtk/features/disertation-thesis/crudApi';

const CommentSection = (props: any) => {

    const { fieldName, fieldLabel, existingProtocol, setMessageCount } = props;

    const [messages, setMessage] = useState<any>([]);
    const [comment, setComment] = useState<any>({});

    const currentUser: any = store.getState().auth.user;

    const [
        createByPostComment,
        {
            isSuccess: isCommentSuccess,
            isLoading: isCommentLoading,
            isError: isCommentError,
            error: commentError,
        },
    ] = useCreateByPostFormCommentsMutation();

    const [
        commentsDataList,
        {
            isLoading: commentsDataListLoading,
            isSuccess: commentsDataListLoadingSuccess,
            error: commentsDataListLoadingError,
        },
    ] = useGetCommentsDataByMutation();

    const getCommentsData = (id: any, nameOfTheField: any) => {
        commentsDataList(`${url.THESIS_DISSERTATION_PROTOCOL_COMMENT_LIST}/${id}/${nameOfTheField}`).then(function (result: any,) {
            // here you can use the result of promiseB
            if (result?.data?.data) {
                setMessage(result?.data?.data);
                setMessageCount(result?.data?.data);
            }
        });

        // setMessage(commentsData?.data || []);
    };

    useEffect(() => {
        if (isCommentSuccess) {
            getCommentsData(existingProtocol?.data?.id, fieldName);
            toast.dismiss();
        }
        if (isCommentError) {
            toast.dismiss();
            // toast.error('Something went wrong');
        }
    }, [isCommentLoading, isCommentSuccess, isCommentError]);

    useEffect(() => {
        getCommentsData(existingProtocol?.data?.id, fieldName);
    }, []);

    return (
        <>
            <Row>
                <Col sm={12}>
                    {/* <Label
                        className={
                            'form-label me-2 text-capitalize'
                        }
                    >
                        Comments for {fieldLabel}
                    </Label> */}
                    <div className="border rounded">
                        <div
                            className="chat-conversation p-3 px-2"
                            style={{
                                height: '425px',
                                overflow:
                                    'auto',
                            }}
                        >
                            <ul className="list-unstyled">
                                {messages &&
                                    messages.map(
                                        (
                                            message: any,
                                        ) => {
                                            console.log(message?.registration_id, currentUser?.registration_no)
                                            return (
                                                <li
                                                    key={'test_k' + message.id}
                                                    className={message?.registration_id === currentUser?.registration_no ? 'right' : ''}
                                                >
                                                    <div className="conversation-list">
                                                        <div className="ctext-wrap">
                                                            <div className="ctext-wrap-content">
                                                                {
                                                                    message?.registration_id ===
                                                                        currentUser?.registration_no
                                                                        ? (<h5 className="conversation-name">
                                                                            <span className="time me-0">
                                                                                {moment(message.updated_at,).calendar()}
                                                                            </span>
                                                                        </h5>)
                                                                        : (<h5 className="conversation-name">
                                                                            <div className="user-name me-2">
                                                                                {
                                                                                    message.name
                                                                                }
                                                                            </div>
                                                                            <div className="user-name text-white">
                                                                                ({
                                                                                    message.type
                                                                                })
                                                                            </div>
                                                                            <span className="time">
                                                                                {moment(message.updated_at,).calendar()}
                                                                            </span>
                                                                        </h5>)
                                                                }

                                                                <p className="mb-0" dangerouslySetInnerHTML={{ __html: message.comments }}>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            );
                                        },
                                    )}
                            </ul>
                        </div>
                        <div className="p-3 border-top">
                            <Row>
                                <Col>
                                    <div className="position-relative">
                                        <input
                                            type="textarea"
                                            name={'comment'}
                                            value={comment[fieldName] || ''}
                                            onChange={(e: any, key: any = fieldName,) => {
                                                let message = e.target.value || '';

                                                let newComment = {
                                                    ...comment,
                                                    [key]: message,
                                                };


                                                setComment(newComment,);
                                            }}
                                            className="form-control border bg-soft-light"
                                            placeholder={'Enter comment for ' + fieldLabel}
                                        />
                                    </div>
                                </Col>
                                <Col className="col-auto">
                                    <Button
                                        type="button"
                                        color="primary"
                                        onClick={() => {
                                            if (comment[fieldName]) {

                                                let postCommentData =
                                                {
                                                    queryUrl: url.THESIS_DISSERTATION_PROTOCOL_COMMENT_SUBMIT,
                                                    formData:
                                                    {
                                                        protocol_id: existingProtocol?.data?.id,
                                                        comments: comment[fieldName] || '',
                                                        column_name: fieldName,
                                                    },
                                                };

                                                createByPostComment(postCommentData,).then(
                                                    (
                                                        res: any,
                                                    ) => {
                                                        if (
                                                            res.data?.success
                                                        ) {
                                                            comment[fieldName];

                                                            let newComment =
                                                            {
                                                                ...comment,
                                                                [fieldName]: "",
                                                            };

                                                            setComment(newComment,);

                                                        }
                                                    },
                                                );
                                            }
                                        }}
                                        className="btn btn-success chat-send w-md"
                                        disabled={
                                            isCommentLoading
                                        }
                                    >
                                        <span className="d-none d-sm-inline-block me-2">
                                            {isCommentLoading
                                                ? 'Sending...'
                                                : 'Send'}
                                        </span>{' '}
                                        <i className="mdi mdi-send float-end" />
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
};

export default CommentSection;