import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { Col, Form, FormGroup, Label, Modal, Row } from 'reactstrap';
import { Button, InputField } from 'src/components';
import {
  useDeleteLogbookEntryCompetencyGroupMutation,
  usePublishLogbookEntrySetupDetailMutation,
} from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import EditCompetencyGroupDetails from './EditCompetencyGroupDetails';

interface SearchFields {
  status?: string;
}

const initialValues: SearchFields = {
  status: '',
};

const CompetencyGroupDetailsList = (props: any) => {
  document.title = 'Logbook Entry Field Setup';
  const { logbookGroup, logbookEntryFieldList, setSearch } = props;

  const [editFormActive, setEditFormActive] = useState<boolean>(false);
  const [competencyGroupForEdit, setCompetencyGroupForEdit] =
    useState<any>({});
  const [competencyGroupForDelete, setCompetencyGroupForDelete] =
    useState<any>({});
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [deleteEntry, setDeleteEntry] = useState<boolean>(false);
  const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);
  //   const [page, setPage] = useState<string | number>('1');
  //   const [paginate_per_page, set_paginate_per_page] = useState(10);  
  const [editCount, setEditCount] = useState<number>(1);
  const [initialPublishStatus, setInitialPublishStatus] = useState<any>({});
  const [publishStatus, setPublishStatus] = useState<any>({});  
  const [hideBasicInfoColumns, setHideBasicInfoColumns] = useState<boolean>(true);

  const logbookGroupTitleList: string[] = logbookGroup?.title.split(' -> ');

  // const {
  //   data: logbookEntryFieldList,
  //   error: logbookEntryFieldListError,
  //   isLoading: isLogbookEntryFieldListLoading,
  //   isError: isLogbookEntryFieldListError,
  //   isSuccess: isLogbookEntryFieldListSuccess,
  // } = useGetDynamicFormPropertyByParticularUpdatedApiQuery({
  //   id: id,
  //   publish_status: 0,
  //   status: search,
  // });

  const [
    deleteCompetencyGroup,
    {
      data: competencyGroupDeleteData,
      isSuccess: isCompetencyGroupDeleteDataSuccess,
      isError: isCompetencyGroupDeleteDataError,
      error: competencyGroupDeleteDataError,
    },
  ] = useDeleteLogbookEntryCompetencyGroupMutation();

  const [
    publishLogbookEntryField,
    {
      data: logbookEntryFieldPublishData,
      isSuccess: isLogbookEntryFieldPublishDataSuccess,
      isError: isLogbookEntryFieldPublishDataError,
      error: logbookEntryFieldPublishDataError,
    },
  ] = usePublishLogbookEntrySetupDetailMutation();

  const competencyGroupDetailsListData: any = logbookEntryFieldList?.data?.competency_group_with_raw!;

  const editHandler = (competencyGroup: any) => {
    setCompetencyGroupForEdit(competencyGroup);
    setEditCount((e: number) => e + 1);
    setModalShow(true);
  };

  const deleteHandler = (competencyGroup: any) => {
    setCompetencyGroupForDelete(competencyGroup);
    setDeleteModalShow(true);
  };

  // const modalShowHandler = (modalState: boolean, logbookEntryField: any) => {
  //   setModalShow(modalState);
  //   // setLogbookForEdit(logbook);
  //   setEditFormActive(false);
  // };

  const handlePublishStatus = (e: any, id: string) => {
    if (e.target.checked) {
      const newPublishStatus: any = { ...publishStatus };
      newPublishStatus[id] = true;
      setPublishStatus(newPublishStatus);
    } else {
      const newPublishStatus: any = { ...publishStatus };
      newPublishStatus[id] = false;
      setPublishStatus(newPublishStatus);
    }
  };

  const handlePublishAllStatus = (e: any) => {
    if (e.target.checked) {
      const newPublishStatus: any = { ...publishStatus };
      competencyGroupDetailsListData?.forEach((l: LogbookEntryDetails) => {
        newPublishStatus[l?.id!] = true;
      });
      setPublishStatus(newPublishStatus);
    } else {
      const newPublishStatus: any = { ...publishStatus };
      competencyGroupDetailsListData?.forEach((l: LogbookEntryDetails) => {
        newPublishStatus[l?.id!] = false;
      });
      setPublishStatus(newPublishStatus);
    }
  };

  const handlePublishSubmit = (e: any) => {
    const finalPublishStatus: any = {};
    finalPublishStatus.publish_status = [];

    competencyGroupDetailsListData?.forEach((l: LogbookEntryDetails) => {
      if (publishStatus[l?.id!] !== initialPublishStatus[l?.id!]) {
        if (publishStatus[l?.id!]) {
          finalPublishStatus.publish_status.push({ id: l?.id, status: 1 });
        } else {
          finalPublishStatus.publish_status.push({ id: l?.id, status: 0 });
        }
      }
    });

    publishLogbookEntryField(finalPublishStatus);
  };

  //   const handlePageChange = (page: number) => {
  //     setPage(page);
  //   };

  //   const handlePerRowsChange = (newPerPage: number, page: number) => {
  //     set_paginate_per_page(newPerPage);
  //     setPage(page);
  //   };

  useEffect(() => {
    const newPublishStatus: any = {};
    const newInitialPublishStatus: any = {};

    for (let i = 0; i < competencyGroupDetailsListData?.length; ++i) {
      if (competencyGroupDetailsListData[i]?.publish_status === 1) {
        newPublishStatus[competencyGroupDetailsListData[i]?.id!] = true;
        newInitialPublishStatus[competencyGroupDetailsListData[i]?.id!] = true;
      } else {
        newPublishStatus[competencyGroupDetailsListData[i]?.id!] = false;
        newInitialPublishStatus[competencyGroupDetailsListData[i]?.id!] = false;
      }
    }

    setPublishStatus(newPublishStatus);
    setInitialPublishStatus(newInitialPublishStatus);
  }, [competencyGroupDetailsListData]);

  useEffect(() => {
    if (deleteEntry) {      
      deleteCompetencyGroup({logbook_entry_setup_master_id: competencyGroupForDelete?.id});
    }
  }, [deleteEntry]);

  const columns: TableColumn<any>[] = [
    {
      name: (
        <div style={{ textAlign: 'center' }}>
          <FormGroup className="mt-3">
            <Button
              size="md"
              color="light"
              style={{ background: '#ccc', border: 'none', }}
              type="button"
              className="mx-4"
              text={hideBasicInfoColumns ? `<span class="fa-solid fa-ellipsis" ></span>` : `<span class="fa-solid fa-eye-slash" ></span>`}
              onClick={(e) => {
                setHideBasicInfoColumns(!hideBasicInfoColumns);
              }}
            />
          </FormGroup>
        </div>
      ),
      width: '20px',
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      center: true,
      wrap: true,
    },

    {
      name: <div style={{ textAlign: 'center' }}>Sl. No.</div>,
      width: '80px',
      cell: (row, index) => index + 1,
      center: true,
    },
    
    {
      name: <div style={{ textAlign: 'center' }}>Group ID</div>,
      selector: (row) => logbookGroup?.group_id,
      sortable: false,
      center: true,
      wrap: true,
      omit: hideBasicInfoColumns,
    },

    {
      name: <div style={{ textAlign: 'center' }}>Subject</div>,
      selector: (row) => logbookGroupTitleList[0],
      width: '100px',
      sortable: false,
      center: true,
      wrap: true,
      omit: hideBasicInfoColumns,
    },

    {
      name: <div style={{ textAlign: 'center' }}>Main Training</div>,
      selector: (row) => logbookGroupTitleList[1],
      width: '150px',
      sortable: false,
      wrap: true,
      center: true,
      omit: hideBasicInfoColumns,
    },

    {
      name: <div style={{ textAlign: 'center' }}>Core/Specialty</div>,
      selector: (row) => logbookGroupTitleList[2] === 'core' ? 'Core' : 'Specialty',
      width: '150px',
      sortable: false,
      wrap: true,
      center: true,
      omit: hideBasicInfoColumns,
    },

    {
      name: <div style={{ textAlign: 'center' }}>Form</div>,
      selector: (row) => logbookGroupTitleList[3],
      width: '150px',
      sortable: false,
      wrap: true,
      center: true,
      omit: hideBasicInfoColumns,
    },

    {
      name: <div style={{ textAlign: 'center' }}>Case Type</div>,
      selector: (row) => logbookGroupTitleList[4],
      width: '150px',
      sortable: false,
      wrap: true,
      center: true,
      omit: hideBasicInfoColumns,
    },

    {
      name: <div style={{ textAlign: 'center' }}>Skill Type</div>,
      selector: (row) => logbookGroupTitleList[5] ?? '-',
      width: '150px',
      sortable: false,
      wrap: true,
      center: true,
    },    

    {
      name: <div style={{ textAlign: 'center' }}>Competence Title</div>,
      selector: (row) => row.particulars,
      sortable: false,
      width: '250px',
      center: true,
      wrap: true,
    },

    {
      name: <div style={{ textAlign: 'center' }}>Competence Parent</div>,
      selector: (row) => row.parent?.particulars ? row.parent?.particulars : '-',
      width: '250px',
      sortable: false,
      center: true,
      wrap: true,
    },    

    {
      name: <div style={{ textAlign: 'center' }}>Status</div>,
      selector: (row) => (row.publish_status === 1 ? 'Published' : 'Draft'),
      sortable: false,
      wrap: true,
      center: true,
    },

    {
      name: <div style={{ textAlign: 'center' }}>Action</div>,
      width: '150px',
      cell: (row) => (
        <>
          {/* btn-group-example */}
          <FormGroup className="btn-group mt-2">
            <>
              <Button
                size="sm"
                color="success"
                type="button"
                text={`<span class="fas fa-edit" ></span> Edit`}
                onClick={() => {
                  editHandler(row);
                }}
              />
              <Button
                size="sm"
                color="danger"
                type="button"
                text={`<span class="fas fa-trash" ></span> Delete`}
                onClick={() => {
                  deleteHandler(row);
                }}
              />
            </>
          </FormGroup>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      center: true,
      wrap: true,
    },

    // {
    //   name: (
    //     <div style={{ textAlign: 'center' }}>
    //       <FormGroup className="mt-3">
    //         <Button
    //           size="md"
    //           color="success"
    //           type="button"
    //           className="me-2"
    //           text={`<span class="fa-solid fa-upload" ></span> Publish`}
    //           onClick={(e) => {
    //             handlePublishSubmit(e);
    //           }}
    //         />
    //         <Label check>
    //           <Input
    //             name="publish_all"
    //             id="publish_all"
    //             type="checkbox"
    //             onChange={(e) => {
    //               handlePublishAllStatus(e);
    //             }}
    //           />{' '}
    //         </Label>
    //       </FormGroup>
    //     </div>
    //   ),
    //   width: '150px',
    //   cell: (row) => (
    //     <>
    //       <FormGroup check className="mb-3 mt-4">
    //         <Label check>
    //           <Input
    //             name="publish_ind"
    //             id="publish_ind"
    //             type="checkbox"
    //             checked={publishStatus[row?.id]}
    //             onChange={(e) => {
    //               handlePublishStatus(e, row?.id);
    //             }}
    //           />{' '}
    //         </Label>
    //       </FormGroup>
    //     </>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    //   center: true,
    //   wrap: true,
    // },
  ];

  const {
    handleSubmit,
    getFieldProps,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.status !== '') {
        s = s + `${values.status}`;
      }

      setSearch(s);
    },
  });

  useEffect(() => {
    if (!isCompetencyGroupDeleteDataError && isCompetencyGroupDeleteDataSuccess) {
      toast.dismiss();
      toast.success('Competency group deleted successfully');
      setDeleteModalShow(false);
      setDeleteEntry(false);
    } else if (isCompetencyGroupDeleteDataError && !isCompetencyGroupDeleteDataSuccess) {
      toast.dismiss();
      toast.error('Error while deleting the competency group');
    }
  }, [isCompetencyGroupDeleteDataError, isCompetencyGroupDeleteDataSuccess]);

  useEffect(() => {
    if (
      !isLogbookEntryFieldPublishDataError &&
      isLogbookEntryFieldPublishDataSuccess
    ) {
      toast.dismiss();
      toast.success('Logbook Entry Fields Published Successfully');
    } else if (
      isLogbookEntryFieldPublishDataError &&
      !isLogbookEntryFieldPublishDataSuccess
    ) {
      toast.dismiss();
      toast.error('Error while publishing logbook entry fields');
    }
  }, [
    isLogbookEntryFieldPublishDataError,
    isLogbookEntryFieldPublishDataSuccess,
  ]);

  return (
    <>
      <hr />
      <Row>
        <Col>
          <div className="gap-2 w-100">
            <Form
              className="custom-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="filter-wrapper">
                <FormGroup>
                  <Label for="status">Status</Label>
                  <InputField
                    type="select"
                    placeholder=""
                    options={[
                      { label: 'Draft', value: '0' },
                      { label: 'Published', value: '1' },
                    ]}
                    invalid={{ errors, touched }}
                    {...getFieldProps('status')}
                  />
                </FormGroup>

                <FormGroup className="btn-group btn-group-example">
                  <Button
                    color="danger"
                    type="reset"
                    text="Reset"
                    onClick={() => {
                      resetForm();
                      handleSubmit();
                    }}
                  />
                  <Button
                    type="button"
                    text="Search"
                    onClick={() => {
                      handleSubmit();
                    }}
                  />
                </FormGroup>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
      <DataTable
        columns={columns}
        data={competencyGroupDetailsListData ? competencyGroupDetailsListData : []}        
        expandOnRowClicked
        highlightOnHover
      />

      <Modal
        onOpened={function () {
          toast.dismiss();
        }}
        isOpen={modalShow}
        scrollable={true}
        size="xl"
        toggle={() => {
          document.body.classList.add('no_padding');
        }}
        backdrop={'static'}
        modalTransition={{ timeout: 0 }}
        backdropTransition={{ timeout: 0 }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Edit Competency Group
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalShow(!modalShow);
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <Row>
            <Col>
              {competencyGroupForEdit && (
                <EditCompetencyGroupDetails
                  key={editCount}
                  logbookEntryFieldList={logbookEntryFieldList}
                  competencyGroupDetailsForEdit={competencyGroupForEdit}
                  setShowAddForm={setModalShow}
                />
              )}
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        onOpened={function () {
          toast.dismiss();
        }}
        isOpen={deleteModalShow}
        scrollable={true}
        toggle={() => {
          document.body.classList.add('no_padding');
        }}
        backdrop={'static'}
        modalTransition={{ timeout: 0 }}
        backdropTransition={{ timeout: 0 }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Delete Competency Group
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setDeleteModalShow(!deleteModalShow);
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          {competencyGroupForDelete && (
            <Row>
              <Col>
                <p>
                  Are you sure you want to delete the competency group -{' '}
                  {competencyGroupForDelete?.particulars}
                </p>
              </Col>
            </Row>
          )}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setDeleteModalShow(false);
            }}
            className="btn btn-secondary me-2"
            data-dismiss="modal"
          >
            No
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={(e) => {
              setDeleteEntry(true);
            }}
          >
            Yes
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CompetencyGroupDetailsList;
