import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Card,
  CardBody,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Table,
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetPaymentListsByPaginateQuery } from 'src/rtk/features/payment/paymentApprovalApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import loader from '../../assets/images/loader.gif';
import PaymentListTableRow from './PaymentListTableRow';
import PaymentPaginate from './PaymentPaginate';

interface SearchFields {
  bmdc_reg_no?: string;
  subject?: string;
  payment_confirmation_ind?: boolean | null;
  from_date?: string;
  to_date?: string;
  type?: string;
}

const initialValues: SearchFields = {
  bmdc_reg_no: '',
  subject: '',
  payment_confirmation_ind: null,
  from_date: '',
  to_date: '',
  type: '',
};

const ViewPaymentList = () => {
  const [page, setPage] = useState<number | string>('1');
  const [paginate_per_page, set_paginate_per_page] = useState(20);
  const [search, setSearch] = useState('&type=Trainee,Supervisor,Fellow');
  const [selectedSubject, setSelectedSubject] = useState<any>('');

  const [status, setStatus] = useState(false);

  const { data, error, isLoading, isError, isFetching } = useGetPaymentListsByPaginateQuery(
    {
      page,
      paginate_per_page,
      search,
    },
  );

  const paymentInfo: PaymentList[] = data?.data?.data || [];
  const newList = paymentInfo.filter((f) => f.extra_info !== null);

  const err: CustomError = error as CustomError;

  let content = null;

  if (isLoading) {
    content = (
      <tr>
        <td colSpan={9} className="text-center">
          Loading...
        </td>
      </tr>
    );
  }
  if (!isLoading && isError && error) {
    content = (
      <tr>
        <td colSpan={9} className="text-center">
          {err.message}
        </td>
      </tr>
    );
  }
  if (!isLoading && !isError && paymentInfo?.length > 0) {
    // console.log('Reg Info: ' + paymentInfo);

    content = newList.map((payment, idx) => (
      <PaymentListTableRow paymentInfo={payment} index={idx} key={payment.id} page={page} paginate_per_page={paginate_per_page} />
    ));
  }

  const handlePendingStatus = (e: any) => {
    if (e.target.checked) {
      setStatus(false);
    }
  };

  const handleApprovedStatus = (e: any) => {
    if (e.target.checked) {
      setStatus(true);
    }
  };

  const handleApprovalStatus = (e: any) => {
    console.log(e.target.value);
    if (e?.target?.value === 'Approved') {
      setStatus(true);
    }
    else {
      setStatus(false);
    }
  };

  const { data: subjects } = useGetSubjectInfosQuery();

  const allSubjects: SelectOption[] =
    subjects?.data?.map((subject) => ({
      value: subject.id!,
      label: subject.subject_name,
    })) || [];

  const registrationType: SelectOption[] =
    useGetLookupDataQuery('registration_type')
      .data?.data[0]?.childs.map((item) => ({
        value: item.name,
        label: item.name,
      })) || [];


  const {
    handleSubmit,
    handleChange,
    getFieldProps,
    setFieldValue,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    onSubmit: (values) => {
      let s: string = '';
      // console.log(values);

      if (values.bmdc_reg_no !== '') {
        s = s + `&bmdc_registration_no=${values.bmdc_reg_no}`;
      }
      if (values.type !== '') {
        s = s + `&type=${values.type}`;
      }
      if (values.subject !== '' && values.subject !== undefined) {
        s = s + `&subject=${values.subject}`;
      }
      if (values.payment_confirmation_ind !== null) {
        s = s + `&payment_confirmation_ind=${status}`;
        if (values.type === '') {
          s = s + `&type=Trainee,Supervisor,Fellow`;
        }
      }
      if (values.from_date !== '') {
        s = s + `&from_date=${values.from_date}`;
      }

      if (values.to_date !== '') {
        s = s + `&to_date=${values.to_date}`;
      }

      if (values.bmdc_reg_no === '' && values.type === '' && values.subject === '' && values.payment_confirmation_ind === null && values.from_date === '' && values.to_date === '') {
        s = s + `&type=Trainee,Supervisor,Fellow`;
      }

      setSearch(s);
      setPage('1');
      set_paginate_per_page(20);
    },
  });

  useEffect(() => {
    if (selectedSubject !== '') {
      setFieldValue('subject', selectedSubject?.value);
    }
  }, [selectedSubject]);

  return (
    <div className="page-content">
      {isLoading || isFetching ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb
          breadcrumbItem={[{ link: '#', name: 'Dashboard' }]}
          title={'View Payment List'}
        />
        <Card>
          <CardBody>
            <Form
              className="custom-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              onReset={(e) => {
                resetForm();
                setSelectedSubject('');
                handleSubmit();
              }}
            >
              {/* <div>
                <div className="form-check form-check-inline">
                  <Label for="pending">Pending</Label>
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="payment_confirmation_ind"
                    id="pending"
                    onChange={(e) => {
                      handleChange(e);
                      handlePendingStatus(e);
                    }}
                  ></Input>
                </div>
                <div className="form-check form-check-inline">
                  <Label for="approved">Approved</Label>
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="payment_confirmation_ind"
                    id="approved"
                    onChange={(e) => {
                      handleChange(e);
                      handleApprovedStatus(e);
                    }}
                  ></Input>
                </div>
              </div> */}
              <div className="filter-wrapper">
                <FormGroup>
                  <Label>BMDC Reg No.</Label>
                  <Input
                    type="text"
                    placeholder="BMDC Reg No"
                    {...getFieldProps('bmdc_reg_no')}
                  ></Input>
                </FormGroup>

                <FormGroup>
                  <Label for="type">Reg. Type</Label>
                  <InputField
                    type="select"
                    placeholder="Type"
                    options={registrationType}
                    {...getFieldProps('type')}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="from_date">From Date</Label>
                  <InputField
                    type="date"
                    placeholder=""
                    invalid={{ errors, touched }}
                    {...getFieldProps('from_date')}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="to_date">To Date</Label>
                  <InputField
                    type="date"
                    placeholder=""
                    invalid={{ errors, touched }}
                    {...getFieldProps('to_date')}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="bmdc_reg_no">Subject</Label>
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    value={selectedSubject}
                    onChange={setSelectedSubject}
                    options={allSubjects}
                    placeholder='Select...'
                    name="subject"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="payment_confirmation_ind">
                    Approval Status
                  </Label>
                  <InputField
                    type="select"
                    name='payment_confirmation_ind'
                    placeholder="Approval Status"
                    options={[
                      { value: 'Approved', label: 'Approved' },
                      { value: 'Pending', label: 'Pending' },
                    ]}
                    onChange={(e) => {
                      handleChange(e);
                      handleApprovalStatus(e);
                    }}
                  />
                </FormGroup>
                <div className="btn-column mb-3">
                  <Button color="danger" type="reset" text="Reset" />
                  <Button type="submit" text="Search" />
                </div>
              </div>
            </Form>
            <div className="table-responsive">
              <Table className="table table-striped table-hover table-sm">
                <thead>
                  <tr>
                    <th style={{ width: '' }}>SL.</th>
                    <th>BMDC Reg. No.</th>
                    <th>Reg. Type</th>
                    <th>Name</th>
                    <th>Training Name</th>
                    <th>Subject</th>
                    <th>Payment Date</th>
                    <th>Fees</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
            {!isLoading && (
              <PaymentPaginate
                data={data}
                setPage={setPage}
                setPaginatePerPage={set_paginate_per_page}
              />
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default ViewPaymentList;
