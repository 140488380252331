import { apiSlice } from 'src/rtk/api/apiSlice';
import * as noticeApprovalUrl from 'src/helpers/url_helper';

export const noticeApprovalApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['NoticeList', 'Signatories', 'NoticeTemplateList'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      updateSignatoryStatus: builder.mutation<any, any>({
        query: (obj: any) => ({
          method: 'GET',
          url: `${noticeApprovalUrl.UPDATE_SIGNATORY_STATUS}/${obj?.id}/${obj?.status}`,
        }),
        invalidatesTags: ['NoticeList'],
      }),              
    }),
  });

export const {
  useUpdateSignatoryStatusMutation,  
} = noticeApprovalApi;
