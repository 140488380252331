import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { setFromErrors } from 'src/helpers';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import { useCreateFcpsPaymentMutation } from 'src/rtk/features/trainee/fcpsOfflinePaymentApi';
import { useGetFcpsCourseListByIdQuery } from 'src/rtk/features/trainee/traineeFcpsCourseListApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

const initialValues: AddFcpsOfflinePayment = {
  id: '',
  exam_course_id: '',
  payment_method: '',
  payment_mode: 'Offline',
  status: true,
  bank_name: '',
  branch_name: '',
  deposit_slip_no: '',
  bank_deposit_slip: '',
  total_amount: '',
  payment_date: new Date(Date.now()).toISOString().slice(0, 10),
  order_submit_type: '',
  passing_year: '',
  passing_session: '',
};

const UpdatedAddTraineeOfflinePayment = (props: any) => {
  const { id: courseId, type: courseType, fees: courseFee, year: passingYear, session: passingSession } = useParams<{
    id: string;
    type: string;
    fees: string;
    year: string;
    session: string;
  }>();
  const fileInputRef = useRef<any>();
  const [onlineRadio, setOnlineRadio] = useState<boolean>(false);
  const [offlineRadio, setOfflineRadio] = useState<boolean>(true);
  const [uploadSlipStatus, setUploadSlipStatus] = useState<boolean>(false);
  const [btnClicked, setBtnClicked] = useState<string>('');
  const [cashSelected, setCashSelected] = useState<boolean>(false);
  const [
    createFcpsPayment,
    { data: createFcpsPaymentResponse, isSuccess, isError, error, isLoading },
  ] = useCreateFcpsPaymentMutation();
  const history = useHistory();
  console.log(courseFee);
  const {
    data: fcpsCourse,
    isSuccess: isFcpsCourseSuccess,
    isError: isFcpsCourseError,
    error: fcpsCourseError,
    isLoading: isFcpsCourseLoading,
  } = useGetFcpsCourseListByIdQuery(courseId);

  useEffect(() => {
    values.exam_course_id = courseId;
    values.order_submit_type = 'Submit';
  }, []);

  // useEffect(() => {
  //   createFcpsCourse(courseType);
  // }, []);

  // useEffect(() => {
  //   if (isFcpsCourseListSuccess && fcpsCourseListData?.success) {
  //     const selectedCourse: FcpsCourseList = fcpsCourseListData?.data?.find((f: FcpsCourseList) => f.id === courseId)!;
  //     setFcpsCourse(selectedCourse);
  //     values.total_amount = selectedCourse?.exam_tranning_fee?.exam_fee;
  //   }
  // }, [isFcpsCourseListSuccess, fcpsCourseListData?.success]);

  const err: CustomError = error as CustomError;

  const {
    handleSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    resetForm,
    setFieldValue,
    setErrors,
  } = useFormik({
    initialValues,

    validationSchema: Yup.object({
      payment_method: Yup.string().required(),
      payment_date: Yup.date().max(new Date(Date.now()), 'Payment date can\'t be a future date').required(),
      bank_name: cashSelected ? Yup.string() : Yup.string().required('Bank name is Required!'),
      branch_name: cashSelected ? Yup.string() : Yup.string().required('Branch name is Required!'),
      deposit_slip_no: uploadSlipStatus
        ? Yup.string().required().max(17, 'You have exceeded the maximum allowed length')
        : Yup.string().max(17, 'You have exceeded the maximum allowed length'),
    }),

    onSubmit: (values: any) => {
      values.total_amount = courseFee;
      values.payment_date = moment(values.payment_date).format('YYYY-MM-DD');
      values.passing_year = passingYear;
      values.passing_session = passingSession;
      console.log(values);

      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        if (key === 'status') {
          values[key] = values.status ? 1 : 0;
        }
        if (key === 'order_submit_type') {
          if (btnClicked === 'submit') {
            values[key] = 'Submit';
          } else if (btnClicked === 'save_as_draft') {
            values[key] = 'Draft';
          }
        }
        formData.append(key, values[key]);
        console.log(key, values[key], formData);
      });

      createFcpsPayment(formData);
    },
  });

  const handleFormReset = () => {
    resetForm();
    setOnlineRadio(false);
    setOfflineRadio(true);
    setUploadSlipStatus(false);
    setCashSelected(false);
    fileInputRef.current.value = '';
  };

  const handleCashSelection = (e: any) => {
    if (e.target.value.trim() === 'Cash') {
      setCashSelected(true);
    } else {
      setCashSelected(false);
    }
  };

  const paymentMethods: SelectOption[] =
    useGetLookupDataQuery('payment_method')
      .data?.data[0]?.childs.filter(
        (item) =>
          item.name.trim() !== 'Mobile Finance' &&
          item.name.trim() !== 'Credit Card',
      )
      .map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      }) || [];

  const bankNames: SelectOption[] =
    useGetLookupDataQuery('bank_name')
      .data?.data[0]?.childs.map((item) => ({
        value: item.name,
        label: item.name,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) || [];

  const branchNames: SelectOption[] =
    useGetLookupDataQuery('branch_name')
      .data?.data[0]?.childs.map((item) => ({
        value: item.name,
        label: item.name,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) || [];

  useEffect(() => {
    if (isSuccess) {
      handleFormReset();
      if (!createFcpsPaymentResponse?.data?.message)
        toast.success('Fcps payment created successfully');
      else toast.error(createFcpsPaymentResponse?.data?.message);

      history.push('/trainee/offline-payment-list');
    }
    if (isError) {
      Object?.entries(err?.data?.errors ?? {}).forEach((entry: any) => {
        entry[1]?.forEach((message: any) => {
          toast.error(message);
        });
      });

      toast.error(err?.message);

      setFromErrors(err?.data, setErrors);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (cashSelected) {
      setFieldValue('bank_name', '');
      setFieldValue('branch_name', '');
    }
  }, [cashSelected]);

  return (
    <div className="page-content">
      {isLoading || isFcpsCourseLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb
          breadcrumbItem={[
            { link: '/trainee/offline-payment-list', name: 'Payment List' },
          ]}
          title={'FCPS Offline Payment'}
        />
        <div className="row align-items-center justify-content-center">
          <div className="card">
            <h5 className="card-header">
              <div className="row justify-content-between">
                <div className="col-sm-3">
                  <i className="fa fa-plus me-2" />
                  Make Offline Payment
                </div>
                <div className="col-sm-3">
                  <a
                    type='button'
                    className='btn btn-link font-size-14 text-decoration-underline'
                    href='/documents/Procedure of Ofline Payment.pdf'
                    rel="noreferrer"
                    target='_blank'>
                    Procedure of Offline Payment
                  </a>
                </div>
              </div>
            </h5>
            <div className="card-body">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
                onReset={(e) => {
                  e.preventDefault();
                  handleFormReset();
                }}
              >
                <Row className="justify-content-evenly">
                  <Col sm={12}>
                    <div className="mb-3">
                      <Label className="form-label required-field">
                        Payment Mode
                      </Label>
                      <br />
                      <FormGroup check inline>
                        <Input
                          id="paymentMode1"
                          name="payment_mode"
                          type="radio"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setOnlineRadio(true);
                              setOfflineRadio(false);
                            }

                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          value={'Online'}
                          checked={onlineRadio}
                          readOnly
                          disabled
                        />

                        <Label check for="paymentMode1">
                          Online
                        </Label>
                      </FormGroup>

                      <FormGroup check inline>
                        <Input
                          id="paymentMode2"
                          name="payment_mode"
                          type="radio"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setOnlineRadio(false);
                              setOfflineRadio(true);
                            }

                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          value={'Offline'}
                          checked={offlineRadio}
                        />

                        <Label check for="paymentMode2">
                          Offline
                        </Label>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>

                <Row>
                  {fcpsCourse && (
                    <Col md={3} sm={12}>
                      <FormGroup className="mb-3">
                        <Label className="form-label required-field">
                          Course Title
                        </Label>
                        <InputField
                          type="text"
                          name="course_title"
                          id="course_title"
                          value={fcpsCourse.data.name}
                          placeholder="Course Title"
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  )}

                  {fcpsCourse && (
                    <Col md={3} sm={12}>
                      <FormGroup className="mb-3">
                        <Label className="form-label required-field">
                          Fees
                        </Label>
                        <InputField
                          type="text"
                          name="total_amount"
                          id="total_amount"
                          value={courseFee}
                          placeholder="Amount"
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                  )}

                  <Col md={3} sm={12}>
                    <FormGroup className="mb-3">
                      <Label className="form-label required-field">
                        Payment Method
                      </Label>
                      <InputField
                        id="payment_method"
                        name="payment_method"
                        type="select"
                        placeholder=""
                        options={paymentMethods}
                        value={values.payment_method}
                        invalid={{ errors, touched }}
                        onChange={(e) => {
                          handleCashSelection(e);
                          handleChange(e);
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3} sm={12}>
                    <FormGroup className="mb-3">
                      <Label className="form-label required-field">
                        Payment Date
                      </Label>
                      <InputField
                        type="date"
                        invalid={{ errors, touched }}
                        {...getFieldProps('payment_date')}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3} sm={12}>
                    {!cashSelected ? (
                      <FormGroup className="mb-3">
                        <Label className="form-label required-field">
                          Bank Name
                        </Label>
                        <InputField
                          type="text"
                          list="bank_name"
                          placeholder="Bank Name"
                          invalid={{ errors, touched }}
                          {...getFieldProps('bank_name')}
                        />
                        <datalist id="bank_name">
                          {bankNames.map((bank) => {
                            return (
                              <option
                                value={`${bank.value}`}
                                key={`${bank.value}`}
                              >
                                {`${bank.label}`}
                              </option>
                            );
                          })}
                        </datalist>
                      </FormGroup>
                    ) : (
                      <FormGroup className="mb-3">
                        <Label className="form-label">
                          Bank Name
                        </Label>
                        <InputField
                          type="text"
                          list="bank_name"
                          placeholder="Bank Name"
                          invalid={{ errors, touched }}
                          {...getFieldProps('bank_name')}
                          readOnly
                        />
                        <datalist id="bank_name">
                          {bankNames.map((bank) => {
                            return (
                              <option
                                value={`${bank.value}`}
                                key={`${bank.value}`}
                              >
                                {`${bank.label}`}
                              </option>
                            );
                          })}
                        </datalist>
                      </FormGroup>
                    )}
                  </Col>

                  <Col md={3} sm={12}>
                    {!cashSelected ? (
                      <FormGroup className="mb-3">
                        <Label className="form-label required-field">
                          Branch Name
                        </Label>
                        <InputField
                          type="text"
                          list="branch_name"
                          placeholder="Branch Name"
                          invalid={{ errors, touched }}
                          {...getFieldProps('branch_name')}
                        />
                        <datalist id="branch_name">
                          {branchNames.map((branch) => {
                            return (
                              <option
                                value={`${branch.value}`}
                                key={`${branch.value}`}
                              >
                                {`${branch.label}`}
                              </option>
                            );
                          })}
                        </datalist>
                      </FormGroup>
                    ) : (
                      <FormGroup className="mb-3">
                        <Label className="form-label">
                          Branch Name
                        </Label>
                        <InputField
                          type="text"
                          list="branch_name"
                          placeholder="Branch Name"
                          invalid={{ errors, touched }}
                          {...getFieldProps('branch_name')}
                          readOnly
                        />
                        <datalist id="branch_name">
                          {branchNames.map((branch) => {
                            return (
                              <option
                                value={`${branch.value}`}
                                key={`${branch.value}`}
                              >
                                {`${branch.label}`}
                              </option>
                            );
                          })}
                        </datalist>
                      </FormGroup>
                    )}
                  </Col>

                  <Col md={3} sm={12}>
                    <FormGroup className="mb-3">
                      <Label
                        className={
                          uploadSlipStatus
                            ? 'form-label required-field'
                            : 'form-label'
                        }
                      >
                        Deposit/Check No.
                      </Label>
                      <InputField
                        type="text"
                        placeholder="Deposit Slip No"
                        invalid={{ errors, touched }}
                        {...getFieldProps('deposit_slip_no')}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3} sm={12}>
                    <FormGroup className="mb-3">
                      <Label className="form-label">Upload Slip</Label>{' '}
                      <input
                        ref={fileInputRef}
                        name="bank_deposit_slip"
                        type="file"
                        className="form-control"
                        placeholder="Upload Slip"
                        onChange={(e: any) => {
                          setFieldValue('bank_deposit_slip', e.target.files[0]);
                          if (e.target.files[0]) {
                            setUploadSlipStatus(true);
                          } else {
                            setUploadSlipStatus(false);
                          }
                        }}
                      ></input>
                      {errors.bank_deposit_slip && touched.bank_deposit_slip ? (
                        <div className="text-danger">
                          {errors.bank_deposit_slip as string}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>

                  {/* <Col sm={12}>
                                <FormGroup check className='mb-3 mt-4'>
                                  <Input
                                    type="checkbox"
                                    role="check"
                                    checked={values.status}
                                    {...getFieldProps('status')}
                                  />
                                  <Label check>Status</Label>
                                </FormGroup>
                              </Col>                                                                                                                                                                                                                                                                 */}

                  <Col sm={12}>
                    <FormGroup className="mb-3 text-center">
                      <Button
                        type="reset"
                        text="Reset"
                        className="btn btn-secondary me-2"
                      ></Button>
                      <Button
                        type="submit"
                        text="Save as Draft"
                        className="btn btn-info me-2"
                        onClick={(e) => {
                          setBtnClicked('save_as_draft');
                        }}
                      ></Button>
                      <Button
                        type="submit"
                        text="Submit"
                        className="btn btn-success"
                        onClick={(e) => {
                          setBtnClicked('submit');
                        }}
                      ></Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default UpdatedAddTraineeOfflinePayment;
