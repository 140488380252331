import { useEffect, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { InputField } from 'src/components';
import { useGetDynamicFormPropertyByParticularQuery } from 'src/rtk/features/e-log-book/traineeAcademicActivitiesApi';
import loader from '../../../../assets/images/loader.gif';
import DynamicForm from './DynamicLogbookEntry';

export const TraineeAcademicActivitiesEntryGeneralType = (props: any) => {
    const { form, formName, setForm } = props;
    const [skillTypeId, setSkillTypeId] = useState<string>('');
    const [skillTypeName, setSkillTypeName] = useState<string>('');
    const [logbookEntrySetupMasters, setLogbookEntrySetupMasters] = useState<LogbookEntrySetupMaster[][]>([]);
    const [logbookEntrySetupMaster, setLogbookEntrySetupMaster] = useState<LogbookEntrySetupMaster[]>([]);
    const [competencyGroup, setCompetencyGroup] = useState<LogbookEntrySetupMaster[]>([]);

    useEffect(() => {
        const newLogbookEntrySetupMasters: LogbookEntrySetupMaster[][] = [];
        const newLogbookEntrySetupMaster: LogbookEntrySetupMaster[] = [];
        if (form) {
            newLogbookEntrySetupMasters[0] = form?.entry_setup_master!;
            newLogbookEntrySetupMaster[0] = form!;
            setLogbookEntrySetupMasters(newLogbookEntrySetupMasters);
            setLogbookEntrySetupMaster(newLogbookEntrySetupMaster);
        } else {
            setLogbookEntrySetupMasters([]);
            setLogbookEntrySetupMaster([]);
        }
    }, []);

    // useEffect(() => {
    //     if (caseTypeName) {
    //         setSkillTypeId('');
    //         setSkillTypeName('');
    //         setEntrySetupMaster([]);
    //     }
    // }, [caseTypeName]);

    const {
        data: logbookEntrySetupDetailsBySkillType,
        isError: isFormFieldsSkillError,
        isLoading: isFormFieldsSkillLoading,
    } = useGetDynamicFormPropertyByParticularQuery(skillTypeId);
    const formFieldsBySkillType = logbookEntrySetupDetailsBySkillType?.data || [];

    const handleEntrySetupMasterChilds = (e: any, index: number) => {
        const { options, selectedIndex, value } = e.target;
        const newLogbookEntrySetupMasters: LogbookEntrySetupMaster[][] = [...logbookEntrySetupMasters];
        const newLogbookEntrySetupMaster: LogbookEntrySetupMaster[] = [...logbookEntrySetupMaster];

        if (value !== '') {
            const skillType: LogbookEntrySetupMaster = logbookEntrySetupMasters[index]?.find((s: any) => s.id === value)!;

            if (skillType?.entry_setup_master?.length! > 0 && !newLogbookEntrySetupMasters[index + 1]) {
                newLogbookEntrySetupMasters.push(skillType?.entry_setup_master!);
                newLogbookEntrySetupMaster[index] = skillType;
                newLogbookEntrySetupMaster.forEach((entrySetupMaster: LogbookEntrySetupMaster, i: number) => {
                    if (i > index) {
                        newLogbookEntrySetupMaster[i] = { id: '', setup_logbook_master_id: '', status: false };
                    }
                });
                setSkillTypeId('');
                setSkillTypeName('');
            } else if (skillType?.entry_setup_master?.length! > 0 && newLogbookEntrySetupMasters[index + 1]) {
                newLogbookEntrySetupMasters[index + 1] = skillType?.entry_setup_master!;
                newLogbookEntrySetupMaster[index] = skillType;
                newLogbookEntrySetupMaster.forEach((entrySetupMaster: LogbookEntrySetupMaster, i: number) => {
                    if (i > index) {
                        newLogbookEntrySetupMaster[i] = { id: '', setup_logbook_master_id: '', status: false };
                    }
                });
                setSkillTypeId('');
                setSkillTypeName('');
            } else if (skillType?.entry_setup_master?.length! === 0) {
                newLogbookEntrySetupMaster[index] = skillType;
                if (newLogbookEntrySetupMaster.length > index) {
                    newLogbookEntrySetupMaster.splice(index + 1, newLogbookEntrySetupMaster.length - index - 1);
                }
                if (newLogbookEntrySetupMasters.length > index) {
                    newLogbookEntrySetupMasters.splice(index + 1, newLogbookEntrySetupMasters.length - index - 1);
                }
                setSkillTypeId(skillType?.id!);
                setSkillTypeName(skillType?.particulars!);
                setCompetencyGroup(skillType?.competency_group!);
            }

            newLogbookEntrySetupMaster[index] = skillType;
        } else {
            newLogbookEntrySetupMasters.splice(index + 1, newLogbookEntrySetupMasters.length - index - 1);
            newLogbookEntrySetupMaster[index] = { id: '', setup_logbook_master_id: '', status: false };
            setSkillTypeId('');
            setSkillTypeName('');
        }

        setLogbookEntrySetupMasters(newLogbookEntrySetupMasters);
        setLogbookEntrySetupMaster(newLogbookEntrySetupMaster);
    };

    return (
        <>
            {isFormFieldsSkillLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            {logbookEntrySetupMasters?.map((entrySetupMaster: LogbookEntrySetupMaster[], index: number) => {
                return (
                    <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                        <FormGroup className="mb-3">
                            <Label className="form-label">{index === 0 ? 'System Type' : index === 1 ? 'Topic Type' : `Skill Type ${index - 1}`}</Label>
                            <InputField
                                type="select"
                                name={`skillType${index + 1}`}
                                value={logbookEntrySetupMaster[index]?.id}
                                placeholder=""
                                options={
                                    entrySetupMaster?.map((e: LogbookEntrySetupMaster) => {
                                        return {
                                            label: e.particulars!,
                                            value: e.id!,
                                        };
                                    }) ?? []
                                }
                                onChange={(e) => {
                                    handleEntrySetupMasterChilds(e, index);
                                }}
                            />
                        </FormGroup>
                    </div>
                );
            })}
            {logbookEntrySetupDetailsBySkillType && !isFormFieldsSkillError ? (
                <DynamicForm
                    dynamicFormProps={formFieldsBySkillType}
                    formName={formName}
                    systemTypeName={logbookEntrySetupMaster[0]?.particulars}
                    caseTypeName={logbookEntrySetupMaster[1]?.particulars}
                    skillTypeName={skillTypeName}
                    competencyGroup={competencyGroup}
                    entrySetupMasterId={skillTypeId}
                    setForm={setForm}
                />
            ) : null}
        </>
    );
};
