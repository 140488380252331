import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import BetterBreadcrumb from "src/components/Common/BetterBreadCrumb";
import { getTime } from "src/helpers";
import { useAppSelector } from "src/rtk/app/hook";
import { useGetAllNotificationQuery, useGetNotificationSeenQuery } from "src/rtk/features/user/userApi";
import loader from '../../assets/images/loader.gif';
import NotificationPaginate from "./NotificationPaginate";

const Notification = () => {
    const [allNotification, setAllNotification] = useState<any>([]);
    const [page, setPage] = useState<number>(1);
    const [paginatePerPage, setPaginatePerPage] = useState(15);
    const authUser = useAppSelector((state) => state.auth.user);

    const generateQueryUrl = () => {
        return `page=${page}&paginate_per_page=${paginatePerPage}`;
    };

    const {
        data: notification,
        isError: isNotificationError,
        isLoading: isNotificationLoading,
        isSuccess
    } = useGetAllNotificationQuery(generateQueryUrl());

    const {
        data: notificationSeenData,
        isError: isNotificationSeenDataError,
        isLoading: isNotificationSeenDataLoading,
        isSuccess: isNotificationSeenDataSuccess,
    } = useGetNotificationSeenQuery({ registration_no: authUser.registration_no });

    useEffect(() => {
        if (notification && isSuccess) {
            setAllNotification(notification?.data?.data)
        }
    }, [notification, isSuccess])

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const hasReloaded = params.get('reloaded');

        if (!hasReloaded) {
            params.set('reloaded', 'true');
            history.replace({ search: params.toString() });
            window.location.reload();
        }
    }, [location, history]);

    const cuurentDate = moment(new Date()).format("MMM")

    return (
        <div className="page-content">
            {isNotificationLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <React.Fragment>
                <Container fluid>
                    <BetterBreadcrumb title="Notification" />
                    {allNotification?.map((item: any) => (
                        <Row className="mx-1" key={item.id}>
                            <Col className="shadow-lg p-2 mb-3 bg-white rounded col-lg-12 col-md-12 col-sm-12">
                                <div className="row">
                                    <div className="col-lg-1 text-center">
                                        <div className={`card ${cuurentDate === moment(item?.created_at).format("MMM") ? 'bg-danger bg-opacity-100' : 'bg-primary bg-opacity-75'} text-white p-1 m-0`}>
                                            <p className="font-size-20 fw-bold m-0">
                                                {moment(item?.created_at).format("D")}
                                            </p>
                                            <p className="fw-bold">
                                                {moment(item?.created_at).format("MMM")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-11">
                                        <h6 className="mt-0 mb-1">{item?.title}</h6>
                                        <div className="font-size-12 text-muted">
                                            <p className="mb-1">
                                                {item?.body}
                                            </p>
                                            <p className="mb-0">
                                                <i className="mdi mdi-clock-outline" /> {getTime(item?.created_at)} ago
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    ))}
                    <Row className="mb-3">
                        <Col>
                            {!isNotificationLoading && allNotification.length > 0 && (
                                <NotificationPaginate
                                    data={notification}
                                    setPage={setPage}
                                    setPaginatePerPage={setPaginatePerPage}
                                />
                            )}
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        </div>
    );
}

export default Notification;