import moment from 'moment';
import { useState } from 'react';
import { Button as BsButton, Col, FormGroup, Row } from 'reactstrap';
import defaultAvatar from 'src/assets/images/users/default-avatar.png';
import { config } from 'src/config';
import CommentBtn from './CommentBtn';

const StepThree = (props: any) => {

    const { goToPrevious, protocolEntry, titleEntry } = props;
    const [showCommentSectionModal, setShowCommentSectionModal] = useState<boolean>(false);
    const [commentsFieldName, setCommentsFieldName] = useState<string>('');

    return (
        <>
            <div className="col-lg-12 col-sm-12 ">
                {protocolEntry?.id ?
                    <table className="table table-sm table-bordered ">
                        <thead className="bg-light fixed">
                            <tr className="text-center text-dark font-size-16 fw-bold">
                                <td colSpan={20}>Dissertation/Thesis Protocol</td>
                            </tr>
                        </thead>

                        <tbody className="text-capitalize text-dark font-size-14">
                            <tr>
                                <td className="bg-light fw-bold">Protocol No</td>
                                <td colSpan={2} className='text-center'>{protocolEntry?.protocol_no ?? ''}</td>
                            </tr>
                            <tr>
                                <td>16</td>
                                <td width={'200'} className="fw-bold">Total Budget</td>
                                <td>
                                    <Row className='justify-content-between align-items-center mb-2'>
                                        <Col sm={'10'}>
                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.total_budget_text }}></span>
                                        </Col>
                                        <CommentBtn
                                            protocolEntry={protocolEntry}
                                            showCommentSectionModal={showCommentSectionModal}
                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                            commentsFieldName={commentsFieldName}
                                            setCommentsFieldName={setCommentsFieldName}
                                            commentsFieldNameToCompare={'total_budget_text'}
                                        />
                                    </Row>
                                </td>
                            </tr>
                            <tr>
                                <td>17</td>
                                <td className="fw-bold">Sources of funding (if any)</td>
                                <td>
                                    <Row className='justify-content-between align-items-center mb-2'>
                                        <Col sm={'10'}>
                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.source_of_funding }}></span>
                                        </Col>
                                        <CommentBtn
                                            protocolEntry={protocolEntry}
                                            showCommentSectionModal={showCommentSectionModal}
                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                            commentsFieldName={commentsFieldName}
                                            setCommentsFieldName={setCommentsFieldName}
                                            commentsFieldNameToCompare={'source_of_funding'}
                                        />
                                    </Row>
                                </td>
                            </tr>
                            <tr>
                                <td>18</td>
                                <td className="fw-bold">Facilities available at the place of study</td>
                                <td>
                                    <Row className='justify-content-between align-items-center mb-2'>
                                        <Col sm={'10'}>
                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.facilities_available_at_the_place_of_the_study }}></span>
                                        </Col>
                                        <CommentBtn
                                            protocolEntry={protocolEntry}
                                            showCommentSectionModal={showCommentSectionModal}
                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                            commentsFieldName={commentsFieldName}
                                            setCommentsFieldName={setCommentsFieldName}
                                            commentsFieldNameToCompare={'facilities_available_at_the_place_of_the_study'}
                                        />
                                    </Row>
                                </td>
                            </tr>
                            <tr>
                                <td>19</td>
                                <td className="fw-bold">Other facilities needed</td>
                                <td>
                                    <Row className='justify-content-between align-items-center mb-2'>
                                        <Col sm={'10'}>
                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.other_facilites_needed }}></span>
                                        </Col>
                                        <CommentBtn
                                            protocolEntry={protocolEntry}
                                            showCommentSectionModal={showCommentSectionModal}
                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                            commentsFieldName={commentsFieldName}
                                            setCommentsFieldName={setCommentsFieldName}
                                            commentsFieldNameToCompare={'other_facilites_needed'}
                                        />
                                    </Row>
                                </td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td className="fw-bold">Dissemination and use of findings</td>
                                <td>
                                    <Row className='justify-content-between align-items-center mb-2'>
                                        <Col sm={'10'}>
                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.dissemination_and_use_of_findings }}></span>
                                        </Col>
                                        <CommentBtn
                                            protocolEntry={protocolEntry}
                                            showCommentSectionModal={showCommentSectionModal}
                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                            commentsFieldName={commentsFieldName}
                                            setCommentsFieldName={setCommentsFieldName}
                                            commentsFieldNameToCompare={'dissemination_and_use_of_findings'}
                                        />
                                    </Row>
                                </td>
                            </tr>
                            <tr>
                                <td>21</td>
                                <td className="fw-bold">References</td>
                                <td>
                                    <Row className='justify-content-between align-items-center mb-2'>
                                        <Col sm={'10'}>
                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.references }}></span>
                                        </Col>
                                        <CommentBtn
                                            protocolEntry={protocolEntry}
                                            showCommentSectionModal={showCommentSectionModal}
                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                            commentsFieldName={commentsFieldName}
                                            setCommentsFieldName={setCommentsFieldName}
                                            commentsFieldNameToCompare={'references'}
                                        />
                                    </Row>
                                </td>
                            </tr>
                            <tr>
                                <td>22</td>
                                <td className="fw-bold">Any other relevant information</td>
                                <td>
                                    <Row className='justify-content-between align-items-center mb-2'>
                                        <Col sm={'10'}>
                                            <span dangerouslySetInnerHTML={{ __html: protocolEntry?.any_other_relevant_info }}></span>
                                        </Col>
                                        <CommentBtn
                                            protocolEntry={protocolEntry}
                                            showCommentSectionModal={showCommentSectionModal}
                                            setShowCommentSectionModal={setShowCommentSectionModal}
                                            commentsFieldName={commentsFieldName}
                                            setCommentsFieldName={setCommentsFieldName}
                                            commentsFieldNameToCompare={'any_other_relevant_info'}
                                        />
                                    </Row>
                                </td>
                            </tr>
                            <tr>
                                <td>23</td>
                                <td colSpan={2} className='w-100'>

                                    <Row className='justify-content-between mb-2'>
                                        <Col sm={'12'}>
                                            <p>
                                                I solemnly pledge that this research protocol shall be implemented
                                                in accordance with the relevant ordinance circulars of BCPS and
                                                funding agencies as when it is applicable. I hereby declare that
                                                no part of the proposal research has been used in any thesis dissertation
                                                in partial fulfillment of any degree fellowship or any publication.
                                            </p>

                                            <p>
                                                I also understand that the BCPS reserves the right of accepting or rejecting this protocol.
                                            </p>

                                            <div className="row justify-content-between mt-5">
                                                <div className="col-sm-4 mt-5">
                                                    <p className='text-center mt-4'>{moment(protocolEntry?.signature_date)?.isValid() ? moment(protocolEntry?.signature_date)?.format('DD/MMM/YYYY') : moment(new Date(Date.now()).toISOString()).format('DD/MMM/YYYY')}</p>
                                                    <p className="text-center border-top border-dark">Date</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="image-upload-wrapper">
                                                        <div className="image-preview ms-5">
                                                            <img src={protocolEntry?.researcher_signature ? `${config.APP_URL}${protocolEntry?.researcher_signature}` : defaultAvatar} alt="" />
                                                        </div>
                                                    </div>
                                                    <p className="text-center border-top border-dark">Signature of the Researcher</p>
                                                </div>
                                            </div>
                                        </Col>
                                        {/* <Col sm={'1'} className='align-self-center'>
                                            <BsButton
                                                type='button'
                                                className='btn btn-primary btn-sm'
                                                onClick={(e) => {
                                                    setShowCommentSectionModal(!showCommentSectionModal);
                                                    setCommentsFieldName('signature_of_the_researcher');
                                                }}
                                            >
                                                <i className='fas fa-comments'></i>
                                            </BsButton>
                                        </Col> */}
                                    </Row>
                                </td>
                            </tr>
                            <tr>
                                <td>24</td>
                                <td colSpan={2}>
                                    <Row className='justify-content-between mb-2'>
                                        <Col sm={'12'}>
                                            <table className="table table-bordered">
                                                <thead className="bg-light fixed">
                                                    <tr className="text-center text-dark font-size-16 fw-bold">
                                                        <td>Signature of the Guide</td>
                                                        <td>Signature of the Co-Guide</td>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-capitalize text-dark font-size-14">
                                                    <tr>
                                                        <td>
                                                            <p><strong>Name: </strong>{titleEntry?.guide_name ?? ''}</p>
                                                            <p><strong>Designation: </strong>{titleEntry?.guide?.current_employment_info?.designation}, {titleEntry?.guide?.current_employment_info?.institute_name}</p>
                                                            <p><strong>Signature: </strong></p>
                                                            <div className="image-upload-wrapper">
                                                                <div className="image-preview">
                                                                    <img src={titleEntry?.guide?.personal_info?.signature ? `${config?.APP_URL}${titleEntry?.guide?.personal_info?.signature}` : defaultAvatar} alt="signature" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p><strong>Name: </strong>{titleEntry?.co_guide_1_name ?? ''}</p>
                                                            <p><strong>Designation: </strong>{titleEntry?.co_guide_1?.current_employment_info?.designation}, {titleEntry?.co_guide_1?.current_employment_info?.institute_name}</p>
                                                            <p><strong>Signature: </strong></p>
                                                            <div className="image-upload-wrapper">
                                                                <div className="image-preview">
                                                                    <img src={titleEntry?.co_guide_1?.personal_info?.signature ? `${config?.APP_URL}${titleEntry?.co_guide_1?.personal_info?.signature}` : defaultAvatar} alt="signature" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                        {/* <Col sm={'1'} className='align-self-center'>
                                            <BsButton
                                                type='button'
                                                className='btn btn-primary btn-sm'
                                                onClick={(e) => {
                                                    setShowCommentSectionModal(!showCommentSectionModal);
                                                    setCommentsFieldName('signature_of_the_guide_and_co_guide');
                                                }}
                                            >
                                                <i className='fas fa-comments'></i>
                                            </BsButton>
                                        </Col> */}
                                    </Row>
                                </td>
                            </tr>
                            <tr>
                                <td>25</td>
                                <td colSpan={2} className="fw-bold">
                                    <Row className='justify-content-between mb-2'>
                                        <Col sm={'12'}>
                                            <div className="row justify-content-center">
                                                <div className="col-sm-11 text-center">
                                                    <p>Attachments</p>
                                                </div>
                                                <CommentBtn
                                                    protocolEntry={protocolEntry}
                                                    showCommentSectionModal={showCommentSectionModal}
                                                    setShowCommentSectionModal={setShowCommentSectionModal}
                                                    commentsFieldName={commentsFieldName}
                                                    setCommentsFieldName={setCommentsFieldName}
                                                    commentsFieldNameToCompare={'attachments'}
                                                />
                                                <div className="col-sm-12 text-center">
                                                    <table className='table table-bordered border-dark text-center'>
                                                        <thead>
                                                            <th>Sl. No.</th>
                                                            <th>File Type</th>
                                                            <th>File Preview</th>
                                                        </thead>
                                                        <tbody>
                                                            {protocolEntry?.protocol_attachments?.map((fileObj: any, index: number) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {index + 1}
                                                                        </td>
                                                                        <td>
                                                                            {fileObj.document_type}
                                                                        </td>
                                                                        <td>
                                                                            <a
                                                                                className="me-2 p-0 center"
                                                                                href={`${config?.APP_URL}${fileObj.document_name}`}
                                                                                rel="noreferrer"
                                                                                target="_blank"
                                                                            >
                                                                                <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    :
                    null}
            </div>            

            <FormGroup row className='justify-content-between mt-5'>
                <Col sm={9}>
                    <BsButton color="warning" className='me-3 text-start' onClick={() => {
                        goToPrevious();
                    }}>
                        <i className="fas fa-2x fa-arrow-left-long"></i>
                    </BsButton>
                </Col>
            </FormGroup>
        </>
    )
};

export default StepThree;
