import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Form, Input, Label } from 'reactstrap';
import InputField from 'src/components/Input';
import { setFromErrors } from 'src/helpers';
import { useAppSelector } from 'src/rtk/app/hook';
import { useGetDepartmentsByInstituteQuery } from 'src/rtk/features/setup/department/departmentApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import { useGetInstitutesByOrganizationQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import { useGetPersonalProfileQuery } from 'src/rtk/features/trainee/traineeApi';
import {
  useUpdateWorkExperienceMutation
} from 'src/rtk/features/workExperience/workExperienceApi';
import * as Yup from 'yup';
import loader from '../../../assets/images/loader.gif';

interface editInfoID {
  editInfoID: string;
  setIsAddForm: any;
}

const EditExperienceProfile = ({ editInfoID, setIsAddForm }: editInfoID) => {

  const initialValues: WorkExperience = {
    id: '',
    employment_type: 'Government',
    designation: '',
    others_designation_value: '',
    institute_name: '',
    others_institute_value: '',
    department_name: '',
    others_department_value: '',
    working_address: '',
    from_date: '',
    to_date: '',
    current_position: 0,
    department_head: '',
    in_charge: '',
    unit_name: '',
  };

  const authUser = useAppSelector((state) => state.auth.user);

  const [currentPosition, SetCurrentPosition] = useState(true);
  const [currentPosCheck, setCurrentPosCheck] = useState<boolean>(false);
  const [inChargeCheck, setInChargeCheck] = useState<boolean>(false);
  const [departmentHeadCheck, setDepartmentHeadCheck] = useState<boolean>(false);

  const [organizationName, setOrganizationName] = useState('');
  const [instituteName, setInstituteName] = useState('');

  const [governmentChecked, setGovernmentChecked] = useState<boolean>(true);
  const [privateChecked, setPrivateChecked] = useState<boolean>(false);
  const [foreignChecked, setForeignChecked] = useState<boolean>(false);

  const { data: designationLookupData } = useGetLookupDataQuery('designation');

  const designationList: Lookup[] =
    (designationLookupData as any)?.data[0]?.childs || [];

  const { data: instituteLookupData } = useGetInstitutesByOrganizationQuery(organizationName);

  const instituteList: Organization[] =
    (instituteLookupData as any)?.data || [];

  const allInstitute: SelectOption[] =
    instituteLookupData?.data?.map((institute) => ({
      value: institute.organization_name,
      label: institute.organization_name,
    })) || [];

  // allInstitute.push({ value: 'Others', label: 'Others' });

  const { data: departmentLookupData } = useGetDepartmentsByInstituteQuery(instituteName);

  const departmentList: Department[] =
    (departmentLookupData as any)?.data || [];

  const allDepartment: SelectOption[] =
    departmentLookupData?.data?.map((department) => ({
      value: department.department_name,
      label: department.department_name,
    })) || [];

  allDepartment.push({ value: 'Others', label: 'Others' });

  const [
    updateWorkExperience,
    { data: updatedWorkExperienceData, isSuccess, isError, error, isLoading },
  ] = useUpdateWorkExperienceMutation();

  const { data: workExperience } = useGetPersonalProfileQuery();

  const workExperienceInfo =
    workExperience?.data?.details?.employment_infos?.find(
      (info) => info.id! === editInfoID,
    ) ?? ([] as WorkExperience);

  if (
    !allInstitute.find(
      (option: SelectOption) =>
        option.value === workExperienceInfo.institute_name,
    )
  ) {
    allInstitute.push({
      label: workExperienceInfo.institute_name!,
      value: workExperienceInfo.institute_name!,
    });
  }

  const err: CustomError = error as CustomError;

  const {
    values,
    errors,
    touched,
    setValues,
    getFieldProps,
    setFieldValue,
    resetForm,
    setErrors,
    handleChange,
    handleSubmit,
  } = useFormik<WorkExperience>({
    initialValues,
    validationSchema: Yup.object({
      designation: Yup.string().required('required'),
      others_designation_value: Yup.string().when(
        'designation',
        (designation, schema) => {
          if (designation === 'Others') {
            return schema.required('Designation is Required!');
          }
          return schema;
        },
      ),
      institute_name: Yup.string().required('required'),
      from_date: Yup.date().max(new Date(Date.now()), 'From date can\'t be a future date'),
      to_date: Yup.date().when("from_date", (from_date, schema) => {
        // if (from_date) {
        //   // This can be calculated in many ways. Just be sure that its type is `Date` object
        //   const fromDate = new Date(from_date);

        //   return schema.min(fromDate, 'To date must be greater than from date').nullable();
        // }
        if (!currentPosCheck && from_date) {
          const fromDate = new Date(from_date);

          return schema.required('To date is Required').min(fromDate, 'To date must be greater than from date').nullable();
        }

        return schema;
      }),
      others_institute_value: Yup.string().when(
        'institute_name',
        (institute_name, schema) => {
          if (institute_name === 'Others') {
            return schema.required('Institute is Required!');
          }
          return schema;
        },
      ),
      department_name: Yup.string().required('Department is Required'),
      others_department_value: Yup.string().when(
        'department_name',
        (department_name, schema) => {
          if (department_name === 'Others') {
            return schema.required('Department is Required!');
          }
          return schema;
        },
      ),
      unit_name: inChargeCheck ? Yup.string().required('Unit Name is Required') : Yup.string(),
    }),
    onSubmit(values: any) {
      values.from_date = moment(
        values.from_date,
      ).format('YYYY-MM-DD');
      values.to_date ? values.to_date = moment(
        values.to_date,
      ).format('YYYY-MM-DD') : values.to_date = '';
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key === 'current_position') {
          values[key] = values.current_position ? 1 : 0;
        }

        formData.append(key, values[key]);
      });
      updateWorkExperience({ data: formData, id: values.id });

      console.log(values);
      // const url = putUrlString(values.id, values);
      // updateWorkExperience(url);            
    },
  });

  const handleInstitute = (e: any) => {
    if (e.target.value) {
      setInstituteName(e.target.value);
    }
  };

  useEffect(() => {
    const workExperienceInfo =
      workExperience?.data?.details?.employment_infos?.find(
        (info) => info.id! === editInfoID,
      ) ?? ([] as WorkExperience);

    if (workExperienceInfo) {
      setOrganizationName(workExperienceInfo?.employment_type!);
      setValues({
        id: workExperienceInfo.id,
        employment_type: workExperienceInfo.employment_type || '',
        designation: designationList?.every((designationItem: any) => designationItem.name !== workExperienceInfo.designation) ? 'Others' : workExperienceInfo.designation || '',
        others_designation_value: workExperienceInfo.designation || '',
        // institute_name: instituteList?.every((instituteItem: any) => instituteItem.organization_name !== workExperienceInfo.institute_name) ? 'Others' : workExperienceInfo.institute_name || '',
        others_institute_value: workExperienceInfo.institute_name || '',
        institute_name: workExperienceInfo.institute_name || '',
        department_name: departmentList?.every((departmentItem: any) => departmentItem.department_name !== workExperienceInfo.department_name) ? 'Others' : workExperienceInfo.department_name || '',
        others_department_value: workExperienceInfo.department_name || '',
        from_date: workExperienceInfo.from_date || '',
        to_date: workExperienceInfo.to_date || '',
        current_position: workExperienceInfo.current_position,
        working_address: '',
        department_head: workExperience?.data?.details?.current_employment_info?.department_head || '',
        in_charge: workExperience?.data?.details?.current_employment_info?.in_charge || '',
        unit_name: workExperience?.data?.details?.current_employment_info?.unit_name || '',
      });

      if (workExperienceInfo.employment_type === 'Government') {
        setGovernmentChecked(true);
        setPrivateChecked(false);
        setForeignChecked(false);
      } else if (workExperienceInfo.employment_type === 'Private') {
        setGovernmentChecked(false);
        setPrivateChecked(true);
        setForeignChecked(false);
      } else if (workExperienceInfo.employment_type === 'Foreign') {
        setGovernmentChecked(false);
        setPrivateChecked(false);
        setForeignChecked(true);
      }

      if (workExperienceInfo.current_position) {
        SetCurrentPosition(false);
        setCurrentPosCheck(true);
      } else {
        SetCurrentPosition(true);
        setCurrentPosCheck(false);
      }

      if (workExperience?.data?.details?.current_employment_info?.department_head) {
        setDepartmentHeadCheck(true);
      } else {
        setDepartmentHeadCheck(false);
      }

      if (workExperience?.data?.details?.current_employment_info?.in_charge) {
        setInChargeCheck(true);
      } else {
        setInChargeCheck(false);
      }
    }
  }, [workExperience, editInfoID]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();

      if (updatedWorkExperienceData?.success) {
        toast.success('Experience Information Updated Successfully!');
      } else {
        if (updatedWorkExperienceData?.data.errors) {
          toast.error(updatedWorkExperienceData?.data.message);
        } else {
          toast.error(updatedWorkExperienceData?.message!);
        }
      }

      SetCurrentPosition(true);
      setGovernmentChecked(true);
      setPrivateChecked(false);
      setCurrentPosCheck(false);
      setForeignChecked(false);
      setIsAddForm(true);
    }
    if (isError) {
      toast.error(err?.message || 'Something Went Wrong!');
      setFromErrors(err.data, setErrors);
      SetCurrentPosition(true);
      setGovernmentChecked(true);
      setPrivateChecked(false);
      setForeignChecked(false);
      setCurrentPosCheck(false);
    }
  }, [isSuccess, isError]);

  const handlePosition = (e: any) => {
    if (e.target.checked) {
      SetCurrentPosition(false);
      setFieldValue('to_date', '');
    } else {
      SetCurrentPosition(true);
    }
  };

  const handleCurrentPosCheck = (e: any) => {
    setCurrentPosCheck(e.target.checked);
    // setInChargeCheck(false);
  };

  const handleGovernmentChecked = (e: any) => {
    setGovernmentChecked(e.target.checked);
    setPrivateChecked(!e.target.checked);
    setForeignChecked(!e.target.checked);

    setOrganizationName(e.target.value);
  };

  const handlePrivateChecked = (e: any) => {
    setGovernmentChecked(!e.target.checked);
    setPrivateChecked(e.target.checked);
    setForeignChecked(!e.target.checked);

    setOrganizationName(e.target.value);
  };

  const handleForeignChecked = (e: any) => {
    setGovernmentChecked(!e.target.checked);
    setPrivateChecked(!e.target.checked);
    setForeignChecked(e.target.checked);

    setOrganizationName(e.target.value);
  };

  const handleInChargeCheck = (e: any) => {
    console.log(e.target.checked);
    setInChargeCheck(e.target.checked);
    setFieldValue('in_charge', e.target.checked);
  };

  const handleHeadOfDepartment = (e: any) => {
    setDepartmentHeadCheck(e.target.checked);
    setFieldValue('department_head', e.target.checked);
  };

  return (
    <div>
      {isLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="incremental-from-field-grid">
          <div>
            <div className="large-form-field">
              <h6>Institute Type <i className="fas fa-info-circle" title="Select Government or Private as per your selected institute type."></i></h6>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="employment_type"
                  value="Government"
                  onChange={(e) => {
                    handleGovernmentChecked(e);
                    handleChange(e);
                  }}
                  id="employment-type-government"
                  checked={values.employment_type === 'Government'}
                />
                <label
                  className="form-check-label"
                  htmlFor="employment-type-government"
                >
                  Government
                </label>
              </div>

              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="employment_type"
                  value="Private"
                  onChange={(e) => {
                    handlePrivateChecked(e);
                    handleChange(e);
                  }}
                  id="employment-type-private"
                  checked={values.employment_type === 'Private'}
                />
                <label
                  className="form-check-label"
                  htmlFor="employment-type-private"
                >
                  Private
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="employment_type"
                  value="Foreign"
                  onChange={(e) => {
                    handleForeignChecked(e);
                    handleChange(e);
                  }}
                  checked={values.employment_type === 'Foreign'}
                  id="employment-type-Foreign"
                />
                <label
                  className="form-check-label"
                  htmlFor="employment-type-Foreign"
                >
                  Foreign
                </label>
              </div>
            </div>
            <div className='row mt-3 mb-3'>
              <div className='col-md-4 col-sm-12'>
                <Label className="form-label required-field">Job Title</Label>
                <Input
                  type="select"
                  name="designation"
                  value={values.designation}
                  onChange={handleChange}
                >
                  <option value="">Select Title</option>
                  {designationList?.map((designationItem) => (
                    <option key={designationItem.id} value={designationItem.name}>
                      {designationItem.name}
                    </option>
                  ))}
                  <option value="Others">Others</option>
                </Input>
                {errors.designation && touched.designation ? (
                  <div className="text-danger">
                    {errors.designation}
                  </div>
                ) : null}
              </div>

              {values.designation === 'Others' ? (
                <div className='col-md-4 col-sm-12'>
                  <Label className="form-label required-field">
                    Other Designation
                  </Label>
                  <InputField
                    type="text"
                    placeholder="Other Designation"
                    invalid={{ errors, touched }}
                    {...getFieldProps('others_designation_value')}
                  />
                </div>
              ) : null}

              <div className='col-md-4 col-sm-12'>
                <Label className="form-label required-field">Institute</Label>
                {/* <Input
                  type="select"
                  name="institute_name"
                  value={values.institute_name}
                  onChange={(e) => {
                    handleChange(e); 
                    handleInstitute(e);
                  }}
                >
                  <option value="">Select Institute</option>
                  {instituteList?.map((instituteItem) => (
                    <option key={instituteItem.id} value={instituteItem.organization_name}>
                      {instituteItem.organization_name}
                    </option>
                  ))}
                  <option value="Others">Others</option>
                </Input> */}
                <CreatableSelect
                  isClearable={true}
                  isSearchable={true}
                  value={allInstitute.find(
                    (option: any) =>
                      option.value === values.institute_name,
                  )}
                  onChange={(e: any) => {
                    if (e?.value) {
                      let selectedId = e.value;
                      setInstituteName(e?.value);
                      if (
                        allInstitute.find(
                          (option: SelectOption) =>
                            option.value === selectedId,
                        )
                      ) {
                        setFieldValue('institute_name', selectedId);
                      } else {
                        setFieldValue('institute_name', 'Others');
                        setFieldValue(
                          'others_institute_value',
                          selectedId,
                        );
                      }
                    } else {
                      setFieldValue('institute_name', '');
                    }
                  }}
                  options={allInstitute}
                  placeholder='Select Institute'
                  name="institute_name"
                />
                {errors.institute_name && touched.institute_name ? (
                  <div className="text-danger">
                    {errors.institute_name}
                  </div>
                ) : null}
              </div>

              {/* {values.institute_name === 'Others' ? (
                <div>
                  <Label className="form-label required-field">
                    Other Institute
                  </Label>
                  <InputField
                    type="text"
                    placeholder="Other Institute"
                    invalid={{ errors, touched }}
                    {...getFieldProps('others_institute_value')}
                  />
                </div>
              ) : null} */}

              <div className='col-md-4 col-sm-12'>
                <Label for="exampleSelect" className="form-label required-field">Department</Label>
                {/* <Input
                  type="select"
                  name="department_name"
                  value={values.department_name}
                  onChange={handleChange}
                >
                  <option value="">Select Department</option>
                  {departmentList?.map((departmentItem) => (
                    <option key={departmentItem.id} value={departmentItem.department_name}>
                      {departmentItem.department_name}
                    </option>
                  ))}
                  <option value="Others">Others</option>
                </Input> */}
                <Select
                  isClearable={true}
                  isSearchable={true}
                  value={allDepartment.find(
                    (option: any) =>
                      option.value === values.department_name,
                  )}
                  onChange={(e: any) => {
                    if (e?.value) {
                      let selectedId = e.value;
                      setFieldValue('department_name', selectedId);
                    } else {
                      setFieldValue('department_name', '');
                    }
                  }}
                  options={allDepartment}
                  placeholder='Select Department'
                  name="department_name"
                />
                {errors.department_name && touched.department_name ? (
                  <div className="text-danger">{errors.department_name}</div>
                ) : null}
              </div>

              {values.department_name === 'Others' ? (
                <div className='col-md-4 col-sm-12'>
                  <Label className="form-label required-field">
                    Other Department
                  </Label>
                  <InputField
                    type="text"
                    placeholder="Other Department"
                    invalid={{ errors, touched }}
                    {...getFieldProps('others_department_value')}
                  />
                </div>
              ) : null}

              <div className='col-md-4 col-sm-12'>
                <div className="input-group-with-label">
                  <div>
                    <Label className="form-Label required-field">From Date</Label>
                    <Input
                      className="form-control"
                      type="date"
                      name="from_date"
                      onChange={handleChange}
                      value={values.from_date}
                    />
                    {errors.from_date && touched.from_date ? (
                      <div className="text-danger">
                        {errors.from_date}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <Label className="form-Label">To Date</Label>
                    <Input
                      className="form-control"
                      type="date"
                      name="to_date"
                      onChange={handleChange}
                      value={values.to_date}
                      disabled={currentPosition === false}
                    />
                    {errors.to_date && touched.to_date ? (
                      <div className="text-danger">
                        {errors.to_date}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3 col-sm-12'>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="current_position"
                    onChange={(e) => {
                      handleCurrentPosCheck(e);
                      handleChange(e);
                    }}
                    onClick={(e) => handlePosition(e)}
                    id="employment-current-position"
                    checked={currentPosCheck}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="employment-current-position"
                  >
                    Current Position
                  </label>
                </div>
              </div>

              {currentPosCheck && (authUser?.type === 'Fellow' || authUser?.type === 'Supervisor') &&
                <>
                  <div className="form-check form-check-inline col-md-3 col-sm-12">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="department_head"
                      id='department-head'
                      onChange={(e: any) => {
                        handleHeadOfDepartment(e);
                        handleChange(e);
                      }}
                      checked={departmentHeadCheck}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="department-head"
                    >
                      Head of the Department?
                    </label>
                  </div>
                  <div className="form-check form-check-inline col-md-3 col-sm-12">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="in_charge"
                      id='in-charge'
                      onChange={(e) => {
                        handleInChargeCheck(e);
                        handleChange(e);
                      }}
                      checked={inChargeCheck}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="in-charge"
                    >
                      In-Charge of an Unit?
                    </label>
                  </div>
                  {inChargeCheck &&
                    <div className='col-md-2 col-sm-12'>
                      <Label className="form-label required-field">
                        Unit Name
                      </Label>
                      <InputField
                        type="text"
                        placeholder="Unit Name"
                        invalid={{ errors, touched }}
                        {...getFieldProps('unit_name')}
                      />
                    </div>}
                </>}
            </div>
          </div>
          <div className="d-flex mt-3 gap-2">
            <button className="btn btn-primary px-5" type="submit">
              Update
            </button>
            <button
              className="btn btn-outline-secondary px-5"
              type="button"
              onClick={(e) => {
                resetForm();
                // setValues(initialValues);
                SetCurrentPosition(true);
                setIsAddForm(true);
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default EditExperienceProfile;
