import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { Button, Col, Form, FormGroup, Label, Row, Table } from 'reactstrap';
import { InputField } from 'src/components';
import { config } from 'src/config';
import { setFromErrors } from 'src/helpers';
import { useGetBankInformationQuery } from 'src/rtk/features/researchClinicSetup/researchClinicSetupApi';
import { useGetDepartmentsQuery } from 'src/rtk/features/setup/department/departmentApi';
import { useGetOrganizationsQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import { useGetPersonalProfileQuery } from 'src/rtk/features/trainee/traineeApi';
import { useCreateTrainingAllowanceMutation } from 'src/rtk/features/trainingAllowance/trainingAllowanceApi';
import * as Yup from 'yup';

const initialValues = {
    start_date: '',
    end_date: '',
    name_in_block_letters: '',
    bank_name: '',
    branch_name: '',
    routing_no: '',
    account_no: '',
    submission_status: '',
    attachments: [],
    next_sessions: [],
};

interface Props {
    setModalShow: any;
}

const AllowanceForm = (props: Props) => {
    const { setModalShow } = props;

    const { data: bankInfo, isSuccess: isBankInfoSuccess, isLoading: isBankInfoLoading } = useGetBankInformationQuery();

    const { data: personalProfile, isError: isDeptFetchError, isLoading: isProfileLoading } = useGetPersonalProfileQuery();

    const { data: instituteLookupData } = useGetOrganizationsQuery();

    const { data: departmentLookupData } = useGetDepartmentsQuery();

    const [createTrainingAllowance, { isSuccess, isError, error }] = useCreateTrainingAllowanceMutation();

    const [btnClicked, setBtnClicked] = useState<string>('');
    const [allowanceId, setAllowanceId] = useState('');
    const [optionSelected, setOptionSelected] = useState('');
    const [instituteName, setInstituteName] = useState('');
    const [selectedInstitue, setSelectedInstitute] = useState<any>('');
    const [selectedDepartment, setSelectedDepartment] = useState<any>('');
    const [startDate, setStartDate] = useState<any>('');
    const [endDate, setEndDate] = useState<any>('');
    const [index, setIndex] = useState<number>(0);
    const [plusButtonDisabled, setPlusButtonDisabled] = useState<boolean>(true);

    const [inputList, setInputList] = useState<any>([]);
    const [attachmentList, setAttachmentList] = useState<any>([]);

    const allInstitute: SelectOption[] =
        instituteLookupData?.data?.map((institute) => ({
            value: institute?.id!,
            label: institute.organization_name,
        })) || [];

    const allDepartment: SelectOption[] =
        departmentLookupData?.data?.map((department: any) => ({
            value: department?.id!,
            label: department.department_name,
        })) || [];

    const err: CustomError = error as CustomError;

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({
            name_in_block_letters: Yup.string().required('Enter Your Name').matches(/^[A-Z\s]+$/, 'Name must be in block letters'),
            bank_name: Yup.string().required('Bank Name is Required'),
            branch_name: Yup.string().required('Branch Name is Required'),
            routing_no: Yup.string().required('Routing No is Required'),
            account_no: Yup.string().min(13, 'Must be 13 digits or above').required('Account No is Required'),
            start_date: optionSelected === 'Yes' ? Yup.string().required('Starting Date is Required') : Yup.string(),
            end_date: optionSelected === 'Yes' ? Yup.string().required('Ending Date is Required') : Yup.string(),
        }),

        onSubmit: (values: any) => {
            const formData = new FormData();

            values.submission_status = btnClicked;

            Object.keys(inputList).forEach((key) => {
                values.next_sessions.push(inputList[key]);
            });

            Object.keys(attachmentList).forEach((key) => {
                values.attachments.push(attachmentList[key]);
            });

            Object.keys(values).forEach((key) => {
                if (key === 'attachments') {
                    values.attachments.forEach((item: any, index: number) => {
                        formData.append(`attachments[${index}][file]`, item.file);
                        formData.append(`attachments[${index}][file_name]`, item.file_name);
                    });
                }

                if (key === 'next_sessions') {
                    values.next_sessions.forEach((item: any, index: number) => {
                        formData.append(`next_sessions[${index}][institute_id]`, item.institute_id);
                        formData.append(`next_sessions[${index}][department_id]`, item.department_id);
                        formData.append(`next_sessions[${index}][starting_date]`, item.starting_date);
                        formData.append(`next_sessions[${index}][ending_date]`, item.ending_date);
                    });
                }

                if (key === 'submission_status') {
                    if (btnClicked === 'submit') {
                        values[key] = 'Submit';
                    } else if (btnClicked === 'save_as_draft') {
                        values[key] = 'Draft';
                    }
                }

                if (key !== 'attachments' && key !== 'next_sessions') {
                    formData.append(key, values[key]);
                }
            });
            createTrainingAllowance(formData);
            console.log(values);
        },
    });

    useEffect(() => {
        if (bankInfo?.data?.bank_info) {
            setFieldValue('name_in_block_letters', bankInfo?.data?.registration?.personal_info?.full_name.toUpperCase());
            setFieldValue('bank_name', bankInfo?.data?.bank_info?.bank_name);
            setFieldValue('branch_name', bankInfo?.data?.bank_info?.branch_name);
            setFieldValue('routing_no', bankInfo?.data?.bank_info?.routing_no);
            setFieldValue('account_no', bankInfo?.data?.bank_info?.account_no);
        }
    }, [bankInfo]);

    useEffect(() => {
        if (isSuccess) {
            setSelectedInstitute([]);
            setSelectedDepartment([]);
            setModalShow(false);
            toast.success('Training Allowance Entry Successfully!');
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
            setAttachmentList([]);
        }
    }, [isSuccess, isError]);

    const handleAddFields = () => {
        const values = [...inputList];
        values.push({
            institute_id: selectedInstitue?.value,
            department_id: selectedDepartment?.value,
            starting_date: startDate,
            ending_date: endDate
        });
        setInputList(values);
        setPlusButtonDisabled(true);
        setSelectedInstitute('');
        setSelectedDepartment('');
        setStartDate('');
        setEndDate('');
    };

    const handleRemoveFields = (index: number) => {
        const values = [...inputList];
        values.splice(index, 1);
        setInputList(values);
    };

    const handleInputChange = (name: any, value: any) => {
        if (value !== '' && name === 'starting_date') {
            setStartDate(value);
            const fromDate = new Date(value);
            const newToDate = new Date(fromDate.getFullYear(), fromDate.getMonth() + 6, fromDate.getDate());
            // const values = [...inputList];
            // values[index]['ending_date'] = newToDate?.toISOString().slice(0, 10);
            // setInputList(values);
            setEndDate(newToDate?.toISOString().slice(0, 10))
        }

        // const values = [...inputList];
        // values[index][name] = value;
        // setInputList(values);
    };

    const handleAttachmentChange = (fileName: string, file: any) => {
        if(fileName && file) {
            const newIndex = index;
            const values = [...attachmentList];
            values[newIndex] = {
                file: file,
                file_name: fileName
            };
            setAttachmentList(values);
            setIndex((prev) => prev + 1);
        }
    };

    const fcpsPartOneInfo = personalProfile?.data?.details?.educational_infos?.find((item: any) => item?.name_of_the_degree === 'FCPS-Part-1');
    const bdsInfo = personalProfile?.data?.details?.educational_infos?.find((item: any) => item?.name_of_the_degree === 'BDS');

    useEffect(() => {
        if (selectedInstitue && selectedDepartment && startDate && endDate) {
            setPlusButtonDisabled(false);
        }
    }, [selectedInstitue, selectedDepartment, startDate, endDate]);

    return (
        <React.Fragment>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
                onReset={(e) => {
                    resetForm();
                    setOptionSelected('');
                    setFieldValue('attachments', []);
                }}
            >
                <FormGroup row className="col-sm-12 col-md-8 col-lg-8">
                    <Label sm={6} lg={6} md={6}>Are you selected or continuing the residency training/diploma course/ Govt. service/Private service?</Label>
                    <Col sm={6} lg={6} md={6} className='mt-3'>
                        <div className="d-inline-block mx-2">
                            <div className="form-check form-check-left mb-3">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="selected"
                                    id="optionYes"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setOptionSelected('Yes');
                                        }
                                    }}
                                    value={"Yes"}
                                />
                                <label className="form-check-label" htmlFor="optionYes">Yes</label>
                            </div>
                        </div>

                        <div className="d-inline-block mx-2">
                            <div className="form-check form-check-left">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="selected"
                                    id="optionNo"
                                    value={"No"}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setOptionSelected('No');
                                        }
                                    }}
                                />
                                <label className="form-check-label" htmlFor="optionNo">No</label>
                            </div>
                        </div>
                    </Col>
                </FormGroup>

                {optionSelected === 'Yes' &&
                    <FormGroup row className="col-sm-12 col-md-8 col-lg-8">
                        <Label for="start_date" className="form-label required-field" sm={3} lg={3} md={3}>Starting Date</Label>
                        <Col sm={3} lg={3} md={3}>
                            <InputField
                                type="date"
                                name='start_date'
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                invalid={{ errors, touched }}
                            />
                        </Col>

                        <Label for="end_date" className="form-label required-field" sm={3} lg={3} md={3}>Ending Date</Label>
                        <Col sm={3} lg={3} md={3}>
                            <InputField
                                type="date"
                                name='end_date'
                                onChange={(e: any) => {
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                invalid={{ errors, touched }}
                            />
                        </Col>
                    </FormGroup>}
                <Table className='table table-bordered table-striped table-sm mb-0'>
                    <thead>
                        <tr className='bg-light'>
                            <td colSpan={6}><h5>Mention the name of the institutes with department recognized by BCPS according to your choice where you
                                want to obtain the fellowship training: (Please schedule the rest of training Including FCPS course and excluding current duration)
                            </h5></td>
                        </tr>
                        <tr>
                            <th rowSpan={2} className='col-lg-3'>Name of the Institutes</th>
                            <th rowSpan={2} className='col-lg-3'>Name of the Department</th>
                            <th colSpan={2} className="text-center">Duration of training</th>
                        </tr>

                        <tr>
                            <th className='col-lg-3'>Starting date</th>
                            <th className='col-lg-2'>Ending date</th>
                            <th className='col-lg-1'>Action</th>
                        </tr>
                    </thead>
                </Table>
                <div key={index} className='border p-2'>
                    <Row>
                        <Col lg={3}>
                            {/* <InputField
                                                    type="select"
                                                    name='institute_id'
                                                    onChange={(event: any) => {
                                                        handleInputChange(index, event?.target?.name, event?.target?.value)
                                                    }}
                                                    onBlur={handleBlur}
                                                    invalid={{ errors, touched }}
                                                    options={allInstitute}
                                                /> */}
                            <Select
                                isClearable={true}
                                isSearchable={true}
                                // value={allInstitute.find(
                                //     (option: any) =>
                                //         option.value === selectedInstitue,
                                // )}
                                onChange={setSelectedInstitute}
                                value={selectedInstitue}
                                options={allInstitute}
                                placeholder='Select Institute'
                                name="institute_id"
                            />
                        </Col>
                        <Col lg={3}>
                            {/* <InputField
                                                    type="select"
                                                    name='department_id'
                                                    placeholder='Department'
                                                    onChange={(event: any) => {
                                                        handleInputChange(index, event?.target?.name, event?.target?.value)
                                                    }}
                                                    onBlur={handleBlur}
                                                    invalid={{ errors, touched }}
                                                    options={allDepartment}
                                                /> */}
                            <Select
                                isClearable={true}
                                isSearchable={true}
                                // value={allDepartment.find(
                                //     (option: any) =>
                                //         option.value === selectedDepartment,
                                // )}
                                onChange={setSelectedDepartment}
                                value={selectedDepartment}
                                options={allDepartment}
                                placeholder='Select Department'
                                name="department_id"
                            />
                        </Col>
                        <Col lg={3}>
                            <InputField
                                type="date"
                                name='starting_date'
                                onChange={(event: any) => {
                                    handleInputChange(event?.target?.name, event?.target?.value)
                                }}
                                value={startDate}
                                onBlur={handleBlur}
                                invalid={{ errors, touched }}
                                min={new Date(Date.now() + 86400000).toISOString().slice(0, 10)}
                            />
                        </Col>
                        <Col lg={2}>
                            <InputField
                                type="date"
                                name='ending_date'
                                onChange={(event: any) => {
                                    handleInputChange(event?.target?.name, event?.target?.value)
                                }}
                                value={endDate}
                                onBlur={handleBlur}
                                invalid={{ errors, touched }}
                                disabled
                            />
                        </Col>
                        <Col lg={1}>
                            <div className='text-end my-2'>
                                <button
                                    type="button"
                                    className={'btn btn-sm btn-primary'}
                                    onClick={handleAddFields}
                                    disabled={plusButtonDisabled}
                                >
                                    <i className="fa fa-plus-circle" />
                                </button>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div>
                    <table className='table table-bordered table-sm'>
                        <tbody>
                            {inputList.map((input: any, index: any) => (
                                <tr key={index}>
                                    <td className='col-3'>{allInstitute?.find((item: any) => item?.value === input?.institute_id)?.label}</td>
                                    <td className='col-3'>{allDepartment?.find((item: any) => item?.value === input?.department_id)?.label}</td>
                                    <td className='col-3'>{moment(input?.starting_date).format('DD-MMM-YYYY')}</td>
                                    <td className='col-2'>{moment(input?.ending_date).format('DD-MMM-YYYY')}</td>
                                    <td className='col-1 text-end'>
                                        <button
                                            type="button"
                                            className={'btn btn-sm btn-danger'}
                                            onClick={() => handleRemoveFields(index)}
                                        >
                                            <i className="fa fa-minus-circle" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <fieldset className="border p-2">
                    <legend className='w-auto' style={{ float: 'none', padding: 'inherit', fontSize: 20, fontWeight: 'bold' }}>Applicant's Personal Bank Information:</legend>

                    <FormGroup row className="col-sm-12 col-md-8 col-lg-8">
                        <Label sm={6} lg={6} md={6} for="name_in_block_letters" className="form-label required-field">Name in block letters (as per Bank Account)</Label>
                        <Col sm={6} lg={6} md={6}>
                            <InputField
                                type="text"
                                placeholder='Name in block letters (as per Bank Account)'
                                {...getFieldProps('name_in_block_letters')}
                                invalid={{ errors, touched }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row className="col-sm-12 col-md-8 col-lg-8">
                        <Label sm={6} lg={6} md={6} for="bank_name" className="form-label required-field">Name of the bank</Label>
                        <Col sm={6} lg={6} md={6}>
                            <InputField
                                type="text"
                                placeholder="Name of the bank"
                                {...getFieldProps('bank_name')}
                                invalid={{ errors, touched }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row className="col-sm-12 col-md-8 col-lg-8">
                        <Label sm={6} lg={6} md={6} for="branch_name" className="form-label required-field">Name of the branch</Label>
                        <Col sm={6} lg={6} md={6}>
                            <InputField
                                type="text"
                                placeholder="Name of the branch"
                                {...getFieldProps('branch_name')}
                                invalid={{ errors, touched }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row className="col-sm-12 col-md-8 col-lg-8">
                        <Label sm={6} lg={6} md={6} for="routing_no" className="form-label required-field">Routing Number</Label>
                        <Col sm={6} lg={6} md={6}>
                            <InputField
                                type="text"
                                placeholder="Routing Number"
                                {...getFieldProps('routing_no')}
                                invalid={{ errors, touched }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row className="col-sm-12 col-md-8 col-lg-8">
                        <Label sm={6} lg={6} md={6} for="account_no" className="form-label required-field">Account Number (13 digits or above)</Label>
                        <Col sm={6} lg={6} md={6}>
                            <InputField
                                type="number"
                                placeholder="Account Number (13 digits or above)"
                                {...getFieldProps('account_no')}
                                invalid={{ errors, touched }}
                            />
                        </Col>
                    </FormGroup>
                </fieldset>
                <fieldset className="border p-2">
                    <legend className='w-auto' style={{ float: 'none', padding: 'inherit', fontSize: 20, fontWeight: 'bold' }}>Enclosures: (The applicants have to scan and upload the following documents)</legend>
                    <FormGroup row className="col-sm-12 col-md-8 col-lg-8">
                        <Label sm={6} lg={6} md={6} for="applicant_signature" className="form-label">Applicant’s Signature with Date: </Label>
                        {personalProfile?.data?.details?.personal_info?.signature ?
                            <Col sm={6} lg={6} md={6}>
                                <div>
                                    <a
                                        href={config.APP_URL + personalProfile?.data?.details?.personal_info?.signature}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <p className="btn btn-sm btn-outline-primary w-100">
                                            View Applicant’s Signature
                                        </p>
                                    </a>
                                </div>
                            </Col>
                            :
                            <Col sm={6} lg={6} md={6}>
                                <input
                                    name="applicant_signature"
                                    type="file"
                                    className="form-control"
                                    placeholder="Upload Certificate"
                                    onChange={(e: any) => {
                                        handleAttachmentChange('applicant_signature', e.target.files[0]);
                                    }}
                                // required
                                >
                                </input>
                            </Col>}
                    </FormGroup>
                    <FormGroup row className="col-sm-12 col-md-8 col-lg-8">
                        <Label sm={6} lg={6} md={6} for="recent_photograph" className="form-label">1. Recent passport size color photograph :</Label>
                        {personalProfile?.data?.details?.personal_info?.picture ?
                            <Col sm={6} lg={6} md={6}>
                                <div>
                                    <a
                                        href={config.APP_URL + personalProfile?.data?.details?.personal_info?.picture}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <p className="btn btn-sm btn-outline-primary w-100">
                                            View Applicant’s Photograph
                                        </p>
                                    </a>
                                </div>
                            </Col>
                            :
                            <Col sm={6} lg={6} md={6}>
                                <input
                                    name="recent_photograph"
                                    type="file"
                                    className="form-control"
                                    placeholder="Upload Certificate"
                                    onChange={(e: any) => {
                                        handleAttachmentChange('recent_photograph', e.target.files[0]);
                                    }}
                                // required
                                >
                                </input>
                            </Col>}
                    </FormGroup>
                    <FormGroup row className="col-sm-12 col-md-8 col-lg-8">
                        <Label sm={6} lg={6} md={6} for="congratulation_letter" className="form-label">2. Congratulation letter of FCPS Part-I/ FCPS Part-I passed document: </Label>
                        {fcpsPartOneInfo?.certificate_pdf ?
                            <Col sm={6} lg={6} md={6}>
                                <div>
                                    <a
                                        href={config.APP_URL + fcpsPartOneInfo?.certificate_pdf}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <p className="btn btn-sm btn-outline-primary w-100">
                                            View FCPS Part-1 Document
                                        </p>
                                    </a>
                                </div>
                            </Col>
                            :
                            <Col sm={6} lg={6} md={6}>
                                <input
                                    name="congratulation_letter"
                                    type="file"
                                    className="form-control"
                                    placeholder="Upload Certificate"
                                    onChange={(e: any) => {
                                        handleAttachmentChange('congratulation_letter', e.target.files[0]);
                                    }}
                                // required
                                >
                                </input>
                            </Col>}
                    </FormGroup>
                    <FormGroup row className="col-sm-12 col-md-8 col-lg-8">
                        <Label sm={6} lg={6} md={6} for="mbbs_certificate_pdf" className="form-label">3. Certificate of MBBS/BDS: </Label>
                        {bdsInfo?.certificate_pdf ?
                            <Col sm={6} lg={6} md={6}>
                                <div>
                                    <a
                                        href={config.APP_URL + bdsInfo?.certificate_pdf}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <p className="btn btn-sm btn-outline-primary w-100">
                                            View BDS Certificate
                                        </p>
                                    </a>
                                </div>
                            </Col>
                            :
                            <Col sm={6} lg={6} md={6}>
                                <input
                                    name="mbbs_certificate_pdf"
                                    type="file"
                                    className="form-control"
                                    placeholder="Upload Certificate"
                                    onChange={(e: any) => {
                                        handleAttachmentChange('mbbs_certificate_pdf', e.target.files[0]);
                                    }}
                                // required
                                >
                                </input>
                            </Col>}
                    </FormGroup>
                    <FormGroup row className="col-sm-12 col-md-8 col-lg-8">
                        <Label sm={6} lg={6} md={6} for="permanent_reg_certificate" className="form-label">4. Permanent registration certificate of BMDC: </Label>
                        {personalProfile?.data?.details?.bmdc_certificate ?
                            <Col sm={6} lg={6} md={6}>
                                <div>
                                    <a
                                        href={config.APP_URL + personalProfile?.data?.details?.bmdc_certificate}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <p className="btn btn-sm btn-outline-primary w-100">
                                            View BMDC Certificate
                                        </p>
                                    </a>
                                </div>
                            </Col>
                            :
                            <Col sm={6} lg={6} md={6}>
                                <input
                                    name="permanent_reg_certificate"
                                    type="file"
                                    className="form-control"
                                    placeholder="Upload Certificate"
                                    onChange={(e: any) => {
                                        handleAttachmentChange('permanent_reg_certificate', e.target.files[0]);
                                    }}
                                // required
                                >
                                </input>
                            </Col>}
                    </FormGroup>
                    <FormGroup row className="col-sm-12 col-md-8 col-lg-8">
                        <Label sm={6} lg={6} md={6} for="training_certifcate" className="form-label">5. Training certificates (if applicable): </Label>
                        <Col sm={6} lg={6} md={6}>
                            <input
                                name="training_certifcate"
                                type="file"
                                className="form-control"
                                placeholder="Upload Certificate"
                                onChange={(e: any) => {
                                    handleAttachmentChange('training_certifcate', e.target.files[0]);
                                }}
                            // required
                            >
                            </input>
                        </Col>
                    </FormGroup>
                    <FormGroup row className="col-sm-12 col-md-8 col-lg-8">
                        <Label sm={6} lg={6} md={6} for="bank_cheque" className="form-label">6. A page of the Bank Cheque book of the applicant : </Label>
                        <Col sm={6} lg={6} md={6}>
                            <input
                                name="bank_cheque"
                                type="file"
                                className="form-control"
                                placeholder="Upload Certificate"
                                onChange={(e: any) => {
                                    handleAttachmentChange('bank_cheque', e.target.files[0]);
                                }}
                            // required
                            >
                            </input>
                        </Col>
                    </FormGroup>
                    <FormGroup row className="col-sm-12 col-md-8 col-lg-8">
                        <Label sm={6} lg={6} md={6} for="national_id" className="form-label">7. National ID card: </Label>
                        <Col sm={6} lg={6} md={6}>
                            <input
                                name="national_id"
                                type="file"
                                className="form-control"
                                placeholder="Upload Certificate"
                                onChange={(e: any) => {
                                    handleAttachmentChange('national_id', e.target.files[0]);
                                }}
                            // required
                            >
                            </input>
                        </Col>
                    </FormGroup>
                    <FormGroup row className="col-sm-12 col-md-8 col-lg-8">
                        <Label sm={6} lg={6} md={6} for="testimoninal" className="form-label">8. Joining letter/ Testimonial: </Label>
                        <Col sm={6} lg={6} md={6}>
                            <input
                                name="testimoninal"
                                type="file"
                                className="form-control"
                                placeholder="Upload Certificate"
                                onChange={(e: any) => {
                                    handleAttachmentChange('testimoninal', e.target.files[0]);
                                }}
                            // required
                            >
                            </input>
                        </Col>
                    </FormGroup>
                    <FormGroup row className="col-sm-12 col-md-8 col-lg-8">
                        <Label sm={6} lg={6} md={6} for="others_document" className="form-label">9. Other necessary documents: </Label>
                        <Col sm={6} lg={6} md={6}>
                            <input
                                name="others_document"
                                type="file"
                                className="form-control"
                                placeholder="Upload Certificate"
                                onChange={(e: any) => {
                                    handleAttachmentChange('others_document', e.target.files[0]);
                                }}
                            // required
                            >
                            </input>
                        </Col>
                    </FormGroup>
                </fieldset>
                <FormGroup className="my-3 text-center">
                    <Button
                        type="reset"
                        className="btn btn-danger me-2"
                    >
                        Reset
                    </Button>
                    {/* <button
                                        type="submit"
                                        className="btn btn-info me-2"
                                        // ref={draftButtonRef}
                                        onClick={(e) => {
                                            setBtnClicked('save_as_draft');
                                        }}
                                    >
                                        Save as Draft
                                    </button> */}
                    <button
                        type="submit"
                        // ref={buttonRef}
                        className="btn btn-success"
                        onClick={(e) => {
                            setBtnClicked('submit');
                        }}
                    >
                        Submit
                    </button>
                </FormGroup>
            </Form>
        </React.Fragment >
    );
};

export default AllowanceForm;
