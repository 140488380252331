import html2pdf from 'html2pdf.js';
import moment from 'moment';
import { useEffect } from 'react';

interface Props {
  data: any;
  setCertificate?: any;
  certificateId: string;
  setCertificateId: any;
  certificateInfoObj?: any;
  trainingTitleForCertificate?: string;
  trainingDuration?: string;
  trainingDurationUnit?: string;
}

export const ViewCertificatePdf = (props: Props) => {
  const { data, setCertificate, certificateId, setCertificateId, certificateInfoObj, trainingTitleForCertificate, trainingDuration, trainingDurationUnit } = props;
  const image1 = new Image();
  image1.src = '/images/certificate_header.PNG';
  const image2 = new Image();
  image2.src = '/images/certificate_footer.PNG';

  let html = `
    <style>        
        table {
            border-top: 1px solid black;
            border-left: 1px solid black;
        }
        tr {
          border-bottom: 1px solid black;
        }
        td {
          border-right: 1px solid black;
        }
        th {
          border-right: 1px solid black;
        }
    </style>               
  `;

  try {
    html = html + JSON.parse(data?.notice_body)?.editorHtml;
  } catch (error: any) {
    html =
      '<h1 style={{textAlign: "center"}}><span><em><strong>Certificate Body</strong></em></span></h1>';
  }

  useEffect(() => {
    if (setCertificate) {
      setCertificate({});
    }
  }, []);

  const options = {
    margin: [90, 20, 30, 20],
    image: { type: 'png', quality: 1 },
    html2canvas: { scale: 2, useCORS: true },
    jsPDF: {      
      format: 'a4',                                    
      orientation: 'landscape'
    }
  };

  if (certificateInfoObj && trainingTitleForCertificate) {
    html = html.replaceAll('[Bmdc Reg No]', certificateInfoObj.bmdc_reg_no);
    html = html.replaceAll('[Name]', `<strong>${certificateInfoObj.name}</strong>`);
    html = html.replaceAll('[Institute]', certificateInfoObj.institute);
    html = html.replaceAll('[Subject]', certificateInfoObj.subject);
    html = html.replaceAll('[Issue Date]', certificateInfoObj.issue_date);
    html = html.replaceAll('[Training Title]', trainingTitleForCertificate);
    html = html.replaceAll('[Training Duration]', trainingDuration + ' ' + trainingDurationUnit);
    html = html.replaceAll('[Training Start Date]', moment(new Date(Date.now() + 86400000)).format('DD MMMM'));
    html = html.replaceAll('[Training End Date]', moment(new Date(Date.now()))
                                                  .add(trainingDuration, trainingDurationUnit === 'day' ? 
                                                  'd' : trainingDurationUnit === 'week' ? 
                                                  'w' : trainingDurationUnit === 'month' ? 
                                                  'M' : trainingDurationUnit === 'year' ? 
                                                  'y' : 'd')
                                                  .format('DD MMMM'));
    html = html.replaceAll('[Training Year]', moment(new Date(Date.now())).format('YYYY'));
  }

  if (certificateId) {
    html2pdf()
      .from(`<div style='text-align: justify; -moz-text-align-last: center; text-align-last: center; color: rgb(0, 0, 0);'>${html}</div>`)
      .set(options)
      .toPdf()
      .get('pdf')
      .then(function (pdf: any) {
        var totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor(150);
          pdf.addImage(image1, "PNG", 0, 0, pdf.internal.pageSize.getWidth(), 80, '', 'NONE', -0.55);
          pdf.addImage(image2, "PNG", 0, pdf.internal.pageSize.getHeight() - 30, pdf.internal.pageSize.getWidth(), 20, '', 'NONE', -0.53);
        }

        window.open(pdf.output('bloburl'), '_blank');
      });

    setCertificateId('');
  }


  return null;
};
