import { Alert } from 'reactstrap';

interface Props {
  title?: string;
  message?: string;
  color?: string;
  icon?: string;
}
const NonPermissonMessage = ({
  title = 'You are not authorized!!!',
  message = `You are not authorized to perform this action. Please contact with system admin for permission.`,
  color = 'danger',
  icon = 'mdi mdi-alert-outline ',
}: Props) => {
  return (
    <>
      <Alert color={color} className="px-4 mb-0 text-center">
        <i className={icon + '  d-block display-4 mt-2 mb-3 text-' + color}></i>
        <h5 className={'text-' + color}>{title}</h5>
        <p dangerouslySetInnerHTML={{ __html: message }}></p>
      </Alert>
    </>
  );
};

export default NonPermissonMessage;
