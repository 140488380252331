import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetInstituteRegistrationListQuery } from 'src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi';
import InstituteDeptRegistrationStepFive from './InstituteDeptRegistrationStepFive';
import InstituteDeptRegistrationStepFour from './InstituteDeptRegistrationStepFour';
import InstituteDeptRegistrationStepOne from './InstituteDeptRegistrationStepOne';
import InstituteDeptRegistrationStepSeven from './InstituteDeptRegistrationStepSeven';
import InstituteDeptRegistrationStepThree from './InstituteDeptRegistrationStepThree';
import InstituteDeptRegistrationStepTwo from './InstituteDeptRegistrationStepTwo';

const RenewInstituteDeptRegistration = () => {
    const { departmentId: deptId, formBId: formBDepartmentId } = useParams<{ departmentId: string; formBId: string }>();
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [instituteName, setInstituteName] = useState<string>('');
    const [renewDoneState, setRenewDoneState] = useState<boolean>(false);
    const [newDeptId, setNewDeptId] = useState<string>(deptId);
    const [formBDeptId, setFormBDeptId] = useState<string>(formBDepartmentId);

    const { data: instituteRegData, isSuccess: isInstituteRegDataSuccess, isError: isInstituteRegDataError, error: instituteRegDataError } = useGetInstituteRegistrationListQuery();

    const goToNext = (departmentId?: string, formBDeptId?: string) => {
        if (currentStep < 6) {
            if (currentStep === 1) {
                setRenewDoneState(true);
            }
            setCurrentStep((p: number) => p + 1);
        } else {
            setCurrentStep(1);
        }

        // console.log(departmentId);

        if (departmentId) {
            // console.log(departmentId);
            setNewDeptId(departmentId);
        }

        if (formBDeptId) {
            // console.log(formBDeptId);
            setFormBDeptId(formBDeptId);
        }
    };

    const goToPrevious = (departmentId?: string, formBDeptId?: string) => {
        if (currentStep > 1) {
            setCurrentStep((p: number) => p - 1);
        } else {
            setCurrentStep(6);
        }
    };

    document.title = 'Training Accreditation Application Form | BCPS';

    useEffect(() => {
        if (isInstituteRegDataSuccess) {
            setInstituteName(instituteRegData?.data?.institute_name!);
        }
    }, [isInstituteRegDataSuccess]);

    return (
        <>
            {currentStep === 1 && <InstituteDeptRegistrationStepOne goToNext={goToNext} instituteName={instituteName} departmentId={newDeptId} formBDeptId={formBDeptId} renewDepartment={true} renewDoneState={renewDoneState} />}
            {currentStep === 2 && <InstituteDeptRegistrationStepTwo goToNext={goToNext} goToPrevious={goToPrevious} instituteName={instituteName} departmentId={newDeptId} formBDeptId={formBDeptId} renewDepartment={true} />}
            {currentStep === 3 && <InstituteDeptRegistrationStepThree goToNext={goToNext} goToPrevious={goToPrevious} instituteName={instituteName} departmentId={newDeptId} formBDeptId={formBDeptId} renewDepartment={true} />}
            {currentStep === 4 && <InstituteDeptRegistrationStepFour goToNext={goToNext} goToPrevious={goToPrevious} instituteName={instituteName} departmentId={newDeptId} formBDeptId={formBDeptId} renewDepartment={true} />}
            {currentStep === 5 && <InstituteDeptRegistrationStepFive goToNext={goToNext} goToPrevious={goToPrevious} instituteName={instituteName} departmentId={newDeptId} formBDeptId={formBDeptId} renewDepartment={true} />}
            {currentStep === 6 && <InstituteDeptRegistrationStepSeven goToPrevious={goToPrevious} instituteName={instituteName} departmentId={newDeptId} formBDeptId={formBDeptId} renewDepartment={true} />}
        </>
    );
};

export default RenewInstituteDeptRegistration;
