//import Breadcrumbs
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table
} from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetRolesQuery } from 'src/rtk/features/roles/roleApi';
import loader from '../../assets/images/loader.gif';
import RoleTableRow from './RoleTableRow';

const Roles = () => {
  const { data, isLoading, isError, error } = useGetRolesQuery();

  const err: CustomError = error as CustomError;
  const roles = data?.data || [];

  // what to render
  let content = null;

  if (isLoading) {
    content = <tr><td colSpan={3} className="text-center">Loading...</td></tr>;
  }
  if (!isLoading && isError && error) {
    content = <tr> <td colSpan={3} className="text-center">{err.message}</td></tr>;
  }
  if (!isLoading && !isError && roles?.length > 0) {
    content = roles.map((role, index) => <RoleTableRow role={role} key={role.id} index={index} />);
  }

  return (
    <div className="page-content">
      {isLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb title="Roles" />
        <Card>

          <CardBody>
            <Row>
              <Col className="text-end">
                <Link
                  to="/roles/add-role"
                  className="btn btn-success waves-effect waves-light"
                >
                  <i className="fa fa-plus me-2" /> Create New
                </Link>
              </Col>
            </Row>
            <div className="table-responsive">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Sl.</th>
                    <th>Role</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Roles;
