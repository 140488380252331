import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button, Form, FormGroup, Table } from 'reactstrap';
import { InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import { useCreateMiniCexFormAEntryMutation } from 'src/rtk/features/miniCexCBD/miniCexAndCBDApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

interface Props {
    regitrationNo: string;
    supervisorId: string;
    sessionId: string;
}
const MiniCexFormA = (props: Props) => {
    const { regitrationNo, supervisorId, sessionId } = props;

    const history = useHistory();

    const [miniCexFormData, setMiniCexFormData] = useState<any>({});
    const [createMiniCexFormAEntry, { isSuccess, isError, error, isLoading }] = useCreateMiniCexFormAEntryMutation();

    const { data: allList } = useGetLookupDataQuery('ins_form_a');

    const formAData: Lookup[] =
        (allList as any)?.data[0]?.childs || [];
    // console.log(formAData);

    const initialValues: MiniCEXFormA = {
        registration_id: '',
        supervisor_id: '',
        logbook_entry_session_id: '',
        data: [],
    }

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({

        }),

        onSubmit: (values: any) => {
            // console.log(miniCexFormData);
            values.data = [];
            values.registration_id = regitrationNo;
            values.supervisor_id = supervisorId;
            values.logbook_entry_session_id = sessionId;

            Object.keys(miniCexFormData).forEach((key) => {
                values.data.push(miniCexFormData[key]);
            });

            if(values.data.length !== 35) {
                toast.error('You must select all Information');
            }else {
                console.log(values);
                createMiniCexFormAEntry(values);
            }
        },
    });
    const err: CustomError = error as CustomError;

    const handlePerformData = (e: any, competence: string, descriptor: string, descriptorId: string) => {
        const item = { ...miniCexFormData };

        if (e.target.value) {
            item[descriptorId] = {
                competence: competence,
                descriptor: descriptor,
                perform: e.target.value
            }
        }
        setMiniCexFormData(item);
    }

    useEffect(() => {
        if (isSuccess) {
            setMiniCexFormData({});
            resetForm();
            toast.success('Form A Information Stored Successfully!');
            history.push('/mini-cex-list');
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);

    return (
        <div>
            {isLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Form
                className="custom-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
                onReset={(e) => {
                    setMiniCexFormData({});
                    resetForm();
                }}
            >
                <div className='mt-2'>
                    <div className='table-responsive'>

                        <Table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Competence</th>
                                    <th>Descriptor</th>
                                    <th>Perform</th>
                                </tr>
                            </thead>
                            <tbody>
                                {formAData?.map((item: any) => (
                                    <tr key={item.id}>
                                        <td>{item.name}</td>
                                        <td className='p-0'>
                                            <Table>
                                                <tbody>
                                                    {item.childs.map((i: any) => (
                                                        <tr key={i.id}>
                                                            <td>{i.name}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </td>
                                        <td className='p-0'>
                                            <Table>
                                                <tbody>
                                                    {item.childs.map((i: any) => (
                                                        <tr key={i.id}>
                                                            <td className='p-2'>
                                                                <InputField
                                                                    type="select"
                                                                    className='form-select-sm'
                                                                    options={[
                                                                        { value: '1', label: '1' },
                                                                        { value: '2', label: '2' },
                                                                        { value: '3', label: '3' },
                                                                        { value: '4', label: '4' },
                                                                        { value: '5', label: '5' },
                                                                    ]}
                                                                    name="perform"
                                                                    onChange={(e: any) => {
                                                                        handlePerformData(e, item.name, i.name, i.id);
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                    <FormGroup className="mb-3 text-center">
                        <Button
                            type="reset"
                            className="btn btn-secondary me-2"
                        >
                            Reset
                        </Button>

                        <button
                            type="submit"
                            className="btn btn-success"
                        >
                            Submit
                        </button>
                    </FormGroup>
                </div>
            </Form>
        </div>
    );
};

export default MiniCexFormA;
