import { apiSlice } from 'src/rtk/api/apiSlice';
import * as researchClinicSetupUrl from 'src/helpers/url_helper';

export const researchClinicSetupApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: [
      'researchClinicSetup',
      'paymentPackage',
      'submittedQuery',
      'WorkList',
      'finalExam',
      'ResearchGrantForm',
      'MoneyReceipt',
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getInquiryList: builder.query<APIResponse<InquiryList[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${researchClinicSetupUrl.GET_INQUIRY_LIST}`,
        }),

        providesTags: ['researchClinicSetup'],
      }),

      getInquiryListForTrainee: builder.query<APIResponse<InquiryList[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${researchClinicSetupUrl.GET_INQUIRY_LIST_FOR_TRAINEE}`,
        }),

        providesTags: ['researchClinicSetup'],
      }),

      getSubmittedQueryList: builder.query<APIResponseWithPagination<InquiryList[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${researchClinicSetupUrl.GET_SUBMITTED_QUERY_LIST}`,
        }),

        providesTags: ['submittedQuery'],
      }),

      getResearchClinicWorkList: builder.query<APIResponseWithPagination<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${researchClinicSetupUrl.GET_RESEARCH_CLINIC_WORKLIST}?${url}`,
        }),

        providesTags: ['WorkList'],
      }),

      getWorkListForConsultant: builder.query<APIResponse<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${researchClinicSetupUrl.GET_WORKLIST_FOR_CONSULTANT}?${url}`,
        }),

        providesTags: ['WorkList'],
      }),

      getResolveStatusUpdate: builder.mutation<APIResponse<any>, void>({
        query: (id) => ({
          method: 'GET',
          url: `${researchClinicSetupUrl.RESOLVE_STATUS_UPDATE}/${id}`,
        }),

        invalidatesTags: ['WorkList'],
      }),

      createSolutionEntry: builder.mutation<
        APIResponse<any>,
        any
      >({
        query: (url) => ({
          url: `${researchClinicSetupUrl.CREATE_SOLUTION_ENTRY}/${url.id}`,
          method: 'POST',
          body: url.data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['WorkList'],
      }),

      assignConsultant: builder.mutation<
        APIResponse<any>,
        any
      >({
        query: (url) => ({
          url: `${researchClinicSetupUrl.ASSIGN_CONSULTANT}/${url.id}`,
          method: 'POST',
          body: url.data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['WorkList'],
      }),

      getPaymentInfo: builder.query<APIResponse<any>, void>({
        query: () => ({
          method: 'GET',
          url: `${researchClinicSetupUrl.GET_PAYMENT_INFO}`,
        }),

        providesTags: ['paymentPackage'],
      }),


      createInquiryTypeEntry: builder.mutation<
        APIResponse<any>,
        any
      >({
        query: (data: any) => ({
          url: `${researchClinicSetupUrl.CREATE_INQUIRY_TYPE_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['researchClinicSetup'],
      }),

      createInquiryEntryByTrainee: builder.mutation<
        APIResponse<any>,
        any
      >({
        query: (data: any) => ({
          url: `${researchClinicSetupUrl.CREATE_INQUIRY_ENTRY_BY_TRAINEE}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['submittedQuery'],
      }),

      addPaymentPackage: builder.mutation<
        APIResponse<any>,
        any
      >({
        query: (data: any) => ({
          url: `${researchClinicSetupUrl.ADD_PAYMENT_PACKAGE}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['paymentPackage'],
      }),

      createExpectedFinalExamSession: builder.mutation<
        APIResponse<any>,
        any
      >({
        query: (data: any) => ({
          url: `${researchClinicSetupUrl.CREATE_EXPECTED_FINAL_EXAM_SESSION}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['finalExam'],
      }),

      updateInquiryTypeEntry: builder.mutation<
        APIResponse<InquiryList>,
        any
      >({
        query: (url) => ({
          url: `${researchClinicSetupUrl.UPDATE_INQUIRY_TYPE_ENTRY_IN_RESEARCH_CLINIC_SETUP}/${url.id}`,
          method: 'POST',
          body: url.data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['researchClinicSetup'],
      }),

      deleteInquiryType: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${researchClinicSetupUrl.DELETE_INQUIRY_TYPE_IN_RESEARCH_CLINIC_SETUP}/${id}`,
        }),
        invalidatesTags: ['researchClinicSetup'],
      }),

      deleteSubmittedQuery: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${researchClinicSetupUrl.DELETE_SUBMITTED_QUERY}/${id}`,
        }),
        invalidatesTags: ['submittedQuery'],
      }),

      // Research Grant Form

      createResearchGrantFormEntry: builder.mutation<APIResponse<any>, any>({
        query: (data: any) => ({
          url: `${researchClinicSetupUrl.CREATE_RESEARCH_GRANT_FORM_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['ResearchGrantForm'],
      }),

      getBankInformation: builder.query<APIResponse<any>, void>({
        query: () => ({
          method: 'GET',
          url: `${researchClinicSetupUrl.GET_BANK_INFORMATION}`,
        }),

        providesTags: ['ResearchGrantForm'],
      }),

      getResearchGrantListForRtmd: builder.query<
        APIResponseWithPagination<any>,
        string
      >({
        query: (url) => ({
          method: 'GET',
          url: `${researchClinicSetupUrl.GET_RESEARCH_GRANT_LIST_FOR_RTMD}?${url}`,
        }),

        providesTags: ['ResearchGrantForm'],
      }),

      createMoneyReciept: builder.mutation<APIResponse<any>, any>({
        query: (data: any) => ({
          url: `${researchClinicSetupUrl.CREATE_MONEY_RECEIPT}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['MoneyReceipt'],
      }),

      getMoneyReciptList: builder.query<APIResponse<any>, void>({
        query: () => ({
          method: 'GET',
          url: `${researchClinicSetupUrl.GET_MONEY_RECEIPT_LIST}`,
        }),

        providesTags: ['MoneyReceipt'],
      }),

    }),
  });

export const {
  useGetInquiryListQuery,
  useGetResearchClinicWorkListQuery,
  useGetWorkListForConsultantQuery,
  useCreateSolutionEntryMutation,
  useCreateExpectedFinalExamSessionMutation,
  useAssignConsultantMutation,
  useGetSubmittedQueryListQuery,
  useGetInquiryListForTraineeQuery,
  useGetPaymentInfoQuery,
  useCreateInquiryTypeEntryMutation,
  useAddPaymentPackageMutation,
  useCreateInquiryEntryByTraineeMutation,
  useUpdateInquiryTypeEntryMutation,
  useDeleteInquiryTypeMutation,
  useDeleteSubmittedQueryMutation,
  useCreateResearchGrantFormEntryMutation,
  useGetBankInformationQuery,
  useGetResearchGrantListForRtmdQuery,
  useGetMoneyReciptListQuery,
  useCreateMoneyRecieptMutation,
  useGetResolveStatusUpdateMutation,
} = researchClinicSetupApi;
