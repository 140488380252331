import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { CommentSectionModal } from "../CommentSectionForProtocol/CommentSectionModal";
import RichTextEditor from "./RichTextEditor";
import * as url from 'src/helpers/url_helper';

const StudyDesign = (props: any) => {

    const { setObjectivesRichtexts, objectivesRichtexts, existingProtocol, formikState, commentsDataList, initialContentEditor, setInitialContentEditor, formikField, renderKey } = props;

    const [messages, setMessage] = useState<any>([]);
    const [showCommentSectionModal, setShowCommentSectionModal] = useState<boolean>(false);

    const getCommentsData = (id: any, nameOfTheField: any) => {
        commentsDataList(`${url.THESIS_DISSERTATION_PROTOCOL_COMMENT_LIST}/${id}/${nameOfTheField}`).then(function (result: any,) {
            // here you can use the result of promiseB
            if (result?.data?.data) {
                setMessage(result?.data?.data);
            }
        });

        // setMessage(commentsData?.data || []);
    };

    useEffect(() => {
        getCommentsData(existingProtocol?.data?.id, 'study_design');
    }, []);

    return (
        <React.Fragment>

            <Row>

                <Col lg={12}>
                    <Row className="justify-content-end">
                        <Col sm="auto" className='btn noti-icon position-relative'>
                            <span className="badge bg-danger rounded-pill">{messages?.length ?? 0}</span>
                            <Button
                                type="button"
                                className="btn btn-primary btn-sm mb-3"
                                onClick={(e) => {
                                    setShowCommentSectionModal(!showCommentSectionModal);
                                }}
                            >
                                <i className="fas fa-2x fa-comments"></i>
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col lg={12}>
                    <RichTextEditor
                        setObjectivesRichtexts={setObjectivesRichtexts}
                        objectivesRichtexts={objectivesRichtexts}
                        formikState={formikState}
                        initialContentEditor={initialContentEditor}
                        setInitialContentEditor={setInitialContentEditor}
                        formikField={formikField}
                        key={renderKey}
                    />
                </Col>

                {showCommentSectionModal ?
                    <CommentSectionModal
                        fieldName={'study_design'}
                        fieldLabel={'Study Design'}
                        existingProtocol={existingProtocol}
                        showModal={showCommentSectionModal}
                        setShowModal={setShowCommentSectionModal}
                        setMessageCount={setMessage}
                    />
                    :
                    null
                }

            </Row>

        </React.Fragment>

    );

};

export default StudyDesign;