import AddTrainingWorkshop from 'src/pages/TrainingWorkshop/AddTrainingWorkshop';
import EditTrainingWorkshop from 'src/pages/TrainingWorkshop/EditTrainingWorkshop';
import TrainingNoticeList from 'src/pages/TrainingWorkshop/Notice';
import TrainingList from 'src/pages/TrainingWorkshop/TrainingList';
import ApplyTrainingWorkshop from 'src/pages/TrainingWorkshop/TrainingWorkshopApply';
import TrainingWorkshopList from 'src/pages/TrainingWorkshop/TrainingWorkshopList';

export const trainingWorkshopRoutes = [
  { path: '/training/create', component: TrainingWorkshopList },
  { path: '/training/notice', component: TrainingNoticeList },
  { path: '/training/list', component: TrainingList },
  { path: '/training/edit', component: EditTrainingWorkshop },
  { path: '/training/apply', component: ApplyTrainingWorkshop },
];
