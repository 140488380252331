import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { setFromErrors } from 'src/helpers';
import {
  useGetThesisDissertationTitleListQuery,
} from 'src/rtk/features/fileUploadForThesis/thesisDissertationFileEntryApi';
import {
  useCreateResearchGrantFormEntryMutation,
  useGetBankInformationQuery,
} from 'src/rtk/features/researchClinicSetup/researchClinicSetupApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';
import EditResearchGrantForm from './EditResearchGrantForm';
import { FormATraineePDF } from './FormATraineePDF';

const initialValues = {
  researcher_name_in_bangla: '',
  bank_name: '',
  branch_name: '',
  routing_no: '',
  account_no: '',
  submission_status: '',
};

const ResearchGrantForm = () => {
  const {
    data: titleInfo,
    isSuccess: isTitleSuccess,
    isLoading: isTitleLoading,
  } = useGetThesisDissertationTitleListQuery();

  const { data: bankInfo, isSuccess: isBankInfoSuccess, isLoading: isBankInfoLoading } = useGetBankInformationQuery();

  const [createResearchGrantFormEntry, { isSuccess, isError, error, isLoading: isCreateLoading }] = useCreateResearchGrantFormEntryMutation();
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [btnClicked, setBtnClicked] = useState<string>('');
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [editInformation, setEditInformation] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const [formAId, setFormAId] = useState('');

  const err: CustomError = error as CustomError;

  const {
    handleSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    resetForm,
    setFieldValue,
    setErrors,
  } = useFormik({
    initialValues,

    validationSchema: Yup.object({
      researcher_name_in_bangla: Yup.string()
        .matches(/^[\u0980-\u09FF !@#$%^&*(),.?":{}|<>]+$/, 'Invalid Bangla name')
        .min(2, 'Name is too short')
        .max(100, 'Name is too long')
        .required('Name is required'),
      bank_name: Yup.string().required('Bank Name is Required'),
      branch_name: Yup.string().required('Branch Name is Required'),
      routing_no: Yup.string().required('Routing No is Required'),
      account_no: Yup.string().required('Account No is Required'),
    }),

    onSubmit: (values: any) => {
      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        if (key === 'submission_status') {
          if (btnClicked === 'submit') {
            values[key] = 'Submit';
          } else if (btnClicked === 'save_as_draft') {
            values[key] = 'Draft';
          }
        }
        formData.append(key, values[key]);
      });

      createResearchGrantFormEntry(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Research Grant Entry Successfully!');
      setShowAddModal(false);
    }
    if (isError) {
      toast.error(err?.message || 'Something Went Wrong!');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  const modalShowHandler = (modalState: boolean, info: any) => {
    setModalShow(modalState);
    setEditInformation(info);
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        {isCreateLoading || isBankInfoLoading || isTitleLoading || submitting ? (
          <div className="overlay">
            <img src={loader} alt="Loading" height={100} width={300} />
          </div>
        ) : null}
        <Container fluid>
          <div className='page-breadcrumb-wrapper'>
            <BetterBreadcrumb
              breadcrumbItem={[{ link: '/', name: 'Dashboard' }]}
              title={'Research Grant Entry'}
            />
            <div>
              <Button onClick={() => setShowAddModal(true)} color={'primary'}>Research Grant Entry</Button>
            </div>
          </div>

          <Card>
            <CardBody>
              <div className='table-responsive'>
                <table className='table table-sm'>
                  <thead className='table-secondary'>
                    <tr>
                      <th>Name(বাংলায়)</th>
                      <th>Bank Name</th>
                      <th>Branch Name</th>
                      <th>Account No</th>
                      <th>Routing No</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{bankInfo?.data?.researcher_name_in_bangla}</td>
                      <td>{bankInfo?.data?.bank_info?.bank_name}</td>
                      <td>{bankInfo?.data?.bank_info?.branch_name}</td>
                      <td>{bankInfo?.data?.bank_info?.account_no}</td>
                      <td>{bankInfo?.data?.bank_info?.routing_no}</td>
                      <td>
                        {bankInfo?.data &&
                          <div className='d-flex gap-1'>
                            <Button
                              type='button'
                              className='btn btn-success btn-sm'
                              onClick={(e) => {
                                modalShowHandler(true, bankInfo?.data);
                              }}
                            >
                              {'Edit'}
                            </Button>
                            <Button
                              type='button'
                              color='primary'
                              className='btn btn-sm'

                              onClick={() => {
                                setFormAId(bankInfo?.data?.id);
                                setSubmitting(true);
                              }}
                            >
                              {'Form A'}
                            </Button>
                          </div>
                        }
                      </td>
                    </tr>
                    {modalShow ? (
                      <EditResearchGrantForm
                        isOpen={modalShow}
                        toggle={() => setModalShow(!modalShow)}
                        info={editInformation}
                      />
                    ) : null}
                    {formAId === bankInfo?.data?.id ? (
                      <FormATraineePDF data={bankInfo?.data} setFormAId={setFormAId} setSubmitting={setSubmitting}/>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
      <Modal size="lg" isOpen={showAddModal} show={showAddModal}>
        <ModalHeader toggle={() => setShowAddModal(!showAddModal)}>Research Grant Entry</ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            onReset={(e) => {
              resetForm();
            }}
          >
            <Row>
              <>
                <div className='row'>
                  <div className='col-md-12'>
                    <table className='table table-bordered table-sm table-striped'>
                      <tbody>
                        <tr>
                          <td className='col-md-6'>
                            <strong>Name of the Researcher: </strong>
                          </td>
                          <td className='col-md-6'>
                            {
                              titleInfo?.data?.registration?.personal_info?.full_name
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Title of Thesis/Dissertation: </strong>
                          </td>
                          <td>
                            {
                              titleInfo?.data?.title_of_thesis
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>BMDC Reg. No.: </strong>
                          </td>
                          <td>
                            {
                              titleInfo?.data?.registration?.bmdc_registration_no
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Subject: </strong>
                          </td>
                          <td>
                            {
                              titleInfo?.data?.registration?.subject?.subject_name
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Mobile: </strong>
                          </td>
                          <td>
                            {
                              titleInfo?.data?.registration?.personal_info?.mobile
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Email: </strong>
                          </td>
                          <td>
                            {
                              titleInfo?.data?.registration?.personal_info?.email
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </>
              <Col sm={6}>
                <FormGroup>
                  <Label for='researcher_name_in_bangla' className='form-label required-field'>Name of the
                    Researcher (বাংলায়)</Label>
                  <InputField
                    type='text'
                    placeholder='Name of the Researcher'
                    {...getFieldProps('researcher_name_in_bangla')}
                    invalid={{ errors, touched }}
                  />
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for='bank_name' className='form-label required-field'>Bank Name</Label>
                  <InputField
                    type='text'
                    placeholder='Bank Name'
                    {...getFieldProps('bank_name')}
                    invalid={{ errors, touched }}
                  />
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for='branch_name' className='form-label required-field'>Branch Name</Label>
                  <InputField
                    type='text'
                    placeholder='Branch Name'
                    {...getFieldProps('branch_name')}
                    invalid={{ errors, touched }}
                  />
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for='routing_no' className='form-label required-field'>Routing No.</Label>
                  <InputField
                    type='text'
                    placeholder='Routing No.'
                    {...getFieldProps('routing_no')}
                    invalid={{ errors, touched }}
                  />
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for='account_no' className='form-label required-field'>Account Number</Label>
                  <InputField
                    type='text'
                    placeholder='Acount Number'
                    {...getFieldProps('account_no')}
                    invalid={{ errors, touched }}
                  />
                </FormGroup>
              </Col>
              <FormGroup className='mb-3 text-center'>
                <Button color='secondary' className={'me-2'} onClick={() => setShowAddModal(!showAddModal)}>
                  Close
                </Button>
                <Button
                  type='reset'
                  className='btn btn-danger me-2'
                >
                  Reset
                </Button>
                <button
                  type='submit'
                  className='btn btn-info me-2'
                  // ref={draftButtonRef}
                  onClick={(e) => {
                    setBtnClicked('save_as_draft');
                  }}
                >
                  Save as Draft
                </button>
                <button
                  type='submit'
                  // ref={buttonRef}
                  className='btn btn-success'
                  onClick={(e) => {
                    setBtnClicked('submit');
                  }}
                >
                  Submit
                </button>

              </FormGroup>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ResearchGrantForm;
