import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import { useDeleteNoticeTemplateDetailsMutation } from 'src/rtk/features/setup/noticeTemplateDetails/setupNoticeTemplateDetailsApi';
import { useGetNoticeTemplateListsQuery } from 'src/rtk/features/setup/noticeTemplateList/setupNoticeTemplateListApi';

interface Props {
  noticeTemplateDetails: NoticeTemplateDetails;
  index: number;
}

const NoticeTemplateDetailsTableRow = ({ noticeTemplateDetails, index }: Props) => {
  const history = useHistory();
  const [deleteNoticeTemplateDetails, { isSuccess, isLoading, isError }] =
    useDeleteNoticeTemplateDetailsMutation();
    const { data } = useGetNoticeTemplateListsQuery();
    const noticeTemplateLists = data?.data || [];

  const deleteHandler = (id: string) => {
    const confirm = window.confirm('Are you sure you want to delete?');
    if (confirm) deleteNoticeTemplateDetails(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Notice template details deleted successfully');
    }
    if (isError) {
      toast.error('Error deleting notice template details');
    }
  }, [isSuccess, isError]);

  return (
    <tr key={noticeTemplateDetails.id}>
      <th scope="row">{index + 1}</th>          
      <td>
        {
          noticeTemplateLists?.find((n) => n.id === noticeTemplateDetails.notice_list_id)
            ?.notice_subject
        }
      </td>
      <td>{noticeTemplateDetails.details}</td>          
      <td>{noticeTemplateDetails.status ? 'Active' : 'Inactive'}</td>       
      <td>
        <div className="d-flex gap-2">
          <Button
            size="sm"
            text="Edit"
            color="warning"
            onClick={() =>
              history.push(`/setup/notice-template-details/update/${noticeTemplateDetails.id}`)
            }
          />

          <Button
            size="sm"
            text="Delete"
            color="danger"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => deleteHandler(noticeTemplateDetails.id!)}
          />
        </div>
      </td>
    </tr>
  );
};

export default NoticeTemplateDetailsTableRow;
