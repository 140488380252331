import { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Container, Form, FormGroup, Label, Table } from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetFacultyInfosQuery } from 'src/rtk/features/setup/facultyInfo/facultyInfoApi';
import { useGetSubjectInfosListQuery, useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import { AddSubjectInfo } from './AddSubjectInfo';
import SubjectInfoTableRow from './SubjectInfoTableRow';
import { useFormik } from 'formik';

export const SubjectInfos = () => {
  const [search, setSearch] = useState('');

  const { data, error, isLoading, isError } = useGetSubjectInfosListQuery(search);

  const { data: faculty } = useGetFacultyInfosQuery();
  const facultyInfos: SelectOption[] = (faculty?.data || []).map((item) => ({
    label: item.faculty_name!,
    value: item.id!,
  }));

  const unitOptions: SelectOption[] = [
    { label: '3 Years', value: 3 },
    { label: '4 Years', value: 4 },
    { label: '5 Years', value: 5 },
  ];

  const err: CustomError = error as CustomError;
  const subjectInfos = data?.data || [];
  const [showAddForm, setShowAddForm] = useState<boolean>(false);

  let content = null;

  if (isLoading) {
    content = <div className="mt-3">Loading...</div>;
  }
  if (!isLoading && isError && error) {
    content = <div className="mt-3">{err.message}</div>;
  }
  if (!isLoading && !isError && subjectInfos?.length > 0) {
    content = subjectInfos.map((subjectInfo: any, index: any) => (
      <SubjectInfoTableRow
        subjectInfo={subjectInfo}
        index={index}
        key={subjectInfo.id}
      />
    ));
  } else {
    content = (
      <tr>
        <td colSpan={11}>
          <div className="text-center text-danger h6">No data available!</div>
        </td>
      </tr>
    );
  }

  const {
    handleSubmit,
    getFieldProps,
    values,
    touched,
    setFieldValue,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues: {
      search: '',
      setup_faculty_info_id: '',
      category: '',
      duration: '',
      status: '',
    },

    onSubmit: (values) => {
      let s: string = '';

      if (values.search !== '') {
        s = s + `&search=${values.search}`;
      }

      if (values.setup_faculty_info_id !== '') {
        s = s + `&setup_faculty_info_id=${values.setup_faculty_info_id}`;
      }

      if (values.category !== '') {
        s = s +  `&category=${values.category}`;
      }

      if (values.duration !== '') {
        s = s +  `&duration=${values.duration}`;
      }

      if (values.status !== '') {
        s = s +  `&status=${values.status === 'Active' ? true : false}`;
      }

      setSearch(s);
    },
  });

  
  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Subject List'}
          breadcrumbItem={[{ link: '/setup/subjectInfo/', name: 'Subject' }]}
        />
        <Card>
          <CardHeader className="d-flex justify-content-end">
            <Button
              className="btn btn-sm"
              color='primary'
              onClick={() => {
                setShowAddForm(true)
              }}
            >
              Add Subject
            </Button>
          </CardHeader>
          <CardBody>
            <Form
              className="custom-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              onReset={(e) => {
                e.preventDefault();
                resetForm();
                handleSubmit();
              }}
            >
              <div className='filter-wrapper'>
                <FormGroup>
                  <Label for="faculty">Faculty</Label>
                  <InputField
                    type="select"
                    options={facultyInfos}
                    placeholder="Faculty"
                    {...getFieldProps('setup_faculty_info_id')}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="category">Category</Label>
                  <InputField
                    type="select"
                    options={[
                      {label: 'General ', value: 'General '},
                      {label: 'Specialized  ', value: 'Specialized  '}
                    ]}
                    placeholder="Category"
                    {...getFieldProps('category')}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="duration">Duration</Label>
                  <InputField
                    type="select"
                    options={unitOptions}
                    placeholder="Fellowship Duration"
                    {...getFieldProps('duration')}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="status">Status</Label>
                  <InputField
                    type="select"
                    options={[
                      { label: 'Active', value: 'Active' },
                      { label: 'Inactive', value: 'Inactive' }
                    ]}
                    placeholder="Status"
                    {...getFieldProps('status')}
                  />
                </FormGroup>
                <div className="btn-column mb-3">
                  <Button color="warning" type="reset" outline >Reset</Button>
                  <Button color="primary" type="submit" >Search</Button>
                </div>
              </div>
            </Form>
            <div className="table-responsive text-nowrap text-center x-overflow-scroll">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Subject Code</th>
                    <th>Subject Name</th>
                    <th>Category</th>
                    <th>Description</th>
                    <th>Fellowship Duration</th>
                    <th>Faculty Info</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
          </CardBody>
          {showAddForm &&
            <AddSubjectInfo isOpen={showAddForm} toggle={() => setShowAddForm(!showAddForm)} />
          }
        </Card>
      </Container>
    </div>
  );
};
