import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import PaymentApprovalViewModal from './PaymentApprovalViewModal';
import moment from 'moment';

interface Props {
  paymentInfo: PaymentList;
  index: number;
  page: any;
  paginate_per_page: number;
}

const PaymentListTableRow = ({ paymentInfo, index, page, paginate_per_page }: Props) => {
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);

  const obj = JSON.parse(paymentInfo?.extra_info!);

  return obj ? (
    <tr key={paymentInfo.id}>
      <th scope="row">{((page - 1) * paginate_per_page + index) + 1}</th>
      <td>{obj.bmdc_registration_no}</td>
      <td>{paymentInfo?.details?.payment_for}</td>
      <td>{obj.registration_name}</td>
      <td>{obj.course_name}</td>
      <td>{obj.subject_name}</td>
      <td>{moment(paymentInfo.payment_date).format("DD-MMM-YYYY")}</td>
      {/* <td>{obj.supervisor_name ? obj.supervisor_name : 'N/A'}</td> */}
      <td>{paymentInfo.total_amount}</td>
      <td>
        {['Approved','Approve'].includes(paymentInfo?.payment_verification_status!) ? (
          <p className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">Approved</p>
        ) : (
          <p className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">Pending</p>
        )}
      </td>
      <td>
        <Button
          size="sm"
          text="View"
          color="primary"
          onClick={() => setModalShow(true)}
        />

        {modalShow ? (
          <PaymentApprovalViewModal
            isOpen={modalShow}
            toggle={() => setModalShow(!modalShow)}
            regNo={paymentInfo.registration_no!}
            id={paymentInfo.id!}
          />
        ) : null}
      </td>
    </tr>
  ) : null;
};

export default PaymentListTableRow;
