import * as React from 'react';
import { Link } from 'react-router-dom';
import { Container, Card, CardHeader, CardBody, Table } from 'reactstrap';
import { useGetDocumentFileUploadsQuery } from 'src/rtk/features/setup/documentFileUpload/documentFileUploadApi';
import DocumentFileUploadTableRow from './DocumentFileUploadTableRow';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';

export const DocumentFileUploads = () => {
  const { data, error, isLoading, isError } = useGetDocumentFileUploadsQuery();

  const err: CustomError = error as CustomError;
  const documentFileUploads = data?.data || [];

  let content = null;
  if (isLoading) {
    content = <div className="mt-3">Loading...</div>;
  }
  if (!isLoading && isError && error) {
    content = <div className="mt-3">{err.message}</div>;
  }
  if (!isLoading && !isError && documentFileUploads?.length > 0) {
    content = documentFileUploads.map((documentFileUpload, index) => (
      <DocumentFileUploadTableRow
        documentFileUpload={documentFileUpload}
        index={index}
        key={documentFileUpload.id}
      />
    ));
  } else {
    content = (
      <tr>
        <td colSpan={11}>
          <div className="text-center text-danger h6">No data available!</div>
        </td>
      </tr>
    );
  }
  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb title="Document File Upload" />
        <Card>
          <CardHeader className="d-flex justify-content-end">
            <Link
              className="btn btn-sm btn-primary"
              to="/setup/document-file-upload/add"
            >
              Add Document File
            </Link>
          </CardHeader>
          <CardBody>
            <div>
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Sl. </th>
                    <th>Document Name</th>
                    <th>Document For</th>
                    <th>Document Type</th>
                    <th>File Type</th>
                    <th>File Size</th>
                    <th>File Size Unit</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
