import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { putUrlString } from 'src/helpers';
import * as url from 'src/helpers/url_helper';
import { useGetDataQuery } from 'src/rtk/features/common/crudApi';
import {
  useGetExamEligibilityCriteriaQuery,
  useGetExamEligibilityCriteriasQuery,
  useUpdateExamEligibilityCriteriaMutation,
} from 'src/rtk/features/setup/examEligibilityCriteria/examEligibilityCriteriaApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import * as Yup from 'yup';

const initialValues = {
  exam_training_id: '',
  subject_id: '',
  training_eligibility_criteria_id: '',
  eligibility_criteria: '',
  status: true,
};

export const EditExamEligibilityCriteria = () => {
  const { id } = useParams<{ id: string }>();
  const { data: examEligibilityCriteria, isError: isDeptFetchError } =
    useGetExamEligibilityCriteriaQuery(id);
  const [updateExamEligibilityCriteria, { isSuccess, isError, error }] =
    useUpdateExamEligibilityCriteriaMutation();
  const err: CustomError = error as CustomError;

  const generateQueryUrl = () => {
    return `${url.SETUP_EXAM_TRAINING_INFO_LIST}`;
  };

  const { data: examInfoData } = useGetDataQuery(generateQueryUrl());

  const examInfos = examInfoData?.data?.data || [];

  const examInfoOptions: SelectOption[] =
    examInfos?.map((examInfo: any) => ({
      value: examInfo.id!,
      label: examInfo.name,
    })) || [];

  const { data: subjects } = useGetSubjectInfosQuery();

  const subjectOptions: SelectOption[] =
    subjects?.data.map((subject) => ({
      value: subject.id!,
      label: subject.subject_name,
    })) || [];

  const { data: examEligibilityCriterias } =
    useGetExamEligibilityCriteriasQuery();
  let sl = 0;
  const examEligibilityCriteriaOptions: SelectOption[] =
    examEligibilityCriterias?.data.map((examEligibilityCriteria) => ({
      value: examEligibilityCriteria.id!,
      label: examEligibilityCriteria.eligibility_criteria!,
    })) || [];

  const history = useHistory();
  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    handleChange,
    values,
    setValues,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      exam_training_id: Yup.string().required(
        'Please Select Exam Training Info',
      ),
      subject_id: Yup.string().required('Please Select a Subject'),
    }),

    onSubmit: (values) => {
      const url = putUrlString(id, values);
      updateExamEligibilityCriteria(url);
    },
  });

  useEffect(() => {
    if (examEligibilityCriteria && !isDeptFetchError) {
      const data: ExamEligibilityCriteria = (examEligibilityCriteria as any)
        .data;
      setValues({
        exam_training_id: data.exam_training_id || '',
        subject_id: data.subject_id || '',
        training_eligibility_criteria_id:
          data.training_eligibility_criteria_id || '',
        eligibility_criteria: data.eligibility_criteria || '',
        status: data.status || false,
      });
    }
  }, [examEligibilityCriteria, isDeptFetchError]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Exam Eligibility Criteria updated successfully');
      history.push('/setup/xm-eligibility-criteria');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      const errors: any = {};

      if (err?.data) {
        Object.keys(err?.data).forEach((key) => {
          errors[key] = err?.data[key][0];
        });
      }
      setErrors(errors);
    }
  }, [isSuccess, isError]);

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title="Edit Exam Eligibility Criteria"
          breadcrumbItem={[
            {
              link: '/setup/xm-eligibility-criteria/',
              name: 'Exam Eligibility Criteria',
            },
          ]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-8 col-sm-12 ">
            <div className="card mt-3">
              <h5 className="card-header">
                <i className="fa fa-edit me-2" />
                Edit Exam Eligibility Criteria
              </h5>
              <div className="card-body">
                <Form
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <Row>
                    <FormGroup className="mb-3">
                      <Label for="exam_training_id">Exam Training Id</Label>
                      <InputField
                        type="select"
                        name="exam_training_id"
                        options={[...examInfoOptions]}
                        invalid={{ errors, touched }}
                        value={values.exam_training_id}
                        onChange={handleChange}
                      />
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <Label for="subject_id">Subject</Label>
                      <InputField
                        type="select"
                        name="subject_id"
                        options={subjectOptions}
                        invalid={{ errors, touched }}
                        value={values.subject_id}
                        onChange={handleChange}
                      />
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <Label for="training_eligibility_criteria_id">
                        Training Eligibility Criteria Id
                      </Label>
                      <InputField
                        type="select"
                        name="training_eligibility_criteria_id"
                        options={examEligibilityCriteriaOptions}
                        invalid={{ errors, touched }}
                        value={values.training_eligibility_criteria_id}
                        onChange={handleChange}
                      />
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <Label for="eligibility_criteria">
                        Eligibility Criteria
                      </Label>
                      <InputField
                        type="text"
                        placeholder="Eligibility Criteria"
                        invalid={{ errors, touched }}
                        {...getFieldProps('eligibility_criteria')}
                      />
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <h6>Active Status</h6>
                      <FormGroup check className="mb-3">
                        <Input
                          type="checkbox"
                          role="check"
                          checked={values.status}
                          {...getFieldProps('status')}
                        />
                        <Label check>Active</Label>
                      </FormGroup>
                    </FormGroup>

                    <div className="text-center">
                      <Button className="w-25" type="submit" text="Submit" />
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
