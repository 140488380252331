import 'flatpickr/dist/themes/material_blue.css';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import toast from 'react-hot-toast';
import { Link, useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { Col, Container, Form, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { getSelectFormatedData, setFromErrors } from 'src/helpers';
import * as url from 'src/helpers/url_helper';
import {
  useCreateByPostMutation,
  useGetDataByMutation,
  useGetDataQuery,
} from 'src/rtk/features/common/crudApi';
import * as Yup from 'yup';

const initialValues: any = {
  trn_workshop_training_master_id: '',
  batch_no: '',
  registration_ids: [],
  trainers: {},
  // trainer_id: [],
  description: '',
};

export const RTMDBatchForm = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [trainingDetails, setTrainingDetails] = useState<any>({});
  const [applicantList, setApplicantList] = useState<[]>([]);
  const [applicantAllAssignChecked, setApplicantAllAssignChecked] = useState<boolean>(false);
  const [searchByName, setSearchByName] = useState('');
  const [maxNumberOfApplicant, setMaxNumberOfApplicant] = useState<number>(0);
  const [customActiveTab, setcustomActiveTab] = useState(0);

  const [
    getDataBy,
    {
      data: getApplicantList,
      isSuccess: isGetApplicantListSuccess,
      isLoading: isGetApplicantListLoading,
    },
  ] = useGetDataByMutation();

  useEffect(() => {
    if (
      getApplicantList &&
      !isGetApplicantListLoading &&
      isGetApplicantListSuccess
    ) {
      setApplicantList(getApplicantList?.data || []);
    }
  }, [getApplicantList, isGetApplicantListLoading, isGetApplicantListSuccess]);

  const searchFromApplicant = (searchByName = '', searchKey = '') => {
    if (searchByName.trim() && searchKey.trim()) {
      let prevDataList = getApplicantList?.data || [];
      let newSetupSubjectList = prevDataList.filter((item: any) => {
        let subjectData = getDetailsData(searchKey, item) || '';
        if (
          subjectData.toLowerCase()
            .indexOf(searchByName.trim().toLowerCase()) !== -1
        ) {
          return item;
        } else {
          return false;
        }
      });
      setApplicantList(newSetupSubjectList);
    } else {
      setApplicantList(getApplicantList?.data || []);
    }
  };

  const searchFromApplicant2 = (searchByName = '', searchKey: any) => {
    if (searchByName.trim()) {
      let prevDataList = getApplicantList?.data || [];
      let newSetupSubjectList = prevDataList.filter((item: any) => {
        let subjectData = searchKey(item) || '';
        if (
          subjectData.toLowerCase()
            .indexOf(searchByName.trim().toLowerCase()) !== -1
        ) {
          return item;
        } else {
          return false;
        }
      });
      setApplicantList(newSetupSubjectList);
    } else {
      setApplicantList(getApplicantList?.data || []);
    }
  };


  const {
    data: setupWorkshopTrainingListData,
    // isLoading: mainTrainingListLoading,
    // isSuccess: mainTrainingListLoadingSuccess,
    // error: mainTrainingListLoadingError,
  } = useGetDataQuery(url.RTMD_WORKSHOP_TRAINING_LIST);

  const mainTrainingListDetails: any =
    setupWorkshopTrainingListData?.data || [];

  const mainTrainingList: any = setupWorkshopTrainingListData?.data
    ? getSelectFormatedData(
      setupWorkshopTrainingListData?.data,
      'id',
      'training_name',
    )
    : [];

  const {
    data: setupSuperVisorListData,
    isLoading: setupSuperVisorListLoading,
    // isSuccess: mainTrainingListLoadingSuccess,
    // error: mainTrainingListLoadingError,
  } = useGetDataQuery(url.RTMD_SUPERVISOR_LIST);

  const supterVisorList: any = setupSuperVisorListData?.data
    ? getSelectFormatedData(setupSuperVisorListData?.data, 'id', 'name')
    : [];

  // const [
  //   updateByPatch,
  //   { isSuccess: updateSucess, isError: updateError, error: updateErrorData },
  // ] = useUpdateByPatchMutation();

  const [createByPost, { isSuccess, isLoading, isError, error }] =
    useCreateByPostMutation();
  const err: CustomError = error as CustomError;

  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    resetForm,
    setErrors,
    handleChange,
    values,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues,

    validationSchema: Yup.object({
      // trn_workshop_training_master_id: Yup.string().required(
      //   'Training Title is required',
      // ),
      batch_no: Yup.string().required('Batch no is required'),
      // period_from: Yup.string().required('Period is required'),
      // period_to: Yup.string().required('Period is required'),
      // trainer_id: Yup.string().required('Trainer is required'),
      // registration_ids: Yup.string().required('Applicant is required'),
    }),

    onSubmit: (values) => {
      let postData = {
        queryUrl: url.RTMD_BATCH_CREATE,
        formData: values,
      };

      if ((+trainingDetails?.no_of_participants) && values.registration_ids?.length !== (+trainingDetails?.no_of_participants)) {
        toast.error("Number of participants for this batch should be " + trainingDetails?.no_of_participants);
      } else {
        createByPost(postData);
      }
    },
  });

  const getNumberOfDays = (durationText: string = '') => {
    // durationText = durationText ? durationText : '3 day';
    if (durationText) {
      let dayDurationUnit = 1;
      let day = parseInt(durationText);
      let duration = durationText.split(' ')[1];

      if (duration.toLowerCase() === 'month') {
        dayDurationUnit = 30;
      } else if (duration.toLowerCase() === 'week') {
        dayDurationUnit = 7;
      } else if (duration.toLowerCase() === 'year') {
        dayDurationUnit = 365;
      }
      let numberOfDays = day * dayDurationUnit;
      let dayArray = Array.from(Array(numberOfDays).keys());
      return dayArray;
    }
  };

  const isTrainerSelected = (trainer: any) => {
    let isFound = false;
    if (trainer) {
      isFound = supterVisorList.find((visit: any) => visit.value === trainer)
    }
    return isFound;
  }


  if (id) {
    const {
      data: trainingBatchData,
      isSuccess: trainingBatchDataSuccess,
      isError: isExamFetchError,
    } = useGetDataQuery(`${url.RTMD_WORKSHOP_TRAINING_BATCH_BY_ID}${id}`);

    useEffect(() => {
      if (trainingBatchData && !isExamFetchError) {
        const data: any = (trainingBatchData as any).data;

        console.log(data)

        let trainer_ids = data.trainer_id ? data.trainer_id.split(',') : [];

        let registration_ids: any = [];

        if (data.batch_user.length > 0) {
          data.batch_user.map((user: any) => {
            registration_ids.push(user.registration_id);
          });
        }

        let trainersABC: any = {};

        if (data.batch_trainers.length > 0) {
          data.batch_trainers.map((user: any, key: any) => {
            trainersABC[key] = { ...user };
            let trainer_info = JSON.parse(trainersABC[key]['trainer_info']);
            trainersABC[key]['trainer_info'] = trainer_info;
          });
        }

        let period_from = new Date(data.period_from);
        let period_to = new Date(data.period_to);

        setValues({
          id: id,
          trainers: trainersABC,
          batch_no: data.batch_no || '',
          trn_workshop_training_master_id:
            data?.trn_workshop_training_master_id || '',
          description: data.description || '',
          registration_ids: registration_ids,
        });

        if (data?.workshop_training.exam_training_id) {
          setTrainingDetails(data?.workshop_training);
          getDataBy(
            `${url.RTMD_APPLICATION_LIST_FOR_UPDATE}${data?.workshop_training.exam_training_id}`,
          );
        }
      }
    }, [trainingBatchData, trainingBatchDataSuccess, isExamFetchError]);
  }

  const mainTrainerSelect = (selectedValues: any) => {
    // values['trainer_id'] = [];
    // console.log(selectedValues)
    // selectedValues.map((item: any) => {
    //   values['trainer_id'].push(item['value']);
    // });

    setFieldValue('trainer_id', selectedValues['value'])

    // console.log(values['trainer_id']);
  };

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      let msg = 'Batch created successfully';
      if (id) {
        msg = 'Batch update successfully';
      }
      toast.success(msg);
      history.push('/rtmd/batch/');
    }
    if (isError) {
      if (err.data?.message) {
        toast.error(err.data?.message || 'Something went wrong');
      } else {
        toast.error(err?.message || 'Something went wrong');
      }
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    setFieldValue('trn_workshop_training_master_id', '');
  }, []);

  useEffect(() => {
    if (!values.trn_workshop_training_master_id) {
      setFieldValue('trainers', {});
    }
  }, [values.trn_workshop_training_master_id]);

  const getDetailsData = (key = '', dataObj: any = {}) => {
    let perseData: any = {};    

    if (key === 'fcps_part_one_passing_session' || key === 'fcps_part_one_passing_year') {
      if (dataObj['reg_info'] && typeof dataObj['reg_info'] === 'object') {
        perseData = dataObj.reg_info;
      }
    } else {
      if (dataObj['extra_info'] && typeof dataObj['extra_info'] === 'string') {
        perseData = JSON.parse(dataObj.extra_info);
      } else {
        perseData = dataObj;
      }
    }

    return perseData[key] ? (
      perseData[key]
    ) : perseData[key.toLowerCase()] ? (
      perseData[key]
    ) : (
      ''
    );
  };

  const applicantAllAssign = (isChecked: boolean) => {
    let selectedApplicants = [];
    if (isChecked) {
      selectedApplicants = applicantList.map((item: any) => item.registration_no);
    }
    setApplicantAllAssignChecked(isChecked)
    if (maxNumberOfApplicant) {
      selectedApplicants = selectedApplicants.slice(0, maxNumberOfApplicant)
    }
    setFieldValue('registration_ids', selectedApplicants);
  };

  const applicantAssign = (id: any, isChecked: boolean) => {
    let selectedApplicants = values['registration_ids'];
    var index = selectedApplicants.indexOf(id);
    if (isChecked && index === -1) {
      selectedApplicants.push(id);
    } else {
      if (index !== -1) {
        selectedApplicants.splice(index, 1);
        setApplicantAllAssignChecked(false)
      }
    }
    setFieldValue('registration_ids', selectedApplicants);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={
            id
              ? 'Training/Workshop Batch Update'
              : 'Training/Workshop Batch Create'
          }
          breadcrumbItem={[
            {
              link: '/dashboard',
              name: 'Dashboard',
            },
            {
              link: '/rtmd/batch/',
              name: 'Training/Workshop Batch',
            },
          ]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="card">
            <div className="d-flex card-header">
              <h3 className="card-title mb-0 flex-grow-1">
                <i className="fa fa-plus me-2" />
                Training/Workshop Batch {id ? 'Update' : 'Create'}
              </h3>
              <div className="flex-shrink-0 align-self-end">
                <Link
                  to="/rtmd/batch/"
                  className="btn btn-success waves-effect waves-light"
                >
                  <i className="fas fa-arrow-left me-2" /> Back
                </Link>
              </div>
            </div>

            <div className="card-body">
              <Form
                autoComplete="off"
                className="custom-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Row>
                  <div className="col-lg-12 col-sm-12 ">
                    <Row className="g-0">
                      <FormGroup className="mb-3 col-md-6 col-sm-12 px-1">
                        <Label className="required-field" for="session">
                          Training Title
                        </Label>
                        <Select
                          name="trn_workshop_training_master_id"
                          options={mainTrainingList}
                          value={mainTrainingList?.filter(function (
                            option: any,
                          ) {
                            return (
                              option.value ===
                              values['trn_workshop_training_master_id']
                            );
                          })}
                          feedbackInvalid={{ errors, touched }}
                          onChange={(data: any) => {
                            if (data.value) {
                              let selectedId = data.value;
                              let details = mainTrainingListDetails.find(
                                (item: any) => item.id === selectedId,
                              );

                              values['trn_workshop_training_master_id'] =
                                selectedId;

                              setTrainingDetails(details);
                              setApplicantList([]);

                              getDataBy(
                                `${url.RTMD_APPLICATION_LIST_FOR_CREATE}${details['exam_training_id']}`,
                              );
                            }
                          }}
                        />
                      </FormGroup>

                      <FormGroup className="mb-3 col-md-6 col-sm-12 px-1">
                        <Label className="required-field" for="batch_no">
                          Batch No
                        </Label>
                        <InputField
                          type="text"
                          placeholder="Batch No"
                          invalid={{ errors, touched }}
                          {...getFieldProps('batch_no')}
                        />
                      </FormGroup>

                      {/* <FormGroup className="mb-3 col-md-12 col-sm-12 px-1">
                        <Label for="description">Batch Details</Label>
                        <InputField
                          type="textarea"
                          placeholder="Batch Details"
                          invalid={{ errors, touched }}
                          {...getFieldProps('description')}
                        />
                      </FormGroup> */}


                      {/* <pre> {JSON.stringify(values, null, 2)}</pre> */}
                      {values["trn_workshop_training_master_id"] && (<>

                        <Nav tabs>
                          {getNumberOfDays(trainingDetails?.duration)?.map((day: any) => {

                            return (
                              <NavItem key={Math.random()}>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={customActiveTab === day ? "active" : ""}
                                  onClick={() => {
                                    setcustomActiveTab(day);
                                  }}
                                >
                                  Day {day + 1}
                                </NavLink>
                              </NavItem>
                            )
                          })}
                        </Nav>

                        <TabContent
                          activeTab={customActiveTab}
                          className="p-3  mb-3 "
                          style={{ border: '1px solid #ccc', borderTop: "0", borderRadius: "0 0 7px 7px" }}
                        >
                          {getNumberOfDays(trainingDetails?.duration)?.map((day: any) => {

                            return (<TabPane tabId={day} className='row' key={Math.random()}>
                              <Row>
                                <Col sm="12">
                                  <Row>
                                    <FormGroup className="mb-3 col-md-4 col-sm-12 px-1">
                                      <Label className="required-field">Day {day + 1}</Label>
                                      <Flatpickr
                                        className="form-control d-block"
                                        placeholder="YYYY-MM-DD"
                                        options={{
                                          mode: 'single',
                                          dateFormat: 'Y-m-d',
                                          minDate: new Date(),
                                          // minDate: '',
                                          defaultDate: values?.['trainers']?.[day]?.['training_date'] || '',
                                          disable: [new Date()]
                                        }}
                                        onChange={(data: any) => {
                                          let sDate = moment(data[0]).format(
                                            'YYYY-MM-DD',
                                          );

                                          // trainers
                                          let oldTrainers = values['trainers'];
                                          oldTrainers[day] = {
                                            ...oldTrainers[day],
                                            'training_date': sDate,
                                            'day': day
                                          }

                                          setFieldValue('trainers', oldTrainers)


                                          // if (data.length === 2) {
                                          //   values['period_from'] = moment(data[0]).format(
                                          //     'YYYY-MM-DD',
                                          //   );
                                          //   values['period_to'] = moment(data[1]).format(
                                          //     'YYYY-MM-DD',
                                          //   );
                                          // }
                                        }}
                                      />
                                    </FormGroup>

                                    <FormGroup className="mb-3 col-md-4 col-sm-12 px-1">
                                      <Label className="required-field">Start time for day {day + 1}</Label>
                                      <Input
                                        className="form-control d-block"
                                        type="time"
                                        value={values?.['trainers']?.[day]?.['start_time'] || ''}
                                        onChange={(e: any) => {
                                          let data = e.target.value;
                                          let start_time = data;
                                          let oldTrainers = values['trainers'];
                                          oldTrainers[day] = {
                                            ...oldTrainers[day],
                                            'start_time': start_time,
                                            'day': day
                                          }
                                          setFieldValue('trainers', oldTrainers)
                                        }}
                                      />
                                    </FormGroup>

                                    <FormGroup className="mb-3 col-md-4 col-sm-12 px-1">
                                      <Label className="required-field">End time for day {day + 1}</Label>

                                      <Input
                                        className="form-control d-block"
                                        type="time"
                                        value={values?.['trainers']?.[day]?.['end_time'] || ''}
                                        onChange={(e: any) => {
                                          let data = e.target.value;
                                          let end_time = data;
                                          let oldTrainers = values['trainers'];

                                          oldTrainers[day] = {
                                            ...oldTrainers[day],
                                            'end_time': end_time,
                                            'day': day
                                          }
                                          setFieldValue('trainers', oldTrainers)
                                        }}
                                      />
                                    </FormGroup>

                                    <FormGroup className="mb-3 col-md-4 col-sm-12 px-1">
                                      <Label className="required-field" for="session">
                                        Trainer for Day {day + 1}
                                      </Label>
                                      <Select
                                        // name="trainer_id"
                                        options={supterVisorList}
                                        feedbackInvalid={{ errors, touched }}
                                        defaultValue={isTrainerSelected(values?.['trainers']?.[day]?.['trainer_info']?.['Trainer']?.["trainer_id"])}
                                        onChange={(value: any) => {
                                          let oldTrainers = values['trainers'];
                                          let trainer_info = oldTrainers?.[day]?.['trainer_info'] || {};
                                          trainer_info['Trainer'] = {
                                            trainer_id: value?.value,
                                            name: value?.label,
                                            type: 'Trainer'
                                          };

                                          oldTrainers[day] = {
                                            ...oldTrainers[day],
                                            trainer_info
                                          }

                                          setFieldValue('trainers', oldTrainers)

                                        }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                      />
                                    </FormGroup>

                                    <FormGroup className="mb-3 col-md-4 col-sm-12 px-1">
                                      <Label className="required-field" for="session">
                                        Resource Person for Day {day + 1}
                                      </Label>
                                      <Select
                                        options={supterVisorList}
                                        feedbackInvalid={{ errors, touched }}
                                        defaultValue={isTrainerSelected(values?.['trainers']?.[day]?.['trainer_info']?.['Resource Person']?.["trainer_id"])}
                                        onChange={(value: any) => {

                                          let oldTrainers = values['trainers'];
                                          let trainer_info = oldTrainers[day]['trainer_info'] || {};
                                          trainer_info['Resource Person'] = {
                                            trainer_id: value?.value,
                                            name: value?.label,
                                            type: 'Resource Person'
                                          };

                                          oldTrainers[day] = {
                                            ...oldTrainers[day],
                                            trainer_info
                                          }

                                          setFieldValue('trainers', oldTrainers)

                                        }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                      />
                                    </FormGroup>
                                    <FormGroup className="mb-3 col-md-4 col-sm-12 px-1">
                                      <Label className="required-field" for="session">
                                        Facilatitor for Day {day + 1}
                                      </Label>
                                      <Select
                                        // name="trainer_id"
                                        options={supterVisorList}
                                        feedbackInvalid={{ errors, touched }}
                                        defaultValue={isTrainerSelected(values?.['trainers']?.[day]?.['trainer_info']?.['Facilatitor']?.["trainer_id"])}
                                        onChange={(value: any) => {
                                          let oldTrainers = values['trainers'];
                                          let trainer_info = oldTrainers[day]['trainer_info'] || {};
                                          trainer_info['Facilatitor'] = {
                                            trainer_id: value?.value,
                                            name: value?.label,
                                            type: 'Facilatitor'
                                          };

                                          oldTrainers[day] = {
                                            ...oldTrainers[day],
                                            trainer_info
                                          }

                                          setFieldValue('trainers', oldTrainers)

                                        }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                      />
                                    </FormGroup>
                                  </Row>
                                </Col>
                              </Row>
                            </TabPane>)
                          })}
                        </TabContent>
                      </>)}
                    </Row>
                  </div>

                  <div className="col-lg-12 col-sm-12 ">
                    {trainingDetails?.training_name && (
                      <table className="table table-sm table-bordered border-dark ">
                        <thead className="bg-light fixed">
                          <tr className="text-center text-dark font-size-16 fw-bold">
                            <td colSpan={20}>Training Details</td>
                          </tr>
                        </thead>

                        <tbody className="text-capitalize  text-dark font-size-14 fw-bold">
                          <tr>
                            <td className="bg-light">Training Title: </td>
                            <td>{trainingDetails?.training_name || ''}</td>
                            <td className="bg-light">Training For: </td>
                            <td>{trainingDetails?.training_for || ''}</td>
                            <td className="bg-light">Duration: </td>
                            <td>{trainingDetails?.duration || ''}</td>
                            <td className="bg-light">Fees: </td>
                            <td>{trainingDetails?.fees || ''}</td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                    {trainingDetails?.training_name && (
                      <table className="table table-sm table-striped table-bordered table-hover">
                        <thead className="bg-dark text-white fixed">
                          <tr className="text-center text-white bg-success font-size-16 fw-bold">

                            <td colSpan={7}>Applicant List</td>
                            <td className='text-end'>Select first number of applicant</td>
                            <td
                              style={{ width: '200px' }}
                              className="text-center"
                            >
                              <div className="input-group">
                                <Input
                                  className="form-control form-control-sm"
                                  placeholder="Select first number of applicant"
                                  type="number"
                                  min={0}
                                  max={applicantList.length}
                                  value={maxNumberOfApplicant}
                                  onChange={(e: any) => {
                                    setMaxNumberOfApplicant(e.target.value);
                                  }}
                                />
                                <button onClick={() => { applicantAllAssign(true) }} className="btn btn-dark btn-sm" type="button" id="button-addon2">Select</button>

                              </div>
                            </td>

                          </tr>
                          <tr className="text-center  font-size-16 fw-bold">
                            <td>SL#</td>
                            <td>Name</td>
                            <td>BMDC Reg. NO</td>
                            <td>FCPS Part 1 Passing Session</td>
                            <td>FCPS Part 1 Passing Year</td>
                            <td>Subject</td>
                            <td>Mobile</td>
                            <td>Email</td>
                            <td
                              style={{ width: '200px' }}
                              className="text-center"
                            >
                              Action
                            </td>

                          </tr>
                          <tr className="text-center text-dark font-size-16 fw-bold">
                            <td></td>
                            <td>
                              <Input
                                className="form-control form-control-sm"
                                placeholder="Search by  name"
                                type="search"
                                defaultValue=""
                                onChange={(e: any) => {
                                  searchFromApplicant(e.target.value, 'registration_name');
                                }}
                              />
                            </td>
                            <td>
                              <Input
                                className="form-control form-control-sm"
                                placeholder="Search by  BMDC Reg. NO"
                                type="search"
                                defaultValue=""
                                onChange={(e: any) => {
                                  searchFromApplicant(e.target.value, 'bmdc_registration_no');
                                }}
                              />
                            </td>
                            <td>
                              <Input
                                className="form-control form-control-sm"
                                placeholder="Search by FCPS Part 1 Passing Session"
                                type="search"
                                defaultValue=""
                                onChange={(e: any) => {
                                  searchFromApplicant(e.target.value, 'fcps_part_one_passing_session');
                                }}
                              />
                            </td>
                            <td>
                              <Input
                                className="form-control form-control-sm"
                                placeholder="Search by FCPS Part 1 Passing Year"
                                type="search"
                                defaultValue=""
                                onChange={(e: any) => {
                                  searchFromApplicant(e.target.value, 'fcps_part_one_passing_year');
                                }}
                              />
                            </td>
                            <td>
                              <Input
                                className="form-control form-control-sm"
                                placeholder="Search by  Subject"
                                type="search"
                                defaultValue=""
                                onChange={(e: any) => {
                                  searchFromApplicant(e.target.value, 'subject_name');
                                }}
                              />
                            </td>

                            <td>
                              <Input
                                className="form-control form-control-sm"
                                placeholder="Search by  mobile"
                                type="search"
                                defaultValue=""
                                onChange={(e: any) => {
                                  searchFromApplicant2(e.target.value, (item: any) => { return item?.reg_info?.personal_info?.mobile; });
                                }}
                              />
                            </td>
                            <td>
                              <Input
                                className="form-control form-control-sm"
                                placeholder="Search by  email"
                                type="search"
                                defaultValue=""
                                onChange={(e: any) => {
                                  searchFromApplicant2(e.target.value, (item: any) => { return item?.reg_info?.personal_info?.email; });
                                }}
                              />
                            </td>

                            <td
                              style={{ width: '200px' }}
                              className="text-center"
                            >
                              <div className="">
                                <Input
                                  type="checkbox"
                                  id={'required-switch-all'}
                                  switch="success"
                                  checked={values['registration_ids'].length === applicantList.length}
                                  onChange={(e) => {
                                    setMaxNumberOfApplicant(0)
                                    applicantAllAssign(e.target.checked)
                                  }}
                                />
                                <Label
                                  htmlFor={'required-switch-all'}
                                  data-on-label="All"
                                  data-off-label="No"
                                ></Label>
                              </div>
                            </td>
                          </tr>
                        </thead>

                        <tbody>
                          {applicantList.map((item: any, key: number) => {
                            return (
                              <tr key={item.id} className="">
                                <td style={{ width: '50px' }}>
                                  <span
                                    style={{ width: '20px', height: '20px' }}
                                    className="avatar-title rounded-circle bg-soft-info m-1 text-dark font-size-12"
                                  >
                                    {key + 1}
                                  </span>
                                </td>
                                <td className='font-size-16 fw-bold text-capitalize'>
                                  {getDetailsData(
                                    'registration_name',
                                    item,
                                  )}
                                </td>
                                <td>
                                  {item.reg_info?.bmdc_registration_no}
                                </td>

                                <td>
                                  {item.reg_info?.fcps_part_one_passing_session}
                                </td>

                                <td>
                                  {item.reg_info?.fcps_part_one_passing_year}
                                </td>

                                <td>
                                  {item.reg_info?.subject_name}
                                </td>

                                <td>
                                  {item.reg_info?.personal_info?.mobile}
                                </td>
                                <td>
                                  {item.reg_info?.personal_info?.email}
                                </td>


                                <td className="text-center">
                                  <Input
                                    key={'assign-switch-' + item.registration_no}
                                    type="checkbox"
                                    id={'assign-switch-' + item.registration_no}
                                    data-id={item.registration_no}
                                    switch="success"
                                    checked={values[
                                      'registration_ids'
                                    ].includes(item.registration_no)}
                                    onChange={(e) => {
                                      applicantAssign(
                                        item.registration_no,
                                        e.target.checked,
                                      );
                                    }}
                                  />
                                  <Label
                                    htmlFor={
                                      'assign-switch-' + item.registration_no
                                    }
                                    data-on-label="Yes"
                                    data-off-label="No"
                                  ></Label>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>

                  <div className="col-lg-12 col-sm-12 ">
                    {applicantList.length < 1 &&
                      trainingDetails?.training_name && (
                        <>
                          <div className="alert alert-danger">
                            You can not create a batch without applicants.
                          </div>
                        </>
                      )}
                    <div className="w-25">
                      <Button
                        type="submit"
                        disabled={applicantList.length && !isLoading ? false : true}
                        text={id ? 'Update' : 'Submit'}
                      />
                    </div>
                  </div>
                </Row>
              </Form>

              {/* {JSON.stringify(values)} */}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
