import { useFormik } from 'formik';
// import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Modal,
  Row,
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import * as url from 'src/helpers/url_helper';
import {
  useGetDataQuery,
  useUpdateByGetMutation,
} from 'src/rtk/features/common/crudApi';
import { useGetFormByLogbookTypeQuery } from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import * as Yup from 'yup';
import * as moduleSetup from './moduleSetup';

const DynamicModuleList = () => {
  let { module } = useParams<{ module: string }>();

  module = module.toLowerCase().replaceAll('-', '');

  console.log(module);

  let moduleData: any = moduleSetup[module as keyof typeof moduleSetup];

  console.log(moduleData);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(true);
  const [submitting, setSubmittingl] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [userInfo, setUserInfo] = useState<any>([]);
  // const [extraDetails, setExtraDetails] = useState<any[]>([]);

  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');
  const generateQueryUrl = () => {
    return `${moduleData.listGetUrl}?page=${page}&paginate_per_page=${limit}${search}`;
  };

  const {
    data: eLogBooks,
    isLoading: dataListLoading,
    isSuccess: dataListLoadingSuccess,
    error: dataListLoadingError,
  } = useGetDataQuery(generateQueryUrl());
  const data: any = eLogBooks?.data || [];

  useEffect(() => {
    if (dataListLoadingError) {
      toast.dismiss();
      toast.error('Data Loading error. Please try again later.');
    } else if (dataListLoading || !dataListLoadingSuccess) {
      toast.dismiss();
      // toast.loading('Data Loading...');
    } else {
      toast.dismiss();
      // toast.success('Data Load Successfully');
    }
  }, [dataListLoading, dataListLoadingSuccess, dataListLoadingError]);

  const [updateELogBook, { isSuccess, isError, error }] =
    useUpdateByGetMutation();
  const err = error as CustomError;

  interface approvalStatus {
    id: string;
    status: string;
    message?: string;
  }

  const initialValues: approvalStatus = {
    id: '',
    status: '',
    message: '',
  };
  const {
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    getFieldProps,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      status: Yup.string().required('Status is required'),
      message: Yup.string().when('status', (status, schema) => {
        if (status && status !== 'Approve') {
          return schema.required(status + ' note is Required!');
        }
        return schema;
      }),
    }),
    onSubmit: (values) => {
      toast.loading('Loading...');
      setSubmittingl(true);
      let updateUrl = `${url.SUPER_VISOR_COURSE_APPROVAL}/${values.id}?approve_type=${values.status}&note=${values.message}`;
      updateELogBook(updateUrl);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      setSubmittingl(false);
      resetForm();
      setShowModal(!showModal);
      toast.dismiss();
      toast.success('Update Successfully');
    }
    if (isError) {
      setSubmittingl(false);
      toast.dismiss();
      toast.error(err?.message || 'Something went wrong');
    }
  }, [isSuccess, isError]);

  const print = (data: any[]) => {
    console.log(data);
  };

  const getDetailsData = (key = '', dataObj: any = {}) => {
    let perseData: any = {};

    if (
      dataObj['details_json'] &&
      typeof dataObj['details_json'] === 'string'
    ) {
      perseData = JSON.parse(dataObj.details_json);
    } else {
      perseData = dataObj;
    }

    return perseData[key] ? (
      <span dangerouslySetInnerHTML={{ __html: perseData[key] }}></span>
    ) : perseData[key.toLowerCase()] ? (
      <span dangerouslySetInnerHTML={{ __html: perseData[key] }}></span>
    ) : (
      ''
    );
  };

  interface SearchFields {
    search?: string;
    from_date?: string;
    to_date?: string;
    status?: string;
  }

  const searchInitialValues: SearchFields = {
    search: '',
    from_date: '',
    to_date: '',
    status: '',
  };

  const {
    handleSubmit: searchSubmit,
    getFieldProps: searchGetFieldProps,
    values: searchValues,
    // touched,
    // errors,
    resetForm: searchResetForm,
    // setErrors,
    // isSubmitting,
  } = useFormik({
    initialValues: searchInitialValues,

    onSubmit: (searchValues) => {
      let s: string = '';

      if (searchValues.search !== '') {
        s = s + `&search=${searchValues.search}`;
      }

      if (searchValues.from_date !== '') {
        s = s + `&from_date=${searchValues.from_date}`;
      }

      if (searchValues.to_date !== '') {
        s = s + `&to_date=${searchValues.to_date}`;
      }

      if (searchValues.status !== '') {
        s = s + `&status=${searchValues.status}`;
      }

      setSearch(s);
      setPage('1');
      // setLimit(20);
    },
  });

  const { data: formSetups, isLoading: formSetupsLoading } =
    useGetFormByLogbookTypeQuery(2); // 2 for Academic Activities
  const forms =
    (formSetups?.data && formSetups?.data[0]?.entry_setup_master) || [];
  let formOptions: SelectOption[] = [
    ...forms?.map((e: LogbookEntrySetupMaster) => ({
      label: e.particulars!,
      value: e.particulars!,
    })),
  ];

  let columns: TableColumn<any>[] = [];

  if (moduleData.columns) {
    columns = [
      {
        name: '#',
        width: '50px',
        cell: (row, index: any) => (data.from ? data.from + index : index + 1),
      },

      ...moduleData?.columns,
    ];
  }

  document.title = moduleData?.title + ' | BCPS';

  return (
    <div className="page-content">
      <Container fluid>
        {moduleData.breadcrumbItem && (
          <BetterBreadcrumb
            title={moduleData?.title + ' List'}
            breadcrumbItem={moduleData.breadcrumbItem}
          />
        )}
        <Card>
          <CardBody>
            <Row>
              <Col>
                <div className="gap-2 ">
                  <Form
                    className="custom-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      searchSubmit();
                    }}
                  >
                    <div className="filter-wrapper mb-0">
                      <FormGroup>
                        <Label for="from_date">From Date</Label>
                        <InputField
                          type="date"
                          placeholder=""
                          invalid={{ errors, touched }}
                          {...searchGetFieldProps('from_date')}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="to_date">To Date</Label>
                        <InputField
                          type="date"
                          placeholder=""
                          invalid={{ errors, touched }}
                          {...searchGetFieldProps('to_date')}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="status">Status</Label>
                        <InputField
                          type="select"
                          placeholder=""
                          options={[
                            { label: 'Approve', value: 'Approve' },
                            { label: 'Pending', value: 'Pending' },
                            { label: 'Reject', value: 'Reject' },
                          ]}
                          invalid={{ errors, touched }}
                          {...searchGetFieldProps('status')}
                        />
                      </FormGroup>

                      <FormGroup className="btn-group btn-group-example mb-3">
                        <Button
                          color="danger"
                          type="reset"
                          text="Reset"
                          onClick={() => {
                            searchResetForm();
                            searchSubmit();
                          }}
                        />
                        <Button
                          type="button"
                          text="Search"
                          onClick={() => {
                            searchSubmit();
                          }}
                        />
                      </FormGroup>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>

            {data.total !== undefined && columns.length > 0 && (
              <DataTable
                // title="Academic Activities List"
                columns={columns}
                data={data.data ? data.data : []}
                pagination
                paginationServer
                // selectableRows
                // sortServer
                // onSort={handleSort}
                onChangePage={(page: number) => {
                  setPage(page);
                }}
                onChangeRowsPerPage={(newPerPage: number, page: number) => {
                  setLimit(newPerPage);
                  setPage(page);
                }}
                progressPending={dataListLoading}
                paginationTotalRows={data.total}
                // expandableRows
                // expandableRowsComponent={ExpandedComponent}
                // fixedHeader
                // fixedHeaderScrollHeight="500px"
                expandOnRowClicked
                highlightOnHover
                paginationRowsPerPageOptions={
                  config.paginationRowsPerPageOptions
                }
              />
            )}

            {data.total === undefined && columns.length > 0 && (
              <DataTable
                columns={columns}
                data={data}
                pagination
                progressPending={dataListLoading}
                paginationTotalRows={data.total}
                highlightOnHover
                paginationRowsPerPageOptions={
                  config.paginationRowsPerPageOptions
                }
              />
            )}
          </CardBody>
        </Card>
      </Container>
      <Modal
        onOpened={function () {
          toast.dismiss();
        }}
        isOpen={showModal}
        scrollable={false}
        size="xl"
        toggle={() => {
          document.body.classList.add('no_padding');
        }}
        backdrop={'static'}
        modalTransition={{ timeout: 0 }}
        backdropTransition={{ timeout: 0 }}
      >
        <Form
          className="custom-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          onReset={(e) => {
            e.preventDefault();
            resetForm();
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Details Info
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setShowModal(false);
              }}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {showForm && (
              <>
                <table className="table table-bordered mt-3">
                  <tbody>
                    <tr className="bg-secondary text-white text-center">
                      <th colSpan={2}>Session Approval Form</th>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>
                        <div>
                          <FormGroup check inline className="mb-3 ">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="status"
                              value={values.status}
                              checked={
                                values.status === 'Approve' ? true : false
                              }
                              id="approve"
                              onChange={() => {
                                setFieldValue('status', 'Approve');
                              }}
                            />
                            <label
                              className="form-check-label text-success"
                              htmlFor="approve"
                            >
                              Approve
                            </label>
                          </FormGroup>

                          <FormGroup check inline className="mb-3 ">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="status"
                              value={values.status}
                              checked={
                                values.status === 'Reject' ? true : false
                              }
                              id="reject"
                              onChange={() => {
                                setFieldValue('status', 'Reject');
                              }}
                            />
                            <label
                              className="form-check-label text-danger"
                              htmlFor="reject"
                            >
                              Reject
                            </label>
                          </FormGroup>

                          <FormGroup check inline className="mb-3 ">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="status"
                              value={values.status}
                              id="correction"
                              checked={
                                values.status === 'Correction' ? true : false
                              }
                              onChange={() => {
                                setFieldValue('status', 'Correction');
                              }}
                            />
                            <label
                              className="form-check-label text-info"
                              htmlFor="correction"
                            >
                              Correction
                            </label>
                          </FormGroup>

                          {errors.status && touched.status && (
                            <div className="text-danger">{errors.status}</div>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>{values.status} Note</th>
                      <td>
                        <InputField
                          type="textarea"
                          placeholder={'Enter ' + values.status + ' Note'}
                          invalid={{ errors, touched }}
                          {...getFieldProps('message')}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </div>
          <div className="modal-footer">
            {showForm && (
              <Button
                type="submit"
                text="Update"
                className="btn btn-success"
                disabled={submitting}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              ></Button>
            )}
            <Button
              type="button"
              text="Close"
              className="btn btn-danger"
              onClick={() => {
                setShowModal(!showModal);
              }}
            ></Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default DynamicModuleList;
