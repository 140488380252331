import React, { useState } from 'react';
import { Link } from 'react-router-dom';

//Import Icons
import Icon from '@ailibs/feather-react-ts';

//import component
import NotificationDropdown from '../TopbarDropdown/NotificationDropdown';
import ProfileMenu from '../TopbarDropdown/ProfileMenu';

// Reactstrap

//import images
import logoSvg from '../../assets/images/logo-sm.svg';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { showRightSidebarAction } from 'src/rtk/features/layout/layoutSlice';

const Header = () => {
  const dispatch = useDispatch();
  const { showRightSidebar } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
    showRightSidebar: state.Layout.ShowRightSidebar,
  }));

  const [isClick, setClick] = useState<boolean>(true);

  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    var body = document.body;
    setClick(!isClick);
    if (isClick === true) {
      body.classList.add('sidebar-enable');
      document.body.setAttribute('data-sidebar-size', 'sm');
    } else {
      body.classList.remove('sidebar-enable');
      document.body.setAttribute('data-sidebar-size', 'lg');
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSvg} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logoSvg} alt="" height="24" />{' '}
                  <span className="logo-txt">BCPS</span>
                </span>
              </Link>

              <Link to="/dashboard" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSvg} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logoSvg} alt="" height="24" />{' '}
                  <span className="logo-txt">BCPS</span>
                </span>
              </Link>
            </div>

            <button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>
          </div>
          <div className="d-flex">
            <NotificationDropdown />
            <div
              onClick={() => {
                dispatch(showRightSidebarAction(!showRightSidebar));
              }}
              className="dropdown d-inline-block"
            >
              {/* <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle"
              >
                <Icon name="settings" className="icon-lg" />
              </button> */}
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
