import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Button, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useCreateSolutionEntryMutation, useGetResolveStatusUpdateMutation } from 'src/rtk/features/researchClinicSetup/researchClinicSetupApi';
import { useCreateEligibleStatusUpdateMutation } from 'src/rtk/features/trainingAllowance/trainingAllowanceApi';
import * as Yup from 'yup';

interface Props {
  infoId: any;
}
interface Props {
  isOpen: boolean;
  toggle: any;
  infoId: any;
}
const initialValues = {
  id: '',
  status: 'eligible',
};

const EligibilityModal = (props: Props) => {

  const { isOpen, toggle, infoId } = props;

  const [createEligibleStatusUpdate, { isSuccess, isError, error }] = useCreateEligibleStatusUpdateMutation();

  const err = error as CustomError;

  const {
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    handleChange,
    getFieldProps,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
    }),
    onSubmit: (values) => {
      values.id = infoId;

      console.log(values);
      createEligibleStatusUpdate(values);
    },
  });


  useEffect(() => {
    if (isSuccess) {
      toast.success('Status Updated Successfully!');
      toggle();
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
    }
  }, [isSuccess, isError]);

  return (
    <Modal
      isOpen={isOpen}
      backdrop={'static'}
      toggle={toggle}
      size="md"
    >
      <ModalHeader toggle={toggle}>Eligibility Status</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <ModalBody>
          <div className="col">
            <FormGroup check inline className="mb-3 ms-2">
              <input
                className="form-check-input"
                type="radio"
                name="status"
                id="eligible"
                onChange={(e) => {
                  setFieldValue('status', 'eligible');
                }}
                checked={values.status === 'eligible'}
              />
              <label
                className="form-check-label text-success"
                htmlFor="eligible"
              >
                Eligible
              </label>
            </FormGroup>
            <FormGroup check inline className="mb-3 ">
              <input
                className="form-check-input"
                type="radio"
                name="status"
                id="notEligible"
                onChange={() => {
                  setFieldValue('status', 'notEligible');
                }}
              />
              <label
                className="form-check-label text-danger"
                htmlFor="notEligible"
              >
                Not Eligible
              </label>
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex gap-1">
            <button
              type="submit"
              className="btn btn-primary"
            >
              Save
            </button>

            <Button color="danger" onClick={toggle}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EligibilityModal;
