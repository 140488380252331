import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Alert, Button, Card, CardBody, Table } from 'reactstrap';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import { config } from 'src/config';
import { useGetPersonalProfileQuery } from 'src/rtk/features/trainee/traineeApi';
import { useDeleteTrainingRecordMutation } from 'src/rtk/features/trainingRecord/trainingRecordlApi';
import AddTrainingProfile from './AddTrainingProfile';
import EditTrainingProfile from './EditTrainingProfile';
import { ConfirmToast } from 'react-confirm-toast';

interface TrainingProfileProps {
  nextDisabled?: any;
  setCurrentStep?: any;
}

const TrainingRecordProfile = (props: TrainingProfileProps) => {
  const [editInfoID, setEditInfoID] = useState<string>('');
  const [isAddForm, setIsAddForm] = useState(false);

  const [infoId, setInfoId] = useState('');

  const [modalShow, setModalShow] = useState(false);

  const { data: trainingRecord } = useGetPersonalProfileQuery();

  const trainingRecordData = trainingRecord?.data?.details?.training_infos;

  // useEffect(()=>{
  //   trainingRecordData?.length === 0 ? props.nextDisabled(true) : props.nextDisabled(false);
  // },[trainingRecordData]);
  useEffect(()=>{
    props.nextDisabled(false);
  },[])

  const [
    deleteTrainingRecord,
    { isSuccess: isDeleteSuccess, isError: isDeleteError },
  ] = useDeleteTrainingRecordMutation();

  const deleteHandler = (modalState: boolean, id: string) => {
    setModalShow(modalState);
    setInfoId(id);
    // const confirm = window.confirm('Are you sure you want to delete?');
    // if (confirm) deleteTrainingRecord(id);
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      toast.success('Training Info Deleted Successfully!');
      setModalShow(false);
    }
    if (isDeleteError) {
      toast.error('Error Deleting Training Info!');
    }
  }, [isDeleteSuccess, isDeleteError]);

  const handleUpdate = (traningID: string) => {
    setEditInfoID(traningID);
    setIsAddForm(false);
  };

  let serial = 0;

  return (
    <div className="mb-4">
      <h5 className='block-heading'>Previous Training Information</h5>
      <Alert color="success"> <i className="fas fa-info-circle fa-lg" style={{ color: '#0b45f4' }}/> You can add previous Training Records, Workshop etc. But it is not mandatory and you can add it later as well.
        <Button
          type='button'
          className='btn btn-sm me-2'
          color='primary'
          onClick={() => { 
            setIsAddForm(!isAddForm);
            setEditInfoID('');
          }}
        >
          <i className="fa fa-plus" /> Add
        </Button>

        <Button
          type='button'
          className='btn btn-sm'
          color='success'
          onClick={() => { 
            props.setCurrentStep(4);
          }}
        >
          Skip <i className="fas fa-fast-forward" />
        </Button>
      </Alert>
      {isAddForm && (
        <AddTrainingProfile />
      )} 
      {(!isAddForm && editInfoID !== '') && (
        <EditTrainingProfile
          editInfoID={editInfoID}
          setIsAddForm={setIsAddForm}
        />
      )}

      {trainingRecordData && trainingRecordData?.length > 0 && (
        <Card className="mt-3">
          <CardBody>
            <div className="table-responsive">
              <Table className="table table-striped table-sm table-hover mb-0">
                <thead className='table-secondary'>
                  <tr>
                    <th>Sl</th>
                    <th>Training Title</th>
                    <th>Institute</th>
                    <th>Department</th>
                    <th>Duration</th>
                    <th>Supervisor's Information</th>
                    <th>Document</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {trainingRecordData?.map((elmt) => (
                    <tr key={elmt.id}>
                      <td>{++serial}</td>
                      <td>{elmt.training_title}</td>
                      <td>{elmt.name_of_institute}</td>
                      <td>{elmt.department}</td>
                      <td>{elmt.duration + ' ' + elmt.duration_unit}</td>
                      <td>
                        {elmt.supervisor_name +
                          ', ' +
                          elmt.supervisor_designation +
                          ' (' +
                          elmt.supervisor_post_graduation_degree +
                          ')'}
                      </td>
                      <td>
                        {elmt.document ? (
                          <a
                          href={`${config.APP_URL+elmt.document}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <p className="btn btn-sm btn-outline-primary ">
                              View
                            </p>
                          </a>
                        ) : (
                          <p className="btn btn-sm btn-outline-secondary ">
                            N/A
                          </p>
                        )}
                      </td>
                      <td>
                        <div className='d-flex gap-1'>
                          <Button
                            className="btn btn-success btn-sm"
                            onClick={() => handleUpdate(elmt.id!)}
                          >
                            Edit
                          </Button>
                          <ConfirmToast
                            asModal={true}
                            childrenClassName="margin-top-10"
                            customCancel="No"
                            customConfirm="Yes"
                            customFunction={() =>
                              deleteTrainingRecord(elmt?.id!)
                            }
                            message={'Do You Want to Delete?'}
                            position="top-right"
                            showCloseIcon={true}
                            theme="lilac"
                          >                            
                            <Button
                              className="btn btn-danger btn-sm"
                              // onClick={() => deleteHandler(true, elmt.id!)}
                            >
                              Delete
                            </Button>
                          </ConfirmToast>
                        </div>
                      </td>
                      {/* {modalShow && infoId === elmt?.id ? (
                          <DeleteInformationModal
                            isOpen={modalShow}
                            toggle={() => setModalShow(!modalShow)}
                            id={infoId}
                            deleteInfomation={deleteTrainingRecord}
                          />
                        ) : null} */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default TrainingRecordProfile;
