import CBDFormDetails from "src/pages/LogbookAssesment/CBDFormDetails";
import CBDList from "src/pages/LogbookAssesment/CBDList";
import CBDTab from "src/pages/LogbookAssesment/CBDTab";
import MiniCEX from "src/pages/LogbookAssesment/MiniCEX";
import MiniCEXFormDetails from "src/pages/LogbookAssesment/MiniCEXFormDetails";
import MiniCexEntryBySupervisor from "src/pages/LogbookAssesment/MiniCexEntryBySupervisor";
import MiniCexList from "src/pages/LogbookAssesment/MiniCexList";
import UpdatedSupervisorCBDFormEntry from "src/pages/LogbookAssesment/UpdatedSupervisorCBDFormEntry";
import SupervisorDOPSformEntry from "src/pages/LogbookAssesment/SupervisorDOPSformEntry";
import DOPSlist from "src/pages/LogbookAssesment/DOPSlist";
import MiniCEXTraineeList from "src/pages/LogbookAssesment/MiniCEXTraineeList";
import CBDTraineeList from "src/pages/LogbookAssesment/CBDTraineeList";
import DOPSTraineeList from "src/pages/LogbookAssesment/DOPSTraineeList";

export const logBookAssesmentRoutes = [
  { path: '/assesment-mini-cex', component: MiniCEX },
  { path: '/mini-cex-list', component: MiniCexList },

  { path: '/case-based-disscussion-list', component: CBDList },
  { path: '/assesment-cbd/:regNo/:id/:logbookSessionId', component: CBDTab },

  { path: '/mini-cex/form-details/:regNo/:logbookSessionId', component: MiniCEXFormDetails },
  { path: '/case-based-discussion/form-details/:regNo/:logbookSessionId', component: CBDFormDetails },

  { path: '/mini-cex-entry', component: MiniCEXTraineeList },
  { path: '/cbd-entry/:regNo/:id/:logbookSessionId', component: UpdatedSupervisorCBDFormEntry },
  { path: '/cbd-entry', component: CBDTraineeList },  
  { path: '/assesment-mini-cex-by-supervisor/:regNo/:id/:logbookSessionId', component: MiniCexEntryBySupervisor },

  { path: '/dops-list', component: DOPSlist },
  { path: '/dops-entry', component: DOPSTraineeList },
  { path: '/assesment-dops-by-supervisor/:regNo/:id/:logbookSessionId', component: SupervisorDOPSformEntry },

];
