import { Card, CardText, CardTitle } from 'reactstrap';

export const Criteria = () => {
  return (
    <Card body className="bg-light">
      <CardText>
        <p className="fw-semibold">
          Criteria for becoming a Supervisor for Clinical Training are:
        </p>
        <ul className="list-unstyled ms-3 mt-1">
          <li>
            <h6>
              A. Any Fellow or equivalent degree holders having any of the
              following appointment:{' '}
            </h6>
            <ul>
              <li>Professor or Associate Professor of an institute or,</li>
              <li>
                Assistant Professor or Consultant holding a clinical unit as
                in-charge of an accredited institute or,
              </li>
              <li>Consultant working in the district hospital</li>
            </ul>
          </li>
          <li className="mt-2">
            <h6>
              B. Head of the department of an institute/hospital accredited by
              BCPS
            </h6>
          </li>
        </ul>
      </CardText>
    </Card>
  );
};
