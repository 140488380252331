import * as url from 'src/helpers/url_helper';
import * as Yup from 'yup';

const formObj: any = {
  debug: false,

  createAPIUrl: url.SETUP_EXAM_TRAINING_CREATE,
  updateAPIUrl: url.SETUP_EXAM_TRAINING_UPDATE,
  getDataByIdAPIUrl: url.SETUP_EXAM_TRAINING_BY_ID,
  listAPIUrl: url.SETUP_EXAM_TRAINING_INFO_LIST,
  deleteAPIUrl: url.SETUP_EXAM_TRAINING_DELETE,

  listUrl: '/setup/exam-info/',
  addUrl: '/setup/exam-info/add',
  updateUrl: '/setup/exam-info/update/',

  createNewBtnTitle: 'Create New',
  submitBtnTitle: 'Save',
  updateBtnTitle: 'Update',
  resetBtnTitle: 'Reset',
  draftBtnTitle: '',
  deletePromtMessage: 'Do you want to delete?',

  title: 'Exam Info',
  breadcrumbItemForList: [{ link: '/', name: 'Dashboard' }],
  breadcrumbItemForForm: [
    { link: '/', name: 'Dashboard' },
    { link: '/setup/exam-info/', name: 'Exam Info List' },
  ],

  formFields: {
    // period_from: {
    //   positionInForm: 3,
    //   label: 'Period',
    //   name: 'period_from',
    //   name2: 'period_to',
    //   type: 'date',
    //   options: {
    //     mode: 'single', //range, multiple, single
    //     dateFormat: 'Y-m-d', // 'Y-m-d' 'd/m/Y'
    //     retDateFormat: 'YYYY-MM-DD', // 'YYYY-MM-DD' 'DD-MM-YYYY'
    //     minDate: new Date(),
    //     // defaultDate: new Date(),
    //     defaultDate: '',
    //     // defaultDate: [values['period_from'], values['period_to']],
    //   },
    //   isRequired: true,
    //   validation: Yup.string().required('Period is required'),
    //   showInForm: true,
    //   showInSearch: false,
    //   colSize: 6,
    // },
    // applicable_for: {
    //   positionInForm: 1,
    //   label: 'Applicable for',
    //   name: 'applicable_for',
    //   type: 'checkbox',
    //   default: 'Trainee',
    //   isRequired: true,
    //   validation: Yup.array()
    //     .required('Applicable for is required')
    //     .min(1, 'Select Minimum 1.')
    //     .max(2, 'Select Maximum 2'),
    //   showInForm: true,

    //   showInSearch: true,
    //   colSize: 12,
    //   data: [
    //     { label: 'Trainee', value: 'Trainee' },
    //     { label: 'Supervisor', value: 'Supervisor' },
    //     { label: 'Fellow', value: 'Fellow' },
    //   ],
    // },

    type: {
      positionInForm: 1,
      label: 'Exam Type',
      name: 'type',
      type: 'select',
      default: '',
      // isMulti: false,
      isRequired: true,
      validation: Yup.string()
        .required('Type is required')
        .oneOf(['main-training', 'course', 'exam']),
      showInForm: true,

      showInSearch: true,
      colSize: 6,
      data: [
        { label: 'Course', value: 'course' },
        { label: 'Exam', value: 'exam' },
        { label: 'Main Training', value: 'main-training' },
      ],
    },

    main_trainings: {
      positionInForm: 2,
      label: 'Main Training',
      name: 'main_trainings',
      type: 'select',
      default: '',
      isMulti: false,
      isRequired: true,
      validation: Yup.string().ensure().required('Main Training is required!'),
      showInForm: true,
      showInSearch: true,
      colSize: 6,
      dataApi: {
        url: {
          queryUrl: url.RTMD_GET_COURSE_LISTS,
          formData: {
            type: 'main-training',
            subject_id: 'no-need',
          },
        },
        // url: url.RTMD_GET_COURSE_LISTS,
        key: 'id',
        label: 'name',
        label2: '',
      },
      data: [],
      dependField: 'type',
      dependValue: 'main-training',
      condition: '!==',
    },

    name: {
      positionInForm: 3,
      label: 'Exam Name',
      name: 'name',
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Name is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
    },

    code: {
      positionInForm: 4,
      label: 'Exam Code',
      name: 'code',
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Code is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
    },

    session: {
      positionInForm: 5,
      label: 'Exam Session',
      name: 'session',
      type: 'select',
      default: '',
      isMulti: false,
      isRequired: true,
      validation: Yup.string()
        .required('Type is required')
        .oneOf(['January-June', 'July-December']),
      showInForm: true,
      showInSearch: true,
      colSize: 6,
      data: [
        { label: 'January-June', value: 'January-June' },
        { label: 'July-December', value: 'July-December' },
      ],
    },

    duration: {
      positionInForm: 6,
      label: 'Duration',
      name: 'duration',
      type: 'number',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Duration is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 3,
    },

    duration_unit: {
      positionInForm: 7,
      label: 'Unit',
      name: 'duration_unit',
      type: 'select',
      default: '',
      isMulti: false,
      isRequired: true,
      validation: Yup.string()
        .required('Unit is required')
        .oneOf(['day', 'week', 'month', 'year']),
      showInForm: true,
      showInSearch: true,
      colSize: 3,
      data: [
        { label: 'day', value: 'day' },
        { label: 'week', value: 'week' },
        { label: 'month', value: 'month' },
        { label: 'year', value: 'year' },
      ],
    },

    description: {
      positionInForm: 8,
      label: 'Description',
      name: 'description',
      type: 'textarea',
      default: '',
      isRequired: false,
      validation: Yup.string().required('Description is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },

    paid_course: {
      positionInForm: 9,
      label: 'Paid Status',
      name: 'paid_course',
      type: 'radio',
      default: '1',
      isMulti: false,
      isRequired: true,
      validation: Yup.string()
        .required('Paid Status is required')
        .oneOf(['0', '1']),
      showInForm: true,
      showInSearch: true,
      colSize: 4,
      data: [
        { label: 'Paid', value: '1' },
        { label: 'Free', value: '0' },
      ],
    },

    required_ind: {
      positionInForm: 20,
      label: 'Is Required?',
      name: 'required_ind',
      type: 'radio',
      default: true,
      isMulti: false,
      isRequired: true,
      validation: Yup.string().required('Is Required?'),
      showInForm: true,
      showInSearch: true,
      colSize: 4,
      data: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    },

    status: {
      positionInForm: 20,
      label: 'Status',
      name: 'status',
      type: 'radio',
      default: true,
      isMulti: false,
      isRequired: true,
      validation: Yup.string().required('Is Required?'),
      showInForm: true,
      showInSearch: true,
      colSize: 4,
      // data: ['In-active', 'Active'],
      data: [
        { label: 'Active', value: true },
        { label: 'In-active', value: false },
      ],
    },
    fee: {
      positionInForm: 10,
      label: 'Fee',
      name: 'fee',
      type: 'number',
      default: '',
      isRequired: true,
      validation: Yup.string().when('paid_course', (paid_course, schema) => {
        if (paid_course && parseInt(paid_course) === 1) {
          return schema.required('Fee/Amount is Required!');
        }
        return schema;
      }),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
      dependField: 'paid_course',
      dependValue: '1',
      condition: '===',
    },

    // co_guide_id: {
    //   positionInForm: 4,
    //   label: 'Co-guide',
    //   name: 'co_guide_id',
    //   type: 'select',
    //   default: [],
    //   isMulti: true,
    //   isRequired: true,
    //   validation: Yup.array()
    //     .ensure()
    //     .required('Co-guide is required!')
    //     .min(1, 'Select Minimum 1.')
    //     .max(3, 'Select Maximum 3'),
    //   showInForm: true,
    //   showInSearch: true,
    //   colSize: 4,
    //   dataApi: {
    //     url: '/supervisors',
    //     key: 'id',
    //     label: 'name',
    //   },
    //   data: [],
    // },
  },

  columns: [
    {
      name: 'Name',
      selector: (row: any) => row.name,
    },

    {
      name: 'Code',
      selector: (row: any) => row.code,
    },
    {
      name: 'Type',
      selector: (row: any) => row.type,
    },
    {
      name: 'Session',
      selector: (row: any) => row.session,
    },
    {
      name: 'Duration',
      selector: (row: any) => row.duration,
      cell: (row: any) =>
        row.duration ? row.duration + ' ' + row.duration_unit : '',
    },

    {
      name: 'Is Paid Course?',
      selector: (row: any) => row.paid_course,
      cell: (row: any) => (row.paid_course ? 'Yes' : 'No'),
    },

    {
      name: 'Status',
      selector: (row: any) => row.status,
      cell: (row: any) => (row.status ? 'Active' : 'Inactive'),
    },
  ],
};

export default formObj;
