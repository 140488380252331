import { Formik, Field, Form, useFormik } from "formik";
import React from "react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Container, FormGroup, Input, Label } from "reactstrap";
import { InputField, Button } from "src/components";
import BetterBreadcrumb from "src/components/Common/BetterBreadCrumb";
import * as Yup from 'yup';
import { DynamicFormSelect } from "./DynamicFormSelect";

interface Field {
    name: string;
    type: string;
    dropdownLabel: string;    
    required: boolean;
}

export const MyDynamicForm = ({ formStructure, formikProp, fieldProp } : any) => {   
  
      console.log(formStructure);
      
      const InputFields: Field[] = formStructure.setupFields.map((field: any, index: number) => {  
        console.log(field.dropdownData);

        return (
            {name: field.fieldName, type: field.fieldType.value, dropdownLabel: field.dropdownData.value, required: field.required}
        );
      });            
      
      type FormValues = {
        [key: string]: string | number | boolean | undefined;
      };          

      const initialValues = React.useMemo(() => {
        const fieldsObj = InputFields.reduce((acc: FormValues, field: Field) => {
            switch (field.type) {
              case 'text':
              case 'textarea':
              case 'email':             
              case 'date':
              case 'select':
              case 'file':
              case 'radio':
                acc[field.name] = '';
                break;              
              case 'number':
                acc[field.name] = 0;
                break;
              case 'checkbox':              
                acc[field.name] = false;
                break;
              default:
                acc[field.name] = undefined;
                break;
            }
  
            return acc;
          }, {});

        return fieldsObj;
      }, [InputFields]);

      const generateValidationSchema = (inputFields: any) => {
        const validationSchema: Record<string, any> = {};
      
        inputFields.forEach((field: any) => {
          if (field.required) {
            validationSchema[field.name] = Yup.string().required(`${field.name} is required`);
          }
        });
      
        return Yup.object().shape(validationSchema);
      };
      
      
      const formik = useFormik({
        initialValues: initialValues,
        validationSchema: generateValidationSchema(InputFields),
        onSubmit: (values) => {
          
        },
      });           
      
      const { handleSubmit, handleChange, handleBlur, setFieldValue, getFieldProps, values, touched, errors, resetForm, setErrors } = formik;

      const handleSubmitButtonClick = (e: any, values: FormValues) => {
        e.preventDefault();
        const finalResult = JSON.stringify(values, null, 2);
        if(formikProp && fieldProp) {
          formikProp.values[fieldProp] = finalResult;
          toast.success(`${fieldProp} is submitted successfully`);
          formikProp.handleSubmit();
        }          
      }

      return (
        <>                    
            <Container fluid>
                {/* <BetterBreadcrumb
                    title={'Generated Form'}                    
                /> */}
                <div className="row align-items-center justify-content-center">
                    <div className="card mt-3">
                        <h5 className="card-header">
                            <i className="fa fa-plus me-2" />
                            {fieldProp ? fieldProp : 'Dynamically Generated Form'}
                        </h5>
                        <div className="card-body">                            
                            <Container fluid>
                                <Formik initialValues={initialValues} onSubmit={() => {}}>
                                    <Form className="custom-form"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            handleSubmit();
                                        }}>
                                        <div className="row">                            
                                            {InputFields.map((field, index) => {
                                              switch(field.type) {
                                                case 'checkbox':
                                                  return (
                                                    <div key={index} className='col-lg-4 col-md-6 col-sm-12'>                                       
                                                        <FormGroup check className="mb-3 mt-4">
                                                          <Label check>
                                                            <Input type={field.type} checked={!!values[field.name]} {...getFieldProps(field.name)} />{' '}
                                                            {field.name}
                                                          </Label>
                                                        </FormGroup>                                       
                                                      </div>
                                                  );
                                                case 'File attachment select':
                                                  return  (
                                                    <div key={index} className='col-lg-4 col-md-6 col-sm-12'>
                                                      <FormGroup className="mb-3">
                                                        <Label className="form-label">{field.name}</Label>
                                                        <InputField
                                                            type={field.type as InputType}
                                                            name={field.name}                                           
                                                            onChange={(e) => {
                                                              setFieldValue(field.name, e.target.files[0]);
                                                            }}

                                                            invalid={{ errors, touched }}
                                                        />
                                                      </FormGroup>  
                                                    </div>
                                                  );
                                                case 'select':                                                                                                                                                                
                                                  return (
                                                    <DynamicFormSelect key={index} field={field} formik={formik} />
                                                  );
                                                default:
                                                  return (
                                                    <div key={index} className='col-lg-4 col-md-6 col-sm-12'>
                                                      <FormGroup className="mb-3">
                                                        <Label for={field.name}>{field.name}</Label>
                                                        <InputField
                                                          type={field.type as InputType}
                                                          placeholder={field.name}
                                                          invalid={{ errors, touched }}
                                                          {...getFieldProps(field.name)}
                                                        />
                                                      </FormGroup>
                                                    </div>
                                                  );
                                              }                                                                                                  
                                            }                                                                                                                                              
                                          )}
                                            <div className="col-sm-12">
                                                <Button type="submit" text="Submit" onClick={(e) => handleSubmitButtonClick(e, values)} />
                                            </div>                                                                                                      
                                        </div>                                        
                                    </Form>
                                </Formik>
                            </Container>                                                     
                        </div>
                    </div>
                </div>
            </Container>           
        </>
      );
};
