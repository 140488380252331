import * as url from 'src/helpers/url_helper';
import * as Yup from 'yup';

const formObj: any = {
  debug: true,

  createAPIUrl: url.SETUP_EXAM_TRAINING_CREATE,
  updateAPIUrl: url.SETUP_EXAM_TRAINING_UPDATE,
  getDataByIdAPIUrl: url.SETUP_EXAM_TRAINING_BY_ID,
  listAPIUrl: url.SETUP_EXAM_TRAINING_INFO_LIST,
  deleteAPIUrl: url.SETUP_EXAM_TRAINING_DELETE,

  listUrl: '/demo/',
  addUrl: '/demo/add',
  updateUrl: '/demo/update/',

  showSearchForm: true,
  showCreateNewBtn: true,
  showUpdateBtn: true,
  showViewBtn: true,
  showDeleteBtn: true,
  showTableActionColumn: true,

  createNewBtnTitle: 'Create New',
  submitBtnTitle: 'Save',
  updateBtnTitle: 'Update',
  resetBtnTitle: 'Reset',
  draftBtnTitle: '',
  viewBtnTitle: 'View',
  deletBtnTitle: 'Delete',
  deletePromtMessage: 'Do you want to delete?',

  title: 'Demo Page',
  breadcrumbItemForList: [{ link: '/', name: 'Dashboard' }],
  breadcrumbItemForForm: [
    { link: '/', name: 'Dashboard' },
    { link: '/demo/', name: 'Demo Page List' },
  ],

  formFields: {
    training_for: {
      positionInForm: 15,
      label: 'Applicable for',
      name: 'training_for',
      viewCell: (row: any) => row.training_for,
      // cell: (row: any) => row.training_for,
      type: 'checkbox',
      isSwitch: true,
      default: 'Trainee',
      isRequired: true,
      validation: Yup.array()
        .required('Applicable for is required')
        .min(1, 'Select Minimum 1.')
        .max(2, 'Select Maximum 2'),
      showInForm: true,
      showInSearch: true,
      colSize: 12,
      data: [
        { label: 'Trainee', value: 'Trainee' },
        { label: 'Supervisor', value: 'Supervisor' },
        { label: 'Fellow', value: 'Fellow' },
      ],
    },

    type: {
      positionInForm: 5,
      label: 'Exam Type',
      name: 'type',
      viewCell: 'type',
      cell: (row: any) => row.type,
      type: 'select',
      default: '',
      // isMulti: false,
      isRequired: true,
      validation: Yup.string()
        .required('Type is required')
        .oneOf(['main-training', 'course', 'exam']),
      showInForm: true,

      showInSearch: true,
      colSize: 6,
      data: [
        { label: 'Course', value: 'course' },
        { label: 'Exam', value: 'exam' },
        { label: 'Main Training', value: 'main-training' },
      ],
    },

    main_trainings: {
      positionInForm: 2,
      label: 'Main Training',
      name: 'main_trainings',
      type: 'select',
      default: '',
      isMulti: false,
      isRequired: true,
      validation: Yup.string().ensure().required('Main Training is required!'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
      dataApi: {
        url: {
          queryUrl: url.RTMD_GET_COURSE_LISTS,
          formData: {
            type: 'main-training',
            subject_id: 'no-need',
          },
        },
        // url: url.RTMD_GET_COURSE_LISTS,
        key: 'id',
        label: 'name',
        label2: '',
      },
      data: [],
      dependField: 'type',
      dependValue: 'main-training',
      condition: '!==',
    },

    name: {
      positionInForm: 3,
      label: 'Exam Name',
      name: 'name',
      viewCell: 'name',
      cell: (row: any) => row.name,
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Name is required'),
      showInForm: true,

      showInSearch: true,
      colSize: 6,
    },

    code: {
      positionInForm: 4,
      label: 'Exam Code',
      name: 'code',
      viewCell: 'code',
      cell: (row: any) => row.code,
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Code is required'),
      showInForm: true,

      showInSearch: true,
      colSize: 6,
    },

    // {
    //   positionInForm: 3,
    //   label: 'Period',
    //   name: 'period_from',
    //   name2: 'period_to',
    //   type: 'date',
    //   options: {
    //     mode: 'range', //range, multiple, single
    //     dateFormat: 'Y-m-d', // 'Y-m-d' 'd/m/Y'
    //     retDateFormat: 'YYYY-MM-DD', // 'YYYY-MM-DD' 'DD-MM-YYYY'
    //     minDate: new Date(),
    //     // defaultDate: new Date(),
    //     allowInput: true,
    //     defaultDate: '',
    //     // defaultDate: [values['period_from'], values['period_to']],
    //   },
    //   isRequired: true,
    //   validation: Yup.string().required('Period is required'),
    //   showInForm: true,

    //   showInSearch: false,
    //   colSize: 6,
    // },

    session: {
      positionInForm: 5,
      label: 'Exam Session',
      name: 'session',

      type: 'select',
      default: '',
      isMulti: false,
      isRequired: true,
      validation: Yup.string()
        .required('Type is required')
        .oneOf(['January-June', 'July-December']),
      showInForm: true,

      viewCell: 'session',
      cell: (row: any) => row.session,
      showInSearch: true,
      colSize: 6,
      data: [
        { label: 'January-June', value: 'January-June' },
        { label: 'July-December', value: 'July-December' },
      ],
    },

    duration: {
      positionInForm: 6,
      label: 'Duration',
      name: 'duration',
      cell: (row: any) => row.name,
      default: '',
      isRequired: true,
      validation: Yup.string().required('Duration is required'),
      showInForm: true,
      viewCell: (row: any) =>
        row.duration ? row.duration + ' ' + row.duration_unit : '',

      showInSearch: false,
      colSize: 3,
    },

    duration_unit: {
      positionInForm: 7,
      label: 'Unit',
      name: 'duration_unit',
      type: 'select',
      default: '',
      isMulti: false,
      isRequired: true,
      validation: Yup.string()
        .required('Unit is required')
        .oneOf(['day', 'week', 'month', 'year']),
      showInForm: true,
      showInSearch: false,
      colSize: 3,
      data: [
        { label: 'day', value: 'day' },
        { label: 'week', value: 'week' },
        { label: 'month', value: 'month' },
        { label: 'year', value: 'year' },
      ],
    },

    guide_id: {
      positionInForm: 13,
      label: 'Guide',
      name: 'guide_id',
      viewCell: (row: any) => row.guide_id,
      type: 'modal',
      default: '',
      isMulti: false,
      isRequired: true,
      validation: Yup.string().ensure().required('Guide is required!'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
      dataApi: {
        listAPIUrl: url.GET_GUIDE_COGUIDE_LIST + '?type=Guide&',
        displayLabel: (row: any) => row?.label || '',
        key: (row: any) =>
          row?.registration?.personal_info?.full_name ? row?.id : false,
        label: (row: any) => {
          return row?.registration?.personal_info?.full_name || '';
        },
        showSearchForm: false,
        searchFormFields: [
          {
            label: 'BMDC NO',
            name: 'bmdc_registration_no',
            type: 'text',
            cell: (row: any) => row?.registration?.bmdc_registration_no || '',
            showInSearch: true,
          },
          {
            label: 'Name',
            name: 'full_name',
            type: 'text',
            cell: (row: any) =>
              row?.registration?.personal_info?.full_name || '',
            showInSearch: true,
          },

          {
            label: 'Institute Name',
            name: 'institute_name',
            type: 'text',
            cell: (row: any) =>
              row.registration?.employment_infos?.institute_name || '',
            showInSearch: true,
          },

          {
            label: 'Mobile',
            name: 'mobile',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.mobile || '',
            showInSearch: true,
          },
          {
            label: 'Email',
            name: 'email',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.email || '',
            showInSearch: true,
          },
        ],
      },
      data: [],
    },

    co_guide_id: {
      positionInForm: 14,
      label: 'Co-guide',
      name: 'co_guide_id',
      type: 'modal',
      default: '',
      isMulti: true,
      isRequired: true,
      validation: Yup.array()
        .ensure()
        .required('Co-guide is required!')
        .min(1, 'Select Minimum 1.')
        .max(3, 'Select Maximum 3'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
      dataApi: {
        listAPIUrl: url.GET_GUIDE_COGUIDE_LIST + '?type=Co-Guide&',
        displayLabel: (row: any) => row?.label || '',
        key: (row: any) =>
          row?.registration?.personal_info?.full_name ? row?.id : false,
        label: (row: any) => {
          return row?.registration?.personal_info?.full_name || '';
        },
        showSearchForm: false,
        searchFormFields: [
          {
            label: 'BMDC NO',
            name: 'bmdc_registration_no',
            type: 'text',
            cell: (row: any) => row?.registration?.bmdc_registration_no || '',
            showInSearch: true,
          },
          {
            label: 'Name',
            name: 'full_name',
            type: 'text',
            cell: (row: any) =>
              row?.registration?.personal_info?.full_name || '',
            showInSearch: true,
          },

          {
            label: 'Institute Name',
            name: 'institute_name',
            type: 'text',
            cell: (row: any) =>
              row.registration?.employment_infos?.institute_name || '',
            showInSearch: true,
          },

          {
            label: 'Mobile',
            name: 'mobile',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.mobile || '',
            showInSearch: true,
          },
          {
            label: 'Email',
            name: 'email',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.email || '',
            showInSearch: true,
          },
        ],
      },
      data: [],
    },

    description: {
      positionInForm: 8,
      label: 'Description',
      name: 'description',
      viewCell: (row: any) => row.description,
      type: 'richtext',
      default: '',
      isRequired: false,
      validation: Yup.string().required('Description is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },

    paid_course: {
      positionInForm: 9,
      label: 'Paid Status',
      name: 'paid_course',
      type: 'radio',
      default: '1',
      isMulti: false,
      isRequired: true,
      validation: Yup.string()
        .required('Paid Status is required')
        .oneOf(['0', '1']),
      showInForm: true,
      viewCell: (row: any) => (row.paid_course ? 'Yes' : 'No'),
      cell: (row: any) => (row.paid_course ? 'Yes' : 'No'),
      showInSearch: true,
      colSize: 4,
      data: [
        { label: 'Paid', value: '1' },
        { label: 'Free', value: '0' },
      ],
    },

    required_ind: {
      positionInForm: 20,
      label: 'Is Required?',
      name: 'required_ind',
      type: 'radio',
      default: true,
      isMulti: false,
      isRequired: true,
      validation: Yup.string().required('Is Required?'),
      viewCell: (row: any) => (row.required_ind ? 'Yes' : 'No'),
      showInForm: true,
      showInSearch: false,
      colSize: 4,
      data: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    },

    status: {
      positionInForm: 20,
      label: 'Status',
      name: 'status',
      type: 'radio',
      default: true,
      isMulti: false,
      isRequired: true,
      validation: Yup.string().required('Is Required?'),
      showInForm: true,
      viewCell: (row: any) => (row.status ? 'Active' : 'Inactive'),
      cell: (row: any) => (row.status ? 'Active' : 'Inactive'),
      showInSearch: true,
      colSize: 4,
      // data: ['In-active', 'Active'],
      data: [
        { label: 'Active', value: true },
        { label: 'In-active', value: false },
      ],
    },
    fee: {
      positionInForm: 10,
      label: 'Fee',
      name: 'fee',
      type: 'number',
      default: '',
      isRequired: true,
      validation: Yup.string().when('paid_course', (paid_course, schema) => {
        if (paid_course && parseInt(paid_course) === 1) {
          return schema.required('Fee/Amount is Required!');
        }
        return schema;
      }),
      showInForm: true,
      viewCell: (row: any) => row?.exam_tranning_fee?.exam_fee_new,
      showInSearch: false,
      colSize: 6,
      dependField: 'paid_course',
      dependValue: '1',
      condition: '===',
    },

    designation: {
      positionInForm: 2,
      label: 'Designation',
      name: 'designation',
      type: 'select',
      default: '',
      isMulti: false,
      isRequired: true,
      validation: Yup.string().ensure().required('Designation is required!'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
      dataApi: {
        // url: {
        //   queryUrl: url.RTMD_GET_COURSE_LISTS,
        //   formData: {
        //     type: 'main-training',
        //     subject_id: 'no-need',
        //   },
        // },
        // url: url.RTMD_GET_COURSE_LISTS,
        url: `/setup/get-data/designation`,
        key: 'name',
        label: 'name',
        label2: '',
        listFn: (row: any) => {
          return row?.[0]?.childs || []
        }
      },
      data: [],
      dependField: 'type',
      dependValue: 'main-training',
      condition: '!==',
    },

    // {
    //   positionInForm: 4,
    //   label: 'Co-guide',
    //   name: 'co_guide_id',
    //   type: 'select',
    //   default: [],
    //   isMulti: true,
    //   isRequired: true,
    //   validation: Yup.array()
    //     .ensure()
    //     .required('Co-guide is required!')
    //     .min(1, 'Select Minimum 1.')
    //     .max(3, 'Select Maximum 3'),
    //   showInForm: true,
    //
    //   showInSearch: true,
    //   colSize: 4,
    //   dataApi: {
    //     url: '/supervisors',
    //     key: 'id',
    //     label: 'name',
    //   },
    //   data: [],
    // },
  },

};

export default formObj;
