import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Progress } from 'reactstrap';
import { Button } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import {
  useGetTrainingSummaryQuery,
  useGetUserSlotInfoForTraineeQuery,
  useLazyGetTrainingSummaryByIdQuery,
} from 'src/rtk/features/logbookTrainingSummary/logbookTrainingSummaryApi';
import { useGetPersonalProfileQuery } from 'src/rtk/features/trainee/traineeApi';
import loader from '../../assets/images/loader.gif';

export const SummaryOfAcademicActivities = () => {
  const { userId, sessionId } = useParams<{ userId: string; sessionId: string }>();
  const [parentTrainingSummary, setParentTrainingSummary] =
    useState<LogbookTrainingSummary>({} as LogbookTrainingSummary);
  const [trainingSummaryList, setTrainingSummaryList] = useState<
    LogbookTrainingSummary[]
  >([]);

  const history = useHistory();

  const {
    data: personalProfile,
    isError: isPersonalProfileError,
    isLoading: isPersonalProfileLoading
  } = useGetPersonalProfileQuery();

  const {
    data: userSlotData,
    isLoading: isUserSlotDataLoading,
    isSuccess: isUserSlotDataSuccess,
    isError: isUserSlotDataError,
    error: userSlotDataError,
  } = useGetUserSlotInfoForTraineeQuery(userId ? userId : (personalProfile?.data as any)?.id!);

  const {
    data: trainingSummaries,
    isLoading: isTrainingSummariesLoading,
    isSuccess: isTrainingSummariesSuccess,
  } = useGetTrainingSummaryQuery({
    user_id: userId ? userId : (personalProfile?.data as any)?.id!,
    session_id: sessionId ? sessionId : userSlotData?.data?.log_entry_current_session?.id!
  });

  const [
    getTrainingSummaryById,
    {
      data: trainingSummary,
      isLoading: isTrainingSummaryLoading,
      isSuccess: isTrainingSummarySuccess,
      isFetching
    },
  ] = useLazyGetTrainingSummaryByIdQuery();

  useEffect(() => {
    if (trainingSummaries) {
      const tData = trainingSummaries?.data || [];
      const parentTraining = tData?.find(
        (t: LogbookTrainingSummary) => t.particulars === 'Academic Activity',
      )!;
      setParentTrainingSummary(parentTraining);
    }
  }, [trainingSummaries]);

  useEffect(() => {
    if (parentTrainingSummary) {
      getTrainingSummaryById({ id: parentTrainingSummary.id!, userId: userId ? userId : (personalProfile?.data as any)?.id!, sessionId: sessionId ? sessionId : userSlotData?.data?.log_entry_current_session?.id! });
    }
  }, [parentTrainingSummary]);

  useEffect(() => {
    if (trainingSummary && isTrainingSummarySuccess) {
      const tData = trainingSummary?.data || [];
      setTrainingSummaryList(tData);
    }
  }, [trainingSummary, isTrainingSummarySuccess]);

  const columns: TableColumn<any>[] = [
    {
      name: 'Sl. No.',
      cell: (row, index) => index + 1,
      width: '80px',
    },

    {
      name: 'Events/Tasks',
      selector: (row) => row?.particulars,
      sortable: false,
      wrap: true,
    },

    {
      name: 'Minimum to be Performed',
      selector: (row) => row?.log_entry_target![0]?.min_value,
      sortable: false,
      center: true,
    },

    {
      name: 'Total Performed',
      selector: (row) => row?.total,
      sortable: false,
      center: true,
    },

    {
      name: 'Percent Performed',
      width: '200px',
      cell: (row) => {
        const percentageValue: number = +row?.log_entry_target![0]?.min_value
          ? (row?.total * 100) / +row?.log_entry_target![0]?.min_value
          : 0;
        return (
          <div style={{ width: '900px', position: 'relative' }}>
            <Progress
              color={
                percentageValue < 50
                  ? 'danger'
                  : percentageValue <= 99
                    ? 'warning'
                    : 'success'
              }
              value={percentageValue}
            >
              <div
                style={{
                  position: 'absolute',
                  textAlign: 'center',
                  lineHeight: '10px',
                  color: 'black',
                  overflow: 'hidden',
                  right: 0,
                  left: 0,
                  top: 0,
                }}
              >
                {percentageValue.toFixed(2)}%
              </div>
            </Progress>
          </div>
        );
      },
    },

    {
      name: 'Action',
      cell: (row) => (
        <>

          <Button
            size="sm"
            color="info"
            type="button"
            text={`View`}
            onClick={() => {
              history.push(
                `/trainee/logbook-entry/academic-activities/${row?.particulars?.replaceAll('/', '%2F').replaceAll(',', '%2C').replaceAll('.', '%2E')}`,
              );
            }}
          />

        </>
      ),

      // ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div className="page-content">
      {isTrainingSummariesLoading || isFetching ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb
          title="Summary of Academic Activities"
          breadcrumbItem={
            [
              { link: '/trainee/logbook/academic-activities', name: 'Academic Activities Entry' },
            ]
          }
        />
        <div className="row align-items-center justify-content-center">
          <div className="card">
            <div className="card-body">
              <DataTable
                columns={columns}
                data={trainingSummaryList}
                // progressPending={isTrainingSummaryLoading}
                expandOnRowClicked
                highlightOnHover
                responsive
                persistTableHead
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
