import React from 'react';
import ReactDOM from 'react-dom';
import { PDFViewer } from '@react-pdf/renderer';
import { SummaryReports } from './SummaryReports';

export const PDFDocument = () => (
  <div className='page-content'>
    {/* <PDFViewer> */}
        {/* <SummaryReports /> */}
    {/* </PDFViewer> */}
  </div>  
);
