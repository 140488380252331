import { apiSlice } from 'src/rtk/api/apiSlice';
import * as departmentUrl from 'src/helpers/url_helper';

export const departmentApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['Departments'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDepartments: builder.query<APIResponse<Department[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${departmentUrl.GET_DEPARTMENTS}`,
        }),
        providesTags: ['Departments'],
      }),

      getDepartmentsByPaginate: builder.query<
        APIResponseWithPagination<Department>,
        PaginateForLogbookEntries
      >({
        query: (paginate) => {
          let url = `${departmentUrl.GET_DEPARTMENTS_BY_PAGINATE}?`;

          if (paginate.page) {
            url += `page=${paginate.page}&`;
          }
          if (paginate.paginate_per_page) {
            url += `paginate_per_page=${paginate.paginate_per_page}`;
          }
          if (paginate.search) {
            url += `${paginate.search}`;
          }

          return {
            method: 'GET',
            url: url,
          };
        },

        providesTags: ['Departments'],
      }),

      getDepartmentsByInstitute: builder.query<APIResponse<Department[]>, string>({
        query: (name: string) => ({
          method: 'GET',
          url: `${departmentUrl.GET_DEPARTMENTS_BY_INSTITUTE}?organization_name=${name}`,
        }),
        providesTags: ['Departments'],
      }),

      getDepartment: builder.query<Department, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${departmentUrl.GET_DEPARTMENT}/${id}`,
        }),
        providesTags: ['Departments'],
      }),

      createDepartment: builder.mutation<APIResponse<Department>, Department>({
        query: (data) => ({
          url: `${departmentUrl.CREATE_DEPARTMENT}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['Departments'],
      }),

      updateDepartment: builder.mutation<APIResponse<Department>, string>({
        query: (url) => ({
          url: `${departmentUrl.UPDATE_DEPARTMENT}/${url}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['Departments'],
      }),
      
      deleteDepartment: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${departmentUrl.DELETE_DEPARTMENT}/${id}`,
        }),
        invalidatesTags: ['Departments'],
      }),
    }),
  });

export const {
  useGetDepartmentsQuery,
  useGetDepartmentsByPaginateQuery,
  useGetDepartmentsByInstituteQuery,
  useLazyGetDepartmentsByInstituteQuery,
  useGetDepartmentQuery,
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
} = departmentApi;
