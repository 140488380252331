import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { Card, CardBody, Container, Form, Label } from "reactstrap";
import { InputField } from "src/components";
import BetterBreadcrumb from "src/components/Common/BetterBreadCrumb";
import { useGetLookupDataQuery } from "src/rtk/features/setup/lookup/lookupDataApi";
import { useCreatePolicySetupMasterMutation, useGetPolicySetupDataQuery } from 'src/rtk/features/setup/policy/policyApi';
import * as Yup from 'yup';

const PolicySetup = () => {
    const { data: degreeLookupData } = useGetLookupDataQuery('educational_qualification');
    const { data: designationLookupData } = useGetLookupDataQuery('designation');
    const { data, isSuccess, error, isLoading, isError } = useGetPolicySetupDataQuery();

    const degreeList: Lookup[] = (degreeLookupData as any)?.data[0]?.childs || [];
    const [createPolicySetupMaster, { isSuccess: isCreateSuccess, isError: isCreateError, error: createError }] = useCreatePolicySetupMasterMutation();
    const err: CustomError = createError as CustomError;

    const allDegree: SelectOption[] =
        degreeList?.map((item) => ({
            value: item.name,
            label: item.name,
        })) || [];

    const allDesignation: SelectOption[] =
        designationLookupData?.data[0]?.childs?.map((item) => ({
            value: item.name,
            label: item.name,
        })) || [];

    const allSlot = [
        { value: '1 st Year (Slot-1)', label: '1 st Year (Slot-1)' },
        { value: '1 st Year (Slot-2)', label: '1 st Year (Slot-2)' },
        { value: '2 nd Year (Slot-1)', label: '2 nd Year (Slot-1)' },
        { value: '2 nd Year (Slot-2)', label: '2 nd Year (Slot-2)' },
        { value: '3 rd Year (Slot-1)', label: '3 rd Year (Slot-1)' },
        { value: '3 rd Year (Slot-2)', label: '3 rd Year (Slot-2)' },
    ];

    const [optionSelected, setOptionSelected] = useState<string>('');
    const [allowedDevice, setAllowedDevice] = useState<number>(1);
    const [trainingRecord, setTrainingRecord] = useState<string>('');
    const [selectedProgramType, setSelectedProgramType] = useState<any>('');
    const [allProgramCore, setAllProgramCore] = useState<any>('');
    const [allProgramAdvance, setAllProgramAdvance] = useState<any>('');

    const [selectedDegreeOne, setSelectedDegreeOne] = useState<any>('');
    const [selectedDegreeTwo, setSelectedDegreeTwo] = useState<any>('');
    const [selectedDegreeThree, setSelectedDegreeThree] = useState<any>('');

    const [selectedTraining, setSelectedTraining] = useState<any>('');
    const [selectedExam, setSelectedExam] = useState<any>('');

    const [selectedGuideCoGuideDegree, setSelectedGuideCoGuideDegree] = useState<any>([]);
    const [selectedDesignation, setSelectedDesignation] = useState<any>('');


    const [datas, setDatas] = useState<any>([]);

    const [dataArrayOne, setDataArrayOne] = useState<any>([]);
    const [dataArrayTwo, setDataArrayTwo] = useState<any>([]);
    const [dataArrayThree, setDataArrayThree] = useState<any>([]);


    const initialValuesOne = {
        policy_setup_master_id: '',
        policy_key: '',
        value_1: '',
        value_2: '',
    };

    const {
        handleSubmit: handleFormOneSubmit,
        getFieldProps: getFieldPropsOne,
        handleChange: handleChangeOne,
        values: valuesOne,
        resetForm: resetFormOne,
        setFieldValue: setFieldValueOne,
    } = useFormik({
        initialValues: initialValuesOne,

        validationSchema: Yup.object({
        }),

        onSubmit: (values: any) => {

            values.policy_setup_master_id = data?.data?.[0]?.id;
            values.policy_key = 'restriction';
            values.value_1 = optionSelected;
            values.value_2 = optionSelected === 'On' ? allowedDevice : '';

            const newDataArray = [];
            const newDatas = [];

            newDataArray[0] = values;
            newDatas[0] = { data_array: newDataArray };
            createPolicySetupMaster({ datas: newDatas });
        },
    });

    const initialValuesTwo = {
        policy_setup_master_id: '',
        policy_key: '',
        value_1: '',
        value_2: '',
    };

    const {
        handleSubmit: handleFormTwoSubmit,
        getFieldProps: getFieldPropsTwo,
        handleChange: handleChangeTwo,
        values: valuesTwo,
        resetForm: resetFormTwo,
        setFieldValue: setFieldValueTwo,
    } = useFormik({
        initialValues: initialValuesTwo,

        validationSchema: Yup.object({
        }),

        onSubmit: (values: any) => {
            const newDatas = [];

            newDatas[0] = { data_array: dataArrayTwo };
            createPolicySetupMaster({ datas: newDatas });
        },
    });

    const initialValuesThree = {
        policy_setup_master_id: '',
        policy_key: '',
        value_1: '',
    };

    const {
        handleSubmit: handleFormThreeSubmit,
        getFieldProps: getFieldPropsThree,
        handleChange: handleChangeThree,
        values: valuesThree,
        resetForm: resetFormThree,
        setFieldValue: setFieldValueThree,
    } = useFormik({
        initialValues: initialValuesThree,

        validationSchema: Yup.object({
        }),

        onSubmit: (values: any) => {
            const newDatas = [];

            newDatas[0] = { data_array: dataArrayThree };
            createPolicySetupMaster({ datas: newDatas });
        },
    });

    useEffect(() => {
        if (selectedProgramType?.value) {
            selectedProgramType?.value === 'Core' ?
                allSlot?.filter((item: any, index: number) => {
                    if (index < 4) {
                        index === 0 ? setAllProgramCore((prev: any) => prev + item?.value) : setAllProgramCore((prev: any) => prev + ',' + item?.value);
                        setAllProgramAdvance('');
                    }
                })
                : selectedProgramType?.value === 'Advance' ?
                    allSlot?.filter((item: any, index: number) => {
                        index === 0 ? setAllProgramAdvance((prev: any) => prev + item?.value) : setAllProgramAdvance((prev: any) => prev + ',' + item?.value);
                        setAllProgramCore('');
                    })
                    : ''
        }
        else {
            setAllProgramCore('');
            setAllProgramAdvance('');
        }
    }, [selectedProgramType]);


    // useEffect(() => {
    //     if (data?.data) {
    //         const newData = [...dataArrayTwo];
    //         newData[0] = {
    //             policy_setup_master_id: data?.data?.[1]?.id,
    //             policy_key: selectedDegreeOne?.value,
    //             value_1: selectedProgramType?.value,
    //             value_2: selectedProgramType?.value === 'Core' ? allProgramCore : allProgramAdvance,
    //         }
    //         setDataArrayTwo(newData);
    //     }
    // }, [selectedDegreeOne, selectedProgramType, allProgramCore, allProgramAdvance, data]);


    // useEffect(() => {
    //     if (data?.data) {
    //         const newData = [...dataArrayTwo];
    //         newData[1] = {
    //             policy_setup_master_id: data?.data?.[1]?.id,
    //             policy_key: selectedDegreeTwo?.value,
    //             value_1: selectedTraining?.value,
    //         }
    //         setDataArrayTwo(newData);
    //     }
    // }, [selectedDegreeTwo, selectedTraining, data]);

    // useEffect(() => {
    //     if (data?.data) {
    //         const newData = [...dataArrayTwo];
    //         newData[2] = {
    //             policy_setup_master_id: data?.data?.[1]?.id,
    //             policy_key: selectedDegreeThree?.value,
    //             value_1: selectedExam?.value,
    //         }
    //         setDataArrayTwo(newData);
    //     }
    // }, [selectedDegreeThree, selectedExam, data]);

    useEffect(() => {
        if (data?.data) {
            const newData = [...dataArrayTwo];
            newData[0] = {
                policy_setup_master_id: data?.data?.[1]?.id,
                policy_key: selectedDegreeOne?.value,
                value_1: selectedProgramType?.value,
                value_2: selectedProgramType?.value === 'Core' ? allProgramCore : allProgramAdvance,
            }
            newData[1] = {
                policy_setup_master_id: data?.data?.[1]?.id,
                policy_key: selectedDegreeTwo?.value,
                value_1: selectedTraining?.value,
            }
            newData[2] = {
                policy_setup_master_id: data?.data?.[1]?.id,
                policy_key: selectedDegreeThree?.value,
                value_1: selectedExam?.value,
            }
            setDataArrayTwo(newData);
        }
    }, [selectedDegreeOne, selectedProgramType, allProgramCore, allProgramAdvance, selectedDegreeTwo, selectedTraining, selectedDegreeThree, selectedExam, data?.data])

    // useEffect(() => {
    //     if (data?.data) {
    //         const newData = [...dataArrayThree];
    //         newData[0] = {
    //             policy_setup_master_id: data?.data?.[2]?.id,
    //             policy_key: 'degree',
    //             value_1: selectedGuideCoGuideDegree !== '' ? selectedGuideCoGuideDegree?.map((item: any) => item.value).toString() : '',
    //         }
    //         setDataArrayThree(newData);
    //     }

    // }, [selectedGuideCoGuideDegree, data]);

    // useEffect(() => {
    //     if (data?.data) {
    //         const newData = [...dataArrayThree];
    //         newData[1] = {
    //             policy_setup_master_id: data?.data?.[2]?.id,
    //             policy_key: 'training',
    //             value_1: trainingRecord,
    //         }
    //         setDataArrayThree(newData);
    //     }

    // }, [trainingRecord, data]);

    // useEffect(() => {
    //     if (data?.data) {
    //         const newData = [...dataArrayThree];
    //         newData[2] = {
    //             policy_setup_master_id: data?.data?.[2]?.id,
    //             policy_key: 'designation',
    //             value_1: selectedDesignation?.value,
    //         }
    //         setDataArrayThree(newData);
    //     }

    // }, [selectedDesignation, data]);


    useEffect(() => {
        if (data?.data) {
            const newData = [...dataArrayThree];
            newData[0] = {
                policy_setup_master_id: data?.data?.[2]?.id,
                policy_key: 'degree',
                value_1: selectedGuideCoGuideDegree !== '' ? selectedGuideCoGuideDegree?.map((item: any) => item.value).toString() : '',
            }
            newData[1] = {
                policy_setup_master_id: data?.data?.[2]?.id,
                policy_key: 'training',
                value_1: trainingRecord,
            }
            newData[2] = {
                policy_setup_master_id: data?.data?.[2]?.id,
                policy_key: 'designation',
                value_1: selectedDesignation?.value,
            }
            setDataArrayThree(newData);
        }

    }, [selectedGuideCoGuideDegree, trainingRecord, selectedDesignation, data?.data]);

    useEffect(() => {
        if (isCreateSuccess) {
            toast.success('Setup Updated Successfully!');
        }
        if (isCreateError) {
            toast.error(err?.message!);
        }
    }, [isCreateSuccess, isCreateError]);

    useEffect(() => {
        if (data?.data && isSuccess) {
            const arr = data?.data?.[2]?.policy_setup_details?.[0]?.value_1 ? data?.data?.[2]?.policy_setup_details?.[0]?.value_1?.split(',') : [];
            //Form One

            setOptionSelected(data?.data?.[0]?.policy_setup_details?.[0]?.value_1 ?? '');
            setAllowedDevice(data?.data?.[0]?.policy_setup_details?.[0]?.value_2 ?? '');

            //Form Two part one

            data?.data?.[1]?.policy_setup_details?.[0]?.value_1 ?
                setSelectedProgramType({
                    value: data?.data?.[1]?.policy_setup_details?.[0]?.value_1,
                    label: data?.data?.[1]?.policy_setup_details?.[0]?.value_1,
                }) :
                setSelectedProgramType('');

            data?.data?.[1]?.policy_setup_details?.[0]?.policy_key ?
                setSelectedDegreeOne({
                    value: data?.data?.[1]?.policy_setup_details?.[0]?.policy_key,
                    label: data?.data?.[1]?.policy_setup_details?.[0]?.policy_key,
                }) :
                setSelectedDegreeOne('');

            //Form Two part two

            data?.data?.[1]?.policy_setup_details?.[1]?.policy_key ?
                setSelectedDegreeTwo({
                    value: data?.data?.[1]?.policy_setup_details?.[1]?.policy_key,
                    label: data?.data?.[1]?.policy_setup_details?.[1]?.policy_key,
                }) :
                setSelectedDegreeTwo('');

            data?.data?.[1]?.policy_setup_details?.[1]?.value_1 ?
                setSelectedTraining({
                    value: data?.data?.[1]?.policy_setup_details?.[1]?.value_1,
                    label: data?.data?.[1]?.policy_setup_details?.[1]?.value_1,
                }) :
                setSelectedTraining('');

            //Form Two part three
            data?.data?.[1]?.policy_setup_details?.[2]?.policy_key ?
                setSelectedDegreeThree({
                    value: data?.data?.[1]?.policy_setup_details?.[2]?.policy_key,
                    label: data?.data?.[1]?.policy_setup_details?.[2]?.policy_key,
                }) :
                setSelectedDegreeThree('');
            data?.data?.[1]?.policy_setup_details?.[2]?.value_1 ?
                setSelectedExam({
                    value: data?.data?.[1]?.policy_setup_details?.[2]?.value_1,
                    label: data?.data?.[1]?.policy_setup_details?.[2]?.value_1,
                }) :
                setSelectedExam('');

            //Form Three

            setSelectedGuideCoGuideDegree(arr?.map((item: any) => ({ value: item, label: item })));

            setTrainingRecord(data?.data?.[2]?.policy_setup_details?.[1]?.value_1);

            data?.data?.[2]?.policy_setup_details?.[2]?.value_1 ?
                setSelectedDesignation({
                    value: data?.data?.[2]?.policy_setup_details?.[2]?.value_1,
                    label: data?.data?.[2]?.policy_setup_details?.[2]?.value_1,
                }) :
                setSelectedDesignation('');
        }

    }, [data, isSuccess])

    return (
        <div className="page-content">
            <Container fluid>
                <BetterBreadcrumb
                    breadcrumbItem={[{ link: '/', name: 'Policy Setup' }]}
                    title={'Policy Setup'}
                />
                <Card>
                    <CardBody>
                        {data?.data?.map((item: any, index: number) => (
                            <div key={item.id}>
                                <div>
                                    {index === 0 &&
                                        <>
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    handleFormOneSubmit();
                                                }}
                                                onReset={(e) => {
                                                    resetFormOne();
                                                }}
                                            >
                                                <h5 className="block-heading">Device Restriction</h5>
                                                <div className="row mt-3">
                                                    <div className='col-3'>
                                                        <div className="d-inline-block mx-2">
                                                            <div className="form-check form-check-left mb-3">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="selected"
                                                                    id="optionYes"
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            setOptionSelected('On');
                                                                        }
                                                                    }}
                                                                    value={"On"}
                                                                    checked={optionSelected === 'On'}
                                                                />
                                                                <label className="form-check-label" htmlFor="optionYes">On</label>
                                                            </div>
                                                        </div>

                                                        <div className="d-inline-block mx-2">
                                                            <div className="form-check form-check-left">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="selected"
                                                                    id="optionNo"
                                                                    value={"Off"}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            setOptionSelected('Off');
                                                                        }
                                                                    }}
                                                                    checked={optionSelected === 'Off'}
                                                                />
                                                                <label className="form-check-label" htmlFor="optionNo">Off</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {optionSelected === 'On' &&
                                                        <div className="col-3">
                                                            <Label for="allow" className="form-label">Number of Device Allow</Label>
                                                            <div>
                                                                <InputField
                                                                    type="number"
                                                                    name='allow'
                                                                    min={'1'}
                                                                    placeholder='Allowed Device'
                                                                    value={allowedDevice}
                                                                    onChange={(e) => setAllowedDevice(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>}
                                                </div>
                                                <div className='d-flex justify-content-end'>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary'
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </Form>
                                        </>
                                    }
                                    {index === 1 &&
                                        <>
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    handleFormTwoSubmit();
                                                }}
                                                onReset={(e) => {
                                                    resetFormTwo();
                                                }}
                                            >
                                                <h5 className="block-heading">Training Examption</h5>
                                                <div>
                                                    <div className="row mt-3">
                                                        <div className="col-3">
                                                            <Select
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                options={allDegree}
                                                                placeholder='Select Degree'
                                                                name="name_of_the_degree"
                                                                value={selectedDegreeOne}
                                                                onChange={setSelectedDegreeOne}
                                                            />
                                                        </div>

                                                        <div className="col-3">
                                                            <Select
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                options={[
                                                                    { value: 'Core', label: 'Core' },
                                                                    { value: 'Advance', label: 'Advance' },
                                                                ]}
                                                                value={selectedProgramType}
                                                                onChange={setSelectedProgramType}
                                                                placeholder='Program Type'
                                                                name="slot"
                                                            />
                                                        </div>

                                                        <div className="col-6">
                                                            <Select
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                isMulti={true}
                                                                options={allSlot}
                                                                value={
                                                                    selectedProgramType?.value === 'Core' ?
                                                                        allSlot?.filter((item: any, index: number) => (item?.value === '1 st Year (Slot-1)' || item?.value === '1 st Year (Slot-2)'
                                                                            || item?.value === '2 nd Year (Slot-1)' || item?.value === '2 nd Year (Slot-2)'))
                                                                        : selectedProgramType?.value === 'Advance' ? allSlot : ''
                                                                }
                                                                placeholder='Select Slot'
                                                                name="slot"
                                                                isDisabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row mt-3'>
                                                        <div className="col-3">
                                                            <Select
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                options={allDegree}
                                                                value={selectedDegreeTwo}
                                                                onChange={setSelectedDegreeTwo}
                                                                placeholder='Select Degree'
                                                                name="name_of_the_degree"
                                                            />
                                                        </div>

                                                        <div className="col-3">
                                                            <Select
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                options={[
                                                                    { value: 'Mandatory Workshop', label: 'Mandatory Workshop' },
                                                                    { value: 'Mandatory Training', label: 'Mandatory Training' },
                                                                ]}
                                                                value={selectedTraining}
                                                                onChange={setSelectedTraining}
                                                                placeholder='Select Training'
                                                                name="mandatory"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row mt-3'>
                                                        <div className="col-3">
                                                            <Select
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                options={allDegree}
                                                                value={selectedDegreeThree}
                                                                onChange={setSelectedDegreeThree}
                                                                placeholder='Select Degree'
                                                                name="name_of_the_degree"
                                                            />
                                                        </div>

                                                        <div className="col-3">
                                                            <Select
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                options={[
                                                                    { value: 'Midterm', label: 'Midterm' },
                                                                ]}
                                                                value={selectedExam}
                                                                onChange={setSelectedExam}
                                                                placeholder='Select Exam'
                                                                name="exam"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='d-flex justify-content-end'>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary'
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </>
                                    }
                                    {index === 2 &&
                                        <>
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    handleFormThreeSubmit();
                                                }}
                                                onReset={(e) => {
                                                    resetFormThree();
                                                }}
                                            >
                                                <div className="mt-3">
                                                    <h5 className='block-heading'>Guide/Co-Guide Eligibility Criteria</h5>
                                                </div>
                                                <div className="row mt-3">
                                                    <Label className='col-2'>Degree</Label>
                                                    <div className='col-3'>
                                                        <Select
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            isMulti={true}
                                                            options={allDegree}
                                                            value={selectedGuideCoGuideDegree}
                                                            onChange={setSelectedGuideCoGuideDegree}
                                                            placeholder='Select Degree'
                                                            name="name_of_the_degree"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <Label className='col-2'>Training Record</Label>
                                                    <div className='col-3'>
                                                        {/* <Select
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        options={allDegree}
                                                        placeholder='Select Training Record'
                                                        name="training_record"
                                                    /> */}
                                                        <InputField
                                                            type="text"
                                                            name='training_record'
                                                            placeholder='Select Training Record'
                                                            value={trainingRecord}
                                                            onChange={(e: any) => setTrainingRecord(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <Label className='col-2'>Job Title</Label>
                                                    <div className='col-3'>
                                                        <Select
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            options={allDesignation}
                                                            value={selectedDesignation}
                                                            onChange={setSelectedDesignation}
                                                            placeholder='Select Designation'
                                                            name="designation"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-3 d-flex justify-content-end'>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary'
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </Form>
                                        </>
                                    }
                                </div>
                            </div>
                        ))}
                    </CardBody>
                </Card>
            </Container>
        </div >
    );
}

export default PolicySetup;