import { useFormik } from "formik";
import FacultyPersonalInformation from "./FacultyPersonalInformation";
import FacultyProfessionalEducationInfo from "./FacultyProfessionalEducationInfo";
import FacultyTrainingRecord from "./FacultyTrainingRecord";

const FacultyRegistrationMain = () => {
  const facultyForm: any = useFormik({
    initialValues: {
      regNo: "",
      regValidity: "",
      regCertificate: "",
      verificationStatus: true,
      regName: "",
      regNid: "",
      regPhone: "",
      regEmail: "",
      userType: 0,
      username: "",
      password: "",
      confirmPassword: "",
      name: "",
      fatherOrHusbandName: "",
      motherName: "",
      dateOfBirth: "",
      nid: "",
      phone: "",
      email: "",
      tel: "",
      photo: "",
      signature: "",
      presentDivision: "",
      presentDistrict: "",
      presentPS: "",
      presentAddress: "",
      permanentDivision: "",
      permanentDistrict: "",
      permanentPS: "",
      permanentAddress: "",
      degree: "",
      institute: "",
      year: "",
      certificate: "",
      roll: "",
      session: "",
      subject: "",
      designation: "",
      workingPlace: "",
      TrainingInstitute: "",
      supervisor: "",
      supervisorDesignation: "",
      supervisorPostGraduateQualification: "",
      departmentId: "",
      duration: "",
      document: "",
    },
    // validationSchema: Yup.object({
    //   name: Yup.string().required("Name is required"),
    //   fatherOrHusbandName: Yup.string().required(
    //     "Father/Husband name is required"
    //   ),
    //   motherName: Yup.string().required("Mother name is required"),
    //   dateOfBirth: Yup.string().required("Date of birth is required"),
    //   nid: Yup.string().required("NID is required"),
    //   phone: Yup.string(),
    // }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <div className="page-content">
      {/* <FacultyRegistration facultyRegForm={facultyForm} /> */}
      <FacultyPersonalInformation facultyPersonalInfoForm={facultyForm} />
      <hr />
      <FacultyProfessionalEducationInfo degreeForm={facultyForm} />
      <hr />
      <FacultyTrainingRecord />

      <div className="text-center mb-3">
        <button
          type="submit"
          className="btn btn-primary w-25"
          onSubmit={(e) => {
            e.preventDefault;
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default FacultyRegistrationMain;
