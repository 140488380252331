import { apiSlice } from 'src/rtk/api/apiSlice';
import * as logBookEntrySetupMasterUrl from 'src/helpers/url_helper';

export const logBookEntrySetupMasterApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['logBookEntrySetupMasters'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLogbookEntrySetupMasters: builder.query<APIResponse<LogbookEntrySetupMaster[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${logBookEntrySetupMasterUrl.GET_LOGBOOK_ENTRY_SETUP_MASTERS}`,
        }),
        providesTags: ['logBookEntrySetupMasters'],
      }),

      getLogbookEntrySetupMaster: builder.query<APIResponse<LogbookEntrySetupMaster>, string>({
        query: (id) => ({
          url: `${logBookEntrySetupMasterUrl.GET_LOGBOOK_ENTRY_SETUP_MASTER}/${id}`,
          method: 'GET',
        }),
        providesTags: ['logBookEntrySetupMasters'],
      }),

      createLogbookEntrySetupMaster: builder.mutation<APIResponse<LogbookEntrySetupMaster>, LogbookEntrySetupMaster>({
        query: (data) => ({
          url: `${logBookEntrySetupMasterUrl.CREATE_LOGBOOK_ENTRY_SETUP_MASTER}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['logBookEntrySetupMasters'],
      }),

      updateLogbookEntrySetupMaster: builder.mutation<APIResponse<LogbookEntrySetupMaster>, string>({
        query: (url) => ({
          url: `${logBookEntrySetupMasterUrl.UPDATE_LOGBOOK_ENTRY_SETUP_MASTER}` + url,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['logBookEntrySetupMasters'],
      }),

      deleteLogbookEntrySetupMaster: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${logBookEntrySetupMasterUrl.DELETE_LOGBOOK_ENTRY_SETUP_MASTER}/${id}`,
        }),
        invalidatesTags: ['logBookEntrySetupMasters'],
      }),
    }),
  });

export const {
  useCreateLogbookEntrySetupMasterMutation,
  useGetLogbookEntrySetupMasterQuery,
  useGetLogbookEntrySetupMastersQuery,
  useUpdateLogbookEntrySetupMasterMutation,
  useDeleteLogbookEntrySetupMasterMutation,
} = logBookEntrySetupMasterApi;
