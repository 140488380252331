import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { Button as BsButton, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Modal, ModalBody, Row } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import { setFromErrors } from 'src/helpers';
import { useDeleteAcademicStaffInfoMutation, useDeleteAcademicStaffWorkloadMutation, useGetAcademicStaffInfoListQuery, useGetAcademicStaffWorkloadsListQuery, useGetDepartmentByIdQuery, useGetPartCMasterInfoEntryQuery, usePartCMasterInfoEntryMutation } from 'src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi';
import * as Yup from 'yup';
import AcademicStaffRecordsModal from './AcademicStaffRecordsModal';
import WorkLoadOfAcademicStaffModal from './WorkLoadOfAcademicStaffModal';

const initialValues: PartCMasterInfoEntry = {
    part_c_master_info_id: '',
    form_b_department_id: '',
    number_of_teaching_unit: 0,
    number_of_beds_of_each_teaching_unit: 0,
};

const InstituteDeptRegistrationStepFive = (props: any) => {
    const { goToNext, goToPrevious, instituteName, departmentId, formBDeptId, isDisabled, renewDepartment } = props;
    const [showRecordOfAcademicStaffModal, setShowRecordOfAcademicStaffModal] = useState<boolean>(false);
    const [showWorkLoadOfAcademicStaffModal, setShowWorkLoadOfAcademicStaffModal] = useState<boolean>(false);
    const [academicStaffRecordInModal, setAcademicStaffRecordInModal] = useState<AcademicStaffInfoList>({} as AcademicStaffInfoList);
    const [showAcademicStaffRecordViewModal, setShowAcademicStaffRecordViewModal] = useState<boolean>(false);
    const [academicStaffWorkloadRecordInModal, setAcademicStaffWorkloadRecordInModal] = useState<WorkloadStaffEntryList>({} as WorkloadStaffEntryList);
    const [showAcademicStaffWorkloadRecordViewModal, setShowAcademicStaffWorkloadRecordViewModal] = useState<boolean>(false);
    const [recordOfAcademicStaffForDelete, setRecordOfAcademicStaffForDelete] = useState<AcademicStaffInfoList>({} as AcademicStaffInfoList);
    const [recordOfAcademicStaffDeleteModalShow, setRecordOfAcademicStaffDeleteModalShow] = useState<boolean>(false);
    const [recordOfAcademicStaffWorkloadForDelete, setRecordOfAcademicStaffWorkloadForDelete] = useState<WorkloadStaffEntryList>({} as WorkloadStaffEntryList);
    const [recordOfAcademicStaffWorkloadDeleteModalShow, setRecordOfAcademicStaffWorkloadDeleteModalShow] = useState<boolean>(false);
    const [recordOfAcademicStaffDeleteEntry, setRecordOfAcademicStaffDeleteEntry] = useState<boolean>(false);
    const [recordOfAcademicStaffWorkloadDeleteEntry, setRecordOfAcademicStaffWorkloadDeleteEntry] = useState<boolean>(false);
    const [editAcademicStaff, setEditAcademicStaff] = useState<boolean>(false);
    const [academicStaffToEdit, setAcademicStaffToEdit] = useState<AcademicStaffInfoList>({} as AcademicStaffInfoList);
    const [editAcademicStaffWorkload, setEditAcademicStaffWorkload] = useState<boolean>(false);
    const [academicStaffWorkloadToEdit, setAcademicStaffWorkloadToEdit] = useState<WorkloadStaffEntryList>({} as WorkloadStaffEntryList);

    const [partCMasterInfoEntry, { data: partCMasterInfoData, isSuccess: isPartCMasterInfoDataSuccess, isLoading: isPartCMasterInfoDataLoading, isError: isPartCMasterInfoDataError, error: partCMasterInfoDataError }] =
        usePartCMasterInfoEntryMutation();

    const { data: partCMasterInfoGetData, isSuccess: isPartCMasterInfoGetDataSuccess, isError: isPartCMasterInfoGetDataError, error: partCMasterInfoGetDataError } = useGetPartCMasterInfoEntryQuery(departmentId);

    const { data: submittedDepartmentData, isSuccess: isSubmittedDepartmentDataSuccess, isError: isSubmittedDepartmentDataError, error: submittedDepartmentDataError } = useGetDepartmentByIdQuery(formBDeptId);

    const { data: academicStaffRecordList, isSuccess: isAcademicStaffRecordListSuccess, isError: isAcademicStaffRecordListError, error: academicStaffRecordListError } = useGetAcademicStaffInfoListQuery(departmentId);

    const { data: workloadOfAcademicStaffRecordList, isSuccess: isWorkloadOfAcademicStaffRecordListSuccess, isError: isWorkloadOfAcademicStaffRecordListError, error: workloadOfAcademicStaffRecordListError } = useGetAcademicStaffWorkloadsListQuery(departmentId);

    const [
        deleteAcademicStaffRecordEntry,
        {
            data: academicStaffRecordEntryDelete,
            isSuccess: isAcademicStaffRecordEntryDeleteSuccess,
            isError: isAcademicStaffRecordEntryDeleteError,
            error: academicStaffRecordEntryDeleteError,
        },
    ] = useDeleteAcademicStaffInfoMutation();

    const [
        deleteAcademicStaffWorkloadRecordEntry,
        {
            data: academicStaffWorkloadRecordEntryDelete,
            isSuccess: isAcademicStaffWorkloadRecordEntryDeleteSuccess,
            isError: isAcademicStaffWorkloadRecordEntryDeleteError,
            error: academicStaffWorkloadRecordEntryDeleteError,
        },
    ] = useDeleteAcademicStaffWorkloadMutation();

    document.title = renewDepartment === true ? 'Renew Department | BCPS' : 'Department Registration | BCPS';

    const handleEditAcademicStaffRecord = (e: any, academicStaffRecord: AcademicStaffInfoList) => {
        setEditAcademicStaff(true);
        setAcademicStaffToEdit(academicStaffRecord);
        setShowRecordOfAcademicStaffModal(true);
    };

    const handleEditAcademicStaffWorkloadRecord = (e: any, academicStaffWorkloadRecord: WorkloadStaffEntryList) => {
        setEditAcademicStaffWorkload(true);
        setAcademicStaffWorkloadToEdit(academicStaffWorkloadRecord);
        setShowWorkLoadOfAcademicStaffModal(true);
    };

    const handleAcademicStaffRecordView = (e: any, academicStaffRecord: AcademicStaffInfoList) => {
        setAcademicStaffRecordInModal(academicStaffRecord);
        setShowAcademicStaffRecordViewModal(true);
    };

    const handleAcademicStaffWorkloadRecordView = (e: any, academicStaffWorkloadRecord: WorkloadStaffEntryList) => {
        setAcademicStaffWorkloadRecordInModal(academicStaffWorkloadRecord);
        setShowAcademicStaffWorkloadRecordViewModal(true);
    };

    const academicStaffRecordDeleteHandler = (e: any, academicStaffRecord: AcademicStaffInfoList) => {
        setRecordOfAcademicStaffForDelete(academicStaffRecord);
        setRecordOfAcademicStaffDeleteModalShow(true);
    };

    const academicStaffWorkloadRecordDeleteHandler = (e: any, academicStaffWorkloadRecord: WorkloadStaffEntryList) => {
        setRecordOfAcademicStaffWorkloadForDelete(academicStaffWorkloadRecord);
        setRecordOfAcademicStaffWorkloadDeleteModalShow(true);
    };

    const err: CustomError = partCMasterInfoDataError as CustomError;

    const formik = useFormik({
        initialValues,

        validationSchema: Yup.object({}),

        onSubmit: (values: PartCMasterInfoEntry) => {
            console.log(values);
            values.form_b_department_id = departmentId;

            partCMasterInfoEntry(values);
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    useEffect(() => {
        if (partCMasterInfoGetData?.data?.form_b_department_id && !isPartCMasterInfoGetDataError) {
            const partCMasterInfo: GetPartCMasterInfo = partCMasterInfoGetData?.data!;
            setValues({
                part_c_master_info_id: partCMasterInfo?.id,
                form_b_department_id: partCMasterInfo?.form_b_department_id,
                number_of_teaching_unit: partCMasterInfo?.number_of_teaching_unit,
                number_of_beds_of_each_teaching_unit: partCMasterInfo?.number_of_beds_of_each_teaching_unit,
            });
        }
    }, [partCMasterInfoGetData, isPartCMasterInfoGetDataError]);

    useEffect(() => {
        if (isPartCMasterInfoDataSuccess) {
            handleFormReset();
            toast.success('Department information saved successfully');
            goToNext();
        }
        if (isPartCMasterInfoDataError) {
            toast.error(err?.message || 'Something went wrong');
            setFromErrors(err.data, setErrors);
        }
    }, [isPartCMasterInfoDataSuccess, isPartCMasterInfoDataError]);

    const recordOfAcademicStaffColumns: TableColumn<AcademicStaffInfoList>[] = [
        {
            name: <div className="text-center">Sl. No.</div>,
            selector: (row, index) => index! + 1,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Name</div>,
            selector: (row) => row.name ?? '-',
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Designation</div>,
            selector: (row) => row.designation ?? '-',
            sortable: false,
            wrap: true,
            center: true,
            width: '150px',
        },

        {
            name: <div className="text-center">Full/part time</div>,
            selector: (row) => row.job_type ?? '-',
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Qualification</div>,
            selector: (row) => row.qualification ?? '-',
            sortable: false,
            wrap: true,
            center: true,
            width: '150px',
        },

        {
            name: <div className="text-center">BMDC Reg. No.</div>,
            selector: (row) => row.bmdc_reg_no ?? '-',
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">BMDC Reg. No. validity</div>,
            selector: (row) => moment(row.bmdc_reg_validity).isValid() ? moment(row.bmdc_reg_validity).format('DD/MM/YYYY') : '-',
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Teaching experience in the rank of Asst. Prof. and above</div>,
            selector: (row) => row.teaching_experience ? row.teaching_experience + ' years' : '-',
            sortable: false,
            wrap: true,
            center: true,
            width: '150px',
        },

        {
            name: <div className="text-center">List of publications</div>,
            selector: (row) => row.list_of_publication ?? '-',
            cell: (row) =>
                <>
                    {typeof row.list_of_publication === 'string' && row.list_of_publication !== '' ? (
                        <a
                            className="me-2 p-0 center"
                            href={`${config?.APP_URL}${row.list_of_publication}`}
                            rel="noreferrer"
                            target="_blank"
                        >
                            <span className="btn-outline-info btn-sm btn ms-2">View</span>
                        </a>
                    ) : ''}
                </>,
            sortable: false,
            wrap: true,
            center: true,
            width: '150px',
        },

        {
            name: <div className="text-center">Action</div>,
            width: '200px',
            cell: (row) => (
                <>
                    <FormGroup className="btn-group mt-2">
                        <button
                            className="btn btn-sm btn-secondary"
                            style={isDisabled === true ? { pointerEvents: 'none' } : {}}
                            onClick={(e) => {
                                handleEditAcademicStaffRecord(e, row);
                            }}
                        >
                            <span className="fas fa-edit"></span> Edit
                        </button>
                        <Button
                            size="sm"
                            color="info"
                            type="button"
                            text={`<span class="fas fa-eye"></span> View`}
                            style={{ pointerEvents: 'auto' }}
                            onClick={(e) => {
                                handleAcademicStaffRecordView(e, row);
                            }}
                        />
                        {
                            <Button
                                size="sm"
                                color="danger"
                                type="button"
                                text={`<span class="fas fa-trash" ></span> Delete`}
                                style={isDisabled === true ? { pointerEvents: 'none' } : {}}
                                onClick={(e) => {
                                    academicStaffRecordDeleteHandler(e, row);
                                }}
                            />
                        }
                    </FormGroup>
                </>
            ),

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const workLoadOfAcademicStaffColumns: TableColumn<WorkloadStaffEntryList>[] = [
        {
            name: <div className="text-center">Sl. No.</div>,
            selector: (row, index) => index! + 1,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Duties</div>,
            selector: (row) => row.faculty!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Working hours (per week)</div>,
            selector: (row) => row.in_patient_care!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Action</div>,
            width: '200px',
            cell: (row) => (
                <>
                    <FormGroup className="btn-group mt-2">
                        <button
                            className="btn btn-sm btn-secondary"
                            style={isDisabled === true ? { pointerEvents: 'none' } : {}}
                            onClick={(e) => {
                                handleEditAcademicStaffWorkloadRecord(e, row);
                            }}
                        >
                            <span className="fas fa-edit"></span> Edit
                        </button>
                        <Button
                            size="sm"
                            color="info"
                            type="button"
                            text={`<span class="fas fa-eye"></span> View`}
                            style={{ pointerEvents: 'auto' }}
                            onClick={(e) => {
                                handleAcademicStaffWorkloadRecordView(e, row);
                            }}
                        />
                        {
                            <Button
                                size="sm"
                                color="danger"
                                type="button"
                                text={`<span class="fas fa-trash" ></span> Delete`}
                                style={isDisabled === true ? { pointerEvents: 'none' } : {}}
                                onClick={(e) => {
                                    academicStaffWorkloadRecordDeleteHandler(e, row);
                                }}
                            />
                        }
                    </FormGroup>
                </>
            ),

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (recordOfAcademicStaffDeleteEntry) {
            deleteAcademicStaffRecordEntry(recordOfAcademicStaffForDelete?.id!);
        }
    }, [recordOfAcademicStaffDeleteEntry]);

    useEffect(() => {
        if (!isAcademicStaffRecordEntryDeleteError && isAcademicStaffRecordEntryDeleteSuccess) {
            toast.dismiss();
            toast.success('Academic Staff Record Deleted Successfully');
            setRecordOfAcademicStaffDeleteModalShow(false);
            setRecordOfAcademicStaffDeleteEntry(false);
        } else if (isAcademicStaffRecordEntryDeleteError && !isAcademicStaffRecordEntryDeleteSuccess) {
            toast.dismiss();
            toast.error('Error while deleting the academic staff record');
        }
    }, [isAcademicStaffRecordEntryDeleteError, isAcademicStaffRecordEntryDeleteSuccess]);

    useEffect(() => {
        if (recordOfAcademicStaffWorkloadDeleteEntry) {
            deleteAcademicStaffWorkloadRecordEntry(recordOfAcademicStaffWorkloadForDelete?.id!);
        }
    }, [recordOfAcademicStaffWorkloadDeleteEntry]);

    useEffect(() => {
        if (!isAcademicStaffWorkloadRecordEntryDeleteError && isAcademicStaffWorkloadRecordEntryDeleteSuccess) {
            toast.dismiss();
            toast.success('Academic Staff Workload Entry Deleted Successfully');
            setRecordOfAcademicStaffWorkloadDeleteModalShow(false);
            setRecordOfAcademicStaffWorkloadDeleteEntry(false);
        } else if (isAcademicStaffWorkloadRecordEntryDeleteError && !isAcademicStaffWorkloadRecordEntryDeleteSuccess) {
            toast.dismiss();
            toast.error('Error while deleting the academic staff workload entry');
        }
    }, [isAcademicStaffWorkloadRecordEntryDeleteError, isAcademicStaffWorkloadRecordEntryDeleteSuccess]);

    const handleFormReset = () => {
        resetForm();
    };

    return (
        <div className="page-content">
            <Container fluid>
                <BetterBreadcrumb
                    title="Training Accreditation Application Form"
                    breadcrumbItem={[{ link: '/institute-registration-list', name: 'Department List' }]}
                />
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title mb-0 flex-grow-1">
                                    <span>Part-C: Faculty Information</span>
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={12}>
                                        <Row className='justify-content-evenly'>
                                            <Col sm={12} md={6}>
                                                <FormGroup className="col-sm-12 mb-3">
                                                    <Label for="institute_name">Institute Name</Label>
                                                    <InputField
                                                        id="institute_name"
                                                        name="institute_name"
                                                        type="text"
                                                        value={instituteName}
                                                        placeholder=""
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={12} md={6}>
                                                <FormGroup className="col-sm-12 mb-3">
                                                    <Label for="department_name">Name of the Department</Label>
                                                    <InputField
                                                        id="department_name"
                                                        name="department_name"
                                                        type="text"
                                                        value={submittedDepartmentData?.data?.department_name!}
                                                        placeholder=""
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col sm={12}>
                                        <fieldset className="border p-2">
                                            <legend className='w-auto' style={{ float: 'none', padding: 'inherit' }}>
                                                <span className='px-4 bg-success rounded-pill'><i className="fa-solid fa-7" style={{ color: '#f8f9fc' }}></i></span> Academic staff of the department
                                            </legend>

                                            <div className='filter-wrapper'>
                                                <FormGroup className="ms-auto justify-content-end">
                                                    <BsButton
                                                        type="button"
                                                        className="btn btn-success me-2"
                                                        style={isDisabled === true ? { pointerEvents: 'none' } : {}}
                                                        onClick={(e) => {
                                                            setEditAcademicStaff(false);
                                                            setAcademicStaffToEdit({} as AcademicStaffInfoList);
                                                            setShowRecordOfAcademicStaffModal(true);
                                                        }}
                                                    >
                                                        <i className="fa fa-plus me-2" /> Add Record
                                                    </BsButton>
                                                </FormGroup>
                                            </div>
                                            <DataTable
                                                columns={recordOfAcademicStaffColumns}
                                                data={academicStaffRecordList?.data?.length! > 0 ? academicStaffRecordList?.data! : []}
                                                persistTableHead={true}
                                                noDataComponent={<div className='border border-2 border-light border-top-0 p-5 text-center w-100'>There are no records to display</div>}
                                                expandOnRowClicked
                                                highlightOnHover
                                            />
                                        </fieldset>
                                    </Col>

                                    <Col sm={12}>
                                        <fieldset className="border p-2">
                                            <legend className='w-auto' style={{ float: 'none', padding: 'inherit' }}>
                                                <div className="badge rounded-pill d-inline-block  badge-approved font-size-24 fw-bold px-4">8</div> Work load of academic staff
                                            </legend>

                                            <div className='filter-wrapper'>
                                                <FormGroup className="ms-auto justify-content-end">
                                                    <BsButton
                                                        type="button"
                                                        className="btn btn-success me-2"
                                                        style={isDisabled === true ? { pointerEvents: 'none' } : {}}
                                                        onClick={(e) => {
                                                            setEditAcademicStaffWorkload(false);
                                                            setAcademicStaffWorkloadToEdit({} as WorkloadStaffEntryList);
                                                            setShowWorkLoadOfAcademicStaffModal(true);
                                                        }}
                                                    >
                                                        <i className="fa fa-plus me-2" /> Add Record
                                                    </BsButton>
                                                </FormGroup>
                                            </div>
                                            <DataTable
                                                columns={workLoadOfAcademicStaffColumns}
                                                data={workloadOfAcademicStaffRecordList?.data?.length! > 0 ? workloadOfAcademicStaffRecordList?.data! : []}
                                                persistTableHead={true}
                                                noDataComponent={<div className='border border-2 border-light border-top-0 p-5 text-center w-100'>There are no records to display</div>}
                                                expandOnRowClicked
                                                highlightOnHover
                                            />
                                        </fieldset>
                                    </Col>

                                </Row>

                                <Form
                                    autoComplete="off"
                                    className="custom-form"
                                    style={isDisabled === true ? { pointerEvents: 'none' } : {}}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    onReset={(e) => {
                                        e.preventDefault();
                                        handleFormReset();
                                    }}
                                >
                                    <Row>
                                        <Col sm={12} className='mt-5'>
                                            <FormGroup row className="mb-3">
                                                <Label for="number_of_teaching_unit" sm={5}>a. Number of teaching unit of the department:</Label>
                                                <Col sm={2}>
                                                    <InputField
                                                        min={'0'}
                                                        type="number"
                                                        placeholder=""
                                                        {...getFieldProps('number_of_teaching_unit')}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12}>
                                            <FormGroup row className="mb-3">
                                                <Label for="number_of_beds_of_each_teaching_unit" sm={5}>b. No. of beds in each of the service/teaching unit of the department:</Label>
                                                <Col sm={2}>
                                                    <InputField
                                                        min={'0'}
                                                        type="number"
                                                        placeholder=""
                                                        {...getFieldProps('number_of_beds_of_each_teaching_unit')}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <FormGroup row className='justify-content-between mt-5'>
                                        <Col sm={9}>
                                            <BsButton color="warning" style={isDisabled === true ? { pointerEvents: 'auto' } : {}} className='me-3 text-start' onClick={() => {
                                                goToPrevious();
                                            }}>
                                                {'Previous'}
                                            </BsButton>
                                        </Col>

                                        <Col sm={3}>
                                            {isDisabled !== true ?
                                                <div className='d-flex justify-content-end'>
                                                    <BsButton color="secondary" className='me-3' onClick={() => {
                                                        handleFormReset();
                                                    }}>
                                                        {'Reset'}
                                                    </BsButton>
                                                    <BsButton color="success" disabled={isPartCMasterInfoDataLoading} onClick={() => {
                                                        handleSubmit();
                                                    }}>
                                                        {'Save & Next'}
                                                    </BsButton>
                                                </div>
                                                :
                                                <div className='d-flex justify-content-end'>
                                                    <BsButton color="success" style={{ pointerEvents: 'auto' }} onClick={() => {
                                                        goToNext();
                                                    }}>
                                                        {'Next'}
                                                    </BsButton>
                                                </div>}
                                        </Col>
                                    </FormGroup>
                                </Form>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showRecordOfAcademicStaffModal}
                                    scrollable={true}
                                    size="lg"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Record of Academic Staff
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowRecordOfAcademicStaffModal(!showRecordOfAcademicStaffModal);
                                                setEditAcademicStaff(false);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <Row>
                                            <Col>
                                                {!editAcademicStaff && !academicStaffToEdit?.id ?
                                                    <AcademicStaffRecordsModal departmentId={departmentId} setModalShow={setShowRecordOfAcademicStaffModal} />
                                                    :
                                                    <AcademicStaffRecordsModal departmentId={departmentId} academicStaffToEdit={academicStaffToEdit} setModalShow={setShowRecordOfAcademicStaffModal} />
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showWorkLoadOfAcademicStaffModal}
                                    scrollable={true}
                                    size="lg"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Work Load of Academic Staff
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowWorkLoadOfAcademicStaffModal(!showWorkLoadOfAcademicStaffModal);
                                                setEditAcademicStaffWorkload(false);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <Row>
                                            <Col>
                                                {!editAcademicStaffWorkload && !academicStaffWorkloadToEdit?.id ?
                                                    <WorkLoadOfAcademicStaffModal departmentId={departmentId} setModalShow={setShowWorkLoadOfAcademicStaffModal} />
                                                    :
                                                    <WorkLoadOfAcademicStaffModal departmentId={departmentId} academicStaffWorkloadToEdit={academicStaffWorkloadToEdit} setModalShow={setShowWorkLoadOfAcademicStaffModal} />
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showAcademicStaffRecordViewModal}
                                    scrollable={true}
                                    size="md"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className='modal-header'>
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Academic Staff Record Information
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowAcademicStaffRecordViewModal(!showAcademicStaffRecordViewModal);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <ModalBody>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table table-bordered table-striped table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>Name: </strong>
                                                                {academicStaffRecordInModal?.name ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Designation: </strong>
                                                                {academicStaffRecordInModal?.designation ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Full/part time: </strong>
                                                                {academicStaffRecordInModal?.job_type ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Qualification: </strong>
                                                                {academicStaffRecordInModal?.qualification ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>BMDC Reg. No.: </strong>
                                                                {academicStaffRecordInModal?.bmdc_reg_no ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>BMDC Reg. No. validity: </strong>
                                                                {moment(academicStaffRecordInModal?.bmdc_reg_validity).isValid() ? moment(academicStaffRecordInModal?.bmdc_reg_validity).format('DD/MM/YYYY') : '-'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Teaching experience in the rank of Asst. Prof. and above (in years): </strong>
                                                                {academicStaffRecordInModal?.teaching_experience ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>List of publications: </strong>
                                                                {typeof academicStaffRecordInModal.list_of_publication === 'string' && academicStaffRecordInModal.list_of_publication !== '' ? (
                                                                    <a
                                                                        className="me-2 p-0 center"
                                                                        href={`${config?.APP_URL}${academicStaffRecordInModal.list_of_publication}`}
                                                                        rel="noreferrer"
                                                                        target="_blank"
                                                                    >
                                                                        <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                                                    </a>
                                                                ) : ''}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showAcademicStaffWorkloadRecordViewModal}
                                    scrollable={true}
                                    size="md"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className='modal-header'>
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Academic Staff Workload Record Information
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowAcademicStaffWorkloadRecordViewModal(!showAcademicStaffWorkloadRecordViewModal);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <ModalBody>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table table-bordered table-striped table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>Faculty: </strong>
                                                                {academicStaffWorkloadRecordInModal?.faculty ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>In-Patient Care: </strong>
                                                                {academicStaffWorkloadRecordInModal?.in_patient_care ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>In-Patient Rounds: </strong>
                                                                {academicStaffWorkloadRecordInModal?.in_patient_rounds ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Out-Patient Attendance: </strong>
                                                                {academicStaffWorkloadRecordInModal?.out_patient_attendance ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>On Call: </strong>
                                                                {academicStaffWorkloadRecordInModal?.on_call === 1 ? 'Yes' : academicStaffWorkloadRecordInModal?.on_call === 0 ? 'No' : 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Emergency Attendance: </strong>
                                                                {academicStaffWorkloadRecordInModal?.emergency_attendance === 1 ? 'Yes' : academicStaffWorkloadRecordInModal?.emergency_attendance === 0 ? 'No' : 'N/A'}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={recordOfAcademicStaffDeleteModalShow}
                                    scrollable={true}
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Delete Record of Academic Staff
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setRecordOfAcademicStaffDeleteModalShow(!recordOfAcademicStaffDeleteModalShow);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        {recordOfAcademicStaffForDelete && (
                                            <Row>
                                                <Col>
                                                    <p>
                                                        Are you sure you want to delete the academic staff record? {' '}
                                                    </p>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setRecordOfAcademicStaffDeleteModalShow(false);
                                            }}
                                            className="btn btn-secondary me-2"
                                            data-dismiss="modal"
                                        >
                                            No
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={(e) => {
                                                setRecordOfAcademicStaffDeleteEntry(true);
                                            }}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={recordOfAcademicStaffWorkloadDeleteModalShow}
                                    scrollable={true}
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Delete Workload of Academic Staff Record
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setRecordOfAcademicStaffWorkloadDeleteModalShow(!recordOfAcademicStaffWorkloadDeleteModalShow);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        {recordOfAcademicStaffWorkloadForDelete && (
                                            <Row>
                                                <Col>
                                                    <p>
                                                        Are you sure you want to delete the workload of academic staff record? {' '}
                                                    </p>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setRecordOfAcademicStaffWorkloadDeleteModalShow(false);
                                            }}
                                            className="btn btn-secondary me-2"
                                            data-dismiss="modal"
                                        >
                                            No
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={(e) => {
                                                setRecordOfAcademicStaffWorkloadDeleteEntry(true);
                                            }}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </Modal>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container >
        </div >
    );
};

export default InstituteDeptRegistrationStepFive;
