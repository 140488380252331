import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { Button as BsButton, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Modal, ModalBody, Row } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { setFromErrors } from 'src/helpers';
import { useDeleteLaboratoryRecordMutation, useDeleteOperationRecordMutation, useGetDepartmentByIdQuery, useGetLaboratoryRecordListQuery, useGetOperationRecordListQuery, useGetTotalNoOfOperationEntryQuery, useTotalNoOfOperationEntryMutation } from 'src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi';
import * as Yup from 'yup';
import RecordOfOperationOfTheDeptModal from './RecordOfOperationOfTheDeptModal';
import RecordsOfRoutineAndEmergencyLaboratoryWorkOfTheDeptModal from './RecordsOfRoutineAndEmergencyLaboratoryWorkModal';

const initialValues: TotalNoOfOperationEntry = {
    operation_number_id: '',
    form_b_department_id: '',
    number_of_operation: 0,
};

const InstituteDeptRegistrationStepFour = (props: any) => {
    const { goToNext, goToPrevious, instituteName, departmentId, formBDeptId, isDisabled, renewDepartment } = props;
    const [showRecordOfOperationOfTheDeptModal, setShowRecordOfOperationOfTheDeptModal] = useState<boolean>(false);
    const [showRecordOfRoutineAndEmergencyLaboratoryWorkOfTheDeptModal, setShowRecordOfRoutineAndEmergencyLaboratoryWorkOfTheDeptModal] = useState<boolean>(false);
    const [showOperationRecordViewModal, setShowOperationRecordViewModal] = useState<boolean>(false);
    const [showLaboratoryRecordViewModal, setShowLaboratoryRecordViewModal] = useState<boolean>(false);
    const [operationRecordInModal, setOperationRecordInModal] = useState<OperationRecordList>({} as OperationRecordList);
    const [laboratoryRecordInModal, setLaboratoryRecordInModal] = useState<LaboratoryRecordList>({} as LaboratoryRecordList);
    const [recordOfOperationForDelete, setRecordOfOperationForDelete] = useState<OperationRecordList>({} as OperationRecordList);
    const [recordOfOperationDeleteModalShow, setRecordOfOperationDeleteModalShow] = useState<boolean>(false);
    const [recordOfOperationDeleteEntry, setRecordOfOperationDeleteEntry] = useState<boolean>(false);
    const [recordOfLaboratoryForDelete, setRecordOfLaboratoryForDelete] = useState<LaboratoryRecordList>({} as LaboratoryRecordList);
    const [recordOfLaboratoryDeleteModalShow, setRecordOfLaboratoryDeleteModalShow] = useState<boolean>(false);
    const [recordOfLaboratoryDeleteEntry, setRecordOfLaboratoryDeleteEntry] = useState<boolean>(false);
    const [editOperationRecord, setEditOperationRecord] = useState<boolean>(false);
    const [operationRecordToEdit, setOperationRecordToEdit] = useState<OperationRecordList>({} as OperationRecordList);
    const [editLaboratoryRecord, setEditLaboratoryRecord] = useState<boolean>(false);
    const [laboratoryRecordToEdit, setLaboratoryRecordToEdit] = useState<LaboratoryRecordList>({} as LaboratoryRecordList);

    const [totalNoOfOperationEntry, { data: totalNoOfOperationEntryData, isSuccess: isTotalNoOfOperationEntryDataSuccess, isLoading: isTotalNoOfOperationEntryDataLoading, isError: isTotalNoOfOperationEntryDataError, error: totalNoOfOperationEntryDataError }] =
        useTotalNoOfOperationEntryMutation();

    const { data: totalNoOfOperationData, isSuccess: isTotalNoOfOperationDataSuccess, isError: isTotalNoOfOperationDataError, error: totalNoOfOperationDataError } = useGetTotalNoOfOperationEntryQuery(departmentId);

    const { data: submittedDepartmentData, isSuccess: isSubmittedDepartmentDataSuccess, isError: isSubmittedDepartmentDataError, error: submittedDepartmentDataError } = useGetDepartmentByIdQuery(formBDeptId);

    const { data: operationRecordList, isSuccess: isOperationRecordListSuccess, isError: isOperationRecordListError, error: operationRecordListError } = useGetOperationRecordListQuery(departmentId);

    const { data: laboratoryRecordList, isSuccess: isLaboratoryRecordListSuccess, isError: isLaboratoryRecordListError, error: laboratoryRecordListError } = useGetLaboratoryRecordListQuery(departmentId);

    const [
        deleteOperationRecordEntry,
        {
            data: operationRecordEntryDelete,
            isSuccess: isOperationRecordEntryDeleteSuccess,
            isError: isOperationRecordEntryDeleteError,
            error: operationRecordEntryDeleteError,
        },
    ] = useDeleteOperationRecordMutation();

    const [
        deleteLaboratoryRecordEntry,
        {
            data: laboratoryRecordEntryDelete,
            isSuccess: isLaboratoryRecordEntryDeleteSuccess,
            isError: isLaboratoryRecordEntryDeleteError,
            error: laboratoryRecordEntryDeleteError,
        },
    ] = useDeleteLaboratoryRecordMutation();

    document.title = renewDepartment === true ? 'Renew Department | BCPS' : 'Department Registration | BCPS';

    const handleEditOperationRecordEntry = (e: any, operationRecord: OperationRecordList) => {
        setEditOperationRecord(true);
        setOperationRecordToEdit(operationRecord);
        setShowRecordOfOperationOfTheDeptModal(true);
    };

    const handleEditLaboratoryRecordEntry = (e: any, laboratoryRecord: LaboratoryRecordList) => {
        setEditLaboratoryRecord(true);
        setLaboratoryRecordToEdit(laboratoryRecord);
        setShowRecordOfRoutineAndEmergencyLaboratoryWorkOfTheDeptModal(true);
    };

    const handleOperationRecordView = (e: any, operationRecord: OperationRecordList) => {
        setOperationRecordInModal(operationRecord);
        setShowOperationRecordViewModal(true);
    };

    const handleLaboratoryRecordView = (e: any, laboratoryRecord: LaboratoryRecordList) => {
        setLaboratoryRecordInModal(laboratoryRecord);
        setShowLaboratoryRecordViewModal(true);
    };

    const operationRecordDeleteHandler = (e: any, operationRecord: OperationRecordList) => {
        setRecordOfOperationForDelete(operationRecord);
        setRecordOfOperationDeleteModalShow(true);
    };

    const laboratoryRecordDeleteHandler = (e: any, laboratoryRecord: LaboratoryRecordList) => {
        setRecordOfLaboratoryForDelete(laboratoryRecord);
        setRecordOfLaboratoryDeleteModalShow(true);
    };

    const err: CustomError = totalNoOfOperationEntryDataError as CustomError;

    const formik = useFormik({
        initialValues,

        validationSchema: Yup.object({}),

        onSubmit: (values: TotalNoOfOperationEntry) => {
            console.log(values);
            values.form_b_department_id = departmentId;

            totalNoOfOperationEntry(values);
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    useEffect(() => {
        if (totalNoOfOperationData?.data?.form_b_department_id && !isTotalNoOfOperationDataError) {
            const totalNoOfOperationInfo: GetTotalNoOfOperation = totalNoOfOperationData?.data!;
            setValues({
                operation_number_id: totalNoOfOperationInfo?.id,
                form_b_department_id: totalNoOfOperationInfo?.form_b_department_id,
                number_of_operation: totalNoOfOperationInfo?.number_of_operation,
            });
        }
    }, [totalNoOfOperationData, isTotalNoOfOperationDataError]);

    useEffect(() => {
        if (isTotalNoOfOperationEntryDataSuccess) {
            handleFormReset();
            toast.success('Department information saved successfully');
            goToNext();
        }
        if (isTotalNoOfOperationEntryDataError) {
            toast.error(err?.message || 'Something went wrong');
            setFromErrors(err.data, setErrors);
        }
    }, [isTotalNoOfOperationEntryDataSuccess, isTotalNoOfOperationEntryDataError]);

    const recordOfOperationOfTheDeptColumns: TableColumn<OperationRecordList>[] = [

        {
            name: <div className="text-center">Period</div>,
            selector: (row) => `${moment(row.period_from).format('DD/MMM/YYYY')} to ${moment(row.period_to).format('DD/MMM/YYYY')}`,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Operation Type</div>,
            selector: (row) => row.operation_type!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Name of Major/Minor/Emergency Operation Done</div>,
            selector: (row) => row.operation_name!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center"># Procedure</div>,
            selector: (row) => row.number_of_operation!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Action</div>,
            width: '200px',
            cell: (row) => (
                <>
                    <FormGroup className="btn-group mt-2">
                        <button
                            className="btn btn-sm btn-secondary"
                            onClick={(e) => {
                                handleEditOperationRecordEntry(e, row);
                            }}
                        >
                            <span className="fas fa-edit"></span> Edit
                        </button>
                        <button
                            className="btn btn-sm btn-info"
                            style={{ pointerEvents: 'auto' }}
                            onClick={(e) => {
                                handleOperationRecordView(e, row);
                            }}
                        >
                            <span className="fas fa-eye"></span> View
                        </button>
                        {
                            <Button
                                size="sm"
                                color="danger"
                                type="button"
                                text={`<span class="fas fa-trash" ></span> Delete`}
                                onClick={(e) => {
                                    operationRecordDeleteHandler(e, row);
                                }}
                            />
                        }
                    </FormGroup>
                </>
            ),

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const recordOfRoutineAndEmergencyLaboratoryWorkOfTheDeptColumns: TableColumn<LaboratoryRecordList>[] = [

        {
            name: <div className="text-center">Period</div>,
            selector: (row) => `${moment(row.period_from).format('DD/MMM/YYYY')} to ${moment(row.period_to).format('DD/MMM/YYYY')}`,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Type of Laboratory Work</div>,
            selector: (row) => row.type!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Name of Laboratory Work</div>,
            selector: (row) => row.laboratory_work_name!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center"># Laboratory Work Done</div>,
            selector: (row) => row.laboratory_work_done!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center"># Procedure</div>,
            selector: (row) => row.total_number!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Action</div>,
            width: '200px',
            cell: (row) => (
                <>
                    <FormGroup className="btn-group mt-2">
                        <button
                            className="btn btn-sm btn-secondary"
                            onClick={(e) => {
                                handleEditLaboratoryRecordEntry(e, row);
                            }}
                        >
                            <span className="fas fa-edit"></span> Edit
                        </button>
                        <button
                            className="btn btn-sm btn-info"
                            style={{ pointerEvents: 'auto' }}
                            onClick={(e) => {
                                handleLaboratoryRecordView(e, row);
                            }}
                        >
                            <span className="fas fa-eye"></span> View
                        </button>
                        {
                            <Button
                                size="sm"
                                color="danger"
                                type="button"
                                text={`<span class="fas fa-trash" ></span> Delete`}
                                onClick={(e) => {
                                    laboratoryRecordDeleteHandler(e, row);
                                }}
                            />
                        }
                    </FormGroup>
                </>
            ),

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (recordOfOperationDeleteEntry) {
            deleteOperationRecordEntry(recordOfOperationForDelete?.id!);
        }
    }, [recordOfOperationDeleteEntry]);

    useEffect(() => {
        if (!isOperationRecordEntryDeleteError && isOperationRecordEntryDeleteSuccess) {
            toast.dismiss();
            toast.success('Operation Entry Deleted Successfully');
            setRecordOfOperationDeleteModalShow(false);
            setRecordOfOperationDeleteEntry(false);
        } else if (isOperationRecordEntryDeleteError && !isOperationRecordEntryDeleteSuccess) {
            toast.dismiss();
            toast.error('Error while deleting the operation entry');
        }
    }, [isOperationRecordEntryDeleteError, isOperationRecordEntryDeleteSuccess]);

    useEffect(() => {
        if (recordOfLaboratoryDeleteEntry) {
            deleteLaboratoryRecordEntry(recordOfLaboratoryForDelete?.id!);
        }
    }, [recordOfLaboratoryDeleteEntry]);

    useEffect(() => {
        if (!isLaboratoryRecordEntryDeleteError && isLaboratoryRecordEntryDeleteSuccess) {
            toast.dismiss();
            toast.success('Laboratory Entry Deleted Successfully');
            setRecordOfLaboratoryDeleteModalShow(false);
            setRecordOfLaboratoryDeleteEntry(false);
        } else if (isLaboratoryRecordEntryDeleteError && !isLaboratoryRecordEntryDeleteSuccess) {
            toast.dismiss();
            toast.error('Error while deleting the laboratory entry');
        }
    }, [isLaboratoryRecordEntryDeleteError, isLaboratoryRecordEntryDeleteSuccess]);

    const handleFormReset = () => {
        resetForm();
    };

    return (
        <div className="page-content">
            <Container fluid>
                <BetterBreadcrumb
                    title="Training Accreditation Application Form"
                    breadcrumbItem={[{ link: '/institute-registration-list', name: 'Department List' }]}
                />
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title mb-0 flex-grow-1">
                                    <span>Part-B : Information about Relevant Department (Continued)</span>
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm={12}>
                                        <Row className='justify-content-evenly'>
                                            <Col sm={12} md={6}>
                                                <FormGroup className="col-sm-12 mb-3">
                                                    <Label for="institute_name">Institute Name</Label>
                                                    <InputField
                                                        id="institute_name"
                                                        name="institute_name"
                                                        type="text"
                                                        value={instituteName}
                                                        placeholder=""
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={12} md={6}>
                                                <FormGroup className="col-sm-12 mb-3">
                                                    <Label for="department_name">Name of the Department</Label>
                                                    <InputField
                                                        id="department_name"
                                                        name="department_name"
                                                        type="text"
                                                        value={submittedDepartmentData?.data?.department_name!}
                                                        placeholder=""
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col sm={12}>
                                        <fieldset className="border p-2">
                                            <legend className='w-auto' style={{ float: 'none', padding: 'inherit' }}>
                                                <span className='px-4 bg-success rounded-pill'>
                                                    <i className="fa-solid fa-5" style={{ color: '#f8f9fc' }}></i>
                                                    <span style={{ color: '#f8f9fc' }} className='mx-1'>.</span>
                                                    <i className="fa-solid fa-2" style={{ color: '#f8f9fc' }}></i>
                                                </span> For surgery and allied departments
                                            </legend>

                                            {/* <Row>
                                                    <Col sm={12} md={6} className='mt-4'>
                                                        <FormGroup row className="mb-3">
                                                            <Label for="icu_beds" sm={7}>Number of operating sessions per week:</Label>
                                                            <Col sm={3}>
                                                                <InputField
                                                                    min={'0'}
                                                                    type="number"
                                                                    placeholder="Number of ICU Beds"
                                                                    {...getFieldProps('icu_beds')}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={12} md={3}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="fromDate">From Date</Label>
                                                            <InputField
                                                                type="date"
                                                                placeholder=""
                                                                {...getFieldProps('fromDate')}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={12} md={3}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="toDate">To Date</Label>
                                                            <InputField
                                                                type="date"
                                                                placeholder=""
                                                                {...getFieldProps('toDate')}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row> */}

                                            <Form
                                                autoComplete="off"
                                                className="custom-form"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    handleSubmit();
                                                }}
                                                onReset={(e) => {
                                                    e.preventDefault();
                                                    handleFormReset();
                                                }}
                                            >
                                                <Row>
                                                    <Col sm={12} className='mt-2'>
                                                        <FormGroup row className="mb-3">
                                                            <Label for="number_of_operation" sm={6} className='ms-3'>
                                                                Total number of operations performed by the department during last year:
                                                            </Label>
                                                            <Col sm={2}>
                                                                <InputField
                                                                    min={'0'}
                                                                    type="number"
                                                                    placeholder=""
                                                                    {...getFieldProps('number_of_operation')}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Form>

                                            <div className='filter-wrapper'>
                                                <h5 className='font-size-14 mb-3 ms-3'>Record of operation of the department (last one year)</h5>
                                                <FormGroup className="ms-auto justify-content-end">
                                                    <BsButton
                                                        type="button"
                                                        className="btn btn-success me-2"
                                                        onClick={(e) => {
                                                            setEditOperationRecord(false);
                                                            setOperationRecordToEdit({} as OperationRecordList);
                                                            setShowRecordOfOperationOfTheDeptModal(true);
                                                        }}
                                                    >
                                                        <i className="fa fa-plus me-2" /> Add Record
                                                    </BsButton>
                                                </FormGroup>
                                            </div>
                                            <DataTable
                                                columns={recordOfOperationOfTheDeptColumns}
                                                data={operationRecordList?.data?.length! > 0 ? operationRecordList?.data! : []}
                                                persistTableHead={true}
                                                noDataComponent={<div className='border border-2 border-light border-top-0 p-5 text-center w-100'>There are no records to display</div>}
                                                expandOnRowClicked
                                                highlightOnHover
                                            />
                                        </fieldset>
                                    </Col>

                                    <Col sm={12}>
                                        <fieldset className="border p-2">
                                            <legend className='w-auto' style={{ float: 'none', padding: 'inherit' }}>
                                                <span className='px-4 bg-success rounded-pill'><i className="fa-solid fa-6" style={{ color: '#f8f9fc' }}></i></span> Applicable for Basic Science Departments
                                            </legend>

                                            <div className='filter-wrapper'>
                                                <h5 className='font-size-14 mb-3 ms-3'>Records of routine and emergency laboratory work of the department (last one year)</h5>
                                                <FormGroup className="ms-auto justify-content-end">
                                                    <BsButton
                                                        type="button"
                                                        className="btn btn-success me-2"
                                                        onClick={(e) => {
                                                            setEditLaboratoryRecord(false);
                                                            setLaboratoryRecordToEdit({} as LaboratoryRecordList);
                                                            setShowRecordOfRoutineAndEmergencyLaboratoryWorkOfTheDeptModal(true);
                                                        }}
                                                    >
                                                        <i className="fa fa-plus me-2" /> Add Record
                                                    </BsButton>
                                                </FormGroup>
                                            </div>
                                            <DataTable
                                                columns={recordOfRoutineAndEmergencyLaboratoryWorkOfTheDeptColumns}
                                                data={laboratoryRecordList?.data?.length! > 0 ? laboratoryRecordList?.data! : []}
                                                persistTableHead={true}
                                                noDataComponent={<div className='border border-2 border-light border-top-0 p-5 text-center w-100'>There are no records to display</div>}
                                                expandOnRowClicked
                                                highlightOnHover
                                            />
                                        </fieldset>
                                    </Col>
                                </Row>

                                <FormGroup row className='justify-content-between mt-5'>
                                    <Col sm={9}>
                                        <BsButton color="warning" style={isDisabled === true ? { pointerEvents: 'auto' } : {}} className='me-3 text-start' onClick={() => {
                                            goToPrevious();
                                        }}>
                                            {'Previous'}
                                        </BsButton>
                                    </Col>

                                    <Col sm={3}>
                                        {isDisabled !== true ?
                                            <div className='d-flex justify-content-end'>
                                                <BsButton color="secondary" className='me-3' onClick={() => {
                                                    handleFormReset();
                                                }}>
                                                    {'Reset'}
                                                </BsButton>
                                                <BsButton color="success" onClick={() => {
                                                    handleSubmit();
                                                }}>
                                                    {'Save & Next'}
                                                </BsButton>
                                            </div>
                                            :
                                            <div className='d-flex justify-content-end'>
                                                <BsButton color="success" style={{ pointerEvents: 'auto' }} onClick={() => {
                                                    goToNext();
                                                }}>
                                                    {'Next'}
                                                </BsButton>
                                            </div>}
                                    </Col>
                                </FormGroup>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showRecordOfOperationOfTheDeptModal}
                                    scrollable={true}
                                    size="lg"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Record of operation of the department
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowRecordOfOperationOfTheDeptModal(!showRecordOfOperationOfTheDeptModal);
                                                setEditOperationRecord(false);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <Row>
                                            <Col>
                                                {!editOperationRecord && !operationRecordToEdit?.id ?
                                                    <RecordOfOperationOfTheDeptModal departmentId={departmentId} setModalShow={setShowRecordOfOperationOfTheDeptModal} />
                                                    :
                                                    <RecordOfOperationOfTheDeptModal departmentId={departmentId} operationRecordToEdit={operationRecordToEdit} setModalShow={setShowRecordOfOperationOfTheDeptModal} />
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showRecordOfRoutineAndEmergencyLaboratoryWorkOfTheDeptModal}
                                    scrollable={true}
                                    size="lg"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Record of routine and emergency laboratory work of the department
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowRecordOfRoutineAndEmergencyLaboratoryWorkOfTheDeptModal(!showRecordOfRoutineAndEmergencyLaboratoryWorkOfTheDeptModal);
                                                setEditLaboratoryRecord(false);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <Row>
                                            <Col>
                                                {!editLaboratoryRecord && !laboratoryRecordToEdit?.id ?
                                                    <RecordsOfRoutineAndEmergencyLaboratoryWorkOfTheDeptModal departmentId={departmentId} setModalShow={setShowRecordOfRoutineAndEmergencyLaboratoryWorkOfTheDeptModal} />
                                                    :
                                                    <RecordsOfRoutineAndEmergencyLaboratoryWorkOfTheDeptModal departmentId={departmentId} laboratoryRecordToEdit={laboratoryRecordToEdit} setModalShow={setShowRecordOfRoutineAndEmergencyLaboratoryWorkOfTheDeptModal} />
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showOperationRecordViewModal}
                                    scrollable={true}
                                    size="md"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className='modal-header'>
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Operation Record Information
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowOperationRecordViewModal(!showOperationRecordViewModal);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <ModalBody>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table table-bordered table-striped table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>Period: </strong>
                                                                {moment(operationRecordInModal?.period_from).format('DD/MMM/YYYY')} to {moment(operationRecordInModal?.period_to).format('DD/MMM/YYYY')}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Operation Type: </strong>
                                                                {operationRecordInModal?.operation_type ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Name of Major Operation Done: </strong>
                                                                {operationRecordInModal?.operation_name ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>No. of Procedure: </strong>
                                                                {operationRecordInModal?.number_of_operation ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showLaboratoryRecordViewModal}
                                    scrollable={true}
                                    size="md"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className='modal-header'>
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Laboratory Record Information
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowLaboratoryRecordViewModal(!showLaboratoryRecordViewModal);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <ModalBody>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table table-bordered table-striped table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>Period: </strong>
                                                                {moment(laboratoryRecordInModal?.period_from).format('DD/MMM/YYYY')} to {moment(laboratoryRecordInModal?.period_to).format('DD/MMM/YYYY')}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Type of Laboratory Work: </strong>
                                                                {laboratoryRecordInModal?.type ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Name of Laboratory Work: </strong>
                                                                {laboratoryRecordInModal?.laboratory_work_name ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>No. of Laboratory Work Done: </strong>
                                                                {laboratoryRecordInModal?.laboratory_work_done ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>No. of Procedure: </strong>
                                                                {laboratoryRecordInModal?.total_number ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={recordOfOperationDeleteModalShow}
                                    scrollable={true}
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Delete Record of Operation
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setRecordOfOperationDeleteModalShow(!recordOfOperationDeleteModalShow);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        {recordOfOperationForDelete && (
                                            <Row>
                                                <Col>
                                                    <p>
                                                        Are you sure you want to delete the operation record? {' '}
                                                    </p>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setRecordOfOperationDeleteModalShow(false);
                                            }}
                                            className="btn btn-secondary me-2"
                                            data-dismiss="modal"
                                        >
                                            No
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={(e) => {
                                                setRecordOfOperationDeleteEntry(true);
                                            }}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={recordOfLaboratoryDeleteModalShow}
                                    scrollable={true}
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Delete Record of Laboratory
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setRecordOfLaboratoryDeleteModalShow(!recordOfLaboratoryDeleteModalShow);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        {recordOfLaboratoryForDelete && (
                                            <Row>
                                                <Col>
                                                    <p>
                                                        Are you sure you want to delete the laboratory record? {' '}
                                                    </p>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setRecordOfLaboratoryDeleteModalShow(false);
                                            }}
                                            className="btn btn-secondary me-2"
                                            data-dismiss="modal"
                                        >
                                            No
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={(e) => {
                                                setRecordOfLaboratoryDeleteEntry(true);
                                            }}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </Modal>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container >
        </div >
    );
};

export default InstituteDeptRegistrationStepFour;