import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { setFromErrors } from 'src/helpers';
import { useCreateMoneyRecieptMutation, useGetMoneyReciptListQuery } from 'src/rtk/features/researchClinicSetup/researchClinicSetupApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';
import EditMoneyReceiptModal from './EditMoneyReceiptModal';
import { GuideCoGuideMoneyReceiptPdf } from './GuideCoGuideMoneyReceiptPdf';
import { TraineeMoneyReceiptPdf } from './TraineeMoneyReceiptPdf';

const initialValues = {
    registration_no: '',
    research_grant_info_id: '',
    from_date: '',
    to_date: '',
    day_count: '',
    rate: 0,
    amount: '',
    submission_status: '',
};

const FormCMoneyReceiptResearcher = () => {
    const { role: roleName, reg: regId, grant: grantId } = useParams<{ role: string, reg: string, grant: string }>();
    const {
        data: recieptList,
        isSuccess: isListSuccess,
        isLoading: isListLoading,
    } = useGetMoneyReciptListQuery();

    const [createMoneyReciept, { isSuccess, isError, error, isLoading: isCreateLoading }] = useCreateMoneyRecieptMutation();
    const [btnClicked, setBtnClicked] = useState<string>('');

    const [startDate, setStartDate] = useState<string>('');
    const [daysDifference, setDaysDifference] = useState<any>('');
    const [amount, setAmount] = useState<number>(0);
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [editInformation, setEditInformation] = useState<any>({});
    const [traineePrintId, setTraineePrintId] = useState('');
    const [guideCoGuidePrintId, setGuideCoGuidePrintId] = useState('');
    const [traineeInfo, setTraineeInfo] = useState<any>({});
    const [guideCoGuideInfo, setGuideCoGuideInfo] = useState<any>({});
    const [updatedRecieptList, setUpdatedRecieptList] = useState<any>([]);
    const [submitting, setSubmitting] = useState(false);

    const buttonRef = useRef<any>();
    const draftButtonRef = useRef<any>();

    const allRole: SelectOption[] = [
        { value: 'Trainee', label: 'Trainee' },
        { value: 'Guide', label: 'Guide' },
        { value: 'Co-Guide', label: 'Co-Guide' },
    ]

    const err: CustomError = error as CustomError;

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({
            from_date: Yup.date().required('Start Date is Required!'),
            to_date: Yup.date().when("start_date", (from_date, schema) => {
                if (from_date) {
                    const fromDate = new Date(from_date);

                    return schema.required('To date is Required').min(fromDate, 'To date must be greater than or equal start date').nullable();
                }

                return schema;
            }),
            day_count: Yup.string().required('Days Count is Required!'),
            rate: Yup.string().required('Rate is Required!'),
            amount: Yup.string().required('Amount is Required!'),
        }),

        onSubmit: (values: any) => {
            values.registration_no = regId;
            values.research_grant_info_id = grantId;

            const formData = new FormData();

            Object.keys(values).forEach((key) => {
                if (key === 'submission_status') {
                    if (btnClicked === 'submit') {
                        values[key] = 'Submit';
                    } else if (btnClicked === 'save_as_draft') {
                        values[key] = 'Draft';
                    }
                }
                formData.append(key, values[key]);
            });
            createMoneyReciept(values);
        },
    });

    useEffect(() => {
        if (isSuccess) {
            resetForm();
            toast.success('Money Receipt Added Successfully!');
            setDaysDifference('');
            setAmount(0)
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);

    const handleStartDate = (e: any) => {
        setStartDate(e.target.value);
    };

    const handleCalculateDays = (e: any) => {
        const from = new Date(startDate);
        const to = new Date(e.target.value);

        if (from <= to) {
            const differenceInTime = to.getTime() - from.getTime();
            const differenceInDays = differenceInTime / (1000 * 3600 * 24);
            setDaysDifference(Math.round(differenceInDays) + 1);
        } else {
            setDaysDifference('');
        }
    };

    useEffect(() => {
        if (daysDifference > 0) {
            setFieldValue('day_count', daysDifference);
            setFieldValue('amount', daysDifference * values.rate);
            setAmount(daysDifference * values.rate)
        }
        else {
            setFieldValue('day_count', '');
        }
    }, [daysDifference, values.rate]);

    const modalShowHandler = (modalState: boolean, info: any) => {
        setModalShow(modalState);
        setEditInformation(info);
    }

    useEffect(() => {
        if (recieptList) {
            const list = recieptList?.data?.filter((item: any) => item?.research_grant_info?.role === roleName);
            setUpdatedRecieptList(list);
        }
    }, [recieptList]);

    return (
        <div className="page-content">
            {isCreateLoading || isListLoading || submitting ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Container fluid>
                <BetterBreadcrumb
                    breadcrumbItem={[{ link: '/research-grant-list', name: 'Research Grant' }]}
                    title={'Money Receipt (DSA) for Researcher'}
                />
                <React.Fragment>
                    <Card>
                        <CardBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                                onReset={(e) => {
                                    resetForm();
                                    setDaysDifference('');
                                    setAmount(0)
                                }}
                            >
                                <Row>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label for="role" className="form-label">Role</Label>
                                            <Input
                                                className="form-control"
                                                type="text"
                                                name="role"
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                                readOnly
                                                value={roleName}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label for="from_date" className="form-label required-field">From Date</Label>
                                            <InputField
                                                type="date"
                                                name='from_date'
                                                onChange={(e: any) => {
                                                    handleChange(e);
                                                    handleStartDate(e);
                                                }}
                                                onBlur={handleBlur}
                                                value={values.from_date}
                                                invalid={{ errors, touched }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label for="to_date" className="form-label required-field">To Date</Label>
                                            <InputField
                                                type="date"
                                                name='to_date'
                                                onChange={(e: any) => {
                                                    handleChange(e);
                                                    handleCalculateDays(e);
                                                }}
                                                onBlur={handleBlur}
                                                value={values.to_date}
                                                invalid={{ errors, touched }}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label for="day_count" className="form-label required-field">Day Count</Label>
                                            <InputField
                                                type="text"
                                                placeholder='Day Count'
                                                name='day_count'
                                                value={daysDifference}
                                                disabled
                                                invalid={{ errors, touched }}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label for="rate" className="form-label required-field">Rate</Label>
                                            <InputField
                                                type="number"
                                                min={0}
                                                placeholder='Enter Rate'
                                                {...getFieldProps('rate')}
                                                invalid={{ errors, touched }}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label for="amount" className="form-label required-field">Amount</Label>
                                            <InputField
                                                type="number"
                                                min={0}
                                                name='amount'
                                                value={amount}
                                                invalid={{ errors, touched }}
                                                readOnly
                                            />
                                        </FormGroup>
                                    </Col>
                                    <FormGroup className="mb-3 text-center">
                                        <Button
                                            type="reset"
                                            className="btn btn-danger me-2"
                                        >
                                            Reset
                                        </Button>
                                        <button
                                            type="submit"
                                            className="btn btn-info me-2"
                                            ref={draftButtonRef}
                                            onClick={(e) => {
                                                setBtnClicked('save_as_draft');
                                            }}
                                        >
                                            Save as Draft
                                        </button>
                                        <button
                                            type="submit"
                                            ref={buttonRef}
                                            className="btn btn-success"
                                            onClick={(e) => {
                                                setBtnClicked('submit');
                                            }}
                                        >
                                            Submit
                                        </button>
                                    </FormGroup>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <h6 className='block-heading'>Money Receipt List</h6>
                            <Table className='table-striped table-sm'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Role</th>
                                        <th>From Date</th>
                                        <th>To Date</th>
                                        <th>Total Days</th>
                                        <th>Rate</th>
                                        <th>Amount</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {updatedRecieptList?.map((item: any) => (
                                        <tr key={item.id}>
                                            <td>{item?.registration?.personal_info?.full_name}</td>
                                            <td>{item?.research_grant_info?.role}</td>
                                            <td>{moment(item?.from_date).format("DD-MMM-YYYY")}</td>
                                            <td>{moment(item?.to_date).format("DD-MMM-YYYY")}</td>
                                            <td>{item?.day_count}</td>
                                            <td>{item?.rate}</td>
                                            <td>{item?.amount}</td>
                                            <td>
                                                {(item?.submission_status === 'Submit' && item?.research_grant_info?.role === 'Trainee') &&
                                                    <Button
                                                        className="btn btn-sm"
                                                        color='primary'
                                                        onClick={() => {
                                                            setTraineePrintId(item.id);
                                                            setTraineeInfo(item);
                                                            setSubmitting(true);
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-print" /> Print
                                                    </Button>
                                                }

                                                {(item?.submission_status === 'Submit' && (item?.research_grant_info?.role === 'Guide' || item?.research_grant_info?.role === 'Co-Guide')) &&
                                                    <Button
                                                        className="btn btn-sm"
                                                        color='primary'
                                                        onClick={() => {
                                                            setGuideCoGuidePrintId(item.id);
                                                            setGuideCoGuideInfo(item);
                                                            setSubmitting(true);
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-print" /> Print
                                                    </Button>
                                                }

                                                {item?.submission_status === 'Draft' &&
                                                    <Button
                                                        className="btn btn-sm"
                                                        color='success'
                                                        onClick={(e) => {
                                                            modalShowHandler(true, item)
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-edit" /> Edit
                                                    </Button>
                                                }
                                            </td>
                                            {modalShow && item?.id === editInformation?.id ? (
                                                <EditMoneyReceiptModal
                                                    isOpen={modalShow}
                                                    toggle={() => setModalShow(!modalShow)}
                                                    info={editInformation}
                                                />
                                            ) : null}

                                            {traineePrintId === item.id && item?.research_grant_info?.role === 'Trainee' ? (
                                                <TraineeMoneyReceiptPdf data={traineeInfo} setTraineePrintId={setTraineePrintId} setSubmitting={setSubmitting} />
                                            ) : null}

                                            {guideCoGuidePrintId === item.id && (item?.research_grant_info?.role === 'Guide' || item?.research_grant_info?.role === 'Co-Guide') ? (
                                                <GuideCoGuideMoneyReceiptPdf data={guideCoGuideInfo} setGuideCoGuidePrintId={setGuideCoGuidePrintId} setSubmitting={setSubmitting} />
                                            ) : null}

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </React.Fragment>
            </Container>
        </div>
    );
};

export default FormCMoneyReceiptResearcher;
