import * as url from 'src/helpers/url_helper';
import * as Yup from 'yup';

const formObj: any = {
  debug: false,
  isUpdateByPatch: true,

  createAPIUrl: url.USER_CREATE,
  updateAPIUrl: url.USER_UPDATE,
  getDataByIdAPIUrl: url.USER_BY_ID,
  listAPIUrl: url.USER_LIST,
  deleteAPIUrl: url.USER_DELETE,
  setPermissonAPIUrl: url.SET_USER_PERMISSIONS,
  getPermissonAPIUrl: url.GET_USER_PERMISSIONS,

  listUrl: '/users/',
  addUrl: '/users/add',
  updateUrl: '/users/update/',
  permissonUrl: '/users/permisson/',

  showSearchForm: true,
  showCreateNewBtn: true,
  showUpdateBtn: true,
  showViewBtn: true,
  showDeleteBtn: false,
  showTableActionColumn: true,

  createNewBtnTitle: 'Create New',
  submitBtnTitle: 'Save',
  updateBtnTitle: 'Update',
  resetBtnTitle: 'Reset',
  draftBtnTitle: '',
  viewBtnTitle: 'View',
  deletBtnTitle: 'Delete',
  deletePromtMessage: 'Do you want to delete?',

  title: 'User',
  breadcrumbItemForList: [{ link: '/', name: 'Dashboard' }],
  breadcrumbItemForForm: [
    { link: '/', name: 'Dashboard' },
    { link: '/users/', name: 'User List' },
  ],

  formFields: {
    name: {
      positionInForm: 3,
      label: 'Name',
      name: 'name',
      viewCell: 'name',
      cell: (row: any) => row.name,
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Name is required'),
      showInForm: true,

      showInSearch: true,
      colSize: 6,
    },

    username: {
      positionInForm: 4,
      label: 'Username',
      name: 'username',
      viewCell: 'username',
      cell: (row: any) => row.username,
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Username is required'),
      showInForm: true,
      showInSearch: true,
      colSize: 6,
    },

    mobile: {
      positionInForm: 5,
      label: 'Phone',
      name: 'mobile',
      viewCell: 'mobile',
      cell: (row: any) => row.mobile,
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string()
        .required('Please Enter Your Phone Number')
        .max(14, 'Invalid phone number')
        .matches(/^(?:\+88|88)?(01[0-9]\d{8})$/, 'Invalid phone number'),
      showInForm: true,
      showInSearch: true,
      colSize: 6,
    },

    email: {
      positionInForm: 6,
      label: 'Email',
      name: 'email',
      viewCell: 'email',
      cell: (row: any) => row.email,
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string()
        .email('Please Enter Valid Email')
        .required('Please Enter Your Email'),
      showInForm: true,
      showInSearch: true,
      colSize: 6,
    },

    roles: {
      positionInForm: 2,
      label: 'Select Role',
      name: 'roles',
      type: 'select',
      default: '',
      isMulti: true,
      isRequired: true,
      validation: Yup.array().required('Please Select Role')
        .min(1, 'Please Select Role(s)'),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
      dataApi: {
        url: url.GET_ROLES_LIST,
        key: 'name',
        label: 'name',
        label2: '',
      },
      data: [],
    },

    photo: {
      positionInForm: 7,
      label: 'Photograph',
      name: 'photo',
      viewCell: 'photo',
      cell: (row: any) => row.photo,
      type: 'file',
      default: '',
      isRequired: true,
      validation: Yup.mixed()
        .required('Image is Required!')
        .test('is-image', 'Must be an Image', (value) => {
          if (value) {
            return ['image/jpeg', 'image/png', 'image/jpg'].includes(
              value.type,
            );
          }

          return true;
        })
        .test('file-size', 'Image too Large', (value) => {
          if (value) {
            return value.size <= 20 * 1024 * 1024;
          }

          return true;
        }),
      validationForUpdate: Yup.mixed()
        .test('is-image', 'Must be an Image', (value) => {
          if (value) {
            return ['image/jpeg', 'image/png', 'image/jpg'].includes(
              value.type,
            );
          }

          return true;
        })
        .test('file-size', 'Image too Large', (value) => {
          if (value) {
            return value.size <= 20 * 1024 * 1024;
          }

          return true;
        }),
      accept: ".jpg, .jpeg, .png",
      placeholder: "Upload Picture",
      showInForm: true,
      showInSearch: false,
      colSize: 6,
    },

    signature: {
      positionInForm: 8,
      label: 'Signature',
      name: 'signature',
      viewCell: 'signature',
      cell: (row: any) => row.signature,
      type: 'file',
      default: '',
      isRequired: true,
      validation: Yup.mixed()
        .required('Image is Required!')
        .test('is-pdf', 'Must be an Image', (value) => {
          if (value) {
            return ['image/jpeg', 'image/png', 'image/jpg'].includes(
              value.type,
            );
          }
          return true;
        })
        .test('file-size', 'Image too Large', (value) => {
          if (value) {
            return value.size <= 20 * 1024 * 1024;
          }
          return true;
        }),
      validationForUpdate: Yup.mixed()
        .test('is-image', 'Must be an Image', (value) => {
          if (value) {
            return ['image/jpeg', 'image/png', 'image/jpg'].includes(
              value.type,
            );
          }

          return true;
        })
        .test('file-size', 'Image too Large', (value) => {
          if (value) {
            return value.size <= 20 * 1024 * 1024;
          }

          return true;
        }),
      accept: ".jpg, .jpeg, .png",
      placeholder: "Upload Signature",
      showInForm: true,
      showInSearch: false,
      colSize: 6,
    },

    password: {
      positionInForm: 4,
      label: 'password',
      name: 'password',
      type: 'password',
      default: '',
      isRequired: true,
      validation: Yup.string()
        .min(6, 'Password must be 6 characters long')
        .required('Password is required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
          'Must contain at least one uppercase, one lowercase, one numeric and one special character. Example: Abc@123',
        ),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
    },

    password_confirmation: {
      positionInForm: 4,
      label: 'Confirm Password',
      name: 'password_confirmation',
      type: 'password',
      default: '',
      isRequired: true,
      validation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords Must match')
        .required('Please Enter Your Confirm Password'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
    },
    status: {
      positionInForm: 20,
      label: 'Active Status',
      name: 'status',
      type: 'radio',
      default: true,
      isMulti: false,
      isRequired: true,
      validation: Yup.string().required('Is Required?'),
      showInForm: true,
      viewCell: (row: any) => (row.status ? 'Active' : 'Inactive'),
      cell: (row: any) => (row.status ? 'Active' : 'Inactive'),
      showInSearch: true,
      colSize: 4,
      // data: ['In-active', 'Active'],
      data: [
        { label: 'Active', value: true },
        { label: 'In-active', value: false },
      ],
    },
  },

  searchFormFields: {
    name: {
      positionInForm: 3,
      label: 'Name',
      name: 'name',
      viewCell: 'name',
      cell: (row: any) => row.name,
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Name is required'),
      showInForm: true,

      showInSearch: true,
      colSize: 12,
    },

    username: {
      positionInForm: 4,
      label: 'Username',
      name: 'username',
      viewCell: 'username',
      cell: (row: any) => row.username,
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Username is required'),
      showInForm: true,
      showInSearch: true,
      colSize: 12,
    },

    email: {
      positionInForm: 4,
      label: 'Email',
      name: 'email',
      viewCell: 'email',
      cell: (row: any) => row.email,
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string()
        .email('Please Enter Valid Email')
        .required('Please Enter Your Email'),
      showInForm: true,
      showInSearch: true,
      colSize: 12,
    },

    mobile: {
      positionInForm: 4,
      label: 'Phone',
      name: 'mobile',
      viewCell: 'mobile',
      cell: (row: any) => row.mobile,
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string()
        .required('Please Enter Your Phone Number')
        .max(14, 'Invalid phone number')
        .matches(/^(?:\+88|88)?(01[0-9]\d{8})$/, 'Invalid phone number'),
      showInForm: true,
      showInSearch: true,
      colSize: 12,
    },

    user_role: {
      positionInForm: 19,
      label: 'User Role',
      name: 'user_role',
      type: 'radio',
      default: '',
      isMulti: false,
      isRequired: true,
      validation: Yup.string().required('Is Required?'),
      showInForm: false,
      viewCell: (row: any) => row?.role?.map((item: any, index: number) => {
        return (item + `${index === row.role.length - 1 ? '' : ', '}`)
      }),
      cell: (row: any) => row?.role?.map((item: any, index: number) => {
        return (item.charAt(0).toUpperCase() + item.slice(1) + `${index === row.role.length - 1 ? '' : ', '}`)
      }),
      showInSearch: true,
      colSize: 4,
      dataApi: {
        url: url.GET_ROLES_LIST,
        key: 'name',
        label: 'name',
        label2: '',
      },
      data: [],
    },

    status: {
      positionInForm: 20,
      label: 'Active Status',
      name: 'status',
      type: 'radio',
      default: 'Active',
      isMulti: false,
      isRequired: true,
      validation: Yup.string().required('Is Required?'),
      showInForm: true,
      viewCell: (row: any) => (row.status ? 'Active' : 'Inactive'),
      cell: (row: any) => (row.status ? 'Active' : 'Inactive'),
      showInSearch: true,
      colSize: 4,
      // data: ['In-active', 'Active'],
      data: [
        { label: 'Active', value: 'Active' },
        { label: 'In-active', value: 'In-active' },
      ],
    },
  },
};

export default formObj;
