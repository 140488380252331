import { Departments } from 'src/pages/Setup/Department';
import { AddDepartment } from 'src/pages/Setup/Department/AddDepartment';
import { EditDepartment } from 'src/pages/Setup/Department/EditDepartment';
import UpdatedDepartmentList from 'src/pages/Setup/Department/UpdatedDepartmentList';

export const departmentSetupRoutes = [
  // department setup
  {
    path: '/setup/department/',
    component: UpdatedDepartmentList,
  },
  {
    path: '/setup/department/update/:id',
    component: EditDepartment,
  },
  {
    path: '/setup/department/add',
    component: AddDepartment,
  },
];
