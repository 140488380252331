import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Alert, Button, Col, Form, Label, Row } from 'reactstrap';
import { AuthLayout, InputField } from 'src/components';
import { useAppSelector } from 'src/rtk/app/hook';
import { useLoginMutation } from 'src/rtk/features/auth/authApi';
import * as Yup from 'yup';
interface LoginProps {}

const Login = ({}: LoginProps) => {
  const signUser = useAppSelector((state) => state.auth);
  const history = useHistory();
  const [signInUser, { data, isLoading, isSuccess, isError, error }] =
    useLoginMutation();

  const [showPassword, setShowPassword] = useState(true);

  const user = data?.data;
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(true);

  useEffect(() => {
    if (error !== undefined) {
      const errorData = JSON.stringify(error);
      const errorDataParsed = JSON.parse(errorData);
      setIsEmailVerified(errorDataParsed?.data?.email_verified ?? true);
    }
  }, [error]);

  const err = error as CustomError;

  useEffect(() => {
    if (isSuccess && user?.token) {
      if(signUser?.user?.details?.profile_approval_status === 'Draft'){
        history.push('/profile-update');
      }
      else{
        history.push('/dashboard');
      }
    }
    if (signUser?.token && signUser?.user?.name) {
      if(signUser?.user?.details?.profile_approval_status === 'Draft'){
        history.push('/profile-update');
      }
      else{
        history.push('/dashboard');
      }
    }
  }, [isSuccess]);

  const initialValues: SignInUser = {
    login_type: '',
    username: '',
    password: '',
    token_name: 'testing',
    remember_me: false,
  };

  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      username: Yup.string().required('Username is required'),
      password: Yup.string().required('Please Enter Your Password'),
      login_type: Yup.string().required('Please Select User Type'),
    }),
    onSubmit: (values) => {
      signInUser(values);
    },
  });
  document.title = 'Login | BCPS';

  return (
    <AuthLayout>
      <div className="auth-content my-auto">
        {isError ? (
          <Alert color="danger">
            <div className="d-flex justify-content-between align-items-center">
              {err.message}
              {!isEmailVerified && (
                <Link to="/resend-verification-email">
                  <span className="btn btn-sm btn-primary text-white">
                    Resend Verification Link
                  </span>
                </Link>
              )}
            </div>
          </Alert>
        ) : null}
        <Form
          className="custom-form pt-2"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Row className="justify-content-center">
            <Col>
              <div className="mb-3">
                <Label className='form-label required-field'>User Type</Label>
                <InputField
                  name="login_type"
                  type="select"
                  onChange={handleChange}
                  value={values.login_type}
                  options={[
                    { value: 'Trainee', label: 'Trainee' },
                    { value: 'Supervisor', label: 'Supervisor' },
                    { value: 'Fellow', label: 'Fellow' },
                  ]}
                  invalid={{ errors, touched }}
                />
              </div>

              <div className="mb-3">
                <Label className="form-label required-field">
                  Username{' '}
                  <i
                    className="fas fa-info-circle"
                    title="Enter your valid BMDC Reg. No. as Username."
                  ></i>
                </Label>
                <InputField
                  name="username"
                  type="text"
                  placeholder="Example: 39692"
                  onChange={handleChange}
                  value={values.username}
                  invalid={{ errors, touched }}
                />
              </div>

              <div className="mb-3">
                <Label className="form-label required-field">Password</Label>
                <div className="input-group password-input">
                  <InputField
                    name="password"
                    type={showPassword ? 'password' : 'text'}
                    placeholder="Enter Password"
                    onChange={handleChange}
                    value={values.password}
                    invalid={{ touched, errors }}
                  />
                  <Button
                    color="light"
                    size="sm"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <i
                      className={`${
                        showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'
                      }`}
                    />
                  </Button>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="remember_me"
                      name="remember_me"
                      onChange={handleChange}
                      checked={values.remember_me}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="remember_me"
                    >
                      Remember me
                    </label>
                  </div>

                  <div className="mt-3 d-grid">
                    <button
                      className="btn btn-primary btn-block d-flex gap-2 justify-content-center align-items-center"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading && (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      <span>Log In</span>
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Form>

        <div className="mt-3 text-center">
          <p className="text-muted mb-2">
            New User ?
            <Link to="/registration" className="btn btn-sm btn-outline-primary fw-semibold ms-3">
              Register
            </Link>
            {/* <Link to="/institute-registration" className="text-primary fw-semibold ms-3">
              Register as Institute Admin
            </Link> */}
          </p>
          <p className="text-muted mb-0">
            Forgot Your Password ?
            <Link
              to="/forget-password"
              className="btn btn-sm btn-outline-primary fw-semibold ms-3"
            >
              Recover
            </Link>
          </p>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Login;
