import { Button, Container } from "reactstrap";

const NotFound = () => {

    return (
        <div className="not-found-container vh-100">
            <Container className="text-center">
                <h1 className="display-1">404</h1>
                <h2>Page Not Found</h2>
                <p className="lead">
                    Oops! The page you are looking for does not exist.
                </p>
                <div className="animation">
                    <img src="https://media.tenor.com/eDchk3srtycAAAAi/piffle-error.gif" alt="Funny Animation" />
                </div>
                <Button variant="primary" href="/">
                    Go to Homepage
                </Button>
            </Container>
        </div>
    );
};
export default NotFound;
