import React from 'react';
import { Button, Card, CardBody, Table } from 'reactstrap';

export const WorkExperienceTable = () => {
  return (
    <Card>
      <CardBody>
        <div className="table-responsive">
          <Table className="table table-striped table-hover mb-0">
            <thead>
              <tr>
                <th>Sl</th>
                <th>Designation</th>
                <th>Institute</th>
                <th>Department</th>
                <th>From</th>
                <th>To</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="d-flex gap-2">
                  <Button color="success" size="sm">
                    Edit
                  </Button>
                  <Button color="danger" size="sm">
                    Delete
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};
