import { useEffect, useState } from 'react';
import { useAppDispatch } from 'src/rtk/app/hook';
import { userLoggedIn } from 'src/rtk/features/auth/authSlice';

const useAuthCheck = () => {
    const [authCheck, setAuthCheck] = useState(false);

    const dispatch = useAppDispatch();
    useEffect(() => {
        const localAuth = localStorage.getItem('bcps-auth-token-and-user');

        if (localAuth) {
            const auth = JSON.parse(localAuth);
            if (auth.token && auth.user) {
                dispatch(userLoggedIn(auth));
            }
        }
        setAuthCheck(true);
    }, [dispatch, setAuthCheck]);

    return authCheck;
};

export default useAuthCheck;
