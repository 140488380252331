import html2pdf from 'html2pdf.js';
import moment from 'moment';
import { useEffect } from 'react';

export const MiniCexPDFWrapper = (props: any) => {

    const { data, miniCexAssessmentData, performanceNumber, options, setPrintId, setFlag } = props;

    useEffect(() => {
        setFlag('');
        setPrintId('');
    }, []);

    const feedBackTrainee = data?.feadbacks?.find((item: any) => item?.type === 'mini-cex-trainee');
    const feedBackAssesors = data?.feadbacks?.find((item: any) => item?.type === 'mini-cex-supervisor');

    let html = `
            <html>
                <style>   
                    
                    body {
                        margin: 0;
                        color: #000;
                        background-color: #fff;
                    }

                    .rounded-pill{
                        padding-right: .6em;
                        padding-left: .6em;
                    } 

                    .circleWithoutColor {
                        width: 23px;
                        height: 23px;
                        border: 1px solid gray;
                        line-height: 22px;
                        border-radius: 50%;
                        font-weight: bold;
                        text-align: center;
                    }
                    .circleWithColor {
                        width: 23px;
                        height: 23px;
                        border: 1px solid gray;
                        line-height: 22px;
                        border-radius: 50%;
                        font-weight: bold;
                        background: green;
                        color: white;
                        text-align: center;
                    }

                </style>  
                        
                <div>
                    <div class='text-center'>
                        <strong class='fs-4'>Mini Clinical Evaluation Exercise (MINI-CEX)</strong>
                    </div>              
                    
                    <div class='d-flex justify-content-between mt-2'>
                        <div>                               
                            <div><strong>Name of the Institution : </strong>  ${data?.trainee_info?.current_session_and_supervisor_info?.supervisor?.institute_name ?? ''}</div>
                        </div>
                        <div>                         
                            <div><strong>Date of Examination :  </strong>${moment(miniCexAssessmentData?.date_of_assessment).format('DD/MM/YYYY') ?? ''}</div>                                                                       
                        </div>
                    </div>

                    <div>-----------------------------------------------------------------------------------------------------------------------</div>

                    <div class='mt-2'><strong>1. Trainee Information</strong></div>
                    <div class='row my-3'>
                        <div class='col-sm-6'>
                            <div>Name of the Trainee : ${data?.trainee_info?.personal_info?.full_name ?? ''}</div>
                            <div>BMDC Reg No : ${data?.trainee_info?.bmdc_registration_no ?? ''}</div>
                            <div>Speciality : ${data?.trainee_info?.subject?.subject_name ?? ''}</div>
                        </div>
                        <div class='col-sm-6'>                           
                            <div>Program : FCPS Part-2</div>
                            <div>Years of Training : ${data?.trainee_info?.current_session_and_supervisor_info?.slot_info?.name ?? ''}</div>
                        </div>
                    </div>

                    <div><strong>2. Assessor’s Information</strong></div>
                    <div class='row my-3'>
                        <div class='col-sm-6'>
                            <div>Name of the assessor : ${data?.trainee_info?.current_session_and_supervisor_info?.supervisor?.name ?? ''}</div>
                            <div>Assessor’s Speciality : ${data?.trainee_info?.current_session_and_supervisor_info?.supervisor?.department_name ?? ''}</div>
                            <div>Assessor’s position: : ${data?.trainee_info?.current_session_and_supervisor_info?.supervisor?.designation ?? ''}</div>
                        </div>
                        <div class='col-sm-6'>
                            <div>Assessor’s phone number : ${data?.trainee_info?.current_session_and_supervisor_info?.supervisor?.mobile ?? ''}</div>
                            <div>Assessor’s email : ${data?.trainee_info?.current_session_and_supervisor_info?.supervisor?.email ?? ''}</div>
                        </div>
                    </div>
                    <div><strong>3. Patient Information</strong></div>
                    <div class='row my-3'>
                        <div class='col'>
                            <div>Patient Name : ${miniCexAssessmentData?.patient_name ?? ''}</div>
                            <div>Address of the Patient : ${miniCexAssessmentData?.patient_address ?? ''}</div>
                            <div>Diagnosis/Problem : </div>                           
                            <div>${miniCexAssessmentData?.diagnosis_of_the_case ?? ''}</div>                           
                        </div>
                        <div class='col'>
                            <div>Reg. No. of the Patient : ${miniCexAssessmentData?.patient_reg_no ?? ''}</div>
                            <div>Age : ${miniCexAssessmentData?.patient_age ?? ''}</div>
                            <div>Gender : ${miniCexAssessmentData?.patient_gender ?? ''}</div>
                            <div>Setting : ${miniCexAssessmentData?.competency_setting ?? ''}</div>
                            <div>Complexity : ${miniCexAssessmentData?.complexity_level ?? ''}</div>
                        </div>
                    </div>

                    <div>
                        <div><strong>4. Brief case description/Case summary/Salient feature : </strong>(Provided by the trainee)</div>
                        <div>${miniCexAssessmentData?.case_description ?? ''}</div>
                    </div>

                    <div class='my-3'>
                        <strong>5. Input Validation  </strong>
                    </div>

                    <div>
                        Input Validated by Assessor/Supervisor : <strong>${miniCexAssessmentData?.assessments?.input_validation_supervisor === 1 ? 'Verified' : 'Not Verified'}</strong> 
                    </div>
                    <div class='my-3'>
                        <strong>6. Assesment</strong>
                        <table class='table table-bordered border-dark table-sm'>
                            <thead>
                                <tr>
                                    <th>Sl.</th>
                                    <th>Competencies</th>
                                    <th>Unsatisfactory</th>
                                    <th>Satisfactory</th>
                                    <th>Superior</th>
                                    <th>Not Observed</th>
                                    <th>Remarks/Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${miniCexAssessmentData?.assessments?.details?.map((item: any, index: number) =>
                                `<tr key=${item.id}>
                                    <td class='text-dark'>${++index}</td>
                                    <td>${item?.competence}</td>
                                    <td>
                                        <div class='d-flex gap-1'> 
                                            ${+item?.perform === 1 ?
                                                `<div class='circleWithColor'>
                                                    1
                                                </div>`:
                                                `<div class='circleWithoutColor'>
                                                    1
                                                </div>`
                                            }
                                            ${+item?.perform === 2 ?
                                                `<div class='circleWithColor'>
                                                    2
                                                </div>`:
                                                `<div class='circleWithoutColor'>
                                                    2
                                                </div>`
                                            }
                                            ${+item?.perform === 3 ?
                                                `<div class='circleWithColor'>
                                                    3
                                                </div>`:
                                                `<div class='circleWithoutColor'>
                                                    3
                                                </div>`
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div class='d-flex gap-1'> 
                                            ${+item?.perform === 4 ?
                                                `<div class='circleWithColor'>
                                                    4
                                                </div>`:
                                                `<div class='circleWithoutColor'>
                                                    4
                                                </div>`
                                            }
                                            ${+item?.perform === 5 ?
                                                `<div class='circleWithColor'>
                                                    5
                                                </div>`:
                                                `<div class='circleWithoutColor'>
                                                    5
                                                </div>`
                                            }
                                            ${+item?.perform === 6 ?
                                                `<div class='circleWithColor'>
                                                    6
                                                </div>`:
                                                `<div class='circleWithoutColor'>
                                                    6
                                                </div>`
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div class='d-flex gap-1'> 
                                            ${+item?.perform === 7 ?
                                                `<div class='circleWithColor'>
                                                    7
                                                </div>`:
                                                `<div class='circleWithoutColor'>
                                                    7
                                                </div>`
                                            }
                                            ${+item?.perform === 8 ?
                                                `<div class='circleWithColor'>
                                                    8
                                                </div>`:
                                                `<div class='circleWithoutColor'>
                                                    8
                                                </div>`
                                            }
                                            ${+item?.perform === 9 ?
                                                `<div class='circleWithColor'>
                                                    9
                                                </div>`:
                                                `<div class='circleWithoutColor'>
                                                    9
                                                </div>`
                                            }
                                        </div>
                                    </td>
                                    <td>${+item?.perform === 0 ? 'Yes' : '-'}</td>
                                    <td>${item?.descriptor ?? ''}</td>
                                </tr>`
                                ).join('')}                            
                            </tbody>
                        </table>
                    </div>

                    <div class='my-3'>
                        <div><strong>7. Overall Perfromance : </strong>${miniCexAssessmentData?.assessments?.overall_performance ?? ''}</div>
                    </div>

                    <div class='border border-dark mt-1 py-1 px-2'>
                        <p><strong>Comment of Areas of Strength of the trainee</strong></p>
                        <p>${miniCexAssessmentData?.assessments?.areas_of_strength ?? ''}</p>
                    </div>

                    <div class='border border-top-0 border-dark py-1 px-2'>
                        <p><strong>Suggestions for further Development/Improvement</strong></p>
                        <p>${miniCexAssessmentData?.assessments?.suggestions_for_development}</p>
                    </div>

                    <div class='row my-3'>
                        <div class='col-sm-12'>
                            <table class='table table-bordered'>
                                <thead>
                                    <tr>
                                        <th>Total Mini-CEX Evaluation Time: ${miniCexAssessmentData?.assessments?.evaluation_time ?? ''} Minutes</th>
                                        <th>Time spent giving Feedback: ${miniCexAssessmentData?.assessments?.feedback_time ?? ''} Minutes</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                    <div>
                        <div class='row'>
                            <div class='col-sm-5'>
                                <Label>Assessor Satisfaction using Mini-CEX</Label>
                            </div>
                            <div class='col-sm-7'>
                                <div class='d-flex gap-2'> 
                                    <span class='fw-bold'>LOW</span>

                                    ${performanceNumber?.map((item: any) => {
                                        return (
                                            item === feedBackAssesors?.satisfactory_label ? `<div class='circleWithColor'>${item}</div>` : `<div class='circleWithoutColor'>${item}</div>`
                                        )
                                    }).join('')}
                                    <span class='fw-bold'>HIGH</span>
                                </div>
                            </div>
                        </div>

                        <div class='row'>
                            <div class='col-sm-5'>
                                <Label>Trainee Satisfaction using Mini-CEX</Label>
                            </div>
                            <div class='col-sm-7'>
                                <div class='d-flex gap-2'> 
                                    <span class='fw-bold'>LOW</span>

                                    ${performanceNumber?.map((item: any) => {
                                        return (
                                            item === feedBackTrainee?.satisfactory_label ? `<div class='circleWithColor'>${item}</div>` : `<div class='circleWithoutColor'>${item}</div>`
                                        )
                                    }).join('')}  
                                    <span class='fw-bold'>HIGH</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class='row mt-5'>
                        <div class='col-sm-6'>
                            <div>............................................................</div>
                            <div>Assessor’s Signature with date </div>
                            <div>Name : ${data?.trainee_info?.current_session_and_supervisor_info?.supervisor?.name}</div>
                            <div>Designation : ${data?.trainee_info?.current_session_and_supervisor_info?.supervisor?.designation}</div>
                            <div>Institute : ${data?.trainee_info?.current_session_and_supervisor_info?.supervisor?.institute_name}</div>
                        </div>
                        <div class='col-sm-6'>
                            <div>............................................................</div>
                            <div>Trainee Signature with date </div>
                            <div>Name : ${data?.trainee_info?.personal_info?.full_name}</div>
                            <div>Designation : </div>
                            <div>Institute : ${data?.trainee_info?.current_session_and_supervisor_info?.supervisor?.institute_name}</div>
                        </div>
                    </div>
                </div> 
            </html>               
        `;

    html2pdf()
        .from(html)
        .set(options)
        .toPdf()
        .get('pdf')
        .then(function (pdf: any) {
            var totalPages = pdf.internal.getNumberOfPages();

            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                pdf.setFontSize(10);
                pdf.setTextColor(150);
                pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() / 2.3), (pdf.internal.pageSize.getHeight() - 2.0));
            }

            window.open(pdf.output('bloburl'), '_blank');
        });


    return null;
};