import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Card, CardBody, Col, Container, Modal, Row } from 'reactstrap';
import { Button } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import store from 'src/rtk/app/store';
import {
  useGetThesisDissertationFileEntryListQuery,
  useGetThesisDissertationFormSetupListQuery,
  useGetThesisDissertationTitleListQuery,
  useGetThesisEntryListForTraineeQuery,
} from 'src/rtk/features/fileUploadForThesis/thesisDissertationFileEntryApi';
import { DynamicInputField } from './DynamicInputField';
import ListGroupContent from './ListGroupContent';

const NewThesisEntry = () => {

  const [activeStates, setActiveStates] = useState<any>({});
  const [toggleStates, setToggleStates] = useState<any>({});
  const [dynamicInputFields, setDynamicInputFields] = useState<any>({});
  const [currentActiveStateId, setCurrentActiveStateId] = useState<string>('');
  const [formObj, setFormObj] = useState<ThesisDissertationEntryFormSetup[]>([]);
  const [thesisEntryDetails, setThesisEntryDetails] = useState<any>({});
  const [noteModalShow, setNoteModalShow] = useState<boolean>(false);
  const [typeOfNote, setTypeOfNote] = useState<string>('');

  let counter: number = 0;
  let childFullIndex: number = 0;
  const lastIndex: number = (Object.keys(dynamicInputFields)?.length)!;

  const currentUser = store.getState().auth.user;

  const {
    data: thesisDissertationFormSetups,
    isSuccess: isThesisDissertationFormSetupsSuccess,
    isLoading: isThesisDissertationFormSetupsLoading,
    isError: isThesisDissertationFormSetupsError,
    error: thesisDissertationFormSetupsError,
  } = useGetThesisDissertationFormSetupListQuery();

  const {
    data: thesisDissertationTitleList,
    isSuccess: isThesisDissertationTitleListSuccess,
    isLoading: isThesisDissertationTitleListLoading,
    isError: isThesisDissertationTitleListError,
    error: thesisDissertationTitleListError,
  } = useGetThesisDissertationTitleListQuery();

  const {
    data: thesisEntryListForTrainee,
    isSuccess: isThesisEntryListForTraineeSuccess,
    isLoading: isThesisEntryListForTraineeLoading,
    isError: isThesisEntryListForTraineeError,
    error: thesisEntryListForTraineeError,
  } = useGetThesisEntryListForTraineeQuery();

  const {
    data: thesisDissertationFileList,
    isSuccess: isThesisDissertationFileListSuccess,
    isLoading: isThesisDissertationFileListLoading,
    isError: isThesisDissertationFileListError,
    error: thesisDissertationFileListError,
  } = useGetThesisDissertationFileEntryListQuery();

  const handleAllActiveStates = (formObj: any, dataExists: boolean, newActiveStates: any, newToggleStates: any, newDynamicInputFields: any, newThesisEntryDetails: any) => {
    formObj?.map((f: any, index: number) => {
      if (!f?.child?.length) {
        if (counter === 0) {
          newActiveStates[f?.id] = true;
        } else {
          newActiveStates[f?.id] = false;
        }

        newDynamicInputFields[f?.id] = {
          id: f?.id,
          sl_no: f?.sl_no,
          column_name: f?.column_name,
          data_type: f?.data_type,
          has_file: f?.has_file,
          publish_status: f?.publish_status,
          required_ind: f?.required_ind,
          input_ind: f?.input_ind,
          status: f?.status,
          child: f?.child,
          parent_id: f?.parent_id,
        };

        if (!dataExists) {
          newThesisEntryDetails[f?.id] = {
            thesis_dissertation_setup_id: f?.id,
            thesis_setup_column_name: f?.column_name,
            details_text: '',
            details_file: '',
            comments_text: '',
            plagarism: '',
            file_array: [],
          };
        }

        counter++;
      } else {
        if (childFullIndex === 0) {
          newToggleStates[f?.id] = true;
        } else {
          newToggleStates[f?.id] = false;
        }

        newActiveStates[f?.id] = false;
        newDynamicInputFields[f?.id] = {
          id: f?.id,
          sl_no: f?.sl_no,
          column_name: f?.column_name,
          data_type: f?.data_type,
          has_file: f?.has_file,
          publish_status: f?.publish_status,
          required_ind: f?.required_ind,
          input_ind: f?.input_ind,
          status: f?.status,
          child: f?.child,
          parent_id: f?.parent_id,
        };

        handleAllActiveStates(
          f?.child,
          dataExists,
          newActiveStates,
          newToggleStates,
          newDynamicInputFields,
          newThesisEntryDetails,
        );

        childFullIndex++;
      }
    });

    if (dataExists) {
      thesisEntryListForTrainee?.data?.details?.forEach((thesisEntry: any, index: number) => {
        newThesisEntryDetails[thesisEntry?.thesis_dissertation_setup_id] = {
          thesis_dissertation_setup_id: thesisEntry?.thesis_dissertation_setup_id ?? '',
          thesis_setup_column_name: thesisEntry?.thesis_setup_column_name ?? '',
          details_text: thesisEntry?.details_text ?? '',
          details_file: thesisEntry?.details_file ?? '',
          comments_text: thesisEntry?.comments_text ?? '',
          plagarism: thesisEntry?.plagarism ?? '',
          file_array: JSON.parse(thesisEntry?.file_array) instanceof Array &&
            JSON.parse(thesisEntry?.file_array)?.length > 0 ?
            JSON.parse(thesisEntry?.file_array)?.map((fileEle: any, index: number) => ({
              file: fileEle.file,
              file_name: fileEle.file_name,
            }))
            :
            [],
        };
      });
    }

    const newObj: any = {
      newActiveStates: newActiveStates,
      newToggleStates: newToggleStates,
      newDynamicInputFields: newDynamicInputFields,
      newThesisEntryDetails: newThesisEntryDetails,
    };

    return newObj;
  };

  const handlePreviousButtonClick = (e: any) => {
    // Handling the active menu item at each previous button click
    const newActiveStates: any = { ...activeStates };
    const newActiveStatesList: string[] = Object.keys(newActiveStates);
    let newActiveStateId: string = '';

    Object.keys(newActiveStates)?.forEach((activeId: string, index: number) => {
      if (activeId === currentActiveStateId && index > 0) {
        let i = index - 1;

        while (i > 0 && dynamicInputFields[newActiveStatesList[i]]?.child?.length) {
          i = i - 1;
        }

        // console.log(i);
        if (i >= 0 && !dynamicInputFields[newActiveStatesList[i]]?.child?.length) {
          newActiveStates[activeId] = false;
          newActiveStates[newActiveStatesList[i]] = true;
          newActiveStateId = newActiveStatesList[i];
        }
      }
    });

    setActiveStates(newActiveStates);

    // Handling the menu item toggling at each previous button click
    const newToggleStates: any = { ...toggleStates };
    const parentIds: string[] = [];
    const currentToggleId: string = newActiveStateId ? dynamicInputFields[newActiveStateId]?.parent_id : dynamicInputFields[currentActiveStateId]?.parent_id;
    // console.log(newActiveStateId);
    // console.log(currentToggleId);

    Object.keys(newToggleStates)?.forEach((toggleId: string, index: number) => {
      if (toggleId === currentToggleId) {
        let initialParentId: string = dynamicInputFields[currentToggleId]?.parent_id;
        while (initialParentId) {
          parentIds.push(initialParentId);
          initialParentId = dynamicInputFields[initialParentId]?.parent_id;
        }
      }
    });

    if (!newToggleStates[currentToggleId]) {
      Object.keys(newToggleStates)?.forEach((toggleId: string, index: number) => {
        if (toggleId === currentToggleId || parentIds.indexOf(toggleId) !== -1) {
          newToggleStates[toggleId] = true;
        } else {
          newToggleStates[toggleId] = false;
        }
      });
    }

    setToggleStates(newToggleStates);

    // Scrolling the page back to top
    window.scrollTo(0, 0);
  };

  const handleNextButtonClick = (e: any) => {
    // Handling the active menu item at each next button click
    const newActiveStates: any = { ...activeStates };
    const newActiveStatesList: string[] = Object.keys(newActiveStates);
    let newActiveStateId: string = '';

    Object.keys(newActiveStates)?.forEach((activeId: string, index: number) => {
      if (activeId === currentActiveStateId && index < lastIndex - 1) {
        let i = index + 1;

        while (i < lastIndex - 1 && dynamicInputFields[newActiveStatesList[i]]?.child?.length) {
          i = i + 1;
        }

        // console.log(i);
        if (i <= lastIndex - 1 && !dynamicInputFields[newActiveStatesList[i]]?.child?.length) {
          newActiveStates[activeId] = false;
          newActiveStates[newActiveStatesList[i]] = true;
          newActiveStateId = newActiveStatesList[i];
        }
      }
    });

    setActiveStates(newActiveStates);

    // Handling the menu item toggling at each next button click
    const newToggleStates: any = { ...toggleStates };
    const parentIds: string[] = [];
    const currentToggleId: string = newActiveStateId ? dynamicInputFields[newActiveStateId]?.parent_id : dynamicInputFields[currentActiveStateId]?.parent_id;
    console.log(newActiveStateId);
    console.log(currentToggleId);

    Object.keys(newToggleStates)?.forEach((toggleId: string, index: number) => {
      if (toggleId === currentToggleId) {
        let initialParentId: string = dynamicInputFields[currentToggleId]?.parent_id;
        while (initialParentId) {
          parentIds.push(initialParentId);
          initialParentId = dynamicInputFields[initialParentId]?.parent_id;
        }
      }
    });

    if (!newToggleStates[currentToggleId]) {
      Object.keys(newToggleStates)?.forEach((toggleId: string, index: number) => {
        if (toggleId === currentToggleId || parentIds.indexOf(toggleId) !== -1) {
          newToggleStates[toggleId] = true;
        } else {
          newToggleStates[toggleId] = false;
        }
      });
    }

    setToggleStates(newToggleStates);

    // Scrolling the page back to top
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (isThesisDissertationFormSetupsSuccess && !isThesisDissertationFormSetupsLoading) {
      setFormObj(thesisDissertationFormSetups?.data!);
    }
  }, [isThesisDissertationFormSetupsSuccess, isThesisDissertationFormSetupsLoading]);

  useEffect(() => {
    if (isThesisEntryListForTraineeSuccess && !isThesisEntryListForTraineeLoading && formObj?.length) {
      if (!thesisEntryListForTrainee?.data?.details?.length) {
        const {
          newActiveStates,
          newToggleStates,
          newDynamicInputFields,
          newThesisEntryDetails,
        } = handleAllActiveStates(formObj, false, {}, {}, {}, {});
        setActiveStates(newActiveStates);
        setToggleStates(newToggleStates);
        setDynamicInputFields(newDynamicInputFields);
        setThesisEntryDetails(newThesisEntryDetails);
        // console.log(newActiveStates);
        // console.log(newToggleStates);
        // console.log(newDynamicInputFields);
        // console.log(newThesisEntryDetails);
      } else {
        const {
          newActiveStates,
          newToggleStates,
          newDynamicInputFields,
          newThesisEntryDetails,
        } = handleAllActiveStates(formObj, true, {}, {}, {}, {});
        setActiveStates(newActiveStates);
        setToggleStates(newToggleStates);
        setDynamicInputFields(newDynamicInputFields);
        setThesisEntryDetails(newThesisEntryDetails);
        // console.log(newActiveStates);
        // console.log(newToggleStates);
        // console.log(newDynamicInputFields);
        // console.log(newThesisEntryDetails);

        if (thesisDissertationFileList?.data?.submission_status === 'Approve') {
          setTypeOfNote('Approval');
        } else if (thesisDissertationFileList?.data?.submission_status === 'Correction') {
          setTypeOfNote('Correction');
        } else if (thesisDissertationFileList?.data?.submission_status === 'Reject') {
          setTypeOfNote('Rejection');
        } else {
          setTypeOfNote('');
        }
      }
    }
  }, [isThesisEntryListForTraineeSuccess, isThesisEntryListForTraineeLoading, formObj]);

  useEffect(() => {
    Object.keys(activeStates).forEach((activeStateId: string, index: number) => {
      if (activeStates[activeStateId]) {
        setCurrentActiveStateId(activeStateId);
      }
    });
  }, [activeStates]);

  return (
    <div className='page-content'>
      <Container fluid>
        <div className='page-breadcrumb-wrapper'>
          <BetterBreadcrumb title='Dissertation/Thesis Entry' />
        </div>

        <Card>
          <CardBody>
            <Row>
              <Col>
                <p className='mb-0'>
                  <b>Name: </b>
                  {
                    currentUser.name
                  }
                </p>
              </Col>

              <Col>
                <p className='mb-0'>
                  <b>BMDC Reg. No.: </b>
                  {
                    currentUser.details.bmdc_registration_no
                  }
                </p>
              </Col>

              <Col>
                <p className='mb-0'>
                  <b>Guide: </b>
                  {
                    thesisDissertationTitleList?.data?.guide_name
                  }
                </p>
              </Col>

              <Col>
                <p className='mb-0'>
                  <b>Co-Guide: </b>
                  {
                    thesisDissertationTitleList?.data?.co_guide_1_name

                  }
                </p>
              </Col>
              <Col>
                <p className='mb-0'>
                  {thesisDissertationFileList?.data?.submission_status === 'Submit' ? (
                    <p className='header-basic-info'>
                      <b>Submission Status: </b> <br /><span
                        className='badge rounded-pill d-inline-block badge-applied font-size-12 fw-medium'
                        onClick={(e) => {
                          if (thesisDissertationFileList?.data?.admin_approval_note ||
                            thesisDissertationFileList?.data?.guide_approval_note ||
                            thesisDissertationFileList?.data?.co_guide1_approval_note ||
                            thesisDissertationFileList?.data?.co_guide2_approval_note ||
                            thesisDissertationFileList?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}> {(thesisDissertationFileList?.data?.admin_approval_status?.toLowerCase() === 'correction' ||
                          thesisDissertationFileList?.data?.guide_approval_status?.toLowerCase() === 'correction' ||
                          thesisDissertationFileList?.data?.co_guide1_approval_status?.toLowerCase() === 'correction' ||
                          thesisDissertationFileList?.data?.co_guide2_approval_status?.toLowerCase() === 'correction' ||
                          thesisDissertationFileList?.data?.co_guide3_approval_status?.toLowerCase() === 'correction') ?
                          'Resubmitted' : 'Submitted'}</span>
                    </p>

                  ) : thesisDissertationFileList?.data?.submission_status === 'Approve' ? (
                    <p className='header-basic-info'>
                      <b>Submission Status: </b> <br /><span
                        className='badge rounded-pill badge-approved font-size-12 fw-medium'
                        onClick={(e) => {
                          if (thesisDissertationFileList?.data?.admin_approval_note ||
                            thesisDissertationFileList?.data?.guide_approval_note ||
                            thesisDissertationFileList?.data?.co_guide1_approval_note ||
                            thesisDissertationFileList?.data?.co_guide2_approval_note ||
                            thesisDissertationFileList?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}>Approved</span>
                    </p>


                  ) : thesisDissertationFileList?.data?.submission_status === 'Reject' ? (
                    <p className='header-basic-info'>
                      <b>Submission Status: </b> <br /><span
                        className='badge rounded-pill badge-rejected font-size-12 fw-medium'
                        onClick={(e) => {
                          if (thesisDissertationFileList?.data?.admin_approval_note ||
                            thesisDissertationFileList?.data?.guide_approval_note ||
                            thesisDissertationFileList?.data?.co_guide1_approval_note ||
                            thesisDissertationFileList?.data?.co_guide2_approval_note ||
                            thesisDissertationFileList?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}>Rejected</span>
                    </p>

                  ) : thesisDissertationFileList?.data?.submission_status === 'Correction' ? (
                    <p className='header-basic-info'>
                      <b>Submission Status: </b> <br /><span
                        className='badge rounded-pill badge-correction font-size-12 fw-medium'
                        onClick={(e) => {
                          if (thesisDissertationFileList?.data?.admin_approval_note ||
                            thesisDissertationFileList?.data?.guide_approval_note ||
                            thesisDissertationFileList?.data?.co_guide1_approval_note ||
                            thesisDissertationFileList?.data?.co_guide2_approval_note ||
                            thesisDissertationFileList?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}>Correction</span>
                    </p>

                  ) : thesisDissertationFileList?.data?.submission_status === 'Draft' ? (
                    <p className='header-basic-info'>
                      <b>Submission Status: </b> <br /><span
                        className={(thesisDissertationFileList?.data?.admin_approval_status?.toLowerCase() === 'correction' ||
                          thesisDissertationFileList?.data?.guide_approval_status?.toLowerCase() === 'correction' ||
                          thesisDissertationFileList?.data?.co_guide1_approval_status?.toLowerCase() === 'correction' ||
                          thesisDissertationFileList?.data?.co_guide2_approval_status?.toLowerCase() === 'correction' ||
                          thesisDissertationFileList?.data?.co_guide3_approval_status?.toLowerCase() === 'correction') ?
                          'badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium'
                          :
                          'badge rounded-pill d-inline-block badge-draft font-size-12 fw-medium'}
                        onClick={(e) => {
                          if (thesisDissertationFileList?.data?.admin_approval_note ||
                            thesisDissertationFileList?.data?.guide_approval_note ||
                            thesisDissertationFileList?.data?.co_guide1_approval_note ||
                            thesisDissertationFileList?.data?.co_guide2_approval_note ||
                            thesisDissertationFileList?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}> {(thesisDissertationFileList?.data?.admin_approval_status?.toLowerCase() === 'correction' ||
                          thesisDissertationFileList?.data?.guide_approval_status?.toLowerCase() === 'correction' ||
                          thesisDissertationFileList?.data?.co_guide1_approval_status?.toLowerCase() === 'correction' ||
                          thesisDissertationFileList?.data?.co_guide2_approval_status?.toLowerCase() === 'correction' ||
                          thesisDissertationFileList?.data?.co_guide3_approval_status?.toLowerCase() === 'correction') ?
                          'Correction' : 'Draft'}</span>
                    </p>

                  ) : (
                    <p className='header-basic-info'>
                      <b>Submission Status: </b> <br /><span
                        className='badge rounded-pill badge-draft font-size-12 fw-medium'
                        onClick={(e) => {
                          if (thesisDissertationFileList?.data?.admin_approval_note ||
                            thesisDissertationFileList?.data?.guide_approval_note ||
                            thesisDissertationFileList?.data?.co_guide1_approval_note ||
                            thesisDissertationFileList?.data?.co_guide2_approval_note ||
                            thesisDissertationFileList?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}>{thesisDissertationFileList?.data?.submission_status ?
                          thesisDissertationFileList?.data?.submission_status
                          : thesisEntryListForTrainee?.data?.submission_status && isThesisDissertationFileListSuccess ?
                            thesisEntryListForTrainee?.data?.submission_status :
                            'Not Yet Submitted'}</span>
                    </p>
                  )
                  }
                </p>
              </Col>
              <Col>
                <p className='mb-0'>
                  {thesisDissertationFileList?.data?.admin_approval_status === 'Submit' ? (
                    <p className='header-basic-info'>
                      <b>Admin Approval Status: </b> <br /><span
                        className='badge rounded-pill d-inline-block badge-applied font-size-12 fw-medium'
                        onClick={(e) => {
                          if (thesisDissertationFileList?.data?.admin_approval_note ||
                            thesisDissertationFileList?.data?.guide_approval_note ||
                            thesisDissertationFileList?.data?.co_guide1_approval_note ||
                            thesisDissertationFileList?.data?.co_guide2_approval_note ||
                            thesisDissertationFileList?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}>Submitted</span>
                    </p>

                  ) : thesisDissertationFileList?.data?.admin_approval_status === 'Approve' ? (
                    <p className='header-basic-info'>
                      <b>Admin Approval Status: </b> <br /><span
                        className='badge rounded-pill badge-approved font-size-12 fw-medium'
                        onClick={(e) => {
                          if (thesisDissertationFileList?.data?.admin_approval_note ||
                            thesisDissertationFileList?.data?.guide_approval_note ||
                            thesisDissertationFileList?.data?.co_guide1_approval_note ||
                            thesisDissertationFileList?.data?.co_guide2_approval_note ||
                            thesisDissertationFileList?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}>Approved</span>
                    </p>


                  ) : thesisDissertationFileList?.data?.admin_approval_status === 'Reject' ? (
                    <p className='header-basic-info'>
                      <b>Admin Approval Status: </b> <br /><span
                        className='badge rounded-pill badge-rejected font-size-12 fw-medium'
                        onClick={(e) => {
                          if (thesisDissertationFileList?.data?.admin_approval_note ||
                            thesisDissertationFileList?.data?.guide_approval_note ||
                            thesisDissertationFileList?.data?.co_guide1_approval_note ||
                            thesisDissertationFileList?.data?.co_guide2_approval_note ||
                            thesisDissertationFileList?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}>Rejected</span>
                    </p>

                  ) : thesisDissertationFileList?.data?.admin_approval_status === 'Correction' ? (
                    <p className='header-basic-info'>
                      <b>Admin Approval Status: </b> <br /><span
                        className='badge rounded-pill badge-correction font-size-12 fw-medium'
                        onClick={(e) => {
                          if (thesisDissertationFileList?.data?.admin_approval_note ||
                            thesisDissertationFileList?.data?.guide_approval_note ||
                            thesisDissertationFileList?.data?.co_guide1_approval_note ||
                            thesisDissertationFileList?.data?.co_guide2_approval_note ||
                            thesisDissertationFileList?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}>Correction</span>
                    </p>

                  ) : (
                    <p className='header-basic-info'>
                      <b>Admin Approval Status: </b> <br /><span
                        className='badge rounded-pill badge-draft font-size-12 fw-medium'
                        onClick={(e) => {
                          if (thesisDissertationFileList?.data?.admin_approval_note ||
                            thesisDissertationFileList?.data?.guide_approval_note ||
                            thesisDissertationFileList?.data?.co_guide1_approval_note ||
                            thesisDissertationFileList?.data?.co_guide2_approval_note ||
                            thesisDissertationFileList?.data?.co_guide3_approval_note) {
                            setNoteModalShow(true);
                          }
                        }}>{thesisDissertationFileList?.data?.admin_approval_status ?? 'Pending'}</span>
                    </p>
                  )
                  }
                </p>
              </Col>
              {/*<Col lg={12}>*/}
              {/*  <p className='mt-2 mb-0'>*/}
              {/*    <b>Title: </b>*/}
              {/*    {*/}
              {/*      <b>{thesisDissertationTitleList?.data?.title_of_thesis}</b>*/}
              {/*    }*/}
              {/*  </p>*/}
              {/*</Col>*/}


            </Row>
          </CardBody>
        </Card>

        <Row>
          {/* <Col lg={4} md={6} sm={12}>
                        <ThesisDissertationFileUpload
                            list={thesisDissertationFileList}
                            isSuccess={isThesisDissertationFileListSuccess}
                            isLoading={isThesisDissertationFileListLoading}
                            thesisDissertationTitle={thesisDissertationTitleList?.data!}
                            thesisEntryListForTrainee={thesisEntryListForTrainee}
                        />
                    </Col> */}
          <Col sm={12}>
            <div className='protocol-entry-tab-wrapper'>
              <div className='protocol-entry-tab-nav'>
                <div className='dissertation-entry-sidebar-menu'>
                  {dynamicInputFields && activeStates && toggleStates && formObj?.length &&
                    <ListGroupContent
                      formObj={formObj}
                      dynamicInputFields={dynamicInputFields}
                      activeStates={activeStates}
                      setActiveStates={setActiveStates}
                      toggleStates={toggleStates}
                      setToggleStates={setToggleStates}
                    />}
                </div>
              </div>
              <div className='protocol-entry-tab-content'>
                {
                  currentActiveStateId !== '' ?
                    <DynamicInputField
                      dynamicInputFields={dynamicInputFields}
                      activeStates={activeStates}
                      setActiveStates={setActiveStates}
                      activeStateId={currentActiveStateId}
                      thesisDissertationTitle={thesisDissertationTitleList?.data!}
                      thesisEntryDetails={thesisEntryDetails}
                      thesisEntryListForTrainee={thesisEntryListForTrainee}
                      setThesisEntryDetails={setThesisEntryDetails}
                      toggleStates={toggleStates}
                      setToggleStates={setToggleStates}
                      thesisDissertationFile={thesisDissertationFileList}
                      isThesisDissertationFileSuccess={isThesisDissertationFileListSuccess}
                      isThesisDissertationFileLoading={isThesisDissertationFileListLoading}
                    />
                    :
                    null
                }
              </div>
              <div className={'protocol-entry-tab-navigator'}>
                <Button
                  type='button'
                  text='Previous'
                  className='btn btn-secondary start-button'
                  onClick={(e) => {
                    handlePreviousButtonClick(e);
                  }}
                ></Button>
                <Button
                  type='button'
                  text='Next'
                  className='btn btn-success end-button'
                  onClick={(e) => {
                    handleNextButtonClick(e);
                  }}
                  disabled={Object.keys(dynamicInputFields)[lastIndex - 1] === currentActiveStateId}
                ></Button>
              </div>

            </div>

          </Col>
        </Row>

        <Modal
          onOpened={function () {
            toast.dismiss();
          }}
          isOpen={noteModalShow}
          scrollable={true}
          size={'md'}
          toggle={() => {
            document.body.classList.add('no_padding');
          }}
          backdrop={'static'}
          modalTransition={{ timeout: 0 }}
          backdropTransition={{ timeout: 0 }}
        >
          <div className="modal-header bg-light">
            <h5 className="modal-title" id="staticBackdropLabel">
              {typeOfNote} Note
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setNoteModalShow(!noteModalShow);
              }}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <Row>
              <Col>
                <>
                  <table className="table table-bordered table-striped table-sm text-capitalize">
                    <tbody>
                      {
                        thesisDissertationFileList?.data?.admin_approval_note &&
                        <tr>
                          <th style={{ width: '25%' }}>Admin Approval Note</th>
                          <td>
                            {thesisDissertationFileList?.data?.admin_approval_note}
                          </td>
                        </tr>
                      }
                      {
                        thesisDissertationFileList?.data?.guide_approval_note &&
                        <tr>
                          <th style={{ width: '25%' }}>Guide Approval Note</th>
                          <td>
                            {thesisDissertationFileList?.data?.guide_approval_note}
                          </td>
                        </tr>
                      }
                      {
                        thesisDissertationFileList?.data?.co_guide1_approval_note &&
                        <tr>
                          <th style={{ width: '25%' }}>Co-Guide 1 Approval Note</th>
                          <td>
                            {thesisDissertationFileList?.data?.co_guide1_approval_note}
                          </td>
                        </tr>
                      }
                      {
                        thesisDissertationFileList?.data?.co_guide2_approval_note &&
                        <tr>
                          <th style={{ width: '25%' }}>Co-Guide 2 Approval Note</th>
                          <td>
                            {thesisDissertationFileList?.data?.co_guide2_approval_note}
                          </td>
                        </tr>
                      }
                      {
                        thesisDissertationFileList?.data?.co_guide3_approval_note &&
                        <tr>
                          <th style={{ width: '25%' }}>Co-Guide 3 Approval Note</th>
                          <td>
                            {thesisDissertationFileList?.data?.co_guide3_approval_note}
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </>
              </Col>
            </Row>
          </div>
        </Modal>
      </Container>
    </div>
  );
};

export default NewThesisEntry;
