import { apiSlice } from 'src/rtk/api/apiSlice';
import * as approvalUrl from 'src/helpers/url_helper';

export const approvalApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['Approvals'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getApprovals: builder.query<APIResponse<Approval[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${approvalUrl.GET_APPROVALS}`,
        }),
        providesTags: ['Approvals'],
      }),

      getApproval: builder.query<Approval, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${approvalUrl.GET_APPROVAL}/${id}`,
        }),
        providesTags: ['Approvals'],
      }),

      createApproval: builder.mutation<APIResponse<Approval>, Approval>({
        query: (data) => ({
          url: `${approvalUrl.CREATE_APPROVAL}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['Approvals'],
      }),

      updateApproval: builder.mutation<APIResponse<Approval>, string>({
        query: (url) => ({
          url: `${approvalUrl.UPDATE_APPROVAL}/${url}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['Approvals'],
      }),
      deleteApproval: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${approvalUrl.DELETE_APPROVAL}/${id}`,
        }),
        invalidatesTags: ['Approvals'],
      }),
    }),
  });

export const {
  useGetApprovalsQuery,
  useGetApprovalQuery,
  useCreateApprovalMutation,
  useUpdateApprovalMutation,
  useDeleteApprovalMutation,
} = approvalApi;
