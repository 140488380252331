import { useEffect, useState } from 'react';
import Select from 'react-select';
import { Container, Form, FormGroup, Label } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetLogbookEntrySetupDetailsGroupsQuery, useLazyGetDynamicFormPropertyByParticularSetupApiQuery } from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import { AddLogbookEntryDetails } from './AddLogbookEntryDetails';
import { LoadLogbookEntryDetails } from './LoadLogbookEntryDetails';

const SelectLogbookEntryDetailsGroup = () => {
  const [subject, setSubject] = useState<SubjectInfo>({} as SubjectInfo);
  const [selectedSubject, setSelectedSubject] = useState<any>('');
  const [selectedGroup, setSelectedGroup] = useState<any>('');
  const [entryDetailGroupOptions, setEntryDetailGroupOptions] = useState<
    SelectOption[]
  >([]);
  const [selectedEntryGroup, setSelectedEntryGroup] =
    useState<LogbookEntryDetailsGroup>({} as LogbookEntryDetailsGroup);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [search, setSearch] = useState('');

  // const styles = {
  //   competencyGroupBtn: {
  //     marginTop: '29px',
  //   },
  // };

  const [
    getDynamicFormFieldsAndCompetencyGroup,
    {
      data: logbookEntryFieldList,
      error: logbookEntryFieldListError,
      isLoading: isLogbookEntryFieldListLoading,
      isError: isLogbookEntryFieldListError,
      isSuccess: isLogbookEntryFieldListSuccess,
    }
  ] = useLazyGetDynamicFormPropertyByParticularSetupApiQuery();

  const {
    data: logbookEntryDetailGroups,
    error: logbookEntryDetailGroupsError,
    isError: isLogbookEntryDetailGroupsError,
    isSuccess: isLogbookEntryDetailGroupsSuccess,
  } = useGetLogbookEntrySetupDetailsGroupsQuery();

  const detailGroups: LogbookEntryDetailsGroup[] =
    logbookEntryDetailGroups?.data!;

  const { data: getSubjectInfolist } = useGetSubjectInfosQuery();
  const subjects = getSubjectInfolist?.data;

  const subjectOptions: SelectOption[] =
    subjects?.map((subject) => ({
      value: subject.id!,
      label: subject.subject_name,
    })) || [];

  // const handleSubjectSelect = (e: any) => {
  //   const { options, selectedIndex, value } = e.target;
  //   const subject = subjects?.find((subject) => subject.id === value);
  //   setSubject(subject!);
  //   setSelectedEntryGroup({ id: '' } as LogbookEntryDetailsGroup);
  // };

  // const handleGroupSelection = (e: any) => {
  //   const { options, selectedIndex, value } = e.target;
  //   const group = detailGroups?.find((detailGroup) => detailGroup.id === value);
  //   setSelectedEntryGroup(group!);
  // };

  // const handleAddCompetencyGroup = (e: any) => {
  //   setModalShow(true);
  // }; 

  useEffect(() => {
    const detailGroupsBySubjectId: LogbookEntryDetailsGroup[] =
      detailGroups?.filter((d) => d.subject_id === subject?.id);
    const detailGroupOptions: SelectOption[] =
      detailGroupsBySubjectId?.filter((detailGroup) => {
        const logbookGroupTitleList: string[] = detailGroup?.title.split(' -> ');
        return logbookGroupTitleList[3] !== 'Academic Activity';
      })?.map((detailGroup) => ({
        value: detailGroup.id!,
        label: detailGroup.title!,
      })) || [];

    setEntryDetailGroupOptions(detailGroupOptions);
  }, [subject]);

  useEffect(() => {
    const subject = subjects?.find((subject) => subject.id === selectedSubject?.value);
    setSubject(subject!);
    setSelectedEntryGroup({ id: '' } as LogbookEntryDetailsGroup);
    setSelectedGroup({ label: '', value: '' });
  }, [selectedSubject]);

  useEffect(() => {
    const group = detailGroups?.find((detailGroup) => detailGroup.id === selectedGroup?.value);
    setSelectedEntryGroup(group!);
  }, [selectedGroup]);

  useEffect(() => {
    if (selectedEntryGroup?.id) {
      getDynamicFormFieldsAndCompetencyGroup({
        id: selectedEntryGroup?.skill_type_id ? selectedEntryGroup?.skill_type_id : selectedEntryGroup?.case_type_id,
        publish_status: 0,
        status: search,
      });

      console.log(selectedEntryGroup?.id);
    }
  }, [selectedEntryGroup, search]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BetterBreadcrumb title={'Add Logbook Entry Field'} />
          <div className="row">
           <div className='col'>
             <div className="card">
               <div className="card-body">
                 <Form
                   className="custom-form"
                   onSubmit={(e) => {
                     e.preventDefault();
                   }}
                 >
                   <div className="row">
                     <div className="col-lg-3 col-md-5 col-sm-12">
                       <FormGroup className="mb-3">
                         <Label className="form-label" htmlFor="subject">Subject</Label>
                         <Select
                           isClearable={true}
                           isSearchable={true}
                           value={selectedSubject}
                           onChange={setSelectedSubject}
                           options={subjectOptions}
                           placeholder=""
                           name="subject"
                           id="subject"
                         />
                       </FormGroup>
                     </div>

                     <div className="col-lg-9 col-md-7 col-sm-12">
                       <FormGroup className="mb-3">
                         <Label className="form-label" htmlFor="group">Select Group</Label>
                         <Select
                           isClearable={true}
                           isSearchable={true}
                           value={selectedGroup}
                           onChange={setSelectedGroup}
                           options={entryDetailGroupOptions}
                           placeholder=""
                           name="group"
                           id="group"
                         />
                       </FormGroup>
                     </div>

                     {/* <div className="col-lg-2 col-md-2 col-sm-12">
                      <Button
                        type="button"
                        color='secondary'
                        text="Add Competency Group"
                        style={styles.competencyGroupBtn}
                        className="btn-sm me-2 py-2"
                        disabled={!selectedEntryGroup?.id}
                        onClick={(e) => {
                          handleAddCompetencyGroup(e);
                        }} />
                    </div> */}
                   </div>
                 </Form>

                 {selectedEntryGroup?.id ? (
                   selectedEntryGroup?.id !== '' && isLogbookEntryFieldListSuccess && !isLogbookEntryFieldListLoading ? <LoadLogbookEntryDetails key={selectedEntryGroup?.id} logbookGroup={selectedEntryGroup} logbookEntryFieldList={logbookEntryFieldList} setSearch={setSearch} /> : <AddLogbookEntryDetails />
                 ) : (
                   <AddLogbookEntryDetails />
                 )}

                 {/* <Modal
                  onOpened={function () {
                    toast.dismiss();
                  }}
                  isOpen={modalShow}
                  scrollable={true}
                  size="lg"
                  toggle={() => {
                    document.body.classList.add('no_padding');
                  }}
                  backdrop={'static'}
                  modalTransition={{ timeout: 0 }}
                  backdropTransition={{ timeout: 0 }}
                >
                  <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                      Add Competency Group
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => {
                        setModalShow(!modalShow);
                      }}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <Row>
                      <Col>
                        <AddCompetencyGroupDetails
                          lastChildId={selectedEntryGroup?.skill_type_id!}
                          groupTitle={selectedEntryGroup?.title!}
                          competencyList={competencyList}
                          setCompetencyList={setCompetencyList}
                          setModalShow={setModalShow}
                        />

                        <div className="col-sm-12  border-top border-light pt-3">
                          <FormGroup className="mb-3 text-left">
                            <Button
                              size="md"
                              color="success"
                              type="button"
                              text={`<span class="fas fa-plus" ></span> Submit`}
                              disabled={isCompetencyGroupDataLoading}
                              onClick={(e) => {
                                handleCompetencyGroupSubmission(e);
                              }}
                            />
                            <Button
                              className="ms-2"
                              size="md"
                              color="danger"
                              type="button"
                              text={`<span class="fas fa-times" ></span> Close`}
                              onClick={() => {
                                setModalShow(!modalShow);
                              }}
                            />
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Modal> */}
               </div>
             </div>
           </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default SelectLogbookEntryDetailsGroup;
