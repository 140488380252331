import { CSSProperties, MouseEventHandler } from 'react';
import { Button as BsButton, Spinner } from 'reactstrap';

type color =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'info'
  | 'warning'
  | 'danger'
  | 'dark'
  | 'link'
  | 'light';
interface Props {
  outline?: boolean;
  block?: boolean;
  color?: color;
  innerRef?: React.Ref<HTMLButtonElement>;
  size?: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  isLoading?: boolean;
  text: string;
}

const Button = ({
  outline = false,
  block = false,
  color = 'primary',
  size = 'md',
  innerRef,
  className,
  type = 'button',
  style,
  onClick,
  text = '',
  disabled = false,
  isLoading = false,
}: Props) => {
  return (
    <BsButton
      type={type}
      color={color}
      outline={outline}
      block={block}
      size={size}
      innerRef={innerRef}
      className={className}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      <div className="d-flex gap-2 align-items-center justify-content-center">
        {isLoading && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        <span dangerouslySetInnerHTML={{ __html: text }}></span>
      </div>
    </BsButton>
  );
};

export default Button;
