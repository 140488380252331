import * as url from 'src/helpers/url_helper';
import * as Yup from 'yup';
import { materialsKeyValue, objectivesKeyValue } from '../ProtoColEntry_mock/StaticFilesProtocolEntry';


const processObjectives = (objectives: any) => {
  let value: string = '';
  const objectivesObject = JSON.parse(objectives)
  objectivesObject.forEach((objective: { key: string, value: any }) => {
    const keyValue = objectivesKeyValue.find(x => x.key === objective.key)?.value;
    value = value.concat('<h6><b>' + keyValue! + "</b></h6>");
    value = value.concat('\n');
    value = value.concat(objective?.value?.value)
  });
  return value;
};
const processMaterialAndMethods = (materials: any) => {
  let value: string = '';
  const materialsObject = JSON.parse(materials)
  materialsObject.forEach((material: { key: string, value: any }) => {
    const keyValue = materialsKeyValue.find(x => x.key === material.key)?.value;
    value = value.concat('<h6><b>' + keyValue! + "</b></h6>");
    value = value.concat('\n');
    value = value.concat(material?.value?.value)
  });
  return value;
};



const formObj: any = {
  debug: true,

  listAPIUrl: url.THESIS_DISSERTATION_PROTOCOL_LIST_SUPERVISOR,
  approvalAPIUrl: url.THESIS_DISSERTATION_PROTOCOL_APPROVE_BY_SUPERVISOR,

  listUrl: '/demo/',
  addUrl: '/demo/add',
  updateUrl: '/demo/update/',
  commentsUrl: '/thesis-dissertation-protocol-entry-view/',

  showSearchForm: true,
  showCreateNewBtn: false,
  showUpdateBtn: false,
  showViewBtn: true,
  showProtocolSummaryViewBtn: true,
  showCommentsBtn: true,
  showDeleteBtn: false,
  showTableActionColumn: true,

  createNewBtnTitle: 'Create New',
  submitBtnTitle: 'Save',
  updateBtnTitle: 'Update',
  commentsBtnTitle: 'Comments',
  resetBtnTitle: 'Reset',
  draftBtnTitle: '',
  viewBtnTitle: 'Approval',
  protocolSummaryViewBtnTitle: 'Protocol Summary',
  deletBtnTitle: 'Delete',
  deletePromtMessage: 'Do you want to delete?',

  title: 'Protocol Entry List For Approval',
  breadcrumbItemForList: [{ link: '/', name: 'Dashboard' }],
  breadcrumbItemForForm: [{ link: '/', name: 'Dashboard' }],

  formFields: [],

  searchFormFields: {
    category: {
      positionInForm: 1,
      label: 'Category',
      name: 'category',
      viewCell: (row: any) => row?.title?.category || '',
      cell: (row: any) => row?.title?.category || '',
      type: 'select',
      default: 'Dissertation',
      // isMulti: false,
      isRequired: true,
      validation: Yup.string()
        .required('Category is required')
        .oneOf(['Thesis', 'Dissertation']),
      showInForm: true,

      showInSearch: true,
      colSize: 6,
      data: [
        { label: 'Thesis', value: 'Thesis' },
        { label: 'Dissertation', value: 'Dissertation' },
      ],
    },

    title_of_thesis: {
      positionInForm: 2,
      label: 'Title',
      name: 'title_of_thesis',
      viewCell: (row: any) => row?.title?.title_of_thesis || '',
      cell: (row: any) => row?.title?.title_of_thesis || '',
      type: 'text',
      default: '',
      isRequired: false,
      showInSearch: true,
      colSize: 6,
    },
    bmdc_registration_no: {
      positionInForm: 3,
      label: 'BMDC NO',
      viewCell: (row: any) => row?.bmdc_registration_no || '',
      cell: (row: any) => row?.bmdc_registration_no || '',
      name: 'bmdc_registration_no',
      type: 'text',
      default: '',
      isRequired: false,
      showInSearch: true,
      colSize: 3,
    },

    full_name: {
      positionInForm: 3,
      label: 'Trainee Name',
      name: 'full_name',
      type: 'text',
      default: '',
      isRequired: false,
      showInSearch: true,
      colSize: 3,
      viewCell: (row: any) => row?.name_of_the_researcher || '',
      cell: (row: any) => row?.name_of_the_researcher || '',
    },
    // subject_name: {
    //   positionInForm: 4,
    //   name: 'full_name',
    //   type: 'text',
    //   default: '',
    //   isRequired: false,
    //   showInSearch: true,
    //   colSize: 3,
    //   label: 'Discipline',
    //   viewCell: (row: any) => row?.registration?.subject_name || '',
    //   cell: (row: any) => row?.registration?.subject_name || '',
    // },

    mobile: {
      positionInForm: 5,
      label: 'Contact No',
      viewCell: (row: any) => row?.examinee_phone || '',
      cell: (row: any) => row?.examinee_phone || '',
      name: 'mobile',
      type: 'text',
      default: '',
      isRequired: false,
      showInSearch: true,
      colSize: 3,
    },

    introduction_context: {
      label: 'Introduction',
      viewCell: (row: any) => row.introduction_context,
    },

    relevant_faculty: {
      label: 'Relevant faculty',
      viewCell: (row: any) => row.relevant_faculty,
    },
    relevant_discipline: {
      label: 'Relevant discipline',
      viewCell: (row: any) => row.relevant_discipline,
    },
    study_design: {
      label: 'Study Design',
      viewCell: (row: any) => row.study_design,
    },
    summary: {
      label: 'Summary',
      viewCell: (row: any) => row.summary,
    },

    rationale_of_the_research: {
      label: 'Rationale of the research',
      viewCell: (row: any) => row.rationale_of_the_research,
    },

    research_question_hypothesis: {
      label: 'Research question hypothesis',
      viewCell: (row: any) => row.research_question_hypothesis === 'null' ? 'N/A' : row.research_question_hypothesis,
    },
    objectives: {
      label: 'Objectives',
      viewCell: (row: any) => processObjectives(row.objectives),
    },
    material_methods: {
      label: 'Material methods',
      viewCell: (row: any) => processMaterialAndMethods(row.material_methods),
    },
    ethical_implication: {
      label: 'Ethical implication',
      viewCell: (row: any) => row.ethical_implication,
    },

    facilities_available_at_the_place_of_the_study: {
      label: 'Facilities available at the place of the study',
      viewCell: (row: any) =>
        row.facilities_available_at_the_place_of_the_study,
    },
    other_facilites_needed: {
      label: 'Other facilities needed',
      viewCell: (row: any) => row.other_facilites_needed,
    },

    dissemination_and_use_of_findings: {
      label: 'Dissemination and use of findings',
      viewCell: (row: any) => row.dissemination_and_use_of_findings,
    },
    references: {
      label: 'References',
      viewCell: (row: any) => row.references,
    },
    others: {
      label: 'others',
      viewCell: (row: any) => row.others === 'null' ? 'N/A' : row.others,
    },
    any_other_relevant_info: {
      label: 'Any other relevant info',
      viewCell: (row: any) => row.any_other_relevant_info,
    },

    total_budget: {
      label: 'Total budget',
      viewCell: (row: any) => row.total_budget,
    },

    total_budget_text: {
      label: 'Total budget text',
      viewCell: (row: any) => row.total_budget_text,
    },

    source_of_funding: {
      label: 'Source of funding',
      viewCell: (row: any) => row.source_of_funding,
    },



    place_of_study: {
      label: 'Place of study',
      name: 'place_of_study',
      viewCell: (row: any) => row.place_of_study,
      // cell: (row: any) => row.place_of_study,
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Place of study is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
    },

    study_period: {
      label: 'Study period',
      name: 'study_period',
      viewCell: (row: any) =>
        row.study_period ? row.study_period : '',
      // cell: (row: any) =>
      //   row.study_period ? row.study_period : '',
      type: 'number',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Study period is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 3,
    },

    guide_id: {
      label: 'Guide',
      name: 'guide_id',
      viewCell: (row: any) => row?.title?.guide?.personal_info?.full_name || '',
      type: 'modal',
      default: '',
      isMulti: false,
      isRequired: true,
      validation: Yup.string().ensure().required('Guide is required!'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
      dataApi: {
        listAPIUrl: url.GET_GUIDE_COGUIDE_LIST + '?type=Guide&',
        displayLabel: (row: any) => row?.label || '',
        key: (row: any) =>
          row?.registration?.personal_info?.full_name
            ? row?.registration_no
            : false,
        label: (row: any) => {
          return row?.registration?.personal_info?.full_name || '';
        },
        showSearchForm: true,
        searchFormFields: [
          {
            label: 'BMDC NO',
            name: 'bmdc_registration_no',
            type: 'text',
            cell: (row: any) => row?.registration?.bmdc_registration_no || '',
            showInSearch: true,
          },
          {
            label: 'Name',
            name: 'full_name',
            type: 'text',
            cell: (row: any) =>
              row?.registration?.personal_info?.full_name || '',
            showInSearch: true,
          },

          {
            label: 'Institute Name',
            name: 'institute_name',
            type: 'text',
            cell: (row: any) =>
              row.registration?.employment_infos?.institute_name || '',
            showInSearch: true,
          },

          {
            label: 'Mobile',
            name: 'mobile',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.mobile || '',
            showInSearch: true,
          },
          {
            label: 'Email',
            name: 'email',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.email || '',
            showInSearch: true,
          },
        ],
      },
      data: [],
    },

    co_guide_id: {
      label: 'Co-guide',
      name: 'co_guide_id',
      type: 'modal',
      default: '',
      isMulti: true,
      isRequired: true,
      validation: Yup.array()
        .ensure()
        .required('Co-guide is required!')
        .min(1, 'Select Minimum 1.')
        .max(3, 'Select Maximum 3'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
      dataApi: {
        listAPIUrl: url.GET_GUIDE_COGUIDE_LIST + '?type=Co-Guide&',
        displayLabel: (row: any) => row?.label || '',
        key: (row: any) =>
          row?.registration?.personal_info?.full_name
            ? row?.registration_no
            : false,
        label: (row: any) => {
          return row?.registration?.personal_info?.full_name || '';
        },
        showSearchForm: true,
        searchFormFields: [
          {
            label: 'BMDC NO',
            name: 'bmdc_registration_no',
            type: 'text',
            cell: (row: any) => row?.registration?.bmdc_registration_no || '',
            showInSearch: true,
          },
          {
            label: 'Name',
            name: 'full_name',
            type: 'text',
            cell: (row: any) =>
              row?.registration?.personal_info?.full_name || '',
            showInSearch: true,
          },

          {
            label: 'Institute Name',
            name: 'institute_name',
            type: 'text',
            cell: (row: any) =>
              row.registration?.employment_infos?.institute_name || '',
            showInSearch: true,
          },

          {
            label: 'Mobile',
            name: 'mobile',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.mobile || '',
            showInSearch: true,
          },
          {
            label: 'Email',
            name: 'email',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.email || '',
            showInSearch: true,
          },
        ],
      },
      data: [],
    },

    guide_approval_note: {
      label: 'Guide approval note',
      viewCell: (row: any) => row?.guide_approval_note || '',
    },

    guide_approval_status: {
      label: 'Guide Approval Status',
      viewCell: (row: any) => row?.guide_approval_status || '',
      cell: (row: any) => {
        switch (row?.guide_approval_status) {

          case 'Approve':
            return (
              <div className="badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium">
                {'Approved'}
              </div>
            );
          case 'Pending':
            return (
              <div className="badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium">
                {'Pending'}
              </div>
            );
          case 'Correction':
            return (
              <div className="badge rounded-pill d-inline-block  badge-draft font-size-12 fw-medium">
                {row?.guide_approval_status}
              </div>
            );
          case 'Reject':
            return (
              <div className="badge rounded-pill d-inline-block  badge-rejected font-size-12 fw-medium">
                {'Rejected'}
              </div>
            );

          default:
            return (
              <div className="badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium">
                {'Pending'}
              </div>
            );
        }
      },
      showInSearch: false,
    },

    co_guide_view: {
      label: 'Co-Guide',
      viewCell: (row: any) => {
        return (
          <div className="d-flex">
            {row?.co_guide_id_1 && (
              <div className="m-1 p-2 border rounded  ">
                <div>Name: {row?.title?.co_guide_1_name || ''}</div>
                <div>Note: {row?.co_guide1_approval_note || ''}</div>
                <div>
                  Approval status: {row?.co_guide1_approval_status || ''}
                </div>
              </div>
            )}
            {row?.co_guide_id_2 && (
              <div className="m-1 p-2 border rounded">
                <div>Name: {row?.title?.co_guide_2_name || ''}</div>
                <div>Note: {row?.co_guide2_approval_note || ''}</div>
                <div>
                  Approval status: {row?.co_guide2_approval_status || ''}
                </div>
              </div>
            )}
            {row?.co_guide_id_3 && (
              <div className="m-1 p-2 border rounded">
                <div>Name: {row?.title?.co_guide_3_name || ''}</div>
                <div>Note: {row?.co_guide3_approval_note || ''}</div>
                <div>
                  Approval status: {row?.co_guide3_approval_status || ''}
                </div>
              </div>
            )}
          </div>
        );
      },
    },

    submission_status: {
      label: 'Submission status',
      viewCell: (row: any) => row?.submission_status || '',
      cell: (row: any) =>
        ((row?.admin_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit') ||
          (row?.guide_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit') ||
          (row?.co_guide1_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit') ||
          (row?.co_guide2_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit') ||
          (row?.co_guide3_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit')
        )
          ? (
            <div className="badge rounded-pill d-inline-block badge-applied font-size-12 fw-medium">
              {'Resubmitted'}
            </div>
          ) :
          <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
            {row?.submission_status === 'Approve' ? 
            <span className='text-success'>Approved</span>  
            : 
            row?.submission_status === 'Submit' ? 
            <span className='text-warning'>Submitted</span> 
            :
            row?.submission_status === 'Reject' ? 
            <span className='text-danger'>Rejected</span> 
            :
            <span className='text-info'>{row?.submission_status}</span>}
          </div>
      ,
    },


  },
};

export default formObj;
