import { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import { Button } from 'src/components';
import DisplayImage from 'src/components/Common/displayImage';
import { config } from 'src/config';
import * as url from 'src/helpers/url_helper';
import { useLazyGetDataQuery } from 'src/rtk/features/common/crudApi';
import { useGetRegInfoQuery } from 'src/rtk/features/registration-approval/registrationApprovalApi';
import loader from '../../assets/images/loader.gif';

interface Props {
  isOpen: boolean;
  toggle: any;
  id: string;
  info: any;
}


const ProfileViewModal = (props: Props) => {
  const { isOpen, toggle, id, info } = props;
  const [fcpsPartOneInfoModalShow, setFcpsPartOneInfoModalShow] = useState<boolean>(false);

  const { data, isLoading } = useGetRegInfoQuery(id);
  const userInfo = data?.data || ({} as RegInfo);

  const [getFcpsPartOneDetails,
    {
      data: fcpsPartOneDetailsData,
      isLoading: isFcpsPartOneDetailsDataLoading,
      isSuccess: isFcpsPartOneDetailsDataSuccess,
      isError: isFcpsPartOneDetailsDataError,
      error: fcpsPartOneDetailsDataError,
    }
  ] = useLazyGetDataQuery();

  const columns: TableColumn<any>[] = [
    {
      name: <div>Sl. No.</div>,
      cell: (row, index) => index + 1,
      width: '80px',
    },

    {
      name: <div>Roll No.</div>,
      selector: (row) => row?.roll ? row?.roll : '-',
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Subject</div>,
      selector: (row) => row?.subject ? row?.subject : '-',
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Institute</div>,
      selector: (row) => row?.institute ? row?.institute : '-',
      sortable: false,
      wrap: true,
      width: '250px',
    },

    {
      name: <div>Passing Session</div>,
      selector: (row) => row?.passing_session ? row?.passing_session : '-',
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Passing Year</div>,
      selector: (row) => row?.passing_year ? row?.passing_year : '-',
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Result</div>,
      selector: (row) => row?.result ? row?.result : '-',
      sortable: false,
      wrap: true,
    },
  ];

  return (
    <>
      <Modal
        isOpen={isOpen && !isLoading}
        backdrop={'static'}
        toggle={toggle}
        size="lg"
      >
        <ModalHeader toggle={toggle}>Trainee Profile</ModalHeader>
        <ModalBody>
          {isLoading ? (
            <div className="overlay">
              <img src={loader} alt="Loading" height={100} width={300} />
            </div>
          ) : null}
          <div>
            <h6 className="block-heading">Personal Information</h6>
            <div className="row">
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-12">
                    <table className="table table-bordered table-striped table-sm">
                      <tbody>
                        <tr>
                          <td>
                            <strong>Name: </strong>
                            {userInfo?.personal_info?.full_name}
                          </td>
                          <td>
                            {userInfo?.personal_info?.spouse_name && (
                              <div>
                                <strong>Spouse Name: </strong>
                                {userInfo?.personal_info?.spouse_name ?? 'N/A'}
                              </div>
                            )}

                            {userInfo?.personal_info?.fathers_name && (
                              <div>
                                <strong>Father's Name: </strong>
                                {userInfo?.personal_info?.fathers_name ?? 'N/A'}
                              </div>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Mother's Name: </strong>
                            {userInfo?.personal_info?.mothers_name ?? 'N/A'}
                          </td>
                          <td>
                            <strong>Date of Birth: </strong>
                            {userInfo?.personal_info?.date_of_birth ?? 'N/A'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>NID: </strong>
                            {userInfo?.personal_info?.national_id ?? 'N/A'}
                          </td>

                          <td>
                            <strong>Mobile Number: </strong>
                            {userInfo?.personal_info?.mobile ?? 'N/A'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Email: </strong>
                            {userInfo?.personal_info?.email ?? 'N/A'}
                          </td>

                          <td>
                            <strong>Alternative Number: </strong>
                            {userInfo?.personal_info?.telephone ?? 'N/A'}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <strong>Subject: </strong>
                            {userInfo?.subject?.subject_name ?? 'N/A'}
                          </td>
                          <td></td>
                        </tr>

                        <tr>
                          <td colSpan={2}>
                            <strong className='me-3'>FCPS Part-1 History: </strong>
                            <Button
                              size="sm"
                              color="info"
                              outline={true}
                              type="button"
                              text={`View`}
                              onClick={() => {
                                getFcpsPartOneDetails(`${url.GET_FCPS_PART_1_DETAILS}/${userInfo?.bmdc_registration_no}`);
                                setFcpsPartOneInfoModalShow(true);
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-3 text-center">
                {/* <div className="img-fluid img-rounded text-center">
                <img
                  src={
                    userInfo?.personal_info?.picture
                      ? `${config?.APP_URL}${userInfo?.personal_info?.picture}`
                      : avatar
                  }
                  width="120"
                  className="img-thumbnail"
                />
              </div> */}
                <DisplayImage
                  className="img-thumbnail profile-img-width"
                  src={`${config?.APP_URL}${userInfo?.personal_info?.picture}`}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12 mb-2">
                <h6 className="block-heading">Present Address</h6>
                <table className="table table-bordered table-striped table-sm">
                  <tbody>
                    <tr>
                      <td>
                        <strong>Address: </strong>
                        {userInfo?.latest_present_address?.address_line_1 ?? ''}
                        &nbsp;
                        {userInfo?.latest_present_address?.address_line_2 ?? ''}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Upazilla: </strong>
                        {userInfo?.latest_present_address?.police_station ??
                          'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>District: </strong>
                        {userInfo?.latest_present_address?.district ?? 'N/A'}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Division: </strong>
                        {userInfo?.latest_present_address?.division ?? 'N/A'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-6 col-sm-12 mb-2">
                <h6 className="block-heading">Permanent Address</h6>
                <table className="table table-bordered table-striped table-sm">
                  <tbody>
                    <tr>
                      <td>
                        <strong>Address: </strong>
                        {userInfo?.latest_permanent_address?.address_line_1 ??
                          ''}
                        &nbsp;
                        {userInfo?.latest_permanent_address?.address_line_2 ??
                          ''}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Upazilla: </strong>

                        {userInfo?.latest_permanent_address?.police_station ??
                          'N/A'}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>District: </strong>

                        {userInfo?.latest_permanent_address?.district ?? 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Division: </strong>

                        {userInfo?.latest_permanent_address?.division ?? 'N/A'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {userInfo?.educational_infos?.length > 0 && (
              <>
                <h6 className="block-heading">
                  Education Information
                </h6>
                <Row>
                  {userInfo?.educational_infos.map((edu, index) => (
                    <div className="col-md-6" key={edu.id}>
                      <Card>
                        <CardBody>
                          <ul className="list-unstyled mb-0">
                            <li>
                              <strong>Degree: </strong>
                              {edu.name_of_the_degree}
                            </li>
                            <li>
                              <strong>Institute: </strong>
                              {edu.institute_or_university}
                            </li>

                            <li>
                              <strong>Passing Year: </strong>
                              {edu.passing_year}
                            </li>

                            <li>
                              <strong>Result: </strong>
                            </li>
                            <li>
                              <span className="">
                                <strong>Source Verify: </strong>
                                {edu?.certificate_pdf_source_verified === 'Approve' ? (
                                  <span className="badge badge-soft-success text-success">
                                    Yes
                                  </span>
                                ) : (
                                  <span className="badge badge-soft-danger text-danger">
                                    No
                                  </span>
                                )}
                              </span>
                            </li>
                          </ul>
                        </CardBody>
                      </Card>
                    </div>
                  ))}
                </Row>
              </>
            )}
            {userInfo?.training_infos?.length > 0 && (
              <>
                <h6 className="block-heading">Training Record</h6>
                <div className="row">
                  {userInfo?.training_infos?.map((training, index) => (
                    <div className="col-md-6" key={training.id}>
                      <div className="card">
                        <div className="card-body">
                          <ul className="list-unstyled mb-0">
                            <li>
                              <strong>Institute: </strong>
                              <span>{training.name_of_institute ?? 'N/A'}</span>
                            </li>

                            <li>
                              <strong>Supervisor: </strong>
                              <span>{training.supervisor_name ?? 'N/A'}</span>
                            </li>

                            <li>
                              <strong>Department: </strong>
                              <span>{training.department ?? 'N/A'}</span>
                            </li>

                            <li>
                              <strong>Duration: </strong>
                              <span className="me-2">
                                {training.duration ?? '-'}
                              </span>
                              <span>{training.duration_unit ?? '-'}</span>
                            </li>

                            <li>
                              <strong>Supervisor's Post-Grad. Degree:</strong>
                              <span className="ms-2">
                                {training.supervisor_post_graduation_degree ??
                                  'N/A'}
                              </span>
                            </li>

                            <li>
                              <strong>Document: </strong>
                              <span>
                                {training.document ? (
                                  <a
                                    className="m-0 p-0"
                                    href={config.APP_URL + training.document}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    Download
                                  </a>
                                ) : (
                                  <span className="text-muted">N/A</span>
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}

            {userInfo?.employment_infos?.length > 0 && (
              <>
                <h6 className="block-heading">Work Experience</h6>
                <div className="row">
                  {userInfo?.employment_infos?.map((employment, index) => (
                    <div className="col-md-6" key={employment.id}>
                      <div className="card">
                        <div className="card-body">
                          <ul className="list-unstyled mb-0">
                            <li>
                              <strong>Institute: </strong>
                              {employment.institute_name ?? 'N/A'}
                            </li>

                            <li>
                              <strong>Designation: </strong>
                              {employment.designation ?? 'N/A'}
                            </li>

                            <li>
                              <strong>Employment Type: </strong>
                              {employment.employment_type ?? 'N/A'}
                            </li>

                            <li>
                              <strong>Current Position: </strong>

                              {employment.current_position
                                ? 'Yes'
                                : 'No'}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </ModalBody>
      </Modal>
      <Modal
        onOpened={function () {
          toast.dismiss();
        }}
        isOpen={fcpsPartOneInfoModalShow}
        size='xl'
        toggle={() => {
          document.body.classList.add('no_padding');
        }}
        backdrop={'static'}
        modalTransition={{ timeout: 0 }}
        backdropTransition={{ timeout: 0 }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            FCPS Part-1 History
          </h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => {
              setFcpsPartOneInfoModalShow(!fcpsPartOneInfoModalShow);
            }}
          ></button>
        </div>
        <div className="modal-body">
          <Row>
            <Col sm={12}>
              <DataTable
                columns={columns}
                data={fcpsPartOneDetailsData?.data ? fcpsPartOneDetailsData?.data : []}
                // progressPending={isTrainingSummariesLoading}
                expandOnRowClicked
                highlightOnHover
                responsive
                persistTableHead
              />
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ProfileViewModal;
