import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container, Table } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetLogbookEntrySetupMastersQuery } from 'src/rtk/features/setup/logBookEntrySetupMaster/logBookEntrySetupMasterApi';
import LogbookEntrySetupMasterTableRow from './LogbookEntrySetupMasterTableRow';

export const LogbookEntrySetupMasters = () => {
  // const { data, error, isLoading, isError } = useGetLogbookEntrySetupMastersQuery();

  // const err: CustomError = error as CustomError;
  // const logbookEntrySetupMasters = data?.data || [];

  let content = <div className="text-center text-danger h6">No data available!</div>;

  // if (isLoading) {
  //   content = <div className="mt-3">Loading...</div>;
  // }
  // if (!isLoading && isError && error) {
  //   content = <div className="mt-3">{err.message}</div>;
  // }
  // if (!isLoading && !isError && logbookEntrySetupMasters?.length > 0) {
  //   content = logbookEntrySetupMasters.map((logbookEntrySetupMaster: any, index: any) => (
  //     <LogbookEntrySetupMasterTableRow logbookEntrySetupMaster={logbookEntrySetupMaster} index={index} key={logbookEntrySetupMaster.id} />
  //   ));
  // } else {
  //   content = (
  //     <tr>
  //       <td colSpan={11}>
  //         <div className="text-center text-danger h6">No data available!</div>
  //       </td>
  //     </tr>
  //   );
  // }

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Logbook Entry Setup Master List'}
          breadcrumbItem={[{ link: '/setup/logbook-entry-setup-master/add', name: 'Add Logbook Entry Setup Master' }]}
        />
        <Card>
          <CardHeader className="d-flex justify-content-end">
            <Link className="btn btn-sm btn-primary" to="/setup/logbook-entry-setup-master/add">
              Add Logbook Entry Setup Master
            </Link>
          </CardHeader>
          <CardBody>
            <div className="table-responsive">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Sl. </th>
                    <th>Setup Logbook Master Heading</th>
                    <th>Logbook Entry Setup Master Details</th>
                    <th>Particulars</th>
                    <th>Details</th>
                    <th>Note</th>
                    <th>Help</th>  
                    <th>Show</th>   
                    <th>Serial No</th>               
                    <th>Particulary Type</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
