import { useHistory } from 'react-router-dom';
import {
  Button,  
  Form,  
  FormGroup,  
  Input,  
  Label,  
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,  
} from 'reactstrap';

interface Props {
    isOpen: boolean;
    toggle: any;
    element: any;
    personalProfile: any;
}
const InstructionDeatailsModal = (props: Props) => {
  const { isOpen, toggle, element, personalProfile } = props;

  const history = useHistory();

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="md">
      <ModalHeader toggle={toggle}><i className="fa-sharp fa-solid fa-triangle-exclamation" style={{ color: "#f0d719" }} /> Warning</ModalHeader>

        <ModalBody>
            <div>                
                <div className="row justify-content-start">                    
                    <div className="col-md-12">
                        <div className="row justify-content-between">
                            <div>
                              <p style={{ fontSize: '18px' }}>You have pending tasks to be performed. Logbook cannot be closed!</p>
                            </div>
                        </div>
                    </div>                    
                </div>                                        
            </div>
        </ModalBody>

        <ModalFooter>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <Button color="secondary" onClick={toggle}>
              Close
            </Button>
            <Button color="success" onClick={() => history.push(`/trainee/training-summary/${personalProfile?.data?.id}/${element?.fcps_part1_info?.current_session_and_supervisor_info?.id}`)}>
              View Details
            </Button>
          </div>
        </ModalFooter>      
    </Modal>
  );
};

export default InstructionDeatailsModal;
