import ApplicationEntryList from "src/pages/ResearchClinicSetup/ApplicationEntryList";
import FAQViewForTrainee from "src/pages/ResearchClinicSetup/FAQViewForTrainee";
import ResearchClinicSetupEntryList from "src/pages/ResearchClinicSetup/ResearchClinicSetupEntryList";
import ResearchClinicWorkList from "src/pages/ResearchClinicSetup/ResearchClinicWorkList";
import WorkListViewByConsultant from "src/pages/ResearchClinicSetup/WorkListViewByConsultant";
import FormCMoneyReceiptResearcher from "src/pages/ResearchGrantReport/FormCMoneyReceiptResearcher";
import ResearchGrantForm from "src/pages/ResearchGrantReport/ResearchGrantForm";
import ResearchGrantList from "src/pages/ResearchGrantReport/ResearchGrantList";

export const researchClinicSetupRoutes = [
  { path: "/research-clinic-setup", component:  ResearchClinicSetupEntryList},
  { path: "/research-clinic-application", component:  ApplicationEntryList},
  { path: "/research-clinic/faq-view", component:  FAQViewForTrainee},
  { path: "/research-clinic-worklist", component:  ResearchClinicWorkList},
  { path: "/research-clinic-service", component:  WorkListViewByConsultant},

  // Research Grant

  { path: "/research-grant-entry", component:  ResearchGrantForm},
  { path: "/research-grant-list", component:  ResearchGrantList},
  { path: "/money-reciept-for-researcher/:role/:reg/:grant", component:  FormCMoneyReceiptResearcher},
];
