import html2pdf from 'html2pdf.js';
import { useEffect } from 'react';

interface Props {
  data: any;
  noticeId: any;
  setNoticeId: any;
  notice?: any;
  setNotice?: any,
}

export const UpdatedViewNoticePdf = (props: Props) => {
  const { data, noticeId, setNoticeId, notice, setNotice } = props;
  const image1 = new Image();
  image1.src = '/images/notice_header.png';
  const image2 = new Image();
  image2.src = '/images/notice_footer.png';  

  let html = `
    <style>        
        table td {
            border: 1px solid black;
        }
    </style>            
  `;

  try {
    html = html + JSON.parse(data?.notice_body)?.editorHtml;
  } catch (error: any) {
    html =
      '<h1 style={{textAlign: "center"}}><span><em><strong>Notice Body</strong></em></span></h1>';
  }

  const options = {
    margin: [32, 10, 30, 10],
    image: { type: 'png', quality: 0.98 },
    html2canvas: { scale: 2, useCORS: true },
  };

  useEffect(() => {
    if (setNotice) {
      setNotice({});
      console.log(notice);
    }
  }, []);

  if (noticeId) {    
    html2pdf()
      .from(html)
      .set(options)
      .toPdf()
      .get('pdf')
      .then(function (pdf: any) {
        var totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor(150);
          pdf.addImage(image1, "PNG", 5, 2, pdf.internal.pageSize.getWidth() - 10, 30);
          pdf.addImage(image2, "PNG", 0, pdf.internal.pageSize.getHeight() - 20, pdf.internal.pageSize.getWidth(), 10);
        }

        window.open(pdf.output('bloburl'), '_blank');
      });

      setNoticeId(''); 
      console.log(noticeId);     
  }

  return null;
};
