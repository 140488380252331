import { apiSlice } from 'src/rtk/api/apiSlice';
import * as policyUrl from 'src/helpers/url_helper';

export const policyApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['PolicySetup'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPolicySetupData: builder.query<APIResponse<any>, void>({
        query: () => ({
          method: 'GET',
          url: `${policyUrl.GET_POLICY_SETUP_DATA}`,
        }),
        providesTags: ['PolicySetup'],
      }),

      getRoom: builder.query<APIResponse<Room>, string>({
        query: (id) => ({
          url: `${policyUrl.GET_ROOM}/${id}`,
          method: 'GET',
        }),
        providesTags: ['PolicySetup'],
      }),

      createPolicySetupMaster: builder.mutation<APIResponse<any>, any>({
        query: (data) => ({
          url: `${policyUrl.CREATE_POLICY_SETUP_MASTER}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['PolicySetup'],
      }),

      updateRoom: builder.mutation<APIResponse<Room>, string>({
        query: (url) => ({
          url: `${policyUrl.UPDATE_ROOM}` + url,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['PolicySetup'],
      }),

      deleteRoom: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${policyUrl.DELETE_ROOM}/${id}`,
        }),
        invalidatesTags: ['PolicySetup'],
      }),
    }),
  });

export const {
  useCreatePolicySetupMasterMutation,
  useGetPolicySetupDataQuery,
  useGetRoomQuery,
  useUpdateRoomMutation,
  useDeleteRoomMutation,
} = policyApi;
