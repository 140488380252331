import { Link } from 'react-router-dom';
import { actionColumnStyles } from 'src/components/Common/FixedActionColumn';
import * as url from 'src/helpers/url_helper';
import * as Yup from 'yup';


const formObj: any = {
  debug: true,

  createAPIUrl: url.RTMD_BATCH_CREATE,
  updateAPIUrl: url.RTMD_BATCH_UPDATE,
  getDataByIdAPIUrl: '',
  listAPIUrl: url.RTMD_WORKSHOP_TRAINING_BATCH_LIST,

  listUrl: '/rtmd/batch/',
  addUrl: '/rtmd/batch/add',
  updateUrl: '/rtmd/attendance/form/',

  showSearchForm: true,
  showCreateNewBtn: false,
  showUpdateBtn: true,
  showViewBtn: true,
  showDeleteBtn: false,
  showTableActionColumn: false,

  createNewBtnTitle: 'Create New',
  submitBtnTitle: 'Save',
  updateBtnTitle: 'Attendance',
  resetBtnTitle: 'Reset',
  draftBtnTitle: '',
  viewBtnTitle: 'View',
  deletBtnTitle: 'Delete',
  deletePromtMessage: 'Do you want to delete?',

  title: 'Training/Workshop Attendance',
  breadcrumbItemForList: [{ link: '/', name: 'Dashboard' }],
  breadcrumbItemForForm: [
    { link: '/', name: 'Dashboard' },
    { link: '/rtmd/attendance/', name: 'Training/Workshop Attendance List' },
  ],

  formFields: {
    training_name: {
      positionInForm: 2,
      label: 'Training Title',
      name: 'training_name',
      viewCell: (row: any) => row.workshop_training.training_name,
      cell: (row: any) => row.workshop_training.training_name,
      type: 'select',
      default: '',
      // isMulti: false,
      isRequired: true,
      validation: Yup.string().ensure().required('Training Title is required!'),
      showInForm: true,
      showInSearch: true,
      colSize: 6,
      dataApi: {
        url: url.RTMD_WORKSHOP_TRAINING_LIST,
        key: 'id',
        label: 'training_name',
        label2: '',
      },
      data: [],
    },

    batch_no: {
      positionInForm: 1,
      label: 'Batch No',
      name: 'batch_no',
      viewCell: (row: any) => row.batch_no,
      cell: (row: any) => row.batch_no,
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Bbatch no is required'),
      showInForm: true,
      showInSearch: true,
      colSize: 6,
    },

    batch_user: {
      label: 'No of Participants',
      viewCell: (row: any) => Object.keys(row.batch_user).length,
      cell: (row: any) => Object.keys(row.batch_user).length,
    },

    // no_of_trainer: {
    //   label: 'No of Trainer',
    //   viewCell: (row: any) => row.trainer_id.split(',').length,
    //   cell: (row: any) => row.trainer_id.split(',').length,
    // },

    training_for: {
      label: 'Training For',
      viewCell: (row: any) => row.workshop_training.training_for,
      cell: (row: any) => row.workshop_training.training_for,
    },

    duration: {
      label: 'Duration',
      viewCell: (row: any) => row.workshop_training.duration,
      cell: (row: any) => row.workshop_training.duration,
    },

    Action: {
      label: 'Action',
      name: 'Action',
      width: '280px',
      style: actionColumnStyles,
      selector: (row: any) => row.id,
      cell: (row: any, index: any) => (
        <>
          <div>
            <Link
              to={`/rtmd/attendance/form/${row.id}`}
              className="btn btn-sm btn-info waves-effect waves-light my-1 me-1"
            >
              <i className="fas fa-edit me-1" /> Attendance
            </Link>

            <Link
              to={`/rtmd/attendance/report/${row.id}`}
              className="btn btn-sm btn-success waves-effect waves-light my-1"
            >
              <i className="fas fa-eye me-1" /> Attendance Report
            </Link>
          </div>
        </>
      ),
    },

  },
};

export default formObj;
