import {
  Modal,
  ModalBody,
  ModalHeader
} from 'reactstrap';
import { config } from 'src/config';
import avatar from '../../assets/images/users/default-avatar.png';

interface Props {
  isOpen: boolean;
  toggle: any;
  info: any;
}


const ProfileViewModal = (props: Props) => {
  const { isOpen, toggle, info } = props;
  console.log(info);
  return (
    <Modal
      isOpen={isOpen}
      backdrop={'static'}
      toggle={toggle}
      size="lg"
    >
      <ModalHeader toggle={toggle}>Profile</ModalHeader>
      <ModalBody>
        <div>
          <h6 className="block-heading">Personal Information</h6>
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-bordered table-striped table-sm">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Name: </strong>
                          {info?.registration?.personal_info?.full_name}
                        </td>
                        <td>
                          {info?.registration?.personal_info?.spouse_name && (
                            <div>
                              <strong>Spouse Name: </strong>
                              {info?.registration?.personal_info?.spouse_name ?? 'N/A'}
                            </div>
                          )}

                          {info?.registration?.personal_info?.fathers_name && (
                            <div>
                              <strong>Father's Name: </strong>
                              {info?.registration?.personal_info?.fathers_name ?? 'N/A'}
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Mother's Name: </strong>
                          {info?.registration?.personal_info?.mothers_name ?? 'N/A'}
                        </td>
                        <td>
                          <strong>Date of Birth: </strong>
                          {info?.registration?.personal_info?.date_of_birth ?? 'N/A'}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>NID: </strong>
                          {info?.registration?.personal_info?.national_id ?? 'N/A'}
                        </td>

                        <td>
                          <strong>Mobile Number: </strong>
                          {info?.registration?.personal_info?.mobile ?? 'N/A'}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Email: </strong>
                          {info?.registration?.personal_info?.email ?? 'N/A'}
                        </td>

                        <td>
                          <strong>Alternative Number: </strong>
                          {info?.registration?.personal_info?.telephone ?? 'N/A'}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Subject: </strong>
                          {info?.registration?.subject?.subject_name ?? 'N/A'}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="img-fluid img-rounded text-center">
                <img
                  src={
                    info?.registration?.personal_info?.picture
                      ? `${config?.APP_URL}${info?.registration?.personal_info?.picture}`
                      : avatar
                  }
                  width="120"
                  className="img-thumbnail"
                />
              </div>
            </div>
          </div>
          <div>
            <h6 className="block-heading">Bank Infomartion</h6>
          </div>
          <div className="row">
            <div className="col-md-12">
              <table className="table table-sm table-bordered table-striped">
                <tbody>
                  <tr>
                    <td width="50%">
                      <strong>Bank Name: </strong>
                      <span>{info?.bank_info?.bank_name}</span>
                    </td>
                    <td width="50%">
                      <strong>Branch Name: </strong>
                      <span>{info?.bank_info?.branch_name ?? 'N/A'}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Account No: </strong>
                      <span>{info?.bank_info?.account_no ?? 'N/A'}</span>
                    </td>
                    <td>
                      <strong>Routing No: </strong>
                      <span>{info?.bank_info?.routing_no ?? 'N/A'}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Role: </strong>
                      <span>
                        {info?.role ?? 'N/A'}
                      </span>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ProfileViewModal;
