import { useFormik } from 'formik';
import {
  Button,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader
} from 'reactstrap';
import * as Yup from 'yup';
import { useAppSelector } from 'src/rtk/app/hook';
import { useCreateDocumentUploadMutation } from 'src/rtk/features/myTraining/myTrainingApi';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { setFromErrors } from 'src/helpers';
import { config } from 'src/config';
import { useGetPersonalProfileQuery } from 'src/rtk/features/trainee/traineeApi';

interface Props {
  isOpen: boolean;
  toggle: any;
}

const DocumentUploadModal = (props: Props) => {
  const { isOpen, toggle } = props;

  const authUser = useAppSelector((state) => state.auth.user);

  const { data: userInfo } = useGetPersonalProfileQuery();

  const [createDocumentUpload, { isError, isSuccess, error }] = useCreateDocumentUploadMutation();
  const err: CustomError = error as CustomError;

  const initialValues: DocumentUpload = {
    registration_id: '',
    appointment_letter: '',
    joining_letter: '',
  };


  const {
    handleSubmit,
    getFieldProps,
    handleChange,
    touched,
    errors,
    values,
    resetForm,
    setErrors,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      appointment_letter: Yup.string().required('Appointment Letter is Required!'),
      joining_letter: Yup.string().required('Joining Letter is Required!'),
    }),

    onSubmit: (values: any) => {
      values.registration_id = authUser?.registration_no;
      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      createDocumentUpload(formData);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Document Uploaded Successfully!');
      toggle(true);
    }
    if (isError) {
      toast.error(err?.message || 'Something Went Wrong!');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);


  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="md">
      <ModalHeader toggle={toggle}>Document Upload</ModalHeader>

      <ModalBody>
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className='row'>
            <div className='col-md-12'>
              <Label className="form-label required-field">
                Appointment/Govt. Order (GO)/Institutional Training Order Letter
              </Label>{' '}
              {userInfo?.data?.details?.employment_not_at_work?.appointment_latter && 
              <a
                className="me-2 p-0 center"
                href={config.APP_URL + userInfo?.data?.details?.employment_not_at_work?.appointment_latter!}
                rel="noreferrer"
                target="_blank"
              >
                <span className="btn-outline-info btn-sm btn ms-2">View</span>
              </a>}
              <input
                name="appointment_letter"
                type="file"
                className="form-control"
                placeholder="Upload Certificate"
                onChange={(e: any) => {
                  setFieldValue('appointment_letter', e.target.files[0]);
                }}
              ></input>
              {errors.appointment_letter && touched.appointment_letter ? (
                  <div className="text-danger">
                    {errors.appointment_letter as String}
                  </div>
                ) : null}
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-md-12'>
              <Label className="form-label required-field">Joining Letter</Label>{' '}
              {userInfo?.data?.details?.employment_not_at_work?.joining_latter &&
              <a
                className="me-2 p-0 center"
                href={config.APP_URL + userInfo?.data?.details?.employment_not_at_work?.joining_latter!}
                rel="noreferrer"
                target="_blank"
              >
                <span className="btn-outline-info btn-sm btn ms-2">View</span>
              </a>}
              <input
                name="joining_letter"
                type="file"
                className="form-control"
                placeholder="Upload Certificate"
                onChange={(e: any) => {
                  setFieldValue('joining_letter', e.target.files[0]);
                }}
              ></input>
              {errors.joining_letter && touched.joining_letter ? (
                  <div className="text-danger">
                    {errors.joining_letter as String}
                  </div>
                ) : null}
            </div>
          </div>
          <hr />
          <div className="d-grid gap-2 d-md-flex justify-content-md-center">
            <Button color="danger" onClick={toggle}>
              Close
            </Button>

            <Button color="success" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default DocumentUploadModal;
