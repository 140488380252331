import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Button, InputField } from 'src/components';
import {
  useCreateLogbookEntryMutation,
  useGetDynamicFormPropertyByParticularQuery,
} from 'src/rtk/features/e-log-book/traineeAcademicActivitiesApi';
import * as Yup from 'yup';
import loader from '../../../../assets/images/loader.gif';

interface Field {
  name: string;
  placeholder?: string;
  type: string;
  options?: SelectOption[];
  required?: number;
}

const DynamicFormById = (props: any) => {
  const {
    dynamicFormId,
    formName,
    caseTypeName,
    skillTypeName,
    competencyGroup,
    entrySetupMasterId,
    setForm,
  } = props;

  let dynamicFormFields: any = [];
  if (dynamicFormId) {
    const { data: dynamicFormFieldsData, isError } =
      useGetDynamicFormPropertyByParticularQuery(dynamicFormId);
    dynamicFormFields = dynamicFormFieldsData?.data || [];
  }

  const history = useHistory();

  const [submitIndex, setSubmitIndex] = useState<number>(1);

  const InputFields: Field[] = dynamicFormFields.map(
    (field: any, index: number) => {
      return {
        name: `${field['column_name']}`,
        placeholder: field.details_text,
        type: field.column_value_type,
        options: [],
        required: field.required_ind,
      };
    },
  );

  //   const { data: form, isError: isFormError } = useGetLogBookMasterSetupQuery(formId);
  //   const { data: caseType, isError: isCaseTypeError } = useGetLogbookEntrySetupMasterQuery(caseTypeId);
  //   const { data: skillType, isError: isSkillTypeError } = useGetLogbookEntrySetupMasterQuery(skillTypeId);

  const [
    createLogbookEntry,
    {
      data: logbookEntry,
      error: LogbookEntryError,
      isSuccess: isLogbookEntrySuccess,
      isError: isLogbookEntryError,
      isLoading: isLogbookEntryLoading,
    },
  ] = useCreateLogbookEntryMutation();

  type FormValues = {
    [key: string]: string | number | boolean | undefined;
  };

  const initialValues = React.useMemo(() => {
    const fieldsObj = InputFields.reduce((acc: FormValues, field: Field) => {
      switch (field.type) {
        case 'text':
        case 'textarea':
        case 'email':
        case 'date':
        case 'select':
        case 'file':
        case 'radio':
          acc[`${field['name']}`] = '';
          break;
        case 'number':
          acc[field['name']] = 0;
          break;
        case 'checkbox':
          acc[field['name']] = false;
          break;
        default:
          acc[field['name']] = undefined;
          break;
      }

      return acc;
    }, {});

    return fieldsObj;
  }, [InputFields]);

  const generateValidationSchema = (inputFields: any) => {
    const validationSchema: Record<string, any> = {};

    // inputFields.forEach((field: any) => {
    //   if (field.required &&
    //     (field.type === 'text'
    //     || field.type === 'textarea'
    //     || field.type === 'date'
    //     || field.type === 'email'
    //     || field.type === 'select'
    //     || field.type === 'file'
    //     || field.type === 'radio')) {
    //         validationSchema[field['name']] = Yup.string().required(`${field['name']} is required`);
    //   }
    // });

    return Yup.object().shape(validationSchema);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema:
      InputFields.length > 0 ? generateValidationSchema(InputFields) : null,
    onSubmit: (values) => {
      console.log(values);

      const finalSubmittedValue = {
        entry_setup_master_id: entrySetupMasterId,
        heading: formName,
        details_json: JSON.stringify(
          {
            entry_setup_master_id: entrySetupMasterId,
            form: formName,
            SystemType: caseTypeName,
            TopicType: skillTypeName,
            ...values,
          },
          null,
          2,
        ),
        submit_ind: submitIndex,
        log_type: 2, // 2 for Academic Activities
      };

      createLogbookEntry({
        ...finalSubmittedValue,
      });

      // alert(JSON.stringify(values, null, 2));
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    handleReset,
    setValues,
    setFieldValue,
    getFieldProps,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = formik;

  useEffect(() => {
    if (isLogbookEntrySuccess) {
      toast.success('Logbook entry successful');
      history.push('/e-logbook/academic-activities/');
    }
    if (isLogbookEntryError) {
      toast.error('Something went wrong!');
    }
  }, [isLogbookEntrySuccess, isLogbookEntryError]);

  return (
    <>
      {isLogbookEntryLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Form
        className="custom-form"
        onSubmit={(e: any) => {
          e.preventDefault();
          handleSubmit();
        }}
        onReset={(e: any) => {
          e.preventDefault();
          setForm({ id: '' });
          resetForm();
        }}
      >
        <div className="row">
          {InputFields.map((field, index) => {
            switch (field.type) {
              case 'checkbox':
                return (
                  <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup check className="mb-3 mt-4">
                      <Label check>
                        <Input
                          type={field.type}
                          checked={!!values[field['name']]}
                          {...getFieldProps(field['name'])}
                        />{' '}
                        {field['name']}
                      </Label>
                    </FormGroup>
                  </div>
                );
              case 'File attachment select':
                return (
                  <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label className="form-label">{field['name']}</Label>
                      <InputField
                        type={field.type as InputType}
                        name={field['name']}
                        placeholder={field.placeholder}
                        onChange={(e) => {
                          setFieldValue(field['name'], e.target.files[0]);
                        }}
                        onBlur={handleBlur}
                        invalid={{ errors, touched }}
                      />
                    </FormGroup>
                  </div>
                );
              case 'select':
                return (
                  <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for={field['name']}>{field['name']}</Label>
                      <InputField
                        type={field.type as InputType}
                        placeholder={field.placeholder}
                        options={field.options}
                        invalid={{ errors, touched }}
                        {...getFieldProps(field['name'])}
                      />
                    </FormGroup>
                  </div>
                );
              default:
                return (
                  <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for={field['name']}>{field['name']}</Label>
                      <InputField
                        type={field.type as InputType}
                        placeholder={field.placeholder}
                        invalid={{ errors, touched }}
                        {...getFieldProps(`${field['name']}`)}
                      />
                    </FormGroup>
                  </div>
                );
            }
          })}

          {/* {competencyGroup ? (
          <CompetencyGroupAndDetails
            formik={formik}
            caseTypeName={caseTypeName}
            skillTypeName={skillTypeName}
            compentencyGroup={competencyGroup}
          />
        ) : null} */}

          <div className="col-sm-12">
            <FormGroup className="mb-3 text-center">
              <Button
                type="reset"
                text="Reset"
                className="btn btn-secondary me-2"
                onClick={(e) => {
                  setValues(initialValues);
                }}
              ></Button>
              <Button
                type="button"
                text="Save as Draft"
                className="btn btn-info me-2"
                onClick={(e: any) => {
                  e.preventDefault();
                  setSubmitIndex(0);
                  handleSubmit();
                }}
              ></Button>
              <Button
                type="button"
                text="Submit"
                className="btn btn-success"
                onClick={(e: any) => {
                  e.preventDefault();
                  setSubmitIndex(1);
                  handleSubmit();
                }}
              ></Button>
            </FormGroup>
          </div>
        </div>
      </Form>
    </>
  );
};

export default DynamicFormById;
