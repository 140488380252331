import {
  Modal,
  ModalBody, ModalFooter,
  ModalHeader,
} from 'reactstrap';

import { useHistory } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  toggle: any;
  username: string;
  name: string;
}

const RegistrationSuccessModal = (props: Props) => {
  const { isOpen, toggle, username, name } = props;
  const history = useHistory();
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="md" >
      <ModalHeader >Username-{username} Registered Successfully </ModalHeader>

      <ModalBody className="mt-1">
        <div>
          Welcome {name}! <br /><br />
          A verification link is sent to your email address. Please verify your email address by clicking the link. This helps keep your account secure.
          <br /><br />If you don't see the email, please check your Spam folder.

        </div>
      </ModalBody>
      <ModalFooter>
        <button type="button" className="close btn btn-danger" aria-label="Close" onClick={() => history.push('/login')}>
          <span aria-hidden="true">Close;</span>
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default RegistrationSuccessModal;
