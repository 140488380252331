import { useFormik } from "formik";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { Button, InputField } from "src/components";
import { setFromErrors } from "src/helpers";
import { useAcademicStaffInfoEntryMutation } from "src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi";
import * as Yup from 'yup';

interface AcademicStaffProps {
    departmentId?: string;
    academicStaffToEdit?: AcademicStaffInfoList;
    setModalShow?: any;
}

const initialValues: AcademicStaffInfoEntry = {
    staff_info_id: '',
    form_b_department_id: '',
    designation: '',
    total_number: 0,
    no_of_full_time: 0,
    no_of_part_time: 0,
    qualification: '',
    bmdc_reg_validity: '',
    teaching_experience: 0,
};

const AcademicStaffRecordsModal = (props: AcademicStaffProps) => {
    const { departmentId, academicStaffToEdit, setModalShow } = props;

    const [academicStaffRecordEntry, { data: academicStaffRecordEntryData, isSuccess: isAcademicStaffRecordEntryDataSuccess, isLoading: isAcademicStaffRecordEntryDataLoading, isError: isAcademicStaffRecordEntryDataError, error: academicStaffRecordEntryDataError }] =
        useAcademicStaffInfoEntryMutation();

    const err: CustomError = academicStaffRecordEntryDataError as CustomError;

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({}),

        onSubmit: (values: AcademicStaffInfoEntry) => {
            console.log(values);
            values.form_b_department_id = departmentId;
            academicStaffRecordEntry(values);
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    const handleFormReset = () => {
        resetForm();
    };

    useEffect(() => {
        if (academicStaffToEdit?.id) {
            setValues({
                staff_info_id: academicStaffToEdit?.id,
                form_b_department_id: academicStaffToEdit?.form_b_department_id,
                designation: academicStaffToEdit?.designation,
                total_number: academicStaffToEdit?.total_number,
                no_of_full_time: academicStaffToEdit?.no_of_full_time,
                no_of_part_time: academicStaffToEdit?.no_of_part_time,
                qualification: academicStaffToEdit?.qualification,
                bmdc_reg_validity: academicStaffToEdit?.bmdc_reg_validity,
                teaching_experience: +academicStaffToEdit?.teaching_experience!,
            });
        }
    }, []);

    useEffect(() => {
        if (isAcademicStaffRecordEntryDataSuccess) {
            handleFormReset();
            toast.success('Academic staff record saved successfully');
            setModalShow(false);
        }
        if (isAcademicStaffRecordEntryDataError) {
            Object.entries(err?.data).forEach((entry: any) => {
                entry[1]?.forEach((message: any) => {
                    toast.error(message);
                });
            });

            setFromErrors(err.data, setErrors);
        }
    }, [isAcademicStaffRecordEntryDataSuccess, isAcademicStaffRecordEntryDataError]);

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title">
                                    <i className="fa fa-plus me-2" />
                                    Record of academic staff
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    autoComplete="off"
                                    className="custom-form"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    onReset={(e) => {
                                        e.preventDefault();
                                        handleFormReset();
                                    }}
                                >
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="designation">Designation</Label>
                                                <InputField
                                                    type="select"
                                                    options={[
                                                        {label: 'Professor', value: 'Professor'},
                                                        {label: 'Associate Professor', value: 'Associate Professor'},
                                                        {label: 'Assistant Professor', value: 'Assistant Professor'},
                                                        {label: 'Sr. Consultant', value: 'Sr. Consultant'},
                                                        {label: 'Jr. Consultant', value: 'Jr. Consultant'},
                                                    ]}
                                                    placeholder=""
                                                    {...getFieldProps('designation')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="total_number">Total Number</Label>
                                                <InputField
                                                    type="number"
                                                    placeholder=""
                                                    {...getFieldProps('total_number')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="no_of_full_time">No. of Full Time</Label>
                                                <InputField
                                                    type="number"
                                                    placeholder=""
                                                    {...getFieldProps('no_of_full_time')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="no_of_part_time">No. of Part Time</Label>
                                                <InputField
                                                    type="number"
                                                    placeholder=""
                                                    {...getFieldProps('no_of_part_time')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="qualification">Qualification</Label>
                                                <InputField
                                                    type="text"
                                                    placeholder=""
                                                    {...getFieldProps('qualification')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="teaching_experience">Teaching Experience (in Year)</Label>
                                                <InputField
                                                    type="number"
                                                    placeholder=""
                                                    {...getFieldProps('teaching_experience')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="bmdc_reg_validity">Reg. No. Validity Up to</Label>
                                                <InputField
                                                    type="date"
                                                    placeholder=""
                                                    {...getFieldProps('bmdc_reg_validity')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} className="mt-3">
                                            <FormGroup className="mb-3 text-center">
                                                <Button
                                                    type="reset"
                                                    text="Reset"
                                                    className="btn btn-warning me-2"
                                                ></Button>
                                                <Button
                                                    type="submit"
                                                    text="Save"
                                                    className="btn btn-success me-2"
                                                    disabled={isAcademicStaffRecordEntryDataLoading}
                                                ></Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default AcademicStaffRecordsModal;
