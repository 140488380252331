import { apiSlice } from 'src/rtk/api/apiSlice';
import * as workExperienceUrl from 'src/helpers/url_helper';

export const workExperienceApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['WorkExperiences'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getWorkExperience: builder.query<WorkExperience, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${workExperienceUrl.GET_WORK_EXPERIENCE}/${id}`,
        }),
        providesTags: ['WorkExperiences'],
      }),

      createWorkExperience: builder.mutation<APIResponse<WorkExperience>, any>({
        query: (data: any) => ({
          url: `${workExperienceUrl.CREATE_WORK_EXPERIENCE}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['WorkExperiences'],
      }),

      updateWorkExperience: builder.mutation<APIResponse<WorkExperience>, any>({
        query: (url) => ({
          url: `${workExperienceUrl.UPDATE_WORK_EXPERIENCE}/${url.id}`,
          method: 'POST',
          body: url.data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['WorkExperiences'],
      }),
      deleteWorkExperience: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${workExperienceUrl.DELETE_WORK_EXPERIENCE}/${id}`,
        }),
        invalidatesTags: ['WorkExperiences'],
      }),
    }),
  });

export const {
  useGetWorkExperienceQuery,
  useCreateWorkExperienceMutation,
  useUpdateWorkExperienceMutation,
  useDeleteWorkExperienceMutation,
} = workExperienceApi;
