import * as url from 'src/helpers/url_helper';
import * as Yup from 'yup';

const formObj: any = {
  debug: true,

  createAPIUrl: url.SETUP_EXAM_TRAINING_CREATE,
  updateAPIUrl: url.SETUP_EXAM_TRAINING_UPDATE,
  getDataByIdAPIUrl: url.SETUP_EXAM_TRAINING_BY_ID,
  listAPIUrl: url.SETUP_EXAM_TRAINING_INFO_LIST+`?workshop_training=Workshop/Training`,
  deleteAPIUrl: url.SETUP_EXAM_TRAINING_DELETE,

  listUrl: '/rtmd/training/',
  addUrl: '/rtmd/training/add',
  updateUrl: '/rtmd/training/update/',

  showSearchForm: true,
  showCreateNewBtn: true,
  showUpdateBtn: true,
  showViewBtn: true,
  showDeleteBtn: true,
  showTableActionColumn: true,

  createNewBtnTitle: 'Create New',
  submitBtnTitle: 'Save',
  updateBtnTitle: 'Update',
  resetBtnTitle: 'Reset',
  draftBtnTitle: '',
  viewBtnTitle: 'View',
  deletBtnTitle: 'Delete',
  deletePromtMessage: 'Do you want to delete?',

  title: 'Training/Workshop Setup',
  breadcrumbItemForList: [{ link: '/', name: 'Dashboard' }],
  breadcrumbItemForForm: [
    { link: '/', name: 'Dashboard' },
    { link: '/rtmd/training/', name: 'Training/Workshop Setup List' },
  ],

  formFields: {
    name: {
      positionInForm: 4,
      label: 'Training Title',
      name: 'name',
      viewCell: 'name',
      cell: (row: any) => row.name,
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Name is required'),
      showInForm: true,
      showInSearch: true,
      colSize: 6,
    },

    code: {
      positionInForm: 3,
      label: 'Code',
      name: 'code',
      viewCell: 'code',
      cell: (row: any) => row.code,
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Code is required'),
      showInForm: true,

      showInSearch: true,
      colSize: 6,
    },
    training_for: {
      positionInForm: 15,
      label: 'Applicable for',
      name: 'training_for',
      viewCell: (row: any) => row.training_for,
      // cell: (row: any) => row.training_for,
      type: 'checkbox',
      isSwitch: true,
      default: 'Trainee',
      isRequired: true,
      validation: Yup.array()
        .required('Applicable for is required')
        .min(1, 'Select Minimum 1.')
        .max(2, 'Select Maximum 2'),
      showInForm: true,
      showInSearch: true,
      colSize: 12,
      data: [
        { label: 'Trainee', value: 'Trainee' },
        { label: 'Supervisor', value: 'Supervisor' },
        { label: 'Fellow', value: 'Fellow' },
        { label: 'Other', value: 'Other' },
      ],
    },

    type: {
      positionInForm: 5,
      label: 'Program Type',
      name: 'type',
      viewCell: 'type',
      cell: (row: any) => row.type,
      type: 'select',
      default: '',
      // isMulti: false,
      isRequired: true,
      validation: Yup.string()
        .required('Type is required')
        .oneOf(['Training', 'Workshop']),
      showInForm: true,

      showInSearch: true,
      colSize: 6,
      data: [
        { label: 'Training', value: 'Training' },
        { label: 'Workshop', value: 'Workshop' },
      ],
    },

    main_trainings: {
      positionInForm: 5,
      label: 'Applicable for',
      name: 'main_trainings',
      cell: (row: any) => row.training_for,
      type: 'select',
      default: '',
      isMulti: false,
      isRequired: true,
      validation: Yup.string().ensure().required('Applicable for is required!'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
      dataApi: {
        url: {
          queryUrl: url.RTMD_GET_COURSE_LISTS,
          formData: {
            type: 'main-training',
            subject_id: 'no-need',
          },
        },
        // url: url.RTMD_GET_COURSE_LISTS,
        key: 'id',
        label: 'name',
        label2: '',
      },
      data: [],
      dependField: 'type',
      dependValue: 'main-training',
      condition: '!==',
    },

    paid_course: {
      positionInForm: 9,
      label: 'Paid/Free',
      name: 'paid_course',
      type: 'radio',
      default: '1',
      isMulti: false,
      isRequired: true,
      validation: Yup.string()
        .required('Paid Status is required')
        .oneOf(['0', '1']),
      showInForm: true,
      viewCell: (row: any) => (row.paid_course ? 'Paid' : 'Free'),
      cell: (row: any) => (row.paid_course ? 'Paid' : 'Free'),
      showInSearch: true,
      colSize: 4,
      data: [
        { label: 'Paid', value: '1' },
        { label: 'Free', value: '0' },
      ],
    },

    assigned_susbjects: {
      label:'No of Subject ',
      viewCell: (row:any)=> {
        return row?.assigned_susbjects? Object.keys(row.assigned_susbjects).length : 0
      },
      cell: (row:any)=> {
        return row?.assigned_susbjects? Object.keys(row.assigned_susbjects).length : 0
      },
    },
    
    status: {
      positionInForm: 20,
      label: 'Status',
      name: 'status',
      type: 'radio',
      default: true,
      isMulti: false,
      isRequired: true,
      validation: Yup.string().required('Is Required?'),
      showInForm: true,
      // viewCell: (row: any) => (row.status ? 'Active' : 'Inactive'),
      // cell: (row: any) => (row.status ? 'Active' : 'Inactive'),
      showInSearch: false,
      colSize: 4,
      // data: ['In-active', 'Active'],
      data: [
        { label: 'Active', value: true },
        { label: 'In-active', value: false },
      ],
    },
  },
};

export default formObj;
