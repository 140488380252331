import { useFormik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { Button, InputField } from "src/components";

const AcademicStaff = () => {
  const { values, errors, touched, handleChange } = useFormik({
    initialValues: {
      demo: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="px-md-4 px-sm-2">
            <h5>Academic Staff of the department</h5>
            <FormGroup className="col-sm-6 col-md-3">
              <Label>Name</Label>
              <InputField
                type="text"
                name="totalCabins"
                placeholder="Name"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>
            <FormGroup className="col-sm-6 col-md-3">
              <Label for="departmentName">Designation</Label>
              <Input type="select" name="departmentName">
                <option value={0}>Select Designation </option>
                <option value={1}>Professor</option>
              </Input>
            </FormGroup>
            <FormGroup className="col-sm-6 col-md-3">
              <Label>Qualification</Label>
              <InputField
                type="text"
                name="totalCabins"
                placeholder="Enter a number"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>
            <FormGroup className="col-sm-6 col-md-3">
              <div className="mb-3">
                <h5 className="font-size-14 mb-3">Staff Type</h5>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                      defaultChecked
                    />
                    <label className="form-check-label">Full Time</label>
                  </div>
                </div>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                    />
                    <label className="form-check-label">Part Time</label>
                  </div>
                </div>
              </div>
            </FormGroup>
            <FormGroup className="col-sm-12 col-md-6">
              <Label>
                Teaching experience in the rank of Asst. Prof. and above
              </Label>
              <InputField
                type="text"
                name="totalCabins"
                placeholder="Experience details"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>
            <FormGroup className="col-sm-6 col-md-3">
              <Label>BMDC Registration No.</Label>
              <InputField
                type="text"
                name="totalCabins"
                placeholder="Enter a number"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>
            <FormGroup className="col-sm-6 col-md-3">
              <Label>BMDC Validity</Label>
              <InputField
                type="date"
                name="totalCabins"
                placeholder="Enter a number"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>
            <Col>
              <div className="mb-3 d-flex flex-row-reverse">
                <Button className="float-right px-3" text={"+ Add"} />
              </div>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-striped table-hover mb-0">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Designation</th>
                          <th>Full/Part Time</th>
                          <th>Qualification</th>
                          <th>BMDC Reg no.</th>
                          <th>
                            Teaching Experience in the rank of Asst. Prof. and
                            above
                          </th>
                          <th>List of publications</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Dr. John Doe</td>
                          <td>Professor</td>
                          <td>Fulltime</td>
                          <td>Ph.D</td>
                          <td>June 2026</td>
                          <td>4 Years</td>
                          <td>
                            <li>Pub 1</li>
                            <li>Pub 2</li>
                          </td>
                          <td>23</td>
                          <td>
                            <div className="btn btn-sm btn-primary d-inline-block m-1">
                              Edit
                            </div>
                            <div className="btn btn-sm btn-danger d-inline-block">
                              Delete
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <h6>Work Load if Academic Staff</h6>
            <Row>
              <FormGroup className="col-sm-6 col-md-3">
                <Label for="departmentName">Duties</Label>
                <Input type="select" name="departmentName">
                  <option value={0}>Select Duties </option>
                  <option value={1}>Duty 1</option>
                  <option value={1}>Duty 2</option>
                  <option value={1}>Duty 3</option>
                </Input>
              </FormGroup>
              <FormGroup className="col-sm-6 col-md-3">
                <Label>Working Hour</Label>
                <InputField
                  type="time"
                  name="totalCabins"
                  placeholder="Enter a number"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
              <FormGroup className="col-sm-6 col-md-3">
                <Label>To</Label>
                <InputField
                  type="time"
                  name="totalCabins"
                  placeholder="Enter a number"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
            </Row>
            {/* Table */}
            <Col>
              <div className="mb-3 d-flex flex-row-reverse">
                <Button className="float-right px-3" text={"+ Add"} />
              </div>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-striped table-hover mb-0">
                      <thead>
                        <tr>
                          <th>Duties</th>
                          <th>Working Hour</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Duty name</td>
                          <td>9:00 AM - 6:00 PM</td>
                          <td>
                            <div className="btn btn-sm btn-primary d-inline-block m-1">
                              Edit
                            </div>
                            <div className="btn btn-sm btn-danger d-inline-block">
                              Delete
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Row className="mb-3">
              <FormGroup className="col-sm-12 col-md-6">
                <Label>Number of teaching unit of the department</Label>
                <InputField
                  type="number"
                  name="totalCabins"
                  placeholder="Enter a number"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
              <FormGroup className="col-sm-12 col-md-6">
                <Label>
                  No. of beds in each of the service/teaching unit of the
                  department
                </Label>
                <InputField
                  type="number"
                  name="totalCabins"
                  placeholder="Enter a number"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
            </Row>
          </Row>
          <div className="text-center mb-3">
            <Link to="/institute/subject-registration-5">
              <button
                type="submit"
                className="btn btn-primary w-25"
                onSubmit={(e) => {
                  e.preventDefault;
                }}>
                Save and Next {">"}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AcademicStaff;
