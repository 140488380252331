import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetRecomendationListQuery } from 'src/rtk/features/instituteInspection/institutueInspectionApi';
import { useGetInstituteNameQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import loader from '../../assets/images/loader.gif';
import ViewAssignedDepartmentModal from './ViewAssignedDepartmentModal';

const InspectionRecomendationList = () => {
  const history = useHistory();
  
  const { data: instituteLookupData } = useGetInstituteNameQuery();

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [info, setInfo] = useState<RecomendationList>({});
  const [selectedInstitue, setSelectedInstitute] = useState<any>('');

  const allInstitute: SelectOption[] = instituteLookupData?.map((item) => ({
    value: item.id!,
    label: item.institute_name!,
  })) || [];

  const allStatus: SelectOption[] = [
    { value: 'Pending', label: 'Pending' },
    { value: 'Recommendation Given', label: 'Recommendation Given' },
    { value: 'Recommendation Given (Partially)', label: 'Recommendation Given (Partially)' },
  ];

  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');

  const generateQueryUrl = () => {
    return `page=${page}&paginate_per_page=${limit}${search}`;
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setPage(page);
  };

  interface SearchFields {
    search?: string;
    institute_id?: string;
    inspection_from?: string;
    inspection_to?: string;
    status?: string;
  }

  const initialValues: SearchFields = {
    search: '',
    institute_id: '',
    inspection_from: '',
    inspection_to: '',
    status: '',
  };

  const {
    data: recomendationList,
    isSuccess,
    isLoading,
  } = useGetRecomendationListQuery(generateQueryUrl());

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.search !== '') {
        s = s + `&search=${values.search}`;
      }

      if (values.institute_id !== '') {
        s = s + `&institute_id=${values.institute_id}`;
      }

      if (values.inspection_from !== '') {
        s = s + `&inspection_from=${values.inspection_from}`;
      }

      if (values.status !== '') {
        s = s + `&status=${values.status}`;
      }

      if (values.inspection_to !== '') {
        s = s + `&inspection_to=${values.inspection_to}`;
      }

      setSearch(s);
      setPage('1');
    },
  });

  const columns: TableColumn<any>[] = [
    {
      name: <div>Sl. No.</div>,
      cell: (row, index) => <strong>{((page - 1) * limit + index) + 1}</strong>,
      width: '80px',
      wrap: true,
    },

    {
      name: <div>Team Name</div>,
      selector: (row) => row?.team?.team_name,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Inspection Date</div>,
      selector: (row) => moment(row?.inspection_date).format("DD-MMM-YYYY"),
      sortable: false,
      wrap: true,
    },
    
    {
      name: <div>Institute</div>,
      selector: (row) => row?.institute?.institute_name,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Number of Departments</div>,
      selector: (row) => row?.total_department,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Status</div>,
      selector: (row) => row?.recommendation_status,
      cell: (row) => {
        switch (row?.recommendation_status) {
          case 'Recommendation Given':
            return (
              <div className="badge rounded-pill  badge-approved font-size-12 fw-medium text-wrap">
                {row?.recommendation_status}
              </div>
            );

          case 'Recommendation Given (Partially)':
            return (
              <div className="badge rounded-pill  badge-draft font-size-12 fw-medium text-wrap">
                {row?.recommendation_status}
              </div>
            );

          default:
            return (
              <div className="badge rounded-pill  badge-pending font-size-12 fw-medium text-wrap">
                {row?.recommendation_status}
              </div>
            );
        }
      },
    },

    {
      name: <div>Action</div>,
      width: '350px',
      cell: (row) => (
        <div className="d-flex flex-nowrap gap-2 my-2">
          <Button
            className="btn btn-sm btn-primary"
            text={`<span class="fas fa-eye" ></span> Assignment`}
            onClick={() => {
              setModalShow(true);
              setInfo(row);
            }}
          />

          <Button
            className="btn btn-sm btn-info"
            text={`<span class="fas fa-eye" ></span> View Details`}
            onClick={() => {
              history.push(`/institute-department-details/${row?.institute_id}/${row?.department?.id}`)
            }}
          />

          <Link
            to={{ pathname: `/recomendation-form/${row?.team_creation_id}/${row?.institute_id}/${row?.institute?.institute_location}/${row?.inspection_date}/${row?.team?.chairman_id}` }}
            className="btn btn-sm btn-success"
          >
            <i className="fas fa-edit" /> Recomendation
          </Link>

          {modalShow && info?.institute_id === row?.institute_id &&
            <ViewAssignedDepartmentModal
              isOpen={modalShow}
              toggle={() => setModalShow(!modalShow)}
              info={info}
            />
          }

        </div>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    if (selectedInstitue !== '') {
      setFieldValue('institute_id', selectedInstitue?.value);
    }
  }, [selectedInstitue]);

  return (
    <div className="page-content">
      {isLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb
          breadcrumbItem={[{ link: '#', name: 'Inspection Recomendation List' }]}
          title={'Inspection Recomendation List'}
        />
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Form
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  onReset={(e) => {
                    e.preventDefault();
                    resetForm();
                    handleSubmit();
                    setSelectedInstitute('');
                  }}
                >
                  <div className="filter-wrapper">

                    <FormGroup className='extent-field'>
                      <Label for="institute_id">Institute</Label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        value={selectedInstitue}
                        onChange={setSelectedInstitute}
                        options={allInstitute}
                        placeholder='Institute'
                        name="institute_id"
                      />
                    </FormGroup>
                    <FormGroup className='extent-field'>
                      <Label for="inspection_from">Inspection From</Label>
                      <InputField
                        type="date"
                        placeholder=""
                        invalid={{ errors, touched }}
                        {...getFieldProps('inspection_from')}
                      />
                    </FormGroup>

                    <FormGroup className='extent-field'>
                      <Label for="creation_to">Inspection To</Label>
                      <InputField
                        type="date"
                        placeholder=""
                        invalid={{ errors, touched }}
                        {...getFieldProps('inspection_to')}
                      />
                    </FormGroup>

                    <FormGroup className='extent-field'>
                      <Label for="status">Status</Label>
                      <InputField
                        type="select"
                        placeholder="Status"
                        options={allStatus}
                        {...getFieldProps('status')}
                      />
                    </FormGroup>

                    <FormGroup className="btn-column mb-3">
                      <Button color="danger" type="reset" text="Reset" />
                      <Button type="submit" text="Search" />
                    </FormGroup>
                  </div>
                </Form>
              </Col>
            </Row>

            <DataTable
              columns={columns}
              data={recomendationList?.data!}
              pagination
              paginationServer
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              progressPending={isLoading}
              expandOnRowClicked
              highlightOnHover
              paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
              responsive
              persistTableHead={true}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default InspectionRecomendationList;
