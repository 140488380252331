import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import {
  Col,  
  Form,  
  FormGroup,
  Input,  
  Label,
  Row,  
  Button as BsButton,
  Container
} from 'reactstrap';

import { Button, InputField } from 'src/components';

interface OnlinePaymentProps {
    exam_course_id: string;
    user_id: string;
}

const OnlinePayment = (props: OnlinePaymentProps) => {

    const { exam_course_id, user_id } = props;

    const { handleSubmit, getFieldProps, handleChange, handleBlur, values, touched, errors, resetForm, setFieldValue, setErrors } = useFormik({
        initialValues: {
          fullName: '',
          mobile: '',
          email: '',
          address: '',
          second_address: '',
        },

        validationSchema: Yup.object({
          fullName: Yup.string().required("Name is required"),
          mobile: Yup.string().required("Mobile number is required"),
          address: Yup.string().required("Address is required"),          
        }),

        onSubmit: (values) => {
          console.log(values);
        },
    });

    return (
          <div className="page-content">
            <Container fluid>
                <div className="row align-items-center justify-content-center">
                    <div className="card mt-3">     
                        <h5 className="card-header">
                            <i className="fa fa-plus me-2" />
                            Course Online Payment
                        </h5>   
                        <div className="card-body">
                            <Form                                                  
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                                onReset={(e) => {
                                    e.preventDefault();
                                    //   handleFormReset();
                                }}                                                    
                            >
                                <Row>                                    
                                    <Col md={4} sm={12}>
                                        <FormGroup className="mb-3">
                                            <Label className="form-label">Full Name</Label>
                                            <InputField
                                                type="text"
                                                placeholder=""                                                    
                                                invalid={{ errors, touched }}
                                                {...getFieldProps('fullName')}
                                            />                        
                                        </FormGroup>
                                    </Col>

                                    <Col md={4} sm={12}>
                                        <FormGroup className="mb-3">
                                            <Label className="form-label">Mobile</Label>                                            
                                            <InputField
                                                type="text"
                                                placeholder="Mobile"                                                    
                                                invalid={{ errors, touched }}
                                                {...getFieldProps('mobile')}
                                            />                        
                                        </FormGroup>
                                    </Col> 

                                    <Col md={4} sm={12}>
                                        <FormGroup className="mb-3">
                                            <Label className="form-label">Email <span className="text-muted">(Optional)</span></Label>
                                            <InputField
                                                type="email"
                                                placeholder="you@example.com"                                                    
                                                invalid={{ errors, touched }}
                                                {...getFieldProps('email')}
                                            />                        
                                        </FormGroup>
                                    </Col>

                                    <Col md={4} sm={12}>
                                        <FormGroup className="mb-3">
                                            <Label className="form-label">Address</Label>
                                            <InputField
                                                type="text"
                                                placeholder="1234 Main S"                                                    
                                                invalid={{ errors, touched }}
                                                {...getFieldProps('address')}
                                            /> 
                                        </FormGroup>
                                    </Col>

                                    <Col md={4} sm={12}>
                                        <FormGroup className="mb-3">
                                            <Label className="form-label">Address 2 <span className="text-muted">(Optional)</span></Label>
                                            <InputField
                                                type="text"
                                                placeholder="Apartment or suite"                                                    
                                                invalid={{ errors, touched }}
                                                {...getFieldProps('second_address')}
                                            /> 
                                        </FormGroup>
                                    </Col>                                                                                                                                                                                                                                                                                                                                                                 

                                    <Col sm={12}>
                                        <FormGroup className='mb-3 text-center'>                                      
                                            <Button className="btn btn-primary btn-lg btn-block" type='submit' text='PAY NOW'></Button>  
                                        </FormGroup>
                                    </Col>   
                                </Row>                                                                                                                  
                            </Form>                      
                        </div>                  
                    </div>

                    <Row className='justify-content-end'>
                        <Col sm={3}>   
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="d-flex justify-content-between align-items-center mb-3">
                                        <span className="text-muted">Your cart</span>
                                    </h4>
                                </div>

                                <div className="card-body">
                                    <ul className="list-group mb-3">
                                        <li className="list-group-item d-flex justify-content-between lh-condensed">
                                            <div>
                                                <h6 className='my-0'>

                                                </h6>

                                                <small className='text-muted'>

                                                </small>
                                            </div>

                                            <span className='text-muted'>

                                            </span>
                                        </li>

                                        <li className="list-group-item d-flex justify-content-between">
                                            <span>Total (BDT)</span>
                                            <strong></strong>
                                        </li>
                                    </ul>
                                </div>                        
                            </div>                                    
                        </Col>
                    </Row>
                </div> 
            </Container>            
          </div>                   
    );
    
};

export default OnlinePayment;