import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import { useDeleteInspectionTeamMutation, useGetInspectionTeamListForAdminQuery } from 'src/rtk/features/instituteInspection/institutueInspectionApi';
import DepartmentAssignModal from './DepartmentAssignModal';
import TeamViewModal from './TeamViewModal';
import loader from '../../assets/images/loader.gif';

const InspectionTeamList = () => {
  const [deleteInspectionTeam, { isSuccess: isDeleteSuccess, isError, error }] = useDeleteInspectionTeamMutation();

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [assignModalShow, setAssignModalShow] = useState<boolean>(false);
  const [teamViewModalShow, setTeamViewModalShow] = useState<boolean>(false);

  const [infoId, setInfoId] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [viewDetails, setViewDetails] = useState<InspectionTeam>({});

  const allStatus: SelectOption[] = [
    { value: 'Assigned', label: 'Assigned' },
    { value: 'Not Assigned', label: 'Not Assigned' },
  ];

  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');

  const generateQueryUrl = () => {
    return `page=${page}&paginate_per_page=${limit}${search}`;
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setPage(page);
  };

  interface SearchFields {
    search?: string;
    creation_from?: string;
    creation_to?: string;
    inspection_from?: string;
    inspection_to?: string;
    assignment_status?: string;
  }

  const initialValues: SearchFields = {
    search: '',
    creation_from: '',
    creation_to: '',
    inspection_from: '',
    inspection_to: '',
    assignment_status: '',
  };

  const {
    data: teamList,
    isSuccess,
    isLoading,
  } = useGetInspectionTeamListForAdminQuery(generateQueryUrl());

  const deleteHandler = (modalState: boolean, id: string) => {
    setModalShow(modalState);
    setDeleteId(id);
  };

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.search !== '') {
        s = s + `&search=${values.search}`;
      }

      if (values.creation_from !== '') {
        s = s + `&creation_from=${values.creation_from}`;
      }

      if (values.creation_to !== '') {
        s = s + `&creation_to=${values.creation_to}`;
      }

      if (values.inspection_from !== '') {
        s = s + `&inspection_from=${values.inspection_from}`;
      }

      if (values.assignment_status !== '') {
        s = s + `&assignment_status=${values.assignment_status}`;
      }

      if (values.inspection_to !== '') {
        s = s + `&inspection_to=${values.inspection_to}`;
      }

      setSearch(s);
      setPage('1');
    },
  });


  const columns: TableColumn<any>[] = [
    {
      name: <div>Sl. No.</div>,
      cell: (row, index) => <strong>{((page - 1) * limit + index) + 1}</strong>,
      width: '80px',
      wrap: true,
    },

    {
      name: <div>Team Name</div>,
      selector: (row) => row?.team_name,
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Creation Date</div>,
      selector: (row) => moment(row?.created_at).format("DD-MMM-YYYY"),
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Number of Members</div>,
      selector: (row) => row?.team_members?.length,
      sortable: false,
      wrap: true,
    },

    // {
    //   name: 'Inspection Date',
    //   selector: (row) =>
    //     row?.registration?.current_employment_info?.assignment_status ?? 'N/A',
    //   sortable: false,
    //   wrap: true,
    // },

    {
      name: <div>Status</div>,
      selector: (row) => row?.assignment_status ?? 'N/A',
      cell: (row) => {
        switch (row?.assignment_status) {

            case 'Assigned':
                return (
                    <div className="badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium">
                        {row?.assignment_status}
                    </div>
                );
            case 'Not Assigned':
                return (
                    <div className="badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium">
                        {row?.assignment_status}
                    </div>
                );

            default:
                return (
                    <div className="badge rounded-pill d-inline-block  badge-draft font-size-12 fw-medium">
                        {row?.assignment_status}
                    </div>
                );
        }
    },
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Action</div>,
      width: '350px',
      cell: (row) => (
        <div className='my-2'>
          <Button
            className="btn btn-sm btn-dark me-1"
            text={`<span class="fas fa-book" ></span> Assign`}
            onClick={() => {
              setAssignModalShow(true);
              setInfoId(row?.id);
            }}
          />
          <Button
            className="btn btn-sm btn-primary me-1"
            text={`<span class="fas fa-eye" ></span> View`}
            onClick={() => {
              setViewDetails(row);
              setTeamViewModalShow(true);
            }}
          />
          <Link
            to={{ pathname: `/update-inspection-team/${row?.id}` }}
            className="btn btn-sm btn-info me-1"
          >
            <i className="fas fa-edit" /> Update
          </Link>

          <Button
            className="btn btn-sm btn-danger"
            text={`<span class="fas fa-trash" ></span> Delete`}
            onClick={() => deleteHandler(true, row?.id!)}
          />

          {assignModalShow && infoId === row?.id &&
            <DepartmentAssignModal
              isOpen={assignModalShow}
              toggle={() => setAssignModalShow(!assignModalShow)}
              teamId={infoId}
            />
          }

          {teamViewModalShow && viewDetails?.id === row?.id &&
            <TeamViewModal
              isOpen={teamViewModalShow}
              toggle={() => setTeamViewModalShow(!teamViewModalShow)}
              viewDetails={viewDetails}
            />
          }
          {modalShow && deleteId === row?.id ? (
            <DeleteInformationModal
              isOpen={modalShow}
              toggle={() => setModalShow(!modalShow)}
              id={deleteId}
              deleteInfomation={deleteInspectionTeam}
            />
          ) : null}

        </div>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    if (isDeleteSuccess) {
      toast.success('Team Deleted Successfully!');
    }
    if (isError) {
      toast.error('Error Deleting Leave Entry!');
    }
  }, [isDeleteSuccess, isError]);

  return (
    <div className="page-content">
      {isLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb
          breadcrumbItem={[{ link: '#', name: 'Inspection' }]}
          title={'Inspection Team'}
        />
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Form
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  onReset={(e) => {
                    e.preventDefault();
                    resetForm();
                    handleSubmit();
                  }}
                >
                  <div className="filter-wrapper">
                    <FormGroup>
                      <Label for="creation_from">Creation From</Label>
                      <InputField
                        type="date"
                        placeholder=""
                        invalid={{ errors, touched }}
                        {...getFieldProps('creation_from')}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="creation_to">Creation To</Label>
                      <InputField
                        type="date"
                        placeholder=""
                        invalid={{ errors, touched }}
                        {...getFieldProps('creation_to')}
                      />
                    </FormGroup>

                    {/* <FormGroup>
                      <Label for="inspection_from">Inspection From</Label>
                      <InputField
                        type="date"
                        placeholder=""
                        invalid={{ errors, touched }}
                        {...getFieldProps('inspection_from')}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="inspection_to">Inspection To</Label>
                      <InputField
                        type="date"
                        placeholder=""
                        invalid={{ errors, touched }}
                        {...getFieldProps('inspection_to')}
                      />
                    </FormGroup> */}

                    <FormGroup>
                      <Label for="assignment_status">Status</Label>
                      <InputField
                        type="select"
                        placeholder="Status"
                        options={allStatus}
                        {...getFieldProps('assignment_status')}
                      />
                    </FormGroup>

                    <FormGroup className="btn-column">
                      <Button color="danger" type="reset" text="Reset" />
                      <Button type="submit" text="Search" />
                    </FormGroup>

                    <FormGroup className="">
                      <Link
                        to="/create-inspection-team"
                        className="btn btn-success"
                        style={{ width: '190px', height: '37px' }}
                      >
                        <i className="fa fa-plus" /> Create New Team
                      </Link>
                    </FormGroup>
                  </div>
                </Form>
              </Col>
            </Row>

            <DataTable
              columns={columns}
              data={teamList?.data!}
              pagination
              paginationServer
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              progressPending={isLoading}
              expandOnRowClicked
              highlightOnHover
              paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
              paginationTotalRows={teamList?.total}
              responsive
              persistTableHead={true}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default InspectionTeamList;
