import moment from 'moment';
import { Button } from 'src/components';

interface Props {
    thesisExam: any;
    index: number;
}
const colors = [ '#ffd1d1', '#d7ffd7', '#d7d7ff', '#ffffd6','#ffd7ff', '#caffff', '#e3e3e3'];

const getDayOfWeekColor = (date: any) => {
  const dayOfWeek = new Date(date).getDay();
  return colors[dayOfWeek];
};

const ThesisExamScheduleListTableRow = ({ thesisExam, index }: Props) => {

    return (
        <tr key={thesisExam.id} style={{ backgroundColor: getDayOfWeekColor(moment(thesisExam?.exam_date).format('YYYY-MM-DD')) }}>
            <th scope="row">
                {index + 1}
            </th>
            <td>
                {thesisExam?.subject_info?.subject_name ?? '-'}
            </td>
            <td>
                {moment(thesisExam?.exam_date)?.isValid() ? moment(thesisExam?.exam_date).format('DD-MM-YYYY') : '-'}
            </td>
            <td>
                {thesisExam?.exam_start_time && thesisExam?.exam_end_time ? thesisExam?.exam_start_time + " - " + thesisExam?.exam_end_time : '-'}
            </td>
            <td>
                {thesisExam?.building ?? '-'}
            </td>
            <td>
                {thesisExam?.room_no ?? '-'}
            </td>
            <td>
                {thesisExam?.table_no ?? '-'}
            </td>
            <td>
                {'-'}
            </td>
            <td>
                {'-'}
            </td>
            <td>
                { '-' }
            </td>
            <td>
                <div className="d-flex gap-2">
                    <Button
                        size="sm"
                        text="View"
                        color="info"
                        onClick={() => {

                        }}
                    />
                </div>
            </td>
        </tr>
    );
};

export default ThesisExamScheduleListTableRow;
