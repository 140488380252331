import { Button, Collapse, Input, Label } from "reactstrap";
import { EditCompetencyGroupLevels } from "./EditCompetencyGroupLevels";
import { useEffect, useState } from "react";

const EditRecursiveCompetencyItem = (props: any) => {

    const {
        competencyGroup,
        searchTerm,
        competencyGroupDetails,
        competencyGroupParticulars,
        handleCheckboxChange,
        checkedComIds,
        competencyGroupState,
        setCompetencyGroupState,
        skillTypeName,
        formik,
        searchTermCheck,
        competenceLevelOptions,
    } = props;

    const [toggleValues, setToggleValues] = useState<any>({});

    useEffect(() => {
        const newToggleValues: any = { ...toggleValues };

        competencyGroup?.forEach((competency: any, index: number) => {
            if (competency?.childs?.length) {
                newToggleValues[competency?.id] = true;
            }
        });

        setToggleValues(newToggleValues);
    }, []);

    return (
        <ul className="">
            {competencyGroup
                ?.filter((competency: any, index: number) => competency.particulars.toLowerCase().includes(searchTerm.toLowerCase()))
                ?.map((competency: any, index: number) => {

                    if (competency?.childs?.length) {
                        return (
                            <li key={competency?.id}>
                                <div
                                    className="collapse-title"
                                    onClick={(e: any) => {
                                        const newToggleValues: any = { ...toggleValues };
                                        newToggleValues[competency?.id] = !newToggleValues[competency?.id];
                                        setToggleValues(newToggleValues);
                                    }}
                                >
                                    {toggleValues[competency?.id] ? (
                                        <i className="fa fa-minus me-2" />
                                    ) : (
                                        <i className="fa fa-plus me-2" />
                                    )}
                                    {competency?.particulars}
                                </div>{' '}                                
                                <Collapse isOpen={toggleValues[competency?.id]}>
                                    <ul className="ms-2 list-unstyled">
                                        <EditRecursiveCompetencyItem
                                            competencyGroup={competency?.childs}
                                            searchTerm={searchTerm}
                                            competencyGroupDetails={competencyGroupDetails}
                                            competencyGroupParticulars={competencyGroupParticulars}
                                            handleCheckboxChange={handleCheckboxChange}
                                            checkedComIds={checkedComIds}
                                            competencyGroupState={competencyGroupState}
                                            setCompetencyGroupState={setCompetencyGroupState}
                                            skillTypeName={skillTypeName}
                                            formik={formik}
                                            searchTermCheck={searchTermCheck}
                                            competenceLevelOptions={competenceLevelOptions}
                                        />
                                    </ul>
                                </Collapse>
                            </li>
                        )
                    } else {
                        const competencyGroupDetail: LogbookCompetencyLevels =
                            competencyGroupDetails?.find(
                                (c: LogbookCompetencyLevels) =>
                                    c.skill_name?.trim() ===
                                    competency.particulars?.trim(),
                            )!;

                        return (
                            <li key={competency.id}>
                                <span>
                                    <Label check className="mb-2 mt-1 me-3">
                                        <Input
                                            type="checkbox"
                                            name={competency.particulars}
                                            id={competency.particulars}
                                            checked={
                                                competencyGroupParticulars.indexOf(
                                                    competency.particulars.trim(),
                                                ) !== -1
                                            }
                                            onChange={(e) => {
                                                handleCheckboxChange(
                                                    e,
                                                    competency.id,
                                                    competency.particulars,
                                                );
                                            }}
                                        />{' '}
                                        {competency.particulars}
                                    </Label>
                                </span>

                                {
                                    checkedComIds?.length &&
                                        competencyGroupState &&
                                        skillTypeName &&
                                        skillTypeName.toLowerCase() !== 'interpretative skill' ? (
                                        <EditCompetencyGroupLevels
                                            formik={formik}
                                            competencyGroup={competencyGroupState}
                                            setCompetencyGroup={setCompetencyGroupState}
                                            competency={competency}
                                            checkedCompetencyIds={checkedComIds}
                                            competencyGroupDetail={competencyGroupDetail}
                                            searchTermCheck={searchTermCheck}
                                            competenceLevelOptions={competenceLevelOptions}
                                        />
                                    )
                                        :
                                        null
                                }
                            </li>
                        );
                    }
                })}
        </ul>
    )
};

export default EditRecursiveCompetencyItem;