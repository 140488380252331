import moment from 'moment';
import { config } from 'src/config';
import * as url from 'src/helpers/url_helper';
import * as Yup from 'yup';

const formObj: any = {
  debug: true,

  listAPIUrl: url.THESIS_DISSERTATION_FILE_LIST_SUPERVISOR,
  approvalAPIUrl: url.THESIS_DISSERTATION_FILE_APPROVE_BY_SUPERVISOR,

  listUrl: '/demo/',
  addUrl: '/demo/add',
  updateUrl: '/demo/update/',

  showSearchForm: true,
  showCreateNewBtn: false,
  showUpdateBtn: false,
  showViewBtn: true,
  showDeleteBtn: false,
  showTableActionColumn: true,

  createNewBtnTitle: 'Create New',
  submitBtnTitle: 'Save',
  updateBtnTitle: 'Update',
  resetBtnTitle: 'Reset',
  draftBtnTitle: '',
  viewBtnTitle: 'Approval',
  deletBtnTitle: 'Delete',
  deletePromtMessage: 'Do you want to delete?',

  title: 'Dissertation/Thesis List for Guide/Co-Guide',
  breadcrumbItemForList: [{ link: '/', name: 'Dashboard' }],
  breadcrumbItemForForm: [{ link: '/', name: 'Dashboard' }],

  formFields: [],

  searchFormFields: {
    document_name: {
      label: 'Document',
      viewCell: (row: any) => {
        return (<>
          <a
            href={config.APP_URL + row?.document_name}
            rel="noreferrer"
            target="_blank"
          >
            <span className="btn rounded-0  btn-sm btn-success ">
              <span className="fas fa-download" ></span> Document
            </span>
          </a>
        </>)
      },
    },
    category: {
      positionInForm: 1,
      label: 'Category',
      name: 'category',
      viewCell: (row: any) => row?.title?.category || '',
      cell: (row: any) => row?.title?.category || '',
      type: 'select',
      default: 'Dissertation',
      // isMulti: false,
      isRequired: true,
      validation: Yup.string()
        .required('Category is required')
        .oneOf(['Thesis', 'Dissertation']),
      showInForm: true,

      showInSearch: true,
      colSize: 6,
      data: [
        { label: 'Thesis', value: 'Thesis' },
        { label: 'Dissertation', value: 'Dissertation' },
      ],
    },

    title_of_thesis: {
      positionInForm: 2,
      label: 'Title',
      name: 'title_of_thesis',
      viewCell: (row: any) => row?.title?.title_of_thesis || '',
      cell: (row: any) => row?.title?.title_of_thesis || '',
      type: 'text',
      default: '',
      isRequired: false,
      showInSearch: true,
      colSize: 6,
    },

    submission_date: {
      label: 'Submission Date',
      viewCell: (row: any) => row.submission_date,
      cell: (row: any) => moment(row.submission_date).format('DD/MMM/YYYY'),
    },

    bmdc_registration_no: {
      positionInForm: 3,
      label: 'BMDC NO',
      viewCell: (row: any) => row?.bmdc_registration_no || '',
      cell: (row: any) => row?.bmdc_registration_no || '',
      name: 'bmdc_registration_no',
      type: 'text',
      default: '',
      isRequired: false,
      showInSearch: true,
      colSize: 3,
    },

    full_name: {
      positionInForm: 3,
      label: 'Trainee Name',
      name: 'full_name',
      type: 'text',
      default: '',
      isRequired: false,
      showInSearch: true,
      colSize: 3,
      viewCell: (row: any) => row?.trainee_name || '',
      cell: (row: any) => row?.trainee_name || '',
    },
    // subject_name: {
    //   positionInForm: 4,
    //   name: 'full_name',
    //   type: 'text',
    //   default: '',
    //   isRequired: false,
    //   showInSearch: true,
    //   colSize: 3,
    //   label: 'Discipline',
    //   viewCell: (row: any) => row?.registration?.subject_name || '',
    //   cell: (row: any) => row?.registration?.subject_name || '',
    // },


    document_type: {
      label: 'Document type',
      viewCell: (row: any) => row.document_type,
      cell: (row: any) => row.document_type,
    },

    guide_id: {
      label: 'Guide',
      name: 'guide_id',
      viewCell: (row: any) => row?.title?.guide?.personal_info?.full_name || '',
      type: 'modal',
      default: '',
      isMulti: false,
      isRequired: true,
      validation: Yup.string().ensure().required('Guide is required!'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
      dataApi: {
        listAPIUrl: url.GET_GUIDE_COGUIDE_LIST + '?type=Guide&',
        displayLabel: (row: any) => row?.label || '',
        key: (row: any) =>
          row?.registration?.personal_info?.full_name
            ? row?.registration_no
            : false,
        label: (row: any) => {
          return row?.registration?.personal_info?.full_name || '';
        },
        showSearchForm: true,
        searchFormFields: [
          {
            label: 'BMDC NO',
            name: 'bmdc_registration_no',
            type: 'text',
            cell: (row: any) => row?.registration?.bmdc_registration_no || '',
            showInSearch: true,
          },
          {
            label: 'Name',
            name: 'full_name',
            type: 'text',
            cell: (row: any) =>
              row?.registration?.personal_info?.full_name || '',
            showInSearch: true,
          },

          {
            label: 'Institute Name',
            name: 'institute_name',
            type: 'text',
            cell: (row: any) =>
              row.registration?.employment_infos?.institute_name || '',
            showInSearch: true,
          },

          {
            label: 'Mobile',
            name: 'mobile',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.mobile || '',
            showInSearch: true,
          },
          {
            label: 'Email',
            name: 'email',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.email || '',
            showInSearch: true,
          },
        ],
      },
      data: [],
    },

    co_guide_id: {
      label: 'Co-guide',
      name: 'co_guide_id',
      type: 'modal',
      default: '',
      isMulti: true,
      isRequired: true,
      validation: Yup.array()
        .ensure()
        .required('Co-guide is required!')
        .min(1, 'Select Minimum 1.')
        .max(3, 'Select Maximum 3'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
      dataApi: {
        listAPIUrl: url.GET_GUIDE_COGUIDE_LIST + '?type=Co-Guide&',
        displayLabel: (row: any) => row?.label || '',
        key: (row: any) =>
          row?.registration?.personal_info?.full_name
            ? row?.registration_no
            : false,
        label: (row: any) => {
          return row?.registration?.personal_info?.full_name || '';
        },
        showSearchForm: true,
        searchFormFields: [
          {
            label: 'BMDC NO',
            name: 'bmdc_registration_no',
            type: 'text',
            cell: (row: any) => row?.registration?.bmdc_registration_no || '',
            showInSearch: true,
          },
          {
            label: 'Name',
            name: 'full_name',
            type: 'text',
            cell: (row: any) =>
              row?.registration?.personal_info?.full_name || '',
            showInSearch: true,
          },

          {
            label: 'Institute Name',
            name: 'institute_name',
            type: 'text',
            cell: (row: any) =>
              row.registration?.employment_infos?.institute_name || '',
            showInSearch: true,
          },

          {
            label: 'Mobile',
            name: 'mobile',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.mobile || '',
            showInSearch: true,
          },
          {
            label: 'Email',
            name: 'email',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.email || '',
            showInSearch: true,
          },
        ],
      },
      data: [],
    },

    guide_approval_note: {
      label: 'Guide approval note',
      viewCell: (row: any) => row?.guide_approval_note || '',
    },

    guide_approval_status: {
      label: 'Guide approval status',
      viewCell: (row: any) => row?.guide_approval_status || '',
    },

    co_guide_view: {
      label: 'Co-Guide',
      viewCell: (row: any) => {
        return (
          <div className="d-flex">
            {row?.co_guide_id_1 && (
              <div className="m-1 p-2 border rounded  ">
                <div>Name: {row?.title?.co_guide_1_name || ''}</div>
                <div>Note: {row?.co_guide1_approval_note || ''}</div>
                <div>
                  Approval status: {row?.co_guide1_approval_status || ''}
                </div>
              </div>
            )}
            {row?.co_guide_id_2 && (
              <div className="m-1 p-2 border rounded">
                <div>Name: {row?.title?.co_guide_2_name || ''}</div>
                <div>Note: {row?.co_guide2_approval_note || ''}</div>
                <div>
                  Approval status: {row?.co_guide2_approval_status || ''}
                </div>
              </div>
            )}
            {row?.co_guide_id_3 && (
              <div className="m-1 p-2 border rounded">
                <div>Name: {row?.title?.co_guide_3_name || ''}</div>
                <div>Note: {row?.co_guide3_approval_note || ''}</div>
                <div>
                  Approval status: {row?.co_guide3_approval_status || ''}
                </div>
              </div>
            )}
          </div>
        );
      },
    },

    submission_status: {
      label: 'Submission status',
      viewCell: (row: any) => row?.submission_status ?
        row?.submission_status === 'Approve' ?
          'Approved' : row?.submission_status === 'Reject' ?
            'Rejected' : row?.submission_status === 'Submit' ?
              'Submitted' : row?.submission_status : '',
      cell: (row: any) => ((row?.admin_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit') ||
        (row?.guide_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit') ||
        (row?.co_guide1_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit') ||
        (row?.co_guide2_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit') ||
        (row?.co_guide3_approval_status?.toLowerCase() === 'correction' && row?.submission_status?.toLowerCase() === 'submit')
      )
        ? (
          <div className="badge rounded-pill d-inline-block badge-applied font-size-12 fw-medium">
            {'Resubmitted'}
          </div>
        ) :
        <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
          {row?.submission_status === 'Approve' ? 
          <span className='text-success'>Approved</span>  
          : 
          row?.submission_status === 'Submit' ? 
          <span className='text-warning'>Submitted</span> 
          :
          row?.submission_status === 'Reject' ? 
            <span className='text-danger'>Rejected</span> 
          :
          <span className='text-info'>{row?.submission_status}</span>}
        </div>,
    },


  },
};

export default formObj;
