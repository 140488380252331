import { apiSlice } from 'src/rtk/api/apiSlice';
import * as listOfTitleProtocolThesisUrl from 'src/helpers/url_helper';

export const listOfTitleProtocolThesisApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['listOfTitleProtocolThesis'] })
  .injectEndpoints({
    endpoints: (builder) => ({      
      getTitleProtocolThesisDissertationList: builder.query<APIResponse<any>, void>({
        query: () => ({
          method: 'GET',
          url: `${listOfTitleProtocolThesisUrl.GET_TITLE_PROTOCOL_THESIS_DISSERTATION_LIST}`,
        }),
        
        providesTags: ['listOfTitleProtocolThesis'],
      }),       
    }),
  });

export const {  
  useGetTitleProtocolThesisDissertationListQuery,  
} = listOfTitleProtocolThesisApi;
