import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label, Modal, ModalBody, ModalHeader,
  Row,
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import { generateUniqueDeviceId } from 'src/components/Common/UniqueUserIdGenerator';
import { setFromErrors } from 'src/helpers';
import store from 'src/rtk/app/store';
import { useCreateDeviceRegInfoMutation } from 'src/rtk/features/device-registration/deviceRegistrationApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import * as Yup from 'yup';

const initialValues: DeviceInfo = {
  id: '',
  registration_no: '',
  device_type: '',
  manufacturer_name: '',
  platform: '',
  mac_address: '',
  model_no: '',
  submit_status: '',
  status: true,
  creator_organization_id: '',
  updater_organization_id: '',
  created_by: '',
  updated_by: '',
  deleted_by: '',
  created_at: '',
  updated_at: '',
  deleted_at: '',
};

interface AddDeviceRegProps {
  isOpen: boolean;
  toggle: any;
}

const AddDeviceReg = (props: AddDeviceRegProps) => {
  const { isOpen, toggle } = props;
  const [createDeviceRegInfo, { data: createDeviceRegData, isSuccess, isError, error }] =
    useCreateDeviceRegInfoMutation();

  const currentUser: any = store.getState().auth.user;

  // const { data: user, isError: isUserFetchError } =
  //       useGetUserQuery(currentUser.id!); 

  const [uniqueDeviceId, setUniqueDeviceId] = useState<string>('');
  const [btnClicked, setBtnClicked] = useState<string>('');

  const err: CustomError = error as CustomError;

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      // registration_no: Yup.string().required(),              
    }),
    onSubmit: (values) => {

      values.registration_no = currentUser.registration_no;

      if (btnClicked === 'submit') {
        values.submit_status = 'Submit';
      } else if (btnClicked === 'save_as_draft') {
        values.submit_status = 'Draft';
      }

      createDeviceRegInfo({
        ...values,
      });

      console.log(values);
    },
  });

  const deviceTypeOptions: SelectOption[] =
    useGetLookupDataQuery('device_type')
      .data?.data[0]?.childs.map((item) => ({
      value: item.name,
      label: item.name,
    }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) || [];

  const manufacturerNameOptions: SelectOption[] =
    useGetLookupDataQuery('manufacturer_name')
      .data?.data[0]?.childs.map((item) => ({
      value: item.name,
      label: item.name,
    }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) || [];

  const platformOptions: SelectOption[] =
    useGetLookupDataQuery('platform')
      .data?.data[0]?.childs.map((item) => ({
      value: item.name,
      label: item.name,
    }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) || [];

  useEffect(() => {
    const generateDeviceId: Promise<string> = generateUniqueDeviceId();
    generateDeviceId.then((value: string) => {
      setUniqueDeviceId(JSON.parse(value).browser_fingerprint_id);
    });
  }, []);

  useEffect(() => {
    if (uniqueDeviceId) {
      setFieldValue('mac_address', uniqueDeviceId);
    }
  }, [uniqueDeviceId]);

  useEffect(() => {
    if (isSuccess) {
      resetForm(
        {
          values: {
            id: '',
            registration_no: '',
            device_type: '',
            manufacturer_name: '',
            platform: '',
            mac_address: uniqueDeviceId,
            model_no: '',
            submit_status: '',
            status: true,
            creator_organization_id: '',
            updater_organization_id: '',
            created_by: '',
            updated_by: '',
            deleted_by: '',
            created_at: '',
            updated_at: '',
            deleted_at: '',
          },
        },
      );
      toast.success('Device registered successfully');
      toggle();
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} size={'lg'}>
        <ModalHeader toggle={toggle}>Register New Device</ModalHeader>
        <ModalBody>
          <Form
            className='custom-form'
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            onReset={(e) => {
              e.preventDefault();
              resetForm(
                {
                  values: {
                    id: '',
                    registration_no: '',
                    device_type: '',
                    manufacturer_name: '',
                    platform: '',
                    mac_address: uniqueDeviceId,
                    model_no: '',
                    submit_status: '',
                    status: true,
                    creator_organization_id: '',
                    updater_organization_id: '',
                    created_by: '',
                    updated_by: '',
                    deleted_by: '',
                    created_at: '',
                    updated_at: '',
                    deleted_at: '',
                  },
                },
              );
            }}
          >

            <Row className='align-items-end'>
              <Col lg={6}>
                <FormGroup className='mb-3'>
                  <Label className='form-label'>Device Type</Label>
                  <InputField
                    type='select'
                    placeholder=''
                    options={deviceTypeOptions}
                    invalid={{ errors, touched }}
                    {...getFieldProps('device_type')}
                  />
                </FormGroup>
              </Col>

              <Col lg={6}>
                <FormGroup className='mb-3'>
                  <Label className='form-label'>Manufacturer Name</Label>
                  <InputField
                    type='select'
                    placeholder=''
                    options={manufacturerNameOptions}
                    invalid={{ errors, touched }}
                    {...getFieldProps('manufacturer_name')}
                  />
                </FormGroup>
              </Col>

              <Col lg={6}>
                <FormGroup className='mb-3'>
                  <Label className='form-label'>Platform</Label>
                  <InputField
                    type='select'
                    placeholder=''
                    options={platformOptions}
                    invalid={{ errors, touched }}
                    {...getFieldProps('platform')}
                  />
                </FormGroup>
              </Col>

              <Col lg={6}>
                <FormGroup className='mb-3'>
                  <Label className='form-label'>Device ID</Label>
                  <InputField
                    type='text'
                    placeholder='Device ID'
                    invalid={{ errors, touched }}
                    readOnly={true}
                    {...getFieldProps('mac_address')}
                  />
                </FormGroup>
              </Col>

              <Col lg={12}>
                <FormGroup check className='mb-4 mt-2'>
                  <Input
                    type='checkbox'
                    role='check'
                    checked={values.status}
                    {...getFieldProps('status')}
                  />
                  <Label check>Status</Label>
                </FormGroup>
              </Col>

              <Col lg={12}>
                <Button type='reset' text='Reset' className='btn btn-secondary me-2 mb-3'></Button>
                {/* <Button type='submit' text='Save as Draft' className='btn btn-info me-2' onClick={(e) => {
              setBtnClicked('save_as_draft');
            }}></Button> */}
                <Button type='submit' text='Register' className='btn btn-primary mb-3' onClick={(e) => {
                  setBtnClicked('submit');
                }}></Button>
              </Col>
            </Row>

          </Form>
        </ModalBody>
      </Modal>
    </>

  );
};

export default AddDeviceReg;
