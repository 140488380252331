import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useState } from 'react';
import { config } from 'src/config';

const RichTextEditor = (props: any) => {
  const { formikState, initialContentEditor, setInitialContentEditor } = props;
  const [contentEditor, setContentEditor] = useState();
  const [textEditorContent, setTextEditorContent] = useState<string>('');

  const handleEditorChange = (content: any, editor: any) => {
    // console.log('Content was updated:', content);
    setContentEditor(content);
    setTextEditorContent(content);
  };

  console.log('main form');
  useEffect(() => {    

    let initialBody: any = '';

    if(formikState['description']) {
      initialBody = formikState['description'];
    } else {
      initialBody = '';
    }

    const initialContent: any = initialBody;
    setInitialContentEditor(initialContent);     
  }, []);

  useEffect(() => {
    formikState['description'] = contentEditor;
  }, [contentEditor]);

  return (
    <>
      <Editor
        initialValue={initialContentEditor}
        apiKey={config.EDITOR_API_KEY}
        init={{
          height: 500,
          menubar: true,
          plugins: [            
            'advlist',                        
            'autolink',            
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',            
            'fullscreen',            
            'insertdatetime',
            'media',
            'table',
            'wordcount',
          ],
          toolbar:
            'undo redo | casechange blocks | bold italic backcolor | ' +
            'alignleft aligncenter alignright alignjustify | ' +
            'bullist numlist checklist outdent indent | removeformat | a11ycheck code table',          
        }}
        // onInit={(evt, editor) => (editorRef.current = editor)}
        value={contentEditor ?? initialContentEditor}
        onEditorChange={handleEditorChange}
      />      
    </>
  );
};

export default RichTextEditor;
