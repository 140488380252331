import html2pdf from 'html2pdf.js';
import moment from 'moment';
import { useEffect } from 'react';
import { config } from 'src/config';
import fileOthers from '../../../assets/images/file-others.svg';

interface Props {
    thesisEntry: any;
    showReport: boolean;
    setShowReport: any;
}

export const ThesisDissertationSummaryReport = (props: Props) => {
    const { thesisEntry, showReport, setShowReport } = props;

    const thesisDetails: any = thesisEntry?.title?.thesis_master_data?.details!;

    const backgroundImage = new Image();
    backgroundImage.src = '/images/reviewers_copy_watermark.jpg';

    let html =
        `
            <style>   
                    @media print {
                    
                        body {
                            margin: 0;
                            color: #000;
                            background-color: #fff;
                        }
                        .ThesisDissertationSummaryReport {
                            padding: 0.5in;
                            color: #000000;
                        }    
                    }
                                       
                    tr {
                      border-top: 1px solid black;
                      border-left: 1px solid black;
                      border-bottom: 1px solid black;
                    }
                    td {
                      border-right: 1px solid black;
                    }
                    th {
                      border-right: 1px solid black;
                    }
            
            </style>  
                        
            <body>
               <div class='ThesisDissertationSummaryReport'> 
                    <table class='table table-sm'>
                        <thead class='bg-light fixed'>
                            <tr class='text-center text-dark font-size-16 fw-bold'>
                                <td colspan='4'>${thesisEntry?.document_type}</td>
                            </tr>
                        </thead>

                        <tbody class='text-capitalize text-dark font-size-14'>
                            <tr>
                                <td>1</td>
                                <td class='fw-bold'>Submission Date</td>
                                <td class=''>${moment(thesisEntry?.submission_date).isValid() ? moment(thesisEntry?.submission_date).format('DD/MMM/YYYY') : ''}</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td class='fw-bold'>Discipline</td>
                                <td>
                                    ${thesisEntry?.title?.registration?.subject?.subject_name ?? ''}
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td class='fw-bold'>Name of the researcher</td>
                                <td>${thesisEntry?.trainee_name ?? ''}</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td class='fw-bold'>Correspondence of the examinee and contact phone numbers</td>
                                <td>
                                    <p>
                                        <strong>Address: </strong>
                                        ${thesisEntry?.title?.registration?.address_infos[1]?.address_line_1 ? `${thesisEntry?.title?.registration?.address_infos[1]?.address_line_1}, ` : ``}
                                        ${thesisEntry?.title?.registration?.address_infos[1]?.police_station ? `${thesisEntry?.title?.registration?.address_infos[1]?.police_station}, ` : ``}
                                        ${thesisEntry?.title?.registration?.address_infos[1]?.district ? `${thesisEntry?.title?.registration?.address_infos[1]?.district}` : ``}.
                                    </p>
                                    <p><strong>Email Address: </strong> ${thesisEntry?.title?.registration?.personal_info?.email ?? ''}</p>
                                    <p><strong>Phone number: </strong> ${thesisEntry?.title?.registration?.personal_info?.mobile ?? ''}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td class='fw-bold'>Place of study</td>
                                <td>${thesisEntry?.title?.place_of_study ?? ''}</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td class='fw-bold'>Study period</td>
                                <td>${thesisEntry?.title?.study_period ?? ''} ${thesisEntry?.title?.study_period_unit ?? ''}</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td class='fw-bold'>Title of the ${thesisEntry?.document_type}</td>
                                <td>${thesisEntry?.title?.title_of_thesis ?? ''}</td>
                            </tr>

                            <tr>
                                <td>6</td>
                                <td class='fw-bold'>${thesisEntry?.document_type} File</td>
                                <td>
                                    <div class='dissertation-upload-files-wrapper'>
                                        <div class='dissertation-file-field-wrapper'>
                                            <div class='m-0 m-auto'>
                                                <a                                                    
                                                    href='${config?.APP_URL}${thesisEntry?.document_name}'
                                                    rel='noreferrer'
                                                    target='_blank'
                                                >
                                                    <span class='uploaded-file-preview'>
                                                        <img src=${fileOthers} alt='' />
                                                    </span>
                                                    <p class='file-name my-2'>
                                                        ${thesisEntry?.document_name}
                                                    </p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                </td>
                            </tr>

        ${thesisDetails?.map((detailsObj: any, index: number) => {
            if (['acceptance (signatory)', 'protocol', 'title', '1.1 title']?.includes(detailsObj?.thesis_setup_column_name?.toLowerCase())) {
                return null;
            } else if (detailsObj.details_text && !detailsObj.details_file) {
                return (
                    `<tr>
                        <td>${index + 6}</td>
                        <td class='fw-bold'>${detailsObj.thesis_setup_column_name}</td>
                        <td>
                            <span>${detailsObj?.details_text}</span>
                        </td>
                    </tr>`
                )
            } else if (!detailsObj.details_text && detailsObj.details_file) {
                return (
                    `<tr>
                        <td>${index + 6}</td>
                        <td class='fw-bold'>${detailsObj.thesis_setup_column_name}</td>
                        <td>
                            <div class='dissertation-upload-files-wrapper'>
                                <div class='dissertation-file-field-wrapper'>
                                    <div class='m-0 m-auto'>
                                        <a
                                            class=''
                                            href='${config?.APP_URL}${detailsObj.details_file}'
                                            rel='noreferrer'
                                            target='_blank'
                                        >
                                            <span class='uploaded-file-preview'>
                                                <img src=${fileOthers} alt='' />
                                            </span>
                                            <p class='file-name my-2'>
                                                ${detailsObj.details_file}
                                            </p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>`
                )
            } else if (detailsObj.details_text && detailsObj.details_file) {
                return (
                    `<tr>
                        <td rowspan="2">${index + 6}</td>
                        <td class='border-end border-dark'>
                            <p class='fw-bold'>${detailsObj.thesis_setup_column_name}</p>
                        </td>
                        <td>
                            <span>${detailsObj?.details_text}</span>
                        </td>
                    </tr>
                    <tr>                        
                        <td class='border-end border-dark'>
                            <p class='fw-bold'>${detailsObj.thesis_setup_column_name} (Attachment)</p>
                        </td>
                        <td>
                            <div class='dissertation-upload-files-wrapper'>
                                <div class='dissertation-file-field-wrapper'>
                                    <div class='m-0 m-auto'>
                                        <a
                                            class=''
                                            href='${config?.APP_URL}${detailsObj.details_file}'
                                            rel='noreferrer'
                                            target='_blank'
                                        >
                                            <span class='uploaded-file-preview'>
                                                <img src=${fileOthers} alt='' />
                                            </span>
                                            <p class='file-name my-2'>
                                                ${detailsObj.details_file}
                                            </p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>`
                )
            } else if (JSON.parse(detailsObj?.file_array) instanceof Array
                && JSON.parse(detailsObj?.file_array)?.length > 0) {
                return (
                    `<tr>
                        <td>${index + 6}</td>
                        <td class='fw-bold'>${detailsObj.thesis_setup_column_name}</td>
                        <td>
                            <table class='table text-center'>
                                <thead>
                                    <th>Sl. No.</th>
                                    <th>File Name</th>
                                    <th>File Preview</th>
                                </thead>
                                <tbody>
                                    ${JSON.parse(detailsObj?.file_array)?.map((fileObj: any, index: number) => {
                        return (
                            `<tr>
                                                <td>
                                                    ${index + 1}
                                                </td>
                                                <td>
                                                    ${fileObj.file_name}
                                                </td>
                                                <td>
                                                    <a
                                                        class="me-2 p-0 center"
                                                        href='${config?.APP_URL}${fileObj.file}'
                                                        rel="noreferrer"
                                                        target="_blank"
                                                    >
                                                        <span class="btn-outline-info btn-sm btn ms-2">View</span>
                                                    </a>
                                                </td>
                                            </tr>`
                        )
                    }).join('')}                                               
                                </tbody>
                            </table>
                        </td>
                    </tr>`
                )
            } else {
                return (
                    `<tr key=${detailsObj.id}>
                        <td>${index + 6}</td>
                        <td class='fw-bold'>${detailsObj.thesis_setup_column_name}</td>
                        <td>

                        </td>
                    </tr>`
                )
            }
        }).join('')}
                        </tbody>
                    </table>               
                </div>
            </body>`;

    useEffect(() => {
        setShowReport(false);
    }, []);

    const options = {
        margin: [15, 15],
        filename: 'thesisDissertation.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 1.5, letterRendering: true },
        jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    };

    html2pdf()
        .from(html)
        .set(options)
        .toPdf()
        .get('pdf')
        .then(function (pdf: any) {
            let totalPages = pdf.internal.getNumberOfPages();

            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                pdf.setFontSize(117);
                pdf.setTextColor('#f7efef');                                
                pdf.text("Reviewer's Copy", 90, pdf.internal.pageSize.getHeight() / 2 + 400, { angle: '55', rotationDirection: '1', renderingMode: 'fillAndAddForClipping' });                          
            }

            window.open(pdf.output('bloburl'), '_blank');
        });

    return null;
};
