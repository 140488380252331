import { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import * as url from 'src/helpers/url_helper';
import { CommentSectionModal } from "../CommentSectionForProtocol/CommentSectionModal";
import RichTextEditor from "./RichTextEditor";

const BasicInformation = (props: any) => {

    const { existingProtocol, title, setObjectivesRichtexts, objectivesRichtexts, formik, commentsDataList, initialContentEditor, setInitialContentEditor, formikField, renderKey, personalProfile } = props;

    const [messages, setMessage] = useState<any>([]);
    const [showCommentSectionModal, setShowCommentSectionModal] = useState<boolean>(false);

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    const getCommentsData = (id: any, nameOfTheField: any) => {
        commentsDataList(`${url.THESIS_DISSERTATION_PROTOCOL_COMMENT_LIST}/${id}/${nameOfTheField}`).then(function (result: any,) {
            // here you can use the result of promiseB
            if (result?.data?.data) {
                setMessage(result?.data?.data);
            }
        });

        // setMessage(commentsData?.data || []);
    };

    useEffect(() => {
        getCommentsData(existingProtocol?.data?.id, 'summary');
    }, []);

    // console.log("existingProtocol", existingProtocol); 
    return (
        <>
            <Row>
                <Col lg={12}>

                    <div className='thesis-basic-info'>
                        <div>
                            <ol>
                                {existingProtocol.success ? (existingProtocol.data.submission_status === 'Draft' ?
                                    <li>Protocol submission date: <b>Not yet submitted</b></li> :
                                    <li>Protocol submission date: <b>{existingProtocol.data.protocol_submission_date}</b></li>)
                                    : null}

                                <li> Relevant:
                                    <ol style={{ listStyleType: 'lower-alpha' }}>
                                        <li><b>Faculty: </b> {existingProtocol?.data?.relevant_faculty ?? 'N/A'}</li>
                                        <li><b>Discipline: </b> {existingProtocol?.data?.relevant_discipline ?? 'N/A'}</li>

                                    </ol>
                                </li>
                                <li>Researcher Name: {existingProtocol?.data?.title?.trainee_name || 'N/A'}</li>
                            </ol>
                        </div>
                        <div>
                            <ol start={4}>

                                <li>Correspondence of the examinee and contact
                                    {existingProtocol?.data?.title?.registration ? (
                                        <ol style={{ listStyleType: 'lower-alpha' }}>
                                            <li>
                                                <b>Address: </b> {existingProtocol?.data?.title?.registration?.address_infos[1]?.address_line_2} , {existingProtocol?.data?.title?.registration?.address_infos[1]?.police_station}, {existingProtocol?.data?.title?.registration?.address_infos[1]?.district}.
                                            </li>
                                            <li><b>Phone: </b> {existingProtocol?.data?.title?.registration?.personal_info?.mobile}</li>
                                            <li><b>E-mail: </b> {existingProtocol?.data?.title?.registration?.personal_info?.email}</li>
                                        </ol>
                                    ) : (
                                        <ol style={{ listStyleType: 'lower-alpha' }}>
                                            <li><b>Address: </b> N/A</li>
                                            <li><b>Phone: </b> N/A</li>
                                            <li><b>E-mail: </b> N/A</li>
                                        </ol>
                                    )}
                                    {/* <li>Address: {existingProtocol?.data?.title?.registration?.address_info[1]?.address_line_1} , {existingProtocol?.data?.title?.registration?.address_info[1]?.police_station}, {existingProtocol?.data?.title?.registration?.address_info[1]?.district}.</li>
                                <li>Phone Number: 017XXXXXXXX</li>
                                <li>E-mail Address: fcpstrainee@gmail.com</li> */}
                                </li>
                            </ol>
                        </div>
                        <div>
                            <ol start={7}>
                                {existingProtocol?.data?.title ? (
                                    <li>Place of Study: <b>{existingProtocol?.data?.title?.place_of_study}</b>
                                    </li>) : (<li>Place of Study: <b>N/A</b></li>)}

                                {existingProtocol?.data?.title ? (
                                    <li>Study Period: <b>{existingProtocol?.data?.title?.study_period} {existingProtocol?.data?.title?.study_period_unit} </b>
                                    </li>) : (
                                    <li>Study Period: <b>N/A</b></li>)}
                            </ol>
                        </div>
                        <div className='thesis-title-col'>
                            <ol start={5}>
                                {existingProtocol?.data?.title ? (<li>Title of the Thesis: <b>{existingProtocol?.data?.title?.title_of_thesis}</b></li>) : (
                                    <li>Title of the Thesis: <b>N/A</b></li>)}
                            </ol>

                        </div>
                    </div>

                </Col>
            </Row>
            <Row className='justify-content-between align-items-center py-3'>
                <Col sm={3}>
                    <p><b>6. Summary</b></p>
                </Col>
                <Col sm="auto" style={{ pointerEvents: 'auto' }} className='btn noti-icon position-relative'>
                    <span className="badge bg-danger rounded-pill">{messages?.length ?? 0}</span>
                    <Button
                        type='button'
                        className='btn-sm'
                        onClick={(e) => {
                            setShowCommentSectionModal(!showCommentSectionModal);
                        }}
                    >
                        <i className='fas fa-2x fa-comments'></i>
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <RichTextEditor
                        setObjectivesRichtexts={setObjectivesRichtexts}
                        objectivesRichtexts={objectivesRichtexts}
                        formikState={formik.values}
                        initialContentEditor={initialContentEditor}
                        setInitialContentEditor={setInitialContentEditor}
                        formikField={formikField}
                        key={renderKey}
                    />


                    {/* <InputField
                        type="textarea"
                        className="textarea-protocol-entry"
                        placeholder="Summary"
                        invalid={{ errors, touched }}
                        {...getFieldProps('summary')}
                    /> */}



                </Col>

                {showCommentSectionModal ?
                    <CommentSectionModal
                        fieldName={'summary'}
                        fieldLabel={'Summary'}
                        existingProtocol={existingProtocol}
                        showModal={showCommentSectionModal}
                        setShowModal={setShowCommentSectionModal}
                        setMessageCount={setMessage}
                    />
                    :
                    null
                }
            </Row>
        </>
    );

};
export default BasicInformation;