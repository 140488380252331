import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { Button } from 'src/components';
import { useGetPermissionOfRoleQuery } from 'src/rtk/features/permissions/permissionApi';
import { useDeleteRoleMutation } from 'src/rtk/features/roles/roleApi';
import DetailsModal from './DetailsModal';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';

interface Props {
  role: Role;
  index: number;
}

const RoleTableRow = ({ role, index }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSkip, setIsSkip] = useState(true);

  const [infoId, setInfoId] = useState<any>('');
  const [modalShow, setModalShow] = useState(false);

  const [deleteUser, { isSuccess, isLoading, isError }] =
    useDeleteRoleMutation();

  const { data: permissionData, isLoading: isPermissionLoading } =
    useGetPermissionOfRoleQuery(role.name, {
      skip: isSkip,
    });

  const permissions = permissionData?.data;

  const openModalHandler = () => {
    setIsModalOpen(true);
    setIsSkip(false);
  };
  const closeModalHandler = () => {
    setIsModalOpen(false);
    setIsSkip(true);
  };

  const deleteHandler = (modalState: boolean, id: number) => {
    setModalShow(modalState);
    setInfoId(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('User deleted successfully');
    }
    if (isError) {
      toast.error('Error deleting user');
    }
  }, [isSuccess, isError]);

  return (
    <tr key={role.id}>
      <th scope="row">{++index}</th>
      <td className="text-capitalize">{role.name}</td>
      <td>
        <div className="d-flex gap-2">
          <Link
            to={`/roles/assign-permission/${role.name}`}
            type="button"
            className="btn btn-success btn-sm"
          >
            Permission Assign
          </Link>

          <Button
            size="sm"
            text="Details"
            color="info"
            onClick={openModalHandler}
          />
          <Link
            to={`/roles/edit-role/${role.name}`}
            type="button"
            className="btn btn-warning btn-sm"
          >
            Edit
          </Link>

          <DetailsModal
            isOpen={isModalOpen}
            onClose={closeModalHandler}
            role={role}
            isLoading={isPermissionLoading}
            permissions={permissions}
          />
          <Button
            size="sm"
            text="Delete"
            color="danger"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => deleteHandler(true, role.id as number)}
          />
        </div>
      </td>
      {modalShow && infoId === role?.id ? (
        <DeleteInformationModal
          isOpen={modalShow}
          toggle={() => setModalShow(!modalShow)}
          id={infoId}
          deleteInfomation={deleteUser}
        />
      ) : null}
    </tr>
  );
};

export default RoleTableRow;
