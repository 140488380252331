import { apiSlice } from 'src/rtk/api/apiSlice';
import * as logbookEntrySetupDetailsUrl from 'src/helpers/url_helper';

export const logBookEntrySetupDetailsApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: [
      'logBookEntrySetupDetails',
      'logBookEntrySetupMaster',
      'logBookEntrySetupDetailsGroup',
      'logBookEntrySetupCompetencyGroup',
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLogbookEntrySetupDetails: builder.query<
        APIResponse<LogbookEntryDetails[]>,
        void
      >({
        query: () => ({
          method: 'GET',
          url: `${logbookEntrySetupDetailsUrl.GET_LOGBOOK_ENTRY_SETUP_DETAILS_IN_SETUP}`,
        }),

        providesTags: ['logBookEntrySetupDetails'],
      }),

      getLogbookEntrySetupDetailsByPaginate: builder.query<
        APIResponseWithPagination<LogbookEntryDetails>,
        PaginateForLogbookEntries
      >({
        query: (paginate) => {
          let url = `${logbookEntrySetupDetailsUrl.GET_LOGBOOK_ENTRY_SETUP_DETAILS_BY_PAGINATE}?`;

          if (paginate.page) {
            url += `page=${paginate.page}&`;
          }
          if (paginate.paginate_per_page) {
            url += `paginate_per_page=${paginate.paginate_per_page}`;
          }

          return {
            method: 'GET',
            url: url,
          };
        },
        providesTags: ['logBookEntrySetupDetails'],
      }),

      getLogbookEntrySetupDetail: builder.query<
        APIResponse<LogbookEntryDetails>,
        string
      >({
        query: (id) => ({
          url: `${logbookEntrySetupDetailsUrl.GET_LOGBOOK_ENTRY_SETUP_DETAIL_IN_SETUP}/${id}`,
          method: 'GET',
        }),

        providesTags: ['logBookEntrySetupDetails'],
      }),

      getFormByLogbookType: builder.query<
        APIResponse<LogbookEntrySetupMaster[]>,
        number
      >({
        query: (id: number) => ({
          url: `${logbookEntrySetupDetailsUrl.GET_FORM_BY_LOGBOOK_TYPE_IN_SETUP}/${id}`,
          method: 'GET',
        }),

        // providesTags: ['logBookEntrySetupMaster'],
      }),

      getFormByLogbookTypeAndSubject: builder.query<
        APIResponse<LogbookEntrySetupMaster[]>,
        any
      >({
        query: (queryParams: any) => ({
          url: `${logbookEntrySetupDetailsUrl.GET_FORM_BY_LOGBOOK_TYPE_AND_SUBJECT}/${queryParams.id}?course_type=${queryParams.course_type}&subject_id=${queryParams.subject_id}&main_training=${queryParams.main_training}`,
          method: 'GET',
        }),

        // providesTags: ['logBookEntrySetupMaster'],
      }),

      // getCaseTypeByForm: builder.query<APIResponse<LogbookEntrySetupMaster[]>, number>({
      //   query: (id) => ({
      //     url: `/get-form-list/${id}`,
      //     method: 'GET',
      //   }),

      //   // providesTags: ['logBookEntrySetupMaster'],
      // }),

      // getSkillTypeByCase: builder.query<APIResponse<LogbookEntrySetupMaster[]>, string>({
      //   query: (id) => ({
      //     url: `/skill-type-by-case/${id}`,
      //     method: 'GET',
      //   }),

      //   // providesTags: ['logBookEntrySetupMaster'],
      // }),

      getDynamicFormPropertyByParticular: builder.query<
        APIResponse<LogbookEntryDetails[]>,
        string
      >({
        query: (id) => ({
          url: `${logbookEntrySetupDetailsUrl.GET_DYNAMIC_FORM_PROPERTY_BY_PARTICULAR_IN_SETUP}/${id}`,
          method: 'GET',
        }),

        // providesTags: ['logBookEntrySetupDetails'],
      }),

      getDynamicFormPropertyByParticularUpdatedApi: builder.query<
        APIResponse<LogbookEntryDetails[]>,
        any
      >({
        query: (obj) => {
          let url = `${logbookEntrySetupDetailsUrl.GET_DYNAMIC_FORM_PROPERTY_BY_PARTICULAR_UPDATED_API}/${obj.id}?publish_status=${obj.publish_status}`;

          if(obj?.status === '0' || obj?.status === '1') {
            url = url + `&status=${obj?.status}`;
          }

          return {
            url: url,
            method: 'GET'
          };
          
        },

        providesTags: ['logBookEntrySetupDetails'],
      }),

      getDynamicFormPropertyByParticularSetupApi: builder.query<
        APIResponse<DynamicLogbookFieldsSetup>,
        any
      >({
        query: (obj) => {
          let url = `${logbookEntrySetupDetailsUrl.GET_DYNAMIC_FORM_PROPERTY_BY_PARTICULAR_SETUP_API}/${obj.id}?publish_status=${obj.publish_status}&type=${'setup'}`;          

          if(obj?.status === '0' || obj?.status === '1') {
            url = url + `&status=${obj?.status}`;
          }

          return {
            url: url,
            method: 'GET'
          };          
        },

        providesTags: ['logBookEntrySetupDetails'],
      }),

      getFcpsCourseListsForLogbookEntry: builder.mutation<
        APIResponse<FcpsCourseList[]>,
        string
      >({
        query: (subject_id: string) => ({
          url: `${logbookEntrySetupDetailsUrl.GET_FCPS_COURSE_LISTS_FOR_LOGBOOK_ENTRY}`,
          method: 'POST',
          body: { type: 'main-training', subject_id: subject_id },
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        // invalidatesTags: ['TraineeFcpsCourseList'],
      }),

      createLogbookEntrySetupDetail: builder.mutation<
        APIResponse<LogbookEntryDetails>,
        LogbookEntryDetails
      >({
        query: (data) => ({
          url: `${logbookEntrySetupDetailsUrl.CREATE_LOGBOOK_ENTRY_SETUP_DETAIL_IN_SETUP}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['logBookEntrySetupDetails'],
      }),

      deleteLogbookEntrySetupDetailUpdated: builder.mutation<
        any,
        any
      >({
        query: (data) => ({
          url: `${logbookEntrySetupDetailsUrl.DELETE_LOGBOOK_ENTRY_SETUP_DETAIL_UPDATED}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['logBookEntrySetupDetails'],
      }),

      publishLogbookEntrySetupDetail: builder.mutation<
        any,
        any
      >({
        query: (data) => ({
          url: `${logbookEntrySetupDetailsUrl.PUBLISH_LOGBOOK_ENTRY_SETUP_DETAIL}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['logBookEntrySetupDetails'],
      }),

      updateLogbookEntrySetupDetail: builder.mutation<
        APIResponse<LogbookEntryDetails>,
        any
      >({
        query: (obj) => ({
          url: `${logbookEntrySetupDetailsUrl.UPDATE_LOGBOOK_ENTRY_SETUP_DETAIL_IN_SETUP}` + obj.url,
          method: 'PATCH',
          body: {options: obj.options},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['logBookEntrySetupDetails'],
      }),

      deleteLogbookEntrySetupDetail: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${logbookEntrySetupDetailsUrl.DELETE_LOGBOOK_ENTRY_SETUP_DETAIL_IN_SETUP}/${id}`,
        }),
        invalidatesTags: ['logBookEntrySetupDetails'],
      }),

      createLogbookEntrySetupDetailGroup: builder.mutation<
        APIResponse<LogbookEntryDetailsGroup>,
        LogbookEntryDetailsGroup
      >({
        query: (data) => ({
          url: `${logbookEntrySetupDetailsUrl.CREATE_LOGBOOK_ENTRY_SETUP_DETAIL_GROUP}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['logBookEntrySetupDetailsGroup'],
      }),

      createLogbookEntrySetupCompetencyGroup: builder.mutation<
        APIResponse<any>,
        AddCompetencyGroup       
      >({
        query: (data: AddCompetencyGroup) => ({
          url: `${logbookEntrySetupDetailsUrl.CREATE_LOGBOOK_ENTRY_SETUP_COMPETENCY_GROUP}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['logBookEntrySetupCompetencyGroup', 'logBookEntrySetupDetails'],
      }),

      getLogbookEntrySetupDetailsGroups: builder.query<
        APIResponse<LogbookEntryDetailsGroup[]>,
        void
      >({
        query: () => ({
          method: 'GET',
          url: `${logbookEntrySetupDetailsUrl.GET_LOGBOOK_ENTRY_SETUP_DETAILS_GROUPS}`,
        }),

        providesTags: ['logBookEntrySetupDetailsGroup'],
      }),

      deleteLogbookEntryCompetencyGroup: builder.mutation<
        APIResponse<number>,
        {logbook_entry_setup_master_id: string}
      >({
        query: (data) => ({
          url: `${logbookEntrySetupDetailsUrl.DELETE_LOGBOOK_ENTRY_COMPETENCY_GROUP}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['logBookEntrySetupDetails'],
      }),
    }),
  });

export const {
  useCreateLogbookEntrySetupDetailMutation,
  useGetLogbookEntrySetupDetailQuery,
  useGetLogbookEntrySetupDetailsQuery,
  useGetLogbookEntrySetupDetailsByPaginateQuery,
  useGetFormByLogbookTypeQuery,
  useLazyGetFormByLogbookTypeQuery,
  useGetFormByLogbookTypeAndSubjectQuery,
  useLazyGetFormByLogbookTypeAndSubjectQuery,
  useGetFcpsCourseListsForLogbookEntryMutation,
  useGetDynamicFormPropertyByParticularQuery,
  useLazyGetDynamicFormPropertyByParticularQuery,
  useUpdateLogbookEntrySetupDetailMutation,
  useDeleteLogbookEntrySetupDetailMutation,
  useCreateLogbookEntrySetupDetailGroupMutation,
  useCreateLogbookEntrySetupCompetencyGroupMutation,
  useGetLogbookEntrySetupDetailsGroupsQuery,
  useLazyGetLogbookEntrySetupDetailsGroupsQuery,
  useGetDynamicFormPropertyByParticularUpdatedApiQuery,
  useGetDynamicFormPropertyByParticularSetupApiQuery,
  useLazyGetDynamicFormPropertyByParticularSetupApiQuery,
  useDeleteLogbookEntrySetupDetailUpdatedMutation,
  useDeleteLogbookEntryCompetencyGroupMutation,
  usePublishLogbookEntrySetupDetailMutation,
} = logBookEntrySetupDetailsApi;
