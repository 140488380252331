import DepartmentOfflinePayment from "src/pages/InstituteDepartmentPayment/DepartmentOfflinePayment";
import InstitueOfflinePayment from "src/pages/InstituteRegistration/InstitueOfflinePayment";
import InstitutePaymentList from "src/pages/InstituteRegistration/InstitutePaymentList";
import DepartmentPaymentApprovalList from "src/pages/Payment/DepartmentPaymentApprovalList";
import InstitutePaymentApprovalList from "src/pages/Payment/InstitutePaymentApprovalList";
import ViewPaymentList from "src/pages/Payment/ViewPaymentList";


export const paymentRoutes = [
    { path: '/view-payment-list', component: ViewPaymentList },    
    { path: '/institute-offline-payment/:type/:id/:fees', component: InstitueOfflinePayment },    
    { path: '/department-offline-payment/:type/:id/:fees', component: DepartmentOfflinePayment },    
    { path: '/institute/payment-list', component: InstitutePaymentList },    

    //For Admin Approval List
    { path: '/institute/payment-approval-list', component: InstitutePaymentApprovalList },    
    { path: '/department/payment-approval-list', component: DepartmentPaymentApprovalList },    
];
