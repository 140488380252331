import { apiSlice } from 'src/rtk/api/apiSlice';
import * as instituteInspectionUrl from 'src/helpers/url_helper';

export const institutueInspectionApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['Inspection', 'CheckMember'] })
  .injectEndpoints({
    endpoints: (builder) => ({     
      
      createInspectionTeam: builder.mutation<any, any>({
        query: (data: any) => ({
          url: `${instituteInspectionUrl.CREATE_INSPECTION_TEAM}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['Inspection'],
      }),

      departmentAssign: builder.mutation<any, any>({
        query: (data: any) => ({
          url: `${instituteInspectionUrl.DEPARTMENT_ASSIGN}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['Inspection'],
      }),

      updateInspectionTeam: builder.mutation<any, any>({
        query: (data: any) => ({
          url: `${instituteInspectionUrl.UPDATE_INSPECTION_TEAM}/${data.id}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['Inspection'],
      }),


      getInspectionTeamListForAdmin: builder.query<DataWithPagination<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${instituteInspectionUrl.GET_INSPECTION_TEAM_LIST_FOR_ADMIN}?${url}`,
        }),

        providesTags: ['Inspection'],
      }),

      getRecomendationListForAdmin: builder.query<APIResponse<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${instituteInspectionUrl.GET_RECOMMENDATION_LIST_FOR_ADMIN}?${url}`,
        }),

        providesTags: ['Inspection'],
      }),

      getRecomendationList: builder.query<APIResponse<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${instituteInspectionUrl.GET_RECOMMENDATION_LIST}?${url}`,
        }),

        providesTags: ['Inspection'],
      }),

      getInspectionTeamView: builder.query<any, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${instituteInspectionUrl.GET_INSPECTION_TEAM_VIEW}/${id}`,
        }),

        providesTags: ['Inspection'],
      }),

      getAssignedDepartment: builder.query<any, any>({
        query: (url: any) => ({
          method: 'GET',
          url: `${instituteInspectionUrl.GET_ASSIGNED_DEPARTMENT}/${url.id}?institute_id=${url.institute_id}`,
        }),

        providesTags: ['Inspection'],
      }),

      getAssignedDepartmentForView: builder.query<any, any>({
        query: (url: any) => ({
          method: 'GET',
          url: `${instituteInspectionUrl.GET_ASSIGNED_DEPARTMENT_FOR_VIEW}/${url.id}?institute_id=${url.institute_id}&inspection_date=${url.inspection_date}`,
        }),

        providesTags: ['Inspection'],
      }),

      createInspectionRecomendation: builder.mutation<any, any>({
        query: (data) => ({
          url: `${instituteInspectionUrl.CREATE_INSPECTION_RECOMMENDATION}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['Inspection'],
      }),

      getMemberRecomendationCheck: builder.mutation<any, any>({
        query: (url: any) => ({
          method: 'GET',
          url: `${instituteInspectionUrl.GET_MEMBER_RECOMMENDATION_CHECK}?${url}`,
        }),

        invalidatesTags: ['CheckMember'],
      }),

      getRecommendationApproval: builder.mutation<APIResponse<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${instituteInspectionUrl.GET_RECOMMENDATION_APPROVAL}?${url}`,
        }),
        invalidatesTags: ['Inspection'],
      }),

      deleteInspectionTeam: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${instituteInspectionUrl.DELETE_INSPECTION_TEAM}/${id}`,
        }),
        invalidatesTags: ['Inspection'],
      }),
    }),
  });

export const {
    useCreateInspectionTeamMutation,
    useDepartmentAssignMutation,
    useUpdateInspectionTeamMutation,
    useGetInspectionTeamListForAdminQuery,
    useGetRecomendationListForAdminQuery,
    useGetInspectionTeamViewQuery,
    useGetRecomendationListQuery,
    useLazyGetAssignedDepartmentQuery,
    useLazyGetAssignedDepartmentForViewQuery,
    useGetMemberRecomendationCheckMutation,
    useCreateInspectionRecomendationMutation,
    useGetRecommendationApprovalMutation,
    useDeleteInspectionTeamMutation,
} = institutueInspectionApi;
