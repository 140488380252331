import { apiSlice } from 'src/rtk/api/apiSlice';
import * as educationalUrl from 'src/helpers/url_helper';

export const educationalApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['EducationalProfiles'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getEducationalProfile: builder.query<EducationalProfile, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${educationalUrl.GET_EDUCATIONAL_PROFILE}/${id}`,
        }),
        providesTags: ['EducationalProfiles'],
      }),

      getFcpsPartOneInfo: builder.query<any, string>({
        query: (bmdcRegNo: string) => ({
          method: 'GET',
          url: `${educationalUrl.GET_FCPS_PART_1_INFO}/${bmdcRegNo}`,
        }),
        providesTags: ['EducationalProfiles'],
      }),

      createEducationalProfile: builder.mutation<
        APIResponse<EducationalProfile>,
        any
      >({
        query: (data: any) => ({
          url: `${educationalUrl.CREATE_EDUCATIONAL_PROFILE}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['EducationalProfiles'],
      }),

      updateEducationalProfile: builder.mutation<
        APIResponse<EducationalProfile>,
        any
      >({
        query: (url) => ({
          url: `${educationalUrl.UPDATE_EDUCATIONAL_PROFILE}/${url.id}`,
          method: 'POST',
          body: url.data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['EducationalProfiles'],
      }),
      deleteEducationalProfile: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${educationalUrl.DELETE_EDUCATIONAL_PROFILE}/${id}`,
        }),
        invalidatesTags: ['EducationalProfiles'],
      }),
    }),
  });

export const {
  useGetEducationalProfileQuery,
  useLazyGetFcpsPartOneInfoQuery,
  useCreateEducationalProfileMutation,
  useUpdateEducationalProfileMutation,
  useDeleteEducationalProfileMutation,
} = educationalApi;
