import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Col, Form, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import * as Yup from 'yup';

import toast from 'react-hot-toast';
import { Button, InputField } from 'src/components';
import {
  useGetTrainingNameListQuery,
  useLazyGetTrainingNameListQuery,
  useUpdateTrainingWorkshopMutation,
} from 'src/rtk/features/trainingWorkshop/trainingWorkshopApi';
import NoticeList from './NoticeSelectionList';

const initialValues: TrainingWorkshopEdit = {
  id: '',
  workshop_create_date: '',
  notice_date: '',
  notice_ref_no: '',
  training_type: '',
  exam_training_id: '',
  training_name: '',
  training_mode: '',
  training_for: '',
  duration: '',
  fees: '',
  application_start_date: '',
  application_deadline: '',
  no_of_participants: '',
  submit_status: 'Submit',
};

const EditTrainingWorkshop = (props: any) => {
  const { trainingWorkshopForEdit, setShowAddForm } = props;

  // const [mainTrainingData, setMainTrainingData] = useState<FcpsCourseList[]>([]);
  // const [defaultMainTrainingOption, setDefaultMainTrainingOption] = useState<SelectOption>({} as SelectOption);
  // const [mainTrainingOptions, setMainTrainingOptions] = useState<SelectOption[]>([]);
  // const [mainTrainingId, setMainTrainingId] = useState<string>('');
  const [defaultTrainingOption, setDefaultTrainingOption] =
    useState<SelectOption>({} as SelectOption);
  const [trainingNameOptions, setTrainingNameOptions] = useState<
    SelectOption[]
  >([]);
  const [examTrainingId, setExamTrainingId] = useState<string>('');
  const [trainingName, setTrainingName] = useState<string>('');
  const [trainingType, setTrainingType] = useState<string>('');
  const [duration, setDuration] = useState<string>('');
  const [trainingFee, setTrainingFee] = useState<string>('');
  const [onlineRadio, setOnlineRadio] = useState<boolean>(true);
  const [offlineRadio, setOfflineRadio] = useState<boolean>(false);
  const [btnClicked, setBtnClicked] = useState<string>('');
  const [applicableForTrainee, setApplicableForTrainee] =
    useState<boolean>(false);
  const [applicationStartDate, setApplicationStartDate] = useState<string>(
    trainingWorkshopForEdit?.application_start_date?.substring(0, 10),
  );
  const [applicationDeadline, setApplicationDeadline] = useState<string>(
    trainingWorkshopForEdit?.application_deadline?.substring(0, 10),
  );
  const [noticeDate, setNoticeDate] = useState<string>(
    trainingWorkshopForEdit?.notice_date?.substring(0, 10),
  );
  const [noticeModalShow, setNoticeModalShow] = useState<boolean>(false);
  const [continueState, setContinueState] = useState<boolean>(false);
  const [selectedNotice, setSelectedNotice] = useState<NoticeList>(
    {} as NoticeList,
  );
  const [initialRenderState, setInitialRenderState] = useState<boolean>(true);
  const [secondInitialRenderState, setSecondInitialRenderState] = useState<boolean>(true);

  const [updateTrainingWorkshop, { isSuccess, isError, error }] =
    useUpdateTrainingWorkshopMutation();
  const {
    data: allMainTraining,
    isError: isAllMainTrainingError,
    error: allMainTrainingError,
    isSuccess: isAllMainTrainingSuccess,
  } = useGetTrainingNameListQuery({ workshop_training: 'Workshop/Training', page: 'all' });
  // const [getMainTraining, {data: mainTraining, isError: isMainTrainingError, error: mainTrainingError, isSuccess: isMainTrainingSuccess}] = useGetFcpsCourseListsMutation();
  const [
    getTrainingNames,
    {
      data: trainingNames,
      isError: isTrainingNamesError,
      error: trainingNamesError,
      isSuccess: isTrainingNamesSuccess,
    },
  ] = useLazyGetTrainingNameListQuery();

  const err: CustomError = error as CustomError;

  // const noticeReferenceOptions: SelectOption[] =
  //   useGetNoticeTemplateListQuery()
  //     .data?.data?.map((item) => ({
  //       value: item?.id!,
  //       label: item?.template_name!,
  //     }))
  //     .sort((a, b) => {
  //       return a?.label?.localeCompare(b?.label);
  //     }) || [];

  const {
    handleSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    resetForm,
    setValues,
    setFieldValue,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      training_type: Yup.string().required(),
      notice_date: Yup.date()
        .min(
          new Date(Date.now() - 86400000),
          "Notice date can't be a past date",
        )
        .required('Notice Date is Required!'),
    }),
    onSubmit: (values) => {
      if (btnClicked === 'submit') {
        values.submit_status = 'Submit';
      } else if (btnClicked === 'save_as_draft') {
        values.submit_status = 'Draft';
      }

      values.id = trainingWorkshopForEdit.id;
      values.exam_training_id = examTrainingId;
      values.training_name = trainingName;
      values.training_for = values.training_for.toString();
      values.duration = duration;
      values.fees = trainingFee;
      // values.notice_date = noticeDate;
      values.notice_ref_no = selectedNotice?.id;
      // values.workshop_create_date = new Date(Date.now()).toISOString().slice(0, 10);

      updateTrainingWorkshop({
        ...values,
      });
    },
  });

  useEffect(() => {
    const data: TrainingWorkshopEdit = trainingWorkshopForEdit as any;

    console.log(data);

    let applicable_for = data.training_for ? data.training_for.split(',') : [];

    setValues({
      id: data.id,
      workshop_create_date: data.workshop_create_date,
      notice_date: data.notice_date?.substring(0, 10),
      notice_ref_no: data.notice_ref_no,
      training_type: data.training_type,
      exam_training_id: data.exam_training_id,
      training_name: data.training_name,
      training_mode: data.training_mode,
      training_for: applicable_for,
      duration: data.duration,
      fees: data.fees,
      application_start_date: data.application_start_date?.substring(0, 10),
      application_deadline: data.application_deadline?.substring(0, 10),
      no_of_participants: data.no_of_participants,
      submit_status: data.submit_status,
    });

    if (data.training_mode === 'Online') {
      setOnlineRadio(true);
      setOfflineRadio(false);
    } else if (data.training_mode === 'Offline') {
      setOnlineRadio(false);
      setOfflineRadio(true);
    }

    if (data.training_type === 'Mandatory') {
      setApplicableForTrainee(true);
    } else if (data.training_type === 'Optional') {
      setApplicableForTrainee(false);
    }

    setExamTrainingId(data.exam_training_id);
    setTrainingName(data.training_name);
    setDuration(data.duration);
    setTrainingFee(data.fees);

    const startDate = new Date(
      trainingWorkshopForEdit?.application_start_date?.substring(0, 10),
    );
    const endDate = new Date(
      trainingWorkshopForEdit?.application_deadline?.substring(0, 10),
    );

    if (endDate.getFullYear() - startDate.getFullYear() >= 2000) {
      setContinueState(true);
    } else {
      setFieldValue(
        'application_start_date',
        trainingWorkshopForEdit?.application_start_date?.substring(0, 10),
      );
      setFieldValue(
        'application_deadline',
        trainingWorkshopForEdit?.application_deadline?.substring(0, 10),
      );
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      // setMainTrainingId('');
      setDuration('');
      setTrainingFee('');
      setApplicationStartDate('');
      setApplicationDeadline('');
      setNoticeDate('');
      setOnlineRadio(true);
      setOfflineRadio(false);
      // setDefaultMainTrainingOption({value: '', label: 'Select...'});
      setDefaultTrainingOption({ value: '', label: 'Select...' });
      resetForm();
      setShowAddForm(false);
      setContinueState(false);
      setApplicableForTrainee(false);
      setSelectedNotice({} as NoticeList);
      toast.success('Training/Workshop info updated successfully');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      const errors: any = {};

      if (err?.data) {
        Object.keys(err?.data).forEach((key) => {
          errors[key] = err?.data[key][0];
        });
      }
      setErrors(errors);
    }
  }, [isSuccess, isError]);

  // useEffect(() => {
  //   getMainTraining('main-training');
  // }, []);

  useEffect(() => {
    if (allMainTraining && isAllMainTrainingSuccess) {
      const trainingName: ExamInfo = allMainTraining?.data?.find(
        (m) => m.id === trainingWorkshopForEdit.exam_training_id,
      )!;
      // const mainTraining: ExamInfo = trainingName.main_training;
      // const option: SelectOption = {value: mainTraining.id!, label: mainTraining.name!};
      // setDefaultMainTrainingOption(option);

      getTrainingNames({ workshop_training: 'Workshop/Training', page: 'all' });

      const trainingOption: SelectOption = {
        value: trainingName?.id!,
        label: trainingName?.name!,
      };
      setDefaultTrainingOption(trainingOption);

      // setTrainingFee(trainingWorkshopForEdit?.fees);
    }
  }, [allMainTraining, isAllMainTrainingSuccess]);

  // useEffect(() => {
  //   if(mainTraining && !isMainTrainingError) {
  //     const mainTrainingList: FcpsCourseList[] = mainTraining.data;
  //     setMainTrainingData(mainTrainingList);
  //   }
  // }, [mainTraining, isMainTrainingError]);

  // useEffect(() => {
  //   if(mainTrainingData.length) {
  //     const mainTrainingOps: SelectOption[] = mainTrainingData?.map((item) => ({
  //           value: item.id!,
  //           label: item.name!,
  //       }))
  //       .sort((a, b) => {
  //           return a.label.localeCompare(b.label);
  //       }) || [];

  //       const options: SelectOption[] = [{ value: '', label: 'Select...' }, ...mainTrainingOps];

  //     setMainTrainingOptions(options);
  //   }
  // }, [mainTrainingData]);

  // useEffect(() => {
  //   getTrainingNames({
  //     workshop_training: 'Workshop/Training',
  //   });
  // }, []);

  useEffect(() => {
    if (trainingNames?.data?.length && isTrainingNamesSuccess) {
      const trainingNameOps: SelectOption[] =
        trainingNames?.data
          ?.map((item) => ({
            value: item.id!,
            label: item.name! + '-' + item.code!,
          }))
          .sort((a, b) => {
            return a.label.localeCompare(b.label);
          }) || [];

      const options: SelectOption[] = [
        { value: '', label: 'Select...' },
        ...trainingNameOps,
      ];

      setTrainingNameOptions(options);
    }
  }, [trainingNames, isTrainingNamesSuccess]);

  // const handleMainTrainingSelection = (e: any) => {
  //   const { options, selectedIndex, value } = e.target;
  //   setMainTrainingId(value);
  // };

  useEffect(() => {
    if (continueState) {
      setFieldValue(
        'application_start_date',
        new Date(Date.now()).toISOString().substring(0, 10),
      );

      const thousandsYearsFromNow = new Date(Date.now());
      thousandsYearsFromNow.setFullYear(
        thousandsYearsFromNow.getFullYear() + 2000,
      );
      setFieldValue(
        'application_deadline',
        thousandsYearsFromNow.toISOString().substring(0, 10),
      );
    } else {
      if (!initialRenderState) {
        setFieldValue('application_start_date', '');
        setFieldValue('application_deadline', '');
      }
    }
  }, [continueState]);

  useEffect(() => {
    if (secondInitialRenderState) {
      if (values.training_type === 'Mandatory') {
        setApplicableForTrainee(true);
        setSecondInitialRenderState(false);
      } else if (values.training_type === 'Optional') {
        setApplicableForTrainee(false);
        setSecondInitialRenderState(false);
      }
    } else {
      if (values.training_type === 'Mandatory') {
        setApplicableForTrainee(true);
        setFieldValue('training_for', []);
      } else if (values.training_type === 'Optional') {
        setApplicableForTrainee(false);
        setFieldValue('training_for', []);
      }
    }
  }, [values.training_type]);

  useEffect(() => {
    values.no_of_participants = values.no_of_participants?.toString();
  }, [values.no_of_participants]);

  const handleTrainingName = (e: any) => {
    const { options, selectedIndex, value } = e.target;
    setExamTrainingId(value);
    setTrainingName(options[selectedIndex].innerHTML);
    const training: ExamInfo = trainingNames?.data?.find(
      (t) => t.id === value,
    )!;
    setTrainingFee(training?.exam_tranning_fee?.exam_fee);
    setDuration(
      (training?.duration?.toString() || '') +
      ' ' +
      (training?.duration_unit || ''),
    );
    setTrainingType(training?.type!);
  };

  const handleApplicationStartDate = (e: any) => {
    const { value } = e.target;
    setApplicationStartDate(value?.substring(0, 10));
  };

  const handleApplicationDeadline = (e: any) => {
    const { value } = e.target;
    setApplicationDeadline(value?.substring(0, 10));
  };

  const handleNoticeDate = (e: any) => {
    const { value } = e.target;
    setNoticeDate(value?.substring(0, 10));
  };

  const handleNoticeSelection = (e: any) => {
    setNoticeModalShow(true);
  };

  return (
    <div className="row align-items-center justify-content-center">
      <div className="card mt-3">
        <h5 className="card-header">
          <i className="fa fa-plus me-2" />
          Edit Training / Workshop
        </h5>
        <div className="card-body">
          <Form
            className="custom-form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            onReset={(e) => {
              e.preventDefault();
              // setMainTrainingId('');
              setTrainingName('');
              setDuration('');
              setTrainingFee('');
              setApplicationStartDate('');
              setApplicationDeadline('');
              setNoticeDate('');
              setOnlineRadio(true);
              setOfflineRadio(false);
              setSelectedNotice({} as NoticeList);
              setContinueState(false);
              setApplicableForTrainee(false);
              // setDefaultMainTrainingOption({ value: '', label: 'Select...' });
              setDefaultTrainingOption({ value: '', label: 'Select...' });
              resetForm();
            }}
          >
            <Row>
              {/* <Col lg={4} md={6} sm={12}>
                <FormGroup className="mb-3">
                  <Label className="form-label">Main Training</Label>
                  <InputField
                    type="text"
                    name="mainTraining"
                    id="mainTraining"
                    value={defaultMainTrainingOption.label}
                    readOnly
                  />
                </FormGroup>
              </Col> */}

              <Row className="justify-content-evenly">
                <Col sm={12}>
                  <div className="mb-3">
                    <Label className="form-label">{trainingType ? trainingType : 'Training'} Mode</Label>
                    <br />
                    <FormGroup check inline>
                      <Input
                        id="trainingMode1"
                        name="training_mode"
                        type="radio"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setOnlineRadio(true);
                            setOfflineRadio(false);
                          }

                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        value={'Online'}
                        checked={onlineRadio}
                      />

                      <Label check for="trainingMode1">
                        Online
                      </Label>
                    </FormGroup>

                    <FormGroup check inline>
                      <Input
                        id="trainingMode2"
                        name="training_mode"
                        type="radio"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setOnlineRadio(false);
                            setOfflineRadio(true);
                          }

                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        value={'Offline'}
                        checked={offlineRadio}
                      />

                      <Label check for="trainingMode2">
                        Offline
                      </Label>
                    </FormGroup>
                  </div>
                </Col>
              </Row>

              <Col lg={4} md={6} sm={12} style={{ marginTop: '14px' }}>
                <FormGroup className="mb-3">
                  <Label className="form-label">{trainingType ? trainingType : 'Training'} Name</Label>

                  <select
                    name="trainingName"
                    className="form-select"
                    onChange={(e) => {
                      handleTrainingName(e);
                    }}
                  >
                    {trainingNameOptions?.map((option, index) => {
                      return option.value !== defaultTrainingOption.value ? (
                        <option
                          key={index}
                          className="form-control"
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ) : (
                        <option
                          key={index}
                          className="form-control"
                          value={option.value}
                          selected
                        >
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              </Col>

              <Col lg={4} md={6} style={{ marginTop: '14px' }}>
                <FormGroup className="mb-3">
                  <Label className="form-label required-field">
                    Notice Date
                  </Label>
                  <InputField
                    type="date"
                    invalid={{ errors, touched }}
                    {...getFieldProps('notice_date')}
                  />
                </FormGroup>
              </Col>

              <Col lg={4} md={6} sm={12}>
                <FormGroup className="mb-3">
                  <Label className="form-label required-field">
                    Notice Title
                  </Label>
                  <Button
                    type="button"
                    text="Select Template"
                    className="btn btn-secondary mb-1 ms-2"
                    onClick={(e) => {
                      handleNoticeSelection(e);
                    }}
                  ></Button>
                  <InputField
                    type="text"
                    placeholder="Select Notice"
                    name="notice_ref_no"
                    value={selectedNotice?.notice_subject ?? ''}
                    invalid={{ errors, touched }}
                    readOnly
                  />
                </FormGroup>
              </Col>

              <Col lg={4} md={6} sm={12}>
                <FormGroup className="mb-3">
                  <Label className="form-label">{trainingType ? trainingType : 'Training'} Type</Label>
                  <InputField
                    type="select"
                    placeholder=""
                    options={[
                      { label: 'Mandatory', value: 'Mandatory' },
                      { label: 'Optional', value: 'Optional' },
                    ]}
                    invalid={{ errors, touched }}
                    {...getFieldProps('training_type')}
                  />
                </FormGroup>
              </Col>

              {!applicableForTrainee ? (
                <Col lg={5} md={6} sm={12}>
                  <h6 className="required-field">Applicable For</h6>
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      value={'Trainee'}
                      checked={values['training_for'].includes('Trainee')}
                      id={'trainingForTrainee'}
                      name="training_for"
                      onChange={handleChange}
                    />
                    <Label check htmlFor={'trainingForTrainee'}>
                      Trainee
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      value={'Supervisor'}
                      checked={values['training_for'].includes('Supervisor')}
                      name="training_for"
                      onChange={handleChange}
                      id={'trainingForSupervisor'}
                    />
                    <Label check htmlFor={'trainingForSupervisor'}>
                      Supervisor
                    </Label>
                  </FormGroup>

                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      value={'Fellow'}
                      name="training_for"
                      checked={values['training_for'].includes('Fellow')}
                      onChange={handleChange}
                      id={'trainingForFellow'}
                    />
                    <Label check htmlFor={'trainingForFellow'}>
                      Fellow
                    </Label>
                  </FormGroup>

                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      value={'Other'}
                      name="training_for"
                      checked={values['training_for'].includes('Other')}
                      onChange={handleChange}
                      id={'trainingForOther'}
                    />
                    <Label check htmlFor={'trainingForOther'}>
                      Other
                    </Label>
                  </FormGroup>
                  {errors.training_for && touched.training_for ? (
                    <div className="text-danger">
                      {errors.training_for as string}
                    </div>
                  ) : null}
                </Col>
              ) : (
                <Col lg={5} md={6} sm={12}>
                  <h6 className="required-field">Applicable For</h6>
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      value={'Trainee'}
                      checked={values['training_for'].includes('Trainee')}
                      id={'trainingForTrainee'}
                      name="training_for"
                      onChange={handleChange}
                    />
                    <Label check htmlFor={'trainingForTrainee'}>
                      Trainee
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      value={'Supervisor'}
                      checked={values['training_for'].includes('Supervisor')}
                      name="training_for"
                      onChange={handleChange}
                      id={'trainingForSupervisor'}
                      disabled
                    />
                    <Label check htmlFor={'trainingForSupervisor'}>
                      Supervisor
                    </Label>
                  </FormGroup>

                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      value={'Fellow'}
                      name="training_for"
                      checked={values['training_for'].includes('Fellow')}
                      onChange={handleChange}
                      id={'trainingForFellow'}
                      disabled
                    />
                    <Label check htmlFor={'trainingForFellow'}>
                      Fellow
                    </Label>
                  </FormGroup>

                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      value={'Other'}
                      name="training_for"
                      checked={values['training_for'].includes('Other')}
                      onChange={handleChange}
                      id={'trainingForOther'}
                      disabled
                    />
                    <Label check htmlFor={'trainingForOther'}>
                      Other
                    </Label>
                  </FormGroup>
                  {errors.training_for && touched.training_for ? (
                    <div className="text-danger">
                      {errors.training_for as string}
                    </div>
                  ) : null}
                </Col>
              )}

              {/* <Col lg={4} md={6} sm={12}>
                <FormGroup className="mb-3">
                  <Label className="form-label">Training For</Label>
                  <InputField
                    type="select"
                    placeholder=""
                    options={[
                      { label: 'Trainee', value: 'Trainee' },
                      { label: 'Supervisor', value: 'Supervisor' },
                    ]}
                    invalid={{ errors, touched }}
                    {...getFieldProps('training_for')}
                  />
                </FormGroup>
              </Col> */}

              <Col lg={4} md={6} sm={12}>
                <FormGroup className="mb-3">
                  <Label className="form-label">
                    No. of Participants (Per Batch)
                  </Label>
                  <InputField
                    type="number"
                    min={0}
                    invalid={{ errors, touched }}
                    {...getFieldProps('no_of_participants')}
                  />
                </FormGroup>
              </Col>

              {duration ? (
                <Col lg={4} md={6} sm={12}>
                  <FormGroup className="mb-3">
                    <Label className="form-label">Duration</Label>
                    <InputField
                      name="duration"
                      id="duration"
                      type="text"
                      value={duration}
                      readOnly
                    />
                  </FormGroup>
                </Col>
              ) : (
                <Col lg={4} md={6} sm={12}>
                  <FormGroup className="mb-3">
                    <Label className="form-label">Duration</Label>
                    <InputField
                      name="duration"
                      id="duration"
                      type="text"
                      value={trainingWorkshopForEdit?.duration}
                      readOnly
                    />
                  </FormGroup>
                </Col>
              )}

              {trainingFee ? (
                <Col lg={4} md={6} className="me-0 pe-0">
                  <FormGroup className="mb-3">
                    <Label className="form-label">Fees</Label>
                    <InputField
                      type="text"
                      id="trainingFee"
                      name="trainingFee"
                      value={trainingFee}
                      readOnly
                    />
                  </FormGroup>
                </Col>
              ) : (
                <Col lg={4} md={6} className="me-0 pe-0">
                  <FormGroup className="mb-3">
                    <Label className="form-label">Fees</Label>
                    <InputField
                      type="text"
                      id="trainingFee"
                      name="trainingFee"
                      value={trainingWorkshopForEdit?.fees}
                      readOnly
                    />
                  </FormGroup>
                </Col>
              )}

              <Col lg={4} md={6} sm={12}>
                <div className="ms-3 mt-4 mb-3">
                  <FormGroup switch className="mb-3">
                    <Input
                      type="switch"
                      role="switch"
                      checked={continueState}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setContinueState(true);
                          setInitialRenderState(false);
                        } else {
                          setContinueState(false);
                          setInitialRenderState(false);
                        }
                      }}
                    />
                    <Label>Continue</Label>
                  </FormGroup>
                </div>
              </Col>

              {!continueState ? (
                <Col lg={4} md={6} className="me-0 pe-0">
                  <FormGroup className="mb-3">
                    <Label className="form-label required-field">
                      Application Start Date
                    </Label>
                    <InputField
                      type="date"
                      invalid={{ errors, touched }}
                      {...getFieldProps('application_start_date')}
                    />
                  </FormGroup>
                </Col>
              ) : (
                <Col lg={4} md={6} className="me-0 pe-0">
                  <FormGroup className="mb-3">
                    <Label className="form-label required-field">
                      Application Start Date
                    </Label>
                    <InputField
                      type="date"
                      readOnly={true}
                      invalid={{ errors, touched }}
                      {...getFieldProps('application_start_date')}
                    />
                  </FormGroup>
                </Col>
              )}

              {!continueState ? (
                <Col lg={4} md={6} className="me-0 pe-0">
                  <FormGroup className="mb-3">
                    <Label className="form-label required-field">
                      Application Deadline
                    </Label>
                    <InputField
                      type="date"
                      invalid={{ errors, touched }}
                      {...getFieldProps('application_deadline')}
                    />
                  </FormGroup>
                </Col>
              ) : (
                <Col lg={4} md={6} className="me-0 pe-0">
                  <FormGroup className="mb-3">
                    <Label className="form-label required-field">
                      Application Deadline
                    </Label>
                    <InputField
                      type="date"
                      name="invalid_deadline"
                      id="invalid_deadline"
                      value={''}
                      readOnly={true}
                    />
                  </FormGroup>
                </Col>
              )}

              {/* <Col sm={12}>
                            <FormGroup check className='mb-3 mt-4'>
                              <Input
                                type="checkbox"
                                role="check"
                                checked={values.status}
                                {...getFieldProps('required_ind')}
                              />
                              <Label check>Required</Label>
                            </FormGroup>
                          </Col>                                                                                                                                   */}

              <Col sm={12}>
                <FormGroup className="mb-3 text-center">
                  <Button
                    type="reset"
                    text="Reset"
                    className="btn btn-secondary me-2"
                  ></Button>
                  <Button
                    type="submit"
                    text="Save as Draft"
                    className="btn btn-info me-2"
                    onClick={(e) => {
                      setBtnClicked('save_as_draft');
                    }}
                  ></Button>
                  <Button
                    type="submit"
                    text="Save"
                    className="btn btn-success"
                    onClick={(e) => {
                      setBtnClicked('submit');
                    }}
                  ></Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>

        <Modal
          onOpened={function () {
            toast.dismiss();
          }}
          isOpen={noticeModalShow}
          scrollable={true}
          // style={{ maxWidth: '1600px', width: '95%' }}
          // size="xl"
          fullscreen={true}
          toggle={() => {
            document.body.classList.add('no_padding');
          }}
          backdrop={'static'}
          modalTransition={{ timeout: 0 }}
          backdropTransition={{ timeout: 0 }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Select Notice
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setNoticeModalShow(!noticeModalShow);
              }}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <Row>
              <Col>
                <NoticeList
                  selectedNotice={selectedNotice}
                  setSelectedNotice={setSelectedNotice}
                  setModalShow={setNoticeModalShow}
                />
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default EditTrainingWorkshop;
