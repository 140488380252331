import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import { useGetLogBookMasterSetupsQuery } from 'src/rtk/features/setup/logBookMasterSetup/logBookMasterSetupApi';
import { useDeleteLogbookTableOfContentMutation, useGetLogbookTableOfContentsQuery } from 'src/rtk/features/setup/logBookTableOfContentSetup/logBookTableOfContentSetupApi';

interface Props {
  logbookTableOfContent: LogbookTableOfContent;
  index: number;
}

const LogbookTableOfContentTableRow = ({ logbookTableOfContent, index }: Props) => {
  const history = useHistory();
  const [deleteLogbookTableOfContent, { isSuccess, isLoading, isError }] =
    useDeleteLogbookTableOfContentMutation();

  const [infoId, setInfoId] = useState('');
  const [modalShow, setModalShow] = useState(false);
  
  const { data: logbookMastersData } = useGetLogBookMasterSetupsQuery();
  const logbookMasters = logbookMastersData?.data || [];

  const { data: logbookTableOfContentsData } = useGetLogbookTableOfContentsQuery();
  const logbookTableOfContents = logbookTableOfContentsData?.data || [];

  const deleteHandler = (modalState: boolean, id: string) => {
    setModalShow(modalState);
    setInfoId(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Logbook Table of Content deleted successfully');
    }
    if (isError) {
      toast.error('Error deleting logbook table of content');
    }
  }, [isSuccess, isError]);

  return (
    <tr key={logbookTableOfContent.id}>
      <th scope="row">{index + 1}</th>      
      <td>
        {
          logbookMasters?.find((l: LogBookSetupMasters) => l.id === logbookTableOfContent.setup_logbook_master_id)
            ?.logbook_heading
        }
      </td>
      <td>{logbookTableOfContent.details}</td>
      <td>{logbookTableOfContent.note}</td>
      <td>
        {
          logbookTableOfContents?.find((l: LogbookTableOfContent) => l.id === logbookTableOfContent.table_of_contents_details_id)
            ?.details
        }
      </td>
      <td>{logbookTableOfContent.page_no}</td>
      <td>{logbookTableOfContent.sl_no}</td>
      <td>{logbookTableOfContent.status ? 'Active' : 'Inactive'}</td>       
      <td>
        <div className="d-flex gap-2">
          <Button
            size="sm"
            text="Edit"
            color="warning"
            onClick={() =>
              history.push(`/setup/logbook-table-of-content/update/${logbookTableOfContent.id}`)
            }
          />

          <Button
            size="sm"
            text="Delete"
            color="danger"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => deleteHandler(true, logbookTableOfContent.id!)}
          />
        </div>
        {modalShow ? (
          <DeleteInformationModal
            isOpen={modalShow}
            toggle={() => setModalShow(!modalShow)}
            id={infoId}
            deleteInfomation={deleteLogbookTableOfContent}
          />
        ) : null}
      </td>
    </tr>
  );
};

export default LogbookTableOfContentTableRow;
