import * as url from 'src/helpers/url_helper';
import * as Yup from 'yup';

const formObj: any = {
  debug: false,

  createAPIUrl: url.THESIS_DISSERTATION_PROTOCOL_ENTRY_CREATE,
  updateAPIUrl: url.THESIS_DISSERTATION_PROTOCOL_ENTRY_UPDATE,
  getDataByIdAPIUrl: url.THESIS_DISSERTATION_PROTOCOL_ENTRY_BY_ID,
  listAPIUrl: url.THESIS_DISSERTATION_PROTOCOL_ENTRY_LIST,

  listCommentAPIUrl: url.THESIS_DISSERTATION_PROTOCOL_COMMENT_LIST,
  createCommentAPIUrl: url.THESIS_DISSERTATION_PROTOCOL_COMMENT_SUBMIT,

  listUrl: '/thesis-dissertation-list',
  addUrl: '/thesis-dissertation-list/add',
  updateUrl: '/thesis-dissertation-list/update/',

  showSearchForm: true,
  showCreateNewBtn: true,
  showUpdateBtn: true,
  showViewBtn: true,
  showDeleteBtn: true,
  showTableActionColumn: true,

  createNewBtnTitle: 'Create New',
  submitBtnTitle: 'Save',
  editBtnTitle: 'Edit',
  updateBtnTitle: 'Update',
  resetBtnTitle: 'Reset',
  draftBtnTitle: 'Save as Draft',
  deletePromtMessage: 'Do you want to delete?',

  title: 'Thesis & Dissertation Protocol View',
  breadcrumbItemForList: [{ link: '/', name: 'Dashboard' }],
  breadcrumbItemForForm: [{ link: '/', name: 'Dashboard' }],

  formFields: {
    full_name: {
      positionInForm: 1,
      label: 'Trainee Name',
      viewCell: (row: any) => row?.registration?.personal_info?.full_name || '',
    },
    address_line_1: {
      positionInForm: 2,
      label: 'Address',
      viewCell: (row: any) => {
        if (row?.registration?.address_infos?.['0']?.address_line_1) {
          return (
            row?.registration?.address_infos?.['0']?.address_line_1 +
            ', ' +
            row?.registration?.address_infos?.['0']?.police_station +
            ', ' +
            row?.registration?.address_infos?.['0']?.district
          );
        } else {
          return '';
        }
      },
    },
    mobile: {
      positionInForm: 3,
      label: 'Contact No',
      viewCell: (row: any) => row?.registration?.personal_info?.mobile || '',
    },
    subject_name: {
      positionInForm: 4,
      label: 'Discipline',
      viewCell: (row: any) => row?.registration?.subject_name || '',
    },

    introduction_context: {
      positionInForm: 1,
      label: 'Introduction',
      name: 'introduction_context',
      viewCell: (row: any) => row.introduction_context,
      type: 'richtext',
      default: '',
      isRequired: false,
      validation: Yup.string().required('Introduction is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },

    relevant_faculty: {
      positionInForm: 1,
      label: 'Relevant faculty',
      name: 'relevant_faculty',
      viewCell: (row: any) => row.relevant_faculty,
      type: 'richtext',
      default: '',
      isRequired: false,
      validation: Yup.string().required('Relevant faculty is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },

    relevant_discipline: {
      positionInForm: 1,
      label: 'Relevant discipline',
      name: 'relevant_discipline',
      viewCell: (row: any) => row.relevant_discipline,
      type: 'richtext',
      default: '',
      isRequired: false,
      validation: Yup.string().required('Relevant discipline is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },

    study_design: {
      positionInForm: 1,
      label: 'Study Design',
      name: 'study_design',
      viewCell: (row: any) => row.study_design,
      type: 'richtext',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Study design is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },

    summary: {
      positionInForm: 1,
      label: 'Summary',
      name: 'summary',
      viewCell: (row: any) => row.summary,
      type: 'richtext',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Summary is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },

    rationale_of_the_research: {
      positionInForm: 1,
      label: 'Rationale of the research',
      name: 'rationale_of_the_research',
      viewCell: (row: any) => row.rationale_of_the_research,
      type: 'richtext',
      default: '',
      isRequired: false,
      validation: Yup.string().required(
        'Rationale of the research is required',
      ),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },
    research_question_hypothesis: {
      positionInForm: 1,
      label: 'Research question hypothesis',
      name: 'research_question_hypothesis',
      viewCell: (row: any) => row.research_question_hypothesis,
      type: 'richtext',
      default: '',
      isRequired: false,
      validation: Yup.string().required('Summary is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },
    objectives: {
      positionInForm: 1,
      label: 'Objectives',
      name: 'objectives',
      viewCell: (row: any) => row.objectives,
      type: 'richtext',
      default: '',
      isRequired: false,
      validation: Yup.string().required('objectives is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },
    material_methods: {
      positionInForm: 1,
      label: 'Material methods',
      name: 'material_methods',
      viewCell: (row: any) => row.material_methods,
      type: 'richtext',
      default: '',
      isRequired: false,
      validation: Yup.string().required('Material methods is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },
    ethical_implication: {
      positionInForm: 1,
      label: 'Ethical implication',
      name: 'ethical_implication',
      viewCell: (row: any) => row.ethical_implication,
      type: 'richtext',
      default: '',
      isRequired: false,
      validation: Yup.string().required('Ethical implication is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },

    facilities_available_at_the_place_of_the_study: {
      positionInForm: 1,
      label: 'Facilities available at the place of the study',
      name: 'facilities_available_at_the_place_of_the_study',
      viewCell: (row: any) =>
        row.facilities_available_at_the_place_of_the_study,
      type: 'richtext',
      default: '',
      isRequired: false,
      validation: Yup.string().required(
        'Facilities available at the place of the study is required',
      ),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },
    other_facilites_needed: {
      positionInForm: 1,
      label: 'Other facilities needed',
      name: 'other_facilites_needed',
      viewCell: (row: any) => row.other_facilites_needed,
      type: 'richtext',
      default: '',
      isRequired: false,
      validation: Yup.string().required('Other facilities needed is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },

    dissemination_and_use_of_findings: {
      positionInForm: 1,
      label: 'Dissemination and use of findings',
      name: 'dissemination_and_use_of_findings',
      viewCell: (row: any) => row.dissemination_and_use_of_findings,
      type: 'richtext',
      default: '',
      isRequired: false,
      validation: Yup.string().required(
        'Dissemination and use of findings is required',
      ),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },
    references: {
      positionInForm: 1,
      label: 'References',
      name: 'references',
      viewCell: (row: any) => row.references,
      type: 'richtext',
      default: '',
      isRequired: false,
      validation: Yup.string().required('References is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },
    others: {
      positionInForm: 1,
      label: 'others',
      name: 'others',
      viewCell: (row: any) => row.others,
      type: 'richtext',
      default: '',
      isRequired: false,
      validation: Yup.string().required('others is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },
    any_other_relevant_info: {
      positionInForm: 1,
      label: 'Any other relevant info',
      name: 'any_other_relevant_info',
      viewCell: (row: any) => row.any_other_relevant_info,
      type: 'richtext',
      default: '',
      isRequired: false,
      validation: Yup.string().required('others is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },

    total_budget: {
      positionInForm: 10,
      label: 'Total budget',
      name: 'total_budget',
      viewCell: (row: any) => row.total_budget,
      type: 'text',
      default: '',
      isRequired: false,
      validation: Yup.string().required('Total budget is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
    },

    total_budget_text: {
      positionInForm: 10,
      label: 'Total budget text',
      name: 'total_budget_text',
      viewCell: (row: any) => row.total_budget_text,
      type: 'text',
      default: '',
      isRequired: false,
      validation: Yup.string().required('Total budget text is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
    },

    source_of_funding: {
      positionInForm: 10,
      label: 'Source of funding',
      name: 'source_of_funding',
      viewCell: (row: any) => row.source_of_funding,
      type: 'text',
      default: '',
      isRequired: false,
      validation: Yup.string().required('Source of funding is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
    },

    place_of_study: {
      positionInForm: 10,
      label: 'Place of study',
      name: 'place_of_study',
      viewCell: (row: any) => row.place_of_study,
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Place of study is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
    },

    study_period: {
      positionInForm: 11,
      label: 'Study period',
      name: 'study_period',
      viewCell: (row: any) =>
        row.study_period ? row.study_period + ' ' + row.study_period_unit : '',
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Study period is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 3,
    },

    // study_period_unit: {
    //   positionInForm: 12,
    //   label: 'Unit',
    //   name: 'study_period_unit',
    //   type: 'select',
    //   default: '',
    //   isRequired: false,
    //   validation: Yup.string()
    //     .required('Unit is required')
    //     .oneOf(['Day', 'Week', 'Month', 'Year']),
    //   showInForm: true,

    //   showInSearch: true,
    //   colSize: 3,
    //   data: [
    //     { label: 'Day', value: 'Day' },
    //     { label: 'Week', value: 'Week' },
    //     { label: 'Month', value: 'Month' },
    //     { label: 'Year', value: 'Year' },
    //   ],
    // },

    submission_status: {
      label: 'Submission status',
      viewCell: (row: any) => row?.submission_status || '',
    },
  },
};

export default formObj;
