import { useFormik } from "formik";
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";

const SubjectSetup = () => {
  const subjectSetupForm: any = useFormik({
    initialValues: {
      subjectCode: "",
      subjectName: "",
      category: "",
      description: "",
      duration: "",
      unit: "",
    },

    // validationSchema: Yup.object({
    //   name: Yup.string().required("Name is required"),
    //   fatherOrHusbandName: Yup.string().required(
    //     "Father/Husband name is required"
    //   ),
    //   motherName: Yup.string().required("Mother name is required"),
    //   dateOfBirth: Yup.string().required("Date of birth is required"),
    //   nid: Yup.string().required("NID is required"),
    //   phone: Yup.string(),
    // }),

    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <div className="page-content">
      <Row className="justify-content-center">
        <Col sm={12} md={12} lg={12}>
          <h4>Create Subject</h4>
        </Col>
        <hr />
        <Col sm={12} md={8} lg={8}>
          <div className="mb-3">
            <Label className="form-label">Subject Code</Label>
            <Input
              name="subjectCode"
              className="form-control"
              placeholder="Enter Subject Code"
              type="text"
              onChange={subjectSetupForm.handleChange}
              onBlur={subjectSetupForm.handleBlur}
              value={subjectSetupForm.values.subjectCode || ""}
              invalid={
                subjectSetupForm.touched.subjectCode &&
                !!subjectSetupForm.errors.subjectCode
              }
            />
            {subjectSetupForm.touched.subjectCode &&
            subjectSetupForm.errors.subjectCode ? (
              <FormFeedback type="invalid">
                {subjectSetupForm.errors.subjectCode}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Subject Name</Label>
            <Input
              name="subjectName"
              className="form-control"
              placeholder="Enter Subject Name"
              type="text"
              onChange={subjectSetupForm.handleChange}
              onBlur={subjectSetupForm.handleBlur}
              value={subjectSetupForm.values.subjectName || ""}
              invalid={
                subjectSetupForm.touched.subjectName &&
                !!subjectSetupForm.errors.subjectName
              }
            />
            {subjectSetupForm.touched.subjectName &&
            subjectSetupForm.errors.subjectName ? (
              <FormFeedback type="invalid">
                {subjectSetupForm.errors.subjectName}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label>Category</Label>
            <select className="form-select">
              <option value="" selected>
                Select
              </option>
            </select>
          </div>

          <div className="mb-3">
            <Label className="form-label">Description</Label>
            <Input
              name="description"
              className="form-control"
              placeholder="Confirm Password"
              type="textarea"
              onChange={subjectSetupForm.handleChange}
              onBlur={subjectSetupForm.handleBlur}
              value={subjectSetupForm.values.description || ""}
              invalid={
                subjectSetupForm.touched.description &&
                !!subjectSetupForm.errors.description
              }
            />
            {subjectSetupForm.touched.description &&
            subjectSetupForm.errors.description ? (
              <FormFeedback type="invalid">
                {subjectSetupForm.errors.description}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Duration</Label>
            <Input
              name="duration"
              className="form-control"
              placeholder="Enter Duration"
              type="text"
              onChange={subjectSetupForm.handleChange}
              onBlur={subjectSetupForm.handleBlur}
              value={subjectSetupForm.values.duration || ""}
              invalid={
                subjectSetupForm.touched.duration &&
                !!subjectSetupForm.errors.duration
              }
            />
            {subjectSetupForm.touched.duration &&
            subjectSetupForm.errors.duration ? (
              <FormFeedback type="invalid">
                {subjectSetupForm.errors.duration}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label>Unit</Label>
            <select className="form-select">
              <option value="" selected>
                Select
              </option>
            </select>
          </div>

          <div className="text-center mb-3">
            <button
              type="submit"
              className="btn btn-primary w-25"
              onSubmit={(e) => {
                e.preventDefault;
              }}
            >
              Save
            </button>
          </div>
        </Col>
      </Row>

      <Row className="pb-5">
        <Col sm={12} md={12} lg={12}>
          <Table bordered>
            <thead>
              <tr>
                <th>SL.</th>
                <th>Subject</th>
                <th>Category</th>
                <th>Description</th>
                <th>Duration</th>
                <th>Unit</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Pediatrics</td>
                <td>Health Science</td>
                <td>Science on Child Health</td>
                <td>1 year</td>
                <td>Child Health</td>
                <td
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <Button className="me-4 btn-dark">
                    <i className="fa-solid fa-eye"></i>
                  </Button>
                </td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Gastroentorology</td>
                <td>Medicine</td>
                <td>No info</td>
                <td>1 year</td>
                <td>Medical Unit</td>
                <td
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <Button className="me-4 btn-dark">
                    <i className="fa-solid fa-eye"></i>
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default SubjectSetup;
