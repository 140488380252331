import AssignExaminerForThesis from 'src/pages/DissertationAndThesis/AssignExaminerForThesis/index';
import AssignReviewerForProtocolEntryList from 'src/pages/DissertationAndThesis/AssignReviewerForProtocolEntryList/index';
import DissertationListForAssigningReview from 'src/pages/DissertationAndThesis/DissertationListForAssigningReviewer/DissertationList';
import DissertationList from 'src/pages/DissertationAndThesis/DissertationListForReviewer/DissertationList';
import ExaminerMarkingForThesis from 'src/pages/DissertationAndThesis/ExaminerMarkingForThesis/index';
import FileListForApproval from 'src/pages/DissertationAndThesis/FileListForApproval/index';
import ListOfDissertationTitle from 'src/pages/DissertationAndThesis/ListOfDissertationTitle/ListOfDissertationTitle';
import ProtocolEntry from 'src/pages/DissertationAndThesis/ProtocolEntry/form';
import ProtocolEntryMock from 'src/pages/DissertationAndThesis/ProtoColEntry_mock/ProtocolEntry';
import ProtocolEntryListApproval from 'src/pages/DissertationAndThesis/ProtocolEntryList/index';
import ProtocolView from 'src/pages/DissertationAndThesis/ProtocolView/form';
import ReviewerProtocolEntryList from 'src/pages/DissertationAndThesis/ReviewerProtocolEntryList/index';
import SceduleListForAdmin from 'src/pages/DissertationAndThesis/SceduleListForAdmin/index';
import ThesisDissertationFileUpload from 'src/pages/DissertationAndThesis/ThesisDissertationFileUpload';
import TitleEntry from 'src/pages/DissertationAndThesis/TitleEntry/form';
import TitleList from 'src/pages/DissertationAndThesis/TitleList/index';
import TitleListForRTMD from 'src/pages/DissertationAndThesis/TitleList/TitleListForRTMD';
import NewThesisEntry from 'src/pages/DissertationAndThesis/NewThesisEntry/NewThesisEntry';
import ThesisEntryDetailsForSupervisor from 'src/pages/ThesisEntryDetails/ThesisEntryDetailsForSupervisor';
import ProtocolEntryMockView from 'src/pages/DissertationAndThesis/ProtoColEntry_mock_view/ProtocolEntryView';
import ThesisMarksheetList from 'src/pages/DissertationAndThesis/ThesisMarksheet/ThesisMarksheet';
import ProtocolApprovalView from 'src/pages/DissertationAndThesis/ProtocolSummaryView/ProtocolApprovalView';
import ThesisDissertationSummaryView from 'src/pages/DissertationAndThesis/ThesisDissertationSummaryView/ThesisDissertationSummaryView';
import TitelChangeRequests from 'src/pages/DissertationAndThesis/TitleList/TitelChangeRequests';

export const dissertationAndThesisRoutes = [
  // thesis and dissertation
  { path: '/thesis-dissertation-title-entry', component: TitleEntry },
  { path: '/thesis-dissertation-title-list-approval', component: TitleList },
  { path: '/thesis-dissertation-title-list-for-rtmd', component: TitleListForRTMD },
  { path: '/title-change-requests', component: TitelChangeRequests },
  { path: '/thesis-dissertation-protocol-list-approval', component: ProtocolEntryListApproval },
  { path: '/thesis-dissertation-file-list-approval', component: FileListForApproval },
  { path: '/rtmd-thesis-dissertation-protocol-list-approval', component: AssignReviewerForProtocolEntryList },
  { path: '/rtmd-assign-examiner-for-thesis', component: AssignExaminerForThesis },
  { path: '/rtmd-scedule-list-for-admin', component: SceduleListForAdmin },
  { path: '/rtmd-examiner-marking-for-thesis', component: ExaminerMarkingForThesis },
  { path: '/reviewer-thesis-dissertation-protocol-list-approval', component: ReviewerProtocolEntryList },
  { path: '/protocol-entry-view/:id', component: ProtocolApprovalView },
  { path: '/thesis-dissertation-protocol-list/add', component: ProtocolEntry },
  // { path: '/thesis-dissertation-protocol-entry', component: ProtocolEntry },
  { path: '/thesis-dissertation-protocol-entry', component: ProtocolEntryMock },
  { path: '/thesis-dissertation-protocol-entry-view/:protocolId', component: ProtocolEntryMockView },
  { path: '/thesis-file-entry', component: NewThesisEntry },
  { path: '/dissertation-file-entry', component: ThesisDissertationFileUpload },
  { path: '/thesis-dissertation-protocol-view/:id', component: ProtocolView },
  { path: '/thesis-dissertation-list-for-reviewer', component: DissertationList },
  { path: '/rtmd-assign-reviewer-dissertation-list', component: DissertationListForAssigningReview },
  { path: '/rtmd-list-of-dissertation-title', component: ListOfDissertationTitle },
  { path: '/thesis-entry-details-for-supervisor/:id', component: ThesisEntryDetailsForSupervisor },
  { path: '/view-thesis-entry-details/:id', component: ThesisDissertationSummaryView },
  { path: '/rtmd-thesis-marksheet', component: ThesisMarksheetList },
];
