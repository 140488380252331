import { useFormik } from "formik";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { Button, InputField } from "src/components";
import { setFromErrors } from "src/helpers";
import { useInsPublicationsEntryMutation } from "src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi";
import * as Yup from 'yup';

interface InsPublicationsProps {
    departmentId?: string;
    recordOfInsPublicationsToEdit?: InsPublicationsList;
    setModalShow?: any;
}

const initialValues: InsPublicationsEntry = {
    form_b_department_id: '',
    publication_id: '',
    title: '',
    journal: '',
    year: '',
};

const InsPublicationsModal = (props: InsPublicationsProps) => {
    const { departmentId, recordOfInsPublicationsToEdit, setModalShow } = props;

    const [insPublicationsEntry, { data: insPublicationsEntryData, isSuccess: isInsPublicationsEntryDataSuccess, isLoading: isInsPublicationsEntryDataLoading, isError: isInsPublicationsEntryDataError, error: insPublicationsEntryDataError }] =
        useInsPublicationsEntryMutation();

    const err: CustomError = insPublicationsEntryDataError as CustomError;

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({}),

        onSubmit: (values: InsPublicationsEntry) => {
            console.log(values);
            values.form_b_department_id = departmentId;
            insPublicationsEntry(values);
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    const handleFormReset = () => {
        resetForm();
    };

    useEffect(() => {
        if (recordOfInsPublicationsToEdit?.id) {
            setValues({
                form_b_department_id: recordOfInsPublicationsToEdit?.form_b_department_id,
                publication_id: recordOfInsPublicationsToEdit?.id,
                title: recordOfInsPublicationsToEdit?.title,
                journal: recordOfInsPublicationsToEdit?.journal,
                year: recordOfInsPublicationsToEdit?.year,
            });
        }
    }, []);

    useEffect(() => {
        if (isInsPublicationsEntryDataSuccess) {
            handleFormReset();
            toast.success('Publication record saved successfully');
            setModalShow(false);
        }
        if (isInsPublicationsEntryDataError) {
            Object.entries(err?.data).forEach((entry: any) => {
                entry[1]?.forEach((message: any) => {
                    toast.error(message);
                });
            });
            setFromErrors(err.data, setErrors);
        }
    }, [isInsPublicationsEntryDataSuccess, isInsPublicationsEntryDataError]);

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title">
                                    <i className="fa fa-plus me-2" />
                                    Publication/s by the faculty members of the department in the last 3 years
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    autoComplete="off"
                                    className="custom-form"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    onReset={(e) => {
                                        e.preventDefault();
                                        handleFormReset();
                                    }}
                                >
                                    <Row>                                        
                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="title">Title</Label>
                                                <InputField
                                                    type="text"
                                                    placeholder=""
                                                    {...getFieldProps('title')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="journal">Journal</Label>
                                                <InputField
                                                    type="text"
                                                    placeholder=""
                                                    {...getFieldProps('journal')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="year">Year</Label>
                                                <InputField
                                                    type="text"
                                                    placeholder=""
                                                    {...getFieldProps('year')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} className="mt-3">
                                            <FormGroup className="mb-3 text-center">
                                                <Button
                                                    type="reset"
                                                    text="Reset"
                                                    className="btn btn-warning me-2"
                                                ></Button>
                                                <Button
                                                    type="submit"
                                                    text="Save"
                                                    className="btn btn-success me-2"
                                                    disabled={isInsPublicationsEntryDataLoading}
                                                ></Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default InsPublicationsModal;