import { apiSlice } from 'src/rtk/api/apiSlice';
import * as setupTrnNoticeDetailsUrl from 'src/helpers/url_helper';

export const setupTrnNoticeDetailsApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['Trn Notice Details'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTransactionNoticeDetails: builder.query<APIResponse<TransactionNoticeDetails[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${setupTrnNoticeDetailsUrl.GET_TRANSACTION_NOTICE_DETAILS}`,
        }),
        providesTags: ['Trn Notice Details'],
      }),

      getTransactionNoticeDetail: builder.query<APIResponse<TransactionNoticeDetails>, string>({
        query: (id) => ({
          url: `${setupTrnNoticeDetailsUrl.GET_TRANSACTION_NOTICE_DETAIL}/${id}`,
          method: 'GET',
        }),
        providesTags: ['Trn Notice Details'],
      }),

      createTransactionNoticeDetails: builder.mutation<APIResponse<TransactionNoticeDetails>, TransactionNoticeDetails>({
        query: (data) => ({
          url: `${setupTrnNoticeDetailsUrl.CREATE_TRANSACTION_NOTICE_DETAILS}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['Trn Notice Details'],
      }),

      updateTransactionNoticeDetails: builder.mutation<APIResponse<TransactionNoticeDetails>, string>({
        query: (url) => ({
          url: `${setupTrnNoticeDetailsUrl.UPDATE_TRANSACTION_NOTICE_DETAILS}` + url,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['Trn Notice Details'],
      }),

      deleteTransactionNoticeDetails: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${setupTrnNoticeDetailsUrl.DELETE_TRANSACTION_NOTICE_DETAILS}/${id}`,
        }),
        invalidatesTags: ['Trn Notice Details'],
      }),
    }),
  });

export const {
  useCreateTransactionNoticeDetailsMutation,
  useGetTransactionNoticeDetailsQuery,
  useGetTransactionNoticeDetailQuery,
  useUpdateTransactionNoticeDetailsMutation,
  useDeleteTransactionNoticeDetailsMutation,
} = setupTrnNoticeDetailsApi;
