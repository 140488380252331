import ApplyForSupervisor from 'src/pages/Supervisor/ApplyForSupervisor';
import SessionApprovalSuperVisorList from 'src/pages/Supervisor/SessionApproval/SessionApprovalSuperVisorList';
import SupervisorApprovalList from 'src/pages/Supervisor/SupervisorApprovalList';
import SupervisorList from 'src/pages/Supervisor/SupervisorList';
import SupervisorRegistration from 'src/pages/Supervisor/SupervisorRegistration';

export const supervisorRoutes = [
  {
    path: '/supervisor/registration',
    component: SupervisorRegistration,
  },

  { path: '/supervisor/list', component: SupervisorList },
  {
    path: '/supervisor/session-approval',
    component: SessionApprovalSuperVisorList,
  },
  {
    path: '/supervisor/session-approval/:id',
    component: SessionApprovalSuperVisorList,
  },
  { path: '/apply-for-supervisor', component: ApplyForSupervisor },
  { path: '/supervisor-approval', component: SupervisorApprovalList },
];
