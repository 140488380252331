import { apiSlice } from 'src/rtk/api/apiSlice';
import * as thesisExamDashboardUrl from 'src/helpers/url_helper';

export const thesisExamDashboardApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['ThesisExamDashboard', 'ThesisExamScheduleList'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getThesisExamDashboardBasicInfo: builder.query<APIResponse<any>, void>({
        query: () => ({
          method: 'GET',
          url: `${thesisExamDashboardUrl.GET_THESIS_EXAM_DASHBOARD_BASIC_INFO}`,
        }),

        providesTags: ['ThesisExamDashboard'],
      }), 
      
      getThesisExamScheduleListForDashboard: builder.query<APIResponse<any>, void>({
        query: () => ({
          method: 'GET',
          url: `${thesisExamDashboardUrl.GET_THESIS_EXAM_SCHEDULE_LIST_FOR_DASHBOARD}`,
        }),

        providesTags: ['ThesisExamScheduleList'],
      }), 
    }),
  });

export const {
  useGetThesisExamDashboardBasicInfoQuery,  
  useGetThesisExamScheduleListForDashboardQuery,
} = thesisExamDashboardApi;
