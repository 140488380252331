import { apiSlice } from 'src/rtk/api/apiSlice';
import * as setupNoticeTemplateAttachmentUrl from 'src/helpers/url_helper';

export const setupNoticeTemplateAttachmentApi = apiSlice
    .enhanceEndpoints({ addTagTypes: ['NoticeTemplateAttachments'] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getNoticeTemplateAttachments: builder.query<APIResponse<NoticeTemplateCopyAttachment[]>, void>({
                query: () => ({
                    method: 'GET',
                    url: `${setupNoticeTemplateAttachmentUrl.GET_NOTICE_TEMPLATE_ATTACHMENTS}`,
                }),
                providesTags: ['NoticeTemplateAttachments'],
            }),

            getNoticeTemplateAttachment: builder.query<APIResponse<NoticeTemplateCopyAttachment>, string>({
                query: (id) => ({
                    url: `${setupNoticeTemplateAttachmentUrl.GET_NOTICE_TEMPLATE_ATTACHMENT}/${id}`,
                    method: 'GET',
                }),
                providesTags: ['NoticeTemplateAttachments'],
            }),

            createNoticeTemplateAttachment: builder.mutation<APIResponse<NoticeTemplateCopyAttachment>, NoticeTemplateCopyAttachment>({
                query: (data) => ({
                    url: `${setupNoticeTemplateAttachmentUrl.CREATE_NOTICE_TEMPLATE_ATTACHMENT}`,
                    method: 'POST',
                    body: data,
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                }),
                invalidatesTags: ['NoticeTemplateAttachments'],
            }),

            updateNoticeTemplateAttachment: builder.mutation<APIResponse<NoticeTemplateCopyAttachment>, string>({
                query: (url) => ({
                    url: `${setupNoticeTemplateAttachmentUrl.UPDATE_NOTICE_TEMPLATE_ATTACHMENT}` + url,
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                }),
                invalidatesTags: ['NoticeTemplateAttachments'],
            }),

            deleteNoticeTemplateAttachment: builder.mutation({
                query: (id: string) => ({
                    method: 'DELETE',
                    url: `${setupNoticeTemplateAttachmentUrl.DELETE_NOTICE_TEMPLATE_ATTACHMENT}/${id}`,
                }),
                invalidatesTags: ['NoticeTemplateAttachments'],
            }),
        }),
    });

export const {
    useCreateNoticeTemplateAttachmentMutation,
    useGetNoticeTemplateAttachmentQuery,
    useGetNoticeTemplateAttachmentsQuery,
    useUpdateNoticeTemplateAttachmentMutation,
    useDeleteNoticeTemplateAttachmentMutation
} = setupNoticeTemplateAttachmentApi;
