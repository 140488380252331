import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Select from 'react-select';
import { Button, Card, CardBody, Container, Form, FormGroup, Label } from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetSupervisorApplicantsListQuery } from 'src/rtk/features/fellowSupervisorApply/fellowSupervisorApplyApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import SupervisorApprovalViewModal from './SupervisorApprovalViewModal';
import loader from '../../assets/images/loader.gif';

const SupervisorApprovalList = () => {
    const [modalShow, setModalShow] = useState(false);
    const [regId, setRegId] = useState('');
    const [selectedSubject, setSelectedSubject] = useState<any>('');


    const allStatus: SelectOption[] = [
        { value: 'Approve', label: 'Approve' },
        { value: 'Pending', label: 'Pending' }
    ];


    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [search, setSearch] = useState('');

    const generateQueryUrl = () => {
        return `page=${page}&paginate_per_page=${limit}${search}`;
    };

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setLimit(newPerPage);
        setPage(page);
    };

    const {
        data: applicantsList,
        isSuccess,
        isLoading,
        isFetching,
    } = useGetSupervisorApplicantsListQuery(generateQueryUrl());
    console.log(applicantsList?.data?.data);

    const { data: subjects } = useGetSubjectInfosQuery();

    const allSubjects: SelectOption[] =
        subjects?.data?.map((subject) => ({
            value: subject.subject_name,
            label: subject.subject_name,
        })) || [];

    interface SearchFields {
        search?: string;
        approval_status?: string;
        bmdc_registration_no?: string;
        subject_name?: string;
    }

    const initialValues: SearchFields = {
        search: '',
        approval_status: '',
        bmdc_registration_no: '',
        subject_name: '',
    };

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        onSubmit: (values: any) => {
            let s: string = '';

            if (values.search !== '') {
                s = s + `&search=${values.search}`;
            }

            if (values.approval_status !== '') {
                s = s + `&approval_status=${values.approval_status}`;
            }

            if (values.bmdc_registration_no !== '') {
                s = s + `&bmdc_registration_no=${values.bmdc_registration_no}`;
            }

            if (values.subject_name !== '') {
                s = s + `&subject_name=${values.subject_name}`;
            }

            setSearch(s);
            setPage(1);
            console.log(values);
        },
    });

    useEffect(() => {
        if (selectedSubject !== '') {
            setFieldValue('subject_name', selectedSubject?.value);
        }
    }, [selectedSubject]);

    const modalShowHanlder = (modalState: boolean, id: string) => {
        setModalShow(modalState);
        setRegId(id);
    }

    const columns: TableColumn<any>[] = [
        {
            name: <div>Sl. No.</div>,
            cell: (row, index) => <strong>{applicantsList?.data?.from! + index}</strong>,
            width: '80px',
        },

        {
            name: <div>Name</div>,
            selector: (row) => row?.personal_info?.full_name,
            sortable: false,
        },
        {
            name: <div>BMDC Reg. No</div>,
            selector: (row) => row?.bmdc_registration_no,
            sortable: false,
        },
        {
            name: <div>Subject</div>,
            selector: (row) => row?.subject?.subject_name,
            sortable: false,
        },
        {
            name: <div>Status</div>,
            selector: (row) => row?.approval_for_fellow_supervisor_apply,
            cell: (row) => {
                switch (row?.approval_for_fellow_supervisor_apply) {
                    case 'Reject':
                        return (
                            <div className="badge rounded-pill d-inline-block  badge-rejected font-size-12 fw-medium">
                                {'Rejected'}
                            </div>
                        );

                    case 'Approve':
                        return (
                            <div className="badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium">
                                {'Approved'}
                            </div>
                        );

                    default:
                        return (
                            <div className="badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium">
                                {row?.approval_for_fellow_supervisor_apply}
                            </div>
                        );
                }
            },
            sortable: false,
        },

        {
            name: <div>Action</div>,
            cell: (row) => (
                <>
                    <Button
                        className="btn btn-sm btn-info my-2"
                        onClick={() => modalShowHanlder(true, row?.registration_no)}
                    >
                        <span className="fas fa-eye"></span> View
                    </Button>
                    {modalShow && regId === row?.registration_no ? (
                        <SupervisorApprovalViewModal
                            isOpen={modalShow}
                            toggle={() => setModalShow(!modalShow)}
                            regNo={row.registration_no!}
                        />
                    ) : null}
                </>
            ),

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];



    return (
        <div className="page-content">
            {isLoading || isFetching ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Container fluid>
                <BetterBreadcrumb
                    breadcrumbItem={[{ link: '#', name: 'Supervisor' }]}
                    title={'Supervisor Approval'}
                />
                <Card>
                    <CardBody>
                        <Form
                            className="custom-form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                            onReset={(e) => {
                                e.preventDefault();
                                handleSubmit();
                                setSelectedSubject('');
                                resetForm();
                            }}
                        >
                            <div className="filter-wrapper">
                                <FormGroup>
                                    <Label for="bmdc_registration_no">BMDC Reg. No</Label>
                                    <InputField
                                        type="text"
                                        placeholder="BMDC Reg. No"
                                        {...getFieldProps('bmdc_registration_no')}
                                    />
                                </FormGroup>

                                {/* <FormGroup>
                                    <Label for="from_date">From Date</Label>
                                    <InputField
                                        type="date"
                                        {...getFieldProps('from_date')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="to_date">To Date</Label>
                                    <InputField
                                        type="date"
                                        {...getFieldProps('to_date')}
                                    />
                                </FormGroup> */}
                                <FormGroup>
                                    <Label for="subject_name">Subject</Label>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        value={selectedSubject}
                                        onChange={setSelectedSubject}
                                        options={allSubjects}
                                        placeholder='Select...'
                                        name="subject_name"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="approval_status">Status</Label>
                                    <InputField
                                        type="select"
                                        placeholder="Status"
                                        options={allStatus}
                                        {...getFieldProps('approval_status')}
                                    />
                                </FormGroup>

                                <FormGroup className="btn-column">
                                    <Button color="danger" type="reset" >Reset</Button>
                                    <Button type="submit" color='primary'>Search</Button>
                                </FormGroup>
                            </div>
                        </Form>

                        <DataTable
                            columns={columns}
                            data={applicantsList?.data?.data!}
                            pagination
                            paginationServer
                            progressPending={isLoading}
                            expandOnRowClicked
                            highlightOnHover
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            paginationTotalRows={applicantsList?.data?.total}
                            paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
                            responsive
                            persistTableHead={true}
                        />
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default SupervisorApprovalList;
