import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  pdf
} from '@react-pdf/renderer';
import { useEffect } from 'react';
import Html from 'react-pdf-html';

interface Props {
  htmlData: any;
  setDownloadReportPdf: any;
}
// Create styles

// Create Document Component
// const MyDocument = () => (
//   <Document>
//     <Page size="A4" style={styles.page}>
//       <View style={styles.section}>
//         <Text>Section #1</Text>
//       </View>
//       <View style={styles.section}>
//         <Text>Section #2</Text>
//       </View>
//     </Page>
//   </Document>
// );

export const ReportPDF = (props: Props) => {
  const { htmlData = '', setDownloadReportPdf } = props;

  let pdfUrl = '';

  const styles = StyleSheet.create({
    page: {
      margin: 20,
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    row: {
      flexDirection: 'row',
      marginBottom: 0,
    },
    nextLine: {
      marginTop: -20,
      textAlign: 'center',
      fontSize: '10px',
    },
  });

  const html = `  
  <html>    
    <head>
     <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    <style>
          @import url("https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css");
          body {
           font-size: 12px;
          }
          .print-container {
            padding: 0 15px;
            padding-top: 30px;
            margin-top: 20px;
            padding-bottom: 60px;
            border-top: 1px solid #222222;
          }

          .table {
            width: 100%;
            margin-top: 15px;
            margin-bottom: 15px;
            color: #000000;
            vertical-align: top;
            text-align: center;
            font-size: 10px;
            border: 1px solid #222222;
          }

          .table > tbody {
            vertical-align: inherit;
          }

          .table th, .table td  {
            padding: 7px 5px;
          }
    </style>
    
    </head>
    <body>

      <div class="print-container"> 
          ${htmlData}
      </div>      
    </body>
  </html>
`;

  const MyDocument = (
    <Document>
      <Page size="A4">
        <View style={styles.row}>
          <Image
            src="/images/apple-icon-180x180.png"
            style={{
              width: '40px',
              marginTop: '20px',
              marginBottom: '0px',
              marginLeft: '10px',
            }}
          />
          <Text
            style={{
              textAlign: 'center',
              fontFamily: 'Times-Bold',
              fontSize: '14px',
              margin: '20px',
              marginBottom: '0px',
            }}
          >
            BANGLADESH COLLEGE OF PHYSICIANS AND SURGEONS (BCPS)
          </Text>
        </View>
        <Text style={styles.nextLine}>
          67, Shaheed Tajuddin Ahmed Sarani, Mohakhali, Dhaka-1212
        </Text>
        <Html>{html}</Html>
      </Page>
    </Document>
  );

  let isSuccess = true;
  let isLoading = false;

  useEffect(() => {
    setDownloadReportPdf(false);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      const pdfData = pdf(MyDocument).toBlob();
      pdfData.then((pdfData) => {
        const pdfContent = new Blob([pdfData], { type: 'application/pdf' });
        const url = URL.createObjectURL(pdfContent);
        pdfUrl = url;
        window.open(pdfUrl, '_blank');
      });
    }
  }, [isSuccess]);

  return (
    <div>
    </div>
  );
};
