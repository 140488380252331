import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Modal,
  Row
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import { config } from 'src/config';
import { setFromErrors } from 'src/helpers';
import {
  useGetSignatoriesByPermissionQuery,
  useUpdateNoticeAttachmentMutation,
  useUpdateNoticeMasterMutation,
} from 'src/rtk/features/notice/publishNoticeApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoGlobalApi';
import * as Yup from 'yup';
import EditAttachments from './EditAttachments';
import EditSignatories from './EditSignatories';
import EditRichTextEditor from './EditUpdatedRichTextEditor';

const initialValues: UpdateNotice = {
  trn_notice_master_id: '',
  notice_no: '',
  notice_date: '',
  department_id: '',
  notice_subject: '',
  notice_type: '',
  notice_header: '',
  notice_footer: '',
  notice_body: '',
  save_as_template: 0,
  template_name: '',
  type: '',
};

const EditNotice = (props: any) => {
  const { noticeToEdit, setModalShow } = props;

  const [updateNotice, { isSuccess, isError, error }] =
    useUpdateNoticeMasterMutation();

  const [
    updateNoticeAttachment,
    {
      isSuccess: isUpdateAttachmentSuccess,
      isError: isUpdateAttachmentError,
      error: updateAttachmenterror,
    },
  ] = useUpdateNoticeAttachmentMutation();

  const [attachmentInputList, setAttachmentInputList] = useState<any>([]);

  const [signatoryInputList, setSignatoryInputList] = useState<any>([]);

  const [forwardToInputList, setForwardToInputList] = useState<any>([
    { name: '' },
  ]);

  const [noticeHeaderFile, setNoticeHeaderFile] = useState('');

  const [noticeFooterFile, setNoticeFooterFile] = useState('');

  // const [attachmentRadio, setAttachmentRadio] = useState<boolean>(true);
  // const [signatoryRadio, setSignatoryRadio] = useState<boolean>(false);

  const noticeHeaderFileRef = useRef<any>();
  const noticeFooterFileRef = useRef<any>();

  const [saveAsTemplateState, setSaveAsTemplateState] =
    useState<boolean>(false);
  const [reRenderState, setReRenderState] = useState<number>(1);
  const [initialContentEditor, setInitialContentEditor] = useState<string>('');
  const [templateNameShow, setTemplateNameShow] = useState<boolean>(false);

  const err: CustomError = error as CustomError;

  const { data: subjectList, isError: isSubFetchError } =
    useGetSubjectInfosQuery();

  const departmentOptions: SelectOption[] = (
    subjectList?.data || []
  ).map((item) => ({
    value: item.subject_name ?? '',
    label: item.subject_name ?? '',
  }));

  departmentOptions.push({ value: 'All', label: 'All' });

  // const noticeTemplateOptions: SelectOption[] = (
  //   useGetNoticeListQuery().data?.data?.data?.filter(
  //     (item) => item.notice_template_list_id !== null,
  //   ) || []
  // ).map((item) => ({
  //   value: item.id ?? '',
  //   label: item.notice_no ?? '',
  // }));

  const noticeTypeOptions: SelectOption[] =
    useGetLookupDataQuery('notice_type')
      .data?.data[0]?.childs.map((item) => ({
        value: item.name,
        label: item.name,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) || [];

  const signatoryUsers = useGetSignatoriesByPermissionQuery(
    'can-approve-notice-as-signatory',
  ).data?.data!;

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      department_id: Yup.string().required('Please select department'),
      notice_no: Yup.string().required('Notice no. is required'),
      notice_date: Yup.date()
        .min(
          new Date(Date.now() - 86400000),
          "Notice date can't be a past date",
        )
        .required(),
    }),

    onSubmit: (values: any) => {
      console.log(values);
      values.type = 'Notice';

      if (!JSON.parse(values['notice_body']).editorHtml) {
        values['notice_body'] = JSON.stringify({
          editorHtml: initialContentEditor,
        });
      }

      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        if (key === 'save_as_template') {
          if (saveAsTemplateState) {
            values[key] = 1;
          } else {
            values[key] = 0;
          }
        }

        if (key !== 'attachmentRadioButton') {
          formData.append(key, values[key]);
        }
      });

      updateNotice(formData);

      attachmentInputList?.forEach((attachment: any) => {
        if (attachment?.details !== '') {
          const attachmentFormData = new FormData();

          Object.keys(attachment).forEach((key) => {
            attachmentFormData.append(key, attachment[key]);
          });

          updateNoticeAttachment(attachmentFormData);
        }
      });

      signatoryInputList?.forEach((signatory: any) => {
        if (signatory?.user_id !== '') {
          updateNoticeAttachment(signatory);
        }
      });
    },
  });

  const {
    handleSubmit,
    getFieldProps,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    resetForm,
    setValues,
    setErrors,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      noticeHeaderFileRef.current.value = '';
      noticeFooterFileRef.current.value = '';
      if (reRenderState === 1) {
        setReRenderState(0);
      } else if (reRenderState === 0) {
        setReRenderState(1);
      }
      toast.success('Notice updated successfully');
      if (setModalShow) {
        setModalShow(false);
      }
      setAttachmentInputList([]);
      setSignatoryInputList([]);
      setSaveAsTemplateState(false);
      // history.push('/setup/department');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    // console.log(noticeToEdit);
    // console.log(noticeToEdit?.department_id?.split(', '));

    setValues({
      trn_notice_master_id: noticeToEdit?.id,
      notice_no: noticeToEdit?.notice_no,
      notice_date: noticeToEdit?.notice_date?.slice(0, 10),
      department_id: noticeToEdit?.department_id,
      notice_subject: noticeToEdit?.notice_subject,
      notice_type: noticeToEdit?.notice_type,
      notice_header: '',
      notice_footer: '',
      notice_body: noticeToEdit?.notice_body,
      save_as_template: noticeToEdit?.save_as_template,
    });

    const allNoticeAttachments: any =
      noticeToEdit?.trn_notice_copy_attachments?.map((t: any) => {
        return {
          trn_notice_attachment_id: t.id,
          notice_master_id: t.notice_master_id,
          attachment_type: t.attachment_type,
          details: t.details,
          user_id: t.user_id,
          signatory_name: t.signatory_name,
          signatory_status: t.signatory_status,
          signatory_serial: t.signatory_serial,
        };
      });

    const fetchedAttachmentInputList: any = allNoticeAttachments
      ?.filter((n: any) => n.attachment_type === 'Attachment')
      ?.map((n: any) => {
        return {
          trn_notice_attachment_id: n.trn_notice_attachment_id ?? '',
          notice_master_id: n.notice_master_id ?? '',
          attachment_type: n.attachment_type ?? '',
          details: n.details ?? '',
        };
      });

    const fetchedSignatoryInputList: any = allNoticeAttachments
      ?.filter((n: any) => n.attachment_type === 'Signatory')
      ?.map((n: any) => {
        return {
          trn_notice_attachment_id: n.trn_notice_attachment_id ?? '',
          notice_master_id: n.notice_master_id ?? '',
          attachment_type: n.attachment_type ?? '',
          user_id: n.user_id ?? 0,
          signatory_serial: n.signatory_serial ?? 0,
        };
      });

    setAttachmentInputList(fetchedAttachmentInputList);
    setSignatoryInputList(fetchedSignatoryInputList);

    setNoticeHeaderFile(`${config?.APP_URL}${noticeToEdit?.notice_header}`);

    setNoticeFooterFile(`${config?.APP_URL}${noticeToEdit?.notice_footer}`);
  }, []);

  const handleTemplateNameModalShow = (e: any) => {
    setTemplateNameShow(true);
    setSaveAsTemplateState(true);
  };

  const handleDepartmentChange = (e: any) => {
    const departmentList: string = e?.reduce((acc: any, option: any) => {
      if (acc === '') {
        return option?.value;
      }

      return acc + ', ' + option?.value;
    }, '');

    console.log(departmentList);

    setFieldValue('department_id', departmentList);
  };

  const handleFormReset = () => {
    resetForm({
      values: {
        trn_notice_master_id: '',
        notice_no: '',
        notice_date: '',
        department_id: '',
        notice_subject: '',
        notice_type: '',
        notice_header: '',
        notice_footer: '',
        notice_body: JSON.stringify({ editorHtml: '' }),
        save_as_template: 0,
      },
    });
    noticeHeaderFileRef.current.value = '';
    noticeFooterFileRef.current.value = '';
    setNoticeHeaderFile('');
    setNoticeFooterFile('');
    // setAttachmentRadio(true);
    // setSignatoryRadio(false);
    if (reRenderState === 1) {
      setReRenderState(0);
    } else if (reRenderState === 0) {
      setReRenderState(1);
    }
    setAttachmentInputList([]);
    setSignatoryInputList([]);
    setSaveAsTemplateState(false);
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <h5 className="card-title">
                  <i className="fa fa-edit me-2" />
                  Edit Notice
                </h5>
              </CardHeader>
              <CardBody className="p-4">
                <React.Fragment>
                  <Form
                    autoComplete="off"
                    className="custom-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    onReset={(e) => {
                      e.preventDefault();
                      handleFormReset();
                    }}
                  >
                    <Row>
                      <Col lg={4} md={6} sm={12}>
                        <div className="mb-3">
                          <Label htmlFor="notice_no" className="form-Label">
                            Reference No.
                          </Label>
                          <InputField
                            type="text"
                            placeholder="Reference No."
                            invalid={{ errors, touched }}
                            {...getFieldProps('notice_no')}
                          />
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={12}>
                        <div className="mb-3">
                          <Label htmlFor="notice_date" className="form-Label">
                            Date
                          </Label>
                          <InputField
                            type="date"
                            placeholder=""
                            invalid={{ errors, touched }}
                            {...getFieldProps('notice_date')}
                          />
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={12}>
                        <div className="mb-3">
                          <Label htmlFor="department_id" className="form-Label">
                            Subject
                          </Label>
                          {/* <InputField
                            type="select"
                            options={departmentOptions}
                            placeholder=""
                            invalid={{ errors, touched }}
                            {...getFieldProps('department_id')}
                          /> */}
                          <Select
                            defaultValue={noticeToEdit?.department_id
                              ?.split(', ')
                              ?.map((dept: any) => ({
                                label: dept,
                                value: dept,
                              }))}
                            isMulti
                            name="department_id"
                            value={
                              values.department_id !== ''
                                ? values.department_id
                                  ?.split(', ')
                                  ?.map((dept: any) => ({
                                    label: dept,
                                    value: dept,
                                  }))
                                : []
                            }
                            options={departmentOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={handleDepartmentChange}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={{ menuPortal: (provided: any, state: any) => ({ ...provided, zIndex: '9999', }) }}
                          />
                          {errors?.['department_id'] && touched?.['department_id'] &&
                            <div className='text-danger'>
                              {errors?.['department_id'] as string}
                            </div>
                          }
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={12}>
                        <div className="mb-3">
                          <Label
                            htmlFor="notice_subject"
                            className="form-Label"
                          >
                            Notice Title
                          </Label>
                          <InputField
                            type="text"
                            placeholder="Notice Title"
                            invalid={{ errors, touched }}
                            {...getFieldProps('notice_subject')}
                          />
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={12}>
                        <div className="mb-3">
                          <Label htmlFor="notice_type" className="form-Label">
                            Notice Type
                          </Label>
                          <InputField
                            type="select"
                            options={noticeTypeOptions}
                            placeholder=""
                            invalid={{ errors, touched }}
                            {...getFieldProps('notice_type')}
                          />
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={12}>
                        <div className="mb-3">
                          <Label htmlFor="notice_header" className="form-Label">
                            Notice Header
                          </Label>
                          {noticeHeaderFile && (
                            <a
                              className="me-2 p-0 center"
                              href={noticeHeaderFile}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <span className="btn-outline-info btn-sm btn ms-2">
                                View
                              </span>
                            </a>
                          )}
                          <input
                            ref={noticeHeaderFileRef}
                            name="noticeHeader"
                            type="file"
                            className="form-control"
                            placeholder="Upload File"
                            onChange={(e: any) => {
                              setFieldValue('notice_header', e.target.files[0]);
                            }}
                          ></input>
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={12}>
                        <div className="mb-3">
                          <Label htmlFor="notice_footer" className="form-Label">
                            Notice Footer
                          </Label>
                          {noticeFooterFile && (
                            <a
                              className="me-2 p-0 center"
                              href={noticeFooterFile}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <span className="btn-outline-info btn-sm btn ms-2">
                                View
                              </span>
                            </a>
                          )}
                          <input
                            ref={noticeFooterFileRef}
                            name="noticeFooter"
                            type="file"
                            className="form-control"
                            placeholder="Upload File"
                            onChange={(e: any) => {
                              setFieldValue('notice_footer', e.target.files[0]);
                            }}
                          ></input>
                        </div>
                      </Col>
                      {formik.values['notice_body'] && (
                        <Col sm={12} className="my-3">
                          <div className="mb-3">
                            <Label htmlFor="notice_body" className="form-Label">
                              Notice Body
                            </Label>
                            <EditRichTextEditor
                              key={reRenderState}
                              formikState={values}
                              initialContentEditor={initialContentEditor}
                              setInitialContentEditor={setInitialContentEditor}
                            />
                          </div>
                        </Col>
                      )}
                      {/* <Col lg={4} md={6} sm={12}>
                        <Label htmlFor="attachments" className="form-Label">
                          Attachments
                        </Label>
                        <EditAttachments
                          key={reRenderState}
                          inputList={attachmentInputList}
                          setInputList={setAttachmentInputList}
                        />
                      </Col>
                      <Col lg={4} md={6} sm={12}>
                        <Label htmlFor="signatories" className="form-Label">
                          Signatories
                        </Label>
                        <EditSignatories
                          key={reRenderState}
                          inputList={signatoryInputList}
                          setInputList={setSignatoryInputList}
                        />
                      </Col> */}
                      {/* <Col lg={4} md={6} sm={12}>
                        <Label htmlFor="forwardTo" className="form-Label">
                          Forward To
                        </Label>
                        <EditForwardTo
                          key={reRenderState}
                          inputList={forwardToInputList}
                          setInputList={setForwardToInputList}
                        />
                      </Col> */}
                      {/* <Col sm={12}>
                        <div className="mb-3">
                          {/* <Label className="form-label">Training Mode</Label> */}
                      {/* <br />
                          <FormGroup check inline>
                            <Input
                              id="attachmentRadio1"
                              name="attachmentRadioButton"
                              type="radio"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setAttachmentRadio(true);
                                  setSignatoryRadio(false);
                                }

                                // console.log('attachment clicked');

                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              value={'Attachment'}
                              checked={attachmentRadio}
                            />

                            <Label check for="attachmentRadio1">
                              Attachments
                            </Label>
                          </FormGroup>

                          <FormGroup check inline>
                            <Input
                              id="attachmentRadio2"
                              name="attachmentRadioButton"
                              type="radio"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setAttachmentRadio(false);
                                  setSignatoryRadio(true);
                                }

                                // console.log('signatory clicked');

                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              value={'Signatory'}
                              checked={signatoryRadio}
                            />

                            <Label check for="attachmentRadio2">
                              Signatories
                            </Label>
                          </FormGroup>
                        </div>
                      </Col> */}
                      {attachmentInputList[0]?.trn_notice_attachment_id ? (
                        <Col md={6} sm={12}>
                          <Label htmlFor="attachments" className="form-Label">
                            Attachments
                          </Label>
                          <EditAttachments
                            key={reRenderState}
                            attachmentInputList={attachmentInputList}
                            setInputList={setAttachmentInputList}
                          />
                        </Col>
                      ) : null}
                      {signatoryInputList[0]?.trn_notice_attachment_id ? (
                        <Col md={6} sm={12}>
                          <Label htmlFor="signatories" className="form-Label">
                            Signatories
                          </Label>
                          <EditSignatories
                            key={reRenderState}
                            userList={signatoryUsers}
                            signatoryInputList={signatoryInputList}
                            setInputList={setSignatoryInputList}
                          />
                        </Col>
                      ) : null}

                      {/* <Col sm={12}>
                        <div className="mb-3">
                          <FormGroup switch className="mb-3">
                            <Input
                              type="switch"
                              role="switch"
                              checked={saveAsTemplateState}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSaveAsTemplateState(true);
                                } else {
                                  setSaveAsTemplateState(false);
                                }
                              }}
                            />
                            <Label>Save as Template</Label>
                          </FormGroup>
                        </div>
                      </Col> */}

                      <Col sm={12} className="mt-3">
                        <FormGroup className="mb-3 text-center">
                          <Button
                            type="reset"
                            text="Reset"
                            className="btn btn-warning me-2"
                            onClick={(e) => {
                              handleFormReset();
                            }}
                          ></Button>
                          <Button
                            type="submit"
                            text="Save as Draft"
                            className="btn btn-info me-2"
                            onClick={(e) => {
                              setSaveAsTemplateState(false);
                            }}
                          ></Button>
                          <Button
                            type="submit"
                            text="Submit"
                            className="btn btn-success me-2"
                            onClick={(e) => {
                              setSaveAsTemplateState(false);
                            }}
                          ></Button>
                          <Button
                            type="button"
                            text="Save as Template"
                            className="btn btn-secondary"
                            onClick={(e) => {
                              handleTemplateNameModalShow(e);
                            }}
                          ></Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </React.Fragment>

                <Modal
                  onOpened={function () {
                    toast.dismiss();
                  }}
                  isOpen={templateNameShow}
                  scrollable={true}
                  size="sm"
                  toggle={() => {
                    document.body.classList.add('no_padding');
                  }}
                  backdrop={'static'}
                  modalTransition={{ timeout: 0 }}
                  backdropTransition={{ timeout: 0 }}
                >
                  <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                      Provide Template Name
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => {
                        setTemplateNameShow(!templateNameShow);
                      }}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <Row>
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label
                            htmlFor="notice_subject"
                            className="form-Label"
                          >
                            Name of the template
                          </Label>
                          <InputField
                            type="text"
                            placeholder="Template Name"
                            invalid={{ errors, touched }}
                            {...getFieldProps('template_name')}
                          />
                        </div>
                      </Col>
                      <Col sm={12} className="mt-3">
                        <FormGroup className="mb-3 text-end">
                          <Button
                            type="button"
                            text="Submit"
                            disabled={!values.template_name}
                            className="btn btn-success me-2"
                            onClick={(e) => {
                              handleSubmit();
                              setTemplateNameShow(false);
                            }}
                          ></Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default EditNotice;
