import FacultyInfo from "src/pages/Setup/FacultyInfo";
import AddFacultyInfo from "src/pages/Setup/FacultyInfo/AddFacultyInfo";
import EditFacultyInfo from "src/pages/Setup/FacultyInfo/EditFacultyInfo";

export const facultyInfoSetupRoutes = [
  // setup faculty info
  { path: '/setup/facultyInfo', component: FacultyInfo },
  { path: '/setup/facultyInfo/add', component: AddFacultyInfo },
  {
    path: '/setup/facultyInfo/update/:id',
    component: EditFacultyInfo,
  },
];
