import moment from 'moment';
import { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Modal,
  Row
} from 'reactstrap';
import { Button } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import store from 'src/rtk/app/store';
import { useGetTraineePaymentListQuery } from 'src/rtk/features/trainee/fcpsOfflinePaymentApi';
import loader from '../../assets/images/loader.gif';
import EditDepartmentOfflinePayment from '../InstituteDepartmentPayment/EditDepartmentOfflinePayment';
import EditInstituteOfflinePayment from './EditInstituteOfflinePayment';

const InstitutePaymentList = () => {
  document.title = 'Institute Payment List | BCPS';

  const [editFormActive, setEditFormActive] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [editCount, setEditCount] = useState<number>(1);
  const [institutePayment, setInstitutePayment] = useState<GetFcpsOfflinePayment>({} as GetFcpsOfflinePayment);

  const {
    data: institutePayments,
    error: institutePaymentsError,
    isLoading: isInstitutePaymentsLoading,
    isError: isInstitutePaymentsError,
    isSuccess: isInstitutePaymentsSuccess,
  } = useGetTraineePaymentListQuery('');

  const institutePaymentList = institutePayments?.data?.data;

  const editHandler = (institutePayment: GetFcpsOfflinePayment) => {
    setInstitutePayment(institutePayment);
    setEditCount((e: number) => e + 1);
    setEditFormActive(true);
    setModalShow(true);
  };

  const modalShowHandler = (modalState: boolean, institutePayment: GetFcpsOfflinePayment) => {
    setInstitutePayment(institutePayment);
    setModalShow(modalState);
    setEditFormActive(false);
  };

  const columns: TableColumn<any>[] = [
    {
      name: <div>Sl. No.</div>,
      width: '80px',
      cell: (row, index) => index + 1,
      center: true,
    },
    {
      name: <div>Name</div>,
      selector: (row) => store.getState().auth.user.name,
      sortable: false,
      wrap: true,
      center: true,
    },


    {
      name: <div>Payment For</div>,
      selector: (row) => row.payment_for,
      sortable: false,
      wrap: true,
      center: true,
    },

    {
      name: <div>Payment Mode</div>,
      selector: (row) => row.payment_mode,
      sortable: false,
      wrap: true,
      center: true,
    },

    {
      name: <div>Payment Date</div>,
      selector: (row) =>
        row.payment_date ? moment(row.payment_date).format('DD-MMM-YYYY') : '',
      sortable: false,
      wrap: true,
      center: true,
    },

    {
      name: <div>Payment Method</div>,
      selector: (row) => row.payment_method,
      sortable: false,
      wrap: true,
      center: true,
    },
    {
      name: <div>Fees</div>,
      selector: (row) => row.total_amount,
      sortable: false,
      wrap: true,
      center: true,
    },

    {
      name: <div>Submit Type</div>,
      selector: (row) =>
        row.order_submit_type.toLowerCase() === 'submit'
          ? 'Submitted'
          : row.order_submit_type,
      sortable: false,
      wrap: true,
      center: true,
    },

    {
      name: <div>Status</div>,
      selector: (row) => row.payment_verification_status,
      cell: (row) => {
        switch (row.payment_verification_status) {
          case 'Reject':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-rejected font-size-12 fw-medium">
                  {'Rejected'}
                </div>
              </>
            );

          case 'Draft':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-draft font-size-12 fw-medium">
                  {row.payment_verification_status}
                </div>
              </>
            );

          case 'Approve':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium">
                  {'Approved'}
                </div>
              </>
            );

          case 'Approved':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium">
                  {row.payment_verification_status}
                </div>
              </>
            );

          case 'Pending':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium">
                  {row.payment_verification_status}
                </div>
              </>
            );

          default:
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium">
                  {row.payment_verification_status}
                </div>
              </>
            );
        }
      },
    },

    {
      name: <div>Action</div>,
      width: '230px',
      cell: (row) => (
        <>
          {/* btn-group-example */}
          <FormGroup className="mt-2 me-1">
            <Button
              size="sm"
              color="info"
              type="button"
              className="me-1"
              text={`<span class="fas fa-eye" ></span> View`}
              onClick={() => {
                modalShowHandler(true, row);
              }}
            />
            {row.order_submit_type &&
              row.order_submit_type.toLowerCase() === 'draft' &&
              row.payment_mode && row.application_type === 'Institute' &&
              row.payment_mode.toLowerCase() === 'offline' && (
                <Button
                  size="sm"
                  color="success"
                  type="button"
                  text={`<span class="fas fa-edit" ></span> Edit`}
                  onClick={() => {
                    editHandler(row);
                  }}
                />
              )}
            {row.order_submit_type &&
              row.order_submit_type.toLowerCase() === 'draft' &&
              row.payment_mode && row.application_type === 'Department' &&
              row.payment_mode.toLowerCase() === 'offline' && (
                <Button
                  size="sm"
                  color="success"
                  type="button"
                  text={`<span class="fas fa-edit" ></span> Edit`}
                  onClick={() => {
                    editHandler(row);
                  }}
                />
              )}
          </FormGroup>
          {row?.bank_deposit_slip &&
            <div className='mt-2'>
              <a
                href={config.APP_URL + row?.bank_deposit_slip}
                rel="noreferrer"
                target="_blank"
              >
                <p className="btn btn-sm btn-outline-primary ">
                  <i className="fa-solid fa-file" /> Deposit Slip
                </p>
              </a>
            </div>}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      center: true,
    },
  ];


  return (
    <div className="page-content">
      {isInstitutePaymentsLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb breadcrumbItem={[{ link: '#', name: 'Institute' }]} title={'Institute & Department Payment List'} />
        <Card>
          <CardBody>
            <DataTable
              columns={columns}
              data={institutePaymentList ? institutePaymentList : []}
              progressPending={isInstitutePaymentsLoading}
              expandOnRowClicked
              highlightOnHover
              responsive
              persistTableHead={true}
            />
          </CardBody>
        </Card>

        <Modal
          onOpened={function () {
            toast.dismiss();
          }}
          isOpen={modalShow}
          scrollable={true}
          size="lg"
          toggle={() => {
            document.body.classList.add('no_padding');
          }}
          backdrop={'static'}
          modalTransition={{ timeout: 0 }}
          backdropTransition={{ timeout: 0 }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {editFormActive && 'Edit '}
              {institutePayment?.application_type} Payment Details
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setModalShow(!modalShow);
              }}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <Row>
              <Col>
                {editFormActive &&
                  institutePayment.order_submit_type && institutePayment.application_type === 'Institute' &&
                  institutePayment.order_submit_type.toLowerCase() === 'draft' && (
                    <EditInstituteOfflinePayment
                      key={editCount}
                      institutePayment={institutePayment}
                      setShowAddForm={setModalShow}
                    />
                  )}

                {editFormActive &&
                  institutePayment.order_submit_type && institutePayment.application_type === 'Department' &&
                  institutePayment.order_submit_type.toLowerCase() === 'draft' && (
                    <EditDepartmentOfflinePayment
                      key={editCount}
                      departmentPayment={institutePayment}
                      setShowAddForm={setModalShow}
                    />
                  )}

                {!editFormActive && (
                  <table className="table table-sm table-bordered table-striped">
                    <tbody>
                      <tr>
                        <td>
                          <span className="fw-semibold">Payment Date: </span>
                          <span>{moment(institutePayment.payment_date).format('DD-MMM-YYYY')}</span>
                        </td>
                        <td>
                          <span className="fw-semibold">Total Amount: </span>
                          <span>{institutePayment.total_amount}</span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span className="fw-semibold">Payment For: </span>
                          <span>{institutePayment.payment_for}</span>
                        </td>
                        <td>
                          <span className="fw-semibold">
                            Payment Method:{' '}
                          </span>
                          <span>{institutePayment.payment_method}</span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span className="fw-semibold">Bank Name: </span>
                          <span>{institutePayment.bank_name}</span>
                        </td>
                        <td>
                          <span className="fw-semibold">Branch Name: </span>
                          <span>{institutePayment.branch_name}</span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span className="fw-semibold">
                            Deposit Slip No:{' '}
                          </span>
                          <span>{institutePayment.deposit_slip_no}</span>
                        </td>
                        <td>
                          <span className="fw-semibold">
                            Payment Confirmed:{' '}
                          </span>
                          <span>
                            {institutePayment.payment_confirmation_ind
                              ? 'Yes'
                              : 'No'}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span className="fw-semibold">
                            Payment Verification Status:{' '}
                          </span>
                          <span>
                            {institutePayment.payment_verification_status}
                          </span>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </Col>
            </Row>
          </div>
        </Modal>
      </Container>
    </div>
  );
};

export default InstitutePaymentList;
