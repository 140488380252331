import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import {
    Button as BsButton,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    Row
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import { setFromErrors } from 'src/helpers';
import {
    useDeleteInsDutiesEntryMutation,
    useDeleteInsPublicationsEntryMutation,
    useDeletePartCResearchProjectMutation,
    useGetDepartmentByIdQuery,
    useGetInsDutiesListQuery,
    useGetInsPublicationsListQuery,
    useGetPartCResearchInfoEntryQuery,
    useGetPartCResearchProjectListQuery,
    usePartCResearchInfoEntryMutation
} from 'src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi';
import * as Yup from 'yup';
import InsPublicationsModal from './InsPublicationsModal';
import ResearchProjectConductedByTheDepartmentModal from './ResearchProjectConductedByTheDepartmentModal';
import TraineeWorkAttachmentModal from './TraineeWorkAttachmentModal';
import TrainersAcademicInvolvementModal from './TrainersAcademicInvolvementModal';

const initialValues: PartCResearchInfoEntry = {
    research_info_id: '',
    form_b_department_id: '',
    is_research_info: '',
    research_info_details: '',
    no_of_ongoing_research: 0,
    no_of_research_conducted_last_year: 0,
};

const InstituteDeptRegistrationStepSix = (props: any) => {
    const { goToNext, goToPrevious, instituteName, departmentId, formBDeptId, isDisabled, renewDepartment } = props;
    const history = useHistory();
    const [showInsPublicationsModal, setShowInsPublicationsModal] = useState<boolean>(false);
    const [showResearchProjectConductedByTheDeptModal, setShowResearchProjectConductedByTheDeptModal] = useState<boolean>(false);
    const [showTraineeWorkAttachmentModal, setShowTraineeWorkAttachmentModal] = useState<boolean>(false);
    const [showTrainersAcademicInvolvementModal, setShowTrainersAcademicInvolvementModal] = useState<boolean>(false);
    const [showInsPublicationsRecordViewModal, setShowInsPublicationsRecordViewModal] = useState<boolean>(false);
    const [showResearchProjectConductedByTheDeptRecordViewModal, setShowResearchProjectConductedByTheDeptRecordViewModal] = useState<boolean>(false);
    const [showTraineeWorkAttachmentRecordViewModal, setShowTraineeWorkAttachmentRecordViewModal] = useState<boolean>(false);
    const [showTrainersAcademicInvolvementRecordViewModal, setShowTrainersAcademicInvolvementRecordViewModal] = useState<boolean>(false);
    const [insPublicationsRecordInModal, setInsPublicationsRecordInModal] = useState<InsPublicationsList>({} as InsPublicationsList);
    const [researchProjectConductedByTheDeptRecordInModal, setResearchProjectConductedByTheDeptRecordInModal] = useState<GetResearchProjectConductedByTheDepartmentEntry>({} as GetResearchProjectConductedByTheDepartmentEntry);
    const [traineeWorkAttachmentRecordInModal, setTraineeWorkAttachmentRecordInModal] = useState<InsDutiesList>({} as InsDutiesList);
    const [trainersAcademicInvolvementRecordInModal, setTrainersAcademicInvolvementRecordInModal] = useState<InsDutiesList>({} as InsDutiesList);
    const [recordOfInsPublicationsForDelete, setRecordOfInsPublicationsForDelete] = useState<InsPublicationsList>({} as InsPublicationsList);
    const [recordOfInsPublicationsDeleteModalShow, setRecordOfInsPublicationsDeleteModalShow] = useState<boolean>(false);
    const [recordOfInsPublicationsDeleteEntry, setRecordOfInsPublicationsDeleteEntry] = useState<boolean>(false);
    const [researchProjectConductedByTheDeptForDelete, setResearchProjectConductedByTheDeptForDelete] = useState<GetResearchProjectConductedByTheDepartmentEntry>({} as GetResearchProjectConductedByTheDepartmentEntry);
    const [researchProjectConductedByTheDeptDeleteModalShow, setResearchProjectConductedByTheDeptDeleteModalShow] = useState<boolean>(false);
    const [researchProjectConductedByTheDeptDeleteEntry, setResearchProjectConductedByTheDeptDeleteEntry] = useState<boolean>(false);
    const [recordOfTraineeWorkAttachmentForDelete, setRecordOfTraineeWorkAttachmentForDelete] = useState<InsDutiesList>({} as InsDutiesList);
    const [recordOfTraineeWorkAttachmentDeleteModalShow, setRecordOfTraineeWorkAttachmentDeleteModalShow] = useState<boolean>(false);
    const [recordOfTraineeWorkAttachmentDeleteEntry, setRecordOfTraineeWorkAttachmentDeleteEntry] = useState<boolean>(false);
    const [recordOfTrainersAcademicInvolvementForDelete, setRecordOfTrainersAcademicInvolvementForDelete] = useState<InsDutiesList>({} as InsDutiesList);
    const [recordOfTrainersAcademicInvolvementDeleteModalShow, setRecordOfTrainersAcademicInvolvementDeleteModalShow] = useState<boolean>(false);
    const [recordOfTrainersAcademicInvolvementDeleteEntry, setRecordOfTrainersAcademicInvolvementDeleteEntry] = useState<boolean>(false);
    const [editResearchProjectConductedByTheDept, setEditResearchProjectConductedByTheDept] = useState<boolean>(false);
    const [researchProjectConductedByTheDeptToEdit, setResearchProjectConductedByTheDeptToEdit] = useState<GetResearchProjectConductedByTheDepartmentEntry>({} as GetResearchProjectConductedByTheDepartmentEntry);
    const [editRecordOfInsPublications, setEditRecordOfInsPublications] = useState<boolean>(false);
    const [recordOfInsPublicationsToEdit, setRecordOfInsPublicationsToEdit] = useState<InsPublicationsList>({} as InsPublicationsList);
    const [editRecordOfTraineeWorkAttachment, setEditRecordOfTraineeWorkAttachment] = useState<boolean>(false);
    const [recordOfTraineeWorkAttachmentToEdit, setRecordOfTraineeWorkAttachmentToEdit] = useState<InsDutiesList>({} as InsDutiesList);
    const [editRecordOfTrainersAcademicInvolvement, setEditRecordOfTrainersAcademicInvolvement] = useState<boolean>(false);
    const [recordOfTrainersAcademicInvolvementToEdit, setRecordOfTrainersAcademicInvolvementToEdit] = useState<InsDutiesList>({} as InsDutiesList);

    const researchInfoDetailsFileReference = useRef<any>();

    const [partCResearchInfoEntry, { data: partCResearchInfoData,
        isSuccess: isPartCResearchInfoDataSuccess,
        isLoading: isPartCResearchInfoDataLoading,
        isError: isPartCResearchInfoDataError,
        error: partCResearchInfoDataError
    }] = usePartCResearchInfoEntryMutation();

    const { data: partCResearchInfoGetData, isSuccess: isPartCResearchInfoGetDataSuccess, isError: isPartCResearchInfoGetDataError, error: partCResearchInfoGetDataError } = useGetPartCResearchInfoEntryQuery(departmentId);

    const { data: submittedDepartmentData, isSuccess: isSubmittedDepartmentDataSuccess, isError: isSubmittedDepartmentDataError, error: submittedDepartmentDataError } = useGetDepartmentByIdQuery(formBDeptId);

    const { data: insPublicationsList, isSuccess: isInsPublicationsListSuccess, isError: isInsPublicationsListError, error: insPublicationsListError } = useGetInsPublicationsListQuery(departmentId);

    const { data: researchProjectListData, isSuccess: isResearchProjectListDataSuccess, isError: isResearchProjectListDataError, error: researchProjectListDataError } = useGetPartCResearchProjectListQuery(departmentId);

    const { data: traineesWorkAttachmentList, isSuccess: isTraineesWorkAttachmentListSuccess, isError: isTraineesWorkAttachmentListError, error: traineesWorkAttachmentListError } = useGetInsDutiesListQuery({ type: 'laboratory_work_attachment', form_b_department_id: departmentId });

    const { data: trainersAcademicInvolvementList, isSuccess: isTrainersAcademicInvolvementListSuccess, isError: isTrainersAcademicInvolvementListError, error: trainersAcademicInvolvementListError } = useGetInsDutiesListQuery({ type: 'academic_involvement', form_b_department_id: departmentId });

    const [
        deleteInsPublicationsEntry,
        {
            data: insPublicationsEntryDelete,
            isSuccess: isInsPublicationsEntryDeleteSuccess,
            isError: isInsPublicationsEntryDeleteError,
            error: insPublicationsEntryDeleteError,
        },
    ] = useDeleteInsPublicationsEntryMutation();

    const [
        deletePartCResearchProjectEntry,
        {
            data: partCResearchProjectDelete,
            isSuccess: isPartCResearchProjectDeleteSuccess,
            isError: isPartCResearchProjectDeleteError,
            error: partCResearchProjectDeleteError,
        },
    ] = useDeletePartCResearchProjectMutation();

    const [
        deleteTraineeWorkAttachmentEntry,
        {
            data: traineeWorkAttachmentDelete,
            isSuccess: isTraineeWorkAttachmentEntryDeleteSuccess,
            isError: isTraineeWorkAttachmentEntryDeleteError,
            error: traineeWorkAttachmentEntryDeleteError,
        },
    ] = useDeleteInsDutiesEntryMutation();

    const [
        deleteTrainersAcademicInvolvementEntry,
        {
            data: trainersAcademicInvolvementEntryDelete,
            isSuccess: isTrainersAcademicInvolvementEntryDeleteSuccess,
            isError: isTrainersAcademicInvolvementEntryDeleteError,
            error: trainersAcademicInvolvementEntryDeleteError,
        },
    ] = useDeleteInsDutiesEntryMutation();

    const handleEditResearchProjectConductedByTheDeptRecord = (e: any, researchProjectConductedByTheDeptRecord: SupportServiceList) => {
        setEditResearchProjectConductedByTheDept(true);
        setResearchProjectConductedByTheDeptToEdit(researchProjectConductedByTheDeptRecord);
        setShowResearchProjectConductedByTheDeptModal(true);
    };

    const handleEditInsPublicationsRecord = (e: any, insPublicationsRecord: InsPublicationsList) => {
        setEditRecordOfInsPublications(true);
        setRecordOfInsPublicationsToEdit(insPublicationsRecord);
        setShowInsPublicationsModal(true);
    };

    const handleEditTraineeWorkAttachmentRecord = (e: any, traineeWorkAttachmentRecord: InsDutiesList) => {
        setEditRecordOfTraineeWorkAttachment(true);
        setRecordOfTraineeWorkAttachmentToEdit(traineeWorkAttachmentRecord);
        setShowTraineeWorkAttachmentModal(true);
    };

    const handleEditTrainersAcademicInvolvementRecord = (e: any, trainersAcademicInvolvementRecord: InsDutiesList) => {
        setEditRecordOfTrainersAcademicInvolvement(true);
        setRecordOfTrainersAcademicInvolvementToEdit(trainersAcademicInvolvementRecord);
        setShowTrainersAcademicInvolvementModal(true);
    };

    const handleInsPublicationsRecordView = (e: any, insPublicationsRecord: InsPublicationsList) => {
        setInsPublicationsRecordInModal(insPublicationsRecord);
        setShowInsPublicationsRecordViewModal(true);
    };

    const handleResearchProjectConductedByTheDeptRecordView = (e: any, researchProjectConductedByTheDeptRecord: SupportServiceList) => {
        setResearchProjectConductedByTheDeptRecordInModal(researchProjectConductedByTheDeptRecord);
        setShowResearchProjectConductedByTheDeptRecordViewModal(true);
    };

    const handleTraineeWorkAttachmentRecordView = (e: any, traineeWorkAttachmentRecord: InsDutiesList) => {
        setTraineeWorkAttachmentRecordInModal(traineeWorkAttachmentRecord);
        setShowTraineeWorkAttachmentRecordViewModal(true);
    };

    const handleTrainersAcademicInvolvementRecordView = (e: any, trainersAcademicInvolvementRecord: InsDutiesList) => {
        setTrainersAcademicInvolvementRecordInModal(trainersAcademicInvolvementRecord);
        setShowTrainersAcademicInvolvementRecordViewModal(true);
    };

    const insPublicationsRecordDeleteHandler = (e: any, insPublicationsRecord: InsPublicationsList) => {
        setRecordOfInsPublicationsForDelete(insPublicationsRecord);
        setRecordOfInsPublicationsDeleteModalShow(true);
    };

    const researchProjectConductedByTheDeptRecordDeleteHandler = (e: any, researchProjectConductedByTheDeptRecord: GetResearchProjectConductedByTheDepartmentEntry) => {
        setResearchProjectConductedByTheDeptForDelete(researchProjectConductedByTheDeptRecord);
        setResearchProjectConductedByTheDeptDeleteModalShow(true);
    };

    const traineeWorkAttachmentRecordDeleteHandler = (e: any, traineeWorkAttachmentRecord: InsDutiesList) => {
        setRecordOfTraineeWorkAttachmentForDelete(traineeWorkAttachmentRecord);
        setRecordOfTraineeWorkAttachmentDeleteModalShow(true);
    };

    const trainersAcademicInvolvementRecordDeleteHandler = (e: any, trainersAcademicInvolvementRecord: InsDutiesList) => {
        setRecordOfTrainersAcademicInvolvementForDelete(trainersAcademicInvolvementRecord);
        setRecordOfTrainersAcademicInvolvementDeleteModalShow(true);
    };

    const err: CustomError = partCResearchInfoDataError as CustomError;

    document.title = renewDepartment === true ? 'Renew Department | BCPS' : 'Department Registration | BCPS';

    const formik = useFormik({
        initialValues,

        validationSchema: Yup.object({}),

        onSubmit: (values: any) => {
            // console.log(values);
            values.form_b_department_id = departmentId;

            if (editResearchProjectConductedByTheDept) {
                setEditResearchProjectConductedByTheDept(false);
            }

            if (editRecordOfInsPublications) {
                setEditRecordOfInsPublications(false);
            }

            if (editRecordOfTraineeWorkAttachment) {
                setEditRecordOfTraineeWorkAttachment(false);
            }

            if (editRecordOfTrainersAcademicInvolvement) {
                setEditRecordOfTrainersAcademicInvolvement(false);
            }

            if (!showInsPublicationsRecordViewModal &&
                !showResearchProjectConductedByTheDeptRecordViewModal &&
                !showTraineeWorkAttachmentRecordViewModal &&
                !showTrainersAcademicInvolvementRecordViewModal &&
                !editResearchProjectConductedByTheDept &&
                !editRecordOfInsPublications &&
                !editRecordOfTraineeWorkAttachment &&
                !editRecordOfTrainersAcademicInvolvement) {
                const formData = new FormData();
                Object.keys(values).forEach((key: string) => {
                    if (['research_info_details'].includes(key)) {
                        if (typeof values[key] !== 'string') {
                            formData.append(key, values[key]);
                        }
                    } else {
                        formData.append(key, values[key]);
                    }
                });

                partCResearchInfoEntry(formData);
            }
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    useEffect(() => {
        if (partCResearchInfoGetData?.data?.form_b_department_id && !isPartCResearchInfoGetDataError) {
            const partCResearchInfo: GetPartCResearchInfoEntry = partCResearchInfoGetData?.data!;
            setValues({
                research_info_id: partCResearchInfo?.id,
                form_b_department_id: partCResearchInfo?.form_b_department_id,
                is_research_info: partCResearchInfo?.is_research_info,
                research_info_details: partCResearchInfo?.research_info_details,
                no_of_ongoing_research: partCResearchInfo?.no_of_ongoing_research,
                no_of_research_conducted_last_year: partCResearchInfo?.no_of_research_conducted_last_year,
            });
        } else {
            setFieldValue('is_research_info', 'Nil');
        }
    }, [partCResearchInfoGetData, isPartCResearchInfoGetDataError]);

    useEffect(() => {
        if (isPartCResearchInfoDataSuccess) {
            handleFormReset();
            toast.success('Department information saved successfully');
            goToNext();
        }
        if (isPartCResearchInfoDataError) {
            toast.error(err?.message || 'Something went wrong');
            setFromErrors(err.data, setErrors);
        }
    }, [isPartCResearchInfoDataSuccess, isPartCResearchInfoDataError]);

    const researchProjectConductedByTheDeptColumns: TableColumn<GetResearchProjectConductedByTheDepartmentEntry>[] = [
        {
            name: <div className="text-center">Sl. No.</div>,
            selector: (row, index) => index! + 1,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Name of project</div>,
            selector: (row) => row.name_of_project ?? '',
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Sponsoring agency</div>,
            selector: (row) => row.sponsoring_agency ?? '',
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">On-going/proposed</div>,
            selector: (row) => row.project_status ?? '',
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Action</div>,
            width: '200px',
            cell: (row) => (
                <>
                    <FormGroup className="btn-group mt-2">
                        <button
                            className="btn btn-sm btn-secondary"
                            onClick={(e) => {
                                handleEditResearchProjectConductedByTheDeptRecord(e, row);
                            }}
                        >
                            <span className="fas fa-edit"></span> Edit
                        </button>
                        <button
                            className="btn btn-sm btn-info"
                            style={{ pointerEvents: 'auto' }}
                            onClick={(e) => {
                                handleResearchProjectConductedByTheDeptRecordView(e, row);
                            }}
                        >
                            <span className="fas fa-eye"></span> View
                        </button>
                        {
                            <Button
                                size="sm"
                                color="danger"
                                type="button"
                                text={`<span class="fas fa-trash" ></span> Delete`}
                                onClick={(e) => {
                                    researchProjectConductedByTheDeptRecordDeleteHandler(e, row);
                                }}
                            />
                        }
                    </FormGroup>
                </>
            ),

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const insPublicationsColumns: TableColumn<InsPublicationsList>[] = [
        {
            name: <div className="text-center">Sl. No.</div>,
            selector: (row, index) => index! + 1,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Title</div>,
            selector: (row) => row.title ?? '',
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Journal</div>,
            selector: (row) => row.journal ?? '',
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Year</div>,
            selector: (row) => row.year ?? '',
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Action</div>,
            width: '200px',
            cell: (row) => (
                <>
                    <FormGroup className="btn-group mt-2">
                        <button
                            className="btn btn-sm btn-secondary"
                            onClick={(e) => {
                                handleEditInsPublicationsRecord(e, row);
                            }}
                        >
                            <span className="fas fa-edit"></span> Edit
                        </button>
                        <button
                            className="btn btn-sm btn-info"
                            style={{ pointerEvents: 'auto' }}
                            onClick={(e) => {
                                handleInsPublicationsRecordView(e, row);
                            }}
                        >
                            <span className="fas fa-eye"></span> View
                        </button>
                        {
                            <Button
                                size="sm"
                                color="danger"
                                type="button"
                                text={`<span class="fas fa-trash" ></span> Delete`}
                                onClick={(e) => {
                                    insPublicationsRecordDeleteHandler(e, row);
                                }}
                            />
                        }
                    </FormGroup>
                </>
            ),

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const traineesWorkAttachmentColumns: TableColumn<InsDutiesList>[] = [
        {
            name: <div className="text-center">Sl. No.</div>,
            selector: (row, index) => index! + 1,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Duties</div>,
            selector: (row) => row.duties ?? '',
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Frequency/Week</div>,
            selector: (row) => row.frequency_or_week ?? '',
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Action</div>,
            width: '200px',
            cell: (row) => (
                <>
                    <FormGroup className="btn-group mt-2">
                        <button
                            className="btn btn-sm btn-secondary"
                            onClick={(e) => {
                                handleEditTraineeWorkAttachmentRecord(e, row);
                            }}
                        >
                            <span className="fas fa-edit"></span> Edit
                        </button>
                        <button
                            className="btn btn-sm btn-info"
                            style={{ pointerEvents: 'auto' }}
                            onClick={(e) => {
                                handleTraineeWorkAttachmentRecordView(e, row);
                            }}
                        >
                            <span className="fas fa-eye"></span> View
                        </button>
                        {
                            <Button
                                size="sm"
                                color="danger"
                                type="button"
                                text={`<span class="fas fa-trash" ></span> Delete`}
                                onClick={(e) => {
                                    traineeWorkAttachmentRecordDeleteHandler(e, row);
                                }}
                            />
                        }
                    </FormGroup>
                </>
            ),

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const trainersAcademicInvolvementColumns: TableColumn<InsDutiesList>[] = [
        {
            name: <div className="text-center">Sl. No.</div>,
            selector: (row, index) => index! + 1,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Duties</div>,
            selector: (row) => row.duties ?? '',
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Frequency per month</div>,
            selector: (row) => row.frequency_or_week ?? '',
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Action</div>,
            width: '200px',
            cell: (row) => (
                <>
                    <FormGroup className="btn-group mt-2">
                        <button
                            className="btn btn-sm btn-secondary"
                            onClick={(e) => {
                                handleEditTrainersAcademicInvolvementRecord(e, row);
                            }}
                        >
                            <span className="fas fa-edit"></span> Edit
                        </button>
                        <button
                            className="btn btn-sm btn-info"
                            style={{ pointerEvents: 'auto' }}
                            onClick={(e) => {
                                handleTrainersAcademicInvolvementRecordView(e, row);
                            }}
                        >
                            <span className="fas fa-eye"></span> View
                        </button>
                        {
                            <Button
                                size="sm"
                                color="danger"
                                type="button"
                                text={`<span class="fas fa-trash" ></span> Delete`}
                                onClick={(e) => {
                                    trainersAcademicInvolvementRecordDeleteHandler(e, row);
                                }}
                            />
                        }
                    </FormGroup>
                </>
            ),

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (recordOfInsPublicationsDeleteEntry) {
            deleteInsPublicationsEntry(recordOfInsPublicationsForDelete?.id!);
        }
    }, [recordOfInsPublicationsDeleteEntry]);

    useEffect(() => {
        if (!isInsPublicationsEntryDeleteError && isInsPublicationsEntryDeleteSuccess) {
            toast.dismiss();
            toast.success('Publication entry deleted successfully');
            setRecordOfInsPublicationsDeleteModalShow(false);
            setRecordOfInsPublicationsDeleteEntry(false);
        } else if (isInsPublicationsEntryDeleteError && !isInsPublicationsEntryDeleteSuccess) {
            toast.dismiss();
            toast.error('Error while deleting the publication record');
        }
    }, [isInsPublicationsEntryDeleteError, isInsPublicationsEntryDeleteSuccess]);

    useEffect(() => {
        if (researchProjectConductedByTheDeptDeleteEntry) {
            deletePartCResearchProjectEntry(researchProjectConductedByTheDeptForDelete?.id!);
        }
    }, [researchProjectConductedByTheDeptDeleteEntry]);

    useEffect(() => {
        if (!isPartCResearchProjectDeleteError && isPartCResearchProjectDeleteSuccess) {
            toast.dismiss();
            toast.success('Research project entry deleted successfully');
            setResearchProjectConductedByTheDeptDeleteModalShow(false);
            setResearchProjectConductedByTheDeptDeleteEntry(false);
        } else if (isPartCResearchProjectDeleteError && !isPartCResearchProjectDeleteSuccess) {
            toast.dismiss();
            toast.error('Error while deleting the research project entry');
        }
    }, [isPartCResearchProjectDeleteError, isPartCResearchProjectDeleteSuccess]);

    useEffect(() => {
        if (recordOfTraineeWorkAttachmentDeleteEntry) {
            deleteTraineeWorkAttachmentEntry(recordOfTraineeWorkAttachmentForDelete?.id!);
        }
    }, [recordOfTraineeWorkAttachmentDeleteEntry]);

    useEffect(() => {
        if (!isTraineeWorkAttachmentEntryDeleteError && isTraineeWorkAttachmentEntryDeleteSuccess) {
            toast.dismiss();
            toast.success('Trainees\' Clinical/Laboratory work attachment entry deleted successfully');
            setRecordOfTraineeWorkAttachmentDeleteModalShow(false);
            setRecordOfTraineeWorkAttachmentDeleteEntry(false);
        } else if (isTraineeWorkAttachmentEntryDeleteError && !isTraineeWorkAttachmentEntryDeleteSuccess) {
            toast.dismiss();
            toast.error('Error while deleting trainees\' Clinical/Laboratory work attachment record');
        }
    }, [isTraineeWorkAttachmentEntryDeleteError, isTraineeWorkAttachmentEntryDeleteSuccess]);

    useEffect(() => {
        if (recordOfTrainersAcademicInvolvementDeleteEntry) {
            deleteTrainersAcademicInvolvementEntry(recordOfTrainersAcademicInvolvementForDelete?.id!);
        }
    }, [recordOfTrainersAcademicInvolvementDeleteEntry]);

    useEffect(() => {
        if (!isTrainersAcademicInvolvementEntryDeleteError && isTrainersAcademicInvolvementEntryDeleteSuccess) {
            toast.dismiss();
            toast.success('Trainer academic involvement record deleted successfully');
            setRecordOfTrainersAcademicInvolvementDeleteModalShow(false);
            setRecordOfTrainersAcademicInvolvementDeleteEntry(false);
        } else if (isTrainersAcademicInvolvementEntryDeleteError && !isTrainersAcademicInvolvementEntryDeleteSuccess) {
            toast.dismiss();
            toast.error('Error while deleting the trainer academic involvement record');
        }
    }, [isTrainersAcademicInvolvementEntryDeleteError, isTrainersAcademicInvolvementEntryDeleteSuccess]);

    const handleFormReset = () => {
        resetForm();
    };

    useEffect(() => {
        if (values.is_research_info === 'Nil') {
            if (researchInfoDetailsFileReference?.current?.value) {
                researchInfoDetailsFileReference.current.value = '';
            }
            setFieldValue('research_info_details', '');
        }
    }, [values.is_research_info]);

    return (
        <div className="page-content">
            <Container fluid>
                <BetterBreadcrumb
                    title="Training Accreditation Application Form"
                    breadcrumbItem={[{ link: '/institute-registration-list', name: 'Department List' }]}
                />
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title mb-0 flex-grow-1">
                                    <span>Part-D: Support Service of the Department (To be checked and verified against the information provided by the institute in part D of Form - 1)</span>
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    autoComplete="off"
                                    className="custom-form"
                                    style={isDisabled === true ? { pointerEvents: 'none' } : {}}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    onReset={(e) => {
                                        e.preventDefault();
                                        handleFormReset();
                                    }}
                                >
                                    <Row>
                                        <Col sm={12}>
                                            <Row className='justify-content-evenly'>
                                                <Col sm={12} md={6}>
                                                    <FormGroup className="col-sm-12 mb-3">
                                                        <Label for="institute_name">Institute Name</Label>
                                                        <InputField
                                                            id="institute_name"
                                                            name="institute_name"
                                                            type="text"
                                                            value={instituteName}
                                                            placeholder=""
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col sm={12} md={6}>
                                                    <FormGroup className="col-sm-12 mb-3">
                                                        <Label for="department_name">Name of the Department</Label>
                                                        <InputField
                                                            id="department_name"
                                                            name="department_name"
                                                            type="text"
                                                            value={submittedDepartmentData?.data?.department_name!}
                                                            placeholder=""
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col sm={12}>
                                            <fieldset className="border p-2">
                                                <legend className='w-auto' style={{ float: 'none', padding: 'inherit' }}>
                                                    <span className='px-4 bg-success rounded-pill'>
                                                        <i className="fa-solid fa-9" style={{ color: '#f8f9fc' }}></i>
                                                    </span>
                                                </legend>

                                                <Row>
                                                    <Col sm={12}>
                                                        <FormGroup row className="mb-3">
                                                            <Label className="font-size-14 mb-3" sm={3}>
                                                                Research Related Information:
                                                            </Label>
                                                            <Col sm={1}>
                                                                <div className="d-inline-block mx-2 mt-2">
                                                                    <div className="form-check form-check-left mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="isResearchInfo"
                                                                            id="isResearchInfoYes"
                                                                            value={'Yes'}
                                                                            checked={values.is_research_info === 'Yes'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('is_research_info', 'Yes');
                                                                                }
                                                                            }}
                                                                            defaultChecked
                                                                        />
                                                                        <label className="form-check-label" htmlFor="isResearchInfoYes">Yes</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col sm={1}>
                                                                <div className="d-inline-block mx-2 mt-2">
                                                                    <div className="form-check form-check-left">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="isResearchInfo"
                                                                            id="isResearchInfoNo"
                                                                            value={'Nil'}
                                                                            checked={values.is_research_info === 'Nil'}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setFieldValue('is_research_info', 'Nil');
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="isResearchInfoNo">Nil</label>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            {values.is_research_info === 'Yes' ?
                                                                <Col sm={6}>
                                                                    <FormGroup row className="col-sm-12 mb-3">
                                                                        <Label htmlFor="research_info_details" sm={3}>Provide Details</Label>
                                                                        <Col sm={6}>
                                                                            <input
                                                                                type="file"
                                                                                ref={researchInfoDetailsFileReference}
                                                                                name="research_info_details"
                                                                                id="research_info_details"
                                                                                className="form-control"
                                                                                placeholder=""
                                                                                onChange={(e) => {
                                                                                    setFieldValue('research_info_details', e.target.files![0]);
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col sm={1}>
                                                                            {typeof values.research_info_details === 'string' && values.research_info_details !== '' ? (
                                                                                <a
                                                                                    style={{ pointerEvents: 'auto' }}
                                                                                    className="me-2 p-0 center"
                                                                                    href={`${config?.APP_URL}${values.research_info_details}`}
                                                                                    rel="noreferrer"
                                                                                    target="_blank"
                                                                                >
                                                                                    <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                                                                </a>
                                                                            ) : (
                                                                                typeof values.research_info_details !== 'string' && values.research_info_details &&
                                                                                <a
                                                                                    className="me-2 p-0 center"
                                                                                    href={URL.createObjectURL(values.research_info_details)}
                                                                                    rel="noreferrer"
                                                                                    target="_blank"
                                                                                >
                                                                                    <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                                                                </a>
                                                                            )}
                                                                        </Col>
                                                                    </FormGroup>
                                                                </Col>
                                                                :
                                                                null}
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={12} className='mt-2'>
                                                        <FormGroup row className="mb-3">
                                                            <Label htmlFor="no_of_ongoing_research" sm={3}>a. No. of on-going Research:</Label>
                                                            <Col sm={2}>
                                                                <InputField
                                                                    min={'0'}
                                                                    type="number"
                                                                    placeholder=""
                                                                    {...getFieldProps('no_of_ongoing_research')}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col sm={12}>
                                                        <FormGroup row className="mb-3">
                                                            <Label htmlFor="no_of_research_conducted_last_year" sm={3}>b. No. of research conducted last year:</Label>
                                                            <Col sm={2}>
                                                                <InputField
                                                                    min={'0'}
                                                                    type="number"
                                                                    placeholder=""
                                                                    {...getFieldProps('no_of_research_conducted_last_year')}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </fieldset>
                                        </Col>

                                        <Col sm={12}>
                                            <fieldset className="border p-2">
                                                <legend className='w-auto' style={{ float: 'none', padding: 'inherit' }}>
                                                    <span className='px-4 bg-success rounded-pill'>
                                                        <i className="fa-solid fa-9" style={{ color: '#f8f9fc' }}></i>
                                                        <span style={{ color: '#f8f9fc' }} className='mx-1'>.</span>
                                                        <i className="fa-solid fa-1" style={{ color: '#f8f9fc' }}></i>
                                                    </span> Research projects conducted by the department
                                                </legend>

                                                <div className='filter-wrapper'>
                                                    <FormGroup className="ms-auto justify-content-end">
                                                        <BsButton
                                                            type="button"
                                                            className="btn btn-success me-2"
                                                            onClick={(e) => {
                                                                setEditResearchProjectConductedByTheDept(false);
                                                                setResearchProjectConductedByTheDeptToEdit({} as SupportServiceList);
                                                                setShowResearchProjectConductedByTheDeptModal(true);
                                                            }}
                                                        >
                                                            <i className="fa fa-plus me-2" /> Add Record
                                                        </BsButton>
                                                    </FormGroup>
                                                </div>
                                                <DataTable
                                                    columns={researchProjectConductedByTheDeptColumns}
                                                    data={researchProjectListData?.data ?? []}
                                                    persistTableHead={true}
                                                    noDataComponent={<div className='border border-2 border-light border-top-0 p-5 text-center w-100'>There are no records to display</div>}
                                                    expandOnRowClicked
                                                    highlightOnHover
                                                />
                                            </fieldset>
                                        </Col>

                                        <Col sm={12}>
                                            <fieldset className="border p-2">
                                                <legend className='w-auto' style={{ float: 'none', padding: 'inherit' }}>
                                                    <span className='px-4 bg-success rounded-pill'>
                                                        <i className="fa-solid fa-9" style={{ color: '#f8f9fc' }}></i>
                                                        <span style={{ color: '#f8f9fc' }} className='mx-1'>.</span>
                                                        <i className="fa-solid fa-2" style={{ color: '#f8f9fc' }}></i>
                                                    </span> Publication/s by the faculty members of the department in the last 3 years
                                                </legend>

                                                <div className='filter-wrapper'>
                                                    <FormGroup className="ms-auto justify-content-end">
                                                        <BsButton
                                                            type="button"
                                                            className="btn btn-success me-2"
                                                            onClick={(e) => {
                                                                setEditRecordOfInsPublications(false);
                                                                setRecordOfInsPublicationsToEdit({} as InsPublicationsList);
                                                                setShowInsPublicationsModal(true);
                                                            }}
                                                        >
                                                            <i className="fa fa-plus me-2" /> Add Record
                                                        </BsButton>
                                                    </FormGroup>
                                                </div>
                                                <DataTable
                                                    columns={insPublicationsColumns}
                                                    data={insPublicationsList?.data ?? []}
                                                    persistTableHead={true}
                                                    noDataComponent={<div className='border border-2 border-light border-top-0 p-5 text-center w-100'>There are no records to display</div>}
                                                    expandOnRowClicked
                                                    highlightOnHover
                                                />
                                            </fieldset>
                                        </Col>

                                        <Col sm={12}>
                                            <fieldset className="border p-2">
                                                <legend className='w-auto' style={{ float: 'none', padding: 'inherit' }}>
                                                    <span className='px-4 bg-success rounded-pill'>
                                                        <i className="fa-solid fa-1" style={{ color: '#f8f9fc' }}></i>
                                                        <i className="fa-solid fa-0" style={{ color: '#f8f9fc' }}></i>
                                                    </span>
                                                    Trainees' Clinical/Laboratory work attachment
                                                </legend>

                                                <div className='filter-wrapper'>
                                                    <FormGroup className="ms-auto justify-content-end">
                                                        <BsButton
                                                            type="button"
                                                            className="btn btn-success me-2"
                                                            onClick={(e) => {
                                                                setEditRecordOfTraineeWorkAttachment(false);
                                                                setRecordOfTraineeWorkAttachmentToEdit({} as InsDutiesList);
                                                                setShowTraineeWorkAttachmentModal(true);
                                                            }}
                                                        >
                                                            <i className="fa fa-plus me-2" /> Add Record
                                                        </BsButton>
                                                    </FormGroup>
                                                </div>
                                                <DataTable
                                                    columns={traineesWorkAttachmentColumns}
                                                    data={traineesWorkAttachmentList?.data ?? []}
                                                    persistTableHead={true}
                                                    noDataComponent={<div className='border border-2 border-light border-top-0 p-5 text-center w-100'>There are no records to display</div>}
                                                    expandOnRowClicked
                                                    highlightOnHover
                                                />
                                            </fieldset>
                                        </Col>

                                        <Col sm={12}>
                                            <fieldset className="border p-2">
                                                <legend className='w-auto' style={{ float: 'none', padding: 'inherit' }}>
                                                    <span className='px-4 bg-success rounded-pill'>
                                                        <i className="fa-solid fa-1" style={{ color: '#f8f9fc' }}></i>
                                                        <i className="fa-solid fa-1" style={{ color: '#f8f9fc' }}></i>
                                                    </span>
                                                    Trainers' Academic Involvement
                                                </legend>

                                                <div className='filter-wrapper'>
                                                    <FormGroup className="ms-auto justify-content-end">
                                                        <BsButton
                                                            type="button"
                                                            className="btn btn-success me-2"
                                                            onClick={(e) => {
                                                                setEditRecordOfTrainersAcademicInvolvement(false);
                                                                setRecordOfTrainersAcademicInvolvementToEdit({} as InsDutiesList);
                                                                setShowTrainersAcademicInvolvementModal(true);
                                                            }}
                                                        >
                                                            <i className="fa fa-plus me-2" /> Add Record
                                                        </BsButton>
                                                    </FormGroup>
                                                </div>
                                                <DataTable
                                                    columns={trainersAcademicInvolvementColumns}
                                                    data={trainersAcademicInvolvementList?.data ?? []}
                                                    persistTableHead={true}
                                                    noDataComponent={<div className='border border-2 border-light border-top-0 p-5 text-center w-100'>There are no records to display</div>}
                                                    expandOnRowClicked
                                                    highlightOnHover
                                                />
                                            </fieldset>
                                        </Col>
                                    </Row>

                                    <FormGroup row className='justify-content-between mt-5'>
                                        <Col sm={9}>
                                            <BsButton color="warning" style={isDisabled === true ? { pointerEvents: 'auto' } : {}} className='me-3 text-start' onClick={() => {
                                                goToPrevious();
                                            }}>
                                                {'Previous'}
                                            </BsButton>
                                        </Col>

                                        <Col sm={3}>
                                            {isDisabled !== true ?
                                                <div className='d-flex justify-content-end'>
                                                    <BsButton color="secondary" className='me-3' onClick={() => {
                                                        handleFormReset();
                                                    }}>
                                                        {'Reset'}
                                                    </BsButton>
                                                    <BsButton color="success" disabled={isPartCResearchInfoDataLoading} onClick={() => {
                                                        handleSubmit();
                                                    }}>
                                                        {'Save & Next'}
                                                    </BsButton>
                                                </div>
                                                :
                                                <div className='d-flex justify-content-end'>
                                                    <BsButton color="success" style={{ pointerEvents: 'auto' }} onClick={() => {
                                                        goToNext();
                                                    }}>
                                                        {'Next'}
                                                    </BsButton>
                                                </div>}
                                        </Col>
                                    </FormGroup>
                                </Form>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showResearchProjectConductedByTheDeptModal}
                                    scrollable={true}
                                    size="lg"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Research project conducted by the department
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowResearchProjectConductedByTheDeptModal(!showResearchProjectConductedByTheDeptModal);
                                                setEditResearchProjectConductedByTheDept(false);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <Row>
                                            <Col>
                                                {!editResearchProjectConductedByTheDept && !researchProjectConductedByTheDeptToEdit?.id ?
                                                    <ResearchProjectConductedByTheDepartmentModal departmentId={departmentId} setModalShow={setShowResearchProjectConductedByTheDeptModal} />
                                                    :
                                                    <ResearchProjectConductedByTheDepartmentModal departmentId={departmentId} recordOfResearchProjectToEdit={researchProjectConductedByTheDeptToEdit} setModalShow={setShowResearchProjectConductedByTheDeptModal} />
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showInsPublicationsModal}
                                    scrollable={true}
                                    size="lg"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Publication/s by the faculty members of the department in the last 3 years
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowInsPublicationsModal(!showInsPublicationsModal);
                                                setEditRecordOfInsPublications(false);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <Row>
                                            <Col>
                                                {!editRecordOfInsPublications && !recordOfInsPublicationsToEdit?.id ?
                                                    <InsPublicationsModal departmentId={departmentId} setModalShow={setShowInsPublicationsModal} />
                                                    :
                                                    <InsPublicationsModal departmentId={departmentId} recordOfInsPublicationsToEdit={recordOfInsPublicationsToEdit} setModalShow={setShowInsPublicationsModal} />
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showTraineeWorkAttachmentModal}
                                    scrollable={true}
                                    size="lg"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Trainees' Clinical/Laboratory work attachment
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowTraineeWorkAttachmentModal(!showTraineeWorkAttachmentModal);
                                                setEditRecordOfTraineeWorkAttachment(false);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <Row>
                                            <Col>
                                                {!editRecordOfTraineeWorkAttachment && !recordOfTraineeWorkAttachmentToEdit?.id ?
                                                    <TraineeWorkAttachmentModal departmentId={departmentId} setModalShow={setShowTraineeWorkAttachmentModal} />
                                                    :
                                                    <TraineeWorkAttachmentModal departmentId={departmentId} recordOfTraineeWorkAttachmentToEdit={recordOfTraineeWorkAttachmentToEdit} setModalShow={setShowTraineeWorkAttachmentModal} />
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showTrainersAcademicInvolvementModal}
                                    scrollable={true}
                                    size="lg"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Trainers' Academic Involvement
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowTrainersAcademicInvolvementModal(!showTrainersAcademicInvolvementModal);
                                                setEditRecordOfTrainersAcademicInvolvement(false);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <Row>
                                            <Col>
                                                {!editRecordOfTrainersAcademicInvolvement && !recordOfTrainersAcademicInvolvementToEdit?.id ?
                                                    <TrainersAcademicInvolvementModal departmentId={departmentId} setModalShow={setShowTrainersAcademicInvolvementModal} />
                                                    :
                                                    <TrainersAcademicInvolvementModal departmentId={departmentId} recordOfTrainersAcademicInvolvementToEdit={recordOfTrainersAcademicInvolvementToEdit} setModalShow={setShowTrainersAcademicInvolvementModal} />
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showResearchProjectConductedByTheDeptRecordViewModal}
                                    scrollable={true}
                                    size="md"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className='modal-header'>
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Research project conducted by the department
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowResearchProjectConductedByTheDeptRecordViewModal(!showResearchProjectConductedByTheDeptRecordViewModal);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <ModalBody>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table table-bordered table-striped table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>Name of the project: </strong>
                                                                {researchProjectConductedByTheDeptRecordInModal?.name_of_project ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Sponsoring agency: </strong>
                                                                {researchProjectConductedByTheDeptRecordInModal?.sponsoring_agency ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Project status: </strong>
                                                                {researchProjectConductedByTheDeptRecordInModal?.project_status ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showInsPublicationsRecordViewModal}
                                    scrollable={true}
                                    size="md"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className='modal-header'>
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Publication information
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowInsPublicationsRecordViewModal(!showInsPublicationsRecordViewModal);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <ModalBody>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table table-bordered table-striped table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>Title: </strong>
                                                                {insPublicationsRecordInModal?.title ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Journal: </strong>
                                                                {insPublicationsRecordInModal?.journal ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Year: </strong>
                                                                {insPublicationsRecordInModal?.year ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showTraineeWorkAttachmentRecordViewModal}
                                    scrollable={true}
                                    size="md"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className='modal-header'>
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Trainees' Clinical/Laboratory work attachment
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowTraineeWorkAttachmentRecordViewModal(!showTraineeWorkAttachmentRecordViewModal);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <ModalBody>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table table-bordered table-striped table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>Duties: </strong>
                                                                {traineeWorkAttachmentRecordInModal?.duties ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Frequency/Week: </strong>
                                                                {traineeWorkAttachmentRecordInModal?.frequency_or_week ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showTrainersAcademicInvolvementRecordViewModal}
                                    scrollable={true}
                                    size="md"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className='modal-header'>
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Trainers' Academic Involvement
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowTrainersAcademicInvolvementRecordViewModal(!showTrainersAcademicInvolvementRecordViewModal);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <ModalBody>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table table-bordered table-striped table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>Duties: </strong>
                                                                {trainersAcademicInvolvementRecordInModal?.duties ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Frequency per month: </strong>
                                                                {trainersAcademicInvolvementRecordInModal?.frequency_or_week ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={researchProjectConductedByTheDeptDeleteModalShow}
                                    scrollable={true}
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Delete Record of Department and Service
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setResearchProjectConductedByTheDeptDeleteModalShow(!researchProjectConductedByTheDeptDeleteModalShow);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        {researchProjectConductedByTheDeptForDelete && (
                                            <Row>
                                                <Col>
                                                    <p>
                                                        Are you sure you want to delete the research project conducted by the department record? {' '}
                                                    </p>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setResearchProjectConductedByTheDeptDeleteModalShow(false);
                                            }}
                                            className="btn btn-secondary me-2"
                                            data-dismiss="modal"
                                        >
                                            No
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={(e) => {
                                                setResearchProjectConductedByTheDeptDeleteEntry(true);
                                            }}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={recordOfInsPublicationsDeleteModalShow}
                                    scrollable={true}
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Delete Record of Publication
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setRecordOfInsPublicationsDeleteModalShow(!recordOfInsPublicationsDeleteModalShow);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        {recordOfInsPublicationsDeleteModalShow && (
                                            <Row>
                                                <Col>
                                                    <p>
                                                        Are you sure you want to delete the books / journals / periodicals record? {' '}
                                                    </p>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setRecordOfInsPublicationsDeleteModalShow(false);
                                            }}
                                            className="btn btn-secondary me-2"
                                            data-dismiss="modal"
                                        >
                                            No
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={(e) => {
                                                setRecordOfInsPublicationsDeleteEntry(true);
                                            }}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={recordOfTraineeWorkAttachmentDeleteModalShow}
                                    scrollable={true}
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Delete Record of Trainees' Clinical/Laboratory work attachment
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setRecordOfTraineeWorkAttachmentDeleteModalShow(!recordOfTraineeWorkAttachmentDeleteModalShow);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        {recordOfTraineeWorkAttachmentDeleteModalShow && (
                                            <Row>
                                                <Col>
                                                    <p>
                                                        Are you sure you want to delete trainee's Clinical/Laboratory work attachment record? {' '}
                                                    </p>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setRecordOfTraineeWorkAttachmentDeleteModalShow(false);
                                            }}
                                            className="btn btn-secondary me-2"
                                            data-dismiss="modal"
                                        >
                                            No
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={(e) => {
                                                setRecordOfTraineeWorkAttachmentDeleteEntry(true);
                                            }}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={recordOfTrainersAcademicInvolvementDeleteModalShow}
                                    scrollable={true}
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Delete Record of Trainers' Academic Involvement
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setRecordOfTrainersAcademicInvolvementDeleteModalShow(!recordOfTrainersAcademicInvolvementDeleteModalShow);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        {recordOfTrainersAcademicInvolvementDeleteModalShow && (
                                            <Row>
                                                <Col>
                                                    <p>
                                                        Are you sure you want to delete the trainer academic involvement record? {' '}
                                                    </p>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setRecordOfTrainersAcademicInvolvementDeleteModalShow(false);
                                            }}
                                            className="btn btn-secondary me-2"
                                            data-dismiss="modal"
                                        >
                                            No
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={(e) => {
                                                setRecordOfTrainersAcademicInvolvementDeleteEntry(true);
                                            }}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </Modal>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container >
        </div >
    );
};

export default InstituteDeptRegistrationStepSix;
