import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { InputField } from 'src/components';
import DisplayImage from 'src/components/Common/displayImage';
import { config } from 'src/config';
import { useGetLeaveApplicationApprovalMutation } from 'src/rtk/features/leaveManagement/leaveManagementApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

interface Props {
  isOpen: boolean;
  toggle: any;
  userInfo: any;
  id: string;
}

const initialValues: RegApprove = {
  registration_no: '',
  status: '',
  message: '',
  type: '',
  registration_type: '',
};

const LeaveApprovalViewModal = (props: Props) => {
  const { isOpen, toggle, userInfo, id } = props;

  const [getLeaveApplicationApproval, { isSuccess, isError, error, isLoading }] = useGetLeaveApplicationApprovalMutation();

  const {
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    handleChange,
    getFieldProps,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      message: Yup.string().when('status', (status, schema) => {
        if (status === 'Correction') {
          return schema.required('Correction note is Required!');
        }
        return schema;
      }),
    }),
    onSubmit: (values) => {

      if (values.status === 'Approve') {
        const approveUrl = `${userInfo.id}/${values.status}`;
        getLeaveApplicationApproval(approveUrl);
      }

      if (values.status === 'Reject') {
        const rejectUrl = `${userInfo.id}/${values.status}?note=${values.message}&email=${userInfo?.registration?.personal_info?.email}`;
        getLeaveApplicationApproval(rejectUrl);
      }
    },
  });

  const err = error as CustomError;

  useEffect(() => {
    if (isSuccess) {
      toast.success('Leave Approval Status Updated Successfully');
      isOpen && toggle();
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
    }
  }, [isSuccess, isError]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="lg">
      {isLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <ModalHeader toggle={toggle}>Applicant View</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <ModalBody>
          <div>
            <h6 className="block-heading">Personal Information</h6>
            <div className="row">
              <div className="col-md-9">
                <table className="table table-sm table-bordered table-striped">
                  <tbody>
                    <tr>
                      <td className='col-md-6'>
                        <strong>Name: </strong>
                        <span>{userInfo?.registration?.personal_info?.full_name}</span>
                      </td>

                      <td className='col-md-6'>
                        <strong>Father's Name: </strong>
                        <span>
                          {userInfo?.registration?.personal_info?.fathers_name ?? 'N/A'}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Mother's Name: </strong>
                        <span>
                          {userInfo?.registration?.personal_info?.mothers_name ?? 'N/A'}
                        </span>
                      </td>
                      <td>
                        <strong>Date of Birth: </strong>
                        <span>
                          {userInfo?.registration?.personal_info?.date_of_birth ?? 'N/A'}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>NID: </strong>
                        <span>
                          {userInfo?.registration?.personal_info?.national_id ?? 'N/A'}
                        </span>
                      </td>

                      <td>
                        <strong>Mobile Number: </strong>
                        <span>{userInfo?.registration?.personal_info?.mobile ?? 'N/A'}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Email: </strong>
                        <span>{userInfo?.registration?.personal_info?.email ?? 'N/A'}</span>
                      </td>

                      <td>
                        <strong>Alt Number: </strong>
                        <span>{userInfo?.registration?.personal_info?.telephone ?? 'N/A'}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-3 col-sm-12 text-center">
                {/* <div className="img-fluid img-rounded">
                  <img
                    src={
                      userInfo?.registration?.personal_info?.picture
                        ? `${config?.APP_URL}${userInfo?.registration?.personal_info?.picture}`
                        : avatar
                    }
                    width="120"
                  />
                </div> */}
                <DisplayImage
                  className="img-thumbnail profile-img-width"
                  src={`${config?.APP_URL}${userInfo?.registration?.personal_info?.picture}`}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-9'>
                <h6 className="block-heading">Leave Record</h6>
                <table className="table table-sm table-bordered table-striped">
                  <tbody>
                    <tr>
                      <td className='col-md-6'>
                        <strong>Leave Type: </strong>
                        <span>{userInfo?.leave_type}</span>
                      </td>

                      <td className='col-md-6'>
                        <strong>Reason: </strong>
                        <span>
                          {userInfo?.leave_reason ?? 'N/A'}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>From Date: </strong>
                        <span>
                          {userInfo?.start_date ?? 'N/A'}
                        </span>
                      </td>
                      <td>
                        <strong>To Date: </strong>
                        <span>
                          {userInfo?.end_date ?? 'N/A'}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>No. Of Days : </strong>
                        <span>
                          {userInfo?.no_of_days ?? 'N/A'}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {userInfo?.approved_status === 'Pending' ?
              <div>
                <h6 className="block-heading">Leave Approval</h6>
                <table className="table table-sm table-bordered table-striped">
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <FormGroup check inline className="mb-3 ">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="status"
                              id="approve"
                              onChange={(e) => {
                                setFieldValue('status', 'Approve');
                              }}
                            />
                            <label
                              className="form-check-label text-success"
                              htmlFor="approve"
                            >
                              Approve
                            </label>
                          </FormGroup>

                          <FormGroup check inline className="mb-3 ">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="status"
                              id="reject"
                              onChange={(e) => {
                                setFieldValue('status', 'Reject');
                              }}
                            />
                            <label
                              className="form-check-label text-danger"
                              htmlFor="reject"
                            >
                              Reject
                            </label>
                          </FormGroup>
                        </div>
                      </td>
                    </tr>
                    {values.status && <tr>
                      <td>
                        <Label>{values.status} Note</Label>
                        <InputField
                          type="textarea"
                          placeholder='Enter Note'
                          invalid={{ errors, touched }}
                          {...getFieldProps('message')}
                        />
                      </td>
                    </tr>}
                  </tbody>
                </table>
              </div>
              :
              <div>
                <h6 className="block-heading">Leave Approval</h6>
                <span><strong>Approval Status : </strong>{userInfo?.approved_status}</span>
              </div>
            }

          </div>
        </ModalBody>

        <ModalFooter>
          <div className="d-flex gap-2 ">
            {userInfo?.approved_status === 'Pending' &&
              <button type="submit" className="btn btn-primary">
                Save
              </button>}
            <Button color="danger" onClick={toggle}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default LeaveApprovalViewModal;
