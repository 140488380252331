import moment from 'moment';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table
} from 'reactstrap';

interface Props {
  isOpen: boolean;
  toggle: any;
  checkData: RecomendationForm[];
  deparmentName: string;
  instituteName: string;
}

const MembersRecommendedDataModal = (props: Props) => {
  const { isOpen, toggle, checkData, instituteName, deparmentName } = props;

  console.log(checkData);
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="lg">
      <ModalHeader toggle={toggle}>Member Recomendation</ModalHeader>

      <ModalBody>
        {checkData?.map((item: RecomendationForm) => (
          <>
            <Table className='table-bordered table-sm' key={item.institute_id}>
              <tbody>
                <tr className="bg-light text-dark text-center">
                  <th colSpan={4}>Recomended By {item?.recommender?.name}</th>
                </tr>
                <tr>
                  <td>
                    <strong>Name Of Institute :</strong>
                  </td>
                  <td>
                    {instituteName}
                  </td>
                  <td>
                    <strong>Name Of Department: </strong>
                  </td>
                  <td>
                    {deparmentName}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Previous Status Of Recognition: </strong>
                  </td>
                  <td>
                    {item?.previous_recognition_status}
                  </td>
                  <td>
                    <strong>Period of training to be accepted: </strong>
                  </td>
                  <td>
                    {item?.period_of_accepted_training_in_year + ' Year ' + item?.period_of_accepted_training_in_month + ' Months'}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Period of Recognition : </strong>
                  </td>
                  <td>
                    {item?.period_of_recognition}
                  </td>
                  <td>
                    <strong>Strating Date of Recognition: </strong>
                  </td>
                  <td>
                    {moment(item?.starting_date_of_recognition).format("DD-MMM-YYYY")}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Valid Up to: </strong>
                  </td>
                  <td>
                    {moment(item?.validity_date_of_recognition ).format("DD-MMM-YYYY")}
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table className='table-bordered table-sm' style={{ width: '525px' }}>
              <tbody>
                <tr>
                  <td>
                    <strong>Overall Patient load:</strong>
                  </td>
                  <td>{item?.overall_patient_load}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Inpatient:</strong>
                  </td>
                  <td>{item?.in_patient}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Outpatient:</strong>
                  </td>
                  <td>{item?.out_patient}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Practical work load:</strong>
                  </td>
                  <td>
                    {item?.practical_workload}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Availability of investigative services:</strong>
                  </td>
                  <td>
                    {item?.availibility_of_investigative_service}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Availability of other support services:</strong>
                  </td>
                  <td>
                    {item?.availibility_of_other_support_service}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Number and status of trainer:</strong>
                  </td>
                  <td>
                    {item?.number_and_status_of_trainer}
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        ))}
      </ModalBody>

      <ModalFooter>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <Button color="danger" onClick={toggle}>
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default MembersRecommendedDataModal;
