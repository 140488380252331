import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import { useDeleteDeviceRegInfoMutation } from 'src/rtk/features/device-registration/deviceRegistrationApi';

interface Props {
  deviceRegInfo: DeviceInfo;
  editHandler: any;
  index: number;
}

const DeviceRegTableRow = ({ deviceRegInfo, editHandler, index }: Props) => {
  const history = useHistory();

  const [infoId, setInfoId] = useState('');
  const [modalShow, setModalShow] = useState(false);
  
  const [deleteDeviceRegInfo, { isSuccess, isLoading, isError }] =
    useDeleteDeviceRegInfoMutation();

  const deleteHandler = (modalState: boolean, id: string) => {
    setModalShow(modalState);
    setInfoId(id);
  };

  return (
    <tr key={deviceRegInfo.id}>
      <th scope="row">{index + 1}</th>
      <td>{deviceRegInfo.device_type}</td>
      <td>{deviceRegInfo.manufacturer_name}</td>
      <td>{deviceRegInfo.mac_address}</td>
      <td>{deviceRegInfo.approval_status}</td>
      <td>
        <div className="d-flex gap-2">
          {/*<Button*/}
          {/*  size="sm"*/}
          {/*  text="Edit"*/}
          {/*  color="primary"*/}
          {/*  disabled={deviceRegInfo.submit_status === 'Submit'}*/}
          {/*  onClick={() =>*/}
          {/*    editHandler(deviceRegInfo.id!)*/}
          {/*  }*/}
          {/*/>*/}

          <Button
            size="sm"
            text="Delete"
            color="danger"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => deleteHandler(true, deviceRegInfo.id!)}
          />
        </div>
      </td>
      {modalShow && infoId === deviceRegInfo?.id ? (
        <DeleteInformationModal
          isOpen={modalShow}
          toggle={() => setModalShow(!modalShow)}
          id={infoId}
          deleteInfomation={deleteDeviceRegInfo}
        />
      ) : null}
    </tr>
  );
};

export default DeviceRegTableRow;
