import moment from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Button, Col, Modal, Row } from "reactstrap";
import * as url from 'src/helpers/url_helper';
import {
    useCreateByPostFormCommentsMutation,
    useGetCommentsDataByMutation,
} from 'src/rtk/features/disertation-thesis/crudApi';

export const CommentsModal = (props: any) => {

    const {
        thesisDissertationFile,
        thesisDissertationFormSetupId,
        thesisDissertationFormSetupColumnName,
        showModal,
        setShowModal,
        messages,
        setMessages,
    } = props;

    // const [messages, setMessages] = useState<any>([]);
    const [comment, setComment] = useState<any>({});

    const [
        createByPostComment,
        {
            isSuccess: isCommentSuccess,
            isLoading: isCommentLoading,
            isError: isCommentError,
            error: commentError,
        },
    ] = useCreateByPostFormCommentsMutation();

    const [
        commentsDataList,
        {
            isLoading: commentsDataListLoading,
            isSuccess: commentsDataListLoadingSuccess,
            error: commentsDataListLoadingError,
        },
    ] = useGetCommentsDataByMutation();

    const getCommentsData = (thesis_dissertation_id: string, column_id: string) => {
        commentsDataList(`${url.THESIS_DISSERTATION_ENTRY_COMMENT_LIST}/${thesis_dissertation_id}/${column_id}`).then(function (result: any,) {
            // here you can use the result of promiseB
            if (result?.data?.data) {
                setMessages(result?.data?.data);
            }
        });

        // setMessage(commentsData?.data || []);
    };

    useEffect(() => {
        if (isCommentSuccess) {
            getCommentsData(thesisDissertationFile?.id, thesisDissertationFormSetupId);
            toast.dismiss();
        }
        if (isCommentError) {
            toast.dismiss();
            // toast.error('Something went wrong');
        }
    }, [isCommentLoading, isCommentSuccess, isCommentError]);

    // useEffect(() => {
    //     getCommentsData(thesisDissertationFile?.id, thesisDissertationFormSetupId);
    // }, []);

    return (
        <Modal
            onOpened={function () {
                toast.dismiss();
            }}
            isOpen={showModal}
            scrollable={true}
            size="md"
            toggle={() => {
                document.body.classList.add('no_padding');
            }}
            backdrop={'static'}
            modalTransition={{ timeout: 0 }}
            backdropTransition={{ timeout: 0 }}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                    Comments for {thesisDissertationFormSetupColumnName}
                </h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                        setShowModal(!showModal);
                    }}
                    aria-label="Close"
                ></button>
            </div>
            <div className="modal-body">
                <Row>
                    <Col sm={12}>
                        <div className="border rounded">
                            <div
                                className="chat-conversation p-3 px-2"
                                style={{
                                    height: '425px',
                                    overflow:
                                        'auto',
                                }}
                            >
                                <ul className="list-unstyled">
                                    {messages &&
                                        messages.map(
                                            (
                                                message: any,
                                            ) => {
                                                return (
                                                    <li
                                                        key={'test_k' + message.id}
                                                        className={'left'}
                                                    >
                                                        <div className="conversation-list">
                                                            <div className="ctext-wrap-content">
                                                                <h5 className="conversation-name">
                                                                    <div className="user-name me-2 text-white">
                                                                        {
                                                                            message.name
                                                                        }
                                                                    </div>
                                                                    <div className="user-name text-white">
                                                                        ({
                                                                            message.type
                                                                        })
                                                                    </div>
                                                                    <span className="time">
                                                                        {moment(message.updated_at,).calendar()}
                                                                    </span>
                                                                </h5>

                                                                <p className="mb-0" dangerouslySetInnerHTML={{ __html: message.comments }}>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            },
                                        )}
                                </ul>
                            </div>
                            <div className="p-3 border-top">
                                <Row>
                                    <Col>
                                        <div className="position-relative">
                                            <input
                                                type="textarea"
                                                name={'comment'}
                                                value={comment[thesisDissertationFormSetupColumnName] || ''}
                                                onChange={(e: any, key: any = thesisDissertationFormSetupColumnName,) => {
                                                    let message = e.target.value || '';

                                                    let newComment = {
                                                        ...comment,
                                                        [key]: message,
                                                    };


                                                    setComment(newComment,);
                                                }}
                                                className="form-control border bg-soft-light"
                                                placeholder={'Enter comment for ' + thesisDissertationFormSetupColumnName}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="col-auto">
                                        <Button
                                            type="button"
                                            color="primary"
                                            onClick={() => {
                                                if (comment[thesisDissertationFormSetupColumnName]) {

                                                    let postCommentData =
                                                    {
                                                        queryUrl: url.THESIS_DISSERTATION_ENTRY_COMMENT_SUBMIT,
                                                        formData:
                                                        {
                                                            thesis_dissertation_id: thesisDissertationFile?.id,
                                                            colum_id: thesisDissertationFormSetupId,
                                                            comments: comment[thesisDissertationFormSetupColumnName] || '',
                                                            column_name: thesisDissertationFormSetupColumnName,
                                                        },
                                                    };

                                                    createByPostComment(postCommentData,).then(
                                                        (
                                                            res: any,
                                                        ) => {
                                                            if (
                                                                res.data?.success
                                                            ) {
                                                                comment[thesisDissertationFormSetupColumnName];

                                                                let newComment =
                                                                {
                                                                    ...comment,
                                                                    [thesisDissertationFormSetupColumnName]: "",
                                                                };

                                                                setComment(newComment,);

                                                            }
                                                        },
                                                    );
                                                }
                                            }}
                                            className="btn btn-success chat-send w-md"
                                            disabled={
                                                isCommentLoading
                                            }
                                        >
                                            <span className="d-none d-sm-inline-block me-2">
                                                {isCommentLoading
                                                    ? 'Sending...'
                                                    : 'Send'}
                                            </span>{' '}
                                            <i className="mdi mdi-send float-end" />
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
};