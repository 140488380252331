import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { putUrlString } from 'src/helpers';
import { useGetLogbookEntrySetupDetailsQuery } from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import { useGetLogbookEntrySetupDetailsRecordQuery, useUpdateLogbookEntrySetupDetailsRecordMutation } from 'src/rtk/features/setup/logBookEntrySetupDetailsRecord/logBookEntrySetupDetailsRecordApi';
import * as Yup from 'yup';

const initialValues: LogbookEntrySetupDetailsRecord = {
    id: '',
    logbook_entry_setup_detail_id: '',
    details_text: '',
    details_json: '',
    status: true,
    creator_organization_id: '',
    updater_organization_id: '',
    created_by: '',
    updated_by: '',
    deleted_by: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
};

export const EditLogbookEntrySetupDetailsRecord = () => {
  const { id } = useParams<{ id: string }>();
  const { data: logbookEntrySetupDetailsRecord, isError: isLogbookEntrySetupDetailsRecordFetchError } = useGetLogbookEntrySetupDetailsRecordQuery(id);
  const [updateLogbookEntrySetupDetailsRecord, { isSuccess, isError, error }] = useUpdateLogbookEntrySetupDetailsRecordMutation();

  const { data: logbookEntrySetupDetailsData } = useGetLogbookEntrySetupDetailsQuery();
  const logbookEntrySetupDetails = logbookEntrySetupDetailsData?.data || [];

  const err: CustomError = error as CustomError;
  const history = useHistory();
  const {
    handleSubmit,
    getFieldProps,
    values,
    touched,
    errors,
    setValues,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
        logbook_entry_setup_detail_id: Yup.string().required('Please select the logbook entry setup detail'),        
    }),

    onSubmit: (values) => {
      const url = putUrlString(id, values);
      updateLogbookEntrySetupDetailsRecord(url);
    },
  });

  useEffect(() => {
    if (logbookEntrySetupDetailsRecord && !isLogbookEntrySetupDetailsRecordFetchError) {
      const data: LogbookEntrySetupDetailsRecord = (logbookEntrySetupDetailsRecord as any).data;
      setValues({
        id: data.id,
        logbook_entry_setup_detail_id: data.logbook_entry_setup_detail_id,
        details_text: data.details_text,
        details_json: data.details_json,
        status: data.status,
        creator_organization_id: data.creator_organization_id,
        updater_organization_id: data.updater_organization_id,
        created_by: data.created_by,
        updated_by: data.updated_by,
        deleted_by: data.deleted_by,
        created_at: data.created_at,
        updated_at: data.updated_at,
        deleted_at: data.deleted_at,
      });
    }
  }, [logbookEntrySetupDetailsRecord, isLogbookEntrySetupDetailsRecordFetchError]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Logbook entry setup details record updated successfully');
      history.push('/setup/logbook-entry-setup-details-record');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      const errors: any = {};

      if (err?.data) {
        Object.keys(err?.data).forEach((key) => {
          errors[key] = err?.data[key][0];
        });
      }
      setErrors(errors);
    }
  }, [isSuccess, isError]);

  let logbookEntrySetupDetailsOptions: SelectOption[] = [    
    ...logbookEntrySetupDetails?.map((l: LogbookEntryDetails) => ({
      label: l.details_text!,
      value: l.id!,
    })),
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Edit Logbook Entry Setup Details Record'}
          breadcrumbItem={[{ link: '/setup/logbook-entry-setup-details-record/', name: 'Logbook Entry Setup Details Record' }]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="card mt-3">
            <h5 className="card-header">
              <i className="fa fa-edit me-2" />
              Edit Logbook Entry Setup Details Record
            </h5>
            <div className="card-body">
              <Form
                className="custom-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="row">                  
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="logbook_entry_setup_detail_id">Logbook Entry Setup Detail</Label>
                      <InputField
                        type="select"
                        placeholder="Logbook Entry Setup Detail"
                        options={logbookEntrySetupDetailsOptions}
                        invalid={{ errors, touched }}
                        {...getFieldProps('logbook_entry_setup_detail_id')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="details_text">Details Text</Label>
                      <InputField
                        type="textarea"
                        placeholder="Details Text"
                        invalid={{ errors, touched }}
                        {...getFieldProps('details_text')}
                      />
                    </FormGroup>
                  </div>

                  {/* <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="description">Description</Label>
                      <InputField
                        type="textarea"
                        placeholder="Description"
                        invalid={{ errors, touched }}
                        {...getFieldProps('description')}
                      />
                    </FormGroup>
                  </div> */}                 

                  {/* <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="setup_logbook_essential_info_id">Logbook Essential Info Name</Label>
                      <InputField
                        type="select"
                        placeholder="Logbook Essential Info"
                        options={logbookEssentialInfoOptions}
                        invalid={{ errors, touched }}
                        {...getFieldProps('setup_logbook_essential_info_id')}
                      />
                    </FormGroup>
                  </div> */}

                  {/* <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="sl_no">Sl No</Label>
                      <InputField
                        type="number"
                        placeholder="Sl No"
                        invalid={{ errors, touched }}
                        {...getFieldProps('sl_no')}
                      />
                    </FormGroup>
                  </div>                   */}

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup check className="mb-3 mt-4">
                      <Label check>
                        <Input type="checkbox" checked={values.status} {...getFieldProps('status')} />{' '}
                        Status
                      </Label>
                    </FormGroup>
                  </div>
                </div>
                <Button type="submit" text="Submit" />
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
