import { useEffect, useState } from 'react';
import { Col, Collapse, Row, Tooltip } from 'reactstrap';
import RecursiveCompetencyItem from './RecursiveCompetencyItem';

const UpdatedCompetencyGroupAndDetails = (props: any) => {
    const { formik, caseTypeName, skillTypeName, compentencyGroup, competenceLevelOptions } = props;
    const [isFirstOpen, setIsFirstOpen] = useState(true);
    const [isSecondOpen, setIsSecondOpen] = useState(true);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [finalFormikProp, setFinalFormikProp] = useState<any>([]);
    const [checkedComIds, setCheckedComIds] = useState<string[]>([]);
    const [tooltipState, setTooltipState] = useState<boolean>(false);

    const firstToggle = () => setIsFirstOpen(!isFirstOpen);

    const secondToggle = () => setIsSecondOpen(!isSecondOpen);

    const tooltipToggle = () => setTooltipState(!tooltipState);   
    
    const styleObj: any = {
        tooltipStyling: {
            maxWidth: 'none'
        }
    };

    useEffect(() => {
        // console.log('initial competency group list assigned');
        formik.values['competency_group'] = [];
    }, []);

    useEffect(() => {
        // console.log(formik.values['competency_group']);
        formik.values['competency_group'] = finalFormikProp;
    }, [finalFormikProp]);

    // useEffect(() => {
    //   compentencyGroup
    //   .filter((competency: any, index: number) => !competency.particulars.toLowerCase().includes(searchTerm.toLowerCase()))
    //   .forEach((competency: any, index: number) => {
    //     const checkIndex = checkedComIds.indexOf(competency.id);
    //     if (checkIndex !== -1) {
    //       const arr = [...checkedComIds];
    //       arr.splice(checkIndex, 1);
    //       setCheckedComIds(arr);
    //     }
    //   })
    // }, [searchTerm]);

    return (
        <>
            <hr />
            <Row className='justify-content-between'>
                <Col sm={4}>
                    <form className="">
                        <div className="position-relative input-group competency-search-form">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search Competency Group List..."
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                }}
                            />
                            <button className="btn btn-secondary" type="button">
                                <i className="bx bx-search-alt align-middle"></i>
                            </button>
                        </div>
                    </form>
                </Col>
                <Col sm={1} className='mt-2'>
                    <span id='infoTooltip'>
                        <i className="fa-solid fa-2x fa-circle-info"></i>
                    </span>
                    <Tooltip
                        placement={'bottom'}
                        isOpen={tooltipState}
                        target={'infoTooltip'}
                        toggle={tooltipToggle}
                        style={styleObj.tooltipStyling}
                    >
                        <ul className='list-unstyled'>
                            <li>
                                <h5 className='text-light'>Patient care competence</h5>
                                <ul className='list-unstyled my-3'>
                                    {
                                        competenceLevelOptions?.[0]?.childs?.map((option: any, index: number) => {
                                            return <li key={option.id}><strong>{option.name}</strong> ({option.childs[0]?.name})</li>
                                        })
                                    }
                                </ul>
                            </li>
                            <li>
                                <h5 className='text-light'>Procedural/Surgical skill</h5>

                                <ul className='list-unstyled my-3'>
                                    {
                                        competenceLevelOptions?.[1]?.childs?.map((option: any, index: number) => {
                                            return <li key={option.id}><strong>{option.name}</strong> ({option.childs[0]?.name})</li>
                                        })
                                    }
                                </ul>
                            </li>
                        </ul>
                    </Tooltip>
                </Col>
            </Row>
            <Row className='py-3'>
                <Col sm={12}>
                    <div className='collapse-wrapper'>
                        <div className='collapse-title' onClick={firstToggle}>
                            {isFirstOpen ? <i className="fa fa-minus me-2" /> : <i className="fa fa-plus me-2" />}
                            {caseTypeName ?? ''}
                        </div>
                        <Collapse isOpen={isFirstOpen}>
                            <div className='collapse-title' onClick={secondToggle}>
                                {isSecondOpen ? <i className="fa fa-minus me-2" /> : <i className="fa fa-plus me-2" />} {skillTypeName ?? ''}
                            </div>
                            <Collapse isOpen={isSecondOpen}>
                                <RecursiveCompetencyItem
                                    competencyGroup={compentencyGroup}
                                    searchTerm={searchTerm}
                                    skillTypeName={skillTypeName}
                                    formik={formik}
                                    finalFormikProp={finalFormikProp}
                                    setFinalFormikProp={setFinalFormikProp}
                                    competenceLevelOptions={competenceLevelOptions}
                                    checkedComIds={checkedComIds}
                                    setCheckedComIds={setCheckedComIds}
                                />
                            </Collapse>
                        </Collapse>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default UpdatedCompetencyGroupAndDetails;
