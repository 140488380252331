interface LayoutState {
    layoutType: 'horizontal' | 'vertical';
    layoutWidth: 'fluid' | 'boxed';
    leftSideBarTheme: 'light' | 'brand' | 'dark';
    leftSideBarType: 'lg' | 'md' | 'sm';
    layoutMode: 'light' | 'dark';
    layoutPosition: 'true' | 'false';
    topbarTheme: 'light' | 'dark';
    isPreloader: boolean;
    showRightSidebar: boolean;
    isMobile: boolean;
    showSidebar: boolean;
    leftMenu: boolean;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: LayoutState = {
    layoutType: 'vertical',
    layoutWidth: 'fluid',
    leftSideBarTheme: 'dark',
    leftSideBarType: 'lg',
    layoutMode: 'light',
    layoutPosition: 'true',
    topbarTheme: 'light',
    isPreloader: false,
    showRightSidebar: false,
    isMobile: false,
    showSidebar: true,
    leftMenu: false,
};

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        changeLayout: (state, action) => {
            state.layoutType = action.payload;
        },
        changePreloader: (state, action) => {
            state.isPreloader = action.payload;
        },
        changeLayoutWidth: (state, action) => {
            state.layoutWidth = action.payload;
        },
        changeSidebarTheme: (state, action) => {
            state.leftSideBarTheme = action.payload;
        },
        changeSidebarType: (state, action) => {
            state.leftSideBarType = action.payload;
        },
        changeTopbarTheme: (state, action) => {
            state.topbarTheme = action.payload;
        },
        changeLayoutPosition: (state, action) => {
            state.layoutPosition = action.payload;
        },
        changeLayoutMode: (
            state,
            action: PayloadAction<{
                layoutMode: any;
                layoutType: any;
            }>
        ) => {
            state.layoutMode = action.payload.layoutMode;
        },
        showRightSidebarAction: (state, action) => {
            state.showRightSidebar = action.payload;
        },
        showSidebar: (state, action) => {
            state.showSidebar = action.payload;
        },
        toggleLeftmenu: (state, action) => {
            state.leftMenu = action.payload;
        },
    },
});

export default layoutSlice.reducer;
export const {
    changeLayout,
    changePreloader,
    changeLayoutMode,
    changeLayoutPosition,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    changeTopbarTheme,
    showRightSidebarAction,
    showSidebar,
    toggleLeftmenu,
} = layoutSlice.actions;
