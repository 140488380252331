import { Container } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import formObj from './formObj';

import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { ConfirmToast } from 'react-confirm-toast';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  Row,
} from 'reactstrap';
import {
  Button,
  DaynamicInputField,
  DaynamicSearchInputField,
  DynamicView,
} from 'src/components';
import { config } from 'src/config';
import { putUrlString } from 'src/helpers';
import {
  useCreateByPostMutation,
  useGetDataByMutation,
  useGetDataQuery
} from 'src/rtk/features/common/crudApi';


const AssignExaminerForThesis = () => {
  if (formObj?.title) {
    document.title = formObj?.title + ' | BCPS';
  }

  const history = useHistory();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(true);
  const [viewData, setViewData] = useState<any>({});
  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');
  const [submitFormType, setSubmitFormType] = useState('Examinner');
  const generateQueryUrl = () => {
    return `${formObj.listAPIUrl}?category=Thesis&page=${page}&paginate_per_page=${limit}${search}`;
  };

  const {
    data: dataList,
    isLoading: dataListLoading,
    isSuccess: dataListLoadingSuccess,
    error: dataListLoadingError,
  } = useGetDataQuery(generateQueryUrl());
  const data: any = dataList ? dataList : [];

  useEffect(() => {
    if (dataListLoadingError) {
      toast.dismiss();
      toast.error('Data Loading error. Please try again later.');
    } else if (dataListLoading || !dataListLoadingSuccess) {
      toast.dismiss();
      // toast.loading('Data Loading...');
    } else {
      toast.dismiss();
      // toast.success('Data Load Successfully');
    }
  }, [dataListLoading, dataListLoadingSuccess, dataListLoadingError]);


  let initialValues: any = {};
  let validationObj: any = {};
  let inputFields: any = [];
  let formFilds = formObj?.formFields || {};
  Object.keys(formFilds)
    .sort(function (a: any, b: any) {
      return parseInt(a.positionInForm) - parseInt(b.positionInForm);
    })
    .map((key) => {
      if (formFilds[key]['showInForm']) {
        let field = formFilds[key];
        inputFields.push(formFilds[key]);

        if (
          field['type'] === 'checkbox' &&
          typeof field['default'] === 'string'
        ) {
          field['default'] = [field['default'].trim()];
        } else if (
          field['type'] === 'radio' &&
          typeof field['default'] === 'object'
        ) {
          field['default'] = field['default'].join(' ').trim();
        } else if (field['type'] === 'date') {
          field['default'] = field?.options?.defaultDate || '';
        }

        if (field['isRequired']) {
          validationObj[field['name']] = field['validation'];
        }

        initialValues[field['name']] = field['default'];
      }
    });

  const [deleteByID, { isSuccess, isLoading, isError, error }] =
    useCreateByPostMutation();
  const err: CustomError = error as CustomError;

  useEffect(() => {
    if (isSuccess) {
      toast.success('Thesis/Dissertation successfully sent to library');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
    }
  }, [isSuccess, isError]);


  let initialSearchValues: any = {};
  let inputSearchFields: any = [];
  let tableColumn: any = [];
  let viewColumn: any = [];
  let searchFormFilds = formObj?.searchFormFields || {};


  Object.keys(searchFormFilds)
    .sort(function (a: any, b: any) {
      return (
        parseInt(searchFormFilds[a].positionInForm) -
        parseInt(searchFormFilds[b].positionInForm)
      );
    })
    .map((key) => {
      let searchFormField = searchFormFilds[key];

      if (searchFormField['viewCell']) {
        viewColumn.push({
          name: searchFormField['label'],
          viewCell: searchFormField['viewCell'],
        });
      }

      if (searchFormField['cell']) {
        tableColumn.push({
          name: <div>{searchFormField['label']}</div>,
          cell: searchFormField['cell'],
          width: searchFormField['cellWidth'] ?? "110px",

        });
      }

      if (formObj?.showSearchForm && searchFormFilds[key]['showInSearch']) {
        inputSearchFields.push(searchFormFilds[key]);

        if (
          searchFormField['type'] === 'checkbox' &&
          typeof searchFormField['default'] === 'string'
        ) {
          searchFormField['default'] = [searchFormField['default'].trim()];
        } else if (
          searchFormField['type'] === 'radio' &&
          typeof searchFormField['default'] === 'object'
        ) {
          searchFormField['default'] = searchFormField['default']
            .join(' ')
            .trim();
        } else if (searchFormField['type'] === 'date') {
          searchFormField['default'] =
            searchFormField?.options?.defaultDate || '';
        }

        // initialSearchValues[searchFormField['name']] = '';
      }
    });

  let columns: TableColumn<any>[] = [
    {
      name: '#',
      width: '50px',
      cell: (row, index: any) => (data.from ? data.from + index : index + 1),
    },
    ...tableColumn,
  ];

  if (formObj?.showTableActionColumn) {
    columns.push({
      name: 'Action',
      width: '275px',
      selector: (row: any) => row.id,
      cell: (row: any, index: any) => (
        <div className="d-flex flex-wrap gap-2 py-1">
          <a
            href={config.APP_URL + row?.document_name}
            rel='noreferrer'
            target='_blank'
          >
            <span className='btn  btn-sm btn-primary '>
              <span className='fas fa-download'></span> {row?.document_type}
            </span>
          </a>
          <Button
            size='sm'
            className=''
            text={`<span class='fas fa-edit' ></span> Details`}
            color='warning'
            onClick={() => {
              window.open(`/thesis-entry-details-for-supervisor/${row?.id}`, '_blank');
            }}
          />
          {formObj?.showViewBtn && (
            <Button
              size='sm'
              className=''
              text={`<span class='fas fa-eye' ></span> ${formObj.viewBtnTitle}`}
              color='info'
              onClick={() => {
                approvalResetForm();
                setShowModal(true);
                setSubmitFormType('view');
                setViewData(row);
              }}
            />
          )}

          {/* examiner  */}

          <Button
            size='sm'
            className=''
            text={`<span class='fas fa-edit' ></span> Assign Examiner`}
            color='success'
            onClick={() => {
              // approvalResetForm();
              setShowModal(true);
              setSubmitFormType('Examinner');
              // setViewData(row);
              setViewData(row);
            }}
          />

          {/* <Button
            size="sm"
            className=""
            text={`<span class="fas fa-eye" ></span> Examinner`}
            color="success"
            onClick={() => {
              approvalResetForm();
              setShowModal(true);
              setSubmitFormType('Examinner');
              // setViewData(row);
              setViewData(row);
            }}
          /> */}

          {formObj?.showUpdateBtn && (
            <Button
              size='sm'
              className=''
              text={`<span class='fas fa-edit' ></span> ${formObj.updateBtnTitle}`}
              color='info'
              onClick={() => history.push(`${formObj.updateUrl}${row.id}`)}
            />
          )}

          {formObj?.showDeleteBtn && (row?.library_status === '' || row?.library_status === null) && (
            <ConfirmToast
              asModal={true}
              childrenClassName='margin-top-10'
              customCancel='No'
              customConfirm='Yes'
              customFunction={() => {

                let postData = {
                  queryUrl: `${formObj.deleteAPIUrl}${row.id}`,
                  formData: {},
                };


                deleteByID(postData);

              }
              }
              message={formObj.deletePromtMessage}
              position='top-right' //will be ignored cause asModal=true
              showCloseIcon={true}
              theme='lilac'
            >
              <Button
                size='sm'
                className=''
                text={`<span class='fas fa-send' ></span> ${formObj.deletBtnTitle}`}
                color='secondary'
              />
            </ConfirmToast>
          )}
        </div>
      ),
    });
  }

  const {
    handleSubmit: handleSearchSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    values,
    setValues,
    setFieldValue,
    touched,
    errors,
    resetForm: resetSearchForm,
    setErrors,
  } = useFormik({
    initialValues: initialSearchValues,
    onSubmit: (searchValues) => {
      let s: string = '';

      Object.keys(searchValues).map((i: any) => {
        if (searchValues[i] === '') {
          delete searchValues[i];
        }
      });

      if (searchValues?.name) {
        s = s + `&search=${searchValues.name}`;
        delete searchValues.name;
      }

      if (Object.keys(searchValues).length > 0) {
        s = s + `&` + putUrlString('', searchValues).replaceAll('?', '');
      }

      setSearch(s);
      setPage('1');
      // setLimit(20);
    },
  });

  /******* Examinner Form Start ****** */

  const [
    updateApprovalStatus,
    {
      data: approveSuccessData,
      isSuccess: isApproveSuccess,
      isLoading: isApproveLoading,
      isError: isApproveError,
    },
  ] = useGetDataByMutation();
  const approvErr: CustomError = error as CustomError;


  useEffect(() => {
    if (isApproveSuccess) {
      if (approveSuccessData?.success) {
        toast.dismiss();
        setShowModal(false);
        toast.success('Examiner assigned successfully');
      } else {
        toast.dismiss();
        toast.error(
          approveSuccessData?.message || 'Something went wrong',
          { icon: <i className="fa-solid fa-2x fa-circle-exclamation" style={{ color: '#FFD43B' }}></i> }
        );
      }
    }
    if (isApproveError) {
      if (approvErr?.data?.message) {
        toast.error(approvErr?.data?.message || 'Something went wrong');
      } else {
        toast.error(approvErr?.message || 'Something went wrong');
      }
    }
  }, [isApproveSuccess, isApproveError]);


  const [createByPost, { data: formSubmitData, isSuccess: formSubmitSuccess, isError: formSubmitError, error: formError }] =
    useCreateByPostMutation();
  const formSubError: CustomError = formError as CustomError;


  useEffect(() => {
    if (formSubmitSuccess) {
      if (formSubmitData?.success) {
        toast.dismiss();
        setShowModal(false);
        toast.success('Examiner assigned successfully');
      } else {
        toast.dismiss();
        toast.error(
          formSubmitData?.message || 'Something went wrong',
          { icon: <i className="fa-solid fa-2x fa-circle-exclamation" style={{ color: '#FFD43B' }}></i> }
        );
      }
    }
    if (formSubmitError) {
      if (formSubError?.data?.message) {
        toast.error(formSubError?.data?.message || 'Something went wrong');
      } else {
        toast.error(formSubError?.message || 'Something went wrong');
      }
    }
  }, [formSubmitSuccess, formSubmitError]);


  const {
    values: approvalValues,
    handleSubmit: approvalHandleSubmit,
    setFieldValue: approvalSetFieldValue,
    getFieldProps: approvalGetFieldProps,
    touched: approvalTouched,
    errors: approvalErrors,
    resetForm: approvalResetForm,
  } = useFormik({
    initialValues,
    // validationSchema: Yup.object({ ...validationObj }),
    onSubmit: (approvalValues) => {
      let examiners: any = [];
      approvalValues['examiners_id'].map((user: any, key: any) => {
        examiners.push({ 'examiner_id': user, 'add': 1 });
      });

      // toast.loading(submitFormType + ' Submited...');

      approvalValues['thesis_id'] = viewData['id'];
      approvalValues['examiners'] = examiners;


      let postData = {
        queryUrl: formObj['reviewerAssignAPIUrl'],
        formData: approvalValues,
      };

      console.log(approvalValues, submitFormType.toLowerCase() === 'examinner');


      createByPost(postData);


    },
  });

  const setFormValue = (key: any, value: any = '') => {
    approvalSetFieldValue(key, value);
  };

  /******* Examinner Form End ****** */

  return (
    <>
      <div className='page-content'>
        <Container fluid>
          {formObj.breadcrumbItemForList && (
            <BetterBreadcrumb
              title={formObj?.title}
              breadcrumbItem={formObj.breadcrumbItemForList}
            />
          )}

          <Card>

            <CardBody>
              <Row className='d-flex'>
                {formObj?.showSearchForm && inputSearchFields?.length > 0 && (

                  <Form
                    className='custom-form w-100'
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSearchSubmit();
                    }}
                  >
                    <div className='filter-wrapper'>
                      {inputSearchFields?.length > 0 &&
                        inputSearchFields?.map(
                          (searchField: any, key: any) => {
                            if (
                              !['hidden', 'submit'].includes(
                                searchField['type'],
                              )
                            ) {
                              searchField['isRequired'] = false;
                              searchField['default'] = '';

                              if (
                                ['radio', 'checkbox'].includes(
                                  searchField['type'],
                                )
                              ) {
                                searchField['type'] = 'select';
                              }

                              if (
                                searchField['dependField'] &&
                                searchField['dependValue'] &&
                                searchField['condition']
                              ) {
                                let isMatch = true;
                                switch (searchField['condition']) {
                                  case '===':
                                    isMatch =
                                      values[searchField['dependField']] ===
                                      searchField['dependValue'];
                                    break;

                                  case '!==':
                                    isMatch =
                                      values[searchField['dependField']] !==
                                      searchField['dependValue'];
                                    break;

                                  case 'includes':
                                    isMatch = searchField[
                                      'dependValue'
                                    ].includes(
                                      values[searchField['dependField']],
                                    );
                                    break;

                                  default:
                                    break;
                                }
                                if (!isMatch) {
                                  if (
                                    values[searchField['name']] !==
                                    searchField['default']
                                  ) {
                                    setFieldValue(
                                      searchField['name'],
                                      searchField['default'],
                                    );
                                  }
                                  return <></>;
                                }
                              }

                              return (

                                <FormGroup
                                  key={searchField['name'] + key}

                                >
                                  <>
                                    {['select'].includes(
                                      searchField['type'],
                                    ) && (
                                        <DaynamicSearchInputField
                                          label={searchField['label']}
                                          name={searchField['name']}
                                          type={searchField['type']}
                                          placeholder={'Select'}
                                          options={searchField['data']}
                                          dataApi={searchField['dataApi']}
                                          value={
                                            values[searchField['name']] !==
                                              undefined
                                              ? values[searchField['name']]
                                              : searchField['default']
                                          }
                                          isMulti={searchField['isMulti']}
                                          required={
                                            searchField['isRequired']
                                          }
                                          invalid={{ errors, touched }}
                                          onChange={(data: any) => {
                                            if (data === null) {
                                              setFieldValue(
                                                searchField['name'],
                                                '',
                                              );
                                            } else if (
                                              data &&
                                              data?.length === undefined &&
                                              data.value !== undefined
                                            ) {
                                              setFieldValue(
                                                searchField['name'],
                                                data.value,
                                              );
                                            } else if (
                                              data &&
                                              data?.length !== undefined
                                            ) {
                                              setFieldValue(
                                                searchField['name'],
                                                data.map(
                                                  (item: any) => item.value,
                                                ),
                                              );
                                            }
                                          }}
                                        />
                                      )}

                                    {['date'].includes(
                                      searchField['type'],
                                    ) && (
                                        <>
                                          <DaynamicSearchInputField
                                            label={searchField['label']}
                                            name={searchField['name']}
                                            type={searchField['type']}
                                            placeholder={''}
                                            options={searchField['options']}
                                            invalid={{ errors, touched }}
                                            value={
                                              values[
                                                searchField['name']
                                              ] !== undefined
                                                ? values[
                                                searchField['name']
                                                ]
                                                : searchField?.options
                                                  ?.defaultDate
                                            }
                                            required={
                                              searchField['isRequired']
                                            }
                                            setData={(data: any) => {
                                              if (
                                                searchField?.options
                                                  ?.mode === 'range'
                                              ) {
                                                setFieldValue(
                                                  searchField['name'],
                                                  data[0],
                                                );
                                                setFieldValue(
                                                  searchField['name2'],
                                                  data[1],
                                                );
                                              } else {
                                                setFieldValue(
                                                  searchField['name'],
                                                  data.join(',').trim(),
                                                );
                                              }
                                            }}
                                          />
                                        </>
                                      )}

                                    {['radio', 'checkbox'].includes(
                                      searchField['type'],
                                    ) && (
                                        <DaynamicSearchInputField
                                          label={searchField['label']}
                                          name={searchField['name']}
                                          type={searchField['type']}
                                          placeholder={''}
                                          options={searchField['data']}
                                          dataApi={searchField['dataApi']}
                                          value={
                                            values[searchField['name']] !==
                                              undefined
                                              ? values[searchField['name']]
                                              : searchField['default']
                                          }
                                          isMulti={searchField['isMulti']}
                                          required={
                                            searchField['isRequired']
                                          }
                                          invalid={{ errors, touched }}
                                          onChange={(data: any) => {
                                            let selectedValue =
                                              data.target.value.toString();
                                            if (
                                              searchField['type'] ===
                                              'checkbox'
                                            ) {
                                              let isChecked =
                                                data.target.checked;
                                              let oldValue =
                                                values[searchField['name']];

                                              let getIndex =
                                                oldValue.indexOf(
                                                  selectedValue,
                                                );
                                              if (
                                                isChecked &&
                                                getIndex === -1
                                              ) {
                                                oldValue.push(
                                                  selectedValue,
                                                );
                                              } else if (
                                                !isChecked &&
                                                getIndex > -1
                                              ) {
                                                oldValue.splice(
                                                  getIndex,
                                                  1,
                                                );
                                              }

                                              setFieldValue(
                                                searchField['name'],
                                                oldValue,
                                              );
                                            } else {
                                              setFieldValue(
                                                searchField['name'],
                                                selectedValue,
                                              );
                                            }
                                          }}
                                        />
                                      )}

                                    {![
                                      'select',
                                      'radio',
                                      'checkbox',
                                      'date',
                                    ].includes(searchField['type']) && (
                                        <DaynamicSearchInputField
                                          label={searchField['label']}
                                          type={searchField['type']}
                                          placeholder={searchField['label']}
                                          required={
                                            searchField['isRequired']
                                          }
                                          invalid={{ errors, touched }}
                                          {...getFieldProps(
                                            searchField['name'],
                                          )}
                                        />
                                      )}
                                  </>
                                </FormGroup>

                              );
                            }
                          },
                        )}
                      <FormGroup className='btn-column'>

                        <Button
                          color='danger'
                          type='reset'
                          text='Reset'
                          onClick={() => {
                            resetSearchForm();
                            handleSearchSubmit();
                          }}
                        />
                        <Button
                          type='button'
                          text='Search'
                          onClick={() => {
                            handleSearchSubmit();
                          }}
                        />
                        {formObj?.showCreateNewBtn && (
                          <Button
                            color='success'
                            type='button'
                            text={formObj.createNewBtnTitle || 'Create New'}
                            onClick={() => {
                              history.push(`${formObj.addUrl}`);
                              // handleSearchSubmit();
                            }}
                          />
                        )}

                      </FormGroup>
                    </div>
                  </Form>

                )}
                {formObj?.addUrl &&
                  formObj?.showCreateNewBtn &&
                  inputSearchFields.length === 0 && (
                    <Col>
                      <div className='mt-2'>
                        <Link
                          to={formObj.addUrl}
                          className='btn btn-success waves-effect waves-light'
                        >
                          <i className='fa fa-plus me-2' />
                          {formObj.createNewBtnTitle || 'Create New'}
                        </Link>
                      </div>
                    </Col>
                  )}
              </Row>
              {data?.total !== undefined && columns.length > 0 && (
                <DataTable
                  columns={columns}
                  data={data?.data ? data.data : []}
                  pagination
                  paginationServer
                  onChangePage={(page: number) => {
                    setPage(page);
                  }}
                  onChangeRowsPerPage={(newPerPage: number, page: number) => {
                    setLimit(newPerPage);
                    setPage(page);
                  }}
                  progressPending={dataListLoading}
                  paginationTotalRows={data?.total}
                  expandOnRowClicked
                  highlightOnHover
                  paginationRowsPerPageOptions={
                    config.paginationRowsPerPageOptions
                  }
                  persistTableHead
                />
              )}

              {/* {data?.total === undefined && columns.length > 0 && (
            <DataTable
              columns={columns}
              data={data}
              pagination
              progressPending={dataListLoading}
              paginationTotalRows={data?.total}
              highlightOnHover
              paginationRowsPerPageOptions={config.paginationRowsPerPageOptions}
            />
          )} */}
            </CardBody>
          </Card>
          <Modal
            onOpened={function () {
            }}
            isOpen={showModal}
            scrollable={false}
            size='xl'
            toggle={() => {
              document.body.classList.add('no_padding');
            }}
            backdrop={'static'}
            modalTransition={{ timeout: 0 }}
            backdropTransition={{ timeout: 0 }}
          >
            <Form
              className='custom-form'
              onSubmit={(e) => {
                e.preventDefault();
                approvalHandleSubmit();
              }}
              onReset={(e) => {
                e.preventDefault();
                approvalResetForm();
              }}
            >
              <div className='modal-header'>
                <h5 className='modal-title' id='staticBackdropLabel'>
                  {submitFormType} Details Info
                </h5>
                <button
                  type='button'
                  className='btn-close'
                  onClick={() => {
                    setShowModal(false);
                  }}
                  aria-label='Close'
                ></button>
              </div>
              <div className='modal-body'>
                <div style={{
                  maxHeight: submitFormType.toLowerCase() !== 'view' ? '50vh' : '',
                  overflow: 'hidden',
                  overflowY: 'auto',
                }}>
                  <DynamicView viewColumn={viewColumn} viewData={viewData} />
                </div>
                {submitFormType.toLowerCase().trim() === 'examinner' && (<>
                  <div className='align-items-center justify-content-center mt-3'>

                    <div className='card '>
                      <div className='card-header text-center fw-bold bg-light'>Assign Examiner For Thesis</div>
                      <div className='card-body'>
                        <Row>
                          {inputFields.length > 0 &&
                            inputFields.map((field: any, key: any) => {
                              if (!['hidden', 'submit'].includes(field['type'])) {
                                field['type'] = field['type'];

                                if (
                                  field['dependField'] &&
                                  field['dependValue'] &&
                                  field['condition']
                                ) {
                                  let isMatch = true;
                                  switch (field['condition']) {
                                    case '===':
                                      isMatch =
                                        approvalValues[field['dependField']] ===
                                        field['dependValue'];
                                      break;

                                    case '!==':
                                      isMatch =
                                        approvalValues[field['dependField']] !==
                                        field['dependValue'];
                                      break;

                                    case 'includes':
                                      isMatch = field['dependValue'].includes(
                                        approvalValues[field['dependField']],
                                      );
                                      break;

                                    default:
                                      break;
                                  }
                                  if (!isMatch) {
                                    if (approvalValues[field['name']] !== field['default']) {
                                      setFormValue(field['name'], field['default']);
                                    }
                                    return <></>;
                                  }
                                }

                                return (
                                  <Col
                                    key={field['name'] + key}
                                    md={field['colSize']}
                                    sm={12}
                                  >
                                    <FormGroup className='mb-3'>
                                      <>
                                        {['richtext'].includes(
                                          field['type'],
                                        ) && (
                                            <div className='accordion-item'>
                                              <h2
                                                className='accordion-header'
                                                id={'heading-' + field['name']}
                                              >
                                                <button
                                                  className='accordion-button text-capitalize'
                                                  type='button'
                                                  data-bs-toggle='collapse'
                                                  data-bs-target={
                                                    '#collapse-' + field['name']
                                                  }
                                                  aria-expanded='true'
                                                  aria-controls='collapseOne'
                                                  onClick={(e: any) => {
                                                    let bsTarget =
                                                      e.target?.dataset
                                                        ?.bsTarget || '';

                                                    let selectorTarget =
                                                      document.querySelector(
                                                        bsTarget,
                                                      );

                                                    if (
                                                      selectorTarget.classList.contains(
                                                        'show',
                                                      )
                                                    ) {
                                                      selectorTarget.classList.remove(
                                                        'show',
                                                      );
                                                    } else {
                                                      selectorTarget.className +=
                                                        ' show';
                                                    }
                                                  }}
                                                >
                                                  <Label
                                                    className={
                                                      field['isRequired']
                                                        ? 'form-label me-2 required-field text-capitalize'
                                                        : 'form-label me-2 text-capitalize'
                                                    }
                                                  >
                                                    {field['label']}
                                                  </Label>
                                                </button>
                                              </h2>
                                              <div
                                                id={'collapse-' + field['name']}
                                                className='accordion-collapse collapse '
                                                aria-labelledby={
                                                  'heading-' + field['name']
                                                }
                                                data-bs-parent='#accordionExample'
                                              >
                                                <div className='accordion-body'>
                                                  <DaynamicInputField
                                                    label={field['label']}
                                                    type={field['type']}
                                                    placeholder={
                                                      field['label']
                                                    }
                                                    required={
                                                      field['isRequired']
                                                    }
                                                    invalid={{
                                                      errors,
                                                      touched,
                                                    }}
                                                    setData={(
                                                      data: any,
                                                    ) => {
                                                      setFormValue(
                                                        field['name'],
                                                        data,
                                                      );
                                                    }}
                                                    {...getFieldProps(
                                                      field['name'],
                                                    )}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          )}

                                        {['modal'].includes(field['type']) && (
                                          <DaynamicInputField
                                            label={field['label']}
                                            name={field['name']}
                                            type={field['type']}
                                            placeholder={field['label']}
                                            options={field['data']}
                                            dataApi={field['dataApi']}
                                            value={
                                              approvalValues[field['name']] !== undefined
                                                ? approvalValues[field['name']]
                                                : field['default']
                                            }
                                            isMulti={field['isMulti']}
                                            required={field['isRequired']}
                                            invalid={{ errors, touched }}
                                            onChange={(
                                              data: any,
                                              isSelected: boolean = false,
                                            ) => {
                                              if (data === null) {
                                                setFormValue(field['name'], '');
                                                field['data'] = [];
                                              } else if (
                                                data &&
                                                typeof data === 'string'
                                              ) {
                                                let isSelected =
                                                  approvalValues[field['name']].includes(
                                                    data,
                                                  );

                                                if (isSelected) {
                                                  setFormValue(field['name'], '');
                                                  field['data'] = [];
                                                } else {
                                                  setFormValue(field['name'], data);
                                                  field['data'] = [data];
                                                }
                                              } else if (
                                                data &&
                                                typeof data === 'object' &&
                                                data?.length === undefined
                                              ) {
                                                let isSelected =
                                                  approvalValues[field['name']] ===
                                                  data?.value;

                                                if (isSelected) {
                                                  setFormValue(field['name'], '');
                                                  field['data'] = [];
                                                } else {
                                                  setFormValue(
                                                    field['name'],
                                                    data?.value || '',
                                                  );
                                                  field['data'] = data;
                                                }
                                              } else if (
                                                data &&
                                                typeof data === 'object' &&
                                                data?.length > 0
                                              ) {
                                                if (!isSelected) {
                                                  let filterData = field[
                                                    'data'
                                                  ].filter((item: any) => {
                                                    return (
                                                      item.value !== data?.[0].value
                                                    );
                                                  });

                                                  setFormValue(
                                                    field['name'],
                                                    filterData.map(
                                                      (item: any) => item.value,
                                                    ),
                                                  );
                                                  field['data'] = filterData;
                                                } else {
                                                  setFormValue(
                                                    field['name'],
                                                    data.map(
                                                      (item: any) => item.value,
                                                    ),
                                                  );
                                                  field['data'] = data;
                                                }
                                              }
                                            }}
                                          />
                                        )}

                                        {['select'].includes(field['type']) && (
                                          <DaynamicInputField
                                            label={field['label']}
                                            name={field['name']}
                                            type={field['type']}
                                            placeholder={field['label']}
                                            options={field['data']}
                                            dataApi={field['dataApi']}
                                            value={
                                              approvalValues[field['name']] !== undefined
                                                ? approvalValues[field['name']]
                                                : field['default']
                                            }
                                            isMulti={field['isMulti']}
                                            required={field['isRequired']}
                                            invalid={{ errors, touched }}
                                            onChange={(data: any) => {
                                              if (data === null) {
                                                setFormValue(field['name'], '');
                                              } else if (
                                                data &&
                                                data?.length === undefined &&
                                                data.value !== undefined
                                              ) {
                                                setFormValue(
                                                  field['name'],
                                                  data.value,
                                                );
                                              } else if (
                                                data &&
                                                data?.length !== undefined
                                              ) {
                                                if (data?.length <= 3) {
                                                  setFormValue(
                                                    field['name'],
                                                    data.map((item: any) => item.value),
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        )}

                                        {['date'].includes(field['type']) && (
                                          <>
                                            <DaynamicInputField
                                              label={field['label']}
                                              name={field['name']}
                                              type={field['type']}
                                              placeholder={field['label']}
                                              options={field['options']}
                                              invalid={{ errors, touched }}
                                              value={
                                                approvalValues[field['name']] !== undefined
                                                  ? approvalValues[field['name']]
                                                  : field?.options?.defaultDate
                                              }
                                              required={field['isRequired']}
                                              setData={(data: any) => {
                                                if (
                                                  field?.options?.mode === 'range'
                                                ) {
                                                  setFormValue(
                                                    field['name'],
                                                    data[0],
                                                  );
                                                  setFormValue(
                                                    field['name2'],
                                                    data[1],
                                                  );
                                                } else {
                                                  setFormValue(
                                                    field['name'],
                                                    data.join(',').trim(),
                                                  );
                                                }
                                              }}
                                            />
                                          </>
                                        )}

                                        {['radio', 'checkbox'].includes(
                                          field['type'],
                                        ) && (
                                            <DaynamicInputField
                                              label={field['label']}
                                              name={field['name']}
                                              type={field['type']}
                                              placeholder={field['label']}
                                              options={field['data']}
                                              dataApi={field['dataApi']}
                                              value={
                                                approvalValues[field['name']] !== undefined
                                                  ? approvalValues[field['name']]
                                                  : field['default']
                                              }
                                              isMulti={field['isMulti']}
                                              required={field['isRequired']}
                                              invalid={{ errors, touched }}
                                              onChange={(data: any) => {
                                                let selectedValue =
                                                  data.target.value.toString();
                                                if (field['type'] === 'checkbox') {
                                                  let isChecked = data.target.checked;
                                                  let oldValue = approvalValues[field['name']];

                                                  let getIndex =
                                                    oldValue.indexOf(selectedValue);
                                                  if (isChecked && getIndex === -1) {
                                                    oldValue.push(selectedValue);
                                                  } else if (
                                                    !isChecked &&
                                                    getIndex > -1
                                                  ) {
                                                    oldValue.splice(getIndex, 1);
                                                  }

                                                  setFormValue(
                                                    field['name'],
                                                    oldValue,
                                                  );
                                                } else {
                                                  setFormValue(
                                                    field['name'],
                                                    selectedValue,
                                                  );
                                                }
                                              }}
                                            />
                                          )}

                                        {![
                                          'richtext',
                                          'modal',
                                          'select',
                                          'radio',
                                          'checkbox',
                                          'date',
                                        ].includes(field['type']) && (
                                            <DaynamicInputField
                                              label={field['label']}
                                              type={field['type']}
                                              placeholder={field['label']}
                                              required={field['isRequired']}
                                              invalid={{ errors, touched }}
                                              {...approvalGetFieldProps(field['name'])}
                                            />
                                          )}
                                      </>
                                    </FormGroup>
                                  </Col>
                                );
                              }
                            })}
                        </Row>
                      </div>
                    </div>

                  </div>
                </>)}

                {/* <pre>{JSON.stringify(approvalValues, null, 2)}</pre> */}
              </div>
              <div className='modal-footer'>
                {showForm && submitFormType.toLowerCase() !== 'view' && (
                  <Button
                    type='submit'
                    text='Save'
                    className='btn btn-success'

                  ></Button>
                )}
                <Button
                  type='button'
                  text='Close'
                  className='btn btn-danger'
                  onClick={() => {
                    setShowModal(!showModal);
                  }}
                ></Button>
              </div>
            </Form>
          </Modal>
        </Container>
      </div>
    </>
  );
};

export default AssignExaminerForThesis;
