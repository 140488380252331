import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Select from 'react-select';
import { Button, Container, Form, FormGroup, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { actionColumnStyles, dataTableCustomStyles } from 'src/components/Common/FixedActionColumn';
import { useGetOrganizationsQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import { useGetTrainingAllowanceListForTraineeQuery } from 'src/rtk/features/trainingAllowance/trainingAllowanceApi';
import loader from '../../assets/images/loader.gif';
import AllowanceForm from './AllowanceForm';
import { TrainingAllowanceApplicationPDF } from './TrainingAllowanceApplicationPDF';
import { TrainingAllowancePDF } from './TrainingAllowancePDF';
import moment from 'moment';


const TrainingAllowanceListForTrainee = () => {

  const [selectedSubject, setSelectedSubject] = useState<any>('');
  const [selectedInstitue, setSelectedInstitute] = useState<any>('');
  const [infoId, setInfoId] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [appointmentModalShow, setAppointmentModalShow] = useState(false);
  const [appointmentId, setAppointmentId] = useState('');
  const [allowanceId, setAllowanceId] = useState('');
  const [allowanceFormId, setAllowanceFormId] = useState('');
  const [showAllowanceReport, setShowAllowanceReport] = useState<boolean>(false);

  const { data: subjects } = useGetSubjectInfosQuery();

  const allSubjects: SelectOption[] =
    subjects?.data?.map((subject_id) => ({
      value: subject_id.subject_name,
      label: subject_id.subject_name,
    })) || [];

  const instituteList: SelectOption[] =
    useGetOrganizationsQuery()
      .data?.data?.map((item) => ({
        value: item.id!,
        label: item.organization_name!,
      })) || [];

  const allStatus: SelectOption[] = [
    { value: 'Approved', label: 'Approved' },
    { value: 'Pending', label: 'Pending' },
  ]
  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');

  const generateQueryUrl = () => {
    return `page=${page}&paginate_per_page=${limit}${search}`;
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setPage(page);
  };

  interface SearchFields {
    search?: string;
    bmdc_registration_no?: string;
    institute_name?: string;
    mobile?: string;
    approval_status?: string;
    subject_name?: string;
  }

  const initialValues: SearchFields = {
    search: '',
    bmdc_registration_no: '',
    institute_name: '',
    mobile: '',
    approval_status: '',
    subject_name: '',
  };

  const {
    data: allowanceList,
    isSuccess,
    isLoading,
  } = useGetTrainingAllowanceListForTraineeQuery(generateQueryUrl());

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.search !== '') {
        s = s + `&search=${values.search}`;
      }

      if (values.bmdc_registration_no !== '') {
        s = s + `&bmdc_registration_no=${values.bmdc_registration_no}`;
      }

      if (values.institute_name !== '') {
        s = s + `&institute_name=${values.institute_name}`;
      }

      if (values.mobile !== '') {
        s = s + `&mobile=${values.mobile}`;
      }

      if (values.subject_name !== '') {
        s = s + `&subject_name=${values.subject_name}`;
      }

      if (values.approval_status !== '') {
        s = s + `&approval_status=${values.approval_status}`;
      }

      setSearch(s);
      setPage('1');
    },
  });

  const columns: TableColumn<any>[] = [
    {
      name: <div>Sl. No.</div>,
      cell: (row, index) => <strong>{index + 1}</strong>,
      width: '60px',
    },

    {
      name: <div>Name of the Trainee</div>,
      selector: (row) => row?.registration?.personal_info?.full_name,
      sortable: false,
      wrap: true,
      width: '130px',
    },
    {
      name: <div>BCPS Reg. No.</div>,
      selector: (row) => row?.registration?.bcps_registration_no,
      sortable: false,
      wrap: true,
      width: '160px',
    },
    {
      name: <div>BMDC Reg. No.</div>,
      selector: (row) => row?.registration?.bmdc_registration_no,
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Present Working Place</div>,
      selector: (row) => row?.registration?.current_session_and_supervisor_info?.supervisor?.institute_name,
      sortable: false,
      wrap: true,
      width: '160px',
    },
    {
      name: <div>Mobile Number</div>,
      selector: (row) => row?.registration?.personal_info?.mobile,
      sortable: false,
      wrap: true,
      width: '110px',
    },
    {
      name: <div>Email</div>,
      selector: (row) => row?.registration?.personal_info?.email,
      sortable: false,
      wrap: true,
      width: '130px',
    },
    {
      name: <div>Specialty</div>,
      selector: (row) => row?.registration?.subject?.subject_name,
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Current Duration of FCPS Part-II</div>,
      selector: (row) => moment(row?.registration?.current_session_and_supervisor_info?.session_start_date).format('DD-MMM-YYYY') +' to '+ moment(row?.registration?.current_session_and_supervisor_info?.session_end_date).format('DD-MMM-YYYY'),
      sortable: false,
      wrap: true,
      width: '160px',
    },
    {
      name: <div>Status</div>,
      width: '170px',
      selector: (row) => row?.approval_status,
      cell: (row: any) => {
        switch (row?.approval_status) {
          case 'Approve':
            return (
              <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                {row?.approval_status}
              </div>
            );
          default:
            return (
              <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                {row?.approval_status}
              </div>
            );
        }
      },
      sortable: false,
      wrap: true,
    },

    // {
    //   name: 'Action',
    //   width: '250px',
    //   style: actionColumnStyles,
    //   cell: (row) => (
    //     <>
    //       <div className="d-flex gap-1 my-1">
    //         <Button
    //           className="btn btn-sm btn-info"
    //           onClick={() => setAllowanceId(row?.id)}
    //         >
    //           <span className="fas fa-eye"></span> Allowance Form
    //         </Button>

    //         <Button
    //           className="btn btn-sm btn-success"
    //           onClick={(e) => {
    //             setShowAllowanceReport(true);
    //             setAllowanceFormId(row?.id);
    //           }}
    //         >
    //           <span className="fas fa-eye"></span> Allowance
    //         </Button>
    //       </div>
    //       {allowanceId === row?.id ? (
    //         <TrainingAllowanceApplicationPDF data={row} setAllowanceId={setAllowanceId} />
    //       ) : null}
    //       {showAllowanceReport && allowanceFormId === row?.id ? (
    //         <TrainingAllowancePDF data={row} setShowAllowanceReport={setShowAllowanceReport} setAllowanceFormId={setAllowanceFormId} />
    //       ) : null}
    //     </>
    //   ),
    // },
  ];


  useEffect(() => {
    if (selectedSubject !== '') {
      setFieldValue('subject_name', selectedSubject?.value);
    }
  }, [selectedSubject]);

  useEffect(() => {
    if (selectedInstitue !== '') {
      setFieldValue('institute_name', selectedInstitue?.value);
    }
  }, [selectedInstitue]);

  return (
    <div className="page-content">
      {isLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <div className='page-breadcrumb-wrapper'>
          <BetterBreadcrumb
            breadcrumbItem={[{ link: '/', name: 'Training Allowance' }]}
            title={'Training Allowance List'}
          />
          <div>
            <Button color="primary" onClick={() => setModalShow(true)}>
              Allowance Entry
            </Button>
          </div>
        </div>
        <div className="row align-items-center justify-content-center">
          <div className="card">
            <div className="card-body">
              <DataTable
                columns={columns}
                data={allowanceList?.data!}
                customStyles={dataTableCustomStyles}
                pagination
                paginationServer
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                progressPending={isLoading}
                expandOnRowClicked
                responsive
                persistTableHead={true}
              />
            </div>
          </div>
        </div>
      </Container>

      <Modal isOpen={modalShow} size={'xl'} toggle={() => setModalShow(!modalShow)}>
        <ModalHeader toggle={() => setModalShow(!modalShow)}>Allowance Form</ModalHeader>
        <ModalBody>
          <AllowanceForm setModalShow={setModalShow} />
        </ModalBody>
      </Modal>

    </div>
  );
};

export default TrainingAllowanceListForTrainee;
