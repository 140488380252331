export const actionColumnStyles: any = {
  background: 'white',
  position: 'sticky',
  right: 0,
};

export const dataTableCustomStyles: any = {
  headCells: {
    style: {
      position: 'sticky',
      right: 0,
      background: '#f8faff',
    },
  },
};