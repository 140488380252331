export const TRAINEE_LOGBOOK = {
    module: 'trainee-logbook',
    submenu: 'sub-menu-trainee-logbook-entry',
    list: 'action-trainee-trainee-logbook-list',
    view: 'action-trainee-trainee-logbook-view',
    search: 'action-trainee-trainee-logbook-search',
    create: 'action-trainee-trainee-logbook-create',
    edit: 'action-trainee-trainee-logbook-edit',
}

export const TRAINEE_ACADEMIC_ACTIVITIES = {
    module: 'trainee-academic-activities',
    submenu: 'sub-menu-trainee-academic-activities',
    list: 'action-trainee-logbook-academic-activities-list',
    view: 'action-trainee-logbook-academic-activities-view',
    search: 'action-trainee-logbook-academic-activities-search',
    create: 'action-trainee-logbook-academic-activities-create',
    edit: 'action-trainee-logbook-academic-activities-edit',
}
