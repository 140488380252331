import React from "react";

class TextFieldComponent extends React.Component<{ field: any, form: any }> {
    render() {
        let {field, form: {}} = this.props;
        return (
            <>
                <div className={`input-group mb-3`}>
                    <input
                        {...field}
                        type="text"
                        className="form-control"
                        aria-label="sample_area_label"
                        aria-describedby="basic-addon1"
                    />
                </div>

            </>
        );
    }
}

export default TextFieldComponent;