import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import { useGetLogbookEntrySetupDetailsQuery } from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import { useDeleteLogbookEntrySetupDetailsRecordMutation } from 'src/rtk/features/setup/logBookEntrySetupDetailsRecord/logBookEntrySetupDetailsRecordApi';
import DetailsInfoViewModal from './DetailsInfoViewModal';

interface Props {
  logbookEntrySetupDetailsRecord: LogbookEntrySetupDetailsRecord;
  index: number;
}

const LogbookEntrySetupDetailsRecordTableRow = ({ logbookEntrySetupDetailsRecord, index }: Props) => {
  const history = useHistory();
  const [deleteLogbookEntrySetupDetailsRecord, { isSuccess, isLoading, isError }] =
    useDeleteLogbookEntrySetupDetailsRecordMutation();

  const [ modalShow, setModalShow ] = useState<boolean>(false);
  const [ logbookEntrySetupDetailsRecordId, setLogbookEntrySetupDetailsRecordId ] = useState<string>('');

  const { data: logbookEntrySetupDetailsData } = useGetLogbookEntrySetupDetailsQuery();
  const logbookEntrySetupDetails = logbookEntrySetupDetailsData?.data || [];

  const deleteHandler = (id: string) => {
    const confirm = window.confirm('Are you sure you want to delete?');
    if (confirm) deleteLogbookEntrySetupDetailsRecord(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Logbook entry setup details record deleted successfully');
    }
    if (isError) {
      toast.error('Error deleting logbook entry setup details record');
    }
  }, [isSuccess, isError]);

  const handleViewButtonClick = (id: string) => {
    setModalShow(true);
    setLogbookEntrySetupDetailsRecordId(id);
  };

  return (
    <>
      <tr key={logbookEntrySetupDetailsRecord.id}>
        <th scope="row">{index + 1}</th>      
        <td>
          {
            logbookEntrySetupDetails?.find((l: LogbookEntryDetails) => l.id === logbookEntrySetupDetailsRecord.logbook_entry_setup_detail_id)
              ?.details_text
          }
        </td>
        <td>{logbookEntrySetupDetailsRecord.details_text}</td>        
        <td>{logbookEntrySetupDetailsRecord.status ? 'Active' : 'Inactive'}</td>       
        <td>
          <div className="d-flex gap-2">
            <Button
              size="sm"
              text="Edit"
              color="warning"
              onClick={() =>
                history.push(`/setup/logbook-entry-setup-details-record/update/${logbookEntrySetupDetailsRecord.id}`)
              }
            />

            <Button
              size="sm"
              text="Delete"
              color="danger"
              disabled={isLoading}
              isLoading={isLoading}
              onClick={() => deleteHandler(logbookEntrySetupDetailsRecord.id!)}
            />

            <Button
              size="sm"
              text="View Details"
              color="info"
              onClick={() =>
                handleViewButtonClick(logbookEntrySetupDetailsRecord.id!)
              }
            />
          </div>
        </td>
      </tr>
      {
        modalShow ? (
          <DetailsInfoViewModal
              isOpen={modalShow}
              toggle={() => setModalShow(!modalShow)}
              id={logbookEntrySetupDetailsRecordId}
          /> 
        ) : null
      }
    </>
  );
};

export default LogbookEntrySetupDetailsRecordTableRow;
