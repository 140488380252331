import * as logbookTrainingSummaryUrl from 'src/helpers/url_helper';
import { apiSlice } from 'src/rtk/api/apiSlice';

export const logbookTrainingSummaryApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['logbookTrainingSummaryList'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTrainingSummary: builder.query<
        APIResponse<LogbookTrainingSummary[]>,
        { user_id: string; session_id: string }
      >({
        query: (obj) => ({
          method: 'GET',
          url: `${logbookTrainingSummaryUrl.GET_TRAINING_SUMMARY}?user_id=${obj.user_id}&session_id=${obj.session_id}`,
        }),

        providesTags: ['logbookTrainingSummaryList'],
      }),

      getSupervisors: builder.query<APIResponse<Supervisors[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${logbookTrainingSummaryUrl.GET_SUPERVISORS}`,
        }),

        providesTags: ['logbookTrainingSummaryList'],
      }),

      getInstituteName: builder.query<Institute[], void>({
        query: () => ({
          method: 'GET',
          url: `${logbookTrainingSummaryUrl.GET_INSTITUTE_NAME}`,
        }),

        providesTags: ['logbookTrainingSummaryList'],
      }),

      getLogbookReportListForAdminAndSupervisorByPaginate: builder.query<
        APIResponseWithPagination<any>,
        PaginateForLogbookEntries
      >({
        query: (paginate) => {
          let url = `${logbookTrainingSummaryUrl.GET_LOGBOOK_REPORT_LIST_FOR_ADMIN_AND_SUPERVISOR}?`;

          if (paginate.page) {
            url += `page=${paginate.page}&`;
          }
          if (paginate.paginate_per_page) {
            url += `paginate_per_page=${paginate.paginate_per_page}`;
          }
          if (paginate.search) {
            url += `${paginate.search}`;
          }

          return {
            method: 'GET',
            url: url,
          };
        },

        providesTags: ['logbookTrainingSummaryList'],
      }),

      getTrainingSummaryForAdminAndSupervisor: builder.query<
        APIResponse<LogbookTrainingSummary[]>,
        { user_id: string; session_id: string }
      >({
        query: (obj) => ({
          method: 'GET',
          url: `${logbookTrainingSummaryUrl.GET_TRAINING_SUMMARY_FOR_ADMIN_AND_SUPERVISOR}?user_id=${obj.user_id}&session_id=${obj.session_id}`,
        }),

        providesTags: ['logbookTrainingSummaryList'],
      }),

      getUserSlotInfoForAdminAndSupervisor: builder.query<
        APIResponse<any>,
        string
      >({
        query: (userId: string) => ({
          method: 'GET',
          url: `${logbookTrainingSummaryUrl.GET_USER_SLOT_INFO_FOR_ADMIN_AND_SUPERVISOR}/${userId}`,
        }),

        providesTags: ['logbookTrainingSummaryList'],
      }),

      getUserSlotInfoForTrainee: builder.query<
        APIResponse<any>,
        string
      >({
        query: (userId: string) => ({
          method: 'GET',
          url: `${logbookTrainingSummaryUrl.GET_USER_SLOT_INFO_FOR_TRAINEE}/${userId}`,
        }),

        providesTags: ['logbookTrainingSummaryList'],
      }),

      getTrainingSummaryById: builder.query<
        APIResponse<LogbookTrainingSummary[]>,
        { id: string; userId: string; sessionId: string }
      >({
        query: (obj) => ({
          url: `${logbookTrainingSummaryUrl.GET_TRAINING_SUMMARY_BY_ID}/${obj.id}?user_id=${obj.userId}&session_id=${obj.sessionId}`,
          method: 'GET',
        }),

        providesTags: ['logbookTrainingSummaryList'],
      }),

      getTrainingSummaryForAdminAndSupervisorById: builder.query<
        APIResponse<LogbookTrainingSummary[]>,
        { id: string; userId: string; sessionId: string }
      >({
        query: (obj) => ({
          url: `${logbookTrainingSummaryUrl.GET_TRAINING_SUMMARY_FOR_ADMIN_AND_SUPERVISOR_BY_ID}/${obj.id}?user_id=${obj.userId}&session_id=${obj.sessionId}`,
          method: 'GET',
        }),

        providesTags: ['logbookTrainingSummaryList'],
      }),
    }),
  });

export const {
  useGetTrainingSummaryQuery,
  useGetSupervisorsQuery,
  useGetInstituteNameQuery,
  useGetLogbookReportListForAdminAndSupervisorByPaginateQuery,
  useGetTrainingSummaryForAdminAndSupervisorQuery,
  useGetTrainingSummaryByIdQuery,
  useLazyGetTrainingSummaryByIdQuery,
  useGetTrainingSummaryForAdminAndSupervisorByIdQuery,
  useLazyGetTrainingSummaryForAdminAndSupervisorByIdQuery,
  useGetUserSlotInfoForAdminAndSupervisorQuery,
  useGetUserSlotInfoForTraineeQuery,
} = logbookTrainingSummaryApi;
