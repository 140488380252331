import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { putUrlString } from 'src/helpers';
import {
  useGetDepartmentQuery,
  useUpdateDepartmentMutation,
} from 'src/rtk/features/setup/department/departmentApi';
import { useGetOrganizationsQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import * as Yup from 'yup';

const initialValues = {
  department_name: '',
  organization_id: '',
  building_no: '',
  room_no: '',
  floor_no: '',
  description: '',
  status: true,
};

export const EditDepartment = () => {
  const { id } = useParams<{ id: string }>();
  const { data: department, isError: isDeptFetchError } =
    useGetDepartmentQuery(id);
  const [updateDepartment, { isSuccess, isError, error }] =
    useUpdateDepartmentMutation();
  const err: CustomError = error as CustomError;
  const history = useHistory();
  const organizations: SelectOption[] = (
    useGetOrganizationsQuery().data?.data || []
  ).map((item) => ({
    value: item.id ?? '',
    label: item.organization_name ?? '',
  }));

  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    values,
    setValues,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      department_name: Yup.string().required('Please Enter Department Name'),
      organization_id: Yup.string().required(
        'Department must be under an organization',
      ),
    }),

    onSubmit: (values) => {
      const url = putUrlString(id, values);
      updateDepartment(url);
    },
  });

  useEffect(() => {
    if (department && !isDeptFetchError) {
      const data: Department = (department as any).data;
      setValues({
        department_name: data.department_name,
        organization_id: data.organization_id,
        building_no: data.building_no || '',
        room_no: data.room_no || '',
        floor_no: data.floor_no || '',
        description: data.description || '',
        status: data.status!,
      });
    }
  }, [department, isDeptFetchError]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Department updated successfully');
      history.push('/setup/department');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      const errors: any = {};

      if (err?.data) {
        Object.keys(err?.data).forEach((key) => {
          errors[key] = err?.data[key][0];
        });
      }
      setErrors(errors);
    }
  }, [isSuccess, isError]);

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Edit Department'}
          breadcrumbItem={[{ link: '/setup/department/', name: 'Department' }]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-8 col-sm-12 ">
            <div className="card mt-3">
              <h5 className="card-header">
                <i className="fa fa-edit me-2" />
                Edit Department
              </h5>
              <div className="card-body">
                <Form
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <Row>
                    <div className="row">
                      <FormGroup className="mb-3">
                        <Label for="department_name">Department Name</Label>
                        <InputField
                          type="text"
                          placeholder="Department Name"
                          invalid={{ errors, touched }}
                          {...getFieldProps('department_name')}
                        />
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <Label for="organization_id">Organization</Label>
                        <InputField
                          type="select"
                          options={organizations}
                          placeholder="Organization"
                          invalid={{ errors, touched }}
                          {...getFieldProps('organization_id')}
                        />
                      </FormGroup>

                      {/* <FormGroup className="mb-3 col-md-4 col-sm-12">
                        <Label for="building_no">Building No</Label>
                        <InputField
                          type="text"
                          placeholder="Building No"
                          invalid={{ errors, touched }}
                          {...getFieldProps('building_no')}
                        />
                      </FormGroup> */}
                      {/* <FormGroup className="mb-3 col-md-4 col-sm-12">
                        <Label for="floor_no">Floor No</Label>
                        <InputField
                          type="text"
                          placeholder="Floor No"
                          invalid={{ errors, touched }}
                          {...getFieldProps('floor_no')}
                        />
                      </FormGroup> */}

                      {/* <FormGroup className="mb-3 col-md-4 col-sm-12">
                        <Label for="room_no">Room No</Label>
                        <InputField
                          type="text"
                          placeholder="Room No"
                          invalid={{ errors, touched }}
                          {...getFieldProps('room_no')}
                        />
                      </FormGroup> */}

                      {/* <FormGroup className="mb-3">
                        <Label for="description">Description</Label>
                        <InputField
                          type="textarea"
                          placeholder="Description"
                          invalid={{ errors, touched }}
                          {...getFieldProps('description')}
                        />
                      </FormGroup> */}
                      {/* <div className="col-sm-12">
                        <h6>Active Status</h6>
                        <FormGroup check className="mb-3">
                          <Input
                            type="checkbox"
                            role="check"
                            checked={values.status}
                            {...getFieldProps('status')}
                          />
                          <Label check>Active</Label>
                        </FormGroup>
                      </div> */}
                      <div className="text-center">
                        <Button className="w-25" type="submit" text="Submit" />
                      </div>
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
