import { LogbookEssentialInfos } from "src/pages/Setup/LogbookEssentialInfo";
import { AddLogbookEssentialInfo } from "src/pages/Setup/LogbookEssentialInfo/AddLogbookEssentialInfo";
import { EditLogbookEssentialInfo } from "src/pages/Setup/LogbookEssentialInfo/EditLogbookEssentialInfo";

export const logBookEssentialInfoSetupRoutes = [
  // logbook essential info setup
  {
    path: '/setup/logbook-essential-info/',
    component: LogbookEssentialInfos,
  },
  {
    path: '/setup/logbook-essential-info/update/:id',
    component: EditLogbookEssentialInfo,
  },
  {
    path: '/setup/logbook-essential-info/add',
    component: AddLogbookEssentialInfo,
  },
];
