import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { Container, Form, Label, FormGroup, Input } from 'reactstrap';
import { InputField, Button } from 'src/components';
import { useFormik } from 'formik';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { putUrlString } from 'src/helpers';
import { useGetNoticeTemplateAttachmentQuery, useGetNoticeTemplateAttachmentsQuery, useUpdateNoticeTemplateAttachmentMutation } from 'src/rtk/features/setup/noticeTemplateAttachment/setupNoticeTemplateAttachmentApi';
import { useGetNoticeTemplateListQuery } from 'src/rtk/features/setup/noticeTemplateList/setupNoticeTemplateListApi';

interface AttachmentProps {    
    noticeAttachmentId: string;
}

const initialValues: NoticeTemplateCopyAttachment = {
    id: '',
    notice_template_list_id: '',
    attachment_type: '',
    details: '',
    user_id: '',
    status: true,
    creator_organization_id: '',
    updater_organization_id: '',
    created_by: '',
    updated_by: '',
    deleted_by: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
};

const EditNoticeTemplateCopyAttachment = (props: AttachmentProps) => {
    const { id } = useParams<{ id: string }>();
    const { noticeAttachmentId } = props;    
    const [updateNoticeTemplateCopyAttachment, { isSuccess, isError, error }] =
        useUpdateNoticeTemplateAttachmentMutation();    
    
    const { data: notice } = useGetNoticeTemplateListQuery(id);
    const { data: noticeAttachmentResponse, isError: isNoticeTemplateAttachmentFetchError } =
        useGetNoticeTemplateAttachmentQuery(noticeAttachmentId);      
    const err: CustomError = error as CustomError;
    const history = useHistory();
  const {
    handleSubmit,
    getFieldProps,
    values,
    touched,
    errors,
    setValues,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({        
        notice_template_list_id: Yup.string().required(
          'Notice template attachment must be under a notice template',
        ),
    }),

    onSubmit: (values) => {
      const url = putUrlString(noticeAttachmentId, values);      
      updateNoticeTemplateCopyAttachment(url);
    },
  });

  useEffect(() => {
    if (noticeAttachmentResponse && !isNoticeTemplateAttachmentFetchError) {
      const noticeAttachment: NoticeTemplateCopyAttachment = (noticeAttachmentResponse as any).data; 
      setValues({
        id: noticeAttachment?.id,
        notice_template_list_id: noticeAttachment?.notice_template_list_id!,
        attachment_type: noticeAttachment?.attachment_type,
        details: noticeAttachment?.details,
        user_id: noticeAttachment?.user_id,
        status: noticeAttachment?.status,
        creator_organization_id: noticeAttachment?.creator_organization_id,
        updater_organization_id: noticeAttachment?.updater_organization_id,
        created_by: noticeAttachment?.created_by,
        updated_by: noticeAttachment?.updated_by,
        deleted_by: noticeAttachment?.deleted_by,
        created_at: noticeAttachment?.created_at,
        updated_at: noticeAttachment?.updated_at,
        deleted_at: noticeAttachment?.deleted_at,
      });
    }
  }, [noticeAttachmentResponse, isNoticeTemplateAttachmentFetchError]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Notice template attachment updated successfully');      
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      const errors: any = {};

      if (err?.data) {
        Object.keys(err?.data).forEach((key) => {
          errors[key] = err?.data[key][0];
        });
      }
      setErrors(errors);
    }
  }, [isSuccess, isError]);

  return (         
      <div className="row align-items-center justify-content-center">     
        <div className="card mt-3">
            <h5 className="card-header">
                <i className="fa fa-edit me-2" />
                Edit Notice Template Attachment
            </h5>
            <div className="card-body">
                <Form
                    className="custom-form"
                    onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                    }}
                >
                    <div className="row">                    
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <FormGroup className="mb-3">
                        <Label for="attachment_type">Attachment Type</Label>
                        <InputField
                            type="text"
                            placeholder="Attachment Type"                         
                            invalid={{ errors, touched }}
                            {...getFieldProps('attachment_type')}
                        />
                        </FormGroup>
                    </div>

                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <FormGroup className="mb-3">
                        <Label for="notice_subject">Details</Label>
                        <InputField
                            type="textarea"
                            placeholder="Details"
                            invalid={{ errors, touched }}
                            {...getFieldProps('details')}
                        />
                        </FormGroup>
                    </div>

                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <FormGroup check className='mb-3 mt-5'>
                        <Label check>
                            <Input type="checkbox" {...getFieldProps('status')} checked={values.status} />{' '}
                            Status
                        </Label>
                        </FormGroup>
                    </div>

                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <FormGroup className="mb-3" hidden>
                        <Label for="user_id">User</Label>
                        <InputField
                            type="text"
                            placeholder="User"
                            invalid={{ errors, touched }}
                            {...getFieldProps('user_id')}
                        />
                        </FormGroup>
                    </div>                    
                    </div>
                    <Button type="submit" text="Submit" />
                </Form>
            </div>
        </div>
      </div>    
  );
};

export default EditNoticeTemplateCopyAttachment;

function useGetNoticeTemplateQuery(id: string): { data: any; isError: any; } {
  throw new Error('Function not implemented.');
}

