import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface Props {
  isOpen: boolean;
  toggle: any;
  id: string;
  deleteInfomation?: any;
}

const DeleteInformationModal = (props: Props) => {
  const { isOpen, toggle, id, deleteInfomation } = props;

  const handleDelete = (e: any) => {
    deleteInfomation(id);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="sm" backdrop={'static'}>
      <ModalHeader toggle={toggle} className='delete-font'>
        <i className="fa-solid fa-trash" /> Delete
      </ModalHeader>

      <ModalBody className='delete-font'>
        <div className="row justify-content-center">
          <div className="row justify-content-between">
            <div>
              <h4>Are You Sure?</h4>
            </div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter className='p-1 delete-font'>
        <div className="d-flex gap-2">
          <Button color="success" onClick={(e) => handleDelete(e)}>
            Yes
          </Button>

          <Button color="danger" onClick={toggle}>
            No
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteInformationModal;
