import { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.scss';
import NonAuthLayout from './components/NonAuthLayout';
import VerticalLayout from './components/VerticalLayout/';
import useAuthCheck from './hooks/useAuthCheck';
import AuthMiddleware from './routes/AuthMiddleware';
import { authRoutes, protectedRoutes } from './routes/allRoutes';

const App = () => {
  const { layoutType } = useSelector((state: any) => ({
    layoutType: state.Layout.layoutType,
  }));

  function getLayout() {
    let layoutCls: Object = VerticalLayout;
    switch (layoutType) {
      default:
        layoutCls = VerticalLayout;
    }
    return layoutCls;
  }

  const Layout = getLayout();
  const authCheck = useAuthCheck();

  return !authCheck ? (
    <div>Checking Authentication....</div>
  ) : (
    <Router>
      <Toaster position="top-right" />
      <Switch>
        {authRoutes.map((route, idx) => (
          <AuthMiddleware
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            isAuthProtected={false}
            exact
          />
        ))}

        {protectedRoutes.map((route: any, idx: number) => (
          <AuthMiddleware
            path={route.path}
            layout={Layout}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
      </Switch>
    </Router>
  );
};

export default App;
