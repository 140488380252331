import { useState } from 'react';
import { Card, CardBody, Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import CBDFormA from './CBDFormA';
import CBDFormB from './CBDFormB';
import { useParams } from 'react-router-dom';

const CBDTab = () => {
    const { regNo: registrationNo, id: supervisorId, logbookSessionId: sessionId } = useParams<{
        regNo: string;
        id: string;
        logbookSessionId: string;
    }>();
    
    const [activeTab, setActiveTab] = useState('formA');
   
    const toggleTab = (tab: string) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    return (
        <div className="page-content">
            <Container fluid>
                <BetterBreadcrumb
                    breadcrumbItem={[{ link: '#', name: 'Assessment' },{ link: '/case-based-disscussion-list', name: 'CBD List' }]}
                    title={'CBD Form'}
                />
                <Card>
                    <CardBody>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={activeTab === 'formA' ? 'active' : ''}
                                    onClick={() => toggleTab('formA')}
                                >
                                    Form A
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={activeTab === 'formB' ? 'active' : ''}
                                    onClick={() => toggleTab('formB')}
                                >
                                    Form B
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="formA">
                                <CBDFormA regitrationNo={registrationNo} supervisorId={supervisorId} sessionId={sessionId}/>
                            </TabPane>
                            <TabPane tabId="formB">
                                <CBDFormB regitrationNo={registrationNo} supervisorId={supervisorId} sessionId={sessionId}/>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default CBDTab;
