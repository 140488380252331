import React, { useEffect, useState } from "react";
import { Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import * as url from 'src/helpers/url_helper';
import { CommentSectionModal } from "../CommentSectionForProtocol/CommentSectionModal";
import RichTextEditor from "./RichTextEditor";

const MaterialAndMethods = (props: any) => {

    const { setObjectivesRichtexts, objectivesRichtexts, materialsAndMethods, existingProtocol, formikState, commentsDataList, initialContentEditor, setInitialContentEditor, renderKey } = props;

    const [messages, setMessage] = useState<any>([]);
    const [showCommentSectionModal, setShowCommentSectionModal] = useState<boolean>(false);

    // tabs - materialsAndMethods
    const [activeTab, setActiveTab] = useState(materialsAndMethods.main_outcome.objKey);
    const toggleTab = (tab: string) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const getCommentsData = (id: any, nameOfTheField: any) => {
        commentsDataList(`${url.THESIS_DISSERTATION_PROTOCOL_COMMENT_LIST}/${id}/${nameOfTheField}`).then(function (result: any,) {
            // here you can use the result of promiseB
            if (result?.data?.data) {
                setMessage(result?.data?.data);
            }
        });

        // setMessage(commentsData?.data || []);
    };

    useEffect(() => {
        getCommentsData(existingProtocol?.data?.id, 'material_methods');
    }, []);

    // useEffect(() => {
    //     setActiveTab(materialsAndMethods.main_outcome.objKey);
    //     setActiveTab(materialsAndMethods.confounding_variable.objKey);
    //     setActiveTab(materialsAndMethods.main_outcome.objKey);
    // }, []);
    return (
        <React.Fragment>

            <Row>
                <Col lg={12}>
                    <Row className="justify-content-end">
                        <Col sm="auto" style={{ pointerEvents: 'auto' }} className='btn noti-icon position-relative'>
                            <span className="badge bg-danger rounded-pill">{messages?.length ?? 0}</span>
                            <Button
                                type="button"
                                className="btn btn-primary btn-sm mb-3"
                                onClick={(e) => {
                                    setShowCommentSectionModal(!showCommentSectionModal);
                                }}
                            >
                                <i className="fas fa-2x fa-comments"></i>
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col lg={12}>
                    <Nav tabs style={{ pointerEvents: 'auto' }}>
                        <NavItem>
                            <NavLink
                                className={activeTab === materialsAndMethods.main_outcome.objKey ? 'active' : ''}
                                onClick={() => toggleTab(materialsAndMethods.main_outcome.objKey)}>
                                {materialsAndMethods.main_outcome.text.slice(0, 2)}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === materialsAndMethods.confounding_variable.objKey ? 'active' : ''}
                                onClick={() => toggleTab(materialsAndMethods.confounding_variable.objKey)}
                            >
                                {materialsAndMethods.confounding_variable.text.slice(0, 2)}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === materialsAndMethods.study_population.objKey ? 'active' : ''}
                                onClick={() => toggleTab(materialsAndMethods.study_population.objKey)}
                            >
                                {materialsAndMethods.study_population.text.slice(0, 2)}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === materialsAndMethods.sample_size.objKey ? 'active' : ''}
                                onClick={() => toggleTab(materialsAndMethods.sample_size.objKey)}
                            >
                                {materialsAndMethods.sample_size.text.slice(0, 2)}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === materialsAndMethods.screening_methods.objKey ? 'active' : ''}
                                onClick={() => toggleTab(materialsAndMethods.screening_methods.objKey)}
                            >
                                {materialsAndMethods.screening_methods.text.slice(0, 2)}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === materialsAndMethods.sampling_methods.objKey ? 'active' : ''}
                                onClick={() => toggleTab(materialsAndMethods.sampling_methods.objKey)}
                            >
                                {materialsAndMethods.sampling_methods.text.slice(0, 2)}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === materialsAndMethods.inclusion_exclusion_criteria.objKey ? 'active' : ''}
                                onClick={() => toggleTab(materialsAndMethods.inclusion_exclusion_criteria.objKey)}
                            >
                                {materialsAndMethods.inclusion_exclusion_criteria.text.slice(0, 2)}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === materialsAndMethods.operational_definitions.objKey ? 'active' : ''}
                                onClick={() => toggleTab(materialsAndMethods.operational_definitions.objKey)}
                            >
                                {materialsAndMethods.operational_definitions.text.slice(0, 2)}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === materialsAndMethods.flow_chart.objKey ? 'active' : ''}
                                onClick={() => toggleTab(materialsAndMethods.flow_chart.objKey)}
                            >
                                {materialsAndMethods.flow_chart.text.slice(0, 2)}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === materialsAndMethods.material_preparation.objKey ? 'active' : ''}
                                onClick={() => toggleTab(materialsAndMethods.material_preparation.objKey)}
                            >
                                {materialsAndMethods.material_preparation.text.slice(0, 2)}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === materialsAndMethods.nature_of_controls.objKey ? 'active' : ''}
                                onClick={() => toggleTab(materialsAndMethods.nature_of_controls.objKey)}
                            >
                                {materialsAndMethods.nature_of_controls.text.slice(0, 2)}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === materialsAndMethods.randomization_blinding.objKey ? 'active' : ''}
                                onClick={() => toggleTab(materialsAndMethods.randomization_blinding.objKey)}
                            >
                                {materialsAndMethods.randomization_blinding.text.slice(0, 2)}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === materialsAndMethods.equipment.objKey ? 'active' : ''}
                                onClick={() => toggleTab(materialsAndMethods.equipment.objKey)}
                            >
                                {materialsAndMethods.equipment.text.slice(0, 2)}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === materialsAndMethods.data_collection.objKey ? 'active' : ''}
                                onClick={() => toggleTab(materialsAndMethods.data_collection.objKey)}
                            >
                                {materialsAndMethods.data_collection.text.slice(0, 2)}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === materialsAndMethods.professional_assistance.objKey ? 'active' : ''}
                                onClick={() => toggleTab(materialsAndMethods.professional_assistance.objKey)}
                            >
                                {materialsAndMethods.professional_assistance.text.slice(0, 2)}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === materialsAndMethods.data_analysis_interpretation.objKey ? 'active' : ''}
                                onClick={() => toggleTab(materialsAndMethods.data_analysis_interpretation.objKey)}
                            >
                                {materialsAndMethods.data_analysis_interpretation.text.slice(0, 2)}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === materialsAndMethods.quality_assurance.objKey ? 'active' : ''}
                                onClick={() => toggleTab(materialsAndMethods.quality_assurance.objKey)}
                            >
                                {materialsAndMethods.quality_assurance.text.slice(0, 2)}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === materialsAndMethods.time_table.objKey ? 'active' : ''}
                                onClick={() => toggleTab(materialsAndMethods.time_table.objKey)}
                            >
                                {materialsAndMethods.time_table.text.slice(0, 2)}
                            </NavLink>
                        </NavItem>


                    </Nav>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={materialsAndMethods.main_outcome.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{materialsAndMethods.main_outcome.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={activeTab}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane>
                        <TabPane tabId={materialsAndMethods.confounding_variable.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{materialsAndMethods.confounding_variable.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={activeTab}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane>
                        <TabPane tabId={materialsAndMethods.study_population.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{materialsAndMethods.study_population.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={activeTab}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane>
                        <TabPane tabId={materialsAndMethods.sample_size.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{materialsAndMethods.sample_size.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={activeTab}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane>
                        <TabPane tabId={materialsAndMethods.screening_methods.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{materialsAndMethods.screening_methods.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={activeTab}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane>
                        <TabPane tabId={materialsAndMethods.sampling_methods.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{materialsAndMethods.sampling_methods.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={activeTab}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane>
                        <TabPane tabId={materialsAndMethods.inclusion_exclusion_criteria.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{materialsAndMethods.inclusion_exclusion_criteria.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={activeTab}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane>
                        <TabPane tabId={materialsAndMethods.operational_definitions.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{materialsAndMethods.operational_definitions.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={activeTab}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane>
                        <TabPane tabId={materialsAndMethods.flow_chart.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{materialsAndMethods.flow_chart.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={activeTab}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane>
                        <TabPane tabId={materialsAndMethods.material_preparation.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{materialsAndMethods.material_preparation.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={activeTab}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane>
                        <TabPane tabId={materialsAndMethods.nature_of_controls.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{materialsAndMethods.nature_of_controls.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={activeTab}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane>
                        <TabPane tabId={materialsAndMethods.randomization_blinding.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{materialsAndMethods.randomization_blinding.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={activeTab}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane>
                        <TabPane tabId={materialsAndMethods.equipment.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{materialsAndMethods.equipment.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={activeTab}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane>
                        <TabPane tabId={materialsAndMethods.data_collection.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{materialsAndMethods.data_collection.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={activeTab}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane>
                        <TabPane tabId={materialsAndMethods.professional_assistance.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{materialsAndMethods.professional_assistance.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={activeTab}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane>
                        <TabPane tabId={materialsAndMethods.data_analysis_interpretation.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{materialsAndMethods.data_analysis_interpretation.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={activeTab}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane>
                        <TabPane tabId={materialsAndMethods.quality_assurance.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{materialsAndMethods.quality_assurance.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={activeTab}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane>
                        <TabPane tabId={materialsAndMethods.time_table.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{materialsAndMethods.time_table.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={activeTab}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane>
                        {/* <TabPane tabId={materialsAndMethods.confounding_variable.objKey}>
                            <Row className="p-2">
                                <Col lg={12} className="m-0 tab-title">
                                    <p className="tab-title-text"><b>{materialsAndMethods.confounding_variable.text}</b></p>
                                </Col>
                                <Col lg={12} className="p-0">

                                    <RichTextEditor
                                        setObjectivesRichtexts={setObjectivesRichtexts}
                                        objectivesRichtexts={objectivesRichtexts}
                                        formikState={formikState}
                                        initialContentEditor={initialContentEditor}
                                        setInitialContentEditor={setInitialContentEditor}
                                        formikField={materialsAndMethods.confounding_variable.objKey}
                                        key={renderKey}
                                    ></RichTextEditor>
                                </Col>


                            </Row>
                        </TabPane> */}
                    </TabContent>

                </Col>
            </Row>

            {showCommentSectionModal ?
                <CommentSectionModal
                    fieldName={'material_methods'}
                    fieldLabel={'Materials and Methods'}
                    existingProtocol={existingProtocol}
                    showModal={showCommentSectionModal}
                    setShowModal={setShowCommentSectionModal}
                    setMessageCount={setMessage}
                />
                :
                null
            }

        </React.Fragment>
    );
};

export default MaterialAndMethods;