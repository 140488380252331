import { apiSlice } from 'src/rtk/api/apiSlice';
import * as publishNoticeUrl from 'src/helpers/url_helper';

export const publishNoticeApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['NoticeList', 'Signatories', 'NoticeTemplateList'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getNoticeList: builder.query<any, void>({
        query: () => ({
          method: 'GET',
          url: `${publishNoticeUrl.GET_NOTICE_LIST}`,
        }),
        providesTags: ['NoticeList'],
      }),

      getNoticePdf: builder.query<any, string>({
        query: (id) => ({
          method: 'GET',
          url: `${publishNoticeUrl.GET_NOTICE_PDF}/${id}`,
        }),
        providesTags: ['NoticeList'],
      }),

      getNoticeListByPaginate: builder.query<
        any,
        PaginateForLogbookEntries
      >({
        query: (paginate) => {
          let url = `${publishNoticeUrl.GET_NOTICE_LIST_BY_PAGINATE}?type=${'Notice'}&`;

          if (paginate.page) {
            url += `page=${paginate.page}&`;
          }
          if (paginate.paginate_per_page) {
            url += `paginate_per_page=${paginate.paginate_per_page}`;
          }
          if (paginate.search) {
            url += `${paginate.search}`;
          }

          return {
            method: 'GET',
            url: url,
          };
        },

        providesTags: ['NoticeList'],
      }),

      getNoticeTemplateList: builder.query<APIResponse<NoticeTemplate[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${publishNoticeUrl.GET_NOTICE_TEMPLATE_LIST}?type=${'Notice'}`,
        }),

        providesTags: ['NoticeTemplateList'],
      }),

      getNoticeView: builder.query<any, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${publishNoticeUrl.GET_NOTICE_VIEW}/${id}`,
        }),
        providesTags: ['NoticeList'],
      }),

      getSignatoriesByPermission: builder.query<APIResponse<SignatoryUser[]>, string>({
        query: (id) => ({
          url: `${publishNoticeUrl.GET_SIGNATORIES_BY_PERMISSION_IN_NOTICE}/${id}`,
          method: 'GET',
        }),
        providesTags: ['Signatories'],
      }),

      createNotice: builder.mutation<APIResponse<PostNotice>, any>({
        query: (data: any) => ({
          url: `${publishNoticeUrl.CREATE_NOTICE}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['NoticeList'],
      }),

      updateNoticeMaster: builder.mutation<APIResponse<any>, any>({
        query: (data) => ({
            url: `${publishNoticeUrl.UPDATE_NOTICE_MASTER}`,
            method: 'POST',
            body: data,
            Headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
        }),
        invalidatesTags: ['NoticeList'],
      }),

      updateNoticeAttachment: builder.mutation<APIResponse<any>, any>({
        query: (data) => ({
            url: `${publishNoticeUrl.UPDATE_NOTICE_ATTACHMENT}`,
            method: 'POST',
            body: data,
            Headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
        }),
        invalidatesTags: ['NoticeList'],
      }),
    }),
  });

export const {
  useGetNoticeListQuery,
  useLazyGetNoticeListQuery,
  useLazyGetNoticePdfQuery,
  useGetNoticeListByPaginateQuery,
  useGetNoticeTemplateListQuery,
  useGetNoticeViewQuery,
  useLazyGetNoticeViewQuery,
  useGetSignatoriesByPermissionQuery,  
  useCreateNoticeMutation,
  useUpdateNoticeMasterMutation,
  useUpdateNoticeAttachmentMutation,
} = publishNoticeApi;
