import AllowanceForm from 'src/pages/TrainingAllowance/AllowanceForm';
import TrainingAllowanceListForAdmin from 'src/pages/TrainingAllowance/TrainingAllowanceListForAdmin';
import TrainingAllowanceListForTrainee from 'src/pages/TrainingAllowance/TrainingAllowanceListForTrainee';

export const traininAllowanceRoutes = [
  {
    path: '/training-allowance-application',
    component: TrainingAllowanceListForTrainee,
  },  
  {
    path: '/training-allowance-list',
    component: TrainingAllowanceListForAdmin,
  },  
];
