import * as url from 'src/helpers/url_helper';
import * as Yup from 'yup';

const formObj: any = {
  debug: false,
  isUpdateByPatch: false,

  createAPIUrl: url.THESIS_DISSERTATION_TITLE_ENTRY_CREATE,
  updateAPIUrl: url.THESIS_DISSERTATION_TITLE_ENTRY_UPDATE,
  getDataByIdAPIUrl: url.THESIS_DISSERTATION_TITLE_ENTRY_BY_ID,
  listAPIUrl: url.THESIS_DISSERTATION_TITLE_ENTRY_LIST,
  newListAPIUrl: url.NEW_THESIS_DISSERTATION_TITLE_ENTRY_LIST,
  deleteAPIUrl: url.THESIS_DISSERTATION_TITLE_ENTRY_DELETE,

  listUrl: '/thesis-dissertation-list',
  addUrl: '/thesis-dissertation-list/add',
  updateUrl: '/thesis-dissertation-list/update/',

  showSearchForm: true,
  showCreateNewBtn: true,
  showUpdateBtn: true,
  showViewBtn: true,
  showDeleteBtn: true,
  showTableActionColumn: true,

  createNewBtnTitle: 'Create New',
  submitBtnTitle: 'Submit',
  editBtnTitle: 'Edit',
  updateBtnTitle: 'Update',
  resetBtnTitle: 'Reset',
  draftBtnTitle: 'Save as Draft',
  deletePromtMessage: 'Do you want to delete?',

  title: 'Thesis/Dissertation Title',
  breadcrumbItemForList: [{ link: '/', name: 'Dashboard' }],
  breadcrumbItemForForm: [{ link: '#', name: 'Dissertation & Thesis' }],

  formFields: {
    full_name: {
      positionInForm: 1,
      label: 'Trainee Name',
      viewCell: (row: any) => row?.registration?.personal_info?.full_name || '',
    },
    address_line_1: {
      positionInForm: 2,
      label: 'Address',
      viewCell: (row: any) => {
        if (row?.registration?.address_infos?.['0']?.district) {
          return (
            row?.registration?.address_infos?.['0']?.address_line_1 +
            ', ' +
            row?.registration?.address_infos?.['0']?.police_station +
            ', ' +
            row?.registration?.address_infos?.['0']?.district
          );
        } else {
          return '';
        }
      },
    },
    mobile: {
      positionInForm: 3,
      label: 'Contact No',
      viewCell: (row: any) => row?.registration?.personal_info?.mobile || '',
    },
    subject_name: {
      positionInForm: 4,
      label: 'Discipline',
      viewCell: (row: any) => row?.registration?.subject_name || '',
    },

    category: {
      positionInForm: 8,
      label: 'Category',
      name: 'category',
      viewCell: (row: any) => row.category,
      type: 'select',
      default: '',
      // isMulti: false,
      isRequired: true,
      validation: Yup.string()
        .required('Category is required')
        .oneOf(['Thesis', 'Dissertation']),
      showInForm: true,

      showInSearch: true,
      colSize: 12,
      data: [
        { label: 'Thesis', value: 'Thesis' },
        { label: 'Dissertation', value: 'Dissertation' },
      ],
      disabled: true,
    },

    title_of_thesis: {
      positionInForm: 9,
      label: 'Title',
      name: 'title_of_thesis',
      viewCell: (row: any) => row.title_of_thesis,
      type: 'text',
      default: '',
      isRequired: true,
      validation: Yup.string().required('Title is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 12,
    },

    place_of_study: {
      positionInForm: 10,
      label: 'Place of study',
      name: 'place_of_study',
      viewCell: (row: any) => row.place_of_study,
      type: 'text',
      default: '',
      isRequired: true,
      disabled: true,
      // validation: Yup.string().required('Place of study is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
    },

    study_period: {
      positionInForm: 11,
      label: 'Study period',
      name: 'study_period',
      viewCell: (row: any) =>
        row.study_period ? row.study_period + ' ' + row.study_period_unit : '',
      type: 'number',
      default: 12,
      isRequired: true,
      validation: Yup.string().required('Study period is required'),
      showInForm: true,
      showInSearch: false,
      colSize: 3,
      min: '12',
    },

    study_period_unit: {
      positionInForm: 12,
      label: 'Unit',
      name: 'study_period_unit',
      type: 'select',
      default: 'Months',
      isRequired: true,
      validation: Yup.string()
        .required('Unit is required'),
      showInForm: true,

      showInSearch: true,
      colSize: 3,
      data: [
        { label: 'Day', value: 'Day' },
        { label: 'Week', value: 'Week' },
        { label: 'Months', value: 'Months' },
        { label: 'Year', value: 'Year' },
      ],
      disabled: true,
    },

    guide_id: {
      positionInForm: 13,
      label: 'Guide',
      name: 'guide_id',
      viewCell: (row: any) => row?.guide?.personal_info?.full_name || '',
      type: 'modal',
      default: '',
      isMulti: false,
      isRequired: true,
      validation: Yup.string().ensure().required('Guide is required!'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
      dataApi: {
        listAPIUrl: url.GET_GUIDE_COGUIDE_LIST + '?type=Guide&',
        displayLabel: (row: any) => row?.label || '',
        key: (row: any) =>
          row?.registration?.personal_info?.full_name
            ? row?.registration_no
            : false,
        label: (row: any) => {
          return row?.registration?.personal_info?.full_name || '';
        },
        showSearchForm: true,
        searchFormFields: [
          {
            label: 'BMDC NO',
            name: 'bmdc_registration_no',
            type: 'text',
            cell: (row: any) => row?.registration?.bmdc_registration_no || '',
            showInSearch: true,
          },

          {
            label: 'Name',
            name: 'full_name',
            type: 'text',
            cell: (row: any) =>
              row?.registration?.personal_info?.full_name || '',
            showInSearch: true,
          },

          {
            label: 'Institute Name',
            name: 'institute_name',
            type: 'text',
            cell: (row: any) =>
              row.registration?.current_employment_info?.institute_name || '',
            showInSearch: false,
          },                   

          {
            label: 'Mobile',
            name: 'mobile',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.mobile || '',
            showInSearch: true,
          },
          {
            label: 'Email',
            name: 'email',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.email || '',
            showInSearch: true,
          },

          {
            label: 'Thesis Limit',
            name: 'thesis_limit',
            // type: 'text',
            cell: (row: any) => row?.thesis_limit +' out of 6' || '',
            // showInSearch: true,
          },
        ],
      },
      data: [],
    },

    co_guide_id: {
      positionInForm: 14,
      label: 'Co-guide',
      name: 'co_guide_id',
      type: 'modal',
      default: '',
      isMulti: true,
      isRequired: false,
      validation: Yup.array()
        .ensure()
        .required('Co-guide is required!')
        .min(1, 'Select Minimum 1.')
        .max(3, 'Select Maximum 3'),
      showInForm: true,
      showInSearch: false,
      colSize: 6,
      dataApi: {
        listAPIUrl: url.GET_GUIDE_COGUIDE_LIST + '?type=Co-Guide&',
        displayLabel: (row: any) => row?.label || '',
        key: (row: any) =>
          row?.registration?.personal_info?.full_name
            ? row?.registration_no
            : false,
        label: (row: any) => {
          return row?.registration?.personal_info?.full_name || '';
        },
        showSearchForm: true,
        searchFormFields: [
          {
            label: 'BMDC NO',
            name: 'bmdc_registration_no',
            type: 'text',
            cell: (row: any) => row?.registration?.bmdc_registration_no || '',
            showInSearch: true,
          },

          {
            label: 'Name',
            name: 'full_name',
            type: 'text',
            cell: (row: any) =>
              row?.registration?.personal_info?.full_name || '',
            showInSearch: true,
          },

          {
            label: 'Institute Name',
            name: 'institute_name',
            type: 'text',
            cell: (row: any) =>
              row.registration?.current_employment_info?.institute_name || '',
            showInSearch: false,
          },

          {
            label: 'Subject',
            name: 'subject_name',
            type: 'select',
            cell: (row: any) =>
              row.registration?.subject_name || '',
            dataApi: {
              url: url.SETUP_SUBJECT_INFO_LIST,
              key: 'subject_name',
              label: 'subject_name',
              label2: '',
            },
            data: [],
            showInSearch: true,
          },

          {
            label: 'Mobile',
            name: 'mobile',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.mobile || '',
            showInSearch: true,
          },
          
          {
            label: 'Email',
            name: 'email',
            type: 'text',
            cell: (row: any) => row?.registration?.personal_info?.email || '',
            showInSearch: true,
          },
        ],
      },
      data: [],
    },

    guide_approval_note: {
      label: 'Guide approval note',
      viewCell: (row: any) => row?.guide_approval_note || '',
    },

    guide_approval_status: {
      label: 'Guide approval status',
      viewCell: (row: any) => row?.guide_approval_status || '',
    },

    co_guide_view: {
      label: 'Co-Guide',
      viewCell: (row: any) => {
        return (
          <div className="d-flex">
            {row?.co_guide_id_1 && (
              <div className="m-1 p-2 border rounded  ">
                <div>Name: {row?.co_guide_1_name || ''}</div>
                <div>Note: {row?.co_guide1_approval_note || ''}</div>
                <div>
                  Approval status: {row?.co_guide1_approval_status || ''}
                </div>
              </div>
            )}
            {row?.co_guide_id_2 && (
              <div className="m-1 p-2 border rounded">
                <div>Name: {row?.co_guide_2_name || ''}</div>
                <div>Note: {row?.co_guide2_approval_note || ''}</div>
                <div>
                  Approval status: {row?.co_guide2_approval_status || ''}
                </div>
              </div>
            )}
            {row?.co_guide_id_3 && (
              <div className="m-1 p-2 border rounded">
                <div>Name: {row?.co_guide_3_name || ''}</div>
                <div>Note: {row?.co_guide3_approval_note || ''}</div>
                <div>
                  Approval status: {row?.co_guide3_approval_status || ''}
                </div>
              </div>
            )}
          </div>
        );
      },
    },

    submission_status: {
      label: 'Submission status',
      viewCell: (row: any) => row?.submission_status || '',
    },
  },
};

export default formObj;
