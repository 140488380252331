import { PDFDocument } from "src/pages/ELogBookSummary/DocumentRender";
import { GeneralTrainingSummary } from "src/pages/ELogBookSummary/GeneralTrainingSummary";
import { SummaryOfAcademicActivities } from "src/pages/ELogBookSummary/SummaryOfAcademicActivities";
import { SummaryOfPatientManagementCaseLog } from "src/pages/ELogBookSummary/SummaryOfPatientManagementCaseLog";
import { SummaryOfProceduralAndInterpretativeSkills } from "src/pages/ELogBookSummary/SummaryOfProceduralAndInterpretativeSkills";
import { TrainingSummary } from "src/pages/ELogBookSummary/TrainingSummary";
import { TrainingSummary as TrainingSummaryForAdminAndSupervisor } from "src/pages/ELogBookSummaryForAdminAndSupervisor/TrainingSummary";
import { GeneralTrainingSummary as GeneralTrainingSummaryForAdminAndSupervisor } from "src/pages/ELogBookSummaryForAdminAndSupervisor/GeneralTrainingSummary";
import { SummaryOfPatientManagementCaseLog as SummaryOfPatientManagementCaseLogForAdminAndSupervisor } from "src/pages/ELogBookSummaryForAdminAndSupervisor/SummaryOfPatientManagementCaseLog";
import { SummaryOfProceduralAndInterpretativeSkills as SummaryOfProceduralAndInterpretativeSkillsForAdminAndSupervisor } from "src/pages/ELogBookSummaryForAdminAndSupervisor/SummaryOfProceduralAndInterpretativeSkills";
import { SummaryOfAcademicActivities as SummaryOfAcademicActivitiesForAdminAndSupervisor } from "src/pages/ELogBookSummaryForAdminAndSupervisor/SummaryOfAcademicActivities";
import { LogbookSummaryReportListForAdminAndSupervisor } from "src/pages/ELogBookSummaryForAdminAndSupervisor/LogbookSummaryReportListForAdminAndSupervisor";

export const logbookTrainingSummaryRoutes = [
  // logbook training summary
  {
    path: '/trainee/training-summary',
    component: TrainingSummary,
  },
  {
    path: '/trainee/training-summary/:userId/:sessionId',
    component: TrainingSummary,
  },
  {
    path: '/logbook-training-summary',
    component: LogbookSummaryReportListForAdminAndSupervisor,
  },
  {
    path: '/trainee/training-summary-for-admin-and-supervisor/:userId/:sessionId',
    component: TrainingSummaryForAdminAndSupervisor,
  },
  {
    path: '/trainee/training-summary/:particulars/:id',
    component: GeneralTrainingSummary,
  },
  {
    path: '/trainee/training-summary/:particulars/:id/:userId/:sessionId',
    component: GeneralTrainingSummary,
  },
  {
    path: '/trainee/training-summary-for-admin-and-supervisor/:particulars/:id/:userId/:sessionId',
    component: GeneralTrainingSummaryForAdminAndSupervisor,
  },
  {
    path: '/trainee/summary-of-patient-management-case-log',
    component: SummaryOfPatientManagementCaseLog,
  },
  {
    path: '/trainee/summary-of-patient-management-case-log/:userId/:sessionId',
    component: SummaryOfPatientManagementCaseLog,
  },
  {
    path: '/trainee/summary-of-patient-management-case-log-for-admin-and-supervisor/:userId/:sessionId',
    component: SummaryOfPatientManagementCaseLogForAdminAndSupervisor,
  },
  {
    path: '/trainee/summary-of-procedural-and-interpretative-skills',
    component: SummaryOfProceduralAndInterpretativeSkills,
  },
  {
    path: '/trainee/summary-of-procedural-and-interpretative-skills/:userId/:sessionId',
    component: SummaryOfProceduralAndInterpretativeSkills,
  },
  {
    path: '/trainee/summary-of-procedural-and-interpretative-skills-for-admin-and-supervisor/:userId/:sessionId',
    component: SummaryOfProceduralAndInterpretativeSkillsForAdminAndSupervisor,
  },
  {
    path: '/trainee/summary-of-academic-activities',
    component: SummaryOfAcademicActivities,
  },
  {
    path: '/trainee/summary-of-academic-activities/:userId/:sessionId',
    component: SummaryOfAcademicActivities,
  },
  {
    path: '/trainee/summary-of-academic-activities-for-admin-and-supervisor/:userId/:sessionId',
    component: SummaryOfAcademicActivitiesForAdminAndSupervisor,
  },
  {
    path: '/trainee/pdf-summary',
    component: PDFDocument,
  },
];
