import html2pdf from 'html2pdf.js';
import moment from 'moment';
import { useEffect } from 'react';

interface Props {
  data: any;
  setFormAId: any;
  setSubmitting?: any;
}

export const FormATraineePDF = (props: Props) => {
  const { data, setFormAId, setSubmitting } = props;
  const image1 = new Image();
  image1.src = '/images/apple-icon-180x180.png';
  const image2 = new Image();
  console.log(data);
  const html =`
    <html>
      <style>
        @media print {                    
          body {
            margin: 0;
            color: #000;
            background-color: #fff;
          }           
        }
        .fontName {
          font-family: "Times New Roman", Times, serif;
          line-height: 1.5;
        }

        .box {
          width: 100px;
          height: 25px;
          border: 1px solid black;
        }        

      </style>
      <body>

        <div class="fontName">
          <div class="row">
            <div class="col-md-8" style="text-align: center; margin-left: 100px">
              <h4>BANGLADESH COLLEGE OF PHYSICIANS AND SURGEONS (BCPS)</h4>
            </div>
            <div class="box text-center col-md-2">
              <p>Form A</p>
            </div>
          </div>
          <div class="mt-3">
            <div class="d-flex justify-content-center mb-1">
                <span class="text-decoration h6">গবেষণা (ডিজার্টেশন/থিসিস) বাবদ সহায়তা (পারিতোষিক) বিল প্রদান সংক্রান্ত ফরম ।</span>
            </div>


            <div>
              <div class="row">
                  <div class="col-12">
                      <span><b>ক.</b> ১) গবেষকের নাম (বাংলায়) : ${data?.researcher_name_in_bangla ?? ''}</span>                   
                  </div>
              </div>
              <div class="row">
                  <div class="col-12">
                      <span>২) গবেষকের নাম (ইংরেজী) Capital letter : ${data?.registration?.personal_info?.full_name?.toUpperCase() ?? ''}</span>                  
                  </div>
              </div>
              <div class="row">
                  <div class="col-12">
                      <span>৩) গবেষণা/ডিজার্টেশন/থিসিস শিরোনাম : ${data?.title?.title_of_thesis ?? ''}</span>                 
                  </div>
              </div>
              <div class="row">
                  <div class="col-6">
                      <span>৪) বিএমডিসি রেজিস্ট্রেশন নম্বর : ${data?.registration?.bmdc_registration_no ?? ''}</span>                                                                        
                  </div>
                  <div class="col-6">
                      <span>৫) ফ্যাকাল্টি/বিভাগ : ${data?.registration?.subject_name ?? ''}</span>                                                
                  </div>
              </div>
              <div class="row">
                  <div class="col-6">
                      <span>৬) মোবাইল নম্বর : ${data?.registration?.personal_info?.mobile ?? ''}</span>                                                 
                  </div>
                  <div class="col-6">
                      <span>৭) ইমেইল : ${data?.registration?.personal_info?.email ?? ''}</span>                                              
                  </div>
              </div>
              
              <div class="row">
                  <div class="col-6">
                      <span>৮) ব্যাংকের নাম : ${data?.bank_info?.bank_name ?? ''}</span>                                                  
                  </div>
                  <div class="col-6">
                      <span>৯) শাখা : ${data?.bank_info?.branch_name ?? ''}</span>                                                
                  </div>
              </div>
              <div class="row">
                  <div class="col-6">
                      <span>১০) ব্যাংক হিসাব নম্বর : ${data?.bank_info?.account_no ?? ''}</span>                                                 
                  </div>
                  <div class="col-6">
                      <span>১১) রাউটিং নম্বর : ${data?.bank_info?.routing_no ?? ''}</span>                                              
                  </div>
              </div>
            </div>


            <div>
              <div class="row">
                  <div class="col-6">
                      <span><b>খ.</b> ১) গাইডের নাম (ইংরেজীতে) : ${data?.title?.guide_name ?? ''}</span>                                                  
                  </div>
                  <div class="col-6">
                      <span>২) মোবাইল নং : ${data?.title?.guide?.personal_info?.mobile ?? ''}</span>                                                
                  </div>
              </div>
              <div class="row">
                  <div class="col-6">
                      <span>৩) ব্যাংকের নাম : ${data?.guide_bank_info?.bank_name ?? ''}</span>
                  </div>
                  <div class="col-6">
                      <span>৪) শাখা : ${data?.guide_bank_info?.branch_name ?? ''}</span>
                  </div>
              </div>
              <div class="row">
                  <div class="col-6">
                      <span>৫) ব্যাংক হিসাব নম্বর : ${data?.guide_bank_info?.account_no ?? ''}</span>
                  </div>
                  <div class="col-6">
                      <span>৬) রাউটিং নম্বর : ${data?.guide_bank_info?.routing_no ?? ''}</span>
                  </div>
              </div>
            </div>


            ${data?.co_guide1_bank_info ? 
            `<div>
              <div class="row">
                  <div class="col-6">
                      <span><b>গ.</b> ১) কো-গাইডের নাম (ইংরেজীতে) (প্রযোজ্য ক্ষেত্রে) : ${data?.title?.co_guide_1_name ?? ''}</span>  
                  </div>
                  <div class="col-6">
                      <span>২) মোবাইল নং : ${data?.title?.co_guide_1?.personal_info?.mobile ?? ''}</span> 
                  </div>
              </div>
              <div class="row">
                  <div class="col-6">
                      <span>৩) ব্যাংকের নাম : ${data?.co_guide1_bank_info?.bank_name ?? ''}</span> 
                  </div>
                  <div class="col-6">
                      <span>৪) শাখা : ${data?.co_guide1_bank_info?.branch_name ?? ''}</span>
                  </div>
              </div>
              <div class="row">
                  <div class="col-6">
                      <span>৫) ব্যাংক হিসাব নম্বর : ${data?.co_guide1_bank_info?.account_no ?? ''}</span>
                  </div>
                  <div class="col-6">
                      <span>৬) রাউটিং নম্বর : ${data?.co_guide1_bank_info?.routing_no ?? ''}</span>
                  </div>
              </div>
            </div>`
            : `<div></div>`
          }

            <div class="row mt-2">
                <div class="col-12">
                    <p>
                      উপরোক্ত গবেষণা/ডিজার্টেশন/থিসিস (Agreement) সংযুক্ত চুক্তিপত্র অনুযায়ী চুড়ান্ত রিপোর্ট জমা দিয়েছি, যাবতীয় ব্যয় নির্বাহের জন্য অনুমোদিত বাজেট বিভাজন মোতাবেক বিসিপিএস হতে মোট ৫০,০০০/- (পঞ্চাশ হাজার) টাকা মাত্র বুঝিয়া পাইলাম ।                    
                    </p>
                </div>
            </div>
            <div class="row justify-content-end mb-1">
                <div class="col-3">
                    <span>গবেষকের স্বাক্ষর :..........</span>
                    <hr />
                    <span>গবেষকের সীল ও তারিখ</span>
                </div>
            </div>

            <div class="d-flex justify-content-center mb-2">
                <span class="text-decoration h6">আরটিএমডি ও প্রশাসন বিভাগের প্রতিবেদন</span>
            </div>
            <div class="row">
                <div class="col-12">
                    <span>ডিএসএন:................................................................. গবেষণার সমাপ্তিকাল:................................................................</span>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-12">
                    <span>উপরোক্ত গবেষণা (ডিজার্টেশন/থিসিস) বাবদ রিপোর্ট জমা দিয়েছে। দাখিলকৃত প্রতিবেদন, তথ্য প্রমাণাদি সঠিক পাওয়া গেছে বিধায় গবেষককে ৫০,০০০/- (পঞ্চাশ হাজার) টাকা বিল প্রদান করা যেতে পারে।</span>
                </div>
            </div>
            <div class="row justify-content-between mb-2">
              <div class="col-3 text-center">
                  <span>বিভাগীয় কর্মকর্তা (RTMD)</span>
              </div>
              <div class="col-3 text-center">
                  <span>উপ পরিচালক (প্রশাসন)</span>
              </div>
              <div class="col-3 text-center">
                  <span>পরিচালক (প্রশাসন)</span>
              </div>
              <div class="col-3 text-center">
                  <span>পরিচালক (RTM)</span>
              </div>
            </div>
            <div class="d-flex justify-content-center mb-1">
                <span class="text-decoration h6">অর্থ ও হিসাব বিভাগে ব্যবহারের জন্য</span>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <p>
                      উক্ত গবেষণা/ডিজার্টেশন বাবদ দাখিলকৃত বিল, লাইন ডাইরেক্টর, এইচইএমপিডি, স্বাস্থ্য অধিদপ্তর থেকে প্রাপ্ত অর্থ (কোড নং ৩২৫৭১০৩) হতে আরটিএমডি এর প্রতিবেদন মোতাবেক মোট ৫০,০০০/- (পঞ্চাশ হাজার) টাকা মাত্র মঞ্জুর করা হলো এবং বিএফটিএন এর মাধ্যমে উল্লেখিত ব্যাংক হিসাব সমূহে পরিশোধ করা হইল।
                    </p>
                </div>
            </div>
            
            <div class="row justify-content-between">
                <div class="col-3 text-center">
                    <span>হিসাবরক্ষণ কর্মকর্তা</span>
                </div>
                <div class="col-3 text-center">
                    <span>উপ প্রধান হিসাবরক্ষণ কর্মকর্তা</span>
                </div>
                <div class="col-3 text-center">
                    <span>অনারারি সচিব</span>
                </div>
                <div class="col-3 text-center">
                    <span>কোষাধ্যক্ষ</span>
                </div>
            </div>
          </div>
        </div>
      </body>
    </html>`
    ;

  useEffect(() => {
    setFormAId('');
    setSubmitting(false);
  }, []);

  const options = {
    margin: [10, 10, 10, 10],
    image: { type: 'png', quality: 0.98 },
    html2canvas: { scale: 2, useCORS: true },
  };

  html2pdf()
    .from(html)
    .set(options)
    .toPdf()
    .get('pdf')
    .then(function (pdf: any) {
      var totalPages = pdf.internal.getNumberOfPages();

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10);
        pdf.setTextColor(150);
        pdf.addImage(image1, "PNG", 10, 10, 20, 20);
      }

      window.open(pdf.output('bloburl'), '_blank');
    });

  return null;
};
