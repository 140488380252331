import { useFormik } from "formik";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { Button, InputField } from "src/components";
import { config } from "src/config";
import { setFromErrors } from "src/helpers";
import { usePartCResearchProjectEntryMutation } from "src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi";
import * as Yup from 'yup';

interface ResearchProjectConductedByTheDepartmentProps {
    departmentId?: string;
    recordOfResearchProjectToEdit?: GetResearchProjectConductedByTheDepartmentEntry;
    setModalShow?: any;
}

const initialValues: ResearchProjectConductedByTheDepartmentEntry = {
    form_b_department_id: '',
    research_project_id: '',
    name_of_project: '',
    sponsoring_agency: '',
    project_status: '',
};

const ResearchProjectConductedByTheDepartmentModal = (props: ResearchProjectConductedByTheDepartmentProps) => {
    const { departmentId, recordOfResearchProjectToEdit, setModalShow } = props;

    const [researchProjectEntry, { data: researchProjectEntryData, isSuccess: isResearchProjectEntryDataSuccess, isLoading: isResearchProjectEntryDataLoading, isError: isResearchProjectEntryDataError, error: researchProjectEntryDataError }] =
        usePartCResearchProjectEntryMutation();

    const err: CustomError = researchProjectEntryDataError as CustomError;

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({}),

        onSubmit: (values: any) => {
            // console.log(values);
            values.form_b_department_id = departmentId;
            researchProjectEntry(values);
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    const handleFormReset = () => {
        resetForm();
    };

    useEffect(() => {
        if (recordOfResearchProjectToEdit?.id) {
            setValues({
                form_b_department_id: recordOfResearchProjectToEdit?.form_b_department_id,
                research_project_id: recordOfResearchProjectToEdit?.id,
                name_of_project: recordOfResearchProjectToEdit?.name_of_project,
                sponsoring_agency: recordOfResearchProjectToEdit?.sponsoring_agency,
                project_status: recordOfResearchProjectToEdit?.project_status,
            });
        } else {
            setFieldValue('project_status', 'On-going');
        }
    }, []);

    useEffect(() => {
        if (isResearchProjectEntryDataSuccess) {
            handleFormReset();
            toast.success('Research coducted by the department saved successfully');
            setModalShow(false);
        }
        if (isResearchProjectEntryDataError) {
            Object.entries(err?.data?.errors).forEach((entry: any) => {
                entry[1]?.forEach((message: any) => {
                    toast.error(message);
                });
            });
            setFromErrors(err.data, setErrors);
        }
    }, [isResearchProjectEntryDataSuccess, isResearchProjectEntryDataError]);

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title">
                                    <i className="fa fa-plus me-2" />
                                    Research project conducted by the department
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    autoComplete="off"
                                    className="custom-form"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    onReset={(e) => {
                                        e.preventDefault();
                                        handleFormReset();
                                    }}
                                >
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="name_of_project">Name of the project</Label>
                                                <InputField
                                                    type="text"
                                                    placeholder=""
                                                    {...getFieldProps('name_of_project')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="sponsoring_agency">Sponsoring agency</Label>
                                                <InputField
                                                    type="text"
                                                    placeholder=""
                                                    {...getFieldProps('sponsoring_agency')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12}>
                                            <FormGroup row className="mb-3">
                                                <Label className="font-size-14 mb-3" sm={3}>
                                                    On-going/proposed:
                                                </Label>
                                                <Col sm={3}>
                                                    <div className="d-inline-block mx-2 mt-2">
                                                        <div className="form-check form-check-left mb-3">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="OnGoingOrProposed"
                                                                id="OnGoingOrProposedOnGoing"
                                                                value={'On-going'}
                                                                checked={values.project_status === 'On-going'}
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setFieldValue('project_status', 'On-going');
                                                                    }
                                                                }}
                                                                defaultChecked
                                                            />
                                                            <label className="form-check-label" htmlFor="OnGoingOrProposedOnGoing">On-going</label>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col sm={3}>
                                                    <div className="d-inline-block mx-2 mt-2">
                                                        <div className="form-check form-check-left">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="OnGoingOrProposed"
                                                                id="OnGoingOrProposedProposed"
                                                                value={'Proposed'}
                                                                checked={values.project_status === 'Proposed'}
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setFieldValue('project_status', 'Proposed');
                                                                    }
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor="OnGoingOrProposedProposed">Proposed</label>
                                                        </div>
                                                    </div>
                                                </Col>                                                
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} className="mt-3">
                                            <FormGroup className="mb-3 text-center">
                                                <Button
                                                    type="reset"
                                                    text="Reset"
                                                    className="btn btn-warning me-2"
                                                ></Button>
                                                <Button
                                                    type="submit"
                                                    text="Save"
                                                    className="btn btn-success me-2"
                                                    disabled={isResearchProjectEntryDataLoading}
                                                ></Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default ResearchProjectConductedByTheDepartmentModal;