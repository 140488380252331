import { apiSlice } from 'src/rtk/api/apiSlice';
import * as roomUrl from 'src/helpers/url_helper';

export const setupRoomApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['Rooms'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getRooms: builder.query<APIResponse<Room[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${roomUrl.GET_ROOMS}`,
        }),
        providesTags: ['Rooms'],
      }),

      getRoom: builder.query<APIResponse<Room>, string>({
        query: (id) => ({
          url: `${roomUrl.GET_ROOM}/${id}`,
          method: 'GET',
        }),
        providesTags: ['Rooms'],
      }),

      createRoom: builder.mutation<APIResponse<Room>, Room>({
        query: (data) => ({
          url: `${roomUrl.CREATE_ROOM}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['Rooms'],
      }),

      updateRoom: builder.mutation<APIResponse<Room>, string>({
        query: (url) => ({
          url: `${roomUrl.UPDATE_ROOM}` + url,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['Rooms'],
      }),

      deleteRoom: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${roomUrl.DELETE_ROOM}/${id}`,
        }),
        invalidatesTags: ['Rooms'],
      }),
    }),
  });

export const {
  useCreateRoomMutation,
  useGetRoomsQuery,
  useGetRoomQuery,
  useUpdateRoomMutation,
  useDeleteRoomMutation,
} = setupRoomApi;
