import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Progress } from 'reactstrap';
import { Button } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import {
    useGetUserSlotInfoForTraineeQuery,
    useGetTrainingSummaryByIdQuery,
} from 'src/rtk/features/logbookTrainingSummary/logbookTrainingSummaryApi';
import { useGetPersonalProfileQuery } from 'src/rtk/features/trainee/traineeApi';
import loader from '../../assets/images/loader.gif';

export const GeneralTrainingSummary = () => {

    const { particulars, id, userId, sessionId } = useParams<{ particulars: string; id: string; userId: string; sessionId: string }>();

    const history = useHistory();

    const [trainingSummaryList, setTrainingSummaryList] = useState<
        LogbookTrainingSummary[]
    >([]);

    const {
        data: personalProfile,
        isError: isPersonalProfileError,
        isLoading: isPersonalProfileLoading
    } = useGetPersonalProfileQuery();

    const {
        data: userSlotData,
        isLoading: isUserSlotDataLoading,
        isSuccess: isUserSlotDataSuccess,
        isError: isUserSlotDataError,
        error: userSlotDataError,
    } = useGetUserSlotInfoForTraineeQuery(userId ? userId : (personalProfile?.data as any)?.id!);

    const {
        data: trainingSummary,
        isLoading: isTrainingSummaryLoading,
        isSuccess: isTrainingSummarySuccess,
        isFetching: isTrainingSummaryFetching
    } = useGetTrainingSummaryByIdQuery({ id: id!, userId: userId ? userId : (personalProfile?.data as any)?.id!, sessionId: sessionId ? sessionId : userSlotData?.data?.log_entry_current_session?.id! });

    useEffect(() => {
        if (trainingSummary && isTrainingSummarySuccess) {
            const tData = trainingSummary?.data || [];
            setTrainingSummaryList(tData);
        }
    }, [trainingSummary, isTrainingSummarySuccess]);

    const columns: TableColumn<any>[] = [
        {
            name: 'Sl. No.',
            cell: (row, index) => index + 1,
            width: '80px',
        },

        {
            name: 'Form',
            selector: (row) => row?.particulars,
            sortable: false,
            wrap: true,
        },

        {
            name: 'Minimum to be Performed',
            selector: (row) => row?.log_entry_target![0]?.min_value !== undefined ? row?.log_entry_target![0]?.min_value : row?.total_min_target,
            sortable: false,
            center: true,
        },

        {
            name: 'Total Performed',
            selector: (row) => row?.total,
            sortable: false,
            center: true,
        },

        {
            name: 'Percent Performed',
            width: '200px',
            cell: (row) => {
                const percentageValue: number = +(row?.log_entry_target![0]?.min_value !== undefined ? row?.log_entry_target![0]?.min_value : row?.total_min_target)
                    ? (row?.total * 100) / +(row?.log_entry_target![0]?.min_value !== undefined ? row?.log_entry_target![0]?.min_value : row?.total_min_target)
                    : 0;
                return (
                    <div className="" style={{ width: '700px', position: 'relative' }}>
                        <Progress
                            color={
                                percentageValue < 50
                                    ? 'danger'
                                    : percentageValue <= 99
                                        ? 'warning'
                                        : 'success'
                            }
                            value={percentageValue}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    textAlign: 'center',
                                    lineHeight: '10px',
                                    color: 'black',
                                    overflow: 'hidden',
                                    right: 0,
                                    left: 0,
                                    top: 0,
                                }}
                            >
                                {percentageValue.toFixed(2)}%
                            </div>
                        </Progress>
                    </div>
                );
            },
        },

        {
            name: 'Action',
            cell: (row) => (
                <>

                    <Button
                        size="sm"
                        color="info"
                        type="button"
                        disabled={
                            row?.particulars?.trim() !== 'In-Patient Management' &&
                            row?.particulars?.trim() !== 'Out-Patient Management' &&
                            row?.particulars?.trim() !== 'Emergency Patient Management' &&
                            row?.particulars?.trim() !== 'Mandatory Lectures attended' &&
                            row?.particulars?.trim() !== 'Lectures attended Other' &&
                            row?.particulars?.trim() !== 'teaching attended Tutorials/small group' &&
                            row?.particulars?.trim() !== 'Mortality/morbidity meeting presentations/Clinical audit' &&
                            row?.particulars?.trim() !== 'Journal club presentation' &&
                            row?.particulars?.trim() !== 'Grand round/ clinical meeting presentations' &&
                            row?.particulars?.trim() !== 'Mandatory courses/ Workshops attended' &&
                            row?.particulars?.trim() !== 'Papers/ posters presented in seminars/ symposium / conferences' &&
                            row?.particulars?.trim() !== 'Conferences/ seminar/symposium/workshop, CME, etc. attended' &&
                            row?.particulars?.trim() !== 'Papers/abstracts published in BMDC approved journals' &&
                            row?.entry_setup_master_for_log?.length === 0
                        }
                        text={`View`}
                        onClick={() => {
                            if (
                                row?.particulars === 'In-Patient Management' ||
                                row?.particulars === 'Out-Patient Management' ||
                                row?.particulars === 'Emergency Patient Management'
                            ) {
                                history.push(`/trainee/logbook-entry/${row?.particulars}`);
                            } else if (
                                row?.particulars === 'Mandatory Lectures attended' ||
                                row?.particulars === 'Lectures attended Other' ||
                                row?.particulars === 'teaching attended Tutorials/small group' ||
                                row?.particulars === 'Mortality/morbidity meeting presentations/Clinical audit' ||
                                row?.particulars === 'Journal club presentation' ||
                                row?.particulars === 'Grand round/ clinical meeting presentations' ||
                                row?.particulars === 'Mandatory courses/ Workshops attended' ||
                                row?.particulars === 'Papers/ posters presented in seminars/ symposium / conferences' ||
                                row?.particulars === 'Conferences/ seminar/symposium/workshop, CME, etc. attended' ||
                                row?.particulars === 'Papers/abstracts published in BMDC approved journals'
                            ) {
                                history.push(
                                    `/trainee/logbook-entry/academic-activities/${row?.particulars?.replaceAll('/', '%2F').replaceAll(',', '%2C').replaceAll('.', '%2E')}`,
                                );
                            } else {
                                history.push(`/trainee/training-summary/${row?.particulars}/${row?.id}/${userId ? userId : (personalProfile?.data as any)?.id!}/${sessionId ? sessionId : userSlotData?.data?.log_entry_current_session?.id!}`);
                            }
                        }}
                    />

                </>
            ),

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <div className="page-content">
            {isTrainingSummaryLoading || isTrainingSummaryFetching ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Container fluid>
                <div className='page-breadcrumb-wrapper'>
                    <BetterBreadcrumb
                        title={`Summary of ${particulars}`}
                        breadcrumbItem={
                            particulars === 'Academic Activity' ?
                                [
                                    { link: '/trainee/logbook/academic-activities', name: 'Academic Activities Entry' },
                                ]
                                :
                                [
                                    { link: '/trainee/logbook-entry', name: 'Logbook Entry' },
                                ]
                        }
                    />
                </div>

                <div className="row align-items-center justify-content-center">
                    <div className="card">
                        <div className="card-title rounded text-center bg-secondary text-white py-2 my-2">
                            Summary of {particulars}
                        </div>
                        <div className="card-body">
                            <DataTable
                                columns={columns}
                                data={trainingSummaryList}
                                // progressPending={isTrainingSummaryLoading}
                                expandOnRowClicked
                                highlightOnHover
                                responsive
                            />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};
