import html2pdf from 'html2pdf.js';
import moment from 'moment';
import { useEffect } from 'react';

export const CBDPDFWrapper = (props: any) => {

    const { data, cbdAssessmentData, performanceNumber, options, setPrintId, setFlag } = props;

    useEffect(() => {
        setFlag('');
        setPrintId('');
    }, []);

    const feedBackTrainee = data?.feadbacks?.find((item: any) => item?.type === 'cbd-trainee');
    const feedBackAssesors = data?.feadbacks?.find((item: any) => item?.type === 'cbd-supervisor');

    let html = `
            <html>
                <style>   
                    
                    body {
                        margin: 0;
                        color: #000;
                        background-color: #fff;
                    }

                    .rounded-pill{
                        padding-right: .6em;
                        padding-left: .6em;
                    } 

                    .assessmentDefaultNumber {
                        border: 1px solid black;
                        border-radius: 100px;
                        padding-left: 5px;
                        padding-right: 5px;
                    }

                    .assessmentColoredNumber {
                        border: 1px solid black;
                        border-radius: 100px;
                        padding-left: 5px;
                        padding-right: 5px;
                        background-color: green;
                        color: white;
                    }

                </style>  
                        
                <div>
                    <div class='text-center mb-2 mt-2 font-size-22'><strong>Case Based Discussion (CBD)</strong></div>
                    
                    <div class='row justify-content-between'>
                        <div class='col-sm-6'>                               
                            <p><strong>Name of the Institution: ${data?.trainee_info?.current_session_and_supervisor_info?.supervisor?.institute_name ?? ''}</strong></p>
                        </div>
                        <div class='col-sm-5'>                         
                            <p><strong>Date of Examination: ${moment(cbdAssessmentData?.date_of_assessment).format('DD/MM/YYYY') ?? ''}</strong></p>                                                                       
                        </div>
                    </div>

                    <div class='row mb-3'>
                        <span><strong>------------------------------------------------------------------------------------------------------------------------</strong></span>
                    </div>

                    <div class='mb-3'><strong>1. Trainee's Information</strong></div>
                    <div class='row justify-content-between mb-4'>
                        <div class='col-sm-6'>
                            <div>Name of the Trainee: ${data?.trainee_info?.personal_info?.full_name ?? ''}</div>
                            <div>BMDC Reg No: ${data?.trainee_info?.bmdc_registration_no ?? ''}</div>                            
                            <div>Speciality: ${data?.trainee_info?.subject?.subject_name ?? ''}</div>
                        </div>
                        <div class='col-sm-4'>                            
                            <div>Program: FCPS Part-2</div>
                            <div>Years of Training: ${data?.trainee_info?.current_session_and_supervisor_info?.slot_info?.name ?? ''}</div>
                        </div>
                    </div>

                    <div class='mb-3'><strong>2. Assessor’s Information</strong></div>
                    <div class='row justify-content-between mb-4'>
                        <div class='col-sm-6'>
                            <div>Name of the assessor: ${data?.trainee_info?.current_session_and_supervisor_info?.supervisor?.name ?? ''}</div>
                            <div>Assessor’s Speciality: ${data?.trainee_info?.current_session_and_supervisor_info?.supervisor?.department_name ?? ''}</div>
                            <div>Assessor’s position: ${data?.trainee_info?.current_session_and_supervisor_info?.supervisor?.designation ?? ''}</div>
                        </div>
                        <div class='col-sm-4'>
                            <div>Assessor’s phone number: ${data?.trainee_info?.current_session_and_supervisor_info?.supervisor?.mobile ?? ''}</div>
                            <div>Assessor’s email: ${data?.trainee_info?.current_session_and_supervisor_info?.supervisor?.email ?? ''}</div>
                        </div>
                    </div>
                    <div class='mb-3'><strong>3. Patient's Information</strong></div>
                    <div>
                        <div class='row justify-content-between mb-4'>
                            <div class='col-sm-6'>
                                <div>Patient Name: ${cbdAssessmentData?.patient_name ?? ''}</div>
                                <div>Address of the Patient: ${cbdAssessmentData?.patient_address ?? ''}</div>
                                <div>Diagnosis/Problem: ${cbdAssessmentData?.diagnosis_of_the_case ?? ''}</div>                                
                            </div>
                            <div class='col-sm-4'>
                                <div>Reg. No. of the Patient: ${cbdAssessmentData?.patient_reg_no ?? ''}</div>
                                <div>Age: ${cbdAssessmentData?.patient_age ?? ''}</div>
                                <div>Gender: ${cbdAssessmentData?.patient_gender ?? ''}</div>      
                                <div>Setting: ${cbdAssessmentData?.competency_setting ?? ''}</div>                          
                                <div>Complexity: ${cbdAssessmentData?.complexity_level ?? ''}</div>                                
                            </div>
                        </div>
                        <div class='mb-3'><strong>4. Detail Description of Case:</strong> (Provided by the trainee)</div>
                        <div>                            
                            <div class='row mb-4'>
                                <div class='col-sm-12'>
                                    <div>${cbdAssessmentData?.case_description ?? ''}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='mb-3'><strong>5. Input Validation:</strong></div>
                    <div>
                        <div class='row mb-4'>
                            <div class='col-sm-12'>
                                <div>Input validated by assessor/supervisor : ${cbdAssessmentData?.assessments?.input_validation_supervisor === 1 ? `<span><strong>${'Verified'}</strong></span>` : `<span><strong>${'Not Verified'}</strong></span>`}</div> 
                            </div>
                        </div>                        
                    </div>
                    <div class='mt-2'>
                        <div class='mb-3'><strong>6. Assesment:</strong></div>
                        <table class='table table-bordered table-sm border-dark'>
                            <thead>
                                <tr>
                                    <th class='text-dark text-center'>Sl.</th>
                                    <th class='text-dark text-center'>Competencies</th>
                                    <th class='text-dark text-center'>Unsatisfactory</th>
                                    <th class='text-dark text-center'>Satisfactory</th>
                                    <th class='text-dark text-center'>Superior</th>
                                    <th class='text-dark text-center'>Not Observed</th>
                                    <th class='text-dark text-center'>Remarks/Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${cbdAssessmentData?.assessments?.details?.map((item: any, index: number) =>
        `<tr key=${item.id}>
                                    <td class='text-dark'>${++index}</td>
                                    <td class='text-dark'>${item?.competence}</td>
                                    <td class='text-center text-dark'>
                                        ${+item?.perform === 1 ? `<span class='assessmentColoredNumber'>1</span>` : `<span class='assessmentDefaultNumber'>1</span>`}
                                        ${+item?.perform === 2 ? `<span class='assessmentColoredNumber'>2</span>` : `<span class='assessmentDefaultNumber'>2</span>`}
                                        ${+item?.perform === 3 ? `<span class='assessmentColoredNumber'>3</span>` : `<span class='assessmentDefaultNumber'>3</span>`}
                                    </td>
                                    <td class='text-center text-dark'>
                                        ${+item?.perform === 4 ? `<span class='assessmentColoredNumber'>4</span>` : `<span class='assessmentDefaultNumber'>4</span>`}
                                        ${+item?.perform === 5 ? `<span class='assessmentColoredNumber'>5</span>` : `<span class='assessmentDefaultNumber'>5</span>`}
                                        ${+item?.perform === 6 ? `<span class='assessmentColoredNumber'>6</span>` : `<span class='assessmentDefaultNumber'>6</span>`}
                                    </td>
                                    <td class='text-center text-dark w-25'>
                                        ${+item?.perform === 7 ? `<span class='assessmentColoredNumber'>7</span>` : `<span class='assessmentDefaultNumber'>7</span>`}
                                        ${+item?.perform === 8 ? `<span class='assessmentColoredNumber'>8</span>` : `<span class='assessmentDefaultNumber'>8</span>`}
                                        ${+item?.perform === 9 ? `<span class='assessmentColoredNumber'>9</span>` : `<span class='assessmentDefaultNumber'>9</span>`}
                                    </td>
                                    <td class='text-center text-dark'>${+item?.perform === 0 ? 'Yes' : '-'}</td>
                                    <td class='text-dark'>${item?.descriptor ?? ''}</td>
                                </tr>`
    ).join('')}
                                <tr>
                                    <td class='text-dark' colspan="2">Total Score</td>
                                    <td colspan="5">${cbdAssessmentData?.assessments?.total_score}</td>                  
                                </tr>
                                <tr>
                                    <td class='text-dark' colspan="2">Average Score</td>
                                    <td colspan="5">${cbdAssessmentData?.assessments?.avg_score}</td>                           
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class='mt-3'>
                        <p><strong>7. Overall Perfromance : ${cbdAssessmentData?.assessments?.overall_performance ?? ''}</strong></p>
                    </div>

                    <div class='border border-dark mt-1 py-1 px-2'>
                        <p><strong>Comment of Areas of Strength of the trainee:</strong></p>
                        <p>${cbdAssessmentData?.assessments?.areas_of_strength ?? ''}</p>
                    </div>
                    <div class='border border-top-0 border-dark py-1 px-2'>
                        <p><strong>Suggestions for further Development/Improvement:</strong></p>
                        <p>${cbdAssessmentData?.assessments?.suggestions_for_development}</p>
                    </div>                   

                    <div class='row mt-2'>
                        <div class='col-sm-12 text-center'>                            
                            <span class='me-5'><strong>Total CBD Evaluation Time: ${cbdAssessmentData?.assessments?.evaluation_time ?? ''} Minutes</strong></span>
                            <span><strong>Time spent giving Feedback: ${cbdAssessmentData?.assessments?.feedback_time ?? ''} Minutes</strong></span>                                   
                        </div>
                    </div>

                    <div class='row mt-4'>
                        <div class='col-sm-4'>
                            <Label>Assessor satisfaction using CBD</Label>
                        </div>
                        <div class='col-sm-8'>
                            <span className='fw-bold'>LOW</span>
                            ${performanceNumber?.map((item: any) => {
                                return (
                                    item === feedBackAssesors?.satisfactory_label ? `<span class='assessmentColoredNumber me-3'>${item}</span>` : `<span class='assessmentDefaultNumber me-3'>${item}</span>`
                                )
                            }).join('')}
                            <span className='fw-bold'>HIGH</span>
                        </div>
                    </div>

                    <div class='row'>
                        <div class='col-sm-4'>
                            <Label>Trainee satisfaction using CBD</Label>
                        </div>
                        <div class='col-sm-8'>
                            <span className='fw-bold'>LOW</span>
                            ${performanceNumber?.map((item: any) => {
                                return (
                                    item === feedBackTrainee?.satisfactory_label ? `<span class='assessmentColoredNumber me-3'>${item}</span>` : `<span class='assessmentDefaultNumber me-3'>${item}</span>`
                                )
                            }).join('')}
                            <span className='fw-bold'>HIGH</span>
                        </div>
                    </div>

                    <div class='row justify-content-between mt-5'>
                        <div class='col-sm-4'>
                            <div class='mb-3'>............................................................</div>
                            <div>Assessor’s Signature with date </div>
                            <div>Name : ${data?.trainee_info?.current_session_and_supervisor_info?.supervisor?.name}</div>
                            <div>Designation : ${data?.trainee_info?.current_session_and_supervisor_info?.supervisor?.designation}</div>
                            <div>Institute : ${data?.trainee_info?.current_session_and_supervisor_info?.supervisor?.institute_name}</div>
                        </div>
                        <div class='col-sm-4'>
                            <div class='mb-3'>............................................................</div>
                            <div>Trainee's Signature with date </div>
                            <div>Name : ${data?.trainee_info?.personal_info?.full_name}</div>
                            <div>Specialty : </div>
                            <div>Institute : ${data?.trainee_info?.current_session_and_supervisor_info?.supervisor?.institute_name}</div>
                        </div>
                    </div>
                </div> 
            </html>               
        `;
        
    html2pdf()
        .from(html)
        .set(options)
        .toPdf()
        .get('pdf')
        .then(function (pdf: any) {
            var totalPages = pdf.internal.getNumberOfPages();

            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                pdf.setFontSize(10);
                pdf.setTextColor(150);
                pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() / 2.3), (pdf.internal.pageSize.getHeight() - 2.0));
                // pdf.addImage(image1, "PNG", 0, 0, pdf.internal.pageSize.getWidth(), 20);
                // pdf.addImage(image2, "PNG", 0, pdf.internal.pageSize.getHeight() - 10, pdf.internal.pageSize.getWidth(), 10);
            }

            window.open(pdf.output('bloburl'), '_blank');
        });

    return null;
};