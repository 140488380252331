import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import { useCreateMoneyRecieptMutation } from 'src/rtk/features/researchClinicSetup/researchClinicSetupApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

const initialValues = {
    money_receipt_id: '',
    registration_no: '',
    research_grant_info_id: '',
    from_date: '',
    to_date: '',
    day_count: '',
    rate: 0,
    amount: '',
    submission_status: '',
};

interface Props {
    isOpen: boolean;
    toggle: any;
    info: any;
}

const EditMoneyReceiptModal = (props: Props) => {
    const { isOpen, toggle, info } = props;

    const [createMoneyReciept, { isSuccess, isError, error, isLoading: isCreateLoading }] = useCreateMoneyRecieptMutation();
    const [btnClicked, setBtnClicked] = useState<string>('');

    const [startDate, setStartDate] = useState<string>('');
    const [daysDifference, setDaysDifference] = useState<any>('');
    const [amount, setAmount] = useState<number>(0);

    const buttonRef = useRef<any>();
    const draftButtonRef = useRef<any>();

    const allRole: SelectOption[] = [
        { value: 'Trainee', label: 'Trainee' },
        { value: 'Guide', label: 'Guide' },
        { value: 'Co-Guide', label: 'Co-Guide' },
    ]

    const err: CustomError = error as CustomError;

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setValues,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({
            from_date: Yup.date().required('Start Date is Required!'),
            to_date: Yup.date().when("start_date", (from_date, schema) => {
                if (from_date) {
                    const fromDate = new Date(from_date);

                    return schema.required('To date is Required').min(fromDate, 'To date must be greater than or equal start date').nullable();
                }

                return schema;
            }),
            day_count: Yup.string().required('Days Count is Required!'),
            rate: Yup.string().required('Rate is Required!'),
            amount: Yup.string().required('Amount is Required!'),
        }),

        onSubmit: (values: any) => {
            const formData = new FormData();
            Object.keys(values).forEach((key) => {
                if (key === 'submission_status') {
                    if (btnClicked === 'submit') {
                        values[key] = 'Submit';
                    } else if (btnClicked === 'save_as_draft') {
                        values[key] = 'Draft';
                    }
                }
                formData.append(key, values[key]);
            });
            createMoneyReciept(values);
        },
    });

    useEffect(() => {
        if (isSuccess) {
            toast.success('Money Receipt Updated Successfully!');
            setDaysDifference('');
            setAmount(0);
            toggle();
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);

    const handleStartDate = (e: any) => {
        setStartDate(e.target.value);
    };

    const handleCalculateDays = (e: any) => {
        const from = new Date(startDate);
        const to = new Date(e.target.value);

        if (from <= to) {
            const differenceInTime = to.getTime() - from.getTime();
            const differenceInDays = differenceInTime / (1000 * 3600 * 24);
            setDaysDifference(Math.round(differenceInDays) + 1);
        } else {
            setDaysDifference('');
        }
    };

    useEffect(() => {
        if (daysDifference > 0) {
            setFieldValue('day_count', daysDifference);
            setFieldValue('amount', daysDifference * values.rate);
            setAmount(daysDifference * values.rate)
        }
        else {
            setFieldValue('day_count', '');
        }
    }, [daysDifference, values.rate]);

    useEffect(() => {
        if (info) {
            setDaysDifference(info?.day_count)
            setValues({
                money_receipt_id: info?.id,
                registration_no: info?.registration_no,
                research_grant_info_id: info?.research_grant_info_id,
                from_date: info?.from_date,
                to_date: info?.to_date,
                day_count: info?.day_count,
                rate: info?.rate ?? 0,
                amount: info?.amount,
                submission_status: info?.submission_status,
            })
        }
    }, [info]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg" backdrop={'static'}>
            {isCreateLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <ModalHeader toggle={toggle}>Edit Information</ModalHeader>
            <ModalBody>
                <Card>
                    <CardBody>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                            onReset={(e) => {
                                resetForm();
                                setDaysDifference('');
                                setAmount(0)
                            }}
                        >
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label for="role" className="form-label">Role</Label>
                                        <Input
                                            className="form-control"
                                            type="text"
                                            name="role"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                            readOnly
                                            value={info?.research_grant_info?.role}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label for="from_date" className="form-label required-field">From Date</Label>
                                        <InputField
                                            type="date"
                                            name='from_date'
                                            onChange={(e: any) => {
                                                handleChange(e);
                                                handleStartDate(e);
                                            }}
                                            onBlur={handleBlur}
                                            value={values.from_date}
                                            invalid={{ errors, touched }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label for="to_date" className="form-label required-field">To Date</Label>
                                        <InputField
                                            type="date"
                                            name='to_date'
                                            onChange={(e: any) => {
                                                handleChange(e);
                                                handleCalculateDays(e);
                                            }}
                                            onBlur={handleBlur}
                                            value={values.to_date}
                                            invalid={{ errors, touched }}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label for="day_count" className="form-label required-field">Day Count</Label>
                                        <InputField
                                            type="text"
                                            placeholder='Day Count'
                                            name='day_count'
                                            value={daysDifference ? daysDifference : values.day_count}
                                            disabled
                                            invalid={{ errors, touched }}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label for="rate" className="form-label required-field">Rate</Label>
                                        <InputField
                                            type="number"
                                            min={0}
                                            placeholder='Enter Rate'
                                            {...getFieldProps('rate')}
                                            invalid={{ errors, touched }}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label for="amount" className="form-label required-field">Amount</Label>
                                        <InputField
                                            type="number"
                                            min={0}
                                            name='amount'
                                            value={amount ? amount : values.amount}
                                            invalid={{ errors, touched }}
                                            readOnly
                                        />
                                    </FormGroup>
                                </Col>
                                <FormGroup className="mb-3 text-center">
                                    <Button
                                        type="reset"
                                        className="btn btn-danger me-2"
                                    >
                                        Reset
                                    </Button>
                                    <button
                                        type="submit"
                                        className="btn btn-info me-2"
                                        ref={draftButtonRef}
                                        onClick={(e) => {
                                            setBtnClicked('save_as_draft');
                                        }}
                                    >
                                        Save as Draft
                                    </button>
                                    <button
                                        type="submit"
                                        ref={buttonRef}
                                        className="btn btn-success"
                                        onClick={(e) => {
                                            setBtnClicked('submit');
                                        }}
                                    >
                                        Submit
                                    </button>
                                </FormGroup>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </ModalBody>
        </Modal>
    );
};

export default EditMoneyReceiptModal;
