import moment from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Col, Modal, Row } from "reactstrap";
import * as url from 'src/helpers/url_helper';
import store from "src/rtk/app/store";
import {
    useGetCommentsDataByMutation,
} from 'src/rtk/features/disertation-thesis/crudApi';

const ReviewerCommentsModal = (props: any) => {

    const { fieldName, showModal, setShowModal, existingProtocol } = props;

    const [messages, setMessage] = useState<any>([]);
    const [comment, setComment] = useState<any>({});

    const currentUser: any = store.getState().auth.user;

    const [
        commentsDataList,
        {
            isLoading: commentsDataListLoading,
            isSuccess: commentsDataListLoadingSuccess,
            error: commentsDataListLoadingError,
        },
    ] = useGetCommentsDataByMutation();

    const getCommentsData = (id: any, nameOfTheField: any) => {
        commentsDataList(`${url.THESIS_DISSERTATION_PROTOCOL_COMMENT_LIST}/${id}/${nameOfTheField}`).then(function (result: any,) {
            // here you can use the result of promiseB
            if (result?.data?.data) {
                setMessage(result?.data?.data);
            }
        });

        // setMessage(commentsData?.data || []);
    };

    useEffect(() => {
        getCommentsData(existingProtocol?.id, fieldName);
    }, []);

    return (
        <>
            <Modal
                onOpened={function () {
                    toast.dismiss();
                }}
                isOpen={showModal}
                scrollable={true}
                size="md"
                toggle={() => {
                    document.body.classList.add('no_padding');
                }}
                backdrop={'static'}
                modalTransition={{ timeout: 0 }}
                backdropTransition={{ timeout: 0 }}
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        Reviewer Comments
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setShowModal(!showModal);
                        }}
                        aria-label="Close"
                    ></button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col sm={12}>
                            <Row>
                                <Col sm={12}>
                                    <div className="border rounded">
                                        <div
                                            className="chat-conversation p-3 px-2"
                                            style={{
                                                height: '425px',
                                                overflow:
                                                    'auto',
                                            }}
                                        >
                                            <ul className="list-unstyled">
                                                {messages &&
                                                    messages.map(
                                                        (
                                                            message: any,
                                                        ) => {
                                                            return (
                                                                <li
                                                                    key={'test_k' + message.id}
                                                                    className={'left'}
                                                                >
                                                                    <div className="conversation-list">
                                                                        <div className="ctext-wrap-content">
                                                                            {
                                                                                <h5 className="conversation-name">
                                                                                    <div className="user-name me-2">
                                                                                        {
                                                                                            message.name
                                                                                        }
                                                                                    </div>
                                                                                    <div className="user-name text-white">
                                                                                        ({
                                                                                            message.type
                                                                                        })
                                                                                    </div>
                                                                                    <span className="time">
                                                                                        {moment(message.updated_at,).calendar()}
                                                                                    </span>
                                                                                </h5>
                                                                            }

                                                                            <p className="mb-0" dangerouslySetInnerHTML={{ __html: message.comments }}>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            );
                                                        },
                                                    )}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    )
};

export default ReviewerCommentsModal;