import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Col, Form, FormGroup, Label, Modal, ModalHeader, Row } from 'reactstrap';
import { InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import { useAddPaymentPackageMutation, useGetPaymentInfoQuery } from 'src/rtk/features/researchClinicSetup/researchClinicSetupApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

const initialValues = {
    payment_option: '',
    amount: '',
    duration: '',
    duration_unit: '',
    active_status: 'Active'
};

interface Props {
    isOpen: boolean;
    toggle: any;
}

const PaymentOption = (props: Props) => {
    const { isOpen, toggle } = props;

    const [addPaymentPackage, { isSuccess, isError, error, isLoading: isCreateLoading }] = useAddPaymentPackageMutation();
    const { data: paymentInfo, isSuccess: isPaymentSuccesss, isLoading: isPaymentLoading } = useGetPaymentInfoQuery();

    const [fieldStatus, setFieldStatus] = useState<boolean>(false);

    console.log(paymentInfo?.data);
    const buttonRef = useRef<any>();
    const draftButtonRef = useRef<any>();

    const err: CustomError = error as CustomError;

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        resetForm,
        setValues,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({
        }),

        onSubmit: (values: any) => {
            if (values.payment_option === 'free') {
                values.amount = 0;
                values.duration = '';
                values.duration_unit = '';
            }

            console.log(values);
            addPaymentPackage(values);
        },
    });

    useEffect(() => {
        if (isSuccess) {
            resetForm();
            toast.success('Payment Information Stored Successfully!');
            toggle();
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);

    useEffect(() => {
        if (isPaymentSuccesss && paymentInfo) {
            setValues({
                payment_option: paymentInfo?.data?.payment_option,
                amount: paymentInfo?.data?.amount,
                duration: paymentInfo?.data?.duration,
                duration_unit: paymentInfo?.data?.duration_unit,
                active_status: paymentInfo?.data?.active_status
            });
            setFieldStatus(true);
        }
    }, [isPaymentSuccesss, paymentInfo]);


    return (
        <React.Fragment>
            {isCreateLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Modal isOpen={isOpen} toggle={toggle} size="lg" backdrop={'static'}>
                <ModalHeader toggle={toggle}>Payment Method</ModalHeader>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                    onReset={(e) => {
                        resetForm();
                    }}
                >
                    <Row className='p-3'>
                        <Col sm={3}>
                            <FormGroup>
                                <Label for="payment_option" className="form-label">Paid or Free?</Label>
                                <InputField
                                    type="select"
                                    placeholder="Paid or Free"
                                    options={[
                                        { value: 'paid', label: 'Paid' },
                                        { value: 'free', label: 'Free' },
                                    ]}
                                    {...getFieldProps('payment_option')}
                                    invalid={{ errors, touched }}
                                    disabled={fieldStatus}
                                />
                            </FormGroup>
                        </Col>

                        {values.payment_option === 'paid' &&
                            <>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label for="amount" className="form-label">Amount</Label>
                                        <InputField
                                            type="number"
                                            min={'1'}
                                            placeholder="Enter Amount"
                                            {...getFieldProps('amount')}
                                            invalid={{ errors, touched }}
                                            readOnly={fieldStatus}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label for="duration" className="form-label">Validity Duration</Label>
                                        <InputField
                                            type="number"
                                            min={'1'}
                                            placeholder="Validity Duration"
                                            {...getFieldProps('duration')}
                                            invalid={{ errors, touched }}
                                            readOnly={fieldStatus}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label for="duration_unit" className="form-label">Unit</Label>
                                        <InputField
                                            type="select"
                                            placeholder="Unit"
                                            options={[
                                                { value: 'Month', label: 'Month' },
                                                { value: 'Year', label: 'Year' },
                                            ]}
                                            {...getFieldProps('duration_unit')}
                                            invalid={{ errors, touched }}
                                            disabled={fieldStatus}
                                        />
                                    </FormGroup>
                                </Col>
                            </>
                        }
                        <Col>
                            <FormGroup className='mt-4'>
                                <Button
                                    type="button"
                                    className="btn btn-primary"
                                    color='primary'
                                    disabled={!fieldStatus}
                                    onClick={() => setFieldStatus(false)}
                                >
                                    Edit
                                </Button>
                            </FormGroup>
                        </Col>
                        <div className='d-flex justify-content-end'>
                            <Button
                                type="submit"
                                className="btn btn-success"
                            >
                                Update
                            </Button>
                        </div>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default PaymentOption;
