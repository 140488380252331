import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import { config } from 'src/config';
import store from 'src/rtk/app/store';

interface Props {
    isOpen: boolean;
    toggle: any;
    instituteName: string;
    feesInWord: string;
    departmentFees: string | number;
    departmentName: string;
    id: string;
}

const DepartmentPaymentModal = (props: Props) => {
    const { isOpen, toggle, instituteName, feesInWord, departmentFees, departmentName, id } = props;
    const [onlinePayment, setOnlinePayment] = useState<boolean>(false);
    const [offlinePayment, setOfflinePayment] = useState<boolean>(true);
    const [pageShow, setPageShow] = useState<boolean>(false);

    const pageShowHandler = (modalState: boolean) => {
        setPageShow(modalState);
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="md">
            <ModalHeader toggle={toggle}>Apply for Training Institute Department Accreditation</ModalHeader>

            <ModalBody>
                <div className='table-responsive'>
                    <table className='table table-bordered'>
                        <tbody>
                            <tr>
                                <td><strong>Institute Name: </strong></td>
                                <td>
                                    {instituteName}
                                </td>
                            </tr>

                            <tr>
                                <td><strong>Department: </strong></td>
                                <td>
                                    {departmentName}
                                </td>
                            </tr>

                            <tr>
                                <td><strong>Fees: </strong></td>
                                <td>
                                    {departmentFees} ({feesInWord})
                                </td>
                            </tr>

                            <tr>
                                <td><strong>Payment Mode:</strong></td>
                                <td>
                                    <Form
                                        className="custom-form"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                        }}
                                        onReset={(e) => {
                                            e.preventDefault();
                                        }}
                                    >

                                        <FormGroup check inline>
                                            <Input
                                                id='paymentMode1'
                                                name='payment_mode'
                                                type='radio'
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setOnlinePayment(true);
                                                        setOfflinePayment(false);
                                                    }
                                                }}
                                                checked={onlinePayment}
                                                value={'Online'}
                                            />

                                            <Label check for="paymentMode1">
                                                Online
                                            </Label>
                                        </FormGroup>

                                        <FormGroup check inline>
                                            <Input
                                                id='paymentMode2'
                                                name='payment_mode'
                                                type='radio'
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setOnlinePayment(false);
                                                        setOfflinePayment(true);
                                                    }
                                                }}
                                                checked={offlinePayment}
                                                value={'Offline'}
                                            />

                                            <Label check for="paymentMode2">
                                                Offline
                                            </Label>
                                        </FormGroup>

                                    </Form>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ModalBody>

            <ModalFooter>
                <div className="d-flex gap-2">
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                    {
                        offlinePayment ? (
                            <Link to={{ pathname: `/department-offline-payment/${'Department'}/${id}/${departmentFees}` }} className='btn btn-success'>
                                Proceed for Payment
                            </Link>
                        ) : null
                    }

                    {
                        onlinePayment ? (
                            <a href={`${config.APP_URL}fcps-online-payment/${id}/${store.getState().auth.user.id}/${departmentFees}?type=department`} rel='noreferrer' target={'_blank'} className='btn btn-success'>
                                Proceed for Payment
                            </a>
                        ) : null
                    }

                </div>
            </ModalFooter>
        </Modal>
    );
};

export default DepartmentPaymentModal;
