import FingerprintJS from '@fingerprintjs/fingerprintjs';
import * as Bowser from 'bowser';

export const generateUniqueDeviceId = async () => {
  const fp = await FingerprintJS.load();

  const result = await fp.get();

  const {
    screenFrame,
    screenResolution,
    platform,
    plugins,
    deviceMemory,
    ...components
  } = result.components;

  if ('value' in components.canvas) {
    components.canvas.value.text = '';
  }

  const visitorId = FingerprintJS.hashComponents(components);  

  const browserInfo: any = getUserAgentInfo();

  const ipAddress: any = await getIPAddress();

  const uniqueFingerprintObj: any = {
    browser: browserInfo.browser.name,
    browser_version: browserInfo.browser.version,
    browser_fingerprint_id: visitorId,
    os: browserInfo.os.name,
    os_version: browserInfo.os.versionName,
    platform: browserInfo.platform.type,
    ip_address: ipAddress.ip,
  }  

  return JSON.stringify(uniqueFingerprintObj);
};

export const getUserAgentInfo = () => {
  const browser = Bowser.parse(window.navigator.userAgent);
  return browser;
};

export const getIPAddress = async () => {
  const response = await fetch('https://api.ipify.org?format=json');
  const data = await response.json();
  return data;
};
