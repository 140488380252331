import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { Form, FormGroup, Label } from 'reactstrap';
import { Button, InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import { useCreateLogbookEntrySetupDetailMutation } from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import * as Yup from 'yup';
import CompetencyGroupTargetsList from './CompetencyGroupTargetsList';

const initialValues: AddCompetencyGroup = {
    id: '',
    logbook_entry_setup_master_id: '',
    particulars: '',
    particulary_type: 6,
    competence_parent: '',
    sl_no: 0,
    has_competence_levels: 0,
    competence_level_type: '',
};

const AddCompetencyGroupTargets = (props: any) => {
    const { logbookEntrySetupMasterId, logbookGroup, logbookEntryFieldList, setSearch, submitStatus, readOnly } = props;

    const [selectedCompetenceTitle, setSelectedCompetenceTitle] = useState<any>('');
    const [selectedCompetenceLevel, setSelectedCompetenceLevel] = useState<any>('');
    const [selectedSlot, setSelectedSlot] = useState<any>([]);
    const [competenceLevelTypeOptions, setCompetenceLevelTypeOptions] = useState<SelectOption[]>([]);

    let logbookGroupTitleList: string[] = [];
    let yearOrSlotPrefix: string = '';

    if (logbookGroup?.title) {
        logbookGroupTitleList = logbookGroup?.title.split(' -> ');
        yearOrSlotPrefix = logbookGroupTitleList[2] === 'core' ? 'Core' : 'Specialty';
    }

    const [createLogbookEntryDetails, { data, isSuccess, isError, error }] =
        useCreateLogbookEntrySetupDetailMutation();

    const competenceTitleOptions: SelectOption[] = logbookEntryFieldList?.data?.competency_group?.map((competence: any, index: number) => {
        return { label: competence.particulars, value: competence.particulars };
    });

    let competenceLevelOptions: SelectOption[] = [];
    let initialCompetenceLevelOptions: LookupData[] = useGetLookupDataQuery('competence_level').data?.data[0]?.childs!;

    const err: CustomError = error as CustomError;

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({

        }),

        onSubmit: (values) => {

            values.logbook_entry_setup_master_id = logbookEntrySetupMasterId;

            // createLogbookEntryDetails({
            //   ...values,
            // });

            console.log(values);
        },
    });

    const {
        handleSubmit,
        handleChange,
        getFieldProps,
        values,
        touched,
        errors,
        resetForm,
        setErrors,
    } = formik;

    useEffect(() => {
        if (selectedCompetenceTitle?.value) {
            const selectedCompetencyGroup: any = logbookEntryFieldList?.data?.competency_group?.find((competence: any, index: number) => competence.particulars === selectedCompetenceTitle?.value);

            if (selectedCompetencyGroup?.competence_level_type === 'Patient Care Competence') {
                competenceLevelOptions = initialCompetenceLevelOptions[0]?.childs?.map((item: any) => ({
                    value: item.name,
                    label: item.name,
                }))
                    .sort((a: any, b: any) => {
                        return a.label.localeCompare(b.label);
                    }) || [];

                setCompetenceLevelTypeOptions(competenceLevelOptions);
            } else if (selectedCompetencyGroup?.competence_level_type === 'Procedural/Surgical skill') {
                competenceLevelOptions = initialCompetenceLevelOptions[1]?.childs?.map((item: any) => ({
                    value: item.name,
                    label: item.name,
                }))
                    .sort((a: any, b: any) => {
                        return a.label.localeCompare(b.label);
                    }) || [];

                setCompetenceLevelTypeOptions(competenceLevelOptions);
            } else {
                setCompetenceLevelTypeOptions([]);
                setSelectedCompetenceLevel({ label: 'Select...', value: '' });
            }
        } else {
            setCompetenceLevelTypeOptions([]);
            setSelectedCompetenceLevel({ label: 'Select...', value: '' });
        }
    }, [selectedCompetenceTitle]);

    useEffect(() => {
        if (isSuccess) {
            resetForm();
            toast.success('Logbook entry details created successfully');
        }
        if (isError) {
            toast.error(err?.data?.message || err?.message || 'Something went wrong');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);

    return (
        <>
            <Form
                className="custom-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
                onReset={(e) => {
                    resetForm();
                }}
            >
                <div className="row">
                    <div className="col-sm-12">
                        <FormGroup className="mb-3">
                            <div className="d-inline-block mx-2">
                                <div className="form-check form-check-left mb-3">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="target"
                                        id="overallTarget"
                                        value={"Overall Target"}
                                        // checked={values.applied_previously === 'Yes'}
                                        onChange={(e) => {
                                            // if (e.target.checked) {
                                            //     setFieldValue('applied_previously', 'Yes');
                                            // }
                                        }}
                                        disabled={readOnly}
                                    />
                                    <label className="form-check-label" htmlFor="overallTarget">Overall Target</label>
                                </div>
                            </div>

                            <div className="d-inline-block mx-2">
                                <div className="form-check form-check-left">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="target"
                                        id="competencyWiseTarget"
                                        value={"Competency Wise Target"}
                                        // checked={values.applied_previously === 'No'}
                                        onChange={(e) => {
                                            // if (e.target.checked) {
                                            //     setFieldValue('applied_previously', 'No');
                                            // }
                                        }}
                                        defaultChecked
                                        disabled={readOnly}
                                    />
                                    <label className="form-check-label" htmlFor="competencyWiseTarget">Competency Wise Target</label>
                                </div>
                            </div>
                        </FormGroup>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="competence_title">Competence Title</Label>
                            <Select
                                isClearable={true}
                                isSearchable={true}
                                value={selectedCompetenceTitle}
                                onChange={setSelectedCompetenceTitle}
                                options={competenceTitleOptions}
                                placeholder='Select...'
                                name="competence_title"
                                id="competence_title"
                                isDisabled={readOnly}
                            />
                        </FormGroup>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="competence_level">Competence Level</Label>
                            <Select
                                isClearable={true}
                                isSearchable={true}
                                value={selectedCompetenceLevel}
                                onChange={setSelectedCompetenceLevel}
                                options={competenceLevelTypeOptions}
                                placeholder='Select...'
                                name="competence_level"
                                id="competence_level"
                                isDisabled={readOnly}
                            />
                        </FormGroup>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="year_or_slot">Year/Slot</Label>
                            <Select
                                isClearable={true}
                                isSearchable={true}
                                isMulti={true}
                                value={selectedSlot?.map((item: any, index: number) => {
                                    return { label: item, value: item };
                                })}
                                onChange={(item: any) => {
                                    let selectedData: any = [];
                                    item.map((data: any) => {
                                        if (data.value) {
                                            selectedData.push(data.value);
                                        }
                                    });
                                    setSelectedSlot(selectedData);
                                }}
                                options={yearOrSlotPrefix === 'Core' ?
                                    [
                                        { label: yearOrSlotPrefix + ' -> 1st Year (Slot-1)', value: yearOrSlotPrefix + ' -> 1st Year (Slot-1)' },
                                        { label: yearOrSlotPrefix + ' -> 1st Year (Slot-2)', value: yearOrSlotPrefix + ' -> 1st Year (Slot-2)' },
                                        { label: yearOrSlotPrefix + ' -> 2nd Year (Slot-1)', value: yearOrSlotPrefix + ' -> 2nd Year (Slot-1)' },
                                        { label: yearOrSlotPrefix + ' -> 2nd Year (Slot-2)', value: yearOrSlotPrefix + ' -> 2nd Year (Slot-2)' },
                                    ]
                                    :
                                    [
                                        { label: yearOrSlotPrefix + ' -> 1st Year (Slot-1)', value: yearOrSlotPrefix + ' -> 1st Year (Slot-1)' },
                                        { label: yearOrSlotPrefix + ' -> 1st Year (Slot-2)', value: yearOrSlotPrefix + ' -> 1st Year (Slot-2)' },
                                        { label: yearOrSlotPrefix + ' -> 2nd Year (Slot-1)', value: yearOrSlotPrefix + ' -> 2nd Year (Slot-1)' },
                                        { label: yearOrSlotPrefix + ' -> 2nd Year (Slot-2)', value: yearOrSlotPrefix + ' -> 2nd Year (Slot-2)' },
                                        { label: yearOrSlotPrefix + ' -> 3rd Year (Slot-1)', value: yearOrSlotPrefix + ' -> 3rd Year (Slot-1)' },
                                        { label: yearOrSlotPrefix + ' -> 3rd Year (Slot-2)', value: yearOrSlotPrefix + ' -> 3rd Year (Slot-2)' },
                                    ]}
                                placeholder='Select...'
                                name="year_or_slot"
                                id="year_or_slot"
                                isDisabled={readOnly}
                            />
                        </FormGroup>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <FormGroup className="mb-3 mt-3">
                            <Label htmlFor="target_minimum">Minimum to be Performed</Label>
                            <InputField
                                type="number"
                                placeholder=""
                                invalid={{ errors, touched }}
                                {...getFieldProps('target_minimum')}
                                readOnly={readOnly}
                            />
                        </FormGroup>
                    </div>

                    <div className="col-sm-12">
                        <div className="row justify-content-center">
                            <div className="col-sm-2">
                                <Button
                                    type="submit"
                                    color="secondary"
                                    text="Submit"
                                    className="me-2 mb-2"
                                ></Button>
                                <Button
                                    type="reset"
                                    color="warning"
                                    text="Reset"
                                    className="me-2 mb-2"
                                ></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>

            {logbookEntrySetupMasterId &&
                logbookGroup &&
                submitStatus &&
                logbookEntrySetupMasterId !== '' && (
                    <CompetencyGroupTargetsList
                        id={logbookEntrySetupMasterId}
                        logbookGroup={logbookGroup}
                        logbookEntryFieldList={logbookEntryFieldList}
                        setSearch={setSearch}
                    />
                )}
        </>
    );
};

export default AddCompetencyGroupTargets;
