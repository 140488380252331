import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table
} from 'reactstrap';
import { setFromErrors } from 'src/helpers';
import { useGetRecommendationApprovalMutation } from 'src/rtk/features/instituteInspection/institutueInspectionApi';
import * as Yup from 'yup';

interface Props {
  isOpen: boolean;
  toggle: any;
  info: RecomendationForm;
}

const initialValues = {
  status: '',
};

const ViewRecomendedDataModal = (props: Props) => {
  const { isOpen, toggle, info } = props;

  const [getRecommendationApproval, { isSuccess, isLoading, isError, error }] = useGetRecommendationApprovalMutation();

  const err: CustomError = error as CustomError;
console.log(info);
  const {
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    handleChange,
    setErrors,
    getFieldProps,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      message: Yup.string().when('status', (status, schema) => {
        if (status === 'Correction') {
          return schema.required('Correction note is Required!');
        }
        return schema;
      }),
    }),
    onSubmit: (values) => {
      // console.log(`institute_id=${info?.institute_id}&department_id=${info?.form_b_department_id}&status=${values.status}`);

      getRecommendationApproval(`institute_id=${info?.institute_id}&department_id=${info?.form_b_department_id}&recommendation_id=${info?.id}&status=${values.status}`);
    },
  });


  useEffect(() => {
    if (isSuccess) {
      toast.success('Approval Updated Successfully!');
    }
    if (isError) {
      toast.error(err?.message || 'Something Went Wrong!');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="lg">
      <ModalHeader toggle={toggle}>Recommended Data</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <ModalBody>
          <Table className='table-bordered table-sm'>
            <tbody>
              <tr className="bg-light text-dark text-center">
                <th colSpan={4}>Recommended By Chairman</th>
              </tr>
              <tr>
                <td>
                  <strong>Name Of Institute :</strong>
                </td>
                <td>
                  {info?.institute?.institute_name}
                </td>
                <td>
                  <strong>Name Of Department: </strong>
                </td>
                <td>
                  {info?.department?.department_name}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Previous Status Of Recognition: </strong>
                </td>
                <td>
                  {info?.previous_recognition_status}
                </td>
                <td>
                  <strong>Period of training to be accepted: </strong>
                </td>
                <td>
                  {info?.period_of_accepted_training_in_year + ' Year ' + info?.period_of_accepted_training_in_month + ' Months'}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Period of Recognition : </strong>
                </td>
                <td>
                  {info?.period_of_recognition + ' Years'}
                </td>
                <td>
                  <strong>Strating Date of Recognition: </strong>
                </td>
                <td>
                  {moment(info?.starting_date_of_recognition).format("DD-MMM-YYYY")}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Valid Up to: </strong>
                </td>
                <td>
                  {moment(info?.validity_date_of_recognition).format("DD-MMM-YYYY")}
                </td>
              </tr>
            </tbody>
          </Table>
          <Table className='table-bordered table-sm' style={{ width: '525px' }}>
            <tbody>
              <tr>
                <td>
                  <strong>Overall Patient load:</strong>
                </td>
                <td>{info?.overall_patient_load}</td>
              </tr>
              <tr>
                <td>
                  <strong>Inpatient:</strong>
                </td>
                <td>{info?.in_patient}</td>
              </tr>
              <tr>
                <td>
                  <strong>Outpatient:</strong>
                </td>
                <td>{info?.out_patient}</td>
              </tr>
              <tr>
                <td>
                  <strong>Practical work load:</strong>
                </td>
                <td>
                  {info?.practical_workload}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Availability of investigative services:</strong>
                </td>
                <td>
                  {info?.availibility_of_investigative_service}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Availability of other support services:</strong>
                </td>
                <td>
                  {info?.availibility_of_other_support_service}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Number and status of trainer:</strong>
                </td>
                <td>
                  {info?.number_and_status_of_trainer}
                </td>
              </tr>
            </tbody>
          </Table>
          {info?.accreditation_status !== 'Accredited' && 
          <div>
            <h6 className="block-heading">Approval</h6>
            <table className="table table-sm table-bordered table-striped">
              <tbody>
                <tr>
                  <td>
                    <div>
                      <FormGroup check inline className="mb-3 ">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="status"
                          id="approve"
                          checked={values.status === 'Accredited'}
                          onChange={(e) => {
                            setFieldValue('status', 'Accredited');
                          }}
                        />
                        <label
                          className="form-check-label text-success"
                          htmlFor="approve"
                        >
                          Approve
                        </label>
                      </FormGroup>

                      <FormGroup check inline className="mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="status"
                          id="reject"
                          checked={values.status === 'Rejected'}
                          onChange={(e) => {
                            setFieldValue('status', 'Rejected');
                          }}
                        />
                        <label
                          className="form-check-label text-danger"
                          htmlFor="reject"
                        >
                          Reject
                        </label>
                      </FormGroup>
                    </div>
                  </td>
                </tr>
                {/* {values.status && <tr>
                <td>
                  <Label>{values.status} Note</Label>
                  <InputField
                    type="textarea"
                    placeholder='Enter Note'
                    invalid={{ errors, touched }}
                    {...getFieldProps('message')}
                  />
                </td>
              </tr>} */}
              </tbody>
            </table>
          </div>}
        </ModalBody>

        <ModalFooter>
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            {info?.accreditation_status !== 'Accredited' && 
            <button type="submit" className="btn btn-primary">
              Save
            </button>}
            <Button color="danger" onClick={toggle}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ViewRecomendedDataModal;
