import * as instituteAndDepartmentRegistrationUrl from 'src/helpers/url_helper';
import { apiSlice } from 'src/rtk/api/apiSlice';

export const instituteAndDepartmentRegistrationApi = apiSlice
  .enhanceEndpoints({ addTagTypes: [
  'InstituteList',
  'InstituteAdminReg',
  'InstituteAdminInstituteList',
  'DepartmentList',
  'IpdRecordList', 
  'OpdRecordList', 
  'DepartmentInfo',   
  'OTInfoList',
  'totalNoOfOperation',
  'ProcedureRecordList',
  'OperationRecordEntryList',
  'LaboratoryRecordEntryList',
  'AcademicStaffInfoList',
  'AcademicStaffWorkloadsList',
  'partCMasterInfo',
  'partCResearchInfo',
  'partCResearchProjectInfo',
  'insPublicationsInfo',
  'insDutiesInfo',
  'partDMasterInfo',
  'libraryServiceEntry',
  'supportServiceEntry',
  'teachingInfrastructureEntry',
  'InstituteDepartmentList',
]}).injectEndpoints({
    endpoints: (builder) => ({     
      
      createInstitute: builder.mutation<any, any>({
        query: (data: any) => ({
          url: `${instituteAndDepartmentRegistrationUrl.CREATE_INSTITUTE}/${data?.id}`,
          method: 'POST',
          body: data.formData,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['InstituteList'],
      }),

      registerDepartmentStepOne: builder.mutation<any, InstituteDeptRegistrationStepOne>({
        query: (data: InstituteDeptRegistrationStepOne) => ({
          url: `${instituteAndDepartmentRegistrationUrl.REGISTER_DEPARTMENT_STEP_ONE}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['DepartmentList', 'DepartmentInfo'],
      }),

      instituteDepartmentRenew: builder.mutation<any, InstituteDepartmentRenew>({
        query: (data: InstituteDepartmentRenew) => ({
          url: `${instituteAndDepartmentRegistrationUrl.INSTITUTE_DEPARTMENT_RENEW}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['DepartmentList', 'DepartmentInfo'],
      }),

      registerDepartmentStepTwo: builder.mutation<any, any>({
        query: (data: any) => ({
          url: `${instituteAndDepartmentRegistrationUrl.REGISTER_DEPARTMENT_STEP_TWO}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['IpdRecordList', 'OpdRecordList'],
      }),

      registerDepartmentStepThree: builder.mutation<any, any>({
        query: (data: any) => ({
          url: `${instituteAndDepartmentRegistrationUrl.REGISTER_DEPARTMENT_STEP_THREE}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['OTInfoList'],
      }),

      procedureRecordEntry: builder.mutation<any, any>({
        query: (data: any) => ({
          url: `${instituteAndDepartmentRegistrationUrl.PROCEDURE_RECORD_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['ProcedureRecordList'],
      }),

      getOTInfo: builder.query<APIResponse<OTInfoGet>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.GET_OT_INFO}/${id}`,
        }),

        providesTags: ['OTInfoList'],
      }),

      getProcedureRecordEntryList: builder.query<APIResponse<ProcedureRecordList[]>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.GET_PROCEDURE_RECORD_LIST}?form_b_department_id=${id}`,
        }),

        providesTags: ['ProcedureRecordList'],
      }),

      deleteProcedureRecord: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${instituteAndDepartmentRegistrationUrl.DELETE_PROCEDURE_RECORD}/${id}`,
        }),

        invalidatesTags: ['ProcedureRecordList'],
      }),

      instituteAdminRegistration: builder.mutation<any, InstituteAdminRegistration>({
        query: (data) => ({
          url: `${instituteAndDepartmentRegistrationUrl.INSTITUTE_ADMIN_REGISTRATION}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['InstituteAdminReg'],
      }),

      getInstituteRegistrationList: builder.query<APIResponse<InstituteAdminInstituteList>, void>({
        query: () => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.GET_INSTITUTE_REGISTRATION_LIST}`,
        }),

        providesTags: ['InstituteAdminInstituteList', 'InstituteList'],
      }),

      getInstituteDepartmentListByPaginate: builder.query<
        APIResponseWithPagination<InstituteDepartmentRegData>,
        PaginateForLogbookEntries
      >({
        query: (paginate) => {
          let url = `${instituteAndDepartmentRegistrationUrl.GET_INSTITUTE_DEPARTMENT_LIST_BY_PAGINATE}?`;

          if (paginate.page) {
            url += `page=${paginate.page}&`;
          }
          if (paginate.paginate_per_page) {
            url += `paginate_per_page=${paginate.paginate_per_page}`;
          }
          if (paginate.search) {
            url += `${paginate.search}`;
          }

          return {
            method: 'GET',
            url: url,
          };
        },
        providesTags: ['InstituteDepartmentList'],
      }),

      totalNoOfOperationEntry: builder.mutation<any, TotalNoOfOperationEntry>({
        query: (data: TotalNoOfOperationEntry) => ({
          url: `${instituteAndDepartmentRegistrationUrl.TOTAL_NO_OF_OPERATION_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['totalNoOfOperation'],
      }),

      getTotalNoOfOperationEntry: builder.query<APIResponse<GetTotalNoOfOperation>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.TOTAL_NO_OF_OPERATION_LIST}?form_b_department_id=${id}`,
        }),

        providesTags: ['totalNoOfOperation'],
      }),

      deleteTotalNoOfOperationEntry: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${instituteAndDepartmentRegistrationUrl.DELETE_TOTAL_NO_OF_OPERATION}/${id}`,
        }),

        invalidatesTags: ['totalNoOfOperation'],
      }),

      getDepartmentById: builder.query<APIResponse<GetInstituteDept>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.GET_DEPARTMENT_BY_ID}/${id}`,
        }),

        providesTags: ['DepartmentInfo'],
      }),

      getInstituteById: builder.query<APIResponse<InstituteAdminInstituteList>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.GET_INSTITUTE_BY_ID}/${id}`,
        }),

        providesTags: ['InstituteList'],
      }),

      getIpdRecordList: builder.query<APIResponse<IpdOpdRecordList[]>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.GET_IPD_RECORD_LIST}?record_type=${'ipd'}&form_b_department_id=${id}`,
        }),

        providesTags: ['IpdRecordList'],
      }),

      getOpdRecordList: builder.query<APIResponse<IpdOpdRecordList[]>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.GET_OPD_RECORD_LIST}?record_type=${'opd'}&form_b_department_id=${id}`,
        }),

        providesTags: ['OpdRecordList'],
      }),

      deleteIpdOpdRecord: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${instituteAndDepartmentRegistrationUrl.DELETE_IPD_OPD_RECORD}/${id}`,
        }),
        invalidatesTags: ['IpdRecordList', 'OpdRecordList'],
      }),

      operationRecordEntry: builder.mutation<any, OperationRecordEntry>({
        query: (data: OperationRecordEntry) => ({
          url: `${instituteAndDepartmentRegistrationUrl.OPERATION_RECORD_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['OperationRecordEntryList'],
      }),

      getOperationRecordList: builder.query<APIResponse<OperationRecordList[]>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.GET_OPERATION_RECORD_LIST}?form_b_department_id=${id}`,
        }),

        providesTags: ['OperationRecordEntryList'],
      }),

      deleteOperationRecord: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${instituteAndDepartmentRegistrationUrl.DELETE_OPERATION_RECORD}/${id}`,
        }),
        invalidatesTags: ['OperationRecordEntryList'],
      }),

      laboratoryRecordEntry: builder.mutation<any, LaboratoryRecordEntry>({
        query: (data: LaboratoryRecordEntry) => ({
          url: `${instituteAndDepartmentRegistrationUrl.LABORATORY_RECORD_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['LaboratoryRecordEntryList'],
      }),

      getLaboratoryRecordList: builder.query<APIResponse<LaboratoryRecordList[]>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.GET_LABORATORY_RECORD_LIST}?form_b_department_id=${id}`,
        }),

        providesTags: ['LaboratoryRecordEntryList'],
      }),

      deleteLaboratoryRecord: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${instituteAndDepartmentRegistrationUrl.DELETE_LABORATORY_RECORD}/${id}`,
        }),

        invalidatesTags: ['LaboratoryRecordEntryList'],
      }),

      academicStaffInfoEntry: builder.mutation<any, any>({
        query: (data: any) => ({
          url: `${instituteAndDepartmentRegistrationUrl.ACADEMIC_STAFF_INFO_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['AcademicStaffInfoList'],
      }),

      getAcademicStaffInfoList: builder.query<APIResponse<AcademicStaffInfoList[]>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.GET_ACADEMIC_STAFF_INFO_LIST}?form_b_department_id=${id}`,
        }),

        providesTags: ['AcademicStaffInfoList'],
      }),

      deleteAcademicStaffInfo: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${instituteAndDepartmentRegistrationUrl.DELETE_ACADEMIC_STAFF_INFO}/${id}`,
        }),

        invalidatesTags: ['AcademicStaffInfoList'],
      }),

      academicStaffWorkloadsEntry: builder.mutation<any, WorkloadStaffEntry>({
        query: (data: WorkloadStaffEntry) => ({
          url: `${instituteAndDepartmentRegistrationUrl.ACADEMIC_STAFF_WORKLOADS_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['AcademicStaffWorkloadsList'],
      }),

      getAcademicStaffWorkloadsList: builder.query<APIResponse<WorkloadStaffEntryList[]>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.GET_ACADEMIC_STAFF_WORKLOADS_LIST}?form_b_department_id=${id}`,
        }),

        providesTags: ['AcademicStaffWorkloadsList'],
      }),

      deleteAcademicStaffWorkload: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${instituteAndDepartmentRegistrationUrl.DELETE_ACADEMIC_STAFF_WORKLOAD}/${id}`,
        }),

        invalidatesTags: ['AcademicStaffWorkloadsList'],
      }),

      partCMasterInfoEntry: builder.mutation<any, PartCMasterInfoEntry>({
        query: (data: PartCMasterInfoEntry) => ({
          url: `${instituteAndDepartmentRegistrationUrl.PART_C_MASTER_INFO_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['partCMasterInfo'],
      }),

      partCResearchInfoEntry: builder.mutation<any, any>({
        query: (data: any) => ({
          url: `${instituteAndDepartmentRegistrationUrl.PART_C_RESEARCH_INFO_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['partCResearchInfo'],
      }),

      getPartCMasterInfoEntry: builder.query<APIResponse<GetPartCMasterInfo>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.GET_PART_C_MASTER_INFO_ENTRY}/${id}`,
        }),

        providesTags: ['partCMasterInfo'],
      }),

      getPartCResearchInfoEntry: builder.query<APIResponse<GetPartCResearchInfoEntry>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.GET_PART_C_RESEARCH_INFO_ENTRY}?form_b_department_id=${id}`,
        }),

        providesTags: ['partCResearchInfo'],
      }),

      partCResearchProjectEntry: builder.mutation<any, ResearchProjectConductedByTheDepartmentEntry>({
        query: (data: ResearchProjectConductedByTheDepartmentEntry) => ({
          url: `${instituteAndDepartmentRegistrationUrl.PART_C_RESEARCH_PROJECT_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['partCResearchProjectInfo'],
      }),

      getPartCResearchProjectList: builder.query<APIResponse<GetResearchProjectConductedByTheDepartmentEntry[]>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.GET_PART_C_RESEARCH_PROJECT_LIST}?form_b_department_id=${id}`,
        }),

        providesTags: ['partCResearchProjectInfo'],
      }),

      deletePartCResearchProject: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${instituteAndDepartmentRegistrationUrl.DELETE_PART_C_RESEARCH_PROJECT}/${id}`,
        }),

        invalidatesTags: ['partCResearchProjectInfo'],
      }),

      insPublicationsEntry: builder.mutation<any, InsPublicationsEntry>({
        query: (data: InsPublicationsEntry) => ({
          url: `${instituteAndDepartmentRegistrationUrl.INS_PUBLICATIONS_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['insPublicationsInfo'],
      }),

      getInsPublicationsList: builder.query<APIResponse<InsPublicationsList[]>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.INS_PUBLICATIONS_LIST}?form_b_department_id=${id}`,
        }),

        providesTags: ['insPublicationsInfo'],
      }),

      deleteInsPublicationsEntry: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${instituteAndDepartmentRegistrationUrl.DELETE_INS_PUBLICATIONS}/${id}`,
        }),

        invalidatesTags: ['insPublicationsInfo'],
      }),

      insDutiesEntry: builder.mutation<any, InsDutiesEntry>({
        query: (data: InsDutiesEntry) => ({
          url: `${instituteAndDepartmentRegistrationUrl.INS_DUTIES_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['insDutiesInfo'],
      }),

      getInsDutiesList: builder.query<APIResponse<InsDutiesList[]>, { type: string; form_b_department_id: string }>({
        query: (obj: { type: string; form_b_department_id: string }) => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.INS_DUTIES_LIST}?type=${obj.type}&form_b_department_id=${obj.form_b_department_id}`,
        }),

        providesTags: ['insDutiesInfo'],
      }),

      deleteInsDutiesEntry: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${instituteAndDepartmentRegistrationUrl.DELETE_INS_DUTIES}/${id}`,
        }),

        invalidatesTags: ['insDutiesInfo'],
      }),

      partDMasterInfoEntry: builder.mutation<any, PartDMasterInfoEntry>({
        query: (data: PartDMasterInfoEntry) => ({
          url: `${instituteAndDepartmentRegistrationUrl.PART_D_MASTER_INFO_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['partDMasterInfo', 'InstituteDepartmentList'],
      }),

      getPartDMasterInfoEntry: builder.query<APIResponse<GetPartDMasterInfo>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.GET_PART_D_MASTER_INFO_ENTRY}/${id}`,
        }),

        providesTags: ['partDMasterInfo'],
      }),

      libraryServiceEntry: builder.mutation<any, LibraryServiceEntry>({
        query: (data: LibraryServiceEntry) => ({
          url: `${instituteAndDepartmentRegistrationUrl.LIBRARY_SERVICE_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['libraryServiceEntry'],
      }),

      getLibraryServiceList: builder.query<APIResponse<LibraryServiceList[]>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.GET_LIBRARY_SERVICE_LIST}?form_b_department_id=${id}`,
        }),

        providesTags: ['libraryServiceEntry'],
      }),

      deleteLibraryService: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${instituteAndDepartmentRegistrationUrl.DELETE_LIBRARY_SERVICE}/${id}`,
        }),

        invalidatesTags: ['libraryServiceEntry'],
      }),

      supportServiceEntry: builder.mutation<any, any>({
        query: (data: any) => ({
          url: `${instituteAndDepartmentRegistrationUrl.SUPPORT_SERVICE_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['supportServiceEntry'],
      }),

      getSupportServiceList: builder.query<APIResponse<SupportServiceList[]>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.GET_SUPPORT_SERVICE_LIST}?form_b_department_id=${id}`,
        }),

        providesTags: ['supportServiceEntry'],
      }),

      deleteSupportService: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${instituteAndDepartmentRegistrationUrl.DELETE_SUPPORT_SERVICE}/${id}`,
        }),

        invalidatesTags: ['supportServiceEntry'],
      }),

      teachingInfrastructureEntry: builder.mutation<any, TeachingInfrastructureEntry>({
        query: (data: TeachingInfrastructureEntry) => ({
          url: `${instituteAndDepartmentRegistrationUrl.TEACHING_INFRASTRUCTURE_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['teachingInfrastructureEntry'],
      }),

      getTeachingInfrastructureList: builder.query<APIResponse<TeachingInfrastructureList[]>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${instituteAndDepartmentRegistrationUrl.GET_TEACHING_INFRASTRUCTURE_LIST}?form_b_department_id=${id}`,
        }),

        providesTags: ['teachingInfrastructureEntry'],
      }),

      deleteTeachingInfrastructure: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${instituteAndDepartmentRegistrationUrl.DELETE_TEACHING_INFRASTRUCTURE}/${id}`,
        }),

        invalidatesTags: ['teachingInfrastructureEntry'],
      }),
    }),
  });

export const {
    useCreateInstituteMutation,
    useRegisterDepartmentStepOneMutation,
    useInstituteDepartmentRenewMutation,
    useRegisterDepartmentStepTwoMutation,
    useRegisterDepartmentStepThreeMutation,
    useGetOTInfoQuery,
    useTotalNoOfOperationEntryMutation,
    useGetTotalNoOfOperationEntryQuery,
    useDeleteTotalNoOfOperationEntryMutation,
    useGetProcedureRecordEntryListQuery,
    useProcedureRecordEntryMutation,
    useDeleteProcedureRecordMutation,
    useInstituteAdminRegistrationMutation,
    useGetInstituteRegistrationListQuery,
    useGetInstituteDepartmentListByPaginateQuery,
    useGetDepartmentByIdQuery,
    useGetInstituteByIdQuery,
    useGetIpdRecordListQuery,
    useGetOpdRecordListQuery,
    useDeleteIpdOpdRecordMutation,
    useOperationRecordEntryMutation,
    useGetOperationRecordListQuery,
    useDeleteOperationRecordMutation,
    useLaboratoryRecordEntryMutation,
    useGetLaboratoryRecordListQuery,
    useDeleteLaboratoryRecordMutation,
    useAcademicStaffInfoEntryMutation,
    useGetAcademicStaffInfoListQuery,
    useDeleteAcademicStaffInfoMutation,
    useAcademicStaffWorkloadsEntryMutation,
    useGetAcademicStaffWorkloadsListQuery,
    useDeleteAcademicStaffWorkloadMutation,
    usePartCMasterInfoEntryMutation,
    usePartCResearchInfoEntryMutation,
    usePartCResearchProjectEntryMutation,
    useGetPartCMasterInfoEntryQuery,
    useGetPartCResearchInfoEntryQuery,
    useGetPartCResearchProjectListQuery,
    useDeletePartCResearchProjectMutation,
    useGetInsPublicationsListQuery,
    useInsPublicationsEntryMutation,
    useDeleteInsPublicationsEntryMutation,
    useGetInsDutiesListQuery,
    useInsDutiesEntryMutation,
    useDeleteInsDutiesEntryMutation,
    usePartDMasterInfoEntryMutation,
    useGetPartDMasterInfoEntryQuery,
    useLibraryServiceEntryMutation,
    useGetLibraryServiceListQuery,
    useDeleteLibraryServiceMutation,
    useSupportServiceEntryMutation,
    useGetSupportServiceListQuery,
    useDeleteSupportServiceMutation,
    useTeachingInfrastructureEntryMutation,
    useGetTeachingInfrastructureListQuery,
    useDeleteTeachingInfrastructureMutation,
} = instituteAndDepartmentRegistrationApi;
