//import Breadcrumbs
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label } from 'reactstrap';
import { Button, InputField } from 'src/components';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { putUrlString } from 'src/helpers';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import {
  useGetOrganizationQuery,
  useUpdateOrganizationMutation,
} from 'src/rtk/features/setup/organization/setupOrganizationApi';

import * as Yup from 'yup';

const initialValues: Organization = {
  id: undefined,
  organization_name: '',
  organization_name_bangla: '',
  organization_type: '',
  organization_slogan: '',
  address1: '',
  address2: '',
  district: '',
  division: '',
  country: '',
  telephone1: '',
  telephone2: '',
  email: '',
  website: '',
  logo: '',
  organization_parent_id: '',
  status: false,
};

const EditOrganization = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [updateOrganization, { data, isSuccess, isError, error }] =
    useUpdateOrganizationMutation();
  const { data: organization, isError: isOrganizationFetchError } =
    useGetOrganizationQuery(id);

  const { data: divisionLookupData } = useGetLookupDataQuery('division');
  const division: Lookup[] =
      (divisionLookupData as any)?.data[0]?.childs || [];  
  
  const [district, setDistrict] = useState<Lookup[]>([]); 

  const err: CustomError = error as CustomError;

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setValues,
    setErrors,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      organization_name: Yup.string().required(),
      email: Yup.string().email(),
    }),
    onSubmit: (values) => {
      const url = putUrlString(id, values);
      updateOrganization(url);
      console.log(url);
    },
  });

  useEffect(() => {
    if (organization && !isOrganizationFetchError) {
      const data = organization?.data as any;
      setValues({
        id: data.id,
        organization_name: data.organization_name,
        organization_name_bangla: data.organization_name_bangla,
        organization_type: data.organization_type,
        organization_slogan: data.organization_slogan,
        address1: data.address1,
        address2: data.address2,
        district: data.district,
        division: data.division,
        country: data.country,
        telephone1: data.telephone1,
        telephone2: data.telephone2,
        email: data.email,
        website: data.website,
        logo: data.logo,
        organization_parent_id: data.organization_parent_id,
        status: data.status,
      });

      if(data.division) {
        localStateHandler(division, setDistrict, data.division);
        setFieldValue('division', data.division);
      }

      if(data.district) {
        setFieldValue('district', data.district);
      }
    }
  }, [organization, isOrganizationFetchError]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      history.push('/setup/organizations');
      toast.success('Organization updated successfully');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      const errors: any = {};

      if (err?.data) {
        Object.keys(err?.data).forEach((key) => {
          errors[key] = err?.data[key][0];
        });
      }
      setErrors(errors);
    }
  }, [isSuccess, isError]);

  const localStateHandler = async (
    setValueFrom: Lookup[],
    setValueTo: any,
    selectedValue: string,
  ) => {
    const selectedParent = setValueFrom?.find(
      (item) => item.name === selectedValue,
    );
    setValueTo(selectedParent?.childs as any);
  };

  const handleDivision = (e: any) => {
    const { value } = e.target;
    localStateHandler(division, setDistrict, value);    
    setFieldValue('division', value);
  };
  
  const handleDistrict = (e: any) => {
    const { value } = e.target;    
    setFieldValue('district', value);
  };

  const selectOptionHandler = (optionList: Lookup[], selectedOpt: any) => {
    return (
      <>
        {selectedOpt
          ? optionList?.map((option) => (
              <option
                key={option.id}
                selected={option.name === selectedOpt}
                value={option.name}
              >
                {option.name}
              </option>
            ))
          : optionList?.map((option) => (
              <option key={option.id} value={option.name}>
                {option.name}
              </option>
            ))}
      </>
    );
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Organization" breadcrumbItem="Organization" />
        <div className="row">
          <div className="card mt-3">
            <h5 className="card-header">
              <i className="fa fa-plus me-2" />
              Edit Organization
            </h5>
            <div className="card-body">
              <Form
                className="custom-form mt-4 pt-2"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="row">
                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Organization Name</Label>
                      <InputField
                        name="organization_name"
                        type="text"
                        placeholder="Enter Name"
                        value={values.organization_name}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">
                        Organization Name (Bangla)
                      </Label>
                      <InputField
                        name="organization_name_bangla"
                        type="text"
                        placeholder="Enter Name"
                        value={values.organization_name_bangla}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Organization Type</Label>
                      <InputField
                        name="organization_type"
                        type="text"
                        placeholder="Enter Organization Type"
                        value={values.organization_type}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Organization Slogan</Label>
                      <InputField
                        name="organization_slogan"
                        type="text"
                        placeholder="Enter Organization Slogan"
                        value={values.organization_slogan}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Address 1</Label>
                      <InputField
                        name="address1"
                        type="text"
                        placeholder="Enter Address 1"
                        value={values.address1}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Address 2</Label>
                      <InputField
                        name="address2"
                        type="text"
                        placeholder="Enter Address 2"
                        value={values.address2}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label for="division">Division</Label>
                      <select
                        className="form-select"
                        onChange={handleDivision}
                        name="division"
                      >
                        <option> Select Division</option>
                        {selectOptionHandler(
                          division,
                          values.division
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                    <Label for="permanent_district">District</Label>
                    <select
                      className="form-select"
                      onChange={handleDistrict}
                      name="district"
                    >
                      <option value="">Select District</option>
                      {selectOptionHandler(
                        district,
                        values.district,
                      )}
                    </select>
                    </div>
                  </div>  

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Country</Label>
                      <InputField
                        name="country"
                        type="text"
                        placeholder="Enter Country"
                        value={values.country}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Telephone 1</Label>
                      <InputField
                        name="telephone1"
                        type="text"
                        placeholder="Enter Phone Number"
                        value={values.telephone1}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Telephone 2</Label>
                      <InputField
                        name="telephone2"
                        type="text"
                        placeholder="Enter Phone Number"
                        value={values.telephone2}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Email</Label>
                      <InputField
                        name="email"
                        type="email"
                        placeholder="Enter Email"
                        value={values.email}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Website</Label>
                      <InputField
                        name="website"
                        type="text"
                        placeholder="Enter Website URL"
                        value={values.website}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Logo</Label>
                      <InputField
                        name="logo"
                        type="text"
                        placeholder="Enter Logo URL"
                        value={values.logo}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3 mt-4">
                      <FormGroup check className="mb-3">
                        <Label check>
                          <Input
                            type="checkbox"
                            name="status"
                            checked={values.status}
                            onChange={handleChange}
                          />{' '}
                          Status
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                </div>

                <Button type="submit" text="Submit" />
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default EditOrganization;
