import { apiSlice } from 'src/rtk/api/apiSlice';
import * as paymentApprovalUrl from 'src/helpers/url_helper';

interface Paginate {
  page: number | string;
  paginate_per_page?: number;
  search?: string;
}

export const paymentApprovalApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['PaymentList'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPaymentLists: builder.query<APIResponse<PaymentList[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${paymentApprovalUrl.GET_PAYMENT_LISTS}`,
        }),
        providesTags: ['PaymentList'],
      }),

      getPaymentListsByPaginate: builder.query<
        APIResponseWithPagination<PaymentList>,
        Paginate
      >({
        query: (paginate) => {
          let url = `${paymentApprovalUrl.GET_PAYMENT_LISTS_BY_PAGINATE}`;

          if (paginate.page) {
            url += `?page=${paginate.page}&`;
          }
          if (paginate.paginate_per_page) {
            url += `paginate_per_page=${paginate.paginate_per_page}`;
          }
          if (paginate.search) {
            url += `${paginate.search}`;
          }

          return {
            method: 'GET',
            url: url,
          };
        },
        providesTags: ['PaymentList'],
      }),

      getPaymentList: builder.query<APIResponse<PaymentList>, string>({
        query: (id) => ({
          url: `${paymentApprovalUrl.GET_PAYMENT_LIST}/${id}`,
          method: 'GET',
        }),
        providesTags: ['PaymentList'],
      }),

      getPaymentConfirm: builder.mutation<APIResponse<PaymentList>, string>({
        query: (id) => ({
          url: `${paymentApprovalUrl.GET_PAYMENT_CONFIRM}/${id}`,
          method: 'GET',
        }),
        invalidatesTags: ['PaymentList'],
      }),

      getInstitutePaymentApprovalList: builder.query<APIResponseWithPagination<InstituteDepartmentPaymentList>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${paymentApprovalUrl.GET_INSTITUTE_PAYMENT_APPROVAL_LIST}/${url}`,
        }),

        providesTags: ['PaymentList'],
      }),

      createPaymentList: builder.mutation<APIResponse<PaymentList>, PaymentList>({
        query: (data) => ({
          url: `${paymentApprovalUrl.CREATE_PAYMENT_LIST}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['PaymentList'],
      }),

      // updatePaymentList: builder.mutation<APIResponse<PaymentList>, PaymentList>({
      //   query: (data) => ({
      //     url: `/`,
      //     method: 'POST',
      //     body: data,
      //     headers: {
      //       'Content-Type': 'application/json',
      //       Accept: 'application/json',
      //     },
      //   }),
      //   invalidatesTags: ['PaymentList'],
      // }),
      deletePaymentList: builder.mutation({
        query: (id: number) => ({
          method: 'DELETE',
          url: `${paymentApprovalUrl.DELETE_PAYMENT_LIST}/${id}`,
        }),
        invalidatesTags: ['PaymentList'],
      }),
    }),
  });

export const {
  useCreatePaymentListMutation,
  useGetPaymentListsQuery,
  useGetPaymentListQuery,
  useGetPaymentConfirmMutation,  
  useDeletePaymentListMutation,
  useGetPaymentListsByPaginateQuery,
  useGetInstitutePaymentApprovalListQuery,
} = paymentApprovalApi;
