import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Row, Table } from 'reactstrap';
import { InputField } from 'src/components';
import DisplayImage from 'src/components/Common/displayImage';
import { config } from 'src/config';
import { setFromErrors } from 'src/helpers';
import { useCreateCBDFormAEntryMutation } from 'src/rtk/features/miniCexCBD/miniCexAndCBDApi';
import { useGetMyRegInfoQuery } from 'src/rtk/features/registration-approval/registrationApprovalApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

const formAarray = [
    "History Writing",
    "History Taking",
    "Physical Examination",
    "Diagnosis and possible differentials with reasoning/justification",
    "Planing Invasgination and Justification",
    "Planing Management and its Justification",
    "Follow up Prognosis and Referral Knowledge",
    "Recent update on the topic",
    "Communication skill/ Dealing with patient and attendants/ Ethical approach",
    "Overall clinical care"
];

const performanceOption = [
    { value: 'Below expectation for the stage of training < 12', label: 'Below expectation for the stage of training < 12' },
    { value: 'Borderline for the stage of training < 12-13', label: 'Borderline for the stage of training < 12-13' },
    { value: 'Meets Expectation for the stage of training < 14-15', label: 'Meets Expectation for the stage of training < 14-15' },
    { value: 'Above Expectation for the stage of training < 16-17', label: 'Above Expectation for the stage of training < 16-17' },
    { value: 'Well above Expectation for the stage of training > 18', label: 'Well above Expectation for the stage of training > 18' },
];
interface Props {
    regitrationNo: string;
    supervisorId: string;
    sessionId: string;
}

const CBDFormA = (props: Props) => {
    const { regitrationNo, supervisorId, sessionId } = props;
    const history = useHistory();

    const { data, isLoading } = useGetMyRegInfoQuery(regitrationNo);
    const userInfo = data?.data || ({} as RegInfo);

    // console.log(formAarray);
    const [discussionData, setDiscussionData] = useState<any>({});

    const [discussionOption, setDiscussionOption] = useState(Array(formAarray.length).fill(''));
    const [feedbackOption, setFeedbackOption] = useState(Array(formAarray.length).fill(''));

    const [createCBDFormAEntry, { isSuccess, isError, error, isLoading: isCreateLoading }] = useCreateCBDFormAEntryMutation();
    const err: CustomError = error as CustomError;

    const initialValues = {
        registration_id: '',
        supervisor_id: '',
        logbook_entry_session_id: '',
        date_of_assessment: '',
        difficulty_level: '',
        start_time: '',
        end_time: '',
        diagnosis_of_the_case: '',
        competency_to_be_covered: '',
        institute: '',
        supervisor_name: '',
        academic_status: '',
        student_name: '',
        training_duration: '',
        trainee_signature: '',
        supervisor_signature: '',
        overall_performance: '',
        data: [],
    }

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({
            difficulty_level: Yup.string().required('Please Select!'),
            diagnosis_of_the_case: Yup.string().required('Diagnosis of the case Required'),
            start_time: Yup.string().required('Start Time is Required'),
            end_time: Yup.string()
                .required('End time is required')
                .test('is-after-start', 'End time must be after start time', function (
                    value
                ) {
                    const { start_time } = this.parent;
                    return !start_time || !value || value > start_time;
                }),
            overall_performance: Yup.string().required('Please Select'),
            competency_to_be_covered: Yup.string().required('Competency is Required'),
        }),

        onSubmit: (values: any) => {
            values.data = [];

            values.registration_id = regitrationNo;
            values.supervisor_id = supervisorId;
            values.logbook_entry_session_id = sessionId;

            Object.keys(discussionData).forEach((key) => {
                values.data.push(discussionData[key]);
            });

            console.log(values);
            createCBDFormAEntry(values);

        },
    });

    useEffect(() => {
        if (isSuccess) {
            const arr = Array(formAarray.length).fill('');
            setDiscussionOption(arr);
            const arr1 = Array(formAarray.length).fill('');
            setFeedbackOption(arr1);
            // values.competancies = [];
            // values.questions = [];
            setDiscussionData({});
            resetForm();
            toast.success('CBD Form A Information Stored Successfully!');
            history.push('/case-based-disscussion-list');
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);

    const handleDiscussion = (e: any, title: string, id: number) => {
        const item = { ...discussionData };

        if (e.target.value) {
            item[id] = {
                discussion_area: title,
                discussion_covered: e.target.value,
                feedback: discussionData[id]?.feedback!,
            }
        }
        setDiscussionData(item);
        // console.log(discussionData);
    }
    const handleFeedback = (e: any, title: string, id: number) => {
        const item = { ...discussionData };

        if (e.target.value) {
            item[id] = {
                discussion_area: title,
                discussion_covered: discussionData[id]?.discussion_covered!,
                feedback: e.target.value,
            }
        }
        setDiscussionData(item);
        // console.log(discussionData);
    }

    return (
        <div>
            {isCreateLoading || isLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Form
                className="custom-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
                onReset={(e) => {
                    // e.preventDefault();
                    const arr = Array(formAarray.length).fill('');
                    setDiscussionOption(arr);
                    const arr1 = Array(formAarray.length).fill('');
                    setFeedbackOption(arr1);
                    setDiscussionData({});
                    resetForm();
                }}
            >
                <Card className='mt-2'>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="date_of_assessment" className='form-label required-field'>Date Of Assesment</Label>
                                    <InputField
                                        type="date"
                                        placeholder=""
                                        invalid={{ errors, touched }}
                                        name='date_of_assessment'
                                        value={values.date_of_assessment = new Date(Date.now()).toISOString().slice(0, 10)}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="institute" className='form-label'>Accredited Institute</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Accridited Institute"
                                        {...getFieldProps('institute')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="supervisor_name" className='form-label'>Name Of Supervisor</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Name Of Supervisor"
                                        name='supervisor_name'
                                        value={values.supervisor_name = userInfo?.current_session_and_supervisor_info?.supervisor?.name!}
                                        readOnly
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="academic_status" className='form-label'>Academic Status</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Academic Status"
                                        {...getFieldProps('academic_status')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="student_name" className='form-label'>Student`s Name</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Student Name"
                                        name='student_name'
                                        value={values.student_name = userInfo?.personal_info?.full_name}
                                        readOnly
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="training_duration" className='form-label'>Years Of Training</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Training Years"
                                        {...getFieldProps('training_duration')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="diagnosis_of_the_case" className='form-label required-field'>Diagnosis Of the Case</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Diagnosis Of the Case"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('diagnosis_of_the_case')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="difficulty_level" className='form-label required-field'>Dificulty Level</Label>
                                    <InputField
                                        type="select"
                                        placeholder="Dificulty Level"
                                        invalid={{ errors, touched }}
                                        options={[
                                            { value: 'Simple', label: 'Simple' },
                                            { value: 'Moderate', label: 'Moderate' },
                                            { value: 'Difficult', label: 'Difficult' },
                                        ]}
                                        {...getFieldProps('difficulty_level')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="competency_to_be_covered" className='form-label required-field'>Competency Level</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Competency Level"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('competency_to_be_covered')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="start_time" className='form-label required-field'>Time Of Start</Label>
                                    <InputField
                                        type="time"
                                        placeholder="Time Of Start"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('start_time')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="end_time" className='form-label required-field'>Time Of Ending</Label>
                                    <InputField
                                        type="time"
                                        placeholder="Time Of Ending"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('end_time')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                <div className='mb-2'><strong>Areas to be considered Disccusion and feedback : </strong></div>
                                <Row>
                                    {formAarray.map((item, index) => (
                                        <Col sm={6} key={index}>
                                            <Label>{item}</Label>
                                            <Table className='table-bordered'>
                                                <thead>
                                                    <tr>
                                                        <td>Discussion Covered</td>
                                                        <td>Feedback to be given</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <InputField
                                                                type="textarea"
                                                                invalid={{ errors, touched }}
                                                                name='discussion_covered'
                                                                value={discussionOption[index]}
                                                                onChange={(e) => {
                                                                    handleDiscussion(e, item, index);
                                                                    const assesment = [...discussionOption];
                                                                    assesment[index] = e.target.value;
                                                                    setDiscussionOption(assesment);
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <InputField
                                                                type="textarea"
                                                                invalid={{ errors, touched }}
                                                                name='feedback'
                                                                value={feedbackOption[index]}
                                                                onChange={(e) => {
                                                                    handleFeedback(e, item, index);
                                                                    const feedback = [...feedbackOption];
                                                                    feedback[index] = e.target.value;
                                                                    setFeedbackOption(feedback);
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    ))
                                    }
                                </Row>
                                {/* <Row>
                                    <Col sm={6}>
                                        <Label>History Writing :</Label>
                                        <Table className='table-bordered'>
                                            <thead>
                                                <tr>
                                                    <td>Disscussion Covered</td>
                                                    <td>Feedback to be given</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <InputField
                                                            type="textarea"
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('history_writing1')}
                                                        />
                                                    </td>
                                                    <td>
                                                        <InputField
                                                            type="textarea"
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('history_writing2')}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col sm={6}>
                                        <Label>History Taking : </Label>
                                        <Table className='table-bordered'>
                                            <thead>
                                                <tr>
                                                    <td>Disscussion Covered</td>
                                                    <td>Feedback to be given</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <InputField
                                                            type="textarea"
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('history_taking1')}
                                                        />
                                                    </td>
                                                    <td>
                                                        <InputField
                                                            type="textarea"
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('history_taking2')}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <Label>Physical Examination: </Label>
                                        <Table className='table-bordered'>
                                            <thead>
                                                <tr>
                                                    <td>Disscussion Covered</td>
                                                    <td>Feedback to be given</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <InputField
                                                            type="textarea"
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('physical_exam1')}
                                                        />
                                                    </td>
                                                    <td>
                                                        <InputField
                                                            type="textarea"
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('physical_exam2')}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col sm={6}>
                                        <Label>Diagnosis and possible differentials with reasoning/justification : </Label>
                                        <Table className='table-bordered'>
                                            <thead>
                                                <tr>
                                                    <td>Disscussion Covered</td>
                                                    <td>Feedback to be given</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <InputField
                                                            type="textarea"
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('diagnocis1')}
                                                        />
                                                    </td>
                                                    <td>
                                                        <InputField
                                                            type="textarea"
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('diagnocis2')}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <Label>Planing Invasgination and Justification: </Label>
                                        <Table className='table-bordered'>
                                            <thead>
                                                <tr>
                                                    <td>Disscussion Covered</td>
                                                    <td>Feedback to be given</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <InputField
                                                            type="textarea"
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('planing1')}
                                                        />
                                                    </td>
                                                    <td>
                                                        <InputField
                                                            type="textarea"
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('planing2')}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col sm={6}>
                                        <Label>Planing Management and its Justification : </Label>
                                        <Table className='table-bordered'>
                                            <thead>
                                                <tr>
                                                    <td>Disscussion Covered</td>
                                                    <td>Feedback to be given</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <InputField
                                                            type="textarea"
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('planing_management1')}
                                                        />
                                                    </td>
                                                    <td>
                                                        <InputField
                                                            type="textarea"
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('planing_management2')}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <Label>Follow up Prognosis and Referral Knowledge: </Label>
                                        <Table className='table-bordered'>
                                            <thead>
                                                <tr>
                                                    <td>Disscussion Covered</td>
                                                    <td>Feedback to be given</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <InputField
                                                            type="textarea"
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('follow_up1')}
                                                        />
                                                    </td>
                                                    <td>
                                                        <InputField
                                                            type="textarea"
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('follow_up2')}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col sm={6}>
                                        <Label>Recent update on the topic: </Label>
                                        <Table className='table-bordered'>
                                            <thead>
                                                <tr>
                                                    <td>Disscussion Covered</td>
                                                    <td>Feedback to be given</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <InputField
                                                            type="textarea"
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('recent_update1')}
                                                        />
                                                    </td>
                                                    <td>
                                                        <InputField
                                                            type="textarea"
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('recent_update2')}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <Label>Communication skill/ Dealing with patient and attendants/ Ethical approach: </Label>
                                        <Table className='table-bordered'>
                                            <thead>
                                                <tr>
                                                    <td>Disscussion Covered</td>
                                                    <td>Feedback to be given</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <InputField
                                                            type="textarea"
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('communication_skill1')}
                                                        />
                                                    </td>
                                                    <td>
                                                        <InputField
                                                            type="textarea"
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('communication_skill2')}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col sm={6}>
                                        <Label>Overall clinical care: </Label>
                                        <Table className='table-bordered'>
                                            <thead>
                                                <tr>
                                                    <td>Disscussion Covered</td>
                                                    <td>Feedback to be given</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <InputField
                                                            type="textarea"
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('clinical_care1')}
                                                        />
                                                    </td>
                                                    <td>
                                                        <InputField
                                                            type="textarea"
                                                            invalid={{ errors, touched }}
                                                            {...getFieldProps('clinical_care2')}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row> */}
                                <Row className='mt-2'>
                                    <Col sm={3}>
                                        <Label className='form-label required-field'>Overall Performance : </Label>
                                    </Col>
                                    <Col sm={3}>
                                        <InputField
                                            className='form-select'
                                            type="select"
                                            placeholder=""
                                            options={performanceOption}
                                            invalid={{ errors, touched }}
                                            {...getFieldProps('overall_performance')}
                                        />
                                    </Col>
                                </Row>

                                <Row className='mt-3'>
                                    <Col sm={2}>
                                        <Label>Trainee Signature : </Label>
                                        <div className="img-fluid img-rounded">
                                            {userInfo?.personal_info?.signature &&
                                                <DisplayImage className="img-thumbnail profile-img-width" src={`${config?.APP_URL}${userInfo?.personal_info?.signature}`} />
                                            }
                                        </div>
                                    </Col>
                                    <Col sm={2}>
                                        <Label>Supervisor Signature : </Label>
                                        <div className="img-fluid img-rounded">
                                            {userInfo?.personal_info?.signature &&
                                                <DisplayImage className="img-thumbnail profile-img-width" src={`${config?.APP_URL}${userInfo?.personal_info?.signature}`} />
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <FormGroup className="mb-3 text-center">
                            <Button
                                type="reset"
                                className="btn btn-secondary me-2"
                            >
                                Reset
                            </Button>

                            <button
                                type="submit"
                                className="btn btn-success"
                            >
                                Submit
                            </button>
                        </FormGroup>
                    </CardBody>
                </Card>
            </Form>
        </div>
    );
};

export default CBDFormA;
