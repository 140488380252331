import { apiSlice } from 'src/rtk/api/apiSlice';
import * as examInfoSetupUrl from 'src/helpers/url_helper';

export const examInfoSetupApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['ExamInfos'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getExamInfos: builder.query<APIResponse<ExamInfo[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${examInfoSetupUrl.GET_EXAM_INFOS}`,
        }),
        providesTags: ['ExamInfos'],
      }),

      getExamInfo: builder.query<APIResponse<ExamInfo>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${examInfoSetupUrl.GET_EXAM_INFO}/${id}`,
        }),
        providesTags: ['ExamInfos'],
      }),

      createExamInfo: builder.mutation<APIResponse<ExamInfo>, ExamInfo>({
        query: (data) => ({
          url: `${examInfoSetupUrl.CREATE_EXAM_INFO}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['ExamInfos'],
      }),

      updateExamInfo: builder.mutation<APIResponse<ExamInfo>, string>({
        query: (url) => ({
          url: `${examInfoSetupUrl.UPDATE_EXAM_INFO}/${url}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['ExamInfos'],
      }),
      
      deleteExamInfo: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${examInfoSetupUrl.DELETE_EXAM_INFO}/${id}`,
        }),
        invalidatesTags: ['ExamInfos'],
      }),
    }),
  });

export const {
  useGetExamInfosQuery,
  useGetExamInfoQuery,
  useCreateExamInfoMutation,
  useUpdateExamInfoMutation,
  useDeleteExamInfoMutation,
} = examInfoSetupApi;
