import { useFormik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { Button, InputField } from "src/components";

const OperationTheater = () => {
  const { values, errors, touched, handleChange } = useFormik({
    initialValues: {
      demo: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="px-md-4 px-sm-2">
            {/* <h5 className="mb-3">Operation Theater</h5> */}
            <Col sm={12} md={6} lg={6}>
              {/* Department Name */}
              <div className="mb-3">
                <FormGroup className="mb-3">
                  <Label for="departmentName">Operation Theater</Label>
                  <Input type="select" name="departmentName">
                    <option value={0}>Separate Sterilization Area </option>
                    <option value={1}>Others</option>
                  </Input>
                </FormGroup>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <FormGroup>
                <div className="mb-3">
                  <h5 className="font-size-14 mb-3">
                    Separate Sterilization Area
                  </h5>
                  <div className="d-inline-block mx-2">
                    <div className="form-check form-check-left mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="hasDGHSLicense"
                        defaultChecked
                      />
                      <label className="form-check-label">Yes</label>
                    </div>
                  </div>
                  <div className="d-inline-block mx-2">
                    <div className="form-check form-check-left">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="hasDGHSLicense"
                      />
                      <label className="form-check-label">No</label>
                    </div>
                  </div>
                </div>
              </FormGroup>
            </Col>
            <FormGroup>
              <Label>Details</Label>
              <InputField
                type="textarea"
                name=""
                placeholder="Write details"
                onChange={handleChange}
                value={""}
                invalid={{ errors, touched }}
              />
            </FormGroup>
            {/* table */}
            <Col>
              <div className="mb-3 d-flex flex-row-reverse">
                <Button className="float-right px-3" text={"+ Add"} />
              </div>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-striped table-hover mb-0">
                      <thead>
                        <tr>
                          <th>OT</th>
                          <th>Status</th>
                          <th>Details</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Operation theater 1</td>
                          <td>Active</td>
                          <td>Details...</td>
                          <td>
                            <div className="btn btn-sm btn-primary d-inline mx-1">
                              Edit
                            </div>
                            <div className="btn btn-sm btn-danger d-inline">
                              Delete
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <h5 className="mb-3">
              Surgical Instrument: Provide list with number (Please use separate
              sheet)
            </h5>
            <Col sm={12} md={6} lg={6}>
              <FormGroup>
                <div className="mb-3">
                  <h5 className="font-size-14 mb-3">
                    Separate Sterilization Area
                  </h5>
                  <div className="d-inline-block mx-2">
                    <div className="form-check form-check-left mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="hasDGHSLicense"
                        defaultChecked
                      />
                      <label className="form-check-label">Yes</label>
                    </div>
                  </div>
                  <div className="d-inline-block mx-2">
                    <div className="form-check form-check-left">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="hasDGHSLicense"
                      />
                      <label className="form-check-label">No</label>
                    </div>
                  </div>
                </div>
              </FormGroup>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <h6>Mention Instrument List</h6>
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <FormGroup>
                    <Label for="totalBed">Name</Label>
                    <InputField
                      type="text"
                      name="totalBeds"
                      placeholder="Total Beds"
                      value={undefined}
                      invalid={{
                        errors,
                        touched,
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <FormGroup>
                    <Label for="totalBed">Quantity</Label>
                    <InputField
                      type="number"
                      name="totalCabins"
                      placeholder="Total Cabins"
                      onChange={handleChange}
                      value={""}
                      invalid={{ errors, touched }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            {/* table */}
            <Col sm={12} md={12} lg={12}>
              <div className="mb-3 d-flex flex-row-reverse">
                <Button className="float-right px-3" text={"+ Add"} />
              </div>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-hover mb-0">
                      <thead>
                        <tr>
                          <th>Sl</th>
                          <th>Instrument</th>
                          <th>Quantity</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Instrument name</td>
                          <td>21</td>
                          <td>
                            <div className="btn btn-sm btn-primary d-inline mx-1">
                              Edit
                            </div>
                            <div className="btn btn-sm btn-danger d-inline">
                              Delete
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <FormGroup>
                    <div className="mb-3">
                      <h5 className="font-size-14 mb-3">Recovery Name</h5>
                      <div className="d-inline-block mx-2">
                        <div className="form-check form-check-left mb-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="hasDGHSLicense"
                            defaultChecked
                          />
                          <label className="form-check-label">Yes</label>
                        </div>
                      </div>
                      <div className="d-inline-block mx-2">
                        <div className="form-check form-check-left">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="hasDGHSLicense"
                          />
                          <label className="form-check-label">No</label>
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>Number of Beds</Label>
                    <InputField
                      type="number"
                      name="totalCabins"
                      placeholder="Enter a number"
                      onChange={handleChange}
                      value={""}
                      invalid={{ errors, touched }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="totalBed">
                      Number of operating sessions per week
                    </Label>
                    <InputField
                      type="number"
                      name="totalBeds"
                      placeholder="Total operating sessions per week"
                      value={undefined}
                      invalid={{
                        errors,
                        touched,
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <FormGroup>
                    <Label for="totalBed">
                      Average number of major operations performed per session
                    </Label>
                    <InputField
                      type="number"
                      name="totalPayingBeds"
                      placeholder="Major operations performed per session"
                      invalid={{ errors, touched }}
                      value={undefined}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="totalBed">
                      Average number of minor operations performed per session
                    </Label>
                    <InputField
                      type="number"
                      name="totalPayingBeds"
                      placeholder="Minor operations performed per session"
                      invalid={{ errors, touched }}
                      value={undefined}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="totalBed">
                      Average number of emergency operations done per week
                    </Label>
                    <InputField
                      type="number"
                      name="totalPayingBeds"
                      placeholder="Average number of emergency operations done per week"
                      invalid={{ errors, touched }}
                      value={undefined}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="text-center mb-3">
            <Link to="/institute/subject-registration-3">
              <button
                type="submit"
                className="btn btn-primary w-25"
                onSubmit={(e) => {
                  e.preventDefault;
                }}>
                Save and Next {">"}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OperationTheater;
