import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';
import { Alert, Button, Col, Form, Label, Row } from 'reactstrap';
import { AuthLayout, InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import { useInstituteAdminRegistrationMutation } from 'src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi';
import * as Yup from 'yup';

const initialValues: InstituteAdminRegistration = {
    ins_admin_name: '',
    ins_admin_username: '',
    ins_admin_email: '',
    ins_admin_mobile: '',
    ins_admin_password: '',
    ins_admin_password_confirmation: '',
    institute_name: '',
    institute_location: '',
    institute_type: '',
    non_gov_ins_type: '',
};

const InstituteAdminRegistration = () => {

    const [showPassword, setShowPassword] = useState(true);
    const [showConfirmPassword, setShowConfirmPassword] = useState(true);
    const [nonGovtStatus, setNonGovtStatus] = useState<boolean>(false);

    const [
        instituteAdminRegistration,
        {
            data: registrationData,
            isLoading: isRegLoading,
            isError: isRegError,
            isSuccess: isRegSuccess,
            error: regError,
        },
    ] = useInstituteAdminRegistrationMutation();

    const err: CustomError = regError as CustomError;

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
        setErrors,
        getFieldProps,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({
            ins_admin_name: Yup.string().required('Name is required').max(50, 'Invalid Length'),
            ins_admin_username: Yup.string().required(
                'Username is required',
            ),
            ins_admin_email: Yup.string().email().required('Email is required'),
            ins_admin_mobile: Yup.string()
                .required('Mobile number is required')
                .max(14, 'Invalid mobile number')
                .matches(/^(?:\+88|88)?(01[0-9]\d{8})$/, 'Invalid mobile number'),
            ins_admin_password: Yup.string()
                .min(6, 'Password must be 6 characters long')
                .required('Password is required')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
                    'Must contain at least one uppercase, one lowercase, one numeric and one special character. Example: Abc@123',
                ),
            ins_admin_password_confirmation: Yup.string()
                .oneOf(
                    [Yup.ref('ins_admin_password'), null],
                    'Passwords and Confirm Password does not match',
                )
                .required('Password confirmation is required'),
            institute_name: Yup.string().required('Institute name is required'),
        }),

        onSubmit: (values) => {
            if (!nonGovtStatus) {
                values.non_gov_ins_type = '';
            }

            instituteAdminRegistration({
                ...values,
            });

            console.log(values);
        },
    });

    const history = useHistory();

    useEffect(() => {
        if (isRegSuccess) {
            setNonGovtStatus(false);
            toast.success('User registered successfully');
            history.push('/institute-login');
        }
        if (isRegError) {
            setNonGovtStatus(false);
            toast.error(err?.message || 'Something went wrong');
            setFromErrors(err.data, setErrors);
        }
    }, [isRegSuccess, isRegError]);

    useEffect(() => {
        if (values.institute_type === 'Government' || values.institute_type === 'Foreign') {
            setNonGovtStatus(false);
        } else if (values.institute_type === 'Private') {
            setNonGovtStatus(true);
        }
    }, [values.institute_type]);

    const handleFormReset = () => {
        setNonGovtStatus(false);
        resetForm();
    }

    return (
        <AuthLayout>
            <Row>
                <Form onSubmit={handleSubmit} onReset={handleFormReset} encType="multipart/form-data">
                    <Row>
                        <Col sm={12} md={6} lg={6}>
                            <div className="mb-3">
                                <Label className="form-label required-field">Name</Label>
                                <InputField
                                    type="text"
                                    placeholder="Enter Name"
                                    invalid={{ errors, touched }}
                                    {...getFieldProps('ins_admin_name')}
                                />
                            </div>
                        </Col>

                        <Col sm={12} md={6} lg={6}>
                            <div className="mb-3">
                                <Label className="form-label required-field">Username</Label>
                                <InputField
                                    type="text"
                                    placeholder="Enter Username"
                                    invalid={{ errors, touched }}
                                    {...getFieldProps('ins_admin_username')}
                                />
                            </div>
                        </Col>

                        <Col sm={12} md={6} lg={6}>
                            <div className="mb-3">
                                <Label className="form-label required-field">Mobile</Label>
                                <InputField
                                    type="text"
                                    placeholder="Enter Mobile Number"
                                    invalid={{ errors, touched }}
                                    {...getFieldProps('ins_admin_mobile')}
                                />
                            </div>
                        </Col>

                        <Col sm={6} md={6} lg={6}>
                            <div className="mb-3">
                                <Label className="form-label required-field">
                                    Email Address
                                </Label>
                                <InputField
                                    type="email"
                                    placeholder="Enter Email Address"
                                    invalid={{ errors, touched }}
                                    {...getFieldProps('ins_admin_email')}
                                />
                            </div>
                        </Col>

                        <Col sm={12} md={6} lg={6}>
                            <div className="mb-3">
                                <Label for="institute_name" className='form-label required-field'>Institute Name</Label>
                                <InputField
                                    type="text"
                                    placeholder="Enter Institute Name"
                                    invalid={{ errors, touched }}
                                    {...getFieldProps('institute_name')}
                                />
                            </div>
                        </Col>

                        <Col sm={12} md={6} lg={6}>
                            <div className="mb-3">
                                <Label for="institute_location" className='form-label required-field'>Institute Location</Label>
                                <InputField
                                    type="text"
                                    placeholder="Enter Institute Location"
                                    invalid={{ errors, touched }}
                                    {...getFieldProps('institute_location')}
                                />
                            </div>
                        </Col>

                        <Col md={6} sm={12}>
                            <div className="mb-3">
                                <Label className="form-label">Institute Type</Label>
                                <InputField
                                    type="select"
                                    placeholder=""
                                    options={
                                        [
                                            { label: 'Government', value: 'Government' },
                                            { label: 'Private', value: 'Private' },
                                            { label: 'Foreign', value: 'Foreign' }
                                        ]
                                    }
                                    invalid={{ errors, touched }}
                                    {...getFieldProps('institute_type')}
                                />
                            </div>
                        </Col>

                        <Col sm={12} md={6} lg={6}>
                            <div className="mb-3">
                                <Label className="form-label required-field">Password</Label>
                                <div className="input-group password-input">
                                    <InputField
                                        type={showPassword ? 'password' : 'text'}
                                        placeholder="Enter Password"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('ins_admin_password')}
                                    />
                                    <Button
                                        color="light"
                                        size="sm"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        <i
                                            className={`${showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'
                                                }`}
                                        />
                                    </Button>
                                </div>
                            </div>
                        </Col>

                        {nonGovtStatus ?
                            <>
                                <Col md={6} sm={12}>
                                    <div className='mb-3'>
                                        <div className="d-inline-block mx-2">
                                            <div className="form-check form-check-left">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="institutionStatus"
                                                    id="institutionStatusTrust"
                                                    value={'Trust'}
                                                    onChange={(e) => {
                                                        setFieldValue('non_gov_ins_type', 'Trust');
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor="institutionStatusTrust">Trust</label>
                                            </div>
                                        </div>
                                        <div className="d-inline-block mx-2">
                                            <div className="form-check form-check-left">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="institutionStatus"
                                                    id="institutionStatusFoundation"
                                                    value={'Foundation'}
                                                    onChange={(e) => {
                                                        setFieldValue('non_gov_ins_type', 'Foundation');
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor="institutionStatusFoundation">Foundation</label>
                                            </div>
                                        </div>
                                        <div className="d-inline-block mx-2">
                                            <div className="form-check form-check-left">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="institutionStatus"
                                                    id="institutionStatusLtdCo"
                                                    value={'Ltd. Co.'}
                                                    onChange={(e) => {
                                                        setFieldValue('non_gov_ins_type', 'Ltd. Co.');
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor="institutionStatusLtdCo">Ltd. Co.</label>
                                            </div>
                                        </div>
                                        <div className="d-inline-block mx-2">
                                            <div className="form-check form-check-left">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="institutionStatus"
                                                    id="institutionStatusOthers"
                                                    value={'Others'}
                                                    onChange={(e) => {
                                                        setFieldValue('non_gov_ins_type', 'Others');
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor="institutionStatusOthers">Others</label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} sm={12}>

                                </Col>
                            </> : null}

                        <Col sm={12} md={6} lg={6}>
                            <div className="mb-3">
                                <Label className="form-label required-field">
                                    Confirm Password
                                </Label>
                                <div className="input-group password-input">
                                    <InputField
                                        type={showConfirmPassword ? 'password' : 'text'}
                                        placeholder="Confirm Password"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('ins_admin_password_confirmation')}
                                    />
                                    <Button
                                        color="light"
                                        size="sm"
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    >
                                        <i
                                            className={`${showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'
                                                }`}
                                        />
                                    </Button>
                                </div>
                            </div>
                        </Col>

                        <Col sm={12} md={6} lg={6}>
                            <div className='card mt-4'>
                                <ul>
                                    <li>Password must be 6 characters long</li>
                                    <li>Password must contain at least one uppercase and one lowercase</li>
                                    <li>Password must contain at least one numeric value</li>
                                    <li>Password must contain at least one special character</li>
                                    <li>Example : Aa@12345</li>
                                </ul>                                
                            </div>
                        </Col>
                    </Row>


                    <Row>
                        <Col sm={12}>
                            <div className="mt-3 d-grid justify-content-center">
                                <button
                                    className="btn btn-primary btn-block d-flex  align-items-center"
                                    type="submit"
                                    disabled={isRegLoading}
                                >
                                    {!isRegLoading && <span className='px-5'>Register</span>}

                                    {isRegLoading && (
                                        <>
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className='px-5'>Registering...</span>
                                        </>
                                    )}
                                </button>
                            </div>

                            {isRegError ? (
                                <Alert className="mt-3" color="danger">
                                    {err.message}
                                </Alert>
                            ) : null}

                            <div className="text-center mt-3 mb-3">
                                <p className="my-3">
                                    Already have account?
                                    <Link to="/institute-login" className="btn btn-sm btn-outline-primary fw-semibold mx-1">
                                        Login
                                    </Link>
                                    here.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </AuthLayout>
    );
};

export default InstituteAdminRegistration;
