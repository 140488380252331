import { useFormik } from 'formik';
import { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetTraineeDeviceRegInfosQuery } from 'src/rtk/features/device-registration/deviceRegistrationApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import AddDeviceReg from './AddDeviceReg';
import DeviceRegTableRow from './DeviceRegTableRow';
import EditDeviceReg from './EditDeviceReg';

interface SearchFields {
  general_search_term?: string;
}

const initialValues: SearchFields = {
  general_search_term: '',
};

const TraineeDeviceRegList = () => {

  const [showAddForm, setShowAddForm] = useState<boolean>(false);
  const [deviceRegId, setDeviceRegId] = useState('');
  //   const [page, setPage] = useState<number | string>('1');
  //   const [paginate_per_page, set_paginate_per_page] = useState(20);
  //   const [search, setSearch] = useState('');
  const { data: deviceRegInfos, error, isLoading, isError } = useGetTraineeDeviceRegInfosQuery();

  const deviceInfos: DeviceInfo[] = deviceRegInfos?.data || [];
  const err: CustomError = error as CustomError;

  const editHandler = (id: string) => {
    setDeviceRegId(id);
    window.scrollTo(0, 0);
  };

  const deviceTypeOptions: SelectOption[] =
    useGetLookupDataQuery('device_type')
      .data?.data[0]?.childs.map((item) => ({
        value: item.name,
        label: item.name,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) || [];

  // what to render
  let content = null;

  if (isLoading) {
    content = <tr><td colSpan={5} className="text-center">Loading...</td></tr>;
  }
  if (!isLoading && isError && error) {
    content = <tr><td colSpan={5} className="text-center">{err.message}</td></tr>;
  }
  if (!isLoading && !isError && deviceInfos?.length > 0) {

    content = deviceInfos.map((deviceInfo, idx) => (
      <DeviceRegTableRow deviceRegInfo={deviceInfo} index={idx} editHandler={editHandler} key={deviceInfo.id!} />
    ));
  }

  const { handleSubmit, getFieldProps, values, touched, errors, resetForm, setErrors } =
    useFormik({
      initialValues,

      onSubmit: (values) => {

        let s: string = '';

        if (values.general_search_term !== '') {
          s = s + `&search=${values.general_search_term}`;
        }

        // setSearch(s);
        // setPage('1');
        // set_paginate_per_page(20);        
      },
    });

  return (
    <div className="page-content">
      <Container fluid>
        <div className='page-breadcrumb-wrapper'>
          <BetterBreadcrumb title="Device Registration"  breadcrumbItem={[{ link: '#', name: 'Device' }]}/>
          <div>
            <Button color="primary" onClick={() => setShowAddForm(!showAddForm)}>
              Add Device
            </Button>
          </div>
        </div>

        <Card>

          <CardBody>


            <Row>
              <Col>
                {/* <div className="gap-2 w-100 ">
                  <Form
                    className="custom-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="filter-wrapper">

                      <FormGroup>
                        <Label for="general_search_term">Device Type</Label>
                        <InputField
                          type="select"
                          placeholder=""
                          options={deviceTypeOptions}
                          invalid={{ errors, touched }}
                          {...getFieldProps('general_search_term')}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Button type="button" text="Search" onClick={() => {
                          handleSubmit();
                        }} />
                      </FormGroup>

                    </div>
                  </Form>
                </div> */}
              </Col>
            </Row>
            <div className="table-responsive">
              <Table className="table table-striped table-sm mb-0">
                <thead>
                  <tr>
                    <th>Sl.</th>
                    <th>Device Type</th>
                    <th>Manufacturer</th>
                    <th>Device ID</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
            {/* {!isLoading && <DeviceRegPaginate data={deviceRegInfos} setPage={setPage} setPaginatePerPage={set_paginate_per_page} />} */}
          </CardBody>
        </Card>
      </Container>
      <AddDeviceReg isOpen={showAddForm} toggle={() => setShowAddForm(!showAddForm)}  />
      {/*<EditDeviceReg id={deviceRegId} setShowAddForm={setShowAddForm} />*/}
    </div>
  );
};

export default TraineeDeviceRegList;
