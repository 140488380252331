import { RTMDAttendanceForm } from 'src/pages/RTMD/Attendance/Form';
import { RTMDAttendanceReport } from 'src/pages/RTMD/Attendance/Report';
import { RTMDAttendanceList } from 'src/pages/RTMD/Attendance/index';

import { RTMDBatchForm } from 'src/pages/RTMD/Batch/Add';
import { RTMDBatchList } from 'src/pages/RTMD/Batch/index';
//
// import { RTMDBatchList } from 'src/pages/RTMD/Batch2';
// import { RTMDBatchForm } from 'src/pages/RTMD/Batch2/Add';
// import { RTMDBatchForm } from 'src/pages/RTMD/Batch2/form';

import { AddRTMDTraining } from 'src/pages/RTMD/Training/Add';
import { RTMDTrainingList } from 'src/pages/RTMD/Training/index';

export const rtmdRoutes = [
  // RTMD Training
  { path: '/rtmd/training/', component: RTMDTrainingList },
  { path: '/rtmd/training/add', component: AddRTMDTraining },
  { path: '/rtmd/training/update/:id', component: AddRTMDTraining },

  // RTMD Batch
  { path: '/rtmd/batch/', component: RTMDBatchList },
  { path: '/rtmd/batch/add', component: RTMDBatchForm },
  { path: '/rtmd/batch/update/:id', component: RTMDBatchForm },

  // RTMD Attendance
  { path: '/rtmd/attendance/', component: RTMDAttendanceList },
  { path: '/rtmd/attendance/form/:id', component: RTMDAttendanceForm },
  { path: '/rtmd/attendance/report/:id', component: RTMDAttendanceReport },
];
