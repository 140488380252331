import { Redirect, Route } from 'react-router-dom';
import { useAppSelector } from 'src/rtk/app/hook';

interface AuthLayoutProps {
  component: any;
  layout: any;
  isAuthProtected: any;
  path?: string;
  exact?: boolean;
  key?: number;
}

const AuthMiddleware = ({
  component,
  layout,
  isAuthProtected,
  path,
  exact,
  key,
  ...rest
}: AuthLayoutProps) => {
  const Layout = layout;
  const Component = component;

  const { token: userToken, user: loginUser } = useAppSelector(
    (state) => state.auth,
  );

  const isAuth = isAuthProtected && !userToken && !loginUser.name;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuth) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          );
        }
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default AuthMiddleware;
