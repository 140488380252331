import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container, Table } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetLogbookEntrySetupDetailsRecordsQuery } from 'src/rtk/features/setup/logBookEntrySetupDetailsRecord/logBookEntrySetupDetailsRecordApi';
import LogbookEntrySetupDetailsRecordTableRow from './LogbookEntrySetupDetailsRecordTableRow';

export const LogbookEntrySetupDetailsRecords = () => {
  const { data, error, isLoading, isError } = useGetLogbookEntrySetupDetailsRecordsQuery();

  const err: CustomError = error as CustomError;
  const logbookEntrySetupDetailsRecords = data?.data || [];

  let content = null;

  if (isLoading) {
    content = <div className="mt-3">Loading...</div>;
  }
  if (!isLoading && isError && error) {
    content = <div className="mt-3">{err.message}</div>;
  }
  if (!isLoading && !isError && logbookEntrySetupDetailsRecords?.length > 0) {
    content = logbookEntrySetupDetailsRecords.map((logbookEntrySetupDetailsRecord: any, index: any) => (
      <LogbookEntrySetupDetailsRecordTableRow logbookEntrySetupDetailsRecord={logbookEntrySetupDetailsRecord} index={index} key={logbookEntrySetupDetailsRecord.id} />
    ));
  } else {
    content = (
      <tr>
        <td colSpan={11}>
          <div className="text-center text-danger h6">No data available!</div>
        </td>
      </tr>
    );
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Logbook Entry Setup Details Record List'}
          breadcrumbItem={[{ link: '/setup/logbook-entry-setup-details-record/add', name: 'Add Logbook Entry Setup Details Record' }]}
        />
        <Card>
          <CardHeader className="d-flex justify-content-end">
            <Link className="btn btn-sm btn-primary" to="/setup/logbook-entry-setup-details-record/add">
              Add Logbook Entry Setup Details Record
            </Link>
          </CardHeader>
          <CardBody>
            <div className="table-responsive">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Sl. </th>
                    <th>Logbook Entry Setup Detail</th>
                    <th>Details Text</th>                                     
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
