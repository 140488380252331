import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container, Table } from 'reactstrap';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { useGetLookupItemQuery } from 'src/rtk/features/setup/lookup/lookupDetails';
import LookupTableRow from './LookupTableRow';

export const LookupItem = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { data, error, isLoading, isError } = useGetLookupItemQuery(uuid);

  const lookupDetails = data?.data || ({} as LookupDetails);
  const err: CustomError = error as CustomError;

  let content = null;
  if (isLoading) {
    content = <div className="mt-3">Loading...</div>;
  }
  if (!isLoading && isError && error) {
    content = <div className="mt-3">{err.message}</div>;
  }
  if (!isLoading && !isError && lookupDetails.childs.length > 0) {
    content = lookupDetails.childs.map((item, index) => (
      <LookupTableRow
        key={index}
        index={index}
        lookup={item}
        parentName={lookupDetails.lookup_name}
      />
    ));
  } else {
    content = (
      <tr>
        <td colSpan={11}>
          <div className="text-center text-danger h6">No data available!</div>
        </td>
      </tr>
    );
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Lookup Data" breadcrumbItem="Lookup" />

        <Card>
          <CardHeader className="text-center align-content-center">
            <div className="fw-bold fs-5">Lookup List</div>
          </CardHeader>
          <CardBody>
            <div className="table-responsive text-nowrap x-overflow-scroll">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Sl. </th>
                    <th>Lookup Name</th>
                    <th>Lookup List Name</th>
                    <th>Serial</th>
                    <th>Status</th>
                    <th>Default Ind</th>
                    <th>Required Ind</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
