import html2pdf from 'html2pdf.js';
import moment from 'moment';
import { useEffect } from 'react';
import loader from '../../assets/images/loader.gif';

interface Props {
  data: any;
  setAllowanceId: any;
  setSubmitting?: any;
}

export const TrainingAllowanceApplicationPDF = (props: Props) => {
  const { data, setAllowanceId, setSubmitting } = props;
  const image1 = new Image();
  image1.src = '/images/apple-icon-180x180.png';
  const image2 = new Image();
  console.log(data);

  const fcpsPartOneInfo = data?.registration?.educational_infos?.find((item: any) => item?.name_of_the_degree === 'FCPS-Part-1');
  const mmbbsInfo = data?.registration?.educational_infos?.find((item: any) => item?.name_of_the_degree === 'MBBS');
  const bdsInfo = data?.registration?.educational_infos?.find((item: any) => item?.name_of_the_degree === 'BDS');

  const html =`
    <html>
      <style>
        .print-container{
          padding: 0 15px;
        }

        .table-bordered {
          border: 1px solid;
        }

        .wide-column {
          width: 500px;
          background-color: powderblue;
        }
        
        .flex-container {
          display: flex;
          flex-wrap: nowrap;
        }

        .flex-container > div {
          width: 300px;
          text-align: center;
        }
        .fontName {
          font-family: "Times New Roman", Times, serif;
          line-height: 1.5;
        }        

        .box {
          width: 320px;
          height: 50px;
          padding: 10px;
          border: 1px solid gray;
        }

        b {
          font-family: 'Times-Bold'; 
        }
        p {
          font-size: 14px
        }

        .align-right {
          text-align: right;
        }
      </style>
      <body>

        <div class="fontName">
          <div class="row">
            <div class="col-md-8" style="text-align: center; margin-left: 100px">
              <h4>BANGLADESH COLLEGE OF PHYSICIANS AND SURGEONS (BCPS)</h4>
            </div>
            <div class="border border-dark text-center p-3 ms-2 col-md-2">
              <p>2 copies Passport size Coloured Photo</p>
            </div>
          </div>
          <div class="text-center mb-3">
            <h5>Application form</h5>
            <h5>(Training allowances for the FCPS Part-I qualified honorary trainees)</h5>
          </div>
          
          <table class="table table-bordered table-sm mb-5">
            <thead>
              <tr class="bg-light">
                <th colspan="2">General Information</th>
              </tr>
            </thead>
            <tbody>
              <tr >
                <td class="col-md-4">Applicant's Name (Block Letters):</td>
                <td class="col-md-8">${data?.registration?.personal_info?.full_name.toUpperCase() ?? ''}</td>
              </tr>
              <tr>
                <td>Father's/Spouse Name:</td>
                <td>${data?.registration?.personal_info?.fathers_name ?? ''}</td>
              </tr>
              <tr>
                <td>Mother's Name: </td>
                <td>${data?.registration?.personal_info?.mothers_name ?? ''}</td>
              </tr>
              <tr>
                <td>Date of Birth: </td>
                <td>${moment(data?.registration?.personal_info?.date_of_birth).format('DD-MMM-YYYY') ?? ''}</td>
              </tr>
              <tr>
                <td>Nationality: </td>
                <td>Bangladeshi</td>
              </tr>
              <tr>
                <td>Religion: </td>
                <td>${data?.registration?.personal_info?.religion ?? ''}</td>
              </tr>
              <tr>
                <td>National ID No.: </td>
                <td>${data?.registration?.personal_info?.national_id ?? ''}</td>
              </tr>
              <tr>
                <td>Address of communication</td>
                <td>
                  <div>
                   ${data?.registration?.address_infos[1]?.address_line_1 ?? ''}
                  </div>
                  <hr />
                  <div class="d-flex justify-content-between">
                    <div>
                      Mobile: ${data?.registration?.personal_info?.mobile ?? ''}
                    </div>
                    <div>
                      Tel(Res): ${data?.registration?.personal_info?.telephone ?? ''}
                    </div>                    
                  </div>
                  <hr />
                  <div>
                   Email: ${data?.registration?.personal_info?.email ?? ''}
                  </div>
                </td>
              </tr>
              <tr>
                <td>Permanent Address:</td>
                <td>${data?.registration?.address_infos[0]?.address_line_2 ?? ''}</td>
              </tr>
              <tr>
                <td>MBBS/BDS Data</td>
                <td>
                  <div>
                    Year Of Qualification: ${(mmbbsInfo ? mmbbsInfo?.passing_year : bdsInfo?.passing_year) ?? ''}
                  </div>
                  <hr />
                  <div>
                    Institute: ${(mmbbsInfo ?  mmbbsInfo?.institute_or_university : bdsInfo?.institute_or_university) ?? ''}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  BMDC Reg. No.:
                </td>
                <td>
                  ${data?.registration?.bmdc_registration_no ?? ''}
                </td>
              </tr>
              <tr>
                <td>
                  FCPS PART-1 Examination Data
                </td>
                <td>
                  <div class="d-flex justify-content-between">
                    <p>Speciality: ${fcpsPartOneInfo?.subject ?? ''}</p>
                    <p>Roll No: ${fcpsPartOneInfo?.roll ?? ''}</p>
                  </div>
                  <hr />
                  <div class="d-flex justify-content-between">
                    <p>Year Of Passing: ${data?.registration?.fcps_part_one_passing_year !== "undefined" ? data?.registration?.fcps_part_one_passing_year : ''}</p>
                    <p>Session : ${data?.registration?.fcps_part_one_passing_session !== "undefined" ? data?.registration?.fcps_part_one_passing_session : ''}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Online Reg No./ <br />
                  Reg. No. (after passing FCPS Part-1)
                </td>
                <td>
                  1
                </td>
              </tr>
              <tr>
                <td>
                  Are you selected or continuing the residency training/diploma course/ Govt. service/Private service?
                </td>
                <td>
                  <div>
                    Starting Date: ${data?.start_date ? moment(data?.start_date).format('DD-MMM-YYYY') : ''}
                  </div>
                  <div>
                    Ending Date: ${data?.end_date ? moment(data?.end_date).format('DD-MMM-YYYY') : ''}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table table-bordered table-sm breakPage">
            <thead>
              <tr class="bg-light">
                <th colspan="6">Current training database: (Please mention here current six month training duration only, if you have)</th>
              </tr>
              <tr class="bg-light">
                <th colspan="6">Are you continuing the FCPS training? <img src="/images/checkbox.png" style="width: 20px; margin-left: 25px;" /> Yes <img src="/images/checkbox.png" style="width: 20px; margin-left: 25px;" />  No If yes, please fill up the following table</th>
              </tr>
              <tr>
                <th rowspan="2">Name of the Institutes</th>
                <th rowspan="2">Name of the Department</th>
                <th rowspan="2">Name of the Supervisor, Designation</th>
                <th colspan="2" class="text-center">Duration of training</th>
                <th class="text-center">Continuing training</th>
              </tr>

              <tr>
                <th>Starting date</th>
                <th>Ending date</th>
                <th class='col-auto'>In Month</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>${data?.registration?.current_session_and_supervisor_info?.supervisor?.institute_name ?? ''}</td>
                <td>${data?.registration?.current_session_and_supervisor_info?.supervisor?.department_name ?? ''}</td>
                <td>${data?.registration?.current_session_and_supervisor_info?.supervisor?.name ?? ''},${ data?.registration?.current_session_and_supervisor_info?.supervisor?.designation ?? ''}</td>
                <td>${data?.registration?.current_session_and_supervisor_info?.session_start_date ?? ''}</td>
                <td>${data?.registration?.current_session_and_supervisor_info?.session_end_date ?? ''}</td>
                <td>6 Month</td>
              </tr>
            </tbody>
          </table>
          <table class="table table-bordered table-sm">
            <thead>             
              <tr class="bg-light">
                <th colspan="5">Have you obtained FCPS training before: (Please mention here previous completed training of every six month duration)</th>
              </tr>
              <tr>
                <th rowspan="2">Name of the Institutes</th>
                <th rowspan="2">Name of the Department</th>
                <th rowspan="2">Name of the Supervisor, Designation</th>
                <th colspan="2" class="text-center">Duration of training</th>
                <th class="text-center">Total Previous training</th>
              </tr>

              <tr>
                <th>Starting date</th>
                <th>Ending date</th>
                <th class='col-auto'>In month</th>
              </tr>
            </thead>
            <tbody>
              ${data?.previous_slots?.map((item: any) => 
                `
                <tr>
                  <td>${item?.supervisor?.institute_name ?? ''}</td>
                  <td>${item?.supervisor?.department_name ?? ''}</td>
                  <td>${item?.supervisor?.name ?? ''},${item?.supervisor?.designation ?? ''}</td>
                  <td>${item?.session_start_date ?? ''}</td>
                  <td>${item?.session_end_date ?? ''}</td>
                  <td>6 Month</td>
                </tr>
                `
              ).join('')}
            </tbody>
          </table>
          <table class="table table-bordered table-sm">
            <thead>             
              <tr class="bg-light">
                <th colspan="5">Mention the name of the institutes with department recognized by BCPS according to your choice where you want to obtain the fellowship training: (Please schedule the rest of training excluding current duration)</th>
              </tr>
              <tr>
                <th rowspan="2">Name of the Institutes</th>
                <th rowspan="2">Name of the Department</th>
                <th colspan="2" class="text-center">Duration of training</th>
                <th class="text-center">Total Remaining training</th>
              </tr>

              <tr>
                <th>Starting date</th>
                <th>Ending date</th>
                <th class='col-auto'>In month</th>
              </tr>
            </thead>
            <tbody>
              ${data?.next_sessions?.map((item: any) => 
                `
                <tr>
                  <td>${item?.institute?.organization_name ?? ''}</td>
                  <td>${item?.department?.department_name ?? ''}</td>
                  <td>${moment(item?.starting_date).format('DD-MMM-YYYY') ?? ''}</td>
                  <td>${moment(item?.ending_date).format('DD-MMM-YYYY') ?? ''}</td>
                  <td>6 Month</td>
                </tr>
                `
              ).join('')}
              ${data?.next_sessions.length > 0 && 
              `<tr>
                <td colspan="5">
                  <div class="d-flex justify-content-end">
                    ${data?.next_sessions.length*6} Month
                  </div>
                </td>
              </tr>`}
            </tbody>
          </table>
          <div class="border mb-2 breakPage">
            <div class="bg-light">
              <b>Bank Information of the applicants</b>
            </div>
            <div class="row">
              <div class="col-md-6">
                <p>Name in block letters (as per Bank Account): ${data?.registration?.personal_info?.full_name.toUpperCase() ?? ''}</p>
                <p>Name of the bank: ${data?.registration?.bank_info?.bank_name ?? ''}</p>
                <p>Account Number (13 digits or above): ${data?.registration?.bank_info?.account_no ?? ''}</p>
              </div>
              <div class="col-md-6">
                <p>Name of the branch: ${data?.registration?.bank_info?.branch_name ?? ''}</p>
                <p>Routing Number: ${data?.registration?.bank_info?.routing_no ?? ''}</p>
              </div>
            </div>
          </div>
          <div class="border mb-2 breakPage">
            <div class="bg-light">
              <b>Undertaking</b>
            </div>
            <div>
              <p>
                I Dr. ${data?.registration?.personal_info?.full_name ?? ''} declared that the information given by me in this form
                is entirely true and authentic. The application may be cancelled if any information mentioned above is found to be false or incomplete.               
              </p>
              <p>
                ......................... <br />
                Applicant's Signature & Date
              </p>
            </div>
          </div>
          <div class="border breakPage">
            <div class="bg-light text-center">
              <b>For Official use only</b>
            </div>
            <div class="bg-light">
              <b>Applicant's will be scrutinized by the department of Research and Training Monitoring (RTM) of BCPS</b>             
            </div>
            <div class="row">
              <div class="col-md-6">
                <p>The applicant is: <img src="/images/checkbox.png" style="width: 20px; margin-left: 25px;" /> eligible 
                  <img src="/images/checkbox.png" style="width: 20px; margin-left: 25px;" /> not eligible
                </p>
                <div class="d-flex justify-content-between mt-4">
                  <div>
                    <p>......................................</p>
                    <p>Principal Research Officer</p>
                  </div>
                  <div>
                    <p>......................................</p>
                    <p>Honorary Director (RTM)</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="d-flex justify-content-between mt-5">
                  <div>
                    <p>....................................</p>
                    <p>Deputy Director Admin</p>
                  </div>
                  <div>
                    <p>..........................</p>
                    <p>Director Admin</p>
                  </div>
                  <div>
                    <p>...............................</p>
                    <p>Honorary Secretary</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </html>`
    ;

  useEffect(() => {
    setAllowanceId('');
    setSubmitting(false);
  }, []);

  const options = {
    margin: [10, 10, 20, 10],
    image: { type: 'png', quality: 0.98 },
    html2canvas: { scale: 2, useCORS: true },
    pagebreak: { avoid: ['div', '.breakPage'] },
  };

  html2pdf()
    .from(html)
    .set(options)
    .toPdf()
    .get('pdf')
    .then(function (pdf: any) {
      var totalPages = pdf.internal.getNumberOfPages();

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10);
        pdf.setTextColor(150);
        if(i === 1) {
          pdf.addImage(image1, "PNG", 10, 10, 20, 20);
        }
      }

      window.open(pdf.output('bloburl'), '_blank');
    });

  return null;
};
