import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { InputField } from 'src/components';
import DisplayImage from 'src/components/Common/displayImage';
import { config } from 'src/config';
import { setFromErrors } from 'src/helpers';
import { useCreateFormBEntryMutation } from 'src/rtk/features/miniCexCBD/miniCexAndCBDApi';
import { useGetMyRegInfoQuery } from 'src/rtk/features/registration-approval/registrationApprovalApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

interface Props {
    regitrationNo: string;
    supervisorId: string;
    sessionId: string;
}

const MiniCexFormB = (props: Props) => {
    const { regitrationNo, supervisorId, sessionId } = props;

    const history = useHistory();

    const { data, isLoading } = useGetMyRegInfoQuery(regitrationNo);
    const userInfo = data?.data || ({} as RegInfo);

    const { data: allListFormB } = useGetLookupDataQuery('ins_form_b');
    const { data: allListRatingDesc } = useGetLookupDataQuery('monitoring_form_b_part2');
    const { data: questionList } = useGetLookupDataQuery('monitoring_form_b_question');

    const formBData: Lookup[] =
        (allListFormB as any)?.data[0]?.childs || [];

    const ratings: Lookup[] =
        (allListRatingDesc as any)?.data[0]?.childs || [];

    const questions: Lookup[] =
        (questionList as any)?.data[0]?.childs || [];


    const [miniCexFormBdata, setMiniCexFormBdata] = useState<any>({});
    const [questionData, setQuestionData] = useState<any>({});

    const [assesmentOption, setAssesmentOption] = useState(Array(formBData.length).fill(''));
    const [questionsOption, setQuestionsOption] = useState(Array(questions.length).fill(''));

    const performanceOption: SelectOption[] =
        useGetLookupDataQuery('monitoring_form_b_performance_option')
            .data?.data[0]?.childs.map((item) => ({
                value: item.name,
                label: item.name,
            })) || [];

    const [createFormBEntry, { isSuccess, isError, error, isLoading: isCreateLoading }] = useCreateFormBEntryMutation();
    const err: CustomError = error as CustomError;

    const initialValues: MiniCEXFormB = {
        registration_id: '',
        supervisor_id: '',
        logbook_entry_session_id: '',
        date_of_assessment: '',
        difficulty_level: '',
        start_time: '',
        end_time: '',
        diagnosis_of_the_case: '',
        competency_to_be_covered: '',
        institute: '',
        supervisor_name: '',
        academic_status: '',
        student_name: '',
        training_duration: '',
        trainee_signature: '',
        supervisor_signature: '',
        ratings: {},
        competancies: [],
        questions: [],
    }

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({
            questions: Yup.mixed().required('required'),
            // date_of_assessment: Yup.mixed().required('required'),
            difficulty_level: Yup.string().required('Please Select!'),
            diagnosis_of_the_case: Yup.string().required('Diagnosis of the case Required'),
            start_time: Yup.string().required('Start Time is Required'),
            end_time: Yup.string()
                .required('End time is required')
                .test('is-after-start', 'End time must be after start time', function (
                    value
                ) {
                    const { start_time } = this.parent;
                    return !start_time || !value || value > start_time;
                }),
            competency_to_be_covered: Yup.string().required('Competency is Required'),
        }),

        onSubmit: (values: any) => {
            values.competancies = [];
            values.questions = [];


            values.registration_id = regitrationNo;
            values.supervisor_id = supervisorId;
            values.logbook_entry_session_id = sessionId;

            Object.keys(miniCexFormBdata).forEach((key) => {
                values.competancies.push(miniCexFormBdata[key]);
            })

            Object.keys(questionData).forEach((key) => {
                values.questions.push(questionData[key]);
            })

            console.log(values);
            createFormBEntry(values);
        },
    });


    useEffect(() => {
        if (isSuccess) {
            const arr = Array(formBData.length).fill('');
            setAssesmentOption(arr);
            const arr1 = Array(questions.length).fill('');
            setQuestionsOption(arr1);
            // values.competancies = [];
            // values.questions = [];
            setMiniCexFormBdata({});
            setQuestionData({});
            values.ratings = {};
            resetForm();
            toast.success('Form B Information Stored Successfully!');
            history.push('/mini-cex-list');
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);


    const handleCompetences = (e: any, particulars: string, id: string) => {
        const item = { ...miniCexFormBdata };

        if (e.target.value) {
            item[id] = {
                particulars: particulars,
                assessment: e.target.value,
            }
        }
        setMiniCexFormBdata(item);
    }

    const handleRatingDescription = (e: any, rating: string, description: string) => {
        if (e.target.checked) {
            values.ratings = {
                ratings: rating,
                description: description,
            }
        }
        // console.log(values.ratings);
    }

    const handleQuestionAnswer = (e: any, question: string, id: string) => {
        const item = { ...questionData };

        if (e.target.value) {
            item[id] = {
                question: question,
                answer: e.target.value,
            }
        }
        setQuestionData(item);
    }

    return (
        <div>
            {isCreateLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Form
                className="custom-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
                onReset={(e) => {
                    // e.preventDefault();
                    setMiniCexFormBdata({});
                    setQuestionData({});
                    const arr = Array(formBData.length).fill('');
                    setAssesmentOption(arr);
                    resetForm();
                }}
            >
                <Card className='mt-2'>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="date_of_assessment" className='form-label required-field'>Date Of Assesment</Label>
                                    <InputField
                                        type="date"
                                        placeholder=""
                                        invalid={{ errors, touched }}
                                        name='date_of_assessment'
                                        value={values.date_of_assessment = new Date(Date.now()).toISOString().slice(0, 10)}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="institute" className='form-label'>Accredited Institute</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Accridited Institute"
                                        {...getFieldProps('institute')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="supervisor_name" className='form-label'>Name Of Supervisor</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Name Of Supervisor"
                                        name='supervisor_name'
                                        value={values.supervisor_name = userInfo?.current_session_and_supervisor_info?.supervisor?.name!}
                                        readOnly
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="academic_status" className='form-label'>Academic Status</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Academic Status"
                                        {...getFieldProps('academic_status')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="student_name" className='form-label'>Student`s Name</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Student Name"
                                        name='student_name'
                                        value={values.student_name = userInfo?.personal_info?.full_name}
                                        readOnly
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="training_duration" className='form-label'>Years Of Training</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Training Years"
                                        {...getFieldProps('training_duration')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="diagnosis_of_the_case" className='form-label required-field'>Diagnosis Of the Case</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Diagnosis Of the Case"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('diagnosis_of_the_case')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="difficulty_level" className='form-label required-field'>Dificulty Level</Label>
                                    <InputField
                                        type="select"
                                        placeholder="Dificulty Level"
                                        invalid={{ errors, touched }}
                                        options={[
                                            { value: 'Simple', label: 'Simple' },
                                            { value: 'Moderate', label: 'Moderate' },
                                            { value: 'Difficult', label: 'Difficult' },
                                        ]}
                                        {...getFieldProps('difficulty_level')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="competency_to_be_covered" className='form-label required-field'>Competences To be Covered</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Competences To be Covered"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('competency_to_be_covered')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="start_time" className='form-label required-field'>Time Of Start</Label>
                                    <InputField
                                        type="time"
                                        placeholder="Time Of Start"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('start_time')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="end_time" className='form-label required-field'>Time Of Ending</Label>
                                    <InputField
                                        type="time"
                                        placeholder="Time Of Ending"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('end_time')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Table className='table-bordered'>
                            {formBData.map((item: any, index: number) => (
                                <tr key={item.id}>
                                    <td className='col-sm-4'>
                                        <div>
                                            {item.name}
                                        </div>
                                    </td>
                                    <td className='col-sm-2'>
                                        <div className='m-1 p-1'>
                                            <InputField
                                                className='form-select'
                                                type="select"
                                                placeholder=""
                                                options={performanceOption}
                                                name='competancies'
                                                value={assesmentOption[index]}
                                                onChange={(e: any) => {
                                                    handleCompetences(e, item.name, item.id);
                                                    const assesment = [...assesmentOption];
                                                    assesment[index] = e.target.value;
                                                    setAssesmentOption(assesment);
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td></td>
                                </tr>
                            ))}
                        </Table>

                        <div>
                            <p><strong> Based On above observation, please rate the level of overall competence of the student : </strong></p>
                        </div>
                        <Table className='table-bordered'>
                            <thead>
                                <tr>
                                    <th>Rating</th>
                                    <th>Description</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>
                                    <td>Below expectation for the stage of training</td>
                                    <td>Basic consultation skills resulting in incomplete history and/or examination findings. Limited clinical judgement following encounter</td>
                                    <td>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="score_check"
                                            name="score_check"
                                        />
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td>Meets expectation for stage of training</td>
                                    <td>Sound consultation skills resulting in adequate history and/or examination findings. Basic clinical judgement following encounter</td>
                                    <td>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="score_check"
                                            name="score_check"
                                      
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Above expectation for stage of training</td>
                                    <td>Good consultation skills resulting in a sound history, and/or examination findings. Solid clinical judgement following encounter higher than expected for stage of training</td>
                                    <td>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="score_check"
                                            name="score_check"
                                        
                                        />
                                    </td>
                                </tr> */}
                                {ratings.map((item: any) => (
                                    <tr key={item.id}>
                                        <td>
                                            {item.name}
                                        </td>
                                        {
                                            item.childs.map((i: any) => (
                                                <td key={i.id}>
                                                    {i.name}
                                                </td>
                                            ))
                                        }
                                        {
                                            item.childs.map((i: any) => (
                                                <td key={i.id}>
                                                    <Input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="type"
                                                        id="type-guide"
                                                        onChange={(e: any) => {
                                                            handleRatingDescription(e, item.name, i.name);
                                                        }}
                                                    // checked={guideChecked}
                                                    />
                                                </td>
                                            ))
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        {questions.map((item: any, index: number) => (
                            <Row key={item.id}>
                                <Col>
                                    <Label>{item.name}</Label>
                                    <InputField
                                        type="textarea"
                                        invalid={{ errors, touched }}
                                        name='questions'
                                        value={questionsOption[index]}
                                        onChange={(e: any) => {
                                            handleQuestionAnswer(e, item.name, item.id);
                                            const qa = [...questionsOption];
                                            qa[index] = e.target.value;
                                            setQuestionsOption(qa);
                                        }}
                                    />
                                </Col>
                            </Row>
                        ))}

                        <Row className='mt-3'>
                            <Col sm={2}>
                                <Label>Trainee Signature : </Label>
                                <div className="img-fluid img-rounded">
                                    {userInfo?.personal_info?.signature &&
                                        <DisplayImage className="img-thumbnail profile-img-width" src={`${config?.APP_URL}${userInfo?.personal_info?.signature}`} />
                                    }
                                </div>
                            </Col>
                            <Col sm={2}>
                                <Label>Supervisor Signature : </Label>
                                <div className="img-fluid img-rounded">
                                    {userInfo?.personal_info?.signature &&
                                        <DisplayImage className="img-thumbnail profile-img-width" src={`${config?.APP_URL}${userInfo?.personal_info?.signature}`} />
                                    }
                                </div>
                            </Col>
                        </Row>

                        <FormGroup className="mb-3 text-center mt-3">
                            <Button
                                type="reset"
                                className="btn btn-secondary me-2"
                            >
                                Reset
                            </Button>

                            <button
                                type="submit"
                                className="btn btn-success"
                            >
                                Submit
                            </button>
                        </FormGroup>
                    </CardBody>
                </Card>
            </Form>
        </div>
    );
};

export default MiniCexFormB;
