import { useState } from 'react';
import { useGetSessionWiseMiniCexListQuery } from 'src/rtk/features/miniCexCBD/miniCexAndCBDApi';
import { DopsPDFWrapper } from './DopsPDFWrapper';

interface Props {
    printId: any;
    setPrintId: any;
    data?: any;
    dopsAssessmentData?: any;
}

export const DopsPDF = (props: Props) => {
    const { printId, setPrintId, data, dopsAssessmentData } = props;

    const [flag, setFlag] = useState<string>('random text');

    const image1 = new Image();
    image1.src = '/images/notice_header.png';
    const image2 = new Image();
    image2.src = '/images/notice_footer.png';
    const performanceNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    const options = {
        margin: [15, 15, 20, 15],
        image: { type: 'png', quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        pagebreak: { avoid: ['div', '.none'] },
    };

    return (
        <>
            {flag !== '' && data ? <DopsPDFWrapper data={data} dopsAssessmentData={dopsAssessmentData} performanceNumber={performanceNumber} options={options} setPrintId={setPrintId} setFlag={setFlag} /> : null}
        </>
    );
};
