import { useEffect, useState } from 'react';
import { ConfirmToast } from 'react-confirm-toast';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { Button, Container } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useDeleteSubmittedQueryMutation, useGetSubmittedQueryListQuery } from 'src/rtk/features/researchClinicSetup/researchClinicSetupApi';
import loader from '../../assets/images/loader.gif';
import AnswerViewModal from './AnswerViewModal';
import EditResearchClinicApplication from './EditResearchClinicApplication';
import FCPSFinalExam from './FCPSFinalExam';
import ResearchClinicApplication from './ResearchClinicApplication';

const ApplicationEntryList = () => {
  const [answer, setAnswer] = useState<any>({});

  const [showEditForm, setShowEditForm] = useState<boolean>(true);
  const [info, setInfo] = useState<any>({});

  const [infoId, setInfoId] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [answerModalShow, setAnswerModalShow] = useState(false);
  const [researchClinicModalOpen, setResearchClinicModalOpen] = useState(false);
  const [finalExamModalOpen, setFinalExamModalOpen] = useState(false);

  const {
    data: inquiryList,
    isSuccess,
    isLoading,
  } = useGetSubmittedQueryListQuery();

  const [deleteSubmittedQuery, { isSuccess: isDeleteSuccess, isError: isDeleteError }] = useDeleteSubmittedQueryMutation();

  const editHandler = (modalState: boolean, row: any) => {
    setInfo(row);
    setShowEditForm(modalState);
  };

  const deleteHandler = (modalState: boolean, id: string) => {
    setModalShow(modalState);
    setInfoId(id);
  };

  const answerHandler = (modalState: boolean, row: any) => {
    setAnswerModalShow(modalState);
    setAnswer(row);
  }

  useEffect(() => {
    if (isDeleteSuccess) {
      toast.success('Inquiry Deleted Successfully!');
      setModalShow(false);
    }
    if (isDeleteError) {
      toast.error('Error Deleting Inquiry!');
    }
  }, [isDeleteSuccess, isDeleteError]);


  const columns: TableColumn<any>[] = [
    {
      name: 'Sl. No.',
      cell: (row, index) => index + 1,
      width: '80px',
    },
    {
      name: 'Inquiry Type',
      selector: (row) => row?.inquiry_type?.inquiry_type,
      width: '120px',
    },
    {
      name: 'Page No',
      selector: (row) => row?.page_no ?? 'N/A',
      width: '120px',
    },
    {
      name: 'Inquiry',
      selector: (row) => row?.query,
      wrap: true
    },
    {
      name: <div>Submission Status </div>,
      selector: (row) => row?.submission_status,
      width: '150px',
      cell: (row: any) => {
        switch (row?.submission_status) {
          case 'Submit':
            return (
              <div className="badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium">
                {'Submitted'}
              </div>
            );
          default:
            return (
              <div className="badge rounded-pill d-inline-block  badge-draft font-size-12 fw-medium">
                {row?.submission_status}
              </div>
            );
        }
      },
    },

    {
      name: <div>Progress Status</div>,
      width: '150px',
      selector: (row) => row?.progress_status,
      cell: (row: any) => {
        switch (row?.progress_status) {
          case 'Resolved':
            return (
              <div className="badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium">
                {row?.progress_status}
              </div>
            );
          case 'Appointment Created':
            return (
              <div className="badge rounded-pill d-inline-block  badge-applied font-size-12 fw-medium">
                {row?.progress_status}
              </div>
            );
          default:
            return (
              <div className="badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium">
                {row?.progress_status}
              </div>
            );
        }
      },
      wrap: true,
    },

    {
      name: 'Action',
      width: '250px',
      cell: (row) => (
        <>
          <div className="d-flex gap-1 my-1 flex-wrap">
            {row?.answer !== null &&
              <Button
                className="btn btn-sm btn-success"
                onClick={() => answerHandler(true, row)}
              >
                View Answer
              </Button>}

            <Button
              className="btn btn-sm btn-info"
              onClick={() => editHandler(true, row)}
              disabled={row?.submission_status !== 'Draft'}
            >
              Edit
            </Button>

            <ConfirmToast
              asModal={true}
              childrenClassName="margin-top-10"
              customCancel="No"
              customConfirm="Yes"
              customFunction={() =>
                deleteSubmittedQuery(row?.id!)
              }
              message={'Do You Want to Delete?'}
              position="top-right"
              showCloseIcon={true}
              theme="lilac"
            >
              <Button
                className="btn btn-sm btn-danger"
                // onClick={() => deleteHandler(true, row?.id!)}
                disabled={row?.progress_status === 'Resolved'}
              >
                Delete
              </Button>
            </ConfirmToast>
            {/* {modalShow && infoId === row?.id ? (
              <DeleteInformationModal
                isOpen={modalShow}
                toggle={() => setModalShow(!modalShow)}
                id={infoId}
                deleteInfomation={deleteSubmittedQuery}
              />
            ) : null} */}

            {showEditForm && info?.id === row?.id ? (
              <EditResearchClinicApplication
                isOpen={showEditForm}
                toggle={() => setShowEditForm(!showEditForm)}
                info={info}
              />
            ) : null}

            {answerModalShow && answer?.id === row?.id ? (
              <AnswerViewModal
                isOpen={answerModalShow}
                toggle={() => setAnswerModalShow(!answerModalShow)}
                info={answer}
              />
            ) : null}
          </div>
        </>
      ),
    },
  ];

  return (
    <div className="page-content">
      {isLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <div className='page-breadcrumb-wrapper'>
          <BetterBreadcrumb
            breadcrumbItem={[{ link: '/', name: 'Research Clinic' }]}
            title={'Research Clinic Application'}
          />
          <div>
            <Button color="primary" className="mx-2" onClick={() => setResearchClinicModalOpen(true)}>
              Research Application
            </Button>
            <Button color="secondary" onClick={() => setFinalExamModalOpen(true)}>
              Final Exam
            </Button>
          </div>
        </div>

        {/* {showAddForm ? ( */}
        {/*<ResearchClinicApplication />*/}
        {/* ) : (
          <EditInquiryType
            id={inquiryId}
            setShowAddForm={setShowAddForm}
          />
        )} */}
        <div className="row align-items-center justify-content-center">
          <div className="card">
            <div className="card-body">
              <DataTable
                columns={columns}
                data={inquiryList?.data?.data!}
                progressPending={isLoading}
                expandOnRowClicked
                highlightOnHover
                responsive
                persistTableHead={true}
              />
            </div>
          </div>
        </div>
        <FCPSFinalExam
          isOpen={finalExamModalOpen}
          toggle={() => setFinalExamModalOpen(!finalExamModalOpen)}
        />
      </Container>
      <ResearchClinicApplication
        isOpen={researchClinicModalOpen}
        toggle={() => setResearchClinicModalOpen(!researchClinicModalOpen)}
      />
    </div>
  );
};

export default ApplicationEntryList;
