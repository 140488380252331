import { useRef, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import toast from "react-hot-toast";
import { Label, Modal } from "reactstrap";
import { config } from "src/config";

const EditAttachments = (props: any) => {
  const { attachmentInputList, setInputList } = props;

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [attachmentFilePath, setAttachmentFilePath] = useState<string>('');
  const [attachmentFileIndex, setAttachmentFileIndex] = useState<number>(-1);
  const uploadedFileReference = useRef<any>();

  const handleInputChange = (event: any) => {
    if (event.target.files[0]) {
      setAttachmentFilePath(event.target.files[0]);
    }
  };

  const handleEditAttachment = (index: number) => {
    if (index !== -1) {
      setModalShow(true);
      setAttachmentFileIndex(index);
      setAttachmentFilePath(attachmentInputList[index]?.details);
    }
  };

  const handleAttachmentSubmit = (e: any, index: number) => {
    if (typeof attachmentFilePath !== 'string') {
      attachmentInputList[index].details = attachmentFilePath;
    }

    setModalShow(false);
  };

  console.log(attachmentInputList);

  const columns: TableColumn<any>[] = [
    {
      name: 'Sl. No.',
      width: '100px',
      cell: (row, index) => index + 1,
      center: true,
    },

    {
      name: 'File Preview',
      selector: (row) => row.details && (
        typeof row.details === 'string' ? (
          <a
            className="me-2 p-0 center"
            href={`${config?.APP_URL}${row.details}`}
            rel="noreferrer"
            target="_blank"
          >
            <span className="btn-outline-info btn-sm btn ms-2">View</span>
          </a>
        ) : (
          <a
            className="me-2 p-0 center"
            href={URL.createObjectURL(row.details)}
            rel="noreferrer"
            target="_blank"
          >
            <span className="btn-outline-info btn-sm btn ms-2">View</span>
          </a>
        )
      ),

      sortable: false,
      wrap: true,
      center: true,
    },

    {
      name: 'Action',
      width: '150px',
      cell: (row) => (
        <>
          <button
            type="button"
            className={'btn btn-secondary mb-2'}
            onClick={() => handleEditAttachment(attachmentInputList?.indexOf(row))}
          >
            <i className="fa fa-edit" />
          </button>
        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <>
      {attachmentInputList?.length > 0 &&
        <div className="row mb-5">
          <div className="col-sm-12">
            <DataTable
              columns={columns}
              data={attachmentInputList?.length > 0 ? attachmentInputList : []}
              expandOnRowClicked
              highlightOnHover
            />
          </div>
        </div>}

      <Modal
        onOpened={function () {
          toast.dismiss();
        }}
        isOpen={modalShow}
        scrollable={true}
        size="md"
        toggle={() => {
          document.body.classList.add('no_padding');
        }}
        backdrop={'static'}
        modalTransition={{ timeout: 0 }}
        backdropTransition={{ timeout: 0 }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Edit Attachment
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalShow(!modalShow);
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-sm-12">
              {typeof attachmentFilePath === 'string' ?
                <a
                  className="me-2 p-0 center"
                  href={`${config?.APP_URL}${attachmentFilePath}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <span className="btn-outline-info btn-sm btn mb-3">View</span>
                </a>
                :
                <a
                  className="me-2 p-0 center"
                  href={URL.createObjectURL(attachmentFilePath)}
                  rel="noreferrer"
                  target="_blank"
                >
                  <span className="btn-outline-info btn-sm btn mb-3">View</span>
                </a>
              }
              <br/>
              <Label className='form-label'>Attachment File</Label>
              <input
                type="file"
                ref={uploadedFileReference}
                className="form-control"
                name="attachmentFile"
                onChange={(event) => {
                  handleInputChange(event);
                }}
              />
            </div>
            <div className="col-sm-12">
              <a
                className="me-2 p-0 center"
                rel="noreferrer"
                target="_blank"
                onClick={(e) => {
                  handleAttachmentSubmit(e, attachmentFileIndex);
                }}
              >
                <span className="btn-success btn mt-3">Submit</span>
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditAttachments;
