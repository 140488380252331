import { apiSlice } from 'src/rtk/api/apiSlice';
import * as setupNoticeTemplateListUrl from 'src/helpers/url_helper';

export const setupNoticeTemplateListApi = apiSlice
    .enhanceEndpoints({ addTagTypes: ['NoticeTemplateLists'] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getNoticeTemplateLists: builder.query<APIResponse<NoticeTemplateList[]>, void>({
                query: () => ({
                    method: 'GET',
                    url: `${setupNoticeTemplateListUrl.GET_NOTICE_TEMPLATE_LISTS}`,
                }),
                providesTags: ['NoticeTemplateLists'],
            }),

            getNoticeTemplateList: builder.query<APIResponse<NoticeTemplateList>, string>({
                query: (id) => ({
                    url: `${setupNoticeTemplateListUrl.GET_NOTICE_TEMPLATE_LIST}/${id}`,
                    method: 'GET',
                }),
                providesTags: ['NoticeTemplateLists'],
            }),

            createNoticeTemplateList: builder.mutation<APIResponse<NoticeTemplateList>, NoticeTemplateList>({
                query: (data) => ({
                    url: `${setupNoticeTemplateListUrl.CREATE_NOTICE_TEMPLATE_LIST}`,
                    method: 'POST',
                    body: data,
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                }),
                invalidatesTags: ['NoticeTemplateLists'],
            }),

            updateNoticeTemplateList: builder.mutation<APIResponse<NoticeTemplateList>, string>({
                query: (url) => ({
                    url: `${setupNoticeTemplateListUrl.UPDATE_NOTICE_TEMPLATE_LIST}` + url,
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                }),
                invalidatesTags: ['NoticeTemplateLists'],
            }),

            deleteNoticeTemplateList: builder.mutation({
                query: (id: string) => ({
                    method: 'DELETE',
                    url: `${setupNoticeTemplateListUrl.DELETE_NOTICE_TEMPLATE_LIST}/${id}`,
                }),
                invalidatesTags: ['NoticeTemplateLists'],
            }),
        }),
    });

export const {
    useCreateNoticeTemplateListMutation,
    useGetNoticeTemplateListsQuery,
    useGetNoticeTemplateListQuery,
    useUpdateNoticeTemplateListMutation,
    useDeleteNoticeTemplateListMutation
} = setupNoticeTemplateListApi;
