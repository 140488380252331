import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalHeader, Row } from 'reactstrap';
import { InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import { useCreateInquiryTypeEntryMutation } from 'src/rtk/features/researchClinicSetup/researchClinicSetupApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

const initialValues = {
    inquiry_type: '',
    active_status: 'Active',
};

interface Props {
    isOpen: boolean;
    toggle: any;
}

const AddInquiryType = (props: Props) => {
    const { isOpen, toggle } = props;

    const [createInquiryTypeEntry, { isSuccess, isError, error, isLoading }] = useCreateInquiryTypeEntryMutation();

    const buttonRef = useRef<any>();
    const draftButtonRef = useRef<any>();

    // const [guideChecked, setGuideChecked] = useState(true);
    // const [coGuideChecked, setCoGuideChecked] = useState(false);

    const err: CustomError = error as CustomError;

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({
            inquiry_type: Yup.mixed().required('Please Enter Type'),
            // active_status: Yup.mixed().required('Please check'),
        }),

        onSubmit: (values: any) => {
            console.log(values);
            createInquiryTypeEntry(values);
        },
    });

    useEffect(() => {
        if (isSuccess) {
            resetForm();
            toast.success('Inquiry Type Stored Successfully!');
            toggle();
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);

    const handleStatusCheck = (e: any) => {
        if (e.target.checked) {
            setFieldValue('active_status', 'Active');
        }
        else {
            setFieldValue('active_status', 'Inactive');
        }
    }

    return (
        <React.Fragment>
            {isLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Modal isOpen={isOpen} toggle={toggle} size="lg" backdrop={'static'}>
                <ModalHeader toggle={toggle}>Add Inquiry</ModalHeader>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                    onReset={(e) => {
                        resetForm();
                    }}
                >
                    <Row className='p-3'>
                        <Col sm={10}>
                            <FormGroup>
                                <Label for="inquiry_type" className="form-label required-field">Inquiry Type</Label>
                                <InputField
                                    type="text"
                                    placeholder="Inquiry Type"
                                    {...getFieldProps('inquiry_type')}
                                    invalid={{ errors, touched }}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup className='mt-4'>
                                <Input
                                    className="form-check-input me-1"
                                    type="checkbox"
                                    name="active_status"
                                    id="active-status"
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleStatusCheck(e);
                                    }}
                                    checked={values.active_status === 'Active'}
                                />
                                <label className="form-check-label" htmlFor="active-status">
                                    Active
                                </label>
                                {/* {errors.active_status && touched.active_status ? (
                                            <div className="text-danger">{errors.active_status as string}</div>
                                        ) : null} */}
                            </FormGroup>
                        </Col>
                        <Col className='d-flex justify-content-end'>
                            <FormGroup>
                                <Button
                                    type="reset"
                                    className="btn btn-danger me-2"
                                >
                                    Reset
                                </Button>

                                <Button
                                    type="submit"
                                    className="btn btn-success"
                                >
                                    Add
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default AddInquiryType;
