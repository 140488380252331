import { useFormik } from 'formik';
import React from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';

const TrainingNoticeList = () => {
  const trainingNoticeSearchForm = useFormik({
    initialValues: {
      fromDate: Date.now().toString(),
      toDate: Date.now().toString(),
      searchTitle: '',
    },
    // validationSchema: Yup.object({
    //   name: Yup.string().required("Name is required"),
    //   fatherOrHusbandName: Yup.string().required(
    //     "Father/Husband name is required"
    //   ),
    //   motherName: Yup.string().required("Mother name is required"),
    //   dateOfBirth: Yup.string().required("Date of birth is required"),
    //   nid: Yup.string().required("NID is required"),
    //   phone: Yup.string(),
    // }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <h6>Notice</h6>

        <Col sm={12} md={12} lg={12}>
          <Form
            onSubmit={trainingNoticeSearchForm.handleSubmit}
            encType="multipart/form-data"
          >
            <Row>
              <Col className="border-right p-2 mr-2" sm={3} md={3} lg={3}></Col>

              <Col className="border-right p-2 mr-2" sm={3} md={3} lg={3}>
                <FormGroup className="mb-5">
                  <div className="mb-3">
                    <Label className="form-label">From</Label>
                    <Input
                      name="fromDate"
                      className="form-control"
                      placeholder=""
                      type="date"
                      onChange={trainingNoticeSearchForm.handleChange}
                      onBlur={trainingNoticeSearchForm.handleBlur}
                      value={trainingNoticeSearchForm.values.fromDate}
                    />
                  </div>
                </FormGroup>
              </Col>

              <Col className="border-right p-2 mr-2" sm={3} md={3} lg={3}>
                <FormGroup className="mb-5">
                  <div className="mb-3">
                    <Label className="form-label">To</Label>
                    <Input
                      name="toDate"
                      className="form-control"
                      placeholder=""
                      type="date"
                      onChange={trainingNoticeSearchForm.handleChange}
                      onBlur={trainingNoticeSearchForm.handleBlur}
                      value={trainingNoticeSearchForm.values.toDate}
                    />
                  </div>
                </FormGroup>
              </Col>

              <Col className="border-right p-2 mr-2" sm={3} md={3} lg={3}>
                <FormGroup className="mb-5">
                  <div className="mb-3">
                    <Label className="form-label">Search</Label>
                    <Input
                      name="searchTitle"
                      className="form-control"
                      placeholder=""
                      type="search"
                      onChange={trainingNoticeSearchForm.handleChange}
                      onBlur={trainingNoticeSearchForm.handleBlur}
                      value={trainingNoticeSearchForm.values.searchTitle}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>

        <Col sm={12} md={12} lg={12}>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <Table bordered>
                <thead>
                  <tr>
                    <th>SL. No</th>
                    <th>Workshops / Training Programs</th>
                    <th>Duration</th>
                    <th>Applicable For</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>WS 07</td>
                    <td>2 months</td>
                    <td>Some Application</td>
                    <td
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <Button className="me-4 btn-dark">
                        <i className="fa-solid fa-eye"></i>
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>02</td>
                    <td>WS 07</td>
                    <td>2 months</td>
                    <td>Some Application</td>
                    <td
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <Button className="me-4 btn-dark">
                        <i className="fa-solid fa-eye"></i>
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>03</td>
                    <td>WS 07</td>
                    <td>2 months</td>
                    <td>Some Application</td>
                    <td
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <Button className="me-4 btn-dark">
                        <i className="fa-solid fa-eye"></i>
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default TrainingNoticeList;
