import { useFormik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  Table,
} from "reactstrap";
import { InputField } from "src/components";

const ProcedureRecords = () => {
  const { values, errors, touched, handleChange } = useFormik({
    initialValues: {
      demo: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="px-md-4 px-sm-2">
            <h5 className="mb-3">Procedure Records</h5>
            <h6>
              Records of Invasive procedure/investigation done in the department
              (Last one year)
            </h6>
            <Row sm={12} md={12} lg={12}>
              <Col sm={12} md={12} lg={12}>
                <FormGroup className="col-sm-12 col-md-6" sm={12} md={6} lg={6}>
                  <div className="mb-3">
                    <h5 className="font-size-14 mb-3">Recovery Name</h5>
                    <div className="d-inline-block mx-2">
                      <div className="form-check form-check-left mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="hasDGHSLicense"
                          defaultChecked
                        />
                        <label className="form-check-label">Yes</label>
                      </div>
                    </div>
                    <div className="d-inline-block mx-2">
                      <div className="form-check form-check-left">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="hasDGHSLicense"
                        />
                        <label className="form-check-label">No</label>
                      </div>
                    </div>
                  </div>
                </FormGroup>
              </Col>
              <FormGroup className="col-sm-12 col-md-6">
                <Label>From</Label>
                <InputField
                  type="date"
                  name="totalCabins"
                  placeholder="Enter a number"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
              <FormGroup className="col-sm-12 col-md-6">
                <Label for="totalBed">To </Label>
                <InputField
                  type="date"
                  name="totalBeds"
                  placeholder="Total operating sessions per week"
                  value={undefined}
                  invalid={{
                    errors,
                    touched,
                  }}
                />
              </FormGroup>
              <FormGroup className="col-sm-12 col-md-6">
                <Label for="totalBed">
                  Name of invasive procedure done in the department
                </Label>
                <InputField
                  type="text"
                  name="totalPayingBeds"
                  placeholder="Major operations performed per session"
                  invalid={{ errors, touched }}
                  value={undefined}
                />
              </FormGroup>
              <FormGroup className="col-sm-12 col-md-6">
                <Label for="totalBed">Number of procedures</Label>
                <InputField
                  type="number"
                  name="totalPayingBeds"
                  placeholder="Minor operations performed per session"
                  invalid={{ errors, touched }}
                  value={undefined}
                />
              </FormGroup>
            </Row>
            {/* Table */}
            <Col>
              <div className="mb-3 d-flex flex-row-reverse">
                <Button className="float-right px-3" text={"+ Add"} />
              </div>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table  table-hover mb-0">
                      <thead>
                        <tr>
                          <th>
                            Name of invasive procedure done in the department
                          </th>
                          <th>Number of process</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Procedure 1</td>
                          <td>23</td>
                          <td>
                            <div className="btn btn-sm btn-primary d-inline mx-1">
                              Edit
                            </div>
                            <div className="btn btn-sm btn-danger d-inline">
                              Delete
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <h5 className="mb-3">
              Following parts are to be filled in by only Surgery and allied
              departments for which accreditation is required
            </h5>
            <Row>
              <FormGroup className="col-sm-6 col-md-6 mb-6">
                <Label for="totalBed">
                  Total Number of Operations performed by the department during
                  last year
                </Label>
                <InputField
                  type="number"
                  name="totalPayingBeds"
                  placeholder="Number of Operations"
                  invalid={{ errors, touched }}
                  value={undefined}
                />
              </FormGroup>
              <FormGroup className="col-sm-6 col-md-3">
                <Label>From</Label>
                <InputField
                  type="date"
                  name="totalCabins"
                  placeholder="Enter a number"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
              <FormGroup className="col-sm-6 col-md-3">
                <Label for="totalBed">To </Label>
                <InputField
                  type="date"
                  name="totalBeds"
                  placeholder="Total operating sessions per week"
                  value={undefined}
                  invalid={{
                    errors,
                    touched,
                  }}
                />
              </FormGroup>
              <hr />
              <FormGroup className="col-sm-12 col-md-6">
                <Label for="totalBed">Records of Operation </Label>
                <InputField
                  type="date"
                  name="totalBeds"
                  placeholder="Total operating sessions per week"
                  value={undefined}
                  invalid={{
                    errors,
                    touched,
                  }}
                />
              </FormGroup>
              <FormGroup className="col-sm-6 col-md-3">
                <Label>From</Label>
                <InputField
                  type="date"
                  name="totalCabins"
                  placeholder="Enter a number"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
              <FormGroup className="col-sm-6 col-md-3">
                <Label for="totalBed">To </Label>
                <InputField
                  type="date"
                  name="totalBeds"
                  placeholder="Total operating sessions per week"
                  value={undefined}
                  invalid={{
                    errors,
                    touched,
                  }}
                />
              </FormGroup>
              <FormGroup className="col-sm-12 col-md-6">
                <Label for="totalBed">OT Item </Label>
                <InputField
                  type="text"
                  name="totalBeds"
                  placeholder="OT Item"
                  value={undefined}
                  invalid={{
                    errors,
                    touched,
                  }}
                />
              </FormGroup>
              <FormGroup className="col-sm-6 col-md-3">
                <Label for="totalBed">Number of OT </Label>
                <InputField
                  type="number"
                  name="totalBeds"
                  placeholder="OT Item"
                  value={undefined}
                  invalid={{
                    errors,
                    touched,
                  }}
                />
              </FormGroup>
            </Row>
            {/* Table */}
            <Col>
              <div className="mb-3 d-flex flex-row-reverse">
                <Button className="float-right px-3" text={"+ Add"} />
              </div>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table  table-hover mb-0">
                      <thead>
                        <tr>
                          <th>Name of:</th>
                          <th>Number of Operations</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Operation name</td>
                          <td>23</td>
                          <td>
                            <div className="btn btn-sm btn-primary d-inline mx-1">
                              Edit
                            </div>
                            <div className="btn btn-sm btn-danger d-inline">
                              Delete
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <hr />
            <Row>
              <FormGroup className="col-sm-12 col-md-12">
                <Label>Records Name</Label>
                <InputField
                  type="text"
                  name="totalCabins"
                  placeholder="Enter a number"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
              <h6>Period</h6>
              <FormGroup className="col-sm-6 col-md-3">
                <Label>From</Label>
                <InputField
                  type="date"
                  name="totalCabins"
                  placeholder="Enter a number"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
              <FormGroup className="col-sm-6 col-md-3">
                <Label>To</Label>
                <InputField
                  type="date"
                  name="totalCabins"
                  placeholder="Enter a number"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
              <FormGroup className="col-sm-6 col-md-3">
                <Label>Work Item</Label>
                <InputField
                  type="text"
                  name="totalCabins"
                  placeholder="Work Item"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
              <FormGroup className="col-sm-6 col-md-3">
                <Label>Total No</Label>
                <InputField
                  type="number"
                  name="totalCabins"
                  placeholder="Enter a number"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
            </Row>
            {/* Table */}
            <Col>
              <div className="mb-3 d-flex flex-row-reverse">
                <Button className="float-right px-3" text={"+ Add"} />
              </div>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table  table-hover mb-0">
                      <thead>
                        <tr>
                          <th>Records</th>
                          <th>Total No</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Record Name</td>
                          <td>23</td>
                          <td>
                            <div className="btn btn-sm btn-primary d-inline mx-1">
                              Edit
                            </div>
                            <div className="btn btn-sm btn-danger d-inline">
                              Delete
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="text-center mb-3">
            <Link to="/institute/subject-registration-4">
              <button
                type="submit"
                className="btn btn-primary w-25"
                onSubmit={(e) => {
                  e.preventDefault;
                }}>
                Save and Next {">"}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProcedureRecords;
