import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Modal, ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import { DaynamicInputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { putUrlString, setFromErrors } from 'src/helpers';
import store from 'src/rtk/app/store';
import {
  useCreateByPostMutation,
  useGetDataQuery,
  useUpdateByPatchMutation,
} from 'src/rtk/features/common/crudApi';
import { useGetPersonalProfileQuery } from 'src/rtk/features/trainee/traineeApi';
import * as Yup from 'yup';
import ApprovalForGuideCoGuide from '../TitleList/ApprovalForGuideCoGuide';
import ChangeRequestModal from './ChangeRequestModal';
import formObj from './formObj';

const TitleEntry = (props: any) => {
  // const { id } = useParams<{ id: string }>();
  const { titleEditBtnStatus, titleForEdit, setTitleEditBtnStatus, setTitleForEdit } = props;
  const history = useHistory();
  const [btnClicked, setBtnClicked] = useState<string>('');
  const [id, setId] = useState<string>('');
  const [showForm, setShowForm] = useState<boolean>(true);
  const [viewData, setViewData] = useState<any>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAddForm, setShowAddForm] = useState<boolean>(false);
  const [activeEditForm, setActiveEditForm] = useState<boolean>(false);
  const [viewTitleInfo, setViewTitleInfo] = useState<any>('');
  const [changeRequestModalShow, setChangeRequestModalShow] = useState<boolean>(false);

  const { data: personalProfile, isSuccess: isDataSuccess } = useGetPersonalProfileQuery();
  const currentUser: any = store.getState().auth.user;

  let passingYear = parseInt(currentUser?.details?.fcps_part_one_passing_year!);

  let initialValues: any = {};
  let validationObj: any = {};
  let inputFields: any = [];
  let viewColumn: any = [];
  let formFilds = formObj?.formFields || {};
  Object.keys(formFilds)
    .sort(function (a: any, b: any) {
      return parseInt(a.positionInForm) - parseInt(b.positionInForm);
    })
    .map((key) => {
      let field = formFilds[key];

      if (field['viewCell']) {
        viewColumn.push({
          name: field['label'],
          viewCell: field['viewCell'],
        });
      }

      if (formFilds[key]['showInForm']) {
        inputFields.push(formFilds[key]);

        if (
          field['type'] === 'checkbox' &&
          typeof field['default'] === 'string'
        ) {
          field['default'] = [field['default'].trim()];
        } else if (
          field['type'] === 'radio' &&
          typeof field['default'] === 'object'
        ) {
          field['default'] = field['default'].join(' ').trim();
        } else if (field['type'] === 'date') {
          field['default'] = field?.options?.defaultDate || '';
        } else if (field['type'] === 'select' && field['name'] === 'category') {
          field['default'] = passingYear < 2020 ? 'Dissertation' : 'Thesis';
        }

        if (field['isRequired']) {
          validationObj[field['name']] = field['validation'];
        }
        if (field['disabled']) {
          field['disabled'] = true;
        }
        initialValues[field['name']] = field['default'];
      }
    });

  const [createByPost, { isSuccess, isLoading, isError, error }] =
    useCreateByPostMutation();
  const err: CustomError = error as CustomError;

  const [
    updateByPatch,
    {
      isSuccess: isUpdateSuccess,
      isLoading: isUpdateLoading,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateByPatchMutation();
  const updateErr: CustomError = updateError as CustomError;

  useEffect(() => {
    if (isUpdateSuccess) {
      toast.dismiss();
      toast.success(formObj.title + ' Updated successfully');
      setShowAddForm(false)
      setShowForm(false);
    }
    if (isUpdateError) {
      toast.dismiss();
      toast.error(updateErr?.message || 'Something went wrong');
      setFromErrors(updateErr.data, setErrors);
    }
  }, [isUpdateLoading, isUpdateSuccess, isUpdateError]);

  const {
    handleSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    values,
    setValues,
    setFieldValue,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({ ...validationObj }),
    onSubmit: (values) => {
      values.registration_no = currentUser.registration_no;
      values.submission_status = 'Submit';
      values.place_of_study = personalProfile?.data?.details?.current_session_and_supervisor_info?.supervisor?.institute_name;

      if (values.co_guide_id.length > 0) {
        values.co_guide_id.map((cguide: any, index: any) => {
          values['co_guide_id_' + (index + 1)] = cguide;
        });
      }

      if (btnClicked === 'submit') {
        values.submission_status = 'Submit';
      } else if (btnClicked === 'save_as_draft') {
        values.submission_status = 'Draft';
      }

      if (id) {
        if (formObj?.isUpdateByPatch) {
          const updateUrl = formObj.updateAPIUrl + putUrlString(id, values);
          updateByPatch(updateUrl);
        } else {
          let postData = {
            queryUrl: formObj['updateAPIUrl'],
            formData: values,
          };
          createByPost(postData);
        }
      } else {
        let postData = {
          queryUrl: formObj['createAPIUrl'],
          formData: values,
        };
        // console.log(postData);
        createByPost(postData);
      }
    },
  });

  useEffect(() => {
    if (isSuccess) {
      toast.dismiss();
      toast.success(formObj.title + ' Saved successfully');
      setShowAddForm(false);
      // history.push(formObj.listUrl);
      setShowForm(false);
    }

    if (isError) {
      toast.dismiss();
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isLoading, isSuccess, isError]);

  let apiUrl = formObj.newListAPIUrl;

  // if (apiUrl) {
  const {
    data: dataList,
    isLoading: dataListLoading,
    isSuccess: dataListLoadingSuccess,
    error: dataListLoadingError,
  } = useGetDataQuery(`${apiUrl}`);

  useEffect(() => {
    setViewData(dataList?.data?.title!);
  }, [dataList]);

  const [titleId, settitleId] = useState<string>('');

  useEffect(() => {
    if (titleId && activeEditForm) {
      let vData = viewData.find((item: any) => item?.id === titleId);
      setId(titleId);
      // setShowForm(false);
      console.log(vData);
      let coGuideId = [];
      let coGuideOptions = [];
      let guideOptions = [];

      if (vData['co_guide_id_1']) {
        coGuideId.push(vData['co_guide_id_1']);

        coGuideOptions.push({
          label: vData?.co_guide_1_name || '',
          value: vData?.co_guide_id_1 || '',
        });
      }

      if (vData['co_guide_id_2']) {
        coGuideId.push(vData['co_guide_id_2']);
        coGuideOptions.push({
          label: vData?.co_guide_2_name || '',
          value: vData?.co_guide_id_2 || '',
        });
      }
      if (vData['co_guide_id_3']) {
        coGuideId.push(vData['co_guide_id_3']);
        coGuideOptions.push({
          label: vData?.co_guide_3_name || '',
          value: vData?.co_guide_id_3 || '',
        });
      }
      if (vData['guide_id']) {
        guideOptions.push({
          label: vData?.guide?.personal_info?.full_name || '',
          value: vData?.guide_id || '',
        });
      }

      formObj.formFields.co_guide_id.data = coGuideOptions;
      formObj.formFields.guide_id.data = guideOptions;

      setValues({
        category: vData?.['category'] || '',
        co_guide_id: coGuideId,
        guide_id: vData?.['guide_id'] || '',
        place_of_study: vData?.['place_of_study'] || '',
        study_period: vData?.['study_period'] || '',
        study_period_unit: vData?.['study_period_unit'] || '',
        title_of_thesis: vData?.['title_of_thesis'] || '',
      });
    }
  }, [titleId, activeEditForm]);

  const modalShowHandler = (modalState: boolean, data: any) => {
    setChangeRequestModalShow(modalState);
    setViewTitleInfo(data);
  };

  useEffect(() => {
    if (titleEditBtnStatus && titleForEdit) {
      setActiveEditForm(true);
      setShowForm(true);
      setShowAddForm(true);
      settitleId(titleForEdit?.id);
      setViewData([{ ...titleForEdit }]);
    }
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <div className='page-breadcrumb-wrapper'>
            <div>
              {formObj.breadcrumbItemForForm && (
                <BetterBreadcrumb
                  title={formObj?.title}
                  breadcrumbItem={formObj.breadcrumbItemForForm}
                />
              )}
            </div>
            <div>
              <Button
                color='primary'
                onClick={() => {
                  setShowAddForm(!showAddForm);
                  setActiveEditForm(false);
                  formObj.formFields.guide_id.data = [];
                  formObj.formFields.co_guide_id.data = [];
                  resetForm();
                }}
              >Add Title</Button>
            </div>
          </div>
          <Row>
            <Col>
              <div className='card'>
                <div className='card-body'>
                  <div className='table-responsive'>
                    <table className='table'>
                      <thead>
                        <tr style={{ backgroundColor: '#f8faff' }}>
                          <th>Category</th>
                          <th>Title</th>
                          <th>Study Place </th>
                          <th>Study Period</th>
                          <th>Co-Guide 1 Approval Status</th>
                          <th>Co-Guide 2 Approval Status</th>
                          {/* <th>Co-Guide 3 Approval Status</th> */}
                          <th>Guide Approval Status</th>
                          {/* <th>Admin Approval Status</th> */}
                          <th>Submission Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {viewData?.map((item: any) => (
                          <tr key={item.id}>
                            <td>
                              {item?.category}
                            </td>
                            <td>
                              {item?.title_of_thesis}
                            </td>
                            <td className="text-capitalize">
                              {item?.place_of_study}
                            </td>
                            <td>
                              {item?.study_period + ' ' + item?.study_period_unit}
                            </td>
                            <td>
                              {
                                item?.co_guide1_approval_status === 'Approve' &&
                                (<div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>
                                  {'Accepted'}
                                </div>)
                              }
                              {
                                item?.co_guide1_approval_status === 'Reject' &&
                                (<div className='badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium'>
                                  {'Rejected'}
                                </div>)
                              }
                              {
                                item?.co_guide1_approval_status === 'Correction' &&
                                (<div className='badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium'>
                                  {item?.co_guide1_approval_status}
                                </div>)
                              }
                              {
                                item?.co_guide1_approval_status === null &&
                                (<div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>
                                  N/A
                                </div>)
                              }

                            </td>
                            <td>
                              {
                                item?.co_guide2_approval_status === 'Approve' &&
                                (<div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>
                                  {'Accepted'}
                                </div>)
                              }
                              {
                                item?.co_guide2_approval_status === 'Reject' &&
                                (<div className='badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium'>
                                  {'Rejected'}
                                </div>)
                              }
                              {
                                item?.co_guide2_approval_status === 'Correction' &&
                                (<div className='badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium'>
                                  {item?.co_guide2_approval_status}
                                </div>)
                              }
                              {
                                item?.co_guide2_approval_status === null &&
                                (<div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>
                                  N/A
                                </div>)
                              }

                            </td>
                            {/* <td>
                              {
                                item?.co_guide3_approval_status === 'Approve' &&
                                (<div className='badge badge-soft-success font-size-12'>
                                  {'Accepted'}
                                </div>)
                              }
                              {
                                item?.co_guide3_approval_status === 'Reject' &&
                                (<div className='badge badge-soft-danger font-size-12'>
                                  {'Rejected'}
                                </div>)
                              }
                              {
                                item?.co_guide3_approval_status === 'Correction' &&
                                (<div className='badge badge-soft-info font-size-12'>
                                  {item?.co_guide3_approval_status}
                                </div>)
                              }
                              {
                                item?.co_guide3_approval_status === null &&
                                (<div className='badge badge-soft-secondary font-size-12'>
                                  N/A
                                </div>)
                              }

                            </td> */}
                            <td>
                              {
                                item?.guide_approval_status === 'Approve' &&
                                (<div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>
                                  {'Accepted'}
                                </div>)
                              }
                              {
                                item?.guide_approval_status === 'Reject' &&
                                (<div className='badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium'>
                                  {'Rejected'}
                                </div>)
                              }
                              {
                                item?.guide_approval_status === 'Correction' &&
                                (<div className='badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium'>
                                  {item?.guide_approval_status}
                                </div>)
                              }
                              {
                                item?.guide_approval_status === null &&
                                (<div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>
                                  N/A
                                </div>)
                              }

                            </td>
                            {/* <td>
                              {
                                item?.admin_approval_status === 'Approve' &&
                                (<div className='badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium'>
                                  {'Accepted'}
                                </div>)
                              }
                              {
                                item?.admin_approval_status === 'Reject' &&
                                (<div className='badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium'>
                                  {'Rejected'}
                                </div>)
                              }
                              {
                                item?.admin_approval_status === 'Correction' &&
                                (<div className='badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium'>
                                  {item?.guide_approval_status}
                                </div>)
                              }
                              {
                                item?.admin_approval_status === null &&
                                (<div className='badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium'>
                                  N/A
                                </div>)
                              }
                            </td> */}
                            <td>
                              {((item?.guide_approval_status?.toLowerCase() === 'correction' && item?.submission_status?.toLowerCase() === 'submit') ||
                                (item?.co_guide1_approval_status?.toLowerCase() === 'correction' && item?.submission_status?.toLowerCase() === 'submit') ||
                                (item?.co_guide2_approval_status?.toLowerCase() === 'correction' && item?.submission_status?.toLowerCase() === 'submit') ||
                                (item?.co_guide3_approval_status?.toLowerCase() === 'correction' && item?.submission_status?.toLowerCase() === 'submit')
                              )
                                ? (
                                  <div className="badge rounded-pill d-inline-block badge-applied font-size-12 fw-medium">
                                    {'Resubmitted'}
                                  </div>
                                ) :
                                <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                  {item?.submission_status === 'Approve' ?
                                    <span className='text-success'>Approved</span>
                                    :
                                    item?.submission_status === 'Submit' ?
                                      <span className='text-warning'>Submitted</span>
                                      :
                                      item?.submission_status === 'Reject' ?
                                        <span className='text-danger'>Rejected</span>
                                        :
                                        <span className='text-info'>{item?.submission_status}</span>}
                                </div>
                              }
                            </td>

                            <td>
                              <Button
                                className='btn btn-sm btn-info me-1'
                                onClick={() => {
                                  setShowModal(true);
                                  setViewTitleInfo(item);
                                }}
                              >
                                <i className="fas fa-eye" /> View
                              </Button>

                              {(item?.['admin_approval_status']?.toLowerCase() === 'approve' || item?.guide_approval_status?.toLowerCase() === 'approve') &&
                                <Button
                                  className='btn btn-sm btn-success me-1'
                                  onClick={() => {
                                    modalShowHandler(true, item);
                                  }}
                                >
                                  <i className="fas fa-edit" /> Change Request
                                </Button>}

                              {formObj.addUrl &&
                                item?.['submission_status'] &&
                                item?.['submission_status']?.toLowerCase() ===
                                'draft' && (
                                  <Button
                                    type='button'
                                    className='btn btn-success btn-sm'
                                    onClick={(e) => {
                                      setActiveEditForm(true);
                                      setShowAddForm(true);
                                      settitleId(item?.id);
                                      // setShowForm(true);
                                    }}
                                    disabled={item?.['admin_approval_status'] === 'Reject'
                                      || item?.['co_guide1_approval_status'] === 'Reject'
                                      || item?.['co_guide2_approval_status'] === 'Reject'
                                      || item?.['co_guide3_approval_status'] === 'Reject'
                                    }
                                  >
                                    <i className="fas fa-edit" /> {formObj.editBtnTitle ? formObj.editBtnTitle : 'Edit'}
                                  </Button>
                                )}

                            </td>
                          </tr>
                        ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Modal
            onOpened={function () {
            }}
            isOpen={showModal}
            scrollable={false}
            size='lg'
            toggle={() => {
              document.body.classList.add('no_padding');
            }}
            backdrop={'static'}
            modalTransition={{ timeout: 0 }}
            backdropTransition={{ timeout: 0 }}
          >
            <div className='modal-header'>
              <h5 className='modal-title' id='staticBackdropLabel'>
                Details Info
              </h5>
              <button
                type='button'
                className='btn-close'
                onClick={() => {
                  setShowModal(false);
                }}
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              {/* <DynamicView viewColumn={viewColumn} viewData={viewData} /> */}
              <ApprovalForGuideCoGuide info={viewTitleInfo} />
            </div>
          </Modal>

          <Modal isOpen={showAddForm} size={'lg'} toggle={() => {
            setShowAddForm(!showAddForm);
            if(setTitleEditBtnStatus) {
              setTitleEditBtnStatus(false);
            }            
            if(setTitleForEdit) {
              setTitleForEdit({});
            }            
          }
          }>
            <ModalHeader toggle={() => {
              setShowAddForm(!showAddForm);
              if(setTitleEditBtnStatus) {
                setTitleEditBtnStatus(false);
              }              
              if(setTitleForEdit) {
                setTitleForEdit({});
              }              
            }
            }>Add Thesis/Dissertation Title</ModalHeader>
            <ModalBody>
              <Form
                className='custom-form'
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
                onReset={(e) => {
                  e.preventDefault();
                  resetForm();
                  formObj.formFields.guide_id.data = [];
                  formObj.formFields.co_guide_id.data = [];
                }}
              >
                <Row>
                  {inputFields.length > 0 &&
                    inputFields.map((field: any, key: any) => {
                      if (
                        !['hidden', 'submit'].includes(field['type'])
                      ) {
                        field['type'] = field['type'];

                        if (
                          field['dependField'] &&
                          field['dependValue'] &&
                          field['condition']
                        ) {
                          let isMatch = true;
                          switch (field['condition']) {
                            case '===':
                              isMatch =
                                values[field['dependField']] ===
                                field['dependValue'];
                              break;

                            case '!==':
                              isMatch =
                                values[field['dependField']] !==
                                field['dependValue'];
                              break;

                            case 'includes':
                              isMatch = field['dependValue'].includes(
                                values[field['dependField']],
                              );
                              break;

                            default:
                              break;
                          }
                          if (!isMatch) {
                            if (
                              values[field['name']] !== field['default']
                            ) {
                              setFieldValue(
                                field['name'],
                                field['default'],
                              );
                            }
                            return <></>;
                          }
                        }

                        return (
                          <Col
                            key={field['name'] + key}
                            md={field['colSize']}
                            sm={12}
                          >
                            <FormGroup className='mb-3'>
                              <>
                                {['modal'].includes(field['type']) && (
                                  <DaynamicInputField
                                    label={field['label']}
                                    name={field['name']}
                                    type={field['type']}
                                    placeholder={field['label']}
                                    options={field['data']}
                                    dataApi={field['dataApi']}
                                    value={
                                      values[field['name']] !==
                                        undefined
                                        ? values[field['name']]
                                        : field['default']
                                    }
                                    isMulti={field['isMulti']}
                                    required={field['isRequired']}
                                    invalid={{ errors, touched }}
                                    onChange={(
                                      data: any,
                                      isSelected: boolean = false,
                                    ) => {
                                      if (data === null) {
                                        setFieldValue(
                                          field['name'],
                                          '',
                                        );
                                        field['data'] = [];
                                      } else if (
                                        data &&
                                        typeof data === 'string'
                                      ) {
                                        let isSelected =
                                          values[
                                            field['name']
                                          ].includes(data);

                                        if (isSelected) {
                                          setFieldValue(
                                            field['name'],
                                            '',
                                          );
                                          field['data'] = [];
                                        } else {
                                          setFieldValue(
                                            field['name'],
                                            data,
                                          );
                                          field['data'] = [data];
                                        }
                                      } else if (
                                        data &&
                                        typeof data === 'object' &&
                                        data?.length === undefined
                                      ) {
                                        let isSelected =
                                          values[field['name']] ===
                                          data?.value;

                                        if (isSelected) {
                                          setFieldValue(
                                            field['name'],
                                            '',
                                          );
                                          field['data'] = [];
                                        } else {
                                          setFieldValue(
                                            field['name'],
                                            data?.value || '',
                                          );
                                          field['data'] = data;
                                        }
                                      } else if (
                                        data &&
                                        typeof data === 'object' &&
                                        data?.length > 0
                                      ) {
                                        if (!isSelected) {
                                          let filterData = field[
                                            'data'
                                          ].filter((item: any) => {
                                            return (
                                              item.value !==
                                              data?.[0].value
                                            );
                                          });

                                          setFieldValue(
                                            field['name'],
                                            filterData.map(
                                              (item: any) => item.value,
                                            ),
                                          );
                                          field['data'] = filterData;
                                        } else {
                                          setFieldValue(
                                            field['name'],
                                            data.map(
                                              (item: any) => item.value,
                                            ),
                                          );
                                          field['data'] = data;
                                        }
                                      }
                                    }}
                                  />
                                )}

                                {['select'].includes(field['type']) && (
                                  <DaynamicInputField
                                    label={field['label']}
                                    name={field['name']}
                                    type={field['type']}
                                    placeholder={field['label']}
                                    options={field['data']}
                                    dataApi={field['dataApi']}
                                    value={
                                      values[field['name']] !==
                                        undefined
                                        ? values[field['name']]
                                        : field['default']
                                    }
                                    disabled={field['disabled']}
                                    isMulti={field['isMulti']}
                                    required={field['isRequired']}
                                    invalid={{ errors, touched }}
                                    onChange={(data: any) => {
                                      if (data === null) {
                                        setFieldValue(
                                          field['name'],
                                          '',
                                        );
                                      } else if (
                                        data &&
                                        data?.length === undefined &&
                                        data.value !== undefined
                                      ) {
                                        setFieldValue(
                                          field['name'],
                                          data.value,
                                        );
                                      } else if (
                                        data &&
                                        data?.length !== undefined
                                      ) {
                                        setFieldValue(
                                          field['name'],
                                          data.map(
                                            (item: any) => item.value,
                                          ),
                                        );
                                      }
                                    }}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    styles={{
                                      menuPortal: (provided: any, state: any) => ({
                                        ...provided,
                                        zIndex: '9999',
                                      }),
                                    }}
                                  />
                                )}

                                {['date'].includes(field['type']) && (
                                  <>
                                    <DaynamicInputField
                                      label={field['label']}
                                      name={field['name']}
                                      type={field['type']}
                                      placeholder={field['label']}
                                      options={field['options']}
                                      invalid={{ errors, touched }}
                                      value={
                                        values[field['name']] !==
                                          undefined
                                          ? values[field['name']]
                                          : field?.options?.defaultDate
                                      }
                                      required={field['isRequired']}
                                      setData={(data: any) => {
                                        if (
                                          field?.options?.mode ===
                                          'range'
                                        ) {
                                          setFieldValue(
                                            field['name'],
                                            data[0],
                                          );
                                          setFieldValue(
                                            field['name2'],
                                            data[1],
                                          );
                                        } else {
                                          setFieldValue(
                                            field['name'],
                                            data.join(',').trim(),
                                          );
                                        }
                                      }}
                                    />
                                  </>
                                )}

                                {['radio', 'checkbox'].includes(
                                  field['type'],
                                ) && (
                                    <DaynamicInputField
                                      label={field['label']}
                                      name={field['name']}
                                      type={field['type']}
                                      placeholder={field['label']}
                                      options={field['data']}
                                      dataApi={field['dataApi']}
                                      value={
                                        values[field['name']] !==
                                          undefined
                                          ? values[field['name']]
                                          : field['default']
                                      }
                                      isMulti={field['isMulti']}
                                      required={field['isRequired']}
                                      invalid={{ errors, touched }}
                                      onChange={(data: any) => {
                                        let selectedValue =
                                          data.target.value.toString();
                                        if (
                                          field['type'] === 'checkbox'
                                        ) {
                                          let isChecked =
                                            data.target.checked;
                                          let oldValue =
                                            values[field['name']];

                                          let getIndex =
                                            oldValue.indexOf(
                                              selectedValue,
                                            );
                                          if (
                                            isChecked &&
                                            getIndex === -1
                                          ) {
                                            oldValue.push(selectedValue);
                                          } else if (
                                            !isChecked &&
                                            getIndex > -1
                                          ) {
                                            oldValue.splice(getIndex, 1);
                                          }

                                          setFieldValue(
                                            field['name'],
                                            oldValue,
                                          );
                                        } else {
                                          setFieldValue(
                                            field['name'],
                                            selectedValue,
                                          );
                                        }
                                      }}
                                    />
                                  )}

                                {![
                                  'modal',
                                  'select',
                                  'radio',
                                  'checkbox',
                                  'date',
                                ].includes(field['type']) && field['name'] !== 'place_of_study' && (
                                    <DaynamicInputField
                                      label={field['label']}
                                      type={field['type']}
                                      placeholder={field['label']}
                                      required={field['isRequired']}
                                      invalid={{ errors, touched }}
                                      min={field['min']}
                                      {...getFieldProps(field['name'])}
                                    />
                                  )}

                                {![
                                  'modal',
                                  'select',
                                  'radio',
                                  'checkbox',
                                  'date',
                                ].includes(field['type']) && field['name'] === 'place_of_study' && (
                                    <DaynamicInputField
                                      label={field['label']}
                                      name={field['name']}
                                      value={personalProfile?.data?.details?.current_session_and_supervisor_info?.supervisor?.institute_name}
                                      type={field['type']}
                                      placeholder={field['label']}
                                      required={field['isRequired']}
                                      invalid={{ errors, touched }}
                                      readOnly={true}
                                    />
                                  )}
                              </>
                            </FormGroup>
                          </Col>
                        );
                      }
                    })}
                </Row>
                <Row>
                  <Col className='text-center' sm={12}>

                    <Button
                      type='submit'
                      className='btn btn-success mx-2 mt-3'
                      onClick={(e) => {
                        setBtnClicked('submit');
                      }}
                      disabled={isLoading}
                    >
                      {id === ''
                        ? formObj.submitBtnTitle
                          ? formObj.submitBtnTitle
                          : 'Submit'
                        : formObj.updateBtnTitle
                          ? formObj.updateBtnTitle
                          : 'Update'}
                    </Button>

                    {formObj.draftBtnTitle && (
                      <Button
                        type='submit'
                        className='btn btn-info mx-2 mt-3'
                        disabled={isLoading}
                        onClick={(e) => {
                          setBtnClicked('save_as_draft');
                        }}
                      >
                        {formObj.draftBtnTitle}
                      </Button>
                    )}

                    {formObj.resetBtnTitle && (
                      <Button
                        type='reset'
                        className='btn btn-danger mx-2 mt-3'
                        disabled={isLoading}
                      >
                        {formObj.resetBtnTitle}
                      </Button>
                    )}

                  </Col>
                </Row>
                {formObj.debug && (
                  <Row>
                    <Col sm={12}>{JSON.stringify(values)}</Col>
                  </Row>
                )}

              </Form>
            </ModalBody>
          </Modal>
          <Modal isOpen={changeRequestModalShow} size={'lg'} toggle={() => setChangeRequestModalShow(!changeRequestModalShow)}>
            <ModalHeader toggle={() => setChangeRequestModalShow(!changeRequestModalShow)}>Change Information</ModalHeader>
            <ModalBody>
              <ChangeRequestModal changeInfo={viewTitleInfo} />
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TitleEntry;
