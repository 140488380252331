import { apiSlice } from 'src/rtk/api/apiSlice';
import * as lookupItemUrl from 'src/helpers/url_helper';

export const lookupItemApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['LookupItem'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLookupItem: builder.query<APIResponse<LookupDetails>, string>({
        query: (uuid: string) => ({
          method: 'GET',
          url: `${lookupItemUrl.GET_LOOKUP_ITEM}/${uuid}`,
        }),
        providesTags: ['LookupItem'],
      }),

      createLookupItem: builder.mutation<
        APIResponse<LookupDetails>,
        LookupDetails
      >({
        query: (data) => ({
          url: `${lookupItemUrl.CREATE_LOOKUP_ITEM}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['LookupItem'],
      }),

      updateLookupItem: builder.mutation<APIResponse<LookupDetails>, string>({
        query: (url) => ({
          url: `${lookupItemUrl.UPDATE_LOOKUP_ITEM}/${url}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['LookupItem'],
      }),

      deleteLookupItem: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${lookupItemUrl.DELETE_LOOKUP_ITEM}/${id}`,
        }),
        invalidatesTags: ['LookupItem'],
      }),
    }),
  });

export const {
  useGetLookupItemQuery,
  //   useGetLookupItemQuery,
  useCreateLookupItemMutation,
  useUpdateLookupItemMutation,
  useDeleteLookupItemMutation,
} = lookupItemApi;
