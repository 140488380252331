import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import InfoViewModal from './ViewCertificateModal';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoGlobalApi';
import { useGetCertificateListByPaginateQuery } from 'src/rtk/features/certificate/publishCertificateApi';

interface SearchFields {
  from_date?: string;
  to_date?: string;
  department_id?: string;
  notice_type?: string;
  notice_no?: string;
  notice_subject?: string;
}

const initialValues: SearchFields = {
  from_date: '',
  to_date: '',
  department_id: '',
  notice_type: '',
  notice_no: '',
  notice_subject: '',
};

const CertificateList = (props: any) => {
  const { selectedCertificate, setSelectedCertificate, setModalShow } = props;
  // document.title = 'Notice Entry | BCPS';
  // const { id: courseId } = useParams<{ id: string }>();

  const [page, setPage] = useState<string | number>('1');
  const [paginate_per_page, set_paginate_per_page] = useState(15);
  const [search, setSearch] = useState('');
  const [viewModalShow, setViewModalShow] = useState<boolean>(true);
  const [certificateToView, setCertificateToView] = useState<CertificateList>(
    {} as CertificateList,
  );
  const [reRenderState, setRerenderState] = useState<number>(0);

  const {
    data: certificateListData,
    error: certificateListDataError,
    isLoading: isCertificateListDataLoading,
    isError: isCertificateListDataError,
    isSuccess: isCertificateListDataSuccess,
  } = useGetCertificateListByPaginateQuery({
    page,
    paginate_per_page,
    search,
  });

  const { data: subjectList, isError: isSubFetchError } =
    useGetSubjectInfosQuery();

  const departmentOptions: SelectOption[] = (
    subjectList?.data || []
  ).map((item) => ({
    value: item.subject_name ?? '',
    label: item.subject_name ?? '',
  }));

  departmentOptions.push({ value: 'All', label: 'All' });

  const certificateTypeOptions: SelectOption[] =
    useGetLookupDataQuery('notice_type')
      .data?.data[0]?.childs.map((item) => ({
        value: item.name,
        label: item.name,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) || [];

  const allCertificates = certificateListData?.data;  

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    set_paginate_per_page(newPerPage);
    setPage(page);
  };

  const handleViewTemplate = (certificate: CertificateList) => {
    setCertificateToView(certificate);
    setViewModalShow(true);
    if (reRenderState === 0) {
      setRerenderState(1);
    } else {
      setRerenderState(0);
    }
  };

  const columns: TableColumn<any>[] = [    

    {
      name: <div className='text-center'>Certificate No.</div>,
      selector: (row) => row.notice_no,
      sortable: false,
      wrap: true,
      center: true,
    },

    // {
    //   name: 'Notice Title',
    //   selector: (row) => row.notice_subject,
    //   sortable: false,
    //   wrap: true,
    //   center: true,
    // },

    {
      name: <div className='text-center'>Certificate Type</div>,
      selector: (row) => row.notice_type,
      sortable: false,
      wrap: true,
      center: true,
    },

    {
      name: <div className='text-center'>Subject</div>,
      selector: (row) =>
        row.department_id ?? '-',
      sortable: false,
      wrap: true,
      center: true,
    },

    {
      name: <div className='text-center'>Action</div>,
      width: '150px',
      cell: (row) => (
        <>
          {/* btn-group-example */}
          <FormGroup className="btn-group mt-2">
            <Button
              size="sm"
              color="info"
              type="button"
              text={`<span class="fas fa-eye" ></span> View`}
              onClick={() => {
                handleViewTemplate(row);
              }}
            />
            <Button
              size="sm"
              color="success"
              type="button"
              text={`<span class="fas fa-square-check" ></span> Select`}
              onClick={() => {
                setSelectedCertificate(row);
                setModalShow(false);
              }}
            />
          </FormGroup>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const {
    handleSubmit,
    getFieldProps,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.from_date !== '') {
        s = s + `&from_date=${values.from_date}`;
      }

      if (values.to_date !== '') {
        s = s + `&to_date=${values.to_date}`;
      }

      if (values.department_id !== '') {
        s = s + `&department_id=${values.department_id}`;
      }

      if (values.notice_type !== '') {
        s = s + `&notice_type=${values.notice_type}`;
      }

      if (values.notice_no !== '') {
        s = s + `&notice_no=${values.notice_no}`;
      }

      if (values.notice_subject !== '') {
        s = s + `&notice_subject=${values.notice_subject}`;
      }

      setSearch(s);
      setPage('1');
      set_paginate_per_page(15);
    },
  });

  useEffect(() => {
    if (isCertificateListDataLoading || !isCertificateListDataSuccess) {
      toast.dismiss();
      // toast.loading('Data Loading...');
    } else {
      toast.dismiss();
      // toast.success('Data Load Successfully');
    }
  }, [isCertificateListDataLoading, isCertificateListDataSuccess]);

  return (
    <Container fluid>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <Row>
            <Col>
              <div className="gap-2 w-100">
                <Form
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="filter-wrapper">
                    <FormGroup>
                      <Label for="from_date">From Date</Label>
                      <InputField
                        type="date"
                        invalid={{ errors, touched }}
                        {...getFieldProps('from_date')}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="to_date">To Date</Label>
                      <InputField
                        type="date"
                        invalid={{ errors, touched }}
                        {...getFieldProps('to_date')}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="department_id">Subject</Label>
                      <InputField
                        type="select"
                        options={departmentOptions}
                        invalid={{ errors, touched }}
                        {...getFieldProps('department_id')}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="notice_type">Notice Type</Label>
                      <InputField
                        type="select"
                        options={certificateTypeOptions}
                        invalid={{ errors, touched }}
                        {...getFieldProps('notice_type')}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="notice_no">Notice No.</Label>
                      <InputField
                        type="text"
                        invalid={{ errors, touched }}
                        {...getFieldProps('notice_no')}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="notice_subject">Notice Title</Label>
                      <InputField
                        type="text"
                        invalid={{ errors, touched }}
                        {...getFieldProps('notice_subject')}
                      />
                    </FormGroup>

                    <FormGroup className="btn-group btn-group-example mb-3">
                      <Button
                        color="danger"
                        type="reset"
                        text="Reset"
                        onClick={() => {
                          resetForm();
                          handleSubmit();
                        }}
                      />
                      <Button
                        type="button"
                        text="Search"
                        onClick={() => {
                          handleSubmit();
                        }}
                      />
                    </FormGroup>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm={5} className="me-5">
              <DataTable
                columns={columns}
                data={allCertificates ? allCertificates : []}
                pagination
                paginationServer
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                progressPending={isCertificateListDataLoading}
                paginationTotalRows={certificateListData?.total}
                expandOnRowClicked
                highlightOnHover
                paginationRowsPerPageOptions={[2, 5, 10, 15, 20, 50, 100, 200]}
              />
            </Col>
            {viewModalShow ? (
              <Col sm={6} className="ps-5 border-start">
                <InfoViewModal key={reRenderState} data={certificateToView} />
              </Col>
            ) : null}
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default CertificateList;
