import { ExamEligibilityCriterias } from "src/pages/Setup/ExamEligibilityCriteria";
import { AddExamEligibilityCriteria } from "src/pages/Setup/ExamEligibilityCriteria/AddExamEligibilityCriteria";
import { EditExamEligibilityCriteria } from "src/pages/Setup/ExamEligibilityCriteria/EditExamEligibilityCriteria";


export const examEligibilityCriteriaSetupRoutes = [
  // examEligibilityCriteria setup
  {
    path: '/setup/xm-eligibility-criteria/',
    component: ExamEligibilityCriterias,
  },
  {
    path: '/setup/xm-eligibility-criteria/update/:id',
    component: EditExamEligibilityCriteria,
  },
  {
    path: '/setup/xm-eligibility-criteria/add',
    component: AddExamEligibilityCriteria,
  },
];
