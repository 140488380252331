import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useAppSelector } from 'src/rtk/app/hook';
import { useLazyGetSessionWiseMiniCexListQuery } from 'src/rtk/features/miniCexCBD/miniCexAndCBDApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import loader from '../../assets/images/loader.gif';
import { DopsPDF } from './DopsPDF';
import DOPSPrintModal from './DOPSPrintModal';

const DOPSlist = () => {
  const [modalShow, setModalShow] = useState(false);
  const [printId, setPrintId] = useState<string>('');
  const authUser = useAppSelector((state) => state.auth.user);

  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');
  const [selectedSubject, setSelectedSubject] = useState<any>('');

  const generateQueryUrl = () => {
    return `page=${page}&paginate_per_page=${limit}${search}`;
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setPage(page);
  };

  const [getSessionWiseMiniCexList, { data: list, isFetching, isLoading }] = useLazyGetSessionWiseMiniCexListQuery();

  useEffect(() => {
    const url = {
      registrationNo: null,
      supervisorId: authUser?.id,
      urls: `page=${page}&paginate_per_page=${limit}${search}`,
    }
    getSessionWiseMiniCexList(url);
  }, [page, limit, search]);

  const data: any = list?.data?.data || [];

  interface SearchFields {
    search?: string;
    bmdc_registration_no?: string;
    subject?: string;
  }

  const searchInitialValues: SearchFields = {
    search: '',
    bmdc_registration_no: '',
    subject: '',
  };

  const { data: subjects } = useGetSubjectInfosQuery();

  const allSubjects: SelectOption[] =
    subjects?.data?.map((subject) => ({
      value: subject.subject_name,
      label: subject.subject_name,
    })) || [];

  const {
    handleSubmit: searchSubmit,
    getFieldProps: searchGetFieldProps,
    values: searchValues,
    touched,
    errors,
    setFieldValue,
    resetForm: searchResetForm,
    // setErrors,
    // isSubmitting,
  } = useFormik({
    initialValues: searchInitialValues,

    onSubmit: (searchValues) => {
      let s: string = '';

      if (searchValues.search !== '') {
        s = s + `&search=${searchValues.search}`;
      }

      if (searchValues.bmdc_registration_no !== '') {
        s = s + `&bmdc_registration_no=${searchValues.bmdc_registration_no}`;
      }

      if (searchValues.subject !== '') {
        s = s + `&subject=${searchValues.subject}`;
      }

      setSearch(s);
      setPage('1');
      // setLimit(20);
    },
  });


  useEffect(() => {
    if (selectedSubject !== '') {
      setFieldValue('subject', selectedSubject?.value);
    }
  }, [selectedSubject]);

  const columns: TableColumn<any>[] = [
    {
      name: '#',
      width: '50px',
      cell: (row, index) => <strong>{((page - 1) * limit + index) + 1}</strong>,
    },

    {
      name: <div>Full Name</div>,
      selector: (row) => row?.personal_info?.full_name,
      wrap: true,
    },

    {
      name: <div>BMDC Reg. No.</div>,
      selector: (row) => row?.bmdc_registration_no,
      wrap: true,
    },
    {
      name: <div>Reg. Type</div>,
      selector: (row) => row?.registration_type,
      wrap: true,
    },
    {
      name: <div>Subject Name</div>,
      selector: (row) => row?.subject?.subject_name,
      wrap: true,
    },

    {
      name: <div>Action</div>,
      cell: (row, index) => (
        <>
          <div className='mt-2 mb-2'>
            {(authUser.type !== 'Trainee' && authUser.type !== 'Operation') &&
              <Link
                to={{ pathname: `/assesment-dops-by-supervisor/${row?.registration_no}/${row?.current_session_and_supervisor_info?.supervisor_id}/${row?.current_session_and_supervisor_info?.id}` }}
                className="btn btn-success btn-sm waves-effect waves-light me-1"
              >
                <i className="fa fa-plus" /> Form Entry
              </Link>}
            <button
              className="btn btn-sm btn-info"
              onClick={() => {
                setPrintId(row?.registration_no);
                setModalShow(true);
              }}
            >
              <i className="fa-solid fa-print" /> Print
            </button>
          </div>
          {/* {printId === row?.registration_no ? (
            <DopsPDF
              registrationNo={row?.registration_no}
              supervisorId={row?.current_session_and_supervisor_info?.supervisor_id}
              sessionId={row?.current_session_and_supervisor_info?.id}
              printId={printId}
              setPrintId={setPrintId}
            />
          ) : null} */}
          {modalShow && printId === row?.registration_no ? (
            <DOPSPrintModal
              isOpen={modalShow}
              toggle={() => setModalShow(!modalShow)}
              registrationNo={row?.registration_no}
              supervisorId={row?.current_session_and_supervisor_info?.supervisor_id}
            />
          ) : null}
        </>
      ),
    },
  ];

  document.title = 'DOPS | BCPS';

  return (
    <div className="page-content">
      {isLoading || isFetching ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb
          title="DOPS"
          breadcrumbItem={[
            { link: '#', name: 'Assessment' },
          ]}
        />
        <Card>
          <CardBody>
            <Row>
              <Col>
                <div className="gap-2">
                  <Form
                    className="custom-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      searchSubmit();
                    }}
                  >
                    <div className="filter-wrapper">
                      <FormGroup>
                        <Label for="bmdc_registration_no">BMDC Reg. No.</Label>
                        <InputField
                          type="text"
                          placeholder="BMDC Reg. No."
                          className="form-control"
                          {...searchGetFieldProps('bmdc_registration_no')}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="subject">Subject</Label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          value={selectedSubject}
                          onChange={setSelectedSubject}
                          options={allSubjects}
                          placeholder='Select...'
                          name="subject"
                        />
                      </FormGroup>

                      <FormGroup className="btn-column mb-3">
                        <Button
                          color="danger"
                          type="reset"
                          text="Reset"
                          onClick={() => {
                            setSelectedSubject('');
                            searchResetForm();
                            searchSubmit();
                          }}
                        />
                        <Button
                          type="button"
                          text="Search"
                          onClick={() => {
                            searchSubmit();
                          }}
                        />
                      </FormGroup>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
            <DataTable
              columns={columns}
              data={data ? data : []}
              pagination
              paginationServer
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              progressPending={isLoading}
              paginationTotalRows={data?.data?.total}
              expandOnRowClicked
              highlightOnHover
              paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
              responsive
              persistTableHead={true}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default DOPSlist;
