import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import { useGetLogbookEntrySetupDetailsQuery } from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import { useDeleteLogbookEntrySetupDetailsValueMutation } from 'src/rtk/features/setup/logBookEntrySetupDetailsValue/logBookEntrySetupDetailsValueApi';

interface Props {
  logbookEntrySetupDetailsValue: LogbookEntrySetupDetailsValue;
  index: number;
}

const LogbookEntrySetupDetailsValueTableRow = ({ logbookEntrySetupDetailsValue, index }: Props) => {
  const history = useHistory();
  const [deleteLogbookEntrySetupDetailsValue, { isSuccess, isLoading, isError }] =
    useDeleteLogbookEntrySetupDetailsValueMutation();

  const { data: logbookEntrySetupDetailsData } = useGetLogbookEntrySetupDetailsQuery();
  const logbookEntrySetupDetails = logbookEntrySetupDetailsData?.data || [];

  const deleteHandler = (id: string) => {
    const confirm = window.confirm('Are you sure you want to delete?');
    if (confirm) deleteLogbookEntrySetupDetailsValue(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Logbook entry setup details value deleted successfully');
    }
    if (isError) {
      toast.error('Error deleting logbook entry setup details value');
    }
  }, [isSuccess, isError]);

  return (
    <tr key={logbookEntrySetupDetailsValue.id}>
      <th scope="row">{index + 1}</th>      
      <td>
        {
          logbookEntrySetupDetails?.find((l: LogbookEntryDetails) => l.id === logbookEntrySetupDetailsValue.logbook_entry_setup_detail_id)
            ?.column_name
        }
      </td>
      <td>{logbookEntrySetupDetailsValue.value_list}</td>
      <td>{logbookEntrySetupDetailsValue.required_ind ? 'Yes' : 'No'}</td>     
      <td>{logbookEntrySetupDetailsValue.is_show ? 'Yes' : 'No'}</td>
      <td>{logbookEntrySetupDetailsValue.sl_no}</td>
      <td>{logbookEntrySetupDetailsValue.default_ind ? 'Yes' : 'No'}</td>
      <td>{logbookEntrySetupDetailsValue.status ? 'Active' : 'Inactive'}</td>       
      <td>
        <div className="d-flex gap-2">
          <Button
            size="sm"
            text="Edit"
            color="warning"
            onClick={() =>
              history.push(`/setup/logbook-entry-setup-details-value/update/${logbookEntrySetupDetailsValue.id}`)
            }
          />

          <Button
            size="sm"
            text="Delete"
            color="danger"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => deleteHandler(logbookEntrySetupDetailsValue.id!)}
          />
        </div>
      </td>
    </tr>
  );
};

export default LogbookEntrySetupDetailsValueTableRow;
