import { useEffect, useState } from "react";
import { Input, Label } from "reactstrap";

export const EditCompetencyGroupLevels = (props: any) => {
    const { formik, competencyGroup, setCompetencyGroup, competency, checkedCompetencyIds, competencyGroupDetail, searchTermCheck, competenceLevelOptions } = props;

    const [levelOne, setLevelOne] = useState<boolean>(false);
    const [levelTwo, setLevelTwo] = useState<boolean>(false);
    const [levelThree, setLevelThree] = useState<boolean>(false);
    const [levelFour, setLevelFour] = useState<boolean>(false);
    // const [competencyGroup, setCompetencyGroup] = useState<any>(competency_group);

    let levelLabels: string[] = [];

    if(competenceLevelOptions?.length && competency?.competence_level_type === 'Patient Care Competence') {
        levelLabels = competenceLevelOptions[0]?.childs?.map((option: any, index: number) => option.name);
    } else if(competenceLevelOptions?.length && competency?.competence_level_type === 'Procedural/Surgical skill') {
        levelLabels = competenceLevelOptions[1]?.childs?.map((option: any, index: number) => option.name);
    } else {
        levelLabels = ['Level 1', 'Level 2', 'Level 3', 'Level 4'];
    }

    const handleCompetencyLevel = (e: any, value: number) => {
        if (e.target.checked) {
            const newCompetencyGroup: any = [...competencyGroup];

            // console.log(newCompetencyGroup);

            newCompetencyGroup?.forEach((c: any) => {
                if (c.id === competency.id) {
                    c['level'].push(value);

                    if (value === 1) {
                        setLevelOne(true);
                    } else if (value === 2) {
                        setLevelTwo(true);
                    } else if (value === 3) {
                        setLevelThree(true);
                    } else if (value === 4) {
                        setLevelFour(true);
                    }
                }
            });

            // console.log(newCompetencyGroup);

            setCompetencyGroup(newCompetencyGroup);
        } else {
            const newCompetencyGroup: any = [...competencyGroup];

            // console.log(newCompetencyGroup);

            newCompetencyGroup?.forEach((c: any) => {
                if (c.id === competency.id) {
                    const index = c['level'].indexOf(value);
                    c['level'].splice(index, 1);

                    if (value === 1) {
                        setLevelOne(false);
                    } else if (value === 2) {
                        setLevelTwo(false);
                    } else if (value === 3) {
                        setLevelThree(false);
                    } else if (value === 4) {
                        setLevelFour(false);
                    }
                }
            });

            // console.log(newCompetencyGroup);

            setCompetencyGroup(newCompetencyGroup);
        }
    };

    useEffect(() => {

        if (!searchTermCheck) {
            // console.log(competencyGroup);

            const newCompetencyGroup: any = [...competencyGroup];

            // console.log(newCompetencyGroup);

            newCompetencyGroup?.forEach((c: any) => {
                if (c.id === competency.id) {
                    // c['level'] = [];

                    if (competencyGroupDetail?.level1) {
                        c['level'].push(1);
                        setLevelOne(true);
                    }

                    if (competencyGroupDetail?.level2) {
                        c['level'].push(2);
                        setLevelTwo(true);
                    }

                    if (competencyGroupDetail?.level3) {
                        c['level'].push(3);
                        setLevelThree(true);
                    }

                    if (competencyGroupDetail?.level4) {
                        c['level'].push(4);
                        setLevelFour(true);
                    }
                }
            });

            // console.log(newCompetencyGroup);

            setCompetencyGroup(newCompetencyGroup);
        } else {
            // console.log(competencyGroup);            

            // console.log(newCompetencyGroup);

            competencyGroup?.forEach((c: any) => {
                if (c.id === competency.id) {
                    // c['level'] = [];

                    if (c['level'].includes(1)) {                        
                        setLevelOne(true);
                    }

                    if (c['level'].includes(2)) {                        
                        setLevelTwo(true);
                    }

                    if (c['level'].includes(3)) {                        
                        setLevelThree(true);
                    }

                    if (c['level'].includes(4)) {                        
                        setLevelFour(true);
                    }
                }
            });

            // console.log(newCompetencyGroup);            
        }

    }, []);

    useEffect(() => {
        formik.values['competency_group'] = competencyGroup;
    }, [competencyGroup]);

    return (
        checkedCompetencyIds?.indexOf(competency.id) !== -1 ? (
            <>
                {'['}
                <Label check className="ms-3 me-3">
                    <Input type='checkbox'
                        name='levelOne'
                        id='levelOne'
                        checked={levelOne}
                        onChange={(e) => {
                            handleCompetencyLevel(e, 1);
                        }} />{' '}
                    {`${levelLabels[0]}`}
                </Label>
                <Label check className="me-3">
                    <Input type='checkbox'
                        name='levelTwo'
                        id='levelTwo'
                        checked={levelTwo}
                        onChange={(e) => {
                            handleCompetencyLevel(e, 2);
                        }} />{' '}
                    {`${levelLabels[1]}`}
                </Label>
                <Label check className="me-3">
                    <Input type='checkbox'
                        name='levelThree'
                        id='levelThree'
                        checked={levelThree}
                        onChange={(e) => {
                            handleCompetencyLevel(e, 3);
                        }} />{' '}
                    {`${levelLabels[2]}`}
                </Label>
                <Label check className="me-3">
                    <Input type='checkbox'
                        name='levelFour'
                        id='levelFour'
                        checked={levelFour}
                        onChange={(e) => {
                            handleCompetencyLevel(e, 4);
                        }} />{' '}
                    {`${levelLabels[3]}`}
                </Label>
                {']'}
            </>
        ) : null
    );
};