import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import InputField from "../Input";

class CheckBoxComponent extends React.Component<{ field: any, form: any }> {
    render() {
        let {field, form: {}} = this.props;

        return (
            <>
                <FormGroup check className="mb-3 mt-4">
                      <Label check>
                        <Input {...field} type="checkbox" />{' '}
                        Required
                      </Label>
                </FormGroup>
            </>
        );
    }
}

export default CheckBoxComponent;