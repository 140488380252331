import { AddThesisEntrySetupForm } from "src/pages/Setup/ThesisEntrySetupForm/AddThesisEntrySetupForm";
import { EditThesisEntrySetupForm } from "src/pages/Setup/ThesisEntrySetupForm/EditThesisEntrySetupForm";
import ThesisEntrySetupFormList from "src/pages/Setup/ThesisEntrySetupForm/ThesisEntrySetupFormList";


export const thesisEntrySetupRoutes = [
  // thesis entry setup
  {
    path: '/setup/thesis-dissertation',
    component: ThesisEntrySetupFormList,
  },
  {
    path: '/setup/thesis-dissertation/update/:id',
    component: EditThesisEntrySetupForm,
  },
  {
    path: '/setup/thesis-dissertation/add',
    component: AddThesisEntrySetupForm,
  },
];
