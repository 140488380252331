import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { Container, Form, Label, FormGroup, Input } from 'reactstrap';
import { InputField, Button } from 'src/components';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { setFromErrors } from 'src/helpers';
import { useHistory } from 'react-router-dom';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import DynamicFormSetup from 'src/components/DynamicForm/Setup/DynamicFormSetup';
import { useCreateLogbookEntrySetupDetailsRecordMutation } from 'src/rtk/features/setup/logBookEntrySetupDetailsRecord/logBookEntrySetupDetailsRecordApi';
import { useGetLogbookEntrySetupDetailsQuery } from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';

const initialValues: LogbookEntrySetupDetailsRecord = {
    id: '',
    logbook_entry_setup_detail_id: '',
    details_text: '',
    details_json: '',
    status: true,
    creator_organization_id: '',
    updater_organization_id: '',
    created_by: '',
    updated_by: '',
    deleted_by: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
};

export const AddLogbookEntrySetupDetailsRecord = () => {
  const [createLogbookEntrySetupDetailsRecord, { isSuccess, isError, error }] = useCreateLogbookEntrySetupDetailsRecordMutation();

  const { data: logbookEntrySetupDetailsData } = useGetLogbookEntrySetupDetailsQuery();
  const logbookEntrySetupDetails = logbookEntrySetupDetailsData?.data || [];

  const [ showDetailsJsonForm, setShowDetailsJsonForm ] = useState<boolean>(false);

  const history = useHistory();

  const err: CustomError = error as CustomError;

  const formik =
    useFormik({
      initialValues,
      validationSchema: Yup.object({
        logbook_entry_setup_detail_id: Yup.string().required('Please select the logbook entry setup detail'),        
      }),

      onSubmit: (values) => {
        createLogbookEntrySetupDetailsRecord({
          ...values,
        });

        console.log(values);
      },
    });

  const { handleSubmit, getFieldProps, values, touched, errors, resetForm, setErrors } = formik;

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Logbook entry setup details record created successfully');
      history.push('/setup/logbook-entry-setup-details-record/');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);
  
  let logbookEntrySetupDetailsOptions: SelectOption[] = [    
    ...logbookEntrySetupDetails?.map((l: LogbookEntryDetails) => ({
      label: l.details_text!,
      value: l.id!,
    })),
  ];  

  const handleSubmitButtonClick = (e: any) => {
    setShowDetailsJsonForm(true);
  };

  return !showDetailsJsonForm ? (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Add Logbook Setup Details Record'}
          breadcrumbItem={[{ link: '/setup/logbook-entry-setup-details-record/', name: 'Logbook Setup Details Record' }]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="card mt-3">
            <h5 className="card-header">
              <i className="fa fa-plus me-2" />
              Add Logbook Setup Details Record
            </h5>
            <div className="card-body">
              <Form
                className="custom-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="row">                  
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="logbook_entry_setup_detail_id">Logbook Entry Setup Detail</Label>
                      <InputField
                        type="select"
                        placeholder="Logbook Entry Setup Detail"
                        options={logbookEntrySetupDetailsOptions}
                        invalid={{ errors, touched }}
                        {...getFieldProps('logbook_entry_setup_detail_id')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="details_text">Details Text</Label>
                      <InputField
                        type="textarea"
                        placeholder="Details Text"
                        invalid={{ errors, touched }}
                        {...getFieldProps('details_text')}
                      />
                    </FormGroup>
                  </div>

                  {/* <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="description">Description</Label>
                      <InputField
                        type="textarea"
                        placeholder="Description"
                        invalid={{ errors, touched }}
                        {...getFieldProps('description')}
                      />
                    </FormGroup>
                  </div> */}                 

                  {/* <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="setup_logbook_essential_info_id">Logbook Essential Info Name</Label>
                      <InputField
                        type="select"
                        placeholder="Logbook Essential Info"
                        options={logbookEssentialInfoOptions}
                        invalid={{ errors, touched }}
                        {...getFieldProps('setup_logbook_essential_info_id')}
                      />
                    </FormGroup>
                  </div> */}

                  {/* <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="sl_no">Sl No</Label>
                      <InputField
                        type="number"
                        placeholder="Sl No"
                        invalid={{ errors, touched }}
                        {...getFieldProps('sl_no')}
                      />
                    </FormGroup>
                  </div>                   */}

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup check className="mb-3 mt-4">
                      <Label check>
                        <Input type="checkbox" checked={values.status} {...getFieldProps('status')} />{' '}
                        Status
                      </Label>
                    </FormGroup>
                  </div>
                </div>
                <Button type="button" text="Submit" onClick={(e) => handleSubmitButtonClick(e)} />
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  ) : (
    <DynamicFormSetup formikProp={formik} fieldProp={'details_json'} />
  );
};
