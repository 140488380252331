import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { useLazyGetStatusUpdateQuery } from 'src/rtk/features/trainee/traineeApi';
import loader from '../../assets/images/loader.gif';

interface Props {
  isOpen: boolean;
  toggle: any;
}

const TraineeRegModal = (props: Props) => {
  const { isOpen, toggle } = props;
  const [submitButtonStatus, setSubmitButtonStatus] = useState<boolean>(true);
  const history = useHistory();

  const [getStatusUpdate, { data, isSuccess, isError, isLoading, error }] = useLazyGetStatusUpdateQuery();

  const handleChange = (e: any) => {
    if (e.target.checked) {
      setSubmitButtonStatus(false);
    } else {
      setSubmitButtonStatus(true);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Submitted for admin review');
      toggle();
      history.push('/dashboard');
    }
    if (isError) {
      toast.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="md">
      {isLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <ModalHeader toggle={toggle}>Profile Submission</ModalHeader>

      <ModalBody>
        <div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Label>
                <h3>DECLARATION</h3>

                <p className='justified-text'>
                  The information given by me in the profile is entirely correct. I understand that for wrong information punitive action may be taken by BCPS which may amount to cancellation of Registration and debarring from appearing in examination of the BCPS for such periods as specified by the BCPS.
                </p>
              </Label>
              <FormGroup check>
                <Input
                  type="checkbox"
                  style={{ width: '20px', height: '20px', border: '2px solid black' }}
                  role="check"
                  id='review'
                  onChange={
                    (e) => {
                      handleChange(e);
                    }
                  }
                />
                <Label check htmlFor='review'>
                  <p className='fw-semibold'>I Agree</p>
                </Label>
              </FormGroup>
            </div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <div className="d-flex gap-2 ">
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={(e) => {
            getStatusUpdate();
          }}

            disabled={submitButtonStatus}
          >
            Submit
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default TraineeRegModal;
