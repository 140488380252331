import { apiSlice } from 'src/rtk/api/apiSlice';
import * as roleUrl from 'src/helpers/url_helper';

export const roleApi = apiSlice
    .enhanceEndpoints({ addTagTypes: ['Roles'] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getRoles: builder.query<APIResponse<Role[]>, void>({
                query: () => ({
                    method: 'GET',
                    url: `${roleUrl.GET_ROLES}`,
                }),
                providesTags: ['Roles'],
            }),
            createRole: builder.mutation({
                query: (data) => ({
                    url: `${roleUrl.CREATE_ROLE}`,
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: ['Roles'],
            }),
            updateRole: builder.mutation({
                query: (data) => ({
                    method: 'PATCH',
                    url: `${roleUrl.UPDATE_ROLE}/${data.name}`,
                    body: {
                        new_role_name: data.new_role_name,
                        permissions: data.permissions,
                    },
                }),
                invalidatesTags: ['Roles'],
            }),

            deleteRole: builder.mutation({
                query: (id: number) => ({
                    method: 'DELETE',
                    url: `${roleUrl.DELETE_ROLE}/${id}`,
                }),
                invalidatesTags: ['Roles'],
            }),
        }),
    });

export const {
    useCreateRoleMutation,
    useGetRolesQuery,
    useUpdateRoleMutation,
    useDeleteRoleMutation,
} = roleApi;
