import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import { useCreateInquiryEntryByTraineeMutation, useGetInquiryListForTraineeQuery } from 'src/rtk/features/researchClinicSetup/researchClinicSetupApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

const initialValues = {
    inquiry_type_id: '',
    page_no: '',
    query: '',
    submission_status: '',
    query_id: '',
};

interface Props {
    isOpen: boolean;
    toggle: any;
    info: any;
}

const EditResearchClinicApplication = (props: Props) => {
    const { isOpen, toggle, info } = props;

    const [createInquiryEntryByTrainee, { isSuccess, isError, error, isLoading: isCreateLoading }] = useCreateInquiryEntryByTraineeMutation();
    const { data: inquiryList, isSuccess: isListSuccess, isLoading: isListLoading } = useGetInquiryListForTraineeQuery();

    const allInquiry: SelectOption[] = inquiryList?.data?.map((item: any) => ({
        value: item?.id,
        label: item?.inquiry_type
    })) || [];
    console.log(info);
    const [btnClicked, setBtnClicked] = useState<string>('');

    const buttonRef = useRef<any>();
    const draftButtonRef = useRef<any>();

    const err: CustomError = error as CustomError;

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setValues,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({
            inquiry_type_id: Yup.mixed().required('Please Select Type'),
            query: Yup.mixed().required('Enter Your Query'),
        }),

        onSubmit: (values: any) => {
            const formData = new FormData();

            Object.keys(values).forEach((key) => {
                if (key === 'submission_status') {
                    if (btnClicked === 'submit') {
                        values[key] = 'Submit';
                    } else if (btnClicked === 'save_as_draft') {
                        values[key] = 'Draft';
                    }
                }
                formData.append(key, values[key]);
            });

            createInquiryEntryByTrainee(formData);
            console.log(values);
        },
    });

    useEffect(() => {
        if (info) {
            setValues({
                inquiry_type_id: info?.inquiry_type_id || '',
                page_no: info?.page_no || '',
                query: info?.query || '',
                submission_status: info?.submission_status || '',
                query_id: info?.id || '',
            })
        }

    }, [info]);

    useEffect(() => {
        if (isSuccess) {
            toast.success('Inquiry Updated Successfully!');
            toggle();
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);


    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg" backdrop={'static'}>
            {isCreateLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <ModalHeader toggle={toggle}>Edit Information</ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                    onReset={(e) => {
                        resetForm();
                    }}
                >
                    <Row>
                        <Col lg={6}>
                            <FormGroup>
                                <Label for="inquiry_type_id" className="form-label required-field">Inquiry Type</Label>
                                <InputField
                                    type="select"
                                    placeholder="Paid or Free"
                                    options={allInquiry}
                                    {...getFieldProps('inquiry_type_id')}
                                    invalid={{ errors, touched }}
                                />
                            </FormGroup>
                        </Col>

                        <Col lg={6}>
                            <FormGroup>
                                <Label for="page_no" className="form-label">Page</Label>
                                <InputField
                                    type="number"
                                    min={'1'}
                                    placeholder="Enter Page No"
                                    {...getFieldProps('page_no')}
                                    invalid={{ errors, touched }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={12}>
                            <FormGroup>
                                <Label for="query" className="form-label required-field">Inquiry on Research Protocol/Thesis</Label>
                                <InputField
                                    type="textarea"
                                    placeholder="Inquiry on Research Protocol/Thesis"
                                    {...getFieldProps('query')}
                                    invalid={{ errors, touched }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <FormGroup className='mt-4 text-center'>
                            <Button
                                type="reset"
                                className="btn btn-danger me-2"
                            >
                                Reset
                            </Button>
                            <button
                                type="submit"
                                className="btn btn-info me-2"
                                // ref={draftButtonRef}
                                onClick={(e) => {
                                    setBtnClicked('save_as_draft');
                                }}
                            >
                                Save as Draft
                            </button>
                            <button
                                type="submit"
                                // ref={buttonRef}
                                className="btn btn-success"
                                onClick={(e) => {
                                    setBtnClicked('submit');
                                }}
                            >
                                Submit
                            </button>
                        </FormGroup>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default EditResearchClinicApplication;
