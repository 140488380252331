import { Container } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import formObj from './formObj';

import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { ConfirmToast } from 'react-confirm-toast';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';
import {
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Modal,
  Row,
} from 'reactstrap';
import {
  Button,
  DaynamicSearchInputField,
  DynamicView,
  InputField,
} from 'src/components';
import { config } from 'src/config';
import { putUrlString } from 'src/helpers';
import {
  useDeleteByIdMutation,
  useGetDataByMutation,
  useGetDataQuery
} from 'src/rtk/features/common/crudApi';
import * as Yup from 'yup';
const InstituteApproval = () => {
  if (formObj?.title) {
    document.title = formObj?.title + ' | BCPS';
  }

  const history = useHistory();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(true);
  const [viewData, setViewData] = useState<any>({});
  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');
  const generateQueryUrl = () => {
    return `${formObj.listAPIUrl}?page=${page}&paginate_per_page=${limit}${search}`;
  };

  const {
    data: dataList,
    isLoading: dataListLoading,
    isSuccess: dataListLoadingSuccess,
    error: dataListLoadingError,
  } = useGetDataQuery(generateQueryUrl());
  const data: any = dataList ? dataList : [];

  useEffect(() => {
    if (dataListLoadingError) {
      toast.dismiss();
      toast.error('Data Loading error. Please try again later.');
    } else if (dataListLoading || !dataListLoadingSuccess) {
      toast.dismiss();
      // toast.loading('Data Loading...');
    } else {
      toast.dismiss();
      // toast.success('Data Load Successfully');
    }
  }, [dataListLoading, dataListLoadingSuccess, dataListLoadingError]);

  const [deleteByID, { isSuccess, isLoading, isError, error }] =
    useDeleteByIdMutation();
  const err: CustomError = error as CustomError;

  useEffect(() => {
    if (isSuccess) {
      toast.success(formObj.title + ' delete successfully');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
    }
  }, [isSuccess, isError]);

  let initialSearchValues: any = {};
  let inputSearchFields: any = [];
  let tableColumn: any = [];
  let viewColumn: any = [];
  let searchFormFilds = formObj?.searchFormFields || {};

  Object.keys(searchFormFilds)
    .sort(function (a: any, b: any) {
      return (
        parseInt(searchFormFilds[a].positionInForm) -
        parseInt(searchFormFilds[b].positionInForm)
      );
    })
    .map((key) => {
      let searchFormField = searchFormFilds[key];

      if (searchFormField['viewCell']) {
        viewColumn.push({
          name: searchFormField['label'],
          viewCell: searchFormField['viewCell'],
        });
      }

      if (searchFormField['cell']) {
        tableColumn.push({
          name: searchFormField['label'],
          cell: searchFormField['cell'],
        });
      }

      if (formObj?.showSearchForm && searchFormFilds[key]['showInSearch']) {
        inputSearchFields.push(searchFormFilds[key]);

        if (
          searchFormField['type'] === 'checkbox' &&
          typeof searchFormField['default'] === 'string'
        ) {
          searchFormField['default'] = [searchFormField['default'].trim()];
        } else if (
          searchFormField['type'] === 'radio' &&
          typeof searchFormField['default'] === 'object'
        ) {
          searchFormField['default'] = searchFormField['default']
            .join(' ')
            .trim();
        } else if (searchFormField['type'] === 'date') {
          searchFormField['default'] =
            searchFormField?.options?.defaultDate || '';
        }

        initialSearchValues[searchFormField['name']] = '';
      }
    });

  let columns: TableColumn<any>[] = [
    {
      name: '#',
      width: '50px',
      cell: (row, index: any) => (data.from ? data.from + index : index + 1),
    },
    ...tableColumn,
  ];

  if (formObj?.showTableActionColumn) {
    columns.push({
      name: 'Action',
      width: '100px',
      selector: (row: any) => row.id,
      cell: (row: any, index: any) => (
        <>
          {formObj?.showViewBtn && (
            <Button
              size="sm"
              className="rounded-0"
              text={`<span class="fas fa-eye" ></span> ${formObj.viewBtnTitle}`}
              color="primary"
              onClick={() => {
                setShowModal(true);
                // setViewData(row);
                setViewData(row);
              }}
            />
          )}

          {formObj?.showUpdateBtn && (
            <Button
              size="sm"
              className="rounded-0"
              text={`<span class="fas fa-edit" ></span> ${formObj.updateBtnTitle}`}
              color="info"
              onClick={() => history.push(`${formObj.updateUrl}${row.id}`)}
            />
          )}

          {formObj?.showDeleteBtn && (
            <ConfirmToast
              asModal={true}
              childrenClassName="margin-top-10"
              customCancel="No"
              customConfirm="Yes"
              customFunction={() =>
                deleteByID(`${formObj.deleteAPIUrl}${row.id}`)
              }
              message={formObj.deletePromtMessage}
              position="top-right" //will be ignored cause asModal=true
              showCloseIcon={true}
              theme="lilac"
            >
              <Button
                size="sm"
                className="rounded-0"
                text={`<span class="fas fa-trash" ></span> ${formObj.deletBtnTitle}`}
                color="danger"
              />
            </ConfirmToast>
          )}
        </>
      ),
    });
  }

  const {
    handleSubmit: handleSearchSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    values,
    setValues,
    setFieldValue,
    touched,
    errors,
    resetForm: resetSearchForm,
    setErrors,
  } = useFormik({
    initialValues: initialSearchValues,
    onSubmit: (searchValues) => {
      let s: string = '';

      Object.keys(searchValues).map((i: any) => {
        if (searchValues[i] === '') {
          delete searchValues[i];
        }
      });

      if (searchValues?.name) {
        s = s + `&search=${searchValues.name}`;
        delete searchValues.name;
      }

      if (Object.keys(searchValues).length > 0) {
        s = s + `&` + putUrlString('', searchValues).replaceAll('?', '');
      }

      setSearch(s);
      setPage('1');
      // setLimit(20);
    },
  });

  /******* Approval Form Start ****** */

  const [
    updateApprovalStatus,
    {
      isSuccess: isApproveSuccess,
      isLoading: isApproveLoading,
      isError: isApproveError,
      error: approveError
    },
  ] = useGetDataByMutation();
  const approvErr: CustomError = approveError as CustomError;

  useEffect(() => {
    if (isApproveSuccess) {
      toast.dismiss();
      setShowModal(false);
      toast.success('Approval save successfully');
    }
    if (isApproveError) {
      toast.dismiss();
      if (approvErr?.data?.message) {
        toast.error(approvErr?.data?.message || 'Something went wrong');
      } else {
        toast.error(approvErr?.message || 'Something went wrong');
      }
    }
  }, [isApproveSuccess, isApproveError]);

  const initialValues: any = {
    id: '',
    status: '',
    message: '',
  };
  const {
    values: approvalValues,
    handleSubmit: approvalHandleSubmit,
    setFieldValue: approvalSetFieldValue,
    getFieldProps: approvalGetFieldProps,
    touched: approvalTouched,
    errors: approvalErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      status: Yup.string().required('Status is required'),
      message: Yup.string().when('status', (status, schema) => {
        if (status && status !== 'Approve') {
          return schema.required(status + ' note is Required!');
        }
        return schema;
      }),
    }),
    onSubmit: (approvalValues) => {
      toast.loading('Approval Submited...');
      approvalValues['id'] = viewData['id']
      updateApprovalStatus(`${formObj.approvalAPIUrl}/${approvalValues.id}/${approvalValues.status}?note=${approvalValues['message']}`);
    },
  });

  /******* Approval Form End ****** */

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {formObj.breadcrumbItemForList && (
            <BetterBreadcrumb
              title={formObj?.title}
              breadcrumbItem={formObj.breadcrumbItemForList}
            />
          )}

          <Card>
            <CardHeader className=" ">
              <Row className="d-flex">
                {formObj?.showSearchForm && inputSearchFields.length > 0 && (
                  <>
                    <Col className="d-flex d-md-block  d-sm-none justify-content-start align-items-center">
                      <Form
                        className="custom-form w-100"
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSearchSubmit();
                        }}
                      >
                        <div className="row">
                          {inputSearchFields.length > 0 &&
                            inputSearchFields.map(
                              (searchField: any, key: any) => {
                                if (
                                  !['hidden', 'submit'].includes(
                                    searchField['type'],
                                  )
                                ) {
                                  searchField['isRequired'] = false;
                                  searchField['default'] = '';

                                  if (
                                    ['radio', 'checkbox'].includes(
                                      searchField['type'],
                                    )
                                  ) {
                                    searchField['type'] = 'select';
                                  }

                                  if (
                                    searchField['dependField'] &&
                                    searchField['dependValue'] &&
                                    searchField['condition']
                                  ) {
                                    let isMatch = true;
                                    switch (searchField['condition']) {
                                      case '===':
                                        isMatch =
                                          values[searchField['dependField']] ===
                                          searchField['dependValue'];
                                        break;

                                      case '!==':
                                        isMatch =
                                          values[searchField['dependField']] !==
                                          searchField['dependValue'];
                                        break;

                                      case 'includes':
                                        isMatch = searchField[
                                          'dependValue'
                                        ].includes(
                                          values[searchField['dependField']],
                                        );
                                        break;

                                      default:
                                        break;
                                    }
                                    if (!isMatch) {
                                      if (
                                        values[searchField['name']] !==
                                        searchField['default']
                                      ) {
                                        setFieldValue(
                                          searchField['name'],
                                          searchField['default'],
                                        );
                                      }
                                      return <></>;
                                    }
                                  }

                                  return (
                                    <Col
                                      md={'6'}
                                      lg={'6'}
                                      xl={'4'}
                                      xxl={''}
                                      key={Math.random()}
                                    >
                                      <FormGroup
                                        // key={searchField['name'] + key}
                                        className="mb-3"
                                      >
                                        <>
                                          {['select'].includes(
                                            searchField['type'],
                                          ) && (
                                              <DaynamicSearchInputField
                                                label={searchField['label']}
                                                name={searchField['name']}
                                                type={searchField['type']}
                                                placeholder={'Select'}
                                                options={searchField['data']}
                                                dataApi={searchField['dataApi']}
                                                value={
                                                  values[searchField['name']] !==
                                                    undefined
                                                    ? values[searchField['name']]
                                                    : searchField['default']
                                                }
                                                isMulti={searchField['isMulti']}
                                                required={
                                                  searchField['isRequired']
                                                }
                                                invalid={{ errors, touched }}
                                                onChange={(data: any) => {
                                                  if (data === null) {
                                                    setFieldValue(
                                                      searchField['name'],
                                                      '',
                                                    );
                                                  } else if (
                                                    data &&
                                                    data?.length === undefined &&
                                                    data.value !== undefined
                                                  ) {
                                                    setFieldValue(
                                                      searchField['name'],
                                                      data.value,
                                                    );
                                                  } else if (
                                                    data &&
                                                    data?.length !== undefined
                                                  ) {
                                                    setFieldValue(
                                                      searchField['name'],
                                                      data.map(
                                                        (item: any) => item.value,
                                                      ),
                                                    );
                                                  }
                                                }}
                                              />
                                            )}

                                          {['date'].includes(
                                            searchField['type'],
                                          ) && (
                                              <>
                                                <DaynamicSearchInputField
                                                  label={searchField['label']}
                                                  name={searchField['name']}
                                                  type={searchField['type']}
                                                  placeholder={''}
                                                  options={searchField['options']}
                                                  invalid={{ errors, touched }}
                                                  value={
                                                    values[
                                                      searchField['name']
                                                    ] !== undefined
                                                      ? values[
                                                      searchField['name']
                                                      ]
                                                      : searchField?.options
                                                        ?.defaultDate
                                                  }
                                                  required={
                                                    searchField['isRequired']
                                                  }
                                                  setData={(data: any) => {
                                                    if (
                                                      searchField?.options
                                                        ?.mode === 'range'
                                                    ) {
                                                      setFieldValue(
                                                        searchField['name'],
                                                        data[0],
                                                      );
                                                      setFieldValue(
                                                        searchField['name2'],
                                                        data[1],
                                                      );
                                                    } else {
                                                      setFieldValue(
                                                        searchField['name'],
                                                        data.join(',').trim(),
                                                      );
                                                    }
                                                  }}
                                                />
                                              </>
                                            )}

                                          {['radio', 'checkbox'].includes(
                                            searchField['type'],
                                          ) && (
                                              <DaynamicSearchInputField
                                                label={searchField['label']}
                                                name={searchField['name']}
                                                type={searchField['type']}
                                                placeholder={''}
                                                options={searchField['data']}
                                                dataApi={searchField['dataApi']}
                                                value={
                                                  values[searchField['name']] !==
                                                    undefined
                                                    ? values[searchField['name']]
                                                    : searchField['default']
                                                }
                                                isMulti={searchField['isMulti']}
                                                required={
                                                  searchField['isRequired']
                                                }
                                                invalid={{ errors, touched }}
                                                onChange={(data: any) => {
                                                  let selectedValue =
                                                    data.target.value.toString();
                                                  if (
                                                    searchField['type'] ===
                                                    'checkbox'
                                                  ) {
                                                    let isChecked =
                                                      data.target.checked;
                                                    let oldValue =
                                                      values[searchField['name']];

                                                    let getIndex =
                                                      oldValue.indexOf(
                                                        selectedValue,
                                                      );
                                                    if (
                                                      isChecked &&
                                                      getIndex === -1
                                                    ) {
                                                      oldValue.push(
                                                        selectedValue,
                                                      );
                                                    } else if (
                                                      !isChecked &&
                                                      getIndex > -1
                                                    ) {
                                                      oldValue.splice(
                                                        getIndex,
                                                        1,
                                                      );
                                                    }

                                                    setFieldValue(
                                                      searchField['name'],
                                                      oldValue,
                                                    );
                                                  } else {
                                                    setFieldValue(
                                                      searchField['name'],
                                                      selectedValue,
                                                    );
                                                  }
                                                }}
                                              />
                                            )}

                                          {![
                                            'select',
                                            'radio',
                                            'checkbox',
                                            'date',
                                          ].includes(searchField['type']) && (
                                              <DaynamicSearchInputField
                                                label={searchField['label']}
                                                type={searchField['type']}
                                                placeholder={searchField['label']}
                                                required={
                                                  searchField['isRequired']
                                                }
                                                invalid={{ errors, touched }}
                                                {...getFieldProps(
                                                  searchField['name'],
                                                )}
                                              />
                                            )}
                                        </>
                                      </FormGroup>
                                    </Col>
                                  );
                                }
                              },
                            )}
                        </div>
                      </Form>
                    </Col>

                    <Col lg={'auto'} md={'5'} sm={12} className="mt-4 pt-1">
                      <FormGroup className="btn-group btn-group-example ">
                        <ButtonGroup className="d-inline-block ">
                          <Button
                            color="danger"
                            type="reset"
                            text="Reset"
                            onClick={() => {
                              resetSearchForm();
                              handleSearchSubmit();
                            }}
                          />
                          <Button
                            type="button"
                            text="Search"
                            onClick={() => {
                              handleSearchSubmit();
                            }}
                          />
                          {formObj?.showCreateNewBtn && (
                            <Button
                              color="success"
                              type="button"
                              text={formObj.createNewBtnTitle || 'Create New'}
                              onClick={() => {
                                history.push(`${formObj.addUrl}`);
                                // handleSearchSubmit();
                              }}
                            />
                          )}
                        </ButtonGroup>
                      </FormGroup>
                    </Col>
                  </>
                )}
                {formObj?.addUrl &&
                  formObj?.showCreateNewBtn &&
                  inputSearchFields.length === 0 && (
                    <Col
                      md={
                        formObj?.showSearchForm && inputSearchFields.length > 0
                          ? ''
                          : 12
                      }
                      sm={12}
                      className="d-flex justify-content-end align-items-center"
                    >
                      <div className="mt-2">
                        <Link
                          to={formObj.addUrl}
                          className="btn btn-success waves-effect waves-light"
                        >
                          <i className="fa fa-plus me-2" />{' '}
                          {formObj.createNewBtnTitle || 'Create New'}
                        </Link>
                      </div>
                    </Col>
                  )}
              </Row>
            </CardHeader>
            <CardBody>
              {columns.length > 0 && (
                <DataTable
                  columns={columns}
                  data={data?.data ? data.data : []}
                  pagination
                  paginationServer
                  onChangePage={(page: number) => {
                    setPage(page);
                  }}
                  onChangeRowsPerPage={(newPerPage: number, page: number) => {
                    setLimit(newPerPage);
                    setPage(page);
                  }}
                  progressPending={dataListLoading}
                  paginationTotalRows={data?.total}
                  expandOnRowClicked
                  highlightOnHover
                  paginationRowsPerPageOptions={
                    config.paginationRowsPerPageOptions
                  }
                  persistTableHead
                />
              )}

              {/* {data?.total === undefined && columns.length > 0 && (
            <DataTable
              columns={columns}
              data={data}
              pagination
              progressPending={dataListLoading}
              paginationTotalRows={data?.total}
              highlightOnHover
              paginationRowsPerPageOptions={config.paginationRowsPerPageOptions}
            />
          )} */}
            </CardBody>
          </Card>
          <Modal
            onOpened={function () { }}
            isOpen={showModal}
            scrollable={false}
            size="xl"
            toggle={() => {
              document.body.classList.add('no_padding');
            }}
            backdrop={'static'}
            modalTransition={{ timeout: 0 }}
            backdropTransition={{ timeout: 0 }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Details Info
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setViewData({});
                  setShowModal(false);
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div style={{ maxHeight: '50vh', overflow: 'hidden', overflowY: 'auto', }} >
                <DynamicView viewColumn={viewColumn} viewData={viewData} />
              </div>

              <table className="table table-bordered">
                <tbody>
                  <tr className="bg-light text-dark text-center">
                    <th colSpan={2}>Approval Form</th>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>
                      <div>
                        <FormGroup check inline className="mb-3 ">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            value={approvalValues.status}
                            checked={
                              approvalValues.status === 'Approve'
                                ? true
                                : false
                            }
                            id="approve"
                            onChange={() => {
                              approvalSetFieldValue('status', 'Approve');
                            }}
                          />
                          <label
                            className="form-check-label text-success"
                            htmlFor="approve"
                          >
                            Approve
                          </label>
                        </FormGroup>

                        <FormGroup check inline className="mb-3 ">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            value={approvalValues.status}
                            checked={
                              approvalValues.status === 'Reject' ? true : false
                            }
                            id="reject"
                            onChange={() => {
                              approvalSetFieldValue('status', 'Reject');
                            }}
                          />
                          <label
                            className="form-check-label text-danger"
                            htmlFor="reject"
                          >
                            Reject
                          </label>
                        </FormGroup>

                        <FormGroup check inline className="mb-3 ">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            value={approvalValues.status}
                            id="correction"
                            checked={
                              approvalValues.status === 'Correction'
                                ? true
                                : false
                            }
                            onChange={() => {
                              approvalSetFieldValue('status', 'Correction');
                            }}
                          />
                          <label
                            className="form-check-label text-info"
                            htmlFor="correction"
                          >
                            Correction
                          </label>
                        </FormGroup>

                        {/* {errors.status && touched.status && (
                          <div className="text-danger">{errors.status}</div>
                        )} */}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th style={{ width: '30%' }}>
                      {approvalValues.status} Note
                    </th>
                    <td>
                      <InputField
                        type="textarea"
                        placeholder={'Enter ' + approvalValues.status + ' Note'}
                        invalid={{ errors, touched }}
                        {...approvalGetFieldProps('message')}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>


            </div>
            <div className="modal-footer">
              {showForm && (
                <Button
                  type="submit"
                  text="Save"
                  className="btn btn-success"
                  onClick={(e) => {
                    e.preventDefault();
                    approvalHandleSubmit();
                  }}
                ></Button>
              )}
              <Button
                type="button"
                text="Close"
                className="btn btn-danger"
                onClick={() => {
                  setViewData({});
                  setShowModal(!showModal);
                }}
              ></Button>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  );
};

export default InstituteApproval;
