import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Form, Input, Label } from 'reactstrap';
import InputField from 'src/components/Input';
import { setFromErrors } from 'src/helpers';
import {
  useCreateEducationalProfileMutation,
  useLazyGetFcpsPartOneInfoQuery,
  useUpdateEducationalProfileMutation,
} from 'src/rtk/features/educational/educationalApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import { useGetOrganizationsQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import { useGetPersonalProfileQuery } from 'src/rtk/features/trainee/traineeApi';
import * as Yup from 'yup';
import loader from '../../../assets/images/loader.gif';

const AddEducation = () => {
  const initialValues: EducationalProfile = {
    id: '',
    name_of_the_degree: 'FCPS-Part-1',
    others_name_of_the_degree_value: '',
    institute_or_university: '',
    others_institute_or_university_value: '',
    passing_year: '',
    certificate_pdf: '',
    roll: '',
    session: '',
    subject: '',
    present_designation: '',
    present_working_place: '',
    // start_date: '',
    // end_date: '',
  };

  const FILE_SIZE = 1024 * 1024;
  const history = useHistory();
  const [selectedSubject, setSelectedSubject] = useState<any>('');
  const [selectedInstitue, setSelectedInstitute] = useState<any>('');
  const [selectedYear, setSelectedYear] = useState<any>('');

  const fileInputRef = useRef<any>();
  const buttonRef = useRef<any>();
  const instituteSearchableRef = useRef<any>();

  const { data: degreeLookupData } = useGetLookupDataQuery(
    'educational_qualification',
  );

  const { data: instituteLookupData } = useGetOrganizationsQuery();

  const { data: designationLookupData } = useGetLookupDataQuery('designation');

  const { data: sessionLookupData } = useGetLookupDataQuery('session');

  const { data: subjectLookupData } = useGetSubjectInfosQuery();

  const { data: educationalProfile } = useGetPersonalProfileQuery();

  const [getFcpsPartOneInfo, { data: fcpsPart }] = useLazyGetFcpsPartOneInfoQuery();

  useEffect(() => {
    getFcpsPartOneInfo(educationalProfile?.data?.details?.bmdc_registration_no!);
  }, []);

  const degreeList: Lookup[] = (degreeLookupData as any)?.data[0]?.childs || [];

  const instituteList: Organization[] =
    (instituteLookupData as any)?.data || [];

  const instituteOptions: SelectOption[] = [
    ...instituteList?.map((o: Organization) => ({
      label: o.organization_name!,
      value: o.organization_name!,
    })),
  ];

  const designationList: Lookup[] =
    (designationLookupData as any)?.data[0]?.childs || [];

  const sessionList: Lookup[] =
    (sessionLookupData as any)?.data[0]?.childs || [];

  const subjectList: SubjectInfo[] = (subjectLookupData as any)?.data || [];

  const allSubjects: SelectOption[] =
    subjectLookupData?.data?.map((subject) => ({
      value: subject.subject_name,
      label: subject.subject_name,
    })) || [];

  const currentYear = new Date().getFullYear();
  const differenceOfTwoYear = currentYear - 1990;
  const startYear = currentYear - differenceOfTwoYear;

  const years = [];
  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }

  const allYears: SelectOption[] = years?.map((item: any) => ({
    value: item,
    label: item,
  }));

  // console.log(educationalProfile);

  const [createEducationalProfile, { isSuccess, isError, error, isLoading }] =
    useCreateEducationalProfileMutation();

  const [
    updateEducationalProfile,
    { isSuccess: isUpdateSuccess, isError: isUpdateError, error: updateError },
  ] = useUpdateEducationalProfileMutation();

  const err: CustomError = error as CustomError;
  const updateErr: CustomError = updateError as CustomError;
  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    values,
    setValues,
    handleChange,
    setFieldValue,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      name_of_the_degree: Yup.string().required('Please Select a Degree'),
      institute_or_university: Yup.string().required('Institute is Required'),
      passing_year: Yup.string().required('Passsing Year is Required'),
      others_name_of_the_degree_value: Yup.string().when(
        'name_of_the_degree',
        (name_of_the_degree, schema) => {
          if (name_of_the_degree === 'Others') {
            return schema.required('Degree is Required!');
          }
          return schema;
        },
      ),
      subject: Yup.string().when(
        'name_of_the_degree',
        (name_of_the_degree, schema) => {
          if (name_of_the_degree === 'FCPS' ||
            name_of_the_degree === 'FCPS-Part-1' ||
            name_of_the_degree === 'MD' ||
            name_of_the_degree === 'MS' ||
            name_of_the_degree === 'MRCP' ||
            name_of_the_degree === 'MRCS' ||
            name_of_the_degree === 'MRCOG' ||
            name_of_the_degree === 'MRCPH') {
            return schema.required('Subject is Required!');
          }
          return schema;
        },
      ),

      present_designation: Yup.string().when(
        'name_of_the_degree',
        (name_of_the_degree, schema) => {
          if (name_of_the_degree === 'FCPS' ||
            name_of_the_degree === 'MD' ||
            name_of_the_degree === 'MS' ||
            name_of_the_degree === 'MRCP' ||
            name_of_the_degree === 'MRCS' ||
            name_of_the_degree === 'MRCOG' ||
            name_of_the_degree === 'MRCPH') {
            return schema.required('Present Designation is Required!');
          }
          return schema;
        },
      ),

      present_working_place: Yup.string().when(
        'name_of_the_degree',
        (name_of_the_degree, schema) => {
          if (name_of_the_degree === 'FCPS' ||
            name_of_the_degree === 'MD' ||
            name_of_the_degree === 'MS' ||
            name_of_the_degree === 'MRCP' ||
            name_of_the_degree === 'MRCS' ||
            name_of_the_degree === 'MRCOG' ||
            name_of_the_degree === 'MRCPH') {
            return schema.required('Present Working Place is Required!');
          }
          return schema;
        },
      ),
      
      // start_date: Yup.date().max(new Date(Date.now()), 'From date can\'t be a future date').when(
      //   'name_of_the_degree',
      //   (name_of_the_degree, schema) => {
      //     if (name_of_the_degree === 'FCPS-Part-1') {
      //       return schema.required('Strat Date is Required!');
      //     }
      //     return schema;
      //   }),
      // end_date: Yup.date().when("start_date", (start_date, schema) => {
      //   if (start_date) {
      //     const fromDate = new Date(start_date);

      //     return schema.required('End date is Required').min(fromDate, 'To date must be greater than start date').nullable();
      //   }

      //   return schema;
      // }),
      others_institute_or_university_value: Yup.string().when(
        'institute_or_university',
        (institute_or_university, schema) => {
          if (institute_or_university === 'Others') {
            return schema.required('Institute is Required!');
          }
          return schema;
        },
      ),
      certificate_pdf: Yup.mixed()
        .required('File is required')
        .test('is-pdf', 'Must be a File', (value) => {
          if (value) {
            return [
              'application/pdf',
              'image/jpeg',
              'image/png',
              'image/jpg',
            ].includes(value.type);
          }
          return true;
        })
        .test('file-size', 'File too large', (value) => {
          if (value) {
            return value.size <= FILE_SIZE;
          }
          return true;
        }),
    }),
    onSubmit: (values: any) => {
      // if(values.start_date && values.end_date){
      //   values.start_date = moment(
      //     values.start_date,
      //   ).format('YYYY-MM-DD');
      //   values.end_date = moment(
      //     values.end_date,
      //   ).format('YYYY-MM-DD');
      // }
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      buttonRef.current.disabled = true;
      createEducationalProfile(formData);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      setSelectedSubject('');
      setSelectedInstitute('');
      setSelectedYear('');
      resetForm();
      fileInputRef.current.value = '';
      toast.success('Educational Information Stored Successfully!');
      buttonRef.current.disabled = false;
    }
    if (isError) {
      toast.error(err?.message || 'Something Went Wrong!');
      setFromErrors(err.data, setErrors);
      buttonRef.current.disabled = false;
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    console.log(selectedSubject?.value);
    if (selectedSubject !== '') {
      setFieldValue('subject', selectedSubject?.value);
    }
  }, [selectedSubject]);

  useEffect(() => {
    if (selectedYear !== '') {
      setFieldValue('passing_year', selectedYear?.value);
    }
  }, [selectedYear]);

  useEffect(() => {
    console.log(selectedInstitue?.value);
    if (selectedInstitue?.value) {
      let selectedId = selectedInstitue.value;
      if (
        instituteOptions.find(
          (option: SelectOption) =>
            option.value === selectedId,
        )
      ) {
        setFieldValue('institute_or_university', selectedId);
      } else {
        setFieldValue('institute_or_university', 'Others');
        setFieldValue('others_institute_or_university_value', selectedId);
      }
    } else {
      setFieldValue('institute_or_university', '');
    }
  }, [selectedInstitue]);

  useEffect(() => {
    if (values.name_of_the_degree === 'FCPS-Part-1' || values.name_of_the_degree === 'FCPS') {
      setSelectedInstitute({
        label: 'Bangladesh College of Physicians and Surgeons(BCPS)',
        value: 'Bangladesh College of Physicians and Surgeons(BCPS)',
      });
    } else if (values.name_of_the_degree === 'MD' || values.name_of_the_degree === 'MS') {
      setSelectedInstitute({
        label: 'Bangabandhu Sheikh Mujib Medical University (BSMMU)',
        value: 'Bangabandhu Sheikh Mujib Medical University (BSMMU)',
      });
    } else {
      setSelectedInstitute('');
    }
  }, [values.name_of_the_degree]);


  useEffect(() => {
    if (fcpsPart?.data) {
      setSelectedInstitute({
        label: fcpsPart?.data?.institute ?? '',
        value: fcpsPart?.data?.institute,
      });
      setSelectedSubject({
        label: fcpsPart?.data?.subject ?? '',
        value: fcpsPart?.data?.subject,
      });
      setFieldValue('roll', fcpsPart?.data?.roll ?? '');
      setFieldValue('session', fcpsPart?.data?.passing_session ?? '');
      setFieldValue('passing_year', fcpsPart?.data?.passing_year ?? '');
      setSelectedYear({ value: fcpsPart?.data?.passing_year, label: fcpsPart?.data?.passing_year });
    }
  }, [fcpsPart]);

  return (
    <div>
      {isLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        id="reset-file-input"
      >
        <div className="incremental-from-field-grid mb-4">
          <div className="large-from-grid">
            <div>
              <Label for="name_of_the_degree" className="required-field">
                Degree
              </Label>
              <Input
                type="select"
                name="name_of_the_degree"
                value={values.name_of_the_degree}
                onChange={handleChange}
              >
                <option value="">Select Degree</option>
                {degreeList?.map((degreeItem) => (
                  <option key={degreeItem.id} value={degreeItem.name}>
                    {degreeItem.name}
                  </option>
                ))}
                <option value="Others">Others</option>
              </Input>
              {errors.name_of_the_degree && touched.name_of_the_degree ? (
                <div className="text-danger error-size">{errors.name_of_the_degree as string}</div>
              ) : null}
            </div>

            {values.name_of_the_degree === 'Others' ? (
              <div>
                <Label className="form-label required-field">
                  Other Degree
                </Label>
                <InputField
                  type="text"
                  placeholder="Other Degree"
                  invalid={{ errors, touched }}
                  {...getFieldProps('others_name_of_the_degree_value')}
                />
              </div>
            ) : null}

            {/* <div>
              <Label for="institute_or_university" className="required-field">
                Institute
              </Label>
              <Input
                type="select"
                name="institute_or_university"
                value={values.institute_or_university}
                onChange={handleChange}
              >
                <option value="">Select Institute</option>
                {instituteList?.map((instituteItem) => (
                  <option
                    key={instituteItem.id}
                    value={instituteItem.organization_name}
                  >
                    {instituteItem.organization_name}
                  </option>
                ))}
                <option value="Others">Others</option>
              </Input>
              {errors.institute_or_university &&
              touched.institute_or_university ? (
                <div className="text-danger">
                  {errors.institute_or_university}
                </div>
              ) : null}
            </div> */}

            <div>
              <Label for="institute_or_university" className="required-field">
                Institute
              </Label>
              <CreatableSelect
                // ref={instituteSearchableRef}
                isSearchable={true}
                options={instituteOptions}
                isClearable={true}
                name="institute_or_university"
                value={selectedInstitue}
                onChange={setSelectedInstitute}
              />
              {errors.institute_or_university &&
                touched.institute_or_university ? (
                <div className="text-danger error-size">
                  {errors.institute_or_university as string}
                </div>
              ) : null}
            </div>

            {/* {values.institute_or_university === 'Others' ? (
              <div>
                <Label className="form-label required-field">
                  Other Institute
                </Label>
                <InputField
                  type="text"
                  placeholder="Other Institute"
                  invalid={{ errors, touched }}
                  {...getFieldProps('others_institute_or_university_value')}
                />
              </div>
            ) : null} */}

            {/* {values.name_of_the_degree === 'FCPS-Part-1' ? (
              <div>
              <div className="input-group-with-label">
                <div>
                  <Label className="form-Label required-field">Start Date</Label>
                  <Input
                    className="form-control"
                    type="date"
                    name="start_date"
                    onChange={handleChange}
                    value={values.start_date}
                  />
                  {errors.start_date && touched.start_date ? (
                  <div className="text-danger">
                    {errors.start_date as string}
                  </div>
                ) : null}
                </div>
                <div>
                  <Label className="form-Label required-field">End Date</Label>
                  <Input
                    className="form-control"
                    type="date"
                    name="end_date"
                    onChange={handleChange}
                    value={values.end_date}
                  />
                  {errors.end_date && touched.end_date ? (
                  <div className="text-danger">
                    {errors.end_date as string}
                  </div>
                  ) : null}
                </div>
              </div>
            </div>
            ) : null} */}

            {values.name_of_the_degree === 'FCPS' ||
              values.name_of_the_degree === 'FCPS-Part-1' ||
              values.name_of_the_degree === 'MD' ||
              values.name_of_the_degree === 'MS' ||
              values.name_of_the_degree === 'MRCP' ||
              values.name_of_the_degree === 'MRCS' ||
              values.name_of_the_degree === 'MRCOG' ||
              values.name_of_the_degree === 'MRCPH' ? (
              <div>
                <Label className='required-field'>Subject</Label>
                {/* <Select
                  isClearable={true}
                  isSearchable={true}
                  value={ allSubjects.find(
                    (option: any) =>
                      option.value === values.subject,
                  )}
                  onChange={(e: any) => {
                    if (e?.value) {
                      let selectedId = e.value;
                      setFieldValue('subject', selectedId);     
                    } else {
                      setFieldValue('subject', '');
                    }
                  }}
                  options={allSubjects}
                  placeholder='Select Subject'
                  name="subject"
                /> */}
                <Select
                  isClearable={true}
                  isSearchable={true}
                  value={selectedSubject}
                  onChange={setSelectedSubject}
                  options={allSubjects}
                  placeholder='Select Subject'
                  name="subject"
                />
                {errors.subject && touched.subject ? (
                  <div className="text-danger error-size">{errors.subject as string}</div>
                ) : null}
              </div>
            ) : null}

            {values.name_of_the_degree === 'FCPS-Part-1' ? (
              <div>
                <Label className="form-label">Roll</Label>
                <InputField
                  type="text"
                  placeholder="Roll Number"
                  invalid={{ errors, touched }}
                  {...getFieldProps('roll')}
                />
              </div>
            ) : null}

            {values.name_of_the_degree === 'FCPS-Part-1' ? (
              <div>
                <Label for="session">Session</Label>
                <Input
                  type="select"
                  name="session"
                  value={values.session}
                  onChange={handleChange}
                >
                  <option>Select Session</option>
                  {sessionList?.map((sessionItem) => (
                    <option key={sessionItem.id} value={sessionItem.name}>
                      {sessionItem.name}
                    </option>
                  ))}
                </Input>
                {errors.session && touched.session ? (
                  <div className="text-danger error-size">{errors.session as string}</div>
                ) : null}
              </div>
            ) : null}

            <div>
              <Label className="form-label required-field">Passing Year</Label>
              <Select
                isClearable={true}
                isSearchable={true}
                value={selectedYear}
                onChange={setSelectedYear}
                options={allYears}
                placeholder='Select Year'
                name="passing_year"
              />
              {errors.passing_year && touched.passing_year ? (
                <div className="text-danger error-size">{errors.passing_year as string}</div>
              ) : null}
            </div>

            {values.name_of_the_degree === 'FCPS' ||
              values.name_of_the_degree === 'MD' ||
              values.name_of_the_degree === 'MS' ||
              values.name_of_the_degree === 'MRCP' ||
              values.name_of_the_degree === 'MRCS' ||
              values.name_of_the_degree === 'MRCOG' ||
              values.name_of_the_degree === 'MRCPH' ? (
              <div>
                <Label className="form-label required-field">Present Designation</Label>
                <Input
                  type="select"
                  name="present_designation"
                  value={values.present_designation}
                  onChange={handleChange}
                >
                  <option>Select Designation</option>
                  {designationList?.map((designationItem) => (
                    <option
                      key={designationItem.id}
                      value={designationItem.name}
                    >
                      {designationItem.name}
                    </option>
                  ))}
                </Input>
                {errors.present_designation && touched.present_designation ? (
                  <div className="text-danger error-size">
                    {errors.present_designation as string}
                  </div>
                ) : null}
              </div>
            ) : null}
            {values.name_of_the_degree === 'FCPS' ||
              values.name_of_the_degree === 'MD' ||
              values.name_of_the_degree === 'MS' ||
              values.name_of_the_degree === 'MRCP' ||
              values.name_of_the_degree === 'MRCS' ||
              values.name_of_the_degree === 'MRCOG' ||
              values.name_of_the_degree === 'MRCPH' ? (
              <div>
                <Label className="form-label required-field">Present Working Place</Label>
                <InputField
                  name="present_working_place"
                  type="text"
                  placeholder="Present Working Place"
                  onChange={handleChange}
                  value={values.present_working_place}
                  invalid={{ errors, touched }}
                />
              </div>
            ) : null}
            <div>
              <Label className="form-label required-field">Certificate/Document</Label>{' '}
              <i
                className="fas fa-info-circle"
                title="Upload certificate as per selected Degree. Format must be .pdf or .jpg or .png. Size cannot be greater than 1MB"
              ></i>
              <input
                ref={fileInputRef}
                name="certificate_pdf"
                type="file"
                className="form-control"
                placeholder="Upload Certificate"
                onChange={(e: any) => {
                  setFieldValue('certificate_pdf', e.target.files[0]);
                }}
              ></input>
              {errors.certificate_pdf && touched.certificate_pdf ? (
                <div className="text-danger error-size">{errors.certificate_pdf as string}</div>
              ) : null}
            </div>
          </div>
          <div className="d-flex mt-3 gap-2">
            <button
              className="btn btn-primary px-5"
              type="submit"
              onClick={(e) => handleSubmit}
              ref={buttonRef}
            >
              {'Add'}
            </button>
            <button
              className="btn btn-outline-secondary px-5"
              type="button"
              onClick={(e) => {
                setSelectedSubject('');
                setSelectedInstitute('');
                setSelectedYear('');
                resetForm();
                fileInputRef.current.value = '';
                // instituteSearchableRef.current.value = '';
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddEducation;
