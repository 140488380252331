import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import { useDeleteTransactionNoticeDetailsMutation } from 'src/rtk/features/setup/trnNoticeDetails/setupTrnNoticeDetails';
import { useGetTrnNoticeMasterListsQuery } from 'src/rtk/features/setup/trnNoticeMaster/setupTrnNoticeMaster';

interface Props {
  transactionNoticeDetails: TransactionNoticeDetails;
  index: number;
}

const TransactionNoticeDetailsTableRow = ({ transactionNoticeDetails, index }: Props) => {
  const history = useHistory();
  const [deleteTransactionNoticeDetails, { isSuccess, isLoading, isError }] =
    useDeleteTransactionNoticeDetailsMutation();

  const [infoId, setInfoId] = useState('');
  const [modalShow, setModalShow] = useState(false);
  
  const { data } = useGetTrnNoticeMasterListsQuery();
  const noticeMasterList = data?.data || [];

  const deleteHandler = (modalState: boolean, id: string) => {
    setModalShow(modalState);
    setInfoId(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Notice details deleted successfully');
    }
    if (isError) {
      toast.error('Error deleting notice details');
    }
  }, [isSuccess, isError]);

  return (
    <tr key={transactionNoticeDetails.id}>
      <th scope="row">{index + 1}</th>        
      <td>
        {
          noticeMasterList?.find((n) => n.id === transactionNoticeDetails.notice_master_id)
            ?.notice_subject
        }
      </td>      
      <td>{transactionNoticeDetails.details}</td>
      <td>{transactionNoticeDetails.status ? 'Active' : 'Inactive'}</td>       
      <td>
        <div className="d-flex gap-2">
          <Button
            size="sm"
            text="Edit"
            color="warning"
            onClick={() =>
              history.push(`/setup/trn-notice-details/update/${transactionNoticeDetails.id}`)
            }
          />

          <Button
            size="sm"
            text="Delete"
            color="danger"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => deleteHandler(true, transactionNoticeDetails.id!)}
          />
        </div>
        {modalShow ? (
          <DeleteInformationModal
            isOpen={modalShow}
            toggle={() => setModalShow(!modalShow)}
            id={infoId}
            deleteInfomation={deleteTransactionNoticeDetails}
          />
        ) : null}
      </td>
    </tr>
  );
};

export default TransactionNoticeDetailsTableRow;
