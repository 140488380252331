import { useEffect, useState } from 'react';
import { Container, Form, FormGroup, Label } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetLogbookEntrySetupDetailsGroupsQuery } from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import { AddLogbookEntryDetails } from './AddLogbookEntryDetails';
import { LoadLogbookEntryDetails } from './LoadLogbookEntryDetails';

const SelectAcademicActivityEntryDetailsGroup = () => {
  const [subject, setSubject] = useState<SubjectInfo>({} as SubjectInfo);
  const [entryDetailGroupOptions, setEntryDetailGroupOptions] = useState<
    SelectOption[]
  >([]);
  const [selectedEntryGroup, setSelectedEntryGroup] =
    useState<LogbookEntryDetailsGroup>({} as LogbookEntryDetailsGroup);

  const {
    data: logbookEntryDetailGroups,
    error: logbookEntryDetailGroupsError,
    isError: isLogbookEntryDetailGroupsError,
    isSuccess: isLogbookEntryDetailGroupsSuccess,
  } = useGetLogbookEntrySetupDetailsGroupsQuery();

  const detailGroups: LogbookEntryDetailsGroup[] =
    logbookEntryDetailGroups?.data!;

  const { data: getSubjectInfolist } = useGetSubjectInfosQuery();
  const subjects = getSubjectInfolist?.data;

  const subjectOptions: SelectOption[] =
    subjects?.map((subject) => ({
      value: subject.id!,
      label: subject.subject_name,
    })) || [];

  const handleSubjectSelect = (e: any) => {
    const { options, selectedIndex, value } = e.target;
    const subject = subjects?.find((subject) => subject.id === value);
    setSubject(subject!);
    setSelectedEntryGroup({ id: '' } as LogbookEntryDetailsGroup);
  };

  const handleGroupSelection = (e: any) => {
    const { options, selectedIndex, value } = e.target;
    const group = detailGroups?.find((detailGroup) => detailGroup.id === value);
    setSelectedEntryGroup(group!);
  };

  useEffect(() => {
    const detailGroupsBySubjectId: LogbookEntryDetailsGroup[] =
      detailGroups?.filter((d) => d.subject_id === subject?.id);
    const detailGroupOptions: SelectOption[] =
      detailGroupsBySubjectId?.filter((detailGroup) => {
        const logbookGroupTitleList: string[] = detailGroup?.title.split(' -> ');
        return logbookGroupTitleList[3] === 'Academic Activity';
      })?.map((detailGroup) => ({
        value: detailGroup.id!,
        label: detailGroup.title!,
      })) || [];

    setEntryDetailGroupOptions(detailGroupOptions);
  }, [subject]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BetterBreadcrumb title={'Add Academic Activity Field'} />
          <div className="row align-items-center justify-content-center">
            <div className="card">
              <h5 className="card-header">
                <i className="fa fa-plus me-2" />
                Add Academic Activity Field
              </h5>
              <div className="card-body">
                <Form
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-12">
                      <FormGroup className="mb-3">
                        <Label className="form-label">Subject</Label>
                        <InputField
                          type="select"
                          name="subject"
                          placeholder=""
                          options={subjectOptions}
                          onChange={(e) => {
                            handleSubjectSelect(e);
                          }}
                        />
                      </FormGroup>
                    </div>

                    <div className="col-lg-8 col-md-8 col-sm-12">
                      <FormGroup className="mb-3">
                        <Label className="form-label">Select Group</Label>
                        <InputField
                          type="select"
                          name="group"
                          placeholder=""
                          value={selectedEntryGroup?.id}
                          options={entryDetailGroupOptions}
                          onChange={(e) => {
                            handleGroupSelection(e);
                          }}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </Form>

                {selectedEntryGroup?.id ? (
                  selectedEntryGroup?.id !== '' ? <LoadLogbookEntryDetails key={selectedEntryGroup?.id} logbookGroup={selectedEntryGroup} /> : <AddLogbookEntryDetails />
                ) : (
                  <AddLogbookEntryDetails />
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default SelectAcademicActivityEntryDetailsGroup;
