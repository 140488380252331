import { useFormik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Modal,
  Row,
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import store from 'src/rtk/app/store';
import {
  useGetFormByLogbookTypeQuery,
} from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import { useGetLogbookEntriesByPaginateQuery } from 'src/rtk/features/trainee/traineeLogbookEntryApi';
import loader from '../../../../assets/images/loader.gif';
import EditDynamicForm from './EditDynamicLogbookEntry';
import { authSlice } from '../../../../rtk/features/auth/authSlice';

interface SearchFields {
  form?: string;
  type?: string;
  from_date?: string;
  to_date?: string;
  approved_status?: string;
}

const UpdatedLogbookEntryList = () => {
  document.title = 'Trainee Logbook Entry Management | BCPS';
  let { id: formValue } = useParams<{ id: string }>();

  const [formName, setFormName] = useState<string>(formValue);
  const [editFormActive, setEditFormActive] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState(false);
  const [page, setPage] = useState<string | number>('1');
  const [paginate_per_page, set_paginate_per_page] = useState(10);
  const [search, setSearch] = useState(
    formName ? `&search=${'Patient Management'}` : '',
  );
  const [editCount, setEditCount] = useState<number>(1);
  const [logbookForEdit, setLogbookForEdit] = useState<LogbookEntryData>(
    {} as LogbookEntryData,
  );
  const [logbookForModal, setLogbookForModal] = useState<LogbookEntryData>(
    {} as LogbookEntryData,
  );
  const [logbookEntryDetails, setLogbookEntryDetails] = useState<any>({});
  const [logbookCompetencyGroup, setLogbookCompetencyGroup] = useState<
    LogbookCompetencyLevels[]
  >([]);
  const [competencyGroupModalShow, setCompetencyGroupModalShow] =
    useState<boolean>(false);
  const [approveStatus, setApproveStatus] = useState<string>('');
  const [note, setNote] = useState<string>('');

  const { data: formSetups } = useGetFormByLogbookTypeQuery(1);
  const forms = formSetups?.data || [];

  let formOptions: SelectOption[] = [
    ...forms?.map((e: LogbookEntrySetupMaster) => ({
      label: e.particulars!,
      value: e.particulars!,
    })),
  ];

  const {
    data: logbookEntries,
    error: logbookEntriesError,
    isLoading: isLogbookEntriesLoading,
    isError: isLogbookEntriesError,
    isSuccess: isLogbookEntriesSuccess,
  } = useGetLogbookEntriesByPaginateQuery({
    page,
    paginate_per_page,
    search,
  });

  const logbookEntryList: LogbookEntryData[] = logbookEntries?.data?.data!;

  const editHandler = (logbookEntry: LogbookEntryData) => {
    setLogbookForEdit(logbookEntry);
    setEditCount((e: number) => e + 1);
    setEditFormActive(true);
    setModalShow(true);
  };

  const modalShowHandler = (modalState: boolean, logbook: LogbookEntryData) => {
    setModalShow(modalState);
    setLogbookForEdit(logbook);
    let logbookEntryDetails = JSON.parse(logbook?.details_json!) || {};
    setLogbookCompetencyGroup(logbook?.competency_group);
    setLogbookEntryDetails(logbookEntryDetails);
    setApproveStatus(logbook?.approved_status?.toLowerCase()!);
    setNote(logbook?.note!);
    setEditFormActive(false);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    set_paginate_per_page(newPerPage);
    setPage(page);
  };

  const getDetailsData = (key = '', dataObj: any = {}) => {
    let perseData: any = {};

    if (
      dataObj['details_json'] &&
      typeof dataObj['details_json'] === 'string'
    ) {
      perseData = JSON.parse(dataObj.details_json);
    } else {
      perseData = dataObj;
    }

    return perseData[key] ? (
      <span
        dangerouslySetInnerHTML={{
          __html:
            key === 'Diagnosis/Findings' ||
            key === 'Brief Procedural Note / Interpretation'
              ? perseData[key].length <= 50
                ? perseData[key]
                : perseData[key].substring(0, 50) + '...'
              : perseData[key],
        }}
      ></span>
    ) : (
      ''
    );
  };

  const decodeBase64EncodeString = (base64String: string) => {
    let correctBase64EncodedString: string = base64String.split('base64,')?.[1];
    let tempString: string = base64String.split('base64,')?.[0];
    let typeOfDocument: string = tempString.split('data:')?.[1] + ';base64';
    let byteCharacters = atob(correctBase64EncodedString);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: typeOfDocument });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const columns1: TableColumn<any>[] = [
    {
      name: '#',
      width: '60px',
      cell: (row, index) => (+page - 1) * paginate_per_page + index + 1,
    },
    {
      name: 'Slot',
      selector: (row) => row?.logbook_entry_session?.exam_training_info?.name,
      sortable: false,
      wrap: true,
    },
    {
      name: 'Date',
      selector: (row) =>
        row.created_at ? moment(row.created_at).format('DD/MMM/YYYY h:mm:ss a') : '',
      sortable: false,
      wrap: true,
    },
    {
      name: 'Form',
      selector: (row) => row.heading,
      sortable: false,
      wrap: true,
    },
    {
      name: 'Case Type',
      selector: (row) => getDetailsData('caseType', row),
      sortable: false,
      wrap: true,
    },
    {
      name: 'Skill Type',
      selector: (row) => getDetailsData('skillType', row),
      sortable: false,
      wrap: true,
    },
    {
      name: 'Status',
      selector: (row) => row.approved_status,
      cell: (row) => {
        switch (row.approved_status.toLowerCase()) {
          case 'reject':
            return (
              <>
                <div className='badge rounded-pill d-inline-block  badge-rejected font-size-12 fw-medium'>
                  {'Rejected'}
                </div>
              </>
            );

          case 'draft':
            return (
              <>
                <div className='badge rounded-pill d-inline-block  badge-draft font-size-12 fw-medium'>
                  {row.approved_status}
                </div>
              </>
            );

          case 'approved':
            return (
              <>
                <div className='badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium'>
                  {row.approved_status}
                </div>
              </>
            );

          case 'correction':
            return (
              <>
                <div className='badge rounded-pill d-inline-block  badge-correction font-size-12 fw-medium'>
                  {row.approved_status}
                </div>
              </>
            );

          case 'pending':
            return (
              <>
                <div className='badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium'>
                  {row.approved_status}
                </div>
              </>
            );

          default:
            return (
              <>
                <div className='badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium'>
                  {row.approved_status}
                </div>
              </>
            );
        }
      },
      sortable: false,
    },
    {
      name: 'Action',
      width: '150px',
      cell: (row) => (
        <>
          {/* btn-group-example */}
          <div className='d-flex gap-1 my-1'>
            <Button
              size='sm'
              color='info'
              type='button'
              text={`View`}
              onClick={() => {
                modalShowHandler(true, row);
              }}
            />
            {row.approved_status &&
              (row.approved_status.toLowerCase() === 'draft' ||
                row.approved_status.toLowerCase() === 'correction') && (
                <Button
                  size='sm'
                  color='success'
                  type='button'
                  text={`Edit`}
                  onClick={() => {
                    editHandler(row);
                  }}
                />
              )}
          </div>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      // button: true,
    },
  ];

  const columns2: TableColumn<any>[] = [
    {
      name: '#',
      width: '60px',
      cell: (row, index) => (+page - 1) * paginate_per_page + index + 1,
    },
    {
      name: 'Date',
      selector: (row) =>
        row.created_at ? moment(row.created_at).format('DD/MMM/YYYY h:mm:ss a') : '',
      sortable: false,
      wrap: true,
    },
    {
      name: 'Form',
      selector: (row) => row.heading,
      sortable: false,
      wrap: true,
    },
    {
      name: 'Case Type',
      selector: (row) => getDetailsData('caseType', row),
      sortable: false,
      wrap: true,
    },
    {
      name: 'Skill Type',
      selector: (row) => getDetailsData('skillType', row),
      sortable: false,
      wrap: true,
    },
    {
      name: 'Action',
      width: '150px',
      cell: (row) => (
        <>
          {/* btn-group-example */}
          <div className='d-flex gap-1 my-1'>
            <Button
              size='sm'
              color='info'
              type='button'
              text={`View`}
              onClick={() => {
                modalShowHandler(true, row);
              }}
            />
            {row.approved_status &&
              (row.approved_status.toLowerCase() === 'draft' ||
                row.approved_status.toLowerCase() === 'correction') && (
                <Button
                  size='sm'
                  color='success'
                  type='button'
                  text={`Edit`}
                  onClick={() => {
                    editHandler(row);
                  }}
                />
              )}
          </div>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      // button: true,
    },
  ];

  const initialValues: SearchFields = {
    form: formName ? 'Patient Management' : '',
    type: '',
    from_date: '',
    to_date: '',
    approved_status: '',
  };

  const {
    handleSubmit,
    handleChange,
    getFieldProps,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.form !== '') {
        s = s + `&heading=${values.form}`;
      }

      if (values.from_date !== '') {
        s = s + `&from_date=${values.from_date}`;
      }

      if (values.to_date !== '') {
        s = s + `&to_date=${values.to_date}`;
      }

      if (values.approved_status !== '') {
        s = s + `&approved_status=${values.approved_status}`;
      }

      formValue = '';
      setSearch(s);
      setPage('1');
      set_paginate_per_page(10);
      setFormName('');
    },
  });

  const handleFormReset = () => {
    formValue = '';
    setFormName('');
    resetForm();
  };

  // useEffect(() => {
  //   if (isLogbookEntriesLoading || !isLogbookEntriesSuccess) {
  //     toast.dismiss();
  //     toast.loading('Data Loading...');
  //   } else {
  //     toast.dismiss();
  //     toast.success('Data Load Successfully');
  //   }
  // }, [isLogbookEntriesLoading, isLogbookEntriesSuccess]);

  // useEffect(() => {
  //   if(form) {
  //     values.form = form;
  //   }
  // }, []);

  return (
    <div className='page-content'>
      {isLogbookEntriesLoading ? (
        <div className='overlay'>
          <img src={loader} alt='Loading' height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <div className='page-breadcrumb-wrapper'>
          <div>
            <BetterBreadcrumb
              title={'Trainee E-Logbook Management'}
              breadcrumbItem={[
                {
                  link: '/e-logbook/logbook-entry/add',
                  name: 'Logbook Entry',
                },
              ]}
            />
          </div>
          <div>
            <a
              type='button'
              rel='noreferrer'
              className='btn btn-link text-decoration-underline'
              href={
                store?.getState()?.auth?.user?.details?.subject?.subject_name?.toLowerCase() === 'medicine' ?
                  '/documents/Logbook Essential Information - Medicine.pdf'
                  : store?.getState()?.auth?.user?.details?.subject?.subject_name?.toLowerCase() === 'surgery' ?
                    '/documents/Logbook Essential Information - Surgery.pdf'
                    : store?.getState()?.auth?.user?.details?.subject?.subject_name?.toLowerCase() === 'paediatrics' ?
                      '/documents/Logbook Essential Information - Paediatrics.pdf'
                      : '/documents/Logbook Essential Information - Medicine.pdf'
              }
              target='_blank'>
              Essential Info
            </a>
            <Link to='/e-logbook/logbook-entry/add'
                  className='btn btn-success waves-effect waves-light'>
              Create New
            </Link>
          </div>
        </div>

        {/* <Row className='justify-content-between'>
          <Col md="4">
            <Card>
              <CardBody>
                <h5>Name: {store?.getState()?.auth?.user?.name}</h5>
                <h6> Supervisor:  {store?.getState()?.auth?.user?.details?.current_session_and_supervisor_info?.supervisor?.name}  </h6>
              </CardBody>
            </Card>
          </Col>
          <Col md="3" className='mt-3'>
            <a
              type='button'
              rel="noreferrer"
              className='btn btn-link font-size-14 text-decoration-underline'
              href={
                store?.getState()?.auth?.user?.details?.subject?.subject_name?.toLowerCase() === 'medicine' ?
                  '/documents/Logbook Essential Information - Medicine.pdf'
                  : store?.getState()?.auth?.user?.details?.subject?.subject_name?.toLowerCase() === 'surgery' ?
                    '/documents/Logbook Essential Information - Surgery.pdf'
                    : store?.getState()?.auth?.user?.details?.subject?.subject_name?.toLowerCase() === 'paediatrics' ?
                      '/documents/Logbook Essential Information - Paediatrics.pdf'
                      : ''
              }
              target='_blank'>
              Logbook Essential Information
            </a>
          </Col>
        </Row> */}

        <Card className='mb-3'>
          <CardBody>
            <Form
              className='custom-form'
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              onReset={(e) => {
                handleFormReset();
              }}
            >

              <div className='filter-wrapper'>
                <FormGroup>
                  <Label for='form'>Form</Label>
                  <InputField
                    type='select'
                    name='form'
                    placeholder=''
                    options={formOptions}
                    onChange={handleChange}
                  />
                </FormGroup>

                {/* <select
                          className="form-select"
                          placeholder=""
                          id='form'
                          name='form'
                        >
                          <option className="form-control" value={''}>
                            {'Select...'}
                          </option>
                          <option
                            className="form-control"
                            value={'Patient Management'}
                            selected
                          >
                            {'Patient Management'}
                          </option>
                          <option
                            className="form-control"
                            value={'Procedural and Interpretative Skills'}
                          >
                            {'Procedural and Interpretative Skills'}
                          </option>
                        </select> */}

                {/* <FormGroup>
                        <Label for="type">Type</Label>
                        <InputField
                          type="select"
                          placeholder=""
                          options={[]}
                          invalid={{ errors, touched }}
                          {...getFieldProps('type')}
                        />
                      </FormGroup> */}

                <FormGroup>
                  <Label for='from_date'>From Date</Label>
                  <InputField
                    type='date'
                    placeholder=''
                    invalid={{ errors, touched }}
                    {...getFieldProps('from_date')}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for='to_date'>To Date</Label>
                  <InputField
                    type='date'
                    placeholder=''
                    invalid={{ errors, touched }}
                    {...getFieldProps('to_date')}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for='status'>Status</Label>
                  <InputField
                    type='select'
                    placeholder=''
                    options={[
                      { label: 'Draft', value: 'Draft' },
                      { label: 'Approved', value: 'Approved' },
                      { label: 'Pending', value: 'Pending' },
                      { label: 'Rejected', value: 'Reject' },
                      { label: 'Correction', value: 'Correction' },
                    ]}
                    invalid={{ errors, touched }}
                    {...getFieldProps('approved_status')}
                  />
                </FormGroup>

                <div className='btn-column mb-3'>
                  <Button
                    color='danger'
                    type='reset'
                    text='Reset'
                    onClick={() => {
                      resetForm();
                      handleSubmit();
                    }}
                  />
                  <Button
                    type='button'
                    text='Search'
                    onClick={() => {
                      handleSubmit();
                    }}
                  />
                </div>
              </div>
            </Form>
            <DataTable
              columns={(store?.getState()?.auth?.user?.type == 'Trainee' ? columns1 : columns2)}
              data={
                !formName && logbookEntryList
                  ? logbookEntryList || []
                  : logbookEntryList?.filter(
                  (l) =>
                    JSON.parse(l?.details_json!)?.caseType === formName,
                ) || []
              }
              pagination
              paginationServer
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              // progressPending={isLogbookEntriesLoading}
              paginationTotalRows={logbookEntries?.data?.total}
              paginationPerPage={paginate_per_page}
              expandOnRowClicked
              highlightOnHover
              paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
              persistTableHead
            />
          </CardBody>
        </Card>

        <Modal
          onOpened={function() {
            toast.dismiss();
          }}
          isOpen={modalShow}
          scrollable={true}
          size={editFormActive ? 'xl' : 'lg'}
          toggle={() => {
            document.body.classList.add('no_padding');
          }}
          backdrop={'static'}
          modalTransition={{ timeout: 0 }}
          backdropTransition={{ timeout: 0 }}          
        >
          <div className='modal-header bg-light'>
            <h5 className='modal-title' id='staticBackdropLabel'>
              {editFormActive && 'Edit '}
              Logbook Details
            </h5>
            <button
              type='button'
              className='btn-close'
              onClick={() => {
                setModalShow(!modalShow);
              }}
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body' id='logbookEditAndViewModalId'>
            <Row>
              <Col>
                {editFormActive &&
                  logbookForEdit?.approved_status &&
                  (logbookForEdit?.approved_status.toLowerCase() === 'draft' ||
                    logbookForEdit?.approved_status.toLowerCase() === 'correction') && (
                    <EditDynamicForm
                      key={editCount}
                      logbookData={logbookForEdit}
                      setShowAddForm={setModalShow}
                    />
                  )}

                {!editFormActive && (
                  <>
                    <table className='table table-bordered table-striped table-sm text-capitalize'>
                      <tbody>
                      {Object.entries(logbookEntryDetails).map(
                        (entry: any) => {
                          let formLabel = entry[0]
                            .replace(/([A-Z])/g, ' $1')
                            .trim()
                            .replaceAll('_', ' ');
                          return (
                            ![
                              'entry_setup_master_id',
                              'competency_group',
                            ].includes(entry[0]) && (
                              <tr key={entry[0]}>
                                <th
                                  // className="bg-light"
                                  style={{ width: '25%' }}
                                  dangerouslySetInnerHTML={{
                                    __html: formLabel,
                                  }}
                                ></th>
                                <td>
                                  {typeof entry[1] === 'string' &&
                                  entry[1]?.toLowerCase()?.includes('base64') ? (
                                    <a
                                      className='btn btn-sm btn-outline-info'
                                      rel='noreferrer'
                                      target='_blank'
                                      onClick={(e) => {
                                        decodeBase64EncodeString(entry[1]);
                                      }}
                                    >
                                      View
                                    </a>
                                  ) : (
                                    entry[1]
                                  )}
                                </td>
                              </tr>
                            )
                          );
                        },
                      )}
                      <>
                        {logbookCompetencyGroup?.length > 0 && (
                          <tr>
                            <th
                              // className="bg-light"
                              style={{ width: '25%' }}
                              dangerouslySetInnerHTML={{
                                __html: 'Competency Group',
                              }}
                            ></th>
                            <td>
                              {logbookCompetencyGroup?.map((competency: LogbookCompetencyLevels) => {
                                return (
                                  <div key={competency?.skill_name}>
                                    {competency?.skill_name}{(competency?.level1 || competency?.level2 || competency?.level3 || competency?.level4) && ' ('}
                                    {competency?.level1
                                      ? competency?.level2 ||
                                      competency?.level3 ||
                                      competency?.level4
                                        ? 'Level 1, '
                                        : 'Level 1'
                                      : ''}
                                    {competency?.level2
                                      ? competency?.level3 || competency?.level4
                                        ? 'Level 2, '
                                        : 'Level 2'
                                      : ''}
                                    {competency?.level3
                                      ? competency?.level4
                                        ? 'Level 3, '
                                        : 'Level 3'
                                      : ''}
                                    {competency?.level4 ? 'Level 4' : ''}{(competency?.level1 || competency?.level2 || competency?.level3 || competency?.level4) && ')'}
                                  </div>
                                );
                              })}
                            </td>
                          </tr>
                        )}
                      </>
                      {(store?.getState()?.auth?.user?.type == 'Trainee' ?
                        <tr>
                          <th style={{ width: '25%' }}>
                            Status
                          </th>
                          <td>{approveStatus.toLowerCase() === 'reject' ? 'Rejected' : approveStatus}</td>
                        </tr> : '')}

                      {approveStatus.toLowerCase() === 'correction' ||
                      approveStatus.toLowerCase() === 'reject' ||
                      (approveStatus.toLowerCase() === 'approved' && note) ?
                        <tr>
                          <th style={{ width: '25%' }}>
                            {approveStatus.toLowerCase() === 'correction' ? 'Correction Note' :
                              approveStatus.toLowerCase() === 'reject' ? 'Rejection Note' :
                                (approveStatus.toLowerCase() === 'approved' && note) ? 'Approval Note' : ''}
                          </th>
                          <td>
                              <span className={approveStatus.toLowerCase() === 'approved' ? 'text-success' :
                                approveStatus.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}>
                                {note}
                              </span>
                          </td>
                        </tr> : null}
                      {/* <tr>
                          <th className="bg-light" style={{ width: '25%' }}>Note</th>
                          <td>{note}</td>
                        </tr> */}
                      </tbody>
                    </table>
                    <div className='col-sm-12'>
                      <div className='text-end mt-3'>
                        {logbookForEdit.approved_status &&
                          (logbookForEdit.approved_status.toLowerCase() ===
                            'draft' || logbookForEdit.approved_status.toLowerCase() ===
                            'correction') && (
                            <Button
                              size='md'
                              color='success'
                              type='button'
                              style={{ 'width': '120px' }}
                              text={`<span class='fas fa-edit' ></span> Edit`}
                              onClick={() => {
                                editHandler(logbookForEdit);
                              }}
                            />
                          )}
                        <Button
                          className='ms-2'
                          size='md'
                          color='danger'
                          type='button'
                          text={`<span class='fas fa-times' ></span> Close`}
                          onClick={() => {
                            setModalShow(!modalShow);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </Modal>

        <Modal
          onOpened={function() {
            toast.dismiss();
          }}
          isOpen={competencyGroupModalShow}
          scrollable={true}
          size='xl'
          toggle={() => {
            document.body.classList.add('no_padding');
          }}
          backdrop={'static'}
          modalTransition={{ timeout: 0 }}
          backdropTransition={{ timeout: 0 }}
        >
          <div className='modal-header'>
            <h5 className='modal-title' id='staticBackdropLabel'>
              Competency Group Details
            </h5>
            <button
              type='button'
              className='btn-close'
              onClick={() => {
                setCompetencyGroupModalShow(!competencyGroupModalShow);
              }}
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            <Row>
              <Col>
                <table className='table table-bordered border-dark text-capitalize'>
                  <tbody>
                  {logbookCompetencyGroup?.map(
                    (competency: LogbookCompetencyLevels) => {
                      return (
                        <tr key={competency?.id}>
                          <th
                            className='bg-light'
                            style={{ width: '25%' }}
                            dangerouslySetInnerHTML={{
                              __html: competency?.skill_name!,
                            }}
                          ></th>
                          <td>
                            {competency?.level1
                              ? competency?.level2 ||
                              competency?.level3 ||
                              competency?.level4
                                ? 'Level 1, '
                                : 'Level 1'
                              : ''}
                            {competency?.level2
                              ? competency?.level3 || competency?.level4
                                ? 'Level 2, '
                                : 'Level 2'
                              : ''}
                            {competency?.level3
                              ? competency?.level4
                                ? 'Level 3, '
                                : 'Level 3'
                              : ''}
                            {competency?.level4 ? 'Level 4' : ''}
                          </td>
                        </tr>
                      );
                    },
                  )}
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </Modal>
      </Container>
    </div>
  );
};

export default UpdatedLogbookEntryList;
