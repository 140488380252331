import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Col, Container, Form, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { getSelectFormatedData, setFromErrors } from 'src/helpers';
import * as url from 'src/helpers/url_helper';
import {
  useCreateByPostMutation,
  useGetByDataQuery,
  useGetDataQuery,
} from 'src/rtk/features/common/crudApi';
import * as Yup from 'yup';
import CertificateList from './CertificateSelectionList';
import RichTextEditor from './RichTextEditor';
import EditRichTextEditor from './EditRichTextEditor';
import { useGetCertificateListByPaginateQuery } from 'src/rtk/features/certificate/publishCertificateApi';

const initialValues: any = {
  applicable_for: '',
  fee: 0,
  code: '',
  name: '',
  type: '',
  // session: '',
  main_trainings: [],
  // subject_id: '',
  description: '',
  duration: '',
  duration_unit: '',
  certificate_ref_no: '',
  paid_course: '',
  // required_ind: true,
  status: true,
};

export const AddRTMDTraining = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [certificateModalShow, setCertificateModalShow] = useState<boolean>(false);
  const [selectedCertificate, setSelectedCertificate] = useState<CertificateList>(
    {} as CertificateList,
  );

  // console.log(id);
  const [initialContentEditor, setInitialContentEditor] = useState<string>('');
  const [reRenderState, setReRenderState] = useState<number>(1);

  let courseData: any = {
    queryUrl: url.RTMD_GET_COURSE_LISTS,
    formData: {
      type: 'main-training',
      subject_id: 'no-need',
    },
  };

  const {
    data: setupCourseListData,
    // isLoading: mainTrainingListLoading,
    // isSuccess: mainTrainingListLoadingSuccess,
    // error: mainTrainingListLoadingError,
  } = useGetByDataQuery(courseData);

  // const mainTrainingList: any = setupCourseListData?.data || [];

  const mainTrainingList: any = setupCourseListData?.data
    ? getSelectFormatedData(setupCourseListData?.data, 'id', 'name')
    : [];

  // const [
  //   updateByPatch,
  //   { isSuccess: updateSucess, isError: updateError, error: updateErrorData },
  // ] = useUpdateByPatchMutation();

  const [createByPost, { isSuccess, isError, error }] =
    useCreateByPostMutation();
  const err: CustomError = error as CustomError;

  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    resetForm,
    setErrors,
    handleChange,
    values,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      // subject_id: Yup.string().required('Please select a subject'),
      name: Yup.string().required('Training Title is required'),
      code: Yup.string().required('Code is required'),
      duration: Yup.string().required('Duration is required'),
      duration_unit: Yup.string().required('Unit is required'),
      paid_course: Yup.string().required('Is paid required'),
      // applicable_for: Yup.string().required('Applicable for is required'),
      // type: Yup.string().required('Type is required'),
      // session: Yup.string().required('Session is required'),
      // main_trainings: Yup.string().required('Main training is required'),
      // paid_course: Yup.string().required('Paid status is required'),
      // main_trainings: Yup.string().required('Main training is required'),
      certificate_ref_no: Yup.string().required('Certificate is required'),
      fee: Yup.number().when('paid_course', (paid_course, schema) => {
        if (paid_course && paid_course === 'Paid') {
          return schema.required('Fee/Amount is Required!').min(1);
        }
        return schema;
      }),
    }),

    onSubmit: (values) => {
      values['applicable_for'] = values['applicable_for'].toString();
      values['paid_course'] = values['paid_course'] === 'Paid' ? 1 : 0;
      values['code'] = values['type'] === 'Training' ? 'T-' + values['code'] : 'W-' + values['code'];

      if (id) {
        values['id'] = id;
      }
      if(selectedCertificate?.id){
        values['certificate_ref_no'] = selectedCertificate?.id;
      }
      else{
        values['certificate_ref_no'] = certificateListData?.data?.find((item: any) => item.id === values.certificate_ref_no)?.id;
      }

      let postData = {
        queryUrl: url.RTMD_Training_CREATE,
        formData: values,
      };
      createByPost(postData);
    },
  });

  if (id) {
    const { data: exam, isError: isExamFetchError } = useGetDataQuery(
      `/setup/v1/setup-exam-training-info/${id}`,
    );

    useEffect(() => {
      if (exam && !isExamFetchError) {
        let data: any = (exam as any).data;

        let applicable_for = data.training_for
          ? data.training_for.split(',')
          : [];

        setValues({
          code: data?.code?.replace('T-', '').replace('W-', '') || '',
          name: data?.name || '',
          type: data?.type || '',
          // session: data.session || '',
          main_trainings: [data?.exam_training_id],
          description: data?.description || '',
          applicable_for: applicable_for,
          duration: data?.duration || '',
          duration_unit: data?.duration_unit
            ? data.duration_unit.toLowerCase()
            : '',
          paid_course: data?.paid_course ? 'Paid' : 'Free',
          fee: data?.exam_tranning_fee?.exam_fee || 0,
          // required_ind: data.required_ind,
          status: data?.status,
          certificate_ref_no: data?.certificate_ref_no
        });
      }
    }, [exam, isExamFetchError]);
  }

  const page = 1;
  const paginate_per_page = 10;
  const search = '';
    
  const {
    data: certificateListData,
    error: certificateListDataError,
    isLoading: isCertificateListDataLoading,
    isError: isCertificateListDataError,
    isSuccess: isCertificateListDataSuccess,
  } = useGetCertificateListByPaginateQuery({
    page,
    paginate_per_page,
    search,
  });


  const mainTrainingSelect = (selectedValues: []) => {
    // console.log(selectedValues);
    values['main_trainings'] = [];
    selectedValues.map((item: any) => {
      values['main_trainings'].push(item['value']);
    });
  };

  // useEffect(() => {
  //   if (updateSucess) {
  //     resetForm();
  //     toast.success('Training update successfully');
  //     history.push('/rtmd/training/');
  //   }
  //   if (updateError) {
  //     toast.error(err?.message || 'Something went wrong');
  //     setFromErrors(err.data, setErrors);
  //   }
  // }, [updateSucess, updateError]);

  useEffect(() => {
    if(selectedCertificate?.id) {
      setFieldValue('certificate_ref_no', selectedCertificate?.id);
    }
  },[selectedCertificate]);
  
  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Training created successfully');
      history.push('/rtmd/training/');
    }
    if (isError) {
      toast.dismiss();
      setFromErrors(err.data, setErrors);
      if (err.data?.message) {
        toast.error(err.data?.message || 'Something went wrong');
      } else {
        toast.error(err?.message || 'Something went wrong');
      }
      setFieldValue('code', values?.code?.replace('T-', '').replace('W-', ''));
    }
  }, [isSuccess, isError]);

  const handleCertificateSelection = (e: any) => {
    setCertificateModalShow(true);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={
            id
              ? 'Training/Workshop Setup Update '
              : 'Training/Workshop Setup Create '
          }
          breadcrumbItem={[
            {
              link: '/dashboard',
              name: 'Dashboard',
            },
            {
              link: '/rtmd/training/',
              name: 'Training/Workshop Setup',
            },
          ]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-12 col-sm-12 ">
            <div className="card">
              <div className="d-flex card-header">
                <h3 className="card-title mb-0 flex-grow-1">
                  <i className="fa fa-plus me-2" />
                  Training/Workshop Setup {id ? 'Update' : 'Create'}
                </h3>
                <div className="flex-shrink-0 align-self-end">
                  <Link
                    to="/rtmd/training/"
                    className="btn btn-success waves-effect waves-light"
                  >
                    <i className="fas fa-arrow-left me-2" /> Back
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <Form
                  autoComplete="off"
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <Row className="g-0">
                    <div className="mb-3 col-md-6 col-sm-12 px-1">
                      <h6 className="required-field">Program Type:</h6>
                      <FormGroup check inline>
                        <Input
                          type="radio"
                          value={'Training'}
                          name="type"
                          checked={values['type'] === 'Training'}
                          onChange={handleChange}
                          id={'typeTraining'}
                        />
                        <Label check htmlFor={'typeTraining'}>
                          Training
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input
                          type="radio"
                          value={'Workshop'}
                          checked={values['type'] === 'Workshop'}
                          name="type"
                          onChange={handleChange}
                          id={'typeWorkshop'}
                        />
                        <Label check htmlFor={'typeWorkshop'}>
                          Workshop
                        </Label>
                      </FormGroup>
                    </div>

                    <div className="mb-3 col-md-6 col-sm-12 px-1">
                      <h6 className="required-field">Applicable for: </h6>
                      <FormGroup check inline>
                        <Input
                          type="checkbox"
                          value={'Trainee'}
                          checked={values['applicable_for'].includes('Trainee')}
                          id={'applicableForTrainee'}
                          name="applicable_for"
                          onChange={handleChange}
                        />
                        <Label check htmlFor={'applicableForTrainee'}>
                          Trainee
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input
                          type="checkbox"
                          value={'Supervisor'}
                          checked={values['applicable_for'].includes(
                            'Supervisor',
                          )}
                          name="applicable_for"
                          onChange={handleChange}
                          id={'applicableForTrainer'}
                        />
                        <Label check htmlFor={'applicableForTrainer'}>
                          Supervisor
                        </Label>
                      </FormGroup>

                      <FormGroup check inline>
                        <Input
                          type="checkbox"
                          value={'Fellow'}
                          name="applicable_for"
                          checked={values['applicable_for'].includes('Fellow')}
                          onChange={handleChange}
                          id={'applicableForFellow'}
                        />
                        <Label check htmlFor={'applicableForFellow'}>
                          Fellow
                        </Label>
                      </FormGroup>

                      <FormGroup check inline>
                        <Input
                          type="checkbox"
                          value={'Other'}
                          name="applicable_for"
                          checked={values['applicable_for'].includes('Other')}
                          onChange={handleChange}
                          id={'applicableForOther'}
                        />
                        <Label check htmlFor={'applicableForOther'}>
                          Other
                        </Label>
                      </FormGroup>
                    </div>

                    <FormGroup className="mb-3 col-md-6 col-sm-12 px-1">
                      <Label className="required-field" for="name">
                        Training/Workshop Title
                      </Label>
                      <InputField
                        type="text"
                        placeholder="Training/Workshop Title"
                        invalid={{ errors, touched }}
                        {...getFieldProps('name')}
                      />
                    </FormGroup>

                    <FormGroup className="mb-3 col-md-4 col-sm-12 px-1">
                      <Label className="required-field" for="code">
                        Code
                      </Label>
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">
                          {values['type'] === 'Training' ? 'T-' : 'W-'}
                        </span>
                        <InputField
                          type="text"
                          placeholder="Code"
                          invalid={{ errors, touched }}
                          {...getFieldProps('code')}
                        />
                      </div>
                    </FormGroup>

                    {/* {id === undefined && (
                      <FormGroup className="mb-3 col-md-6 col-sm-12 px-1">
                        <Label for="session">Main Training</Label>
                        <Select
                          isMulti
                          name="main_trainings"
                          options={mainTrainingList}
                          // value={mainTrainingList.filter(function (
                          //   option: any,
                          // ) {
                          //   return option.value === values['main_trainings'];
                          // })}
                          feedbackInvalid={{ errors, touched }}
                          onChange={(value: any) => {
                            mainTrainingSelect(value);
                          }}
                          // {...getFieldProps('main_trainings')}
                        />
                      </FormGroup>
                    )} */}

                    {/* <FormGroup className="mb-3 col-md-6 col-sm-12 px-1">
                      <Label for="session">Session</Label>
                      <InputField
                        type="select"
                        placeholder="Session"
                        options={getSelectFormatedData([
                          'January-June',
                          'July-December',
                        ])}
                        invalid={{ errors, touched }}
                        {...getFieldProps('session')}
                      />
                    </FormGroup> */}

                    {/* <FormGroup className="mb-3 col-md-6 col-sm-12">
                      <Label for="type">Exam Type</Label>
                      <InputField
                        type="select"
                        placeholder="Exam Type"
                        invalid={{ errors, touched }}
                        {...getFieldProps('type')}
                        options={typeOptions}
                      />
                    </FormGroup> */}

                    {/* <FormGroup className="mb-3 col-md-6 col-sm-12 px-1">
                      <Label for="subject_id">Select a subject</Label>
                      <InputField
                        type="select"
                        name="subject_id"
                        placeholder="Select a subject"
                        invalid={{ errors, touched }}
                        options={subjectOptions}
                        value={values.subject_id}
                        onChange={handleChange}
                      />
                    </FormGroup> */}

                    <FormGroup className="mb-3 col-md-3 col-sm-6 px-1">
                      <Label className="required-field" for="duration">
                        Duration
                      </Label>
                      <InputField
                        min={1}
                        step={1}
                        type="number"
                        placeholder="Duration"
                        invalid={{ errors, touched }}
                        {...getFieldProps('duration')}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3 col-md-3 col-sm-6 px-1">
                      <Label className="required-field" for="duration_unit">
                        Unit
                      </Label>
                      <InputField
                        type="select"
                        options={[
                          { value: 'day', label: 'Day(s)' },
                          { value: 'week', label: 'Week(s)' },
                          { value: 'month', label: 'Month(s)' },
                          { value: 'year', label: 'Year(s)' },
                        ]}
                        placeholder="Select duration unit"
                        invalid={{ errors, touched }}
                        {...getFieldProps('duration_unit')}
                      />
                    </FormGroup>

                    <FormGroup className="mb-3 col-md-6 col-sm-6 px-1">
                      <Label className="form-label required-field">
                        Certificate Title
                      </Label>
                      <Button
                        type="button"
                        text="Select Certificate"
                        className="btn btn-info btn-sm ms-2"
                        onClick={(e) => {
                          handleCertificateSelection(e);
                        }}
                      ></Button>
                      <InputField
                        type="text"
                        placeholder="Select Certificate"
                        name="certificate_ref_no"
                        value={selectedCertificate?.notice_subject ?? certificateListData?.data?.find((item: any) => item.id === values.certificate_ref_no)?.notice_subject}
                        invalid={{ errors, touched }}
                        readOnly
                      />
                    </FormGroup>

                    <FormGroup className="mb-3 col-md-12 col-sm-12 px-1">
                      <Label for="description">
                        Training Details/Instruction
                      </Label>
                      {/* <InputField
                        type="textarea"
                        placeholder="Training Details/Instruction"
                        invalid={{ errors, touched }}
                        {...getFieldProps('description')}
                      /> */}
                      {(id && values !== initialValues) ? 
                      <EditRichTextEditor
                        formikState={values}
                        initialContentEditor={initialContentEditor}
                        setInitialContentEditor={setInitialContentEditor}
                        key={reRenderState}
                      />
                      :
                      <RichTextEditor
                        formikState={values}
                        initialContentEditor={initialContentEditor}
                        setInitialContentEditor={setInitialContentEditor}
                        key={reRenderState}
                      />
                      }

                    </FormGroup>

                    <div className="col-sm-3">
                      <h6>Active Status</h6>
                      <FormGroup check className="mb-3">
                        <Input
                          type="checkbox"
                          role="check"
                          id={'active_status_option'}
                          checked={values.status}
                          {...getFieldProps('status')}
                        />
                        <Label htmlFor={'active_status_option'} check>
                          Active
                        </Label>
                      </FormGroup>
                    </div>

                    <div className="col-sm-3 mb-3">
                      <div className="mb-3 col-md-6 col-sm-12 px-1">
                        <h6 className="required-field">Is Paid?</h6>
                        <FormGroup check inline>
                          <Input
                            type="radio"
                            value={'Paid'}
                            name="paid_course"
                            checked={values['paid_course'] === 'Paid'}
                            onChange={handleChange}
                            id={'paid_course_paid'}
                          />
                          <Label check htmlFor={'paid_course_paid'}>
                            Paid
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            type="radio"
                            value={'Free'}
                            checked={values['paid_course'] === 'Free'}
                            name="paid_course"
                            onChange={handleChange}
                            id={'paid_course_free'}
                          />
                          <Label check htmlFor={'paid_course_free'}>
                            Free
                          </Label>
                        </FormGroup>
                        {errors.paid_course && touched.paid_course ? (
                          <div className="text-danger" style={{ fontSize: '12px' }}>Please Select one</div>
                        ) : null}
                      </div>
                    </div>

                    {values['paid_course'] === 'Paid' && (
                      <FormGroup className="mb-3 col-md-6 col-sm-12 px-1">
                        <Label className="required-field" for="fee">
                          Fee/Amount
                        </Label>
                        <InputField
                          min={0}
                          type="number"
                          placeholder="Fee"
                          invalid={{ errors, touched }}                          
                          {...getFieldProps('fee')}
                        />
                      </FormGroup>
                    )}

                    {/* <div className="col-sm-12">
                      <FormGroup check className="mb-3">
                        <Label check>
                          <Input
                            type="checkbox"
                            {...getFieldProps('required_ind')}
                            checked={values.required_ind}
                          />{' '}
                          Required
                        </Label>
                      </FormGroup>
                    </div> */}

                    <div className="text-left">
                      <Button
                        className="w-25"
                        type="submit"
                        text={id ? 'Update' : 'Submit'}
                      />
                    </div>
                  </Row>
                </Form>
              </div>
            </div>

            <Modal
              onOpened={function () {
                toast.dismiss();
              }}
              isOpen={certificateModalShow}
              scrollable={true}              
              fullscreen={true}
              toggle={() => {
                document.body.classList.add('no_padding');
              }}
              backdrop={'static'}
              modalTransition={{ timeout: 0 }}
              backdropTransition={{ timeout: 0 }}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Select Certificate
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setCertificateModalShow(!certificateModalShow);
                  }}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <Row>
                  <Col>
                    <CertificateList
                      selectedCertificate={selectedCertificate}
                      setSelectedCertificate={setSelectedCertificate}
                      setModalShow={setCertificateModalShow}
                    />
                  </Col>
                </Row>
              </div>
            </Modal>
          </div>
        </div>
      </Container>
    </div>
  );
};
