import { useFormik } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';

const TrainingList = () => {
  const trainingListSearchForm = useFormik({
    initialValues: {
      fromDate: Date.now().toString(),
      toDate: Date.now().toString(),
      searchTitle: '',
    },

    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <h6>Training List</h6>

        <Col sm={12} md={12} lg={12}>
          <Form
            onSubmit={trainingListSearchForm.handleSubmit}
            encType="multipart/form-data"
          >
            <Row>
              <Col className="border-right p-2 mr-2" sm={3} md={3} lg={3}></Col>

              <Col className="border-right p-2 mr-2" sm={3} md={3} lg={3}>
                <FormGroup className="mb-5">
                  <div className="mb-3">
                    <Label className="form-label">From</Label>
                    <Input
                      name="fromDate"
                      className="form-control"
                      placeholder=""
                      type="date"
                      onChange={trainingListSearchForm.handleChange}
                      onBlur={trainingListSearchForm.handleBlur}
                      value={trainingListSearchForm.values.fromDate}
                    />
                  </div>
                </FormGroup>
              </Col>

              <Col className="border-right p-2 mr-2" sm={3} md={3} lg={3}>
                <FormGroup className="mb-5">
                  <div className="mb-3">
                    <Label className="form-label">To</Label>
                    <Input
                      name="toDate"
                      className="form-control"
                      placeholder=""
                      type="date"
                      onChange={trainingListSearchForm.handleChange}
                      onBlur={trainingListSearchForm.handleBlur}
                      value={trainingListSearchForm.values.toDate}
                    />
                  </div>
                </FormGroup>
              </Col>

              <Col className="border-right p-2 mr-2" sm={3} md={3} lg={3}>
                <FormGroup className="mb-5">
                  <div className="mb-3">
                    <Label className="form-label">Search</Label>
                    <Input
                      name="searchTitle"
                      className="form-control"
                      placeholder=""
                      type="search"
                      onChange={trainingListSearchForm.handleChange}
                      onBlur={trainingListSearchForm.handleBlur}
                      value={trainingListSearchForm.values.searchTitle}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>

        <Col sm={12} md={12} lg={12}>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <Table bordered>
                <thead>
                  <tr>
                    <th>SL. No</th>
                    <th>Title</th>
                    <th>Applicable For</th>
                    <th>Type</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Duration</th>
                    <th>Creation Date</th>
                    <th>Decision</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>OT Training</td>
                    <td>Some Application</td>
                    <td>Type 1</td>
                    <td>23/07/22</td>
                    <td>12/09/22</td>
                    <td>2 months</td>
                    <td>25/07/22</td>
                    <td>
                      <Button className="me-4 btn-success">
                        <i className="fa-solid fa-check"></i>
                      </Button>
                      <Button className="btn-danger">
                        <i className="fa-sharp fa-solid fa-xmark"></i>
                      </Button>
                    </td>
                    <td
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <Button className="me-4 btn-dark">
                        <i className="fa-solid fa-eye"></i>
                      </Button>
                      <Link to="/training/edit">
                        <Button className="me-4">
                          <i className="fa-solid fa-pen"></i>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>OT Training</td>
                    <td>Some Application</td>
                    <td>Type 1</td>
                    <td>23/07/22</td>
                    <td>12/09/22</td>
                    <td>2 months</td>
                    <td>25/07/22</td>
                    <td>
                      <Button className="me-4 btn-success">
                        <i className="fa-solid fa-check"></i>
                      </Button>
                      <Button className="btn-danger">
                        <i className="fa-sharp fa-solid fa-xmark"></i>
                      </Button>
                    </td>
                    <td
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <Button className="me-4 btn-dark">
                        <i className="fa-solid fa-eye"></i>
                      </Button>
                      <Link to="/training/edit">
                        <Button className="me-4">
                          <i className="fa-solid fa-pen"></i>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>OT Training</td>
                    <td>Some Application</td>
                    <td>Type 1</td>
                    <td>23/07/22</td>
                    <td>12/09/22</td>
                    <td>2 months</td>
                    <td>25/07/22</td>
                    <td>
                      <Button className="me-4 btn-success">
                        <i className="fa-solid fa-check"></i>
                      </Button>
                      <Button className="btn-danger">
                        <i className="fa-sharp fa-solid fa-xmark"></i>
                      </Button>
                    </td>
                    <td
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <Button className="me-4 btn-dark">
                        <i className="fa-solid fa-eye"></i>
                      </Button>
                      <Link to="/training/edit">
                        <Button className="me-4">
                          <i className="fa-solid fa-pen"></i>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default TrainingList;
