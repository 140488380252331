import html2pdf from 'html2pdf.js';
import moment from 'moment';
import { useEffect } from 'react';

interface Props {
  data: any;
  setGuideCoGuidePrintId: any;
  setSubmitting?: any;
}

export const GuideCoGuideMoneyReceiptPdf = (props: Props) => {
  const { data, setGuideCoGuidePrintId, setSubmitting } = props;
  const image1 = new Image();
  image1.src = '/images/apple-icon-180x180.png';
  const image2 = new Image();
  console.log(data);
  const html =`
    <html>
      <style>
        .print-container{
          padding: 0 15px;
        }
        
        td {
          border: 1px solid;
          padding-left: 5px;
        }

        th {
          text-align: center;
          border: 1px solid;    
        }

        table {
          border: 1px solid;
        }

        .wide-column {
          width: 500px;
          background-color: powderblue;
        }
        
        .flex-container {
          display: flex;
          flex-wrap: nowrap;
        }

        .flex-container > div {
          width: 300px;
          text-align: center;
        }
        .fontName {
          font-family: "Times New Roman", Times, serif;
          line-height: 1.5;
        }        

        .box {
          width: 320px;
          height: 50px;
          padding: 10px;
          border: 1px solid gray;
          margin-left: 300px;
        }

        b {
          font-family: 'Times-Bold'; 
        }
        p {
          font-size: 14px
        }

        .align-right {
          text-align: right;
        }
      </style>
      <body>

        <div class="fontName">
          <div style="text-align: center; margin-bottom: 40px; margin-left: 70px">
            <h5>BANGLADESH COLLEGE OF PHYSICIANS AND SURGEONS (BCPS)</h5>
            <h6>67, Shaheed Tajuddin Ahmed Sarani, Mohakhali, Dhaka-1212</h6>
          </div>
          <div style="text-align: center; margin-bottom: 20px;">
            <h4 class="fontName">MONEY RECEIPT (DSA)</h4>
          </div>
          <div style="text-align: center; margin-bottom: 20px;">
            <b class="fontName">DSA bill for Research/Dissertation/Thesis Work.</b>
          </div>
          <div style="margin-bottom: 20px">
            <div><b>Name of the ${data?.research_grant_info?.role}: </b>${data?.registration?.personal_info?.full_name ?? ''}</div>
            <div><b>Designation: </b>${data?.registration?.current_employment_info?.designation ?? ''}</div>
            <div><b>Address: </b>
                ${data?.registration?.address_infos[1]?.address_line_1}
              , ${data?.registration?.address_infos[1]?.police_station}
              , ${data?.registration?.address_infos[1]?.district}
              , ${data?.registration?.address_infos[1]?.division}
            </div>
          </div>
          <div style="margin-bottom: 20px;">
            <p>
             Received Tk. ${data?.amount}/-(${data?.amount_in_word}) only after deduction of 10% sourse tax Tk-${(data?.amount * 0.1)}/-
             only deducted by BCPS as DSA for my involvements in .............. of BCPS during
             ${moment(data?.from_date).format("DD-MMM-YYYY")} to ${moment(data?.to_date).format("DD-MMM-YYYY")}  for -${data?.day_count}-days @ Tk.-${data?.rate}/- from BCPS.
            </p>
          </div>
          <div style="margin: 50px 0px; text-align: right;">
            <p>Seal & Signature of recipient</p>
          </div>
          <div style="margin-bottom: 20px;">
            <p>
              This is to certify that the above mentioned Researcher/Dissertation/Thesis
              work has been completed properly during the specific time frame and has been 
              accepted by BCPS. According to the agreement and budget breakup his/her claimed 
              research bill taka-${data?.amount}/-(${data?.amount_in_word}) may be given.
            </p>
          </div>
          <div style="margin: 50px 0px">
            <div class="flex-container">
              <div>Concern Officer of RTM</div>
              <div>Deputy Director (Admin).</div>
              <div>Director Admin.</div>
              <div>Director RTM</div>
            </div>
          </div>
          <div>
            <p>Approved and passed Tk.-- ${data?.amount}/-(${data?.amount_in_word}) 
            only and transferred by BFTN/Chq. No: ${data?.research_grant_info?.bank_info?.account_no}
            Date: ${moment(data?.research_grant_info?.bank_info?.created_at).format("DD-MMM-YYYY")}
            from ${data?.research_grant_info?.bank_info?.bank_name}
            Bank ${data?.research_grant_info?.bank_info?.branch_name} Branch.</p>
          </div>
          <div class="flex-container" style="margin: 50px 0px">
            <div>Account Officer</div>
            <div>Deputy Chief Accounts Officer</div>
            <div>Secratary</div>
            <div>Treasurer</div>
          </div>
        </div>
      </body>
    </html>`
    ;

  useEffect(() => {
    setGuideCoGuidePrintId('');
    setSubmitting(false);
  }, []);

  const options = {
    margin: [15, 15, 15, 15],
    image: { type: 'png', quality: 0.98 },
    html2canvas: { scale: 2, useCORS: true },
  };

  html2pdf()
    .from(html)
    .set(options)
    .toPdf()
    .get('pdf')
    .then(function (pdf: any) {
      var totalPages = pdf.internal.getNumberOfPages();

      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10);
        pdf.setTextColor(150);
        pdf.addImage(image1, "PNG", 15, 15, 25, 25);
      }

      window.open(pdf.output('bloburl'), '_blank');
    });

  return null;
};
