import { LogbookEntrySetupDetailsRecords } from "src/pages/Setup/LogbookEntrySetupDetailsRecord";
import { AddLogbookEntrySetupDetailsRecord } from "src/pages/Setup/LogbookEntrySetupDetailsRecord/AddLogbookEntrySetupDetailsRecord";
import { EditLogbookEntrySetupDetailsRecord } from "src/pages/Setup/LogbookEntrySetupDetailsRecord/EditLogbookEntrySetupDetailsRecord";

export const logBookEntrySetupDetailsRecordRoutes = [
  // logbook entry setup details record
  {
    path: '/setup/logbook-entry-setup-details-record/',
    component: LogbookEntrySetupDetailsRecords,
  },
  {
    path: '/setup/logbook-entry-setup-details-record/update/:id',
    component: EditLogbookEntrySetupDetailsRecord,
  },
  {
    path: '/setup/logbook-entry-setup-details-record/add',
    component: AddLogbookEntrySetupDetailsRecord,
  },
];
