import React from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    FormGroup,
    Label,
    Row
} from 'reactstrap';

interface Props {
    reviewerComments?: DissertationReviewerCommentListResponse;
}

const FirstReviewerComments = (props: Props) => {
    const { reviewerComments } = props;

    return (
        <>
            <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="p-4">
                                    <React.Fragment>
                                        <Row>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">1. Background Information</Label>
                                                    <br />
                                                    {reviewerComments?.first_reviewer_background_information ? <FormGroup>
                                                        <Label className='me-5'>
                                                            Adequate Background Information {reviewerComments?.first_reviewer_background_information === 'Accepted' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>

                                                        <Label>
                                                            Need Changes (Give Comment) {reviewerComments?.first_reviewer_background_information === 'Correction' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>
                                                    </FormGroup> : <FormGroup><Label>N/A</Label></FormGroup>}
                                                </div>
                                            </Col>

                                            {reviewerComments?.first_reviewer_background_information === 'Correction' ?
                                                (<Col sm={10}>
                                                    <div className="mb-3">
                                                        <Label

                                                            className="form-Label"
                                                        >
                                                            Comment
                                                        </Label>
                                                        <p>{reviewerComments?.first_reviewer_background_information_comment}</p>
                                                    </div>
                                                </Col>) : null}
                                        </Row>

                                        <Row className='mt-4'>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">2. Methodology</Label>
                                                    <br />
                                                    {reviewerComments?.first_reviewer_methodology ? <FormGroup>
                                                        <Label className='me-5'>
                                                            Adequately Described {reviewerComments?.first_reviewer_methodology === 'Accepted' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>

                                                        <Label>
                                                            Need Changes (Give Comment) {reviewerComments?.first_reviewer_methodology === 'Correction' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>
                                                    </FormGroup> : <FormGroup><Label>N/A</Label></FormGroup>}
                                                </div>
                                            </Col>

                                            {reviewerComments?.first_reviewer_methodology === 'Correction' ?
                                                (<Col sm={10}>
                                                    <div className="mb-3">
                                                        <Label
                                                            className="form-Label"
                                                        >
                                                            Comment
                                                        </Label>
                                                        <p>{reviewerComments?.first_reviewer_methodology_comment}</p>
                                                    </div>
                                                </Col>) : null}
                                        </Row>

                                        <Row className='mt-4'>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">3. Overall Study Design</Label>
                                                    <br />
                                                    {reviewerComments?.first_reviewer_overall_study_design ? <FormGroup>
                                                        <Label className='me-5'>
                                                            Acceptable {reviewerComments?.first_reviewer_overall_study_design === 'Accepted' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>

                                                        <Label>
                                                            Need Changes (Give Comment) {reviewerComments?.first_reviewer_overall_study_design === 'Correction' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>
                                                    </FormGroup> : <FormGroup><Label>N/A</Label></FormGroup>}
                                                </div>
                                            </Col>

                                            {reviewerComments?.first_reviewer_overall_study_design === 'Correction' ?
                                                (<Col sm={10}>
                                                    <div className="mb-3">
                                                        <Label
                                                            className="form-Label"
                                                        >
                                                            Comment
                                                        </Label>
                                                        <p>{reviewerComments?.first_reviewer_overall_study_design_comment}</p>
                                                    </div>
                                                </Col>) : null}
                                        </Row>

                                        <Row className='mt-4'>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">4. Result</Label>
                                                    <br />
                                                    {reviewerComments?.first_reviewer_result ? <FormGroup>
                                                        <Label className='me-5'>
                                                            Well Presented {reviewerComments?.first_reviewer_result === 'Accepted' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>

                                                        <Label>
                                                            Need Changes (Give Comment) {reviewerComments?.first_reviewer_result === 'Correction' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>
                                                    </FormGroup> : <FormGroup><Label>N/A</Label></FormGroup>}
                                                </div>
                                            </Col>

                                            {reviewerComments?.first_reviewer_result === 'Correction' ?
                                                (<Col sm={10}>
                                                    <div className="mb-3">
                                                        <Label
                                                            className="form-Label"
                                                        >
                                                            Comment
                                                        </Label>
                                                        <p>{reviewerComments?.first_reviewer_result_comment}</p>
                                                    </div>
                                                </Col>) : null}
                                        </Row>

                                        <Row className='mt-4'>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">5. Table, Photograph and Graphs</Label>
                                                    <br />
                                                    {reviewerComments?.first_reviewer_table_photo_and_graphs ? <FormGroup>
                                                        <Label className='me-5'>
                                                            Placed Accordingly {reviewerComments?.first_reviewer_table_photo_and_graphs === 'Accepted' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>

                                                        <Label>
                                                            Need Changes (Give Comment) {reviewerComments?.first_reviewer_table_photo_and_graphs === 'Correction' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>
                                                    </FormGroup> : <FormGroup><Label>N/A</Label></FormGroup>}
                                                </div>
                                            </Col>

                                            {reviewerComments?.first_reviewer_table_photo_and_graphs === 'Correction' ?
                                                (<Col sm={10}>
                                                    <div className="mb-3">
                                                        <Label
                                                            className="form-Label"
                                                        >
                                                            Comment
                                                        </Label>
                                                        <p>{reviewerComments?.first_reviewer_table_photo_and_graphs_comment}</p>
                                                    </div>
                                                </Col>) : null}
                                        </Row>

                                        <Row className='mt-4'>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">6. Statistical Assessment</Label>
                                                    <br />
                                                    {reviewerComments?.first_reviewer_statistical_assessment ? <FormGroup>
                                                        <Label className='me-5'>
                                                            Mentioned Clearly {reviewerComments?.first_reviewer_statistical_assessment === 'Accepted' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>

                                                        <Label>
                                                            Need Changes (Give Comment) {reviewerComments?.first_reviewer_statistical_assessment === 'Correction' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>
                                                    </FormGroup> : <FormGroup><Label>N/A</Label></FormGroup>}
                                                </div>
                                            </Col>

                                            {reviewerComments?.first_reviewer_statistical_assessment === 'Correction' ?
                                                (<Col sm={10}>
                                                    <div className="mb-3">
                                                        <Label
                                                            className="form-Label"
                                                        >
                                                            Comment
                                                        </Label>
                                                        <p>{reviewerComments?.first_reviewer_statistical_assessment_comment}</p>
                                                    </div>
                                                </Col>) : null}
                                        </Row>

                                        <Row className='mt-4'>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">7. Discussion</Label>
                                                    <br />
                                                    {reviewerComments?.first_reviewer_discussion ? <FormGroup>
                                                        <Label className='me-5'>
                                                            Described Adequately {reviewerComments?.first_reviewer_discussion === 'Accepted' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>

                                                        <Label>
                                                            Need Changes (Give Comment) {reviewerComments?.first_reviewer_discussion === 'Correction' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>
                                                    </FormGroup> : <FormGroup><Label>N/A</Label></FormGroup>}
                                                </div>
                                            </Col>

                                            {reviewerComments?.first_reviewer_discussion === 'Correction' ?
                                                (<Col sm={10}>
                                                    <div className="mb-3">
                                                        <Label
                                                            className="form-Label"
                                                        >
                                                            Comment
                                                        </Label>
                                                        <p>{reviewerComments?.first_reviewer_discussion_comment}</p>
                                                    </div>
                                                </Col>) : null}
                                        </Row>

                                        <Row className='mt-4'>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">8. Conclusion and Recommendation</Label>
                                                    <br />
                                                    {reviewerComments?.first_reviewer_conclusion_and_recommendation ? <FormGroup>
                                                        <Label className='me-5'>
                                                            Mentioned Clearly {reviewerComments?.first_reviewer_conclusion_and_recommendation === 'Accepted' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>

                                                        <Label>
                                                            Not Acceptable {reviewerComments?.first_reviewer_conclusion_and_recommendation === 'Correction' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>
                                                    </FormGroup> : <FormGroup><Label>N/A</Label></FormGroup>}
                                                </div>
                                            </Col>

                                            {reviewerComments?.first_reviewer_conclusion_and_recommendation === 'Correction' ? (<Col sm={10}>
                                                <div className="mb-3">
                                                    <Label
                                                        className="form-Label"
                                                    >
                                                        Comment
                                                    </Label>
                                                    <p>{reviewerComments?.first_reviewer_conclusion_and_recommendation_comment}</p>
                                                </div>
                                            </Col>) : null}
                                        </Row>

                                        <Row className='mt-4'>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">9. References</Label>
                                                    <br />
                                                    {reviewerComments?.first_reviewer_references ? <FormGroup>
                                                        <Label className='me-5'>
                                                            Cited as per Guideline of the Manual {reviewerComments?.first_reviewer_references === 'Accepted' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>

                                                        <Label>
                                                            Needs Corrections (Mark the places of improper citation) {reviewerComments?.first_reviewer_references === 'Correction' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>
                                                    </FormGroup> : <FormGroup><Label>N/A</Label></FormGroup>}
                                                </div>
                                            </Col>

                                            {reviewerComments?.first_reviewer_references === 'Correction' ? (<Col sm={10}>
                                                <div className="mb-3">
                                                    <Label
                                                        className="form-Label"
                                                    >
                                                        Comment
                                                    </Label>
                                                    <p>{reviewerComments?.first_reviewer_references_comment}</p>
                                                </div>
                                            </Col>) : null}
                                        </Row>

                                        <Row className='mt-4'>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">10. Ethical Issues</Label>
                                                    <br />
                                                    {reviewerComments?.first_reviewer_ethical_issues ? <FormGroup>
                                                        <Label className='me-5'>
                                                            Properly Addressed {reviewerComments?.first_reviewer_ethical_issues === 'Properly Addressed' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>

                                                        <Label className='me-5'>
                                                            Not Adequately Addressed {reviewerComments?.first_reviewer_ethical_issues === 'Not Adequately Addressed' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>

                                                        <Label>
                                                            Not Addressed {reviewerComments?.first_reviewer_ethical_issues === 'Not Addressed' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>
                                                    </FormGroup> : <FormGroup><Label>N/A</Label></FormGroup>}
                                                </div>
                                            </Col>

                                            <Col sm={10}>
                                                <div className="mb-3">
                                                    <Label
                                                        className="form-Label"
                                                    >
                                                        Comment
                                                    </Label>
                                                    <p>{reviewerComments?.first_reviewer_ethical_issues_comment ?? '-'}</p>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className='mt-4'>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">11. Final Comment</Label>
                                                    <br />
                                                    {reviewerComments?.first_reviewer_final ? <FormGroup>
                                                        <Label className='me-5'>
                                                            Acceptable {reviewerComments?.first_reviewer_final === 'Acceptable' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>

                                                        <Label className='me-5'>
                                                            Acceptable After Correction {reviewerComments?.first_reviewer_final === 'Acceptable After Correction' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>

                                                        <Label>
                                                            Not Acceptable {reviewerComments?.first_reviewer_final === 'Not Acceptable' ? <i className="fa-sharp fa-solid fa-square-check fa-lg"></i> : null}
                                                        </Label>
                                                    </FormGroup> : <FormGroup><Label>N/A</Label></FormGroup>}
                                                </div>
                                            </Col>

                                            <Col sm={10}>
                                                <div className="mb-3">
                                                    <Label
                                                        className="form-Label"
                                                    >
                                                        Comment
                                                    </Label>
                                                    <p>{reviewerComments?.first_reviewer_final_comment ?? '-'}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        </>
    );
};

export default FirstReviewerComments;
