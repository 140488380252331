import React from 'react';
import { Table } from 'reactstrap';
import { Button } from 'src/components';
import { useHistory } from 'react-router-dom';

interface TableProps {
  lookupList: LookupInfo[];
  setActiveLookup: (lookup: string) => void;
  setUuid: (uuid: string) => void;
}

const DefaultTable = (props: TableProps) => {
  const { lookupList, setActiveLookup, setUuid } = props;
  const history = useHistory();

  function getUuid(name: string) {
    return lookupList.find((item) => item.lookup_name === name)?.id;
  }
  return (
    <div className="table-responsive  text-nowrap x-overflow-scroll">
      <Table className="table mb-0" striped>
        <thead>
          <tr>
            <th>Sl. </th>
            <th>Lookup Name</th>
            <th>Lookup List Name</th>
            <th>Serial</th>
            <th>Status</th>
            <th>Default Ind</th>
            <th>Yearly Demand FY</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {lookupList.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <Button
                  className="w-100"
                  text={item.lookup_name}
                  color="light"
                  onClick={() => {
                    setActiveLookup(item.lookup_name);
                    setUuid(item.id!);
                  }}
                />
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <Button
                  text="View"
                  color="info"
                  disabled={true}
                  onClick={() => {
                    history.push(`${item.id}`);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default DefaultTable;
