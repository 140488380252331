import NoticeTemplateDetails from "src/pages/Setup/NoticeTemplateDetails";
import AddNoticeTemplateDetails from "src/pages/Setup/NoticeTemplateDetails/AddNoticeTemplateDetails";
import EditNoticeTemplateDetails from "src/pages/Setup/NoticeTemplateDetails/EditNoticeTemplateDetails";

export const noticeTemplateDetailsSetupRoutes = [
  // notice template details setup
  {
    path: '/setup/notice-template-details/',
    component: NoticeTemplateDetails,
  },
  {
    path: '/setup/notice-template-details/update/:id',
    component: EditNoticeTemplateDetails,
  },
  {
    path: '/setup/notice-template-details/add',
    component: AddNoticeTemplateDetails,
  },
];
