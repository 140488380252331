import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import { useDeleteLogBookEntryMasterMutation } from 'src/rtk/features/setup/logBookEntryMaster/logBookEntryMasterApi';

interface Props {
  logBookEntryMaster: LogBookEntryMasters;
  index: number;
}

const LogBookEntryMasterTableRow = ({ logBookEntryMaster, index }: Props) => {
  const history = useHistory();
  const [deleteRow, { isSuccess, isLoading, isError }] =
    useDeleteLogBookEntryMasterMutation();

  const [infoId, setInfoId] = useState('');
  const [modalShow, setModalShow] = useState(false);
  
  const deleteHandler = (modalState: boolean, id: string) => {
    setModalShow(modalState);
    setInfoId(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Log Book entry master deleted successfully');
    }
    if (isError) {
      toast.error('Error deleting log book entry master');
    }
  }, [isSuccess, isError]);

  return (
    <tr key={logBookEntryMaster.id}>
      <th scope="row">{index + 1}</th>
      <td>{logBookEntryMaster.exam_name}</td>
      <td>{logBookEntryMaster.subject_name}</td>
      <td>{logBookEntryMaster.signature_trainee}</td>
      <td>{logBookEntryMaster.signature_of_director_rtmd}</td>
      <td>{logBookEntryMaster.approve_status}</td>
      <td>{logBookEntryMaster.serial_no}</td>
      <td>{logBookEntryMaster.completion_ind}</td>
      <td>{logBookEntryMaster.date_of_enrollment_with_rtmd}</td>
      <td>{logBookEntryMaster.date_of_commencement_of_sst}</td>

      <td>{logBookEntryMaster.status ? 'Active' : 'Inactive' || 'N/A'}</td>

      <td>
        <div className="d-flex gap-2">
          <Button
            size="sm"
            text="Edit"
            color="warning"
            onClick={() =>
              history.push(`/log/book/entry/master/${logBookEntryMaster.id}`)
            }
          />

          <Button
            size="sm"
            text="Delete"
            color="danger"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => deleteHandler(true, logBookEntryMaster.id!)}
          />
        </div>
        {modalShow ? (
          <DeleteInformationModal
            isOpen={modalShow}
            toggle={() => setModalShow(!modalShow)}
            id={infoId}
            deleteInfomation={deleteRow}
          />
        ) : null}
      </td>
    </tr>
  );
};

export default LogBookEntryMasterTableRow;
