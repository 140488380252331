import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useLazyGetSessionWiseMiniCexListQuery } from 'src/rtk/features/miniCexCBD/miniCexAndCBDApi';
import { MiniCexPDF } from './MiniCexPDF';
import { CBDPDF } from './CBDPDF';

interface Props {
  isOpen: boolean;
  toggle: any;
  registrationNo: string;
  supervisorId: any;
}

const CBDPrintModal = (props: Props) => {

  const { isOpen, toggle, registrationNo, supervisorId } = props;
  const [tableData, setTableData] = useState<any>([]);
  const [pdfData, setPdfData] = useState<any>({});
  const [printId, setPrintId] = useState<string>('');

  const [getSessionWiseMiniCexList, { data }] = useLazyGetSessionWiseMiniCexListQuery();

  useEffect(() => {
    const url = {
      registrationNo: registrationNo,
      supervisorId: supervisorId
    }
    getSessionWiseMiniCexList(url);
  }, []);

  useEffect(() => {
    if (data) {
        setTableData(data?.data?.cbd_trainee);
        setPdfData(data?.data);
    }
  }, [data]);


  const columns: TableColumn<any>[] = [
    {
      name: 'Sl.',
      width: '50px',
      cell: (row, index) => <strong>{++index}</strong>,
    },

    {
      name: <div>Date of Assessment</div>,
      selector: (row) => moment(row?.date_of_assessment).format("DD-MMM-YYYY"),
      wrap: true,
    },

    {
      name: <div>Action</div>,
      cell: (row, index) => (
        <>
          <div className='mt-2 mb-2'>
            <button
              className="btn btn-sm btn-info"
              onClick={() => {
                setPrintId(row?.id);
              }}
              disabled={row?.assessments === null}
            >
              <i className="fa-solid fa-print" /> PDF
            </button>
          </div>
          {printId === row?.id ? (
            <CBDPDF
              data={pdfData}
              cbdAssessmentData={row}
              printId={printId}
              setPrintId={setPrintId} />
          ) : null}
        </>
      ),
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      backdrop={'static'}
      toggle={toggle}
      size="md"
    >
      <ModalHeader toggle={toggle}>Select</ModalHeader>
      <ModalBody className='p-1'>
        <DataTable
          columns={columns}
          data={tableData ? tableData : []}
          expandOnRowClicked
          highlightOnHover
          responsive
          persistTableHead={true}
        />
      </ModalBody>
    </Modal>
  );
};

export default CBDPrintModal;
