import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetDepartmentByIdQuery } from 'src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi';
import InstituteDeptRegistrationStepFive from './InstituteDeptRegistrationStepFive';
import InstituteDeptRegistrationStepFour from './InstituteDeptRegistrationStepFour';
import InstituteDeptRegistrationStepOne from './InstituteDeptRegistrationStepOne';
import InstituteDeptRegistrationStepSeven from './InstituteDeptRegistrationStepSeven';
import InstituteDeptRegistrationStepThree from './InstituteDeptRegistrationStepThree';
import InstituteDeptRegistrationStepTwo from './InstituteDeptRegistrationStepTwo';
import InstituteDeptRegistrationStepSix from './InstituteDeptRegistrationStepSix';

const ViewInstituteDeptRegistration = () => {
    const { departmentId: deptId, formBId: formBDeptId } = useParams<{ departmentId: string; formBId: string }>();
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [instituteName, setInstituteName] = useState<string>('');

    // const { data: instituteRegData, isSuccess: isInstituteRegDataSuccess, isError: isInstituteRegDataError, error: instituteRegDataError } = useGetInstituteRegistrationListQuery();
    const { data: departmentData, isSuccess: isDepartmentDataSuccess, isError: isDepartmentDataError, error: departmentDataError } = useGetDepartmentByIdQuery(formBDeptId);

    const goToNext = (departmentId?: string, formBDeptId?: string) => {
        if (currentStep < 7) {
            setCurrentStep((p: number) => p + 1);
        } else {
            setCurrentStep(1);
        }
    };

    const goToPrevious = (departmentId?: string, formBDeptId?: string) => {
        if (currentStep > 1) {
            setCurrentStep((p: number) => p - 1);
        } else {
            setCurrentStep(7);
        }
    };

    document.title = 'Training Accreditation Application Form | BCPS';

    useEffect(() => {
        if (isDepartmentDataSuccess) {
            setInstituteName(departmentData?.data?.institute?.institute_name!);
        }
    }, [isDepartmentDataSuccess]);

    return (
        <>
            {currentStep === 1 && <InstituteDeptRegistrationStepOne goToNext={goToNext} instituteName={instituteName} departmentId={deptId} formBDeptId={formBDeptId} isDisabled={true} />}
            {currentStep === 2 && <InstituteDeptRegistrationStepTwo goToNext={goToNext} goToPrevious={goToPrevious} instituteName={instituteName} departmentId={deptId} formBDeptId={formBDeptId} isDisabled={true} />}
            {currentStep === 3 && <InstituteDeptRegistrationStepThree goToNext={goToNext} goToPrevious={goToPrevious} instituteName={instituteName} departmentId={deptId} formBDeptId={formBDeptId} isDisabled={true} />}
            {currentStep === 4 && <InstituteDeptRegistrationStepFour goToNext={goToNext} goToPrevious={goToPrevious} instituteName={instituteName} departmentId={deptId} formBDeptId={formBDeptId} isDisabled={true} />}
            {currentStep === 5 && <InstituteDeptRegistrationStepFive goToNext={goToNext} goToPrevious={goToPrevious} instituteName={instituteName} departmentId={deptId} formBDeptId={formBDeptId} isDisabled={true} />}
            {currentStep === 6 && <InstituteDeptRegistrationStepSix goToNext={goToNext} goToPrevious={goToPrevious} instituteName={instituteName} departmentId={deptId} formBDeptId={formBDeptId} isDisabled={true} />}
            {currentStep === 7 && <InstituteDeptRegistrationStepSeven goToPrevious={goToPrevious} instituteName={instituteName} departmentId={deptId} formBDeptId={formBDeptId} isDisabled={true} />}
        </>
    );
};

export default ViewInstituteDeptRegistration;
