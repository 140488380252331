import { apiSlice } from 'src/rtk/api/apiSlice';
import * as changedUrl from 'src/helpers/url_helper';

export const changeRequest = apiSlice
  .enhanceEndpoints({ addTagTypes: ['TitleChangeRequest'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTraineeChangeRequestList: builder.query<APIResponse<any>, void>({
        query: () => ({
          method: 'GET',
          url: `${changedUrl.THESIS_DISSERTATION_TRAINEE_CHANGE_REQUEST_LIST}`,
        }),

        providesTags: ['TitleChangeRequest'],
      }),

      getAllChangeRequests: builder.query<APIResponseWithPagination<any>, string>({
        query: (url: any) => ({
          method: 'GET',
          url: `${changedUrl.THESIS_DISSERTATION_ALL_CHANGE_REQUESTS}?${url}`,
        }),

        providesTags: ['TitleChangeRequest'],
      }),

      createChangeRequestForTitle: builder.mutation<APIResponse<any>, any>({
        query: (data: any) => ({
          url: `${changedUrl.THESIS_DISSERTATION_TITLE_CHANGE_REQUEST}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['TitleChangeRequest'],
      }),

      deleteChangedRequest: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${changedUrl.THESIS_DISSERTATION_TRAINEE_DELETE_CHANGE_REQUEST}/${id}`,
        }),
        invalidatesTags: ['TitleChangeRequest'],
      }),

      getChangeRequestApproval: builder.mutation<APIResponse<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${changedUrl.THESIS_DISSERTATION_CHANGE_REQUEST_APPROVAL}/${url}`,
        }),
        invalidatesTags: ['TitleChangeRequest'],
      }),

    }),
  });

export const {
  useGetTraineeChangeRequestListQuery,
  useGetAllChangeRequestsQuery,
  useCreateChangeRequestForTitleMutation,
  useDeleteChangedRequestMutation,
  useGetChangeRequestApprovalMutation,
} = changeRequest;
