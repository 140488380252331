import { useFormik } from 'formik';
import React from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import signature from '../../assets/images/signature.png';
import picture from '../../assets/images/users/avatar-1.jpg';
import Breadcrumbs from '../../components/Common/Breadcrumb';


const GuideRegistration = () => {
  const guideInfoForm = useFormik({
    initialValues: {
      name: '',
      mobile: '',
      email: '',
      degree: '',
      institute: '',
      roll: '',
      fellowNo: '',
      division: '',
      district: '',
      po: '',
      address: '',
      subject: '',
      passingYear: '',
      uploadCert: '',
      registrationNo: '',
      workshopAttended: '',
      attachment: '',
      designation: '',
      workInstitute: '',
      department: '',
      fromDate: Date.now().toString(),
      toDate: Date.now().toString(),
      signature: '',
    },
    // validationSchema: Yup.object({
    //   name: Yup.string().required("Name is required"),
    //   fatherOrHusbandName: Yup.string().required(
    //     "Father/Husband name is required"
    //   ),
    //   motherName: Yup.string().required("Mother name is required"),
    //   dateOfBirth: Yup.string().required("Date of birth is required"),
    //   nid: Yup.string().required("NID is required"),
    //   phone: Yup.string(),
    // }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Guide/Co-Guide"
            breadcrumbItem="Guide/Co-Guide Registration"
          />
          <Row>
            <Col sm={12} md={6} lg={6}>
              <div className="mb-3">
                <Label className="form-label">Name</Label>
                <Input
                  name="name"
                  className="form-control"
                  placeholder="Enter name"
                  type="text"
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Father's/Husband's Name</Label>
                <Input
                  name="fatherOrHusbandName"
                  className="form-control"
                  placeholder="Father's/Husband's Name"
                  type="text"
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Mother's Name</Label>
                <Input
                  name="motherName"
                  className="form-control"
                  placeholder="Mother's Name"
                  type="text"
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">Date of Birth</Label>
                <Input
                  name="dateOfBirth"
                  className="form-control"
                  placeholder="Date of birth"
                  type="date"
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">NID</Label>
                <Input
                  name="nid"
                  className="form-control"
                  placeholder="Enter NID number"
                  type="text"
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Phone</Label>
                <Input
                  name="phone"
                  className="form-control"
                  placeholder="Enter phone number"
                  type="text"
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Email</Label>
                <Input
                  name="email"
                  className="form-control"
                  placeholder="Enter email"
                  type="text"
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">Tel (Res)</Label>
                <Input
                  name="tel"
                  className="form-control"
                  placeholder="Enter Telephone number (res)"
                  type="text"
                />
              </div>
            </Col>

            <Col className=" p-2" sm={12} md={5} lg={5}>
              <div className="mb-5">
                <Label className="form-label">Photograph</Label>
                <Input
                  name="photo"
                  className="form-control"
                  placeholder="Upload Photograph"
                  type="file"
                />
                <div className="container  pt-4">
                  <img
                    className="mx-auto d-block rounded"
                    src={picture}
                    width={150}
                  />
                </div>
              </div>
              <div className="mb-3">
                <Label className="form-label">Signature</Label>
                <Input
                  name="signature"
                  className="form-control"
                  placeholder="Upload Signature"
                  type="file"
                />

                <div className="container  pt-4">
                  <img
                    className="mx-auto d-block rounded"
                    src={signature}
                    width={150}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm={12} md={6} lg={6}>
              <h5>Present Address</h5>

              <div className="mb-3">
                <Label>Present Division</Label>
                <select className="form-select">
                  <option value="" selected>
                    Select Division
                  </option>
                  <option value="2">Dhaka</option>
                  <option value="1">Sylhet</option>
                  <option value="3">Barishal</option>
                  <option value="4">Loadding...</option>
                </select>
              </div>

              <div className="mb-3">
                <Label>Present District</Label>
                <select className="form-select">
                  <option value="" selected>
                    Select District
                  </option>
                  <option value="2">Jamalpur</option>
                  <option value="1">Rangpur</option>
                  <option value="3">Sunamganj</option>
                  <option value="4">Loading...</option>
                </select>
              </div>

              <div className="mb-3">
                <Label>Present Police Station</Label>
                <select className="form-select">
                  <option value="" selected>
                    Select PS
                  </option>
                  <option value="2">Jamalpur</option>
                  <option value="1">Rangpur</option>
                  <option value="3">Sunamganj</option>
                  <option value="4">Loading...</option>
                </select>
              </div>

              <div className="mb-3">
                <Label className="form-label">Presnet Address</Label>
                <Input
                  name="presentAddress"
                  className="form-control"
                  placeholder="Enter name"
                  type="textarea"
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <h5>Permanent Address</h5>

              <div className="mb-3">
                <Label>Permanent Division</Label>
                <select className="form-select">
                  <option value="" selected>
                    Select Division
                  </option>
                  <option value="2">Dhaka</option>
                  <option value="1">Sylhet</option>
                  <option value="3">Barishal</option>
                  <option value="4">Loadding...</option>
                </select>
              </div>

              <div className="mb-3">
                <Label>Permanent District</Label>
                <select className="form-select">
                  <option value="" selected>
                    Select District
                  </option>
                  <option value="2">Jamalpur</option>
                  <option value="1">Rangpur</option>
                  <option value="3">Sunamganj</option>
                  <option value="4">Loading...</option>
                </select>
              </div>

              <div className="mb-3">
                <Label>Permanent Police Station</Label>
                <select className="form-select">
                  <option value="" selected>
                    Select PS
                  </option>
                  <option value="2">Jamalpur</option>
                  <option value="1">Rangpur</option>
                  <option value="3">Sunamganj</option>
                  <option value="4">Loading...</option>
                </select>
              </div>

              <div className="mb-3">
                <Label className="form-label">Permanent Address</Label>
                <Input
                  name="permanentAddress"
                  className="form-control"
                  placeholder="Enter name"
                  type="textarea"
                />
              </div>
            </Col>
          </Row>
          {/*<ProfessionalEducationInfo />*/}
          {/*<TrainingRecord />*/}

          <h5>Guide and Co-Guide Registration</h5>

          <Row>
            <Form
              onSubmit={guideInfoForm.handleSubmit}
              encType="multipart/form-data"
            >
              <Row>
                <Col className="border-right mr-2" sm={2} md={2} lg={2}>
                  <div>Registraion For </div>
                </Col>
                <Col sm={2} md={2} lg={2}>
                  <FormGroup check>
                    <Input type="radio" name="GuideRadio" /> Guide
                  </FormGroup>
                </Col>
                <Col sm={2} md={2} lg={2}>
                  <FormGroup check>
                    <Input type="radio" name="GuideRadio" /> Co-Guide
                  </FormGroup>
                </Col>
              </Row>

              <Col sm={12} md={12} lg={12} className="my-3">
                <h5>
                  B. Head of the department of an institute / hospital
                  accredited by BCPS
                </h5>
              </Col>

              <Row>
                <Col sm={12} md={6} lg={6}>
                  <FormGroup
                    className="mb-5"
                    style={{ paddingBottom: '100px' }}
                  >
                    <div className="mb-3">
                      <Label className="form-label">Name</Label>
                      <Input
                        name="name"
                        className="form-control"
                        placeholder="Enter name"
                        type="text"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.name || ''}
                        invalid={
                          guideInfoForm.touched.name &&
                          !!guideInfoForm.errors.name
                        }
                      />
                      {guideInfoForm.touched.name &&
                      guideInfoForm.errors.name ? (
                        <FormFeedback type="invalid">
                          {guideInfoForm.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Mobile</Label>
                      <Input
                        name="mobile"
                        className="form-control"
                        placeholder="Mobile No"
                        type="text"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.mobile || ''}
                        invalid={
                          guideInfoForm.touched.mobile &&
                          !!guideInfoForm.errors.mobile
                        }
                      />
                      {guideInfoForm.touched.mobile &&
                      guideInfoForm.errors.mobile ? (
                        <FormFeedback type="invalid">
                          {guideInfoForm.errors.mobile}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Email</Label>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        type="text"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.email || ''}
                        invalid={
                          guideInfoForm.touched.email &&
                          !!guideInfoForm.errors.email
                        }
                      />
                      {guideInfoForm.touched.email &&
                      guideInfoForm.errors.email ? (
                        <FormFeedback type="invalid">
                          {guideInfoForm.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <h5>Academic Qualifications</h5>
                    <div className="my-3">
                      <Label className="form-label">Select Degree</Label>
                      <Input
                        name="degree"
                        className="form-control"
                        placeholder="Select Year"
                        type="select"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.degree || ''}
                        invalid={
                          guideInfoForm.touched.degree &&
                          !!guideInfoForm.errors.degree
                        }
                      >
                        <option value="0">Select Year</option>
                      </Input>
                      {guideInfoForm.touched.degree &&
                      guideInfoForm.errors.degree ? (
                        <FormFeedback type="invalid">
                          {guideInfoForm.errors.degree}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Institute</Label>
                      <Input
                        name="institute"
                        className="form-control"
                        placeholder="Select Institute"
                        type="select"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.institute || ''}
                        invalid={
                          guideInfoForm.touched.institute &&
                          !!guideInfoForm.errors.institute
                        }
                      >
                        <option value="0">Select Institute</option>
                      </Input>
                      {guideInfoForm.touched.institute &&
                      guideInfoForm.errors.institute ? (
                        <FormFeedback type="invalid">
                          {guideInfoForm.errors.institute}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Roll</Label>
                      <Input
                        name="roll"
                        className="form-control"
                        placeholder="Enter roll"
                        type="text"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.roll || ''}
                      />
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Fellow No</Label>
                      <Input
                        name="text"
                        className="form-control"
                        placeholder="Enter fellow no"
                        type="text"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.fellowNo}
                      />
                    </div>

                    <div className="mt-5">
                      <Button color="primary">Add+</Button>
                    </div>
                  </FormGroup>
                </Col>

                <Col sm={12} md={6} lg={6}>
                  <FormGroup className="mb-5">
                    <h5>Mailing Address</h5>
                    <div className="my-3">
                      <Label className="form-label">Division</Label>
                      <Input
                        name="division"
                        className="form-control"
                        placeholder="Division"
                        type="select"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.division || ''}
                        invalid={
                          guideInfoForm.touched.division &&
                          !!guideInfoForm.errors.division
                        }
                      >
                        <option value="0">Division</option>
                      </Input>
                      {guideInfoForm.touched.division &&
                      guideInfoForm.errors.division ? (
                        <FormFeedback type="invalid">
                          {guideInfoForm.errors.division}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">District</Label>
                      <Input
                        name="district"
                        className="form-control"
                        placeholder="District"
                        type="select"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.district || ''}
                        invalid={
                          guideInfoForm.touched.district &&
                          !!guideInfoForm.errors.district
                        }
                      >
                        <option value="0">District</option>
                      </Input>
                      {guideInfoForm.touched.district &&
                      guideInfoForm.errors.district ? (
                        <FormFeedback type="invalid">
                          {guideInfoForm.errors.district}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">PO</Label>
                      <Input
                        name="po"
                        className="form-control"
                        placeholder="PO"
                        type="select"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.po || ''}
                      >
                        <option value="o">PO</option>
                      </Input>
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Address</Label>
                      <Input
                        name="address"
                        className="form-control"
                        placeholder="Enter address"
                        type="textarea"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.address}
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <div className="my-3">
                      <Label className="form-label">Subject</Label>
                      <Input
                        name="subject"
                        className="form-control"
                        placeholder="Subject"
                        type="select"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.subject || ''}
                        invalid={
                          guideInfoForm.touched.subject &&
                          !!guideInfoForm.errors.subject
                        }
                      >
                        <option value="0">Select Subject</option>
                      </Input>
                      {guideInfoForm.touched.subject &&
                      guideInfoForm.errors.subject ? (
                        <FormFeedback type="invalid">
                          {guideInfoForm.errors.subject}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Passing Year</Label>
                      <Input
                        name="passingYear"
                        className="form-control"
                        placeholder="Passing Year"
                        type="select"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.passingYear || ''}
                        invalid={
                          guideInfoForm.touched.passingYear &&
                          !!guideInfoForm.errors.passingYear
                        }
                      >
                        <option value="0">Select Passing Year</option>
                      </Input>
                      {guideInfoForm.touched.passingYear &&
                      guideInfoForm.errors.passingYear ? (
                        <FormFeedback type="invalid">
                          {guideInfoForm.errors.passingYear}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Upload Certificate</Label>
                      <Input
                        name="uploadCert"
                        className="form-control"
                        placeholder="Upload Certificate"
                        type="file"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.uploadCert || ''}
                      />
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Registration No</Label>
                      <Input
                        name="registrationNo"
                        className="form-control"
                        placeholder="Enter registration no"
                        type="text"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.registrationNo}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>

              <hr />

              <Row>
                <Col sm={12} md={12} lg={12}>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>SL</th>
                        <th>Degree</th>
                        <th>Subject</th>
                        <th>Institute</th>
                        <th>Year</th>
                        <th>Certificate</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>MBBS</td>
                        <td>Medicine</td>
                        <td>Dhaka Medical College</td>
                        <td>2022</td>
                        <td>Bachelor in Medicine</td>
                        <td
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                          }}
                        >
                          <Button className="me-4">
                            <i className="fa-solid fa-pen"></i>
                          </Button>

                          <Button className="btn-danger">
                            <i className="fa-sharp fa-solid fa-xmark"></i>
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>FCPS</td>
                        <td>Medicine</td>
                        <td>Dhaka Medical College</td>
                        <td>2022</td>
                        <td>FCPS</td>
                        <td
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                          }}
                        >
                          <Button className="me-4">
                            <i className="fa-solid fa-pen"></i>
                          </Button>
                          <Button className="btn-danger">
                            <i className="fa-sharp fa-solid fa-xmark"></i>
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>

              {/* <Col sm={12} md={12} lg={12}>
                <Row className="d-flex justify-content-around">
                  <Col sm={12} md={5} lg={5}>
                    <FormGroup className="mb-5">
                      <h5>Workshop Attended</h5>
                      <div className="my-3">
                        <Label check>
                          <Input
                            type="checkbox"
                            id="workshopAttended"
                            name="workshopAttended"
                            value={guideInfoForm.values.workshopAttended}
                          />{" "}
                          Supervisor's Skill Development Workshop
                        </Label>
                      </div>

                      <div className="mb-3">
                        <Label check>
                          <Input
                            type="checkbox"
                            id="workshopAttended"
                            name="workshopAttended"
                            value={guideInfoForm.values.workshopAttended}
                          />{" "}
                          Research Methodology Workshop
                        </Label>
                      </div>

                      <div className="mb-3">
                        <Row>
                          <Col sm={4} md={4} lg={4}>
                            <Input
                              type="checkbox"
                              id="workshopAttended"
                              name="workshopAttended"
                              value={guideInfoForm.values.workshopAttended}
                            />
                            <Label className="ms-2">Other(Specify)</Label>
                          </Col>

                          <Col sm={6} md={6} lg={6}>
                            <Input
                              name="workshopAttendedText"
                              className="form-control"
                              placeholder="Enter"
                              type="text"
                              style={{ width: "200px" }}
                              onChange={guideInfoForm.handleChange}
                              onBlur={guideInfoForm.handleBlur}
                              value={guideInfoForm.values.workshopAttended}
                            />
                          </Col>
                          <Col sm={2} md={2} lg={2}>
                            <Button>
                              <i className="fa-sharp fa-solid fa-plus"></i>
                            </Button>
                          </Col>
                        </Row>
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">PO</Label>
                        <Input
                          name="po"
                          className="form-control"
                          placeholder="PO"
                          type="select"
                          onChange={guideInfoForm.handleChange}
                          onBlur={guideInfoForm.handleBlur}
                          value={guideInfoForm.values.po || ""}
                        >
                          <option value="o">PO</option>
                        </Input>
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Address</Label>
                        <Input
                          name="address"
                          className="form-control"
                          placeholder="Enter address"
                          type="textarea"
                          onChange={guideInfoForm.handleChange}
                          onBlur={guideInfoForm.handleBlur}
                          value={guideInfoForm.values.address}
                        />
                      </div>
                    </FormGroup>
                  </Col>

                  <Col sm={12} md={5} lg={5}>
                    <FormGroup className="mb-5">
                      <div className="mb-3">
                        <Label className="form-label">Attachment</Label>
                        <Row>
                          <Col sm={9} md={9} lg={9}>
                            <Input
                              name="attachment"
                              className="form-control"
                              placeholder="Choose file"
                              type="file"
                              onChange={guideInfoForm.handleChange}
                              onBlur={guideInfoForm.handleBlur}
                              value={guideInfoForm.values.attachment}
                            />
                          </Col>

                          <Col sm={3} md={3} lg={3}>
                            <Button>
                              <i className="fa-sharp fa-solid fa-plus"></i>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Col> */}

              <Row>
                <Col sm={12} md={6} lg={6}>
                  <FormGroup>
                    <h5>
                      Work Experience (please mention only the current position)
                    </h5>
                    <div className="my-3">
                      <Label className="form-label">Select Designation</Label>
                      <Input
                        name="designation"
                        className="form-control"
                        placeholder="Select Designation"
                        type="select"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.designation || ''}
                        invalid={
                          guideInfoForm.touched.designation &&
                          !!guideInfoForm.errors.designation
                        }
                      >
                        <option value="0">Select Designation</option>
                      </Input>
                      {guideInfoForm.touched.designation &&
                      guideInfoForm.errors.designation ? (
                        <FormFeedback type="invalid">
                          {guideInfoForm.errors.designation}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Institute</Label>
                      <Input
                        name="workInstitute"
                        className="form-control"
                        placeholder="Select"
                        type="select"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.workInstitute || ''}
                        invalid={
                          guideInfoForm.touched.workInstitute &&
                          !!guideInfoForm.errors.workInstitute
                        }
                      >
                        <option value="0">Select...</option>
                      </Input>
                      {guideInfoForm.touched.workInstitute &&
                      guideInfoForm.errors.workInstitute ? (
                        <FormFeedback type="invalid">
                          {guideInfoForm.errors.workInstitute}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Department</Label>
                      <Input
                        name="department"
                        className="form-control"
                        placeholder="Select"
                        type="select"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.department || ''}
                        invalid={
                          guideInfoForm.touched.department &&
                          !!guideInfoForm.errors.department
                        }
                      >
                        <option value="0">Select...</option>
                      </Input>
                      {guideInfoForm.touched.department &&
                      guideInfoForm.errors.department ? (
                        <FormFeedback type="invalid">
                          {guideInfoForm.errors.department}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">From</Label>
                      <Input
                        name="fromDate"
                        className="form-control"
                        placeholder=""
                        type="date"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.fromDate}
                      />
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">To</Label>
                      <Input
                        name="toDate"
                        className="form-control"
                        placeholder=""
                        type="date"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.toDate}
                      />
                    </div>

                    <div className="mt-5">
                      <Button color="primary">Add+</Button>
                    </div>
                  </FormGroup>
                </Col>

                <Col sm={12} md={6} lg={6}>
                  <FormGroup className="mb-5">
                    <div className="mb-3">
                      <Label className="form-label">Signature</Label>
                      <Input
                        name="signature"
                        className="form-control mb-4"
                        placeholder="Choose file"
                        type="file"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.signature}
                      />

                      <Input
                        name="signatureText"
                        className="form-control"
                        placeholder=""
                        type="textarea"
                        onChange={guideInfoForm.handleChange}
                        onBlur={guideInfoForm.handleBlur}
                        value={guideInfoForm.values.signature}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col sm={12} md={12} lg={12}>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>SL</th>
                        <th>Designation</th>
                        <th>Institute</th>
                        <th>Department</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>MBBS</td>
                        <td>Medicine</td>
                        <td>Dhaka Medical College</td>
                        <td>2022</td>
                        <td>Bachelor in Medicine</td>
                        <td
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                          }}
                        >
                          <Button className="me-4">
                            <i className="fa-solid fa-pen"></i>
                          </Button>

                          <Button className="btn-danger">
                            <i className="fa-sharp fa-solid fa-xmark"></i>
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>FCPS</td>
                        <td>Medicine</td>
                        <td>Dhaka Medical College</td>
                        <td>2022</td>
                        <td>FCPS</td>
                        <td
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                          }}
                        >
                          <Button className="me-4">
                            <i className="fa-solid fa-pen"></i>
                          </Button>
                          <Button className="btn-danger">
                            <i className="fa-sharp fa-solid fa-xmark"></i>
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>

              <div className="text-center my-3">
                <Button className="w-25" variant="success" type="submit">
                  Save
                </Button>
              </div>
            </Form>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GuideRegistration;
