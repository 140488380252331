import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Alert, Button, Card, CardBody, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import defaultAvatar from 'src/assets/images/users/default-avatar.png';
import { config } from 'src/config';
import { setFromErrors } from 'src/helpers';
import { useAppSelector } from 'src/rtk/app/hook';
import { useCreateGuideCoGuideMutation } from 'src/rtk/features/guide/guideCoguideApi';
import { useGetMyRegInfoQuery } from 'src/rtk/features/registration-approval/registrationApprovalApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';
import GuideCoGuideBankInfoEntry from './GuideCoGuideBankInfoEntry';

const initialValues: ApplyForGuideCoGuide = {
  type: '',
  submit_status: '',
};

const GuideCoGuideApply = () => {
  const authUser = useAppSelector((state) => state.auth.user);

  const { data, isLoading: isDataLoading } = useGetMyRegInfoQuery(authUser?.registration_no);
  const userInfo = data?.data || ({} as RegInfo);

  const [btnClicked, setBtnClicked] = useState<string>('');
  const [isFcps, setIsFcps] = useState<boolean>(false);
  const [createGuideCoGuide, { isSuccess, isError, error, isLoading: isCreateLoading }] =
    useCreateGuideCoGuideMutation();

  const buttonRef = useRef<any>();
  const draftButtonRef = useRef<any>();

  // const [guideChecked, setGuideChecked] = useState(true);
  // const [coGuideChecked, setCoGuideChecked] = useState(false);

  const err: CustomError = error as CustomError;

  const {
    handleSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    resetForm,
    setFieldValue,
    setErrors,
  } = useFormik({
    initialValues,

    validationSchema: Yup.object({
      type: Yup.mixed().required('Please Select a Type'),
    }),

    onSubmit: (values: any) => {
      console.log(values);
      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        if (key === 'submit_status') {
          if (btnClicked === 'submit') {
            values[key] = 'Submit';
          } else if (btnClicked === 'save_as_draft') {
            values[key] = 'Draft';
          }
        }
        formData.append(key, values[key]);
      });
      buttonRef.current.disabled = true;

      createGuideCoGuide(formData);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Information Stored Successfully!');
      buttonRef.current.disabled = false;
    }
    if (isError) {
      toast.error(err?.message || 'Something Went Wrong!');
      setFromErrors(err.data, setErrors);
      buttonRef.current.disabled = false;
    }
  }, [isSuccess, isError]);


  useEffect(() => {
    userInfo?.educational_infos?.find((item: any) => item?.name_of_the_degree === 'FCPS') ? setIsFcps(true) : setIsFcps(false);
  }, [userInfo?.educational_infos]);

  return (
    <React.Fragment>
      {isCreateLoading || isDataLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <div className="row align-items-center justify-content-center">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        // onReset={(e) => {
        //   resetForm();
        // }}
        >
          {/* <div className="card">
            <div className="card-body">
              <div>
                <div>
                  <label className='form-label required-field'>Application For</label>
                </div>
                <div className="form-check form-check-inline">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="type"
                    value={'Guide'}
                    id="type-guide"
                    onChange={(e) => {
                      // handleGuideChecked(e);
                      handleChange(e);
                    }}
                  // checked={guideChecked}
                  />
                  <label className="form-check-label" htmlFor="type-guide">
                    Guide
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name="type"
                    value={'Co-Guide'}
                    id="type-co-guide"
                    onChange={(e) => {
                      // handleCoGuideChecked(e);
                      handleChange(e);
                    }}
                  // checked={coGuideChecked}
                  />
                  <label className="form-check-label" htmlFor="type-co-guide">
                    Co-Guide
                  </label>
                </div>
                {errors.type && touched.type ? (
                  <div className="text-danger">{errors.type as string}</div>
                ) : null}
              </div>
            </div>
            <FormGroup className="mb-3 text-center">
              <Button
                type="reset"
                className="btn btn-secondary me-2"
              >
                Reset
              </Button>
              <button
                type="submit"
                className="btn btn-info me-2"
                ref={draftButtonRef}
                onClick={(e) => {
                  setBtnClicked('save_as_draft');
                }}
              >
                Save as Draft
              </button>
              <button
                type="submit"
                ref={buttonRef}
                className="btn btn-success"
                onClick={(e) => {
                  setBtnClicked('submit');
                }}
              >
                Submit
              </button>
            </FormGroup>
          </div> */}
          <Card>
            <CardBody>
              <Row>
                <Col md={1}>
                  <div className="img-fluid img-rounded text-center">
                    <img
                      src={
                        userInfo?.personal_info?.picture
                          ? `${config?.APP_URL}${userInfo?.personal_info?.picture}`
                          : defaultAvatar
                      }
                      width="120"
                      className="img-thumbnail"
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div><strong>Name: </strong> {userInfo?.personal_info?.full_name}</div>
                  <div><strong>Speciliaty: </strong> {userInfo?.subject?.subject_name}</div>

                </Col>
                <Col>
                  <div>
                    <div>
                      <label className='form-label required-field'><strong>Application For</strong></label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="type"
                        value={'Guide'}
                        id="type-guide"
                        onChange={(e) => {
                          // handleGuideChecked(e);
                          handleChange(e);
                        }}
                      // checked={guideChecked}
                      />
                      <label className="form-check-label" htmlFor="type-guide">
                        Guide
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Input
                        className="form-check-input"
                        type="radio"
                        name="type"
                        value={'Co-Guide'}
                        id="type-co-guide"
                        onChange={(e) => {
                          // handleCoGuideChecked(e);
                          handleChange(e);
                        }}
                      // checked={coGuideChecked}
                      />
                      <label className="form-check-label" htmlFor="type-co-guide">
                        Co-Guide
                      </label>
                    </div>
                    {errors.type && touched.type ? (
                      <div className="text-danger">{errors.type as string}</div>
                    ) : null}
                  </div>
                </Col>
              </Row>
              {values.type !== '' ? <Row className='mt-2'>
                <Col>
                  <div>
                    {(userInfo?.current_employment_info?.designation === 'Professor' ||
                      userInfo?.current_employment_info?.designation === 'Assistant Professor' ||
                      userInfo?.current_employment_info?.designation === 'Associate Professor') && isFcps ?
                      <Alert color="success" style={{ width: '500px' }}><i className="fa-solid fa-check fa-2xl" style={{ color: "#215903" }} />&nbsp;You are eligible for applying as a {values.type}</Alert> :
                      <Alert color="danger" style={{ width: '500px' }}><i className="fa-solid fa-xmark fa-2xl" style={{ color: "#f00505" }} />&nbsp;Eligibility not matched! Please Update Your Profile</Alert>
                    }
                  </div>
                </Col>
              </Row> : null}
              <Row>
                <Col md={8} lg={8} sm={12}>
                  <h6 className="div-heading text-white mt-3">Current Work Information</h6>
                  <Card>
                    <CardBody>
                      <ul className="list-unstyled mb-0">
                        <Row>
                          <Col>
                            <li>
                              <strong>Job Title: </strong>
                              {userInfo?.current_employment_info?.designation ?? 'N/A'}
                            </li>

                            <li>
                              <strong>Institute: </strong>
                              {userInfo?.current_employment_info?.institute_name ?? 'N/A'}
                            </li>

                            <li>
                              <strong>Department: </strong>
                              {userInfo?.current_employment_info?.department_name ?? 'N/A'}
                            </li>

                            <li>
                              <strong>From Date: </strong>
                              {moment(userInfo?.current_employment_info?.from_date).format("DD-MMM-YYYY") ?? 'N/A'}
                            </li>

                            <li>
                              <strong>FCPS Degree: </strong>
                              {isFcps ?
                                <span className="badge rounded-pill d-inline-block  badge-approved font-size-12">
                                  Yes
                                </span>
                                : <span className="badge rounded-pill d-inline-block  badge-pending font-size-12">
                                  No
                                </span>
                              }
                            </li>
                          </Col>
                          <Col>

                            <li>
                              <strong>Institute Type: </strong>
                              {userInfo?.current_employment_info?.employment_type ?? 'N/A'}
                            </li>

                            <li>
                              <strong>Head of the Department: </strong>

                              {userInfo?.current_employment_info?.department_head
                                ? <span className="badge rounded-pill d-inline-block  badge-approved font-size-12">
                                  Yes
                                </span>
                                : <span className="badge rounded-pill d-inline-block  badge-pending font-size-12">
                                  No
                                </span>}
                            </li>
                            <li>
                              <strong>In Charge of Unit: </strong>

                              {userInfo?.current_employment_info?.in_charge
                                ? <span className="badge rounded-pill d-inline-block  badge-approved font-size-12">
                                  Yes
                                </span>
                                : <span className="badge rounded-pill d-inline-block  badge-pending font-size-12">
                                  No
                                </span>}
                            </li>
                            <li>
                              <strong>Unit Name: </strong>
                              {userInfo?.current_employment_info?.unit_name ?? 'N/A'}

                            </li>

                            <li>
                              <strong>Supervisor of FCPS Training: </strong>
                              <span className="badge rounded-pill d-inline-block  badge-approved font-size-12">
                                Yes
                              </span>
                            </li>
                          </Col>
                        </Row>
                      </ul>
                    </CardBody>
                  </Card>
                  <Row>
                    <Col>
                      <h6 className="div-heading text-white mt-3">Guide/Co-Guide Eligibility Criteria</h6>
                      <Card>
                        <CardBody>
                          <>
                            <div className='mb-1'>
                              Professor or Associate Professor of an institute with FCPS Degree &nbsp;
                              {(userInfo?.current_employment_info?.designation === 'Professor' || userInfo?.current_employment_info?.designation === 'Associate Professor') && isFcps ?
                                <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                                : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                              }
                            </div>
                            <div className='mb-1'>
                              Assistant Professor with completion of Research Methodology TOT program &nbsp;
                              {/* {userInfo?.current_employment_info?.designation === 'Assistant Professor' || userInfo?.current_employment_info?.in_charge ?
                                <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                                : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                              } */}
                              <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                            </div>
                            {values?.type === 'Co-Guide' &&
                              <div className='mb-1'>
                                Assistant Professor with FCPS Degree &nbsp;
                                {userInfo?.current_employment_info?.designation === 'Assistant Professor' && isFcps ?
                                  <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                                  : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                                }
                              </div>}
                          </>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <GuideCoGuideBankInfoEntry />
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-3 text-end">
                    <Button
                      type="reset"
                      className="btn btn-danger me-2"
                    >
                      Reset
                    </Button>

                    <button
                      type="submit"
                      ref={buttonRef}
                      className="btn btn-success"
                      disabled={(userInfo?.current_employment_info?.designation !== 'Professor' &&
                        userInfo?.current_employment_info?.designation !== 'Assistant Professor' &&
                        userInfo?.current_employment_info?.designation !== 'Associate Professor') ||
                        userInfo?.current_employment_info === null || !isFcps || values.type === ''
                        // ||
                        // userInfo?.current_employment_info?.department_head === null ||
                        // userInfo?.current_employment_info?.in_charge === null ||
                        // userInfo?.current_employment_info?.department_head === false ||
                        // userInfo?.current_employment_info?.in_charge === false
                      }
                      onClick={() => {
                        setBtnClicked('submit');
                      }}
                    >
                      Apply
                    </button>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default GuideCoGuideApply;
