import { apiSlice } from 'src/rtk/api/apiSlice';
import * as setupSubjectInfoUrl from 'src/helpers/url_helper';

export const setupSubjectInfoApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['SubjectInfos'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSubjectInfos: builder.query<APIResponse<SubjectInfo[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${setupSubjectInfoUrl.GET_SUBJECT_INFOS}`,
        }),
        providesTags: ['SubjectInfos'],
      }),

      getSubjectInfosList: builder.query<APIResponse<SubjectInfo[]>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${setupSubjectInfoUrl.GET_SUBJECT_INFOS}?${url}`,
        }),
        providesTags: ['SubjectInfos'],
      }),

      getSubjectInfo: builder.query<APIResponse<SubjectInfo>, string>({
        query: (id) => ({
          url: `${setupSubjectInfoUrl.GET_SUBJECT_INFO}/${id}`,
          method: 'GET',
        }),
        providesTags: ['SubjectInfos'],
      }),

      createSubjectInfo: builder.mutation<
        APIResponse<SubjectInfo>,
        SubjectInfo
      >({
        query: (data) => ({
          url: `${setupSubjectInfoUrl.CREATE_SUBJECT_INFO}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['SubjectInfos'],
      }),

      updateSubjectInfo: builder.mutation<APIResponse<SubjectInfo>, string>({
        query: (url) => ({
          url: `${setupSubjectInfoUrl.UPDATE_SUBJECT_INFO}` + url,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['SubjectInfos'],
      }),

      deleteSubjectInfo: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${setupSubjectInfoUrl.DELETE_SUBJECT_INFO}/${id}`,
        }),
        invalidatesTags: ['SubjectInfos'],
      }),
    }),
  });

export const {
  useCreateSubjectInfoMutation,
  useGetSubjectInfosQuery,
  useGetSubjectInfosListQuery,
  useGetSubjectInfoQuery,
  useUpdateSubjectInfoMutation,
  useDeleteSubjectInfoMutation,
} = setupSubjectInfoApi;
