import { useState } from 'react';
import { Button } from 'src/components';
import * as protocolSummaryViewUrl from 'src/helpers/url_helper';
import { useGetDataQuery } from 'src/rtk/features/disertation-thesis/crudApi';
import { ProtocolSummaryReport } from './ProtocolSummaryReport';
import StepOne from './StepOne';
import StepThree from './StepThree';
import StepTwo from './StepTwo';

const ProtocolSummaryView = () => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [showReport, setShowReport] = useState<boolean>(false);

    const {
        data: existingProtocol,
        isLoading: existingProtocoLoading,
        isSuccess: existingProtocoLoadingSuccess,
        error: existingProtocoLoadingError,
    } = useGetDataQuery(`${protocolSummaryViewUrl.GET_EXISTING_PROTOCOL}`);

    const {
        data: title,
        isLoading: titleLoading,
        isSuccess: titleLoadingSuccess,
        error: titleLoadingError,
    } = useGetDataQuery(`${protocolSummaryViewUrl.GET_EXISTING_TITLE}`);

    const protocolEntry: any = existingProtocol?.data!;
    const titleEntry: any = title?.data!;

    const goToNext = () => {
        if (currentStep < 3) {
            setCurrentStep((p: number) => p + 1);
        }
    };

    const goToPrevious = () => {
        if (currentStep > 1) {
            setCurrentStep((p: number) => p - 1);
        }
    };

    return (
        <>
            <div className={"d-flex gap-3 justify-content-end mb-3"}>
                <Button
                  type="button"
                  text="Protocol Entry"
                  color="info"
                  outline
                  onClick={(e) => {
                      window.open(`/thesis-dissertation-protocol-entry-view/${protocolEntry?.id}`, '_blank');
                  }}
                ></Button>

                <Button
                  type="button"
                  text="View in PDF"
                  color="secondary"
                  onClick={(e) => {
                      setShowReport(true);
                  }}
                ></Button>
            </div>

            {currentStep === 1 && <StepOne goToNext={goToNext} protocolEntry={protocolEntry} titleEntry={titleEntry} />}
            {currentStep === 2 && <StepTwo goToNext={goToNext} goToPrevious={goToPrevious} protocolEntry={protocolEntry} titleEntry={titleEntry} />}
            {currentStep === 3 && <StepThree goToPrevious={goToPrevious} protocolEntry={protocolEntry} titleEntry={titleEntry} />}

            {
                showReport ?
                    <ProtocolSummaryReport
                        protocolEntry={protocolEntry}
                        titleEntry={titleEntry}
                        showReport={showReport}
                        setShowReport={setShowReport}
                    />
                    :
                    null
            }
        </>
    );
};

export default ProtocolSummaryView;
