import { apiSlice } from 'src/rtk/api/apiSlice';
import * as setupTrnNoticeMasterUrl from 'src/helpers/url_helper';

export const setupTrnNoticeMasterApi = apiSlice
    .enhanceEndpoints({ addTagTypes: ['NoticeMasterList'] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getTrnNoticeMasterLists: builder.query<APIResponse<TransactionNoticeMaster[]>, void>({
                query: () => ({
                    method: 'GET',
                    url: `${setupTrnNoticeMasterUrl.GET_TRN_NOTICE_MASTER_LISTS}`,
                }),

                providesTags: ['NoticeMasterList'],
            }),

            getTrnNoticeMasterList: builder.query<APIResponse<TransactionNoticeMaster>, string>({
                query: (id) => ({
                    url: `${setupTrnNoticeMasterUrl.GET_TRN_NOTICE_MASTER_LIST}/${id}`,
                    method: 'GET',
                }),

                providesTags: ['NoticeMasterList'],
            }),

            createTrnNoticeMasterList: builder.mutation<APIResponse<TransactionNoticeMaster>, TransactionNoticeMaster>({
                query: (data) => ({
                    url: `${setupTrnNoticeMasterUrl.CREATE_TRN_NOTICE_MASTER_LIST}`,
                    method: 'POST',
                    body: data,
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                }),

                invalidatesTags: ['NoticeMasterList'],
            }),

            updateTrnNoticeMasterList: builder.mutation<APIResponse<TransactionNoticeMaster>, string>({
                query: (url) => ({
                    url: `${setupTrnNoticeMasterUrl.UPDATE_TRN_NOTICE_MASTER_LIST}` + url,
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                }),

                invalidatesTags: ['NoticeMasterList'],
            }),

            deleteTrnNoticeMasterList: builder.mutation({
                query: (id: string) => ({
                    method: 'DELETE',
                    url: `${setupTrnNoticeMasterUrl.DELETE_TRN_NOTICE_MASTER_LIST}/${id}`,
                }),

                invalidatesTags: ['NoticeMasterList'],
            }),
        }),
    });

export const {
    useCreateTrnNoticeMasterListMutation,
    useGetTrnNoticeMasterListQuery,
    useGetTrnNoticeMasterListsQuery,
    useUpdateTrnNoticeMasterListMutation,
    useDeleteTrnNoticeMasterListMutation
} = setupTrnNoticeMasterApi;
