import { useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
    Card,
    CardBody,
    Col,
    Container,
    FormGroup,
    Modal,
    Row,
    Table
} from 'reactstrap';
import { Button } from 'src/components';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import { useDeleteFaqMutation, useGetFaqListForAdminQuery } from 'src/rtk/features/research-clinic-faq/FAQApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoGlobalApi';
import AddResearchClinicFAQ from './AddResearchClinicFAQ';
import EditResearchClinicFAQ from './EditResearchClinicFAQ';


const ResearchClinicFAQList = () => {
    document.title = 'Research Clinic | BCPS';
    const { id: courseId } = useParams<{ id: string }>();

    const [modalShow, setModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [infoId, setInfoId] = useState('');
    const [editCount, setEditCount] = useState<number>(1);
    const [faqToEdit, setFaqToEdit] = useState<ResearchClinicFAQ>(
        {} as ResearchClinicFAQ,
    );

    const [researchClinicFAQId, setResearchClinicFAQId] = useState<string>('');

    const {
        data: faqListData,
        error: faqListDataError,
        isLoading: isFaqListDataLoading,
        isError: isFaqListDataError,
        isSuccess: isFaqListDataSuccess,
    } = useGetFaqListForAdminQuery();

    const { data: subjectList, isError: isSubFetchError } =
        useGetSubjectInfosQuery();

    const subjectOptions: SelectOption[] = (
        subjectList?.data || []
    ).map((item) => ({
        value: item.subject_name ?? '',
        label: item.subject_name ?? '',
    }));

    const faqAdminList = faqListData?.data;

    const [deleteFaqEntry, { isSuccess: isDeleteFaqEntrySuccess, isLoading: isDeleteFaqEntryLoading, isError: isDeleteFaqEntryError }] =
        useDeleteFaqMutation();

    const deleteHandler = (modalState: boolean, id: string) => {
        setDeleteModalShow(modalState);
        setInfoId(id);
    };

    const editHandler = (faq: ResearchClinicFAQ) => {
        setFaqToEdit(faq);
        setEditCount((e: number) => e + 1);
        setModalShow(true);
    };

    return (
        <div className="page-content">
            <AddResearchClinicFAQ count={faqAdminList?.length} />
            <Container fluid>
                <Card className="mb-5">
                    <CardBody>
                        <Table className='table table-sm'>
                            <thead className='table-secondary'>
                                <tr>
                                    <th>Serial Number</th>
                                    <th>Question</th>
                                    <th>Answer</th>
                                    <th>Subject Name</th>
                                    <th>Active Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {faqAdminList?.map((item: any, index: number) => (
                                    <tr key={item.id}>
                                        <td>{item?.sl_no}</td>
                                        <td>{item?.question}</td>
                                        <td>
                                            <span dangerouslySetInnerHTML={{ __html: item?.answer }}></span>
                                        </td>
                                        <td>{item?.subject_name}</td>
                                        <td>{item?.active_status}</td>
                                        <td>
                                            <div className="d-flex gap-1">
                                                <Button
                                                    size="sm"
                                                    color="success"
                                                    type="button"
                                                    text={`Edit`}
                                                    onClick={() => {
                                                        editHandler(item);
                                                    }}
                                                />

                                                <Button
                                                    size="sm"
                                                    text={`Delete`}
                                                    color="danger"
                                                    disabled={isFaqListDataLoading}
                                                    isLoading={isFaqListDataLoading}
                                                    onClick={() => deleteHandler(true, item.id!)}
                                                />
                                            </div>
                                        </td>
                                        {deleteModalShow && infoId === item?.id ? (
                                            <DeleteInformationModal
                                                isOpen={deleteModalShow}
                                                toggle={() => setDeleteModalShow(!deleteModalShow)}
                                                id={infoId}
                                                deleteInfomation={deleteFaqEntry}
                                            />
                                        ) : null}
                                    </tr>
                                ))
                                }
                            </tbody>
                        </Table>

                        <Modal
                            onOpened={function () {
                                toast.dismiss();
                            }}
                            isOpen={modalShow}
                            scrollable={true}
                            size="lg"
                            toggle={() => {
                                document.body.classList.add('no_padding');
                            }}
                            backdrop={'static'}
                            modalTransition={{ timeout: 0 }}
                            backdropTransition={{ timeout: 0 }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">
                                    Edit FAQ
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => {
                                        setModalShow(!modalShow);
                                    }}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <Row>
                                    <Col>
                                        {faqToEdit && (
                                            <EditResearchClinicFAQ
                                                key={editCount}
                                                faqToEdit={faqToEdit}
                                                setModalShow={setModalShow}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </Modal>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default ResearchClinicFAQList;
