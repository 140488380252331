import Roles from 'src/pages/Roles';
import AddRole from 'src/pages/Roles/AddRole';
import AssignPermission from 'src/pages/Roles/AssignPermission';
import UpdateRole from 'src/pages/Roles/UpdateRole';

export const roleRoutes = [
  // roles
  { path: '/roles', component: Roles },
  { path: '/roles/add-role', component: AddRole },
  { path: '/roles/edit-role/:name', component: UpdateRole },
  { path: '/roles/assign-permission/:name', component: AssignPermission },
];
