import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface AuthState {
  token: string;
  menus: object;
  menu_permissions: object;
  user: {
    id: number;
    name: string;
    username: string;
    registration_no: string;
    email: string;
    type: string;
    phone: string;
    mobile: string;
    email_verified_at: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    details: {
      subject: {
        id: string;
        subject_code: string;
        subject_name: string;
      };
      bmdc_registration_no?: string;
      profile_approval_status?: string;
      bmdc_registration_no_verified?: string;
      personal_info: {
        picture?: string;
      };
      current_session_and_supervisor_info: {
        exam_training_id?: string;
        id?: string;
        logbook_entry_master_id?: string;
        note?: string;
        session_end_date?: string;
        session_start_date?: string;
        session_name?: string;
        supervisor: {
          email?: string;
          name?: string;
          institute_name?: string;
        };
        supervisor_id?: string;
      };
    };
  };
}

// Define the initial state using that type
const initialState: AuthState = {
  token: '',
  user: {} as any,
  menus: {} as any,
  menu_permissions: {} as any,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userLoggedIn: (state, action: PayloadAction<AuthState>) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.menus = action.payload.menus;
      state.menu_permissions = action.payload.menu_permissions;
    },
    userLogout: (state) => {
      state = initialState;
    },
  },
});

export const { userLoggedIn, userLogout } = authSlice.actions;
export default authSlice.reducer;
