import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Table,
} from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetApprovalsQuery } from 'src/rtk/features/setup/approval/approvalApi';
import { useGetApprovalDetailsQuery } from 'src/rtk/features/setup/approval/approvalDetails';
import { useGetUsersByPaginateQuery } from 'src/rtk/features/user/userApi';
import ApprovalDetailTableRow from './ApprovalDetailTableRow';

export const ApprovalDetail = () => {
  const { data, error, isLoading, isError } = useGetApprovalDetailsQuery();

  const err: CustomError = error as CustomError;
  const approvalDetails = data?.data || [];

  const users: User[] =
    useGetUsersByPaginateQuery({
      page: 1,
      paginate_per_page: 15,
    }).data?.data?.data || [];

  const approvals: Approval[] = useGetApprovalsQuery().data?.data || [];

  let content = null;
  if (isLoading) {
    content = <div className="mt-3">Loading...</div>;
  }
  if (!isLoading && isError && error) {
    content = <div className="mt-3">{err.message}</div>;
  }
  if (!isLoading && !isError && approvalDetails?.length > 0) {
    content = approvalDetails.map(
      (approvalDetail: ApprovalDetail, index: number) => (
        <ApprovalDetailTableRow
          approvalDetail={approvalDetail}
          index={index}
          key={approvalDetail.id}
          userName={
            users.find((user) => user.id === approvalDetail.user_id)?.name ?? ''
          }
          approvalName={
            approvals.find(
              (approval) => approval.id === approvalDetail.setup_approval_id,
            )?.page_name ?? ''
          }
        />
      ),
    );
  }
  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb title="Approval Details" />
        <Card>
          <CardHeader className="d-flex justify-content-end text-center align-items-center ">
            <Link to={'/setup/approval-details/add'}>
              <Button color="success" size="sm" outline className="rounded-5">
                <i className="bx bx-plus"></i> Add New
              </Button>
            </Link>
          </CardHeader>
          <CardBody>
            <div className="table-responsive text-nowrap text-center  x-overflow-scroll">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Sl.</th>
                    <th colSpan={4}>Approval Name</th>
                    <th>User</th>
                    <th>Reg. No.</th>
                    <th>Approve Status</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
