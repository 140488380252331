import 'flatpickr/dist/themes/material_blue.css';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Container, Form, Input, Label, Modal, Row } from 'reactstrap';
import { Button } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { getSelectFormatedData, setFromErrors } from 'src/helpers';
import * as url from 'src/helpers/url_helper';
import {
  useCreateByPostMutation,
  useGetDataByMutation,
  useGetDataQuery,
} from 'src/rtk/features/common/crudApi';
import ProfileView from './ProfileView';

export const RTMDAttendanceForm = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [attendanceDetails, setAttendanceDetails] = useState<any>({});
  const [userInfo, setUserInfo] = useState<any>({});
  const [applicantList, setApplicantList] = useState<any>([]);
  const [dayList, setDayList] = useState<any>([]);
  const [selectAll, setSelectAll] = useState<any>({});
  const [certificateDataset, setCertificateDataset] = useState<any>({});
  const [selectAllActive, setSelectAllActive] = useState<boolean>(true);
  // const [activeDay, setActiveDay] = useState<number>(1);
  const initialValues: any = {
    dataset: {},
  };
  const [
    getDataBy,
    {
      data: getApplicantList,
      isSuccess: isGetApplicantListSuccess,
      isLoading: isGetApplicantListLoading,
    },
  ] = useGetDataByMutation();

  useEffect(() => {
    if (
      getApplicantList &&
      !isGetApplicantListLoading &&
      isGetApplicantListSuccess
    ) {
      // setApplicantList(getApplicantList?.data || []);
    }
  }, [getApplicantList, isGetApplicantListLoading, isGetApplicantListSuccess]);

  const {
    data: setupWorkshopTrainingListData,
    // isLoading: mainTrainingListLoading,
    // isSuccess: mainTrainingListLoadingSuccess,
    // error: mainTrainingListLoadingError,
  } = useGetDataQuery(url.RTMD_WORKSHOP_TRAINING_LIST);

  const mainTrainingListDetails: any =
    setupWorkshopTrainingListData?.data || [];

  const mainTrainingList: any = setupWorkshopTrainingListData?.data
    ? getSelectFormatedData(
      setupWorkshopTrainingListData?.data,
      'id',
      'training_name',
    )
    : [];

  const {
    data: setupSuperVisorListData,
    isLoading: setupSuperVisorListLoading,
    // isSuccess: mainTrainingListLoadingSuccess,
    // error: mainTrainingListLoadingError,
  } = useGetDataQuery(url.RTMD_SUPERVISOR_LIST);

  const supterVisorList: any = setupSuperVisorListData?.data
    ? getSelectFormatedData(setupSuperVisorListData?.data, 'id', 'name')
    : [];

  // const [
  //   updateByPatch,
  //   { isSuccess: updateSucess, isError: updateError, error: updateErrorData },
  // ] = useUpdateByPatchMutation();

  const [createByPost, { isSuccess, isError, error }] =
    useCreateByPostMutation();
  const err: CustomError = error as CustomError;

  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    resetForm,
    setErrors,
    handleChange,
    values,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let postData = {
        queryUrl: url.RTMD_WORKSHOP_TRAINEE_ATTENDANCE_BATCH_BY_ID,
        formData: values,
      };

      // console.log(certificateDataset)
      // console.log(postData)
      createByPost(postData);
    },
  });

  const canIssueCertificate = (registration_no: any) => {
    let canIssue = true;
    let userAttendance = values['dataset'];
    let workshop_skill_details_id = '';

    dayList.map((dayIndex: any) => {
      let day = dayIndex + 1;
      let row = registration_no + '-' + day;
      let attendance_status = userAttendance?.[row]?.['attendance_status'] || false;
      workshop_skill_details_id = userAttendance?.[row]?.['workshop_skill_details_id'] || '';

      if (attendance_status === false) {
        canIssue = false;
      }
    });


    return canIssue;
  }

  const attendanceDataSetup = (batchApplicantList: [], dayList = []) => {
    let valuesDataset: any = {};

    batchApplicantList.map((user: any) => {

      dayList.map((dayIndex: any) => {
        let day = dayIndex + 1;
        let row = user['registration_id'] + '-' + day;
        let attendance_status = false;

        valuesDataset[row] = {
          day: day,
          certificate_status: false,
          attendance_status: attendance_status,
          registration_id: user['registration_id'],
          workshop_skill_details_id: user['id'],
        };
      });

      if (user.user_attendance.length > 0) {
        user.user_attendance.map((attendance: any) => {
          let row = attendance['registration_id'] + '-' + attendance['day'];
          let attendance_status = attendance['attendance_status'];
          let certificate_status = attendance['certificate_status'];

          valuesDataset[row] = {
            day: parseInt(attendance['day']),
            certificate_status: certificate_status,
            attendance_status: attendance_status,
            registration_id: attendance['registration_id'],
            workshop_skill_details_id: user['id'],
          };
        });
      }
    });
    setFieldValue('dataset', valuesDataset)
  };

  const { data: formData, isLoading: isAttendanceLoading, isError: isAttendanceFetchError } = useGetDataQuery(
    `${url.RTMD_WORKSHOP_TRAINING_BATCH_BY_ID}${id}`,
  );

  useEffect(() => {
    if (formData && !isAttendanceFetchError) {
      const data: any = (formData as any).data;
      let durationText = data?.workshop_training?.duration || '';

      let dayListArray: any = getNumberOfDays(durationText);

      if (data?.batch_user?.length > 0)
        setApplicantList(data?.batch_user);
      setAttendanceDetails(data);
      attendanceDataSetup(data?.batch_user, dayListArray);
    } else {
      setFieldValue('dataset', false)
    }
  }, [formData, isAttendanceFetchError]);

  // useEffect(() => {
  //   if (dayList) {
  //     attendanceDataSetup();
  //   }
  // }, [dayList]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Attendance save successfully');
      history.push('/rtmd/attendance/');
    }
    if (isError) {
      if (err.data?.message) {
        toast.error(err.data?.message || 'Something went wrong');
      } else {
        toast.error(err?.message || 'Something went wrong');
      }
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  const getDateForDay = (day: number) => {
    let retDate = '';
    // let trainerName = '';
    if (day !== undefined) {
      retDate = attendanceDetails?.batch_trainers[day]?.['training_date'] || '';
      // trainerName = attendanceDetails?.batch_trainers[day]?.['trainer_details']?.personal_info?.full_name || '';
      if (retDate) {
        retDate = moment(retDate).format("DD-MMM-YY, dddd")
      }

      // if (trainerName) {
      //   retDate += `<div class="text-capitalize">Trainer: ${trainerName}</div>`
      // }

    }

    return retDate;
  }

  const getDetailsData = (key = '', dataObj: any = {}) => {
    let perseData: any = {};

    if (dataObj['extra_info'] && typeof dataObj['extra_info'] === 'string') {
      perseData = JSON.parse(dataObj.extra_info);
    } else {
      perseData = dataObj;
    }

    return perseData[key] ? (
      <span dangerouslySetInnerHTML={{ __html: perseData[key] }}></span>
    ) : perseData[key.toLowerCase()] ? (
      <span dangerouslySetInnerHTML={{ __html: perseData[key] }}></span>
    ) : (
      ''
    );
  };

  const allApplicantAssign = (e: any) => {
    let dataset = e.target.dataset;
    let day = parseInt(dataset['day']);
    let isChecked = e.target.checked ? true : false;
    let checkboxId = e.target.getAttribute('id');
    let attendanceDataSet: any = values['dataset'];

    Object.keys(values['dataset']).map((row) => {
      let attDay = values['dataset'][row]['day'];
      if (attDay === day) {
        attendanceDataSet[row]['attendance_status'] = isChecked;
      }
    });
    setFieldValue('dataset', attendanceDataSet);
    setSelectAll({ ...selectAll, [checkboxId]: isChecked });
  };

  const isAttend = (row: any) => {
    let isCheck: any = values['dataset'][row]
      ? values['dataset'][row]['attendance_status']
        ? true
        : false
      : false;

    return isCheck;
  };

  const isAttendAll = (row: any) => {
    let isCheck: any = selectAll[row] ? selectAll[row] : false;
    return isCheck;
  };

  const applicantAssign = (elm: any) => {
    let dataSet = elm.target.dataset;
    let isChecked = elm.target.checked ? true : false;
    let row = dataSet['row'];

    values['dataset'][row] = {
      day: parseInt(dataSet['day']),
      attendance_status: isChecked,
      registration_id: dataSet['registration'],
      workshop_skill_details_id: dataSet['workshop_skill_details_id'],
    };

    let checkboxAllId = 'required-switch-all-' + parseInt(dataSet['day']);
    setSelectAll({ ...selectAll, [checkboxAllId]: false });
  };

  const allApplicantGetCertificate = (elm: any) => {
    let isChecked = elm.target.checked ? true : false;
    let certificateData = certificateDataset;
    let allCheckboxCertificate = document.querySelectorAll('.checkbox-certificate-issue');

    Object.keys(allCheckboxCertificate).map((checkElemKey: any) => {
      let elm: any = allCheckboxCertificate[checkElemKey];
      let row = elm.getAttribute('data-registration');
      certificateData[row] = {
        certificate_status: isChecked,
        registration_id: elm.getAttribute('data-registration'),
        workshop_skill_details_id: elm.getAttribute('data-workshop_skill_details_id'),
      };
    });

    // if (isChecked) {
    //   Object.keys(allCheckboxCertificate).map((checkElemKey: any) => {
    //     let elm: any = allCheckboxCertificate[checkElemKey];
    //     let row = elm.getAttribute('data-registration');
    //     certificateData[row] = {
    //       certificate_status: isChecked,
    //       registration_id: elm.getAttribute('data-registration'),
    //       workshop_skill_details_id: elm.getAttribute('data-workshop_skill_details_id'),
    //     };
    //   })
    // } else {
    //   certificateData = {};
    // }

    setCertificateDataset(certificateData);
    setFieldValue('certificateDataset', certificateData);
  };

  const applicantGetCertificate = (elm: any) => {
    let dataSet = elm.target.dataset;
    let isChecked = elm.target.checked ? true : false;
    let row = dataSet['registration'];
    let certificateData = certificateDataset;

    certificateData[row] = {
      certificate_status: isChecked,
      registration_id: dataSet['registration'],
      workshop_skill_details_id: dataSet['workshop_skill_details_id'],
    };

    // if (isChecked) {
    //   certificateData[row] = {
    //     certificate_status: isChecked,
    //     registration_id: dataSet['registration'],
    //     workshop_skill_details_id: dataSet['workshop_skill_details_id'],
    //   };
    // } else if (certificateData[row]) {
    //   delete certificateData[row];
    // }


    setCertificateDataset(certificateData)
    setFieldValue('certificateDataset', certificateDataset)
  };

  const applicantGetCertificateYes = (row: any, certification_status: any) => {
    let certificateData = values['certificateDataset'];
    let isCheck: any = certificateData?.[row]
      ? certificateData?.[row]?.['certificate_status'] ? true : false
      : 
      certification_status ? true : false;

    return isCheck;
  };

  const getNumberOfDays = (durationText: string = '') => {
    durationText = durationText ? durationText : '3 day';
    if (durationText) {
      let dayDurationUnit = 1;
      let day = parseInt(durationText);
      let duration = durationText.split(' ')[1];

      if (duration.toLowerCase() === 'month') {
        dayDurationUnit = 30;
      } else if (duration.toLowerCase() === 'week') {
        dayDurationUnit = 7;
      } else if (duration.toLowerCase() === 'year') {
        dayDurationUnit = 365;
      }
      let numberOfDays = day * dayDurationUnit;
      let dayArray = Array.from(Array(numberOfDays).keys());
      setDayList(dayArray);

      return dayArray
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        {Object.keys(attendanceDetails).length > 0 && (
          <BetterBreadcrumb
            title={attendanceDetails?.batch_no + ' Attendance'}
            breadcrumbItem={[
              {
                link: '/dashboard',
                name: 'Dashboard',
              },
              {
                link: '/rtmd/attendance/',
                name: 'Training/Workshop Attendance',
              },
            ]}
          />
        )}
        <div className="row align-items-center justify-content-center">
          <div className="card">
            {/* <h5 className="card-header">
              <i className="fa fa-eye me-2" />
              {attendanceDetails?.batch_no || ''} Attendance
            </h5> */}
            <div className="d-flex card-header">
              <h3 className="card-title mb-0 flex-grow-1">
                <i className="fa fa-plus me-2" />
                {attendanceDetails?.batch_no || ''} Attendance
              </h3>
              <div className="flex-shrink-0 align-self-end">
                <Link
                  to="/rtmd/attendance/"
                  className="btn btn-success waves-effect waves-light"
                >
                  <i className="fas fa-arrow-left me-2" /> Back
                </Link>
              </div>
            </div>

            <div className="card-body">
              {!isAttendanceLoading && (
                <Form
                  autoComplete="off"
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <>
                    <Row>
                      <div className="col-lg-12 col-sm-12 ">
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered border-dark ">
                            <thead className="bg-light fixed">
                              <tr className="text-center text-dark font-size-16 fw-bold">
                                <td colSpan={20}>Batch Details</td>
                              </tr>
                            </thead>

                            <tbody className="text-capitalize  text-dark font-size-14 fw-bold">
                              <tr>
                                <td className="bg-light">Batch no: </td>
                                <td>{attendanceDetails?.batch_no || ''}</td>
                                <td className="bg-light">Duration: </td>
                                <td>
                                  {attendanceDetails?.workshop_training
                                    ?.duration || ''}
                                </td>

                                <td className="bg-light">Training For: </td>
                                <td>
                                  {attendanceDetails?.workshop_training
                                    ?.training_for || ''}
                                </td>
                              </tr>
                              <tr>
                                <td className="bg-light">Training Title: </td>
                                <td colSpan={3}>
                                  {attendanceDetails?.workshop_training
                                    ?.training_name || ''}
                                </td>
                                <td className="bg-light">Training Type: </td>
                                <td>
                                  {attendanceDetails?.workshop_training
                                    ?.training_type || ''}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {attendanceDetails?.batch_user?.length > 0 &&
                          dayList.length > 0 && (
                            <div
                              className="table-responsive mb-3 border border-dark overflow-auto"
                              style={{ maxHeight: '60vh' }}
                            >
                              <table className="table table-sm  table-bordered border-light table-hover">
                                <thead className="bg-light fixed">
                                  <tr className="text-center align-text-top text-dark font-size-12 fw-bold">
                                    <td>SL#</td>
                                    <td>Name</td>
                                    <td>BMDC Reg. NO</td>
                                    <td>Mobile</td>
                                    {dayList.length > 0 &&
                                      dayList.map((day: any) => {
                                        return (
                                          <td
                                            key={Math.random()}
                                            className="text-center"
                                          >
                                            <div className=" ">
                                              <div className="mx-2 mb-2">
                                                Day {day + 1}
                                              </div>

                                              <div className="mx-2 mb-2">
                                                <div
                                                  dangerouslySetInnerHTML={{ __html: getDateForDay(day) }}
                                                ></div>
                                              </div>

                                              {/* {selectAllActive && ( */}
                                              <>
                                                <Input
                                                  key={
                                                    'required-switch-all-' +
                                                    (day + 1)
                                                  }
                                                  type="checkbox"
                                                  id={
                                                    'required-switch-all-' +
                                                    (day + 1)
                                                  }
                                                  data-day={day + 1}
                                                  switch="success"
                                                  onChange={(e) => {
                                                    allApplicantAssign(e);
                                                  }}
                                                  defaultChecked={isAttendAll(
                                                    'required-switch-all-' +
                                                    (day + 1),
                                                  )}
                                                />
                                                <Label
                                                  htmlFor={
                                                    'required-switch-all-' +
                                                    (day + 1)
                                                  }
                                                  data-on-label="All"
                                                  data-off-label="No"
                                                ></Label>
                                              </>
                                              {/* )} */}
                                            </div>
                                          </td>
                                        );
                                      })}

                                    <td>Get Certificate?

                                      <div>
                                        <Input
                                          key={
                                            'certificate-issue-all'
                                          }
                                          type="checkbox"
                                          id={
                                            'certificate-issue-all'
                                          }
                                          switch="success"
                                          onChange={(e) => {
                                            allApplicantGetCertificate(e);
                                          }}
                                          defaultChecked={false}
                                        />
                                        <Label
                                          htmlFor={
                                            'certificate-issue-all'
                                          }
                                          data-on-label="All"
                                          data-off-label="No"
                                        ></Label>
                                      </div>
                                    </td>
                                    <td>Details</td>
                                  </tr>
                                </thead>

                                <tbody>
                                  {attendanceDetails?.batch_user.map(
                                    (item: any, key: number) => {
                                      return (
                                        <tr
                                          key={item.id}
                                          className="align-text-top text-center text-capitalize"
                                        >
                                          <td style={{ width: '50px' }}>
                                            <span
                                              style={{
                                                width: '20px',
                                                height: '20px',
                                              }}
                                              className="avatar-title rounded-circle bg-soft-info text-dark font-size-12 m-2"
                                            >
                                              {key + 1}
                                            </span>
                                          </td>
                                          <td>
                                            {
                                              item?.user_details
                                                ?.personal_info?.full_name
                                            }
                                          </td>

                                          <td>
                                            {
                                              item?.user_details
                                                ?.bmdc_registration_no
                                            }
                                          </td>

                                          <td>
                                            {
                                              item?.user_details
                                                ?.personal_info?.mobile
                                            }
                                          </td>
                                          {/* 
                                          <td>
                                            <div className="text-center ">
                                              <div className="font-size-12 border-bottom border-dark fw-bold text-capitalize text-nowrap">
                                                {
                                                  item?.user_details
                                                    ?.personal_info?.full_name
                                                }
                                              </div>
                                              <div className="text-muted font-size-10 text-nowrap">
                                                <b>BMDC Reg. NO:</b>{' '}
                                                {
                                                  item?.user_details
                                                    ?.bmdc_registration_no
                                                }
                                              </div>
                                            </div>
                                                </td>
                                                 */}
                                          {dayList.length > 0 &&
                                            dayList.map((day: any) => {
                                              return (
                                                <td
                                                  key={'day-uniq-' + (day + 1)}
                                                  className="text-center align-middle"
                                                >
                                                  {isAttend(
                                                    item?.user_details
                                                      ?.registration_no +
                                                    '-' +
                                                    (day + 1),
                                                  )}
                                                  <div className=" ">
                                                    <Input
                                                      key={Math.random()}
                                                      className={
                                                        'checkbox-attendance-day-' +
                                                        (day + 1) +
                                                        ' checkbox-attendance'
                                                      }
                                                      type="checkbox"
                                                      id={
                                                        item?.user_details
                                                          ?.registration_no +
                                                        '-' +
                                                        (day + 1)
                                                      }
                                                      data-registration={
                                                        item?.user_details
                                                          ?.registration_no ||
                                                        ''
                                                      }
                                                      data-workshop_skill_details_id={
                                                        item?.id || ''
                                                      }
                                                      data-day={day + 1}
                                                      // data-row={key}
                                                      data-row={
                                                        item?.user_details
                                                          ?.registration_no +
                                                        '-' +
                                                        (day + 1)
                                                      }
                                                      switch="bool"
                                                      onChange={(e) => {
                                                        applicantAssign(e);
                                                      }}
                                                      // defaultChecked={true}
                                                      defaultChecked={isAttend(
                                                        item?.user_details
                                                          ?.registration_no +
                                                        '-' +
                                                        (day + 1),
                                                      )}

                                                    // disabled={true}
                                                    />
                                                    <Label
                                                      htmlFor={
                                                        item?.user_details
                                                          ?.registration_no +
                                                        '-' +
                                                        (day + 1)
                                                      }
                                                      data-on-label="Yes"
                                                      data-off-label="No"
                                                    ></Label>
                                                  </div>
                                                </td>
                                              );
                                            })}
                                          <td className="text-center">
                                            {canIssueCertificate(item?.user_details
                                              ?.registration_no) && (
                                                <div>
                                                  <Input
                                                    key={
                                                      'certificate-issue-' +
                                                      item?.user_details
                                                        ?.registration_no

                                                    }
                                                    type="checkbox"
                                                    className="checkbox-certificate-issue"
                                                    id={
                                                      'certificate-issue-' +
                                                      item?.user_details
                                                        ?.registration_no
                                                    }
                                                    data-registration={
                                                      item?.user_details
                                                        ?.registration_no ||
                                                      ''
                                                    }
                                                    data-workshop_skill_details_id={
                                                      item?.id || ''
                                                    }
                                                    switch="success"
                                                    onChange={(e) => {
                                                      applicantGetCertificate(e);
                                                    }}
                                                    checked={applicantGetCertificateYes(
                                                      item?.user_details
                                                        ?.registration_no,
                                                      item?.certification_status
                                                    )}
                                                  // defaultChecked={false}
                                                  />
                                                  <Label
                                                    htmlFor={
                                                      'certificate-issue-' +
                                                      item?.user_details
                                                        ?.registration_no
                                                    }
                                                    data-on-label="Yes"
                                                    data-off-label="No"
                                                  ></Label>
                                                </div>
                                              )}

                                            {!canIssueCertificate(item?.user_details
                                              ?.registration_no) && (<>
                                                <span className='badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium'>Did Not Attend 100%</span>
                                              </>)}
                                          </td>
                                          <td className="text-center">
                                            <Button
                                              type="button"
                                              size="sm"
                                              text={'View'}
                                              onClick={() => {
                                                setShowModal(true);
                                                setUserInfo(item);
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      );
                                    },
                                  )}
                                </tbody>
                              </table>
                            </div>
                          )}
                      </div>

                      {dayList.length > 0 && attendanceDetails?.batch_user?.length > 0 && (
                        <div className="col-lg-12 col-sm-12 ">
                          <div className="">
                            <Button
                              className="w-25"
                              type="submit"
                              text={'Save'}
                            />
                          </div>
                        </div>
                      )}
                      {/* <pre>{JSON.stringify(values['certificateDataset'], null, 2)}</pre> */}
                    </Row>

                    <Modal
                      onOpened={function () {
                        toast.dismiss();
                      }}
                      isOpen={showModal}
                      scrollable={false}
                      size="lg"
                      toggle={() => {
                        document.body.classList.add('no_padding');
                      }}
                      backdrop={'static'}
                      modalTransition={{ timeout: 0 }}
                      backdropTransition={{ timeout: 0 }}
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title text-capitalize"
                          id="staticBackdropLabel"
                        >
                          {userInfo.user_details?.personal_info?.full_name}{' '}
                          Profile View
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={() => {
                            setShowModal(!showModal);
                          }}
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body py-0 pb-4 mt-3">
                        <ProfileView
                          attachments={userInfo.attachments}
                          userInfo={userInfo.user_details}
                          paymentDetails={userInfo.payment_details}
                          trainingId={userInfo.workshop_skill_master_id}
                        />
                      </div>
                      <div className="modal-footer ">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            setShowModal(!showModal);
                          }}
                          aria-label="Close"
                        >Close</button>
                      </div>
                    </Modal>
                  </>
                </Form>
              )}

              {isAttendanceLoading && (
                <>
                  <div className="text-center">Loading...</div>
                </>
              )}
              {!isAttendanceLoading && values['dataset'] === false && (
                <>
                  <div className="alert-outline alert alert-danger text-center">There is no applicant select for this batch. Please select applicant for attendance.</div>
                </>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
