import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import { useGetDepartmentsQuery } from 'src/rtk/features/setup/department/departmentApi';
import { useGetLookupChildQuery } from 'src/rtk/features/setup/lookup/lookupChildApi';
import { useDeleteNoticeTemplateAttachmentMutation, useGetNoticeTemplateAttachmentQuery, useGetNoticeTemplateAttachmentsQuery } from 'src/rtk/features/setup/noticeTemplateAttachment/setupNoticeTemplateAttachmentApi';
import { useDeleteNoticeTemplateListMutation } from 'src/rtk/features/setup/noticeTemplateList/setupNoticeTemplateListApi';

interface Props {
  noticeTemplateList: NoticeTemplateList;
  index: number;
}

const NoticeTemplateTableRow = ({ noticeTemplateList, index }: Props) => {
  const history = useHistory();
  
  const [infoId, setInfoId] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const [deleteNoticeTemplateList, { isSuccess, isLoading, isError }] =
    useDeleteNoticeTemplateListMutation();  
  const { data } = useGetDepartmentsQuery();  
  const departments = data?.data;  

  const { data: noticeTypeLookup, isError: isNoticeTypeLookupFetchError } =
        useGetLookupChildQuery(noticeTemplateList.notice_type!);

  const deleteHandler = (modalState: boolean, noticeTemplateId: string) => {
    setModalShow(modalState);
    setInfoId(noticeTemplateId);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Notice template deleted successfully');
    }
    if (isError) {
      toast.error('Error deleting notice template');
    }
  }, [isSuccess, isError]);

  return (
          <tr key={noticeTemplateList.id}>
            <th scope="row">{index + 1}</th>
            <td>{noticeTypeLookup?.data.lookup_list_name}</td>    
            <td>
              {
                departments?.find((d) => d.id === noticeTemplateList.department_id)
                  ?.department_name
              }
            </td>
            <td>{noticeTemplateList.notice_subject}</td>
            <td>{noticeTemplateList.notice_header}</td>
            <td>{noticeTemplateList.notice_body}</td>
            <td>{noticeTemplateList.notice_footer}</td> 
            <td>{noticeTemplateList.status ? 'Active' : 'Inactive'}</td>                                    
            <td>
              <div className="d-flex gap-2">
                <Button
                  size="sm"
                  text="Edit"
                  color="warning"
                  onClick={() =>
                    history.push(`/setup/notice-template-list/update/${noticeTemplateList.id}`)
                  }
                />

                <Button
                  size="sm"
                  text="Delete"
                  color="danger"
                  disabled={isLoading}
                  isLoading={isLoading}
                  onClick={() => deleteHandler(true, noticeTemplateList.id!)}
                />

                <Button
                  size="sm"
                  text="Attachments"
                  color="info"
                  disabled={isLoading}
                  isLoading={isLoading}
                  onClick={() => 
                    history.push(`/setup/notice-attachment-list/${noticeTemplateList.id}`)
                  }
                />
              </div>
              {modalShow ? (
                <DeleteInformationModal
                  isOpen={modalShow}
                  toggle={() => setModalShow(!modalShow)}
                  id={infoId}
                  deleteInfomation={deleteNoticeTemplateList}
                />
              ) : null}
            </td>
          </tr>
  );
};

export default NoticeTemplateTableRow;
