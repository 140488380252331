import { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Button, Container, FormGroup } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetGuideCoGuideListQuery } from 'src/rtk/features/guide/guideCoguideApi';
import EditGuideCoGuideApply from './EditGuideCoGuideApply';
import GuideCoGuideApply from './GuideCoGuideApply';

const GuideList = () => {
  const [guideCoGuideId, setGuideCoGuideId] = useState('');

  const [showAddForm, setShowAddForm] = useState<boolean>(true);

  const {
    data: guideList,
    isSuccess,
    isLoading,
  } = useGetGuideCoGuideListQuery();
  // console.log(guideList);

  const editHandler = (id: string) => {
    setGuideCoGuideId(id);
    setShowAddForm(false);
  };

  const columns: TableColumn<any>[] = [
    {
      name: <div>Sl. No.</div>,
      cell: (row, index) => index + 1,
      width: '80px',
    },

    {
      name: <div>BMDC Reg No</div>,
      selector: (row) => row?.registration?.bmdc_registration_no,
      sortable: false,
    },

    {
      name: <div>Name</div>,
      selector: (row) => row?.registration?.personal_info?.full_name,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Institute</div>,
      selector: (row) => row?.registration?.current_employment_info?.institute_name ?? 'N/A',
      sortable: false,
      wrap: true,
      width: '180px',
    },

    {
      name: <div>Institute Location</div>,
      width: '150px',
      selector: (row) =>
        row?.registration?.current_employment_info?.working_address ?? 'N/A',
      sortable: false,
    },

    {
      name: <div>Department</div>,
      selector: (row) => row?.registration?.current_employment_info?.department_name ?? 'N/A',
      sortable: false,
    },
    {
      name: <div>Designation</div>,
      selector: (row) => row?.registration?.current_employment_info?.designation,
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Applied For</div>,
      selector: (row) => row?.payment_for,
      sortable: false,
    },
    {
      name: <div>Status</div>,
      selector: (row) => row?.payment_verification_status,
      cell: (row) => {
        switch (row?.payment_verification_status) {
          case 'Reject':
            return (
              <>
                <div className="badge badge-soft-danger font-size-12">
                  {'Rejected'}
                </div>
              </>
            );

          case 'Approve':
            return (
              <>
                <div className="badge badge-soft-success font-size-12">
                  {'Approved'}
                </div>
              </>
            );

          default:
            return (
              <>
                <div className="badge badge-soft-danger font-size-12">
                  {row?.payment_verification_status}
                </div>
              </>
            );
        }
      },
    },

    // {
    //   name: 'Action',
    //   cell: (row) => (
    //     <>
    //       <FormGroup className="mt-3 d-flex gap-1">
    //         {row.order_submit_type === 'Draft' ? (
    //           <Button
    //             className="btn btn-sm btn-info"
    //             onClick={() => editHandler(row?.id!)}
    //           >
    //             Edit
    //           </Button>
    //         ) : (
    //           <div className="badge rounded-pill d-block mb-1  badge-soft-success font-size-12 fw-medium">
    //             {'Submitted'}
    //           </div>
    //         )}
    //       </FormGroup>
    //     </>
    //   ),

    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          breadcrumbItem={[{ link: '/', name: 'Dashboard' }]}
          title={'Apply for Guide/Co-Guide'}
        />
        {showAddForm ? (
          <GuideCoGuideApply />
        ) : (
          <EditGuideCoGuideApply
            id={guideCoGuideId}
            setShowAddForm={setShowAddForm}
          />
        )}
        <div className="card mt-2">
          {/* <div className="card-title rounded text-center bg-secondary text-white py-2 m-2">
            Guide/Co-Guide List
          </div> */}
          <div className="card-body">
            <DataTable
              columns={columns}
              data={guideList!}
              progressPending={isLoading}
              expandOnRowClicked
              highlightOnHover
              responsive
              persistTableHead={true}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default GuideList;
