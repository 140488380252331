import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { Container, Form, Label, FormGroup, Input } from 'reactstrap';
import { InputField, Button } from 'src/components';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { setFromErrors } from 'src/helpers';
import { useGetLogBookMasterSetupsQuery } from 'src/rtk/features/setup/logBookMasterSetup/logBookMasterSetupApi';
import { useHistory } from 'react-router-dom';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useCreateLogbookTableOfContentMutation, useGetLogbookTableOfContentsQuery } from 'src/rtk/features/setup/logBookTableOfContentSetup/logBookTableOfContentSetupApi';

const initialValues: LogbookTableOfContent = {
    id: '',
    setup_logbook_master_id: '',
    details: '',
    note: '',
    table_of_contents_details_id: '',
    page_no: 0,
    sl_no: 0,
    status: true,
    creator_organization_id: '',
    updater_organization_id: '',
    created_by: '',
    updated_by: '',
    deleted_by: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
};

export const AddLogbookTableOfContent = () => {
  const [createLogbookTableOfContent, { isSuccess, isError, error }] = useCreateLogbookTableOfContentMutation();

  const { data: logbookMastersData } = useGetLogBookMasterSetupsQuery();
  const logbookMasters = logbookMastersData?.data || [];

  const { data: logbookTableOfContentsData } = useGetLogbookTableOfContentsQuery();
  const logbookTableOfContents = logbookTableOfContentsData?.data || [];

  const history = useHistory();

  const err: CustomError = error as CustomError;
  const { handleSubmit, getFieldProps, values, touched, errors, resetForm, setErrors } =
    useFormik({
      initialValues,
      validationSchema: Yup.object({
        setup_logbook_master_id: Yup.string().required('Please select the logbook master'),        
      }),

      onSubmit: (values) => {
        createLogbookTableOfContent({
          ...values,
        });
        console.log(values);
      },
    });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Logbook table of content created successfully');
      history.push('/setup/logbook-table-of-content');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);
  
  let logbookMasterOptions: SelectOption[] = [    
    ...logbookMasters?.map((l: LogBookSetupMasters) => ({
      label: l.logbook_heading!,
      value: l.id!,
    })),
  ];

  let logbookTableOfContentOptions: SelectOption[] = [    
    ...logbookTableOfContents?.map((l: LogbookTableOfContent) => ({
      label: l.details!,
      value: l.id!,
    })),
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Add Logbook Table of Content'}
          breadcrumbItem={[{ link: '/setup/logbook-table-of-content/', name: 'Table of Contents' }]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="card mt-3">
            <h5 className="card-header">
              <i className="fa fa-plus me-2" />
              Add Logbook Table of Content
            </h5>
            <div className="card-body">
              <Form
                className="custom-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="row">                  
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="setup_logbook_master_id">Setup Logbook Master</Label>
                      <InputField
                        type="select"
                        placeholder="Logbook Master Heading"
                        options={logbookMasterOptions}
                        invalid={{ errors, touched }}
                        {...getFieldProps('setup_logbook_master_id')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="details">Details</Label>
                      <InputField
                        type="textarea"
                        placeholder="Write here..."
                        invalid={{ errors, touched }}
                        {...getFieldProps('details')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="note">Note</Label>
                      <InputField
                        type="text"
                        placeholder="Note"
                        invalid={{ errors, touched }}
                        {...getFieldProps('note')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="table_of_contents_details_id">Table of Content Details</Label>
                      <InputField
                        type="select"
                        placeholder="Table of Content Details"
                        options={logbookTableOfContentOptions}
                        invalid={{ errors, touched }}
                        {...getFieldProps('table_of_contents_details_id')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="page_no">Page No</Label>
                      <InputField
                        type="number"
                        placeholder="Page No"
                        invalid={{ errors, touched }}
                        {...getFieldProps('page_no')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="sl_no">Sl No</Label>
                      <InputField
                        type="number"
                        placeholder="Sl No"
                        invalid={{ errors, touched }}
                        {...getFieldProps('sl_no')}
                      />
                    </FormGroup>
                  </div>                  

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup check className="mb-3 mt-4">
                      <Label check>
                        <Input type="checkbox" checked={values.status} {...getFieldProps('status')} />{' '}
                        Status
                      </Label>
                    </FormGroup>
                  </div>
                </div>
                <Button type="submit" text="Submit" />
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
