import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import { InputField } from 'src/components';
import { config } from 'src/config';
import store from 'src/rtk/app/store';
import {
    useLazyGetFcpsPartOneInfoQuery,
} from 'src/rtk/features/educational/educationalApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';

interface Props {
    isOpen: boolean;
    toggle: any;
    workshopName: string;
    type: string;
    fees: string;
    id: string;
}

const WorkshopListModal = (props: Props) => {
    const { isOpen, toggle, workshopName, type, fees, id } = props;

    const currentYear = new Date().getFullYear();

    //   const [courseListInfo, setCourseListInfo] = useState<FcpsCourseList[]>([]);
    const [onlinePayment, setOnlinePayment] = useState<boolean>(true);
    const [offlinePayment, setOfflinePayment] = useState<boolean>(false);
    const [selectedSession, setSelectedSession] = useState<string>('');
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [undertakingModalShow, setUndertakingModalShow] = useState<boolean>(true);
    const [agreeState, setAgreeState] = useState<boolean>(false);

    const { data: sessionLookupData } = useGetLookupDataQuery('session');

    const sessionList: SelectOption[] =
        (sessionLookupData as any)?.data[0]?.childs?.map((item: any) => ({
            value: item.name,
            label: item.name,
        })) || [];

    const years = [];
    const startYear = currentYear - 20;

    for (let year = currentYear; year >= startYear; year--) {
        years.push(year);
    }

    const allYears: SelectOption[] = years?.map((item: any) => ({
        value: item,
        label: item,
    }));

    const [getFcpsPartOneInfo, { data: fcpsPart,
        isSuccess: isFcpsPartSuccess,
        isError: isFcpsPartError,
        isLoading: isFcpsPartLoading,
        error: fcpsPartError
    }] = useLazyGetFcpsPartOneInfoQuery();

    useEffect(() => {
        getFcpsPartOneInfo(store.getState().auth.user.details.bmdc_registration_no!);
    }, []);

    useEffect(() => {
        if (isFcpsPartSuccess && !isFcpsPartError) {
            setSelectedSession(fcpsPart?.data?.passing_session ?? '');
            setSelectedYear(fcpsPart?.data?.passing_year ?? currentYear);
        }
    }, [isFcpsPartSuccess, isFcpsPartError]);

    /* const [createFcpsCourse, { data: fcpsCourseData, isSuccess, isError, error }] =
       useGetFcpsCourseListMutation();   
   
     let supervisorOptions: SelectOption[] = [...supervisorList?.map((e: Supervisors) => ({label: e.name!, value: e.id!}))]
   
     const err: CustomError = error as CustomError;
   
     useEffect(() => {
       createFcpsCourse(workshopName);           
     }, []);
   
     useEffect(() => {
       if (isSuccess) { 
         setCourseListInfo(fcpsCourseData?.data!);                                    
       }
       if (isError) {
         toast.error(err?.message || 'Something went wrong');         
       }
     }, [isSuccess, isError]);  */

    return (
        <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="md">
            <ModalHeader toggle={toggle}>Apply for {type} -</ModalHeader>

            <ModalBody>
                <div className='table-responsive'>
                    <table className='table table-bordered'>
                        <tbody>
                            <tr>
                                <td><strong>Course Title: </strong></td>
                                <td>
                                    {workshopName}
                                </td>
                            </tr>

                            <tr>
                                <td><strong>FCPS Part-1 Passing Session: </strong></td>
                                <td>
                                    <InputField
                                        className='form-select-sm'
                                        type='select'
                                        name='passing_session'
                                        options={sessionList}
                                        onChange={(e: any) => {
                                            setSelectedSession(e.target.value);
                                        }}
                                        value={selectedSession}
                                        disabled={fcpsPart?.data?.passing_session}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td><strong>FCPS Part-1 Passing Year: </strong></td>
                                <td>
                                    <InputField
                                        className='form-select-sm'
                                        type='select'
                                        name='passing_session'
                                        options={allYears}
                                        onChange={(e: any) => {
                                            setSelectedYear(e.target.value);
                                        }}
                                        value={selectedYear}
                                        disabled={fcpsPart?.data?.passing_year}
                                    />
                                </td>
                                {/* <td>
                        <Select
                            isClearable={true}
                            isSearchable={true}
                            value={allYears.find(
                                (option: any) =>
                                    option.value === selectedYear,
                                )}
                            onChange={setSelectedYear}
                            options={allYears}
                            placeholder='Passing Year'
                            name="passing_year"
                        />
                        </td> */}
                            </tr>

                            <tr>
                                <td><strong>Fees: </strong></td>
                                <td>
                                    {fees}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <a href='#' className='font-size-14 text-decoration-underline' onClick={(e) => {
                    setUndertakingModalShow(!undertakingModalShow);
                }}>
                    Declaration
                </a>

                {undertakingModalShow ? (
                    <div className='border border-dark justified-text p-3'>
                        <h3>DECLARATION</h3>
                        <p>
                            I undertake to keep the BCPS informed about my training every six months and submit progress report from my trainer! supervisor after every six months period of training and within the time stipulated by the BCPS. I undertake to inform the BCPS in case of transfer/change of supervisor. I understand that training undertaken by me without registration and prior information and training for which progress report has not been submitted within the stipulated time will not be accepted by the BCPS for the purpose of appearing in BCPS examinations. I further understand that day to day activities during my training period should be entered into the Logbook collected from BCPS.

                            I understand that I have to complete my dissertation at least 6 months before I intend to appear in the FCPS part-II examination. I undertake to complete the compulsory training for dissertation writing as soon as possible.
                        </p>
                        <FormGroup check inline>
                            <Input
                                id='agreeCheck'
                                name='agreeCheck'
                                type='checkbox'
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setAgreeState(true);
                                    } else {
                                        setAgreeState(false);
                                    }
                                }}
                                checked={agreeState}
                            />

                            <Label check htmlFor="agreeCheck">
                                I agree
                            </Label>
                        </FormGroup>
                    </div>
                ) : null}

                {/* <div className="col-sm-12 mb-2">
                        <span className="fw-semibold">Supervisors: </span>
                        <span>
                            <InputField
                                name='supervisors'
                                id='supervisors'
                                className='form-select'                                        
                                type='select'                                        
                                options={supervisorOptions}                                                                              
                            />
                        </span>
                    </div>                             */}
                <table className='table table-bordered mt-2'>
                    <tr>
                        <td><strong>Payment Mode:</strong></td>
                        <td>
                            <Form
                                className="custom-form"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                                onReset={(e) => {
                                    e.preventDefault();
                                }}
                            >

                                <FormGroup check inline>
                                    <Input
                                        id='paymentMode1'
                                        name='payment_mode'
                                        type='radio'
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setOnlinePayment(true);
                                                setOfflinePayment(false);
                                            }
                                        }}
                                        checked={onlinePayment}
                                        value={'Online'}
                                    />

                                    <Label check for="paymentMode1">
                                        Online
                                    </Label>
                                </FormGroup>

                                <FormGroup check inline>
                                    <Input
                                        id='paymentMode2'
                                        name='payment_mode'
                                        type='radio'
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setOnlinePayment(false);
                                                setOfflinePayment(true);
                                            }
                                        }}
                                        checked={offlinePayment}
                                        value={'Offline'}
                                    />

                                    <Label check for="paymentMode2">
                                        Offline
                                    </Label>
                                </FormGroup>
                            </Form>
                        </td>
                    </tr>
                </table>
                {onlinePayment ? <img src='/images/payment_gateways.png' width={'470px'} /> : null}
            </ModalBody >

            <ModalFooter>
                <div className="d-flex gap-2">
                    <Button color="danger" onClick={toggle}>
                        Close
                    </Button>
                    {
                        offlinePayment ? (
                            <Link to={{ pathname: `/trainee/offline-payment/${'Workshop'}/${id}/${fees}` }} className={`${selectedSession !== '' && agreeState ? 'btn btn-success' : 'btn btn-success disabled'}`}>
                                Proceed for Payment
                            </Link>
                        ) : null
                    }

                    {
                        onlinePayment ? (
                            <a href={`${config.APP_URL}fcps-online-payment/${id}/${store.getState().auth.user.id}/${fees}`} rel='noreferrer' target={'_blank'} className={`${selectedSession !== '' && agreeState ? 'btn btn-success' : 'btn btn-success disabled'}`}>
                                Proceed for Payment
                            </a>
                        ) : null
                    }

                </div>
            </ModalFooter>
        </Modal >
    );
};

export default WorkshopListModal;
