import { useRef, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { Label } from "reactstrap";
import { config } from "src/config";

const Attachments = (props: any) => {
  const { attachmentInputList, setInputList } = props;

  const [plusButtonDisabled, setPlusButtonDisabled] = useState<boolean>(true);
  const [uploadedFile, setUploadedFile] = useState<string>('');
  const uploadedFileReference = useRef<any>();

  const handleAddFields = () => {
    const values = [...attachmentInputList];

    values.push({
      attachmentType: 'Attachment',
      attachmentFile: uploadedFile ? uploadedFile : '',
      userId: 0,
      signatorySerial: 0,
    });

    setInputList(values);
    setPlusButtonDisabled(true);
    setUploadedFile('');
    uploadedFileReference.current.value = '';
  };

  const handleRemoveFields = (index: number) => {
    const values = [...attachmentInputList];
    values.splice(index, 1);
    setInputList(values);
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    if (event.target.files[0]) {
      setPlusButtonDisabled(false);
      setUploadedFile(event.target.files[0]);
    } else {
      setPlusButtonDisabled(true);
      setUploadedFile('');
    }
  };

  console.log(attachmentInputList);

  const columns: TableColumn<any>[] = [
    {
      name: <div className="text-center">Sl. No.</div>,
      width: '100px',
      cell: (row, index) => index + 1,
      center: true,
    },   

    {
      name: <div className="text-center">File Preview</div>,
      selector: (row) => row.attachmentFile && (
        typeof row.attachmentFile === 'string' ? (
          <a
            className="me-2 p-0 center"
            href={`${config?.APP_URL}${row.attachmentFile}`}
            rel="noreferrer"
            target="_blank"
          >
            <span className="btn-outline-info btn-sm btn ms-2">View</span>
          </a>
        ) : (
          <a
            className="me-2 p-0 center"
            href={URL.createObjectURL(row.attachmentFile)}
            rel="noreferrer"
            target="_blank"
          >
            <span className="btn-outline-info btn-sm btn ms-2">View</span>
          </a>
        )
      ),

      sortable: false,
      wrap: true,
      center: true,
    },

    {
      name: <div className="text-center">Action</div>,
      width: '150px',
      cell: (row) => (
        <>
          <button
            type="button"
            className={'btn btn-danger mb-2'}
            onClick={() => handleRemoveFields(attachmentInputList?.indexOf(row))}
          >
            <i className="fa fa-minus-circle" />
          </button>
        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <>
      {attachmentInputList?.length > 0 &&
        <div className="row mb-5">
          <div className="col-sm-12">
            <DataTable
              columns={columns}
              data={attachmentInputList?.length > 0 ? attachmentInputList : []}
              expandOnRowClicked
              highlightOnHover
            />
          </div>
        </div>}

      <div className="row">
        <div className="col-sm-10">
          <Label className='form-label'>Attachment File</Label>
          <input
            type="file"
            ref={uploadedFileReference}
            className="form-control"
            name="attachmentFile"
            onChange={(event) => {
              handleInputChange(event);
            }}
          />
        </div>

        <div className="col-sm-2">
          {!plusButtonDisabled ?
            (<button
              type="button"
              className={'btn btn-secondary mt-4'}
              onClick={handleAddFields}
            >
              <i className="fa fa-plus-circle" />
            </button>)
            :
            (<button
              type="button"
              className={'btn btn-secondary mt-4'}
              onClick={handleAddFields}
              disabled
            >
              <i className="fa fa-plus-circle" />
            </button>)}
        </div>
      </div>
    </>
  );
};

export default Attachments;
