import { apiSlice } from 'src/rtk/api/apiSlice';
import * as facultyInfoUrl from 'src/helpers/url_helper';

export const setupFacultyInfoApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['FacultyInfos'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getFacultyInfos: builder.query<APIResponse<FacultyInfo[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${facultyInfoUrl.GET_FACULTY_INFOS}`,
        }),
        providesTags: ['FacultyInfos'],
      }),

      getFacultyInfosList: builder.query<APIResponse<FacultyInfo[]>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${facultyInfoUrl.GET_FACULTY_INFOS}?${url}`,
        }),
        providesTags: ['FacultyInfos'],
      }),

      getFacultyInfo: builder.query<APIResponse<FacultyInfo>, string>({
        query: (id) => ({
          url: `${facultyInfoUrl.GET_FACULTY_INFO}/${id}`,
          method: 'GET',
        }),
        providesTags: ['FacultyInfos'],
      }),

      createFacultyInfo: builder.mutation<
        APIResponse<FacultyInfo>,
        FacultyInfo
      >({
        query: (data) => ({
          url: `${facultyInfoUrl.CREATE_FACULTY_INFO}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['FacultyInfos'],
      }),

      updateFacultyInfo: builder.mutation<APIResponse<FacultyInfo>, string>({
        query: (url) => ({
          url: `${facultyInfoUrl.UPDATE_FACULTY_INFO}` + url,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['FacultyInfos'],
      }),

      deleteFacultyInfo: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${facultyInfoUrl.DELETE_FACULTY_INFO}/${id}`,
        }),
        invalidatesTags: ['FacultyInfos'],
      }),
    }),
  });

export const {
  useCreateFacultyInfoMutation,
  useGetFacultyInfosQuery,
  useGetFacultyInfosListQuery,
  useGetFacultyInfoQuery,
  useUpdateFacultyInfoMutation,
  useDeleteFacultyInfoMutation,
} = setupFacultyInfoApi;
