import { useState } from 'react';
import Select from 'react-select';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { Button } from 'src/components';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { useGetLookupInfosQuery } from 'src/rtk/features/setup/lookup/lookupInfoApi';
import DefaultTable from './DefaultTable';
import DetailsTable from './DetailsTable';

export const Lookup = () => {
  const { data: lookups } = useGetLookupInfosQuery();

  const lookupList = lookups?.data || [];

  const [activeLookup, setActiveLookup] = useState('');
  const [uuid, setUuid] = useState('');

  const options =
    lookupList.map((item) => ({ value: item.id, label: item.lookup_name })) ||
    [];

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Lookup Data" breadcrumbItem="Lookup" />

        <Card>
          <CardHeader>
            <div className="row justify-content-between flex-row-reverse">
              <div className="col-sm-4 col-md-4">
                <Select
                  isSearchable={true}
                  options={options}
                  name='something'
                  placeHolder="Select Lookup"
                  onChange={(e: { label: any; value: any }) => {
                    setActiveLookup(e?.label || '');
                    setUuid(e?.value || '');
                  }}
                />
              </div>

              {activeLookup !== '' && (
                <div className="col-sm-4 col-md-2">
                  <Button
                    text="Back"
                    color="secondary"
                    size="md"
                    onClick={() => {
                      setActiveLookup('');
                      setUuid('');
                    }}
                  />
                </div>
              )}
            </div>
          </CardHeader>
          <CardBody>
            {activeLookup === '' ? (
              <DefaultTable
                lookupList={lookupList}
                setActiveLookup={setActiveLookup}
                setUuid={setUuid}
              />
            ) : (
              <DetailsTable
                lookupList={[]}
                setActiveLookup={setActiveLookup}
                uuid={uuid}
              />
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
