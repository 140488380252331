import * as protocolEntryMockUrl from 'src/helpers/url_helper';

export const  sideBarIndexInitialValues = {
    basic_information_of_the_thesis: {
        pageOrder: 1,
        numberText: '1 to 8',
        text: 'Basic information of the thesis',
        isSelected: false,
        objKey: 'basic_information_of_the_thesis',
        summaryObjKey: 'summary'
    },
    study_design: {
        pageOrder: 2,
        numberText: '9',
        text: 'Study Design',
        isSelected: false,
        objKey: 'study_design',
    },
    introduction_context: {
        pageOrder: 3,
        numberText: '10',
        text: 'Introduction/Context',
        isSelected: false,
        objKey: 'introduction_context',
    },
    rationale_of_research: {
        pageOrder: 4,
        numberText: '11',
        text: 'Rationale of Research',
        isSelected: false,
        objKey: 'rationale_of_the_research',
    },
    research_question_hypothesis: {
        pageOrder: 5,
        numberText: '12',
        text: 'Research Question/Hypothesis',
        isSelected: false,
        objKey: 'research_question_hypothesis',
    },
    objectives: {
        general_objectives: {
            pageOrder: 6,
            text: 'a. General Objectives',
            objKey: 'general_objectives',
            isSelected: false,
        },
        specific_objectives: {
            pageOrder: 6,
            text: 'b. Specific Objectives',
            objKey: 'specific_objectives',
            isSelected: false,
        },
        pageOrder: 6,
        numberText: '13',
        text: 'Objectives',
        isSelected: false,
        objKey: 'objectives'
    },
    materials_and_methods: {

        main_outcome: {
            pageOrder: 7,
            text: 'A) Main outcome variables to be studied',
            objKey: 'main_outcome',
            isSelected: false,
        },
        confounding_variable: {
            pageOrder: 7,
            text: "B) Confounding variable",
            objKey: "confounding_variable",
            isSelected: false,
        },
        study_population: {
            text: "C) Study population (mentioning species and strains, ethnic group, age, sex, and other relevant characteristics)",
            objKey: "study_population",
            isSelected: false,
        },
        sample_size: {
            text: "D) Sample size and the statistical basis of it",
            objKey: "sample_size",
            isSelected: false,
        },
        screening_methods: {
            text: "E) Screening methods, if applicable",
            objKey: "screening_methods",
            isSelected: false,
        },
        sampling_methods: {
            text: "F) Sampling method(s)",
            objKey: "sampling_methods",
            isSelected: false,
        },
        inclusion_exclusion_criteria: {
            text: "G) Inclusion and exclusion criteria",
            objKey: "inclusion_exclusion_criteria",
            isSelected: false,
        },
        operational_definitions: {
            text: "H) Operational definitions",
            objKey: "operational_definitions",
            isSelected: false,
        },
        flow_chart: {
            text: "I) Flow chart showing the sequence of tasks",
            objKey: "flow_chart",
            isSelected: false,
        },
        material_preparation: {
            text: "J) Procedures of preparing and organizing materials (e.g., into groups)",
            objKey: "material_preparation",
            isSelected: false,
        },
        nature_of_controls: {
            text: "K) Nature of controls, if applicable",
            objKey: "nature_of_controls",
            isSelected: false,
        },
        randomization_blinding: {
            text: "L) Randomization and blinding methods, if and as applicable",
            objKey: "randomization_blinding",
            isSelected: false,
        },
        equipment: {
            text: "M) Equipment's to be used",
            objKey: "equipment",
            isSelected: false,
        },
        data_collection: {
            text: "N) Procedures of collecting data (including methods of intervention, measurement, estimation, etc.)",
            objKey: "data_collection",
            isSelected: false,
        },
        professional_assistance: {
            text: "O) Professional assistance from experts, if applicable",
            objKey: "professional_assistance",
            isSelected: false,
        },
        data_analysis_interpretation: {
            text: "P) Procedure of data analysis or interpretation (including methods of statistical analysis, specific methods, and software used for individual analysis)",
            objKey: "data_analysis_interpretation",
            isSelected: false,
        },
        quality_assurance: {
            text: "Q) Quality assurance strategy",
            objKey: "quality_assurance",
            isSelected: false,
        },
        time_table: {
            text: "R) Time table",
            objKey: "time_table",
            isSelected: false,
        },
        pageOrder: 7,
        numberText: '14',
        text: 'Materials and Methods',
        isSelected: false,
        objKey: 'material_methods'
    },

    ethical_implication: {
        pageOrder: 8,
        numberText: '15',
        text: 'Ethical Implication',
        isSelected: false,
        objKey: 'ethical_implication',
    },
    total_budget: {
        pageOrder: 9,
        numberText: '16',
        text: 'Total Budget',
        isSelected: false,
        objKey: 'total_budget_text',
        total_budget_objKey: 'total_budget'
    },
    source_of_funding: {
        pageOrder: 10,
        numberText: '17',
        text: 'Source(s) of Funding(if any)',
        isSelected: false,
        objKey: 'source_of_funding',
    },
    facilities_available_at_the_place_of_the_study: {
        pageOrder: 11,
        numberText: '18',
        text: 'Facilities available at the place of Study',
        isSelected: false,
        objKey: 'facilities_available_at_the_place_of_the_study',
    },
    other_facilities_needed: {
        pageOrder: 12,
        numberText: '19',
        text: 'Other facilities needed',
        isSelected: false,
        objKey: 'other_facilites_needed',
    },
    dissemination_and_use_of_findings: {
        pageOrder: 13,
        numberText: '20',
        text: 'Dissemination and use of findings',
        isSelected: false,
        objKey: 'dissemination_and_use_of_findings',
    },
    references: {
        pageOrder: 14,
        numberText: '21',
        text: 'References',
        isSelected: false,
        objKey: 'references',
    },
    any_other_relevant_info: {
        pageOrder: 15,
        numberText: '22',
        text: 'Any other relevant information',
        isSelected: false,
        objKey: 'any_other_relevant_info',
    },
    signature_of_the_researcher: {
        pageOrder: 16,
        numberText: '23',
        text: 'Signature of the Researcher',
        isSelected: false,
        objKey: 'signature_of_the_researcher',
    },
    signature_of_the_guide_and_co_guide: {
        pageOrder: 17,
        numberText: '24',
        text: 'Signature of the Guide and Co-Guide',
        isSelected: false,
        objKey: 'signature_of_the_guide_and_co_guide',
    },
    attachment: {
        pageOrder: 18,
        numberText: '25',
        text: 'Attachment',
        isSelected: false,
        ObjKey: 'attachment'
    }
};

export const objectivesKeyValue = [{ key: 'general_objectives', value: 'a. General Objectives' },
{ key: 'specific_objectives', value: 'b. Specific Objectives' }
]

export const materialsKeyValue = [
  { key: "main_outcome", value: "A) Main outcome variables to be studied" },
  { key: "confounding_variable", value: "B) Confounding variable" },
  { key: "study_population", value: "C) Study population (mentioning species and strains, ethnic group, age, sex, and other relevant characteristics)" },
  { key: "sample_size", value: "D) Sample size and the statistical basis of it" },
  { key: "screening_methods", value: "E) Screening methods, if applicable" },
  { key: "sampling_methods", value: "F) Sampling method(s)" },
  { key: "inclusion_exclusion_criteria", value: "G) Inclusion and exclusion criteria" },
  { key: "operational_definitions", value: "H) Operational definitions" },
  { key: "flow_chart", value: "I) Flow chart showing the sequence of tasks" },
  { key: "material_preparation", value: "J) Procedures of preparing and organizing materials (e.g., into groups)" },
  { key: "nature_of_controls", value: "K) Nature of controls, if applicable" },
  { key: "randomization_blinding", value: "L) Randomization and blinding methods, if and as applicable" },
  { key: "equipment", value: "M) Equipment's to be used" },
  { key: "data_collection", value: "N) Procedures of collecting data (including methods of intervention, measurement, estimation, etc.)" },
  { key: "professional_assistance", value: "O) Professional assistance from experts, if applicable" },
  { key: "data_analysis_interpretation", value: "P) Procedure of data analysis or interpretation (including methods of statistical analysis, specific methods, and software used for individual analysis)" },
  { key: "quality_assurance", value: "Q) Quality assurance strategy" },
  { key: "time_table", value: "R) Time table" }
]

export const apiUrls = {
    getProtocolListUrl: `${protocolEntryMockUrl.GET_PROTOCOL_LIST_URL}`,
    addUrl: `${protocolEntryMockUrl.ADD_URL}`,
    updateListUrl: `${protocolEntryMockUrl.UPDATE_LIST_URL}`,
    titleUrl: `${protocolEntryMockUrl.TITLE_URL}`,
    updateUrl: `${protocolEntryMockUrl.UPDATE_URL}`,
    createUrl: `${protocolEntryMockUrl.CREATE_URL}`
};