import { Document, PDFViewer, Page, View } from '@react-pdf/renderer';
import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useState } from 'react';
import { Html } from 'react-pdf-html';
import { config } from 'src/config';

const RichTextEditor = (props: any) => {
  const { formikState, initialContentEditor, setInitialContentEditor } = props;
  const [contentEditor, setContentEditor] = useState();
  const [textEditorContent, setTextEditorContent] = useState<string>('');

  const handleEditorChange = (content: any, editor: any) => {
    // console.log('Content was updated:', content);
    setContentEditor(content);
    setTextEditorContent(content);
  };

  useEffect(() => {    
    let initialBody: any = null;

    if(formikState['notice_body']?.includes('editorHtml')) {
      initialBody = JSON.parse(formikState['notice_body']);
    } else {
      initialBody = {editorHtml: ''};
    }

    const initialContent: any = initialBody?.editorHtml;
    setInitialContentEditor(initialContent);     
  }, []);

  useEffect(() => {
    formikState['notice_body'] = JSON.stringify({ editorHtml: contentEditor });
  }, [contentEditor]);

  return (
    <>
      <Editor
        initialValue={initialContentEditor}
        apiKey={config.EDITOR_API_KEY}
        init={{
          height: 500,
          menubar: true,
          plugins: [            
            'advlist',                        
            'autolink',            
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',            
            'fullscreen',            
            'insertdatetime',
            'media',
            'table',
            'wordcount',
          ],
          toolbar:
            'undo redo | casechange blocks | bold italic backcolor | ' +
            'alignleft aligncenter alignright alignjustify | ' +
            'bullist numlist checklist outdent indent | removeformat | a11ycheck code table',
          // content_style:
          //   'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
        value={contentEditor ?? initialContentEditor}
        onEditorChange={handleEditorChange}
      />
      {/* <textarea className='tiny-mce' id='editor1' value={textEditorContent}></textarea> */}

      {/* <PDFViewer>
        <Document>
          <Page size="A4">
            <Html>{textEditorContent}</Html>
          </Page>
        </Document>
      </PDFViewer> */}
    </>
  );
};

export default RichTextEditor;
