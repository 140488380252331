import React, { useEffect, useState } from 'react';

//import Breadcrumbs
import { toast } from 'react-hot-toast';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap';
import { config } from 'src/config';
import { useAppSelector } from 'src/rtk/app/hook';
import {
  useGetPersonalProfileQuery,
  useGetTraineeIrregularLogEntryMessageQuery,
} from 'src/rtk/features/trainee/traineeApi';
import AccountantDashboard from './Accountant/AccountantDashboard';
import TraineeDashboard from './Trainee/TraineeDashboard';
import DisplayImage from "./../../components/Common/displayImage"
import SupervisorDashboard from './Supervisor/SupervisorDashboard';
import FellowDashboard from './Fellow/FellowDashboard';

const Dashboard = () => {
  const { data: personalProfile } = useGetPersonalProfileQuery();
  const {
    data: irregularLogEntryData,
    isSuccess: isIrregularLogEntrySuccess,
    isError: isIrregularLogEntryError,
    isLoading: isIrregularLogEntryLoading,
    error: irregularLogEntryError,
  } = useGetTraineeIrregularLogEntryMessageQuery();
  const [showAlertMessageModal, setShowAlertMessageModal] = useState<boolean>(false);
  const signUser = useAppSelector((state) => state.auth);
  // console.log(personalProfile);

  useEffect(() => {
    if (isIrregularLogEntrySuccess && !isIrregularLogEntryLoading) {
      toast.dismiss();
      if (irregularLogEntryData?.data?.length > 0) {
        setShowAlertMessageModal(true);
      }
    }
  }, [isIrregularLogEntrySuccess, isIrregularLogEntryLoading]);

  document.title = 'BCPS | eLogbook';
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          {/* <BetterBreadcrumb title="Dashboard" /> */}          

          {personalProfile?.data?.type && personalProfile?.data?.type.toLowerCase() === 'trainee' && (<>
            <TraineeDashboard mainDashboard={true} personalProfile={personalProfile} />
          </>)}

          {personalProfile?.data?.type && personalProfile?.data?.type.toLowerCase() === 'supervisor' && (<>
            <SupervisorDashboard mainDashboard={true} personalProfile={personalProfile} />
          </>)}

          {personalProfile?.data?.type && personalProfile?.data?.type.toLowerCase() === 'fellow' && (<>
            <FellowDashboard personalProfile={personalProfile} />
          </>)}

          {personalProfile?.data?.type && personalProfile?.data?.type.toLowerCase() === 'operation' && (<>
            <AccountantDashboard mainDashboard={true} />
          </>)}


          {/* <pre>{JSON.stringify(personalProfile, null, 2)}</pre> */}
        </Container>

        <Modal
          onOpened={function() {
            toast.dismiss();
          }}
          isOpen={showAlertMessageModal}
          scrollable={false}
          toggle={() => {
            document.body.classList.add('no_padding');
          }}
          backdrop={'static'}
          modalTransition={{ timeout: 0 }}
          backdropTransition={{ timeout: 0 }}
          className='dashboard-user-alert-modal'
        >

          <div className='dashboard-user-alert-wrapper'>
            <button
              type='button'
              className='dashboard-user-alert-close'
              onClick={() => {
                setShowAlertMessageModal(!showAlertMessageModal);
              }}
              aria-label='Close'
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'>
                <path
                  d='M5.71875 5.71877C6.07037 5.36727 6.54719 5.1698 7.04438 5.1698C7.54156 5.1698 8.01839 5.36727 8.37 5.71877L15 12.3488L21.63 5.71877C21.9836 5.37723 22.4573 5.18824 22.9489 5.19251C23.4405 5.19678 23.9108 5.39397 24.2584 5.74161C24.6061 6.08925 24.8032 6.55953 24.8075 7.05115C24.8118 7.54277 24.6228 8.0164 24.2813 8.37002L17.6513 15L24.2813 21.63C24.6228 21.9837 24.8118 22.4573 24.8075 22.9489C24.8032 23.4405 24.6061 23.9108 24.2584 24.2584C23.9108 24.6061 23.4405 24.8033 22.9489 24.8075C22.4573 24.8118 21.9836 24.6228 21.63 24.2813L15 17.6513L8.37 24.2813C8.01637 24.6228 7.54274 24.8118 7.05112 24.8075C6.55951 24.8033 6.08923 24.6061 5.74159 24.2584C5.39395 23.9108 5.19676 23.4405 5.19249 22.9489C5.18822 22.4573 5.3772 21.9837 5.71875 21.63L12.3488 15L5.71875 8.37002C5.36724 8.01841 5.16978 7.54158 5.16978 7.0444C5.16978 6.54722 5.36724 6.07039 5.71875 5.71877Z'
                  fill='black' />
              </svg>
            </button>


            {
              irregularLogEntryData?.data?.map((data: TraineeIrregularLogEntryMessage, index: number) => {
                return (
                  <>
                    <div key={index} className="dashboard-user-alert">
                      <div className='dashboard-user-alert-thumb'>
                        {signUser?.user?.details?.personal_info?.picture && (
                            <DisplayImage src={config.APP_URL +signUser?.user?.details?.personal_info?.picture} alt=""/>
                        )}
                      </div>
                      <div className='dashboard-user-alert-info'>
                        <div className='dashboard-user-alert-title'>Alert !</div>
                        <div className='dashboard-user-alert-message'>{data?.message}</div>
                      </div>
                    </div>
                  </>


                );
              })
            }

          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
