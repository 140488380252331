import { apiSlice } from 'src/rtk/api/apiSlice';
import * as setupOrganizationUrl from 'src/helpers/url_helper';

interface Paginate {
  page: number | string;
  paginate_per_page?: number;
  search?: string;
}

export const setupOrganizationApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['Organizations'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getOrganizations: builder.query<APIResponse<Organization[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${setupOrganizationUrl.GET_ORGANIZATIONS}`,
        }),
        providesTags: ['Organizations'],
      }),

      getAllOrganizationList: builder.query<APIResponseWithPagination<Organization[]>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${setupOrganizationUrl.GET_ALL_ORGANIZATION_LIST}?${url}`,
        }),
        providesTags: ['Organizations'],
      }),

      getInstituteName: builder.query<Institute[], void>({
        query: () => ({
          method: 'GET',
          url: `${setupOrganizationUrl.GET_INSTITUTE_NAME}`,
        }),
        providesTags: ['Organizations'],
      }),

      getInstituteWiseDepartmentName: builder.query<DepartmentName[], void>({
        query: (name) => ({
          method: 'GET',
          url: `${setupOrganizationUrl.GET_INSTITUTE_WISE_DEPARTMENT_NAME}?institute_name=${name}`,
        }),
        providesTags: ['Organizations'],
      }),

      getInstitutesByOrganization: builder.query<APIResponse<Organization[]>, string>({
        query: (name: string) => ({
          method: 'GET',
          url: `${setupOrganizationUrl.GET_INSTITUTES_BY_ORGANIZATION}?organization_type=${name}`,
        }),
        providesTags: ['Organizations'],
      }),

      getOrganization: builder.query<APIResponse<Organization>, string>({
        query: (id) => ({
          url: `${setupOrganizationUrl.GET_ORGANIZATION}/${id}`,
          method: 'GET',
        }),
        providesTags: ['Organizations'],
      }),

      createOrganization: builder.mutation<
        APIResponse<Organization>,
        Organization
      >({
        query: (data) => ({
          url: `${setupOrganizationUrl.CREATE_ORGANIZATION}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['Organizations'],
      }),

      updateOrganization: builder.mutation<APIResponse<Organization>, string>({
        query: (url) => ({
          url: `${setupOrganizationUrl.UPDATE_ORGANIZATION}` + url,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['Organizations'],
      }),

      deleteOrganization: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${setupOrganizationUrl.DELETE_ORGANIZATION}/${id}`,
        }),
        invalidatesTags: ['Organizations'],
      }),
    }),
  });

export const {
  useCreateOrganizationMutation,
  useGetOrganizationsQuery,
  useGetAllOrganizationListQuery,
  useGetOrganizationQuery,
  useGetInstituteNameQuery,
  useLazyGetInstituteWiseDepartmentNameQuery,
  useGetInstitutesByOrganizationQuery,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
} = setupOrganizationApi;
