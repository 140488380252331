import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import DepartmentPaymentModal from '../InstituteDepartmentPayment/DepartmentPaymentModal';

interface Props {
    departmentInfo: InstituteDepartmentRegData;
    index: number;
}

const InstituteDepartmentListTableRow = ({ departmentInfo, index }: Props) => {
    const history = useHistory();

    //   const [deleteDeviceRegInfo, { isSuccess, isLoading, isError }] =
    //     useDeleteDeviceRegInfoMutation();

    //   const deleteHandler = (id: string) => {
    //     const confirm = window.confirm('Are you sure you want to delete?');
    //     if (confirm) deleteDeviceRegInfo(id);
    //   };
    const [regData, setRegData] = useState<InstituteDepartmentRegData>({});
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [departmentFees, setDepartmentFees] = useState('');
    const [feesInWord, setFeesInWord] = useState('');

    const { data: accreditation } = useGetLookupDataQuery('department_accreditation_type');

    const allAccreditation: Lookup[] =
        (accreditation as any)?.data[0]?.childs || [];

    const feesArray = allAccreditation?.find((item: any) => { return item?.name === departmentInfo?.institute?.institute_type });

    useEffect(() => {
        if (feesArray) {
            feesArray?.childs[0].name ? setDepartmentFees(feesArray?.childs[0]?.name) : setDepartmentFees('');
            feesArray?.childs[1].name ? setFeesInWord(feesArray?.childs[1]?.name) : setFeesInWord('');
        }
    }, [feesArray]);


    const modalShowHandler = (modalState: boolean, data: any) => {
        setModalShow(modalState);
        setRegData(data);
        console.log(data);
    };

    return (
        <tr key={departmentInfo.id}>
            <th scope="row">{index + 1}</th>
            <td>{departmentInfo?.institute?.institute_name ?? '-'}</td>
            <td>{departmentInfo?.department_name ?? '-'}</td>
            <td>{'-'}</td>
            <td>{'-'}</td>
            <td>{departmentInfo?.valid_upto ?? '-'}</td>
            <td>{departmentInfo?.payment_info?.payment_verification_status ?? '-'}</td>
            <td>{departmentInfo?.approval_status ?? '-'}</td>
            <td>
                <div className="d-flex gap-2">
                    <Button
                        size="sm"
                        text="Edit"
                        color="secondary"
                        disabled={departmentInfo.submission_status === 'Submit'}
                        onClick={(e) => {
                            history.push(`/institute/department-registration/${departmentInfo?.department_id}/${departmentInfo?.id}`);
                        }}
                    />

                    <Button
                        size="sm"
                        text="View"
                        color="info"
                        onClick={() => {
                            history.push(`/institute/view-department/${departmentInfo?.department_id}/${departmentInfo?.id}`);
                        }}
                    />

                    <Button
                        size="sm"
                        text="Payment"
                        color="success"
                        onClick={() => {
                            modalShowHandler(true, departmentInfo);
                        }}
                        disabled={departmentInfo?.payment_info !== null}
                    />

                    <Button
                        size="sm"
                        text="Renew"
                        color="warning"
                        onClick={() => {
                            history.push(`/institute/renew-department/${departmentInfo?.department_id}/${departmentInfo?.id}`)
                        }}
                    />
                </div>
                {modalShow &&
                    <DepartmentPaymentModal
                        isOpen={modalShow}
                        toggle={() => { setModalShow(!modalShow) }}
                        instituteName={regData?.institute?.institute_name}
                        id={regData?.id!}
                        feesInWord={feesInWord}
                        departmentFees={departmentFees}
                        departmentName={regData?.department_name!}
                    />
                }
            </td>
        </tr>
    );
};

export default InstituteDepartmentListTableRow;
