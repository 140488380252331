import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label, Modal, ModalBody, ModalHeader,
  Row
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { setFromErrors } from 'src/helpers';
import { useCreateFaqMutation } from 'src/rtk/features/research-clinic-faq/FAQApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoGlobalApi';
import * as Yup from 'yup';
import RichTextEditor from './RichTextEditor';

const initialValues: ResearchClinicFAQ = {
  id: '',
  sl_no: 0,
  question: '',
  answer: '',
  subject_id: '',
  subject_name: '',
  active_status: '',
};

const AddResearchClinicFAQ = (props: any) => {
  const { count } = props;
  const [createFAQ, { isSuccess, isError, error }] =
    useCreateFaqMutation();

  const [reRenderState, setReRenderState] = useState<number>(1);
  const [activeState, setActiveState] = useState<boolean>(true);
  const [selectedSubject, setSelectedSubject] = useState<string>('');
  const [showEntryModal, setShowEntryModal] = useState<boolean>(false);
  const err: CustomError = error as CustomError;
  const [initialContentEditor, setInitialContentEditor] = useState<string>('');

  const { data: subjectList, isError: isSubFetchError } =
    useGetSubjectInfosQuery();

  const subjectOptions: SelectOption[] = (
    subjectList?.data || []
  ).map((item) => ({
    value: item.id ?? '',
    label: item.subject_name ?? '',
  }));

  subjectOptions.unshift({
    value: 'All',
    label: 'All Subjects',
  });

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      question: Yup.string().required('Please provide the question'),
      answer: Yup.string().required('Please provide the answer'),
    }),

    onSubmit: (values: any) => {

      if (activeState) {
        values.active_status = 'Active';
      } else {
        values.active_status = 'Inactive';
      }

      createFAQ({
        ...values,
      });

      console.log(values);
    },
  });

  const {
    handleSubmit,
    getFieldProps,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    resetForm,
    setValues,
    setErrors,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      setSelectedSubject('');
      setActiveState(true);
      toast.success('FAQ created successfully');
      setShowEntryModal(false);
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  const handleFormReset = () => {
    resetForm();
    setSelectedSubject('');
    setActiveState(true);
  };

  const handleSubjectChange = (e: any) => {
    const listOfSubjectId: string = e?.reduce((acc: any, option: any) => {
      if (acc === '') {
        return option?.value;
      }

      return acc + ',' + option?.value;
    }, '');

    const listOfSubjectName: string = e?.reduce((acc: any, option: any) => {
      if (acc === '') {
        return option?.label;
      }

      return acc + ',' + option?.label;
    }, '');

    if (listOfSubjectId?.includes('All')) {
      setFieldValue('subject_id', 'All');
      setFieldValue('subject_name', 'All Subjects');
    } else {
      setFieldValue('subject_id', listOfSubjectId);
      setFieldValue('subject_name', listOfSubjectName);
    }

    // setSelectedSubject(e.value);
    // const subject: SubjectInfo = subjectList?.data?.find((s: SubjectInfo) => s.id === e.value)!;
    // if (e.value === 'All') {
    //   setFieldValue('subject_id', 'All');
    //   setFieldValue('subject_name', 'All Subjects');
    // } else {
    //   setFieldValue('subject_id', subject.id ?? '');
    //   setFieldValue('subject_name', subject.subject_name ?? '');
    // }
  };

  useEffect(() => {
    if (count >= 0) {
      setFieldValue('sl_no', count + 1);
    }
  }, [count]);

  return (
    <>
      <React.Fragment>
        <Container fluid>
          <div className='page-breadcrumb-wrapper'>
            <BetterBreadcrumb
              title='Research Clinic FAQ'
            />
            <Button type='button'
              text='FAQ Entry'
              className='btn btn-primary'
              onClick={() => setShowEntryModal(true)}
            ></Button>
          </div>


        </Container>
        <Modal size={'lg'} isOpen={showEntryModal} toggle={() => setShowEntryModal(false)}>
          <ModalHeader toggle={() => setShowEntryModal(false)}>Entry Modal</ModalHeader>
          <ModalBody>
            <Form
              autoComplete='off'
              className='custom-form'
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              onReset={(e) => {
                e.preventDefault();
                handleFormReset();
              }}
            >
              <Row>
                <Col lg={6}>
                  <div className='mb-3'>
                    <Label htmlFor='sl_no' className='form-Label'>
                      Serial No.
                    </Label>
                    <InputField
                      type='number'
                      placeholder='Serial No.'
                      min={'1'}
                      // name='sl_no'
                      // value={values.sl_no}
                      // invalid={{ errors, touched }}
                      {...getFieldProps('sl_no')}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className='mb-3'>
                    <Label
                      htmlFor='subject_id'
                      className='form-Label'
                    >
                      Subject
                    </Label>
                    <Select
                      // defaultValue={[]}
                      isMulti
                      name="subject_id"
                      value={
                        values.subject_id !== ''
                          ? values.subject_id
                            ?.split(',')
                            ?.map((subject: any) => ({
                              label: subjectOptions?.find((s: SelectOption) => s.value === subject)?.label,
                              value: subject,
                            }))
                          : []
                      }
                      options={subjectOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isOptionDisabled={() => values?.subject_id?.includes('All')}
                      onChange={handleSubjectChange}
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                      styles={{ menuPortal: (provided: any, state: any) => ({ ...provided, zIndex: '9999', }) }}
                    />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className='mb-3'>
                    <Label htmlFor='question' className='form-Label'>
                      Question
                    </Label>
                    <InputField
                      type='text'
                      placeholder='Question'
                      invalid={{ errors, touched }}
                      {...getFieldProps('question')}
                    />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className='mb-3'>
                    <Label htmlFor='answer' className='form-Label'>
                      Answer
                    </Label>
                    {/* <InputField
                      type='textarea'
                      placeholder='Answer'
                      invalid={{ errors, touched }}
                      {...getFieldProps('answer')}
                    /> */}
                    <RichTextEditor
                      formikState={values}
                      initialContentEditor={initialContentEditor}
                      setInitialContentEditor={setInitialContentEditor}
                      key={reRenderState}
                    />
                  </div>
                </Col>

                <Col sm={12}>
                  <div className='mb-3'>
                    <FormGroup switch className='mb-3'>
                      <Input
                        type='switch'
                        role='switch'
                        checked={activeState}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setActiveState(true);
                          } else {
                            setActiveState(false);
                          }
                        }}
                      />
                      <Label>{activeState ? 'Active' : 'Inactive'}</Label>
                    </FormGroup>
                  </div>
                </Col>
                <Col sm={12} className='mt-3'>
                  <FormGroup className='mb-3 text-center'>
                    <Button
                      type='reset'
                      text='Reset'
                      className='btn btn-warning me-2'
                      onClick={(e) => {
                        handleFormReset();
                      }}
                    ></Button>
                    <Button
                      type='submit'
                      text='Submit'
                      className='btn btn-success me-2'
                    ></Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default AddResearchClinicFAQ;
