import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { putUrlString } from 'src/helpers';
import { useGetLogBookMasterSetupsQuery } from 'src/rtk/features/setup/logBookMasterSetup/logBookMasterSetupApi';
import { useGetLogbookTableOfContentQuery, useGetLogbookTableOfContentsQuery, useUpdateLogbookTableOfContentMutation } from 'src/rtk/features/setup/logBookTableOfContentSetup/logBookTableOfContentSetupApi';
import * as Yup from 'yup';

const initialValues: LogbookTableOfContent = {
    id: '',
    setup_logbook_master_id: '',
    details: '',
    note: '',
    table_of_contents_details_id: '',
    page_no: 0,
    sl_no: 0,
    status: true,
    creator_organization_id: '',
    updater_organization_id: '',
    created_by: '',
    updated_by: '',
    deleted_by: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
};

export const EditLogbookTableOfContent = () => {
  const { id } = useParams<{ id: string }>();
  const { data: logbookTableOfContent, isError: isLogbookTableOfContentFetchError } = useGetLogbookTableOfContentQuery(id);
  const [updateLogbookTableOfContent, { isSuccess, isError, error }] = useUpdateLogbookTableOfContentMutation();

  const { data: logbookMastersData } = useGetLogBookMasterSetupsQuery();
  const logbookMasters = logbookMastersData?.data || [];

  const { data: logbookTableOfContentsData } = useGetLogbookTableOfContentsQuery();
  const logbookTableOfContents = logbookTableOfContentsData?.data || [];

  const err: CustomError = error as CustomError;
  const history = useHistory();
  const {
    handleSubmit,
    getFieldProps,
    values,
    touched,
    errors,
    setValues,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
        setup_logbook_master_id: Yup.string().required('Please select the logbook master'), 
    }),

    onSubmit: (values) => {
      const url = putUrlString(id, values);
      updateLogbookTableOfContent(url);
    },
  });

  useEffect(() => {
    if (logbookTableOfContent && !isLogbookTableOfContentFetchError) {
      const data: LogbookTableOfContent = (logbookTableOfContent as any).data;
      setValues({
        id: data.id,
        setup_logbook_master_id: data.setup_logbook_master_id,
        details: data.details,
        note: data.note,
        table_of_contents_details_id: data.table_of_contents_details_id,
        page_no: data.page_no,
        sl_no: data.sl_no,
        status: data.status,
        creator_organization_id: data.creator_organization_id,
        updater_organization_id: data.updater_organization_id,
        created_by: data.created_by,
        updated_by: data.updated_by,
        deleted_by: data.deleted_by,
        created_at: data.created_at,
        updated_at: data.updated_at,
        deleted_at: data.deleted_at,
      });
    }
  }, [logbookTableOfContent, isLogbookTableOfContentFetchError]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Logbook table of content updated successfully');
      history.push('/setup/logbook-table-of-content');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      const errors: any = {};

      if (err?.data) {
        Object.keys(err?.data).forEach((key) => {
          errors[key] = err?.data[key][0];
        });
      }
      setErrors(errors);
    }
  }, [isSuccess, isError]);

  const logbookMasterOptions: SelectOption[] = logbookMasters?.map((l: LogBookSetupMasters) => ({
    label: l.logbook_heading!,
    value: l.id!,
  }))!;  

  let logbookTableOfContentOptions: SelectOption[] = [    
    ...logbookTableOfContents?.map((l: LogbookTableOfContent) => ({
      label: l.details!,
      value: l.id!,
    })),
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Edit Logbook Table of Content'}
          breadcrumbItem={[{ link: '/setup/logbook-table-of-content/', name: 'Table of Content' }]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="card mt-3">
            <h5 className="card-header">
              <i className="fa fa-edit me-2" />
              Edit Logbook Table of Content
            </h5>
            <div className="card-body">
                <Form
                className="custom-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="row">                  
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="setup_logbook_master_id">Setup Logbook Master</Label>
                      <InputField
                        type="select"
                        placeholder="Logbook Master Heading"
                        options={logbookMasterOptions}
                        invalid={{ errors, touched }}
                        {...getFieldProps('setup_logbook_master_id')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="details">Details</Label>
                      <InputField
                        type="textarea"
                        placeholder="Write here..."
                        invalid={{ errors, touched }}
                        {...getFieldProps('details')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="note">Note</Label>
                      <InputField
                        type="text"
                        placeholder="Note"
                        invalid={{ errors, touched }}
                        {...getFieldProps('note')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="table_of_contents_details_id">Table of Content Details</Label>
                      <InputField
                        type="select"
                        placeholder="Table of Content Details"
                        options={logbookTableOfContentOptions}
                        invalid={{ errors, touched }}
                        {...getFieldProps('table_of_contents_details_id')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="page_no">Page No</Label>
                      <InputField
                        type="number"
                        placeholder="Page No"
                        invalid={{ errors, touched }}
                        {...getFieldProps('page_no')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="sl_no">Sl No</Label>
                      <InputField
                        type="number"
                        placeholder="Sl No"
                        invalid={{ errors, touched }}
                        {...getFieldProps('sl_no')}
                      />
                    </FormGroup>
                  </div>                  

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup check className="mb-3 mt-4">
                      <Label check>
                        <Input type="checkbox" checked={values.status} {...getFieldProps('status')} />{' '}
                        Status
                      </Label>
                    </FormGroup>
                  </div>
                </div>
                <Button type="submit" text="Submit" />
                </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
