import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import {
  Button,
  Card,
  CardBody,
  Container,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader
} from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import {
  useDeleteLeaveEntryMutation,
  useGetLeaveEntryListQuery,
} from 'src/rtk/features/leaveManagement/leaveManagementApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import loader from '../../assets/images/loader.gif';
import ApplyForLeave from './ApplyForLeave';
import EditLeaveEntry from './EditLeaveEntry';

const LeaveEntryList = () => {
  const [leaveId, setLeaveId] = useState('');

  const [showAddForm, setShowAddForm] = useState<boolean>(false);
  const [showEditForm, setShowEditForm] = useState<boolean>(false);

  const [infoId, setInfoId] = useState('');
  const [rowForEdit, setRowForEdit] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const [deleteLeaveEntry, { isSuccess: isDeleteSuccess, isError: isDeleteError }] = useDeleteLeaveEntryMutation();

  const { data: leaveTypeLookupData } = useGetLookupDataQuery('leave_type');

  const typeList: Lookup[] = (leaveTypeLookupData as any)?.data[0]?.childs || [];

  const allLeaveType: SelectOption[] =
    typeList?.map((type) => ({
      value: type.name,
      label: type.name,
    })) || [];

  const allStatus: SelectOption[] = [
    { value: 'Approve', label: 'Approve' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Reject', label: 'Reject' },
  ];
  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');

  const generateQueryUrl = () => {
    return `page=${page}&paginate_per_page=${limit}${search}`;
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setPage(page);
  };
  const editHandler = (row: string, id: string) => {
    setLeaveId(id);
    setShowEditForm(true)
    setRowForEdit(row);
  };

  const deleteHandler = (modalState: boolean, id: string) => {
    setModalShow(modalState);
    setInfoId(id);
  };

  const {
    data: entryList,
    isSuccess,
    isLoading,
  } = useGetLeaveEntryListQuery(generateQueryUrl());

  useEffect(() => {
    if (isDeleteSuccess) {
      toast.success('Leave Entry Deleted Successfully!');
      setModalShow(false);
    }
    if (isDeleteError) {
      toast.error('Error Deleting Leave Entry!');
    }
  }, [isDeleteSuccess, isDeleteError]);

  interface SearchFields {
    search?: string;
    start_date?: string;
    end_date?: string;
    approved_status?: string;
    leave_type?: string;
  }

  const initialValues: SearchFields = {
    search: '',
    start_date: '',
    end_date: '',
    approved_status: '',
    leave_type: '',
  };

  // const [isOpen, setIsOpen] = useState(false);

  const {
    handleSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    resetForm,
    setFieldValue,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values: any) => {
      let s: string = '';

      if (values.search !== '') {
        s = s + `&search=${values.search}`;
      }

      if (values.start_date !== '') {
        s = s + `&start_date=${values.start_date}`;
      }

      if (values.end_date !== '') {
        s = s + `&end_date=${values.end_date}`;
      }

      if (values.leave_type !== '') {
        s = s + `&leave_type=${values.leave_type}`;
      }

      if (values.approved_status !== '') {
        s = s + `&approved_status=${values.approved_status}`;
      }

      setSearch(s);
      setPage('1');
      console.log(values);
    },
  });
  const columns: TableColumn<any>[] = [
    {
      name: <div>Sl. No.</div>,
      cell: (row, index) => (page - 1) * limit + index + 1,
      width: '80px',
    },

    {
      name: <div>From Date</div>,
      selector: (row) => moment(row?.start_date).format('DD-MMM-YYYY'),
      sortable: false,
    },
    {
      name: <div>To Date</div>,
      selector: (row) => moment(row?.end_date).format('DD-MMM-YYYY'),
      sortable: false,
    },
    {
      name: <div>No. of Days</div>,
      selector: (row) => row?.no_of_days,
      sortable: false,
    },
    {
      name: <div>Leave Type</div>,
      selector: (row) => row?.leave_type,
      sortable: false,
    },
    {
      name: <div>Reason</div>,
      selector: (row) => row?.leave_reason,
      sortable: false,
    },
    {
      name: <div>Status</div>,
      selector: (row) => row?.approved_status,
      cell: (row) => {
        switch (row?.approved_status) {

          case 'Approve':
            return (
              <div className='badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium'>
                {'Approved'}
              </div>
            );
          case 'Pending':
            return (
              <div className='badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium'>
                {'Pending'}
              </div>
            );
          case 'Reject':
            return (
              <div className='badge rounded-pill d-inline-block  badge-rejected font-size-12 fw-medium'>
                {'Rejected'}
              </div>
            );

          default:
            return (
              <div className='badge rounded-pill d-inline-block  badge-draft font-size-12 fw-medium'>
                {row?.approved_status}
              </div>
            );
        }
      },
      sortable: false,
    },

    {
      name: <div>Action</div>,
      cell: (row) => (
        <div className='m-2 d-flex gap-1'>
          {row?.submission_status === 'Draft' &&
            <Button
              className='btn btn-sm btn-info'
              onClick={() => editHandler(row, row?.id!)}
            >
              Edit
            </Button>}

          <Button
            className='btn btn-sm btn-danger'
            onClick={() => deleteHandler(true, row?.id!)}
          >
            Delete
          </Button>
          {modalShow && infoId === row?.id ? (
            <DeleteInformationModal
              isOpen={modalShow}
              toggle={() => setModalShow(!modalShow)}
              id={infoId}
              deleteInfomation={deleteLeaveEntry}
            />
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div className='page-content'>
      {isLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <div className='page-breadcrumb-wrapper'>
          <BetterBreadcrumb
            breadcrumbItem={[{ link: '#', name: 'Leave' }]}
            title={'Leave Apply'}
          />
          <div>
            <Button color="primary" onClick={() => setShowAddForm(true)}>
              Apply for Leave
            </Button>
          </div>
        </div>


        <Card>
          <CardBody>
            <Form
              className='filter-wrapper'
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              onReset={(e) => {
                // e.preventDefault();
                handleSubmit();
                resetForm();
              }}
            >

              <FormGroup>
                <Label for='leave_type'>Leave Type</Label>
                <InputField
                  type='select'
                  placeholder='Leave Type'
                  options={allLeaveType}
                  {...getFieldProps('leave_type')}
                />
              </FormGroup>

              <FormGroup>
                <Label for='start_date'>From Date</Label>
                <InputField
                  type='date'
                  placeholder=''
                  {...getFieldProps('start_date')}
                />
              </FormGroup>

              <FormGroup>
                <Label for='end_date'>To Date</Label>
                <InputField
                  type='date'
                  placeholder=''
                  invalid={{ errors, touched }}
                  {...getFieldProps('end_date')}
                />
              </FormGroup>

              <FormGroup>
                <Label for='approved_status'>Status</Label>
                <InputField
                  type='select'
                  placeholder='Status'
                  options={allStatus}
                  {...getFieldProps('approved_status')}
                />
              </FormGroup>

              <FormGroup className='btn-column mb-3'>
                <Button color='warning' type='reset' outline>Reset</Button>
                <Button type='submit' color='primary'>Search</Button>
              </FormGroup>

            </Form>

            <DataTable
              columns={columns}
              data={entryList?.data!}
              pagination
              paginationServer
              progressPending={isLoading}
              expandOnRowClicked
              highlightOnHover
              paginationTotalRows={entryList?.total}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
              responsive
              persistTableHead={true}
            />
          </CardBody>
        </Card>
      </Container>

      <Modal isOpen={showAddForm} backdrop={'static'} size="lg">
        <ModalHeader>Apply for Leave
          <div className="close" onClick={() => setShowAddForm(false)}></div>
        </ModalHeader>
        <ModalBody>
          <ApplyForLeave setShowAddForm={setShowAddForm} />
        </ModalBody>

      </Modal>
      <Modal isOpen={showEditForm} backdrop={'static'} size="lg">
        <ModalHeader>Edit Leave  <div className="close" onClick={() => setShowEditForm(false)}></div></ModalHeader>
        <ModalBody>
          <EditLeaveEntry
            id={leaveId}
            row={rowForEdit}
            setShowEditForm={setShowEditForm}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LeaveEntryList;
