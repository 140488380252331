import { apiSlice } from 'src/rtk/api/apiSlice';
import * as thesisDissertationFileEntryUrl from 'src/helpers/url_helper';

export const thesisDissertationFileEntryApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: [
    'thesisDissertationFileEntry', 
    'thesisDissertationFormSetup', 
    'thesisEntryMasterWithDetails', 
    'thesisDissertationTitleList'
  ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getThesisDissertationFileEntryList: builder.query<APIResponse<any>, void>({
        query: () => ({
          method: 'GET',
          url: `${thesisDissertationFileEntryUrl.GET_THESIS_DISSERTATION_FILE_ENTRY_LIST}`,
        }),

        providesTags: ['thesisDissertationFileEntry'],
      }),

      getThesisDissertationTitleList: builder.query<APIResponse<any>, void>({
        query: () => ({
          method: 'GET',
          url: `${thesisDissertationFileEntryUrl.GET_THESIS_DISSERTATION_TITLE_LIST}`,
        }),

        providesTags: ['thesisDissertationTitleList'],
      }),

      getThesisDissertationFormSetupList: builder.query<APIResponse<ThesisDissertationEntryFormSetup[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${thesisDissertationFileEntryUrl.GET_THESIS_DISSERTATION_FORM_SETUP_LIST}`,
        }),

        providesTags: ['thesisDissertationFormSetup'],
      }),

      getThesisEntryDetailsById: builder.query<APIResponse<any>, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${thesisDissertationFileEntryUrl.GET_THESIS_ENTRY_DETAILS_BY_ID}/${id}`,
        }),

        providesTags: ['thesisDissertationFormSetup'],
      }),

      getThesisEntryListForTrainee: builder.query<APIResponse<any>, void>({
        query: () => ({
          method: 'GET',
          url: `${thesisDissertationFileEntryUrl.GET_THESIS_ENTRY_LIST_FOR_TRAINEE}`,
        }),

        providesTags: ['thesisDissertationFileEntry'],
      }),

      getThesisEntryListForAdmin: builder.query<APIResponse<any>, void>({
        query: () => ({
          method: 'GET',
          url: `${thesisDissertationFileEntryUrl.GET_THESIS_ENTRY_LIST_FOR_ADMIN}`,
        }),

        providesTags: ['thesisDissertationFileEntry'],
      }),


      createThesisDissertationFileEntry: builder.mutation<
        APIResponse<any>,
        any
      >({
        query: (data: any) => ({
          url: `${thesisDissertationFileEntryUrl.CREATE_THESIS_DISSERTATION_FILE_ENTRY}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['thesisDissertationFileEntry'],
      }),

      createThesisEntryMasterWithDetails: builder.mutation<
        APIResponse<any>,
        any
      >({
        query: (data: any) => ({
          url: `${thesisDissertationFileEntryUrl.CREATE_THESIS_ENTRY_MASTER_WITH_DETAILS}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['thesisEntryMasterWithDetails'],
      }),

      updateInquiryTypeEntry: builder.mutation<
        APIResponse<InquiryList>,
        any
      >({
        query: (url) => ({
          url: `${thesisDissertationFileEntryUrl.UPDATE_INQUIRY_TYPE_ENTRY}/${url.id}`,
          method: 'POST',
          body: url.data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['thesisDissertationFileEntry'],
      }),

      deleteInquiryType: builder.mutation({
        query: (id: string) => ({
          method: 'DELETE',
          url: `${thesisDissertationFileEntryUrl.DELETE_INQUIRY_TYPE}/${id}`,
        }),
        invalidatesTags: ['thesisDissertationFileEntry'],
      }),
    }),
  });

export const {
  useGetThesisDissertationFileEntryListQuery,
  useGetThesisEntryDetailsByIdQuery,
  useGetThesisDissertationTitleListQuery,
  useGetThesisDissertationFormSetupListQuery,
  useCreateThesisDissertationFileEntryMutation,
  useCreateThesisEntryMasterWithDetailsMutation,
  useGetThesisEntryListForAdminQuery,
  useGetThesisEntryListForTraineeQuery,
  useUpdateInquiryTypeEntryMutation,
  useDeleteInquiryTypeMutation,
} = thesisDissertationFileEntryApi;
