import { useFormik } from "formik";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { Button, InputField } from "src/components";
import { setFromErrors } from "src/helpers";
import { useProcedureRecordEntryMutation } from "src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi";
import * as Yup from 'yup';

interface RecordOfProcedureProps {
    departmentId?: string;
    procedureRecordToEdit?: ProcedureRecordList;
    setModalShow?: any;
}

const initialValues: ProcedureRecordEntry = {
    procedure_record_id: '',
    form_b_department_id: '',
    period_from: '',
    period_to: '',
    invasive_procedure_name: '',
    number_of_procedure: 0,
};

const ProcedureRecordOfTheDeptModal = (props: RecordOfProcedureProps) => {
    const { departmentId, procedureRecordToEdit, setModalShow } = props;

    const [procedureRecordEntry, {
        data: procedureRecordEntryData,
        isSuccess: isProcedureRecordEntryDataSuccess,
        isLoading: isProcedureRecordEntryDataLoading,
        isError: isProcedureRecordEntryDataError,
        error: procedureRecordEntryDataError,
    }] = useProcedureRecordEntryMutation();

    const err: CustomError = procedureRecordEntryDataError as CustomError;

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({
            period_to: Yup.date().when("period_from", (period_from, schema) => {
                if (period_from) {
                    const fromDate = new Date(period_from);
                    return schema.min(fromDate, 'To date must be greater than from date');
                }
            }),
        }),

        onSubmit: (values: ProcedureRecordEntry) => {
            console.log(values);
            values.form_b_department_id = departmentId;
            procedureRecordEntry(values);
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    const handleFormReset = () => {
        resetForm();
    };

    useEffect(() => {
        if (procedureRecordToEdit?.id) {
            setValues({
                procedure_record_id: procedureRecordToEdit?.id,
                form_b_department_id: procedureRecordToEdit?.form_b_department_id,
                period_from: procedureRecordToEdit?.period_from,
                period_to: procedureRecordToEdit?.period_to,
                invasive_procedure_name: procedureRecordToEdit?.invasive_procedure_name,
                number_of_procedure: procedureRecordToEdit?.number_of_procedure,
            });
        }
    }, []);

    useEffect(() => {
        if (isProcedureRecordEntryDataSuccess) {
            handleFormReset();
            toast.success('Procedure record saved successfully');
            setModalShow(false);
        }
        if (isProcedureRecordEntryDataError) {
            Object.entries(err?.data ? err?.data : {}).forEach((entry: any) => {
                entry[1]?.forEach((message: any) => {
                    toast.error(message);
                });
            });

            setFromErrors(err.data, setErrors);
        }
    }, [isProcedureRecordEntryDataSuccess, isProcedureRecordEntryDataError]);

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title">
                                    <i className="fa fa-plus me-2" />
                                    Procedure record of the department
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    autoComplete="off"
                                    className="custom-form"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    onReset={(e) => {
                                        e.preventDefault();
                                        handleFormReset();
                                    }}
                                >
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="period_from">From</Label>
                                                <InputField
                                                    type="date"
                                                    placeholder=""
                                                    invalid={{ errors, touched }}
                                                    {...getFieldProps('period_from')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="period_to">To</Label>
                                                <InputField
                                                    type="date"
                                                    placeholder=""
                                                    invalid={{ errors, touched }}
                                                    {...getFieldProps('period_to')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3 mt-1">
                                                <Label for="name_of_procedure">Name of invasive procedure done in the department</Label>
                                                <InputField
                                                    type="text"
                                                    placeholder=""
                                                    invalid={{ errors, touched }}
                                                    {...getFieldProps('invasive_procedure_name')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3 mt-4">
                                                <Label for="number_of_procedure">Number of Procedures</Label>
                                                <InputField
                                                    min={'0'}
                                                    type="number"
                                                    placeholder=""
                                                    invalid={{ errors, touched }}
                                                    {...getFieldProps('number_of_procedure')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} className="mt-3">
                                            <FormGroup className="mb-3 text-center">
                                                <Button
                                                    type="reset"
                                                    text="Reset"
                                                    className="btn btn-warning me-2"
                                                ></Button>
                                                <Button
                                                    type="submit"
                                                    text="Save"
                                                    className="btn btn-success me-2"
                                                    disabled={isProcedureRecordEntryDataLoading}
                                                ></Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default ProcedureRecordOfTheDeptModal;