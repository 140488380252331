import { apiSlice } from 'src/rtk/api/apiSlice';
import * as dissertationListForAssigningReviewerUrl from 'src/helpers/url_helper';

export const dissertationListForAssigningReviewerApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['dissertationList', 'dissertationReviewerAssign', 'dissertationReviewerCommentList'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDissertationListForAssigningReviewerByPaginate: builder.query<
        any,
        PaginateForLogbookEntries
      >({
        query: (paginate) => {
          let url = `${dissertationListForAssigningReviewerUrl.GET_DISSERTATION_LIST_FOR_ASSIGNING_REVIEWER_BY_PAGINATE}?category=${'Dissertation'}&`;

          if (paginate.page) {
            url += `page=${paginate.page}&`;
          }
          if (paginate.paginate_per_page) {
            url += `paginate_per_page=${paginate.paginate_per_page}`;
          }
          if (paginate.search) {
            url += `${paginate.search}`;
          }

          return {
            method: 'GET',
            url: url,
          };
        },

        providesTags: ['dissertationList'],
      }),

      getDissertationApprovalByRtmdAdmin: builder.mutation<APIResponse<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${dissertationListForAssigningReviewerUrl.THESIS_DISSERTATION_FILE_APPROVE_BY_RTMD_USER}/${url}`,
        }),
        invalidatesTags: ['dissertationList'],
      }), 

      assignDissertationReviewer: builder.mutation<
        any,
        AssignDissertationReviewer
      >({
        query: (data) => ({
          url: `${dissertationListForAssigningReviewerUrl.ASSIGN_DISSERTATION_REVIEWER}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['dissertationReviewerAssign'],
      }),

      assignThirdReviewer: builder.mutation<
        any,
        AssignDissertationReviewer
      >({
        query: (data) => ({
          url: `${dissertationListForAssigningReviewerUrl.ASSIGN_THIRD_REVIEWER}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['dissertationReviewerAssign'],
      }),

      getDissertationReviewerCommentList: builder.query<DissertationReviewerCommentListResponse, string>({
        query: (id) => ({
          url: `${dissertationListForAssigningReviewerUrl.GET_DISSERTATION_REVIEWER_COMMENT_LIST}/${id}`,
          method: 'GET',
        }),

        providesTags: ['dissertationReviewerCommentList'],
      }),
    }),
  });

export const {
  useGetDissertationListForAssigningReviewerByPaginateQuery,
  useAssignDissertationReviewerMutation,
  useAssignThirdReviewerMutation,
  useGetDissertationReviewerCommentListQuery,
  useGetDissertationApprovalByRtmdAdminMutation,  
} = dissertationListForAssigningReviewerApi;
