import * as React from 'react';
import { Link } from 'react-router-dom';
import { Container, Card, CardHeader, CardBody, Table } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { useGetExamTrainingFeesQuery } from 'src/rtk/features/setup/examTrainingFee/examTrainingFeeSetupApi';
import ExamTrainingFeeTableRow from './ExamTrainingFeeTableRow';

const ExamTrainingFees = () => {
  const { data, error, isLoading, isError } = useGetExamTrainingFeesQuery();

  const err: CustomError = error as CustomError;
  const examTrainingFees = data?.data || [];   

  let content = null;

  if (isLoading) {
    content = <div className="mt-3">Loading...</div>;
  }
  if (!isLoading && isError && error) {
    content = <div className="mt-3">{err.message}</div>;
  }
  if (!isLoading && !isError && examTrainingFees?.length > 0) {
    content = examTrainingFees.map((examTrainingFee: any, index: any) => (
      <ExamTrainingFeeTableRow
        examTrainingFee={examTrainingFee}
        index={index}
        key={examTrainingFee.id}
      />
    ));
  } else {
    content = (
      <tr>
        <td colSpan={11}>
          <div className="text-center text-danger h6">No data available!</div>
        </td>
      </tr>
    );
  }
  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
            title="Exam Training Fee List"
            breadcrumbItem={[
              {
                link: '/setup/exam-training-fee/add',
                name: 'Add Exam Training Fee',
              },
            ]}
         />
        <Card>
          <CardHeader className="d-flex justify-content-end">
            <Link to="/setup/exam-training-fee/add" className="btn btn-primary btn-sm">
              Add Exam Training Fee
            </Link>
          </CardHeader>
          <CardBody>
            <div className="table-responsive text-nowrap text-center  x-overflow-scroll">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Sl. </th>
                    <th>Exam Training</th>
                    <th>Exam Fee (New)</th>
                    <th>Exam Fee (New) - in words</th>
                    <th>Exam Fee</th>
                    <th>Exam Fee (Old) - in words</th>                    
                    <th>Status</th>                  
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default ExamTrainingFees;