import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Label,
    Modal,
    Row
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import store from 'src/rtk/app/store';
import { useGetNoticeListByPaginateQuery, useGetSignatoriesByPermissionQuery } from 'src/rtk/features/notice/publishNoticeApi';
import { useUpdateSignatoryStatusMutation } from 'src/rtk/features/noticeApproval/noticeApprovalApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoGlobalApi';
import * as Yup from 'yup';

interface SearchFields {
    from_date?: string;
    to_date?: string;
    department_id?: string;
    notice_type?: string;
    notice_no?: string;
    notice_subject?: string;
}

const initialValues: SearchFields = {
    from_date: '',
    to_date: '',
    department_id: '',
    notice_type: '',
    notice_no: '',
    notice_subject: '',
};

const NoticeApprovalList = () => {
    document.title = 'Notice Approval | BCPS';
    const { id: courseId } = useParams<{ id: string }>();

    const [modalShow, setModalShow] = useState(false);
    const [page, setPage] = useState<string | number>('1');
    const [paginate_per_page, set_paginate_per_page] = useState(15);
    const [search, setSearch] = useState('');
    const [showForm, setShowForm] = useState<boolean>(true);
    const [note, setNote] = useState<string>('');
    const [noticeDetails, setNoticeDetails] = useState<NoticeList>({} as NoticeList);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [noticeSignatory, setNoticeSignatory] = useState<NoticeTemplateCopyAttachment>({} as NoticeTemplateCopyAttachment);

    const {
        data: noticeListData,
        error: noticeListDataError,
        isLoading: isNoticeListDataLoading,
        isError: isNoticeListDataError,
        isSuccess: isNoticeListDataSuccess,
    } = useGetNoticeListByPaginateQuery({
        page,
        paginate_per_page,
        search,
    });

    const signatoryUsers = useGetSignatoriesByPermissionQuery(
        'can-approve-notice-as-signatory',
    ).data?.data!;

    const { data: subjectList, isError: isSubFetchError } =
        useGetSubjectInfosQuery();

    const departmentOptions: SelectOption[] = (
        subjectList?.data || []
    ).map((item) => ({
        value: item.subject_name ?? '',
        label: item.subject_name ?? '',
    }));

    departmentOptions.push({ value: 'All', label: 'All' });

    const noticeTypeOptions: SelectOption[] =
        useGetLookupDataQuery('notice_type')
            .data?.data[0]?.childs.map((item) => ({
                value: item.name,
                label: item.name,
            }))
            .sort((a, b) => {
                return a.label.localeCompare(b.label);
            }) || [];

    const allNotices = noticeListData?.data?.filter((n: any) => n.type === 'Notice' && n.trn_notice_copy_attachments?.some((attachment: any) => attachment.user_id === store.getState().auth.user.id));

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        set_paginate_per_page(newPerPage);
        setPage(page);
    };

    const [updateSignatoryStatus, { data: signatoryUpdateData, isSuccess: isSignatoryUpdateSuccess, isError: isSignatoryUpdateError, error: signatoryUpdateError }] =
        useUpdateSignatoryStatusMutation();

    const err = signatoryUpdateError as CustomError;

    const approvalFormInitialValues: NoticeApprovalStatus = {
        id: '',
        status: '',
    };

    const {
        values: approvalValues,
        handleSubmit: handleApprovalSubmit,
        resetForm: approvalFormReset,
        setFieldValue: setApprovalFieldValue,
        getFieldProps: getApprovalFieldProps,
        touched: approvalFieldTouched,
        errors: approvalFieldErrors,
    } = useFormik({
        initialValues: approvalFormInitialValues,
        validationSchema: Yup.object({
            status: Yup.string().required('Status is required'),
        }),
        onSubmit: (values) => {
            toast.loading('Loading...');
            setSubmitting(true);
            console.log(values);
            updateSignatoryStatus(values);
        },
    });


    const columns: TableColumn<any>[] = [
        {
            name: 'Sl. No.',
            width: '100px',
            cell: (row, index) => (+page - 1) * paginate_per_page + index + 1,
            center: true,
        },

        {
            name: 'Date',
            selector: (row) =>
                row.notice_date ? moment(row.notice_date).format('DD/MMM/YYYY') : '',
            sortable: false,
            center: true,
        },

        {
            name: 'Notice No.',
            selector: (row) => row.notice_no,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: 'Notice Title',
            selector: (row) => row.notice_subject,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: 'Notice Type',
            selector: (row) => row.notice_type,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: 'Subject',
            selector: (row) =>
                row.department_id ?? '-',
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: 'Action',
            width: '150px',
            cell: (row) => (
                <>
                    {/* btn-group-example */}
                    <div className="btn-group my-2">
                        <button
                            className="btn btn-sm btn-info"
                            onClick={() => {
                                viewHandler(row);
                            }}
                        >
                            <span className="fas fa-eye"></span> View
                        </button>
                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const {
        handleSubmit,
        getFieldProps,
        values,
        touched,
        errors,
        resetForm,
        setErrors,
    } = useFormik({
        initialValues,

        onSubmit: (values) => {
            let s: string = '';

            if (values.from_date !== '') {
                s = s + `&from_date=${values.from_date}`;
            }

            if (values.to_date !== '') {
                s = s + `&to_date=${values.to_date}`;
            }

            if (values.department_id !== '') {
                s = s + `&department_id=${values.department_id}`;
            }

            if (values.notice_type !== '') {
                s = s + `&notice_type=${values.notice_type}`;
            }

            if (values.notice_no !== '') {
                s = s + `&notice_no=${values.notice_no}`;
            }

            if (values.notice_subject !== '') {
                s = s + `&notice_subject=${values.notice_subject}`;
            }

            setSearch(s);
            setPage('1');
            set_paginate_per_page(15);
        },
    });

    // useEffect(() => {
    //     if (isNoticeListDataLoading || !isNoticeListDataSuccess) {
    //         toast.dismiss();
    //         toast.loading('Data Loading...');
    //     } else {
    //         toast.dismiss();
    //         toast.success('Data Load Successfully');
    //     }
    // }, [isNoticeListDataLoading, isNoticeListDataSuccess]);

    useEffect(() => {
        if (isSignatoryUpdateSuccess) {
            setSubmitting(false);
            approvalFormReset();
            setModalShow(!modalShow);
            toast.dismiss();
            toast.success('Approval Done Successfully');
        }
        if (isSignatoryUpdateError) {
            setSubmitting(false);
            toast.dismiss();
            toast.error(err?.message || 'Something went wrong');
        }
    }, [isSignatoryUpdateSuccess, isSignatoryUpdateError]);

    const viewHandler = (
        data: any = {},
    ) => {
        toast.loading('Notice Details Loading...');
        setModalShow(!modalShow);
        approvalFormReset();
        let pData = data;
        let noticeSignatory: any = pData?.trn_notice_copy_attachments?.find((attachment: any) => attachment?.user_id === store.getState().auth.user.id);
        if (noticeSignatory?.id) {
            approvalValues['id'] = noticeSignatory?.id;
        } else {
            approvalValues['id'] = '';
        }

        if (noticeSignatory?.signatory_status === 'Approve' || noticeSignatory?.signatory_status === 'Reject') {
            approvalValues['status'] = noticeSignatory?.signatory_status;
        } else {
            approvalValues['status'] = '';
        }
        // setNote(note);
        if (
            !noticeSignatory?.id
        ) {
            setShowForm(false);
        } else {
            setShowForm(true);
        }

        // console.log(pData);
        setNoticeDetails(pData);
    };

    return (
        <div className="page-content">
            <Container fluid>
                <BetterBreadcrumb title="Notice Approval List" />
                <Card>
                    <CardBody>
                        <h6 className='block-heading d-flex align-items-center'>Notice Approval</h6>
                        <Form
                            className="custom-form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                //   handleSubmit();
                            }}
                        >
                            <div className="filter-wrapper">
                                <FormGroup>
                                    <Label for="from_date">From Date</Label>
                                    <InputField
                                        type="date"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('from_date')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="to_date">To Date</Label>
                                    <InputField
                                        type="date"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('to_date')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="department_id">Subject</Label>
                                    <InputField
                                        type="select"
                                        options={departmentOptions}
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('department_id')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="notice_type">Notice Type</Label>
                                    <InputField
                                        type="select"
                                        options={noticeTypeOptions}
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('notice_type')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="notice_no">Notice No.</Label>
                                    <InputField
                                        type="text"
                                        placeholder='Notice No'
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('notice_no')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="notice_subject">Notice Title</Label>
                                    <InputField
                                        type="text"
                                        placeholder='Notice Title'
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('notice_subject')}
                                    />
                                </FormGroup>

                                <FormGroup className="btn-group btn-group-example mb-3">
                                    <Button
                                        color="danger"
                                        type="reset"
                                        text="Reset"
                                        onClick={() => {
                                            resetForm();
                                            handleSubmit();
                                        }}
                                    />
                                    <Button
                                        type="button"
                                        text="Search"
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    />
                                </FormGroup>
                            </div>
                        </Form>
                        <DataTable
                            columns={columns}
                            data={allNotices ? allNotices : []}
                            pagination
                            paginationServer
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            progressPending={isNoticeListDataLoading}
                            paginationTotalRows={allNotices?.length}
                            persistTableHead={true}
                            expandOnRowClicked
                            highlightOnHover
                            paginationRowsPerPageOptions={[2, 5, 10, 15, 20, 50, 100, 200]}
                        />
                    </CardBody>
                </Card>
            </Container>
            <Modal
                onOpened={function () {
                    toast.dismiss();
                }}
                isOpen={modalShow}
                scrollable={false}
                size="lg"
                toggle={() => {
                    document.body.classList.add('no_padding');
                }}
                backdrop={'static'}
                modalTransition={{ timeout: 0 }}
                backdropTransition={{ timeout: 0 }}
            >
                <Form
                    className="custom-form"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleApprovalSubmit();
                    }}
                    onReset={(e) => {
                        e.preventDefault();
                        approvalFormReset();
                    }}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            Details Information
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => {
                                setModalShow(!modalShow);
                            }}
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body p-0">
                        <div className="bordered mb-3 p-4">
                            <Row>
                                <Col sm={12} className="table-responsive">
                                    <table className="table table-bordered border-light table-hover text-capitalize">
                                        <thead>
                                            <tr className="bg-light text-dark">
                                                <th colSpan={2}>Notice Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Notice No.</th>
                                                <td>{noticeDetails?.notice_no}</td>
                                            </tr>
                                            <tr>
                                                <th>Notice Subject</th>
                                                <td>{noticeDetails?.notice_subject}</td>
                                            </tr>
                                            <tr>
                                                <th>Notice Type</th>
                                                <td>{noticeDetails?.notice_type}</td>
                                            </tr>
                                            <tr>
                                                <th>Department</th>
                                                <td>{noticeDetails?.department_id}</td>
                                            </tr>
                                            <tr>
                                                <th>Notice Date</th>
                                                <td>{noticeDetails?.notice_date?.substring(0, 10)}</td>
                                            </tr>
                                            <tr>
                                                <th className="">Status</th>
                                                <td>{noticeDetails?.trn_notice_copy_attachments?.find((attachment) => attachment.user_id === store.getState().auth.user.id)?.signatory_status === 'Approve' ? 'Approved' : noticeDetails?.trn_notice_copy_attachments?.find((attachment) => attachment.user_id === store.getState().auth.user.id)?.signatory_status === 'Reject' ? 'Rejected' : noticeDetails?.trn_notice_copy_attachments?.find((attachment) => attachment.user_id === store.getState().auth.user.id)?.signatory_status}</td>
                                            </tr>
                                        </tbody>
                                        {showForm ? <tfoot>
                                            <tr className="bg-light text-dark text-center">
                                                <th colSpan={2}>Approval Form</th>
                                            </tr>
                                            <tr>
                                                <th>Status</th>
                                                <td>
                                                    <div>
                                                        <FormGroup check inline className="mb-3 ">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="status"
                                                                value={approvalValues?.status}
                                                                checked={
                                                                    approvalValues?.status === 'Approve'
                                                                        ? true
                                                                        : false
                                                                }
                                                                id="approve"
                                                                onChange={() => {
                                                                    setApprovalFieldValue('status', 'Approve');
                                                                }}
                                                            />
                                                            <label
                                                                className="form-check-label text-success"
                                                                htmlFor="approve"
                                                            >
                                                                Approved
                                                            </label>
                                                        </FormGroup>

                                                        <FormGroup check inline className="mb-3 ">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="status"
                                                                value={approvalValues?.status}
                                                                checked={
                                                                    approvalValues?.status === 'Reject'
                                                                        ? true
                                                                        : false
                                                                }
                                                                id="reject"
                                                                onChange={() => {
                                                                    setApprovalFieldValue('status', 'Reject');
                                                                }}
                                                            />
                                                            <label
                                                                className="form-check-label text-danger"
                                                                htmlFor="reject"
                                                            >
                                                                Reject
                                                            </label>
                                                        </FormGroup>

                                                        {/* <FormGroup check inline className="mb-3 ">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="status"
                                                                value={approvalValues?.status}
                                                                id="correction"
                                                                checked={
                                                                    approvalValues?.status === 'Correction'
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={() => {
                                                                    setApprovalFieldValue('status', 'Correction');
                                                                }}
                                                            />
                                                            <label
                                                                className="form-check-label text-info"
                                                                htmlFor="correction"
                                                            >
                                                                Correction
                                                            </label>
                                                        </FormGroup> */}

                                                        {approvalFieldErrors.status && approvalFieldTouched.status && (
                                                            <div className="text-danger">
                                                                {approvalFieldErrors.status}
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>

                                            {/* <tr>
                                                <th>{approvalValues?.status} Note</th>
                                                <td>
                                                    <InputField
                                                        type="textarea"
                                                        placeholder={
                                                            'Enter ' + approvalValues?.status + ' Note'
                                                        }
                                                        invalid={{ errors: approvalFieldErrors, touched: approvalFieldTouched }}
                                                        {...getFieldProps('message')}
                                                    />
                                                </td>
                                            </tr> */}
                                            <tr>
                                                <td colSpan={2}>
                                                    {showForm && (
                                                        <Button
                                                            type="submit"
                                                            text="Update"
                                                            className="btn btn-success"
                                                            disabled={submitting}
                                                        // onClick={(e) => {           
                                                        //     e.preventDefault();                                                 
                                                        //     console.log(approvalValues);
                                                        //     console.log(approvalFieldErrors);  
                                                        //     handleApprovalSubmit();                                                              
                                                        // }}
                                                        ></Button>
                                                    )}
                                                </td>
                                            </tr>
                                        </tfoot> : null}
                                    </table>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Form>
            </Modal>
        </div>
    );
};

export default NoticeApprovalList;
