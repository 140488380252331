import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Row
} from 'reactstrap';
import * as Yup from 'yup';
// //Import Scrollbar
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { Button } from 'src/components';
import store from 'src/rtk/app/store';
import { useCreateByPostMutation, useGetCommentsDataByMutation, useGetDataQuery, useUpdateByPatchMutation, } from 'src/rtk/features/disertation-thesis/crudApi';
import { useGetPersonalProfileQuery } from 'src/rtk/features/trainee/traineeApi';
import Attachments from './Attachment';
import BasicInformation from './BasicInformation';
import GuideSignature from './GuideSignature';
import MaterialAndMethods from './MaterialAndMethods';
import Objectives from './Objectives';
import ResearcherSignature from './ResearcherSignature';
import SimpleComponentWithRichTextEditor from './SimpleComponentWithRichTextEditor';
import { apiUrls, sideBarIndexInitialValues } from "./StaticFilesProtocolEntry";
import StudyDesign from './StudyDesign';


let sideBarIndex = structuredClone(sideBarIndexInitialValues);

const arrayOfSideBaritems = Object.values(sideBarIndex).map((item) => (item as { numberText: string }).numberText);

sideBarIndex.basic_information_of_the_thesis.isSelected = true;

const ProtocolEntryMockView = () => {
    const { protocolId } = useParams<{ protocolId: string }>();

    // fetch relevant data
    const { data: personalProfile } = useGetPersonalProfileQuery();
    const currentUser = store.getState().auth.user;

    const {
        data: title,
        isLoading: titleLoading,
        isSuccess: titleLoadingSuccess,
        error: titleLoadingError,
    } = useGetDataQuery(`${apiUrls.titleUrl}`);

    const {
        data: existingProtocol,
        isLoading: existingProtocoLoading,
        isSuccess: existingProtocoLoadingSuccess,
        error: existingProtocoLoadingError,
    } = useGetDataQuery(`${apiUrls.getProtocolListUrl}${protocolId}`);

    const [
        commentsDataList,
        {
          isLoading: commentsDataListLoading,
          isSuccess: commentsDataListLoadingSuccess,
          error: commentsDataListLoadingError,
        },
      ] = useGetCommentsDataByMutation();

    const initialValues: any = {
        relevant_faculty: '',
        relevant_discipline: '',
        summary: '',
        place_of_study: '',
        study_period: '',
        study_design: '',
        introduction_context: '',
        rationale_of_the_research: '',
        research_question_hypothesis: '',
        // objectives: { general_objectives: '', specific_objectives: '' },
        objectives: [],
        material_methods: [],
        ethical_implication: '',
        total_budget: '',
        total_budget_text: '',
        source_of_funding: '',
        facilities_available_at_the_place_of_the_study: '',
        other_facilites_needed: '',
        dissemination_and_use_of_findings: '',
        references: '',
        any_other_relevant_info: '',
        others: '',
        submission_status: '',
        attachments: [],
        // not required to post
        agree_date_researcher: '',
    };

    const [id, setId] = useState<string>('');
    const [btnClicked, setBtnClicked] = useState<string>('');

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({

        }),
        onSubmit: (values) => {

            if (btnClicked === 'Submit') {
                values.submission_status = 'Submit';
            } else if (btnClicked === 'Draft') {
                values.submission_status = 'Draft';
            }

            // process  attachments
            const attachmentsList = attachmentInputList;

            attachmentsList?.forEach((attachment: any, index: number) => {
                let fileIndex = `file_${index}`;
                values[fileIndex] = attachment.attachmentFile;
                values?.attachments?.push({
                    type: attachment.attachmentType,
                    add: 1,
                    fileIndex: fileIndex,
                });
            });

            // process richtexts

            objectivesRichtexts.forEach((item: any, index: number) => {

                if (item.parent === sideBarIndex.objectives.objKey) {

                    if (values?.objectives.length > 0) {
                        if (values!.objectives.find((objective: any) => objective.key === item.name)) {
                            values!.objectives.find((objective: any) => objective.key === item.name).value.value = item.value;
                        } else {
                            values?.objectives?.push({
                                key: item.name,
                                value: item,
                            });
                        }

                    } else {
                        values?.objectives?.push({
                            key: item.name,
                            value: item,
                        });
                    }
                }

                if (item.parent === sideBarIndex.materials_and_methods.objKey) {

                    if (values?.material_methods.length > 0) {

                        if (values!.material_methods.find((material: any) => material.key === item.name)) {
                            values!.material_methods.find((material: any) => material.key === item.name).value.value = item.value;
                        } else {
                            values?.material_methods?.push({
                                key: item.name,
                                value: item,
                            });

                        }
                    } else {
                        values?.material_methods?.push({
                            key: item.name,
                            value: item,
                        });

                    }

                }

                if (item.parent === 'none') {
                    values[item.name] = item.value;
                }
            });

            // process form data
            const formData = new FormData();
            Object.keys(values).forEach((key) => {

                // attachments
                if (key === 'attachments') {
                    for (var i = 0; i < values[key].length; i++) {
                        formData.append('attachments[]', JSON.stringify({ ...values[key][i] }));
                    }
                } else if (key === sideBarIndex.objectives.objKey) {
                    // objectives
                    formData.set(sideBarIndex.objectives.objKey, JSON.stringify(values[key]));
                } else if (key === sideBarIndex.materials_and_methods.objKey) {
                    // materials
                    formData.set(sideBarIndex.materials_and_methods.objKey, JSON.stringify(values[key]));
                } else {
                    // others
                    formData.append(key, values[key]);
                }

            });

            if (id) {
                let postData = {
                    queryUrl: apiUrls.updateUrl,
                    formData: formData,
                };

                createByPost(postData);

                // patch
                // const updateUrl = apiUrls.updateUrl + putUrlString(id, formData);
                // updateByPatch(updateUrl);

            } else {
                let postData = {
                    queryUrl: apiUrls.createUrl,
                    formData: formData,
                };

                createByPost(postData);
            }
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    // side bar
    const ref = useRef<any>();
    const [switchExpressionForSidebar, SetSwitchExpressionForSidebar] = useState(
        sideBarIndexInitialValues.basic_information_of_the_thesis.numberText,
    );
    const [contentTilte, setContentTitle] = useState(
        `${sideBarIndexInitialValues.basic_information_of_the_thesis.numberText}. ${sideBarIndexInitialValues.basic_information_of_the_thesis.text}`,
    );

    // rich text
    const [reRenderState, setReRenderState] = useState<number>(1);
    const [initialContentEditor, setInitialContentEditor] = useState<string>('');
    const [initialContentLoaded, setInitialContentLoaded] = useState<boolean>(false);

    // side bar functionality
    const handleSideBarItemClick = (sidebarNumberText: string) => {
        sideBarIndex = structuredClone(sideBarIndexInitialValues);

        switch (sidebarNumberText) {
            case sideBarIndex.basic_information_of_the_thesis.numberText:
                SetSwitchExpressionForSidebar(
                    sideBarIndex.basic_information_of_the_thesis.numberText,
                );
                sideBarIndex.basic_information_of_the_thesis.isSelected = true;
                setContentTitle(
                    `${sideBarIndex.basic_information_of_the_thesis.numberText}. ${sideBarIndex.basic_information_of_the_thesis.text}`,
                );

                break;
            case sideBarIndex.study_design.numberText:
                SetSwitchExpressionForSidebar(sideBarIndex.study_design.numberText);
                sideBarIndex.study_design.isSelected = true;
                setContentTitle(
                    `${sideBarIndex.study_design.numberText}. ${sideBarIndex.study_design.text}`,
                );

                break;
            case sideBarIndex.introduction_context.numberText:
                SetSwitchExpressionForSidebar(
                    sideBarIndex.introduction_context.numberText,
                );
                sideBarIndex.introduction_context.isSelected = true;
                setContentTitle(
                    `${sideBarIndex.introduction_context.numberText}. ${sideBarIndex.introduction_context.text}`,
                );

                break;
            case sideBarIndex.rationale_of_research.numberText:
                SetSwitchExpressionForSidebar(
                    sideBarIndex.rationale_of_research.numberText,
                );
                sideBarIndex.rationale_of_research.isSelected = true;
                setContentTitle(
                    `${sideBarIndex.rationale_of_research.numberText}. ${sideBarIndex.rationale_of_research.text}`,
                );

                break;
            case sideBarIndex.research_question_hypothesis.numberText:
                SetSwitchExpressionForSidebar(
                    sideBarIndex.research_question_hypothesis.numberText,
                );
                sideBarIndex.research_question_hypothesis.isSelected = true;
                setContentTitle(
                    `${sideBarIndex.research_question_hypothesis.numberText}. ${sideBarIndex.research_question_hypothesis.text}`,
                );

                break;
            case sideBarIndex.objectives.numberText:
                SetSwitchExpressionForSidebar(sideBarIndex.objectives.numberText);
                sideBarIndex.objectives.isSelected = true;
                setContentTitle(
                    `${sideBarIndex.objectives.numberText}. ${sideBarIndex.objectives.text}`,
                );

                break;
            case sideBarIndex.materials_and_methods.numberText:
                SetSwitchExpressionForSidebar(
                    sideBarIndex.materials_and_methods.numberText,
                );
                sideBarIndex.materials_and_methods.isSelected = true;
                setContentTitle(
                    `${sideBarIndex.materials_and_methods.numberText}. ${sideBarIndex.materials_and_methods.text}`,
                );

                break;
            case sideBarIndex.ethical_implication.numberText:
                SetSwitchExpressionForSidebar(
                    sideBarIndex.ethical_implication.numberText,
                );
                sideBarIndex.ethical_implication.isSelected = true;
                setContentTitle(
                    `${sideBarIndex.ethical_implication.numberText}. ${sideBarIndex.ethical_implication.text}`,
                );

                break;
            case sideBarIndex.total_budget.numberText:
                SetSwitchExpressionForSidebar(sideBarIndex.total_budget.numberText);
                sideBarIndex.total_budget.isSelected = true;
                setContentTitle(
                    `${sideBarIndex.total_budget.numberText}. ${sideBarIndex.total_budget.text}`,
                );

                break;
            case sideBarIndex.source_of_funding.numberText:
                SetSwitchExpressionForSidebar(
                    sideBarIndex.source_of_funding.numberText,
                );
                sideBarIndex.source_of_funding.isSelected = true;
                setContentTitle(
                    `${sideBarIndex.source_of_funding.numberText}. ${sideBarIndex.source_of_funding.text}`,
                );

                break;
            case sideBarIndex.facilities_available_at_the_place_of_the_study
                .numberText:
                SetSwitchExpressionForSidebar(
                    sideBarIndex.facilities_available_at_the_place_of_the_study
                        .numberText,
                );
                sideBarIndex.facilities_available_at_the_place_of_the_study.isSelected =
                    true;
                setContentTitle(
                    `${sideBarIndex.facilities_available_at_the_place_of_the_study.numberText}. ${sideBarIndex.facilities_available_at_the_place_of_the_study.text}`,
                );

                break;
            case sideBarIndex.other_facilities_needed.numberText:
                SetSwitchExpressionForSidebar(
                    sideBarIndex.other_facilities_needed.numberText,
                );
                sideBarIndex.other_facilities_needed.isSelected = true;
                setContentTitle(
                    `${sideBarIndex.other_facilities_needed.numberText}. ${sideBarIndex.other_facilities_needed.text}`,
                );

                break;
            case sideBarIndex.dissemination_and_use_of_findings.numberText:
                SetSwitchExpressionForSidebar(
                    sideBarIndex.dissemination_and_use_of_findings.numberText,
                );
                sideBarIndex.dissemination_and_use_of_findings.isSelected = true;
                setContentTitle(
                    `${sideBarIndex.dissemination_and_use_of_findings.numberText}. ${sideBarIndex.dissemination_and_use_of_findings.text}`,
                );

                break;
            case sideBarIndex.references.numberText:
                SetSwitchExpressionForSidebar(sideBarIndex.references.numberText);
                sideBarIndex.references.isSelected = true;
                setContentTitle(
                    `${sideBarIndex.references.numberText}. ${sideBarIndex.references.text}`,
                );

                break;
            case sideBarIndex.any_other_relevant_info.numberText:
                SetSwitchExpressionForSidebar(
                    sideBarIndex.any_other_relevant_info.numberText,
                );
                sideBarIndex.any_other_relevant_info.isSelected = true;
                setContentTitle(
                    `${sideBarIndex.any_other_relevant_info.numberText}. ${sideBarIndex.any_other_relevant_info.text}`,
                );

                break;
            case sideBarIndex.signature_of_the_researcher.numberText:
                SetSwitchExpressionForSidebar(
                    sideBarIndex.signature_of_the_researcher.numberText,
                );
                sideBarIndex.signature_of_the_researcher.isSelected = true;
                setContentTitle(
                    `${sideBarIndex.signature_of_the_researcher.numberText}. ${sideBarIndex.signature_of_the_researcher.text}`,
                );

                break;
            case sideBarIndex.signature_of_the_guide_and_co_guide.numberText:
                SetSwitchExpressionForSidebar(
                    sideBarIndex.signature_of_the_guide_and_co_guide.numberText,
                );
                sideBarIndex.signature_of_the_guide_and_co_guide.isSelected = true;
                setContentTitle(
                    `${sideBarIndex.signature_of_the_guide_and_co_guide.numberText}. ${sideBarIndex.signature_of_the_guide_and_co_guide.text}`,
                );

                break;
            case sideBarIndex.attachment.numberText:
                SetSwitchExpressionForSidebar(
                    sideBarIndex.attachment.numberText,
                );
                sideBarIndex.attachment.isSelected = true;
                setContentTitle(
                    `${sideBarIndex.attachment.text}`,
                );

                break;
            default:

                break;
        }
    };

    // handel next - previous
    const handleNextButton = (numberText: string) => {
        const isNumtext = (element: any) => element === numberText;
        let targetIndex = arrayOfSideBaritems.findIndex(isNumtext);
        let nextNumberText = arrayOfSideBaritems[targetIndex + 1];
        handleSideBarItemClick(nextNumberText);
    };
    const handlePreviousButton = (numberText: string) => {
        const isNumtext = (element: any) => element === numberText;
        let targetIndex = arrayOfSideBaritems.findIndex(isNumtext);
        if (targetIndex !== 0) {
            let nextNumberText = arrayOfSideBaritems[targetIndex - 1];
            handleSideBarItemClick(nextNumberText);
        }
    };

    // attachments
    const [attachmentInputList, setAttachmentInputList] = useState<any>([]);

    // objectives
    const [objectivesRichtexts, setObjectivesRichtexts] = useState<any>([]);

    // api communications

    // useEffect based on fetching of relevant data

    useEffect(() => {

        if (existingProtocoLoadingError) {
            toast.dismiss();
            setInitialContentLoaded(true);
            toast.success('Data not found. Please create first.');
        } else if (existingProtocoLoading || !existingProtocoLoadingSuccess) {
            toast.dismiss();
        } else if (existingProtocoLoadingSuccess) {
            let existingProtocolData = existingProtocol?.data || false;
            if (existingProtocolData['id']) {
                setId(existingProtocolData['id']);
                let objectivesJsonObject, materialsJsonIbject, attachmentJsonObject;
                // process received existing protocol
                // console.log(' before set value formik values', formik.values);

                Object.keys(formik.values).forEach((key) => {
                    // console.log('protocol values: key : ', existingProtocolData[key]);

                    if (key === sideBarIndex.objectives.objKey) {
                        objectivesJsonObject = JSON.parse(existingProtocolData[key]);
                        setFieldValue(key, JSON.parse(existingProtocolData[key]));
                        objectivesJsonObject.forEach((objective: any) => {
                            objectivesRichtexts.push({
                                parent: objective.value.parent,
                                value: objective.value.value,
                                name: objective.value.name
                            });
                        });

                    } else if (key === sideBarIndex.materials_and_methods.objKey) {
                        materialsJsonIbject = JSON.parse(existingProtocolData[key]);
                        setFieldValue(key, JSON.parse(existingProtocolData[key]));
                        materialsJsonIbject.forEach((material: any) => {
                            objectivesRichtexts.push({
                                parent: material.value.parent,
                                value: material.value.value,
                                name: material.value.name
                            });
                        });
                    } else if (key === sideBarIndex.attachment.ObjKey) {

                    } else {
                        setFieldValue(key, existingProtocolData[key]);
                    }


                });

                //   setValues(initialValues);
                formik.setFieldValue(sideBarIndex.attachment.ObjKey, initialValues.attachments);
                formik.setFieldValue(sideBarIndex.total_budget.total_budget_objKey, 0);


                //   getCommentsData(vData['id']);
                //   setViewData(vData);
                //   setShowForm(false);


                //   inputFields.findIndex((item: any) => {
                //     setFieldValue(item.name, vData[item.name] || '');
                //   })
                // setFieldValue("id", vData["id"] || '');



                //   Object.keys(vData).map((itemKey: any) => {
                //     console.log('itemKey' ,itemKey);
                //     setFieldValue(itemKey, vData[itemKey] || '');
                //   });







            } else {
                setInitialContentLoaded(true);
                //   setShowForm(true);
            }
            toast.dismiss();
        }

        if (titleLoadingSuccess) {
            setFieldValue('place_of_study', title.data.place_of_study ?? '');
            setFieldValue('study_period', title.data.study_period ?? '');
        }

    }, [existingProtocoLoading, existingProtocoLoadingSuccess, existingProtocoLoadingError, titleLoadingSuccess]);

    const [createByPost, { isSuccess, isLoading, isError, error }] =
        useCreateByPostMutation();
    const [
        updateByPatch,
        {
            isSuccess: isUpdateSuccess,
            isLoading: isUpdateLoading,
            isError: isUpdateError,
            error: updateError,
        },
    ] = useUpdateByPatchMutation();

    useEffect(() => {
        // console.log('formik values: ', formik.values);
    }, [formik.values])

    return (
        <React.Fragment>
            <Container fluid className="page-content">
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <p className='header-basic-info'>Name: {existingProtocol?.data?.title?.trainee_name}</p>                               
                            </Col>
                            <Col>
                                <p className='header-basic-info'>BMDC Reg. No: {existingProtocol?.data?.bmdc_registration_no}</p>                              
                            </Col>                        
                            <Col>
                                <p className='header-basic-info'>
                                    Guide: {existingProtocol?.data?.title?.guide_name || 'N/A'}
                                </p>                         
                            </Col>
                            <Col>
                                <p className='header-basic-info'>
                                    Co-Guide: {existingProtocol?.data?.title?.co_guide_1_name ? `${existingProtocol?.data?.title?.co_guide_1_name}` : ``}
                                    {existingProtocol?.data?.title?.co_guide_2_name ? `, ${existingProtocol?.data?.title?.co_guide_2_name}` : ``}
                                    {existingProtocol?.data?.title?.co_guide_3_name ? `, ${existingProtocol?.data?.title?.co_guide_3_name}` : ``}
                                </p>                           
                            </Col>
                            <Col>
                                <p>Protocol No: <b>{existingProtocol?.data?.protocol_no ?? '-'}</b></p>
                            </Col>
                            <Col>
                                {existingProtocol?.data?.submission_status === 'Submit' ? (
                                    <p className='header-basic-info'>
                                        Protocol Status: <span
                                            className='badge rounded-pill d-inline-block  badge-applied font-size-12 fw-medium'> Submitted</span>
                                    </p>


                                ) : existingProtocol?.data?.submission_status === 'Approve' ? (
                                    <p className='header-basic-info'>
                                        Protocol Status: <span
                                            className='badge rounded-pill  badge-approved font-size-12 fw-medium'>Approved</span>
                                    </p>


                                ) : (
                                    <p className='header-basic-info'>
                                        Protocol Status: <span className='badge rounded-pill  badge-draft font-size-12 fw-medium'> Not Yet Submitted</span>
                                    </p>
                                )
                                }                               
                            </Col>
                        </Row>
                        <Row>
                            <p className='header-basic-info-title'>
                                Title: {existingProtocol?.data?.title?.title_of_thesis || 'N/A'}
                            </p>                
                        </Row>
                    </CardBody>
                </Card>
                <Row>
                    {/* header */}
                    {/* body                    */}
                    <Row className="padding-right-0">
                        {/* side bar                */}
                        <Col mb={2} lg={2} md={2}>
                            {/* side bar */}
                            <div className="vertical-menu-protocol-entry">
                                <React.Fragment>
                                    <SimpleBar style={{ maxHeight: '100%' }}>
                                        <div className='protocol-entry-sidebar-menu'>
                                            <ul>
                                                <li
                                                    className={sideBarIndex.basic_information_of_the_thesis.isSelected ? 'menu-title-protocol-entry clicked' : 'menu-title-protocol-entry'}
                                                    onClick={() =>
                                                        handleSideBarItemClick(
                                                            sideBarIndex.basic_information_of_the_thesis
                                                                .numberText,
                                                        )
                                                    }
                                                >
                                                    <div className='protocol-entry-badge'>
                                                        1 - 8
                                                    </div>
                                                    <div
                                                        className={sideBarIndex.basic_information_of_the_thesis.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                                                        Basic information of the thesis
                                                    </div>

                                                </li>
                                                <li
                                                    className={sideBarIndex.study_design.isSelected ? 'menu-title-protocol-entry clicked' : 'menu-title-protocol-entry '}
                                                    onClick={() =>
                                                        handleSideBarItemClick(
                                                            sideBarIndex.study_design.numberText,
                                                        )
                                                    }
                                                >

                                                    <div className='protocol-entry-badge'>
                                                        9
                                                    </div>
                                                    <div
                                                        className={sideBarIndex.study_design.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                                                        Study Design
                                                    </div>

                                                </li>
                                                <li
                                                    className={sideBarIndex.introduction_context.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                                                    onClick={() =>
                                                        handleSideBarItemClick(
                                                            sideBarIndex.introduction_context.numberText,
                                                        )
                                                    }
                                                >

                                                    <div className='protocol-entry-badge'>10</div>
                                                    <div
                                                        className={sideBarIndex.introduction_context.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                                                        {sideBarIndex.introduction_context.text}
                                                    </div>

                                                </li>
                                                <li
                                                    className={sideBarIndex.rationale_of_research.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                                                    onClick={() =>
                                                        handleSideBarItemClick(
                                                            sideBarIndex.rationale_of_research.numberText,
                                                        )
                                                    }
                                                >

                                                    <div className='protocol-entry-badge'>11</div>
                                                    <div
                                                        className={sideBarIndex.rationale_of_research.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                                                        Rationale of Research

                                                    </div>
                                                </li>
                                                <li
                                                    className={sideBarIndex.research_question_hypothesis.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                                                    onClick={() =>
                                                        handleSideBarItemClick(
                                                            sideBarIndex.research_question_hypothesis
                                                                .numberText,
                                                        )
                                                    }
                                                >

                                                    <div className='protocol-entry-badge'>12</div>
                                                    <div
                                                        className={sideBarIndex.research_question_hypothesis.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                                                        Research Question/Hypothesis
                                                    </div>

                                                </li>
                                                <li
                                                    className={sideBarIndex.objectives.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                                                    onClick={() =>
                                                        handleSideBarItemClick(
                                                            sideBarIndex.objectives.numberText,
                                                        )
                                                    }
                                                >

                                                    <div className='protocol-entry-badge'>13</div>
                                                    <div
                                                        className={sideBarIndex.objectives.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>Objectives
                                                    </div>

                                                </li>
                                                <li
                                                    className={sideBarIndex.materials_and_methods.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                                                    onClick={() =>
                                                        handleSideBarItemClick(
                                                            sideBarIndex.materials_and_methods.numberText,
                                                        )
                                                    }
                                                >

                                                    <div className='protocol-entry-badge'>14</div>
                                                    <div
                                                        className={sideBarIndex.materials_and_methods.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                                                        Materials and Methods
                                                    </div>

                                                </li>
                                                <li
                                                    className={sideBarIndex.ethical_implication.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                                                    onClick={() =>
                                                        handleSideBarItemClick(
                                                            sideBarIndex.ethical_implication.numberText,
                                                        )
                                                    }
                                                >

                                                    <div className='protocol-entry-badge'>15</div>
                                                    <div
                                                        className={sideBarIndex.ethical_implication.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                                                        Ethical Implecation
                                                    </div>

                                                </li>
                                                <li
                                                    className={sideBarIndex.total_budget.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                                                    onClick={() =>
                                                        handleSideBarItemClick(
                                                            sideBarIndex.total_budget.numberText,
                                                        )
                                                    }
                                                >

                                                    <div className='protocol-entry-badge'>16</div>
                                                    <div
                                                        className={sideBarIndex.total_budget.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                                                        Total Budget
                                                    </div>

                                                </li>
                                                <li
                                                    className={sideBarIndex.source_of_funding.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                                                    onClick={() =>
                                                        handleSideBarItemClick(
                                                            sideBarIndex.source_of_funding.numberText,
                                                        )
                                                    }
                                                >

                                                    <div className='protocol-entry-badge'>17</div>
                                                    <div
                                                        className={sideBarIndex.source_of_funding.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                                                        Source(s) of Finding(if any)
                                                    </div>

                                                </li>
                                                <li
                                                    className={sideBarIndex.facilities_available_at_the_place_of_the_study.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                                                    onClick={() =>
                                                        handleSideBarItemClick(
                                                            sideBarIndex
                                                                .facilities_available_at_the_place_of_the_study
                                                                .numberText,
                                                        )
                                                    }
                                                >

                                                    <div className='protocol-entry-badge'>18</div>
                                                    <div
                                                        className={sideBarIndex.facilities_available_at_the_place_of_the_study.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                                                        Facilities available at the place of Study
                                                    </div>

                                                </li>
                                                <li
                                                    className={sideBarIndex.other_facilities_needed.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                                                    onClick={() =>
                                                        handleSideBarItemClick(
                                                            sideBarIndex.other_facilities_needed.numberText,
                                                        )
                                                    }
                                                >

                                                    <div className='protocol-entry-badge'>19</div>
                                                    <div
                                                        className={sideBarIndex.other_facilities_needed.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                                                        Other facilities needed
                                                    </div>

                                                </li>
                                                <li
                                                    className={sideBarIndex.dissemination_and_use_of_findings.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                                                    onClick={() =>
                                                        handleSideBarItemClick(
                                                            sideBarIndex.dissemination_and_use_of_findings
                                                                .numberText,
                                                        )
                                                    }
                                                >

                                                    <div className='protocol-entry-badge'>20</div>
                                                    <div
                                                        className={sideBarIndex.dissemination_and_use_of_findings.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                                                        Dissemination and use of findings
                                                    </div>

                                                </li>
                                                <li
                                                    className={sideBarIndex.references.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                                                    onClick={() =>
                                                        handleSideBarItemClick(
                                                            sideBarIndex.references.numberText,
                                                        )
                                                    }
                                                >

                                                    <div className='protocol-entry-badge'>21</div>
                                                    <div
                                                        className={sideBarIndex.references.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>References
                                                    </div>

                                                </li>
                                                <li
                                                    className={sideBarIndex.any_other_relevant_info.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                                                    onClick={() =>
                                                        handleSideBarItemClick(
                                                            sideBarIndex.any_other_relevant_info.numberText,
                                                        )
                                                    }
                                                >

                                                    <div className='protocol-entry-badge'>22</div>
                                                    <div
                                                        className={sideBarIndex.any_other_relevant_info.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                                                        Any other relavent information
                                                    </div>

                                                </li>
                                                <li
                                                    className={sideBarIndex.signature_of_the_researcher.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                                                    onClick={() =>
                                                        handleSideBarItemClick(
                                                            sideBarIndex.signature_of_the_researcher
                                                                .numberText,
                                                        )
                                                    }
                                                >

                                                    <div className='protocol-entry-badge'>23</div>
                                                    <div
                                                        className={sideBarIndex.signature_of_the_researcher.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                                                        Signature of the Researcher
                                                    </div>

                                                </li>
                                                <li
                                                    className={sideBarIndex.signature_of_the_guide_and_co_guide.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                                                    onClick={() =>
                                                        handleSideBarItemClick(
                                                            sideBarIndex.signature_of_the_guide_and_co_guide
                                                                .numberText,
                                                        )
                                                    }
                                                >

                                                    <div className='protocol-entry-badge'>24</div>
                                                    <div
                                                        className={sideBarIndex.signature_of_the_guide_and_co_guide.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                                                        Signature of the Guide and Co-Guide
                                                    </div>

                                                </li>
                                                <li
                                                    className={sideBarIndex.attachment.isSelected ? 'menu-title-protocol-entry clicked ' : 'menu-title-protocol-entry '}
                                                    onClick={() =>
                                                        handleSideBarItemClick(
                                                            sideBarIndex.attachment
                                                                .numberText,
                                                        )
                                                    }
                                                >

                                                    <div className='protocol-entry-badge'><i className='fa-solid fa-paperclip' /></div>
                                                    <div
                                                        className={sideBarIndex.attachment.isSelected ? 'side-bar-item-text clicked' : 'side-bar-item-text'}>
                                                        Attachment
                                                    </div>

                                                </li>
                                            </ul>
                                        </div>
                                    </SimpleBar>
                                </React.Fragment>
                            </div>
                        </Col>

                        {/* content */}
                        <Col mb={10} lg={10} md={10} className="padding-right-0">
                            {/* main content */}
                            <React.Fragment>
                                <b className="content-header">{contentTilte}</b>
                                <Card className="content-card" style={{ pointerEvents: 'none' }}>
                                    {/* load component dynamically */}
                                    <CardBody>
                                        <Form
                                            autoComplete="off"
                                            className="custom-form"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                handleSubmit();
                                            }}
                                        >


                                            {switchExpressionForSidebar ===
                                                sideBarIndex.basic_information_of_the_thesis.numberText &&
                                                (formik.values?.['summary'] || initialContentLoaded) ? (
                                                <BasicInformation
                                                    setObjectivesRichtexts={setObjectivesRichtexts}
                                                    objectivesRichtexts={objectivesRichtexts}
                                                    existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                                                    title={titleLoadingSuccess ? title : { message: 'null' }}
                                                    formik={formik}
                                                    commentsDataList={commentsDataList}
                                                    personalProfile={personalProfile}
                                                    initialContentEditor={initialContentEditor}
                                                    setInitialContentEditor={setInitialContentEditor}
                                                    formikField={sideBarIndex.basic_information_of_the_thesis.objKey}
                                                    renderKey={reRenderState}
                                                />
                                            ) : switchExpressionForSidebar ===
                                                sideBarIndex.study_design.numberText ? (
                                                <StudyDesign
                                                    setObjectivesRichtexts={setObjectivesRichtexts}
                                                    objectivesRichtexts={objectivesRichtexts}
                                                    existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                                                    formikState={formik.values}
                                                    commentsDataList={commentsDataList}
                                                    initialContentEditor={initialContentEditor}
                                                    setInitialContentEditor={setInitialContentEditor}
                                                    formikField={sideBarIndex.study_design.objKey}
                                                    renderKey={reRenderState}
                                                />
                                            ) : switchExpressionForSidebar ===
                                                sideBarIndex.introduction_context.numberText ? (
                                                <SimpleComponentWithRichTextEditor
                                                    setObjectivesRichtexts={setObjectivesRichtexts}
                                                    objectivesRichtexts={objectivesRichtexts}
                                                    existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                                                    formikState={formik.values}
                                                    commentsDataList={commentsDataList}
                                                    initialContentEditor={initialContentEditor}
                                                    setInitialContentEditor={setInitialContentEditor}
                                                    formikField={sideBarIndex.introduction_context.objKey}
                                                    fieldLabel={sideBarIndex.introduction_context.text}
                                                    renderKey={reRenderState}
                                                    key={sideBarIndex.introduction_context.numberText}
                                                />
                                            ) : switchExpressionForSidebar ===
                                                sideBarIndex.rationale_of_research.numberText ? (
                                                <SimpleComponentWithRichTextEditor
                                                    setObjectivesRichtexts={setObjectivesRichtexts}
                                                    objectivesRichtexts={objectivesRichtexts}
                                                    existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                                                    formikState={formik.values}
                                                    commentsDataList={commentsDataList}
                                                    initialContentEditor={initialContentEditor}
                                                    setInitialContentEditor={setInitialContentEditor}
                                                    formikField={sideBarIndex.rationale_of_research.objKey}
                                                    fieldLabel={sideBarIndex.rationale_of_research.text}
                                                    renderKey={reRenderState}
                                                    key={sideBarIndex.rationale_of_research.numberText}
                                                />
                                            ) : switchExpressionForSidebar ===
                                                sideBarIndex.research_question_hypothesis.numberText ? (
                                                <SimpleComponentWithRichTextEditor
                                                    setObjectivesRichtexts={setObjectivesRichtexts}
                                                    objectivesRichtexts={objectivesRichtexts}
                                                    existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                                                    formikState={formik.values}
                                                    commentsDataList={commentsDataList}
                                                    initialContentEditor={initialContentEditor}
                                                    setInitialContentEditor={setInitialContentEditor}
                                                    formikField={
                                                        sideBarIndex.research_question_hypothesis.objKey
                                                    }
                                                    fieldLabel={sideBarIndex.research_question_hypothesis.text}
                                                    renderKey={reRenderState}
                                                    key={
                                                        sideBarIndex.research_question_hypothesis.numberText
                                                    }
                                                />
                                            ) : switchExpressionForSidebar ===
                                                sideBarIndex.ethical_implication.numberText ? (
                                                <SimpleComponentWithRichTextEditor
                                                    setObjectivesRichtexts={setObjectivesRichtexts}
                                                    objectivesRichtexts={objectivesRichtexts}
                                                    existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                                                    formikState={formik.values}
                                                    commentsDataList={commentsDataList}
                                                    initialContentEditor={initialContentEditor}
                                                    setInitialContentEditor={setInitialContentEditor}
                                                    formikField={sideBarIndex.ethical_implication.objKey}
                                                    fieldLabel={sideBarIndex.ethical_implication.text}
                                                    renderKey={reRenderState}
                                                    key={sideBarIndex.ethical_implication.numberText}
                                                />
                                            ) : switchExpressionForSidebar ===
                                                sideBarIndex.total_budget.numberText ? (
                                                <SimpleComponentWithRichTextEditor
                                                    setObjectivesRichtexts={setObjectivesRichtexts}
                                                    objectivesRichtexts={objectivesRichtexts}
                                                    existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                                                    formikState={formik.values}
                                                    commentsDataList={commentsDataList}
                                                    initialContentEditor={initialContentEditor}
                                                    setInitialContentEditor={setInitialContentEditor}
                                                    formikField={sideBarIndex.total_budget.objKey}
                                                    fieldLabel={sideBarIndex.total_budget.text}
                                                    renderKey={reRenderState}
                                                    key={sideBarIndex.total_budget.numberText}
                                                />
                                            ) : switchExpressionForSidebar ===
                                                sideBarIndex.source_of_funding.numberText ? (
                                                <SimpleComponentWithRichTextEditor
                                                    setObjectivesRichtexts={setObjectivesRichtexts}
                                                    objectivesRichtexts={objectivesRichtexts}
                                                    existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                                                    formikState={formik.values}
                                                    commentsDataList={commentsDataList}
                                                    initialContentEditor={initialContentEditor}
                                                    setInitialContentEditor={setInitialContentEditor}
                                                    formikField={sideBarIndex.source_of_funding.objKey}
                                                    fieldLabel={sideBarIndex.source_of_funding.text}
                                                    renderKey={reRenderState}
                                                    key={sideBarIndex.source_of_funding.numberText}
                                                />
                                            ) : switchExpressionForSidebar ===
                                                sideBarIndex
                                                    .facilities_available_at_the_place_of_the_study
                                                    .numberText ? (
                                                <SimpleComponentWithRichTextEditor
                                                    setObjectivesRichtexts={setObjectivesRichtexts}
                                                    objectivesRichtexts={objectivesRichtexts}
                                                    existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                                                    formikState={formik.values}
                                                    commentsDataList={commentsDataList}
                                                    initialContentEditor={initialContentEditor}
                                                    setInitialContentEditor={setInitialContentEditor}
                                                    formikField={
                                                        sideBarIndex
                                                            .facilities_available_at_the_place_of_the_study
                                                            .objKey
                                                    }
                                                    fieldLabel={sideBarIndex.facilities_available_at_the_place_of_the_study.text}
                                                    renderKey={reRenderState}
                                                    key={
                                                        sideBarIndex
                                                            .facilities_available_at_the_place_of_the_study
                                                            .numberText
                                                    }
                                                />
                                            ) : switchExpressionForSidebar ===
                                                sideBarIndex.other_facilities_needed.numberText ? (
                                                <SimpleComponentWithRichTextEditor
                                                    formikState={formik.values}                                                    
                                                    setObjectivesRichtexts={setObjectivesRichtexts}
                                                    existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                                                    objectivesRichtexts={objectivesRichtexts}
                                                    initialContentEditor={initialContentEditor}
                                                    setInitialContentEditor={setInitialContentEditor}
                                                    formikField={
                                                        sideBarIndex.other_facilities_needed.objKey
                                                    }
                                                    commentsDataList={commentsDataList}
                                                    fieldLabel={sideBarIndex.other_facilities_needed.text}
                                                    renderKey={reRenderState}
                                                    key={sideBarIndex.other_facilities_needed.numberText}
                                                />
                                            ) : switchExpressionForSidebar ===
                                                sideBarIndex.dissemination_and_use_of_findings
                                                    .numberText ? (
                                                <SimpleComponentWithRichTextEditor
                                                    setObjectivesRichtexts={setObjectivesRichtexts}
                                                    objectivesRichtexts={objectivesRichtexts}
                                                    existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                                                    formikState={formik.values}
                                                    commentsDataList={commentsDataList}
                                                    initialContentEditor={initialContentEditor}
                                                    setInitialContentEditor={setInitialContentEditor}
                                                    formikField={
                                                        sideBarIndex.dissemination_and_use_of_findings.objKey
                                                    }
                                                    fieldLabel={sideBarIndex.dissemination_and_use_of_findings.text}
                                                    renderKey={reRenderState}
                                                    key={
                                                        sideBarIndex.dissemination_and_use_of_findings
                                                            .numberText
                                                    }
                                                />
                                            ) : switchExpressionForSidebar ===
                                                sideBarIndex.references.numberText ? (
                                                <SimpleComponentWithRichTextEditor
                                                    setObjectivesRichtexts={setObjectivesRichtexts}
                                                    objectivesRichtexts={objectivesRichtexts}
                                                    existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                                                    formikState={formik.values}
                                                    commentsDataList={commentsDataList}
                                                    initialContentEditor={initialContentEditor}
                                                    setInitialContentEditor={setInitialContentEditor}
                                                    formikField={sideBarIndex.references.objKey}
                                                    fieldLabel={sideBarIndex.references.text}
                                                    renderKey={reRenderState}
                                                    key={sideBarIndex.references.numberText}
                                                />
                                            ) : switchExpressionForSidebar ===
                                                sideBarIndex.any_other_relevant_info.numberText ? (
                                                <SimpleComponentWithRichTextEditor
                                                    setObjectivesRichtexts={setObjectivesRichtexts}
                                                    objectivesRichtexts={objectivesRichtexts}
                                                    existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                                                    formikState={formik.values}
                                                    commentsDataList={commentsDataList}
                                                    initialContentEditor={initialContentEditor}
                                                    setInitialContentEditor={setInitialContentEditor}
                                                    formikField={
                                                        sideBarIndex.any_other_relevant_info.objKey
                                                    }
                                                    fieldLabel={sideBarIndex.any_other_relevant_info.text}
                                                    renderKey={reRenderState}
                                                    key={sideBarIndex.any_other_relevant_info.numberText}
                                                />
                                            ) : switchExpressionForSidebar === sideBarIndex.objectives.numberText ? (
                                                <Objectives
                                                    setObjectivesRichtexts={setObjectivesRichtexts}
                                                    objectivesRichtexts={objectivesRichtexts}
                                                    existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                                                    objectives={sideBarIndex.objectives}
                                                    formikState={formik.values}
                                                    commentsDataList={commentsDataList}
                                                    initialContentEditor={initialContentEditor}
                                                    setInitialContentEditor={setInitialContentEditor}
                                                    renderKey={reRenderState}
                                                    key={sideBarIndex.objectives.numberText}
                                                />
                                            ) : switchExpressionForSidebar === sideBarIndex.materials_and_methods.numberText ? (
                                                <MaterialAndMethods
                                                    setObjectivesRichtexts={setObjectivesRichtexts}
                                                    objectivesRichtexts={objectivesRichtexts}
                                                    existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                                                    materialsAndMethods={sideBarIndex.materials_and_methods}
                                                    formikState={formik.values}
                                                    commentsDataList={commentsDataList}
                                                    initialContentEditor={initialContentEditor}
                                                    setInitialContentEditor={setInitialContentEditor}
                                                    renderKey={reRenderState}
                                                    key={sideBarIndex.materials_and_methods.numberText}
                                                />

                                            ) : switchExpressionForSidebar === sideBarIndex.attachment.numberText ?
                                                (

                                                    <Attachments
                                                        key={reRenderState}
                                                        attachmentInputList={attachmentInputList}
                                                        setInputList={setAttachmentInputList}
                                                        existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                                                        commentsDataList={commentsDataList}
                                                    />

                                                ) : switchExpressionForSidebar === sideBarIndex.signature_of_the_researcher.numberText ?
                                                    (
                                                        <ResearcherSignature
                                                            formik={formik}
                                                            personalProfile={personalProfile}
                                                            existingProtocol={existingProtocoLoadingSuccess ? existingProtocol : { message: 'null' }}
                                                        />
                                                    ) : switchExpressionForSidebar === sideBarIndex.signature_of_the_guide_and_co_guide.numberText ?
                                                        (
                                                            <GuideSignature
                                                                title={title}
                                                                existingProtocol={existingProtocol}
                                                            />

                                                        )
                                                        : null
                                            }
                                        </Form>
                                    </CardBody>

                                    {/* footer content */}
                                    <Col sm={12}>
                                        <FormGroup className="button-container">

                                            <Button
                                                type="submit"
                                                text="< Previous"
                                                onClick={() => handlePreviousButton(switchExpressionForSidebar)}
                                                className="btn  btn-secondary me-2 start-button"
                                                style={{ pointerEvents: 'auto' }}
                                            ></Button>


                                            {
                                                switchExpressionForSidebar ===
                                                    sideBarIndex.attachment.numberText ? null : (
                                                    <Button
                                                        type="submit"
                                                        text="Next >"
                                                        onClick={() => handleNextButton(switchExpressionForSidebar)}
                                                        className="btn btn-success  me-2 end-button"
                                                        style={{ pointerEvents: 'auto' }}
                                                    ></Button>

                                                )
                                            }
                                        </FormGroup>
                                    </Col>
                                </Card>
                            </React.Fragment>
                        </Col>
                    </Row>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default ProtocolEntryMockView;
