import { Col, Container, Row } from 'reactstrap';
import eGenLogo from '../../assets/images/eGeneration.png';
import logo from '../../assets/images/logo.svg';

interface Props {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: Props) => {
  return (
    <div className="auth-page bg-dark">
      <Container>
        <Row>
          <Col>
            <div className="auth-full-page-content">
              <div className="border p-5 shadow shadow-lg bg-white rounded">
                <div className="auth-page-header">
                  <div>
                    <img src={logo} alt="" />
                  </div>
                  <div>
                    <h4>
                      Bangladesh College of Physicians and Surgeons (BCPS)
                    </h4>
                  </div>
                </div>
                {children}
                <div className="mt-4 mt-md-5 text-center">
                  <Row>
                    <Col sm={4} style={{ padding: '0px' }}>In Planning and Implementation: BCPS IT.</Col>
                    <Col sm={4} className="text-center">
                      {new Date().getFullYear()} © BCPS.
                    </Col>
                    <Col sm={4}>
                      <div className="text-sm-end d-none d-sm-block">
                        Technical Partner:
                        <a
                          href="https://egeneration.co/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ms-1 text-decoration-underline"
                        >
                          <img
                            className="ms-3"
                            src={eGenLogo}
                            alt="eGeneration Brand Logo"
                            height="16"
                          />
                        </a>
                      </div>
                    </Col>
                  </Row>
                  {/* <div className="text-muted p-3  d-flex justify-content-center align-content-center">
                    <span className="my-auto">Powered by</span>
                    <img
                      className="ms-3"
                      src={eGenLogo}
                      alt="eGeneration Brand Logo"
                      height="16"
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AuthLayout;
