import { useEffect, useState } from "react";
import { Collapse, Input, Label } from "reactstrap";
import { CompetencyGroupLevels } from "./CompetencyGroupLevels";

const RecursiveCompetencyItem = (props: any) => {

    const {
        competencyGroup,
        searchTerm,
        skillTypeName,
        formik,
        finalFormikProp,
        setFinalFormikProp,
        competenceLevelOptions,
        checkedComIds,
        setCheckedComIds,
    } = props;

    const [toggleValues, setToggleValues] = useState<any>({});

    useEffect(() => {
        const newToggleValues: any = { ...toggleValues };

        competencyGroup?.forEach((competency: any, index: number) => {
            if (competency?.childs?.length) {
                newToggleValues[competency?.id] = true;
            }
        });

        console.log(formik);

        setToggleValues(newToggleValues);
    }, []);

    const handleCheckboxChange = (e: any, competencyGroupId: string, competencyGroupName: string) => {

        const { value, name } = e.target;

        if (e.target.checked) {
            const newFinalFormikProp: any = [...finalFormikProp];
            newFinalFormikProp.push({ id: competencyGroupId, name: competencyGroupName, level: [] });
            console.log(newFinalFormikProp);
            const arr = [...checkedComIds];
            arr.push(competencyGroupId);
            setCheckedComIds(arr);
            setFinalFormikProp(newFinalFormikProp);
        } else {
            const newFinalFormikProp = [...finalFormikProp];
            newFinalFormikProp.forEach((competency: any) => {
                if (competency.id === competencyGroupId) {
                    const index = newFinalFormikProp.indexOf(competency);
                    if (index !== -1) {
                        newFinalFormikProp.splice(index, 1);
                    }
                }
            });

            setFinalFormikProp(newFinalFormikProp);

            console.log(newFinalFormikProp);

            const checkIndex = checkedComIds.indexOf(competencyGroupId);
            if (checkIndex !== -1) {
                const arr = [...checkedComIds];
                arr.splice(checkIndex, 1);
                setCheckedComIds(arr);
            }
        }
    };

    return (
        <ul className=''>
            {
                competencyGroup
                    ?.filter((competency: any, index: number) => competency.particulars.toLowerCase().includes(searchTerm.toLowerCase()))
                    ?.map((competency: any, index: number) => {

                        if (competency?.childs?.length) {
                            return (
                                <li key={competency?.id}>
                                    <div className="collapse-title" onClick={(e: any) => {
                                        const newToggleValues: any = { ...toggleValues };
                                        newToggleValues[competency?.id] = !newToggleValues[competency?.id];
                                        setToggleValues(newToggleValues);
                                    }}>
                                        {toggleValues[competency?.id] ? <i className="fa fa-minus me-2" /> : <i className="fa fa-plus me-2" />}
                                        {competency?.particulars ?? ''}
                                    </div>
                                    <Collapse isOpen={toggleValues[competency?.id]}>
                                        <ul className='ms-2 list-unstyled'>
                                            <RecursiveCompetencyItem
                                                competencyGroup={competency?.childs}
                                                searchTerm={searchTerm}
                                                skillTypeName={skillTypeName}
                                                formik={formik}
                                                finalFormikProp={finalFormikProp}
                                                setFinalFormikProp={setFinalFormikProp}
                                                competenceLevelOptions={competenceLevelOptions}
                                                checkedComIds={checkedComIds}
                                                setCheckedComIds={setCheckedComIds}
                                            />
                                        </ul>
                                    </Collapse>
                                </li>
                            )
                        } else {
                            return (
                                <li key={competency?.id}>
                                    <span>
                                        <Label check className='mb-2 mt-1 me-3'>
                                            <Input
                                                type='checkbox'
                                                name={competency.particulars}
                                                id={competency.particulars}
                                                checked={checkedComIds.includes(competency.id)}
                                                onChange={(e) => {
                                                    handleCheckboxChange(e, competency?.id, competency?.particulars);
                                                }}
                                            />{' '}
                                            {competency.particulars}
                                        </Label>
                                    </span>

                                    {
                                        checkedComIds.length && (!skillTypeName || skillTypeName.toLowerCase() !== 'interpretative skill') ?
                                            <CompetencyGroupLevels
                                                finalFormikProp={finalFormikProp}
                                                setFinalFormikProp={setFinalFormikProp}
                                                competency={competency}
                                                checkedCompetencyIds={checkedComIds}
                                                competenceLevelOptions={competenceLevelOptions}
                                            />
                                            :
                                            null
                                    }
                                </li>
                            );
                        }
                    })
            }
        </ul>
    )
};

export default RecursiveCompetencyItem;
