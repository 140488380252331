import { Link } from 'react-router-dom';
import { BreadcrumbItem, Col, Row } from 'reactstrap';

interface BreadcrumbProps {
  breadcrumbItem?: { link: string; name: string }[];
  title: string;
  component?: any;
}

const BetterBreadcrumb = ({ breadcrumbItem, title, component }: BreadcrumbProps) => {
  return (
    <div className="page-title-right">
      <ol className="breadcrumb">
        {breadcrumbItem?.map((item, index) => (
          <BreadcrumbItem key={index}>
            <Link to={item.link}>{item.name}</Link>
          </BreadcrumbItem>
        ))}
        <BreadcrumbItem active>{title}</BreadcrumbItem>
      </ol>
    </div>
  );
};

export default BetterBreadcrumb;
