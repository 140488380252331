import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { Container, Form, Label, FormGroup, Input } from 'reactstrap';
import { InputField, Button } from 'src/components';
import { useFormik } from 'formik';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import toast from 'react-hot-toast';
import { setFromErrors } from 'src/helpers';
import { useGetDepartmentsQuery } from 'src/rtk/features/setup/department/departmentApi';
import { useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import { useGetNoticeTemplateListsQuery } from 'src/rtk/features/setup/noticeTemplateList/setupNoticeTemplateListApi';
import { useCreateTrnNoticeMasterListMutation } from 'src/rtk/features/setup/trnNoticeMaster/setupTrnNoticeMaster';

interface NoticeMasterProps {
  setShowNotice: (flag: boolean) => void;
  setNoticeMasterId: (id: string) => void;
}

const initialValues: TransactionNoticeMaster = {
    id: '',
    notice_template_list_id: '',
    notice_no: '',
    notice_subject: '',
    notice_type: '',
    department_id: '',
    notice_header: '',
    notice_body: '',
    notice_footer: '',
    notice_date: new Date(),
    status: true,
    creator_organization_id: '',
    updater_organization_id: '',
    created_by: '',
    updated_by: '',
    deleted_by: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
};

const AddNoticeMaster = () => {    
    const [createNoticeMaster, { data: notice, isSuccess, isError, error }] = useCreateTrnNoticeMasterListMutation();

    const { data: departmentsData } = useGetDepartmentsQuery();
    const departments = departmentsData?.data || [];

    const { data: noticeTempalteData } = useGetNoticeTemplateListsQuery();
    const noticeTemplates = noticeTempalteData?.data || [];

    const noticeType: SelectOption[] =
    useGetLookupDataQuery('notice_type')
      .data?.data[0]?.childs.map((item) => ({
        value: item.id,
        label: item.name,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) || [];

    const history = useHistory();

    const err: CustomError = error as CustomError;
    const { handleSubmit, handleChange, getFieldProps, setFieldValue, values, touched, errors, resetForm, setErrors } =
      useFormik({
        initialValues,
        validationSchema: Yup.object({  
          notice_template_list_id: Yup.string().required(
            'Transaction notice must be under a notice template',
            ),    
          department_id: Yup.string().required(
            'Transaction notice must be under a department',
           )          
        }),

      onSubmit: (values) => {        
        createNoticeMaster({
          ...values,
        });              
        
        console.log(values);
      },
    });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Notice created successfully');     
      console.log(notice);
      history.push(`/setup/trn-notice-attachment/${notice?.data.id}`)          
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);
  
  let departmentOptions: SelectOption[] = [...departments?.map((e: Department) => ({label: e.department_name!, value: e.id!}))]
  let noticeTemplateOptions: SelectOption[] = [...noticeTemplates?.map((n: NoticeTemplateList) => ({label: n.notice_subject!, value: n.id!}))]

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          breadcrumbItem={[{ link: '/setup/trn-notice-master-list/', name: 'Notice List' }]}
          title={'Add Notice'}
        />
        <div className="row align-items-center justify-content-center">                    
              <div className="card mt-3">
                <h5 className="card-header">
                  <i className="fa fa-plus me-2" />
                  Add Notice
                </h5>
                <div className="card-body">
                  <Form
                    className="custom-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="row">
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <FormGroup className="mb-3">
                                <Label for="notice_template_list_id">Notice Template</Label>
                                <InputField
                                    type="select"
                                    placeholder="Notice Template"
                                    options={noticeTemplateOptions}
                                    invalid={{ errors, touched }}
                                    {...getFieldProps('notice_template_list_id')}
                                />
                            </FormGroup>
                        </div>

                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <FormGroup className="mb-3">
                                <Label for="notice_no">Notice No</Label>
                                <InputField
                                    type="text"
                                    placeholder="Notice No"
                                    invalid={{ errors, touched }}
                                    {...getFieldProps('notice_no')}
                                />
                            </FormGroup>
                        </div>

                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <FormGroup className="mb-3">
                                <Label for="notice_subject">Notice Subject</Label>
                                <InputField
                                    type="text"
                                    placeholder="Notice Subject"
                                    invalid={{ errors, touched }}
                                    {...getFieldProps('notice_subject')}
                                />
                            </FormGroup>
                        </div>

                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <FormGroup className="mb-3">
                                <Label for="notice_type">Notice Type</Label>
                                <InputField
                                    type="select"
                                    placeholder="Notice Type"
                                    options={noticeType}
                                    invalid={{ errors, touched }}
                                    {...getFieldProps('notice_type')}
                                />
                            </FormGroup>
                        </div>

                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <FormGroup className="mb-3">
                                <Label for="department_id">Department</Label>
                                <InputField
                                    type="select"
                                    placeholder="Department"
                                    options={departmentOptions}
                                    invalid={{ errors, touched }}
                                    {...getFieldProps('department_id')}
                                />
                            </FormGroup>
                        </div>                      

                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <FormGroup className="mb-3">
                                <Label for="notice_header">Notice Header</Label>
                                <InputField
                                    type="text"
                                    placeholder="Notice Header"
                                    invalid={{ errors, touched }}
                                    {...getFieldProps('notice_header')}
                                />
                            </FormGroup>
                        </div>

                      <div className='col-lg-4 col-md-6 col-sm-12'>
                        <FormGroup className="mb-3">
                          <Label for="notice_body">Notice Body</Label>
                          <InputField
                            type="textarea"
                            placeholder="Notice Body"
                            invalid={{ errors, touched }}
                            {...getFieldProps('notice_body')}
                          />
                        </FormGroup>
                      </div>

                      <div className='col-lg-4 col-md-6 col-sm-12'>
                        <FormGroup className="mb-3">
                          <Label for="notice_footer">Notice Footer</Label>
                          <InputField
                            type="textarea"
                            placeholder="Notice Footer"
                            invalid={{ errors, touched }}
                            {...getFieldProps('notice_footer')}
                          />
                        </FormGroup>
                      </div>

                      <div className='col-lg-4 col-md-6 col-sm-12'>
                        <FormGroup className="mb-3">
                          <Label for="notice_date">Notice Date</Label>
                          <DatePicker
                            selected={values.notice_date}                            
                            onChange={(e) => {
                              setFieldValue('notice_date', e);
                              handleChange(e);
                            }}                            
                            dateFormat="yyyy-MM-dd"
                            timeFormat="HH:mm:ss"                             
                          />
                        </FormGroup>
                      </div>

                      <div className='col-lg-4 col-md-6 col-sm-12'>
                        <FormGroup check className='mb-3 mt-4'>
                          <Label check>
                              <Input type="checkbox" {...getFieldProps('status')} checked={values.status} />{' '}
                              Status
                          </Label>
                        </FormGroup>
                      </div>
                    </div>
                    <Button type="submit" text="Submit" />
                  </Form>
                </div>
              </div>                          
        </div>
      </Container>
    </div>
  );
};

export default AddNoticeMaster;
