import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Label, Row, Table } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import { setFromErrors } from 'src/helpers';
import { useCreateThesisDissertationFileEntryMutation, useGetThesisDissertationFileEntryListQuery } from 'src/rtk/features/fileUploadForThesis/thesisDissertationFileEntryApi';
import * as Yup from 'yup';

const ThesisDissertationFileUpload = () => {
    const initialValues = {
        document: '',
        submission_status: '',
    };
    const [btnClicked, setBtnClicked] = useState<string>('');

    const FILE_SIZE = 2048 * 1024;
    const [createThesisDissertationFileEntry, { isSuccess: isCreateSuccess, isError, error }] = useCreateThesisDissertationFileEntryMutation();


    const fileInputRef = useRef<any>();
    const buttonRef = useRef<any>();
    const {
        data: list,
        isSuccess,
        isLoading,
    } = useGetThesisDissertationFileEntryListQuery();
    const fullList = { ...list?.data };
    console.log(fullList);
    const err: CustomError = error as CustomError;
    const {
        handleSubmit,
        getFieldProps,
        touched,
        errors,
        values,
        setValues,
        handleChange,
        setFieldValue,
        resetForm,
        setErrors,
    } = useFormik({
        initialValues,
        validationSchema: Yup.object({
            document: Yup.mixed()
                .required('File is required')
                .test('is-pdf', 'Must be a File', (value) => {
                    if (value) {
                        return [
                            'application/pdf',
                            'image/jpeg',
                            'image/png',
                            'image/jpg',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
                            'image/gif'
                        ].includes(value.type);
                    }
                    return true;
                })
                .test('file-size', 'File too large', (value) => {
                    if (value) {
                        return value.size <= FILE_SIZE;
                    }
                    return true;
                }),
        }),
        onSubmit: (values: any) => {
            const formData = new FormData();
            Object.keys(values).forEach((key) => {
                if (key === 'submission_status') {
                    if (btnClicked === 'submit') {
                        values[key] = 'Submit';
                    } else if (btnClicked === 'save_as_draft') {
                        values[key] = 'Draft';
                    }
                }
                formData.append(key, values[key]);
            });
            // buttonRef.current.disabled = true;
            console.log(values);
            createThesisDissertationFileEntry(formData);
        },
    });

    useEffect(() => {
        if (isCreateSuccess) {
            resetForm();
            toast.success('File Uploaded Successfully!');
            fileInputRef.current.value = '';
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isCreateSuccess, isError]);

    let index = 0;

    return (
        <div className="page-content">
            <React.Fragment>
                <Container fluid>
                    <BetterBreadcrumb
                        breadcrumbItem={[{ link: '/', name: 'Dashboard' }]}
                        title={'Document Upload'}
                    />
                    <Row className='mt-2'>
                        <Col sm={6}>
                            <Card>
                                <CardBody>
                                    <Form
                                        onSubmit={handleSubmit}
                                        onReset={(e) => {
                                            resetForm();
                                            fileInputRef.current.value = '';
                                        }}
                                        encType="multipart/form-data"
                                        id="reset-file-input"
                                    >
                                        <FormGroup>
                                            <div>
                                                <Label className="form-label required-field">Document</Label>{' '}
                                                <input
                                                    ref={fileInputRef}
                                                    name="document"
                                                    type="file"
                                                    className="form-control"
                                                    placeholder="Upload Document"
                                                    onChange={(e: any) => {
                                                        setFieldValue('document', e.target.files[0]);
                                                    }}
                                                ></input>
                                                <div className="form-text">Max file size 2 MB and  .jpg, .png, .gif, .pdf, .doc, .docx only</div>
                                                {errors.document && touched.document ? (
                                                    <div className="text-danger">{errors.document as string}</div>
                                                ) : null}
                                            </div>
                                        </FormGroup>
                                        <FormGroup className="mb-3 text-center">
                                            <Button
                                                type="reset"
                                                className="btn btn-secondary me-2"
                                            >
                                                Reset
                                            </Button>
                                            <button
                                                type="submit"
                                                className="btn btn-info me-2"
                                                onClick={(e) => {
                                                    setBtnClicked('save_as_draft');
                                                }}
                                            >
                                                Save as Draft
                                            </button>
                                            <button
                                                type="submit"
                                                ref={buttonRef}
                                                className="btn btn-success"
                                                onClick={(e) => {
                                                    setBtnClicked('submit');
                                                }}
                                            >
                                                Submit
                                            </button>
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Card>
                        <CardBody>
                            <div className="table-responsive">
                                <Table className="table table-striped table-sm mb-0 table-hover">
                                    <thead>
                                        <tr>
                                            <th>Sl No.</th>
                                            <th>Document Type</th>
                                            <th>Submission Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    {list && 
                                    <tbody>
                                        <tr>
                                            <td>{++index}</td>
                                            <td>{fullList?.document_type}</td>
                                            <td>{fullList?.submission_status === 'Submit' ?
                                                <div className="badge rounded-pill d-inline-block  badge-applied font-size-12 fw-medium">
                                                    {'Submitted'}
                                                </div> :
                                                <div className="badge rounded-pill d-inline-block  badge-draft font-size-12 fw-medium">
                                                    {'Drfat'}
                                                </div>
                                            }</td>
                                            <td>
                                                {fullList?.document_name ? (
                                                    <a
                                                        href={config.APP_URL + fullList?.document_name}
                                                        rel="noreferrer"
                                                        target="_blank"
                                                    >
                                                        <p className="mb-0 btn btn-sm btn-outline-primary ">
                                                            Document
                                                        </p>
                                                    </a>
                                                ) : (
                                                    <p className="mb-0 btn btn-sm btn-outline-secondary ">
                                                        N/A
                                                    </p>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>}
                                </Table>
                            </div>
                        </CardBody>
                    </Card>

                </Container>
            </React.Fragment>
        </div>

    );
};

export default ThesisDissertationFileUpload;
