import { apiSlice } from 'src/rtk/api/apiSlice';
import * as fellowSupervisorApplyUrl from 'src/helpers/url_helper';

export const fellowSupervisorApplyApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['fellowApplySupervisor'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({

      getSupervisorApplicantsList: builder.query<APIResponse<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${fellowSupervisorApplyUrl.GET_SUPERVISOR_APPLICANTS_LIST}?${url}`,
        }),
        providesTags: ['fellowApplySupervisor'],
      }),

      createFellowApplyForSupervisor: builder.mutation<
        APIResponse<any>,
        any
      >({
        query: (data: any) => ({
          url: `${fellowSupervisorApplyUrl.CREATE_FELLOW_APPLY_FOR_SUPERVISOR}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['fellowApplySupervisor'],
      }),

      createSupervisorApproval: builder.mutation<
        APIResponse<any>,
        any
      >({
        query: (data: any) => ({
          url: `${fellowSupervisorApplyUrl.CREATE_SUPERVISOR_APPROVAL}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),

        invalidatesTags: ['fellowApplySupervisor'],
      }),

    }),
  });

export const {
  useGetSupervisorApplicantsListQuery,
  useCreateFellowApplyForSupervisorMutation,
  useCreateSupervisorApprovalMutation,
} = fellowSupervisorApplyApi;
