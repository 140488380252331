import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Button, InputField } from 'src/components';
import { putUrlString } from 'src/helpers';
import { useGetFacultyInfosQuery } from 'src/rtk/features/setup/facultyInfo/facultyInfoApi';
import {
  useGetSubjectInfoQuery,
  useUpdateSubjectInfoMutation,
} from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import * as Yup from 'yup';

const initialValues: SubjectInfo = {
  id: '',
  subject_code: '',
  subject_name: '',
  category: '',
  description: '',
  duration: 0,
  duration_unit: 'Years',
  setup_faculty_info_id: '',
  status: false,
  creator_organization_id: '',
  updater_organization_id: '',
  created_by: '',
  updated_by: '',
  deleted_by: '',
  created_at: '',
  updated_at: '',
  deleted_at: '',
};

interface Props {
  isOpen: boolean;
  toggle: any;
  id: string;
}

export const EditSubjectInfo = (props: Props) => {
  const { isOpen, toggle, id } = props;

  const { data: subjectInfo, isError: isSubjectInfoFetchError } =
    useGetSubjectInfoQuery(id);
  const [updateSubjectInfo, { isSuccess, isError, error }] =
    useUpdateSubjectInfoMutation();

  const { data } = useGetFacultyInfosQuery();
  const facultyInfos: SelectOption[] = (data?.data || []).map((item) => ({
    label: item.faculty_name!,
    value: item.id!,
  }));

  const unitOptions: SelectOption[] = [
    { label: '3 Years', value: 3 },
    { label: '4 Years', value: 4 },
    { label: '5 Years', value: 5 },
  ];

  const err: CustomError = error as CustomError;
  const history = useHistory();
  const {
    values,
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    setValues,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      subject_name: Yup.string().required('Please Enter Subject Name'),
      subject_code: Yup.string().required('Please Enter Subject Code'),
      category: Yup.string().required('Please Enter Subject Type'),
      setup_faculty_info_id: Yup.string()
        .required('Subject must be under a faculty')
        .min(1, 'Subject must be under a faculty'),
      duration: Yup.number()
        .required('Please Enter Duration')
        .min(1, 'Please Enter Duration'),
      duration_unit: Yup.string()
        .required('Please Enter Duration Unit')
        .min(1, 'Please Enter Duration Unit'),
    }),

    onSubmit: (values) => {
      const url = putUrlString(id, values);
      updateSubjectInfo(url);
    },
  });

  useEffect(() => {
    if (subjectInfo && !isSubjectInfoFetchError) {
      const data: SubjectInfo = (subjectInfo as any).data;
      setValues({
        id: data.id ?? '',
        subject_code: data.subject_code ?? '',
        subject_name: data.subject_name ?? '',
        category: data?.category ?? '',
        description: data.description ?? '',
        duration: data.duration ?? '',
        duration_unit: data.duration_unit ?? '',
        setup_faculty_info_id: data.setup_faculty_info_id ?? '',
        status: data.status,
        creator_organization_id: data.creator_organization_id ?? '',
        updater_organization_id: data.updater_organization_id ?? '',
      });
      console.log(data.status ?? 'hello');
    }
  }, [subjectInfo, isSubjectInfoFetchError]);

  useEffect(() => {
    if (isSuccess) {
      toggle();
      toast.success('Subject Info updated successfully');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      const errors: any = {};

      if (err?.data) {
        Object.keys(err?.data).forEach((key) => {
          errors[key] = err?.data[key][0];
        });
      }
      setErrors(errors);
    }
  }, [isSuccess, isError]);

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} size="lg" backdrop={'static'}>
        <ModalHeader toggle={toggle}>Edit Subject</ModalHeader>
        <ModalBody>
          <Form
            className="custom-form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <FormGroup className="mb-3">
                  <Label for="subject_code" className='required-field'>Subject Code</Label>
                  <InputField
                    type="text"
                    placeholder="Subject Code"
                    invalid={{ errors, touched }}
                    {...getFieldProps('subject_code')}
                  />
                </FormGroup>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <FormGroup className="mb-3">
                  <Label for="subject_name" className='required-field'>Subject Name</Label>
                  <InputField
                    type="text"
                    placeholder="Subject Name"
                    invalid={{ errors, touched }}
                    {...getFieldProps('subject_name')}
                  />
                </FormGroup>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <FormGroup className="mb-3">
                  <Label for="category" className='required-field'>Subject Type</Label>
                  <InputField
                    type="text"
                    placeholder="Subject Type"
                    invalid={{ errors, touched }}
                    {...getFieldProps('category')}
                  />
                </FormGroup>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <FormGroup className="mb-3">
                  <Label for="description">Description</Label>
                  <InputField
                    type="text"
                    placeholder="Description"
                    invalid={{ errors, touched }}
                    {...getFieldProps('description')}
                  />
                </FormGroup>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <FormGroup className="mb-3">
                  <Label for="duration" className='required-field'>Fellowship Duration</Label>
                  <InputField
                    type="select"
                    placeholder="Fellowship Duration"
                    // step={0.5}
                    // min={1}
                    options={unitOptions}
                    invalid={{ errors, touched }}
                    {...getFieldProps('duration')}
                  />
                </FormGroup>
              </div>

              {/* <div className="col-lg-4 col-md-6 col-sm-12">
                <FormGroup className="mb-3">
                  <Label for="duration_unit" className='required-field'>Duration Unit</Label>
                  <InputField
                    type="select"
                    options={unitOptions}
                    invalid={{ errors, touched }}
                    {...getFieldProps('duration_unit')}
                  />
                </FormGroup>
              </div> */}

              <div className="col-lg-4 col-md-6 col-sm-12">
                <FormGroup className="mb-3">
                  <Label for="subject_name" className='required-field'>Faculty</Label>
                  <InputField
                    type="select"
                    options={facultyInfos}
                    placeholder="Faculty"
                    invalid={{ errors, touched }}
                    {...getFieldProps('setup_faculty_info_id')}
                  />
                </FormGroup>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <FormGroup check className="mb-3 mt-4">
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={values.status}
                      {...getFieldProps('status')}
                    />{' '}
                    Active
                  </Label>
                </FormGroup>
              </div>
            </div>

            <Button type="submit" text="Submit" />
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};
