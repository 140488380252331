import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import { useGetDepartmentsQuery } from 'src/rtk/features/setup/department/departmentApi';
import { useDeleteRoomMutation } from 'src/rtk/features/setup/room/roomApi';

interface Props {
  room: Room;
  index: number;
}

const RoomTableRow = ({ room, index }: Props) => {
  const history = useHistory();

  const [infoId, setInfoId] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const [deleteRoom, { isSuccess, isLoading, isError }] =
    useDeleteRoomMutation();
  const { data } = useGetDepartmentsQuery();
  const departments = data?.data;

  const deleteHandler = (modalState: boolean, id: string) => {
    setModalShow(modalState);
    setInfoId(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Room deleted successfully');
    }
    if (isError) {
      toast.error('Error deleting room');
    }
  }, [isSuccess, isError]);

  return (
    <tr key={room.id}>
      <th scope="row">{index + 1}</th>
      <td>{room.room_name}</td>    
      <td>
        {
          departments?.find((d) => d.id === room.department_id)
            ?.department_name
        }
      </td>
      <td>{room.building_no}</td>
      <td>{room.floor_no}</td>
      <td>{room.size}</td>
      <td>{room.capacity}</td>
      <td>{room.room_category}</td>
      <td>{room.status ? 'Active' : 'Inactive'}</td>       
      <td>
        <div className="d-flex gap-2">
          <Button
            size="sm"
            text="Edit"
            color="warning"
            onClick={() =>
              history.push(`/setup/room/update/${room.id}`)
            }
          />

          <Button
            size="sm"
            text="Delete"
            color="danger"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => deleteHandler(true, room.id!)}
          />
        </div>
        {modalShow ? (
          <DeleteInformationModal
            isOpen={modalShow}
            toggle={() => setModalShow(!modalShow)}
            id={infoId}
            deleteInfomation={deleteRoom}
          />
        ) : null}
      </td>
    </tr>
  );
};

export default RoomTableRow;
