import { useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { useGetLogbookEssentialInfoQuery } from 'src/rtk/features/setup/logBookEssentialInfoSetup/logBookEssentialInfoSetupApi';

interface Props {
  isOpen: boolean;
  toggle: any;
  id: string;
}

const DetailsInfoViewModal = (props: Props) => {
  const { isOpen, toggle, id } = props;  

  const { data: logbookEssentialInfo, isError: isLogbookEssentialInfoFetchError } = useGetLogbookEssentialInfoQuery(id);
  const [ detailsJsonEntries, setDetailsJsonEntries ] = useState<any>([]);

  useEffect(() => {
    if (logbookEssentialInfo && !isLogbookEssentialInfoFetchError) {
        const entries = logbookEssentialInfo.data.details_json ? Object.entries(JSON.parse(logbookEssentialInfo.data.details_json!)) : [];
        setDetailsJsonEntries(entries);
        console.log(entries);
    }
  }, [logbookEssentialInfo, isLogbookEssentialInfoFetchError]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Details JSON</ModalHeader>

        <ModalBody>
            <div>                
                <div className="row justify-content-center">                    
                    <div className="col-md-9">
                        <div className="row justify-content-between">
                            {
                                detailsJsonEntries.map((jsonEntry: any, index: number) => {
                                    return (
                                        <div key={index} className="col-md-6 col-sm-12 mb-2">
                                            <span className="fw-semibold">{ jsonEntry[0] } : </span>
                                            <span>
                                                { typeof jsonEntry[1] !== 'boolean' ? jsonEntry[1] : (jsonEntry[1] ? 'True' : 'False') }
                                            </span>
                                        </div>
                                    );
                                })
                            }                                                                                                                                                                                            
                        </div>
                    </div>                    
                </div>                                        
            </div>
        </ModalBody>

        <ModalFooter>
            <div className="d-flex gap-2 ">            
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </div>
        </ModalFooter>      
    </Modal>
  );
};

export default DetailsInfoViewModal;
