import React from 'react';
import { Table } from 'reactstrap';
import { Button } from 'src/components';
import { useHistory } from 'react-router-dom';
import { useGetLookupItemQuery } from 'src/rtk/features/setup/lookup/lookupDetails';
import LookupTableRow from './LookupTableRow';

interface TableProps {
  lookupList: LookupInfo[];
  setActiveLookup: (lookup: string) => void;
  uuid: string;
}

const DetailsTable = (props: TableProps) => {
  const { lookupList, setActiveLookup, uuid } = props;
  const history = useHistory();

  const { data, error, isLoading, isError } = useGetLookupItemQuery(uuid);

  const lookupDetails = data?.data || ({} as LookupDetails);
  const err: CustomError = error as CustomError;

  let content = null;
  if (isLoading) {
    content = <div className="mt-3">Loading...</div>;
  }
  if (!isLoading && isError && error) {
    content = <div className="mt-3">{err.message}</div>;
  }
  if (!isLoading && !isError && lookupDetails?.childs?.length > 0) {
    content = lookupDetails.childs.map((item, index) => (
      <LookupTableRow
        key={index}
        index={index}
        lookup={item}
        parentName={lookupDetails.lookup_name}
      />
    ));
  } else {
    content = (
      <tr>
        <td colSpan={11}>
          <div className="text-center text-danger h6">No data available!</div>
        </td>
      </tr>
    );
  }

  return (
    <div className="table-responsive text-nowrap x-overflow-scroll">
      <Table className="table mb-0">
        <thead>
          <tr>
            <th>Sl. </th>
            <th>Lookup Name</th>
            <th>Lookup List Name</th>
            <th>Serial</th>
            <th>Status</th>
            <th>Default Ind</th>
            <th>Yearly Demand FY</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{content}</tbody>
      </Table>
    </div>
  );
};

export default DetailsTable;
