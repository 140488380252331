import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, CardBody, Col, Form, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import { useGetThesisDissertationTitleListQuery } from 'src/rtk/features/fileUploadForThesis/thesisDissertationFileEntryApi';
import { useCreateExpectedFinalExamSessionMutation } from 'src/rtk/features/researchClinicSetup/researchClinicSetupApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

const initialValues = {
    expected_final_exam_month: '',
    expected_final_exam_year: '',
};

const months = [
    { value: 'January', label: 'January' },
    { value: 'February', label: 'February' },
    { value: 'March', label: 'March' },
    { value: 'April', label: 'April' },
    { value: 'May', label: 'May' },
    { value: 'June', label: 'June' },
    { value: 'July', label: 'July' },
    { value: 'August', label: 'August' },
    { value: 'September', label: 'September' },
    { value: 'October', label: 'October' },
    { value: 'November', label: 'November' },
    { value: 'December', label: 'December' },
];
interface Props {
    isOpen: boolean;
    toggle: any;
}
const FCPSFinalExam = (props: Props) => {
    const { isOpen, toggle } = props;
    const [createExpectedFinalExamSession, { isSuccess, isError, error, isLoading: isCreateLoading }] = useCreateExpectedFinalExamSessionMutation();
    const { data: titleInfo, isSuccess: isTitleSuccess, isLoading: isTitleLoading } = useGetThesisDissertationTitleListQuery();

    const [fieldStatus, setFieldStatus] = useState<boolean>(false);

    const err: CustomError = error as CustomError;

    const currentYear = new Date().getFullYear();
    const endYear = currentYear + 10;

    const years = [];
    for (let year = currentYear; year <= endYear; year++) {
        years.push(year);
    }

    const allYears: SelectOption[] = years?.map((item: any) => ({
        value: item,
        label: item,
    }));

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setValues,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({
            expected_final_exam_month: Yup.string().required('Select Month'),
            expected_final_exam_year: Yup.string().required('Select Year'),
        }),

        onSubmit: (values: any) => {
            console.log(values);
            createExpectedFinalExamSession(values);
        },
    });

    useEffect(() => {
        if (isTitleSuccess && titleInfo?.data?.expected_final_exam_month !== null && titleInfo?.data?.expected_final_exam_year !== null) {
            setValues({
                expected_final_exam_month: titleInfo?.data?.expected_final_exam_month,
                expected_final_exam_year: titleInfo?.data?.expected_final_exam_year,
            });
            setFieldStatus(true);
        }

    }, [isTitleSuccess, titleInfo]);

    useEffect(() => {
        if (isSuccess) {
            toast.success('Expected Session Stored Successfully!');
            setFieldStatus(true);
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);


    return (
        <React.Fragment>
            {isCreateLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Modal isOpen={isOpen} toggle={toggle} size="lg" backdrop={'static'}>
                <ModalHeader toggle={toggle}>Expected Session of Appearing Final Exam</ModalHeader>
                <CardBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                        onReset={(e) => {
                            resetForm();
                        }}
                    >
                        <Row>

                            <Col md={12} lg={6}>
                                <FormGroup>
                                    <Label for="expected_final_exam_month" className="form-label required-field">Month</Label>
                                    <InputField
                                        type="select"
                                        placeholder="Month"
                                        options={months}
                                        {...getFieldProps('expected_final_exam_month')}
                                        invalid={{ errors, touched }}
                                        disabled={fieldStatus}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={12} lg={6}>
                                <FormGroup>
                                    <Label for="expected_final_exam_year" className="form-label required-field">Year</Label>
                                    <InputField
                                        type="select"
                                        placeholder="Year"
                                        options={allYears}
                                        {...getFieldProps('expected_final_exam_year')}
                                        invalid={{ errors, touched }}
                                        disabled={fieldStatus}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={12} className=''>
                                <FormGroup className='text-end'>
                                    {fieldStatus && (
                                        <Button
                                            type="submit"
                                            className="btn btn-primary"
                                            color='primary'
                                            disabled={!fieldStatus}
                                            onClick={() => setFieldStatus(false)}
                                            style={{ minWidth: '180px' }}
                                        >
                                            Edit
                                        </Button>
                                    )}

                                    {!fieldStatus && (
                                        <Button
                                            type="submit"
                                            className="btn btn-success"
                                            style={{ minWidth: '180px' }}
                                        >
                                            Submit
                                        </Button>

                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
                <ModalBody>

                </ModalBody>
            </Modal>

        </React.Fragment>
    );
};

export default FCPSFinalExam;
