import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from '@react-pdf/renderer';
import { useEffect } from 'react';
import Html from 'react-pdf-html';
import { useLazyGetSixMonthlyReportDataQuery } from 'src/rtk/features/logbookMonitoringReport/logbookMonitoringReportApi';
import loader from '../../assets/images/loader.gif';

interface Props {
  userInfo: any;
  setReportId: any;
}
// Create styles
const styles = StyleSheet.create({
  page: {
    margin: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 0,
  },
  nextLine: {
    marginTop: -20,
    textAlign: 'center',
    fontSize: '10px',
  },
});

// Create Document Component
// const MyDocument = () => (
//   <Document>
//     <Page size="A4" style={styles.page}>
//       <View style={styles.section}>
//         <Text>Section #1</Text>
//       </View>
//       <View style={styles.section}>
//         <Text>Section #2</Text>
//       </View>
//     </Page>
//   </Document>
// );

export const SixMonthlyReportPDF = (props: Props) => {
  const { userInfo, setReportId } = props;
  const [getSixMonthlyReportData, { data: list, isSuccess, isLoading }] =
    useLazyGetSixMonthlyReportDataQuery();

  useEffect(() => {
    getSixMonthlyReportData(userInfo?.user_id);
  }, []);

  const data: any = list?.data || [];

  console.log(data);
  let pdfUrl = '';

  const html = `  
  <html>    
    <body>
        <style>                    
        .print-container{
          padding: 0 15px;
        }

        .wide-column {
          width: 500px;
          background-color: powderblue;
        }

        table {
          margin: 3px 0px;
          padding: 0px;
          border: 1px solid black;
          font-size: 12px;
        }
        p{
          font-size: 14px;
        }
        table > tbody> tr > *{
          padding: 4.5px
        }

        table th {
          text-align: center;    
        }
        
        table td {
                       
        }

        .bgColor {
          background-color: powderblue;
        }
        b {
          font-family: 'Times-Bold'; 
        }

      </style>

      <div class="print-container"> 
        <table>
          <tbody>
            <tr>
              <td><b>Name:</b></td>
              <td>${userInfo?.personal_info?.full_name ?? ''}</td>
              <td><b>PRN Number:</b></td>
              <td>R0651</td>
            </tr>
            <tr>
              <td><b>Place of Training:</b></td>
              <td>Dhaka</td>
              <td><b>Name of Supervisor:</b></td>
              <td>${
                userInfo?.current_session_and_supervisor_info?.supervisor?.name ?? ''
              }</td>
            </tr>
            <tr>
              <td><b>Report For Duration of:</b></td>
            </tr>
          </tbody>
        </table>
        <p>1.Number of Patient Managed: ${data[0]?.total ?? ''}</p>
        <table>
          <thead> 
            <tr style="background-color: powderblue;">
              <th>Inpatient</th>
              <th>Outpatient</th>
              <th>Emergency</th>
            </tr>
          </thead>
          <tbody>
            <tr>
            ${data[0]?.entry_setup_master_for_log
              ?.map(
                (item: any) =>
                  `
               <td>${item?.total ?? ''}</td>
              `,
              )
              .join('')}
            </tr>
          </tbody>
          </table> 
          <p>2. Number of History Writing : ${data[0]?.history_count ?? ''}</p>
          <p>3. Number of procedures done: </p>
        <table>
          <thead> 
            <tr style="background-color: powderblue;">
              <th style="width: 70%">Name of the procedure</th>
              <th>Number</th>
            </tr>
          </thead>
          <tbody>
            ${data[1]?.entry_setup_master_for_log
              ?.map(
                (item: any) =>
                  `<tr>
                <td>${item?.particulars ?? ''}</td>
                <td>${item?.total ?? ''}</td>
               </tr>
              `,
              )
              .join('')}
              <tr>
                <td><b>Total</b></td>
                <td>
                  ${data[1]?.entry_setup_master_for_log?.reduce(
                    (total: any, item: any) => {
                      return total + item.total;
                    },
                    0,
                  )}
                </td>
              </tr>
          </tbody>
        </table> 
        <p>4. Academic activities: </p>
        <table>
          <thead> 
            <tr style="background-color: powderblue;">
              <th style="width: 90;">Category</th>
              <th>Number</th>
              <th>Presented</th>
              <th>Attended</th>
            </tr>
          </thead>
          <tbody>
            ${data[2]?.entry_setup_master_for_log
              ?.map(
                (item: any) =>
                  `<tr>
                <td>${item?.particulars ?? ''}</td>
                <td>${item?.total ?? ''}</td>
                <td>0</td>
                <td>${item?.total ?? ''}</td>
               </tr>
              `,
              )
              .join('')}
          </tbody>
        </table>
        <div style="margin-left: 25;"><p>5. Formative assessment: </p><span style="font-size: 12;">Results of 2 mini-CEX ____________________________<br/>Results of 1CBD         _____________________________<br/>General comment on direct observation – during bed-side case discussion, patient management, dealing with patients, dealing with nurses and doctor colleague, attitude:  Very good.
        [Excellent/Very good/Good/Satisfactory/ Unsatisfactory]
        
        <br/>Status of dissertation work: __________________________________________________________________________________
        </span></div>
        <div style="margin-left: 25;">
          <h6>
            ---------------------------------------<br/>
            Signature of the Trainee:<br/>
            ${userInfo?.personal_info?.full_name ?? ''}
          </h6>
          <h6>
            ---------------------------------------<br/>
            Signature of the Supervisor:<br/>
            ${
              userInfo?.current_session_and_supervisor_info?.supervisor?.name ?? ''
            }
          </h6>
        </div>
      </div>      
    </body>
  </html>
`;

  // const MyDocument = (
  //   <Document>
  //     <Page size="A4" style={styles.page}>
  //       <View style={styles.section}>
  //         <Text>Section #1</Text>
  //       </View>
  //       <View style={styles.section}>
  //         <Text>Section #2</Text>
  //       </View>
  //     </Page>
  //   </Document>
  // );

  const MyDocument = (
    <Document>
      <Page size="A4">
        <View style={styles.row}>
          <Image
            src="/images/apple-icon-180x180.png"
            style={{
              width: '40px',
              marginTop: '20px',
              marginBottom: '0px',
              marginLeft: '10px',
            }}
          />
          <Text
            style={{
              textAlign: 'center',
              fontFamily: 'Times-Bold',
              fontSize: '14px',
              margin: '20px',
              marginBottom: '0px',
            }}
          >
            BANGLADESH COLLEGE OF PHYSICIANS AND SURGEONS (BCPS)
          </Text>
        </View>
        <Text style={styles.nextLine}>
          67, Shaheed Tajuddin Ahmed Sarani, Mohakhali, Dhaka-1212
        </Text>
        <Text
          style={{
            textAlign: 'center',
            fontFamily: 'Helvetica-Bold',
            fontSize: '14px',
            margin: '15px',
          }}
        >
          Six Monthly Training Report
        </Text>
        <Text
          style={{
            textAlign: 'center',
            fontFamily: 'Helvetica',
            fontSize: '10px',
          }}
        >
          (Competency based Residential Training for Fellowship)
        </Text>
        <Html>{html}</Html>
      </Page>
    </Document>
  );

  useEffect(() => {
    if(isSuccess){
      const pdfData = pdf(MyDocument).toBlob();
      pdfData.then((pdfData) => {
        const pdfContent = new Blob([pdfData], { type: 'application/pdf' });
        const url = URL.createObjectURL(pdfContent);
        pdfUrl = url;
        setReportId('');
        window.open(pdfUrl, '_blank');
      });
    }
  },[isSuccess])
  

  return (
    <div>
      {isLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
    </div>
  );
};
