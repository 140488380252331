import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import { useCreateResearchGrantFormEntryMutation } from 'src/rtk/features/researchClinicSetup/researchClinicSetupApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

const initialValues = {
    researcher_name_in_bangla: '',
    bank_name: '',
    branch_name: '',
    routing_no: '',
    account_no: '',
    submission_status: '',
};

interface Props {
    isOpen: boolean;
    toggle: any;
    info: any;
}

const EditResearchGrantForm = (props: Props) => {
    const { isOpen, toggle, info } = props;

    const [createResearchGrantFormEntry, { isSuccess, isError, error, isLoading: isCreateLoading }] = useCreateResearchGrantFormEntryMutation();

    const [btnClicked, setBtnClicked] = useState<string>('');

    const err: CustomError = error as CustomError;

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        setValues,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({
            researcher_name_in_bangla: Yup.string().required('Enter Your Name in Bangla'),
            bank_name: Yup.string().required('Bank Name is Required'),
            branch_name: Yup.string().required('Branch Name is Required'),
            routing_no: Yup.string().required('Routing No is Required'),
            account_no: Yup.string().required('Account No is Required'),
        }),

        onSubmit: (values: any) => {
            const formData = new FormData();

            Object.keys(values).forEach((key) => {
                if (key === 'submission_status') {
                    if (btnClicked === 'submit') {
                        values[key] = 'Submit';
                    } else if (btnClicked === 'save_as_draft') {
                        values[key] = 'Draft';
                    }
                }
                formData.append(key, values[key]);
            });

            createResearchGrantFormEntry(values);
        },
    });

    useEffect(() => {
        if (isSuccess) {
            resetForm();
            toast.success('Research Grant Entry Updated Successfully!');
            toggle();
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);

    useEffect(() => {
        if (info) {
            setValues({
                researcher_name_in_bangla: info?.researcher_name_in_bangla || '',
                bank_name: info?.bank_info?.bank_name || '',
                branch_name: info?.bank_info?.branch_name || '',
                routing_no: info?.bank_info?.routing_no || '',
                account_no: info?.bank_info?.account_no || '',
                submission_status: info?.bank_info?.submission_status || '',
            })
        }
    }, [info]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg" backdrop={'static'}>
            {isCreateLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <ModalHeader toggle={toggle}>Edit Information</ModalHeader>
            <ModalBody>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                    onReset={(e) => {
                        resetForm();
                    }}
                >
                    <Row>
                        <Col sm={4}>
                            <FormGroup>
                                <Label for="researcher_name_in_bangla" className="form-label required-field">Name of the Researcher (বাংলায়)</Label>
                                <InputField
                                    type="text"
                                    placeholder='Name of the Researcher'
                                    {...getFieldProps('researcher_name_in_bangla')}
                                    invalid={{ errors, touched }}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={4}>
                            <FormGroup>
                                <Label for="bank_name" className="form-label required-field">Bank Name</Label>
                                <InputField
                                    type="text"
                                    placeholder="Bank Name"
                                    {...getFieldProps('bank_name')}
                                    invalid={{ errors, touched }}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={4}>
                            <FormGroup>
                                <Label for="branch_name" className="form-label required-field">Branch Name</Label>
                                <InputField
                                    type="text"
                                    placeholder="Branch Name"
                                    {...getFieldProps('branch_name')}
                                    invalid={{ errors, touched }}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={4}>
                            <FormGroup>
                                <Label for="routing_no" className="form-label required-field">Routing No.</Label>
                                <InputField
                                    type="text"
                                    placeholder="Routing No."
                                    {...getFieldProps('routing_no')}
                                    invalid={{ errors, touched }}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={4}>
                            <FormGroup>
                                <Label for="account_no" className="form-label required-field">Acount Number</Label>
                                <InputField
                                    type="text"
                                    placeholder="Acount Number"
                                    {...getFieldProps('account_no')}
                                    invalid={{ errors, touched }}
                                />
                            </FormGroup>
                        </Col>
                        <FormGroup className="mb-3 text-center">
                            <Button
                                type="reset"
                                className="btn btn-danger me-2"
                            >
                                Reset
                            </Button>
                            <button
                                type="submit"
                                className="btn btn-info me-2"
                                // ref={draftButtonRef}
                                onClick={(e) => {
                                    setBtnClicked('save_as_draft');
                                }}
                            >
                                Save as Draft
                            </button>
                            <button
                                type="submit"
                                // ref={buttonRef}
                                className="btn btn-success"
                                onClick={(e) => {
                                    setBtnClicked('submit');
                                }}
                            >
                                Submit
                            </button>
                        </FormGroup>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default EditResearchGrantForm;
