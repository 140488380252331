import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { putUrlString } from 'src/helpers';
import { useGetDepartmentsQuery } from 'src/rtk/features/setup/department/departmentApi';
import {
  useGetRoomQuery,
  useUpdateRoomMutation,
} from 'src/rtk/features/setup/room/roomApi';
import * as Yup from 'yup';

const initialValues: Room = {
  id: '',
  room_name: '',
  department_id: '',
  building_no: '',
  floor_no: '',
  size: '',
  capacity: '',
  room_category: '',
  room_description: '',
  status: false,
  creator_organization_id: '',
  updater_organization_id: '',
  created_by: '',
  updated_by: '',
  deleted_by: '',
  created_at: '',
  updated_at: '',
  deleted_at: '',
};

export const EditRoom = () => {
  const { id } = useParams<{ id: string }>();
  const { data: room, isError: isRoomFetchError } = useGetRoomQuery(id);
  const [updateRoom, { isSuccess, isError, error }] = useUpdateRoomMutation();

  const { data } = useGetDepartmentsQuery();
  const departments = data?.data;

  const err: CustomError = error as CustomError;
  const history = useHistory();
  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    values,
    setValues,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      room_name: Yup.string().required('Please Enter Room Name'),
      department_id: Yup.string().required('Room must be under a department'),
    }),

    onSubmit: (values) => {
      const url = putUrlString(id, values);
      updateRoom(url);
    },
  });

  useEffect(() => {
    if (room && !isRoomFetchError) {
      const data: Room = (room as any).data;
      setValues({
        id: data.id,
        room_name: data.room_name,
        department_id: data.department_id,
        building_no: data.building_no,
        floor_no: data.floor_no,
        size: data.size,
        capacity: data.capacity,
        room_category: data.room_category,
        room_description: data.room_description,
        status: data.status,
        creator_organization_id: data.creator_organization_id,
        updater_organization_id: data.updater_organization_id,
        created_by: data.created_by,
        updated_by: data.updated_by,
        deleted_by: data.deleted_by,
        created_at: data.created_at,
        updated_at: data.updated_at,
        deleted_at: data.deleted_at,
      });
    }
  }, [room, isRoomFetchError]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Room updated successfully');
      history.push('/setup/room');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      const errors: any = {};

      if (err?.data) {
        Object.keys(err?.data).forEach((key) => {
          errors[key] = err?.data[key][0];
        });
      }
      setErrors(errors);
    }
  }, [isSuccess, isError]);

  const op: SelectOption[] = departments?.map((d) => ({
    label: d.department_name!,
    value: d.id!,
  }))!;
  // op.push({ label: 'Select a Department', value: '' });

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title={'Edit Room'}
          breadcrumbItem={[{ link: '/setup/room/', name: 'Room' }]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="card mt-3">
            <h5 className="card-header">
              <i className="fa fa-edit me-2" />
              Edit Room
            </h5>
            <div className="card-body">
              <Form
                className="custom-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="room_name">Room Name</Label>
                      <InputField
                        type="text"
                        placeholder="Room Name"
                        invalid={{ errors, touched }}
                        {...getFieldProps('room_name')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="department_id">Department</Label>
                      <InputField
                        type="select"
                        placeholder="Department"
                        options={op}
                        invalid={{ errors, touched }}
                        {...getFieldProps('department_id')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="building_no">Building No</Label>
                      <InputField
                        type="text"
                        placeholder="Building No"
                        invalid={{ errors, touched }}
                        {...getFieldProps('building_no')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="floor_no">Floor No</Label>
                      <InputField
                        type="text"
                        placeholder="Floor No"
                        invalid={{ errors, touched }}
                        {...getFieldProps('floor_no')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="size">Size</Label>
                      <InputField
                        type="text"
                        placeholder="Size"
                        invalid={{ errors, touched }}
                        {...getFieldProps('size')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="capacity">Capacity</Label>
                      <InputField
                        type="text"
                        placeholder="Capacity"
                        invalid={{ errors, touched }}
                        {...getFieldProps('capacity')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="capacity">Room Category</Label>
                      <InputField
                        type="text"
                        placeholder="Room Category"
                        invalid={{ errors, touched }}
                        {...getFieldProps('room_category')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup className="mb-3">
                      <Label for="capacity">Room Description</Label>
                      <InputField
                        type="text"
                        placeholder="Room Description"
                        invalid={{ errors, touched }}
                        {...getFieldProps('room_description')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <FormGroup check className="mb-3 mt-4">
                      <Label check>
                        <Input type="checkbox" checked={values.status} {...getFieldProps('status')} />{' '}
                        Status
                      </Label>
                    </FormGroup>
                  </div>
                </div>
                <Button type="submit" text="Submit" />
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
