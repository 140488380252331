import { apiSlice } from 'src/rtk/api/apiSlice';
import * as authUrl from 'src/helpers/url_helper';

interface Paginate {
  page: number | string;
  paginate_per_page?: number;
  search?: string;
}

export const authApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['RegistrationInfo', 'CertificateSourceVerify', 'guideCoguide', 'fellowApplySupervisor'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getRegInfos: builder.query<APIResponse<RegInfo[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${authUrl.GET_REG_INFOS}`,
        }),
        providesTags: ['RegistrationInfo'],
      }),

      getRegInfosByPaginate: builder.query<
        APIResponseWithPagination<RegInfo>,
        Paginate
      >({
        query: (paginate) => {
          let url = `${authUrl.GET_REG_INFOS_BY_PAGINATE}?`;

          if (paginate.page) {
            url += `page=${paginate.page}&`;
          }
          if (paginate.paginate_per_page) {
            url += `paginate_per_page=${paginate.paginate_per_page}`;
          }
          if (paginate.search) {
            url += `${paginate.search}`;
          }

          return {
            method: 'GET',
            url: url,
          };
        },
        providesTags: ['RegistrationInfo'],
      }),

      getRegInfo: builder.query<APIResponse<RegInfo>, string>({
        query: (id) => ({
          url: `${authUrl.GET_REG_INFO}/${id}`,
          method: 'GET',
        }),
        providesTags: ['RegistrationInfo', 'CertificateSourceVerify', 'guideCoguide'],
      }),

      getMyRegInfo: builder.query<APIResponse<RegInfo>, string>({
        query: (id) => ({
          url: `${authUrl.GET_MY_REG_INFO}/${id}`,
          method: 'GET',
        }),
        providesTags: ['RegistrationInfo', 'CertificateSourceVerify', 'guideCoguide', 'fellowApplySupervisor'],
      }),

      getApprovalStatus: builder.query<ApprovalStatus[], void>({
        query: () => ({
          method: 'GET',
          url: `${authUrl.GET_APPROVAL_STATUS}`,
        }),
        providesTags: ['RegistrationInfo'],
      }),

      getBmdcCertificateApprovalStatus: builder.query<ApprovalStatus[], void>({
        query: () => ({
          method: 'GET',
          url: `${authUrl.GET_BMDC_CERTIFICATE_APPROVAL_STATUS}`,
        }),
        providesTags: ['RegistrationInfo'],
      }),

      // Certificate Source Verify

      getCertificateSourceVerify: builder.mutation<APIResponse<any>, string>({
        query: (url) => ({
          url: `${authUrl.GET_CERTIFICATE_SOURCE_VERIFY}/${url}`,
          method: 'GET',
        }),
        invalidatesTags: ['CertificateSourceVerify'],
      }),

      // BMDC APPROVE

      getBmdcApproval: builder.mutation<APIResponse<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${authUrl.GET_BMDC_APPROVAL}/${url}`,
        }),
        invalidatesTags: ['RegistrationInfo'],
      }),

      // BMDC SOURCE VERIFY

      getBmdcSourceVerify: builder.mutation<APIResponse<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${authUrl.GET_BMDC_SOURCE_VERIFY}/${url}`,
        }),
        invalidatesTags: ['RegistrationInfo'],
      }),

      // TRAINEE PROFILE APPROVAL

      getTraineeProfileApproval: builder.mutation<APIResponse<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${authUrl.GET_TRAINEE_PROFILE_APPROVAL}/${url}`,
        }),
        invalidatesTags: ['RegistrationInfo'],
      }),

      createRegInfo: builder.mutation<APIResponse<RegInfo>, RegInfo>({
        query: (data) => ({
          url: `${authUrl.CREATE_REG_INFO}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['RegistrationInfo'],
      }),

      updateRegInfo: builder.mutation<APIResponse<RegApprove>, RegApprove>({
        query: (data) => ({
          url: `${authUrl.UPDATE_REG_INFO}`,
          method: 'POST',
          body: data,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['RegistrationInfo'],
      }),
      deleteRegInfo: builder.mutation({
        query: (id: number) => ({
          method: 'DELETE',
          url: `${authUrl.DELETE_REG_INFO}/${id}`,
        }),
        invalidatesTags: ['RegistrationInfo'],
      }),
    }),
  });

export const {
  useCreateRegInfoMutation,
  useGetRegInfosQuery,
  useGetRegInfoQuery,
  useGetMyRegInfoQuery,
  useLazyGetRegInfoQuery,
  useGetApprovalStatusQuery,
  useGetBmdcCertificateApprovalStatusQuery,
  useGetCertificateSourceVerifyMutation,
  useGetBmdcApprovalMutation,
  useGetBmdcSourceVerifyMutation,
  useGetTraineeProfileApprovalMutation,
  useUpdateRegInfoMutation,
  useDeleteRegInfoMutation,
  useGetRegInfosByPaginateQuery,
} = authApi;
