import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { config } from 'src/config';
import store from 'src/rtk/app/store';
import { useGetThesisDissertationFormSetupListQuery, useGetThesisEntryDetailsByIdQuery } from 'src/rtk/features/fileUploadForThesis/thesisDissertationFileEntryApi';
import DynamicViewDetails from "./DynamicViewDetails";
import ListGroupContent from './ListGroupContent';

const ThesisEntryDetailsForSupervisor = () => {
    const { id } = useParams<{ id: string }>();
    const { data: thesisDetails } = useGetThesisEntryDetailsByIdQuery(id);

    console.log(thesisDetails);

    const [activeStates, setActiveStates] = useState<any>({});
    const [toggleStates, setToggleStates] = useState<any>({});
    const [dynamicInputFields, setDynamicInputFields] = useState<any>({});
    const [activeStateId, setActiveStateId] = useState<string>('');
    const [currentActiveStateId, setCurrentActiveStateId] = useState<string>('');
    const [formObj, setFormObj] = useState<ThesisDissertationEntryFormSetup[]>([]);
    const [thesisEntryDetails, setThesisEntryDetails] = useState<any>({});
    const [columnName, setColumnName] = useState<any>('');
    const [infoForView, setInfoForView] = useState<ThesisDissertationEntryFormSetup>({});

    let counter: number = 0;
    let childFullIndex: number = 0;

    const currentUser = store.getState().auth.user;

    const {
        data: thesisDissertationFormSetups,
        isSuccess: isThesisDissertationFormSetupsSuccess,
        isLoading: isThesisDissertationFormSetupsLoading,
        isError: isThesisDissertationFormSetupsError,
        error: thesisDissertationFormSetupsError
    } = useGetThesisDissertationFormSetupListQuery();

    const handleAllActiveStates = (formObj: any, newActiveStates: any, newToggleStates: any, newDynamicInputFields: any, newThesisEntryDetails: any) => {
        formObj?.map((f: any, index: number) => {
            if (!f?.child?.length) {
                if (counter === 0) {
                    newActiveStates[f?.id] = true;
                } else {
                    newActiveStates[f?.id] = false;
                }
                // newActiveStates[f?.id] = false;

                newDynamicInputFields[f?.id] = {
                    id: f?.id,
                    sl_no: f?.sl_no,
                    column_name: f?.column_name,
                    data_type: f?.data_type,
                    has_file: f?.has_file,
                    publish_status: f?.publish_status,
                    required_ind: f?.required_ind,
                    input_ind: f?.input_ind,
                    status: f?.status,
                    child: f?.child,
                    parent_id: f?.parent_id,
                };

                newThesisEntryDetails[f?.id] = {
                    thesis_dissertation_setup_id: f?.id,
                    thesis_setup_column_name: f?.column_name,
                    details_text: '',
                    details_file: '',
                    comments_text: '',
                    plagarism: '',
                }

                counter++;
            } else {
                if (childFullIndex === 0) {
                    newToggleStates[f?.id] = true;
                } else {
                    newToggleStates[f?.id] = false;
                }
                // newToggleStates[f?.id] = false;
                newActiveStates[f?.id] = false;
                newDynamicInputFields[f?.id] = {
                    id: f?.id,
                    sl_no: f?.sl_no,
                    column_name: f?.column_name,
                    data_type: f?.data_type,
                    has_file: f?.has_file,
                    publish_status: f?.publish_status,
                    required_ind: f?.required_ind,
                    input_ind: f?.input_ind,
                    status: f?.status,
                    child: f?.child,
                    parent_id: f?.parent_id,
                };
                handleAllActiveStates(
                    f?.child,
                    newActiveStates,
                    newToggleStates,
                    newDynamicInputFields,
                    newThesisEntryDetails
                );
                childFullIndex++;

            }
        });

        const newObj: any = {
            newActiveStates: newActiveStates,
            newToggleStates: newToggleStates,
            newDynamicInputFields: newDynamicInputFields,
            newThesisEntryDetails: newThesisEntryDetails
        };

        return newObj;
    };

    useEffect(() => {
        if (isThesisDissertationFormSetupsSuccess && !isThesisDissertationFormSetupsLoading) {
            setFormObj(thesisDissertationFormSetups?.data!);
        }
    }, [isThesisDissertationFormSetupsSuccess, isThesisDissertationFormSetupsLoading]);

    useEffect(() => {
        if (formObj?.length) {
            const { newActiveStates, newToggleStates, newDynamicInputFields, newThesisEntryDetails } = handleAllActiveStates(formObj, {}, {}, {}, {});
            setActiveStates(newActiveStates);
            setToggleStates(newToggleStates);
            setDynamicInputFields(newDynamicInputFields);
            setThesisEntryDetails(newThesisEntryDetails);
            // console.log(newActiveStates);
            // console.log(newToggleStates);
            // console.log(newDynamicInputFields);
            // console.log(newThesisEntryDetails);
        }
    }, [formObj]);

    useEffect(() => {
        Object.keys(activeStates).forEach((activeStateId: string, index: number) => {
            if (activeStates[activeStateId]) {
                setCurrentActiveStateId(activeStateId);
            }
        });
    }, [activeStates]);

    useEffect(() => {
        Object.keys(dynamicInputFields).forEach((key: string, index: number) => {
            if (dynamicInputFields[currentActiveStateId]) {
                setColumnName(dynamicInputFields[currentActiveStateId]?.column_name);
            }
        });
        // console.log(dynamicInputFields);
    }, [dynamicInputFields, currentActiveStateId]);

    useEffect(() => {
        if (columnName !== '') {
            const info = thesisDetails?.data?.title?.thesis_master_data?.details?.find((item: any) => item?.thesis_setup_column_name === columnName);
            setInfoForView(info);
            console.log(columnName);
        }
    }, [columnName]);

    console.log(infoForView);

    return (
        <div className='page-content'>
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardBody>
                                <div className="row">
                                    <div className="col">
                                        <p className='mb-0'>
                                            <b>Trainee Name: </b>
                                            {
                                                thesisDetails?.data?.trainee_name
                                            }
                                        </p>
                                    </div>

                                    <div className="col">
                                        <p className='mb-0'>
                                            <b>BMDC Reg. No.: </b>
                                            {
                                                thesisDetails?.data?.bmdc_registration_no
                                            }
                                        </p>
                                    </div>
                                    <div className="col">
                                        <p className='mb-0'>
                                            <b>Guide: </b>
                                            {
                                                thesisDetails?.data?.title?.guide_name
                                            }
                                        </p>
                                    </div>

                                    <div className="col">
                                        <p className='mb-0'>
                                            <b>Co-Guide: </b>
                                            {
                                                thesisDetails?.data?.title?.co_guide_1_name

                                            }
                                        </p>
                                    </div>
                                    {/* <div className="col">
                                        <p className='mb-0'>
                                            <b>Title: </b>
                                            {
                                                thesisDetails?.data?.title?.title_of_thesis
                                            }
                                        </p>
                                    </div> */}
                                    <div className="col">
                                        <p className='mb-0'>
                                            <a
                                                className=""
                                                href={config.APP_URL + thesisDetails?.data?.document_name}
                                                rel="noreferrer"
                                                target="_blank"
                                            >
                                                <i className="fa-solid fa-file" /> Document
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col sm={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={3} className='border-end border-dark'>
                                        {dynamicInputFields && activeStates && toggleStates && formObj?.length &&
                                            <div className='dissertation-entry-sidebar-menu'>
                                                <ListGroupContent
                                                    formObj={formObj}
                                                    activeStates={activeStates}
                                                    setActiveStates={setActiveStates}
                                                    toggleStates={toggleStates}
                                                    setToggleStates={setToggleStates}
                                                    dynamicInputFields={dynamicInputFields}
                                                />
                                            </div>}
                                    </Col>
                                    <Col sm={9}>
                                        {
                                            currentActiveStateId !== '' && columnName !== '' ?
                                                <DynamicViewDetails
                                                    dynamicInputFields={dynamicInputFields}
                                                    activeStates={activeStates}
                                                    setActiveStates={setActiveStates}
                                                    activeStateId={currentActiveStateId}
                                                    thesisDetails={thesisDetails}
                                                    infoForView={infoForView}
                                                    columnName={columnName}
                                                />
                                                :
                                                null
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default ThesisEntryDetailsForSupervisor;