import { useFormik } from "formik";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { Button, InputField } from "src/components";
import * as Yup from "yup";

const TrainingRecord = () => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      institute: "",
      supervisor: "",
      supervisorDesignation: "",
      supervisorPostGraduateQualification: "",
      departmentId: "",
      duration: "",
      document: "",
    },
    validationSchema: Yup.object({
      institute: Yup.string().required("Institute is required"),
      supervisor: Yup.string().required("Supervisor is required"),
      supervisorDesignation: Yup.string().required(
        "Supervisor Designation is required"
      ),
      supervisorPostGraduateQualification: Yup.string().required(
        "Supervisor Post Graduate Qualification is required"
      ),
      departmentId: Yup.number().required("Department is required"),
      duration: Yup.string().required("Duration is required"),
      document: Yup.string(),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <div>
      <Row className="px-md-4 px-sm-2F">
        <h5>Training Record</h5>
        <Col sm={12} md={6} lg={6}>
          {/* Institute */}
          <div className="mb-3">
            <FormGroup className="mb-3">
              <Label for="exampleSelect">Institute</Label>
              <Input type="select" name="degree">
                <option value={0}>Select Institute </option>
                <option value={1}> Dhaka Medical College</option>
                <option value={2}>Mymensingh Medical College</option>
                <option value={3}>Sir Salimullah Medical College</option>
              </Input>
            </FormGroup>
          </div>

          {/* Supervisor */}
          <div className="mb-3">
            <Label className="form-label">Supervisor</Label>
            <InputField
              name="supervisor"
              type="text"
              placeholder="Supervisor Name"
              onChange={handleChange}
              value={values.supervisor}
              invalid={{ errors, touched }}
            />
          </div>

          {/* Supervisor's Designation */}
          <div className="mb-3">
            <Label className="form-label">Supervisor's Designation</Label>
            <InputField
              name="supervisorDesignation"
              type="text"
              placeholder="Supervisor Designation"
              onChange={handleChange}
              value={values.supervisorDesignation}
              invalid={{ errors, touched }}
            />
          </div>
        </Col>
        <Col sm={12} md={6} lg={6}>
          {/* Department */}
          <div className="mb-3">
            <FormGroup className="mb-3">
              <Label for="exampleSelect">Department</Label>
              <Input type="select" name="department" onChange={handleChange}>
                <option value={0}>Select Department </option>
                <option value={1}>Department 1</option>
                <option value={2}>Department 2</option>
                <option value={3}>Department 3</option>
              </Input>
            </FormGroup>
          </div>

          {/* Duration */}
          <div className="mb-3">
            <Label className="form-label">Duration</Label>
            <InputField
              name="duration"
              type="text"
              placeholder="Duration of the training"
              onChange={handleChange}
              value={values.duration}
              invalid={{ errors, touched }}
            />
          </div>

          {/* Certificate */}
          <div className="mb-3">
            <Label className="form-label">Document</Label>
            <InputField
              name="document"
              type="file"
              placeholder="Upload Document"
              onChange={handleChange}
              value={values.document}
              invalid={{
                errors: errors,
                touched: touched,
              }}
            />
          </div>
        </Col>
        <div className="mb-3">
          <Label className="form-label">
            Supervisor's Post Graduate Qualification
          </Label>
          <InputField
            name="supervisorPostGraduateQualification"
            type="textarea"
            placeholder="Supervisor's Post Graduate Qualification"
            onChange={handleChange}
            value={values.supervisorPostGraduateQualification}
            invalid={{ errors, touched }}
          />
        </div>

        <Col>
          <div className="mb-3 d-flex flex-row-reverse">
            <Button className="float-right px-3" text={"+ Add"} />
          </div>
          <Card>
            <CardBody>
              <div className="table-responsive">
                <Table className="table table-striped table-hover mb-0">
                  <thead>
                    <tr>
                      <th>Sl</th>
                      <th>Institute</th>
                      <th>Department</th>
                      <th>Duration</th>
                      <th>Supervisor's Information</th>
                      <th>Document</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Dhaka Medical College</td>
                      <td>Medicine</td>
                      <td>1 Year</td>
                      <td>Dr. Abdul Karim, MBBS, FCPS (USA)</td>
                      <td>Training Certificate</td>
                      <td>
                        <div className="btn btn-sm btn-primary d-inline-block mx-1">
                          Edit
                        </div>
                        <div className="btn btn-sm btn-danger d-inline-block">
                          Delete
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TrainingRecord;
