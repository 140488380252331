import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { Button, InputField } from "src/components";
import { config } from "src/config";
import { setFromErrors } from "src/helpers";
import { useSupportServiceEntryMutation } from "src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi";
import * as Yup from 'yup';

interface SupportServiceProps {
    departmentId?: string;
    recordOfSupportServiceToEdit?: SupportServiceList;
    setModalShow?: any;
}

const initialValues: SupportServiceEntry = {
    support_service_id: '',
    form_b_department_id: '',
    department_name: '',
    no_of_academic_staff: 0,
    no_of_support_staff: 0,
    equipment_list: '',
    service_offered: '',
};

const DepartmentAndServiceForClinicalDepartmentsOnlyModal = (props: SupportServiceProps) => {
    const { departmentId, recordOfSupportServiceToEdit, setModalShow } = props;

    const essentialEquipmentFileReference = useRef<any>();
    const [essentialEquipmentFile, setEssentialEquipmentFile] = useState<any>('');

    const [supportServiceEntry, { data: supportServiceEntryData, isSuccess: isSupportServiceEntryDataSuccess, isLoading: isSupportServiceEntryDataLoading, isError: isSupportServiceEntryDataError, error: supportServiceEntryDataError }] =
        useSupportServiceEntryMutation();

    const err: CustomError = supportServiceEntryDataError as CustomError;

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({}),

        onSubmit: (values: any) => {
            console.log(values);
            values.form_b_department_id = departmentId;
            const formData = new FormData();

            Object.keys(values).forEach((key: string) => {
                formData.append(key, values[key]);
            });

            supportServiceEntry(formData);
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    const handleFormReset = () => {
        essentialEquipmentFileReference.current.value = '';
        setEssentialEquipmentFile('');
        resetForm();
    };

    useEffect(() => {
        if (recordOfSupportServiceToEdit?.id) {
            setValues({
                support_service_id: recordOfSupportServiceToEdit?.id,
                form_b_department_id: recordOfSupportServiceToEdit?.form_b_department_id,
                department_name: recordOfSupportServiceToEdit?.department_name,
                no_of_academic_staff: recordOfSupportServiceToEdit?.no_of_academic_staff,
                no_of_support_staff: recordOfSupportServiceToEdit?.no_of_support_staff,
                equipment_list: '',
                service_offered: recordOfSupportServiceToEdit?.service_offered,
            });
            setEssentialEquipmentFile(recordOfSupportServiceToEdit?.equipment_list);
        }
    }, []);

    useEffect(() => {
        if (isSupportServiceEntryDataSuccess) {
            handleFormReset();
            toast.success('Department & service record saved successfully');
            setModalShow(false);
        }
        if (isSupportServiceEntryDataError) {
            Object.entries(err?.data?.errors).forEach((entry: any) => {
                entry[1]?.forEach((message: any) => {
                    toast.error(message);
                });
            });
            setFromErrors(err.data, setErrors);
        }
    }, [isSupportServiceEntryDataSuccess, isSupportServiceEntryDataError]);

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title">
                                    <i className="fa fa-plus me-2" />
                                    Department and Service (for clinical departments only)
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    autoComplete="off"
                                    className="custom-form"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    onReset={(e) => {
                                        e.preventDefault();
                                        handleFormReset();
                                    }}
                                >
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="department_name">Facility</Label>
                                                <InputField
                                                    type="select"
                                                    options={[
                                                        {label: 'Pathology', value: 'Pathology'},
                                                        {label: 'Biochemistry', value: 'Biochemistry'},
                                                        {label: 'Microbiology', value: 'Microbiology'},
                                                        {label: 'Virology/Immunology', value: 'Virology/Immunology'},
                                                        {label: 'Radiology and imaging', value: 'Radiology and imaging'},
                                                        {label: 'Blood bank', value: 'Blood bank'},
                                                        {label: 'Nuclear medicine', value: 'Nuclear medicine'},
                                                        {label: 'Anaesthesiology', value: 'Anaesthesiology'},
                                                        {label: 'Oncology', value: 'Oncology'},
                                                    ]}
                                                    placeholder=""
                                                    {...getFieldProps('department_name')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="no_of_academic_staff">Number of academic staff available</Label>
                                                <InputField
                                                    type="number"
                                                    placeholder=""
                                                    {...getFieldProps('no_of_academic_staff')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="no_of_support_staff">Number of support staff (Technologist)</Label>
                                                <InputField
                                                    type="number"
                                                    placeholder=""
                                                    {...getFieldProps('no_of_support_staff')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="equipmentList">Essential equipments (provide list)</Label>
                                                {typeof essentialEquipmentFile === 'string' && essentialEquipmentFile !== '' ? (
                                                    <a
                                                        className="me-2 p-0 center"
                                                        href={`${config?.APP_URL}${essentialEquipmentFile}`}
                                                        rel="noreferrer"
                                                        target="_blank"
                                                    >
                                                        <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                                    </a>
                                                ) : (
                                                    typeof essentialEquipmentFile !== 'string' && essentialEquipmentFile &&
                                                    <a
                                                        className="me-2 p-0 center"
                                                        href={URL.createObjectURL(essentialEquipmentFile)}
                                                        rel="noreferrer"
                                                        target="_blank"
                                                    >
                                                        <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                                    </a>
                                                )}
                                                <input
                                                    type="file"
                                                    ref={essentialEquipmentFileReference}
                                                    name="equipmentList"
                                                    id="equipmentList"
                                                    className="form-control"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        setFieldValue('equipment_list', e.target.files![0]);
                                                        setEssentialEquipmentFile(e.target.files![0]);
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="service_offered">Service offered to department</Label>
                                                <InputField
                                                    type="text"
                                                    placeholder=""
                                                    {...getFieldProps('service_offered')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col sm={12} className="mt-3">
                                            <FormGroup className="mb-3 text-center">
                                                <Button
                                                    type="reset"
                                                    text="Reset"
                                                    className="btn btn-warning me-2"
                                                ></Button>
                                                <Button
                                                    type="submit"
                                                    text="Save"
                                                    className="btn btn-success me-2"
                                                    disabled={isSupportServiceEntryDataLoading}
                                                ></Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default DepartmentAndServiceForClinicalDepartmentsOnlyModal;