import { SubjectInfos } from "src/pages/Setup/SubjectInfo";
import { AddSubjectInfo } from "src/pages/Setup/SubjectInfo/AddSubjectInfo";
import { EditSubjectInfo } from "src/pages/Setup/SubjectInfo/EditSubjectInfo";


export const subjectInfoSetupRoutes = [
  // subject info setup
  {
    path: '/setup/subjectInfo/',
    component: SubjectInfos,
  },
  {
    path: '/setup/subjectInfo/update/:id',
    component: EditSubjectInfo,
  },
  {
    path: '/setup/subjectInfo/add',
    component: AddSubjectInfo,
  },
];
