import { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Label, Row } from 'reactstrap';
import { InputField } from 'src/components';
import { useGetFormByLogbookTypeQuery } from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';

const AddLogbookDynamicFormProperties = () => {
  const [form, setForm] = useState<LogbookEntrySetupMaster>(
    {} as LogbookEntrySetupMaster
  );

  const [caseType, setCaseType] = useState<LogbookEntrySetupMaster>(
    {} as LogbookEntrySetupMaster
  );

  const [caseTypes, setCaseTypes] = useState<LogbookEntrySetupMaster[]>([]);

  const [skillType, setSkillType] = useState<LogbookEntrySetupMaster>(
    {} as LogbookEntrySetupMaster
  );  

  const [ skillTypes, setSkillTypes ] = useState<LogbookEntrySetupMaster[]>([]);

  const { data: formSetups } = useGetFormByLogbookTypeQuery(1);
  const forms = formSetups?.data || [];

  let formOptions: SelectOption[] = 
    forms?.map((e: LogbookEntrySetupMaster) => {
        return ({
            label: e.particulars!,
            value: e.id!,
          });
    });

  let caseTypeOptions: SelectOption[] = 
    caseTypes?.map((e: LogbookEntrySetupMaster) => {
        return ({
            label: e.particulars!,
            value: e.id!,
        });
    });

  let skillTypeOptions: SelectOption[] = 
    skillTypes?.map((e: LogbookEntrySetupMaster) => {
        return ({
            label: e.particulars!,
            value: e.id!,
        });
    }); 

  const handleFormSelect = (e: any) => {
    const { options, selectedIndex, value } = e.target;
    const form = forms.find((form) => form.id === value);
    setForm(form!);
  };

  const handleCaseTypeSelect = (e: any) => {
    const { options, selectedIndex, value } = e.target;
    const caseType: any = caseTypes?.find((c: any) => c.id === value);
    setCaseType(caseType);
  };

  const handleSkillTypeSelect = (e: any) => {
    const { options, selectedIndex, value } = e.target;
    const skillType: any = skillTypes?.find((s: any) => s.id === value);
    setSkillType(skillType);    
  };  

  useEffect(() => {
    setCaseTypes(form?.entry_setup_master! ?? []);
  }, [form]);

  useEffect(() => {
    setSkillTypes(caseType?.entry_setup_master! ?? []);
  }, [caseType]);

  return (
    <div className="page-content">
      <Container fluid>
        <div className="row align-items-center justify-content-center">
          <div className="card mt-3">
            <h5 className="card-header">
              <i className="fa fa-plus me-2" />
              Logbook Dynamic Form Properties Entry
            </h5>
            <div className="card-body">
              <Row>
                <Col lg={4} md={6} sm={12}>
                  <FormGroup className="mb-3">
                    <Label className="form-label">Form</Label>
                    <InputField
                      type="select"
                      name="form"
                      placeholder=""
                      options={formOptions}
                      onChange={(e) => {
                        handleFormSelect(e);
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col lg={4} md={6} sm={12}>
                  <FormGroup className="mb-3">
                    <Label className="form-label">Case Type</Label>
                    <InputField
                      type="select"
                      name="caseType"
                      placeholder=""
                      options={caseTypeOptions}
                      onChange={(e) => {
                        handleCaseTypeSelect(e);
                      }}
                    />
                  </FormGroup>
                </Col>

                {skillTypes?.length > 0 ? (
                  <Col lg={4} md={6} sm={12}>
                    <FormGroup className="mb-3">
                      <Label className="form-label">Skill Type</Label>
                      <InputField
                        type="select"
                        name="skillType"
                        placeholder=""
                        options={skillTypeOptions}
                        onChange={(e) => {
                          handleSkillTypeSelect(e);
                        }}
                      />
                    </FormGroup>
                  </Col>
                ) : null}
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AddLogbookDynamicFormProperties;
