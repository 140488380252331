import { apiSlice } from 'src/rtk/api/apiSlice';
import * as courseApprovalUrl from 'src/helpers/url_helper';

export const courseApprovalApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['courseApproval', 'TitleApproval'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({

      getCourseApplicantionList: builder.query<APIResponseWithPagination<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${courseApprovalUrl.GET_COURSE_APPLICATION_LIST}?${url}`,
        }),
        providesTags: ['courseApproval'],
      }),

      getTitleListForRtmd: builder.query<DataWithPagination<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${courseApprovalUrl.GET_TITLE_LIST_FOR_RTMD}?${url}`,
        }),
        providesTags: ['TitleApproval'],
      }),

      createTitleApprovalForRtmd: builder.mutation<APIResponse<any>, any>({
        query: (data: any) => ({
          url: `${courseApprovalUrl.CREATE_TITLE_APPROVAL_FOR_RTMD}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['TitleApproval'],
      }),

      getSlotApprovalByAdmin: builder.mutation<APIResponse<any>, string>({
        query: (url) => ({
          method: 'GET',
          url: `${courseApprovalUrl.GET_COURSE_APPROVAL_BY_ADMIN}/${url}`,
        }),
        invalidatesTags: ['courseApproval'],
      }),

    }),
  });

export const {
  useGetCourseApplicantionListQuery,
  useGetTitleListForRtmdQuery,
  useGetSlotApprovalByAdminMutation,
  useCreateTitleApprovalForRtmdMutation,
} = courseApprovalApi;
