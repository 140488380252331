import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { setFromErrors } from 'src/helpers';
import { useCreateProgressReportEntryMutation, useLazyGetProgressReportDataQuery } from 'src/rtk/features/logbookMonitoringReport/logbookMonitoringReportApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import * as Yup from 'yup';

const PerformanceEvaluation = () => {
  const { regNo: registrationNo, id: supervisorId, name: supervisorName, trainingId: examTrainingId } = useParams<{
    regNo: string;
    id: string;
    name: string;
    trainingId: string;
  }>();
  const [getProgressReportData, { data }] = useLazyGetProgressReportDataQuery();

  useEffect(() => {
    getProgressReportData(registrationNo);
  }, []);
  
  const history = useHistory();
  const [btnClicked, setBtnClicked] = useState<string>('');

  const { data: reportLookup } = useGetLookupDataQuery('progress_report');

  const evaluationList: Lookup[] = reportLookup?.data[0]?.childs || [];
  const option = [
    { label: 'Can', value: 'Can' },
    { label: 'Cannot', value: 'Cannot' },
  ];

  const [createProgressReportEntry, { isSuccess, isError, error, isLoading }] =
    useCreateProgressReportEntryMutation();
  
  const err: CustomError = error as CustomError;

  const initialValues = {
    attendance: '',
    skill: '',
    attitude: '',
    knowledge: '',
    supervisor_comment: '',
    supervisor_recommendation: '',
    registration_id: '',
    supervisor_id: '',
    supervisor_name: '',
    exam_training_id: '',
    submit_status: '',
  };


  useEffect(()=>{
    const list = data?.data?.find((item:any)=> item?.registration_id === registrationNo) || [];
    console.log(list);
    if(list){
      setValues({
        attendance: list?.attendance || '',
        skill: list?.skill || '',
        attitude: list?.attitude || '',
        knowledge: list?.knowledge || '',
        supervisor_comment: list?.supervisor_comment || '',
        supervisor_recommendation: list?.supervisor_recommendation || '',
        registration_id: list?.registration_id || '',
        supervisor_id: list?.supervisor_id || '',
        supervisor_name: list?.supervisor_name || '',
        exam_training_id: list?.exam_training_id || '',
        submit_status: list?.submit_status,
      }); 
    }
  },[data]);

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      attendance: Yup.string().required('Please select one'),
      skill: Yup.string().required('Please select one'),
      attitude: Yup.string().required('Please select one'),
      knowledge: Yup.string().required('Please select one'),
      supervisor_comment: Yup.string().required('Please write comment'),
      supervisor_recommendation: Yup.string().required('Please Recomend'),
    }),

    onSubmit: (values) => {
      
      values.registration_id = registrationNo;
      values.supervisor_id = supervisorId;
      values.supervisor_name = supervisorName;
      values.exam_training_id = examTrainingId;

      Object.keys(values).forEach((key) => {
        if (key === 'submit_status') {
          if (btnClicked === 'submit') {
            values[key] = 'Submit';
          } else if (btnClicked === 'save_as_draft') {
            values[key] = 'Draft';
          }
        }
      });
      console.log(values);
      createProgressReportEntry(values);
    },
  });

  const {
    handleSubmit,
    getFieldProps,
    handleChange,
    setValues,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = formik;

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Performance Evaluation Successfully!');
      history.push('/progress-report');
    }
    if (isError) {
      toast.error(err?.message || 'Something Went Wrong!');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          breadcrumbItem={[
            { link: '/', name: 'Dashboard' },
            { link: '/progress-report', name: 'Progress Report' },
          ]}
          title={'Performance Evaluation'}
        />
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-end mt-1">
              <Link
                to="/progress-report"
                className="btn btn-success waves-effect waves-light"
              >
                <i className="fas fa-arrow-left me-2" /> Back
              </Link>
            </div>
          </CardHeader>
          <CardBody>
            <div>
              <Form
                className="custom-form"
                onSubmit={(e) => {
                  handleSubmit();
                  e.preventDefault();
                }}
                onReset={(e) => {
                  resetForm();
                  e.preventDefault();
                }}
              >
                <Row className="mb-2">
                  <Col md={3}>
                    <div>
                      <Label for="attendance" className='form-label required-field'>Attendance</Label>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div>
                      <InputField
                        type="select"
                        placeholder="Attendance"
                        options={[
                          { label: 'Poor', value: 'Poor' },
                          { label: 'Average', value: 'Average' },
                          { label: 'Good', value: 'Good' },
                          { label: 'Satisfactory', value: 'Satisfactory' },
                          { label: 'Excellent', value: 'Excellent' },
                        ]}
                        invalid={{ errors, touched }}
                        {...getFieldProps('attendance')}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={3}>
                    <div>
                      <Label for="skill" className='form-label required-field'>Skill</Label>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div>
                      <InputField
                        type="select"
                        placeholder="Skill"
                        options={[
                          { label: 'Poor', value: 'Poor' },
                          { label: 'Average', value: 'Average' },
                          { label: 'Good', value: 'Good' },
                          { label: 'Satisfactory', value: 'Satisfactory' },
                          { label: 'Excellent', value: 'Excellent' },
                        ]}
                        invalid={{ errors, touched }}
                        {...getFieldProps('skill')}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={3}>
                    <div>
                      <Label for="attitude" className='form-label required-field'>Attitude</Label>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div>
                      <InputField
                        type="select"
                        placeholder="Attitude"
                        options={[
                          { label: 'Poor', value: 'Poor' },
                          { label: 'Average', value: 'Average' },
                          { label: 'Good', value: 'Good' },
                          { label: 'Satisfactory', value: 'Satisfactory' },
                          { label: 'Excellent', value: 'Excellent' },
                        ]}
                        invalid={{ errors, touched }}
                        {...getFieldProps('attitude')}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={3}>
                    <div>
                      <Label for="knowledge" className='form-label required-field'>Knowledge</Label>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div>
                      <InputField
                        type="select"
                        placeholder="Knowledge"
                        options={[
                          { label: 'Poor', value: 'Poor' },
                          { label: 'Average', value: 'Average' },
                          { label: 'Good', value: 'Good' },
                          { label: 'Satisfactory', value: 'Satisfactory' },
                          { label: 'Excellent', value: 'Excellent' },
                        ]}
                        invalid={{ errors, touched }}
                        {...getFieldProps('knowledge')}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={6}>
                    <div>
                      <Label for="comment" className='form-label required-field'>Supervisor Comment</Label>
                      <InputField
                        type="textarea"
                        placeholder="Enter Comment"
                        invalid={{ errors, touched }}
                        {...getFieldProps('supervisor_comment')}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  Supervisor’s Recommendation: Considering his/her overall performance I recommend that the trainee
                  <Col sm={1}>
                    <Input
                      type="select"
                      name="supervisor_recommendation"
                      value={values.supervisor_recommendation}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      {option?.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.value}
                        </option>
                      ))}
                    </Input>
                    {
                      errors.supervisor_recommendation && touched.supervisor_recommendation ?
                      <div className='text-danger'>{errors.supervisor_recommendation}</div>: null
                    }
                  </Col>
                  proceed for further training.
                </Row>
                <FormGroup className="mb-3 text-center">
                  <Button type="reset" className="btn btn-secondary me-2">
                    Reset
                  </Button>
                  <button
                    type="submit"
                    className="btn btn-info me-2"
                    onClick={(e) => {
                      setBtnClicked('save_as_draft');
                    }}
                  >
                    Save as Draft
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success me-2"
                    onClick={(e) => {
                      setBtnClicked('submit');
                    }}
                  >
                    Submit
                  </button>
                </FormGroup>
              </Form>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default PerformanceEvaluation;
