import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import { Container, Progress } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import {
  useGetTrainingSummaryQuery,
  useGetUserSlotInfoForTraineeQuery,
  useLazyGetTrainingSummaryByIdQuery,
} from 'src/rtk/features/logbookTrainingSummary/logbookTrainingSummaryApi';
import { useGetPersonalProfileQuery } from 'src/rtk/features/trainee/traineeApi';
import loader from '../../assets/images/loader.gif';
import { AllSummaryReports } from './AllSummaryReports';
import { SummaryReports } from './SummaryReports';

export const SummaryOfProceduralAndInterpretativeSkills = () => {
  const { userId, sessionId } = useParams<{ userId: string; sessionId: string }>();
  const [parentTrainingSummary, setParentTrainingSummary] =
    useState<LogbookTrainingSummary>({} as LogbookTrainingSummary);
  const [trainingSummaryList, setTrainingSummaryList] = useState<
    LogbookTrainingSummary[]
  >([]);
  const [trainingSummaryId, setTrainingSummaryId] = useState<string>('');
  const [showAllTrainingSummaryReports, setShowAllTrainingSummaryReports] = useState<boolean>(false);

  const {
    data: personalProfile,
    isError: isPersonalProfileError,
    isLoading: isPersonalProfileLoading
  } = useGetPersonalProfileQuery();

  const {
    data: userSlotData,
    isLoading: isUserSlotDataLoading,
    isSuccess: isUserSlotDataSuccess,
    isError: isUserSlotDataError,
    error: userSlotDataError,
  } = useGetUserSlotInfoForTraineeQuery(userId ? userId : (personalProfile?.data as any)?.id!);

  const {
    data: trainingSummaries,
    isLoading: isTrainingSummariesLoading,
    isSuccess: isTrainingSummariesSuccess,
  } = useGetTrainingSummaryQuery({
    user_id: userId ? userId : (personalProfile?.data as any)?.id!,
    session_id: sessionId ? sessionId : userSlotData?.data?.log_entry_current_session?.id!
  });

  const [
    getTrainingSummaryById,
    {
      data: trainingSummary,
      isLoading: isTrainingSummaryLoading,
      isSuccess: isTrainingSummarySuccess,
      isFetching,
    },
  ] = useLazyGetTrainingSummaryByIdQuery();

  useEffect(() => {
    if (trainingSummaries) {
      const tData = trainingSummaries?.data || [];
      const parentTraining = tData?.find(
        (t: LogbookTrainingSummary) =>
          t.particulars === 'Procedure' || t.particulars === 'Procedural and Interpretative Skills',
      )!;
      setParentTrainingSummary(parentTraining);
    }
  }, [trainingSummaries]);

  useEffect(() => {
    if (parentTrainingSummary) {
      getTrainingSummaryById({ id: parentTrainingSummary.id!, userId: userId ? userId : (personalProfile?.data as any)?.id!, sessionId: sessionId ? sessionId : userSlotData?.data?.log_entry_current_session?.id! });
    }
  }, [parentTrainingSummary]);

  useEffect(() => {
    if (trainingSummary && isTrainingSummarySuccess) {
      const tData = trainingSummary?.data || [];
      setTrainingSummaryList(tData);
    }
  }, [trainingSummary, isTrainingSummarySuccess]);

  const columns: TableColumn<any>[] = [
    {
      name: 'Sl. No.',
      cell: (row, index) => index + 1,
      width: '80px',
    },

    {
      name: 'Form',
      selector: (row) => row?.particulars,
      sortable: false,
      wrap: true,
    },

    {
      name: 'Minimum to be Performed',
      selector: (row) => row?.total_min_target,
      sortable: false,
      center: true,
    },

    {
      name: 'Total Performed',
      selector: (row) => row?.total,
      sortable: false,
      center: true,
    },

    {
      name: 'Percent Performed',
      width: '200px',
      cell: (row) => {
        const percentageValue: number = +row?.total_min_target
          ? (row?.total * 100) / +row?.total_min_target
          : 0;
        return (
          <div className='' style={{ width: '700px', position: 'relative' }}>
            <Progress
              color={
                percentageValue < 50
                  ? 'danger'
                  : percentageValue <= 99
                    ? 'warning'
                    : 'success'
              }
              value={percentageValue}
            >
              <div
                style={{
                  position: 'absolute',
                  textAlign: 'center',
                  lineHeight: '10px',
                  color: 'black',
                  overflow: 'hidden',
                  right: 0,
                  left: 0,
                  top: 0,
                }}
              >
                {percentageValue.toFixed(2)}%
              </div>
            </Progress>
          </div>
        );
      },
    },

    {
      name: (
        <div>
          Action
        </div>
      ),
      cell: (row, index) => (
        <>
          <button
            className="btn btn-sm btn-info"
            onClick={() => {
              setTrainingSummaryId(row?.id);
            }}
          >
            View
          </button>
          {trainingSummaryId === row?.id ? (
            <SummaryReports data={row} trainingSummaryId={trainingSummaryId} setTrainingSummaryId={setTrainingSummaryId} />
          ) : null}
        </>
      ),

      ignoreRowClick: true,
      allowOverflow: false,
      // button: true,
      width: '150px',
    },
  ];

  return (
    <div className='page-content'>
      {isTrainingSummariesLoading || isFetching ? (
        <div className='overlay'>
          <img src={loader} alt='Loading' height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <div className='page-breadcrumb-wrapper'>
          <BetterBreadcrumb
            title='Summary of Procedural and Interpretative Skill Case Log'
            breadcrumbItem={
              [
                { link: '/trainee/logbook-entry', name: 'Logbook Entry' },
              ]
            }
          />
          <button
            className="btn btn-info"
            onClick={() => {
              setShowAllTrainingSummaryReports(true);
            }}
          >
            View All
          </button>
          {showAllTrainingSummaryReports ?
            <AllSummaryReports
              allData={trainingSummaryList}
              showAllTrainingSummaryReports={showAllTrainingSummaryReports}
              setShowAllTrainingSummaryReports={setShowAllTrainingSummaryReports} />
            : null}
        </div>


        <div className='card'>

          <div className='card-body'>
            <DataTable
              columns={columns}
              data={trainingSummaryList}
              // progressPending={isTrainingSummaryLoading}
              expandOnRowClicked
              highlightOnHover
              responsive
              persistTableHead={true}
              noDataComponent={<div className='border py-4 text-center w-100'>There are no records to display</div>}
            />
          </div>
        </div>

      </Container>
    </div>
  );
};
