import { FormGroup, Label } from "reactstrap";
import { InputField } from "src/components";
import { useGetLookupDataQuery } from "src/rtk/features/setup/lookup/lookupDataApi";

export const DynamicFormSelect = (props: any) => {

    const { field, formik } = props;
    const { getFieldProps, errors, touched } = formik;    

    const dropdownContent: SelectOption[] =
        useGetLookupDataQuery(field.dropdownLabel?.toLowerCase())
        .data?.data[0]?.childs.map((item) => ({
            value: item.name,
            label: item.name,
        }))
        .sort((a, b) => {
            return a.label.localeCompare(b.label);
        }) || [];

    return (
        <div className='col-lg-4 col-md-6 col-sm-12'>
            <FormGroup className="mb-3">
                <Label for={field.name}>{field.name}</Label>
                <InputField
                    type='select'
                    placeholder={field.name}
                    options={dropdownContent}
                    invalid={{ errors, touched }}
                    {...getFieldProps(field.name)}
                />
            </FormGroup>
        </div>
    );
};