import { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { Label } from "reactstrap";
import { InputField } from "src/components";

const Signatories = (props: any) => {
  const { userList, signatoryInputList, setInputList } = props;

  const signatoryUserOptions: SelectOption[] = userList?.map((item: any) => ({
    value: item.id ?? '',
    label: item.name ?? '',
  }));

  const [plusButtonDisabled, setPlusButtonDisabled] = useState<boolean>(true);
  const [selectedUserId, setSelectedUserId] = useState<number>(0);
  const [selectedSignatorySerial, setSelectedSignatorySerial] = useState<number>(0);

  const handleAddFields = () => {
    const values = [...signatoryInputList];

    values.push({
      attachmentType: 'Signatory',
      attachmentFile: '',
      userId: +selectedUserId,
      signatorySerial: +selectedSignatorySerial,
    });

    setInputList(values);
    setSelectedUserId(0);
    setSelectedSignatorySerial(0);
    setPlusButtonDisabled(true);
  };

  const handleRemoveFields = (index: number) => {
    const values = [...signatoryInputList];
    values.splice(index, 1);
    setInputList(values);
  };

  const handleUserInputChange = (event: any) => {
    const { name, value } = event.target;
    setSelectedUserId(value);
  };

  const handleSignatorySerialInputChange = (event: any) => {
    const { name, value } = event.target;    
    setSelectedSignatorySerial(value);    
  };

  useEffect(() => {
    if(selectedUserId && selectedSignatorySerial) {
      setPlusButtonDisabled(false);
    }
  }, [selectedUserId, selectedSignatorySerial]);

  console.log(signatoryInputList);

  const columns: TableColumn<any>[] = [
    {
      name: <div className="text-center">Sl. No.</div>,
      width: '100px',
      cell: (row, index) => index + 1,
      center: true,
    },

    {
      name: <div className="text-center">User</div>,
      selector: (row) => userList?.find((u: any) => u.id === row.userId)?.name,
      sortable: false,
      wrap: true,
      center: true,
    },

    {
      name: <div className="text-center">Signatory Serial</div>,
      selector: (row) => row.signatorySerial,
      sortable: false,
      wrap: true,
      center: true,
    },

    {
      name: <div className="text-center">Action</div>,
      width: '150px',
      cell: (row) => (
        <>
          <button
            type="button"
            className={'btn btn-danger mb-2'}
            onClick={() => handleRemoveFields(signatoryInputList?.indexOf(row))}
          >
            <i className="fa fa-minus-circle" />
          </button>
        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <>
      {signatoryInputList?.length > 0 &&
        <div className="row mb-5">
          <div className="col-sm-12">
            <DataTable
              columns={columns}
              data={signatoryInputList?.length > 0 ? signatoryInputList : []}
              expandOnRowClicked
              highlightOnHover
            />
          </div>
        </div>}

      <div className="row">
        <div className="col-sm-5">
          <Label className='form-label'>User</Label>
          <InputField
            type="select"
            className="form-control"
            name="userId"
            options={signatoryUserOptions}   
            value={selectedUserId}         
            onChange={(event) => handleUserInputChange(event)}
          />
        </div>
        <div className="col-sm-5">
          <Label className='form-label'>Signatory Serial</Label>
          <InputField
            type="number"
            className="form-control"
            name="signatorySerial"     
            value={selectedSignatorySerial}       
            onChange={(event) => handleSignatorySerialInputChange(event)}
          />
        </div>

        <div className="col-sm-2">
          {!plusButtonDisabled ?
            (<button
              type="button"
              className={'btn btn-secondary mt-4'}
              onClick={handleAddFields}
            >
              <i className="fa fa-plus-circle" />
            </button>)
            :
            (<button
              type="button"
              className={'btn btn-secondary mt-4'}
              onClick={handleAddFields}
              disabled
            >
              <i className="fa fa-plus-circle" />
            </button>)}
        </div>
      </div>
    </>
  );
};

export default Signatories;

