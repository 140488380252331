import { Container } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import EducationalProfile from '../../components/ProfileForm/EducationalProfile/EducationalProfile';
import PersonalProfile from '../../components/ProfileForm/PersonalProfile';
import SupervisorReg from 'src/pages/Supervisor/SupervisorReg';

const SupervisorRegistration = () => {
  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title="Profile"
          breadcrumbItem={[{ link: '/dashboard', name: 'Dashboard' }]}
        />

        {/*<PersonalProfile />
         <EducationalProfile />
        <TrainingRecordProfile />
        <ExperienceProfile /> */}
        <hr />
        <SupervisorReg />
      </Container>
    </div>
  );
};

export default SupervisorRegistration;
