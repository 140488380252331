import { apiSlice } from 'src/rtk/api/apiSlice';
import * as publishCertificateUrl from 'src/helpers/url_helper';

export const publishCertificateApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['CertificateList', 'Signatories', 'CertificateTemplateList'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCertificateList: builder.query<any, void>({
        query: () => ({
          method: 'GET',
          url: `${publishCertificateUrl.GET_CERTIFICATE_LIST}`,
        }),
        providesTags: ['CertificateList'],
      }),

      getCertificateListByPaginate: builder.query<
        any,
        PaginateForLogbookEntries
      >({
        query: (paginate) => {
          let url = `${publishCertificateUrl.GET_CERTIFICATE_LIST}?type=${'Certificate'}&`;

          if (paginate.page) {
            url += `page=${paginate.page}&`;
          }
          if (paginate.paginate_per_page) {
            url += `paginate_per_page=${paginate.paginate_per_page}`;
          }
          if (paginate.search) {
            url += `${paginate.search}`;
          }

          return {
            method: 'GET',
            url: url,
          };
        },

        providesTags: ['CertificateList'],
      }),

      getCertificateTemplateList: builder.query<APIResponse<CertificateTemplate[]>, void>({
        query: () => ({
          method: 'GET',
          url: `${publishCertificateUrl.GET_CERTIFICATE_TEMPLATE_LIST}?type=${'Certificate'}`,
        }),

        providesTags: ['CertificateTemplateList'],
      }),

      getCertificateView: builder.query<any, string>({
        query: (id: string) => ({
          method: 'GET',
          url: `${publishCertificateUrl.GET_CERTIFICATE_VIEW}/${id}`,
        }),
        providesTags: ['CertificateList'],
      }),

      getCertificatePdf: builder.query<any, string>({
        query: (id) => ({
          method: 'GET',
          url: `${publishCertificateUrl.GET_CERTIFICATE_PDF}/${id}`,
        }),
        providesTags: ['CertificateList'],
      }),

      getSignatoriesByPermission: builder.query<APIResponse<SignatoryUser[]>, string>({
        query: (id) => ({
          url: `${publishCertificateUrl.GET_SIGNATORIES_BY_PERMISSION}/${id}`,
          method: 'GET',
        }),
        providesTags: ['Signatories'],
      }),

      createCertificate: builder.mutation<APIResponse<PostCertificate>, any>({
        query: (data: any) => ({
          url: `${publishCertificateUrl.CREATE_CERTIFICATE}`,
          method: 'POST',
          body: data,
          Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }),
        invalidatesTags: ['CertificateList'],
      }),

      updateCertificateMaster: builder.mutation<APIResponse<any>, any>({
        query: (data) => ({
            url: `${publishCertificateUrl.UPDATE_CERTIFICATE_MASTER}`,
            method: 'POST',
            body: data,
            Headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
        }),
        invalidatesTags: ['CertificateList'],
      }),

      updateCertificateAttachment: builder.mutation<APIResponse<any>, any>({
        query: (data) => ({
            url: `${publishCertificateUrl.UPDATE_CERTIFICATE_ATTACHMENT}`,
            method: 'POST',
            body: data,
            Headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
        }),
        invalidatesTags: ['CertificateList'],
      }),
    }),
  });

export const {
  useGetCertificateListQuery,
  useGetCertificateListByPaginateQuery,
  useGetCertificateTemplateListQuery,
  useGetCertificateViewQuery,
  useGetSignatoriesByPermissionQuery,  
  useGetCertificatePdfQuery,
  useLazyGetCertificatePdfQuery,
  useCreateCertificateMutation,
  useUpdateCertificateMasterMutation,
  useUpdateCertificateAttachmentMutation,
} = publishCertificateApi;
