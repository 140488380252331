import { useFormik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { Button, InputField } from "src/components";

const AccreditationInformation = () => {
  const { values, errors, touched, handleChange } = useFormik({
    initialValues: {
      demo: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <React.Fragment>
      <Row className="px-md-4 px-sm-2">
        <h5>Accreditation Information</h5>

        <Col sm={12} md={6} lg={6}>
          {/* Department Name */}
          <div className="mb-3">
            <FormGroup className="mb-3">
              <Label for="departmentName">Name of the Departments</Label>
              <Input type="select" name="departmentName">
                <option value={0}>Select Department </option>
                <option value={1}>Department 1</option>
                <option value={2}>Department 2</option>
                <option value={3}>Department 3</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <div className="mb-3">
                <h5 className="font-size-14 mb-3">
                  The department applied previously
                </h5>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                      defaultChecked
                    />
                    <label className="form-check-label">Yes</label>
                  </div>
                </div>

                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                    />
                    <label className="form-check-label">No</label>
                  </div>
                </div>
              </div>
            </FormGroup>

            {/*  */}

            <FormGroup>
              <div className="mb-3">
                <h5 className="font-size-14 mb-3">
                  The department was accredited previously but required
                  re-accreditation
                </h5>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                      defaultChecked
                    />
                    <label className="form-check-label">Yes</label>
                  </div>
                </div>

                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                    />
                    <label className="form-check-label">No</label>
                  </div>
                </div>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                    />
                    <label className="form-check-label">Not Applicable</label>
                  </div>
                </div>
              </div>
            </FormGroup>

            {/* ... */}

            <FormGroup>
              <div className="mb-3">
                <h5 className="font-size-14 mb-3">
                  The department was NOT considered for accreditation due to
                  short comings
                </h5>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                      defaultChecked
                    />
                    <label className="form-check-label">Yes</label>
                  </div>
                </div>

                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                    />
                    <label className="form-check-label">No</label>
                  </div>
                </div>
                <div className="d-inline-block mx-2">
                  <div className="form-check form-check-left">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="hasDGHSLicense"
                    />
                    <label className="form-check-label">Not Applicable</label>
                  </div>
                </div>
              </div>
            </FormGroup>

            {/* ... */}
            <h6>Number of Beds in the Department</h6>
            <FormGroup className="mb-3">
              <Label for="departmentName">Name of the Departments</Label>
              <Input type="select" name="departmentName">
                <option value={0}>
                  IPD, Department, Sub Specialty Department
                </option>
              </Input>
            </FormGroup>
          </div>
        </Col>
        <Col sm={12} md={6} lg={6}>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <FormGroup>
                <Label for="totalBed">Total Number of Beds</Label>
                <InputField
                  type="number"
                  name="totalBeds"
                  placeholder="Total Beds"
                  value={undefined}
                  invalid={{
                    errors,
                    touched,
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="totalBed">Total Number of Paying Beds</Label>
                <InputField
                  type="number"
                  name="totalPayingBeds"
                  placeholder="Total Paying Beds"
                  invalid={{ errors, touched }}
                  value={undefined}
                />
              </FormGroup>
              <FormGroup>
                <Label>Number of patients admitted in paying beds</Label>
                <InputField
                  type="number"
                  name="totalCabins"
                  placeholder="Enter a number"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>

              <FormGroup>
                <Label for="totalBed">Number of Non-Paying Beds</Label>
                <InputField
                  type="number"
                  name="totalNonPayingBeds"
                  placeholder="Total Non-Paying Beds"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
            </Col>

            <Col sm={12} md={6} lg={6}>
              <FormGroup>
                <Label for="totalBed">Number of Cabins</Label>
                <InputField
                  type="number"
                  name="totalCabins"
                  placeholder="Total Cabins"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>

              <FormGroup>
                <Label for="totalBed">From</Label>
                <InputField
                  type="number"
                  name="totalCabins"
                  placeholder="From"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>

              <FormGroup>
                <Label for="totalBed">
                  Number of patients admitted in non-paying beds
                </Label>
                <InputField
                  type="number"
                  name="totalCabins"
                  placeholder="Enter a number"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="totalBed">
                  Number of patients admitted in cabins
                </Label>
                <InputField
                  type="number"
                  name="totalCabins"
                  placeholder="Enter a number"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>

        {/* Table */}

        <Col>
          <div className="mb-3 d-flex flex-row-reverse">
            <Button className="float-right px-3" text={"+ Add"} />
          </div>
          <Card>
            <CardBody>
              <div className="table-responsive">
                <Table className="table table-striped table-hover mb-0">
                  <thead>
                    <tr>
                      <th>Beds For</th>
                      <th>Total Beds</th>
                      <th>Non Paying Beds</th>
                      <th>Paying Beds</th>
                      <th>Admitted non paying patient</th>
                      <th>Admitted paying patients</th>
                      <th>patient in cabin</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Department</td>
                      <td>250</td>
                      <td>150</td>
                      <td>100</td>
                      <td>88</td>
                      <td>62</td>
                      <td>23</td>
                      <td>
                        <div className="btn btn-sm btn-primary d-inline mx-1">
                          Edit
                        </div>
                        <div className="btn btn-sm btn-danger d-inline">
                          Delete
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col sm={12} md={6} lg={6}>
          <FormGroup>
            <div className="mb-3">
              <h5 className="font-size-14 mb-3">
                Availability of ICU Services
              </h5>
              <div className="d-inline-block mx-2">
                <div className="form-check form-check-left mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="hasDGHSLicense"
                    defaultChecked
                  />
                  <label className="form-check-label">Yes</label>
                </div>
              </div>

              <div className="d-inline-block mx-2">
                <div className="form-check form-check-left">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="hasDGHSLicense"
                  />
                  <label className="form-check-label">No</label>
                </div>
              </div>
            </div>
          </FormGroup>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <FormGroup>
                <Label for="totalICUBeds">Number of ICU Beds</Label>
                <InputField
                  type="number"
                  name="totalICUBeds"
                  placeholder="Number of ICU Beds"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="totalICUDoctors">Number of ICU Doctors</Label>
                <InputField
                  type="number"
                  name="totalICUDoctors"
                  placeholder="Number of ICU Doctors"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
            </Col>

            <Col sm={12} md={6} lg={6}>
              <FormGroup>
                <Label for="totalVentilators">Number of Ventilators</Label>
                <InputField
                  type="number"
                  name="totalVentilators"
                  placeholder="Number of Ventilators"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="totalICUNurses">Number of ICU Paramedics</Label>
                <InputField
                  type="number"
                  name="totalICUNurses"
                  placeholder="Number of ICU Nurse and Paramedics"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={6} lg={6}>
          <div className="mb-3">
            <Label>
              Disease profile of in-patients in the department(last one year)
            </Label>
            <InputField
              type="file"
              name="BMDCRecognitionDocs"
              placeholder="Upload Supporting Documents"
              value={""}
              invalid={{ errors, touched }}
            />
          </div>
          <div className="mb-3">
            <h6>
              Out patients records of the department applying for accreditation
              (last one year)
            </h6>
            <Row>
              <FormGroup className="col-sm-12 col-md-6">
                <Label>From</Label>
                <InputField
                  type="number"
                  name="totalICUNurses"
                  placeholder="From"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
              <FormGroup className="col-sm-12 col-md-6">
                <Label>To</Label>
                <InputField
                  type="number"
                  name="totalICUNurses"
                  placeholder="To"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
            </Row>
          </div>
        </Col>

        <Card>
          <CardBody>
            <Row>
              <FormGroup className="col-sm-12 col-md-6">
                <Label>Duration of OPD Patients care (in hours per week)</Label>
                <InputField
                  type="number"
                  name="totalICUNurses"
                  placeholder="Duration in hours"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
              <FormGroup className="col-sm-12 col-md-6">
                <Label>Number of patients attending the OPD per day</Label>
                <InputField
                  type="number"
                  name="totalICUNurses"
                  placeholder="Number of patients"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
              <FormGroup className="col-sm-12 col-md-6">
                <Label>Number of minor operations done in OPD per week</Label>
                <InputField
                  type="number"
                  name="totalICUNurses"
                  placeholder="Number of operations"
                  onChange={handleChange}
                  value={""}
                  invalid={{ errors, touched }}
                />
              </FormGroup>
            </Row>
          </CardBody>
        </Card>

        <div className="d-flex justify-content-center">
          <FormGroup className="col-sm-12 col-md-6">
            <Label>Disease profile of patients of OPD in last year </Label>
            <InputField
              type="file"
              name="totalICUNurses"
              placeholder="Disease profile docs"
              onChange={handleChange}
              value={""}
              invalid={{ errors, touched }}
            />
          </FormGroup>
        </div>

        <div className="text-center mb-3">
          <Link to="/institute/subject-registration-2">
            <button
              type="submit"
              className="btn btn-primary w-25"
              onSubmit={(e) => {
                e.preventDefault;
              }}>
              Save and Next {">"}
            </button>
          </Link>
        </div>
      </Row>
    </React.Fragment>
  );
};

export default AccreditationInformation;
