import { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import BetterBreadcrumb from "src/components/Common/BetterBreadCrumb";
import { useGetFaqListForTraineeQuery } from "src/rtk/features/research-clinic-faq/FAQApi";

const FAQViewForTrainee = () => {
    const {
        data: questionAnsList,
        isSuccess,
        isLoading,
    } = useGetFaqListForTraineeQuery();

    const [openAccordion, setOpenAccordion] = useState<number>(0);

    const toggleAccordion = (index: number) => {
        setOpenAccordion(openAccordion === index ? -1 : index);
    };

    let count: number = 1;
    return (
        <div className="page-content">
            <Container fluid>
                <div className='page-breadcrumb-wrapper'>
                    <BetterBreadcrumb
                      breadcrumbItem={[{ link: '/', name: 'Research Clinic' }]}
                      title={'Frequently Asked Question For Research Clinic'}
                    />
                </div>

                <Card>
                    <CardBody>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <h5 className="text-center">Frequently Asked Questions (FAQs)</h5>
                                <div className="accordion">
                                    {questionAnsList?.data?.map((item, index) => (
                                        <div key={index} className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button
                                                    className={`accordion-button ${openAccordion === index ? '' : 'collapsed'}`}
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#collapse${index}`}
                                                    onClick={() => toggleAccordion(index)}
                                                >
                                                    <strong>Q.{count + index}.</strong>&nbsp;{item.question}
                                                </button>
                                            </h2>
                                            {openAccordion === index && (
                                                <div className={`accordion-collapse collapse show`} id={`#collapse${index}`}>
                                                    <div className="accordion-body">
                                                        <span dangerouslySetInnerHTML={{ __html: item.answer }}></span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

            </Container>

        </div>
    );
}

export default FAQViewForTrainee;
