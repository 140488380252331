import { useEffect, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { useGetFacultyInfosQuery } from 'src/rtk/features/setup/facultyInfo/facultyInfoApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import { CommentSectionModal } from "../CommentSectionForProtocol/CommentSectionModal";
import ChangeRequestModal from '../TitleEntry/ChangeRequestModal';
import RichTextEditor from "./RichTextEditor";
import * as url from 'src/helpers/url_helper';

const BasicInformation = (props: any) => {

  const {
    existingProtocol,
    title,
    setObjectivesRichtexts,
    objectivesRichtexts,
    formik,
    commentsDataList,
    initialContentEditor,
    setInitialContentEditor,
    formikField,
    contentValidationMessage,
    setContentValidationMessage,
    renderKey,
    personalProfile,
  } = props;
  // const [selectedFaculty, setSelectedFaculty] = useState<any>('');
  // const [selectedSubject, setSelectedSubject] = useState<any>('');
  const [showCommentSectionModal, setShowCommentSectionModal] = useState<boolean>(false);

  const { data: facultyList, isError: isFacultyListError } = useGetFacultyInfosQuery();
  const { data: subjectList, isError: isSubjectListError } = useGetSubjectInfosQuery();

  const subject: SubjectInfo = subjectList?.data?.find((s: SubjectInfo, index: number) => s.id === personalProfile?.data?.details?.subject?.id)!;
  const faculty: FacultyInfo = facultyList?.data?.find((f: FacultyInfo, index: number) => f.id === subject?.setup_faculty_info_id)!;

  const [messages, setMessage] = useState<any>([]);
  const [viewTitleInfo, setViewTitleInfo] = useState<any>('');
  const [changeRequestModalShow, setChangeRequestModalShow] = useState<boolean>(false);

  const [onlyPlaceOfStudyChange, setOnlyPlaceOfStudyChange] = useState<boolean>(false);
  const [onlyStudyPeriodChange, setOnlyStudyPeriodChange] = useState<boolean>(false);
  const [onlyTitleOfTheThesisChange, setOnlyTitleOfTheThesisChange] = useState<boolean>(false);

  // const allFaculties: SelectOption[] =
  //     facultyList?.data?.map((faculty: FacultyInfo) => ({
  //         value: faculty.id!,
  //         label: faculty.faculty_name!,
  //     })) || [];

  // const allSubjects: SelectOption[] =
  //     subjectList?.data?.
  //         filter((subject: SubjectInfo) => { return subject.setup_faculty_info_id === selectedFaculty?.value })?.
  //         map((subject: SubjectInfo) => ({
  //             value: subject.subject_name!,
  //             label: subject.subject_name!,
  //         })) || [];

  const {
    handleSubmit,
    getFieldProps,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    resetForm,
    setValues,
    setErrors,
    setFieldValue,
  } = formik;

  // console.log('existingProtocol', existingProtocol);

  const getCommentsData = (id: any, nameOfTheField: any) => {
    commentsDataList(`${url.THESIS_DISSERTATION_PROTOCOL_COMMENT_LIST}/${id}/${nameOfTheField}`).then(function (result: any,) {
      // here you can use the result of promiseB
      if (result?.data?.data) {
        setMessage(result?.data?.data);
      }
    });

    // setMessage(commentsData?.data || []);
  };

  useEffect(() => {
    getCommentsData(existingProtocol?.data?.id, 'summary');
  }, []);

  useEffect(() => {
    setFieldValue('relevant_faculty', faculty?.faculty_name);
  }, []);

  useEffect(() => {
    setFieldValue('relevant_discipline', subject?.subject_name);
  }, []);

  const modalShowHandler = (modalState: boolean, data: any) => {
    setChangeRequestModalShow(modalState);
    setViewTitleInfo(data);
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <div className='thesis-basic-info'>
            <div>
              <ol>
                {(existingProtocol?.data?.protocol_submission_date ?
                  <li>Protocol submission date: <b>{existingProtocol?.data?.protocol_submission_date}</b></li>
                  :
                  <li>Protocol submission date: <b>Not yet submitted</b></li>)
                }

                <li> Relevant:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li><b>Faculty: </b> {faculty?.faculty_name ?? 'N/A'}</li>
                    <li><b>Discipline: </b> {subject?.subject_name ?? 'N/A'}</li>

                  </ol>
                </li>
                <li>Researcher Name: {title?.data?.trainee_name || 'N/A'}</li>
              </ol>
            </div>
            <div>
              <ol start={4}>

                <li>Correspondence of the examinee and contact
                  {title?.data ? (
                    <ol style={{ listStyleType: 'lower-alpha' }}>
                      <li>
                        <b>Address: </b> {title?.data?.registration?.address_infos[1]?.address_line_1} , {title?.data?.registration?.address_infos[1]?.police_station}, {title?.data?.registration?.address_infos[1]?.district}.
                      </li>
                      <li><b>Phone: </b> {title?.data?.registration?.personal_info?.mobile}</li>
                      <li><b>E-mail: </b> {title?.data?.registration?.personal_info?.email}</li>
                    </ol>
                  ) : (
                    <ol style={{ listStyleType: 'lower-alpha' }}>
                      <li><b>Address: </b> N/A</li>
                      <li><b>Phone: </b> N/A</li>
                      <li><b>E-mail: </b> N/A</li>
                    </ol>
                  )}
                  {/* <li>Address: {title?.data?.registration?.address_info[1]?.address_line_1} , {title?.data?.registration?.address_info[1]?.police_station}, {title?.data?.registration?.address_info[1]?.district}.</li>
                        <li>Phone Number: 017XXXXXXXX</li>
                        <li>E-mail Address: fcpstrainee@gmail.com</li> */}
                </li>
              </ol>
            </div>
            <div>
              <ol start={7}>
                {title?.data ? (
                  <li>Place of Study: <b>{title?.data?.place_of_study}</b>
                    {(title?.data?.admin_approval_status?.toLowerCase() === 'approve' || title?.data?.guide_approval_status?.toLowerCase() === 'approve') &&
                      <Button
                        type='button'
                        color='secondary'
                        className='btn btn-sm ms-2 mb-1'
                        outline
                        onClick={() => {
                          modalShowHandler(true, title?.data);
                          setOnlyPlaceOfStudyChange(true);
                          setOnlyStudyPeriodChange(false);
                          setOnlyTitleOfTheThesisChange(false);
                        }}
                      >
                        Change Request
                      </Button>}
                  </li>) : (<li>Place of Study: <b>N/A</b></li>)}

                {title?.data ? (
                  <li>Study Period: <b>{title?.data?.study_period} {title?.data?.study_period_unit} </b>
                    {(title?.data?.admin_approval_status?.toLowerCase() === 'approve' || title?.data?.guide_approval_status?.toLowerCase() === 'approve') &&
                      <Button
                        type='button'
                        color='secondary'
                        className='btn btn-sm ms-2'
                        outline
                        onClick={() => {
                          modalShowHandler(true, title?.data);
                          setOnlyStudyPeriodChange(true);
                          setOnlyPlaceOfStudyChange(false);
                          setOnlyTitleOfTheThesisChange(false);
                        }}
                      >
                        Change Request
                      </Button>}
                  </li>) : (
                  <li>Study Period: <b>N/A</b></li>)}
              </ol>
            </div>
            <div className='thesis-title-col'>
              <ol start={5}>
                {title?.data ? (<li>Title of the Thesis: <b>{title?.data?.title_of_thesis}</b>
                  {(title?.data?.admin_approval_status?.toLowerCase() === 'approve' || title?.data?.guide_approval_status?.toLowerCase() === 'approve') &&
                    <Button
                      type='button'
                      color='secondary'
                      className='btn btn-sm ms-2'
                      outline
                      onClick={() => {
                        modalShowHandler(true, title?.data);
                        setOnlyTitleOfTheThesisChange(true);
                        setOnlyPlaceOfStudyChange(false);
                        setOnlyStudyPeriodChange(false);
                      }}
                    >
                      Change Request
                    </Button>}
                </li>)
                  : (
                    <li>Title of the Thesis: <b>N/A</b>
                    </li>)}
              </ol>

            </div>
          </div>
        </Col>
      </Row>
      <Row className='justify-content-between align-items-center py-3'>
        <Col sm={3}>
          <p className="ms-3 mb-2"><b>6. Summary</b></p>
        </Col>
        <Col sm="auto" className='btn noti-icon position-relative'>
          <span className="badge bg-danger rounded-pill">{messages?.length ?? 0}</span>
          <Button
            type='button'
            className='btn-sm'
            onClick={(e) => {
              setShowCommentSectionModal(!showCommentSectionModal);
            }}
          >
            <i className='fas fa-2x fa-comments'></i>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>

          {/* <p className="required-field text-capitalize" ><b>6. Summary</b></p> */}

          <RichTextEditor
            setObjectivesRichtexts={setObjectivesRichtexts}
            objectivesRichtexts={objectivesRichtexts}
            formikState={formik.values}
            initialContentEditor={initialContentEditor}
            setInitialContentEditor={setInitialContentEditor}
            formikField={formikField}
            contentValidationMessage={contentValidationMessage}
            setContentValidationMessage={setContentValidationMessage}
            key={renderKey}
          />

          {/* <InputField
                            type="textarea"
                            className="textarea-protocol-entry"
                            placeholder="Summary"
                            invalid={{ errors, touched }}
                            {...getFieldProps('summary')}
                        /> */}

          {showCommentSectionModal ?
            <CommentSectionModal
              fieldName={'summary'}
              fieldLabel={'Summary'}
              existingProtocol={existingProtocol}
              showModal={showCommentSectionModal}
              setShowModal={setShowCommentSectionModal}
              setMessageCount={setMessage}
            />
            :
            null
          }
        </Col>
      </Row>
      <Modal isOpen={changeRequestModalShow} size={'lg'} toggle={() => setChangeRequestModalShow(!changeRequestModalShow)}>
        <ModalHeader toggle={() => setChangeRequestModalShow(!changeRequestModalShow)}>Change Information</ModalHeader>
        <ModalBody>
          <ChangeRequestModal
            changeInfo={viewTitleInfo}
            onlyPlaceOfStudyChange={onlyPlaceOfStudyChange}
            setOnlyPlaceOfStudyChange={setOnlyPlaceOfStudyChange}
            onlyStudyPeriodChange={onlyStudyPeriodChange}
            setOnlyStudyPeriodChange={setOnlyStudyPeriodChange}
            onlyTitleOfTheThesisChange={onlyTitleOfTheThesisChange}
            setOnlyTitleOfTheThesisChange={setOnlyTitleOfTheThesisChange}
          />
        </ModalBody>
      </Modal>
    </>


  );

};
export default BasicInformation;
