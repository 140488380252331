import { useState } from 'react';
import { Table } from 'reactstrap';
import { useGetThesisExamScheduleListForDashboardQuery } from 'src/rtk/features/thesis-exam-dashboard/thesisExamDashboardApi';
import ThesisExamScheduleListPaginate from './ThesisExamScheduleListPaginate';
import ThesisExamScheduleListTableRow from './ThesisExamScheduleListTableRow';

const ThesisExamScheduleList = () => {

    const [page, setPage] = useState<number | string>('1');
    const [paginate_per_page, set_paginate_per_page] = useState(20);
    const [search, setSearch] = useState('');

    const {
        data: thesisExamScheduleListData,
        isSuccess: isThesisExamScheduleListDataSuccess,
        isError: isThesisExamScheduleListDataError,
        isLoading: isThesisExamScheduleListDataLoading,
        error: thesisExamScheduleListDataError,
    } = useGetThesisExamScheduleListForDashboardQuery();

    const thesisExamScheduleList: any = thesisExamScheduleListData?.data?.data || [];
    const err: CustomError = thesisExamScheduleListDataError as CustomError;

    // what to render
    let content = null;

    if (isThesisExamScheduleListDataLoading) {
        content = <div className="mt-3">Loading...</div>;
    }
    if (!isThesisExamScheduleListDataLoading && isThesisExamScheduleListDataError && thesisExamScheduleListDataError) {
        content = <div className="mt-3">{err.message}</div>;
    }
    if (!isThesisExamScheduleListDataLoading && !isThesisExamScheduleListDataError && thesisExamScheduleList?.length > 0) {

        let contentCopy: any = [];

        thesisExamScheduleList?.forEach((thesisExam: any, idx: number) => {
            contentCopy.push(<ThesisExamScheduleListTableRow thesisExam={thesisExam} index={idx} />);
        });

        content = contentCopy;
    }

    //   const { handleSubmit, getFieldProps, values, touched, errors, resetForm, setErrors } =
    //     useFormik({
    //       initialValues,

    //       onSubmit: (values) => {

    //         let s: string = '';

    //         if (values.general_search_term !== '') {
    //           s = s + `&search=${values.general_search_term}`;
    //         }

    //         setSearch(s);
    //         setPage('1');
    //         set_paginate_per_page(20);
    //       },
    //     });

    return (
        <>
            <div className="table-responsive">
                <Table className="table table-bordered text-center table-sm">
                    <thead className="table-secondary">
                        <tr>
                            <th>Sl. No.</th>
                            <th>Subject</th>
                            <th>Exam Date</th>
                            <th>Time</th>
                            <th>Building</th>
                            <th>Room No.</th>
                            <th>Table No.</th>
                            <th>Old Candidate</th>
                            <th>New Candidate</th>
                            <th>Total Number of Candidate</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>{content}</tbody>
                </Table>
            </div>
            {!isThesisExamScheduleListDataLoading && <ThesisExamScheduleListPaginate data={thesisExamScheduleListData} setPage={setPage} setPaginatePerPage={set_paginate_per_page} />}
        </>
    );
};

export default ThesisExamScheduleList;
