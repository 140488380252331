import { useSelector } from 'react-redux';
import * as permissions from './permissions_db';
const useCheckPermissions = (moduleName = 'TRAINEE_LOGBOOK') => {
  const { menu_permissions } = useSelector((state: any) => ({
    menu_permissions: state.auth.menu_permissions,
  }));

  let retPermissions: any = {};
  const havePermissions = permissions[moduleName as keyof {}];

  if (menu_permissions && menu_permissions.length > 0) {
    Object.keys(havePermissions).filter((item: any) => {
      if (menu_permissions.includes(havePermissions[item]))
        retPermissions[item] = havePermissions[item];
    });
  }

  // return retPermissions;
  return havePermissions;
};

export default useCheckPermissions;
