import { Col, Row } from "reactstrap";


const ExaminerSignature = (props: any) => {
    const { thesisEntryListForTrainee } = props;

    return (
        <Row>
            <Col xs={6}>
                <p className="fw-bold text-center">Signature of Examiner 1</p>
                <p className="text-center mt-3"><strong>Name: </strong> {thesisEntryListForTrainee?.data?.title?.thesis_file?.examiners?.[0]?.examiner_name ?? ''}</p>
            </Col>
            <Col xs={6}>
                <p className="fw-bold text-center">Signature of Examiner 2</p>
                <p className="text-center mt-3"><strong>Name: </strong> {thesisEntryListForTrainee?.data?.title?.thesis_file?.examiners?.[1]?.examiner_name ?? ''}</p>
            </Col>

        </Row>
    )
};

export default ExaminerSignature;
