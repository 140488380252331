//import Breadcrumbs
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Container, Form, FormGroup, Input, Label } from 'reactstrap';
import { Button, InputField } from 'src/components';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { setFromErrors } from 'src/helpers';
import { useCreateOrganizationMutation } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';

const initialValues: Organization = {
  id: undefined,
  organization_name: '',
  organization_name_bangla: undefined,
  organization_type: undefined,
  organization_slogan: undefined,
  address1: undefined,
  address2: undefined,
  district: undefined,
  division: undefined,
  country: undefined,
  telephone1: undefined,
  telephone2: undefined,
  email: undefined,
  website: undefined,
  logo: undefined,
  organization_parent_id: undefined,
  status: false,
  creator_organization_id: undefined,
  updater_organization_id: undefined,
  created_by: undefined,
  updated_by: undefined,
  deleted_by: undefined,
  created_at: undefined,
  updated_at: undefined,
  deleted_at: undefined,
};

const AddOrganization = () => {
  const [createOrganization, { isSuccess, isError, error }] =
    useCreateOrganizationMutation();
  const err: CustomError = error as CustomError;
  const history = useHistory();
  const buttonRef = useRef<any>();

  const { data: divisionLookupData } = useGetLookupDataQuery('division');
  const division: Lookup[] =
    (divisionLookupData as any)?.data[0]?.childs || [];  

  const [district, setDistrict] = useState<Lookup[]>([]); 

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
    setErrors,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      organization_name: Yup.string().required(
        'Organization Name is Required!',
      ),
      email: Yup.string().email('Email is invalid!'),
    }),
    onSubmit: (values) => {
      buttonRef.current.disabled = true;
      createOrganization(values);
      console.log({ values });
    },
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success('Organization created successfully');
      resetForm();
      buttonRef.current.disabled = false;
      history.push('/setup/organizations');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      buttonRef.current.disabled = false;
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  const localStateHandler = async (
    setValueFrom: Lookup[],
    setValueTo: any,
    selectedValue: string,
  ) => {
    const selectedParent = setValueFrom?.find(
      (item) => item.name === selectedValue,
    );
    setValueTo(selectedParent?.childs as any);
  };

  const handleDivision = (e: any) => {
    const { value } = e.target;
    localStateHandler(division, setDistrict, value);    
    setFieldValue('division', value);
  };
  
  const handleDistrict = (e: any) => {
    const { value } = e.target;    
    setFieldValue('district', value);
  };

  const selectOptionHandler = (optionList: Lookup[], selectedOpt: any) => {
    return (
      <>
        {selectedOpt
          ? optionList?.map((option) => (
              <option
                key={option.id}
                selected={option.name === selectedOpt}
                value={option.name}
              >
                {option.name}
              </option>
            ))
          : optionList?.map((option) => (
              <option key={option.id} value={option.name}>
                {option.name}
              </option>
            ))}
      </>
    );
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Organization" breadcrumbItem="Organization" />
        <div className="row">
          <div className="card mt-3">
            <h5 className="card-header">
              <i className="fa fa-plus me-2" />
              Add Organization
            </h5>
            <div className="card-body">
              <Form
                className="custom-form mt-4 pt-2"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="row">
                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Organization Name</Label>
                      <InputField
                        name="organization_name"
                        type="text"
                        placeholder="Enter Name"
                        value={values.organization_name}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">
                        Organization Name (Bangla)
                      </Label>
                      <InputField
                        name="organization_name_bangla"
                        type="text"
                        placeholder="Enter Name"
                        value={values.organization_name_bangla}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Organization Type</Label>
                      <InputField
                        name="organization_type"
                        type="text"
                        placeholder="Enter Organization Type"
                        value={values.organization_type}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Organization Slogan</Label>
                      <InputField
                        name="organization_slogan"
                        type="text"
                        placeholder="Enter Organization Slogan"
                        value={values.organization_slogan}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Address 1</Label>
                      <InputField
                        name="address1"
                        type="text"
                        placeholder="Enter Address 1"
                        value={values.address1}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Address 2</Label>
                      <InputField
                        name="address2"
                        type="text"
                        placeholder="Enter Address 2"
                        value={values.address2}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label for="division">Division</Label>
                      <select
                        className="form-select"
                        onChange={handleDivision}
                        name="division"
                      >
                        <option> Select Division</option>
                        {selectOptionHandler(
                          division,
                          values.division
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                    <Label for="permanent_district">District</Label>
                    <select
                      className="form-select"
                      onChange={handleDistrict}
                      name="district"
                    >
                      <option value="">Select District</option>
                      {selectOptionHandler(
                        district,
                        values.district,
                      )}
                    </select>
                    </div>
                  </div>                  

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Country</Label>
                      <InputField
                        name="country"
                        type="text"
                        placeholder="Enter Country"
                        value={values.country}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Telephone 1</Label>
                      <InputField
                        name="telephone1"
                        type="text"
                        placeholder="Enter Phone Number"
                        value={values.telephone1}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Telephone 2</Label>
                      <InputField
                        name="telephone2"
                        type="text"
                        placeholder="Enter Phone Number"
                        value={values.telephone2}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Email</Label>
                      <InputField
                        name="email"
                        type="email"
                        placeholder="Enter Email"
                        value={values.email}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Website</Label>
                      <InputField
                        name="website"
                        type="text"
                        placeholder="Enter Website URL"
                        value={values.website}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3">
                      <Label className="form-label">Logo</Label>
                      <InputField
                        name="logo"
                        type="text"
                        placeholder="Enter Logo URL"
                        value={values.logo}
                        onChange={handleChange}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className="mb-3 mt-4">
                      <FormGroup check className="mb-3">
                        <Label check>
                          <Input
                            type="checkbox"
                            name="status"
                            checked={values.status}
                            onChange={handleChange}
                          />{' '}
                          Status
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                </div>

                <button className="btn btn-primary" type="submit" ref={buttonRef} >Submit</button>
              </Form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AddOrganization;
