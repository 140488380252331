import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { setFromErrors } from 'src/helpers';

import { useGetLogBookMasterSetupsQuery } from 'src/rtk/features/setup/logBookMasterSetup/logBookMasterSetupApi';

import { useCreateLogBookEntryMasterMutation } from 'src/rtk/features/setup/logBookEntryMaster/logBookEntryMasterApi';

import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';

import * as Yup from 'yup';

const initialValues: LogBookEntryMasters = {
  setup_logbook_master_id: '',
  exam_name: '',
  subject_id: '',
  subject_name: '',
  logbook_type: '',
  signature_trainee: '',
  signature_of_director_rtmd: '',
  approve_status: '',
  serial_no: 0,
  completion_ind: false,
  date_of_enrollment_with_rtmd: '',
  date_of_commencement_of_sst: '',
  status: false,
};

export const AddLogBookEntryMaster = () => {
  const history = useHistory();
  const [createLogBookEntryMaster, { isSuccess, isError, error }] =
    useCreateLogBookEntryMasterMutation();

  const { data: getLogBookMasterSetupInfo } = useGetLogBookMasterSetupsQuery();
  const LogBookMasterSetupList = getLogBookMasterSetupInfo?.data;

  const { data: getSubjectInfolist } = useGetSubjectInfosQuery();
  const SubjectList = getSubjectInfolist?.data;

  const err: CustomError = error as CustomError;
  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    resetForm,
    setErrors,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      setup_logbook_master_id: Yup.string().required(
        'Log Book Master  required',
      ),
      exam_name: Yup.string().required('Log book heading is required'),
      subject_id: Yup.string().required('Subject name is required'),
    }),

    onSubmit: (values) => {
      createLogBookEntryMaster(values);
      console.log(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Log Book Master created successfully');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  const handleSubject = (e: any) => {
    const { options, selectedIndex, value } = e.target;
    // console.log(options[selectedIndex].innerHTML, value);
    setFieldValue('subject_id', value);
    setFieldValue('subject_name', options[selectedIndex].innerHTML);
  };
  const handleLogBookMasterSetup = (e: any) => {
    const { options, selectedIndex, value } = e.target;
    // console.log(options[selectedIndex].innerHTML, value);
    setFieldValue('registration_id', 'as required');
    setFieldValue('setup_logbook_master_id', value);
    //setFieldValue('subject_name', options[selectedIndex].innerHTML);
  };
  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title="Log Book Entry Master"
          breadcrumbItem={[
            {
              link: '/log/book/entry/master',
              name: 'Log Book Entry Master List',
            },
          ]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="col-md-9 col-sm-9 ">
            <div className="card mt-3">
              <h5 className="card-header">
                <i className="fa fa-plus me-2" />
                Add New
              </h5>
              <div className="card-body">
                <Form
                  autoComplete="off"
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <Row>
                    <FormGroup className="col-lg-6 mb-3">
                      <Label for="setup_logbook_master_id">
                        Log Book Master Setup
                      </Label>
                      <Input
                        id="setup_logbook_master_id"
                        name="setup_logbook_master_id"
                        type="select"
                        onChange={(e) => handleLogBookMasterSetup(e)}
                      >
                        <option value="">Select..</option>
                        {LogBookMasterSetupList?.map((sub) => (
                          <option key={sub.id} value={sub.id}>
                            {sub.exam_name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>

                    <FormGroup className="col-md-6 mb-3">
                      <Label for="exam_name">Subject Name</Label>
                      <Input
                        id="subject_id"
                        name="subject_id"
                        type="select"
                        onChange={(e) => handleSubject(e)}
                      >
                        <option value="">Select..</option>
                        {SubjectList?.map((sub) => (
                          <option key={sub.id} value={sub.id}>
                            {sub.subject_name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>

                    <FormGroup className="col-md-6 mb-3">
                      <Label for="exam_name">Exam Name</Label>
                      <InputField
                        type="text"
                        placeholder="Exam Name"
                        invalid={{ errors, touched }}
                        {...getFieldProps('exam_name')}
                      />
                    </FormGroup>

                    <FormGroup className="col-md-6 mb-3">
                      <Label for="signature_trainee">Signature Trainee</Label>
                      <InputField
                        type="text"
                        placeholder="Signature Trainee"
                        invalid={{ errors, touched }}
                        {...getFieldProps('signature_trainee')}
                      />
                    </FormGroup>

                    <FormGroup className="col-md-6 mb-3">
                      <Label for="signature_of_director_rtmd">
                        Signature of Director RTMD
                      </Label>
                      <InputField
                        type="text"
                        placeholder="Signature of Director rtmd"
                        invalid={{ errors, touched }}
                        {...getFieldProps('signature_of_director_rtmd')}
                      />
                    </FormGroup>

                    <FormGroup className="col-md-6 mb-3">
                      <Label for="approve_status">Approval Status</Label>
                      <InputField
                        type="text"
                        placeholder="Approval Status"
                        invalid={{ errors, touched }}
                        {...getFieldProps('approve_status')}
                      />
                    </FormGroup>

                    <FormGroup className="col-md-6 mb-3">
                      <Label for="serial_no">Serial No</Label>
                      <InputField
                        type="text"
                        placeholder="Approval Status"
                        invalid={{ errors, touched }}
                        {...getFieldProps('serial_no')}
                      />
                    </FormGroup>

                    <FormGroup className="col-md-6 mb-3">
                      <Label for="date_of_enrollment_with_rtmd">
                        Date of Enrollment With RTMD
                      </Label>
                      <InputField
                        type="date"
                        placeholder="Date of Enrollment With RTMD"
                        invalid={{ errors, touched }}
                        {...getFieldProps('date_of_enrollment_with_rtmd')}
                      />
                    </FormGroup>

                    <FormGroup className="col-md-6 mb-3">
                      <Label for="date_of_commencement_of_sst">
                        Date of Commencement of SST
                      </Label>
                      <InputField
                        type="date"
                        placeholder="Date of Commencement of SST"
                        invalid={{ errors, touched }}
                        {...getFieldProps('date_of_commencement_of_sst')}
                      />
                    </FormGroup>

                    <div className="col-md-3 col-sm-6 ">
                      <h6>Completion Indicator</h6>
                      <FormGroup check className="mb-3">
                        <Input
                          type="checkbox"
                          role="check"
                          {...getFieldProps('completion_ind')}
                        />
                        <Label check>Active</Label>
                      </FormGroup>
                    </div>

                    <div className="col-md-3 col-sm-6 ">
                      <h6>Active Status</h6>
                      <FormGroup check className="mb-3">
                        <Input
                          type="checkbox"
                          role="check"
                          {...getFieldProps('status')}
                        />
                        <Label check>Active</Label>
                      </FormGroup>
                    </div>

                    <div className="text-center">
                      <Button
                        className="w-25"
                        outline
                        color="secondary"
                        type="button"
                        text="Cancel"
                        onClick={() => {
                          history.push('/log/book/master/setup');
                        }}
                      />
                      <Button
                        className="w-25 ms-3"
                        type="submit"
                        text="Submit"
                      />
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
