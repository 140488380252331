import { Container } from 'reactstrap';
import { DynamicTable } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import formObj from './formObj';

const DemoPage = () => {
  if (formObj?.title) {
    document.title = formObj?.title + ' | BCPS';
  }
  return (
    <>
      <div className="page-content">
        <Container fluid>
          {formObj.breadcrumbItemForList && (
            <BetterBreadcrumb
              title={formObj?.title + ' List'}
              breadcrumbItem={formObj.breadcrumbItemForList}
            />
          )}
          <DynamicTable formObj={formObj} />
        </Container>
      </div>
    </>
  );
};

export default DemoPage;
