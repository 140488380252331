import React from 'react';

export const allCapabilities = (
    permissionWithChecked: PermissionWithChecked[]
) => {
    const selectedPermission = permissionWithChecked.map((permission) => {
        const capabilities = permission.capabilities
            .filter((capability) => capability.isChecked)
            .map((capability) => capability.name);
        return capabilities;
    });
    return selectedPermission.flat();
};

export const handleSingleCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    permissionWithChecked: PermissionWithChecked[]
) => {
    const { checked, name } = e.target;

    const newPermissionWithChecked = permissionWithChecked.map((permission) => {
        const _permission = { ...permission };
        _permission.capabilities = permission.capabilities.map((capability) => {
            if (capability.name === name) {
                const _capability = { ...capability };
                _capability.isChecked = checked;
                return _capability;
            }
            return capability;
        });
        return _permission;
    });

    return newPermissionWithChecked;
};

export const handleSelectAllCheckbox = (
    _name: string,
    e: React.ChangeEvent<HTMLInputElement>,
    permissionWithChecked: PermissionWithChecked[]
) => {
    const { checked } = e.target;

    const newPermissionWithChecked = permissionWithChecked.map((permission) => {
        const _permission = { ...permission };
        if (permission.name === _name) {
            _permission.isChecked = checked;
            _permission.capabilities = permission.capabilities.map(
                (capability) => {
                    const _capability = { ...capability };
                    _capability.isChecked = checked;
                    return _capability;
                }
            );
        }
        return _permission;
    });

    return newPermissionWithChecked;
};
