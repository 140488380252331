import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button as BsButton, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Modal, ModalBody, Row } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import { setFromErrors } from 'src/helpers';
import { useDeleteLibraryServiceMutation, useDeleteSupportServiceMutation, useDeleteTeachingInfrastructureMutation, useGetDepartmentByIdQuery, useGetLibraryServiceListQuery, useGetPartDMasterInfoEntryQuery, useGetSupportServiceListQuery, useGetTeachingInfrastructureListQuery, usePartDMasterInfoEntryMutation } from 'src/rtk/features/instituteAndDepartmentRegistration/instituteAndDepartmentRegistrationApi';
import * as Yup from 'yup';
import BooksJournalsPeriodicalsModal from './BooksJournalsPeriodicalsModal';
import DepartmentAndServiceForClinicalDepartmentsOnlyModal from './DepartmentAndServiceForClinicalDepartmentsOnlyModal';
import TeachingInfrastructureModal from './TeachingInfrastructureModal';

const initialValues: PartDMasterInfoEntry = {
    part_d_master_info_id: '',
    form_b_department_id: '',
    number_of_seat_in_library: 0,
    service_hour: 0,
    electronic_version_of_book: 0,
    student_access_to_internet: 0,
    link_with_other_library: 0,
    access_to_electronic_journal: 0,
    link_to_who_website: 0,
    submission_status: '',
};

const InstituteDeptRegistrationStepSix = (props: any) => {
    const { goToPrevious, goToNext, instituteName, departmentId, formBDeptId, isDisabled, renewDepartment } = props;
    const history = useHistory();
    const [submitStatus, setSubmitStatus] = useState<boolean>(true);
    const [showLibraryServiceModal, setShowLibraryServiceModal] = useState<boolean>(false);
    const [showSupportServiceModal, setShowSupportServiceModal] = useState<boolean>(false);
    const [showTeachingInfrastructureModal, setShowTeachingInfrastructureModal] = useState<boolean>(false);
    const [showLibraryServiceRecordViewModal, setShowLibraryServiceRecordViewModal] = useState<boolean>(false);
    const [showSupportServiceRecordViewModal, setShowSupportServiceRecordViewModal] = useState<boolean>(false);
    const [showTeachingInfrastructureRecordViewModal, setShowTeachingInfrastructureRecordViewModal] = useState<boolean>(false);
    const [libraryServiceRecordInModal, setLibraryServiceRecordInModal] = useState<LibraryServiceList>({} as LibraryServiceList);
    const [supportServiceRecordInModal, setSupportServiceRecordInModal] = useState<SupportServiceList>({} as SupportServiceList);
    const [teachingInfrastructureRecordInModal, setTeachingInfrastructureRecordInModal] = useState<TeachingInfrastructureList>({} as TeachingInfrastructureList);
    const [recordOfLibraryServiceForDelete, setRecordOfLibraryServiceForDelete] = useState<LibraryServiceList>({} as LibraryServiceList);
    const [recordOfLibraryServiceDeleteModalShow, setRecordOfLibraryServiceDeleteModalShow] = useState<boolean>(false);
    const [recordOfLibraryServiceDeleteEntry, setRecordOfLibraryServiceDeleteEntry] = useState<boolean>(false);
    const [recordOfSupportServiceForDelete, setRecordOfSupportServiceForDelete] = useState<SupportServiceList>({} as SupportServiceList);
    const [recordOfSupportServiceDeleteModalShow, setRecordOfSupportServiceDeleteModalShow] = useState<boolean>(false);
    const [recordOfSupportServiceDeleteEntry, setRecordOfSupportServiceDeleteEntry] = useState<boolean>(false);
    const [recordOfTeachingInfrastructureForDelete, setRecordOfTeachingInfrastructureForDelete] = useState<TeachingInfrastructureList>({} as TeachingInfrastructureList);
    const [recordOfTeachingInfrastructureDeleteModalShow, setRecordOfTeachingInfrastructureDeleteModalShow] = useState<boolean>(false);
    const [recordOfTeachingInfrastructureDeleteEntry, setRecordOfTeachingInfrastructureDeleteEntry] = useState<boolean>(false);
    const [editRecordOfSupportService, setEditRecordOfSupportService] = useState<boolean>(false);
    const [recordOfSupportServiceToEdit, setRecordOfSupportServiceToEdit] = useState<SupportServiceList>({} as SupportServiceList);
    const [editRecordOfLibraryService, setEditRecordOfLibraryService] = useState<boolean>(false);
    const [recordOfLibraryServiceToEdit, setRecordOfLibraryServiceToEdit] = useState<LibraryServiceList>({} as LibraryServiceList);
    const [editRecordOfTeachingInfrastructure, setEditRecordOfTeachingInfrastructure] = useState<boolean>(false);
    const [recordOfTeachingInfrastructureToEdit, setRecordOfTeachingInfrastructureToEdit] = useState<TeachingInfrastructureList>({} as TeachingInfrastructureList);

    const [partDMasterInfoEntry, { data: partDMasterInfoData, isSuccess: isPartDMasterInfoDataSuccess, isLoading: isPartDMasterInfoDataLoading, isError: isPartDMasterInfoDataError, error: partDMasterInfoDataError }] =
        usePartDMasterInfoEntryMutation();

    const { data: partDMasterInfoGetData, isSuccess: isPartDMasterInfoGetDataSuccess, isError: isPartDMasterInfoGetDataError, error: partDMasterInfoGetDataError } = useGetPartDMasterInfoEntryQuery(departmentId);

    const { data: submittedDepartmentData, isSuccess: isSubmittedDepartmentDataSuccess, isError: isSubmittedDepartmentDataError, error: submittedDepartmentDataError } = useGetDepartmentByIdQuery(formBDeptId);

    const { data: libraryServiceList, isSuccess: isLibraryServiceListSuccess, isError: isLibraryServiceListError, error: libraryServiceListError } = useGetLibraryServiceListQuery(departmentId);

    const { data: supportServiceList, isSuccess: isSupportServiceListSuccess, isError: isSupportServiceListError, error: supportServiceListError } = useGetSupportServiceListQuery(departmentId);

    const { data: teachingInfrastructureList, isSuccess: isTeachingInfrastructureListSuccess, isError: isTeachingInfrastructureListError, error: teachingInfrastructureListError } = useGetTeachingInfrastructureListQuery(departmentId);

    const [
        deleteLibraryServiceEntry,
        {
            data: libraryServiceEntryDelete,
            isSuccess: isLibraryServiceEntryDeleteSuccess,
            isError: isLibraryServiceEntryDeleteError,
            error: libraryServiceEntryDeleteError,
        },
    ] = useDeleteLibraryServiceMutation();

    const [
        deleteSupportServiceEntry,
        {
            data: supportServiceEntryDelete,
            isSuccess: isSupportServiceEntryDeleteSuccess,
            isError: isSupportServiceEntryDeleteError,
            error: supportServiceEntryDeleteError,
        },
    ] = useDeleteSupportServiceMutation();

    const [
        deleteTeachingInfrastructureEntry,
        {
            data: teachingInfrastructureEntryDelete,
            isSuccess: isTeachingInfrastructureEntryDeleteSuccess,
            isError: isTeachingInfrastructureEntryDeleteError,
            error: teachingInfrastructureEntryDeleteError,
        },
    ] = useDeleteTeachingInfrastructureMutation();

    const handleEditSupportServiceRecord = (e: any, supportServiceRecord: SupportServiceList) => {
        setEditRecordOfSupportService(true);
        setRecordOfSupportServiceToEdit(supportServiceRecord);
        setShowSupportServiceModal(true);
    };

    const handleEditLibraryServiceRecord = (e: any, libraryServiceRecord: LibraryServiceList) => {
        setEditRecordOfLibraryService(true);
        setRecordOfLibraryServiceToEdit(libraryServiceRecord);
        setShowLibraryServiceModal(true);
    };

    const handleEditTeachingInfrastructureRecord = (e: any, teachingInfrastructureRecord: TeachingInfrastructureList) => {
        setEditRecordOfTeachingInfrastructure(true);
        setRecordOfTeachingInfrastructureToEdit(teachingInfrastructureRecord);
        setShowTeachingInfrastructureModal(true);
    };

    const handleLibraryServiceRecordView = (e: any, libraryServiceRecord: LibraryServiceList) => {
        setLibraryServiceRecordInModal(libraryServiceRecord);
        setShowLibraryServiceRecordViewModal(true);
    };

    const handleSupportServiceRecordView = (e: any, supportServiceRecord: SupportServiceList) => {
        setSupportServiceRecordInModal(supportServiceRecord);
        setShowSupportServiceRecordViewModal(true);
    };

    const handleTeachingInfrastructureRecordView = (e: any, teachingInfrastructureRecord: TeachingInfrastructureList) => {
        setTeachingInfrastructureRecordInModal(teachingInfrastructureRecord);
        setShowTeachingInfrastructureRecordViewModal(true);
    };

    const libraryServiceRecordDeleteHandler = (e: any, libraryServiceRecord: LibraryServiceList) => {
        setRecordOfLibraryServiceForDelete(libraryServiceRecord);
        setRecordOfLibraryServiceDeleteModalShow(true);
    };

    const supportServiceRecordDeleteHandler = (e: any, supportServiceRecord: SupportServiceList) => {
        setRecordOfSupportServiceForDelete(supportServiceRecord);
        setRecordOfSupportServiceDeleteModalShow(true);
    };

    const teachingInfrastructureRecordDeleteHandler = (e: any, teachingInfrastructureRecord: TeachingInfrastructureList) => {
        setRecordOfTeachingInfrastructureForDelete(teachingInfrastructureRecord);
        setRecordOfTeachingInfrastructureDeleteModalShow(true);
    };

    const err: CustomError = partDMasterInfoDataError as CustomError;

    document.title = renewDepartment === true ? 'Renew Department | BCPS' : 'Department Registration | BCPS';

    const formik = useFormik({
        initialValues,

        validationSchema: Yup.object({}),

        onSubmit: (values: PartDMasterInfoEntry) => {
            console.log(values);
            values.form_b_department_id = departmentId;

            if (submitStatus) {
                values.submission_status = 'Submit';
            } else {
                values.submission_status = 'Draft';
            }

            if (editRecordOfSupportService) {
                setEditRecordOfSupportService(false);
            }

            if (editRecordOfLibraryService) {
                setEditRecordOfLibraryService(false);
            }

            if (editRecordOfTeachingInfrastructure) {
                setEditRecordOfTeachingInfrastructure(false);
            }

            if (!showLibraryServiceRecordViewModal &&
                !showSupportServiceRecordViewModal &&
                !showTeachingInfrastructureRecordViewModal &&
                !editRecordOfSupportService &&
                !editRecordOfLibraryService &&
                !editRecordOfTeachingInfrastructure) {
                partDMasterInfoEntry(values);
            }
        },
    });

    const {
        handleSubmit,
        getFieldProps,
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        resetForm,
        setValues,
        setErrors,
        setFieldValue,
    } = formik;

    useEffect(() => {
        if (partDMasterInfoGetData?.data?.form_b_department_id && !isPartDMasterInfoGetDataError) {
            const partDMasterInfo: GetPartDMasterInfo = partDMasterInfoGetData?.data!;
            setValues({
                part_d_master_info_id: partDMasterInfo?.id,
                form_b_department_id: partDMasterInfo?.form_b_department_id,
                number_of_seat_in_library: partDMasterInfo?.number_of_seat_in_library,
                service_hour: +partDMasterInfo?.service_hour!,
                electronic_version_of_book: partDMasterInfo?.electronic_version_of_book,
                student_access_to_internet: partDMasterInfo?.student_access_to_internet,
                link_with_other_library: partDMasterInfo?.link_with_other_library,
                access_to_electronic_journal: partDMasterInfo?.access_to_electronic_journal,
                link_to_who_website: partDMasterInfo?.link_to_who_website,
            });
        }
    }, [partDMasterInfoGetData, isPartDMasterInfoGetDataError]);

    useEffect(() => {
        if (isPartDMasterInfoDataSuccess) {
            handleFormReset();
            toast.success('Department information saved successfully');
            goToNext();
            setSubmitStatus(false);
        }
        if (isPartDMasterInfoDataError) {
            toast.error(err?.message || 'Something went wrong');
            setFromErrors(err.data, setErrors);
            setSubmitStatus(false);
        }
    }, [isPartDMasterInfoDataSuccess, isPartDMasterInfoDataError]);

    const departmentAndServiceColumns: TableColumn<SupportServiceList>[] = [
        {
            name: <div className="text-center">Faculty</div>,
            selector: (row) => row.department_name!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center"># Academic Staff Available</div>,
            selector: (row) => row.no_of_academic_staff!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center"># Support Staff (Technologist)</div>,
            selector: (row) => row.no_of_support_staff!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Essential Equipment (Provide List)</div>,
            selector: (row) => row.equipment_list!,
            cell: (row) => row.equipment_list ? <a
                style={{ pointerEvents: 'auto' }}
                className="me-2 p-0 center"
                href={`${config?.APP_URL}${row.equipment_list!}`}
                rel="noreferrer"
                target="_blank"
            >
                <span className="btn-outline-info btn-sm btn ms-2">View</span>
            </a> : null,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Service Offered to Department</div>,
            selector: (row) => row.service_offered!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Action</div>,
            width: '200px',
            cell: (row) => (
                <>
                    <FormGroup className="btn-group mt-2">
                        <button
                            className="btn btn-sm btn-secondary"
                            onClick={(e) => {
                                handleEditSupportServiceRecord(e, row);
                            }}
                        >
                            <span className="fas fa-edit"></span> Edit
                        </button>
                        <button
                            className="btn btn-sm btn-info"
                            style={{ pointerEvents: 'auto' }}
                            onClick={(e) => {
                                handleSupportServiceRecordView(e, row);
                            }}
                        >
                            <span className="fas fa-eye"></span> View
                        </button>
                        {
                            <Button
                                size="sm"
                                color="danger"
                                type="button"
                                text={`<span class="fas fa-trash" ></span> Delete`}
                                onClick={(e) => {
                                    supportServiceRecordDeleteHandler(e, row);
                                }}
                            />
                        }
                    </FormGroup>
                </>
            ),

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const booksJournalsPeriodicalsColumns: TableColumn<LibraryServiceList>[] = [

        {
            name: <div className="text-center">Type</div>,
            selector: (row) => row.category!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Title</div>,
            selector: (row) => row.titles!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Total Books</div>,
            selector: (row) => row.total_books!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Action</div>,
            width: '200px',
            cell: (row) => (
                <>
                    <FormGroup className="btn-group mt-2">
                        <button
                            className="btn btn-sm btn-secondary"
                            onClick={(e) => {
                                handleEditLibraryServiceRecord(e, row);
                            }}
                        >
                            <span className="fas fa-edit"></span> Edit
                        </button>
                        <button
                            className="btn btn-sm btn-info"
                            style={{ pointerEvents: 'auto' }}
                            onClick={(e) => {
                                handleLibraryServiceRecordView(e, row);
                            }}
                        >
                            <span className="fas fa-eye"></span> View
                        </button>
                        {
                            <Button
                                size="sm"
                                color="danger"
                                type="button"
                                text={`<span class="fas fa-trash" ></span> Delete`}
                                onClick={(e) => {
                                    libraryServiceRecordDeleteHandler(e, row);
                                }}
                            />
                        }
                    </FormGroup>
                </>
            ),

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const teachingInfrastructureColumns: TableColumn<TeachingInfrastructureList>[] = [

        {
            name: <div className="text-center">Physical Facilities</div>,
            selector: (row) => row.physical_facilities!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Number</div>,
            selector: (row) => row.total_number!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Accomodation/Seats</div>,
            selector: (row) => row.accomodation_or_seats!,
            sortable: false,
            wrap: true,
            center: true,
        },

        {
            name: <div className="text-center">Action</div>,
            width: '200px',
            cell: (row) => (
                <>
                    <FormGroup className="btn-group mt-2">
                        <button
                            className="btn btn-sm btn-secondary"
                            onClick={(e) => {
                                handleEditTeachingInfrastructureRecord(e, row);
                            }}
                        >
                            <span className="fas fa-edit"></span> Edit
                        </button>
                        <button
                            className="btn btn-sm btn-info"
                            style={{ pointerEvents: 'auto' }}
                            onClick={(e) => {
                                handleTeachingInfrastructureRecordView(e, row);
                            }}
                        >
                            <span className="fas fa-eye"></span> View
                        </button>
                        {
                            <Button
                                size="sm"
                                color="danger"
                                type="button"
                                text={`<span class="fas fa-trash" ></span> Delete`}
                                onClick={(e) => {
                                    teachingInfrastructureRecordDeleteHandler(e, row);
                                }}
                            />
                        }
                    </FormGroup>
                </>
            ),

            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (recordOfLibraryServiceDeleteEntry) {
            deleteLibraryServiceEntry(recordOfLibraryServiceForDelete?.id!);
        }
    }, [recordOfLibraryServiceDeleteEntry]);

    useEffect(() => {
        if (!isLibraryServiceEntryDeleteError && isLibraryServiceEntryDeleteSuccess) {
            toast.dismiss();
            toast.success('Books / journals / periodicals entry record deleted successfully');
            setRecordOfLibraryServiceDeleteModalShow(false);
            setRecordOfLibraryServiceDeleteEntry(false);
        } else if (isLibraryServiceEntryDeleteError && !isLibraryServiceEntryDeleteSuccess) {
            toast.dismiss();
            toast.error('Error while deleting the books / journals / periodicals record');
        }
    }, [isLibraryServiceEntryDeleteError, isLibraryServiceEntryDeleteSuccess]);

    useEffect(() => {
        if (recordOfSupportServiceDeleteEntry) {
            deleteSupportServiceEntry(recordOfSupportServiceForDelete?.id!);
        }
    }, [recordOfSupportServiceDeleteEntry]);

    useEffect(() => {
        if (!isSupportServiceEntryDeleteError && isSupportServiceEntryDeleteSuccess) {
            toast.dismiss();
            toast.success('Support service entry record deleted successfully');
            setRecordOfSupportServiceDeleteModalShow(false);
            setRecordOfSupportServiceDeleteEntry(false);
        } else if (isSupportServiceEntryDeleteError && !isSupportServiceEntryDeleteSuccess) {
            toast.dismiss();
            toast.error('Error while deleting the support service record');
        }
    }, [isSupportServiceEntryDeleteError, isSupportServiceEntryDeleteSuccess]);

    useEffect(() => {
        if (recordOfTeachingInfrastructureDeleteEntry) {
            deleteTeachingInfrastructureEntry(recordOfTeachingInfrastructureForDelete?.id!);
        }
    }, [recordOfTeachingInfrastructureDeleteEntry]);

    useEffect(() => {
        if (!isTeachingInfrastructureEntryDeleteError && isTeachingInfrastructureEntryDeleteSuccess) {
            toast.dismiss();
            toast.success('Teaching infrastructure entry record deleted successfully');
            setRecordOfTeachingInfrastructureDeleteModalShow(false);
            setRecordOfTeachingInfrastructureDeleteEntry(false);
        } else if (isTeachingInfrastructureEntryDeleteError && !isTeachingInfrastructureEntryDeleteSuccess) {
            toast.dismiss();
            toast.error('Error while deleting the teaching infrastructure record');
        }
    }, [isTeachingInfrastructureEntryDeleteError, isTeachingInfrastructureEntryDeleteSuccess]);

    const handleFormReset = () => {
        resetForm();
    };

    return (
        <div className="page-content">
            <Container fluid>
                <BetterBreadcrumb
                    title="Training Accreditation Application Form"
                    breadcrumbItem={[{ link: '/institute-registration-list', name: 'Department List' }]}
                />
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title mb-0 flex-grow-1">
                                    <span>Part-D: Support Service of the Department (To be checked and verified against the information provided by the institute in part D of Form - 1)</span>
                                </h5>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    autoComplete="off"
                                    className="custom-form"
                                    style={isDisabled === true ? { pointerEvents: 'none' } : {}}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    onReset={(e) => {
                                        e.preventDefault();
                                        handleFormReset();
                                    }}
                                >
                                    <Row>
                                        <Col sm={12}>
                                            <Row className='justify-content-evenly'>
                                                <Col sm={12} md={6}>
                                                    <FormGroup className="col-sm-12 mb-3">
                                                        <Label for="institute_name">Institute Name</Label>
                                                        <InputField
                                                            id="institute_name"
                                                            name="institute_name"
                                                            type="text"
                                                            value={instituteName}
                                                            placeholder=""
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col sm={12} md={6}>
                                                    <FormGroup className="col-sm-12 mb-3">
                                                        <Label for="department_name">Name of the Department</Label>
                                                        <InputField
                                                            id="department_name"
                                                            name="department_name"
                                                            type="text"
                                                            value={submittedDepartmentData?.data?.department_name!}
                                                            placeholder=""
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col sm={12}>
                                            <fieldset className="border p-2">
                                                <legend className='w-auto' style={{ float: 'none', padding: 'inherit' }}>
                                                    <span className='px-4 bg-success rounded-pill'><i className="fa-solid fa-1" style={{ color: '#f8f9fc' }}></i></span> Department and Service (for clinical departments only)
                                                </legend>

                                                <div className='filter-wrapper'>
                                                    <FormGroup className="ms-auto justify-content-end">
                                                        <BsButton
                                                            type="button"
                                                            className="btn btn-success me-2"
                                                            onClick={(e) => {
                                                                setEditRecordOfSupportService(false);
                                                                setRecordOfSupportServiceToEdit({} as SupportServiceList);
                                                                setShowSupportServiceModal(true);
                                                            }}
                                                        >
                                                            <i className="fa fa-plus me-2" /> Add Record
                                                        </BsButton>
                                                    </FormGroup>
                                                </div>
                                                <DataTable
                                                    columns={departmentAndServiceColumns}
                                                    data={supportServiceList?.data?.length! > 0 ? supportServiceList?.data! : []}
                                                    persistTableHead={true}
                                                    noDataComponent={<div className='border border-2 border-light border-top-0 p-5 text-center w-100'>There are no records to display</div>}
                                                    expandOnRowClicked
                                                    highlightOnHover
                                                />
                                            </fieldset>
                                        </Col>

                                        <Col sm={12}>
                                            <fieldset className="border p-2">
                                                <legend className='w-auto' style={{ float: 'none', padding: 'inherit' }}>
                                                    <span className='px-4 bg-success rounded-pill'><i className="fa-solid fa-2" style={{ color: '#f8f9fc' }}></i></span> Library Service
                                                </legend>

                                                <fieldset className="border p-2 mb-4">
                                                    <h5 className='font-size-14'>Library Services</h5>
                                                    <Row>
                                                        <Col sm={12} md={6}>
                                                            <FormGroup row className="mb-3">
                                                                <Label for="number_of_seat_in_library" sm={6}>Number of Seats:</Label>
                                                                <Col sm={3}>
                                                                    <InputField
                                                                        min={'0'}
                                                                        type="number"
                                                                        placeholder=""
                                                                        {...getFieldProps('number_of_seat_in_library')}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm={12} md={6}>
                                                            <FormGroup row className="mb-3">
                                                                <Label for="service_hour" sm={8}>Hours of Service Available: (Hours/day)</Label>
                                                                <Col sm={3}>
                                                                    <InputField
                                                                        min={'0'}
                                                                        type="number"
                                                                        placeholder=""
                                                                        {...getFieldProps('service_hour')}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </fieldset>

                                                <div className='filter-wrapper'>
                                                    <h5 className='font-size-14 ms-3'>Books / Journals / Periodicals</h5>
                                                    <FormGroup className="ms-auto justify-content-end">
                                                        <BsButton
                                                            type="button"
                                                            className="btn btn-success me-2"
                                                            onClick={(e) => {
                                                                setEditRecordOfLibraryService(false);
                                                                setRecordOfLibraryServiceToEdit({} as LibraryServiceList);
                                                                setShowLibraryServiceModal(true);
                                                            }}
                                                        >
                                                            <i className="fa fa-plus me-2" /> Add Record
                                                        </BsButton>
                                                    </FormGroup>
                                                </div>
                                                <DataTable
                                                    columns={booksJournalsPeriodicalsColumns}
                                                    data={libraryServiceList?.data?.length! > 0 ? libraryServiceList?.data! : []}
                                                    persistTableHead={true}
                                                    noDataComponent={<div className='border border-2 border-light border-top-0 p-5 text-center w-100'>There are no records to display</div>}
                                                    expandOnRowClicked
                                                    highlightOnHover
                                                />

                                                <fieldset className="border p-2 mt-3">
                                                    <Row>
                                                        <Col sm={12}>
                                                            <FormGroup row className="mb-3 justify-content-center">
                                                                <Label className="font-size-14 mb-3" sm={4}>Electronic version of books</Label>
                                                                <Col sm={1}>
                                                                    <div className="d-inline-block mx-2 mt-2">
                                                                        <div className="form-check form-check-left mb-3">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="electronicBook"
                                                                                id="electronicBookYes"
                                                                                value={1}
                                                                                checked={values.electronic_version_of_book === 1}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked) {
                                                                                        setFieldValue('electronic_version_of_book', 1);
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <label className="form-check-label" htmlFor="electronicBookYes">Yes</label>
                                                                        </div>
                                                                    </div>
                                                                </Col>

                                                                <Col sm={1}>
                                                                    <div className="d-inline-block mx-2 mt-2">
                                                                        <div className="form-check form-check-left">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="electronicBook"
                                                                                id="electronicBookNo"
                                                                                value={0}
                                                                                checked={values.electronic_version_of_book === 0}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked) {
                                                                                        setFieldValue('electronic_version_of_book', 0);
                                                                                    }
                                                                                }}
                                                                                defaultChecked
                                                                            />
                                                                            <label className="form-check-label" htmlFor="electronicBookNo">No</label>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={12}>
                                                            <FormGroup row className="mb-3 justify-content-center">
                                                                <Label className="font-size-14 mb-3" sm={4}>Student's accessibility to the internet</Label>
                                                                <Col sm={1}>
                                                                    <div className="d-inline-block mx-2 mt-2">
                                                                        <div className="form-check form-check-left mb-3">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="studentsAccessibility"
                                                                                id="studentsAccessibilityYes"
                                                                                value={1}
                                                                                checked={values.student_access_to_internet === 1}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked) {
                                                                                        setFieldValue('student_access_to_internet', 1);
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <label className="form-check-label" htmlFor="studentsAccessibilityYes">Yes</label>
                                                                        </div>
                                                                    </div>
                                                                </Col>

                                                                <Col sm={1}>
                                                                    <div className="d-inline-block mx-2 mt-2">
                                                                        <div className="form-check form-check-left">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="studentsAccessibility"
                                                                                id="studentsAccessibilityNo"
                                                                                value={0}
                                                                                checked={values.student_access_to_internet === 0}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked) {
                                                                                        setFieldValue('student_access_to_internet', 0);
                                                                                    }
                                                                                }}
                                                                                defaultChecked
                                                                            />
                                                                            <label className="form-check-label" htmlFor="studentsAccessibilityNo">No</label>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={12}>
                                                            <FormGroup row className="mb-3 justify-content-center">
                                                                <Label className="font-size-14 mb-3" sm={4}>Does the library have any link with other library?</Label>
                                                                <Col sm={1}>
                                                                    <div className="d-inline-block mx-2 mt-2">
                                                                        <div className="form-check form-check-left mb-3">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="linkWithOtherLibrary"
                                                                                id="linkWithOtherLibraryYes"
                                                                                value={1}
                                                                                checked={values.link_with_other_library === 1}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked) {
                                                                                        setFieldValue('link_with_other_library', 1);
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <label className="form-check-label" htmlFor="linkWithOtherLibraryYes">Yes</label>
                                                                        </div>
                                                                    </div>
                                                                </Col>

                                                                <Col sm={1}>
                                                                    <div className="d-inline-block mx-2 mt-2">
                                                                        <div className="form-check form-check-left">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="linkWithOtherLibrary"
                                                                                id="linkWithOtherLibraryNo"
                                                                                value={0}
                                                                                checked={values.link_with_other_library === 0}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked) {
                                                                                        setFieldValue('link_with_other_library', 0);
                                                                                    }
                                                                                }}
                                                                                defaultChecked
                                                                            />
                                                                            <label className="form-check-label" htmlFor="linkWithOtherLibraryNo">No</label>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={12}>
                                                            <FormGroup row className="mb-3 justify-content-center">
                                                                <Label className="font-size-14 mb-3" sm={4}>Access to electronic journal and publications</Label>
                                                                <Col sm={1}>
                                                                    <div className="d-inline-block mx-2 mt-2">
                                                                        <div className="form-check form-check-left mb-3">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="accessToElectronicJournal"
                                                                                id="accessToElectronicJournalYes"
                                                                                value={1}
                                                                                checked={values.access_to_electronic_journal === 1}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked) {
                                                                                        setFieldValue('access_to_electronic_journal', 1);
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <label className="form-check-label" htmlFor="accessToElectronicJournalYes">Yes</label>
                                                                        </div>
                                                                    </div>
                                                                </Col>

                                                                <Col sm={1}>
                                                                    <div className="d-inline-block mx-2 mt-2">
                                                                        <div className="form-check form-check-left">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="accessToElectronicJournal"
                                                                                id="accessToElectronicJournalNo"
                                                                                value={0}
                                                                                checked={values.access_to_electronic_journal === 0}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked) {
                                                                                        setFieldValue('access_to_electronic_journal', 0);
                                                                                    }
                                                                                }}
                                                                                defaultChecked
                                                                            />
                                                                            <label className="form-check-label" htmlFor="accessToElectronicJournalNo">No</label>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col sm={12}>
                                                            <FormGroup row className="mb-3 justify-content-center">
                                                                <Label className="font-size-14 mb-3" sm={4}>Link to WHO website (HINARI)</Label>
                                                                <Col sm={1}>
                                                                    <div className="d-inline-block mx-2 mt-2">
                                                                        <div className="form-check form-check-left mb-3">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="linkToWhoWebsite"
                                                                                id="linkToWhoWebsiteYes"
                                                                                value={1}
                                                                                checked={values.link_to_who_website === 1}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked) {
                                                                                        setFieldValue('link_to_who_website', 1);
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <label className="form-check-label" htmlFor="linkToWhoWebsiteYes">Yes</label>
                                                                        </div>
                                                                    </div>
                                                                </Col>

                                                                <Col sm={1}>
                                                                    <div className="d-inline-block mx-2 mt-2">
                                                                        <div className="form-check form-check-left">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="linkToWhoWebsite"
                                                                                id="linkToWhoWebsiteNo"
                                                                                value={0}
                                                                                checked={values.link_to_who_website === 0}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked) {
                                                                                        setFieldValue('link_to_who_website', 0);
                                                                                    }
                                                                                }}
                                                                                defaultChecked
                                                                            />
                                                                            <label className="form-check-label" htmlFor="linkToWhoWebsiteNo">No</label>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </fieldset>
                                            </fieldset>
                                        </Col>

                                        <Col sm={12}>
                                            <fieldset className="border p-2">
                                                <legend className='w-auto' style={{ float: 'none', padding: 'inherit' }}>
                                                    <span className='px-4 bg-success rounded-pill'><i className="fa-solid fa-3" style={{ color: '#f8f9fc' }}></i></span> Teaching Infrastructure
                                                </legend>

                                                <div className='filter-wrapper'>
                                                    <FormGroup className="ms-auto justify-content-end">
                                                        <BsButton
                                                            type="button"
                                                            className="btn btn-success me-2"
                                                            onClick={(e) => {
                                                                setEditRecordOfTeachingInfrastructure(false);
                                                                setRecordOfTeachingInfrastructureToEdit({} as TeachingInfrastructureList);
                                                                setShowTeachingInfrastructureModal(true);
                                                            }}
                                                        >
                                                            <i className="fa fa-plus me-2" /> Add Record
                                                        </BsButton>
                                                    </FormGroup>
                                                </div>
                                                <DataTable
                                                    columns={teachingInfrastructureColumns}
                                                    data={teachingInfrastructureList?.data?.length! > 0 ? teachingInfrastructureList?.data! : []}
                                                    persistTableHead={true}
                                                    noDataComponent={<div className='border border-2 border-light border-top-0 p-5 text-center w-100'>There are no records to display</div>}
                                                    expandOnRowClicked
                                                    highlightOnHover
                                                />
                                            </fieldset>
                                        </Col>
                                    </Row>

                                    <FormGroup row className='justify-content-between mt-5'>
                                        <Col sm={9}>
                                            <BsButton color="warning" style={isDisabled === true ? { pointerEvents: 'auto' } : {}} className='me-3 text-start' onClick={() => {
                                                goToPrevious();
                                            }}>
                                                {'Previous'}
                                            </BsButton>
                                        </Col>

                                        {/* {isDisabled !== true &&
                                            <Col sm={3}>
                                                <div className='d-flex justify-content-end'>
                                                    <BsButton color="secondary" className='me-3' onClick={() => {
                                                        handleFormReset();
                                                    }}>
                                                        {'Reset'}
                                                    </BsButton>
                                                    <BsButton color="info" className='me-3' disabled={isPartDMasterInfoDataLoading} onClick={() => {
                                                        setSubmitStatus(false);
                                                        handleSubmit();
                                                    }}>
                                                        {'Save as Draft'}
                                                    </BsButton>
                                                    <BsButton color="primary" disabled={isPartDMasterInfoDataLoading} onClick={() => {
                                                        setSubmitStatus(true);
                                                        handleSubmit();
                                                    }}>
                                                        {'Apply'}
                                                    </BsButton>
                                                </div>
                                            </Col>} */}

                                        <Col sm={3}>
                                            {isDisabled !== true ?
                                                <div className='d-flex justify-content-end'>
                                                    <BsButton color="secondary" className='me-3' onClick={() => {
                                                        handleFormReset();
                                                    }}>
                                                        {'Reset'}
                                                    </BsButton>
                                                    <BsButton color="success" onClick={() => {
                                                        handleSubmit();                                                        
                                                    }}>
                                                        {'Save & Next'}
                                                    </BsButton>
                                                </div>
                                                :
                                                <div className='d-flex justify-content-end'>
                                                    <BsButton color="success" style={{ pointerEvents: 'auto' }} onClick={() => {
                                                        goToNext();
                                                    }}>
                                                        {'Next'}
                                                    </BsButton>
                                                </div>}
                                        </Col>
                                    </FormGroup>
                                </Form>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showSupportServiceModal}
                                    scrollable={true}
                                    size="lg"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Department and Service (for clinical departments only)
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowSupportServiceModal(!showSupportServiceModal);
                                                setEditRecordOfSupportService(false);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <Row>
                                            <Col>
                                                {!editRecordOfSupportService && !recordOfSupportServiceToEdit?.id ?
                                                    <DepartmentAndServiceForClinicalDepartmentsOnlyModal departmentId={departmentId} setModalShow={setShowSupportServiceModal} />
                                                    :
                                                    <DepartmentAndServiceForClinicalDepartmentsOnlyModal departmentId={departmentId} recordOfSupportServiceToEdit={recordOfSupportServiceToEdit} setModalShow={setShowSupportServiceModal} />
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showLibraryServiceModal}
                                    scrollable={true}
                                    size="lg"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Books / Journals / Periodicals
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowLibraryServiceModal(!showLibraryServiceModal);
                                                setEditRecordOfLibraryService(false);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <Row>
                                            <Col>
                                                {!editRecordOfLibraryService && !recordOfLibraryServiceToEdit?.id ?
                                                    <BooksJournalsPeriodicalsModal departmentId={departmentId} setModalShow={setShowLibraryServiceModal} />
                                                    :
                                                    <BooksJournalsPeriodicalsModal departmentId={departmentId} recordOfLibraryServiceToEdit={recordOfLibraryServiceToEdit} setModalShow={setShowLibraryServiceModal} />
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showTeachingInfrastructureModal}
                                    scrollable={true}
                                    size="lg"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Teaching Infrastructure
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowTeachingInfrastructureModal(!showTeachingInfrastructureModal);
                                                setEditRecordOfTeachingInfrastructure(false);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <Row>
                                            <Col>
                                                {!editRecordOfTeachingInfrastructure && !recordOfTeachingInfrastructureToEdit?.id ?
                                                    <TeachingInfrastructureModal departmentId={departmentId} setModalShow={setShowTeachingInfrastructureModal} />
                                                    :
                                                    <TeachingInfrastructureModal departmentId={departmentId} recordOfTeachingInfrastructureToEdit={recordOfTeachingInfrastructureToEdit} setModalShow={setShowTeachingInfrastructureModal} />
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showSupportServiceRecordViewModal}
                                    scrollable={true}
                                    size="md"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className='modal-header'>
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Department and Service Record Information
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowSupportServiceRecordViewModal(!showSupportServiceRecordViewModal);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <ModalBody>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table table-bordered table-striped table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>Faculty: </strong>
                                                                {supportServiceRecordInModal?.department_name ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>No. of Academic Staff Available: </strong>
                                                                {supportServiceRecordInModal?.no_of_academic_staff ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>No. of Support Staff (Technologist): </strong>
                                                                {supportServiceRecordInModal?.no_of_support_staff ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Essential Equipment List: </strong>
                                                                {supportServiceRecordInModal?.equipment_list ? (
                                                                    <a
                                                                        className="ms-2 me-2 px-2 btn btn-outline-info"
                                                                        href={config.APP_URL + supportServiceRecordInModal?.equipment_list}
                                                                        rel="noreferrer"
                                                                        target="_blank"
                                                                    >
                                                                        View
                                                                    </a>
                                                                ) : 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Service Offered to Department: </strong>
                                                                {supportServiceRecordInModal?.service_offered ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showLibraryServiceRecordViewModal}
                                    scrollable={true}
                                    size="md"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className='modal-header'>
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Books / Journals / Periodicals  Record Information
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowLibraryServiceRecordViewModal(!showLibraryServiceRecordViewModal);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <ModalBody>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table table-bordered table-striped table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>Type: </strong>
                                                                {libraryServiceRecordInModal?.category ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Title: </strong>
                                                                {libraryServiceRecordInModal?.titles ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Total Books: </strong>
                                                                {libraryServiceRecordInModal?.total_books ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={showTeachingInfrastructureRecordViewModal}
                                    scrollable={true}
                                    size="md"
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className='modal-header'>
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Teaching Infrastructure Record Information
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowTeachingInfrastructureRecordViewModal(!showTeachingInfrastructureRecordViewModal);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <ModalBody>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table className="table table-bordered table-striped table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>Physical Facilities: </strong>
                                                                {teachingInfrastructureRecordInModal?.physical_facilities ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Number: </strong>
                                                                {teachingInfrastructureRecordInModal?.total_number ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Accomodation/Seats: </strong>
                                                                {teachingInfrastructureRecordInModal?.accomodation_or_seats ?? 'N/A'}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={recordOfSupportServiceDeleteModalShow}
                                    scrollable={true}
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Delete Record of Department and Service
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setRecordOfSupportServiceDeleteModalShow(!recordOfSupportServiceDeleteModalShow);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        {recordOfSupportServiceForDelete && (
                                            <Row>
                                                <Col>
                                                    <p>
                                                        Are you sure you want to delete the department and service record? {' '}
                                                    </p>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setRecordOfSupportServiceDeleteModalShow(false);
                                            }}
                                            className="btn btn-secondary me-2"
                                            data-dismiss="modal"
                                        >
                                            No
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={(e) => {
                                                setRecordOfSupportServiceDeleteEntry(true);
                                            }}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={recordOfLibraryServiceDeleteModalShow}
                                    scrollable={true}
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Delete Record of Books / Journals / Periodicals
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setRecordOfLibraryServiceDeleteModalShow(!recordOfLibraryServiceDeleteModalShow);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        {recordOfLibraryServiceForDelete && (
                                            <Row>
                                                <Col>
                                                    <p>
                                                        Are you sure you want to delete the books / journals / periodicals record? {' '}
                                                    </p>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setRecordOfLibraryServiceDeleteModalShow(false);
                                            }}
                                            className="btn btn-secondary me-2"
                                            data-dismiss="modal"
                                        >
                                            No
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={(e) => {
                                                setRecordOfLibraryServiceDeleteEntry(true);
                                            }}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </Modal>

                                <Modal
                                    onOpened={function () {
                                        toast.dismiss();
                                    }}
                                    isOpen={recordOfTeachingInfrastructureDeleteModalShow}
                                    scrollable={true}
                                    toggle={() => {
                                        document.body.classList.add('no_padding');
                                    }}
                                    backdrop={'static'}
                                    modalTransition={{ timeout: 0 }}
                                    backdropTransition={{ timeout: 0 }}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="staticBackdropLabel">
                                            Delete Record of Teaching Infrastructure
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setRecordOfTeachingInfrastructureDeleteModalShow(!recordOfTeachingInfrastructureDeleteModalShow);
                                            }}
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        {recordOfTeachingInfrastructureForDelete && (
                                            <Row>
                                                <Col>
                                                    <p>
                                                        Are you sure you want to delete the teaching infrastructure record? {' '}
                                                    </p>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setRecordOfTeachingInfrastructureDeleteModalShow(false);
                                            }}
                                            className="btn btn-secondary me-2"
                                            data-dismiss="modal"
                                        >
                                            No
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={(e) => {
                                                setRecordOfTeachingInfrastructureDeleteEntry(true);
                                            }}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </Modal>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container >
        </div >
    );
};

export default InstituteDeptRegistrationStepSix;