import React, { useEffect } from 'react';
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap';
import { useCreateMyTrainingMutation } from 'src/rtk/features/myTraining/myTrainingApi';
import { useGetFcpsCourseListMutation } from 'src/rtk/features/trainee/traineeFcpsCourseListApi';

interface Props {
  isOpen: boolean;
  toggle: any;
  courseName: string;
}

const CourseListViewDetailsModal = (props: Props) => {
  const { isOpen, toggle, courseName } = props;

  const [
    createFcpsCourse,
    { data: fcpsCourseData, isSuccess, isError, error },
  ] = useGetFcpsCourseListMutation();

  //   let supervisorOptions: SelectOption[] = [...supervisorList?.map((e: Supervisors) => ({label: e.name!, value: e.id!}))]

  const err: CustomError = error as CustomError;
  const body = {
    name: courseName,
  };
  const [createMyTraining, { data: courseList }] =
    useCreateMyTrainingMutation();

  useEffect(() => {
    createMyTraining(body);
  }, []);
  console.log(courseList);

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="lg">
      <ModalHeader toggle={toggle}>Course</ModalHeader>
      <ModalBody>
        <React.Fragment>
          <Container fluid>
            <Row>
              <Col>

                <h5 className="block-heading">Logbook List</h5>
                <div className="table-responsive mb-3">
                  <Table className="table table-striped table-hover table-sm">
                    <thead>
                      <tr>
                        <th>Sl No.</th>
                        <th>Program Type</th>
                        <th>Slot</th>
                        <th>Duration</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {courseList?.data?.Course?.map(
                        (elmt, index: number) => {
                          return (
                            <tr key={elmt.id}>
                              <th scope="row">{index + 1}</th>
                              <td>{elmt?.training_type === 'Advance' ? 'Specialty' : elmt?.training_type}</td>
                              <td>
                                {elmt?.name ? elmt?.name : 'N/A'}
                              </td>

                              <td>
                                {elmt?.duration ? elmt?.duration +
                                  ' ' +
                                  elmt?.duration_unit : 'N/A'}
                              </td>
                              <td>{elmt.status ? 'Active' : 'Inactive'}</td>
                            </tr>
                          );
                        },
                      )}
                    </tbody>
                  </Table>
                </div>

                <h5 className="block-heading">Workshop/Training List</h5>
                <div className="table-responsive mb-3">
                  <Table className="table table-striped table-hover table-sm">
                    <thead>
                      <tr>
                        <th>Sl No.</th>
                        <th>Training Type</th>
                        <th>Workshop/Training Title</th>
                        <th>Duration</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {courseList?.data?.WorkshopAndTraining?.map(
                        (elmt, index: number) => {
                          return (
                            <tr key={elmt.id}>
                              <th scope="row">{index + 1}</th>
                              <td>{elmt.type}</td>
                              <td>{elmt.name}</td>
                              <td>
                                {elmt?.duration +
                                  ' ' +
                                  elmt?.duration_unit}
                              </td>

                              <td>{elmt.status ? 'Active' : 'Inactive'}</td>
                            </tr>
                          );
                        },
                      )}
                    </tbody>
                  </Table>
                </div>

              </Col>

              {/* <Col lg={12}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title">Training List</h4>
                  </CardHeader>
                  <CardBody>
                    <div className="table-responsive">
                      <Table className="table table-striped table-hover mb-0">
                        <thead>
                          <tr>
                            <th>Sl No.</th>
                            <th>Training Type</th>
                            <th>Training Name</th>
                            <th>Duration</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {courseList?.data?.Training?.map(
                            (elmt, index: number) => {
                              return (
                                <tr key={elmt.id}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{elmt.type}</td>
                                  <td>{elmt.name}</td>
                                  <td>
                                    {elmt?.duration +
                                      ' ' +
                                      elmt?.duration_unit}
                                  </td>

                                  <td>{elmt.status ? 'Active' : 'Inactive'}</td>
                                </tr>
                              );
                            },
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col> */}
            </Row>
          </Container>
        </React.Fragment>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CourseListViewDetailsModal;
