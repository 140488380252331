import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import { useDeleteLogBookMasterSetupMutation } from 'src/rtk/features/setup/logBookMasterSetup/logBookMasterSetupApi';

interface Props {
  logBookSetupMaster: LogBookSetupMasters;
  index: number;
}

const LogBookMasterSetupTableRow = ({ logBookSetupMaster, index }: Props) => {
  const history = useHistory();
  
  const [infoId, setInfoId] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const [deleteApproval, { isSuccess, isLoading, isError }] =
    useDeleteLogBookMasterSetupMutation();

  const deleteHandler = (modalState: boolean, id: string) => {
    setModalShow(modalState);
    setInfoId(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Log Book Master deleted successfully');
    }
    if (isError) {
      toast.error('Error deleting Log Book Master');
    }
  }, [isSuccess, isError]);

  return (
    <tr key={logBookSetupMaster.id}>
      <th scope="row">{index + 1}</th>
      <td>{logBookSetupMaster.logbook_heading}</td>
      <td>{logBookSetupMaster.exam_name}</td>
      <td>{logBookSetupMaster.subject_name}</td>
      <td>{logBookSetupMaster.logbook_type}</td>

      <td>{logBookSetupMaster.status ? 'Active' : 'Inactive' || 'N/A'}</td>

      <td>
        <div className="d-flex gap-2">
          <Button
            size="sm"
            text="Edit"
            color="warning"
            onClick={() =>
              history.push(`/Log/Book/master/Setup/${logBookSetupMaster.id}`)
            }
          />

          <Button
            size="sm"
            text="Delete"
            color="danger"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => deleteHandler(true, logBookSetupMaster.id!)}
          />
        </div>
        {modalShow ? (
          <DeleteInformationModal
            isOpen={modalShow}
            toggle={() => setModalShow(!modalShow)}
            id={infoId}
            deleteInfomation={deleteApproval}
          />
        ) : null}
      </td>
    </tr>
  );
};

export default LogBookMasterSetupTableRow;
